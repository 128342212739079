<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        :to="{ path: `/admin/account/${currentAid}/channels` }"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>
    <DevDetails :cnt="{name: 'newChannelGroup', obj: newChannelGroup}" />

    <div>
      <h1 class="title is-1">Add workspace to Account</h1>

      <br>

      <form @submit.prevent="createChannelGroup()">
        <fieldset>
          <b-field label="Name (by default use the instagram username of channel)">
            <b-input
              v-model="newChannelGroup.name"
              required
              :placeholder="'Sweet & Spicey new workspace name'"
            />
          </b-field>
        </fieldset>

        <br>

        <div class="buttons is-right">
          <b-button
            label="Save"
            class="is-primary"
            native-type="submit"
          />
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheAdminAccountAddWorkspace',
  data() {
    return {
      newChannelGroup: {
        name: null,
        picture: null,
      },
    };
  },
  computed: {
    currentAid() {
      return this.$route.params.aid;
    },
  },
  methods: {
    ...mapActions({
      _createChannelGroup: 'TheAdmin/createChannelGroup',
      _getAccount: 'TheAdmin/getAccount',
      up_changeState: 'TheAdmin/up_changeState',
    }),
    createChannelGroup() {
      const payload = { aid: this.currentAid, ...this.newChannelGroup };

      this._createChannelGroup({ payload })
        .then(() => this._getAccount({
          payload: { aid: this.currentAid },
          httpQuery: { channelAndChannelGroupList: true },
        }));
    },
  },
};
</script>
