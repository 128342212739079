/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {},

  mutations: {},

  getters: {
    tabItemsForTheAddPeoplePanel: () => ({
      'root.proto': {
        tab: 'root.proto',
        title: 'Add manually',
        subtext: 'Add people',
      },
      'root.proto.selectProtoTarget': {
        tab: 'root.proto.selectProtoTarget',
        title: 'What account do you want to scrap profiles from?',
        subtext: 'Add people',
      },
      'root.proto.createCrmList': {
        tab: 'root.proto.createCrmList',
        title: 'Create a new list',
        subtext: 'Add people',
      },
      'root.proto.selectProtoTarget.createCrmList': {
        tab: 'root.proto.selectProtoTarget.createCrmList',
        title: 'Create a new list',
        subtext: 'Add people',
      },
      'root.proto.createCrmList.done': {
        tab: 'root.proto.createCrmList.done',
        title: 'Success!',
        subtext: 'Add people',
      },
    }),
  },

  actions: {},
};
