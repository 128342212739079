<template>
  <div>
    <span
      v-if="title"
      class="subtext has-text-secondary"
    >
      {{ title }}
    </span>

    <canvas :id="chartId" />
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import { optionLineSimple, calculateStepYAxis } from '../_utils/chartJSParams';

export default {
  name: 'UbuChartLine',
  props: {
    chartId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseOptions: optionLineSimple,
      calculateStepYAxis,
    };
  },
  watch: {
    data() {
      if (this.currentChart) this.currentChart.destroy();

      const ctx = document.getElementById(this.chartId);

      this.baseOptions.options.scales.y.ticks.stepSize = this.calculateStepYAxis(this.data);

      this.currentChart = new Chart(ctx, { ...this.baseOptions, data: this.data });
    },
  },
  mounted() {
    const ctx = document.getElementById(this.chartId);

    this.baseOptions.options.scales.y.ticks.stepSize = this.calculateStepYAxis(this.data);

    this.currentChart = new Chart(ctx, { ...this.baseOptions, data: this.data });
  },
};
</script>
