<template>
  <div class="buttons">
    <b-button
      v-if="threadStateIsOpen === true"
      label="Close"
      type="is-secondary"
      icon-pack="ubu"
      icon-left="ubu-close"
      @click="$emit('openClose')"
    />

    <b-button
      v-if="threadStateIsOpen === false"
      label="Open"
      type="is-secondary"
      icon-pack="ubu"
      icon-left="ubu-open"
      @click="$emit('openClose')"
    />

    <b-button
      label="Send message"
      type="is-secondary"
      icon-pack="ubu"
      icon-left="ubu-compose"
      @click="$emit('click', 'root.sendMessage')"
    />

    <b-button
      label="Assignee"
      type="is-secondary"
      icon-pack="ubu"
      icon-left="ubu-assign"
      @click="$emit('click', 'root.assignUser.done')"
    />

    <b-button
      label="Tags"
      type="is-secondary"
      icon-pack="ubu"
      icon-left="ubu-tag"
      @click="$emit('click', 'root.assignTag.done')"
    />

    <b-button
      label="Labels"
      type="is-secondary"
      icon-pack="ubu"
      icon-left="ubu-contact"
      @click="$emit('click', 'root.assignLabel.done')"
    />

    <b-button
      label="Tribes"
      type="is-secondary"
      icon-pack="ubu"
      icon-left="ubu-tribe"
      @click="$emit('click', 'root.addToTribe')"
    />

    <b-button
      label="Campaigns"
      type="is-secondary"
      icon-pack="ubu"
      icon-left="ubu-influence"
      @click="$emit('click', 'root.addToCampaign')"
    />

    <b-button
      :label="hasUnread ? 'Read' : 'Unread'"
      type="is-secondary"
      icon-pack="ubu"
      :icon-left="hasUnread ? 'ubu-eye' : 'ubu-eye-closed'"
      @click="$emit('readUnread')"
    />
  </div>
</template>

<script>
export default {
  name: 'UbuThreadBulkActions',
  props: {
    threadStateIsOpen: {
      type: Boolean,
      default: null,
    },
    hasUnread: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
