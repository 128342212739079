<template>
  <section
    class="ubuMessageWrapper"
    :isMe="message.isMe"
  >
    <b-skeleton
      :height="message.height"
      :width="message.width"
      size="is-large"
    />
  </section>
</template>

<script>
export default {
  name: 'UbuThreadMessageSkeleton',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
};
</script>
