/* eslint-disable quotes */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const apiCrew = {
  brand: {
    get: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/brand`, method: 'GET', payload, httpQuery, target }),
    post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/brand`, method: 'POST', payload, httpQuery, target }),
    put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/brand`, method: 'PUT', payload, httpQuery, target }),
  },
  crewUser: {
    invite: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/contactPlatform/${payload.contactPlatformCpid}/crewUser`, method: 'POST', payload, httpQuery, target }),
  },
  crewAdmin: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/crewAdmin`, method: 'REPORT', payload, httpQuery, target }),
    put: ({ payload, httpQuery }) => builder({ url: `/api/crewAdmin/${payload.cuid}`, method: 'PUT', payload, httpQuery, target }),

  },

};

export default apiCrew;
