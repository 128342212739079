/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const adminLogin = {
  createLink: ({ payload, httpQuery }) => builder({ url: `/api/adminLogin/${payload.uid}`, method: 'POST', payload, httpQuery, target }),
  connectFromLink: ({ payload, httpQuery }) => builder({ url: `/api/adminLogin/${payload.link}`, method: 'PUT', payload, httpQuery, target }),
};

export default adminLogin;
