export default {
  data() {
    return {
      columns: [
        {
          col: 'aid',
          label: 'Aid',
          visible: true,
          isSortable: false,
          cmp: 'flat',
          cellClass: 'static',
        },
        {
          col: 'owner',
          label: 'Owner',
          visible: true,
          isSortable: false,
          cmp: 'flat',
          cellClass: 'static',
        },
        {
          col: 'isAdmin',
          label: 'isAdmin',
          cmp: 'tag',
          tag: {
            condition: 'isAdmin',
            color: 'is-primary',
            text: 'Admin',
          },
          cellClass: 'static',
        },
        { col: 'action', cmp: 'action', cellClass: 'fullWidth' },
      ],
    };
  },
};
