/* eslint-disable no-multi-spaces */
import TheLeadsDashboard from './TheLeadsDashboard';
import TheLeadsGeneration from './TheLeadsGeneration';
import TheScraping from './TheScraping';
import TheSuggestions from './TheSuggestions';
import TheAdministration from './TheAdministration';
import TheAdministrationScrapingZone from './TheAdministrationScrapingZone';
import TheUbuLeadTemplates from './TheUbuLeadTemplates';

export default [
  {
    path: '/sales',
    redirect: '/ubuLead/dashboard',
  },
  {
    path: '/ubuLead/dashboard',
    name: 'UbuLead - Dashboard',
    component: TheLeadsDashboard,
    meta: {
      requireAuth: true,
      requiredRights: ['UBU_LEAD.REPORT', 'UBU_LEAD.CREATE'],
      hasMenu: true,
      viewStoreNavigation: 'TheLeadsDashboard',
    },
  },
  {
    path: '/ubuLead/scrap',
    redirect: '/ubuLead/leadsGeneration/scrap',
  },
  {
    path: '/ubuLead/leadsGeneration',
    redirect: '/ubuLead/leadsGeneration/scrap',
  },
  {
    path: '/ubuLead/leadsGeneration',
    name: 'Leads Generation',
    component: TheLeadsGeneration,
    meta: {
      inSidebar: true,
      sidebarSlot: 'Sales',
      requireAuth: true,
      mainFolder: 'UbuLead',
      endpointName: 'LeadsGeneration',
      requiredRights: ['UBU_LEAD.CREATE'],
    },
    children: [
      {
        path: '/ubuLead/leadsGeneration/scrap',
        name: 'Scraping',
        component: TheScraping,
        meta: {
          inSidebar: true,
          sidebarSlot: 'Sales',
          requireAuth: true,
          mainFolder: 'UbuLead',
          endpointName: 'Scrap',
          requiredRights: ['UBU_LEAD.CREATE'],
          hasMenu: true,
          viewStoreNavigation: 'TheScraping',
        },
      },
      {
        path: '/ubuLead/leadsGeneration/suggestions',
        name: 'Suggestions',
        component: TheSuggestions,
        meta: {
          inSidebar: true,
          sidebarSlot: 'Sales',
          requireAuth: true,
          mainFolder: 'UbuLead',
          endpointName: 'Scrap',
          requiredRights: ['SUGGESTION.CREATE'],
          hasMenu: true,
          viewStoreNavigation: 'TheSuggestions',
        },
      },
    ],
  },
  {
    path: '/ubuLead/administration',
    name: 'Administration',
    component: TheAdministration,
    meta: {
      inSidebar: true,
      sidebarSlot: 'Sales',
      requireAuth: true,
      mainFolder: 'UbuLead',
      endpointName: 'Administration',
      requiredRights: ['UBU_LEAD.CREATE'],
      hasMenu: true,
      viewStoreNavigation: 'TheAdministration',
    },
    children: [
      {
        path: '/ubuLead/administration/scrapingZone',
        name: 'Administration - Scraping Zone',
        component: TheAdministrationScrapingZone,
        meta: {
          inSidebar: true,
          sidebarSlot: 'Sales',
          requireAuth: true,
          mainFolder: 'UbuLead',
          endpointName: 'Administration',
          requiredRights: ['UBU_LEAD.CREATE'],
          hasMenu: true,
          viewStoreNavigation: 'TheAdministration',
        },
      },
      // {
      //   path: '/ubuLead/leadsGeneration/suggestions',
      //   name: 'Suggestions',
      //   component: TheSuggestions,
      //   meta: {
      //     inSidebar: true,
      //     sidebarSlot: 'Sales',
      //     requireAuth: true,
      //     mainFolder: 'UbuLead',
      //     endpointName: 'Scrap',
      //     requiredRights: ['SUGGESTION.CREATE'],
      //   },
      // },
    ],
  },
  {
    path: '/ubuLead/template',
    name: 'UbuLead - Template',
    component: TheUbuLeadTemplates,
    meta: {
      inSidebar: true,
      sidebarSlot: 'Sales',
      requireAuth: true,
      mainFolder: 'UbuLead',
      endpointName: 'Template',
      // requiredRights: ['UBU_LEAD.CREATE'],
    },
  },
];
