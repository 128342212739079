<template>
  <section class="multibar row-vcentered">
    <UbuVectorIcon
      class="start mr-2"
      :icon="{
        pack: 'ubu',
        icon: `ubu-social-google-drive`,
        iconPaths: 4,
        fontSize: '38'
      }"
    />

    <div class="start">
      <h3 class="text is-3">Google Drive</h3>

      <p class="subtext is-3 has-text-secondary start">admin@wooliebrand.com</p>
    </div>

    <b-button
      class="end"
      label="Connect"
      type="is-info"
    />
  </section>
</template>

<script>
export default {
  name: 'UbuGoogleDriveConnectRow',
};
</script>
