<template>
  <b-dropdown
    class="ubuDropdown"
    :mobile-modal="false"
    :close-on-click="closeOnClick"
    :can-close="canClose"
    :append-to-body="appendToBody"
    :expanded="expanded"
    :position="position"
    :triggers="isDisabled ? [] : ['click']"
    @active-change="handleToggle($event)"
  >
    <template #trigger>
      <slot name="button" />
    </template>

    <slot name="header" />

    <div class="slot-searchbar">
      <slot name="search" />
    </div>

    <div :class="['slot-content', { 'scrollable': scrollable }]">
      <slot name="content" />
    </div>

    <div class="slot-action">
      <slot name="action" />
    </div>
  </b-dropdown>
</template>

<script>
export default {
  name: 'UbuDropdown',
  props: {
    closeOnClick: {
      type: Boolean,
      default: true,
    },
    canClose: {
      type: [Array, Boolean],
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'is-bottom-left',
    },
  },
  methods: {
    handleToggle(active) {
      if (this.isDisabled) return undefined;
      if (!active) return this.$emit('close');
      return this.$emit('open');
    },
  },
};
</script>
