/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {},
  getters: {
    ...getters.campaign,

    tabItemsForCampaignPeople: () => ({
      'root.removeFromCampaign': {
        tab: 'root.removeFromCampaign',
        title: 'Remove from campaign',
        subtext: 'Bulk actions',
      },
      'root.removeFromCampaign.done': {
        tab: 'root.removeFromCampaign.done',
        title: 'Success',
        subtext: 'Bulk actions',
      },
    }),
  },
  actions: {
    ...actions.bulkActivity,
  },
};
