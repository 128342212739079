import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

/* eslint-disable max-len */
export default {
  getters: {
    activityStatusList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ActivityStatus/getterActivityStatusList': activityStatusList } = rootGetters;

      return Object.values(activityStatusList);
    },
    activityStatusListMapped: (_state, _getters, _rootState, rootGetters) => {
      const { 'ActivityStatus/getterActivityStatusList': activityStatusList } = rootGetters;

      return activityStatusList;
    },
  },
  actions: {
    reportActivityStatus({ dispatch }) {
      const action = {
        dispatch,
        target: 'ActivityStatus/report',
        context: { payload: {}, httpQuery: {} },
        textNotifier: 'Successfully report activity status.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report activity status.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
  },
};
