import Errors from '@dailyplanet/utils/Errors';

import { dispatchAndForward, dispatchFailure } from '../../$utils/helpers';

export default {
  namespaced: true,
  getters: {
    adminLoginTokenLink: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAuthenticate/adminLogin/getterAdminLoginTokenLink': getterAdminLoginTokenLink } = rootGetters;

      return getterAdminLoginTokenLink;
    },
  },
  actions: {
    createLink({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'ApiAuthenticate/adminLogin/createLink',
        context: { payload },
        textNotifier: 'Create admin login link',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch((error) => {
          console.log('🚀 ~ file: adminLogin.js ~ line 39 ~ createLink ~ error', error);

          dispatchFailure(action);
          throw new Errors(error);
        });
    },
  },
};
