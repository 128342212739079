<template>
  <section class="ubuSettingView">
    <div class="multibar row-vcentered">
      <h1 class="start title is-3">Organization</h1>

      <div
        v-if="isAdmin"
        class="end buttons is-right"
      >
        <!-- <b-button
          type="is-secondary"
          label="Add Workspace"
          tag="router-link"
          to="/setting/organization/addWorkspace"
        /> -->

        <b-button
          type="is-secondary"
          label="Add Workspace"
          @click="openIntercomAddWorkspace()"
        />

        <!-- <b-button
          type="is-primary"
          label="Add Members"
          tag="router-link"
          to="/setting/organization/addMember"
        /> -->

        <b-button
          type="is-primary"
          label="Add Members"
          @click="openIntercom()"
        />
      </div>
    </div>

    <br>

    <b-tabs
      v-model="activeTab"
      type="is-toggle-rounded"
    >
      <b-tab-item
        label="Members"
      >
        <UbuSearchbar
          v-model="inputSearch"
          placeholder="Search member..."
          @clear="inputSearch = ''"
        />

        <br>

        <UbuOrganizationMembers
          v-if="account"
          :all-channel-group-account="allChannelGroupAccountNewVersion"
          :columns="columns"
          :my-authenticate="myAuthenticate"
          @updateGroup="updateGroupNextGen({...$event, aid: account.aid })"
        />
      </b-tab-item>

      <b-tab-item
        label="Workspaces"
      >
        <UbuOrganizationWorkspace
          v-if="account"
          :all-channel-group-account="accountChannelGroupChannelWorkspace"
          :columns="columnsUbuOrganizationWorkspace"
        />
      </b-tab-item>
    </b-tabs>

    <router-view name="addMember" />

    <router-view name="addWorkspace" />

    <router-view
      :key="$route.params.cgid"
      name="editWorkspace"
    />
  </section>
</template>

<script>
import { debounce } from '@dailyplanet/utils/helpers';
import iconsMixins from '@dailyplanet/cross-addons/crossCommon/$mixins/dataIcons.mixins';
import linkUnlinkChannelChannelGroup from '@dailyplanet/cross-addons/workspace/$mixins/linkUnlinkChannelChannelGroup.mixins';

import { mapActions, mapGetters } from 'vuex';
import { initialFormater } from '@dailyplanet/utils/formate';
import TheSettingOrganizationColumns from '../$mixins/TheSettingOrganizationColumns.columns.mixins';

export default {
  name: 'TheSettingOrganization',
  mixins: [
    TheSettingOrganizationColumns,
    iconsMixins,
    linkUnlinkChannelChannelGroup,
  ],
  data() {
    return {
      accountAid: null,
      activeTab: 0,
      isActive: {
        value: null,
        index: null,
      },
      defaultOpenedDetails: [],
      search: '',
      inputSearch: '',
    };
  },
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheSettingOrganizationSetting/myAuthenticate',
      account: 'TheSettingOrganizationSetting/account',
      currentChannelGroup: 'TheSettingOrganizationSetting/currentChannelGroup',
      userList: 'TheSettingOrganizationSetting/userList',
      accountMemberViewList: 'TheSettingOrganizationSetting/accountMemberViewList',
    }),
    allChannelGroupAccountNewVersion() {
      const accountMemberViewList = Object.values(this.accountMemberViewList);
      if (!accountMemberViewList.length) return [];

      const { uid } = this.myAuthenticate;

      const userMapper = accountMemberViewList.reduce((ac, row) => {
        const {
          activeChannelGroup,
          activeChannel,
          channelGroupList,
          channelList,
          ...member
        } = row;

        const dataToPush = {
          ...member,
          ...member.userDetail,
          fullName: `${member.userDetail.firstName} ${member.userDetail.lastName}`,
          initials: initialFormater({
            firstName: member.userDetail.firstName,
            lastName: member.userDetail.lastName,
          }),
          isMe: member.uid === uid,
          channelGroupList,
          channelList,
          editableItem: channelGroupList.reduce((acc, { cgid, name, picture }) => {
            if (!acc[cgid]) {
              acc[cgid] = {
                channelGroup: {
                  cgid,
                  picture,
                  name,
                  initials: name.charAt(0).toUpperCase(),
                  isActive: activeChannelGroup.includes(cgid),
                },
                channelList: channelList.reduce((channels, channel) => {
                  if (channel.channelGroupCgid === cgid && !channels[channel.cid]) {
                    return {
                      ...channels,
                      [channel.cid]: {
                        ...channel,
                        isActive: activeChannel.includes(channel.cid),
                      },
                    };
                  }

                  return channels;
                }, {}),
              };
              return acc;
            }
            return acc;
          }, {}),
        };

        if (!this.search) {
          ac.push(dataToPush);
          return ac;
        }

        const { firstName, lastName, email } = dataToPush.userDetail;

        const findByFirstName = firstName.toLowerCase().includes(this.search.toLowerCase());
        const findByLastName = lastName.toLowerCase().includes(this.search.toLowerCase());
        const findByEmail = email.toLowerCase().includes(this.search.toLowerCase());

        if (findByFirstName || findByLastName || findByEmail) ac.push(dataToPush);
        return ac;
      }, []);

      return [...userMapper]
        .sort((a, b) => b.isAdmin - a.isAdmin)
        .sort((a, b) => (b.uid === uid) - (a.uid === uid));
    },
    accountChannelGroupChannelWorkspace() {
      if (!this.account) return [];

      return this.account.channelGroupList.map(({
        cgid, name, picture, channelList: _channelList,
      }) => {
        const initials = name.charAt(0).toUpperCase();
        const immutable = true;

        const channelList = _channelList.reduce((acc, {
          cid, name: _name, platformName, channelGroupCgid,
        }) => {
          acc[cid] = {
            cid, name: _name, platformName, channelGroupCgid, immutable,
          };
          return acc;
        }, {});

        return {
          cgid,
          channelGroup: {
            cgid, name, picture, initials, immutable,
          },
          channelList,
        };
      });
    },
    isAdmin() {
      const myAccount = this.allChannelGroupAccountNewVersion
        .find((account) => account.isMe === true);

      if (!myAccount) return false;
      return myAccount.isAdmin;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 500),
  },
  mounted() {
    this.reportAccountMemberView({});
  },
  methods: {
    ...mapActions({
      reportAccountMemberView: 'TheSettingOrganizationSetting/reportAccountMemberView',
    }),
    openIntercom() {
      this.$intercom.showNewMessage('Hi! I would like to add a new member! 🙌');
    },
    openIntercomAddWorkspace() {
      this.$intercom.showNewMessage('Hi! I would like to add a new workspace! 🙌');
    },
  },
};
</script>
