<template>
  <section
    class="ubuSettingView start"
  >
    <div class="multibar">
      <h1 class="start title is-1">Add User</h1>
    </div>
    <DevDetails :cnt="{name: 'getterAccount', obj: getterAccount}" />
    <DevDetails :cnt="{name: 'userList', obj: userList}" />
    <DevDetails :cnt="{name: 'account', obj: account}" />
    <DevDetails :cnt="{name: 'userDetail', obj: userDetail}" />

    <br>

    <form v-if="isFetched">
      <b-steps
        v-model="activeStep"
        :rounded="false"
        :has-navigation="false"
        vertical
        label-position="right"
        size="is-small"
        class="is-flex-wrap-nowrap"
      >
        <b-step-item
          label="Informations"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Add user - Step 1/4</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="stepControl1"
              :prev="false"
            />
          </div>

          <br>

          <div class="multibar">
            <h3 class="start title is-3">User informations</h3>
          </div>

          <br>

          <UbuKaraUserUserDetailForm
            v-model="userDetail"
            :user-role-list="userRoleList"
          />

          <template v-if="userDetail.userRole">
            <template v-if="userDetail.userRole.urid === 'SUPER_ADMIN'">
              <br>

              <UbuHelper
                v
                content-type="warning"
                content="⚠️ You are about to grant all application rights to this new user."
              />
            </template>
            <template v-if="userDetail.userRole.urid === 'UBU_ADMIN'">
              <br>

              <UbuHelper
                v
                content-type="warning"
                content="⚠️ Only Ubu employees can have this user role"
              />
            </template>
          </template>

          <template v-if="userDetail.isUbu">
            <br>

            <UbuHelper
              content-type="warning"
              content="⚠️ You are about to give Ubu rights to this new user"
            />
          </template>
        </b-step-item>
        <b-step-item
          label="Authenticate"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Add user - Step 2/4</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="stepControl2"
            />
          </div>

          <br>

          <div class="multibar">
            <h3 class="start title is-3">User authentication</h3>
          </div>

          <br>

          <UbuKaraUserAuthenticateForm v-model="authenticate" />
        </b-step-item>
        <b-step-item
          label="Link to account"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Add user - Step 3/4</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="stepControl3"
            />
          </div>

          <br>

          <div class="multibar">
            <h3 class="start title is-3">Link user to an account</h3>
          </div>

          <template v-if="warning">
            <br>

            <UbuHelper
              content-type="warning"
              :content="warning"
            />
          </template>

          <br>

          <UbuKaraSearchAccountForm
            :account="account"
            :loading="searchLoading"
            :channel-groups="getterAccount"
            :link="link"
            :role="role"
            :default-channel-group-selected="defaultChannelGroupSelected"
            :cgids="cgids"
            @update:defaultChannelGroupSelected="defaultChannelGroupSelected = $event"
            @update:account="account = $event"
            @update:role="role = $event"
            @updateGroup="updateGroup($event)"
          />
        </b-step-item>

        <b-step-item
          label="Summary"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Add user - Step 4/4</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="true"
              :next="false"
            />
          </div>

          <br>

          <div class="multibar">
            <h3 class="start title is-3">Confirm user</h3>
          </div>

          <br>

          <UbuSummaryAddUser
            :user-detail="userDetail"
            :authenticate="authenticate"
            :link="link"
            :role="role"
            :cgids="cgids"
            :cids="cids"
            :account="account"
            :default-channel-group-selected="defaultChannelGroupSelected"
            @createUser="formatPayload()"
          />
        </b-step-item>

        <b-step-item
          type="is-success"
        >
          <section>
            <div class="multibar">
              <h2 class="start title is-1">The user was created successfuly ! 🥳</h2>
            </div>

            <br>

            <div class="multibar">
              <div class="end buttons">
                <b-button
                  label="Create an other user"
                  type="is-ghost"
                  @click="initProcessCreate()"
                />
                <b-button
                  label="Open details"
                  type="is-primary"
                  @click="localSelected = newUser"
                />
              </div>
            </div>
          </section>
        </b-step-item>
      </b-steps>
    </form>
  </section>
</template>

<script>
/* eslint-disable max-len */
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheUsersAddUser',
  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isFetched: true,
      activeStep: 0,
      searchLoading: false,
      channelGroups: null,
      cgids: [],
      cids: [],
      account: {
        preset: [],
        selected: '',
        value: '',
      },
      userDetail: {
        firstName: null,
        lastName: null,
        phone: null,
        picture: null,
        userRole: null,
        isUbu: false,
      },
      authenticate: {
        passName: null,
        passPhrase: uuidv4(),
        method: 'UBU',
        createdOn: null,
        updatedOn: null,
        allowedOn: null,
        revokedOn: null,
        oneTime: true,
      },
      role: '',
      link: {
        aid: null,
        channelGroups: [],
      },
      defaultChannelGroupSelected: 0,
      warning: null,
    };
  },
  computed: {
    ...mapGetters({
      accountList: 'TheUsers/accountList',
      getterAccount: 'TheUsers/account',
      userRoleList: 'TheUsers/userRoleList',
      userList: 'TheUsers/userList',
    }),
    localSelected: {
      get() { return this.selected; },
      set(newValue) { this.$emit('update:selected', newValue); },
    },
    stepControl1() {
      if (!this.userDetail.firstName
        || !this.userDetail.lastName
        || !this.userDetail.userRole) return false;
      return true;
    },
    stepControl2() {
      if (!this.authenticate.passName || !this.authenticate.passPhrase) return false;

      const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regexEmail.test(this.authenticate.passName)) return false;

      return true;
    },
    stepControl3() {
      if (!this.link.aid || !this.link.channelGroups.length) return false;
      return true;
    },
    stepControl4() {
      if (!this.defaultChannelGroupSelected) return false;
      return true;
    },
    newUser() {
      if (!Object.values(this.userList).length) return null;
      const userPopped = Object.values(this.userList).pop();

      return userPopped;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'account.value': debounce(function (newVal) {
      this.account.preset = [];
      this.cgids = [];
      if (!newVal || !newVal.length || newVal.length < 3) {
        this.searchLoading = false;
        return;
      }
      if (!newVal.match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/)) {
        this.searchLoading = false;
        return;
      }

      this.searchLoading = true;
      this.reportAccountList({ httpQuery: { accountName: newVal.toLowerCase() } })
        .then(() => {
          if (Object.values(this.accountList).length) {
            const [account] = Object.values(this.accountList);
            this.link.aid = account ? account.aid : null;
            this.account.preset = Object.values(this.accountList);
            this.searchLoading = false;
          }
        });
    }, 500),
    // eslint-disable-next-line func-names
    'account.selected': debounce(function (newVal) {
      this.channelGroups = null;
      if (!newVal || typeof newVal !== 'object') return;

      // this.searchLoading = true;
      this.getAccount({
        payload: { aid: newVal.aid },
        httpQuery: { channelAndChannelGroupList: true },
      });
    }, 500),
    cgids(newVal) {
      const [channelGroup] = newVal;
      this.defaultChannelGroupSelected = channelGroup ? channelGroup.value : 0;
    },
    // eslint-disable-next-line func-names
    'userDetail.userRole': function (userRole) {
      if (userRole) {
        const { urid } = userRole;
        this.warning = null;
        const userRolesChangeToMember = ['SUPER_ADMIN', 'UBU_ADMIN', 'ACCOUNT_ADMIN', 'ACCOUNT_MANAGER'];

        if (userRolesChangeToMember.includes(urid)) this.role = 'admin';
        else this.role = 'member';
      }
    },
    // eslint-disable-next-line func-names
    role(newVal) {
      this.warning = null;
      const userRolesForAdmin = ['SUPER_ADMIN', 'UBU_ADMIN', 'ACCOUNT_ADMIN', 'ACCOUNT_MANAGER'];
      const userRolesForMember = ['ACCOUNT_USER', 'ACCOUNT_CONTRIBUTOR', 'ACCOUNT_CREATOR', 'CUSTOM'];

      if (!this.userDetail.userRole) return newVal;

      const { urid } = this.userDetail.userRole;

      if (newVal === 'admin' && !userRolesForAdmin.includes(urid)) {
        this.warning = `The rights you want to gave (${urid}) are less than the status of account (${newVal}) \n you want to give it. \n Please leave the default status (member)`;
      }

      if (newVal === 'member' && !userRolesForMember.includes(urid)) {
        this.warning = `The rights you want to gave (${urid}) are higher than the status of account (${newVal}) \n you want to give it. \n Please leave the default status (admin)`;
      }

      return newVal;
    },
  },
  mounted() {
    this.$emit('reset');
    return Promise.all([
      this.reportUserRole({}),
    ]);
  },
  methods: {
    ...mapActions({
      createUser: 'TheUsers/createUser',
      reportAccountList: 'TheUsers/reportAccountList',
      getAccount: 'TheUsers/getAccount',
      reportUserRole: 'TheUsers/reportUserRole',
      up_addUserInDetailledUserList: 'TheUsers/up_addUserInDetailledUserList',
    }),
    updateGroup(event) {
      const {
        channelGroup: { isActive, cgid, name },
        channelList,
      } = event;

      if (isActive && !this.cgids.find(({ value: _cgid }) => _cgid === cgid)) this.cgids.push({ value: cgid, name });
      if (!isActive && this.cgids.find(({ value: _cgid }) => _cgid === cgid)) {
        const index = this.cgids.findIndex(({ value: _cgid }) => _cgid === cgid);
        this.cgids.splice(index, 1);
      }

      Object.values(channelList).forEach(({
        isActive: isActiveCid, cid: _cid, name: nameCid, platformName, channelGroupCgid, contactPlatformCpid,
      }) => {
        if (isActiveCid && !this.cids.find(({ value: cid }) => cid === _cid)) {
          this.cids.push({
            value: _cid, name: nameCid, platformName, channelGroupCgid, contactPlatformCpid,
          });
        }
        if (!isActiveCid && this.cids.find(({ value: cid }) => cid === _cid)) {
          const index = this.cids.findIndex(({ value: cid }) => cid === _cid);
          this.cids.splice(index, 1);
        }
      });

      this.link.channelGroups = this.cgids.map(({ value: _cgid }) => ({
        cgid,
        channels: this.cids
          ? this.cids.reduce((acc, { value: cid, channelGroupCgid }) => {
            if (_cgid === channelGroupCgid) acc.push({ cid });
            return acc;
          }, [])
          : null,
      }));
    },
    formatPayload() {
      const {
        userRole: { urid: userRoleUrid }, firstName, lastName, isUbu, ...userDetail
      } = this.userDetail;

      const payload = {
        extra: null,
        isUbu,
        lockedOn: null,
        userDetail: {
          firstName: firstName.capitalize(),
          lastName: lastName.capitalize(),
          ...userDetail,
          email: this.authenticate.passName,
        },
        setting: {
          theme: 'AUTO',
          defaultChannelGroupCgid: this.defaultChannelGroupSelected,
          defaultAccountAid: this.account.selected.aid,
        },
        authenticate: [
          {
            ...this.authenticate,
            method: 'UBU',
            createdOn: moment().format(),
            allowedOn: moment().format(),
            updatedOn: moment().format(),
            oneTime: true,
            revokedOn: null,
          },
        ],
        hasRole: [{ userRoleUrid }],
        isAdminOnAccount: this.role === 'admin' ? this.account.selected.aid : null,
      };

      if (this.account.selected) payload.link = { ...this.link };

      this.createUser({ payload, httpQuery: { isStub: true } })
        .then(({ response: user }) => {
          if (user) {
            const {
              authenticate: authenticates, userDetail: details, hasRole: [role], ...rest
            } = user;
            const newUser = {
              authenticates, ...details, ...role, ...rest,
            };
            this.up_addUserInDetailledUserList(newUser);
            this.activeStep += 1;
          }
        });
    },
    initProcessCreate() {
      this.activeStep = 0;
      this.searchLoading = false;
      this.channelGroups = null;
      this.cgids = [];
      this.cids = [];
      this.account = { preset: [], selected: '', value: '' };
      this.userDetail = {
        firstName: null, lastName: null, phone: null, picture: null, userRole: null, isUbu: false,
      };
      this.authenticate = {
        passName: null, passPhrase: uuidv4(), method: 'UBU', createdOn: null, updatedOn: null, allowedOn: null, revokedOn: null, oneTime: true,
      };
      this.role = 'member';
      this.link = { aid: null, channelGroups: [] };
      this.defaultChannelGroupSelected = 0;
    },
  },
};
</script>
