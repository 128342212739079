<template>
  <UbuDropdown :expanded="true">
    <template #button>
      <b-button
        :label="options[selectedOption].label"
        type="is-secondary"
        icon-pack="ubu"
        expanded
        icon-right="ubu-arrow-down"
      />
    </template>

    <template #content>
      <b-dropdown-item
        v-for="(option) in options"
        :key="option.value"
        @click="$emit('select', option.value)"
      >
        {{ option.label }}
      </b-dropdown-item>

      <b-dropdown-item @click="$emit('select', defaultOption)">
        <span class="subtext has-text-primary">Reset default</span>
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
export default {
  name: 'UbuDropdownSortOptions',
  props: {
    options: {
      type: Object,
      required: true,
    },
    selectedOption: {
      type: String,
      default: '',
    },
    defaultOption: {
      type: String,
      default: 'newest',
    },
  },
};
</script>
