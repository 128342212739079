<template>
  <TheMemberGroupPopover v-model="cpo.tribeMember" />
</template>

<script>
export default {
  name: 'UbuTableMemberGroupPopover',
  props: {
    cpo: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
