<template>
  <div class="UbuInfluenceMentionModalMedia card-image">
    <template v-if="!currentMedia.carousel">
      <UbuMediaImage
        v-if="currentMedia.mainMedia.type === 'IMAGE'"
        :key="currentMedia.mid"
        :url="currentMedia.mainMedia.url"
        :src-fallback="srcFallback"
      />
      <UbuMediaVideo
        v-if="currentMedia.mainMedia.type === 'VIDEO'"
        :key="currentMedia.mid"
        :url="currentMedia.mainMedia.url"
        :src-fallback="srcFallback"
      />
      <UbuMediaAudio
        v-if="currentMedia.mainMedia.type === 'AUDIO'"
        :key="currentMedia.mid"
        :url="currentMedia.mainMedia.url"
      />
    </template>
    <template v-else>
      <UbuMediaCarousel
        :key="currentMedia.mid"
        :carousel="currentMedia.carousel"
        @changeCarouselIndex="$emit('changeCarouselIndex', $event)"
      />
    </template>
  </div>
</template>

<script>
const srcFallback = require('@dailyplanet/assets/images/image-placeholder.png');

export default {
  name: 'UbuMentionModalMedia',
  props: {
    currentMedia: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      srcFallback,
    };
  },
};
</script>
