<template>
  <div
    :class="['likeButton', {
      'hasLike': messageLikedByUser
    }]"
    @click="like(message)"
  >
    <span
      v-if="messageLikedByUser"
      class="is-clickable"
    >
      <b-icon
        pack="mdi"
        icon="heart"
        class="is-clickable likeIcon is-liked"
      />
    </span>
    <b-icon
      v-else
      pack="mdi"
      icon="heart-outline"
      class="is-clickable likeIcon"
    />
  </div>
</template>

<script>
export default {
  name: 'UbuLikeButton',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    messageLikedByUser() {
      const {
        reactions, contactPlatformCpid, isMe,
      } = this.message;

      if (!reactions || !reactions.length) return false;
      // Si la réaction est un ❤️
      // Si le message vient d'Ubu, et la réaction ❤️ aussi (ids identiques), true
      // Si le message vient d'Insta, et la réaction ❤️ vient d'Ubu (ids différents entre msg et réac), true
      return reactions.some((reaction) => reaction.emoji === '❤️'
      && (
        (String(contactPlatformCpid) === String(reaction.reactingPersonContactPlatformCpid) && isMe)
      || (String(contactPlatformCpid) !== String(reaction.reactingPersonContactPlatformCpid) && !isMe)
      ));
    },
  },
  methods: {
    like(message) {
      const payload = {
        cid: this.$route.params.channelCid,
        tid: message.threadTid,
        mid: message.originalId,
      };
      this.$emit('likeMessage', { payload });
    },
  },
};
</script>
