<template>
  <div
    v-if="loading"
    class="ubuAnalyticCard"
    :style="`min-width: ${width};`"
  >
    <b-skeleton
      circle
      width="34px"
      height="34px"
      position="is-centered"
    />
    <b-skeleton
      width="25%"
      height="25px"
      position="is-centered"
    />
    <b-skeleton
      width="60%"
      height="20px"
      position="is-centered"
    />
    <b-skeleton
      width="25%"
      height="20px"
      position="is-centered"
    />
  </div>
  <div
    v-else
    class="ubuAnalyticCard"
    :style="`min-width: ${width};`"
  >
    <div :class="`ubuAnalyticCard_icon bg-color-${color}`">
      <b-icon
        pack="ubu"
        :icon="icon"
        size="is-medium"
      />
    </div>

    <b-tooltip
      :label="tooltipValue"
      position="is-bottom"
    >
      <span v-if="!currencyBefore" class="title is-3">
        {{ formatedValue }}{{ formatedValue !== '-' ? symbol : '' }}
      </span>
      <span v-if="currencyBefore" class="title is-3">
        {{ formatedValue !== '-' ? symbol : '' }}{{ formatedValue }}
      </span>
    </b-tooltip>

    <span class="subtext is-3 has-text-secondary">
      {{ name }}
    </span>

    <b-tooltip
      v-if="growthPercent"
      multilined
      :label="`Last period value: ${ (lastPeriodvalue || 0).toLocaleString('fr') }${symbol}`"
      position="is-bottom"
    >
      <div :class="['evolution multibar row-vcentered', { isNegative: growthPercent < 0 }]">
        <b-icon
          pack="mdi"
          :icon="`trending-${ growthPercent > 0 ? 'up' : 'down'}`"
        />
        <span class="subtext ml-1">
          {{ formatedGrowthPercent }}%
        </span>
      </div>
    </b-tooltip>

    <b-tooltip
      v-if="comparedPercentValue"
      multilined
      :label="`${ comparedPercentValue }% ${comparedValueTooltip}`"
      position="is-bottom"
    >
      <span class="subtext ml-1">
        {{ comparedPercentValue }}%
      </span>
    </b-tooltip>
  </div>
</template>

<script>
import { shortenNumber } from '@dailyplanet/utils/formate';

export default {
  name: 'UbuAnalyticCard',
  props: {
    color: {
      type: Number,
      default: 1,
    },
    icon: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'auto',
    },
    currencyBefore: {
      type: Boolean,
      default: false,
    },
    symbol: {
      type: String,
      default: '',
    },
    growthPercent: {
      type: Number,
      default: null,
    },
    lastPeriodvalue: {
      type: Number,
      default: 0,
    },
    comparedPercentValue: {
      type: Number,
      default: null,
    },
    comparedValueTooltip: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formatedValue() {
      if (this.value === 'N/A') return this.value;
      if (typeof this.value === 'string') return this.value;
      const value = Number(this.value);
      if (!value) return '-';
      return shortenNumber(value);
    },
    formatedGrowthPercent() {
      return Math.round(this.growthPercent * 100) / 100;
    },
    tooltipValue() {
      const value = Number(this.value);
      if (!value) return '-';

      if (this.currencyBefore) { return `${this.symbol}${value.toLocaleString('en-US')}`; }

      return `${value.toLocaleString('en-US')}${this.symbol}`;
    },
  },
};
</script>
