import { dispatchAndForward, genericErrorHandler, genericThenHandler } from '../../$utils/helpers';

const moment = require('moment');

export default {
  namespaced: true,
  getters: {
    privateNoteList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiConversation/privateNote/getterPrivateNoteList': privateNoteList } = rootGetters;

      return privateNoteList;
    },
  },
  actions: {
    createPrivateNote({ dispatch, rootGetters }, { payload, mutations }) {
      console.log('🚀 ~ file: privateNote.js ~ line 16 ~ createPrivateNote ~ mutations', mutations);
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/privateNote/create',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
            createdOn: moment().format(),
            updatedOn: moment().format(),
            deletedOn: null,
            ...payload,
          },
        },
        textNotifier: 'create private note',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to create private note.', indefinite: true }));
    },

    updatePrivateNote({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/privateNote/update',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'update private note',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to update private note.', indefinite: true }));
    },

    deletePrivateNote({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/privateNote/delete',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'delete private note',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to delete private note.', indefinite: true }));
    },
  },
};
