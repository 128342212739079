import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    campaignDetailed: null,
  },
  mutations: {
    SET_CAMPAIGN_DETAILED(state, newData) { state.campaignDetailed = newData; },
    RESET_STORE(state) {
      state.campaignDetailed = null;
    },
  },
  getters: {
    getterCampaignDetailled: (state) => state.campaignDetailed,
  },
  actions: {
    report({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'REPORT_DETAILLED_CAMPAIGN.REPORT')) return undefined;

      return clark.api.campaign.reportDetailledCampaign.report({ payload, httpQuery: {} })
        .then((campaignDetailed) => {
          const { 'ContactGroup/getterContactGroupList': contactGroupList } = rootGetters;

          const campaignDetailedMapped = campaignDetailed.map((details) => {
            const {
              contactPlatform: {
                cpid, platformName, username, followerCount,
              },
              contact: { cid },
              labels,
            } = details;

            return {
              ...details,
              cid,
              cpid,
              platformName,
              username,
              followerCount,
              labels: labels.map((id) => contactGroupList[id]),
            };
          });

          commit('SET_CAMPAIGN_DETAILED', campaignDetailedMapped);
        });
    },
    up_activityFromCampaignDetailled({ commit, getters }, updatedActivity) {
      const currentCampaignDetailed = getters.getterCampaignDetailled;

      if (!currentCampaignDetailed) return;

      const updatedCampDetailed = currentCampaignDetailed.reduce((acc, row) => {
        const { activity } = row;

        if (String(activity.aid) === String(updatedActivity.aid)) {
          acc.push({ ...row, activity: { ...activity, ...updatedActivity } });
          return acc;
        }
        acc.push(row);
        return acc;
      }, []);

      commit('SET_CAMPAIGN_DETAILED', updatedCampDetailed);
    },
    up_activititesFromCampaignDetailled({ commit, getters }, { activities }) {
      const currentCampaignDetailed = getters.getterCampaignDetailled;

      if (!currentCampaignDetailed) return;

      const activitiesByAid = activities.arrayMapper('aid');

      const updatedCampDetailed = currentCampaignDetailed.reduce((acc, row) => {
        const { activity: act } = row;

        if (activitiesByAid[act.aid]) {
          acc.push({ ...row, activity: activitiesByAid[act.aid] });
          return acc;
        }
        acc.push(row);
        return acc;
      }, []);

      commit('SET_CAMPAIGN_DETAILED', updatedCampDetailed);
    },
    up_addLabelCampaignDetailled({ commit, getters, rootGetters }, { cids, cgid }) {
      const { 'ContactGroup/getterContactGroupList': contactGroupList } = rootGetters;
      const currentCampaignDetailed = getters.getterCampaignDetailled;

      if (!currentCampaignDetailed) return;

      const updatedCampDetailed = currentCampaignDetailed.reduce((acc, row) => {
        const { cid, labels } = row;

        if (cids.includes(cid)) {
          const labelsToKeep = labels ? labels.filter((label) => label.cgid !== cgid) : [];
          acc.push({ ...row, labels: [...labelsToKeep, contactGroupList[cgid]] });
          return acc;
        }
        acc.push(row);
        return acc;
      }, []);

      commit('SET_CAMPAIGN_DETAILED', updatedCampDetailed);
    },
  },
};
