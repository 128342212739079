import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheNotifierBoard from './TheNotifierBoard';
import TheNotifierLast from './TheNotifierLast';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheNotifierBoard);
    registerComponent(Vue, TheNotifierLast);
  },
};

use(Plugin);

export default Plugin;

export {
  TheNotifierBoard,
  TheNotifierLast,
};
