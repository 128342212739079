<template>
  <aside
    v-if="contactPlatformOverview"
    :class="['ubuContactPlatformPanel ubuPanel-xl scrollable-y', {
      'is-large': isLarge
    }]"
  >
    <UbuContactPlatformPanelHeader
      v-model="contactPlatformOverview"
      @close="$emit('close')"
    />
    <b-tabs
      id="TheUbuContactPlatformPanelTabs"
      v-model="localActiveTab"
      :animated="false"
      type="is-toggle-rounded"
    >
      <b-tab-item
        v-for="item in tabs"
        :key="item.tab"
        :value="item.tab"
      >
        <template #header>
          <b-tooltip position="is-bottom">
            <template v-slot:content><p class="">{{ item.tooltipContent }}</p></template>
            <b-icon
              pack="ubu"
              :icon="item.icon"
            />
          </b-tooltip>
        </template>

        <template>
          <TheContactPlatformPanelOverviewTab
            v-if="activeTab === 'root' && item.tab === 'root'"
            @changeTab="localActiveTab = $event"
          />
          <TheContactPlatformPanelHistoryTab
            v-if="activeTab === 'root.history' && item.tab === 'root.history'"
          />
          <TheContactPlatformPanelMentionTab
            v-if="activeTab === 'root.mentions' && item.tab === 'root.mentions'"
            :is-large="isLarge"
          />
          <TheContactPlatformPanelAffiliationTab
            v-if="activeTab === 'root.affiliation' && item.tab === 'root.affiliation'"
          />
          <TheContactPlatformPanelInfluenceTab
            v-if="activeTab === 'root.influence' && item.tab === 'root.influence'"
          />
        </template>
      </b-tab-item>
    </b-tabs>
  </aside>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheContactPlatformPanel',
  model: {
    prop: 'cpid',
  },
  props: {
    cpid: {
      type: [Number, String],
      required: true,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 'root',
      tabs: [
        {
          tab: 'root',
          icon: 'ubu-overview',
          tooltipContent: 'Overview',
        },
        {
          tab: 'root.history',
          icon: 'ubu-history',
          tooltipContent: 'History',
        },
        {
          tab: 'root.mentions',
          icon: 'ubu-mentions',
          tooltipContent: 'Mentions',
        },
        {
          tab: 'root.affiliation',
          icon: 'ubu-discount',
          tooltipContent: 'Affiliation',
        },
        {
          tab: 'root.influence',
          icon: 'ubu-influence',
          tooltipContent: 'Campaigns',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      contactPlatformOverview: 'TheContactPlatformPanel/contactPlatformOverview',
    }),
    localActiveTab: {
      get() { return this.activeTab; },
      set(newValue) {
        if (newValue === 'root.history') {
          console.log('🚀 ~ file: TheContactPlatformPanel.vue ~ line 89 ~ set ~ newValue', newValue);
          // return this._reportContactNotesForPanel({
          //   payload: { cid: this.contactPlatformOverview.contactPlatform.contactCid },
          //   httpQuery: {
          //     pagin: [0, 50],
          //   },
          // })
          //   .then(() => {
          this.activeTab = newValue;
          // });
        }

        if (newValue === 'root.mentions') {
          return Promise.resolve()
            // .then(() => this._resetContactPlatformMentionList({
            //   payload: { cpids: [this.contactPlatformOverview.contactPlatform.cpid] },
            // }))
            // .then(() => this._reportContactPlatformMentionList({
            //   payload: {
            //     cpids: [this.contactPlatformOverview.contactPlatform.cpid],
            //   },
            //   httpQuery: {
            //     pagin: [0, 50],
            //     filters: JSON.stringify([{ key: 'mention.mentionType', values: ['STORY'] }]),
            //   },
            // }))
            .then(() => {
              this.activeTab = newValue;
            });
        }
        if (newValue === 'root.influence') {
          return this._reportDetailledActivityContactPanel({ payload: { cpid: this.contactPlatformOverview.cpid } })
            .then(() => { this.activeTab = newValue; });
        }
        if (newValue === 'root.affiliation') {
          return this._reportContactPlatformAffiliation({
            payload: { cpid: this.cpid },
            httpQuery: {
              sorting: 'affiliationCode.createdOn,desc',
            },
          })
            .then(() => { this.activeTab = newValue; });
        }

        this.activeTab = newValue;
        return newValue;
      },
    },
  },
  watch: {
    cpid(newVal, oldVal) {
      if (String(newVal) !== String(oldVal)) {
        this.activeTab = 'root';
      }
      return undefined;
    },
  },
  methods: {
    ...mapActions({
      _reportDetailledActivityContactPanel: 'TheContactPlatformPanel/reportDetailledActivityContactPanel',
      _getNoteList: 'TheContactPlatformPanel/getNoteList',
      _reportContactPlatformAffiliation: 'TheContactPlatformPanel/reportContactPlatformAffiliation',
      _reportContactPlatformMentionList: 'TheContactPlatformPanel/reportContactPlatformMentionList',
      _resetContactPlatformMentionList: 'TheContactPlatformPanel/resetContactPlatformMentionList',
      _reportContactNotesForPanel: 'TheContactPlatformPanel/reportContactNotesForPanel',
    }),
  },
};
</script>
