/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  getters: {

  },
  actions: {
    post(_, { payload, httpQuery }) {
      return clark.api.account.link.channel.post({ payload, httpQuery });
    },
  },
  modules: {},
};
