/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '@dailyplanet/providers/engine';

const target = 'clark';

const contactPrivateMetadata = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/contactPrivateMetadata`, method: 'REPORT', payload, httpQuery, target }),
  get: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/contactPrivateMetadata/${payload.contactCid}`, method: 'GET', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/contactPrivateMetadata/${payload.contactCid}`, method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/contactPrivateMetadata/${payload.contactCid}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/contactPrivateMetadata/${payload.contactCid}`, method: 'DELETE', payload, httpQuery, target }),
};

export default contactPrivateMetadata;
