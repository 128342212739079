<template>
  <article class="inboxMainPanel">
    <template v-if="!isBulkOpen">
      <TheThreadPanel
        v-if="contactPlatformOverview && contactPlatformOverview.cpid"
        @openTemplatePanel="isOpenTemplatePanel = true"
      />

      <TheContactPlatformPanel
        v-if="contactPlatformOverview && contactPlatformOverview.cpid"
        v-model="contactPlatformOverview.cpid"
        class="is-inbox"
      />

      <TheSettingAddTemplate
        v-if="isOpenTemplatePanel"
        closing-way="emit"
        @close="isOpenTemplatePanel = false"
      />
    </template>

    <TheThreadBulkPanel v-else />
  </article>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheInboxMainPanel',
  data() {
    return {
      selectedContactPlatform: null,
      isLoading: false,
      isOpenTemplatePanel: false,
    };
  },
  computed: {
    ...mapGetters({
      contactPlatformOverview: 'TheContactPlatformPanel/contactPlatformOverview',
      threadTidsSelectedInBulk: 'TheInbox/threadTidsSelectedInBulk',
    }),
    isBulkOpen() {
      return this.threadTidsSelectedInBulk.length > 0;
    },
  },
  beforeRouteUpdate(to, from, next) {
    const { channelCid: cid, tid } = to.params;
    const { channelCid: fromCid, tid: fromTid } = from.params;

    if (cid && tid && (cid !== fromCid || tid !== fromTid)) this.fetchThreadDetails({ cid, tid });

    next();
  },
  mounted() {
    const { channelCid: cid, tid } = this.$route.params;
    if (cid && tid) return this.fetchThreadDetails({ cid, tid });
    return undefined;
  },
  methods: {
    ...mapActions({
      up_currentThreadAndHeld: 'TheInbox/up_currentThreadAndHeld',
    }),
    fetchThreadDetails({ tid }) {
      this.isLoading = true;
      return Promise.resolve()
        .then(() => this.up_currentThreadAndHeld(tid))
        .then(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
