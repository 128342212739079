/* eslint-disable max-len */
import { mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  mixins: [FilterEngineMixin],
  computed: {
    ...mapGetters({
      selectedCps: 'TheAddToCrmList/selectedCps',
      newBulkAction: 'TheAddToCrmList/newBulkAction',
    }),
    _filterCollection() {
      return {
        alreadyInThisCrmList(raw) {
          return (m) => (m.crmLists.find(({ clid }) => String(clid) === String(raw)) ? m : undefined);
        },
        notInThisCrmList(raw) {
          return (m) => (!m.crmLists.find(({ clid }) => String(clid) === String(raw)) ? m : undefined);
        },
        searchByUsernameNested(rawSearch) {
          const search = rawSearch.toLowerCase();

          return (m) => (m.contactPlatform.username.toLowerCase().includes(search) ? m : undefined);
        },
      };
    },
    crmListFiltered() {
      if (!this.crmListSorted.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByName = this.search;

      return this.filterEngine(this.crmListSorted, filtersToApply);
    },
    alreadyInThisCrmList() {
      if (!this.selectedCps || !this.selectedCps.length) return [];

      const filtersToApply = {
        alreadyInThisCrmList: this.newBulkAction.clid,
      };

      if (this.search) filtersToApply.searchByUsernameNested = this.search;

      return this.filterEngine(this.selectedCps, filtersToApply);
    },
    cpToAddToCrmList() {
      if (!this.selectedCps || !this.selectedCps.length) return [];

      const filtersToApply = {
        notInThisCrmList: this.newBulkAction.clid,
      };

      if (this.search) filtersToApply.searchByUsernameNested = this.search;

      return this.filterEngine(this.selectedCps, filtersToApply);
    },
  },
};
