/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const channelSms = {
  post: ({ payload, httpQuery }) => builder({ url: '/api/manageChannelSms', method: 'POST', payload, httpQuery, target }),
};

export default channelSms;
