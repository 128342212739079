import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    tryFetchReportByUsername(_, { payload, httpQuery = {} }) {
      return clark.api.apiDownload.iqdataReport.tryFetchReportByUsername({ payload, httpQuery })
        .then(({ __response__ }) => __response__);
    },

    createReport(_, { payload, httpQuery = {} }) {
      return clark.api.apiDownload.iqdataReport.createReport({ payload, httpQuery })
        .then(({ __response__ }) => __response__);
    },
  },
};
