import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheActivityDropdown from './smarts/TheActivityDropdown';
import TheAppointDropdown from './smarts/TheAppointDropdown';

import ThePastActivitiesDropdown from './smarts/ThePastActivitiesDropdown';
import TheTemplateDropdown from './smarts/TheTemplateDropdown';
import TheTagPopover from './smarts/TheTagPopover';
import TheLabelPopover from './smarts/TheLabelPopover';
import TheTribePopover from './smarts/TheTribePopover';
import TheCrmListFilterDropdown from './smarts/TheCrmListFilterDropdown';
import TheMemberGroupPopover from './smarts/TheMemberGroupPopover';
import TheActivityStatusPopover from './smarts/TheActivityStatusPopover';
import TheShopDropdown from './smarts/TheShopDropdown';
import TheActiveCampaignDropdown from './smarts/TheActiveCampaignDropdown';
import TheDiscountCodeDropdown from './smarts/TheDiscountCodeDropdown';
import TheSearchContactPlatformDropdown from './smarts/TheSearchContactPlatformDropdown';
import TheAffiliationCodeFolderDropdown from './smarts/TheAffiliationCodeFolderDropdown';
import TheSortingDropdown from './smarts/TheSortingDropdown';

import TheMoreButtonPopover from './smarts/TheMoreButtonPopover';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheActivityDropdown);
    registerComponent(Vue, TheAppointDropdown);

    registerComponent(Vue, ThePastActivitiesDropdown);
    registerComponent(Vue, TheTemplateDropdown);
    registerComponent(Vue, TheTagPopover);
    registerComponent(Vue, TheLabelPopover);
    registerComponent(Vue, TheTribePopover);
    registerComponent(Vue, TheCrmListFilterDropdown);
    registerComponent(Vue, TheMemberGroupPopover);
    registerComponent(Vue, TheActivityStatusPopover);
    registerComponent(Vue, TheMoreButtonPopover);
    registerComponent(Vue, TheShopDropdown);
    registerComponent(Vue, TheActiveCampaignDropdown);
    registerComponent(Vue, TheDiscountCodeDropdown);
    registerComponent(Vue, TheSearchContactPlatformDropdown);
    registerComponent(Vue, TheAffiliationCodeFolderDropdown);
    registerComponent(Vue, TheSortingDropdown);
  },
};

use(Plugin);

export default Plugin;

export {
  TheActivityDropdown,
  TheAppointDropdown,

  ThePastActivitiesDropdown,
  TheTemplateDropdown,
  TheTagPopover,
  TheLabelPopover,
  TheTribePopover,
  TheCrmListFilterDropdown,
  TheMemberGroupPopover,
  TheActivityStatusPopover,
  TheMoreButtonPopover,
  TheShopDropdown,
  TheActiveCampaignDropdown,
  TheDiscountCodeDropdown,
  TheSearchContactPlatformDropdown,
  TheAffiliationCodeFolderDropdown,
  TheSortingDropdown,
};
