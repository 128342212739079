const use = (plugin) => {
  if (typeof window !== 'undefined' && window.Vue) window.Vue.use(plugin);
};

const registerComponent = (Vue, component) => {
  Vue.component(component.name, component);
};

export {
  use,
  registerComponent,
};
