/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {},
  getters: {
    ...getters.shop,
    ...getters.affiliationCodeFolder,
  },
  actions: {
    ...actions.discountCode,
    ...actions.affiliationCode,
  },
};
