/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const threadAppoint = {
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/threadAppoint/${payload.tid}`, method: 'POST', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/threadAppoint/${payload.tid}`, method: 'DELETE', payload, httpQuery, target }),
};

export default threadAppoint;
