<template>
  <div
    id="TheSuggestions"
    class="card-content"
  >
    <UbuSuggestionsQueryForm
      :is-loading="isLoading"
      :scraping-zone-list="Object.keys(scrapingZoneList)"
      @fetchContacts="fetchContacts($event)"
    />

    <br>

    <div v-show="settingVisible">
      <UbuSuggestionsFiltersForm
        :results-count="filteredLeadList.length"
        :date-filters.sync="dateFilters"
        :numbers-filters.sync="numbersFilters"
        :pagin.sync="pagin"
        :columns.sync="columns"
        :zone-filter.sync="zoneFilter"
        :language-filter.sync="languageFilter"
        :scraping-zone-filter.sync="scrapingZoneFilter"
        :scraping-zone-list="Object.keys(scrapingZoneList)"
      />
    </div>

    <br>

    <UbuSalesContactTable
      :sales-contact-list="filteredLeadList"
      :scraping-zone-list="Object.keys(scrapingZoneList)"
      :columns="columns"
      @updateContact="updateAndFetchSuggestion($event)"
      @createLead="createLead($event)"
      @updateLead="updateLead($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import GeoMixin from './$mixins/GeoMixin';

export default {
  name: 'TheSuggestions',
  mixins: [GeoMixin],
  props: {
    settingVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isVerified: 'unVerified',
      origin: 'SUGGESTION',
      pagin: 50,
      columns: {
        // picture: { is: 'contact', visible: true },
        username: { is: 'contact', visible: true },
        // createdOn: { is: 'contact', visible: true },
        updatedOn: { is: 'contact', visible: true },
        engagement: { is: 'contact', visible: true },
        followers: { is: 'contact', visible: true },
        growth: { is: 'contact', visible: false },
        zone: { is: 'contact', visible: false },
        language: { is: 'contact', visible: true },
        category: { is: 'contact', visible: true },
        origin: { is: 'contact', visible: true },
        reviewed: { is: 'contact', visible: true },
        'scraping zone': { is: 'contact', visible: true },
      },
      zoneFilter: {
        preset: [
          'Africa',
          'Asia / Australia',
          'Europe',
          'North America',
          'South America',
        ],
        selected: null,
        value: '',
      },
      languageFilter: {
        selected: null,
        value: '',
      },
      scrapingZoneFilter: {
        selected: null,
        value: '',
      },
      dateFilters: {
        contactCreatedOn: {
          date: null,
          when: null,
          label: 'contact created on',
        },
        contactUpdatedOn: {
          date: null,
          when: null,
          label: 'contact updated on',
        },
        leadCreatedOn: {
          date: null,
          when: null,
          label: 'lead created on',
        },
        leadUpdatedOn: {
          date: null,
          when: null,
          label: 'lead updated on',
        },
      },
      numbersFilters: {
        engagement: {
          min: null,
          max: null,
        },
        followers: {
          min: null,
          max: null,
        },
        // growth: {
        //   min: null,
        //   max: null,
        // },
      },
    };
  },
  computed: {
    ...mapGetters({
      salesContactList: 'TheSuggestions/salesContactList',
      scrapingZoneList: 'TheSuggestions/scrapingZoneList',
    }),
    filteredLeadList() {
      let rows = Object.values(this.salesContactList);

      Object.keys(this.dateFilters).forEach((key) => {
        const { date, when } = this.dateFilters[key];

        if (date && when === 'before') {
          rows = rows.filter((r) => this.$moment(r[key]).isSameOrBefore(this.$moment(date)));
        }

        if (date && when === 'after') {
          rows = rows.filter((row) => this.$moment(row[key]).isSameOrAfter(this.$moment(date)));
        }
      });

      Object.keys(this.numbersFilters).forEach((key) => {
        const { min, max } = this.numbersFilters[key];

        if (min) rows = rows.filter((row) => Number(row[key]) > Number(min));

        if (max) rows = rows.filter((row) => Number(row[key]) < Number(max));
      });

      if (this.zoneFilter.selected) {
        const zone = this.zoneFilter.selected;
        rows = rows.filter((row) => row.zone.toLowerCase().includes(zone.toLowerCase()));
      }

      if (this.languageFilter.selected) {
        const { selected } = this.languageFilter;

        rows = rows.filter((row) => row.language && row.language
          .toLowerCase().includes(this.findShortNameFromLongName(selected).toLowerCase()));
      }

      return rows;
    },
  },
  beforeMount() {
    return this.reportScrapingZone();
  },
  methods: {
    ...mapActions({
      reportSalesContact: 'TheSuggestions/reportSalesContact',
      reportScrapingZone: 'TheSuggestions/reportScrapingZone',
      searchForSuggestions: 'TheSuggestions/searchForSuggestions',
      updateContact: 'TheSuggestions/updateContact',
      createLead: 'TheSuggestions/createLead',
      updateLead: 'TheSuggestions/updateLead',
    }),
    fetchContacts(httpQuery) {
      this.isLoading = true;
      this.reportSalesContact(httpQuery)
        .then(() => {
          this.isLoading = false;
        });
    },
    updateAndFetchSuggestion(contact) {
      return this.updateContact(contact)
        .then(() => {
          const { username, isVerified, scrapingZoneSzid } = contact;

          return isVerified
            ? this.searchForSuggestions({ username, szid: scrapingZoneSzid })
            : undefined;
        });
    },
  },
};
</script>

<style lang="scss">
#TheSuggestions {
  height: calc(100vh - (115px));
  max-height: calc(100vh - (115px));
  overflow-y: auto;
}
</style>
