import Errors from '@dailyplanet/utils/Errors';

import { dispatchAndForward, dispatchFailure } from '../../$utils/helpers';

export default {
  namespaced: true,
  getters: {
    protoLists: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiInfluence/protoList/getterProtoList': getterProtoList } = rootGetters;

      return Object.values(getterProtoList);
    },
  },
  actions: {
    reportProtoList({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'ApiInfluence/protoList/report',
        context: { payload, httpQuery },
        textNotifier: 'Report all Proto List',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch((error) => {
          console.log('🚀 ~ file: protoList.js ~ line 30 ~ reportProtoList ~ error', error);

          dispatchFailure(action);
          throw new Errors(error);
        });
    },
    allowProtoList({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiInfluence/protoList/allow',
        context: { payload, httpQuery },
        textNotifier: 'Allow Proto List',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch((error) => {
          console.log('🚀 ~ file: protoList.js ~ line 30 ~ allowProtoList ~ error', error);

          dispatchFailure(action);
          throw new Errors(error);
        });
    },
    rejectProtoList({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiInfluence/protoList/reject',
        context: { payload, httpQuery },
        textNotifier: 'Reject Proto List',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch((error) => {
          console.log('🚀 ~ file: protoList.js ~ line 30 ~ rejectProtoList ~ error', error);

          dispatchFailure(action);
          throw new Errors(error);
        });
    },
  },
};
