import moment from 'moment';
import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    memberGroupList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiTribe/memberGroup/getterMemberGroupList': memberGroupList } = rootGetters;

      return memberGroupList;
    },
  },
  actions: {
    reportMemberGroupList({ dispatch, rootGetters }, { payload = {}, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/memberGroup/report',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Report memberGroup list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report memberGroup list.', indefinite: true }));
    },
    createMemberGroup({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/memberGroup/create',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
            createdOn: moment().format(),
            updatedOn: null,
            isCommon: false,
          },
          httpQuery,
        },
        textNotifier: 'Create memberGroup',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to create memberGroup.', indefinite: true }));
    },

    updateMemberGroup({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/memberGroup/update',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
            updatedOn: moment().format(),
            isCommon: false,
          },
          httpQuery,
        },
        textNotifier: 'Update memberGroup',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to update memberGroup.', indefinite: true }));
    },

    deleteMemberGroup({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/memberGroup/delete',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
          },
          httpQuery,
        },
        textNotifier: 'Delete memberGroup',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to delete memberGroup.', indefinite: true }));
    },
  },
};
