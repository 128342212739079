<template>
  <TheSimplePanel
    closing-way="emit"
    @close="$emit('close')"
  >
    <template #header>
      <h3 class="subtext has-text-secondary">Reward</h3>
      <h1 class="title is-1">Reward details</h1>
    </template>
    <template #content>
      <!-- <pre>{{ reward }}</pre> -->
      <UbuTableContactPlatform class="ml-2" :cpo="reward" />
      <br>
      <table class="table simple">
        <tbody>
          <tr v-for="(tr, i) in trs" :key="i">
            <td v-for="(td, index) in tr" :key="td" :class="index === 0 ? 'label' : ''">
              {{ td }}
            </td>
          </tr>
        </tbody>
      </table>

      <br>

      <b-message v-for="followUp in followUps" :key="followUp.rfuid" type="is-classic">
        <p class="subtext">
          <span>Date:</span>
          <span class="ml-2 has-text-black">{{ followUp.createdOn | humanizeDate }}</span>
        </p>
        <p class="subtext">
          <span>Action come from:</span>
          <span class="ml-2 has-text-black">{{ followUp.actionComeFrom }}</span>
        </p>
        <p class="subtext">
          <span>Event:</span>
          <span class="ml-2 has-text-black">{{ followUp.eventCpt }}</span>
        </p>
        <p v-if="followUp.contentCpt" class="subtext">
          <span>Reminder:</span>
          <span class="ml-2 has-text-black">{{ followUp.contentCpt }}</span>
        </p>
      </b-message>
    </template>
  </TheSimplePanel>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheRewardDetailsPanel',
  props: {
    reward: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isMarkAsPaidLoading: false,
      content: null,
    };
  },
  computed: {
    ...mapGetters({
      userList: 'User/getterUserList',
    }),
    trs() {
      return [
        [
          'Total revenue generated',
          `$${this.reward.totalRevenue / 100}`,
        ],
        [
          'Reward amount',
          `$${this.reward.totalCommissionFee}`,
        ],
        [
          'Approved on',
          this.reward.reviewedOn ? this.$moment(this.reward.reviewedOn).format('YYYY/MM/DD') : '-',
        ],
        [
          'Approved by',
          this.reward.reviewedOn ? `${this.userList[this.reward.reviewerUid]?.userDetail?.firstName} ${this.userList[this.reward.reviewerUid]?.userDetail?.lastName}` : '-',
        ],
        [
          'Reward asked on',
          this.reward.rewardAskedOn ? this.$moment(this.reward.rewardAskedOn).format('YYYY/MM/DD') : '-',
        ],
        [
          'Reward paid on',
          this.reward.paidOn ? this.$moment(this.reward.paidOn).format('YYYY/MM/DD') : '-',
        ],
        [
          'Reward type',
          this.reward.paidType && this.reward.rewardAskedOn ? this.reward.paidType : '-',
        ],
      ];
    },

    followUps() {
      return [...this.reward.followUp].map(this.convertEvent).sort((a, b) => b.createdOn - a.createdOn);
    },
  },
  methods: {
    convertEvent(followUp) {
      const isFromCrew = !!followUp.crewUserCuid;
      const followUpUser = followUp.userUid ? `${this.userList[followUp.userUid]?.userDetail?.firstName} ${this.userList[followUp.userUid]?.userDetail?.lastName}` : 'UbuBot';

      const updatedFollowUp = { ...followUp };

      updatedFollowUp.actionComeFrom = isFromCrew ? 'Crew member' : followUpUser;

      if (isFromCrew) {
        switch (followUp.event) {
          case 'REWARD_ASKED': updatedFollowUp.eventCpt = 'Crew member asked to get reward'; break;

          default:
            break;
        }
      }

      if (!isFromCrew) {
        switch (followUp.event) {
          case 'REWARD_ASKED': updatedFollowUp.eventCpt = 'Has marked reward as asked'; break;
          case 'MANUAL_PAID': updatedFollowUp.eventCpt = 'Setup a manual reward'; break;

          default:
            break;
        }
        switch (followUp.content) {
          case 'MANUAL': updatedFollowUp.contentCpt = ''; break;

          default: updatedFollowUp.contentCpt = updatedFollowUp.content;
            break;
        }
      }

      return updatedFollowUp;
    },
  },
};
</script>
