export default {
  data() {
    return {
      allTopics: {
        billingAttempt: {
          name: 'Billing attempt',
          topics: [
            'subscription_billing_attempts/challenged',
            'subscription_billing_attempts/failure',
            'subscription_billing_attempts/success',
          ],
        },
        bulkOperation: {
          name: 'Bulk operation',
          topics: ['bulk_operations/finish'],
        },
        cart: {
          name: 'Cart',
          topics: [
            'carts/create',
            'carts/update',
          ],
        },
        checkout: {
          name: 'Checkout',
          topics: [
            'checkouts/create',
            'checkouts/update',
          ],
        },
        collection: {
          name: 'Collection',
          topics: [
            'collections/create',
            'collections/update',
          ],
        },
        collectionPublication: {
          name: 'Collection publication',
          topics: [
            'collection_listings/add',
            'collection_listings/update',
          ],
        },
        customer: {
          name: 'Customer',
          topics: [
            'customers/create',
            'customers/disable',
            'customers/enable',
            'customers/update',
            'customers_marketing_consent/update',
          ],
        },
        customerSavedSearch: {
          name: 'Customer saved search',
          topics: [
            'customer_groups/create',
            'customer_groups/update',
          ],
        },
        dispute: {
          name: 'Dispute',
          topics: [
            'disputes/create',
            'disputes/update',
          ],
        },
        domain: {
          name: 'Domain',
          topics: [
            'domains/create',
            'domains/destroy',
            'domains/update',
          ],
        },
        draftOrder: {
          name: 'Draft order',
          topics: [
            'draft_orders/create',
            'draft_orders/update',
          ],
        },
        fulfillment: {
          name: 'Fulfillment',
          topics: [
            'fulfillments/create',
            'fulfillments/update',
          ],
        },
        fulfillmentEvent: {
          name: 'Fulfillment event',
          topics: [
            'fulfillment_events/create',
          ],
        },
        inventoryItem: {
          name: 'Inventory item',
          topics: [
            'inventory_items/create',
            'inventory_items/update',
          ],
        },
        inventoryLevel: {
          name: 'Inventory level',
          topics: [
            'inventory_levels/connect',
            'inventory_levels/disconnect',
            'inventory_levels/update',
          ],
        },
        location: {
          name: 'Location',
          topics: [
            'locations/create',
            'locations/update',
          ],
        },
        mandate: {
          name: 'Mandate',
          topics: [
            'customer_payment_methods/create',
            'customer_payment_methods/revoke',
            'customer_payment_methods/update',
          ],
        },
        order: {
          name: 'Order',
          topics: [
            'orders/cancelled',
            'orders/create',
            'orders/fulfilled',
            'orders/paid',
            'orders/partially_fulfilled',
            'orders/updated',
          ],
        },
        orderEdit: {
          name: 'Order edit',
          topics: [
            'orders/edited',
          ],
        },
        orderTransaction: {
          name: 'Order transaction',
          topics: [
            'order_transactions/create',
          ],
        },
        product: {
          name: 'Product',
          topics: [
            'products/create',
            'products/update',
          ],
        },
        productListing: {
          name: 'Product listing',
          topics: [
            'product_listings/add',
            'product_listings/update',
          ],
        },
        profile: {
          name: 'Profile',
          topics: [
            'profiles/create',
            'profiles/update',
          ],
        },
        refund: {
          name: 'Refund',
          topics: [
            'refunds/create',
          ],
        },
        sellingPlanGroup: {
          name: 'Selling plan group',
          topics: [
            'selling_plan_groups/create',
            'selling_plan_groups/update',
          ],
        },
        shop: {
          name: 'Shop',
          topics: [
            'app/uninstalled',
            'shop/update',
          ],
        },
        shopAlternateLocale: {
          name: 'Shop alternate locale',
          topics: [
            'locales/create',
            'locales/update',
          ],
        },
        subscriptionContract: {
          name: 'Subscription contract',
          topics: [
            'subscription_contracts/create',
            'subscription_contracts/update',
          ],
        },
        tenderTransaction: {
          name: 'Tender transaction',
          topics: [
            'tender_transactions/create',
          ],
        },
        theme: {
          name: 'Theme',
          topics: [
            'themes/create',
            'themes/publish',
            'themes/update',
          ],
        },
      },
    };
  },
};
