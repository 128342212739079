// import moment from 'moment';
// import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { actions, getters, commonState } from '@dailyplanet/data-stores/commonViewStores';

/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        ...commonState.menu.sales.menuItems,
      ],
    },
  },
  getters: {
    navigation: (state) => state.navigation,
    ...getters.scrapingZone,
    ...getters.salesContact,
    ...getters.lead,
  },
  actions: {
    ...actions.scrapingZone,
    ...actions.salesContactSuggestion,
    ...actions.salesContact,
    ...actions.lead,
  },
};
