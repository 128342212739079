<template>
  <div class="ubuCustomTable">
    <b-table
      :data="customs"
      detailed
      detail-key="id"
      :show-detail-icon="false"
      custom-detail-row
      :opened-detailed="openedDetails"
      @click="toggleOpenedDetails($event)"
    >
      <b-table-column>
        <template v-slot="props">
          <div class="multibar">
            <b-icon
              :icon="iconName"
              pack="ubu"
              :class="`start has-text-color-${props.row.color}`"
              :font-size="20"
            />
            <span class="start">
              {{ props.row.name }}
            </span>
            <b-icon
              class="end"
              pack="ubu"
              :icon="openedDetails.find(
                (id) => id === props.row.id) ? 'ubu-arrow-up' : 'ubu-arrow-down'"
            />
          </div>
        </template>
      </b-table-column>

      <template #detail="props">
        <slot
          :row="props.row"
          name="detail"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'UbuCustomTable',
  props: {
    customs: {
      type: Array,
      default: () => [],
    },
    iconName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      openedDetails: [],
    };
  },
  methods: {
    toggleOpenedDetails(row) {
      if (this.openedDetails.find((id) => id === row.id)) {
        this.openedDetails = [];
      } else {
        this.openedDetails = [row.id];
      }
    },
  },
};
</script>
