<template>
  <b-tooltip
    position="is-bottom"
    append-to-body
  >
    <div class="ubuCustomChip">
      <div
        v-if="model.listItem.length > 1"
        class="ubuCustomChipCounter"
      >
        <span class="subtext is-3">
          {{ model.listItem.length }}
        </span>
      </div>

      <b-icon
        :icon="model.defaultIcon"
        pack="ubu"
        :class="`has-text-color-${model.listItem.length ? model.listItem[0].color : '0'}`"
        size="is-small"
      />
    </div>

    <template v-slot:content>
      <div class="flex column p-0">
        <span class="mb-2">{{ model.title }}</span>
        <ul>
          <li
            v-for="(itemLi, index) in model.listItem"
            :key="`model-${index}`"
            class="multibar row-vcentered"
          >
            <b-icon
              v-if="itemLi.icon"
              :icon="itemLi.icon"
              pack="ubu"
              :class="`start mr-2 has-text-color-${itemLi.color}`"
              size="is-small"
            />
            <span class="start text-truncate has-text-black">{{ itemLi.label }}</span>

            <!-- optional to display campaign status/memberGroup... -->
            <span
              v-if="itemLi.status"
              class="start text-truncate ml-2"
            >
              {{ itemLi.status }}
            </span>
          </li>
        </ul>
      </div>
    </template>
  </b-tooltip>
</template>

<script>
export default {
  name: 'UbuCustomChip',
  model: {
    prop: 'model',
  },
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
