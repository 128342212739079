<template>
  <TheTabsPanel
    v-model="localActiveTab"
    @goToPreviousTab="goToPreviousTab()"
    @goToStart="goToTabByTabPath('root')"
    @close="closePanel()"
  >
    <template #content>
      <b-tab-item value="root">
        <article>
          <div class="buttons">
            <UbuActionCard
              v-for="item in navigableItems"
              :key="item.label"
              class="mb-4"
              :label="item.label"
              icon-pack="ubu"
              :icon-left="item.icon"
              expanded
              :disabled="item.isDisabled"
              @click="handleNavigation(item)"
            />

            <UbuActionCard
              class="mb-4"
              label="Download mentions"
              icon-pack="ubu"
              icon-left="ubu-download"
              expanded
              @click="downloadTaskSelected(localChecked)"
            />

            <UbuActionCard
              v-if="canDisplayMentionActions"
              label="Add these mentions to favorites"
              icon-pack="ubu"
              icon-left="ubu-important"
              expanded
              class="mb-4"
              @click="bulkAddFav()"
            />
            <UbuActionCard
              label="Remove from tribe"
              icon-pack="ubu"
              icon-left="ubu-trash"
              expanded
              class="mb-4"
              @click="bulkRemoveFromTribe()"
            />
          </div>
        </article>
      </b-tab-item>

      <!-- <b-tab-item value="root.downloadMentions">
        <TheDownloadMentions
          v-if="localActiveTab.tab === 'root.downloadMentions' && canDisplayMentionActions"
          v-model="mentionExtrefIdList"
          @done="handleSuccessDownload($event)"
        />
      </b-tab-item> -->

      <b-tab-item value="root.moveToGroup">
        <TheBulkAssignMemberGroup v-model="localChecked" />
      </b-tab-item>

      <TheNewAddToCampaign
        v-model="localChecked"
        :active-tab="localActiveTab"
        :tab-items="tabItems"
        @update:activeTab="UP_changeToNewTab($event)"
      />

      <TheSendInstantMessage
        v-model="localChecked"
        :active-tab="localActiveTab"
        :tab-items="tabItems"
        @update:activeTab="UP_changeToNewTab($event)"
      />
    </template>
  </TheTabsPanel>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import DownloadTaskMixin from '@dailyplanet/mixins/DownloadTaskMixin';

export default {
  name: 'TheTribeBulkPanel',
  mixins: [DownloadTaskMixin],
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      accountFeatures: 'TheMention/accountFeatures',
      activeTab: 'TheTribePeopleBulk/activeTab',
      _checkedRows: 'TheTribePeopleBulk/checkedRows',
      tabItems: 'TheTribePeopleBulk/tabItems',
    }),
    localActiveTab: {
      get() { return this.activeTab; },
      set(newValue) { this.UP_activeTab(newValue); },
    },
    localChecked: {
      get() { return this._checkedRows; },
      set(newValue) {
        this.UP_checkedRows(newValue);
        this.$emit('input', newValue);
      },
    },
    // mentionExtrefIdList() {
    //   return this.localChecked.map(({ mainMedia }) => (mainMedia ? ({ extrefId: mainMedia.extrefId }) : null));
    // },
    // mentionExtrefIdList() {
    //   return this.localChecked.reduce((acc, { mainMedia, carousel }) => {
    //     if (carousel && carousel.length) acc.push(...carousel.map(({ extrefId }) => ({ extrefId })));
    //     if (mainMedia) acc.push({ extrefId: mainMedia.extrefId });
    //     return acc;
    //   }, []);
    // },
    canDisplayMentionActions() {
      return this.$route.fullPath.includes('mentions');
    },
    navigableItems() {
      return {
        'root.sendMessage': {
          to: 'root.sendMessage',
          icon: 'ubu-compose',
          label: 'Send instant message',
        },
        'root.addToCampaign': {
          to: 'root.addToCampaign',
          icon: 'ubu-influence',
          label: 'Add to campaign',
        },
        'root.moveToGroup': {
          to: 'root.moveToGroup',
          icon: 'ubu-folder',
          label: 'Move to group',
        },
      };
    },
  },
  watch: {
    checkedRows() {
      console.log('🚀 ~ file: TheTribeBulkPanel.vue ~ line 99 ~ checkedRows ~ this.checkedRows', this.checkedRows);
      this.UP_checkedRows(this.checkedRows);
    },
  },
  mounted() {
    return Promise.resolve()
      .then(() => this.UP_resetStore())
      .then(() => this.UP_checkedRows(this.checkedRows));
  },
  methods: {
    ...mapActions({
      UP_checkedRows: 'TheTribePeopleBulk/UP_checkedRows',
      UP_activeTab: 'TheTribePeopleBulk/UP_activeTab',
      UP_resetStore: 'TheTribePeopleBulk/UP_resetStore',
      goToPreviousTab: 'TheTribePeopleBulk/goToPreviousTab',
      goToTabByTabPath: 'TheTribePeopleBulk/goToTabByTabPath',
      emitSegmentEvent: 'TheMention/emitSegmentEvent',
      UP_resetNavigation: 'TheTribePeopleBulk/UP_resetNavigation',
      goToPreviousVisitedTab: 'TheTribePeopleBulk/goToPreviousVisitedTab',
      UP_changeToNewTab: 'TheTribePeopleBulk/UP_changeToNewTab',

      _deleteTribeMember: 'TheTribe/deleteTribeMember',
      _favMentions: 'TheMention/favMentions',
    }),
    closePanel() {
      this.UP_resetStore();
      this.$emit('close');
    },
    handleNavigation(item) {
      if (item.requiredFeature && !this.accountFeatures(item.requiredFeature)) {
        this.emitSegmentEvent({ event: item.segmentEvent });
        this.$intercom.showNewMessage('Hi! I would like to activate the Ubu\'s Meta Extension! 🙌');

        return;
      }

      this.UP_activeTab(this.tabItems[item.to]);
    },
    // handleSuccessDownload() {
    //   this.goToTabByTabPath('root');
    // },
    notifySuccessBulkRemove(count) {
      this.goToTabByTabPath('root');
      const message = count === 0 ? 'All members were removed from tribe' : `${count} members were removed from tribe`;
      return this.$buefy.snackbar.open({
        message,
        type: 'is-success',
        position: 'is-top',
      });
    },
    bulkRemoveFromTribe() {
      const tmids = this.localChecked.map(({ tmid }) => tmid);

      this._deleteTribeMember({ payload: { tmids, tid: this.$route.params.tribeTid } })
        .then(() => this.notifySuccessBulkRemove(tmids.length));
    },
    notifySuccessFav(count) {
      this.goToTabByTabPath('root');
      const message = count === 0 ? 'All mentions were added to favorites' : `${count} mentions were added to favorites`;
      return this.$buefy.snackbar.open({
        message,
        type: 'is-success',
        position: 'is-top',
      });
    },
    bulkAddFav() {
      console.log('🚀 ~ file: TheTribeBulkPanel.vue ~ line 209 ~ bulkAddFav ~ publicationsToFav');
      const publicationsToFav = this.localChecked
        .filter(({ favorite }) => !favorite.publicationPid);
      console.log('🚀 ~ file: TheTribeBulkPanel.vue ~ line 209 ~ bulkAddFav ~ publicationsToFav', publicationsToFav);

      return this._favMentions({
        payload: { pids: publicationsToFav.map(({ pid }) => pid) },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_favMentionList',
          payload: {
            pids: publicationsToFav.map(({ pid }) => pid),
            shouldFav: true,
            cpids: publicationsToFav.map(({ cpid }) => cpid),
          },
        }],
      })
        .then(() => this.notifySuccessFav(publicationsToFav.length));
    },
  },
};
</script>
