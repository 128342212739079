/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clarkDl';

const routes = {
  affiliationCode: ({ payload, httpQuery }) => builder({ url: `/dl/channelGroup/${payload.channelGroupCgid}/shop/${payload.shopSid}/affiliationCode`, method: 'GET', payload, httpQuery, target }),
  tribeMember: ({ payload, httpQuery }) => builder({ url: `/dl/channelGroup/${payload.channelGroupCgid}/tribe/${payload.tribeTid}/tribeMember`, method: 'GET', payload, httpQuery, target }),
  activity: ({ payload, httpQuery }) => builder({ url: `/dl/channelGroup/${payload.channelGroupCgid}/campaign/${payload.campaignCid}/activity`, method: 'GET', payload, httpQuery, target }),
  downloadTask: ({ payload, httpQuery }) => builder({ url: `/dl/channel/${payload.channelCid}/downloadTask/${payload.filename}`, method: 'GET', payload, httpQuery, target }),

  iqdataReport: {
    getPDFReport: ({ payload, httpQuery }) => builder({ url: `/dl/channelGroup/${payload.channelGroupCgid}/iqdataReport/${payload.extrefId}`, method: 'GET', payload, httpQuery, target }),
    tryFetchReportByUsername: ({ payload, httpQuery }) => builder({ url: `/dl/channelGroup/${payload.channelGroupCgid}/iqdataReport/tryFetch/${payload.username}`, method: 'GET', payload, httpQuery, target }),
    createReport: ({ payload, httpQuery }) => builder({ url: `/dl/channelGroup/${payload.channelGroupCgid}/iqdataReport/${payload.username}`, method: 'POST', payload, httpQuery, target }),
  },
};

export default routes;
