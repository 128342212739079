import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    manageShopList: null,
    manageShop: null,
  },
  mutations: {
    SET_MANAGE_SHOP_LIST(state, newData) { state.manageShopList = newData; },
    SET_MANAGE_SHOP(state, newData) { state.manageShop = newData; },
  },
  getters: {
    getterManageShopList: (state) => state.manageShopList,
    getterManageShop: (state) => state.manageShop,
  },
  actions: {
    report({ commit }, { payload = {}, httpQuery = {} }) {
      return clark.api.apiAffiliation.manageShop.report({ payload, httpQuery })
        .then((manageShop) => {
          commit('SET_MANAGE_SHOP_LIST', manageShop);
        });
    },
    get({ commit }, { payload = {}, httpQuery = {} }) {
      return clark.api.apiAffiliation.manageShop.get({ payload, httpQuery })
        .then((manageShop) => {
          commit('SET_MANAGE_SHOP', manageShop);
        });
    },
    create({ commit, getters }, { payload = {}, httpQuery = {} }) {
      return clark.api.apiAffiliation.manageShop.post({ payload, httpQuery })
        .then((manageShop) => {
          const shopifyShopList = getters.getterManageShopList;
          shopifyShopList.push(manageShop);

          commit('SET_MANAGE_SHOP_LIST', shopifyShopList);
          return manageShop;
        });
    },
    update({ commit, getters }, { payload = {}, httpQuery = {} }) {
      return clark.api.apiAffiliation.manageShop.put({ payload, httpQuery })
        .then((manageShop) => {
          const shopifyShopList = getters.getterManageShopList;
          const index = shopifyShopList
            .findIndex((shop) => Number(shop.ssid) === Number(manageShop.ssid));

          shopifyShopList[index] = { ...manageShop };

          commit('SET_MANAGE_SHOP_LIST', shopifyShopList);
          return manageShop;
        });
    },
    delete({ commit, getters }, { payload = {}, httpQuery = {} }) {
      return clark.api.apiAffiliation.manageShop.delete({ payload, httpQuery })
        .then(() => {
          const shopifyShopList = getters.getterManageShopList;
          const newShopList = shopifyShopList.reduce((acc, shop) => {
            if (Number(shop.ssid) === Number(payload.ssid)) return acc;
            acc.push(shop);
            return acc;
          }, []);

          commit('SET_MANAGE_SHOP_LIST', newShopList);

          return true;
        });
    },
  },
};
