<template>
  <div>
    <p v-if="value">{{ value }}</p>
    <p v-else>-</p>
  </div>
</template>

<script>
import TableColumnMixin from '../_mixins/UbuTableColumn.mixin';

export default {
  name: 'UbuTableSimple',
  mixins: [TableColumnMixin],
  props: {
    cpo: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: String,
      required: true,
    },
  },
};
</script>
