import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    helpCenterList: (_state, _getters, _rootState, rootGetters) => {
      const { 'HelpCenter/getterHelpCenterList': helpCenterList } = rootGetters;

      return helpCenterList;
    },
  },
  actions: {
    reportHelpCenterList({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'HelpCenter/report',
        context: { payload, httpQuery },
        textNotifier: 'report help center',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to get help center.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
  },
};
