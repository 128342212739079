<template>
  <section id="TheWorkspace">
    <UbuAsideMenu :sidebar-options="sidebarOptions" />
    <div class="has-coreview-spaced kara">
      <DevDetails :cnt="{name: 'selected', obj: selected}" />
      <router-view
        name="addUser"
        :selected.sync="selected"
        @reset="selected = {}"
      />
      <router-view
        name="allUsers"
        :selected.sync="selected"
        @reset="selected = {}"
      />
      <router-view
        name="usersUser"
        :selected.sync="selected"
      />
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions } from 'vuex';

export default {
  name: 'TheUsers',
  data() {
    return {
      selected: {},
      routes: [
        {
          title: 'GLOBAL WORKFLOW',
          ordinal: 0,
          items: [
            {
              text: 'All Users',
              path: '/users/all-users',
            },
            {
              text: 'Add User',
              path: '/users/add-user',
            },
          ],
        },
      ],
    };
  },
  computed: {
    sidebarOptions() {
      const commonRoutes = this.routes;

      if ((!Object.values(this.selected).length || !this.selected.uid) && !this.$route.params.uid) return commonRoutes;

      const basePath = !this.$route.params.uid
        ? `/users/user/${this.selected.uid}`
        : `/users/user/${this.$route.params.uid}`;

      const accountRoutes = [
        {
          title: 'USERS WORKFLOW',
          ordinal: 1,
          items: [
            {
              text: 'Details',
              path: `${basePath}/details`,
            },
            {
              text: 'Accounts',
              path: `${basePath}/accounts`,
            },
            // {
            //   text: 'Themes',
            //   path: `${basePath}/themes`,
            // },
            {
              text: 'Role',
              path: `${basePath}/role`,
            },
          ],
        },
      ];

      return commonRoutes.concat(accountRoutes);
    },
  },
  watch: {
    selected(newVal) {
      if (newVal.uid) {
        this.$router.push({
          path: `/users/user/${newVal.uid}/details`,
        });
      }
    },
  },
  mounted() {
    return Promise.all([
      this.reportDetailledUser({}),
    ]);
  },
  methods: {
    ...mapActions({
      reportDetailledUser: 'TheUsers/reportDetailledUser',
    }),
  },
};
</script>
