var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"ubuSidePanel ubuPanel-xl"},[_c('div',{staticClass:"multibar row-vcentered"},[_c('b-button',{staticClass:"end is-ghost is-borderless is-large",attrs:{"tag":"router-link","to":`/admin/account/${_vm.currentAid}/details`,"icon-pack":"ubu","icon-left":"ubu-cross"}})],1),_c('br'),_c('div',{staticClass:"multibar"},[_c('h2',{staticClass:"start title is-1"},[_vm._v("Add features")]),_c('div',{staticClass:"buttons end"},[_c('b-button',{attrs:{"type":"is-primary","label":"View history","tag":"router-link","to":`/admin/account/${_vm.currentAid}/history`}})],1)]),_c('br'),_c('DevDetails',{attrs:{"cnt":{name: 'accountFeature', obj: _vm.accountFeature}}}),_c('DevDetails',{attrs:{"cnt":{name: '_featureList', obj: _vm._featureList}}}),_c('DevDetails',{attrs:{"cnt":{name: 'addFeatures', obj: _vm.addFeatures}}}),_c('DevDetails',{attrs:{"cnt":{name: 'removeFeatures', obj: _vm.removeFeatures}}}),_c('section',[_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('section',{staticClass:"card ubuCheckboxSwitch"},[_c('div',{staticClass:"card-content"},[_c('h3',{staticClass:"subtitle is-3"},[_vm._v("All features")]),_c('br'),_c('form',[_c('fieldset',[(_vm.featuresToBeAdded.length)?[_c('div',{staticClass:"multibar"},[_c('div',{staticClass:"buttons end"},[_c('b-button',{staticClass:"end",attrs:{"type":"is-ghost","label":_vm.addFeatures.length !== _vm.featuresToBeAdded.length
                        ? 'Select all'
                        : 'Deselect all'},on:{"click":function($event){_vm.addFeatures.length !== _vm.featuresToBeAdded.length
                        ? _vm.selectAll('addFeatures')
                        : _vm.addFeatures = []}}})],1)]),_c('br'),_vm._l((_vm.allFeatures),function(feature){return [(!feature.selected)?_c('b-field',{key:feature.name,staticClass:"featureCheckbox",attrs:{"label":feature.label,"horizontal":""}},[_c('b-checkbox',{attrs:{"native-value":feature},model:{value:(_vm.addFeatures),callback:function ($$v) {_vm.addFeatures=$$v},expression:"addFeatures"}})],1):_vm._e()]})]:_c('span',{staticClass:"has-text-secondary text"},[_vm._v(" All features was added ")])],2)])])]),_c('div',{staticClass:"is-flex is-flex-direction-column is-justify-content-center"},[_c('b-tooltip',{attrs:{"label":"Add features","animated":"","position":"is-right"}},[_c('b-button',{staticClass:"m-2",attrs:{"type":"is-primary","icon-pack":"ubu","icon-left":"ubu-arrow-right","disabled":_vm.addFeatures.length ? false : true},on:{"click":function($event){return _vm.featuresRequest('addFeatures')}}})],1),_c('b-tooltip',{attrs:{"label":"Remove features","animated":"","position":"is-left"}},[_c('b-button',{staticClass:"m-2",attrs:{"type":"is-primary","icon-pack":"ubu","icon-left":"ubu-arrow-left","disabled":_vm.removeFeatures.length ? false : true},on:{"click":function($event){return _vm.featuresRequest('removeFeatures')}}})],1)],1),_c('section',{staticClass:"card ubuCheckboxSwitch"},[_c('div',{staticClass:"card-content"},[_c('h3',{staticClass:"subtitle is-3"},[_vm._v("Features added to account")]),_c('br'),_c('form',[_c('fieldset',[(_vm.featuresToBeRemoved.length)?[_c('div',{staticClass:"multibar"},[_c('div',{staticClass:"buttons end"},[_c('b-button',{staticClass:"end",attrs:{"type":"is-ghost","label":_vm.removeFeatures.length !== _vm.featuresToBeRemoved.length
                        ? 'Select all'
                        : 'Deselect all'},on:{"click":function($event){_vm.removeFeatures.length !== _vm.featuresToBeRemoved.length
                        ? _vm.selectAll('removeFeatures')
                        : _vm.removeFeatures = []}}})],1)]),_c('br'),_vm._l((_vm.allFeatures),function(feature){return [(feature.selected)?_c('b-field',{key:feature.name,staticClass:"featureCheckbox",attrs:{"label":feature.label,"horizontal":""}},[_c('b-checkbox',{attrs:{"native-value":feature},model:{value:(_vm.removeFeatures),callback:function ($$v) {_vm.removeFeatures=$$v},expression:"removeFeatures"}})],1):_vm._e()]})]:_c('span',{staticClass:"has-text-secondary text is-3"},[_vm._v(" No features was added ")])],2)])])])]),(_vm.error)?[_c('br'),_c('UbuHelper',{attrs:{"type":"is-danger","content":_vm.error,"content-type":"is-danger"}})]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }