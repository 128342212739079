/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        {
          path: '/home',
          text: 'What\'s New',
          icon: 'ubu-home',
        },
        {
          path: '/setting',
          text: 'Settings',
          icon: 'ubu-settings',
          hasAlert: false,
        },
        {
          path: '/api',
          text: 'Api',
          icon: 'ubu-share',
        },
      ],
    },
  },
  getters: {
    navigation: (state) => state.navigation,
  },
};
