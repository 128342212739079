<template>
  <section class="ubuSettingView">
    <h1 class="start title is-3">Channels</h1>

    <br>
    <DevDetails :cnt="{name: 'account', obj: account}" />
    <DevDetails :cnt="{name: 'currentChannelGroup', obj: currentChannelGroup}" />
    <DevDetails :cnt="{name: 'formatChannelState', obj: formatChannelState}" />

    <template v-for="({ platform, isConnected, name, path, comingSoon }, i) in formatChannelState">
      <div
        v-if="platform"
        :key="i"
      >
        <div
          class="multibar row-vcentered py-3"
        >
          <div class="start mr-1">
            <component
              :is="`UbuIcon${platform.capitalize()}`"
              font-size="24"
            />
          </div>
          <div class="start ml-3">
            <p
              :class="['text text-truncate', {
                'has-text-secondary': !isConnected
              }]"
            >
              {{ platform }}
            </p>
            <p class="subtext has-text-secondary text-truncate">{{ name }}</p>
          </div>

          <div class="buttons end">
            <b-button
              v-if="!comingSoon"
              :label="isConnected ? 'Reconnect' : 'Connect'"
              :type="isConnected ? 'is-success' : 'is-secondary'"
              tag="router-link"
              :to="path"
            />
            <b-button
              v-else
              label="Coming soon..."
              type="is-ghost"
              disabled
            />
          </div>
        </div>

        <hr
          v-if="i !== formatChannelState.length - 1"
          class="is-l"
        >
      </div>
    </template>
    <router-view name="addMeta" />
    <router-view name="connectMeta" />
    <router-view name="selectFacebook" />
    <router-view name="selectInstagram" />
    <router-view name="addTiktok" />
    <router-view name="addYoutube" />
    <router-view name="addSms" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheSettingChannels',
  computed: {
    ...mapGetters({
      account: 'TheSettingWorkspaceSetting/account',
      currentChannelGroup: 'TheSettingWorkspaceSetting/currentChannelGroup',
    }),
    formatChannelState() {
      if (!this.account) return [];
      if (!this.currentChannelGroup) return [];

      const comingSoon = ['TIKTOK', 'YOUTUBE', 'SMS'];
      const platforms = ['META', 'TIKTOK', 'YOUTUBE', 'SMS'];
      // const alias = {
      //   FACEBOOK: { name: 'META' },
      // };
      const currentChannelGroupCgid = this.currentChannelGroup.cgid;

      const hasPlatformInChildren = this.account.channelGroupList
        .find(({ cgid }) => Number(cgid) === Number(currentChannelGroupCgid));

      if (!hasPlatformInChildren) return [];

      return platforms.reduce((acc, platform) => {
        const channelTarget = hasPlatformInChildren.channelList
          .find(({ platformName }) => platformName === platform);

        acc.push({
          platform,
          isConnected: !!channelTarget,
          name: channelTarget ? channelTarget.name : null,
          path: `/setting/channel/add${platform.capitalize()}`,
          comingSoon: !!comingSoon.includes(platform),
        });

        return acc;
      }, []);
    },
  },
};
</script>
