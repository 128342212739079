<template>
  <div
    id="TheScraping"
    class="card-content"
  >
    <!-- <DevDetails :cnt="{name: 'localNewLead', obj: localNewLead}" /> -->

    <b-message type="is-info">
      <p class="subtext">
        In order to keep database away of duplicates, you need to first type Instagram username.
      </p>
    </b-message>

    <fieldset class="fieldset">
      <legend>Database research</legend>
      <b-field
        label="Username"
        :message="[
          { 'Instagram username must have at least 3 caracters': username && username.length < 3 },
          { 'Only valid Instagram username is allowed': !isUsernameValid }
        ]"
        horizontal
      >
        <b-input
          v-model="username"
          :loading="searchLoading"
          icon="magnify"
          type="text"
          pattern="^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$"
          validation-message="Only valid Instagram username is allowed"
          placeholder="type a username"
          required
          @input="searchLoading = true"
        />
      </b-field>
    </fieldset>
    <br>
    <div v-if="usernameSearchResult.length">
      <b-message type="is-info">
        <p class="subtext">There is Instagram account(s) matching this pattern username.</p>
        <p class="subtext">
          You can update it, its leads, or create new leads by
          toggling details with the ">" on the left of the line.
        </p>
      </b-message>

      <UbuSalesContactTable
        v-if="usernameSearchResult.length"
        :sales-contact-list="Object.values(salesContactList)"
        :scraping-zone-list="Object.keys(scrapingZoneList)"
        :columns="columns"
        @updateContact="updateAndFetchSuggestion($event)"
        @createLead="createLead($event)"
        @updateLead="updateLead($event)"
      />
    </div>

    <div v-else-if="allowedToCreate">
      <b-message type="is-info">
        <p class="subtext">
          There is no Instagram account(s) in database matching this pattern username.
        </p>
        <p class="subtext">
          You can create it, and its leads.
        </p>
      </b-message>
      <form @submit.prevent="addContact(newSalesContact)">
        <UbuSalesContactForm
          v-model="newSalesContact"
          :scraping-zone-list="Object.keys(scrapingZoneList)"
        />

        <br>

        <b-collapse
          v-for="(lead, index) in newSalesContact.leads"
          :key="index"
          class="card"
          animation="slide"
          :open="true"
          aria-id="contentIdForA11y3"
        >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              aria-controls="contentIdForA11y3"
            >
              <p class="card-header-title is-6">
                New Lead #{{ index + 1 }}
              </p>
              <a class="card-header-icon">

                <b-button
                  class=""
                  type="is-ghost"
                  icon-left="minus"
                  :label="`Remove Lead #${index + 1}`"
                  @click="newSalesContact.leads.splice(index, 1)"
                />

                <b-icon
                  :icon="props.open ? 'menu-down' : 'menu-up'"
                />

              </a>
            </div>
          </template>

          <div class="card-content">
            <div class="buttons is-right">
              <b-button
                type="is-link"
                label="Prefill Headquarter"
                @click="prefillHeadquarter(index)"
              />
            </div>
            <UbuLeadForm v-model="newSalesContact.leads[index]" />
          </div>
        </b-collapse>

        <br>

        <div class="buttons is-right">
          <b-button
            type="is-info"
            icon-left="plus"
            label="Add Lead"
            @click="addLead()"
          />
          <b-button
            type="is-success"
            native-type="submit"
            label="Save"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheScraping',
  data() {
    return {
      username: null,
      usernameSearchResult: [],
      searchLoading: false,
      newSalesContact: {
        username: null,
        platformName: 'INSTAGRAM',
        followers: null,
        engagement: null,
        growth: null,
        zone: null,
        language: null,
        scrapingZoneSzid: null,
        category: null,
        picture: null,
        isVerified: null,
        origin: 'MANUAL',
        leads: null,
      },
      columns: {
        // picture: { is: 'contact', visible: true },
        username: { is: 'contact', visible: true },
        // createdOn: { is: 'contact', visible: true },
        updatedOn: { is: 'contact', visible: true },
        engagement: { is: 'contact', visible: true },
        followers: { is: 'contact', visible: true },
        growth: { is: 'contact', visible: true },
        zone: { is: 'contact', visible: false },
        language: { is: 'contact', visible: true },
        category: { is: 'contact', visible: false },
        origin: { is: 'contact', visible: true },
        reviewed: { is: 'contact', visible: true },
        'scraping zone': { is: 'contact', visible: true },
      },
    };
  },
  computed: {
    ...mapGetters({
      salesContactList: 'TheScraping/salesContactList',
      scrapingZoneList: 'TheScraping/scrapingZoneList',
    }),
    isUsernameValid() {
      if (!this.username) return false;
      return !!this.username.match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/);
    },
    allowedToCreate() {
      return this.username && this.username.length >= 3
          && !this.usernameSearchResult.length && this.isUsernameValid && !this.searchLoading;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    username: debounce(function (newVal) {
      this.usernameSearchResult = [];
      if (!newVal || !newVal.length || newVal.length < 3) {
        this.searchLoading = false;
        return;
      }
      if (!newVal.match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/)) {
        this.searchLoading = false;
        return;
      }
      this.searchLoading = true;
      this.newSalesContact.username = newVal;
      this.searchContactByUsername(newVal)
        .then(() => {
          this.usernameSearchResult = Object.values(this.salesContactList);
          this.searchLoading = false;
        });
    }, 1000),
  },
  mounted() {
    return this.reportScrapingZone();
  },
  methods: {
    ...mapActions({
      reportScrapingZone: 'TheScraping/reportScrapingZone',
      searchContactByUsername: 'TheScraping/searchContactByUsername',
      createContact: 'TheScraping/createContact',
      updateContact: 'TheScraping/updateContact',
      createLead: 'TheScraping/createLead',
      updateLead: 'TheScraping/updateLead',
      searchForSuggestions: 'TheScraping/searchForSuggestions',
    }),
    addContact(newSalesContact) {
      return this.createContact(newSalesContact)
        .then((contact) => {
          const { username, isVerified, scrapingZoneSzid } = contact;

          return isVerified
            ? this.searchForSuggestions({ username, szid: scrapingZoneSzid })
            : undefined;
        })
        .then(() => {
          this.username = null;
          this.usernameSearchResult = [];

          this.newSalesContact = {
            username: null,
            platformName: 'INSTAGRAM',
            followers: null,
            engagement: null,
            growth: null,
            zone: null,
            language: null,
            category: null,
            picture: null,
            isVerified: null,
            phone: null,
            origin: 'MANUAL',
            leads: null,
          };
        });
    },
    addLead() {
      const newLead = {
        linkedInUrl: null,
        firstName: null,
        lastName: null,
        position: null,
        email: null,
        phone: null,
        isRisky: false,
      };
      if (!this.newSalesContact.leads) this.newSalesContact.leads = [newLead];
      else this.newSalesContact.leads.push(newLead);
    },
    prefillHeadquarter(index) {
      const myLeads = [...this.newSalesContact.leads];
      myLeads[index] = {
        linkedInUrl: null,
        firstName: null,
        lastName: 'HEADQUARTER',
        position: 'HEADQUARTER',
        email: null,
        phone: null,
        isRisky: false,
      };
      this.newSalesContact = {
        ...this.newSalesContact,
        leads: myLeads,
      };
    },
    updateAndFetchSuggestion(contact) {
      console.log('🚀 ~ file: TheSuggestions.vue ~ line 92 ~ updateAndFetchSuggestion ~ contact', contact);
      return this.updateContact(contact)
        .then(() => {
          const { username, isVerified, scrapingZoneSzid } = contact;

          return isVerified
            ? this.searchForSuggestions({ username, szid: scrapingZoneSzid })
            : undefined;
        });
    },
  },
};
</script>

<style lang="scss">
#TheScraping {
  height: calc(100vh - (115px));
  max-height: calc(100vh - (115px));
  overflow-y: auto;
}
</style>
