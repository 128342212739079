<template>
  <div>
    <section class="ubuEditCustom is-detailed">
      <form @submit.prevent="updatedDetailsThreadGroup(item, custom)">
        <UbuEditCustomForm
          v-model="localCustom"
          type="TAG"
        />

        <br>

        <b-field label="AUTOMATIONS">
          <b-switch
            size="is-small"
            :value="item.hasAutoflush"
            @click.native.prevent="toggleAutoflush()"
          >
            Auto-remove tag and close after a number of days of inactivity
          </b-switch>
          <b-button
            type="is-ghost is-borderless"
            label="Edit number of days"
            :disabled="!localCustom.autoflushDuration"
            @click="editAutoflush()"
          />
        </b-field>

        <b-field>
          <b-switch
            size="is-small"
            :value="item.hasAutotag"
            @click.native.prevent="toggleAutotag()"
          >
            Auto-tag with keywords
          </b-switch>
          <b-button
            type="is-ghost is-borderless"
            label="Edit keywords"
            :disabled="!localCustom.keywords"
            @click="editAutotagKeywords()"
          />
        </b-field>

        <b-field>
          <b-switch
            size="is-small"
            :value="item.hasAutoclose"
            @click.native.prevent="toggleAutoclose($event)"
          >
            Auto-close conversation everytime this tag is added automatically
          </b-switch>
        </b-field>

        <br>

        <b-field label="ACTIONS">
          <div class="buttons">
            <b-button
              type="is-success"
              icon-pack="ubu"
              native-type="submit"
              label="Save changes"
              :disabled="!canSave"
            >
              Save changes
            </b-button>

            <b-button
              type="is-danger"
              icon-pack="ubu"
              icon-left="ubu-trash"
              @click="deleteThreadGroup(item)"
            >
              Delete
            </b-button>
          </div>
        </b-field>
      </form>
    </section>

    <TheSettingTagEditAutoflushPanel
      v-if="isOpenAutoFlushPanel"
      v-model="localCustom"
      @close="isOpenAutoFlushPanel = false; $emit('hasPanelOpen')"
    />
    <TheSettingTagEditAutoTagPanel
      v-if="isOpenAutoTagPanel"
      v-model="localCustom"
      @close="isOpenAutoTagPanel = false; $emit('hasPanelOpen')"
    />
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSettingTagDetails',
  props: {
    item: { type: Object, required: true },
    hasInboxFeature: { type: Boolean, required: true },
  },
  data() {
    return {
      isOpenAutoFlushPanel: false,
      isOpenAutoTagPanel: false,
      custom: {
        name: null,
        color: '1',
      },
    };
  },
  computed: {
    ...mapGetters({
      threadGroupList: 'TheSettingWorkspaceSetting/threadGroupList',
    }),
    localCustom: {
      get() {
        return this.custom.label
          ? this.custom
          : {
            ...this.item,
            autoflushDuration: this.item.autoflushDuration
              ? { ...this.item.autoflushDuration }
              : null,
          };
      },
      set(val) {
        this.custom = {
          ...this.item,
          autoflushDuration: this.item.autoflushDuration
            ? { ...this.item.autoflushDuration }
            : null,
          ...val,
        };
      },
    },
    canSave() {
      const { id, name, ...restCustom } = this.localCustom;
      if (JSON.stringify(restCustom) === JSON.stringify(this.threadGroupList[this.item.tgid])) return false;
      return true;
    },
  },
  methods: {
    ...mapActions({
      _deleteThreadGroupAutoTag: 'TheSettingWorkspaceSetting/deleteThreadGroupAutoTag',
      _updateThreadGroup: 'TheSettingWorkspaceSetting/updateThreadGroup',
      _deleteThreadGroup: 'TheSettingWorkspaceSetting/deleteThreadGroup',
      emitSegmentEvent: 'TheInbox/emitSegmentEvent',
    }),
    updateThreadGroup(event) {
      return this._updateThreadGroup({
        payload: {
          tgid: this.item.tgid,
          createdOn: this.item.createdOn,
          deletedOn: this.item.deletedOn,
          label: this.localCustom.label,
          color: this.localCustom.color,
          hasAutoflush: event.hasAutoflush,
          autoflushDuration: event.autoflushDuration,
          hasAutoclose: event.hasAutoclose,
        },
      });
    },
    toggleAutoflush() {
      // block process if we dont have INBOX feature & try to active autoflush
      if (!this.hasInboxFeature && !this.item.hasAutoflush) return this.activationRequest('activeAutoFlushNoFeature');

      const { hasAutoflush } = this.localCustom;

      if (hasAutoflush) {
        return this.updateThreadGroup({
          ...this.localCustom,
          hasAutoflush: false,
          autoflushDuration: null,
        })
          .then(() => {
            this.localCustom = {
              ...this.localCustom,
              hasAutoflush: false,
              autoflushDuration: null,
            };
          });
      }

      this.isOpenAutoFlushPanel = true;
      this.$emit('hasPanelOpen');
      return undefined;
    },
    editAutoflush() {
      // block process if we dont have INBOX feature & try to active autotag
      if (!this.hasInboxFeature && !this.localCustom.hasAutoflush) return this.activationRequest('activeAutoFlushNoFeature');

      this.isOpenAutoFlushPanel = true;
      this.$emit('hasPanelOpen');
      return undefined;
    },

    toggleAutotag() {
      // block process if we dont have INBOX feature & try to active autotag
      if (!this.hasInboxFeature && !this.localCustom.hasAutotag) return this.activationRequest('activeAutoTagNoFeature');

      const { hasAutotag, tgid } = this.localCustom;

      if (hasAutotag) {
        return this._deleteThreadGroupAutoTag({ payload: { tgid } })
          .then(({ response: updatedThreadGroup }) => { this.localCustom = updatedThreadGroup; });
      }

      this.isOpenAutoTagPanel = true;
      this.$emit('hasPanelOpen');
      return undefined;
    },
    editAutotagKeywords() {
      // block process if we dont have INBOX feature & try to active autotag
      if (!this.hasInboxFeature && !this.localCustom.hasAutotag) return this.activationRequest('activeAutoTagNoFeature');

      this.isOpenAutoTagPanel = true;
      this.$emit('hasPanelOpen');
      return undefined;
    },

    toggleAutoclose() {
      // block process if we dont have INBOX feature & try to active autoclose
      if (!this.hasInboxFeature && !this.item.hasAutoclose) return this.activationRequest('activeAutoCloseNoFeature');

      return this.updateThreadGroup({ ...this.item, hasAutoclose: !this.item.hasAutoclose })
        .then((updatedThreadGroup) => {
          this.localCustom = updatedThreadGroup;

          if (updatedThreadGroup.hasAutoclose) this.emitSegmentEvent({ event: 'activeAutoClose' });
        });
    },
    activationRequest(segment) {
      this.emitSegmentEvent({ event: segment });
      this.$intercom.showNewMessage('Hi! I would like to activate the INBOX module! 🙌');
    },
    deleteThreadGroup() {
      this._deleteThreadGroup({ payload: { tgid: this.item.id } });
    },
    updatedDetailsThreadGroup() {
      this.updateThreadGroup({ ...this.item, ...this.localCustom });
    },
  },
};
</script>
