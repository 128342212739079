import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    create(_, { payload, httpQuery = {} }) {
      return clark.api.channelGroup.askUgcPermission.create({ payload, httpQuery });
    },
  },
};
