<template>
  <div class="ubuSettingView start">
    <header class="start">
      <h2
        v-if="manageShop"
        class="subtitle is-1 has-text-secondary m-0"
      >
        {{ manageShop.baseUrl }}
      </h2>
      <div class="multibar">
        <h1 class="start title is-1">Shop manage webhooks</h1>
        <div class="buttons end">
          <b-button
            label="Create webhook"
            type="is-primary"
            tag="router-link"
            :to="`/shopify/shop/${currentSid}/manage-webhooks/create`"
          />
        </div>
      </div>
    </header>

    <br>

    <DevDetails :cnt="{name: 'manageWebhookList', obj: manageWebhookList}" />

    <template v-if="formatManageWebhookTable.length">
      <b-field>
        <b-input
          v-model="inputSearch"
          placeholder="Search a webhook..."
          type="search"
          icon-pack="ubu"
          icon="ubu-search"
          icon-right="ubu-cross"
          icon-right-clickable
          @icon-right-click="inputSearch = null"
        />
      </b-field>

      <br>

      <TheTable
        :lead-list="formatManageWebhookTable"
        :columns="Object.values(columns)"
        :loading="loading"
        :total="total"
        :page="page"
        :per-page="perPage"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :default-sort-order="defaultSortOrder"
        :selected.sync="localSelected"
        :checked-rows.sync="checkedRows"
        :detail-key="'wid'"
        :checkable="false"
        :sticky-header="false"
        @deleteWebhook="deleteWebhook($event)"
      />
    </template>

    <UbuNoData
      v-else
      title="No webhooks for this shop... Yet ! 🧐"
      subtitle="You can create a new webhook for this shop."
      action
      button-is="link"
      :to="`/shopify/shop/${currentSid}/manage-webhooks/create`"
      type="is-primary"
      label="Create webhook"
    />

    <router-view
      name="addManageWebhook"
      @refresh="_reportManageWebhook()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from '@dailyplanet/utils/helpers';
import manageWebhookColumns from '../$mixins/manageWebhookColumns.columns.mixins';

export default {
  name: 'TheShopManageWebhooks',
  mixins: [manageWebhookColumns],
  props: {
    selected: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      page: 0,
      perPage: 50,
      sortField: 'wid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      checkedRows: [],
      search: '',
      inputSearch: '',
    };
  },
  computed: {
    ...mapGetters({
      manageShop: 'TheShop/manageShop',
      manageWebhookList: 'TheShop/manageWebhookList',
    }),
    localSelected: {
      get() { return this.selected; },
      set(newValue) { this.$emit('update:selected', newValue); },
    },
    currentSid() {
      return this.$route.params.sid;
    },
    formatManageWebhookTable() {
      if (!this.manageWebhookList) return [];

      return this.manageWebhookList.reduce((acc, webhook) => {
        acc.push({
          wid: webhook.wid,
          topic: webhook.topic,
          callbackUrl: webhook.endpoint.callbackUrl,
          createdAt: webhook.createdAt,
        });
        return acc;
      }, []);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 500),
  },
  mounted() {
    return Promise.all([
      this._reportManageWebhook(),
      this.getManageShop({ payload: { sid: this.currentSid } }),
    ]);
  },
  methods: {
    ...mapActions({
      reportManageWebhookList: 'TheShop/reportManageWebhookList',
      deleteManageWebhook: 'TheShop/deleteManageWebhook',
      getManageShop: 'TheShop/getManageShop',
    }),
    deleteWebhook({ wid }) {
      this.deleteManageWebhook({
        payload: {
          sid: this.currentSid,
          wid,
        },
      });
    },
    _reportManageWebhook() {
      this.reportManageWebhookList({ payload: { sid: this.currentSid } });
    },
  },
};
</script>
