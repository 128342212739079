<template>
  <UbuDropdown
    :close-on-click="false"
    :scrollable="true"
    :position="position"
    @open="isOpen = true"
    @close="isOpen = false;"
  >
    <template v-slot:button>
      <b-button
        class="end"
        label="Columns"
        icon-pack="ubu"
        icon-left="ubu-columns"
        type="is-secondary"
      />
    </template>

    <template
      v-if="isOpen"
      v-slot:search
    >
      <b-dropdown-item custom>
        <UbuSearchbar
          v-model="search"
          @clear="search = ''"
        />
      </b-dropdown-item>
    </template>

    <template v-slot:content>
      <b-dropdown-item
        class="no-hover-background"
        :disabled="!hasChange"
        @click="reset()"
      >
        <span class="subtext has-text-primary">Reset</span>
      </b-dropdown-item>

      <b-dropdown-item
        v-for="column in columnsFiltered"
        :key="column.field"
        custom
      >
        <b-switch
          v-model="columns[column.field].visible"
          size="is-small"
        >
          {{ column.label }}
        </b-switch>
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'UbuColumnToggle',
  mixins: [FilterEngineMixin],
  model: {
    prop: 'columns',
  },
  props: {
    columns: {
      type: Object,
      required: true,
    },
    position: {
      type: String,
      default: 'is-bottom-left',
    },
  },
  data() {
    return {
      search: '',
      isOpen: false,
    };
  },
  computed: {
    columnsFiltered() {
      const filtersToApply = { canToggleColumn: true };

      if (this.search) filtersToApply.searchByLabelName = this.search;

      return this.filterEngine(Object.values(this.columns), filtersToApply);
    },

    _filterCollection() {
      return {
        canToggleColumn() {
          return (m) => (m.visible === true || m.visible === false ? m : undefined);
        },
      };
    },

    hasChange() {
      return !!Object.values(this.columns).find(({ visible }) => visible === false);
    },
  },
  methods: {
    reset() {
      this.search = '';

      const columns = Object.values(this.columns);
      const toEmit = { ...this.columns };

      columns.forEach((col) => {
        // care some col are not tooglelable so we cant col.visible = true
        // on every col, just switch to true each col.visible with false value
        if (col.visible === false) toEmit[col.field].visible = true;
      });

      this.$emit('input', toEmit);
    },
  },
};
</script>
