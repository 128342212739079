<template>
  <section class="ubuSettingView">
    <h1 class="title is-2">Extensions</h1>

    <br>

    <!-- <UbuGoogleDriveConnectRow class="p-3" />

    <hr> -->

    <UbuPrivateConnectRow
      class="p-3"
      :extension-state="ubuExtensionState"
      @contactSupport="contactSupport($event)"
    />

    <hr>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSettingExtension',
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      isPrivateDisconnected: 'TheSettingWorkspaceSetting/isPrivateDisconnected',
      accountHasMetaExtension: 'TheSettingWorkspaceSetting/accountHasMetaExtension',
      currentChannelGroupCanUseMetaExtension: 'TheSettingWorkspaceSetting/currentChannelGroupCanUseMetaExtension',
      accountFeatures: 'TheSettingWorkspaceSetting/accountFeatures',
    }),

    ubuExtensionState() {
      // doesnt have extension
      if (!this.accountHasMetaExtension) return 'NO_EXTENSION';
      // has extension but no private token
      if (this.currentChannelGroupCanUseMetaExtension && this.isPrivateDisconnected === true) return 'DISCONNECTED';
      // has extension and private token
      if (this.currentChannelGroupCanUseMetaExtension && this.isPrivateDisconnected === false) return 'CONNECTED';

      return 'NO_EXTENSION';
    },
  },
  methods: {
    ...mapActions({
      emitSegmentEvent: 'TheSettingWorkspaceSetting/emitSegmentEvent',
    }),

    contactSupport(extensionName) {
      if (extensionName === 'Ubu\'s Instagram') this.emitSegmentEvent({ event: 'connectPrivateNoFeature' });

      this.$intercom.showNewMessage(`Hi! I would like to activate the ${extensionName} module! 🙌`);
    },
  },
};
</script>
