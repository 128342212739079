// import moment from 'moment';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    detailledLeadList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sales/reportDetailledLead/getterDetailledLeadList': reportDetailledLead } = rootGetters;

      return reportDetailledLead;
    },
    assigneds: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sales/reportDetailledLead/getterDetailledLeadList': reportDetailledLead } = rootGetters;

      const assigneds = Object.values(reportDetailledLead)
        .reduce((acc, { lid, assignedTo }) => (assignedTo && assignedTo.length ? [
          ...acc,
          ...assignedTo.map(({ userUid, assignedOn }) => ({ userUid, leadLid: lid, assignedOn })),
        ] : acc), []);

      return assigneds;
    },
  },
  actions: {
    reportDetailledLead({ dispatch }, httpQuery) {
      const action = {
        dispatch,
        target: 'Sales/reportDetailledLead/reportDetailledLeads',
        context: { httpQuery },
        textNotifier: 'fetch leads',
        retry: {
          onSuccess: true,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report Detailed Leads.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
  },
};
