<template>
  <div>
    <div class="multibar">
      <h2 class="start title is-2">Do you want to relate it to one of these account ?</h2>
    </div>

    <br>
    <b-field>
      <b-radio
        v-model="newChannel.contactCid"
        name="contactPlatform"
        :native-value="null"
      >
        No, thanks !
      </b-radio>
    </b-field>
    <b-field
      v-for="contactPlatform in allContactPlatforms"
      :key="contactPlatform.cgid"
    >
      <b-radio
        v-model="newChannel.contactCid"
        name="contactPlatform"
        :native-value="Number(contactPlatform.contactCid)"
      >
        <div class="is-flex is-align-items-center">
          <component
            :is="`UbuIcon${contactPlatform.platform.capitalize()}`"
            font-size="16px"
          />
          <span class="ml-2">{{ contactPlatform.username }}</span>
        </div>
      </b-radio>
    </b-field>
  </div>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuAccountAddChannelStepContactForm',
  mixins: [VModelMixin],
  model: {
    prop: 'newChannel',
  },
  props: {
    allContactPlatforms: { type: Array, required: true },
    newChannel: { type: Object, default: () => {} },
  },
};
</script>
