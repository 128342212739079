<template>
  <fieldset>
    <b-field :label="type">
      <b-input
        ref="focusIt"
        v-model="custom.label"
        :placeholder="custom.label"
        @input="$update('label', $event)"
      />
    </b-field>
    <b-field>
      <UbuColorPickerInline
        v-model="custom.color"
        @input="$update('color', $event.toString())"
      />
    </b-field>
  </fieldset>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuEditCustomForm',
  mixins: [VModelMixin],
  model: {
    prop: 'custom',
  },
  props: {
    custom: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$refs.focusIt.focus();
  },
};
</script>
