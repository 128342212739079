<template>
  <fieldset>
    <b-field
      horizontal
      label="email"
    >
      <b-input
        v-model="authenticate.passName"
        type="email"
        required
        @input="$update('passName', $event)"
      />
    </b-field>

    <b-field
      label="password"
      horizontal
    >
      <b-field>
        <p class="control">
          <b-button
            type="is-dark"
            icon-right="sync"
            @click="onPasswordChange()"
          />
        </p>
        <b-input
          v-model="authenticate.passPhrase"
          required
          minlength="8"
          disabled
          expanded
          class="generated-password"
          @input="$update('passPhrase', $event)"
        />
        <p class="control">
          <b-button
            v-clipboard:copy="authenticate.passPhrase"
            v-clipboard:success="onCopy"
            type="is-link"
            icon-right="content-copy"
          />
        </p>
      </b-field>
    </b-field>
  </fieldset>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuKaraUserAuthenticateForm',
  mixins: [VModelMixin],
  model: {
    prop: 'authenticate',
  },
  props: {
    authenticate: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onCopy() {
      this.$buefy.snackbar.open({
        message: 'Copied to clipboard.',
        type: 'is-success',
        position: 'is-top',
        duration: 5000,
      });
    },
    onPasswordChange() {
      this.$update('passPhrase', uuidv4());
    },
  },
};
</script>
