var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`ubuAvatar imageStatus size-${_vm.isSize}`,on:{"click":function($event){_vm.clickable ? _vm.$emit('click', $event) : null}}},[(_vm.profilePicture && !_vm.errorPicture && !_vm.isSelected)?_c('b-image',{class:['ubuUserPicture ubu-rounded', {
      'is-16x16': _vm.isSize === 16,
      'is-18x18': _vm.isSize === 18,
      'is-24x24': _vm.isSize === 24,
      'is-32x32': _vm.isSize === 32,
      'is-40x40': _vm.isSize === 40,
      'is-48x48': _vm.isSize === 48,
      'is-64x64': _vm.isSize === 64,
      'is-96x96': _vm.isSize === 96,
      'is-128x128': _vm.isSize === 128,
    }, {'is-clickable': _vm.clickable}],attrs:{"custom-class":`image is-rounded ${_vm.classSize}`,"src":_vm.profilePicture,"alt":`${_vm.initialName.capitalize()}'s profile picture`},on:{"error":function($event){_vm.errorPicture = true}}}):(_vm.isSelected)?_c('b-image',{class:['ubuUserPicture', {'is-clickable': _vm.clickable}],style:(`width: ${_vm.size}px; height: ${_vm.size}px;`),attrs:{"custom-class":"image is-rounded is-selected","ratio":"1by1","src":_vm.selectedImgLink,"alt":`${_vm.initialName.capitalize()}'s profile picture`},on:{"error":function($event){_vm.errorPicture = true}}}):(_vm.isChannel)?_c('div',{class:['ubuUserPicture ubu-rounded', {'is-clickable': _vm.clickable}]},[_c('div',{class:['avatar', `bg-color-${_vm.color}-filled`, `workspace-bg workspace-bg-${_vm.randomAvatar}`],style:(`width: ${_vm.size}px; height: ${_vm.size}px; font-size: ${_vm.size * .4}px`)},[_vm._v(" "+_vm._s(_vm.initialName)+" ")])]):_c('b-image',{class:['ubuUserPicture ubu-rounded', {
      'is-16x16': _vm.isSize === 16,
      'is-18x18': _vm.isSize === 18,
      'is-24x24': _vm.isSize === 24,
      'is-32x32': _vm.isSize === 32,
      'is-40x40': _vm.isSize === 40,
      'is-48x48': _vm.isSize === 48,
      'is-64x64': _vm.isSize === 64,
      'is-96x96': _vm.isSize === 96,
      'is-128x128': _vm.isSize === 128,
    }, {'is-clickable': _vm.clickable}],style:(`width: ${_vm.size}px; height: ${_vm.size}px; font-size: ${_vm.size * .4}px`),attrs:{"custom-class":`image is-rounded ${_vm.classSize}`,"src":require(`@/assets/ubu_avatar_fallback/avatar_image_${_vm.randomAvatar}.png`),"alt":"Avatar profile picture"}}),(_vm.endedSticker)?_c('UbuIconCircleStatus',{attrs:{"is-active":_vm.isActive}}):_vm._e(),(_vm.platformName)?_c('div',{staticClass:"iconPlatform"},[_c(`UbuIcon${_vm.platformName.capitalize()}`,{tag:"component"})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }