<template>
  <section>
    <UbuSearchbar
      v-model="search"
      placeholder="Search template..."
      @clear="search = ''"
    />

    <br>

    <b-tabs
      v-model="selectedFolderTfid"
      class="ubuTabs paddingless"
      destroy-on-hide
      :animated="false"
      type="is-toggle-rounded"
    >
      <b-tab-item
        label="All"
        class="tab-content"
        :value="'0'"
      >
        <TheTemplateManagerSimpleTable
          :datas="customList"
          @editTemplate="editTemplate($event)"
        />
      </b-tab-item>
      <template v-for="(templateFolder, index) in templateFolderList">
        <b-tab-item
          :key="index"
          :label="templateFolder.label"
          class="tab-content"
          :value="String(templateFolder.tfid)"
        >
          <TheTemplateManagerSimpleTable
            :datas="customList"
            @editTemplate="editTemplate($event)"
          />
        </b-tab-item>
      </template>
    </b-tabs>

    <TheSettingAddTemplate
      v-if="openPanelAddTemplate"
      :closing-way="closingWay"
      @close="$emit('close')"
    />

    <TheSettingEditTemplate
      v-if="openPanelEditTemplate"
      v-model="editableTemplate"
      @close="openPanelEditTemplate = false"
    />
    <div
      v-if="openPanelEditTemplate"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';
import ColumnsMixin from './$mixins/TheTemplateManagerSimple.columns.mixins';

export default {
  name: 'TheTemplateManagerSimple',
  mixins: [FilterEngineMixin, ColumnsMixin],
  props: {
    openPanelAddTemplate: {
      type: Boolean,
      default: false,
    },
    closingWay: {
      type: String,
      default: 'route',
    },
  },
  data() {
    return {
      selectedTid: null,
      selectedFolderTfid: '0',
      search: '',
      openPanelEditTemplate: false,
    };
  },
  computed: {
    ...mapGetters({
      templateList: 'TheTemplateManager/templateList',
      templateFolderList: 'TheTemplateManager/templateFolderList',
    }),

    _filterCollection() {
      return {
        /**
       * @method  search Count
       * @params  {Number}   rawMax  maximum to be compared to
       * @returns {Function}
       * */
        searchByTitleOrText(rawSearch) {
          const search = rawSearch.toLowerCase();

          const _searchByTitle = (raw) => {
            const title = raw.toLowerCase();
            return title.includes(search);
          };

          const _searchByText = (raw) => {
            const text = raw.toLowerCase();
            return text.includes(search);
          };
          return (m) => (_searchByTitle(m.title) || _searchByText(m.text) ? m : undefined);
        },
      };
    },
    customList() {
      const templateList = Object.values(this.templateList)
        .map((r) => ({
          ...r,
          folderName: this.templateFolderList[r.templateFolderTfid]
            ? this.templateFolderList[r.templateFolderTfid].label
            : null,
        }));

      if (!templateList || !templateList.length) return [];

      // console.log('🚀 ~ file: TheTemplateManagerSimple.vue ~ line 140 ~ customList ~ this.selectedFolderTfid', this.selectedFolderTfid);
      const filtersToApply = {
        // filterByTemplateFolder: this.selectedFolderTfid,
      };
      if (this.search) filtersToApply.searchByTitleOrText = this.search;
      if (this.selectedFolderTfid !== '0') filtersToApply.filterByTemplateFolder = this.selectedFolderTfid;

      return this.filterEngine(templateList, filtersToApply)
        .sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
    },

    editableTemplate() {
      return { ...this.templateList[this.selectedTid] };
    },
  },
  methods: {
    editTemplate(event) {
      this.selectedTid = event.tid;
      this.openPanelEditTemplate = true;
    },
  },
};
</script>
