import moment from 'moment';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { v4 as uuidv4 } from 'uuid';

/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {
    user: {
      isUbu: true,
      extra: null,
    },
    userDetail: {
      firstName: '',
      lastName: '',
    },
    authenticate: {
      passName: '',
      passPhrase: uuidv4(),
      method: 'UBU',
    },
  },
  mutations: {
    UP_USER_DETAIL(state, newData) { state.userDetail = newData; },
    UP_AUTHENTICATE(state, newData) { state.authenticate = newData; },
  },
  getters: {
    getterUser: (state) => state.user,
    getterUserDetail: (state) => state.userDetail,
    getterAuthenticate: (state) => state.authenticate,
  },
  actions: {
    up_userDetail({ commit }, newData) { commit('UP_USER_DETAIL', newData); },
    up_authenticate({ commit }, newData) { commit('UP_AUTHENTICATE', newData); },
    createUser({ dispatch, getters, commit }) {
      const payload = {
        ...getters.getterUser,
        userDetail: getters.getterUserDetail,
        authenticate: [
          {
            ...getters.getterAuthenticate,
            createdOn: moment().format(),
            updatedOn: moment().format(),
            allowedOn: null,
            revokedOn: null,
          },
        ],
      };
      return dispatch('User/post', payload, { root: true })
        .then(() => {
          // reset form
          commit('UP_USER_DETAIL', {
            firstName: '',
            lastName: '',
          });

          commit('UP_AUTHENTICATE', {
            passName: '',
            passPhrase: uuidv4(),
            method: 'UBU',
          });

          Snackbar.open({
            message: 'Account successfully created.',
            type: 'is-success',
            position: 'is-top',
            duration: 5000,
          });
        })
        .catch(() => {
          Snackbar.open({
            message: 'Unable to create Account.',
            type: 'is-danger',
            position: 'is-top',
            duration: 5000,
          });
        });
    },
  },
  modules: {},
};
