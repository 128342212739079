<template>
  <div>
    <div
      :class="['ubuTextarea has-padding-s', {
        'is-focus': isTextareaFocus,
      }]"
    >
      <div class="ubuTextareaTop">
        <div
          v-if="localMessage.includes('/') && filteredTemplateList"
          class="templateListRelative"
        >
          <div class="templateList">
            <b-tag
              v-for="(template, index) in filteredTemplateList"
              :key="index"
              type="is-light"
              class="mr-1"
              @click.native="replaceTemplateTitle(template);"
            >
              /{{ template.title }}
            </b-tag>
          </div>
        </div>
      </div>

      <textarea-autosize
        ref="textarea"
        v-model.trim="localMessage"
        placeholder="Start typing or enter ‘/’ to load your templates..."
        class="text is-3 "
        maxlength="1000"
        @keyup.space.native="fillTemplate()"
        @focus.native="isTextareaFocus = true"
        @blur.native="isTextareaFocus = false"
      />

      <div class="multibar row-vcentered ubuTextareaBottomTools">
        <TheTextareaEmojiButton
          class="start"
          :dropdown-position="dropdownPosition.emoji"
          @insertText="insertText($event)"
        />

        <TheTemplateDropdown
          class="start"
          :position="dropdownPosition.template"
          @insertText="insertText($event)"
          @openTemplatePanel="showTemplateManager = true; up_textImported(message)"
        />

        <b-button
          class="start"
          type="is-primary"
          :label="`\{{ Invite on Crew \}}`"
          @click="insertCrewUserText(`\{{ Invite on Crew \}}`)"
        />

        <b-button
          class="end"
          type="is-primary"
          :label="buttonLabel"
          :disabled="!localMessage.length"
          @click="$emit('next')"
        />
      </div>
    </div>

    <TheSettingAddTemplate
      v-if="showTemplateManager"
      closing-way="emit"
      @close="showTemplateManager = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TheTextareaTemplateButtonMixin from '@dailyplanet/selina-addons/textarea/_mixins/TheTextareaTemplateButton.mixin';
import { snackbarError } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheCustomisableTextarea',
  mixins: [TheTextareaTemplateButtonMixin],
  model: {
    prop: 'message',
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    buttonLabel: {
      type: String,
      default: 'Next',
    },
    textareaHeight: {
      type: Number,
      default: 800,
    },
    dropdownPosition: {
      type: Object,
      default: () => ({
        emoji: 'is-bottom-left',
        template: 'is-bottom-left',
      }),
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTemplateManager: false,
      isTextareaFocus: false,
    };
  },
  computed: {
    ...mapGetters({
      accountFeatures: 'TheInbox/accountFeatures',
      _currentBrand: 'TheSettingWorkspaceSetting/currentBrand',
    }),

    localMessage: {
      get() { return this.message; },
      set(newValue) { this.$emit('input', newValue.trim()); },
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.textarea.$el.focus();
    }
  },
  methods: {
    ...mapActions({
      up_textImported: 'TheAddTemplatePanel/up_textImported',
    }),

    insertCrewUserText(text) {
      if (!this.accountFeatures('CREW')) {
        return this.$buefy.snackbar.open({
          ...snackbarError,
          message: 'This feature will coming soon. Contact support to have more informations',
        });
      }

      if (!this._currentBrand) {
        return this.$buefy.snackbar.open({
          message: 'You must create your brand to use this feature.',
          position: 'is-top',
          duration: 8000,
          type: 'is-warning',
          actionText: 'Create my Brand!',
          onAction: () => { this.$router.push('/crew/crewSetting'); },
        });
      }

      return this.insertText(text);
    },

    insertText(text) {
      const { textarea } = this.$refs;
      const trimedText = text.trim();
      const len = this.localMessage.length;
      if (len + trimedText.length > 1000) return;
      let pos = textarea.$el.selectionStart;
      if (pos === undefined) pos = 0;
      const before = this.localMessage.substr(0, pos);
      const after = this.localMessage.substr(pos, len);
      this.localMessage = before + trimedText + after;
      this.$nextTick(() => {
        textarea.$el.focus();
        textarea.$el.selectionStart = pos + trimedText.length;
        textarea.$el.selectionEnd = pos + trimedText.length;
      });
    },
  },
};
</script>
