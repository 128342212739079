<template>
  <section>
    <b-field
      v-for="option in allowedOperators"
      :key="option.value"
      class="mt-3"
    >
      <b-radio
        v-model="localOperator"
        :native-value="option.value"
      >
        {{ option.label }}
      </b-radio>
    </b-field>
  </section>
</template>

<script>
export default {
  name: 'UbuFiltersSelectOperator',
  model: {
    prop: 'operator',
  },
  props: {
    operator: {
      type: String,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    localOperator: {
      get() { return this.operator; },
      set(val) { this.$emit('input', val); },
    },
    allowedOperators() {
      return this.options.filter(({ value }) => this.filter.allowedOperators.includes(value));
    },
  },
};
</script>
