// import moment from 'moment';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    salesUserList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sales/salesUser/getterSalesUserList': getterSalesUserList } = rootGetters;

      return getterSalesUserList;
    },
  },
  actions: {
    reportSalesUser({ dispatch }) {
      const action = {
        dispatch,
        target: 'Sales/salesUser/report',
        context: {},
        textNotifier: 'fetch sales users',
        retry: {
          onSuccess: true,
          onFailure: true,
        },
      };
      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report Sales Users.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
  },
};
