<template>
  <b-table
    ref="table"
    :data="filteredLeadList"
    paginated
    :per-page="pagin"
    pagination-size="is-small"
    pagination-position="both"
    :opened-detailed="defaultOpenedDetails"
    :loading="loading"
    detailed
    detail-key="lid"
    :show-detail-icon="true"
    checkable
    :checked-rows="checkedRows"
    :default-sort="['username', 'lid']"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    sticky-header
    :debounce-search="1000"
    @check="$emit('checkRow', $event)"
    @check-all="$emit('checkAllRows', $event)"
  >
    <b-table-column
      v-slot="props"
      field="picture"
      label="picture"
      :visible="columns.username.visible"
      header-class="is-contact"
      cell-class="is-contact"
    >
      <b-image
        v-if="props.row.picture && !isNaN(props.row.picture)"
        width="100px"
        :src="'https://io.getubu.com/profile_pic/' + props.row.picture"
        lazy
      />
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      sortable
      :visible="columns.username.visible"
      searchable
      field="username"
      label="username"
      header-class="is-contact"
      cell-class="is-contact"
    >
      {{ props.row.username }}
    </b-table-column>

    <b-table-column
      centered
      sortable
      :visible="columns.createdOn.visible"
      field="contactCreatedOn"
      label="created on"
      header-class="is-contact"
      cell-class="is-contact"
    >
      <template v-slot="props">
        {{ $moment(props.row.contactCreatedOn).format('YY-MM-DD') }}
      </template>
    </b-table-column>

    <b-table-column
      centered
      sortable
      :visible="columns.updatedOn.visible"
      field="contactUpdatedOn"
      label="updated on"
      header-class="is-contact"
      cell-class="is-contact"
    >
      <template v-slot="props">
        {{ $moment(props.row.contactUpdatedOn).format('YY-MM-DD') }}
      </template>
    </b-table-column>

    <b-table-column
      centered
      sortable
      :visible="columns.engagement.visible"
      field="engagement"
      label="engagement"
      header-class="is-contact"
      cell-class="is-contact"
    >
      <template v-slot="props">
        {{ props.row.engagement }}
      </template>
    </b-table-column>

    <b-table-column
      centered
      sortable
      :custom-sort="sortInt8('followers')"
      :visible="columns.followers.visible"
      field="followers"
      label="followers"
      header-class="is-contact"
      cell-class="is-contact"
    >
      <template v-slot="props">
        {{ props.row.followers }}
      </template>
    </b-table-column>

    <b-table-column
      centered
      sortable
      :visible="columns.growth.visible"
      field="growth"
      label="growth"
      header-class="is-contact"
      cell-class="is-contact"
    >
      <template v-slot="props">
        {{ props.row.growth }}
      </template>
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      sortable
      :visible="columns.zone.visible"
      field="zone"
      label="zone"
      header-class="is-contact"
      cell-class="is-contact"
    >
      {{ props.row.zone }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      sortable
      :visible="columns.language.visible"
      field="language"
      label="language"
      header-class="is-contact"
      cell-class="is-contact"
    >
      {{ props.row.language }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      sortable
      :visible="columns.category.visible"
      searchable
      field="category"
      label="category"
      header-class="is-contact"
      cell-class="is-contact"
    >
      {{ props.row.category }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      sortable
      :visible="columns['scraping zone'].visible"
      searchable
      field="scrapingZoneSzid"
      label="scraping zone"
      header-class="is-contact"
      cell-class="is-contact"
    >
      {{ props.row.scrapingZoneSzid }}
    </b-table-column>

    <b-table-column
      centered
      sortable
      searchable
      :visible="columns.assigned.visible"
      field="assignedTo"
      label="assigned to"
      header-class="is-lead"
      cell-class="is-lead"
    >
      <template
        #searchable
      >
        <b-input
          v-model="assignee"
          size="is-small"
        />
      </template>
      <template v-slot="props">
        <b-taglist>
          <b-tag
            v-for="(sale, index) in props.row.assignedTo"
            :key="index"
            type="is-info"
            closable
            aria-close-label="Close tag"
            @close="isTag1Active = false"
          >
            {{ sale.firstName }} on {{ $moment(sale.assignedOn).format('YY-MM-DD') }}
          </b-tag>
        </b-taglist>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      sortable
      :custom-sort="sortInt8('lid')"
      :visible="columns.lid.visible"
      searchable
      field="lid"
      label="lead id"
      header-class="is-lead"
      cell-class="is-lead"
    >
      {{ props.row.lid }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      sortable
      :visible="columns.firstName.visible"
      searchable
      field="firstName"
      label="first name"
      header-class="is-lead"
      cell-class="is-lead"
    >
      {{ props.row.firstName }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      sortable
      :visible="columns.lastName.visible"
      searchable
      field="lastName"
      label="last name"
      header-class="is-lead"
      cell-class="is-lead"
    >
      {{ props.row.lastName }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      sortable
      :visible="columns.position.visible"
      searchable
      field="position"
      label="position"
      header-class="is-lead"
      cell-class="is-lead"
    >
      {{ props.row.position }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      sortable
      :visible="columns.email.visible"
      searchable
      field="email"
      label="email"
      header-class="is-lead"
      cell-class="is-lead"
    >
      {{ props.row.email }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      sortable
      :visible="columns.phone.visible"
      searchable
      field="phone"
      label="phone"
      header-class="is-lead"
      cell-class="is-lead"
    >
      {{ props.row.phone }}
    </b-table-column>

    <b-table-column
      centered
      sortable
      :visible="columns.leadCreatedOn.visible"
      field="leadCreatedOn"
      label="created on"
      header-class="is-lead"
      cell-class="is-lead"
    >
      <template v-slot="props">
        {{ $moment(props.row.leadCreatedOn).format('YY-MM-DD') }}
      </template>
    </b-table-column>

    <b-table-column
      centered
      sortable
      :visible="columns.leadUpdatedOn.visible"
      field="leadUpdatedOn"
      label="updated on"
      header-class="is-lead"
      cell-class="is-lead"
    >
      <template v-slot="props">
        {{ $moment(props.row.leadUpdatedOn).format('YY-MM-DD') }}
      </template>
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      field="action"
      label="action"
    >
      <b-button
        type="is-danger"
        icon-right="delete"
        @click="$emit('removeLead', props.row.lid)"
      />
    </b-table-column>

    <template #detail="props">
      <UbuLeadsDashboardTableDetail
        v-model="props.row"
        @updateLead="$emit('updateLead', $event)"
      />
    </template>

    <!-- <template #bottom-left>
      <div class="multibar column">
        <DevDetails :cnt="{name: 'checkedRows', obj: checkedRows}" />
      </div>
    </template> -->
  </b-table>
</template>

<script>
export default {
  name: 'UbuLeadsDashboardTable',
  props: {
    ubuLeadList: {
      type: Array,
      required: true,
    },
    checkedRows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    pagin: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      defaultOpenedDetails: [],
      assignee: '',
    };
  },
  computed: {
    filteredLeadList() {
      let filtered = this.ubuLeadList;

      if (this.assignee.length && this.assignee.length > 3) {
        filtered = filtered.filter((row) => {
          const matches = (row.assignedTo || [])
            .flatMap(({ firstName, lastName }) => ([
              firstName.toLowerCase(), lastName.toLowerCase(),
            ]))
            .filter((names) => names.includes(this.assignee.toLowerCase()));

          return matches.length ? row : undefined;
        });
      }

      return filtered;
    },
  },
  methods: {
    sortInt8: (key) => (a, b, isAsc) => (isAsc
      ? Number(a[key]) > Number(b[key])
      : Number(b[key]) > Number(a[key])),
    sortInt8_nested: (key, nestedKey) => (a, b, isAsc) => (isAsc
      ? Number(a[key][nestedKey]) > Number(b[key][nestedKey])
      : Number(b[key][nestedKey]) > Number(a[key][nestedKey])),
    convertUidIntoSaleName(uid) {
      switch (uid) {
        case 5: return 'Clément';
        case 6: return 'Jiangshan';
        case 7: return 'Chava';

        default: return '???';
      }
    },
  },
};
</script>
