<template>
  <TheTabsPanel
    v-model="localActiveTab"
    @goToPreviousTab="goToPreviousVisitedTab($event)"
    @goToStart="UP_resetNavigation(); goToTabByTabPath('root')"
    @close="closePanel()"
  >
    <template v-slot:content>
      <b-tab-item value="root">
        <div class="buttons">
          <UbuActionCard
            v-for="item in navigableItems"
            :key="item.label"
            class="mb-4"
            :label="item.label"
            icon-pack="ubu"
            :icon-left="item.icon"
            expanded
            :disabled="item.isDisabled"
            @click="handleNavigation(item)"
          />
        </div>
      </b-tab-item>

      <TheNewAddToCampaign
        v-model="localChecked"
        :active-tab="activeTab"
        :tab-items="tabItems"
        @update:activeTab="UP_changeToNewTab($event)"
      />

      <TheAddToLabel
        v-model="localChecked"
        :active-tab="activeTab"
        :tab-items="tabItems"
      />

      <TheAddToCrmList
        v-model="localChecked"
        :active-tab="activeTab"
        :tab-items="tabItems"
        @update:activeTab="UP_changeToNewTab($event)"
        @done="UP_resetNavigation(); goToTabByTabPath('root')"
      />

      <TheAddToTribe
        v-model="localChecked"
        :active-tab="activeTab"
        :tab-items="tabItems"
        @update:activeTab="UP_changeToNewTab($event)"
      />
    </template>
  </TheTabsPanel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheCRMBulk',
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      activeTab: 'TheCRMBulk/activeTab',
      _checkedRows: 'TheCRMBulk/checkedRows',
      tabItems: 'TheCRMBulk/tabItems',

      accountFeatures: 'TheCRM/accountFeatures',
    }),

    localActiveTab: {
      get() { return this.activeTab; },
      set(newValue) { this.UP_activeTab(newValue); },
    },

    localChecked: {
      get() { return this._checkedRows; },
      set(newValue) {
        this.UP_checkedRows(newValue);
        this.$emit('input', newValue);
      },
    },

    navigableItems() {
      return {
        'root.addToCampaign': {
          label: 'Add to campaign',
          to: 'root.addToCampaign',
          icon: 'ubu-influence',
          requiredFeature: 'CAMPAIGNS',
        },
        'root.label': {
          label: 'Add to contact label',
          to: 'root.label',
          icon: 'ubu-contact',
        },
        'root.addToCrmList': {
          label: 'Add to list',
          to: 'root.addToCrmList',
          icon: 'ubu-lists',
          requiredFeature: 'CRM',
        },
        'root.addToTribe': {
          label: 'Add to tribe',
          to: 'root.addToTribe',
          icon: 'ubu-tribe',
          // requiredFeature: 'CRM',
        },
      };
    },
  },
  watch: {
    checkedRows() {
      this.UP_checkedRows(this.checkedRows);
    },
  },
  mounted() {
    return Promise.resolve()
      .then(() => this.UP_resetStore())
      .then(() => this.UP_checkedRows(this.checkedRows));
  },
  methods: {
    ...mapActions({
      UP_checkedRows: 'TheCRMBulk/UP_checkedRows',
      UP_activeTab: 'TheCRMBulk/UP_activeTab',
      UP_resetStore: 'TheCRMBulk/UP_resetStore',
      UP_resetNavigation: 'TheCRMBulk/UP_resetNavigation',
      goToPreviousVisitedTab: 'TheCRMBulk/goToPreviousVisitedTab',
      goToTabByTabPath: 'TheCRMBulk/goToTabByTabPath',
      UP_changeToNewTab: 'TheCRMBulk/UP_changeToNewTab',
      emitSegmentEvent: 'TheCRMBulk/emitSegmentEvent',
    }),

    closePanel() {
      this.UP_resetStore();
      this.$emit('close');
    },

    handleNavigation(item) {
      if (!item.requiredFeature) return this.UP_activeTab(this.tabItems[item.to]);

      if (this.accountFeatures(item.requiredFeature)) {
        return this.UP_activeTab(this.tabItems[item.to]);
      }

      if (item.requiredFeature === 'CAMPAIGNS') this.emitSegmentEvent({ event: 'addToCampaignNoFeature' });
      if (item.requiredFeature === 'CRM') this.emitSegmentEvent({ event: 'addToCRMListNoFeature' });

      // eslint-disable-next-line max-len
      this.$intercom.showNewMessage(`Hi! I would like to activate the ${item.requiredFeature} module! 🙌`);
      return null;
    },
  },
};
</script>
