import moment from 'moment';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import {
  dispatchAndForward,
  dispatchFailure,
  dispatchComment,
  snackBarFailure,
} from '../$utils/helpers';

export default {
  getters: {
    salesContactList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sales/salesContact/getterSalesContactList': contacts } = rootGetters;

      return contacts;
    },
  },
  actions: {
    reportSalesContact({ dispatch }, httpQuery) {
      const action = {
        dispatch,
        target: 'Sales/salesContact/report',
        context: { httpQuery },
        textNotifier: 'report sales contacts',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report SalesContact.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },

    searchContactByUsername({ dispatch }, username) {
      const action = {
        dispatch,
        target: 'Sales/salesContact/report',
        context: { httpQuery: { findUsername: username } },
        textNotifier: 'search contact by username',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to search by username.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },

    createContact({ dispatch }, contact) {
      const {
        leads,
        username,
        category,
        engagement,
        followers,
        growth,
        isVerified,
        picture,
        platformName,
        scrapingZoneSzid,
        zone,
        language,
      } = contact;

      const payload = {
        username,
        category,
        engagement,
        followers,
        growth,
        isVerified,
        picture,
        platformName,
        scrapingZoneSzid,
        origin: 'MANUAL',
        zone: zone && zone.length ? zone : null,
        language: language && language.length ? language : null,
        createdOn: moment().format(),
        updatedOn: moment().format(),
      };
      if (leads && leads.length) {
        payload.leads = leads.map((lead) => ({
          ...lead,
          createdOn: moment().format(),
          updatedOn: moment().format(),
        }));
      }

      const action = {
        dispatch,
        target: 'Sales/salesContact/create',
        context: { payload },
        textNotifier: 'create contact',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .then(({ response: createdContact }) => createdContact)
        .catch((e) => {
          if (e.message === 'E_NOT_FOUND_SALES_CONTACT_SCRAPINGZONESZID') {
            Snackbar.open({
              message: 'Unable to create Contact. <br> Scraping Zone does not exists',
              ...snackBarFailure,
              indefinite: true,
            });

            dispatchComment({ ...action, comment: 'Unable to create Contact. Scraping Zone does not exists' });
          } else {
            Snackbar.open({
              message: 'Unable to create Contact.',
              ...snackBarFailure,
              indefinite: true,
            });
          }

          return dispatchFailure(action);
        });
    },

    updateContact({ dispatch }, contact) {
      const {
        username,
        category,
        engagement,
        followers,
        growth,
        isVerified,
        picture,
        platformName,
        scrapingZoneSzid,
        zone,
        language,
        origin,
        createdOn,
      } = contact;

      const payload = {
        username,
        category,
        engagement,
        followers,
        growth,
        isVerified,
        picture,
        platformName,
        scrapingZoneSzid,
        origin,
        createdOn,
        zone: zone && zone.length ? zone : null,
        language: language && language.length ? language : null,
        updatedOn: moment().format(),
      };

      const action = {
        dispatch,
        target: 'Sales/salesContact/update',
        context: { payload },
        textNotifier: 'update contact',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .then(({ response: updatedContact }) => updatedContact)
        .catch((e) => {
          if (e.message === 'E_NOT_FOUND_SALES_CONTACT_SCRAPINGZONESZID') {
            Snackbar.open({
              message: 'Unable to update Contact. <br> Scraping Zone does not exists',
              ...snackBarFailure,
              indefinite: true,
            });

            dispatchComment({ ...action, comment: 'Unable to update Contact. Scraping Zone does not exists' });
          } else {
            Snackbar.open({
              message: 'Unable to update Contact.',
              ...snackBarFailure,
              indefinite: true,
            });
          }

          return dispatchFailure(action);
        });
    },
  },
};
