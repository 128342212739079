import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  actions: {
    create({ rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CHANNEL.CREATE')) return undefined;

      return clark.api.channelGroup.channel.create({ payload, httpQuery });
    },
    update({ rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CHANNEL.UPDATE')) return undefined;

      return clark.api.channelGroup.channel.update({ payload, httpQuery });
    },
  },
};
