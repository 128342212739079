<template>
  <div>
    <div class="buttons is-right">
      <b-button
        label="Finish"
        type="is-primary"
        @click="$emit('next')"
      />
    </div>

    <template v-for="(summary, index) in summaries">
      <div
        v-if="summary.contactPlatformList.length"
        :key="index"
      >
        <p class="multibar row-vcentered">
          <b-icon
            pack="ubu"
            icon="ubu-information"
            class="start"
          />
          <span class="start text">
            {{ summary.titleBefore }}
          </span>
        </p>

        <b-table
          :data="summary.contactPlatformList"
          paginated
          pagination-size="is-small"
        >
          <b-table-column
            v-slot="{ row }"
            field="username"
            label="Username"
            sortable
          >
            <UbuPlatformCard
              :platform-name="row.contactPlatform.platformName.toLowerCase()"
              :text="row.contactPlatform.username"
              font-size="20px"
            />
          </b-table-column>

          <template #empty>
            <p>{{ summary.emptyMessage }}</p>
          </template>
        </b-table>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TheAddToCampaignMixin from '../mixins/TheAddToCampaign.mixin';

export default {
  name: 'TheAddToCampaignSummary',
  mixins: [TheAddToCampaignMixin],
  computed: {
    ...mapGetters({
      alreadyInOtherCampaignAndWillBeMoved: 'TheNewAddToCampaign/alreadyInOtherCampaignAndWillBeMoved',
      instagramersToSend: 'TheNewAddToCampaign/instagramersToSend',
      removedToScan: 'TheNewAddToCampaign/removedToScan',
    }),
    summaries() {
      return [
        {
          titleBefore: 'People who will be removed from their past campaign',
          emptyMessage: 'No people will be removed from their current campaign',
          contactPlatformList: this.alreadyInOtherCampaignAndWillBeMoved,
        },
        {
          titleBefore: 'People who will be scheduled to receive a message',
          emptyMessage: 'You have selected no accounts to send message to.',
          contactPlatformList: this.instagramersToSend,
        },
        {
          titleBefore: 'People who will be added to campaign to scan mention',
          emptyMessage: 'You have selected no accounts to scan mentions from.',
          contactPlatformList: this.removedToScan,
        },
      ];
    },
  },
};
</script>
