<template>
  <b-dropdown
    ref="dropdown"
    v-model="httpQueryRow"
    aria-role="list"
    class="is-fullWidth"
    position="is-bottom-right"
    :disabled="disabled"
    @active-change="resetIfNoData()"
  >
    <template #trigger>
      <b-button
        v-if="httpQueryRow && httpQueryRow.length && (httpQueryRow[0] > 0 || httpQueryRow[1] > 0)"
        type="is-dashed"
        focused
      >
        {{ colDetail.label }}
      </b-button>

      <b-button
        v-else
        type="is-dashed"
      >
        {{ colDetail.label }}
      </b-button>
    </template>

    <b-dropdown-item
      aria-role="listitem"
      custom
    >
      <div>
        <b-field
          grouped
          class="no-shrink no-margin"
        >
          <b-field
            label="Minimum"
          >
            <b-input
              v-model="min"
              type="number"
              :min="canBeNegative ? '' : '0'"
              :step="step"
            />
          </b-field>

          <b-field label="Maximum">
            <b-input
              v-model="max"
              type="number"
              :step="step"
              :min="httpQueryRow[0]"
            />
          </b-field>
        </b-field>

        <br>

        <b-field grouped>
          <b-field expanded>
            <p class="control">
              <b-button
                expanded
                type="is-secondary"
                label="Reset"
                @click="$emit('reset')"
              />
            </p>
          </b-field>

          <b-field expanded>
            <p class="control">
              <b-button
                expanded
                type="is-primary"
                label="Done"
                @click="closeDropdown()"
              />
            </p>
          </b-field>
        </b-field>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>

export default {
  name: 'UbuCRMFilterNumberRange',
  model: {
    prop: 'httpQueryRow',
  },
  props: {
    httpQueryRow: {
      type: Array,
      required: true,
    },
    colDetail: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    step: {
      type: Number,
      default: 1,
    },
    canBeNegative: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    min: {
      get() { return this.httpQueryRow[0]; },
      set(val) {
        this.$emit('input', [val, this.httpQueryRow[1]]);
      },
    },
    max: {
      get() { return this.httpQueryRow[1]; },
      set(val) {
        this.$emit('input', [this.httpQueryRow[0], val]);
      },
    },
  },
  methods: {
    resetIfNoData() {
      /**
       * reset filter to [] if no data
       * cause if user delete value by keyboard
       * we will have ["", ""]
       */
      if (!this.httpQueryRow[0] && !this.httpQueryRow[1]) this.$emit('reset');
    },
    closeDropdown() {
      this.$refs.dropdown.isActive = false;
    },
  },
};
</script>
