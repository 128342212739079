/* eslint-disable max-len */
export default {
  data() {
    return {
      columns: {
        action: {
          col: 'action', label: 'Media', visible: true, isSortable: false, event: 'viewMedia', type: 'is-ghost is-borderless',
        },
        username: {
          col: 'username', label: 'Username', visible: true, isSortable: true, cmp: 'custom-username',
        },
        followerCount: {
          col: 'followerCount', label: 'Followers', visible: true, isSortable: true, cmp: 'shortenNumber',
        },
        // engagement: {
        //   col: 'engagement', label: 'Engagement', visible: true, isSortable: true, cmp: 'roundedNumber', symbol: '%',
        // },
        like: {
          col: 'likeCount', label: 'Likes', visible: true, isSortable: true, cmp: 'shortenNumber',
        },
        comment: {
          col: 'commentCount', label: 'Comments', visible: true, isSortable: true, cmp: 'shortenNumber',
        },
        // prints: {
        //   col: 'prints', label: 'Prints', visible: true, isSortable: true, cmp: 'shortenNumber',
        // },
        // views: {
        //   col: 'views', label: 'Views', visible: true, isSortable: true, cmp: 'shortenNumber',
        // },
        // language: {
        //   col: 'language', label: 'Language', visible: true, isSortable: true, cmp: 'flat',
        // },
        // location: {
        //   col: 'location', label: 'Location', visible: true, isSortable: true, cmp: 'flat', tooltip: true,
        // },
        // mentions: {
        //   col: 'mentionCount', label: 'Mentions', visible: true, isSortable: true, cmp: 'shortenNumber',
        // },
      },
    };
  },
};
