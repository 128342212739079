<template>
  <section>
    <b-field
      label="Select default workspace"
    >
      <div class="is-flex is-flex-direction-column mt-5">
        <template
          v-for="{ value, name } in cgids"
        >
          <div
            v-if="name"
            :key="name"
          >
            <b-radio
              v-model="localDefaultChannelGroupSelected"
              :name="name"
              :native-value="value"
              class=""
            >
              {{ name }}
            </b-radio>
          </div>
        </template>
      </div>
    </b-field>
  </section>
</template>

<script>
export default {
  name: 'UbuKaraDefaultChannelGroupForm',
  props: {
    defaultChannelGroupSelected: {
      type: Number,
      required: true,
    },
    cgids: {
      type: Array,
      required: true,
    },
  },
  computed: {
    localDefaultChannelGroupSelected: {
      get() { return this.defaultChannelGroupSelected; },
      set(newValue) { this.$emit('update:defaultChannelGroupSelected', newValue); },
    },
  },
};
</script>
