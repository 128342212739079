import { use, registerComponent } from '@dailyplanet/utils/plugins';

// const JsonReader = () => import(/* webpackChunkName: "modules" */ './JsonReader');
import JsonReader from './JsonReader';
import UbuAnimatedLogo from './UbuAnimatedLogo';
import UbuAsideMenu from './UbuAsideMenu';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, JsonReader);
    registerComponent(Vue, UbuAnimatedLogo);
    registerComponent(Vue, UbuAsideMenu);
  },
};

use(Plugin);

export default Plugin;

export {
  JsonReader,
  UbuAnimatedLogo,
  UbuAsideMenu,
};
