<template>
  <i
    :class="`${icon.pack} ${icon.icon}`"
    :style="`font-size: ${icon.fontSize}px;`"
  >
    <span
      v-for="path in icon.iconPaths"
      :key="path"
      :class="`path${path}`"
    />
  </i>
</template>

<script>
export default {
  name: 'UbuVectorIcon',
  props: {
    icon: {
      type: Object,
      required: true,
    },
  },
};
</script>
