<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        :to="{ path: `/admin/account/${currentAid}/details` }"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>
    <DevDetails :cnt="{name: 'newAccount', obj: newAccount}" />

    <UbuAccountDetailsEdit
      v-if="account"
      v-model="newAccount"
      :account="account"
      @updateAccount="updateAccount()"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheAdminAccountDetailsEdit',
  data() {
    return {
      newAccount: {
        state: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      account: 'TheAdmin/account',
    }),
    currentAid() {
      return this.$route.params.aid;
    },
  },
  methods: {
    ...mapActions({
      changeState: 'TheAdmin/changeState',
      up_changeState: 'TheAdmin/up_changeState',
    }),
    updateAccount() {
      const payload = { aid: this.account.aid, state: this.newAccount.state };

      this.changeState({ payload })
        .then(({ response: account }) => {
          if (account) {
            Promise.all([
              this.up_changeState({ payload }),
              this.newAccount.state = null,
              this.$router.push(`/admin/account/${this.currentAid}/details`),
            ]);
          }
        });
    },
  },
};
</script>
