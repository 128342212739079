<template>
  <div>
    <b-dropdown
      :position="dropdownPosition"
      class="textareaDropdown"
      @active-change="toggle($event)"
    >
      <template #trigger>
        <b-tooltip
          label="Affiliations"
          position="is-top"
        >
          <b-button
            v-if="!reachMode"
            icon-left="ubu-shop"
            icon-pack="ubu"
            type="is-tertiary-icon"
            @click="fetchShopifyCodeList()"
          />
        </b-tooltip>
      </template>

      <div class="textareaDropdown_list">
        <b-dropdown-item custom>
          <UbuSearchbar
            v-if="isOpenDropdown"
            v-model="search"
            @clear="search = ''"
          />
        </b-dropdown-item>

        <b-dropdown-item
          v-if="isLoading"
          aria-role="listitem"
        >
          <div
            v-for="i in 3"
            :key="i"
            class="skeletonAffiliation"
          >
            <b-skeleton
              width="20%"
              :animated="true"
            />
            <b-skeleton
              width="60%"
              :animated="true"
            />
            <br>
          </div>
        </b-dropdown-item>

        <template v-else>
          <b-dropdown-item
            v-for="(shopifyCode, index) in filteredShopifyCodeList"
            :key="index"
            aria-role="listitem"
            @click="$emit('insertText', `${shopifyCode.link} `)"
          >
            <p class="text is-3">{{ shopifyCode.code }}</p>
            <div class="multibar row-vcentered">
              <span class="subtext is-3 start has-text-tertiary"> {{ shopifyCode._type }}</span>
              <div
                v-if="shopifyCode._campaign"
                class="start multibar row-vcentered ml-1"
              >
                <b-icon
                  :class="`start has-text-color-${shopifyCode._campaign.color}`"
                  pack="ubu"
                  icon="ubu-custom-influence"
                />
                <span :class="`subtext is-3 start has-text-color-${shopifyCode._campaign.color}`">
                  {{ shopifyCode._campaign.name }}
                </span>
              </div>
            </div>
          </b-dropdown-item>

          <b-dropdown-item aria-role="listitem">
            <b-button
              class="is-ghost no-padding is-borderless"
              label="Add affiliation"
              icon-left="ubu-add"
              icon-pack="ubu"
              expanded
              @click="isCreateAffiliationPanelOpened = true; $emit('hasPanelOpen', true)"
            />
          </b-dropdown-item>
        </template>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheTextareaAffiliationButton',
  mixins: [FilterEngineMixin],
  props: {
    contactDetails: {
      type: Object,
      required: true,
    },
    reachMode: {
      type: Boolean,
      default: false,
    },
    dropdownPosition: {
      type: String,
      default: 'is-top-left',
    },
  },
  data() {
    return {
      search: '',
      isLoading: false,
      isCreateAffiliationPanelOpened: false,
      isOpenDropdown: false,
    };
  },
  computed: {
    ...mapGetters({
      reportShopifyCodeListList: 'TheTextarea/reportShopifyCodeListList',
      accountFeatures: 'TheTextarea/accountFeatures',
      campaignList: 'TheInbox/campaignList',
    }),
    filteredShopifyCodeList() {
      const codeList = Object.values(this.reportShopifyCodeListList);
      if (!codeList.length) return [];

      const filtersToApply = {
        searchByContactPlatformCpid: this.contactDetails.contactPlatform.cpid,
      };
      if (this.search) filtersToApply.searchByCode = this.search;

      return this.filterEngine(codeList, filtersToApply)
        .map((code) => {
          const returnable = code;
          returnable._type = code.type === 'DISCOUNT_CODE' ? 'Discount' : 'Referral';

          if (code.campaignCid && this.campaignList) {
            returnable._campaign = this.campaignList[code.campaignCid] || null;
          }

          return returnable;
        })
        .sort((a, b) => (this.$moment(b.createdOn) - this.$moment(a.createdOn)));
    },
  },
  methods: {
    ...mapActions({
      reportShopifyCodeList: 'TheTextarea/reportShopifyCodeList',
      emitSegmentEvent: 'TheTextarea/emitSegmentEvent',
    }),
    fetchShopifyCodeList() {
      if (!this.contactDetails) return;
      this.isLoading = true;
      this.reportShopifyCodeList({
        httpQuery: { detailled: true, contactCid: this.contactDetails.cid },
      })
        .then(() => {
          this.isLoading = false;
        });
    },
    toggle(isOpen) {
      this.isOpenDropdown = isOpen;
    },
    handleOpenCreateAffiliation() {
      if (!this.accountFeatures('SHOPIFY')) {
        this.emitSegmentEvent({ event: 'createAffiliationNoFeature' });
        this.$intercom.showNewMessage('Hi! I would like to activate the SHOPIFY module! 🙌');

        return;
      }

      this.isCreateAffiliationPanelOpened = true;
    },
  },
};
</script>

<style lang="scss">
.skeletonAffiliation {
  display: flex;
  flex-direction: column;
  & > :first-child {
    margin-bottom: 6px;
  }
}
</style>
