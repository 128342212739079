/* eslint-disable no-multi-spaces */
import TheAdmin from './TheAdmin';

import TheAdminAddAccount from './views/Account/TheAdminAddAccount';
import TheAdminAccounts from './views/Account/TheAdminAccounts';
import TheAdminAccount from './views/Account/TheAdminAccount';
import TheAdminAccountDetails from './views/Account/TheAdminAccountDetails';
import TheAdminAccountMembers from './views/Account/TheAdminAccountMembers';
import TheAdminAccountChannels from './views/Account/TheAdminAccountChannels';
import TheAdminAccountAddWorkspace from './views/Account/TheAdminAccountAddWorkspace';
import TheAdminAccountAddChannelSms from './views/Account/TheAdminAccountAddChannelSms';
import TheAdminAccountHistory from './views/Account/TheAdminAccountHistory';

import TheAdminAccountDetailsEdit from './views/Account/Settings/TheAdminAccountDetailsEdit';
import TheAdminAccountFeatures from './views/Account/TheAdminAccountFeatures';

import TheAdminAccountChannelMigrate from './views/Account/migrate/TheAdminAccountChannelMigrate';
// Channels details
import TheAdminAccountChannelDetails from './views/Account/DetailsChannels/TheAdminAccountChannelDetails';
import TheAdminAccountChannelDetailsInstagram from './views/Account/DetailsChannels/TheAdminAccountChannelDetailsInstagram';
import TheAdminAccountChannelDetailsFacebook from './views/Account/DetailsChannels/TheAdminAccountChannelDetailsFacebook';
import TheAdminAccountChannelDetailsTiktok from './views/Account/DetailsChannels/TheAdminAccountChannelDetailsTiktok';
import TheAdminAccountChannelDetailsYoutube from './views/Account/DetailsChannels/TheAdminAccountChannelDetailsYoutube';
import TheAdminAccountChannelDetailsSms from './views/Account/DetailsChannels/TheAdminAccountChannelDetailsSms';

export default [
  {
    path: '/admin',
    redirect: '/admin/accounts',
  },
  {
    path: '/admin',
    name: 'Admin',
    component: TheAdmin,
    meta: {
      title: 'Admin',
      hasMenu: true,
      viewStoreNavigation: 'TheAdmin',
      requireAuth: true,
    },
    children: [
      {
        path: '/admin/add-account',
        name: 'Admin - Add Accounts',
        components: { addAccount: TheAdminAddAccount },
        meta: {
          title: 'Admin',
          hasMenu: true,
          viewStoreNavigation: 'TheAdmin',
          requireAuth: true,
        },
      },
      {
        path: '/admin/accounts',
        name: 'Admin - Accounts',
        components: { adminAccounts: TheAdminAccounts },
        meta: {
          title: 'Admin',
          hasMenu: true,
          viewStoreNavigation: 'TheAdmin',
          requireAuth: true,
        },
      },
      {
        path: '/admin/account/:aid',
        name: 'Admin - Account',
        components: { adminAccount: TheAdminAccount },
        meta: {
          title: 'Admin',
          hasMenu: true,
          viewStoreNavigation: 'TheAdmin',
          requireAuth: true,
        },
        children: [
          {
            path: '/admin/account/:aid/details',
            name: 'Admin - Account - Details',
            component: TheAdminAccountDetails,
            meta: {
              title: 'Admin',
              hasMenu: true,
              viewStoreNavigation: 'TheAdmin',
              requireAuth: true,
            },
            children: [
              {
                path: '/admin/account/:aid/details/addFeatures',
                name: 'Admin - Account - Details - Add feature',
                components: { addFeatures: TheAdminAccountFeatures },
                meta: {
                  title: 'Admin',
                  hasMenu: true,
                  viewStoreNavigation: 'TheAdmin',
                  requireAuth: true,
                },
              },
              {
                path: '/admin/account/:aid/details/edit',
                name: 'Admin - Account - Details - Edit',
                components: { editAccount: TheAdminAccountDetailsEdit },
                meta: {
                  title: 'Admin',
                  hasMenu: true,
                  viewStoreNavigation: 'TheAdmin',
                  requireAuth: true,
                },
              },
            ],
          },
          {
            path: '/admin/account/:aid/members',
            name: 'Admin - Account - Members',
            component: TheAdminAccountMembers,
            meta: {
              title: 'Admin',
              hasMenu: true,
              viewStoreNavigation: 'TheAdmin',
              requireAuth: true,
            },
          },
          {
            path: '/admin/account/:aid/channels',
            name: 'Admin - Account - Channels',
            component: TheAdminAccountChannels,
            meta: {
              title: 'Admin',
              hasMenu: true,
              viewStoreNavigation: 'TheAdmin',
              requireAuth: true,
            },
            children: [
              {
                path: '/admin/account/:aid/channels/addWorkspace',
                name: 'Admin - Account - Channels - Add Workspace',
                components: { addChannelSms: TheAdminAccountAddWorkspace },
                meta: {
                  title: 'Admin',
                  hasMenu: true,
                  viewStoreNavigation: 'TheAdmin',
                  requireAuth: true,
                },
              },
              {
                path: '/admin/account/:aid/channels/addChannelSms',
                name: 'Admin - Account - Channels - Add channel SMS',
                components: { addChannelSms: TheAdminAccountAddChannelSms },
                meta: {
                  title: 'Admin',
                  hasMenu: true,
                  viewStoreNavigation: 'TheAdmin',
                  requireAuth: true,
                },
              },
              {
                path: '/admin/account/:aid/channels/:cid/details',
                name: 'Admin - Account - Channels - Details',
                components: { detail: TheAdminAccountChannelDetails },
                meta: {
                  title: 'Admin',
                  hasMenu: true,
                  viewStoreNavigation: 'TheAdmin',
                  requireAuth: true,
                },
                children: [
                  {
                    path: '/admin/account/:aid/channels/:cid/migrate',
                    name: 'Admin - Account - Channels - Migrate',
                    components: { detailInstagram: TheAdminAccountChannelMigrate },
                    meta: {
                      title: 'Admin',
                      hasMenu: true,
                      viewStoreNavigation: 'TheAdmin',
                      requireAuth: true,
                    },
                  },
                  {
                    path: '/admin/account/:aid/channels/:cid/details/instagram',
                    name: 'Admin - Account - Channels - Details - Instagram',
                    components: { detailInstagram: TheAdminAccountChannelDetailsInstagram },
                    meta: {
                      title: 'Admin',
                      hasMenu: true,
                      viewStoreNavigation: 'TheAdmin',
                      requireAuth: true,
                    },
                  },
                  {
                    path: '/admin/account/:aid/channels/:cid/details/facebook',
                    name: 'Admin - Account - Channels - Details - Facebook',
                    components: { detailFacebook: TheAdminAccountChannelDetailsFacebook },
                    meta: {
                      title: 'Admin',
                      hasMenu: true,
                      viewStoreNavigation: 'TheAdmin',
                      requireAuth: true,
                    },
                  },
                  {
                    path: '/admin/account/:aid/channels/:cid/details/tiktok',
                    name: 'Admin - Account - Channels - Details - Tiktok',
                    components: { detailTiktok: TheAdminAccountChannelDetailsTiktok },
                    meta: {
                      title: 'Admin',
                      hasMenu: true,
                      viewStoreNavigation: 'TheAdmin',
                      requireAuth: true,
                    },
                  },
                  {
                    path: '/admin/account/:aid/channels/:cid/details/youtube',
                    name: 'Admin - Account - Channels - Details - Youtube',
                    components: { detailYoutube: TheAdminAccountChannelDetailsYoutube },
                    meta: {
                      title: 'Admin',
                      hasMenu: true,
                      viewStoreNavigation: 'TheAdmin',
                      requireAuth: true,
                    },
                  },
                  {
                    path: '/admin/account/:aid/channels/:cid/details/sms',
                    name: 'Admin - Account - Channels - Details - SMS',
                    components: { detailSms: TheAdminAccountChannelDetailsSms },
                    meta: {
                      title: 'Admin',
                      hasMenu: true,
                      viewStoreNavigation: 'TheAdmin',
                      requireAuth: true,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: '/admin/account/:aid/history',
            name: 'Admin - Account - History',
            component: TheAdminAccountHistory,
            meta: {
              title: 'Admin',
              hasMenu: true,
              viewStoreNavigation: 'TheAdmin',
            },
            children: [],
          },
        ],
      },
    ],
  },
];
