<template>
  <div class="buttons">
    <button
      class="button is-secondary is-expanded"
      @click="$emit('changeTab', 'root.sendMessage')"
    >
      <div class="multibar">
        <b-icon
          pack="ubu"
          icon="ubu-compose"
        />
        <span>
          New message
        </span>
      </div>
    </button>

    <button
      class="button is-secondary"
      @click="$emit('downloadMentions')"
    >
      <div class="multibar">
        <b-icon
          pack="ubu"
          icon="ubu-download"
        />
      </div>
    </button>

    <button
      :class="['ubuFavButton button is-secondary']"
      @click="$emit('toggleFavorite')"
    >
      <div class="multibar">
        <b-icon
          pack="mdi"
          :icon="!!mentionItem.favorite.publicationPid ? 'star' : 'star-outline'"
          size="is-medium"
        />
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'UbuMentionModalNavigation',
  model: {
    prop: 'mentionItem',
  },
  props: {
    mentionItem: {
      type: Object,
      required: true,
    },
  },
};
</script>
