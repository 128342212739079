/* eslint-disable max-len */
import moment from 'moment';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { actions, getters, commonState } from '@dailyplanet/data-stores/commonViewStores';
import { dispatchAndForward, dispatchFailure } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        ...commonState.menu.sales.menuItems,
      ],
    },
  },
  getters: {
    ...getters.scrapingZone,
    navigation: (state) => state.navigation,
  },
  actions: {
    ...actions.scrapingZone,

    createScrapingZone({ dispatch }, payload) {
      const action = {
        dispatch,
        target: 'Sales/scrapingZone/create',
        context: {
          payload: {
            ...payload,
            createdOn: moment().format(),
            updatedOn: moment().format(),
            terminatedOn: null,
          },
        },
        textNotifier: 'create scraping zone',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to create Scraping Zone.',
            type: 'is-danger',
            position: 'is-top',
            duration: 5000,
          });

          return dispatchFailure(action);
        });
    },

    deleteScrapingZone({ dispatch }, szid) {
      const action = {
        dispatch,
        target: 'Sales/scrapingZone/delete',
        context: {
          payload: { szid },
        },
        textNotifier: 'delete scraping zone',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to delete Scraping Zone.',
            type: 'is-danger',
            position: 'is-top',
            duration: 5000,
          });

          return dispatchFailure(action);
        });
    },
  },
};
