// Tribe
import TheInbox from '@dailyplanet/selina-addons/inbox/TheInbox';
import TheInboxSidebar from '@dailyplanet/selina-addons/inbox/TheInboxSidebar';
import TheInboxMainPanel from '@dailyplanet/selina-addons/inbox/TheInboxMainPanel';

import TheTribes from './TheTribes';
import TheTribe from './TheTribe';

import TheTribePeople from './views/TheTribePeople';
import TheTribePeopleDetailed from './views/TheTribePeopleDetailed';

import TheTribeMention from './views/TheTribeMention';

import TheTribeAnalytics from './views/TheTribeAnalytics';
import TheTribeAnalyticsMembers from './views/TheTribeAnalyticsMembers';
import TheTribeAnalyticsMentions from './views/TheTribeAnalyticsMentions';
import TheTribeAnalyticsSales from './views/TheTribeAnalyticsSales';

export default [
  {
    path: '/tribe',
    name: 'Tribes',
    component: TheTribes,
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheInfluence',
    },
  },
  {
    path: '/tribe/:tribeTid',
    redirect: '/tribe/:tribeTid/people/detailed',
  },
  {
    path: '/tribe/:tribeTid/people',
    redirect: '/tribe/:tribeTid/people/detailed',
  },
  {
    path: '/tribe/:tribeTid/people/gallery',
    redirect: '/tribe/:tribeTid/people/detailed',
  },
  {
    path: '/tribe/:tribeTid/mentions/gallery',
    redirect: '/tribe/:tribeTid/mentions',
  },
  {
    path: '/tribe/:tribeTid/analytics',
    redirect: '/tribe/:tribeTid/analytics/members',
  },
  {
    path: '/tribe/:tribeTid',
    name: 'Tribe',
    component: TheTribe,
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheInfluence',
    },
    children: [
      {
        path: '/tribe/:tribeTid/people',
        name: 'Tribe - People',
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheInfluence',
        },
        component: TheTribePeople,
        children: [
          {
            path: '/tribe/:tribeTid/people/detailed',
            name: 'Tribe - People - Detailed',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheInfluence',
              viewType: 'detailed',
            },
            component: TheTribePeopleDetailed,
          },
        ],
      },
      {
        path: '/tribe/:tribeTid/mentions',
        name: 'Tribe - Mentions',
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheInfluence',
        },
        component: TheTribeMention,
      },
      {
        path: '/tribe/:tribeTid/messages',
        name: 'Tribe - Messages',
        component: TheInbox,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheInfluence',
        },
        children: [
          {
            path: '/tribe/:tribeTid/messages/tribe/:threadByTribes',
            name: 'Tribe - Messages - Common',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheInfluence',
              isCommonThreadBox: false,
            },
            components: {
              inboxSidebar: TheInboxSidebar,
            },
          },
          {
            path: '/tribe/:tribeTid/messages/tribe/:threadByTribes/channel/:channelCid/thread/:tid',
            name: 'Tribe - Messages - Thread',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheInfluence',
              isCommonThreadBox: false,
            },
            components: {
              inboxSidebar: TheInboxSidebar,
              inboxMainPanel: TheInboxMainPanel,
            },
          },
        ],
      },
      {
        path: '/tribe/:tribeTid/analytics',
        name: 'Tribe - Analytics',
        component: TheTribeAnalytics,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheInfluence',
        },
        children: [
          {
            path: '/tribe/:tribeTid/analytics/members',
            name: 'Tribe - Analytics - Members',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheInfluence',
            },
            component: TheTribeAnalyticsMembers,
          },
          {
            path: '/tribe/:tribeTid/analytics/mentions',
            name: 'Tribe - Analytics - Mentions',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheInfluence',
            },
            component: TheTribeAnalyticsMentions,
          },
          {
            path: '/tribe/:tribeTid/analytics/sales',
            name: 'Tribe - Analytics - Sales',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheInfluence',
            },
            component: TheTribeAnalyticsSales,
          },
        ],
      },
    ],
  },
];
