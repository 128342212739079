export default {
  methods: {
    $update(key, value, prop, event) {
      const propo = prop || this.$vnode.componentOptions.Ctor.options.model.prop || 'value';
      const evento = event || this.$vnode.componentOptions.Ctor.options.model.event || 'input';
      if (key instanceof Object) {
        // $update.info('updatoObjecto', { ...this[propo], ...key }, propo, this[propo], evento);
        return this.$emit(evento, { ...this[propo], ...key });
      }
      // $update.info('updatoNormalo', { ...this[propo], [key]: value }, propo, this[propo], evento);
      this.$emit(`input:${key}`, value);
      return this.$emit(evento, { ...this[propo], [key]: value });
    },
  },
};
