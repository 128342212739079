/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

const internals = {
  contactPrivateMetadataMapper: ({
    contact,
    ...restRow
  }) => ({
    // ce qui suit c'est de la merde en attendant plus de granularité (Ses mots ont était prononcé par le Saint Thomas)
    status: !!restRow.hashedPhone,
    optIn: !!restRow.hashedPhone,
    // Fin de la merde
    ...restRow,
    ...contact.contactPlatform.arrayMapper('platformName'),
    ...contact,
  }),
};

export default {
  namespaced: true,
  state: {
    contactPrivateMetadata: null,
    contactPrivateMetadataList: null,
  },
  mutations: {
    SET_CONTACT_PRIVATE_METADATA(state, newData) { state.contactPrivateMetadata = newData; },
    SET_CONTACT_PRIVATE_METADATA_LIST(state, newData) { state.contactPrivateMetadataList = newData; },
    RESET_STORE(state) {
      state.contactPrivateMetadata = null;
      state.contactPrivateMetadataList = null;
    },
  },
  getters: {
    getterContactPrivateMetadata: (state) => state.contactPrivateMetadata,
    getterContactPrivateMetadataList: (state) => state.contactPrivateMetadataList,
  },
  actions: {
    update(_, { payload, httpQuery }) {
      return clark.api.channelGroup.contactPrivateMetadata.put({ payload, httpQuery })
        .then((contactPrivateMetadata) => contactPrivateMetadata);
    },
    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CONTACT_PRIVATE_METADATA.REPORT')) return undefined;

      return clark.api.channelGroup.contactPrivateMetadata.report({ payload, httpQuery })
        .then((results) => {
          commit('SET_CONTACT_PRIVATE_METADATA_LIST', results.map((row) => internals.contactPrivateMetadataMapper(row)).arrayMapper('cpmid'));
        });
    },
    create({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CONTACT_PRIVATE_METADATA.CREATE')) return undefined;

      return clark.api.channelGroup.contactPrivateMetadata.post({ payload, httpQuery })
        .then((contactPrivateMetadata) => {
          commit('SET_CONTACT_PRIVATE_METADATA', contactPrivateMetadata);
          return contactPrivateMetadata;
        });
    },
    get({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CONTACT_PRIVATE_METADATA.GET')) return undefined;

      return clark.api.channelGroup.contactPrivateMetadata.get({ payload, httpQuery })
        .then((contactPrivateMetadata) => {
          commit('SET_CONTACT_PRIVATE_METADATA', contactPrivateMetadata);
          return contactPrivateMetadata;
        });
    },
  },
};
