<template>
  <TheTabsPanel
    v-model="activeTab"
    @goToPreviousTab="goToPreviousTab()"
    @goToStart="closePanel()"
    @close="closePanel()"
  >
    <template #content>
      <b-tab-item value="root">
        <div class="buttons">
          <UbuActionCard
            class="mb-4"
            label="Edit folder"
            icon-pack="ubu"
            icon-left="ubu-edit"
            expanded
            @click="goToTabByTabPath('root.update')"
          />

          <UbuActionCard
            class="mb-4"
            label="Delete folder"
            icon-pack="ubu"
            icon-left="ubu-trash"
            expanded
            @click="deleteFolder()"
          />
        </div>
      </b-tab-item>

      <b-tab-item value="root.update">
        <form v-if="activeTab.tab === 'root.update'" @submit.prevent="updateFolder()">
          <b-field label="Name">
            <b-input
              v-model="localFolder.title"
              placeholder="10% off"
              required
            />
          </b-field>

          <br>

          <b-field label="Description">
            <b-input
              v-model="localFolder.description"
              placeholder="All codes that give 10% off, limited to one purchase per customers"
              type="textarea"
              required
            />
          </b-field>

          <br>

          <div class="buttons is-right">
            <b-button
              type="is-primary"
              native-type="submit"
              label="Save"
            />
          </div>
        </form>
      </b-tab-item>
    </template>
  </TheTabsPanel>
</template>

<!-- eslint-disable max-len -->
<script>
import { mapActions, mapGetters } from 'vuex';
import { snackbarError } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheManageAffiliationCodeFolder',
  props: {
    selectedFolder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: {
        tab: 'root',
        title: 'Manage folder',
        subtext: 'Affiliation folders',
      },
      tabItems: {
        root: {
          tab: 'root',
          title: 'Manage folder',
          subtext: 'Affiliation folder',
        },
        'root.update': {
          tab: 'root.update',
          title: 'Edit folder',
          subtext: 'Affiliation folders',
        },
      },

      localFolder: null,
    };
  },
  computed: {
    ...mapGetters({
      _shopList: 'TheCreateAffiliationCodePanel/shopList',
      _currentShop: 'TheCreateAffiliationCodePanel/currentShop',
    }),

    navigableItems() {
      return {
        'root.editFolder': {
          to: 'root.editFolder',
          icon: 'ubu-edit',
          label: 'Edit folder',
        },
        'root.changeStatus': {
          to: 'root.changeStatus',
          icon: 'ubu-edit',
          label: 'Delete folder',
        },
      };
    },
  },
  mounted() {
    this.localFolder = { ...this.selectedFolder };
  },
  methods: {
    ...mapActions({
      updateAffiliationCodeFolder: 'TheManageAffiliationCodeFolder/updateAffiliationCodeFolder',
      deleteAffiliationCodeFolder: 'TheManageAffiliationCodeFolder/deleteAffiliationCodeFolder',
    }),

    goToPreviousTab() {
      if (this.activeTab.tab === 'root') return;

      const tabToGo = this.activeTab.tab.split('.').slice(0, -1).join('.');

      if (tabToGo === 'root') this.resetPayload();

      this.activeTab = this.tabItems[tabToGo];
    },

    goToTabByTabPath(tabToGo) { this.activeTab = this.tabItems[tabToGo]; },

    backHome() {
      this.activeTab = this.tabItems.root;
      this.resetPayload();
    },

    resetPayload() { this.localFolder = null; },

    closePanel() { this.$emit('close'); },

    updateFolder() {
      return this.updateAffiliationCodeFolder({
        payload: {
          sid: this._currentShop.sid,
          ...this.selectedFolder,
          ...this.localFolder,
        },
      })
        .then(() => this.backHome());
    },

    deleteFolder() {
      return this.deleteAffiliationCodeFolder({ payload: { sid: this._currentShop.sid, acfid: this.selectedFolder.acfid } })
        .then(() => this.backHome())
        .catch((e) => {
          if (e.message === 'E_UNPROCESSABLE_API_AFFILIATION_AFFILIATION_CODE_FOLDER_MUST_BE_EMPTY') {
            this.$buefy.snackbar.open({ ...snackbarError, message: 'You can\'t delete a folder not empty' });
            return e;
          }

          this.$buefy.snackbar.open({ ...snackbarError, message: 'Unable to delete coupon folder.' });
          return e;
        });
    },
  },
};
</script>
