/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import moment from 'moment';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    hashtagsList: null,
    currentHashtag: null,
  },
  mutations: {
    SET_HASHTAGS_LIST(state, newData) { state.hashtagsList = newData; },
    SET_CURRENT_HASHTAG(state, newData) { state.currentHashtag = newData; },
    UNSET_HASHTAGS_LIST(state, newData) {
      state.hashtagsList = Object.values(state.hashtagsList).filter(({ hashtag }) => hashtag !== newData).arrayMapper('hashtag');
    },
    RESET_STORE(state) {
      state.hashtagsList = null;
      state.currentHashtag = null;
    },
  },
  getters: {
    getterHashtagsList: (state) => state.hashtagsList,
    getterCurrentHashtag: (state) => state.currentHashtag,
  },
  actions: {
    get({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'HASHTAG.GET')) return undefined;

      return clark.api.channelGroup.hashtag.get({ payload, httpQuery })
        .then((hashtag) => {
          commit('SET_CURRENT_HASHTAG', hashtag);
        });
    },

    report({ commit, rootGetters }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'HASHTAG.REPORT')) return undefined;

      return clark.api.channelGroup.hashtag.report({ payload, httpQuery })
        .then((hashtagsList) => {
          const sortedList = hashtagsList.sort((a, b) => (moment(b.createdOn) - moment(a.createdOn)));
          commit('SET_HASHTAGS_LIST', sortedList.arrayMapper('hashtag'));
        });
    },

    create({ commit, rootGetters, getters: { getterHashtagsList } }, { payload }) {
      if (!isActionAllowed(rootGetters, 'HASHTAG.CREATE')) return undefined;

      return clark.api.channelGroup.hashtag.post({ payload })
        .then((hashtag) => {
          commit('SET_HASHTAGS_LIST', { [hashtag.hashtag]: hashtag, ...getterHashtagsList });
        });
    },

    delete({ commit, rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'HASHTAG.DELETE')) return undefined;

      return clark.api.channelGroup.hashtag.delete({ payload, httpQuery })
        .then(() => {
          commit('UNSET_HASHTAGS_LIST', payload.hashtag);
          commit('SET_CURRENT_HASHTAG', null);
        });
    },
  },
};
