/* eslint-disable max-len */
import { actions } from '@dailyplanet/data-stores/commonViewStores';
import BulkActionEngineStore from '@dailyplanet/data-stores/engine-stores/bulkAction';

export default {
  namespaced: true,
  state: {
    ...BulkActionEngineStore.state,
  },
  mutations: {
    ...BulkActionEngineStore.mutations,
  },
  getters: {
    ...BulkActionEngineStore.getters,

    tabItems: (state, getters, _rootState, rootGetters) => ({
      root: {
        tab: 'root',
        title: 'What do you want to do ?',
        subtext: 'Bulk actions',
      },
      'root.downloadMentions': {
        tab: 'root.downloadMentions',
        title: 'Download',
        subtext: 'Bulk actions',
      },
      ...rootGetters['TheBulkChangeStatus/tabItemsForCampaignPeople'],
      ...rootGetters['TheBulkRemoveFromCampaign/tabItemsForCampaignPeople'],
      ...rootGetters['TheAddToTribe/tabItemsForTheCrmBulk'],
      ...rootGetters['TheSendInstantMessage/tabItemsForTheTribeBulk'],
    }),
  },
  actions: {
    ...BulkActionEngineStore.actions,
    ...actions.segment,
  },
};
