<template>
  <UbuCustomChip
    :model="{
      title: 'Active campaign',
      defaultIcon: 'ubu-custom-influence',
      count: 1,
      listItem: [{
        icon: 'ubu-custom-influence',
        color: _campaignList[cpo.lastActivity.campaignCid].color,
        label: _campaignList[cpo.lastActivity.campaignCid].name,
        status: cpo.lastActivity.status,
      }]
    }"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheCampaignChip',
  props: {
    cpo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      _campaignList: 'TheCRM/campaignList',
    }),
  },
};
</script>
