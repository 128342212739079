/* eslint-disable quotes */
/* eslint-disable object-curly-newline */

import builder from '../../engine';

const target = 'clark';

const migrate = {
  common: ({ payload, httpQuery }) => builder({ url: `/api/migrate/${payload.channelUsername}/common`, method: 'POST', payload, httpQuery, target }),
  proto: ({ payload, httpQuery }) => builder({ url: `/api/migrate/${payload.channelUsername}/proto`, method: 'POST', payload, httpQuery, target }),
  various: ({ payload, httpQuery }) => builder({ url: `/api/migrate/${payload.channelUsername}/various`, method: 'POST', payload, httpQuery, target }),
};

export default migrate;
