const optionBarSimple = {
  type: 'bar',
  options: {
    responsive: true,
    local: 'en-US',

    layout: {
      padding: 12,
    },

    plugins: {
      legend: {
        display: false,
      },
    },

    elements: {
      bar: {
        backgroundColor: 'rgba(255, 98, 164, 0.6)',
        borderColor: 'rgba(255, 98, 164, 0.6)',
        borderRadius: 12,
      },
    },

    scales: {
      y: {
        // suggestedMin: 0,
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  },
};

const optionDoughnutSimple = {
  type: 'doughnut',
  options: {
    responsive: true,
    cutout: '50%',
    borderWidth: 1,
    hoverOffset: 20,
    // spacing: 5,
    layout: {
      padding: {
        right: 20,
        left: 20,
        top: 1,
        bottom: 5,
      },
    },
    plugins: {
      legend: {
        position: 'left',
      },
      backgroundColor: [
        'rgb(77, 77, 77)',
        'rgb(255, 96, 96)',
        'rgb(255, 164, 113)',
        'rgb(255, 198, 112)',
        'rgb(107, 206, 61)',
        'rgb(115, 218, 138)',
        'rgb(67, 210, 193)',
        'rgb(60, 166, 242)',
        'rgb(111, 109, 227)',
        'rgb(185, 141, 240)',
        'rgb(248, 130, 236)',
        'rgb(255, 98, 164)',
      ],
      // title: {
      //   display: true,
      //   text: 'Chart.js Doughnut Chart',
      // },
    },
  },
};

const optionLineSimple = {
  type: 'line',
  options: {
    responsive: true,
    local: 'en-US',

    layout: {
      padding: 12,
    },

    plugins: {
      legend: {
        display: false,
      },
    },

    elements: {
      line: {
        tension: 0.4,
        backgroundColor: 'rgba(255, 98, 164, 0.6)',
        borderColor: 'rgba(255, 98, 164, 0.6)',
        fill: true,
      },
      point: {
        radius: 6,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'rgba(255, 98, 164, 0.6)',
      },
    },

    scales: {
      y: {
        beginAtZero: false,
        ticks: {
          stepSize: 1,
        },
      },
    },
  },
};

const calculateStepYAxis = (graphData) => {
  const { datasets: [{ data }] } = graphData;

  const { values, sum } = data.reduce((acc, value) => {
    if (value === 'null' || !value) return acc;
    acc.sum += Number(value);
    acc.values.push(Number(value));

    return acc;
  }, {
    values: [],
    sum: 0,
  });

  const min = Math.min(...values);
  const max = Math.max(...values);

  const maxSubtractMinLength = (max - min).toFixed(0).length;

  const tot = sum / values.length;

  if (tot < 1) return 0.25;

  if (maxSubtractMinLength < 2) return 2;
  if (maxSubtractMinLength === 2) return 5;
  if (maxSubtractMinLength === 3) return 10 ** 2;
  if (maxSubtractMinLength >= 4 && maxSubtractMinLength < 5) return 10 ** 3;
  if (maxSubtractMinLength >= 5 && maxSubtractMinLength < 6) return 10 ** 4;
  if (maxSubtractMinLength >= 6) return 10 ** 5;

  return 10 ** 2;
};

export {
  optionBarSimple,
  optionLineSimple,
  optionDoughnutSimple,
  calculateStepYAxis,
};
