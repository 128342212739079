import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    channelSms: null,
  },
  mutations: {
    SET_CHANNEL_SMS(state, newData) { state.channelSms = newData; },
  },
  getters: {
    getterChannelSms: (state) => state.channelSms,
  },
  actions: {
    create({ commit }, { payload = {}, httpQuery = {} }) {
      return clark.api.apiConversation.channelSms.post({ payload, httpQuery })
        .then((channelSms) => {
          commit('SET_CHANNEL_SMS', channelSms);
          return channelSms;
        });
    },
  },
};
