/* eslint-disable no-multi-spaces */
import TheSettingProfilePanelEditName from './userSetting/TheSettingProfilePanelEditName';
import TheSettingProfilePanelEditEmail from './userSetting/TheSettingProfilePanelEditEmail';
import TheSettingProfilePanelEditPassword from './userSetting/TheSettingProfilePanelEditPassword';

import TheHome from './TheHome';
import TheSetting from './TheSetting';

// User setting
import TheSettingProfile from './userSetting/TheSettingProfile';
import TheSettingTheme from './userSetting/TheSettingTheme';

// Workspace setting
import TheSettingMember from './workspaceSetting/TheSettingMember';
import TheSettingExtension from './workspaceSetting/TheSettingExtension';
import TheSettingDownloadTask from './workspaceSetting/TheSettingDownloadTask';
import TheSettingAddMember from './workspaceSetting/TheSettingAddMember';
import TheSettingAddWorkspace from './workspaceSetting/TheSettingAddWorkspace';
import TheSettingEditWorkspace from './workspaceSetting/TheSettingEditWorkspace';
import TheSettingShopAndCoupon from './workspaceSetting/TheSettingShopAndCoupon/TheSettingShopAndCoupon';
import TheSettingShop from './workspaceSetting/TheSettingShopAndCoupon/TheSettingShop';
import TheSettingCoupon from './workspaceSetting/TheSettingShopAndCoupon/TheSettingCoupon';
import TheSettingAffiliationFolder from './workspaceSetting/TheSettingShopAndCoupon/TheSettingAffiliationFolder';

import TheSettingTemplate from './workspaceSetting/TheSettingTemplate/TheSettingTemplate';

import TheSettingTag from './workspaceSetting/TheSettingTag/TheSettingTag';
import TheSettingAddTag from './workspaceSetting/TheSettingTag/TheSettingAddTag';

import TheSettingLabel from './workspaceSetting/TheSettingLabel/TheSettingLabel';
import TheSettingAddContactLabel from './workspaceSetting/TheSettingLabel/TheSettingAddContactLabel';

// Organization setting
import TheSettingOrganization from './organizationSetting/TheSettingOrganization';

//
import TheSettingChannels from './workspaceSetting/channel/TheSettingChannels';
import TheSettingChannelsAddMeta from './workspaceSetting/channel/TheSettingChannelsAddMeta';
import TheSettingChannelsAddMetaConnectMeta from './workspaceSetting/channel/TheSettingChannelsAddMetaConnectMeta';
import TheSettingChannelsAddMetaSelectFacebook from './workspaceSetting/channel/TheSettingChannelsAddMetaSelectFacebook';
import TheSettingChannelsAddMetaSelectInstagram from './workspaceSetting/channel/TheSettingChannelsAddMetaSelectInstagram';
import TheSettingChannelsAddTiktok from './workspaceSetting/channel/TheSettingChannelsAddTiktok';
import TheSettingChannelsAddYoutube from './workspaceSetting/channel/TheSettingChannelsAddYoutube';
import TheSettingChannelsAddSms from './workspaceSetting/channel/TheSettingChannelsAddSms';

export default [
  {
    path: '/setting/affiliation',
    redirect: '/setting/affiliation/shop',
  },
  {
    path: '/home',
    name: 'Home',
    component: TheHome,
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheSetting',
    },
  },
  {
    path: '/setting',
    redirect: '/setting/profile',
    name: 'Settings',
    component: TheSetting,
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheSetting',
    },
    children: [
      // User setting
      {
        path: '/setting/profile',
        name: 'Settings - Profile',
        component: TheSettingProfile,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
        children: [
          {
            path: '/setting/profile/editName',
            name: 'Settings - Profile - Edit name',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              editName: TheSettingProfilePanelEditName,
            },
          },
          {
            path: '/setting/profile/editEmail',
            name: 'Settings - Profile - Edit email',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              editName: TheSettingProfilePanelEditEmail,
            },
          },
          {
            path: '/setting/profile/editPassword',
            name: 'Settings - Profile - Edit password',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              editName: TheSettingProfilePanelEditPassword,
            },
          },
        ],
      },
      {
        path: '/setting/theme',
        name: 'Workspace - Themes',
        component: TheSettingTheme,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
      },
      // Workspace setting
      {
        path: '/setting/member',
        name: 'Settings - Members',
        component: TheSettingMember,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
        children: [
          {
            path: '/setting/member/addMember',
            name: 'Settings - Members - Add member',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              addMember: TheSettingAddMember,
            },
          },
        ],
      },
      {
        path: '/setting/channel',
        name: 'Settings - Channels',
        component: TheSettingChannels,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
        children: [
          {
            path: '/setting/channel/addMeta',
            name: 'Workspace - Channels - Add Meta',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              addMeta: TheSettingChannelsAddMeta,
            },
          },
          {
            path: '/setting/channel/selectFacebook',
            name: 'Workspace - Channels - Select a Facebook Page',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              selectFacebook: TheSettingChannelsAddMetaSelectFacebook,
            },
          },
          {
            path: '/setting/channel/selectInstagram/:fpid',
            name: 'Workspace - Channels - Select an Instagram Account',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              selectInstagram: TheSettingChannelsAddMetaSelectInstagram,
            },
          },
          {
            path: '/setting/channel/connectMeta',
            name: 'Workspace - Channels - Connect Meta',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              connectMeta: TheSettingChannelsAddMetaConnectMeta,
            },
          },
          {
            path: '/setting/channel/addTiktok',
            name: 'Workspace - Channels - Add Tiktok',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              addTiktok: TheSettingChannelsAddTiktok,
            },
          },
          {
            path: '/setting/channel/addYoutube',
            name: 'Workspace - Channels - Add Youtube',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              addYoutube: TheSettingChannelsAddYoutube,
            },
          },
          {
            path: '/setting/channel/addSms',
            name: 'Workspace - Channels - Add Sms',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              addSms: TheSettingChannelsAddSms,
            },
          },
        ],
      },
      {
        path: '/setting/extension',
        name: 'Settings - Extensions',
        component: TheSettingExtension,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
      },
      {
        path: '/setting/tag',
        name: 'Settings - Tags',
        component: TheSettingTag,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
        children: [
          {
            path: '/setting/tag/addTag',
            name: 'Settings - Tags - Add tag',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
              lockBody: true,
            },
            components: {
              addTag: TheSettingAddTag,
            },
          },
        ],
      },
      {
        path: '/setting/label',
        name: 'Settings - Labels',
        component: TheSettingLabel,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
        children: [
          {
            path: '/setting/label/addContactLabel',
            name: 'Settings - Labels - Add contact label',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
              lockBody: true,
            },
            components: {
              addContactLabel: TheSettingAddContactLabel,
            },
          },
        ],
      },
      {
        path: '/setting/template',
        name: 'Settings - Templates',
        component: TheSettingTemplate,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
      },
      {
        path: '/setting/affiliation',
        name: 'Settings - Affiliation',
        component: TheSettingShopAndCoupon,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
        children: [
          {
            path: '/setting/affiliation/shop',
            name: 'Settings - Affiliation - Shop',
            component: TheSettingShop,
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
          },
          {
            path: '/setting/affiliation/coupon',
            name: 'Settings - Affiliation - Coupon',
            component: TheSettingCoupon,
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
          },
          {
            path: '/setting/affiliation/folder',
            name: 'Settings - Affiliation - Folder',
            component: TheSettingAffiliationFolder,
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
          },
        ],
      },
      {
        path: '/setting/downloadTask',
        name: 'Settings - Download task',
        component: TheSettingDownloadTask,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
      },
      // Organization setting
      {
        path: '/setting/organization',
        name: 'Settings - Organization',
        component: TheSettingOrganization,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
        children: [
          {
            path: '/setting/organization/addMember',
            name: 'Settings - Organization - Add member',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              addMember: TheSettingAddMember,
            },
          },
          {
            path: '/setting/organization/addWorkspace',
            name: 'Settings - Organization - Add workspace',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              addWorkspace: TheSettingAddWorkspace,
            },
          },
          {
            path: '/setting/organization/editWorkspace/:cgid',
            name: 'Settings - Organization - Edit workspace',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheSetting',
            },
            components: {
              editWorkspace: TheSettingEditWorkspace,
            },
          },
        ],
      },
    ],
  },
];
