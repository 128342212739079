<template>
  <div class="ubuPlatformCard">
    <component
      :is="`UbuIcon${platformName.capitalize()}`"
      class="start"
      :font-size="fontSize"
    />
    <span class="start text-truncate">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'UbuPlatformCard',
  props: {
    platformName: {
      type: String,
      default: 'Instagram',
    },
    text: {
      type: String,
      default: 'Followers',
    },
    fontSize: {
      type: String,
      default: '20px',
    },
  },
};
</script>
