/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const activityStatus = {
  report: ({ payload, httpQuery }) => builder({ url: '/api/activityStatus/', method: 'REPORT', payload, httpQuery, target }),
};

export default activityStatus;
