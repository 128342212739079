<template>
  <div>
    <TheTable
      v-if="mediaList.length"
      id="TheHashtagDetailedViewTable"
      :loading="false"
      :lead-list="mediaList"
      :total="mediaList.length"
      :columns="Object.values(columns)"
      :page="page"
      :per-page.sync="perPage"
      :sort-field="sortField"
      :sort-order="sortOrder"
      :default-sort-order="defaultSortOrder"
      :selected.sync="selected"
      :checked-rows.sync="checkedRows"
      :checkable="true"
      :backend-sorting="false"
      detail-key="id"
      @viewMedia="eventViewMedia($event)"
      @update:selected="changeSelected($event)"
      @update:checkedRows="changeCheckedRows($event)"
    />

    <TheHashtagsBulkPanel
      v-if="bulkIsOpen && checkedRows.length"
      v-model="checkedRows"
      @close="bulkIsOpen = false; checkedRows = []"
    />

    <UbuBulkActionPanelTrigger
      :bulk-is-open="bulkIsOpen"
      :checked-rows-length="checkedRows.length"
      @open="bulkIsOpen = true"
      @cancel="cancelBulk()"
    />

    <div
      v-if="bulkIsOpen"
      class="overlayWithLock"
    />
  </div>
</template>

<script>
import TheHashtagsColumnsMixins from './mixins/TheHashtags.columns.mixins';

export default {
  name: 'TheHashtagsDetailedView',
  mixins: [TheHashtagsColumnsMixins],
  props: {
    search: {
      type: String,
      default: '',
    },
    mediaList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      perPage: 50,
      sortField: 'id',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      selected: {},
      checkedRows: [],
      bulkIsOpen: false,
    };
  },
  computed: {
    bulkActionsOptions() {
      return {
        routerViews: [
          {
            name: 'bulkActionsHome',
            options: {
              routerViews: [
                {
                  label: 'Download mentions',
                  to: { path: '/activity/hashtags/detailed-view/bulkActionsPanel/downloadMentions' },
                  icon: 'ubu-download',
                },
              ],
            },
          },
          { name: 'downloadMentions', options: { limit: 500 } },
        ],
        closeBulk: '/activity/hashtags/detailed-view',
      };
    },
  },
  methods: {
    changeCheckedRows(rows) {
      this.checkedRows = rows;
    },
    eventViewMedia(media) {
      this.$emit('openMediaModal', { index: media.id, payload: [media] });
    },
    cancelBulk() {
      this.checkedRows = [];

      if (this.$route.path.includes('/activity/hashtags/detailed-view/bulkActionsPanel')) {
        this.$router.replace({ path: '/activity/hashtags/detailed-view' });
      }
    },
  },
};
</script>
