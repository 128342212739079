import { use, registerComponent } from '@dailyplanet/utils/plugins';

// import TheCRMBulk from './smarts/TheCRMBulk';
// import TheNewAddPeoplePanel from './smarts/TheNewAddPeoplePanel';
// import TheCreateCRMListPanel from './smarts/TheCreateCRMListPanel';
// import TheManageCRMListPanel from './smarts/TheManageCRMListPanel';

const Plugin = {
  install(Vue) {
    // registerComponent(Vue, TheCRMBulk);
    // registerComponent(Vue, TheNewAddPeoplePanel);
    // registerComponent(Vue, TheCreateCRMListPanel);
    // registerComponent(Vue, TheManageCRMListPanel);
  },
};

use(Plugin);

export default Plugin;

export {
  // TheCRMBulk,
  // TheNewAddPeoplePanel,
  // TheCreateCRMListPanel,
  // TheManageCRMListPanel,
};
