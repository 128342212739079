<template>
  <b-dropdown
    ref="dropdown"
    v-model="httpQueryRow"
    class="ubuCRMFilterCheckbox"
    aria-role="list"
    scrollable
    max-height="250px"
    :disabled="disabled"
    @active-change="triggerDropdown($event)"
  >
    <template #trigger>
      <b-button
        v-if="!httpQueryRow.length"
        type="is-dashed"
      >
        {{ label }}
      </b-button>

      <b-button
        v-else
        type="is-dashed"
        focused
      >
        {{ label }} selected ({{ httpQueryRow.length }})
      </b-button>
    </template>

    <b-input
      v-if="searchableKey"
      :ref="searchInputRef"
      v-model="search"
      placeholder="Search"
      type="search"
      class="m-2"
      icon-pack="ubu"
      icon="ubu-search"
      icon-right="ubu-cross"
      icon-right-clickable
      @icon-right-click="search = null"
    />

    <b-dropdown-item
      v-for="item in filteredItemList"
      :key="item[keyName]"
      :value="item[keyName]"
      aria-role="listitem"
      custom
    >
      <b-checkbox
        v-model="localHttpQueryRow"
        :native-value="item[keyName]"
      >
        <div class="multibar row-vcentered">
          <template v-if="icon">
            <b-icon
              v-if="!iconNested"
              pack="ubu"
              :icon="`ubu-custom-${icon}`"
              :class="`has-text-color-${item.color}`"
            />

            <b-icon
              v-else
              pack="ubu"
              :icon="item[icon]"
              :class="`has-text-color-${item.color}`"
            />

            <span
              :class="`ubuCRMFilterCheckbox_optionName
            has-text-color-${item.color}`"
            >{{ item[itemName] }}</span>
          </template>
          <span v-else-if="itemName">{{ item[itemName] }}</span>
          <span v-else>{{ item[keyName] }}</span>
        </div>
      </b-checkbox>
    </b-dropdown-item>

    <b-field
      grouped
      class="padding-reset"
    >
      <b-field expanded>
        <p class="control">
          <b-button
            expanded
            type="is-secondary"
            label="Reset"
            @click="$emit('reset')"
          />
        </p>
      </b-field>

      <b-field expanded>
        <p class="control">
          <b-button
            expanded
            type="is-primary"
            label="Done"
            @click="closeDropdown()"
          />
        </p>
      </b-field>
    </b-field>
  </b-dropdown>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuCRMFilterCheckbox',
  mixins: [VModelMixin],
  model: {
    prop: 'httpQueryRow',
  },
  props: {
    searchableKey: {
      type: String,
      default: null,
    },
    httpQueryRow: {
      type: Array,
      required: true,
    },
    keyName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    list: {
      type: [Object, Array],
      required: true,
    },
    itemName: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    iconNested: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    filteredItemList() {
      if (!this.search || !this.searchableKey) return this.list;

      const parsing = Array.isArray(this.list) ? this.list : Object.values(this.list);

      return parsing.reduce((acc, item) => {
        // eslint-disable-next-line max-len
        if (item[this.searchableKey].toLowerCase().includes(this.search.toLowerCase())) acc.push(item);

        return acc;
      }, []);
    },
    searchInputRef() {
      return `crmDropdownFiltersSearchInput${this.keyName}`;
    },
    localHttpQueryRow: {
      get() { return this.httpQueryRow; },
      set(val) { this.$emit('input', val); },
    },
  },
  methods: {
    closeDropdown() {
      // clear search & force close when clicking button add
      this.search = null;
      this.$refs.dropdown.isActive = false;
    },
    triggerDropdown(open) {
      if (this.searchableKey && open) this.$refs[this.searchInputRef].focus();
      // clear search when close dropdown by clicking outside
      if (open) return;
      this.search = null;
    },
  },
};
</script>
