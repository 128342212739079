/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import Errors from '@dailyplanet/utils/Errors';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {},
  actions: {
    fetchConversation({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'Channel/conversation/fetchConversation',
        context: {
          payload,
        },
        textNotifier: 'fetch conversation.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channel.js ~ line 28 ~ createChannel ~ e', e);
          Snackbar.open({
            message: 'Unable to fetch conversation',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },

    createChannel({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const action = {
        dispatch,
        target: 'ChannelGroup/channel/create',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'create channel.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((error) => {
          dispatchFailure(action);
          throw new Errors(error);
        });
    },

    reconnectChannel({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const action = {
        dispatch,
        target: 'ChannelGroup/channel/update',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'reconnectChannel channel.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((error) => {
          dispatchFailure(action);
          throw new Errors(error);
        });
    },

    connectPrivate({ dispatch }, { payload, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Channel/connectPrivate/connect',
        context: {
          payload,
          httpQuery: { ...httpQuery },
        },
        textNotifier: 'connect Ubu\'s Meta extension.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((error) => {
          dispatchFailure(action);
          throw new Errors(error);
        });
    },
    tryConnectPrivate({ dispatch }, { payload, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Channel/connectPrivate/tryConnect',
        context: {
          payload,
          httpQuery: { ...httpQuery },
        },
        textNotifier: 'try connect Ubu\'s Meta extension.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((error) => {
          dispatchFailure(action);
          throw new Errors(error);
        });
    },
  },
};
