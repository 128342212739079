<template>
  <!-- eslint-disable max-len -->
  <section
    v-if="account"
    class="ubuSettingView start"
  >
    <header class="start">
      <h2 class="subtitle is-1 has-text-secondary m-0">{{ account.owner.toUpperCase() }}</h2>
      <div class="multibar">
        <h1 class="start title is-1">Account members</h1>
      </div>
    </header>

    <br>

    <DevDetails :cnt="{name: 'accountMemberViewList', obj: accountMemberViewList}" />
    <DevDetails :cnt="{name: 'allChannelGroupAccountNewVersion', obj: allChannelGroupAccountNewVersion}" />
    <b-table
      class="ubuTableSetting"
      :data="Object.values(allChannelGroupAccountNewVersion)"
      :opened-detailed="defaultOpenedDetails"
      detailed
      detail-key="uid"
      :has-detailed-visible="() => false"
      hoverable
      :is-row-selectable="() => true"
      :show-header="false"
    >
      <b-table-column
        v-for="column in columns"
        :key="column.col"
        v-slot="props"
        :label="column.label"
        :field="column.col"
        :sortable="column.isSortable"
        :cell-class="column.cellClass"
      >
        <template v-if="column.cmp === 'action'">
          <b-button
            class="is-borderless"
            type="is-ghost"
            pack="ubu"
            icon-pack="ubu"
            :icon-left="defaultOpenedDetails.includes(props.row.uid)
              ? 'ubu-arrow-down'
              : 'ubu-arrow-up'"
            @click.prevent="props.toggleDetails(props.row)"
          />
        </template>

        <template v-if="column.cmp === 'img'">
          <!-- <UbuAvatar
            :initial-name="initialFormater({
              firstName: props.row.userDetail.firstName,
              lastName: props.row.userDetail.lastName })"
          /> -->
        </template>

        <template v-if="column.cmp === 'flat'">
          <p class="ellipsis text is-3 mb-1">{{ props.row[column.col] }}</p>

          <p class="ellipsis subtext is-3 has-text-secondary">
            {{ props.row[column.secondCold] }}
          </p>
        </template>

        <template v-if="column.cmp === 'tag'">
          <b-tag
            v-if="props.row[column.tag.condition]"
            :type="column.tag.color"
          >
            {{ column.tag.text }}
          </b-tag>
        </template>

        <template v-if="column.cmp === 'button'">
          <div class="multibar">
            <b-button
              class="end"
              :label="column.button.label"
              :type="column.button.type"
              @click.prevent="createLink(props.row.uid)"
            />
          </div>
        </template>
      </b-table-column>

      <template #detail="props">
        <legend class="has-text-secondary subtitle is-3">RIGHTS</legend>
        <b-field>
          <b-switch
            v-model="props.row.isAdmin"
            @input="checkIsAdmin($event, props.row.uid)"
          >
            Admin
          </b-switch>
        </b-field>

        <br>

        <DevDetails :cnt="{name: 'props.row', obj: props.row}" />
        <legend class="has-text-secondary subtitle is-3">CHANNELS</legend>
        <div
          v-for="(row) in props.row.editableItem"
          :key="row.channelGroup.cgid"
        >
          <UbuChannelGroupAndChannelSelector
            :model="JSON.stringify(row)"
            @updateGroup="updateGroupNextGen({
              event: $event,
              uid: props.row.uid,
              aid: currentAid,
            })"
          />
        </div>
      </template>
    </b-table>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

import { initialFormater } from '@dailyplanet/utils/formate';
import iconsMixins from '@dailyplanet/cross-addons/crossCommon/$mixins/dataIcons.mixins';
import linkUnlinkChannelChannelGroup from '@dailyplanet/cross-addons/workspace/$mixins/linkUnlinkChannelChannelGroup.mixins';

import TheAdminMemberColumns from '../../$mixins/TheAdminMember.columns.mixins';

export default {
  name: 'TheAdminAccountMembers',
  mixins: [
    TheAdminMemberColumns,
    iconsMixins,
    linkUnlinkChannelChannelGroup,
  ],
  data() {
    return {
      defaultOpenedDetails: [],
    };
  },
  computed: {
    ...mapGetters({
      account: 'TheAdmin/account',
      accountMemberViewList: 'TheAdmin/accountMemberViewList',
      myAuthenticate: 'TheAdmin/myAuthenticate',
    }),
    currentAid() {
      return this.$route.params.aid;
    },
    allChannelGroupAccountNewVersion() {
      if (!this.accountMemberViewList || !Object.values(this.accountMemberViewList).length) return [];
      const accountMemberViewList = Object.values(this.accountMemberViewList);

      const userMapper = accountMemberViewList.map(({
        uid, activeChannelGroup, activeChannel, channelGroupList = [], channelList = [], ...member
      }) => ({
        uid,
        ...member,
        ...member.userDetail,
        fullName: `${member.userDetail.firstName} ${member.userDetail.lastName}`,
        initials: initialFormater({
          firstName: member.userDetail.firstName,
          lastName: member.userDetail.lastName,
        }),
        isMe: Number(uid) === Number(this.myAuthenticate.uid),
        channelGroupList,
        channelList,
        editableItem: channelGroupList.reduce((acc, { cgid, name, picture }) => {
          if (!acc[cgid]) {
            acc[cgid] = {
              channelGroup: {
                cgid,
                picture,
                name,
                initials: name.charAt(0).toUpperCase(),
                isActive: activeChannelGroup ? activeChannelGroup.includes(cgid) : false,
              },
              channelList: channelList.reduce((channels, channel) => {
                if (channel.channelGroupCgid === cgid && !channels[channel.cid]) {
                  return {
                    ...channels,
                    [channel.cid]: {
                      ...channel,
                      isActive: activeChannel.includes(channel.cid),
                    },
                  };
                }

                return channels;
              }, {}),
            };
            return acc;
          }
          return acc;
        }, {}),
      }));

      return [...userMapper]
        .sort((a, b) => b.isAdmin - a.isAdmin)
        .sort((a, b) => b.fullName - a.fullName);
    },
  },
  methods: {
    ...mapActions({
      setAdmin: 'TheAdmin/setAdmin',
      setNotAdmin: 'TheAdmin/setNotAdmin',
      up_setAdmin: 'TheAdmin/up_setAdmin',
      _createLink: 'TheUsers/createLink',
    }),
    createLink(uid) {
      this._createLink({ payload: { uid } })
        .then(({ link }) => window.open(`${process.env.VUE_APP_SELINA_ADMIN_LOGIN_BASE_URL}/adminLogin/${link}`, '_blank'));
    },
    checkIsAdmin(isSwitched, uid) {
      const payload = { aid: this.currentAid, uid };

      return isSwitched
        ? this.setAdmin({ payload })
          .then(() => this.up_setAdmin({ payload: { ...payload, isAdmin: isSwitched } }))
        : this.setNotAdmin({ payload })
          .then(() => this.up_setAdmin({ payload: { ...payload, isAdmin: isSwitched } }));
    },
  },
};
</script>
