/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    activityMentionList: {},
  },
  mutations: {
    SET_ACTIVITY_MENTION_LIST(state, newData) {
      state.activityMentionList = newData;
    },
    RESET_STORE(state) { state.activityMentionList = {}; },
  },
  getters: {
    getterActivityMentionList: (state) => state.activityMentionList,
  },
  actions: {
    report({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'ACTIVITY_MENTION.REPORT')) return undefined;

      return clark.api.apiPublication.activityMention.report({ payload, httpQuery })
        .then(({ total, results }) => {
          commit('SET_ACTIVITY_MENTION_LIST', { ...getters.activityMentionList, ...results.arrayMapper('pid') });

          return { total, results };
        });
    },

    UP_favMentionList({ commit, getters, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const mentionListToEdit = Object.values(getters.getterActivityMentionList).filter(({ pid }) => payload.pids.includes(pid));

      const updated = {
        ...getters.getterActivityMentionList,
        ...mentionListToEdit.reduce((acc, row) => {
          acc[row.pid] = {
            ...row,
            favorite: payload.shouldFav
              ? { channelGroupCgid: currentChannelGroup.cgid, publicationPid: row.pid }
              : { channelGroupCgid: null, publicationPid: null },
          };
          return acc;
        }, {}),
      };

      commit('SET_ACTIVITY_MENTION_LIST', updated);
    },
  },
};
