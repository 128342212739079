<template>
  <article class="ubuContactPlatformPanelItem">
    <header class="multibar row-vcentered">
      <h3 class="start">Attributes</h3>
    </header>
    <div class="dropdowns">
      <TheTribePopover
        :payload="{
          tid: contactPlatformOverview.thread ? contactPlatformOverview.thread.tid : null,
          cid: contactPlatformOverview.contactPlatform.contactCid,
          cpid: contactPlatformOverview.contactPlatform.cpid,
          customs: contactPlatformOverview.tribeMemberList,
        }"
        position="is-bottom-right"
      />

      <TheTagPopover
        :is-disabled="!contactPlatformOverview.thread"
        position="is-bottom-right"
      />

      <TheLabelPopover
        position="is-bottom-left"
      />
    </div>
  </article>
</template>

<script>
export default {
  name: 'UbuContactPlatformPanelCustoms',
  model: {
    prop: 'contactPlatformOverview',
  },
  props: {
    contactPlatformOverview: {
      type: Object,
      required: true,
    },
  },
};
</script>
