<template>
  <UbuDropdown
    position="is-bottom-right"
    :scrollable="true"
    @open="isOpen = true"
    @close="isOpen = false;"
  >
    <template #button>
      <b-button
        :label="selectedCampaign ? selectedCampaign.name : 'Select a campaign'"
        icon-pack="ubu"
        :icon-left="selectedCampaign ? 'ubu-custom-influence' : ''"
        type="is-secondary"
      />
    </template>

    <template
      v-if="isOpen"
      #search
    >
      <b-dropdown-item custom>
        <UbuSearchbar
          v-model="search"
          @clear="search = ''"
        />
      </b-dropdown-item>
    </template>

    <template #content>
      <b-dropdown-item
        v-if="selectedCampaign"
        @click="selectCampaign(null)"
      >
        <div class="multibar row-vcentered">
          <b-icon
            :class="`text-color-${selectedCampaign.color} start`"
            pack="ubu"
            icon="ubu-custom-influence"
          />

          <p class="text start">{{ selectedCampaign.name }}</p>

          <b-icon
            class="end"
            pack="ubu"
            icon="ubu-check"
          />
        </div>
      </b-dropdown-item>

      <b-dropdown-item
        v-for="camp in activeCampaignListFiltered"
        :key="camp.cid"
        @click="selectCampaign(camp)"
      >
        <div class="multibar row-vcentered">
          <b-icon
            :class="`text-color-${camp.color} start`"
            pack="ubu"
            icon="ubu-custom-influence"
          />

          <p class="text start">{{ camp.name }}</p>
        </div>
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheActiveCampaignDropdown',
  mixins: [FilterEngineMixin],
  model: {
    prop: 'selectedCampaign',
  },
  props: {
    selectedCampaign: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      search: '',
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      _activeCampaignList: 'TheActiveCampaignDropdown/activeCampaignList',
    }),

    activeCampaignListFiltered() {
      const activeCampaignList = Object.values(this._activeCampaignList);

      if (!activeCampaignList.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByName = this.search;
      if (this.selectedCampaign) filtersToApply.removeSelected = this.selectedCampaign;

      return this.filterEngine(activeCampaignList, filtersToApply)
        .sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
    },

    _filterCollection() {
      return {
        removeSelected(campaign) {
          return (m) => (m.cid !== campaign.cid ? m : undefined);
        },
      };
    },

  },
  methods: {
    selectCampaign(campaign) {
      this.$emit('input', campaign);
      this.search = '';
    },
  },
};
</script>
