<template>
  <fieldset>
    <!-- <DevDetails :cnt="{name: 'salesContact', obj: salesContact}" />
    <br> -->

    <b-field
      label="location"
      horizontal
    >
      <UbuAutocomplete
        v-model="zoneFilter"
        :options="filteredZonePreset"
        placeholder="Type a geographical zone"
        @input="updateZoneOnAutocomplete($event)"
      />
    </b-field>

    <b-field
      label="language"
      horizontal
    >
      <UbuAutocomplete
        v-model="languageFilter"
        :options="filteredLanguagePreset"
        placeholder="Type a language"
        @input="updateLanguageOnAutocomplete($event)"
      />
    </b-field>

    <b-field
      label="scraping zone"
      horizontal
      :message="[
        {
          'This scraping zone does not exists ! You must create it before using':
            scrapingZoneFilter.selected && !selectedScrapingZoneExists
        }
      ]"
    >
      <UbuAutocomplete
        v-model="scrapingZoneFilter"
        :options="filteredScrapinZonePreset"
        placeholder="Type a scraping zone"
        required
        @input="updateScrapingZoneOnAutocomplete($event)"
      />
    </b-field>

    <b-field
      label="review"
      horizontal
      :message="[
        { 'You change scraping zone, it must be reviewed again later': isVerifiedIsLocked }
      ]"
    >
      <b-field>
        <b-radio-button
          v-model="salesContact.isVerified"
          :native-value="null"
          type="is-dark"
          @input="$update('isVerified',$event)"
        >
          <span>Not Reviewed</span>
        </b-radio-button>

        <b-radio-button
          v-model="salesContact.isVerified"
          :native-value="true"
          type="is-success"
          @input="$update('isVerified',$event)"
        >
          <b-icon icon="check" />
          <span>Validate</span>
        </b-radio-button>

        <b-radio-button
          v-model="salesContact.isVerified"
          :native-value="false"
          type="is-danger"
          @input="$update('isVerified',$event)"
        >
          <b-icon icon="close" />
          <span>Reject</span>
        </b-radio-button>
      </b-field>
    </b-field>
  </fieldset>
</template>

<script>
/* eslint-disable object-curly-newline */
import VModelMixin from '@dailyplanet/mixins/VModelMixin';
import GeoMixin from '@dailyplanet/mixins/GeoMixin';

export default {
  name: 'UbuSalesContactForm',
  mixins: [VModelMixin, GeoMixin],
  model: {
    prop: 'salesContact',
  },
  props: {
    salesContact: {
      type: Object,
      required: true,
    },
    scrapingZoneList: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      zoneFilter: {
        preset: [
          'Africa',
          'Asia / Australia',
          'Europe',
          'North America',
          'South America',
        ],
        selected: this.salesContact.zone || null,
        value: this.salesContact.zone || '',
      },
      languageFilter: {
        selected: this.salesContact.language || null,
        value: this.salesContact.language || '',
      },
      scrapingZoneFilter: {
        selected: this.salesContact.scrapingZoneSzid || null,
        value: this.salesContact.scrapingZoneSzid || '',
      },
      isVerifiedIsLocked: false,
    };
  },
  methods: {
    updateZoneOnAutocomplete({ selected }) { this.$update('zone', selected); },
    updateScrapingZoneOnAutocomplete({ selected }) {
      this.$update('scrapingZoneSzid', selected);
      if (this.salesContact.scrapingZoneSzid !== selected
      && (this.salesContact.isVerified === true || this.salesContact.isVerified === false)) {
        this.$update('isVerified', null);
        this.isVerifiedIsLocked = true;
      }
    },
    updateLanguageOnAutocomplete({ selected }) { this.$update('language', this.findShortNameFromLongName(selected)); },
  },
};
</script>
