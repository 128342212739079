<template>
  <article
    class="ubuContactPlatformPanelItem"
  >
    <header
      class="multibar row-vcentered clickable"
      @click="toggle()"
    >
      <div class="start multibar column p-0">
        <slot name="headerContent" />
      </div>
      <slot name="headerAction" />
      <b-icon
        class="end"
        pack="ubu"
        :icon="isOpen ? 'ubu-arrow-up' : 'ubu-arrow-right'"
      />
    </header>

    <slot
      v-if="isOpen"
      name="content"
    />
  </article>
</template>

<script>
export default {
  name: 'UbuContactPlatformPanelItemTogglable',
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.$emit('isOpen', this.isOpen);
    },
  },
};
</script>
