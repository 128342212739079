<!-- eslint-disable max-len -->
<template>
  <TheTabsPanel
    v-model="activeTab"
    @goToPreviousTab="goToPreviousTab()"
    @goToStart="closePanel()"
    @close="closePanel()"
  >
    <template #customHeader>
      <header v-if="activeTab.tab === 'root'">
        <p class="subtext">{{ activeTab.subtext }}</p>

        <div class="multibar">
          <h1 class="start title is-1">{{ activeTab.title }}</h1>

          <TheShopDropdown
            class="start ml-2"
            icon-right="ubu-arrow-down"
            button-type="is-ghost is-borderless"
          />
        </div>
      </header>
    </template>

    <template #content>
      <b-tab-item value="root">
        <div
          v-if="activeTab.tab === 'root'"
          class="buttons"
        >
          <UbuActionCard
            v-for="item in navigableItems"
            :key="item.label"
            class="mb-4"
            :label="item.label"
            icon-pack="ubu"
            :icon-left="item.icon"
            expanded
            :disabled="item.isDisabled"
            @click="goToTabByTabPath(item.to)"
          />
        </div>
      </b-tab-item>

      <b-tab-item value="root.createDiscount">
        <TheCreateDiscountCode
          v-if="activeTab.tab === 'root.createDiscount'"
          @changeTab="goToTabByTabPath('root')"
        />
      </b-tab-item>

      <b-tab-item value="root.createAffiliation">
        <TheSetupNewAffiliationCode
          v-if="activeTab.tab === 'root.createAffiliation'"
          :affiliation-code-folder-acfid="affiliationCodeFolderAcfid"
          :contact-platform="contactPlatform"
          :campaign="campaign"
          @closePanel="closeAndRefreshPanel()"
        />
      </b-tab-item>

      <b-tab-item value="root.uploadCsv">
        <template v-if="activeTab.tab === 'root.uploadCsv'">
          <TheCreateAffiliationCodeFromCsv
            :affiliation-code-folder-acfid="affiliationCodeFolderAcfid"
            @input="affiliationCodeFolderAcfid = $event"
            @goToTabByTabPath="goToTabByTabPath($event)"
          />
        </template>
      </b-tab-item>

      <b-tab-item value="root.createFromTextarea">
        <template v-if="activeTab.tab === 'root.createFromTextarea'">
          <TheCreateAffiliationCodeFromTextarea
            :affiliation-code-folder-acfid="affiliationCodeFolderAcfid"
            @input="affiliationCodeFolderAcfid = $event"
            @goToTabByTabPath="goToTabByTabPath($event)"
          />
        </template>
      </b-tab-item>
    </template>
  </TheTabsPanel>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable brace-style */
import { mapGetters } from 'vuex';

export default {
  name: 'TheCreateAffiliationCodePanel',
  props: {
    contactPlatform: {
      type: Object,
      default: null,
    },
    campaign: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeTab: {
        tab: 'root',
        title: 'Actions from shop',
        subtext: 'Add affiliation',
      },
      tabItems: {
        root: {
          tab: 'root',
          title: 'Actions from shop',
          subtext: 'Add affiliation',
        },
        'root.createDiscount': {
          tab: 'root.createDiscount',
          title: 'Create discount code on Shopify',
          subtext: 'Add affiliation',
        },
        'root.createAffiliation': {
          tab: 'root.createAffiliation',
          title: 'Setup new affiliate link',
          subtext: 'Add affiliation',
        },
        'root.uploadCsv': {
          tab: 'root.uploadCsv',
          title: 'Upload .CSV file',
          subtext: 'Add affiliation',
        },
        'root.createFromTextarea': {
          tab: 'root.createFromTextarea',
          title: 'Paste codes',
          subtext: 'Add affiliation',
        },
      },
      affiliationCodeFolderAcfid: null,
    };
  },
  computed: {
    ...mapGetters({
      _currentShop: 'TheCreateAffiliationCodePanel/currentShop',
      _affiliationCodeFolderList: 'TheCreateAffiliationCodePanel/affiliationCodeFolderList',
    }),
    navigableItems() {
      return {
        'root.createAffiliation': {
          label: 'Setup new affiliate link',
          to: 'root.createAffiliation',
          icon: 'ubu-affiliation',
        },
        'root.createDiscount': {
          label: 'Create discount code on Shopify',
          to: 'root.createDiscount',
          icon: 'ubu-add',
          isDisabled: this._currentShop.type !== 'SHOPIFY',
        },
        'root.uploadCsv': {
          label: 'Import codes from .CSV',
          to: 'root.uploadCsv',
          icon: 'ubu-upload',
        },
        'root.createFromTextarea': {
          label: 'Paste codes',
          to: 'root.createFromTextarea',
          icon: 'ubu-copy',
        },
      };
    },
  },
  watch: {
    _currentShop() {
      this.setAffiliationCodeFolder();
    },
  },
  mounted() {
    this.setAffiliationCodeFolder();
  },
  methods: {
    goToPreviousTab() {
      if (this.activeTab.tab === 'root') { return; }
      const tabToGo = this.activeTab.tab.split('.').slice(0, -1).join('.');
      this.activeTab = this.tabItems[tabToGo];
    },

    goToTabByTabPath(tabToGo) { this.activeTab = this.tabItems[tabToGo]; },

    closePanel() { this.$emit('close'); },

    closeAndRefreshPanel() { this.$emit('close'); this.$emit('refresh'); },

    setAffiliationCodeFolder() {
      // auto-select a folder by current shop when shop change
      const sortedFolders = Object.values(this._affiliationCodeFolderList)
        .sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());

      const generalFolder = sortedFolders.find(({ title }) => (title === 'General'))?.acfid;

      // We take the last folder if we don't find the General.
      this.affiliationCodeFolderAcfid = generalFolder || sortedFolders.shift()?.acfid;
    },
  },
};
</script>
