<template>
  <form @submit.prevent="$emit('triggerForm')">
    <b-message
      v-if="localModel.isCommon"
      type="is-classic"
    >
      <p>This is a global group, it can't be edited</p>
    </b-message>

    <b-field label="Name">
      <b-input
        v-model="localModel.name"
        required
        placeholder="New Group name ..."
        :readonly="localModel.isCommon"
        @input="$update('name', $event)"
      />
    </b-field>

    <template v-if="!localModel.isCommon">
      <br>

      <b-field label="Color">
        <UbuColorPickerInline
          v-model="localModel.color"
          @input="$update('color', String($event))"
        />
      </b-field>
    </template>

    <br>

    <div class="buttons is-right">
      <b-button
        v-if="localModel.mgid && !localModel.isCommon"
        type="is-secondary"
        label="Delete Group"
        icon-pack="ubu"
        icon-left="ubu-trash"
        @click="confirmDelete()"
      />

      <b-button
        type="is-primary"
        label="Save"
        native-type="submit"
      />
    </div>
  </form>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuMemberGroupForm',
  mixins: [VModelMixin],
  model: {
    prop: 'model',
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localModel: {
      get() { return { ...this.model }; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
  methods: {
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: 'Delete Group',
        message: 'Are you sure you want to <b>Delete this group</b>? This cannot be undone.',
        confirmText: 'Delete Group',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('delete'),
      });
    },
  },
};
</script>
