/* eslint-disable max-len */
import moment from 'moment';
import { actions, getters, gettersSelected } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        {
          path: '/campaign',
          text: 'Campaigns',
          icon: 'ubu-influence',
          // activePathList: [
          //   '/influence/overview',
          //   '/influence/people',
          // ],
        },
        {
          path: '/crm',
          text: 'Crm',
          icon: 'ubu-lists',
        },
        {
          path: '/tribe',
          text: 'Tribes',
          icon: 'ubu-tribe',
        },
        {
          path: '/crew',
          text: 'Crew & Rewards',
          icon: 'ubu-love',
          disclaimer: 'Beta',
        },
        {
          path: '/audit',
          text: 'Audit',
          icon: 'ubu-overview',
        },
      ],
    },
  },
  getters: {
    navigation: (state) => state.navigation,
    // ...getters.activity,
    ...getters.account,
    // ...getters.campaign,
    ...getters.channelGroup,
    // ...getters.contactGroup,
    ...getters.threadGroup,
    ...getters.template,
    // ...getters.influence,
    ...gettersSelected('activity', ['activityStatusListFiltered']),
    ...gettersSelected('campaign', ['campaignList', 'currentCampaignCid']),
    // ...gettersSelected('userDetail', ['currentUserDetail']),
    ...gettersSelected('influence', ['activityStatusList']),
    ...gettersSelected('contactGroup', ['contactGroupList']),
    groupedActiveMenuItems: (_state, _getters, _rootState, rootGetters) => {
      const {
        'Sequence/isAllCustomFetched': isAllCustomFetched,
        'ApiInfluence/campaign/getterCampaignList': campaignList,
        'ApiTribe/tribe/getterTribeList': tribeList,
      } = rootGetters;

      if (!isAllCustomFetched || (!campaignList && !tribeList)) {
        const loadingItem = {
          loading: true,
        };
        const loadingItemList = [loadingItem, loadingItem];
        return {
          tribes: loadingItemList, campaigns: loadingItemList,
        };
      }

      const campaigns = Object.values(campaignList).reduce((acc, campaign) => {
        if (campaign.endedOn) return acc;
        acc.push({
          path: `/campaign/${campaign.cid}`,
          text: campaign.name,
          icon: 'ubu-custom-influence',
          isEnded: !!campaign.endedOn,
          iconColor: campaign.color,
          createdOn: campaign.createdOn,
          event: 'clickOnCampaign',
          hasBadge: false,
          hasBadgeActive: false,
          counter: null,
        });
        return acc;
      }, [])
        .sort((a, b) => moment(b.createdOn).unix() - moment(a.createdOn).unix());

      const tribes = Object.values(tribeList).map((tribe) => ({
        path: `/tribe/${tribe.tid}`,
        text: tribe.name,
        icon: tribe.icon,
        isEnded: false,
        iconColor: tribe.color,
        createdOn: tribe.createdOn,
        event: 'clickOnTribe',
        hasBadge: false,
        hasBadgeActive: false,
        counter: null,
      }))
        .sort((a, b) => moment(b.createdOn).unix() - moment(a.createdOn).unix());

      return {
        tribes,
        campaigns,
      };
    },
  },
  actions: {
    ...actions.activity,
    ...actions.bulkActivity,
    ...actions.campaign,
    ...actions.channelGroup,
    ...actions.contactGroup,
    ...actions.threadGroup,
    ...actions.template,
    ...actions.influence,
    ...actions.segment,
  },
};
