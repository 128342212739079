import TheCRM from './TheCRM';

export default [
  {
    path: '/crm',
    name: 'CRM',
    component: TheCRM,
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheInfluence',
    },
  },
];
