<template>
  <div class="ubuTableSkeleton">
    <div
      v-for="rowIndex in 6"
      :key="rowIndex"
      :class="`ubuTableSkeleton_row opacity-${rowIndex}`"
    >
      <div class="ubuTableSkeleton_col">
        <b-skeleton
          circle
          width="32px"
          height="32px"
        />
      </div>
      <div
        v-for="index in 5"
        :key="index"
        class="ubuTableSkeleton_col ubuTableSkeleton_colData"
      >
        <b-skeleton
          width="100%"
          height="32px"
          size="is-large"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuTableSkeleton',
  props: {
  },
};
</script>
