var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type === 'analytic')?[_c('div',{class:['UbuInfluenceCardAnalyticsContainer', {
        'col-1': _vm.vfor === 1,
        'col-2': _vm.vfor === 2,
        'col-3': _vm.vfor === 3,
        'col-4': _vm.vfor === 4,
        'col-5': _vm.vfor === 5,
        'col-6': _vm.vfor === 6,
      }]},[_vm._l((_vm.vfor),function(i){return [(i)?_c('div',{key:i,staticClass:"ubuAnalyticCard"},[_c('b-skeleton',{attrs:{"circle":"","width":"34px","height":"34px","position":"is-centered"}}),_c('b-skeleton',{attrs:{"width":"25%","height":"25px","position":"is-centered"}}),_c('b-skeleton',{attrs:{"width":"60%","height":"20px","position":"is-centered"}}),_c('b-skeleton',{attrs:{"width":"25%","height":"20px","position":"is-centered"}})],1):_vm._e()]})],2)]:(_vm.type === 'mention')?[_c('div',{staticClass:"is-flex"},_vm._l((_vm.vfor),function(i){return _c('div',{key:i,class:['UbuInfluenceMentionsContent', {
          'col-1': _vm.vfor === 1,
          'col-2': _vm.vfor === 2,
          'col-3': _vm.vfor === 3,
          'col-4': _vm.vfor === 4,
          'col-5': _vm.vfor === 5,
          'col-6': _vm.vfor === 6,
          'post': _vm.option === 'post',
          'stories': _vm.option === 'story',
        }]},[_c('b-skeleton',{attrs:{"height":"320px"}})],1)}),0)]:(_vm.type === 'table')?[_c('UbuTableSkeleton',{staticClass:"mt-4"})]:[_c('span',{staticClass:"has-text-secondary subtitle"},[_vm._v("Loading...")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }