<template>
  <b-field
    label="Search query"
    horizontal
  >
    <b-field>
      <p class="control">
        <b-button
          type="is-primary"
          class="is-reverted"
          label="Reset criterias"
          @click="resetFilters()"
        />
      </p>
      <b-select
        v-model="assignment"
        placeholder="Assignment"
      >
        <option value="all">All leads</option>
        <option value="unassigned">Unassigned</option>
        <option value="assignedTo">Assigned to ...</option>
      </b-select>

      <b-select
        v-show="assignment === 'assignedTo'"
        v-model="assignedTo"
        placeholder="Assignment"
      >
        <option
          v-for="(sale, index) in salesUserList"
          :key="index"
          :value="sale.userUid"
        >
          {{ sale.firstName }}
        </option>
      </b-select>

      <UbuAutocomplete
        v-model="zoneFilter"
        :options="filteredZonePreset"
        placeholder="Zone"
      />

      <UbuAutocomplete
        v-model="languageFilter"
        :options="filteredLanguagePreset"
        placeholder="Language"
      />

      <UbuAutocomplete
        v-model="scrapingZoneFilter"
        :options="filteredScrapinZonePreset"
        placeholder="Scraping zone"
      />

      <p class="control">
        <b-button
          :disabled="isLoading"
          :loading="isLoading"
          type="is-info"
          class="is-reverted"
          label="Research"
          icon-left="magnify"
          @click="buildRequest()"
        />
      </p>
    </b-field>
  </b-field>
</template>

<script>
import GeoMixin from '@dailyplanet/mixins/GeoMixin';

export default {
  name: 'UbuLeadsDashboardQueryForm',
  mixins: [GeoMixin],
  props: {
    authenticate: {
      type: Object,
      default: () => ({}),
    },
    salesUserList: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    scrapingZoneList: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      assignment: null,
      assignedTo: this.authenticate.uid || 4,
      zone: null,
      language: null,
      zoneFilter: {
        preset: [
          'Africa',
          'Asia / Australia',
          'Europe',
          'North America',
          'South America',
        ],
        selected: null,
        value: '',
      },
      languageFilter: {
        selected: null,
        value: '',
      },
      scrapingZoneFilter: {
        selected: null,
        value: '',
      },
    };
  },
  methods: {
    buildRequest() {
      const httpQuery = {};
      if (this.assignment === 'unassigned') httpQuery.unassignedOnly = true;
      if (this.assignment === 'assignedTo') httpQuery.assignedAt = this.assignedTo;
      if (this.zoneFilter.selected) httpQuery.zone = this.zoneFilter.selected;
      if (this.scrapingZoneFilter.selected) {
        httpQuery.scrapingZone = this.scrapingZoneFilter.selected;
      }
      if (this.languageFilter.selected) {
        const { selected } = this.languageFilter;

        httpQuery.language = this.findShortNameFromLongName(selected);
      }
      this.$emit('fetchLead', httpQuery);
    },
    resetFilters() {
      this.assignment = null;
      this.assignedTo = this.authenticate.uid || 4;
      this.zone = null;
      this.language = null;
    },
  },
};
</script>
