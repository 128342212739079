/* eslint-disable max-len */
import moment from 'moment';
import { SnackbarProgrammatic as Snackbar } from 'buefy';

// eslint-disable-next-line object-curly-newline
import { dispatchAndForward, genericErrorHandler, genericThenHandler } from '../$utils/helpers';

export default {
  getters: {
    threadGroupList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ThreadGroup/getterThreadGroupList': threadGroupList } = rootGetters;

      return threadGroupList;
    },
  },
  actions: {
    reportThreadGroup({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ThreadGroup/report',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid } },
        textNotifier: 'Successfully get tags.',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report Tags.', indefinite: true }));
    },

    createThreadGroup({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ThreadGroup/create',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
            createdOn: moment().format(),
            deletedOn: null,
          },
        },
        textNotifier: 'create tag groups',
      };

      return dispatchAndForward(action)
        .then(({ response }) => {
          dispatch('Segment/segmentTrack', { event: 'createTag' }, { root: true });

          return response;
        })
        .catch(genericErrorHandler(action, { message: 'Unable to create Tag.', indefinite: true }));
    },

    updateThreadGroup({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ThreadGroup/update',
        context: {
          payload: {
            ...payload,
            channelGroupCgid: currentChannelGroup.cgid,
          },
        },
        textNotifier: 'Tag successfully updated.',
      };

      return dispatchAndForward(action)
        .then(({ response }) => {
          if (response.hasAutoclose) {
            dispatch('Segment/segmentTrack', { event: 'activeAutoClose' }, { root: true });
          }

          return response;
        })
        .catch(genericErrorHandler(action, { message: 'Unable to update Tag.', indefinite: true }));
    },

    deleteThreadGroup({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const action = {
        dispatch,
        target: 'ThreadGroup/delete',
        context: {
          payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload },
        },
      };

      return dispatchAndForward(action)
        .then(() => {
          Snackbar.open({
            message: 'Tag successfully deleted.',
            type: 'is-success',
            position: 'is-top',
          });
        })
        .catch((error) => {
          let message;

          switch (error.message) {
            case 'E_UNPROCESSABLE_API_CONVERSATION_THREAD_GROUP_CANNOT_DELETE_USED_THREAD_GROUP':
              message = 'Unable to delete Tag. Several thread use it, please untag them before retrying to delete this tag.';
              break;
            case 'E_NOT_FOUND_API_CONVERSATION_THREAD_GROUP_TID':
              message = 'Need to reload the page.';
              break;
            default: message = 'Unexpected error'; break;
          }

          Snackbar.open({
            message,
            type: 'is-danger',
            position: 'is-top',
            indefinite: true,
          });
        });
    },

    _assignThreadGroup({ dispatch, rootGetters }, { payload: { rows, tgid }, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ThreadGroup/assign/create',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, tgid, tids: rows.map(({ tid }) => tid) } },
        textNotifier: 'assign tag groups',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to assign Tag.', indefinite: true }));
    },
    _unassignThreadGroup({ dispatch, rootGetters }, { payload: { rows, tgid }, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ThreadGroup/assign/delete',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, tgid, tids: rows.map(({ tid }) => tid) } },
        textNotifier: 'unassign tag groups',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to unassign Tag.', indefinite: true }));
    },
    updateThreadGroupKeywords({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ThreadGroup/autoTag/patch',
        context: { payload: { ...payload, channelGroupCgid: currentChannelGroup.cgid } },
        textNotifier: 'Tag successfully updated.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to update Tag Keywords.', indefinite: true }));
    },
    deleteThreadGroupAutoTag({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ThreadGroup/autoTag/delete',
        context: { payload: { ...payload, channelGroupCgid: currentChannelGroup.cgid } },
        textNotifier: 'AutoTag was successfully deleted.',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to delete AutoTag.', indefinite: true }));
    },
  },
};
