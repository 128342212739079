<template>
  <TheSimplePanel
    closing-way="emit"
    @close="$emit('close')"
  >
    <!-- <div class="multibar row-vcentered">
      <b-button
        class="end is-simple-icon is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
        @click="selectedTask = null"
      />
    </div> -->
    <template #content>
      <fieldset class="fieldset">
        <legend>DETAILS</legend>

        <br>

        <details
          class="JsonReader"
        >
          <summary>
            selectedTask
          </summary>

          <JsonReader :obj="selectedTask" />
        </details>

        <br>

        <b-field
          grouped
          label="participants"
          horizontal
        >
          <b-field>
            <span class="control">
              <b-button
                type="is-light"
                outlined
              >from</b-button>
            </span>

            <span class="control">
              <b-button
                :label="selectedTask.name"
                type="is-ghost"
                expanded
              />
            </span>
          </b-field>

          <b-field>
            <span class="control">
              <b-button
                type="is-light"
                outlined
              >to</b-button>
            </span>
            <span class="control">
              <b-button
                :label="selectedTask.username"
                type="is-ghost"
                expanded
              />
            </span>
          </b-field>
        </b-field>

        <b-field
          v-for="i in timeFields"
          :key="i.field"
          :label="i.label"
          horizontal
        >
          <!-- <b-datetimepicker
            :value="$moment(selectedTask[i.field]).toDate()"
            rounded
            locale="fr-CA"
            :first-day-of-week="1"
            :timepicker="{ enableSeconds: true, hourFormat: '24' }"
            horizontal-time-picker
            :editable="false"
          /> -->
          <b-input
            :value="$moment(selectedTask[i.field]).format(formatDate)"
            readonly
          />
        </b-field>

        <!-- <br> -->

        <b-field
          v-for="i in stringFields"
          :key="i.field"
          :label="i.label"
          horizontal
        >
          <b-input
            :value="selectedTask[i.field]"
            readonly
          />
        </b-field>
      </fieldset>

      <br>

      <fieldset class="fieldset">
        <legend>ACTIONS</legend>

        <br>

        <div class="buttons">
          <!-- <b-button
              :label="selectedTask.error ? 'Retry Task' : 'Retry only allowed on error'"
              :type="selectedTask.error ? 'is-success' : 'is-warning'"
              :disabled="!selectedTask.error"
              expanded
            /> -->

          <b-button
            label="Cancel Activity and Task"
            type="is-danger"
            expanded
            @click="confirmRejection(selectedTask)"
          />
        </div>
      </fieldset>
    </template>
  </TheSimplePanel>
</template>

<script>
import TheMonitoringTaskMixin from '../_mixins/theMonitoringTask.mixins';

export default {
  name: 'TheMonitoringTaskPanel',
  mixins: [TheMonitoringTaskMixin],
  model: {
    prop: 'selectedTask',
  },
  props: {
    selectedTask: {
      type: Object,
      required: true,
    },
  },
};
</script>
