/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  actions: {
    create(_, { payload }) {
      return clark.api.apiContact.contactGroup.assign.post({ payload, httpQuery: {} });
    },

    delete(_, { payload }) {
      return clark.api.apiContact.contactGroup.assign.delete({ payload, httpQuery: {} });
    },

  },
  modules: {},
};
