<template>
  <UbuDropdown
    id="TheActivityDropdown"
    :close-on-click="true"
    :expanded="expanded"
  >
    <template #button>
      <b-tooltip
        type="is-info"
        animated
        position="is-left"
        class="markAsRead is-clickable"
      >
        <template v-slot:content>
          <div class="multibar row-vcentered">
            <span>Previous activities on a campaign</span>
          </div>
        </template>
        <button id="statusButton">
          <b-icon
            class="iconCircle is-primary"
            pack="mdi"
            icon="checkbox-blank-circle"
          />

          <b-icon
            class="iconStatus"
            pack="ubu"
            :icon="statusListArrayMapped[currentStatus].icon"
          />

          <span class="textStatus">
            {{ currentStatus.split('_').join(' ').capitalize() }}
          </span>
        </button>
      </b-tooltip>
    </template>

    <template #content>
      <b-dropdown-item
        v-for="activity in activities"
        :key="activity.aid"
        disabled
      >
        <div class="multibar row-vcentered">
          <b-icon
            :class="`start mr-1 has-text-color-${activity.color}`"
            pack="ubu"
            icon="ubu-custom-influence"
          />
          <span :class="`start mr-1 has-text-color-${activity.color} text-truncate`">
            {{ activity.name }}
          </span>

          <span class="start mr-1 has-text-secondary">|</span>
          <span class="start has-text-secondary">
            {{ activity.status.split('_').join(' ').capitalize() }}
          </span>
        </div>
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ThePastActivitiesDropdown',
  props: {
    activities: {
      type: Array,
      default: () => ([
        // {
        // aid,
        // cid as campaignCid,
        // color,
        // contactCid,
        // endedOn,
        // createdOnOn,
        // cpid,
        // status,
        // name,
        // },
      ]),
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      activityStatusList: 'TheInfluenceMessages/activityStatusList',
    }),

    currentStatus() {
      return this.activities[0].status;
    },

    statusListArrayMapped() {
      return this.activityStatusList.arrayMapper('status');
    },
  },
};
</script>
