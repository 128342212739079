<template>
  <section class="ubuEditCustom is-detailed">
    <form @submit.prevent="updateTemplate()">
      <UbuEditTemplateForm
        v-model="localCustom"
      />

      <br>

      <b-field label="ACTIONS">
        <div class="buttons">
          <b-button
            type="is-success"
            native-type="submit"
            label="Save changes"
            :disabled="!canSave"
          >
            Save changes
          </b-button>
          <b-button
            type="is-danger"
            icon-pack="ubu"
            icon-left="ubu-trash"
            @click="deleteTemplate()"
          >
            Delete
          </b-button>
        </div>
      </b-field>
    </form>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSettingTemplateDetails',
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      custom: {
        title: null,
        text: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      templateList: 'TheTemplateManager/templateList',
    }),
    localCustom: {
      get() { return this.custom.title ? this.custom : this.item; },
      set(val) { this.custom = val; },
    },
    canSave() {
      const { id, name, ...restCustom } = this.localCustom;
      if (JSON.stringify(restCustom) === JSON.stringify(this.templateList[this.item.tid])) return false;
      return true;
    },
  },
  methods: {
    ...mapActions({
      _updateTemplate: 'TheTemplateManager/updateTemplate',
      _deleteTemplate: 'TheTemplateManager/deleteTemplate',
    }),
    updateTemplate() {
      this._updateTemplate({
        payload: {
          tid: this.item.tid,
          templateFolderTfid: null,
          platformName: this.item.platformName,
          title: this.localCustom.title,
          text: this.localCustom.text,
        },
      }).then((updatedTemplate) => {
        this.localCustom = updatedTemplate;
      });
    },
    deleteTemplate() {
      this._deleteTemplate({ payload: { tid: this.item.tid } });
    },
  },
};
</script>
