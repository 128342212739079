/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  actions: {
    reportMention({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'ACTIVITY_MENTION.REPORT')) return undefined;

      return clark.api.apiContact.contactPlatformMention.report({ payload, httpQuery })
        .then(({ results: contactPlatformMentionList, total }) => {
          const contactPlatformOverviewList = Object.values(getters.getterContactPlatformOverviewList).filter(({ cpid }) => payload.cpids.includes(cpid));

          commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
            ...getters.getterContactPlatformOverviewList,
            ...contactPlatformOverviewList.reduce((acc, row) => {
              acc[row.cpid] = row;
              acc[row.cpid].totalMentionList = total;

              // if (!acc[row.cpid].mentionList) acc[row.cpid].mentionList = [];

              // const mentionList = acc[row.cpid].mentionList.arrayMapper('pid');
              // const newMentions = contactPlatformMentionList.reduce((mentions, mention) => {
              //   if (mention.cpid !== row.cpid) return mentions;
              //   if (!mentionList[mention.pid]) mentions.push(mention);
              //   return mentions;
              // }, []);

              // acc[row.cpid].mentionList = Object.values(mentionList).concat(newMentions);

              if (!acc[row.cpid].mentionList) acc[row.cpid].mentionList = {};

              acc[row.cpid].mentionList = {
                ...acc[row.cpid].mentionList,
                ...contactPlatformMentionList.reduce((mentions, mention) => {
                  if (String(mention.cpid) !== String(row.cpid)) return mentions;
                  if (!acc[row.cpid].mentionList[mention.pid]) mentions[mention.pid] = mention;
                  return mentions;
                }, {}),
              };

              return acc;
            }, {}),
          });

          return ({ results: contactPlatformMentionList, total });
        });
    },

    UP_restMentionList({ commit, getters }, { payload }) {
      const contactPlatformOverviewList = Object.values(getters.getterContactPlatformOverviewList).filter(({ cpid }) => payload.cpids.includes(cpid));

      commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
        ...getters.getterContactPlatformOverviewList,
        ...contactPlatformOverviewList.reduce((acc, row) => {
          acc[row.cpid] = row;

          // if (acc[row.cpid].mentionList) acc[row.cpid].mentionList = [];
          if (acc[row.cpid].mentionList) acc[row.cpid].mentionList = {};

          return acc;
        }, {}),
      });
    },

    UP_favMentionList({ commit, rootGetters, getters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const contactPlatformOverviewList = Object.values(getters.getterContactPlatformOverviewList).filter(({ cpid }) => payload.cpids.includes(cpid));

      commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
        ...getters.getterContactPlatformOverviewList,
        ...contactPlatformOverviewList.reduce((acc, row) => {
          acc[row.cpid] = row;

          // if (!acc[row.cpid].mentionList) acc[row.cpid].mentionList = [];

          // const updatedTribeMentionList = acc[row.cpid].mentionList
          //   .reduce((mentions, { pid, favorite, ...rest }) => {
          //     if (!payload.pids.includes(pid)) {
          //       mentions.push({ pid, favorite, ...rest });
          //       return mentions;
          //     }
          //     mentions.push({
          //       ...rest,
          //       pid,
          //       favorite: payload.shouldFav
          //         ? {
          //           channelGroupCgid: currentChannelGroup.cgid,
          //           publicationPid: pid,
          //         }
          //         : {
          //           channelGroupCgid: null,
          //           publicationPid: null,
          //         },
          //     });
          //     return mentions;
          //   }, []);

          // acc[row.cpid].mentionList = updatedTribeMentionList;

          const updatedMentionList = payload.pids.reduce((mentions, pid) => {
            if (!acc[row.cpid].mentionList[pid]) return mentions;
            acc[row.cpid].mentionList[pid].favorite = payload.shouldFav
              ? {
                channelGroupCgid: currentChannelGroup.cgid,
                publicationPid: pid,
              }
              : {
                channelGroupCgid: null,
                publicationPid: null,
              };
            return mentions;
          }, {});
          console.log('🚀 ~ file: mention.js ~ line 116 ~ updatedMentionList ~ updatedMentionList', updatedMentionList);

          acc[row.cpid].mentionList = { ...acc[row.cpid].mentionList, ...updatedMentionList };
          return acc;
        }, {}),
      });
    },
  },
};
