<template>
  <section
    class="ubuMessageWrapper"
    :isMe="isMe"
  >
    <div class="ubuMessageCard">
      <div class="ubuMessageCard-content">
        ACTION_LOG: COMMING SOON
      </div>
      <UbuThreadMessageFooter :message="message" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'UbuThreadMessageActionLog',
  props: {
    isMe: {
      type: Boolean,
      default: false,
      require: true,
    },
    message: {
      type: Object,
      default: () => {},
      require: true,
    },
  },
};
</script>
