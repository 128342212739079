<template>
  <section id="ubuTemplateForm">
    <DevDetails :cnt="{name: 'localModel', obj: localModel}" />

    <b-field label="Title">
      <b-input
        v-model="localModel.title"
        placeholder="Title ..."
        :required="isTitleRequired"
        minlength="3"
        @input="$update('title', $event.trim())"
      />
    </b-field>

    <br>

    <b-field label="Text">
      <div class="ubuTemplateTextArea">
        <textarea
          ref="input"
          v-model="localModel.text"
          v-autogrow
          :required="isTitleRequired"
          class="text is-3"
          placeholder="Text..."
          maxlength="1000"
          @blur="getCaretPosition($event)"
          @input="$update('text', $event.target.value)"
        />

        <div class="multibar row-vcentered">
          <b-dropdown
            position="is-bottom-left"
            append-to-body
            class="start"
          >
            <template #trigger>
              <b-button
                icon-left="ubu-emoji"
                icon-pack="ubu"
                type="is-tertiary-icon"
              />
            </template>

            <b-dropdown-item
              custom
              paddingless
            >
              <VEmojiPicker
                v-click-outside="() => showEmojiPicker = false"
                :dark="isDark"
                :show-search="false"
                :emoji-size="24"
                :emojis-by-row="6"
                @select="selectEmoji($event)"
              />
            </b-dropdown-item>
          </b-dropdown>

          <b-dropdown
            class="start"
            position="is-top-right"
            append-to-body
          >
            <template #trigger>
              <b-button
                icon-left="ubu-template"
                icon-pack="ubu"
                type="is-tertiary-icon"
              />
            </template>

            <b-dropdown-item
              v-for="(template, index) in templateList"
              :key="index"
              @click="putTemplate(template)"
            >
              /{{ template.title }}
            </b-dropdown-item>

            <!-- <b-dropdown-item>
              <b-button
                class="is-ghost no-padding is-borderless"
                label="+ Add template"
                @click="$emit('openTemplateManager')"
              />
            </b-dropdown-item> -->
          </b-dropdown>

          <!-- <b-button
            class="start"
            type="is-tertiary-icon"
            size="is-small"
            @click="selectName()"
          >
            { name }
          </b-button> -->
        </div>
      </div>
    </b-field>
  </section>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';
import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive';
import VModelMixin from '@dailyplanet/mixins/VModelMixin';
import ThemeMixin from '@dailyplanet/mixins/ThemeMixin';

export default {
  name: 'UbuTemplateForm',
  components: {
    VEmojiPicker,
  },
  directives: {
    autogrow: TextareaAutogrowDirective,
  },
  mixins: [VModelMixin, ThemeMixin],
  model: {
    prop: 'model',
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    showControls: {
      type: Boolean,
      default: true,
    },
    isTitleRequired: {
      type: Boolean,
      default: false,
    },
    isTextRequired: {
      type: Boolean,
      default: false,
    },
    templateList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showEmojiPicker: false,
      dropdownOpened: false,
      cursor: 0,
    };
  },
  computed: {
    localModel: {
      get() { return { ...this.model }; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    getCaretPosition(event) {
      this.cursor = event.target.selectionStart;
    },
    selectEmoji(emoji) {
      if (this.localModel.text.length + emoji.length > 1000) return;
      const templateArray = this.localModel.text.split('');
      templateArray.splice(this.cursor, 0, emoji.data);
      this.$update('text', templateArray.join(''));
      this.$refs.input.focus();
      this.showEmojiPicker = false;
    },
    // selectName() {
    //   const templateArray = this.localModel.text.split('');
    //   templateArray.splice(this.cursor, 0, '{name}');
    //   this.$update('text', templateArray.join(''));
    //   this.$refs.input.focus();
    // },
    putTemplate(t) {
      const templateArray = this.localModel.text.split('');
      templateArray.splice(this.cursor, 0, t.text);
      this.$update('text', templateArray.join(''));
      this.$refs.input.focus();
    },
  },
};
</script>
