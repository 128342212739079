<template>
  <TheSimplePanel>
    <template #header>
      <h1 class="title is-1">Edit email</h1>
    </template>

    <template #content>
      <UbuEditEmailForm
        v-if="userDetailTargeted"
        v-model="newEmailEdit"
        :current-email="userDetailTargeted.email"
        @editEmail="editEmail()"
      />
    </template>
  </TheSimplePanel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheSettingProfilePanelEditEmail',
  data() {
    return {
      newEmailEdit: {
        newEmail: null,
        confirmEmail: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheSettingUserSetting/myAuthenticate',
      userDetailTargeted: 'TheSettingUserSetting/userDetailTargeted',
    }),
    currentUid() {
      return this.myAuthenticate.uid;
    },
  },
  mounted() {
    return Promise.all([
      this.getUser({ payload: { uid: this.currentUid }, httpQuery: { userDetail: 'true' } }),
    ]);
  },
  methods: {
    ...mapActions({
      updateChangeEmailFromSelina: 'TheSettingUserSetting/updateChangeEmailFromSelina',
      getUser: 'TheSettingUserSetting/getUser',
    }),
    editEmail() {
      const { newEmail, confirmEmail } = this.newEmailEdit;

      if (newEmail !== confirmEmail) return;

      const payload = { passName: newEmail };

      this.updateChangeEmailFromSelina({ payload })
        .then(() => { this.$router.push('/setting/profile'); });
    },
  },
};
</script>
