var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-table',{staticClass:"ubuTableSetting",attrs:{"data":Object.values(_vm.allChannelGroupAccount),"opened-detailed":_vm.defaultOpenedDetails,"detailed":"","detail-key":"cgid","has-detailed-visible":() => false,"hoverable":"","is-row-selectable":() => true,"show-header":false},on:{"click":function($event){return _vm.toggleOpenedDetails($event)}},scopedSlots:_vm._u([{key:"detail",fn:function(props){return [_c('DevDetails',{attrs:{"cnt":{name: 'children', obj: props.row}}}),_vm._l((props.row.children),function({
        cid, platformName, isConnected, name, addChannel, cmp,
      }){return [(platformName)?_c('div',{key:platformName},[_c('div',{staticClass:"multibar row-vcentered py-3"},[_c('div',{staticClass:"start mr-1"},[_c(`UbuIcon${platformName.capitalize()}`,{tag:"component",attrs:{"font-size":"32px"}})],1),_c('div',{staticClass:"start ml-3"},[_c('p',{class:['text', {
                'has-text-secondary': !isConnected
              }]},[_vm._v(" "+_vm._s(platformName.capitalize())+" ")]),_c('p',{staticClass:"subtext has-text-secondary"},[_vm._v(_vm._s(name))])]),_c('div',{staticClass:"buttons end"},[(platformName === 'INSTAGRAM')?_c('b-button',{attrs:{"label":"Migration","type":"is-danger","tag":"router-link","to":`/admin/account/${_vm.currentAid}/channels/${cid}/migrate`}}):_vm._e(),(isConnected)?_c('b-button',{attrs:{"label":"View details","type":"is-ghost","tag":"router-link","to":`/admin/account/${_vm.currentAid}/channels/${cid}/details/${cmp}`}}):_vm._e(),(isConnected)?_c('b-button',{attrs:{"label":"Ping channel","type":"is-info"}}):_vm._e(),(!isConnected && addChannel)?_c('b-button',{attrs:{"label":"Add channel","type":"is-primary","tag":"router-link","to":{
                path: `/admin/account/${_vm.currentAid}/channels/addChannel${platformName.capitalize()}`,
                query: { channelGroupCgid: props.row.cgid }
              }}}):_vm._e(),_c('b-button',{attrs:{"label":isConnected ? 'Connected' : 'Not connected',"type":isConnected ? 'is-success' : 'is-secondary'}})],1)]),(platformName !== 'SMS')?_c('hr',{staticClass:"is-l"}):_vm._e()]):_vm._e()]})]}}])},_vm._l((_vm.columns),function(column){return _c('b-table-column',{key:column.col,attrs:{"label":column.label,"field":column.col,"cell-class":column.cellClass},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(column.cmp === 'img')?[_c('UbuAvatar',{attrs:{"initial-name":props.row.initials,"size":40}})]:_vm._e(),(column.cmp === 'flat')?[_c('p',{staticClass:"text is-3"},[_vm._v(_vm._s(props.row[column.col]))])]:_vm._e(),(column.cmp === 'action')?[_c('div',{staticClass:"multibar"},[_c('b-icon',{staticClass:"end",attrs:{"pack":"ubu","icon":_vm.defaultOpenedDetails.includes(props.row.value)
            ? 'ubu-arrow-down'
            : 'ubu-arrow-up'}})],1)]:_vm._e()]}}],null,true)})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }