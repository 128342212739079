/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const campaign = {
  get: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}`, method: 'GET', payload, httpQuery, target }),
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign`, method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/`, method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}`, method: 'DELETE', payload, httpQuery, target }),

  overview: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}/overview`, method: 'REPORT', payload, httpQuery, target }),
  },
};

export default campaign;
