<template>
  <TheSimplePanel>
    <template #header>
      <h1 class="title is-1">Edit name</h1>
    </template>

    <template #content>
      <UbuEditEmailForm
        v-if="userDetailTargeted"
        v-model="newEmailEdit"
        :current-email="userDetailTargeted.email"
        @editEmail="editEmail()"
      />
    </template>
  </TheSimplePanel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheUsersUserDetailsPanelEditEmail',
  props: {
    detailledUser: { type: Object, default: () => {} },
  },
  data() {
    return {
      newEmailEdit: {
        newEmail: null,
        confirmEmail: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      userDetailTargeted: 'TheUsers/userDetailTargeted',
    }),
    currentUid() {
      return this.$route.params.uid;
    },
    currentAuthenticateAid() {
      return this.detailledUser.authenticates.find(({ revokedOn }) => revokedOn === null);
    },
  },
  mounted() {
    return Promise.all([
      this.getUser({ payload: { uid: this.currentUid }, httpQuery: { userDetail: 'true' } }),
    ]);
  },
  methods: {
    ...mapActions({
      updateChangeEmailFromKara: 'TheUsers/updateChangeEmailFromKara',
      getUser: 'TheUsers/getUser',
      up_detailledUserEmail: 'TheUsers/up_detailledUserEmail',
      up_detailledUserListEmail: 'TheUsers/up_detailledUserListEmail',
    }),
    editEmail() {
      const { newEmail, confirmEmail } = this.newEmailEdit;

      if (newEmail !== confirmEmail) return;

      const payload = {
        passName: newEmail,
        aid: this.currentAuthenticateAid.aid,
      };

      this.updateChangeEmailFromKara({ payload })
        .then(() => {
          this.up_detailledUserEmail({ payload });
          this.up_detailledUserListEmail({
            payload: { ...payload, uid: this.currentUid },
          });
        })
        .then(() => { this.$router.push(`/users/user/${this.currentUid}/details`); });
    },
  },
};
</script>
