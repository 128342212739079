<template>
  <TheTabsPanel
    v-model="localActiveTab"
    @goToPreviousTab="goToPreviousTab()"
    @goToStart="goToTabByTabPath('root')"
    @close="closePanel()"
  >
    <template #content>
      <b-tab-item value="root">
        <article>
          <div class="buttons">
            <UbuActionCard
              v-for="item in navigableItems"
              :key="item.to"
              class="mb-4"
              :label="item.label"
              icon-pack="ubu"
              :icon-left="item.icon"
              expanded
              :disabled="item.isDisabled"
              @click="handleNavigation(item)"
            />

            <UbuActionCard
              class="mb-4"
              label="Download mentions"
              icon-pack="ubu"
              icon-left="ubu-download"
              expanded
              @click="downloadTaskSelected(localChecked)"
            />

            <UbuActionCard
              v-if="canDisplayMentionActions"
              label="Add these mentions to favorites"
              icon-pack="ubu"
              icon-left="ubu-important"
              expanded
              class="mb-4"
              @click="bulkAddFav()"
            />
          </div>
        </article>
      </b-tab-item>

      <!-- <b-tab-item value="root.downloadMentions">
        <TheDownloadMentions
          v-if="localActiveTab.tab === 'root.downloadMentions' && canDisplayMentionActions"
          v-model="mentionExtrefIdList"
          @done="handleSuccessDownload($event)"
        />
      </b-tab-item> -->

      <TheSendInstantMessage
        v-model="localChecked"
        :active-tab="localActiveTab"
        :tab-items="tabItems"
        @update:activeTab="UP_changeToNewTab($event)"
      />

      <TheBulkChangeStatus
        v-model="localChecked"
        :active-tab.sync="localActiveTab"
        :tab-items="tabItems"
      />

      <TheAddToTribe
        v-model="localChecked"
        :active-tab.sync="localActiveTab"
        :tab-items="tabItems"
      />

      <TheBulkRemoveFromCampaign
        v-model="localChecked"
        :active-tab.sync="localActiveTab"
        :tab-items="tabItems"
      />
    </template>
  </TheTabsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DownloadTaskMixin from '@dailyplanet/mixins/DownloadTaskMixin';

export default {
  name: 'TheCampaignBulkPanel',
  mixins: [DownloadTaskMixin],
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      activeTab: 'TheCampaignBulkPanel/activeTab',
      _checkedRows: 'TheCampaignBulkPanel/checkedRows',
      tabItems: 'TheCampaignBulkPanel/tabItems',
    }),

    localActiveTab: {
      get() { return this.activeTab; },
      set(newValue) { this.UP_activeTab(newValue); },
    },

    localChecked: {
      get() { return this._checkedRows; },
      set(newValue) {
        this.UP_checkedRows(newValue);
        this.$emit('input', newValue);
      },
    },

    canDisplayMentionActions() {
      return this.$route.fullPath.includes('mentions');
    },

    // mentionExtrefIdList() {
    //   return this.localChecked.reduce((acc, { mainMedia, carousel }) => {
    //     if (carousel && carousel.length) {
    //       acc.push(...carousel.map(({ extrefId }) => ({ extrefId })));
    //     }

    //     if (mainMedia) acc.push({ extrefId: mainMedia.extrefId });

    //     return acc;
    //   }, []);
    // },

    navigableItems() {
      return {
        'root.sendMessage': {
          to: 'root.sendMessage',
          icon: 'ubu-compose',
          label: 'Send instant message',
        },
        'root.changeStatus': {
          to: 'root.changeStatus',
          icon: 'ubu-edit',
          label: 'Change status',
        },
        'root.addToTribe': {
          to: 'root.addToTribe',
          icon: 'ubu-tribe',
          label: 'Add to tribe',
        },
        'root.removeFromCampaign': {
          to: 'root.removeFromCampaign',
          icon: 'ubu-trash',
          label: 'Remove from campaign',
        },
      };
    },
  },
  watch: {
    checkedRows() {
      this.UP_checkedRows(this.checkedRows);
    },
  },
  mounted() {
    return Promise.resolve()
      .then(() => this.UP_resetStore())
      .then(() => this.UP_checkedRows(this.checkedRows));
  },
  methods: {
    ...mapActions({
      UP_checkedRows: 'TheCampaignBulkPanel/UP_checkedRows',
      UP_activeTab: 'TheCampaignBulkPanel/UP_activeTab',
      UP_resetStore: 'TheCampaignBulkPanel/UP_resetStore',
      goToPreviousTab: 'TheCampaignBulkPanel/goToPreviousTab',
      goToTabByTabPath: 'TheCampaignBulkPanel/goToTabByTabPath',
      // emitSegmentEvent: 'TheMention/emitSegmentEvent',
      UP_resetNavigation: 'TheCampaignBulkPanel/UP_resetNavigation',
      goToPreviousVisitedTab: 'TheCampaignBulkPanel/goToPreviousVisitedTab',
      UP_changeToNewTab: 'TheCampaignBulkPanel/UP_changeToNewTab',

      _favMentions: 'TheMention/favMentions',
    }),

    closePanel() {
      this.UP_resetStore();
      this.$emit('close');
    },

    handleNavigation(item) {
      this.UP_activeTab(this.tabItems[item.to]);
    },

    handleSuccessDownload() {
      this.goToTabByTabPath('root');
    },

    notifySuccessFav(count) {
      this.goToTabByTabPath('root');
      const message = count === 0 ? 'All mentions were added to favorites' : `${count} mentions were added to favorites`;
      return this.$buefy.snackbar.open({
        message,
        type: 'is-success',
        position: 'is-top',
      });
    },

    bulkAddFav() {
      const publicationsToFav = this.localChecked
        .filter(({ favorite }) => !favorite.publicationPid);

      return this._favMentions({
        payload: { pids: publicationsToFav.map(({ pid }) => pid) },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_favMentionList',
          payload: {
            pids: publicationsToFav.map(({ pid }) => pid),
            shouldFav: true,
            cpids: publicationsToFav.map(({ cpid }) => cpid),
          },
        }],
      })
        .then(() => this.notifySuccessFav(publicationsToFav.length));
    },
  },
};
</script>
