// import moment from 'moment';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    scrapingZoneList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sales/scrapingZone/getterScrapingZoneList': scrapingZone } = rootGetters;

      return scrapingZone;
    },
  },
  actions: {
    reportScrapingZone({ dispatch }, httpQuery) {
      const action = {
        dispatch,
        target: 'Sales/scrapingZone/report',
        context: { httpQuery },
        textNotifier: 'fetch scraping zones',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report Scraping Zones.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
  },
};
