// import moment from 'moment';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    detailledUserList: (_state, _getters, _rootState, rootGetters) => {
      const { 'DetailledUser/getterDetailledUserList': detailledUserList } = rootGetters;

      return detailledUserList;
    },
    detailledUser: (_state, _getters, _rootState, rootGetters) => {
      const { 'DetailledUser/getterDetailledUser': detailledUser } = rootGetters;

      return detailledUser;
    },
  },
  actions: {
    reportDetailledUser({ dispatch }) {
      const action = {
        dispatch,
        target: 'DetailledUser/report',
        context: {},
        textNotifier: 'fetch detailed user',
        retry: {
          onSuccess: true,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report Detailed User.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
    getDetailledUser({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'DetailledUser/get',
        context: {
          payload: { ...payload },
        },
        textNotifier: 'fetch detailed user',
        retry: {
          onSuccess: true,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to get Detailed User.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
    up_userRole({ dispatch }, { userUid, userRoleUrid }) {
      return dispatch('DetailledUser/up_userRole', {
        userUid, userRoleUrid,
      }, { root: true });
    },
    up_detailledUser({ dispatch }, { payload }) {
      return dispatch('DetailledUser/up_detailledUser', { payload }, { root: true });
    },
    up_detailledUserEmail({ dispatch }, { payload }) {
      return dispatch('DetailledUser/up_detailledUserEmail', { payload }, { root: true });
    },
    up_detailledUserList({ dispatch }, { payload }) {
      return dispatch('DetailledUser/up_detailledUserList', { payload }, { root: true });
    },
    up_detailledUserListEmail({ dispatch }, { payload }) {
      return dispatch('DetailledUser/up_detailledUserListEmail', { payload }, { root: true });
    },
    up_addUserInDetailledUserList({ dispatch }, newUser) {
      return dispatch('DetailledUser/up_addUserInDetailledUserList', newUser, { root: true });
    },
  },
};
