<template>
  <div
    id="TheLeadsDashboard"
    class="card"
  >
    <header class="card-header">
      <p class="card-header-title">
        Leads Dashboard
      </p>
      <div class="card-header-icon buttons margined">
        <b-button
          type="is-info"
          :icon-left="filtersVisible ? 'minus' : 'plus'"
          label="database research"
          @click="filtersVisible = !filtersVisible"
        />
        <!-- <b-button
            v-if="isActionVisible('UBU_LEAD.BULK_REFRESH')"
            :loading="isBulkRefreshLoading"
            type="is-link"
            label="refresh leads"
            :disabled="!checkedRows.length"
            @click="bulkRefreshLeads()"
          /> -->

        <!-- <b-button
            type="is-success"
            :icon-left="createVisible ? 'minus' : 'plus'"
            label="add lead"
            @click="createVisible = !createVisible"
          /> -->
        <b-button
          type="is-info"
          :icon-left="settingVisible ? 'minus' : 'plus'"
          label="results filters"
          @click="settingVisible = !settingVisible"
        />

        <b-field class="no-margin">
          <p class="control">
            <b-button type="is-dark">
              Selecteds: {{ checkedRows.length }}
            </b-button>
          </p>
          <p class="control">
            <b-button
              type="is-primary"
              @click="checkedRows = []"
            >
              clear
            </b-button>
          </p>
        </b-field>

        <b-dropdown aria-role="list">
          <template #trigger="{ active }">
            <b-button
              label="Assign to"
              type="is-primary"
              :disabled="!checkedRows.length"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            />
          </template>

          <b-dropdown-item
            v-for="(user, index) in salesUserList"
            :key="index"
            aria-role="listitem"
            @click="assignTo(user, checkedRows)"
          >
            {{ user.firstName }}
          </b-dropdown-item>
        </b-dropdown>

        <download-csv
          class="button is-link"
          :data="exportableToCsvRows"
          name="filename.csv"
          :disabled="!checkedRows.length"
        >
          Download CSV
        </download-csv>
      </div>
    </header>

    <div
      v-show="filtersVisible"
      class="card-content"
    >
      <!-- <b-message
          title="tips"
          type="is-info"
          aria-close-label="Close message"
        >
          <p>
            Please select a filter.
            <strong>
              The research will request database and prefilter results with matching criterias.
            </strong>
          </p>
          <p>
            You may not find what you're looking for,
            try a request with less restrictive parameters.
          </p>
          <p><strong>Filters are cumulatives, all are optionals</strong></p>
          <p>
            A good research option may be to use a large filter,
            then use the inputs on top of each columns to restrict data shown
          </p>
        </b-message> -->
      <fieldset class="fieldset">
        <legend>Database research</legend>
        <UbuLeadsDashboardQueryForm
          :authenticate="getterAuthenticate"
          :sales-user-list="salesUserList"
          :is-loading="isLoading"
          :scraping-zone-list="Object.keys(scrapingZoneList)"
          @fetchLead="fetchLead($event)"
        />
        <p class="has-text-centered">
          {{ queryEfficience }}
        </p>
      </fieldset>
    </div>

    <div
      v-show="settingVisible"
      class="card-content"
    >
      <UbuLeadsDashboardFiltersForm
        :results-count="filteredLeadList.length"
        :date-filters.sync="dateFilters"
        :numbers-filters.sync="numbersFilters"
        :pagin.sync="pagin"
        :columns.sync="columns"
        :zone-filter.sync="zoneFilter"
        :language-filter.sync="languageFilter"
        :scraping-zone-filter.sync="scrapingZoneFilter"
        :scraping-zone-list="Object.keys(scrapingZoneList)"
      />
    </div>

    <!-- <div
        v-if="createVisible"
        class="card-content"
      >
        <p class="subtitle is-5">
          Add a new Lead
        </p>

        <form @submit.prevent="createLead($event)">
          <UbuKaraUbuLeadForm
            v-model="localNewLead"
          />
          <br>
          <div class="buttons is-right">
            <b-button
              type="is-primary"
              native-type="submit"
            >
              Save
            </b-button>
          </div>
        </form>
      </div> -->

    <div
      v-if="Object.keys(detailledLeadList).length"
      class="card-content"
    >
      <!-- <DevDetails :cnt="{ obj: assigneds}" /> -->
      <!-- <b-message
          title="tips"
          type="is-info"
          aria-close-label="Close message"
        >
          <p class="subtext">
            You can sort data by clicking in the column name (header)
          </p>
          <p class="subtext">
            Under header, the black box is an INPUT you can type and it will filter results.
          </p>
        </b-message> -->

      <UbuLeadsDashboardTable
        id="UbuLeadTable"
        :ubu-lead-list="filteredLeadList"
        :checked-rows="checkedRows"
        :columns="columns"
        :loading="isLoading"
        :pagin.sync="pagin"
        @updateLead="updateLead($event)"
        @removeLead="deleteLead({lid: $event})"
        @checkRow="checkRow($event)"
        @checkAllRows="checkAllRows($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import RightMixin from '@dailyplanet/mixins/RightMixin';
import CommonMixin from '@dailyplanet/mixins/CommonMixin';
import GeoMixin from './$mixins/GeoMixin';

export default {
  name: 'TheLeadsDashboard',
  mixins: [RightMixin, GeoMixin, CommonMixin],
  data() {
    return {
      settingVisible: false,
      createVisible: false,
      filtersVisible: true,
      isLoading: false,
      isBulkRefreshLoading: false,
      checkedRows: [],
      pagin: 50,
      columns: {
        picture: { is: 'contact', visible: true },
        username: { is: 'contact', visible: true },
        createdOn: { is: 'contact', visible: true },
        updatedOn: { is: 'contact', visible: true },
        engagement: { is: 'contact', visible: true },
        followers: { is: 'contact', visible: true },
        growth: { is: 'contact', visible: true },
        zone: { is: 'contact', visible: true },
        language: { is: 'contact', visible: true },
        category: { is: 'contact', visible: true },
        'scraping zone': { is: 'contact', visible: true },

        assigned: { is: 'lead', visible: true },
        lid: { is: 'lead', visible: true },
        firstName: { is: 'lead', visible: true },
        lastName: { is: 'lead', visible: true },
        position: { is: 'lead', visible: true },
        email: { is: 'lead', visible: true },
        phone: { is: 'lead', visible: true },
        leadCreatedOn: { is: 'lead', visible: true },
        leadUpdatedOn: { is: 'lead', visible: true },
      },
      zoneFilter: {
        preset: [
          'Africa',
          'Asia / Australia',
          'Europe',
          'North America',
          'South America',
        ],
        selected: null,
        value: '',
      },
      languageFilter: {
        selected: null,
        value: '',
      },
      scrapingZoneFilter: {
        selected: null,
        value: '',
      },
      dateFilters: {
        contactCreatedOn: {
          date: null,
          when: null,
          label: 'contact created on',
        },
        contactUpdatedOn: {
          date: null,
          when: null,
          label: 'contact updated on',
        },
        leadCreatedOn: {
          date: null,
          when: null,
          label: 'lead created on',
        },
        leadUpdatedOn: {
          date: null,
          when: null,
          label: 'lead updated on',
        },
      },
      numbersFilters: {
        engagement: {
          min: null,
          max: null,
        },
        followers: {
          min: null,
          max: null,
        },
        growth: {
          min: null,
          max: null,
        },
      },
      languages: [
        { value: 'fr', display: 'French' },
        { value: 'en', display: 'English' },
        { value: 'de', display: 'Deutch' },
        { value: 'sp', display: 'Spanish' },
        { value: 'it', display: 'Italian' },
        { value: 'pt', display: 'Portuguese' },
        { value: 'jp', display: 'Japanese' },
        { value: 'ch', display: 'Chinese' },
        { value: 'hi', display: 'Hindi' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      salesUserList: 'TheLeadsDashboard/salesUserList',
      detailledLeadList: 'TheLeadsDashboard/detailledLeadList',
      assigneds: 'TheLeadsDashboard/assigneds',
      getterAuthenticate: 'TheAuthenticate/myAuthenticate',
      scrapingZoneList: 'TheSuggestions/scrapingZoneList',
    }),
    localNewLead: {
      get() { return this.getterNewLead; },
      set(newValue) { this.up_newLead(newValue); },
    },
    queryEfficience() {
      const leads = Object.keys(this.detailledLeadList).length;
      if (leads > 5000) {
        return `Your query match with ${leads} leads.
        Try with more criterias to find only what you need.`;
      }
      if (leads === 0) {
        return `No lead found 🙀.
        Try another research !`;
      }
      return `Your query match with ${leads} leads.`;
    },
    filteredLeadList() {
      let rows = Object.values(this.detailledLeadList);

      Object.keys(this.dateFilters).forEach((key) => {
        const { date, when } = this.dateFilters[key];

        if (date && when === 'before') {
          rows = rows.filter((r) => this.$moment(r[key]).isSameOrBefore(this.$moment(date)));
        }

        if (date && when === 'after') {
          rows = rows.filter((row) => this.$moment(row[key]).isSameOrAfter(this.$moment(date)));
        }
      });

      Object.keys(this.numbersFilters).forEach((key) => {
        const { min, max } = this.numbersFilters[key];

        if (min) rows = rows.filter((row) => Number(row[key]) > Number(min));

        if (max) rows = rows.filter((row) => Number(row[key]) < Number(max));
      });

      if (this.zoneFilter.selected) {
        const zone = this.zoneFilter.selected;
        rows = rows.filter((row) => row.zone.toLowerCase().includes(zone.toLowerCase()));
      }

      if (this.languageFilter.selected) {
        const { selected } = this.languageFilter;

        rows = rows.filter((row) => row.language && row.language
          .toLowerCase().includes(this.findShortNameFromLongName(selected).toLowerCase()));
      }

      return rows;
    },
    exportableToCsvRows() {
      const exportable = [...this.checkedRows].map(({
        assignedTo, ...lead
      }) => {
        const owners = (assignedTo || [])
          // eslint-disable-next-line max-len
          .map(({ userUid, assignedOn }, index) => `owner${index}: ${this.convertUidIntoSaleName(userUid)} : ${assignedOn}`);

        const isIoPictureId = !!Number(lead.picture);

        return {
          ...lead,
          picture: isIoPictureId ? `https://io.getubu.com/profile_pic/${lead.picture}` : null,
          humanFollowers: lead.followers ? this.humanizeInteger(lead.followers) : null,
          humanGrowth: lead.growth ? `${parseFloat(lead.growth.toFixed(3))}%` : null,
          humanEngagement: lead.engagement ? `${parseFloat(lead.engagement.toFixed(3))}%` : null,
          owners: owners.join(','),
        };
      });

      return exportable;
    },
  },
  mounted() {
    this.isLoading = true;
    this.sequence()
      .then(() => { this.isLoading = false; });
  },
  methods: {
    ...mapActions({
      sequence: 'TheLeadsDashboard/sequence',
      reportDetailledLead: 'TheLeadsDashboard/reportDetailledLead',
      up_newLead: 'TheLeadsDashboard/up_newLead',
      createLead: 'TheLeadsDashboard/createLead',
      updateLead: 'TheLeadsDashboard/updateLead',
      deleteLead: 'TheLeadsDashboard/deleteLead',
      refreshLeads: 'TheLeadsDashboard/refreshLeads',
      assignSalesUser: 'TheLeadsDashboard/assignSalesUser',
    }),
    fetchLead(httpQuery) {
      this.isLoading = true;
      this.reportDetailledLead(httpQuery)
        .then(() => {
          this.isLoading = false;
          this.settingVisible = true;
        });
    },
    checkAllRows(event) {
      this.checkedRows = [...event];
    },
    checkRow(event) {
      // this.showFilters = false;
      this.checkedRows = [...event];
    },
    assignTo(user, checkedRows) {
      const { userUid } = user;
      const lids = checkedRows.map(({ lid }) => lid);
      return this.assignSalesUser({ userUid, lids, assignedOn: this.$moment().format() })
        .then((assigneds) => {
          console.log('🚀 ~ file: TheLeadsDashboard.vue ~ line 420 ~ .then ~ assigneds', assigneds);
          this.checkedRows = assigneds;
        });
    },
    convertUidIntoSaleName(uid) {
      return this.salesUserList[uid].firstName;
    },
    bulkRefreshLeads() {
      this.isBulkRefreshLoading = true;
      this.refreshLeads(this.checkedRows)
        .then(() => { this.isBulkRefreshLoading = false; });
    },
  },
};
</script>

<style lang="scss">
#TheLeadsDashboard {
  // height: calc(100vh - 52.5px);
  // max-height: calc(100vh - 52.5px);
  overflow-y: auto;
  // width: calc(100vw - 260px);
  & .card-header {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  & .margined > * {
    margin-right: 0.5rem !important;
    // margin-bottom: 0;
  }
  & th.is-wide, td.is-wide {
    min-width: 150px;
  }
  & th {
    white-space: nowrap;
    font-size: var(--disclaimer);
    color: white;
  }
  & td {
    font-size: var(--disclaimer);
    font-weight: 700;
  }
  & td.is-contact {
    // color: rgba(1, 73, 73, 1) !important;
    color: rgb(107, 206, 61);
  }
  & th.is-contact {
    // color: rgba(1, 73, 73, 1) !important;
    color: rgb(107, 206, 61);
  }
  & td.is-lead {
    // color: rgba(2, 47, 129, 1) !important;

    color: rgb(58, 81, 187);
  }
  & th.is-lead {
    // color: rgba(2, 47, 129, 1) !important;

    color: rgb(58, 81, 187);
  }
  & #UbuLeadTable {
    & > div.table-wrapper.has-mobile-cards.has-sticky-header {
      height: calc(100vh - 221px);
      min-width: 500px;
      // & .th-wrap {
      //   color: white !important;
      // }
      & td {
        white-space: nowrap;
      }
    }
  }
}
</style>
