/* eslint-disable max-len */
import BulkActionEngineStore from '@dailyplanet/data-stores/engine-stores/bulkAction';

export default {
  namespaced: true,
  state: {
    ...BulkActionEngineStore.state,
  },
  mutations: {
    ...BulkActionEngineStore.mutations,
  },
  getters: {
    ...BulkActionEngineStore.getters,
  },
  actions: {
    ...BulkActionEngineStore.actions,
  },
};
