/* eslint-disable max-len */
import { mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  mixins: [FilterEngineMixin],
  computed: {
    ...mapGetters({
      selectedCps: 'TheNewAddToCampaign/selectedCps',
      newBulkAction: 'TheNewAddToCampaign/newBulkAction',
      alreadyInOtherCampaignAndWillBeMovedMapped: 'TheNewAddToCampaign/alreadyInOtherCampaignAndWillBeMovedMapped',
      instagramersToSendMapped: 'TheNewAddToCampaign/instagramersToSendMapped',
    }),

    _filterCollection() {
      const { alreadyInOtherCampaignAndWillBeMovedMapped, instagramersToSendMapped } = this;

      return {
        alreadyActiveInOtherCampaign(rawId) {
          return (m) => (m.active && String(m.active.campaignCid) !== String(rawId) ? m : undefined);
        },
        isRemovedFromPreviousCampaign() {
          return (m) => (alreadyInOtherCampaignAndWillBeMovedMapped[m.cpid] ? m : undefined);
        },
        willNotReceiveMessageOrIsNotInstagram() {
          return (m) => (
            !instagramersToSendMapped[m.cpid] || m.contactPlatform.platformName !== 'INSTAGRAM'
              ? m : undefined);
        },
        filterByPlatform(platform) {
          return (m) => (m.contactPlatform.platformName === platform ? m : undefined);
        },
        isNotOnPlatform(platform) {
          return (m) => (m.contactPlatform.platformName !== platform ? m : undefined);
        },
        isRemovedFromPreviousCampaignOrNotInCampaign() {
          return (m) => (
            alreadyInOtherCampaignAndWillBeMovedMapped[m.cpid] || !m.active
              ? m : undefined
          );
        },
        // isNotInSelectedCampaign(rawId) {
        //   return (m) => (
        //     ((m.active && String(m.active.campaignCid) !== String(rawId)) || !m.active)
        //     && ((m.pasts.length && m.pasts.find(({ campaignCid }) => String(campaignCid) !== String(rawId))) || !m.pasts.length)
        //       ? m
        //       : undefined);
        // },
        searchByUsernameNested(rawSearch) {
          const search = rawSearch.toLowerCase();
          return (m) => (m.contactPlatform.username.toLowerCase().includes(search) ? m : undefined);
        },
        canBeAddedToCampaign() {
          return (m) => (m.canBeAddedToCampaign ? m : undefined);
        },
        cantBeAddedToCampaign() {
          return (m) => (!m.canBeAddedToCampaign ? m : undefined);
        },
      };
    },

    campaignListSorted() {
      return Object.values(this.activeCampaignList)
        .sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
    },

    campaignListFiltered() {
      if (!this.campaignListSorted.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByName = this.search;

      return this.filterEngine(this.campaignListSorted, filtersToApply);
    },

    peopleSelectable() {
      if (!this.selectedCps || !this.selectedCps.length) return [];
      return this.selectedCps;
    },

    /* TheAddToCampaignReachMethod */
    peopleReachableOnThisCampaign() {
      const filtersToApply = {
        canBeAddedToCampaign: this.newBulkAction.cid,
        filterByPlatform: 'INSTAGRAM',
      };

      return this.filterEngine(this.peopleSelectable, filtersToApply);
    },

    peopleAddableOnThisCampaign() {
      const filtersToApply = {
        canBeAddedToCampaign: this.newBulkAction.cid,
      };

      return this.filterEngine(this.peopleSelectable, filtersToApply);
    },

    peopleNotAddableOnThisCampaign() {
      const filtersToApply = {
        cantBeAddedToCampaign: this.newBulkAction.cid,
      };

      return this.filterEngine(this.peopleSelectable, filtersToApply);
    },

    peopleNotReachableOnThisCampaign() {
      const filtersToApply = {
        canBeAddedToCampaign: this.newBulkAction.cid,
        isNotOnPlatform: 'INSTAGRAM',
      };

      return this.filterEngine(this.peopleSelectable, filtersToApply);
    },
    /* TheAddToCampaignReachMethod */

    alreadyActiveInOtherCampaign() {
      const filtersToApply = {
        alreadyActiveInOtherCampaign: this.newBulkAction.cid,
      };

      if (this.search) filtersToApply.searchByUsernameNested = this.search;

      return this.filterEngine(this.peopleSelectable, filtersToApply);
    },
    instagramersToReach() {
      const filtersToApply = {
        canBeAddedToCampaign: this.newBulkAction.cid,
        isRemovedFromPreviousCampaignOrNotInCampaign: true,
        filterByPlatform: 'INSTAGRAM',
      };

      if (this.search) filtersToApply.searchByUsernameNested = this.search;

      return this.filterEngine(this.peopleSelectable, filtersToApply);
    },
    toScans() {
      const filtersToApply = {
        canBeAddedToCampaign: this.newBulkAction.cid,
        isRemovedFromPreviousCampaignOrNotInCampaign: true,
      };

      if (this.newBulkAction.reachMethod === 'COLD_OUTREACH') {
        filtersToApply.willNotReceiveMessageOrIsNotInstagram = true;
      }

      if (this.search) filtersToApply.searchByUsernameNested = this.search;

      return this.filterEngine(this.peopleSelectable, filtersToApply);
    },
  },
};
