/* eslint-disable no-multi-spaces */
/* eslint-disable max-len */
// // Influence - Audit
import TheInfluenceAudit from './TheInfluenceAudit';

export default [
  {
    path: '/influence',
    redirect: '/campaign',
  },
  {
    path: '/audit',
    name: 'Audit',
    component: TheInfluenceAudit,
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheInfluence',
    },
  },
];
