/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const campaignMention = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}/campaignMention`, method: 'REPORT', payload, httpQuery, target }),
};

export default campaignMention;
