<template>
  <TheSimplePanel
    closing-way="emit"
    @close="$emit('close')"
  >
    <template #header>
      <h5 class="subtitle has-text-secondary">New hashtag</h5>
      <h1 class="title is-1">What hashtag do you want to follow?</h1>
    </template>

    <template #content>
      <div class="content">
        <b-field
          label="Hashtag name"
          :type="errorOnInput ? 'is-danger' : ''"
          :message="errorOnInput ? errorOnInput : ''"
        >
          <div class="multibar">
            <b-input
              v-model="name"
              class="start"
              placeholder="FashionWeek..."
              autofocus
              required
              @keyup.enter.native="createHashtag()"
            />

            <b-button
              class="end"
              label="Follow hashtag"
              :disabled="!name"
              :loading="isLoading"
              @click="createHashtag()"
            />
          </div>
        </b-field>
      </div>
    </template>
  </TheSimplePanel>
</template>

<script>
import { mapActions } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheHashtagsCreatePanel',
  data() {
    return {
      name: '',
      isLoading: false,
    };
  },
  computed: {
    errorOnInput() {
      const reg = /^[a-zA-Z0-9_]*$/;

      if (this.name.includes(' ')) return 'Unallowed space!';
      if (!this.name.match(reg)) return 'Unallowed character!';

      return null;
    },
  },
  methods: {
    ...mapActions({
      _createHashtag: 'TheHashtags/createHashtag',
    }),
    createHashtag() {
      if (!this.name || !this.name.length || this.errorOnInput) return undefined;

      this.isLoading = true;

      return this._createHashtag({
        payload: {
          hashtag: this.name.toLowerCase(),
          createdOn: this.$moment().format(),
          deletedOn: null,
        },
      })
        .then(() => {
          this.$buefy.snackbar.open({
            ...snackbarSuccess,
            message: 'Success! Posts will be added when they’re ready.',
          });

          this.isLoading = false;
          this.$emit('close');
        });
    },
  },
};
</script>
