<template>
  <b-message type="is-classic">
    <p
      v-if="subtitle"
      class="subtitle has-text-secondary mb-1"
    >
      {{ subtitle }}
    </p>
    <p
      v-if="text"
      class="text is-3"
    >
      {{ text }}
    </p>

    <div
      class="multibar row-vcentered mt-2 mb-2"
    >
      <b-button
        type="is-ghost"
        class="start mb-0 is-borderless"
        label="Show people"
        icon-pack="ubu"
        :icon-right="showList ? 'ubu-arrow-right' : 'ubu-arrow-down'"
        @click="showList = !showList"
      />
      <span class="start has-text-secondary-20">|</span>
      <b-button
        type="is-ghost"
        class="start mb-0 is-borderless"
        label="Copy list"
        @click="copyToClipBoard(contactPlatformList.map(({username}) => username))"
      />
    </div>

    <template v-if="showList">
      <div
        v-for="(cp, index) in contactPlatformList"
        :key="index"
      >
        <div class="multibar row-vcentered">
          <component
            :is="`UbuIcon${cp.platformName.capitalize()}`"
            class="start mr-2"
          />
          <UbuThreadListUserPicture
            class="start mr-2"
            :profile-picture="cp.profilePicture"
            :initial-name="cp.username"
            :size="16"
            :is-size="16"
          />
          <p class="start text is-3">{{ cp.username }}</p>
          <b-button
            class="end"
            icon-left="ubu-copy"
            icon-pack="ubu"
            type="is-tertiary-icon"
            @click="copyToClipBoard([cp.username])"
          />
        </div>
        <hr>
      </div>
    </template>
  </b-message>
</template>

<script>
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'UbuMessagePeopleWarning',
  props: {
    subtitle: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    contactPlatformList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showList: false,
    };
  },
  beforeDestroy() {
    this.showList = false;
  },
  methods: {
    copyToClipBoard(usernames) {
      navigator.clipboard.writeText(usernames.join(', '));
      this.$buefy.snackbar.open({
        message: `${usernames.length > 1 ? 'usernames were' : 'username was'} copied to clipboard.`,
        ...snackbarSuccess,
      });
    },
  },
};
</script>
