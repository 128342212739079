/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    helpCenterList: [],
  },
  mutations: {
    SET_HELP_CENTER_LIST(state, newData) { state.helpCenterList = newData; },
    RESET_STORE(state) {
      state.helpCenterList = [];
    },
  },
  getters: {
    getterHelpCenterList: (state) => state.helpCenterList,
  },
  actions: {
    report({ commit }, { payload }) {
      return clark.api.helpCenter.report({ payload })
        .then((helpCenterList) => {
          commit('SET_HELP_CENTER_LIST', helpCenterList);
          return helpCenterList;
        });
    },
  },
};
