<template>
  <TheTabsPanel
    v-model="activeTab"
    @goToStart="closePanel()"
    @close="closePanel()"
  >
    <template #customHeader>
      <header v-if="activeTab.tab === 'root'">
        <p class="subtext">{{ activeTab.subtext }}</p>

        <div class="multibar">
          <h1 class="start title is-1">{{ activeTab.title }}</h1>

          <TheShopDropdown
            class="start ml-2"
            icon-right="ubu-arrow-down"
            button-type="is-ghost is-borderless"
          />
        </div>
      </header>
    </template>
    <template #content>
      <b-tab-item value="root">
        <UbuDateRangePicker
          :max-date="$moment().toDate()"
          :option-date-to-use="optionDateToUse"
          @changeDate="changeDate($event)"
        />

        <br>

        <b-field label="file name">
          <b-input v-model="fileName" />
        </b-field>

        <br>

        <b-button
          label="Export"
          :disabled="!_currentShop || !_currentShop.sid"
          type="is-primary"
          icon-pack="ubu"
          icon-left="ubu-download"
          class="end"
          expanded
          @click="downloadExport()"
        />
      </b-tab-item>
    </template>
  </TheTabsPanel>
</template>

<script>
import download from 'downloadjs';

import { mapActions, mapGetters } from 'vuex';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';

export default {
  name: 'TheAffiliationCodeExportPanel',
  mixins: [TableUtilsMixin],
  data() {
    return {
      activeTab: {
        tab: 'root',
        title: 'Export',
        subtext: 'Affiliation codes',
      },
      tabItems: {
        root: {
          tab: 'root',
          title: 'Export',
          subtext: 'Affiliation codes',
        },
      },
      optionDateToUse: ['previousMonth', 'currentWeek', 'previousWeek', 'currentMonth'],
      fileName: 'ubu-export-affiliation-code',
      allowedFilters: ['order.createdOn'],
      filter: { key: 'order.createdOn', values: [this.$moment().subtract({ month: 1 }).startOf('day'), this.$moment().endOf('day')] },
    };
  },
  computed: {
    ...mapGetters({
      _currentShop: 'TheSettingWorkspaceSetting/currentShop',
    }),
  },
  methods: {
    ...mapActions({
      _downloadExport: 'ApiDownload/affiliationCode',
    }),

    closePanel() {
      this.$emit('close');
    },

    changeDate(event) {
      this.filter.values = [this.$moment(event.value[0]).startOf('day'), this.$moment(event.value[1]).endOf('day')];
    },

    downloadExport() {
      const filtersToUse = [this.filter];

      return this._downloadExport({
        payload: { shopSid: this._currentShop.sid },
        httpQuery: {
          filters: this.prepareFiltersToRequest(this.allowedFilters, filtersToUse),
        },
      })
        .then((res) => {
          if (res) download(res, `${this.fileName}.csv`);
          else {
            this.$buefy.snackbar.open({
              type: 'is-success',
              position: 'is-bottom-right',
              message: 'There is no data to export with these filters.',
            });
          }
        });
    },
  },
};
</script>
