import { SnackbarProgrammatic as Snackbar } from 'buefy';
import moment from 'moment';
import { actions, getters, commonState } from '@dailyplanet/data-stores/commonViewStores';
import { dispatchAndForward, dispatchFailure } from '@dailyplanet/data-stores/$utils/helpers';

/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {
    newLead: {
      userame: 'ubu',
      ceoName: '',
      linkedOnUrl: 'http://test.com',
      firstName: '',
      lastName: '',
      position: '',
      zone: '',
      category: '',
      email: 'test@getubu.com',
      isRisky: false,
      phone: '',
    },
    navigation: {
      menuItems: [
        ...commonState.menu.sales.menuItems,
      ],
    },
  },
  mutations: {
    UP_NEW_LEAD(state, newData) { state.newLead = newData; },
  },
  getters: {
    navigation: (state) => state.navigation,
    getterNewLead: (state) => state.newLead,
    ...getters.scrapingZone,
    ...getters.reportDetailledLead,
    ...getters.salesUser,
  },
  actions: {
    ...actions.scrapingZone,
    ...actions.salesContact,
    ...actions.lead,
    ...actions.salesUser,
    ...actions.reportDetailledLead,

    sequence({ dispatch }) {
      return Promise.resolve()
        .then(() => dispatch('TheLeadsDashboard/reportSalesUser', null, { root: true }))
        .then(() => dispatch('TheLeadsDashboard/reportScrapingZone', null, { root: true }));
    },

    assignSalesUser({ dispatch, getters: localGetters }, payload) {
      const alreadyAssigneds = [...new Set(localGetters.assigneds
        .filter((assigned) => assigned.userUid === payload.userUid && moment(assigned.assignedOn).isAfter(moment().subtract({ days: 7 })))
        .map(({ leadLid }) => leadLid))];
      const lidsToAssign = payload.lids.filter((lid) => !alreadyAssigneds.includes(lid));

      if (!lidsToAssign.length) {
        Snackbar.open({
          message: 'Sales Users successfully assigned.',
          type: 'is-success',
          position: 'is-top',
          duration: 5000,
        });
        return [];
      }

      const action = {
        dispatch,
        target: 'Sales/salesUserAssign/create',
        context: { payload: { ...payload, lids: lidsToAssign } },
        textNotifier: 'assign leads',
        retry: {
          onSuccess: true,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .then(({ response: assigneds }) => {
          Snackbar.open({
            message: 'Sales Users successfully assigned.',
            type: 'is-success',
            position: 'is-top',
            duration: 5000,
          });
          return assigneds;
        })
        .catch(() => {
          Snackbar.open({
            message: 'Unable to assign Sales Users.',
            type: 'is-danger',
            position: 'is-top',
            duration: 5000,
          });

          return dispatchFailure(action);
        });
    },
  },
  modules: {},
};
