/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {},
  getters: {
    ...getters.channelGroup,
    ...getters.account,
    ...getters.campaign,
    ...getters.shopify,
    ...getters.shop,
  },
  actions: {
    ...actions.channelGroup,
    ...actions.campaign,
    ...actions.shopify,
    ...actions.shop,
    ...actions.segment,
  },
};
