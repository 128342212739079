/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  actions: {
    migrateCommon(_, { payload, httpQuery }) {
      return clark.api.migrate.common({ payload, httpQuery });
    },
    migrateProto(_, { payload, httpQuery }) {
      return clark.api.migrate.proto({ payload, httpQuery });
    },
    migrateVarious(_, { payload, httpQuery }) {
      return clark.api.migrate.various({ payload, httpQuery });
    },
  },
};
