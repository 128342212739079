<template>
  <aside
    id="TheTopBar"
    class="multibar row-vcentered"
  >
    <TheNotifierLast class="start" />

    <p>
      Build v{{ appVersion }}
      from {{ $moment(appBuildDate).format('YYYY-MM-DD HH:mm:ss') }}
    </p>

    <!-- <TheAuthenticateLogin class="end" /> -->
  </aside>
</template>

<script>
const {
  VUE_APP_BUILD_DATE: appBuildDate,
  VUE_APP_VERSION: appVersion,
} = process.env;
export default {
  name: 'TheTopBar',
  data() {
    return {
      appBuildDate,
      appVersion,
    };
  },
};
</script>

<style lang="scss">
#TheTopBar {
  padding: 10px;
  background-color: var(--givry);
  width: 100%;
  position: sticky;
  top: 0;
}
</style>
