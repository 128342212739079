/* eslint-disable no-multi-spaces */
import TheAnalytics from './TheAnalytics';
import TheAnalyticsAccount from './TheAnalyticsAccount';
import TheAnalyticsInbox from './TheAnalyticsInbox';

// import TheAnalyticsShopify from './TheAnalyticsShopify';
import TheAnalyticsAffiliation from './TheAnalyticsAffiliation';

import TheActivityMention from './TheActivityMention';

import TheHashtags from './TheHashtags';
import TheHashtagsGalleryView from './TheHashtagsGalleryView';
import TheHashtagsDetailedView from './TheHashtagsDetailedView';

import TheAnalyticsAffiliationChart from './views/TheAnalyticsAffiliationChart';
import TheAnalyticsAffiliationBoard from './views/TheAnalyticsAffiliationBoard';

export default [
  {
    path: '/activity',
    redirect: '/activity/analytics/account',
  },
  {
    path: '/activity/analytics/affiliation',
    redirect: '/activity/analytics/affiliation/chart',
  },
  {
    path: '/activity/analytics',
    redirect: '/activity/analytics/account',
    name: 'Activity - Analytics',
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheActivity',
    },
    component: TheAnalytics,
    children: [
      {
        path: '/activity/analytics/account',
        name: 'Activity - Analytics Account',
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheActivity',
          view: 'account',
        },
        components: {
          accountView: TheAnalyticsAccount,
        },
      },
      {
        path: '/activity/analytics/inbox',
        name: 'Activity - Analytics Inbox',
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheActivity',
          view: 'inbox',
        },
        components: {
          inboxView: TheAnalyticsInbox,
        },
      },
      {
        path: '/activity/analytics/affiliation',
        name: 'Activity - Analytics Affiliation',
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheActivity',
          view: 'shopify',
        },
        components: {
          affiliationView: TheAnalyticsAffiliation,
        },
        children: [
          {
            path: '/activity/analytics/affiliation/chart',
            name: 'Activity - Analytics - Affiliation - Chart',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheActivity',
              viewType: 'chart',
            },
            component: TheAnalyticsAffiliationChart,
          },
          {
            path: '/activity/analytics/affiliation/board',
            name: 'Activity - Analytics - Affiliation - Board',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheActivity',
              viewType: 'board',
            },
            component: TheAnalyticsAffiliationBoard,
          },
        ],
      },
    ],
  },
  {
    path: '/activity/mentions',
    name: 'Activity - Mentions',
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheActivity',
    },
    component: TheActivityMention,
  },
  {
    path: '/activity/hashtags',
    name: 'Activity - Hashtags',
    redirect: '/activity/hashtags/gallery-view',
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheActivity',
    },
    component: TheHashtags,
    children: [
      {
        path: '/activity/hashtags/gallery-view',
        name: 'Activity - Hashtags Gallery View',
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheActivity',
          view: 'gallery',
        },
        components: {
          galleryView: TheHashtagsGalleryView,
        },
      },
      {
        path: '/activity/hashtags/detailed-view',
        name: 'Activity - Hashtags Detailed View',
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheActivity',
          view: 'detailed',
        },
        components: {
          detailedView: TheHashtagsDetailedView,
        },
      },
    ],
  },
];
