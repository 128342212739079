/* eslint-disable quotes */
/* eslint-disable object-curly-newline */

import builder from '../../engine';

const target = 'clark';

const feature = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/feature/`, method: 'REPORT', payload, httpQuery, target }),
};

export default feature;
