import TheApiKey from './TheApiKey';
import TheApiKeyList from './views/TheApiKeyList';

// documentation
import TheApiKeyHowToUseKey from './views/documentation/TheApiKeyHowToUseKey';
import TheApiKeyEndpoints from './views/documentation/TheApiKeyEndpoints';

export default [
  {
    path: '/api',
    redirect: '/api/list',
  },
  {
    path: '/api',
    name: 'Api Key | Home',
    component: TheApiKey,
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheSetting',
    },
    children: [
      {
        path: '/api/list',
        name: 'Api Key | List',
        component: TheApiKeyList,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
      },

      // documentation
      {
        path: '/api/howToUseApiKey',
        name: 'Api Key | How to use api key ?',
        component: TheApiKeyHowToUseKey,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
      },
      {
        path: '/api/endpoints',
        name: 'Api Key | Endpoints',
        component: TheApiKeyEndpoints,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
        },
      },
    ],
  },
];
