<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        :to="`/admin/account/${currentAid}/channels`"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <h2 class="title is-1">Details channel Instagram</h2>

    <br>
    <DevDetails :cnt="{ name: 'account', obj: account }" />
    <DevDetails :cnt="{ name: 'channelInstagram', obj: channelInstagram }" />

    <template v-if="channelInstagram">
      <section>
        <div class="is-flex is-justify-content-center">
          <component
            :is="`UbuIcon${channelInstagram.platformName.capitalize()}`"
            style="padding-top: 4px;"
          />
          <p
            class="has-text-secondary"
          >
            @{{ channelInstagram.contactPlatform.username }}
          </p>
        </div>

        <br>

        <fieldset>
          <b-field label="ChannelCid">
            <b-input
              v-model="channelInstagram.cid"
              readonly
            />
          </b-field>

          <br>

          <b-field label="ChannelGroupCgid">
            <b-input
              v-model="channelInstagram.channelGroupCgid"
              readonly
            />
          </b-field>

          <br>

          <b-field label="ContactPlatformCpid">
            <b-input
              v-model="channelInstagram.contactPlatformCpid"
              readonly
            />
          </b-field>

          <br>

          <b-field label="PlatformName">
            <b-input
              v-model="channelInstagram.platformName"
              readonly
            />
          </b-field>

          <br>

          <b-field label="Name">
            <b-input
              v-model="channelInstagram.name"
              readonly
            />
          </b-field>

          <br>

          <b-field label="Username">
            <b-input
              v-model="channelInstagram.contactPlatform.username"
              readonly
            />
          </b-field>

          <br>

          <b-field label="FollowerCount">
            <b-input
              v-model="channelInstagram.contactPlatform.followerCount"
              readonly
            />
          </b-field>

          <br>

          <b-field label="Engagement">
            <b-input
              v-model="channelInstagram.contactPlatform.engagement"
              readonly
            />
          </b-field>

          <br>

          <b-field label="Growth">
            <b-input
              v-model="channelInstagram.contactPlatform.growth"
              readonly
            />
          </b-field>
        </fieldset>
      </section>
    </template>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheAdminAccountChannelDetailsInstagram',
  computed: {
    ...mapGetters({
      account: 'TheAdmin/account',
    }),
    currentAid() {
      return this.$route.params.aid;
    },
    currentCid() {
      return this.$route.params.cid;
    },
    channelInstagram() {
      if (!this.account) return null;

      const platform = this.account.channelGroupList
        .reduce((acc, { channelList }) => {
          acc.push(...channelList);
          return acc;
        }, [])
        .arrayMapper('cid');

      return platform[this.currentCid];
    },
  },
  mounted() {
    if (!this.channelInstagram) {
      this.$router.push({
        path: `/admin/account/${this.currentAid}/channels`,
      });
    }
  },
};
</script>
