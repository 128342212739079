<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Icebreaker Builder
      </div>
      <div class="card-header-icon buttons">
        <b-button
          type="is-info"
          icon-left="pencil"
          label="new icebreaker"
          @click="addBlock()"
        />
        <b-button
          type="is-success"
          icon-left="plus"
          label="new block"
          @click="addBlock()"
        />
        <b-button
          type="is-link"
          icon-left="content-save"
          label="save icebreaker"
          @click="addBlock()"
        />
      </div>
    </div>

    <div class="card-content">
      <div
        v-if="iceBreakers.blocks.length"
        class="render"
      >
        <div
          v-for="(template, index) in iceBreakers.blocks"
          :key="index"
        >
          <div v-if="conditionnal(template, row)">
            {{ strTolinkArray(template.text, row).join('') }}
          </div>
        </div>
      </div>
      <b-message
        v-else
        type="is-info"
      >
        <p>No block yet, start creating now !</p>
        <p>Just click "+ new block"</p>
      </b-message>
    </div>

    <!-- <DevDetails
      :cnt="{name: 'iceBreakers', obj: iceBreakers}"
    /> -->

    <div class="card-content">
      <b-collapse
        v-for="(template, index) in sortedBlocks"
        :key="index"
        class="card"
        animation="slide"
        :open="true"
      >
        <template #trigger="props">
          <div
            class="card-header"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title">
              <b-tag
                type="is-dark"
                ellipsis
                class="is-expanded is-radiusless"
                expanded
              >
                {{ template.text }}
              </b-tag>
            </p>
            <div class="card-header-icon">
              <div class="buttons no-margin-bottom">
                <b-field>
                  <p class="control">
                    <b-button
                      type="is-light is-outlined is-rounded"
                      size="is-small"
                      icon-left="chevron-down"
                      label="move down"
                      @click="moveBlockUp(template)"
                    />
                  </p>
                  <p class="control">
                    <b-button
                      type="is-light is-outlined is-rounded"
                      size="is-small"
                      icon-left="chevron-up"
                      label="move up"
                      @click="moveBlockDown(template)"
                    />
                  </p>
                </b-field>
                <b-button
                  type="is-dark is-light is-outlined"
                  size="is-small"
                  :icon-left="props.open ? 'menu-down' : 'menu-up'"
                />
              </div>
            </div>
          </div>
        </template>

        <div class="card-content">
          <UbuKaraTemplateForm
            v-model="iceBreakers.blocks[index]"
            :ordinal-max="iceBreakers.blocks.length"
            :variables="row"
          />
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'TheUbuLeadTemplates',
  data() {
    return {
      row: {
        lid: '4',
        linkedInUrl: 'https://www.linkedin.com/in/francescalange/?miniProfileUrn=urn3Ali3Afs_miniProfile3AACoAAAH9NgQBXKgLIfk5PdKFsRrWwMD3kzzZGYw',
        firstName: 'Francesca ',
        lastName: null,
        position: 'Head of Social',
        email: 'francesca.lange@rossmann.de',
        phone: 'maybe',
        isRisky: false,
        leadCreatedOn: '2021-09-09T07:27:10.000Z',
        leadUpdatedOn: '2021-09-09T07:27:10.000Z',
        username: 'rossmann_babywelt',
        platformName: 'INSTAGRAM',
        followers: '150',
        engagement: 1.4399778,
        growth: 2.3,
        picture: null,
        zone: 'Europe',
        country: null,
        category: null,
        contactCreatedOn: '2021-09-09T07:27:10.000Z',
        contactUpdatedOn: '2021-09-09T07:27:10.000Z',
        // assignedTo: [
        //   {
        //     assignedOn: '2021-09-15T21:26:16.854953+02:00',
        //     firstName: 'Paul',
        //     lastName: 'Albou',
        //     squad: 'Europe',
        //     userUid: 4,
        //   },
        // ],
      },
      iceBreakers: {
        meta: {
          title: '',
        },
        blocks: [
          // {
          //   id: uuidv4(),
          //   ordinal: 0,
          //   text: 'You have  {{ followers }} less than 200 ordinal 0',
          //   type: 'conditionnal',
          //   condition: {
          //     key: 'followers',
          //     sign: '<',
          //     value: '200',
          //   },
          // },
          // {
          //   id: uuidv4(),
          //   ordinal: 1,
          //   text: 'You have  {{ followers }} more than 200 ordinal 1',
          //   type: 'conditionnal',
          //   condition: {
          //     key: 'followers',
          //     sign: '>',
          //     value: '200',
          //   },
          // },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      authenticate: 'TheAuthenticate/myAuthenticate',
    }),
    sortedBlocks() {
      const myBlock = [...this.iceBreakers.blocks];
      return myBlock.sort((a, b) => a.ordinal - b.ordinal);
    },
  },
  methods: {
    addBlock() {
      this.iceBreakers.blocks.push({
        id: uuidv4(),
        ordinal: this.iceBreakers.blocks.length,
        text: '',
        type: 'regular',
      });
    },
    moveBlockUp(template) {
      const myBlocks = [...this.iceBreakers.blocks];
      const templateIndex = myBlocks.findIndex(({ id }) => id === template.id);
      myBlocks.splice(templateIndex, 1);
      myBlocks.splice(templateIndex + 1, 0, template);

      this.iceBreakers.blocks = myBlocks.map((block, index) => ({ ...block, ordinal: index }));
    },
    moveBlockDown(template) {
      const myBlocks = [...this.iceBreakers.blocks];
      const templateIndex = myBlocks.findIndex(({ id }) => id === template.id);
      myBlocks.splice(templateIndex, 1);
      myBlocks.splice(templateIndex - 1, 0, template);

      this.iceBreakers.blocks = myBlocks.map((block, index) => ({ ...block, ordinal: index }));
    },
    conditionnal(template, values) {
      const { type, condition } = template;
      if (type === 'regular') return true;
      const key = values[condition.key];
      switch (condition.sign) {
        case '>': return key > Number(condition.value);
        case '>=': return key >= Number(condition.value);
        case '=': return key === Number(condition.value);
        case '<=': return key <= Number(condition.value);
        case '<': return key < Number(condition.value);
        default: return false;
      }
    },
    strTolinkArray(str, values) {
      const regex = /(\{\{ ([\w.]*) \}\})/ig;
      const matches = str.match(regex);

      if (!matches) return [str];
      let indexLink = 0;

      return str.replace(regex, '$interpolation').split(/(?=\$interpolation)|(?<=\$interpolation)/g).map((currentStr) => {
        if (currentStr !== '$interpolation') return currentStr;
        const currentLink = matches[indexLink] || null;
        if (!currentLink) return currentStr;
        const value = currentLink.replace('{{', '').replace('}}', '').trim();
        indexLink += 1;
        if (value === 'myName') return this.authenticate.userDetail.firstName;
        return values[value] || 'UNFOUNDABLE_VARIABLE';
      });
    },
  },
};
</script>

<style lang="scss">
.buttons.no-margin-bottom {
  & .field {
    margin-bottom: 0;
    margin-right: 10px;
  }
}
.render {
  padding: 20px;
  background-color: var(--givry);
}
.multibar.is-spaced {
  & > * {
    margin-right: 10px;
  }
}
.tag.is-expanded {
  max-width: 500px;
}
</style>
