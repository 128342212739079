import { clark } from '@dailyplanet/providers';
// import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    report(_, { payload, httpQuery = {} }) {
      return clark.api.apiInfluence.activity.report({ payload, httpQuery })
        .then((activities) => activities);
    },

    create({ dispatch }, { payload }) {
      return clark.api.apiInfluence.activity.post({ payload, httpQuery: {} })
        .then(() => dispatch('Campaign/reportDetailledCampaign/report', { payload: { cgid: payload.cgid, cid: payload.cid } }, { root: true }));
    },
    update({ dispatch }, { payload }) {
      return clark.api.apiInfluence.activity.put({ payload, httpQuery: {} })
        .then((activity) => dispatch('ApiInfluence/reportDetailledCampaign/up_activityFromCampaignDetailled', activity, { root: true }));
    },
    patch(_, { payload }) {
      return clark.api.apiInfluence.activity.patch({ payload, httpQuery: {} });
    },
    delete({ dispatch }, { payload }) {
      return clark.api.apiInfluence.activity.delete({ payload, httpQuery: {} })
        .then(() => dispatch('Campaign/reportDetailledCampaign/report', { payload: { cgid: payload.cgid, cid: payload.cid } }, { root: true }));
    },
  },
};
