/* eslint-disable max-len */
import moment from 'moment';

import { dispatchAndForward, genericErrorHandler, genericThenHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    currentCampaignCid: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiInfluence/campaign/getterCurrentCampaignCid': currentCampaignCid } = rootGetters;
      console.log('🚀 ~ file: campaign.js ~ line 10 ~ currentCampaignCid', currentCampaignCid);

      return currentCampaignCid;
    },
    currentCampaign: (_state, getters, _rootState, rootGetters) => {
      const { currentCampaignCid } = getters;

      const { 'ApiInfluence/campaign/getterCampaignList': campaignList } = rootGetters;

      return campaignList[currentCampaignCid];
    },
    campaignList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiInfluence/campaign/getterCampaignList': campaignList } = rootGetters;

      return campaignList;
    },
    activeCampaignList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiInfluence/campaign/getterCampaignList': campaignList } = rootGetters;

      return Object.values(campaignList).filter(({ endedOn }) => !endedOn).arrayMapper('cid');
    },
    campaignDetails: (_state, getters, _rootState, rootGetters) => {
      const { currentCampaignCid } = getters;

      const { 'ApiInfluence/campaign/reportDetailledCampaign/getterCampaignDetailled': campaignDetails } = rootGetters;
      if (!campaignDetails) return [];

      return campaignDetails.filter(({ campaign }) => campaign.cid === currentCampaignCid);
    },
    campaignStatus: (_state, getters) => {
      const { campaignDetails } = getters;
      if (!campaignDetails) return [];

      return campaignDetails.reduce((acc, { activity: { status } }) => (acc.includes(status) ? acc : [...acc, status]), []);
    },
    activityByStatus: (_state, getters) => {
      const { campaignDetails } = getters;
      if (!campaignDetails) return {};

      return campaignDetails.reduce((acc, row) => {
        const { activity: { status } } = row;

        return acc[status] ? { ...acc, [status]: [...acc[status], row] } : { ...acc, [status]: [row] };
      }, {});
    },
  },
  actions: {
    getCampaign({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiInfluence/campaign/get',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'report campaign',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report campaign list.', indefinite: true }));
    },

    reportCampaign({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiInfluence/campaign/report',
        context: { payload: { cgid: currentChannelGroup.cgid } },
        textNotifier: 'report campaign',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report campaign list.', indefinite: true }));
    },

    createCampaign({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiInfluence/campaign/create',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
            createdOn: moment().format(),
            updatedOn: moment().format(),
            endedOn: null,
          },
        },
        textNotifier: 'create campaign',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .then((response) => {
          dispatch('Segment/segmentTrack', { event: 'createCampaign' }, { root: true });
          return response;
        })
        .catch(genericErrorHandler(action, { message: 'Unable to create campaign.', indefinite: true }));
    },

    updateCampaign({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiInfluence/campaign/update',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid, updatedOn: moment().format() } },
        textNotifier: 'update campaign',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to update campaign.', indefinite: true }));
    },

    endCampaign({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiInfluence/campaign/delete',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid } },
        textNotifier: 'end campaign',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to end campaign.', indefinite: true }));
    },
  },
};
