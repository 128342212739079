export default {
  namespaced: true,
  state: {
    languages: [
      { value: 'fr', display: 'French' },
      { value: 'en', display: 'English' },
      { value: 'de', display: 'Deutch' },
      { value: 'sp', display: 'Spanish' },
      { value: 'it', display: 'Italian' },
      { value: 'pt', display: 'Portuguese' },
      { value: 'jp', display: 'Japanese' },
      { value: 'ch', display: 'Chinese' },
      { value: 'hi', display: 'Hindi' },
    ],
  },
  mutations: {},
  getters: {
    getterLanguages: (state) => state.languages,
  },
  actions: {},
};
