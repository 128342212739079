import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuKaraUserUserDetailForm from './UbuKaraUserUserDetailForm';
import UbuKaraUserAuthenticateForm from './UbuKaraUserAuthenticateForm';
import UbuKaraDetailledUserTable from './UbuKaraDetailledUserTable';
import UbuKaraDetailledUsersRights from './UbuKaraDetailledUsersRights';
import UbuKaraDetailledUserTableDetail from './UbuKaraDetailledUserTableDetail';
import UbuKaraDetailledUsersAuthenticates from './UbuKaraDetailledUsersAuthenticates';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuKaraUserUserDetailForm);
    registerComponent(Vue, UbuKaraUserAuthenticateForm);
    registerComponent(Vue, UbuKaraDetailledUserTable);
    registerComponent(Vue, UbuKaraDetailledUsersRights);
    registerComponent(Vue, UbuKaraDetailledUserTableDetail);
    registerComponent(Vue, UbuKaraDetailledUsersAuthenticates);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuKaraUserUserDetailForm,
  UbuKaraUserAuthenticateForm,
  UbuKaraDetailledUserTable,
  UbuKaraDetailledUsersRights,
  UbuKaraDetailledUserTableDetail,
  UbuKaraDetailledUsersAuthenticates,
};
