<template>
  <div>
    <div class="multibar row-vcentered">
      <span class="start subtext has-text-secondary">{{ title }}</span>
      <b-switch
        v-if="isSwitched !== null"
        v-model="isSwitched"
        class="end"
      >
        Show others
      </b-switch>
    </div>

    <canvas :id="chartId" />
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import { optionBarSimple, calculateStepYAxis } from '../_utils/chartJSParams';

export default {
  name: 'UbuChartMixed',
  model: {
    prop: 'isSwitched',
  },
  props: {
    chartId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      required: true,
    },
    isSwitched: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      baseOptions: optionBarSimple,
      calculateStepYAxis,
    };
  },
  watch: {
    data() {
      if (this.currentChart) this.currentChart.destroy();

      const ctx = document.getElementById(this.chartId);

      // this.baseOptions.options.scales.y.ticks.stepSize = this.calculateStepYAxis(this.data);

      this.currentChart = new Chart(ctx, {
        type: 'scatter',
        options: {
          responsive: true,
          local: 'en-US',

          // plugins: {
          //   tooltip: {
          //     callbacks: {
          //       footer(items) {
          //         console.log('🚀 ~ file: UbuChartMixed.vue ~ line 69 ~ footer ~ items', items);
          //         return `Total: ${items.reduce((a, b) => a + b.parsed.y, 0)}`;
          //       },
          //     },
          //   },
          // },

          // layout: {
          //   padding: 12,
          // },

          // plugins: {
          //   // legend: {
          //   //   display: false,
          //   // },
          // },
          // scales: {
          //   A: {
          //     type: 'linear',
          //     position: 'left',
          //   },
          //   B: {
          //     type: 'linear',
          //     position: 'right',
          //     ticks: {
          //       max: 1,
          //       min: 0,
          //     },
          //   },
          // },
        },
        data: this.data,
      });
    },
  },
  mounted() {
    const ctx = document.getElementById(this.chartId);

    // this.baseOptions.options.scales.y.ticks.stepSize = this.calculateStepYAxis(this.data);

    this.currentChart = new Chart(ctx, {
      options: {
        responsive: true,
        local: 'en-US',

        // plugins: {
        //   tooltip: {
        //     callbacks: {
        //       footer(items) {
        //         console.log('🚀 ~ file: UbuChartMixed.vue ~ line 117 ~ footer ~ items', items);
        //         return `Total: ${items.reduce((a, b) => a + b.parsed.y, 0)}`;
        //       },
        //     },
        //   },
        // },

        // layout: {
        //   padding: 12,
        // },

        // plugins: {
        //   // legend: {
        //   //   display: false,
        //   // },
        // },
      },
      data: this.data,
    });
  },
};
</script>
