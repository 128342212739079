<template>
  <div
    id="TheAuthenticate"
    class="flex"
  >
    <div class="overlay" />

    <div class="ubuPanel ubuPanel-xl">
      <b-image
        class="start mr-2 is-40x40"
        :src="ubuLogo"
      />
      <router-view />
    </div>
  </div>
</template>

<script>
const ubuLogo = require('@dailyplanet/selina/src/assets/images/ubuLogov2.svg');

export default {
  name: 'TheAuthenticate',
  data() {
    return {
      ubuLogo,
    };
  },
};
</script>
