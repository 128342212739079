/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    instagramUsernameList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/searchInstagramUsername/getterSearchInstagramUsername': searchInstagramUsernameList } = rootGetters;

      return searchInstagramUsernameList;
    },
  },
  actions: {
    reportInstagramUsernames({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/searchInstagramUsername/report',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
          },
          httpQuery,
        },
        textNotifier: 'report instagram username',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report instagram username list.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
  },
};
