/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const protoList = {
  report: ({ payload, httpQuery }) => builder({ url: '/api/protoList', method: 'REPORT', payload, httpQuery, target }),
  allow: ({ payload, httpQuery }) => builder({ url: `/api/protoList/${payload.plid}`, method: 'PUT', payload, httpQuery, target }),
  reject: ({ payload, httpQuery }) => builder({ url: `/api/protoList/${payload.plid}`, method: 'DELETE', payload, httpQuery, target }),
};

export default protoList;
