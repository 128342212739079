<template>
  <div>
    <router-view name="detailInstagram" />
    <router-view name="detailFacebook" />
    <router-view name="detailTiktok" />
    <router-view name="detailYoutube" />
    <router-view name="detailSms" />
  </div>
</template>

<script>
export default {
  name: 'TheAdminAccountChannelDetails',
};
</script>
