<!-- eslint-disable max-len -->
<template>
  <section class="mainPanel" style="overflow: auto">
    <header class="mainPanelHeader multibar row-vcentered">
      <h1 class="title is-2 start">Orders</h1>

      <span class="end">Current shop is</span>

      <TheShopDropdown
        class="end"
        icon-right="ubu-arrow-down"
        button-type="is-ghost is-borderless"
      />
    </header>

    <TheEndpointDocumentationBuilder :json-to-build="orderPostJson">
      <template #footer>
        <div class="request-block">
          <div class="request-block-header multibar row-vcentered">
            <span class="start">CurrencyLocale ENUM list</span>
          </div>
          <pre class="request-block-span has-text-black">{{ orderPostJson.currencyEnum.join(',') }}</pre>
        </div>
        <br>
      </template>
    </TheEndpointDocumentationBuilder>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters } from 'vuex';
import orderPostJson from '../../_jsonDocumentation/order.post';

export default {
  name: 'TheApiKeyRoutes',
  data() {
    return {
      orderPostJson,
    };
  },
  computed: {
    ...mapGetters({
      _currentShop: 'TheCreateAffiliationCodePanel/currentShop',
    }),
  },
  watch: {
    _currentShop(newVal, oldVal) {
      if (newVal.sid !== oldVal.sid) this.updateJsonRequestUrl();
    },
  },
  mounted() {
    this.updateJsonRequestUrl();
  },
  methods: {
    updateJsonRequestUrl() {
      // in this case url can change if user select an other shop
      this.orderPostJson.requestUrl = `https://gamma.getubu.com/api/v1/shop/${this._currentShop.sid}/order`;
      this.orderPostJson.requiredParams = {
        shopSid: this._currentShop.sid,
        version: 'v1',
      };
    },
  },
};
</script>
