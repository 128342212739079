<template>
  <fieldset>
    <b-field
      horizontal
      label="first name"
    >
      <b-input
        v-model="userDetail.firstName"
        required
        @input="$update('firstName', $event)"
      />
    </b-field>

    <b-field
      horizontal
      label="last name"
    >
      <b-input
        v-model="userDetail.lastName"
        required
        @input="$update('lastName', $event)"
      />
    </b-field>

    <b-field
      v-if="userRoleList"
      label="user role"
      horizontal
    >
      <b-select
        v-model="userDetail.userRole"
        required
        @input="$update('userRole', $event)"
      >
        <option
          v-for="role in userRoleList"
          :key="role.urid"
          :value="role"
        >
          {{ role.label }}
        </option>
      </b-select>
    </b-field>

    <b-field
      horizontal
      label="Is ubu team ?"
    >
      <b-switch
        v-model="userDetail.isUbu"
        @input="$update('isUbu', $event)"
      />
    </b-field>
  </fieldset>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuKaraUserUserDetailForm',
  mixins: [VModelMixin],
  model: {
    prop: 'userDetail',
  },
  props: {
    userDetail: {
      type: Object,
      required: true,
    },
    userRoleList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
