/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import Errors from '@dailyplanet/utils/Errors';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    account: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/getterAccount': account } = rootGetters;

      return account;
    },
    accountList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/getterAccountList': accountList } = rootGetters;

      return accountList;
    },
    accountChannelGroupList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/getterAccountChannelGroupList': accountList } = rootGetters;

      return accountList;
    },
    accountChannelList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/getterAccountChannelList': accountList } = rootGetters;

      return accountList;
    },
    accountEvent: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/getterAccountEvent': accountEvent } = rootGetters;

      return accountEvent;
    },

    // DEPRECATED, on va utiliser accountFeatures à la place
    accountFeature: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/getterAccountFeature': accountFeature } = rootGetters;

      return accountFeature;
    },

    accountFeatures: (_state, _getters, _rootState, rootGetters) => (feature) => {
      const { 'Account/getterAccountFeatures': accountFeatures } = rootGetters;

      return accountFeatures[feature];
    },
    facebookConnectList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/facebookConnect/getterFacebookConnectList': facebookConnectList } = rootGetters;

      return facebookConnectList;
    },
    isPrivateDisconnected: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/getterIsDisconnectedToPrivate': isPrivateDisconnected } = rootGetters;

      return isPrivateDisconnected;
    },
    accountHasMetaExtension: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/getterAccount': account } = rootGetters;

      if (!account || !account.hasFeature.length) return false;

      const hasMetaExtension = account.hasFeature.find(({ featureName }) => featureName === 'UBU_META_EXTENSION');

      return !!hasMetaExtension;
    },
    currentChannelList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/getterAccount': account, 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      if (!account || !account.channelGroupList.length) return [];

      const mappedChannelGroupList = account.channelGroupList.arrayMapper('cgid');

      const currentChannelList = mappedChannelGroupList[currentChannelGroup.cgid].channelList;

      if (!currentChannelList.length) return [];

      return currentChannelList;
    },
    currentChannelGroupCanUseMetaExtension: (_state, _getters) => {
      const { currentChannelList } = _getters;

      if (!currentChannelList.length) return false;

      const canHaveMetaExtension = currentChannelList.find(({ platformName }) => platformName === 'INSTAGRAM');

      return !!canHaveMetaExtension;
    },
    accountMemberViewList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/accountMemberView/getterAccountMemberViewList': _accountMemberViewList } = rootGetters;
      const accountMemberViewList = Object.values(_accountMemberViewList);

      return accountMemberViewList ? accountMemberViewList.map(({
        activeChannelGroup,
        activeChannel,
        channelGroupList,
        channelList,
        ...member
      }) => ({
        ...member,
        activeChannelGroup: activeChannelGroup || [],
        activeChannel: activeChannel || [],
        channelGroupList: channelGroupList || [],
        channelList: channelList || [],
      })) : [];
    },
    isAdminOnAccount: (_state, _getters, _rootState, rootGetters) => {
      const { 'Authenticate/getterAuthenticate': myAuthenticate } = rootGetters;
      const { account } = _getters;

      if (!account || !account.userList) return false;
      const user = account.userList.find((_user) => _user.uid && Number(myAuthenticate.uid) === Number(_user.uid));

      if (!user || !user.isAdmin) return false;
      return true;
    },
  },
  actions: {
    getAccount({ dispatch, rootGetters }, { payload = {}, httpQuery }) {
      const { 'Authenticate/getterAuthenticate': myAuthenticate } = rootGetters;

      const action = {
        dispatch,
        target: 'Account/get',
        context: {
          payload: {
            aid: myAuthenticate.setting.defaultAccountAid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'get account',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: account.js ~ line 46 ~ getAccount ~ e', e);
          Snackbar.open({
            message: 'Unable to get account detail.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },

    createAccount({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Account/create',
        context: {
          payload: { ...payload },
          httpQuery: { ...httpQuery },
        },
        textNotifier: 'Create account',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: account.js ~ line 81 ~ createAccount ~ e', e);
          Snackbar.open({
            message: 'Unable to create account.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },

    reportAccountList({ dispatch }, { payload = {}, httpQuery }) {
      // const { 'Authenticate/getterAuthenticate': myAuthenticate } = rootGetters;

      const action = {
        dispatch,
        target: 'Account/report',
        context: {
          payload,
          httpQuery,
        },
        textNotifier: 'report account',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: account.js ~ line 74 ~ reportAccountList ~ e', e);
          Snackbar.open({
            message: 'Unable to report account list.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },

    createAccountLinkChannel({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Account/LinkChannel/post',
        context: { payload, httpQuery },
        textNotifier: 'channel link to user',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };
      return dispatchAndForward(action)
        .catch((e) => {
          console.log(e);
          Snackbar.open({
            message: 'Unable to link channel to user.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },

    createAccountUnlinkChannel({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Account/UnlinkChannel/post',
        context: { payload, httpQuery },
        textNotifier: 'channel unlink to user',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };
      return dispatchAndForward(action)
        .catch((e) => {
          console.log(e);
          Snackbar.open({
            message: 'Unable to unlink channel to user.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
    createAccountLinkChannelGroup({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Account/LinkChannelGroup/post',
        context: { payload, httpQuery },
        textNotifier: 'channelGroup link to user',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };
      return dispatchAndForward(action)
        .catch((e) => {
          console.log(e);
          Snackbar.open({
            message: 'Unable to link channelGroup to user.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },

    createAccountUnlinkChannelGroup({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Account/UnlinkChannelGroup/post',
        context: { payload, httpQuery },
        textNotifier: 'channelGroup unlink to user',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };
      return dispatchAndForward(action)
        .catch((e) => {
          console.log(e);
          Snackbar.open({
            message: 'Unable to unlink channelGroup to user.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
    reportFacebookConnect({ dispatch, rootGetters }) {
      const { 'Authenticate/getterAuthenticate': myAuthenticate } = rootGetters;

      const action = {
        dispatch,
        target: 'Account/facebookConnect/report',
        context: { payload: { aid: myAuthenticate.setting.defaultAccountAid } },
        textNotifier: 'report facebook connect',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: account.js ~ line 32 ~ reportFacebookConnect ~ e', e);
          Snackbar.open({
            message: 'Unable to get facebook connect list.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    createFacebookConnect({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterAuthenticate': myAuthenticate } = rootGetters;

      const action = {
        dispatch,
        target: 'Account/facebookConnect/create',
        context: { payload: { aid: myAuthenticate.setting.defaultAccountAid, ...payload } },
        textNotifier: 'create facebook connect',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then((response) => response)
        .catch((e) => {
          console.log('🚀 ~ file: account.js ~ line 32 ~ createFacebookConnect ~ e', e);
          Snackbar.open({
            message: 'Unable to create facebook connect.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    connectMetaChannel({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/connectMetaChannel/create',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'connect Meta channels',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: account.js ~ line 32 ~ createFacebookConnect ~ e', e);
          Snackbar.open({
            message: 'Unable to connect Meta channels.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    tryConnectMeta({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/tryConnectMeta/tryConnectMeta',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'try connect Meta channels',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((error) => {
          dispatchFailure(action);
          throw new Errors(error);
        });
    },
    reportAccountMemberView({ dispatch, rootGetters }, { payload = {}, httpQuery = {} }) {
      const { 'Account/getterAccount': account } = rootGetters;

      if (!account) return undefined;

      const action = {
        dispatch,
        target: 'Account/accountMemberView/report',
        context: { payload: { aid: account.aid, ...payload }, httpQuery },
        textNotifier: 'report account member',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: account.js ~ line 32 ~ reportAccountMemberView ~ e', e);
          Snackbar.open({
            message: 'Unable to get account member list.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },

    setAdmin({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Account/setAdmin/post',
        context: { payload, httpQuery },
        textNotifier: 'Member is now admin',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };
      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to put this member with role admin.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },

    setNotAdmin({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Account/setAdmin/delete',
        context: { payload, httpQuery },
        textNotifier: 'Member isn\'t admin anymore',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };
      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to take off admin role to member.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },

    setFeatures({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Account/setFeature/post',
        context: { payload, httpQuery },
        textNotifier: 'Set feature to account',
        retry: { onSuccess: false, onFailure: false },
      };
      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to set feature to account.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },

    unsetFeatures({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Account/setFeature/delete',
        context: { payload, httpQuery },
        textNotifier: 'Unset feature to account',
        retry: { onSuccess: false, onFailure: false },
      };
      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to unset feature to account.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },

    changeState({ dispatch }, { payload, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Account/changeState/post',
        context: { payload, httpQuery },
        textNotifier: 'Update state of account',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to update state of account', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },

    deleteAccount({ dispatch }, { payload, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Account/delete',
        context: { payload, httpQuery },
        textNotifier: 'Delete account',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to delete account', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },

    up_changeState({ commit, rootGetters }, { payload: { aid, state } }) {
      const { 'Account/getterAccount': account } = rootGetters;

      if (account.aid === aid) {
        const payload = { ...account, state };
        commit('Account/SET_ACCOUNT', payload, { root: true });
      }
    },

    up_deleteAccount({ commit, rootGetters }, { payload: { aid } }) {
      const { 'Account/getterAccountList': accountList } = rootGetters;

      const newAccountList = accountList;

      delete newAccountList[aid];

      commit('Account/SET_ACCOUNT', null, { root: true });
      commit('Account/SET_ACCOUNT_LIST', newAccountList, { root: true });
    },

    up_linkChannelGroup({ dispatch }, {
      toBeLinked, aid, uid, cgid,
    }) {
      return dispatch('Account/accountMemberView/up_linkChannelGroup', {
        toBeLinked, aid, uid, cgid,
      }, { root: true });
    },
    up_unlinkChannelGroup({ dispatch }, {
      toBeUnlinked, aid, uid, cgid,
    }) {
      return dispatch('Account/accountMemberView/up_unlinkChannelGroup', {
        toBeUnlinked, aid, uid, cgid,
      }, { root: true });
    },
    up_linkChannel({ dispatch }, {
      toBeLinked, aid, uid, cid,
    }) {
      return dispatch('Account/accountMemberView/up_linkChannel', {
        toBeLinked, aid, uid, cid,
      }, { root: true });
    },
    up_unlinkChannel({ dispatch }, {
      toBeUnlinked, aid, uid, cid,
    }) {
      return dispatch('Account/accountMemberView/up_unlinkChannel', {
        toBeUnlinked, aid, uid, cid,
      }, { root: true });
    },
    up_setAdmin({ commit, rootGetters }, { payload: { uid, isAdmin } }) {
      const { 'Account/accountMemberView/getterAccountMemberViewList': _accountMemberViewList } = rootGetters;

      const newAccountMemberViewList = _accountMemberViewList;

      newAccountMemberViewList[uid].isAdmin = isAdmin;

      commit('Account/accountMemberView/SET_ACCOUNT_MEMBER_VIEW_LIST', newAccountMemberViewList, { root: true });
    },
  },
};
