<template>
  <UbuDropdown
    :close-on-click="false"
    :scrollable="true"
    :position="position"
    @open="isOpen = true"
    @close="reset(); isOpen = false;"
  >
    <template v-slot:button>
      <b-button
        v-if="checked.length === 1"
        class="start mr-2"
        :type="`is-custom-${crmListList[checked[0]].color}`"
        :label="crmListList[checked[0]].name"
        icon-pack="ubu"
        icon-left="ubu-lists"
      />

      <b-button
        v-else-if="checked.length > 1"
        class="start mr-2"
        type="is-secondary"
        icon-pack="ubu"
        icon-left="ubu-lists"
      >
        <div class="multibar row-vcentered">
          <span class="start">Lists</span>

          <UbuIconCircleStatus
            class="start"
            :is-active="true"
          />

          <span class="start">{{ checked.length }}</span>
        </div>
      </b-button>

      <b-button
        v-else
        class="start mr-2"
        type="is-secondary"
        label="Lists"
        icon-pack="ubu"
        icon-left="ubu-lists"
      />
    </template>

    <template
      v-if="isOpen"
      v-slot:search
    >
      <b-dropdown-item custom>
        <UbuSearchbar
          v-model="search"
          @clear="reset()"
        />
      </b-dropdown-item>
    </template>

    <template v-slot:content>
      <b-dropdown-item
        class="no-hover-background"
        paddingless
        @click="selectOrUnselectAll()"
      >
        <span class="subtext has-text-primary">
          {{ allListChecked ? 'Unselect all' : 'Select all' }}
        </span>
      </b-dropdown-item>

      <b-dropdown-item
        v-for="list in crmListListFiltered"
        :key="list.clid"
        paddingless
      >
        <b-checkbox
          v-model="checked"
          :native-value="list.clid"
        >
          <b-icon
            pack="ubu"
            icon="ubu-custom-lists"
            :class="`has-text-color-${list.color}`"
          />
          {{ list.name }}
        </b-checkbox>
      </b-dropdown-item>
    </template>

    <template
      v-if="showAction"
      v-slot:action
    >
      <p class="subtext is-3 has-text-secondary mb-4 mt-4">People must be in</p>

      <b-field>
        <b-radio
          v-model="operator"
          native-value="is one of"
        >
          one of selected lists
        </b-radio>
      </b-field>

      <b-field>
        <b-radio
          v-model="operator"
          native-value="is all"
        >
          all selected lists
        </b-radio>
      </b-field>

      <b-button
        label="Apply"
        class="mt-2"
        type="is-primary"
        expanded
        @click.stop="applyFilter()"
      />
    </template>
  </UbuDropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheCrmListFilterDropdown',
  mixins: [FilterEngineMixin],
  props: {
    position: {
      type: String,
      default: 'is-bottom-right',
    },
  },
  data() {
    return {
      search: '',
      isOpen: true,
      checked: [],
      operator: 'is one of',
    };
  },
  computed: {
    ...mapGetters({
      crmListList: 'TheCrmListFilterDropdown/crmListList',
    }),

    crmListListFiltered() {
      const crmListList = Object.values(this.crmListList);

      if (!crmListList.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByName = this.search;

      return this.filterEngine(crmListList, filtersToApply);
    },

    showAction() {
      return this.checked.length;
    },

    allListChecked() {
      return this.checked.length === this.crmListListFiltered.length;
    },
  },
  methods: {
    applyFilter() {
      const preparedFilter = {
        id: uuidv4(),
        key: 'crmLists',
        label: undefined,
        type: 'checkbox',
        values: this.checked,
        operator: this.operator,
      };

      this.reset();
      this.$emit('apply', preparedFilter);
    },

    selectOrUnselectAll() {
      if (this.allListChecked) this.checked = [];
      else this.checked = this.crmListListFiltered.map(({ clid }) => clid);
    },

    reset() {
      this.search = '';
    },
  },
};
</script>
