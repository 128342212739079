/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../../engine';

const target = 'clark';

const pingShop = {
  get: ({ payload, httpQuery }) => builder({ url: `/api/manageShopifyShop/${payload.ssid}/pingShop`, method: 'GET', payload, httpQuery, target }),
};

export default pingShop;
