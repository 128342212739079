import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuSwitchSelector from './UbuSwitchSelector';
import UbuSwitchSelectorChildren from './UbuSwitchSelectorChildren';
import UbuOrganizationWorkspace from './UbuOrganizationWorkspace';
import UbuChannelGroupAndChannelSelector from './UbuChannelGroupAndChannelSelector';
import UbuChannelSelector from './UbuChannelSelector';
import UbuInfoSelector from './UbuInfoSelector';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuSwitchSelector);
    registerComponent(Vue, UbuSwitchSelectorChildren);
    registerComponent(Vue, UbuOrganizationWorkspace);
    registerComponent(Vue, UbuChannelGroupAndChannelSelector);
    registerComponent(Vue, UbuChannelSelector);
    registerComponent(Vue, UbuInfoSelector);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuSwitchSelector,
  UbuSwitchSelectorChildren,
  UbuOrganizationWorkspace,
  UbuChannelGroupAndChannelSelector,
  UbuChannelSelector,
  UbuInfoSelector,
};
