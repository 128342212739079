import Errors from '@dailyplanet/utils/Errors';
import { SnackbarProgrammatic as Snackbar } from 'buefy';

const isActionAllowed = (rootGetters, right) => {
  const { rights } = rootGetters['Authenticate/getterAuthenticate'];
  if (!rights[right]) {
    Snackbar.open({
      message: `You are not allowed to do that. ("${right}").`,
      type: 'is-danger',
      position: 'is-top',
      duration: 10000,
    });
    return false;
  }
  return true;
};

const hasAccessToChannel = (rootGetters, cid) => {
  const getterAuthenticate = rootGetters['Authenticate/getterAuthenticate'];

  const allowedChannel = getterAuthenticate && getterAuthenticate.allowedChannel
    ? getterAuthenticate.allowedChannel
    : [];

  if (!allowedChannel.includes(Number(cid))) {
    Snackbar.open({
      message: 'Oups, it seems that you are not allowed on this channel. Contact an admin of your account to require access !',
      type: 'is-danger',
      position: 'is-top',
      duration: 10000,
    });
    return false;
  }
  return true;
};

/* eslint-disable no-param-reassign */
const dispatchAndForward = (action) => Promise.resolve()
  .then(() => action.dispatch('TheNotifier/pushNotif', action, { root: true }))
  .then((nid) => { action.nid = nid; })
  .then(() => action.dispatch(action.target, action.context, { root: true }))
  .then((response) => { action.response = response; })
  .then(() => action.dispatch('TheNotifier/setNotifDone', action.nid, { root: true }))
  .then(() => action);

const dispatchFailure = (action) => Promise.resolve()
  .then(() => action.dispatch('TheNotifier/setNotifFail', action.nid, { root: true }));

const dispatchComment = (action) => Promise.resolve()
  .then(() => action.dispatch('TheNotifier/addCommentInNotif', { nid: action.nid, comment: action.comment }, { root: true }));

const snackBarSuccess = {
  type: 'is-success',
  position: 'is-top',
  duration: 5000,
};

const snackBarFailure = {
  type: 'is-danger',
  position: 'is-top',
  duration: 5000,
};

const genericErrorHandler = (action, snackarParams, shouldThrow = true) => (error) => {
  console.trace('🚀 ~ file: helpers.js ~ line 65 ~ genericErrorHandler ~ error', error);
  if (snackarParams) Snackbar.open({ ...snackBarFailure, ...snackarParams });
  if (action) dispatchFailure(action);
  if (shouldThrow) throw new Errors(error);
  return undefined;
};

const genericThenHandler = (mutations, dispatch) => ({ response }) => {
  if (!mutations || !mutations.length) return response;
  return Promise
    .all(mutations.map(({ target, payload }) => dispatch(target, { payload, response }, { root: true })))
    .then(() => response);
};

export {
  // eslint-disable-next-line import/prefer-default-export
  isActionAllowed,
  hasAccessToChannel,
  dispatchAndForward,
  dispatchFailure,
  dispatchComment,
  snackBarSuccess,
  snackBarFailure,
  Errors,
  genericErrorHandler,
  genericThenHandler,
};
