<template>
  <div :class="`analyticsGrid col-2`">
    <div
      v-for="item in statistics"
      :key="item.name"
      class="ubuAnalyticCard"
    >
      <div :class="`ubuAnalyticCard_icon bg-color-${item.color}`">
        <b-icon
          pack="ubu"
          :icon="item.icon"
          size="is-medium"
        />
      </div>

      <div class="title is-3">
        <span>
          {{ item.value | nFormatter }} {{ item.symbol }}
        </span>
      </div>

      <span class="subtext is-3">
        {{ item.label }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuMentionModalAnalytics',
  model: {
    prop: 'mentionItem',
  },
  props: {
    mentionItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statistics() {
      const stats = [
        // {
        //   label: 'Prints',
        //   value: this.mentionItem.publication.prints,
        //   color: 3,
        //   icon: 'ubu-eye',
        // },
      ];

      if (this.mentionItem.mention.mentionType === 'POST') {
        stats.push(...[
          {
            label: 'Engagement',
            value: this.mentionItem.publication.engagement,
            color: 9,
            icon: 'ubu-organic',
            symbol: '%',
          },
          {
            label: 'Likes',
            value: this.mentionItem.publication.likes,
            color: 9,
            icon: 'ubu-love',
          },
          {
            label: 'Comments',
            value: this.mentionItem.publication.comments,
            color: 9,
            icon: 'ubu-comment',
          },
        ]);
      }
      return stats;
    },
  },
};
</script>
