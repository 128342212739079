<template>
  <section>
    <b-field
      horizontal
      label="Account owner"
    >
      <UbuAutocomplete
        v-model="localAccount"
        :options="account.preset"
        :loading="loading"
        :open-on-focus="true"
        tag="router-link"
        header
        type="is-ghost"
        label="Create account"
        to="/admin/add-account"
        field="owner"
        placeholder="Search account by owner"
      />
    </b-field>

    <br>

    <template v-if="account.selected && typeof account.selected === 'object'">
      <div class="multibar">
        <h2 class="start title is-1">Account selected</h2>
        <b-button
          class="end"
          tag="router-link"
          type="is-ghost"
          label="Edit account"
          :to="`/admin/account/${link.aid}/members`"
        />
      </div>

      <br>

      <TheTable
        :lead-list="accountSelected"
        :columns="Object.values(columns)"
        :loading="loading"
        :total="total"
        :page="page"
        :per-page="perPage"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :default-sort-order="defaultSortOrder"
        :selected.sync="localSelected"
        :checked-rows.sync="checkedRows"
        :detail-key="'uid'"
        :checkable="false"
        :sticky-header="false"
      />

      <br>

      <b-field label="Account Role">
        <b-select
          v-model="localRole"
          required
          placeholder="Select a role"
        >
          <option
            value="admin"
            selected
          >
            Admin
          </option>
          <option value="member">
            Member
          </option>
        </b-select>
      </b-field>

      <br>

      <DevDetails :cnt="{name: 'formatChannelsChannelGroups', obj: formatChannelsChannelGroups}" />
      <DevDetails :cnt="{name: 'localLink', obj: localLink}" />
      <template v-if="channelGroups">
        <legend class="has-text-secondary subtitle is-3">CHANNEL GROUPS / CHANNELS</legend>
        <div
          v-for="(row) in formatChannelsChannelGroups"
          :key="row.channelGroup.cgid"
        >
          <UbuChannelGroupAndChannelSelector
            :model="JSON.stringify(row)"
            reactive
            @updateGroup="$emit('updateGroup', $event)"
          />
        </div>
      </template>

      <br>

      <template v-if="cgids.length">
        <b-field
          label="Select default workspace"
        >
          <div class="is-flex is-flex-direction-column mt-5">
            <template
              v-for="{ value, name } in cgids"
            >
              <div
                v-if="name"
                :key="name"
              >
                <b-radio
                  v-model="localDefaultChannelGroupSelected"
                  :name="name"
                  :native-value="value"
                  class=""
                >
                  {{ name }}
                </b-radio>
              </div>
            </template>
          </div>
        </b-field>
      </template>
    </template>
  </section>
</template>

<script>
import iconsMixins from '@dailyplanet/cross-addons/crossCommon/$mixins/dataIcons.mixins';
import ubuKaraSearchAccountColumns from '../$mixins/userKaraSearchAccountForm.columns.mixins';

export default {
  name: 'UbuKaraSearchAccountForm',
  mixins: [
    ubuKaraSearchAccountColumns,
    iconsMixins,
  ],
  props: {
    options: { type: Array, default: () => ([]) },
    account: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false },
    channelGroups: { type: Object, default: () => ({}) },
    link: { type: Object, default: () => ({}) },
    role: { type: String, required: true },
    defaultChannelGroupSelected: { type: Number, required: true },
    cgids: { type: Array, required: true },
  },
  data() {
    return {
      isOpen: 0,
      total: 0,
      page: 0,
      perPage: 50,
      sortField: 'aid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      checkedRows: [],
      selected: {},
      channelchannelGroupLinked: [],
    };
  },
  computed: {
    localAccount: {
      get() { return this.account; },
      set(newValue) { this.$emit('update:account', newValue); },
    },
    localSelected: {
      get() { return this.selected; },
      set(newValue) { this.$emit('update:selected', newValue); },
    },
    localLink: {
      get() { return this.link; },
      set(newValue) { this.$emit('update:link', newValue); },
    },
    localRole: {
      get() { return this.role; },
      set(newValue) { this.$emit('update:role', newValue); },
    },
    localDefaultChannelGroupSelected: {
      get() { return this.defaultChannelGroupSelected; },
      set(newValue) { this.$emit('update:defaultChannelGroupSelected', newValue); },
    },
    accountSelected() {
      if (!this.account.selected || typeof this.account.selected !== 'object') return [];
      return [
        {
          aid: this.account.selected.aid,
          state: this.account.selected.state,
          owner: this.account.selected.owner,
        },
      ];
    },
    formatChannelsChannelGroups() {
      if (!this.channelGroups) return {};
      return this.channelGroups.channelGroupList.reduce((acc, {
        cgid, name, picture, channelList,
      }) => {
        if (!acc[cgid]) {
          acc[cgid] = {
            channelGroup: {
              cgid,
              picture,
              name,
              initials: name.charAt(0).toUpperCase(),
              isActive: false,
            },
            channelList: channelList.reduce((channels, channel) => {
              if (channel.channelGroupCgid === cgid && !channels[channel.cid]) {
                return {
                  ...channels,
                  [channel.cid]: {
                    ...channel,
                    isActive: false,
                  },
                };
              }

              return channels;
            }, {}),
          };
        }
        return acc;
      }, {});
    },
  },
};
</script>
