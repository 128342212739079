<template>
  <div :class="['ubuAddCustomInline', {'is-detailed': detailed}]">
    <UbuEditCustom
      v-if="showCreateCustom"
      class="mt-4"
      :custom="{name: searchValue}"
      @editCustom="createCustom($event)"
    />
    <b-table
      v-else
      :data="customs"
      :hoverable="clickable"
      :detailed="detailed"
      :show-detail-icon="false"
      custom-detail-row
      :opened-detailed="openedDetails"
      @click="clickHandler($event)"
    >
      <b-table-column
        :searchable="searchable"
      >
        <template
          v-if="!searchable"
          #subheading
        >
          <div class="multibar row-vcentered">
            <h1 class="start title is-2">{{ title }}</h1>
            <b-button
              type="is-primary"
              class="end"
              @click="showCreateCustom = true"
            >
              Create
            </b-button>
          </div>
        </template>
        <template
          #searchable="props"
        >
          <b-input
            ref="input"
            v-model="props.filters.name"
            icon-pack="ubu"
            icon="ubu-search"
            placeholder="Search or create..."
            expanded
            autofocus
            class="is-simple"
            icon-right="ubu-cross"
            icon-right-clickable
            @input="inputChanged($event)"
            @icon-right-click="props.filters.name = ''; searchValue = '';"
          />
        </template>
        <template
          v-slot="props"
        >
          <div class="multibar">
            <UbuCustomCard
              class="start"
              :icon-name="iconName"
              :text="props.row.name"
              :color="props.row.color"
            />
            <div class="end multibar">
              <span
                v-if="clickable && props.row.helpActionText"
                class="helpActionText"
              >{{ props.row.helpActionText }}</span>
              <b-icon
                v-if="props.row.iconRight"
                pack="ubu"
                :icon="props.row.iconRight"
                font-size="24px"
                @click.native="$emit('unassignCustom', props.row)"
              />
              <b-icon
                v-if="detailed"
                class="arrowIcon"
                pack="ubu"
                :icon="openedDetails.find(
                  (r) => r.id === props.row.id) ? 'ubu-arrow-up' : 'ubu-arrow-down'"
                @click.native="props.toggleDetails(props.row)"
              />
            </div>
          </div>
        </template>
      </b-table-column>

      <template #detail="props">
        <UbuEditCustom
          :custom="props.row"
          detailed
          @editCustom="$emit('updateCustom', $event)"
        />
      </template>
    </b-table>
    <b-button
      v-if="searchValue && !showCreateCustom"
      class="is-ghost"
      @click="showCreateCustom = true"
    >
      + create "{{ searchValue }}"
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'UbuAddCustomInline',
  props: {
    customs: {
      type: Array,
      default: () => [],
    },
    iconName: {
      type: String,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    detailed: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchValue: '',
      showCreateCustom: false,
      openedDetails: [],
    };
  },
  computed: {
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.input) this.$refs.input.focus();
    });
  },
  methods: {
    clearSearch() {
      this.searchValue = '';
    },
    createCustom(custom) {
      this.$emit('createCustom', custom);
      this.showCreateCustom = false;
      this.searchValue = '';
      this.openedDetails = [];
    },
    inputChanged(event) {
      this.searchValue = event;
    },
    toggleOpenedDetails(row) {
      if (this.openedDetails.find(({ id }) => id === row.id)) {
        this.openedDetails = this.openedDetails.filter(({ id }) => id !== row.id);
      } else {
        this.openedDetails.push(row);
      }
    },
    clickHandler(row) {
      if (this.detailed) return this.toggleOpenedDetails(row);
      if (!this.clickable) return undefined;
      if (this.isLoading) return undefined;
      if (row.eventOnClick) return this.$emit(row.eventOnClick, row);
      return undefined;
    },
  },
};
</script>
