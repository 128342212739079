var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TheTabsPanel',{on:{"goToPreviousTab":function($event){return _vm.goToPreviousTab()},"goToStart":function($event){return _vm.goToTabByTabPath('root')},"close":function($event){return _vm.closePanel()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('b-tab-item',{attrs:{"value":"root"}},[_c('article',[_c('div',{staticClass:"buttons"},_vm._l((_vm.navigableItems),function(item){return _c('UbuActionCard',{key:item.label,staticClass:"mb-4",attrs:{"label":item.label,"icon-pack":"ubu","icon-left":item.icon,"expanded":"","disabled":item.isDisabled},on:{"click":function($event){return _vm.goToTabByTabPath(item.to)}}})}),1)])]),_c('b-tab-item',{attrs:{"value":"root.importFromLabel"}},[_c('div',{staticClass:"buttons"},_vm._l((Object.values(_vm.contactGroupList)
            .sort((a, b) => _vm.$moment(b.createdOn).unix() - _vm.$moment(a.createdOn).unix())),function(item){return _c('UbuActionCard',{key:item.cgid,class:`mb-4 has-text-color-${item.color}`,attrs:{"label":item.label,"icon-pack":"ubu","icon-left":"ubu-custom-contact","expanded":""},on:{"click":function($event){return _vm.selectAndContinue(
            item.cgid, 'CONTACT_GROUP', `root.importFromLabel`)}}})}),1)]),_c('b-tab-item',{attrs:{"value":"root.importFromTag"}},[_c('div',{staticClass:"buttons"},_vm._l((Object.values(_vm.threadGroupList)
            .sort((a, b) => _vm.$moment(b.createdOn).unix() - _vm.$moment(a.createdOn).unix())),function(item){return _c('UbuActionCard',{key:item.tgid,class:`mb-4 has-text-color-${item.color}`,attrs:{"label":item.label,"icon-pack":"ubu","icon-left":"ubu-custom-tag","expanded":""},on:{"click":function($event){return _vm.selectAndContinue(
            item.tgid, 'THREAD_GROUP', `root.importFromTag`)}}})}),1)]),_c('b-tab-item',{attrs:{"value":"root.importFromCampaign"}},[_c('div',{staticClass:"buttons"},_vm._l((Object.values(_vm.campaignList)
            .sort((a, b) => _vm.$moment(b.createdOn).unix() - _vm.$moment(a.createdOn).unix())),function(item){return _c('UbuActionCard',{key:item.cid,class:`mb-4 has-text-color-${item.color}`,attrs:{"label":item.name,"icon-pack":"ubu","icon-left":"ubu-custom-influence","expanded":""},on:{"click":function($event){return _vm.selectAndContinue(
            item.cid, 'CAMPAIGN', `root.importFromCampaign`)}}})}),1)]),_vm._l((Object.keys(_vm.tabItems).filter((name) => name.includes('selectGroup'))),function(type){return _c('b-tab-item',{key:type,attrs:{"value":type}},[_c('div',{staticClass:"buttons"},[_c('UbuActionCard',{staticClass:"mb-4",attrs:{"label":"Manage group later","expanded":""},on:{"click":function($event){return _vm.finalize(null)}}}),_vm._l((_vm.memberGroupList),function(item){return _c('UbuActionCard',{key:item.mgid,class:`mb-4 has-text-color-${item.color}`,attrs:{"label":item.name,"icon-pack":"ubu","icon-left":"ubu-folder","expanded":""},on:{"click":function($event){return _vm.finalize(item.mgid)}}})})],2)])}),_c('b-tab-item',{attrs:{"value":"root.done"}},[(_vm.canDisplaySuccess)?_c('b-message',{attrs:{"type":"is-success"}},[_c('div',{staticClass:"multibar row-vcentered"},[_c('b-icon',{staticClass:"start",attrs:{"pack":"ubu","icon":"ubu-success","size":"is-medium"}}),_c('span',{staticClass:"start"},[_vm._v(" Success! People are added to your Tribe. ")])],1)]):_vm._e(),(_vm.errorWhileWizard)?_c('b-message',{attrs:{"type":"is-danger"}},[_c('p',{staticClass:"text is-3"},[_vm._v(" "+_vm._s(_vm.errorWhileWizard)+" ")]),_c('div',{staticClass:"multibar row-vcentered mt-2 mb-2"},[_c('b-button',{staticClass:"start",attrs:{"type":"is-ghost","label":"Download"},on:{"click":function($event){return _vm.downloadPayload()}}})],1)]):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}})
}
var staticRenderFns = []

export { render, staticRenderFns }