import { clark } from '@dailyplanet/providers';
import Errors from '@dailyplanet/utils/Errors';

export default {
  namespaced: true,
  state: {
    adminLoginTokenLink: null,
  },
  mutations: {
    SET_ADMIN_LOGIN_TOKEN_LINK(state, newData) { state.channelSms = newData; },
  },
  getters: {
    getterAdminLoginTokenLink: (state) => state.adminLoginTokenLink,
  },
  actions: {
    createLink({ commit }, { payload = {}, httpQuery = {} }) {
      return clark.api.apiAuthenticate.adminLogin.createLink({
        payload,
        httpQuery: {
          ...httpQuery,
        },
      })
        .then((adminLoginTokenLink) => {
          commit('SET_ADMIN_LOGIN_TOKEN_LINK', adminLoginTokenLink);
          return adminLoginTokenLink;
        });
    },

    connectFromLink({ commit }, { payload = {}, httpQuery = {} }) {
      return clark.api.apiAuthenticate.adminLogin.connectFromLink({
        payload,
        httpQuery: {
          ...httpQuery,
        },
      })
        .then((authenticate) => {
          const { setting, channelGroupList } = authenticate;
          const arrayMappedChannelGroupList = channelGroupList.arrayMapper('cgid');
          commit('Authenticate/SET_AUTHENTICATE', authenticate, { root: true });
          commit('User/userDetail/SET_USER_DETAIL', authenticate.userDetail, { root: true });
          commit('User/userSetting/SET_THEME', authenticate.setting.theme || 'AUTO', { root: true });

          if (channelGroupList && channelGroupList.length) {
            commit('Authenticate/SET_CURRENT_CHANNEL_GROUP', arrayMappedChannelGroupList[setting.defaultChannelGroupCgid] || channelGroupList[0], { root: true });
            commit('Authenticate/SET_CURRENT_CHANNEL_GROUP_LIST', arrayMappedChannelGroupList, { root: true });
          }
        })
        .catch((error) => {
          commit('Authenticate/SET_AUTHENTICATE', null, { root: true });
          throw new Errors(error);
        });
    },
  },
};
