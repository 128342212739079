<template>
  <div class="ubuMessageCard-footer">
    <div
      :class="[
        'start is-flex',
        {'has-text-danger': !message.originalId && message.errorDescription},
        {'has-text-secondary': !message.originalId && !message.errorDescription},
      ]"
    >
      {{ $moment(message.itemDate).format('HH:mm') }}
      <div
        v-if="message.reactions"
        class="is-flex"
      >
        <div class="divider-vertical" />
        <b-tooltip
          size="is-small"
          animated
          multilined
        >
          <template v-slot:content>
            {{ tooltipContent }}
          </template>
          <span
            v-for="(reaction, index) in reactions.slice(0, 3)"
            :key="index"
            class="mr-1"
          >{{ reaction.emoji }}</span>
          <span class="ml-1">{{ reactions.total }}</span>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuThreadMessageFooter',
  props: {
    message: {
      type: Object,
      default: () => {},
      require: true,
    },
  },
  computed: {
    reactions() {
      const { reactions } = this.message;
      const formatedReactions = reactions.reduce((acc, reaction) => {
        if (acc.total) acc.total += 1;
        else acc.total = 1;
        const exist = acc.find((reac) => reac.emoji === reaction.emoji);
        if (exist) exist.count += 1;
        else {
          acc.push({
            emoji: reaction.emoji,
            count: 1,
          });
        }
        return acc;
      }, []);
      const sorted = formatedReactions.sort((a, b) => b.count - a.count);

      return sorted;
    },
    tooltipContent() {
      const stringArray = this.reactions.reduce((acc, reaction) => {
        acc.push(`${reaction.emoji} ${reaction.count}`);
        return acc;
      }, []).join('\n');

      return stringArray;
    },
  },
};
</script>
