<template>
  <b-carousel
    :autoplay="false"
    @change="changeCarouselIndex($event)"
  >
    <b-carousel-item
      v-for="(media, i) in carousel"
      :key="i"
    >
      <UbuMediaImage
        v-if="media.type === 'IMAGE'"
        :url="media.url"
        :src-fallback="srcFallback"
      />
      <UbuMediaVideo
        v-if="media.type === 'VIDEO'"
        :url="media.url"
        :src-fallback="srcFallback"
      />
      <UbuMediaAudio
        v-if="media.type === 'AUDIO'"
        :url="media.url"
      />
    </b-carousel-item>
  </b-carousel>
</template>

<script>
const srcFallback = require('@dailyplanet/assets/images/image-placeholder.png');

export default {
  name: 'UbuMediaCarousel',
  props: {
    carousel: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      srcFallback,
    };
  },
  methods: {
    changeCarouselIndex(index) {
      this.$emit('changeCarouselIndex', index);
    },
  },
};
</script>
