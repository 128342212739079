/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

import suggestion from './salesContactSuggestion.store';

export default {
  namespaced: true,

  state: {
    salesContactList: {},
    salesContactHasLead: {},
  },

  mutations: {
    SET_SALES_CONTACT_LIST(state, newDatas) { state.salesContactList = newDatas; },
    SET_SALES_CONTACT_HAS_LEAD(state, newDatas) { state.salesContactHasLead = newDatas; },
    UPDATE_SALES_CONTACT_LIST(state, newDatas) {
      const myList = Object.values(state.salesContactList).concat(newDatas).arrayMapper('username');
      state.salesContactList = myList;
    },
    DELETE_SALES_CONTACT(state, newData) {
      state.salesContactList = Object.values(state.salesContactList).filter(({ username }) => username !== newData.username).arrayMapper('username');
    },
  },

  getters: {
    getterSalesContactList: (state) => state.salesContactList,
  },

  actions: {
    report({ commit, rootGetters }, context) {
      if (!isActionAllowed(rootGetters, 'SALES_CONTACT.REPORT')) return undefined;

      return clark.api.salesContact
        .report(context)
        .then((salesContactList) => {
          commit('SET_SALES_CONTACT_LIST', salesContactList.arrayMapper('username'));
          return salesContactList.arrayMapper('username');
        });
    },

    create({ commit, rootGetters }, context) {
      if (!isActionAllowed(rootGetters, 'SALES_CONTACT.CREATE')) return undefined;

      return clark.api.salesContact
        .post(context)
        .then((salesContact) => {
          commit('UPDATE_SALES_CONTACT_LIST', salesContact);
          return salesContact;
        });
    },

    update({ commit, rootGetters }, context) {
      if (!isActionAllowed(rootGetters, 'SALES_CONTACT.UPDATE')) return undefined;

      return clark.api.salesContact
        .put(context)
        .then((salesContact) => { commit('UPDATE_SALES_CONTACT_LIST', salesContact); });
    },

    delete({ commit, rootGetters }, context) {
      if (!isActionAllowed(rootGetters, 'SALES_CONTACT.DELETE')) return undefined;

      return clark.api.salesContact
        .delete(context)
        .then(() => { commit('DELETE_SALES_CONTACT', context.payload); });
    },

    bulkRefreshLeads({ commit }, context) {
      return clark.api.salesContact.bulkRefresh
        .post(context)
        .then((salesContacts) => { commit('UPDATE_SALES_CONTACT_LIST', Object.values(salesContacts)); });
    },
  },

  modules: {
    suggestion,
  },
};
