<template>
  <div class="buttons gap-8">
    <b-tooltip
      label="Compose"
      position="is-bottom"
    >
      <b-button
        tag="a"
        icon-pack="ubu"
        icon-left="ubu-compose"
        type="is-tertiary"
        class="mb-0"
        href="https://www.instagram.com/direct/new/"
        target="_blank"
      />
    </b-tooltip>
    <b-tooltip
      label="Download"
      position="is-bottom"
    >
      <b-button
        tag="a"
        :href="currentMediaDownloadUrl"
        target="_blank"
        download
        type="is-tertiary"
        class="mb-0"
        @click="$emit('downloadMentionEvent')"
      >
        <b-icon
          pack="ubu"
          icon="ubu-download"
          size="is-medium"
        />
      </b-button>
    </b-tooltip>
    <b-tooltip
      v-if="isMediaFavorited !== null"
      label="Favorite"
      position="is-bottom"
    >
      <UbuFavButton
        class="end"
        :active="isMediaFavorited"
        @click="$emit('toggleFavorite', $event)"
      />
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'UbuMentionModalHeaderButtons',
  props: {
    currentMediaDownloadUrl: {
      type: String,
      required: true,
    },
    isMediaFavorited: {
      type: Boolean,
      default: null,
    },
  },
};
</script>
