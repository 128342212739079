<template>
  <!-- eslint-disable max-len -->
  <section class="ubuSettingView">
    <div class="multibar row-vcentered">
      <h1 class="start title is-2">Members</h1>

      <!-- <b-button
        v-if="isAdmin"
        class="end"
        type="is-primary"
        label="Add member"
        tag="router-link"
        to="/setting/member/addMember"
      /> -->

      <b-button
        v-if="isAdmin"
        class="end"
        type="is-primary"
        label="Add member"
        @click="openIntercom()"
      />
    </div>

    <br>

    <UbuSearchbar
      v-model="inputSearch"
      placeholder="Search member..."
      @clear="inputSearch = ''"
    />

    <br>

    <DevDetails :cnt="{name: 'accountMemberViewList', obj: accountMemberViewList}" />
    <template v-if="account">
      <DevDetails
        :cnt="{name: 'allChannelGroupAccountNewVersion', obj: allChannelGroupAccountNewVersion}"
      />
      <b-table
        :key="currentChannelGroup.cgid"
        class="ubuTableSetting"
        :data="Object.values(allChannelGroupAccountNewVersion)"
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="uid"
        :has-detailed-visible="() => false"
        hoverable
        :is-row-selectable="() => true"
        :show-header="false"
        @click="toggleOpenedDetails($event)"
      >
        <b-table-column
          v-for="column in columns"
          :key="column.col"
          v-slot="props"
          :label="column.label"
          :field="column.col"
          :sortable="column.isSortable"
          :cell-class="column.cellClass"
        >
          <template v-if="column.cmp === 'img'">
            <UbuAvatar
              :initial-name="initialFormater({
                firstName: props.row.userDetail.firstName,
                lastName: props.row.userDetail.lastName })"
            />
          </template>

          <template v-if="column.cmp === 'flat'">
            <p class="ellipsis text is-3 mb-1">{{ props.row[column.col] }}</p>

            <p class="ellipsis subtext is-3 has-text-secondary">
              {{ props.row.userDetail.email }}
            </p>
          </template>

          <template v-if="column.cmp === 'tag'">
            <b-tag
              v-if="props.row[column.tag.condition]"
              :type="column.tag.color"
            >
              {{ column.tag.text }}
            </b-tag>
          </template>

          <template v-if="column.cmp === 'action'">
            <div class="multibar">
              <b-icon
                class="end"
                pack="ubu"
                :icon="defaultOpenedDetails.includes(props.row.uid)
                  ? 'ubu-arrow-up'
                  : 'ubu-arrow-down'"
              />
            </div>
          </template>
        </b-table-column>

        <template #detail="props">
          <DevDetails
            :cnt="{name: 'allChannelGroupAccountNewVersion[props.row.uid]', obj: allChannelGroupAccountNewVersion[props.row.uid]}"
          />
          <legend class="has-text-secondary subtitle is-3">CHANNELS</legend>

          <UbuChannelSelector
            v-if="isAdmin"
            :model="JSON.stringify(allChannelGroupAccountNewVersion[props.row.uid])"
            @updateGroup="updateGroupNextGen({ event: $event, uid: props.row.uid, aid: account.aid })"
          />

          <div v-else>
            <div
              v-for="(channel) in allChannelGroupAccountNewVersion[props.row.uid].channelList"
              :key="channel.cid"
            >
              <div class="multibar">
                <div class="start is-flex">
                  <component
                    :is="`UbuIcon${channel.platformName.capitalize()}`"
                    font-size="20px"
                  />
                  <p class="ml-2 text is-3 has-text-secondary">
                    {{ channel.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-table>
    </template>

    <router-view name="addMember" />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { debounce } from '@dailyplanet/utils/helpers';
import { mapActions, mapGetters } from 'vuex';

import iconsMixins from '@dailyplanet/cross-addons/crossCommon/$mixins/dataIcons.mixins';
import linkUnlinkChannelChannelGroup from '@dailyplanet/cross-addons/workspace/$mixins/linkUnlinkChannelChannelGroup.mixins';

import { initialFormater } from '@dailyplanet/utils/formate';
import TheSettingMemberColumns from '../$mixins/TheSettingMemberColumns.columns.mixins';

export default {
  name: 'TheSettingMember',
  mixins: [
    TheSettingMemberColumns,
    iconsMixins,
    linkUnlinkChannelChannelGroup,
  ],
  data() {
    return {
      initialFormater,
      accountAid: null,
      isCreateModalActive: false,
      invitation: { url: 'une super URL' },
      isCollapseOpen: null,
      isActive: {
        value: null,
        index: null,
      },
      defaultOpenedDetails: [],
      search: '',
      inputSearch: '',
    };
  },
  computed: {
    ...mapGetters({
      account: 'TheSettingWorkspaceSetting/account',
      currentChannelGroup: 'TheSettingWorkspaceSetting/currentChannelGroup',
      myAuthenticate: 'TheSettingWorkspaceSetting/myAuthenticate',
      accountMemberViewList: 'TheSettingWorkspaceSetting/accountMemberViewList',
    }),
    allChannelGroupAccountNewVersion() {
      const currentCgid = this.currentChannelGroup.cgid;
      const accountMemberViewList = Object.values(this.accountMemberViewList);
      if (!accountMemberViewList.length) return [];

      const { uid } = this.myAuthenticate;

      return accountMemberViewList.reduce((acc, {
        activeChannelGroup, activeChannel, channelGroupList, channelList, ...member
      }) => {
        if (!activeChannelGroup || !activeChannelGroup.includes(currentCgid)) return acc;
        // acc[member.uid] =
        const dataToPush = {
          ...member,
          ...member.userDetail,
          fullName: `${member.userDetail.firstName} ${member.userDetail.lastName}`,
          isMe: member.uid === uid,
          isOpen: this.isActive,
          channelList: channelList.reduce((cl, { channelGroupCgid, ...rest }) => {
            if (channelGroupCgid === currentCgid) {
              return {
                ...cl,
                [rest.cid]: {
                  ...rest,
                  channelGroupCgid,
                  isActive: activeChannel.includes(rest.cid),
                  // immutable: true,
                },
              };
            }
            return cl;
          }, {}),
          channelGroupList: channelGroupList.filter(({ cgid }) => cgid === currentCgid),
        };

        if (!this.search) {
          acc[member.uid] = dataToPush;
          return acc;
        }

        const { firstName, lastName, email } = dataToPush.userDetail;

        const findByFirstName = firstName.toLowerCase().includes(this.search.toLowerCase());
        const findByLastName = lastName.toLowerCase().includes(this.search.toLowerCase());
        const findByEmail = email.toLowerCase().includes(this.search.toLowerCase());

        if (findByFirstName || findByLastName || findByEmail) acc[member.uid] = dataToPush;

        return acc;
      }, {});
    },
    isAdmin() {
      const { uid } = this.myAuthenticate;
      const myAccount = this.allChannelGroupAccountNewVersion[uid];

      if (!myAccount) return false;
      return myAccount.isAdmin;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 500),
  },
  mounted() {
    this.reportAccountMemberView({});
  },
  methods: {
    ...mapActions({
      // getAccount: 'TheSettingWorkspaceSetting/getAccount',
      createAccountLinkChannel: 'TheSettingWorkspaceSetting/createAccountLinkChannel',
      createAccountUnlinkChannel: 'TheSettingWorkspaceSetting/createAccountUnlinkChannel',
      createAccountUnlinkChannelGroup: 'TheSettingWorkspaceSetting/createAccountUnlinkChannelGroup',
      reportAccountMemberView: 'TheSettingWorkspaceSetting/reportAccountMemberView',
      up_linkChannel: 'TheSettingWorkspaceSetting/up_linkChannel',
      up_unlinkChannel: 'TheSettingWorkspaceSetting/up_unlinkChannel',
      up_unlinkChannelGroup: 'TheSettingWorkspaceSetting/up_unlinkChannelGroup',
    }),

    toggleOpenedDetails(user) {
      if (this.defaultOpenedDetails.includes(user.uid)) {
        this.defaultOpenedDetails = this.defaultOpenedDetails.filter((uid) => uid !== user.uid);
      } else {
        this.defaultOpenedDetails.push(user.uid);
      }
    },
    openIntercom() {
      this.$intercom.showNewMessage('Hi! I would like to add a new member! 🙌');
    },
  },
};
</script>
