/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '@dailyplanet/providers/engine';

const target = 'clark';

const memberGroup = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/memberGroup`, method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/memberGroup`, method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/memberGroup/${payload.mgid}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/memberGroup/${payload.mgid}`, method: 'DELETE', payload, httpQuery, target }),
};

export default memberGroup;
