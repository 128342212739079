<template>
  <section class="ubuWizardView ubuSidePanel ubuPanel-xl ubuChannelAdd">
    <div class="multibar row-vcentered">
      <b-button
        class="start is-ghost"
        icon-pack="ubu"
        icon-left="ubu-arrow-left"
        label="Back"
        tag="router-link"
        to="/setting/channel/selectFacebook"
      />

      <b-button
        tag="router-link"
        to="/setting/channel"
        class="end is-simple-icon is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <header>
      <p class="text is-3 has-text-secondary">
        Manage Meta channels |
        <span v-if="facebookPage"><UbuIconFacebook /> {{ facebookPage.name }}</span>
      </p>
      <h1 class="title is-1">Select the channels you want to connect</h1>
    </header>

    <template v-if="errorMessage">
      <b-message
        title="Uh oh, it seems there is an issue with Meta !"
        type="is-danger"
        aria-close-label="Close message"
      >
        <span class="breakAll">
          {{ errorMessage }}
        </span>
      </b-message>

      <br>
    </template>

    <div class="buttons is-right">
      <b-button
        label="Done"
        type="is-primary"
        tag="router-link"
        to="/setting/channel"
      />
    </div>

    <article v-if="facebookPage">
      <div class="buttons">
        <UbuActionCard
          v-if="!hasMessengerFeature"
          class="mb-4"
          :label="`Connect ${facebookPage.name}`"
          icon-pack="ubu"
          social-icon="FACEBOOK"
          expanded
          @click="handleClickNoFeature('MESSENGER')"
        />

        <UbuActionCard
          v-else
          class="mb-4"
          :label="`Connect ${facebookPage.name}`"
          icon-pack="ubu"
          social-icon="FACEBOOK"
          :left-text="channelFacebook ? 'Connected' : ''"
          left-text-type="is-success"
          :display-left-text="true"
          :is-checked="channelFacebook ? true : false"
          :has-loading="true"
          :is-loading="tryConnectFacebookLoading"
          expanded
          :disabled="tryConnectFacebookLoading"
          @click="connectFacebook(facebookPage)"
        />
      </div>

      <fieldset
        v-if="routableItemsFromAccounts.avaiblable.length"
        class="fieldset"
      >
        <legend>AVAILABLE</legend>

        <br>

        <div class="buttons">
          <template v-for="item in routableItemsFromAccounts.avaiblable">
            <UbuActionCard
              :key="item.label"
              class="mb-4"
              :label="`Connect ${item.label}`"
              icon-pack="ubu"
              :social-icon="item.socialIcon"
              :left-text="item.isActive ? 'Connected' : ''"
              left-text-type="is-success"
              :display-left-text="true"
              :is-checked="item.isActive ? true : false"
              :has-loading="true"
              :is-loading="tryConnectMetaLoading"
              expanded
              :disabled="tryConnectMetaLoading"
              @click="connectInstagramChannel(facebookPage, item)"
            />
          </template>
        </div>
      </fieldset>

      <fieldset
        v-if="routableItemsFromAccounts.unavailable.length"
        class="fieldset"
      >
        <legend>UNAVAILABLE</legend>

        <br>

        <div class="buttons">
          <UbuActionCard
            v-for="item in routableItemsFromAccounts.unavailable"
            :key="item.label"
            class="mb-4"
            :label="item.label"
            icon-pack="ubu"
            :social-icon="item.socialIcon"
            :info-label="item.subtext"
            info
            expanded
            disabled
          />
        </div>
      </fieldset>
    </article>

    <br>

    <div class="buttons is-right">
      <b-button
        type="is-ghost"
        label="Need help to connect your channels ?"
        @click="$intercom.showNewMessage('Hi! I need help to connect my Meta channels ! 🙌')"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSettingChannelsAddMetaSelectInstagram',
  data() {
    return {
      errorMessage: null,
      tryConnect: null,
      tryConnectFacebookLoading: false,
      tryConnectMetaLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      account: 'TheSettingWorkspaceSetting/account',
      currentChannelGroup: 'TheSettingWorkspaceSetting/currentChannelGroup',
      facebookConnectList: 'TheSettingWorkspaceSetting/facebookConnectList',
      accountFeatures: 'TheSettingWorkspaceSetting/accountFeatures',
    }),
    hasMessengerFeature() {
      return !!this.accountFeatures('MESSENGER');
    },
    channelList() {
      if (!this.account) return [];
      const channels = this.account.channelGroupList.flatMap(({ channelList }) => channelList);

      return channels;
    },
    facebookPage() {
      if (!this.facebookConnectList) return null;
      const { facebookPages } = this.facebookConnectList;

      if (!facebookPages) return null;

      if (!this.$route.params.fpid) return null;

      const page = facebookPages
        .find(({ fpid }) => Number(fpid) === Number(this.$route.params.fpid));

      return page;
    },
    instagramAccountList() {
      if (!this.facebookPage) return [];

      const accountList = this.facebookPage.instagramAccounts.reduce((acc, account) => {
        const fbInstagram = this.channelList.find(({ name, platformName }) => account.username === name && platformName === 'INSTAGRAM');
        if (!acc[account.igbaid]) {
          acc[account.igbaid] = {
            igbaid: account.igbaid,
            username: account.username,
            isActive: !!fbInstagram,
            channelGroupCgid: fbInstagram ? fbInstagram.channelGroupCgid : null,
          };
        }
        return acc;
      }, {});

      return Object.values(accountList);
    },
    routableItemsFromAccounts() {
      return this.instagramAccountList
        .map((account) => ({
          label: account.username,
          id: account.igbaid,
          socialIcon: 'INSTAGRAM',
          isActive: account.isActive,
          ...this.isAvaiblable(account),
        }))
        .reduce((acc, row) => {
          if (row.isAvaiblable) acc.avaiblable.push(row);
          else acc.unavailable.push(row);
          return acc;
        }, { avaiblable: [], unavailable: [] });
    },
    channelFacebook() {
      const { cgid } = this.currentChannelGroup;
      return this.channelList
        .find(({ extrefId, channelGroupCgid, platformName }) => channelGroupCgid === cgid
        && extrefId === this.facebookPage.extrefPageId && platformName === 'FACEBOOK');
    },
    channelInstagram() {
      const { cgid } = this.currentChannelGroup;
      return this.channelList
        .find(({ platformName, channelGroupCgid }) => channelGroupCgid === cgid && platformName === 'INSTAGRAM');
    },
  },
  mounted() {
    this.reportFacebookConnect();
  },
  methods: {
    ...mapActions({
      getAccount: 'TheSettingWorkspaceSetting/getAccount',
      reportFacebookConnect: 'TheSettingWorkspaceSetting/reportFacebookConnect',
      // _connectMetaChannel: 'TheSettingWorkspaceSetting/connectMetaChannel',
      _createChannel: 'TheSettingWorkspaceSetting/createChannel',
      _reconnectChannel: 'TheSettingWorkspaceSetting/reconnectChannel',
      _tryConnectMeta: 'TheSettingWorkspaceSetting/tryConnectMeta',
    }),
    handleClickNoFeature(feature) {
      this.$intercom.showNewMessage(`Hi! I would like to activate the ${feature} module! 🙌`);
    },

    isAvaiblable(account) {
      if (account.isActive) {
        if (Number(account.channelGroupCgid) === Number(this.currentChannelGroup.cgid)) {
          return { isAvaiblable: true, subtext: '' };
        }
        if (Number(account.channelGroupCgid) !== Number(this.currentChannelGroup.cgid)) {
          return { isAvaiblable: false, subtext: 'Used in another workspace' };
        }
      }
      if (this.channelInstagram) {
        return { isAvaiblable: false, subtext: 'This account is already linked in another workspace' };
      }
      return { isAvaiblable: true, subtext: '' };
    },
    connectInstagramChannel(page, { id: igbaid, label: username }) {
      this.tryConnectMetaLoading = true;
      const payload = {
        facebook: { fpid: page.fpid, name: page.name },
        instagram: { igbaid, name: username },
      };

      const channel = this.channelInstagram;

      const errors = ['E_EXTERNAL_INSTAGRAM_CONVERSATION_FETCH_ERROR', 'E_EXTERNAL_API_CHANNEL_CHANNEL_META_ERROR'];

      return this._tryConnectMeta({ payload })
        .then(() => {
          if (!channel) return this._createChannel({ payload: { igbaid, platformName: 'INSTAGRAM' } });
          return this._reconnectChannel({ payload: { igbaid, cid: channel.cid, platformName: 'INSTAGRAM' } });
        })
        .then(() => {
          this.tryConnectMetaLoading = false;
          this.tryConnect = username;
          this.errorMessage = null;
          return this.successFinisher();
        })
        .catch((error) => {
          this.tryConnectMetaLoading = false;
          const { message, body } = error;

          if (errors.includes(message)) {
            this.errorMessage = body.message;
          } else {
            this.$buefy.snackbar.open({
              message: 'Uh oh something unexpected has occured, please ask support !',
              type: 'is-danger',
              position: 'is-top',
              duration: 20000,
            });
          }
        });
    },

    connectFacebook({ fpid }) {
      this.tryConnectFacebookLoading = true;
      const channel = this.channelFacebook;
      return Promise.resolve()
        .then(() => {
          if (!channel) return this._createChannel({ payload: { fpid, platformName: 'FACEBOOK' } });
          return this._reconnectChannel({ payload: { fpid, cid: channel.cid, platformName: 'FACEBOOK' } });
        })
        .then(() => {
          this.tryConnectFacebookLoading = false;
          return this.successFinisher();
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheSettingChannelsAddMetaSelectInstagram.vue ~ line 277 ~ connectFacebook ~ error', error);
          this.tryConnectFacebookLoading = false;
          const { message, body } = error;

          if (message === 'E_EXTERNAL_API_CHANNEL_CHANNEL_META_ERROR') {
            this.errorMessage = body;
          } else {
            this.$buefy.snackbar.open({
              message: 'Uh oh something unexpected has occured, please ask support !',
              type: 'is-danger',
              position: 'is-top',
              duration: 20000,
            });
          }
        });
    },

    successFinisher() {
      return this.getAccount({
        httpQuery: {
          channelAndChannelGroupList: true,
          accountMembers: true,
          accountEvent: true,
          hasFeature: true,
        },
      })
        .then(() => {
          this.$buefy.snackbar.open({
            message: 'Channels connected !',
            type: 'is-success',
            position: 'is-top',
            duration: 5000,
          });
        });
    },
  },
};
</script>
