/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    detailledUserList: [],
    detailledUser: null,
  },
  mutations: {
    SET_DETAILLED_USER_LIST(state, newDatas) { state.detailledUserList = newDatas; },
    SET_DETAILLED_USER(state, newDatas) { state.detailledUser = newDatas; },
    RESET_STORE(state) {
      state.detailledUserList = [];
      state.detailledUser = null;
    },
  },
  getters: {
    getterDetailledUserList: (state) => state.detailledUserList,
    getterDetailledUser: (state) => state.detailledUser,
  },
  actions: {
    report({ commit, rootGetters }) {
      if (!isActionAllowed(rootGetters, 'USER.REPORT_DETAILLED')) return undefined;

      return clark.api.reportDetailledUsers.report({ payload: {}, httpQuery: {} })
        .then((response) => { commit('SET_DETAILLED_USER_LIST', response.arrayMapper('uid')); });
    },
    get({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'USER.GET_DETAILLED')) return undefined;

      return clark.api.reportDetailledUsers.get({ payload, httpQuery: {} })
        .then((response) => { commit('SET_DETAILLED_USER', response); });
    },
    up_userRole({ commit, getters }, {
      userUid, userRoleUrid,
    }) {
      const { getterDetailledUserList, getterDetailledUser } = getters;

      if (getterDetailledUser && Number(userUid) === Number(getterDetailledUser.uid)) {
        const detailledUserUpdated = getterDetailledUser;
        detailledUserUpdated.userRoleUrid = userRoleUrid;

        commit('SET_DETAILLED_USER', detailledUserUpdated);
      }

      if (getterDetailledUserList.length) {
        const detailledUserListUpdated = getterDetailledUserList;
        const index = detailledUserListUpdated.findIndex(({ uid }) => Number(uid) === Number(getterDetailledUser.uid));
        detailledUserListUpdated[index].userRoleUrid = userRoleUrid;

        commit('SET_DETAILLED_USER_LIST', detailledUserListUpdated);
      }
    },
    up_detailledUser({ commit, getters }, { payload: { aid, passName, ...payload } }) {
      const { getterDetailledUser } = getters;

      const prePayload = { ...getterDetailledUser, ...payload };

      commit('SET_DETAILLED_USER', prePayload);
    },
    up_detailledUserEmail({ commit, getters }, { payload: { passName } }) {
      const { getterDetailledUser } = getters;

      const index = getterDetailledUser.authenticates.findIndex(({ revokedOn }) => revokedOn === null);
      const prePayload = getterDetailledUser;

      prePayload.authenticates[index].passName = passName;

      commit('SET_DETAILLED_USER', prePayload);
    },
    up_detailledUserList({ commit, getters }, { payload: { uid, firstName, lastName } }) {
      const { getterDetailledUserList } = getters;

      const newDetailledUserList = getterDetailledUserList;

      newDetailledUserList[uid].firstName = firstName;
      newDetailledUserList[uid].lastName = lastName;

      commit('SET_DETAILLED_USER_LIST', newDetailledUserList);
    },
    up_detailledUserListEmail({ commit, getters }, { payload: { passName, uid } }) {
      const { getterDetailledUserList } = getters;

      const newDetailledUserList = getterDetailledUserList;
      const index = newDetailledUserList[uid].authenticates.findIndex(({ revokedOn }) => revokedOn === null);

      newDetailledUserList[uid].authenticates[index].passName = passName;

      commit('SET_DETAILLED_USER_LIST', newDetailledUserList);
    },
    up_addUserInDetailledUserList({ commit, getters }, newUser) {
      const { getterDetailledUserList } = getters;
      const newDetailledUserList = getterDetailledUserList;

      newDetailledUserList[newUser.uid] = newUser;

      commit('SET_DETAILLED_USER_LIST', newDetailledUserList);
    },
  },
  modules: {},
};
