<template>
  <form @submit.prevent="createAffiliationCodeFromTextarea()">
    <b-input
      v-model="codeListTextarea"
      placeholder="Paste discount codes, one per line..."
      type="textarea"
      required
    />

    <br>

    <fieldset class="is-bordered">
      <h3 class="subtitle is-3">Add codes to folder 👇</h3>

      <TheAffiliationCodeFolderDropdown
        v-model="localAffiliationCodeFolder"
        :can-create="true"
      />
    </fieldset>

    <br>

    <b-field label="Coupon description">
      <b-input
        v-model="description"
        type="textarea"
        placeholder="10% Off for each order over $50"
        maxlength="200"
      />
    </b-field>

    <br>

    <div class="buttons is-right">
      <b-button
        native-type="submit"
        label="Create codes"
        type="is-primary"
      />
    </div>
  </form>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';
import { snackBarSuccess } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  name: 'TheCreateAffiliationCodeFromTextarea',
  props: {
    affiliationCodeFolderAcfid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      description: null,
      codeListTextarea: '',
    };
  },
  computed: {
    ...mapGetters({
      _currentShop: 'TheCreateAffiliationCodePanel/currentShop',
    }),
    localAffiliationCodeFolder: {
      get() { return this.affiliationCodeFolderAcfid; },
      set(val) { this.$emit('input', val); },
    },
  },
  methods: {
    ...mapActions({
      createAffiliationCodeList: 'TheCreateAffiliationCodePanel/createAffiliationCodeList',
    }),
    createAffiliationCodeFromTextarea() {
      const splited = this.codeListTextarea
        .replaceAll(' ', '\n')
        .replaceAll('.', '\n')
        .replaceAll(',', '\n')
        .replaceAll(';', '\n')
        .replaceAll('\'', '')
        .replaceAll(/\n+/g, '\n')
        .trim()
        .split('\n');

      const payload = {
        sid: this._currentShop.sid,
        affiliationCodeList: [...new Set(splited)].map((code) => ({
          shopSid: this._currentShop.sid,
          affiliationCodeFolderAcfid: this.affiliationCodeFolderAcfid,
          contactPlatformCpid: null,
          campaignCid: null,
          type: 'DISCOUNT',
          code,
          provider: 'MANUAL',
          extrefId: null,
          link: `${this._currentShop.baseUrl}/discount/${code}`,
          createdOn: this.$moment().format(),
          description: this.description,
        })),
      };

      return this.createAffiliationCodeList({
        payload,
        httpQuery: { contactPlatform: true },
      })
        .then(() => {
          this.$buefy.snackbar.open({
            ...snackBarSuccess,
            message: 'Discount codes successfully created!',
          });
          this.codeListTextarea = '';
          this.$emit('goToTabByTabPath', 'root');
        });
    },
  },
};
</script>
