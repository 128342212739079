export default class JsonColorizer {
  static value(val) {
    let ret;
    if (val === 'false' || val === 'true') {
      ret = '<span class=json-bool>';
    } else if (val === 'null') {
      ret = '<span class=json-null>';
    } else if (val[0] !== '"') {
      ret = '<span class=json-num>';
    } else {
      ret = '<span class=json-string>';
    }
    return ret;
  }

  static replacer(match, pIndent, pKey, pVal, pEnd) {
    const key = '<span class=json-key>';
    let r = pIndent || '';
    if (pKey) r = `${r + key + pKey.replace(/[": ]/g, '')}</span>: `;
    if (pVal) r = `${r + (JsonColorizer.value(pVal)) + pVal}</span>`;
    return r + (pEnd || '');
  }

  static prettyPrint(obj) {
    const jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;
    return JSON.stringify(obj, null, 3)
      .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(jsonLine, JsonColorizer.replacer);
  }
}
