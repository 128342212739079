import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    campaignMentionList: (_state, _getters, _rootState, rootGetters) => {
      const {
        'ApiPublication/campaignMention/getterCampaignMentionList': campaignMentionList,
        'ApiContact/contactPlatformOverview/getterContactPlatformOverviewList': contactPlatformOverviewList,
      } = rootGetters;

      return Object.entries(campaignMentionList)
        .reduce((acc, [pid, cpid]) => {
          const cpo = contactPlatformOverviewList[cpid];
          if (!cpo) return acc;
          if (cpo.mentionList && cpo.mentionList[pid]) {
            acc.push({
              ...cpo.mentionList[pid],
              ...cpo,
            });
            return acc;
          }
          acc.push(cpo);
          return acc;
        }, []);
    },
  },
  actions: {
    reportCampaignMentionList({ dispatch, rootGetters }, { payload = {}, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiPublication/campaignMention/report',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Report campaign mention list',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report campaign mention list.', indefinite: true }));
    },
  },
};
