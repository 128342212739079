/* eslint-disable global-require */
/* eslint-disable import/prefer-default-export */
const barbara = {
  api: require('./barbara/api'),
  shopify: require('./barbara/shopify'),
};
const clark = {
  api: require('./clark/api'),
};
const repentance = {
  api: require('./repentance/index'),
};

const s3 = {
  api: require('./s3/api'),
};

const providers = {
  barbara,
  clark,
  repentance,
  s3,
};

// eslint-disable-next-line import/no-mutable-exports
let store = {};

const setStore = (s) => { store = s; };

export default providers;

export {
  barbara,
  clark,
  repentance,
  s3,
  store,
  setStore,
};
