<template>
  <div>
    <template v-if="type === 'analytic'">
      <div
        :class="['UbuInfluenceCardAnalyticsContainer', {
          'col-1': vfor === 1,
          'col-2': vfor === 2,
          'col-3': vfor === 3,
          'col-4': vfor === 4,
          'col-5': vfor === 5,
          'col-6': vfor === 6,
        }]"
      >
        <template v-for="i in vfor">
          <div
            v-if="i"
            :key="i"
            class="ubuAnalyticCard"
          >
            <b-skeleton
              circle
              width="34px"
              height="34px"
              position="is-centered"
            />
            <b-skeleton
              width="25%"
              height="25px"
              position="is-centered"
            />
            <b-skeleton
              width="60%"
              height="20px"
              position="is-centered"
            />
            <b-skeleton
              width="25%"
              height="20px"
              position="is-centered"
            />
          </div>
        </template>
      </div>
    </template>

    <template v-else-if="type === 'mention'">
      <div
        class="is-flex"
      >
        <div
          v-for="i in vfor"
          :key="i"
          :class="['UbuInfluenceMentionsContent', {
            'col-1': vfor === 1,
            'col-2': vfor === 2,
            'col-3': vfor === 3,
            'col-4': vfor === 4,
            'col-5': vfor === 5,
            'col-6': vfor === 6,
            'post': option === 'post',
            'stories': option === 'story',
          }]"
        >
          <b-skeleton
            height="320px"
          />
        </div>
      </div>
    </template>

    <template v-else-if="type === 'table'">
      <UbuTableSkeleton class="mt-4" />
    </template>

    <template v-else>
      <span class="has-text-secondary subtitle">Loading...</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UbuSkeleton',
  props: {
    type: { type: String, default: '' },
    vfor: { type: Number, default: 1 },
    option: { type: String, default: '' },
  },
};
</script>
