import axios from 'axios';

import ErrorBuilder from '@dailyplanet/utils/Errors';

const internals = {
  // eslint-disable-next-line arrow-body-style
  parseResponse: ({ data }) => {
    return data;
  },
  // eslint-disable-next-line arrow-body-style
  parseError: (error) => {
    // Used for errors returned with res.send
    throw new ErrorBuilder({
      message: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    });
  },
};

const requestBuilder = (context) => {
  const {
    payload: data, method, url, headers,
  } = context;

  return axios.create({
    // withCredentials: true,
    headers,
  })
    .request({ url, data, method });
};

const builderS3 = (context) => requestBuilder(context)
  .then(internals.parseResponse)
  .catch(internals.parseError);

export default builderS3;
