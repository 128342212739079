<template>
  <div>
    <DevDetails
      :cnt="{name: 'router', obj: routerInfo}"
    />
    <DevDetails
      open
      :cnt="{name: 'routerFiltered', obj: routerFiltered}"
    />
  </div>
</template>

<script>
export default {
  name: 'TheRouter',
  computed: {
    routerInfo() {
      return this.$router.options.routes.map(({ component, ...rest }) => rest);
    },
    routerFiltered() {
      return this.routerInfo
        .filter(({ meta }) => meta.inSidebar)
        .map(({ meta, ...rest }) => ({ ...meta, ...rest }))
        .reduce((acc, { mainFolder, sidebarSlot, ...rest }) => {
          if (!acc[sidebarSlot]) {
            acc[sidebarSlot] = {};
          }
          if (!acc[sidebarSlot][mainFolder]) {
            acc[sidebarSlot][mainFolder] = [{ mainFolder, ...rest }];
            return acc;
          }
          acc[sidebarSlot][mainFolder] = [
            ...acc[sidebarSlot][mainFolder],
            { mainFolder, sidebarSlot, ...rest },
          ];
          return acc;
        }, {});
    },
  },
};
</script>
