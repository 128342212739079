/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    detailledLeadList: {},
  },
  mutations: {
    SET_DETAILLED_LEAD_LIST(state, newDatas) { state.detailledLeadList = newDatas; },
    UPDATE_DETAILLED_LEAD_LIST(state, newDatas) {
      const myUbuLeadList = Object.values(state.detailledLeadList).concat(newDatas).arrayMapper('lid');
      state.detailledLeadList = myUbuLeadList;
    },
    UPDATE_LEAD_ON_LIST(state, newDatas) {
      const myLead = state.detailledLeadList[newDatas.lid];
      const updatedLead = {
        ...myLead,
        ...newDatas,
      };
      // const myUbuLeadList = Object.values(state.detailledLeadList).concat(newDatas).arrayMapper('lid');
      state.detailledLeadList[newDatas.lid] = updatedLead;
      // state.detailledLeadList = myUbuLeadList;
    },
    DELETE_LEAD_ON_LIST(state, newData) {
      state.detailledLeadList = Object.values(state.detailledLeadList).filter(({ lid }) => lid !== newData.lid).arrayMapper('lid');
    },
  },
  getters: {
    getterDetailledLeadList: (state) => state.detailledLeadList,
  },
  actions: {
    reportDetailledLeads({ commit, rootGetters }, { httpQuery }) {
      if (!isActionAllowed(rootGetters, 'LEAD.REPORT_DETAILLED')) return undefined;

      return clark.api.reportDetailledLead
        .report({ payload: {}, httpQuery })
        .then((detailledLeadList) => {
          commit('SET_DETAILLED_LEAD_LIST', detailledLeadList.arrayMapper('lid'));
        });
    },
  },
};
