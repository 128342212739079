/* eslint-disable max-len */

import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';
import { actions, getters, commonState } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        ...commonState.menu.home.menuItems,
      ],
    },
  },
  getters: {
    navigation: (state) => state.navigation,
    ...getters.account,
    ...getters.authenticate,
    ...getters.accountState,
    ...getters.feature,
    ...getters.userRole,
    ...getters.channelGroup,
    channelSms: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiConversation/channelSms/getterChannelSms': channelSms } = rootGetters;
      return channelSms;
    },
  },
  actions: {
    ...actions.account,
    ...actions.accountState,
    ...actions.feature,
    ...actions.userRole,
    ...actions.channelGroup,
    ...actions.migrate,
    ...actions.channel,
    createChannelSms({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'ApiConversation/channelSms/create',
        context: {
          payload,
          httpQuery: { },
        },
        textNorifier: 'create a new channel sms',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to create a channel sms',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    up_accountChannel({ commit, rootGetters }, newChannel) {
      const { 'Account/getterAccount': account } = rootGetters;

      const indexChannelGroup = account.channelGroupList.findIndex(({ cgid }) => cgid === newChannel.channelGroupCgid);
      const newAccount = account;
      newAccount.channelGroupList[indexChannelGroup].channelList.push(newChannel);

      commit('Account/SET_ACCOUNT', newAccount, { root: true });
    },
  },
};
