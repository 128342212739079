<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        :to="`/admin/account/${currentAid}/channels`"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <h2 class="title is-1">Details channel Instagram</h2>

    <br>
    <DevDetails :cnt="{ name: 'account', obj: account }" />
    <DevDetails :cnt="{ name: 'channelInstagram', obj: channelInstagram }" />
    <DevDetails :cnt="{ name: 'myAuthenticate', obj: myAuthenticate }" />

    <template v-if="channelInstagram">
      <section>
        <div class="is-flex is-justify-content-center">
          <component
            :is="`UbuIcon${channelInstagram.platformName.capitalize()}`"
            style="padding-top: 4px;"
          />
          <p
            class="has-text-secondary"
          >
            @{{ channelInstagram.contactPlatform.username }}
          </p>
        </div>

        <br>
        <template v-if="myAuthenticate.roleDetail.urid === 'SUPER_ADMIN'">
          <b-field label="Migrate">
            <b-button
              label="Common"
              type="is-danger"
              @click="migrateCommon({payload: {channelUsername: channelInstagram.name}})"
            />
            <b-button
              label="Proto"
              type="is-danger"
              @click="migrateProto({payload: {channelUsername: channelInstagram.name}})"
            />
            <b-button
              label="Various"
              type="is-danger"
              @click="migrateVarious({payload: {channelUsername: channelInstagram.name}})"
            />
          </b-field>

          <br>

          <b-field label="Fetch conversation">
            <b-datetimepicker
              v-model="dateTo"
              placeholder="Type or select a date..."
              editable
            />
            <b-input
              v-model="maxFetchedThreads"
              type="number"
              step="1"
            />
            <b-button
              label="Fetch"
              type="is-danger"
              @click="fetchConversation()"
            />
          </b-field>

          <br>
        </template>

        <fieldset>
          <b-field label="ChannelCid">
            <b-input
              v-model="channelInstagram.cid"
              readonly
            />
          </b-field>

          <br>

          <b-field label="ChannelGroupCgid">
            <b-input
              v-model="channelInstagram.channelGroupCgid"
              readonly
            />
          </b-field>

          <br>

          <b-field label="ContactPlatformCpid">
            <b-input
              v-model="channelInstagram.contactPlatformCpid"
              readonly
            />
          </b-field>

          <br>

          <b-field label="Name">
            <b-input
              v-model="channelInstagram.name"
              readonly
            />
          </b-field>

          <br>

          <b-field label="Username">
            <b-input
              v-model="channelInstagram.contactPlatform.username"
              readonly
            />
          </b-field>
        </fieldset>
      </section>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheAdminAccountChannelMigrate',
  data() {
    return {
      dateTo: null,
      maxFetchedThreads: null,
    };
  },
  computed: {
    ...mapGetters({
      account: 'TheAdmin/account',
      myAuthenticate: 'TheAdmin/myAuthenticate',
    }),
    currentAid() {
      return this.$route.params.aid;
    },
    currentCid() {
      return this.$route.params.cid;
    },
    channelInstagram() {
      if (!this.account) return null;

      const platform = this.account.channelGroupList
        .reduce((acc, { channelList }) => {
          acc.push(...channelList);
          return acc;
        }, [])
        .arrayMapper('cid');

      return platform[this.currentCid];
    },
  },
  mounted() {
    if (!this.channelInstagram) {
      this.$router.push({
        path: `/admin/account/${this.currentAid}/channels`,
      });
    }
  },
  methods: {
    ...mapActions({
      migrateCommon: 'TheAdmin/migrateCommon',
      migrateProto: 'TheAdmin/migrateProto',
      migrateVarious: 'TheAdmin/migrateVarious',
      _fetchConversation: 'TheAdmin/fetchConversation',
    }),
    fetchConversation() {
      const payload = {
        cid: this.channelInstagram.cid,
      };

      if (this.dateTo) {
        payload.dateTo = this.dateTo.toISOString();
      }

      if (this.maxFetchedThreads) {
        payload.maxFetchedThreads = this.maxFetchedThreads;
      }

      return this._fetchConversation({ payload });
    },
  },
};
</script>
