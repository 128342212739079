/* eslint-disable max-len */
import { actions, getters, commonState } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        ...commonState.menu.home.menuItems,
      ],
    },
  },
  getters: {
    navigation: (state) => state.navigation,
    ...getters.account,
    ...getters.affiliationCodeFolder,
    ...getters.activity,
    ...getters.authenticate,
    ...getters.campaign,
    ...getters.channelGroup,
    ...getters.shop,
    ...getters.crmList,
    ...getters.contact,
    ...getters.contactGroup,
    ...getters.contactPlatformOverview,
    ...getters.memberGroup,
    ...getters.template,
    ...getters.protoList,
    ...getters.searchInstagramUsername,
    ...getters.threadGroup,
    ...getters.tribe,
    ...getters.segmentFilter,
    ...getters.userDetail,
    ...getters.crewAdmin,
  },
  actions: {
    ...actions.account,
    ...actions.activity,
    ...actions.bulkActivity,
    ...actions.campaign,
    ...actions.channelGroup,
    ...actions.contactGroup,
    ...actions.contactPlatformOverview,
    ...actions.protoList,
    ...actions.searchInstagramUsername,
    ...actions.shop,
    ...actions.segment,
    ...actions.template,
    ...actions.threadGroup,
    ...actions.segmentFilter,
    ...actions.crewAdmin,
  },
};
