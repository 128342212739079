<template>
  <div class="container">
    <div class="columns-container columns">
      <div class="column">
        <div class="multibar row-vcentered">
          <h2 class="title is-3 start">
            <b-tag type="is-info mr-2">{{ jsonToBuild.method }}</b-tag>
            {{ jsonToBuild.title }}
          </h2>
        </div>

        <hr>

        <p v-for="(item, i) in jsonToBuild.requirementList" :key="i">
          <b-icon
            class="has-text-primary"
            pack="ubu"
            icon="ubu-warning"
          />
          {{ item }}
        </p>

        <hr>

        <!-- <div class="pt-4 pb-2">
          <p
            v-for="(description, index) in jsonToBuild.description"
            :key="index"
            class="mb-1"
          >
            {{ description }}
          </p>
        </div> -->

        <br>

        <h3 class="subtitle">Path parameters</h3>

        <hr>
        <div v-for="param in jsonToBuild.pathRequestParams" :key="param.key">
          <b-taglist class="mb-0">
            <b-taglist attached class="mb-0 mr-2">
              <b-tag class="mb-0" type="is-link is-light">{{ param.key }}</b-tag>
              <b-tag class="mb-0" type="is-dark is-light">
                {{ jsonToBuild?.requiredParams?.[param.key] }}
              </b-tag>
            </b-taglist>
            <!-- <b-tag type="is-success is-light mr-2">{{ param.type }}</b-tag> -->
            <b-tag class="mb-0" type="is-danger is-light">
              {{ param.required ? 'Required' : 'Optional' }}
            </b-tag>
          </b-taglist>
          <hr>
        </div>

        <br>

        <h3 class="subtitle">Request body</h3>

        <hr>
        <div v-for="param in jsonToBuild.bodyRequestParams" :key="param.key">
          <b-tag type="is-link is-light mr-2">{{ param.key }}</b-tag>
          <b-tag type="is-success is-light mr-2">{{ param.type }}</b-tag>
          <b-tag type="is-danger is-light">{{ param.required ? 'Required' : 'Optional' }}</b-tag>
          <hr>
        </div>
      </div>

      <div class="column is-half">
        <div class="request-block">
          <div class="request-block-header multibar row-vcentered">
            <span class="start">
              <b-tag type="is-info" size="">POST</b-tag>
              {{ jsonToBuild.requestUrl }}
            </span>

            <b-icon
              pack="ubu"
              icon="ubu-copy"
              class="end clickable"
              @click.native="copyToClipboard(jsonToBuild.requestUrl)"
            />
          </div>

          <pre class="request-block-span has-text-black">
            {{ jsonToBuild.requestExample }}
          </pre>
        </div>

        <br>

        <div class="request-block">
          <div class="request-block-header multibar row-vcentered">
            <span class="start">Response</span>
          </div>

          <pre class="request-block-span has-text-black">
            {{ jsonToBuild.responseExpected }}
          </pre>
        </div>
      </div>
    </div>

    <div class="request-block">
      <div class="request-block-header multibar row-vcentered">
        <b-icon pack="ubu" icon="ubu-ad" class="start" />
        <span class="start">Error list</span>
      </div>

      <pre class="request-block-span has-text-black">
<span v-for="error in jsonToBuild.errorList" :key="error.name">
  [{{ error.code }}]<strong>{{ error.name }}</strong> -- {{ error.reason }}
</span>
          </pre>
    </div>
    <br>

    <slot name="footer" />
  </div>
</template>

<script>
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheEndpointDocumentationBuilder',
  props: {
    jsonToBuild: {
      type: Object,
      required: true,
    },
  },
  methods: {
    copyToClipboard(valueToCopy) {
      navigator.clipboard.writeText(valueToCopy);
      this.$buefy.snackbar.open({
        message: 'Copied to clipboard.',
        ...snackbarSuccess,
      });
    },
  },
};
</script>

<style lang="scss">
.container {
  padding: 24px 16px;
  height: 100%;
  & .request-block {
    background-color: var(--note60);
    border-radius: 6px;
    padding: 4px;
    & .request-block-header {
      background-color: var(--note);
      border-radius: 6px;
      padding: 4px;
    }
    & .request-block-span {
      margin: 0;
      padding: 4px;
      background-color: transparent;
      width: 100%;
      // white-space: pre-wrap;
      word-break: break-word;
      overflow: auto;
    }
  }
}
</style>
