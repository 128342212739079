<template>
  <section
    id="TheMessageList"
  >
    <DevDetails :cnt="{name: 'threadDetails', obj: threadDetails}" />
    <DevDetails :cnt="{name: 'sortedMessages', obj: sortedMessages}" />
    <DevDetails :cnt="{name: 'threadDetails', obj: threadDetails}" />
    <UbuScrollerV2
      id="UbuScroller"
      :item-list="sortedMessages"
      :item-count-total="itemCountTotal"
      :reversed="reversed"
      :page="page"
      :per-page="perPage"
      :page-range="pageRange"
      :loading="isLoading"
      :scroll-range="200"
      :last-page-fetched="lastPageFetched"
      :previous-last-item-id="previousLastItemId"
      class="ubuMessageList"
      :style="`height: calc(100vh - ${headerHeight}px - ${textareaHeight}px - 12px);`"
      @fetchNewPage="fetchNewPage()"
      @changePageToNext="changePageToNext()"
      @changePageToPrevious="changePageToPrevious()"
    >
      <template v-slot:loading>
        <div
          v-for="item in messagesSkeletons"
          :key="item.mid"
          class="ubuMessageRow"
        >
          <UbuThreadMessageSkeleton
            :class="[{'start': item.isMe, 'end': !item.isMe}]"
            :message="item"
          />
        </div>
      </template>
      <template v-slot:item="{ item, index }">
        <TheMessageRow
          v-if="item"
          :item="item"
          :item-list="sortedMessages"
          :index="index"
          :has-more-to-load="threadDetails ? threadDetails.thread.hasMoreMessagesToLoad : false"
          :thread-details="threadDetails"
          :window-width="windowWidth"
          @translate="translateText({ payload: $event })"
          @revertTranslate="revertTranslate({ payload: $event })"
        />
      </template>
      <template
        v-if="threadDetails ? threadDetails.thread.hasMoreMessagesToLoad : false"
        v-slot:end
      >
        <b-button
          class="loadMore"
          type="is-secondary"
          :loading="isLoadingMoreItems"
          @click="loadMore()"
        >
          <b-icon
            pack="mdi"
            size="is-small"
            icon="refresh"
          />
          <span>Load more</span>
        </b-button>
      </template>
    </UbuScrollerV2>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ScrollerMixin from '@dailyplanet/mixins/ScrollerMixin';
import TestMessagesWithMedias from '@dailyplanet/mixins/TestMessagesWithMedias';
// import TestMessengerMessagesWithMedias from '@dailyplanet/mixins/TestMessengerMessagesWithMedias';

export default {
  name: 'TheMessageList',
  mixins: [ScrollerMixin, TestMessagesWithMedias],
  props: {
    textareaHeight: {
      type: Number,
      default: 500,
      required: true,
    },
    headerHeight: {
      type: Number,
      default: 43,
      required: true,
    },
    threadDetails: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingMessage: {
        mid: 0,
        loading: true,
        isMe: true,
      },
      httpQuery: {
        pagin: [0, 25],
      },
      page: 0,
      perPage: 25,
      // dateKey: 'itemDate',
      // itemKey: 'customId',
      // keyField: 'customIndex',
      reversed: true,
      isLoading: true,
      // idsToRemove: [],
      pageRange: 3,
      listName: 'itemList',
      listIndex: 'customId',
      listFetcher: 'fetchItemList',
      payload: {},
      testMode: false,
      messagesSkeletons: [],
      previousLastItemId: null,
      previousFirstItem: null,
      loadingMessagesArray: [],
      threadDetailsFetched: false,
      debouncer: null,
    };
  },
  computed: {
    ...mapGetters({
      itemList: 'TheInbox/messageList',
      itemCountTotal: 'TheInbox/messageListTotal',
      userList: 'TheInbox/userList',
      campaignList: 'TheInbox/campaignList',
      windowWidth: 'TheMenu/getterWindowWidth',
      isLoadingMoreMsgsList: 'TheInbox/isLoadingMoreMessagesList',
    }),
    sortedMessages() {
      if (!this.itemList.length) return [];

      const sortedMessages = this.itemList;
      // .map((msg, index) => ({
      //   customIndex: index,
      //   isMessage: !['PRIVATE_NOTE', 'LOGBOOK'].includes(msg.type),
      //   component: this.messageCmpt(msg),
      //   ...msg,
      // }));

      return sortedMessages;
    },
    isLoadingMoreItems() {
      return this.isLoadingMoreMsgsList[this.$route.params.tid]
        ? this.isLoadingMoreMsgsList[this.$route.params.tid] : false;
    },
    firstItem() {
      if (this.itemList.length) return this.itemList[0];
      return null;
    },
  },
  watch: {
    isLoadingMoreMsgsList: {
      handler(val) {
        if (val[this.$route.params.tid] === false) {
          this.page += 1;
          const pageToRemove = this.page - this.pageRange;
          this.handlePages(null, pageToRemove);
          this.lastPageFetched = this.page;
          this.$nextTick(() => {
            this.previousLastItemId = null;
          });
        } else {
          this.previousLastItemId = this.sortedMessages[this.sortedMessages.length - 1].customId;
        }
      },
      deep: true,
    },
    firstItem: {
      handler(val) {
        if (val && this.previousFirstItemId === null) this.previousFirstItemId = val;
        if (val && JSON.stringify(this.previousFirstItemId) !== JSON.stringify(val)) {
          this.previousFirstItemId = val;
          // if (val.isMe || val.type === 'PRIVATE_NOTE')
          this.changePageToFirst();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadingMessagesArray = this.loadingMessages();
    this.resetItemList();
    this.messagesSkeletons = this.loadingMessages();
    this.debouncer = this.later(500);
    this.debouncer.promise
      .then(() => this.fetchData())
      .catch((e) => {
        console.log('canceled promise', e);
      });
  },
  beforeDestroy() {
    this.debouncer.cancel();
  },
  methods: {
    ...mapActions({
      fetchItemList: 'TheInbox/reportMessages',
      resetItemList: 'TheInbox/resetMessages',
      translateText: 'TheInbox/translateText',
      revertTranslate: 'TheInbox/revertTranslate',
      loadMoreMessages: 'TheInbox/loadMoreMessages',
      setIsLoadingMoreMessagesList: 'TheInbox/up_isLoadingMoreMessagesList',
    }),
    later(delay) {
      let timer = 0;
      let reject = null;
      const promise = new Promise((resolve, _reject) => {
        reject = _reject;
        timer = setTimeout(resolve, delay);
      });
      return {
        get promise() { return promise; },
        cancel() {
          if (timer) {
            clearTimeout(timer);
            timer = 0;
            reject();
            reject = null;
          }
        },
      };
    },
    handleTranslateText(event) {
      this.translateText({ payload: event });
    },
    messageCmpt({ type }) {
      const words = type.split('_');

      const lib = words.reduce((acc, word) => {
        const firstLetter = word.slice(0, 1);
        const messageType = word.slice(1, word.length).toLowerCase();
        // eslint-disable-next-line no-param-reassign
        acc += `${firstLetter}${messageType}`;

        return acc;
      }, '');

      return `UbuThreadMessage${lib}`;
    },
    loadingMessages() {
      const messages = [];
      const widths = ['100px', '150px', '250px'];

      for (let i = 0; i < 10; i += 1) {
        const randomWidthsIndex = Math.floor(Math.random() * widths.length);
        messages.push({
          mid: i,
          loading: true,
          isMe: i > 0 ? !messages[i - 1].isMe : true,
          width: widths[randomWidthsIndex],
          height: '35px',
        });
      }

      return messages;
    },
    fetchData() {
      const { channelCid: cid, tid } = this.$route.params;
      console.log('🚀 ~ file: TheMessageList.vue ~ line 295 ~ fetchData ~ tid', tid);
      this.isLoading = true;
      this.page = 0;
      // Payload initialization
      this.payload = { cid, tid };
      return this[this.listFetcher]({
        payload: this.payload,
        httpQuery: this.httpQuery,
      })
        .then(() => {
          this.isLoading = false;
        });
    },
    loadMore() {
      const { channelCid, tid } = this.$route.params;
      this.setIsLoadingMoreMessagesList({ isLoading: true, tid });
      this.loadMoreMessages({ payload: { cid: parseInt(channelCid, 10), tid: parseInt(tid, 10) } })
        .catch(() => {
          this.setIsLoadingMoreMessagesList({ isLoading: false, tid });
        });
    },
  },
};
</script>
