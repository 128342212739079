import { use, registerComponent } from '@dailyplanet/utils/plugins';

/* eslint-disable no-multi-spaces */

import UbuTribeForm from './UbuTribeForm';
import UbuMemberGroupForm from './UbuMemberGroupForm';
import UbuDisplayStat from './UbuDisplayStat';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuTribeForm);
    registerComponent(Vue, UbuMemberGroupForm);
    registerComponent(Vue, UbuDisplayStat);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuTribeForm,
  UbuMemberGroupForm,
  UbuDisplayStat,
};
