/* eslint-disable max-len */
import moment from 'moment';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import ErrorBuilder from '@dailyplanet/utils/Errors';

// eslint-disable-next-line object-curly-newline
import { dispatchAndForward, dispatchFailure, snackBarFailure, genericErrorHandler, genericThenHandler } from '../$utils/helpers';

export default {
  getters: {
    threadDetails: (_state, _getters, _rootState, rootGetters) => {
      const {
        'Channel/thread/getterThreadDetails': fetchedThreadDetails,
      } = rootGetters;

      return fetchedThreadDetails;
    },
    messageList: (_state, _getters, _rootState, rootGetters) => {
      const {
        // 'User/getterUserList': userList,
        // 'ThreadGroup/getterThreadGroupList': threadGroupList,
        // 'ContactGroup/getterContactGroupList': contactGroupList,
        // 'ChannelGroup/crmList/getterCrmListList': crmListList,
        'Channel/thread/message/getterMessageList': messageList,
        // 'TheContactPlatformPanel/contactPlatformOverview': currentDetailled,
      } = rootGetters;
      // console.trace('🚀 ~ file: thread.js ~ line 34 ~ messageList', messageList);

      if (!messageList) return [];
      const {
        'TheContactPlatformPanel/contactPlatformOverview': currentDetailled,
      } = rootGetters;
      // console.trace('🚀 ~ file: thread.js ~ line 34 ~ currentDetailled', currentDetailled);
      if (!currentDetailled) return [];

      return Object.values(messageList)
        // .map((message) => ({
        //   ...message,
        //   user: message.userUid ? userList[message.userUid] : null,
        //   threadGroup: message.threadGroupTgid ? threadGroupList[message.threadGroupTgid] : null,
        //   contactGroup: message.contactGroupCgid ? contactGroupList[message.contactGroupCgid] : null,
        //   crmList: message.crmListClid ? crmListList[message.crmListClid] : null,
        //   contactPlatform: currentDetailled.contactPlatform,
        // }))
        .sort((a, b) => (moment(b.itemDate) - moment(a.itemDate)));
    },
    messageListTotal: (_state, _getters, _rootState, rootGetters) => {
      const { 'Channel/thread/message/getterMessageListTotal': messageListTotal } = rootGetters;
      return messageListTotal;
    },
    holdedMessage: (_state, _getters, _rootState, rootGetters) => {
      const { 'Channel/thread/message/getterHoldedMessage': holdedMessage } = rootGetters;
      return holdedMessage;
    },
    holdedThread: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiContact/contactPlatformOverview/getterHeldThreads': holdedThread } = rootGetters;

      return holdedThread;
    },
    holdedThreadFormated: (_state, _getters, _rootState, rootGetters) => {
      const {
        'ApiContact/contactPlatformOverview/getterHeldThreads': holdedThread,
        // 'ThreadGroup/getterThreadGroupList': threadGroupList,
        // 'ContactGroup/getterContactGroupList': contactGroupList,
      } = rootGetters;

      const [obj] = Object.values(holdedThread);
      if (obj) {
        return {
          [obj.tid]: {
            ...obj,
            // tags: obj.tags
            //   ? Object.values(threadGroupList).filter((tg) => obj.tags.includes(tg.tgid))
            //   : null,
            // labels: obj.labels
            //   ? Object.values(contactGroupList).filter((cg) => obj.labels.includes(cg.cgid))
            //   : null,
          },
        };
      }
      return undefined;
    },
    currentThreadTid: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiConversation/threadList/getterCurrentThreadTid': currentThreadTid } = rootGetters;
      return currentThreadTid;
    },
  },
  actions: {
    sendMessage({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Channel/thread/message/create',
        context: { payload, httpQuery },
        textNotifier: 'send message',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'sendMessage' }, { root: true }))
        .catch((e) => {
          Snackbar.open({
            message: 'Unable to send message.',
            ...snackBarFailure,
            indefinite: true,
          });

          dispatchFailure(action);
          throw new ErrorBuilder(e);
        });
    },

    sendInstantMessage({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Channel/thread/message/create',
        context: { payload, httpQuery },
        textNotifier: 'send message',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'sendMessage' }, { root: true }))
        .catch((e) => {
          dispatchFailure(action);
          throw new ErrorBuilder(e);
        });
    },

    translateText({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Account/getterAccountFeatures': accountFeatures } = rootGetters;

      if (!accountFeatures.INBOX) {
        return dispatch('Segment/segmentTrack', { event: 'translateTextNoFeature' }, { root: true });
      }

      const action = {
        dispatch,
        target: 'ApiConversation/translateText/translate',
        context: { payload, httpQuery },
        textNotifier: 'translate text',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then((translated) => {
          dispatch('Segment/segmentTrack', { event: 'translateText' }, { root: true });
          return translated;
        })
        .catch(() => {
          Snackbar.open({
            message: 'Unable to translate text.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
    revertTranslate({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'ApiConversation/translateText/revert',
        context: { payload },
        textNotifier: 'revert translate text',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: thread.js ~ line 146 ~ translateText ~ e', e);
          Snackbar.open({
            message: 'Unable to revert translate text.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
    reportMessages({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Channel/thread/message/report',
        context: { payload, httpQuery },
        textNotifier: 'report messages',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: thread.js ~ line 30 ~ reportMessages ~ e', e);
          Snackbar.open({
            message: 'Unable to report messages.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
    resetMessages({ dispatch }) {
      const action = {
        dispatch,
        target: 'Channel/thread/message/reset',
        textNotifier: 'reset messages',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: thread.js ~ line 30 ~ resetMessages ~ e', e);
          Snackbar.open({
            message: 'Unable to reset messages.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
    setHoldedMessage({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Channel/thread/message/setHolded',
        context: { payload, httpQuery },
        textNotifier: 'set holded message',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: thread.js ~ line 156 ~ setHoldedMessage ~ e', e);
          Snackbar.open({
            message: 'Unable to set holded message.',
            ...snackBarFailure,
            indefinite: true,
          });

          dispatchFailure(action);
          throw new ErrorBuilder(e);
        });
    },
    setThreadAppoint({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/threadAppoint/create',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'assign user to thread',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'assignThread' }, { root: true }))
        .catch(() => {
          Snackbar.open({
            message: 'Unable assign this user on this thread.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
    unsetThreadAppoint({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/threadAppoint/delete',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'unassign user to thread',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: thread.js ~ line 30 ~ reportMessages ~ e', e);
          Snackbar.open({
            message: 'Unable to unassign this user to this thread.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },

    _setThreadAppoint({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/threadAppoint/create',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'assign user to thread',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .then(() => dispatch('Segment/segmentTrack', { event: 'assignThread' }, { root: true }))
        .catch(genericErrorHandler(action, { message: 'Unable to assign this user on this thread.', indefinite: true }));
    },
    _unsetThreadAppoint({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/threadAppoint/delete',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'unassign user to thread',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to unassign this user to this thread.', indefinite: true }));
    },

    _bulkThreadsState({ dispatch, rootGetters }, { payload, mutations }) {
      console.log('🚀 ~ file: thread.js ~ line 390 ~ _bulkThreadsState ~ mutations', mutations);
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/bulkThreadsState/update',
        context: { payload: { tgid: null, cgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'update threads state',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .then(() => {
          const { event } = payload;
          let segmentEvent = null;

          switch (event) {
            case 'THREAD_DONE': segmentEvent = 'doneThread'; break;
            case 'THREAD_OPENED': segmentEvent = 'openThread'; break;
            case 'THREAD_CLOSED': segmentEvent = 'closeThread'; break;

            default: break;
          }

          if (segmentEvent) dispatch('Segment/segmentTrack', { event: segmentEvent }, { root: true });
        })
        .catch(genericErrorHandler(action, { message: 'Unable to update threads state.', indefinite: true }));
    },

    // up_changeCurrentThreadAppoint({ dispatch }, { userUid, tid }) {
    //   return dispatch('Channel/thread/up_changeCurrentThreadAppoint', { userUid, tid }, { root: true });
    // },
    // up_deleteCurrentThreadAppoint({ dispatch }, { tid }) {
    //   return dispatch('Channel/thread/up_deleteCurrentThreadAppoint', { tid }, { root: true });
    // },
    // up_addThreadListAppoints({ dispatch }, { userUid, tids }) {
    //   return dispatch('ApiConversation/threadList/up_addThreadListAppoints', { userUid, tids }, { root: true });
    // },
    // up_removeThreadListAppoints({ dispatch }, { userUid, tids }) {
    //   return dispatch('ApiConversation/threadList/up_removeThreadListAppoints', { userUid, tids }, { root: true });
    // },
    // up_addThreadListLabels({ dispatch }, { cgids, tids }) {
    //   return dispatch('ApiConversation/threadList/up_addThreadListLabels', { cgids, tids }, { root: true });
    // },
    // up_removeThreadListLabels({ dispatch }, { cgids, tids }) {
    //   return dispatch('ApiConversation/threadList/up_removeThreadListLabels', { cgids, tids }, { root: true });
    // },
    // up_addThreadListTags({ dispatch }, { tgids, tids }) {
    //   return dispatch('ApiConversation/threadList/up_addThreadListTags', { tgids, tids }, { root: true });
    // },
    // up_removeThreadListTags({ dispatch }, { tgids, tids }) {
    //   return dispatch('ApiConversation/threadList/up_removeThreadListTags', { tgids, tids }, { root: true });
    // },
    // up_changeCurrentThreadIsOpen({ dispatch }, { tid, isOpen }) {
    //   return dispatch('Channel/thread/up_changeCurrentThreadIsOpen', { tid, isOpen }, { root: true })
    //     .then(() => dispatch('ApiConversation/threadList/up_changeBulkIsOpen', { tids: [tid], isOpen }, { root: true }));
    // },
    // up_changeCurrentThreadIsRead({ dispatch }, { tid, isRead }) {
    //   return dispatch('Channel/thread/up_changeCurrentThreadIsRead', { tid, isRead }, { root: true })
    //     .then(() => dispatch('ApiConversation/threadList/up_changeBulkIsRead', { tids: [tid], isRead }, { root: true }));
    // },
    // up_changeCurrentThreadIsMute({ dispatch }, { tid, isMute }) {
    //   return dispatch('Channel/thread/up_changeCurrentThreadIsMute', { tid, isMute }, { root: true })
    //     .then(() => dispatch('ApiConversation/threadList/up_changeBulkIsMute', { tids: [tid], isMute }, { root: true }));
    // },
    // up_changeCurrentThreadThreadGroup({ dispatch }, { tid, tgids }) {
    //   return dispatch('Channel/thread/up_deleteCurrentThreadThreadGroup', { tid, tgids }, { root: true })
    //     .then(() => dispatch('ApiConversation/threadList/up_removeThreadListTags', { tids: [tid], tgids }, { root: true }));
    // },
    // up_changeBulkIsOpen({ dispatch }, { tids, isOpen }) {
    //   return dispatch('ApiConversation/threadList/up_changeBulkIsOpen', { tids, isOpen }, { root: true });
    // },
    // up_changeBulkIsRead({ dispatch }, { tids, isRead }) {
    //   return dispatch('ApiConversation/threadList/up_changeBulkIsRead', { tids, isRead }, { root: true });
    // },
    // up_changeBulkIsMute({ dispatch }, { tids, isMute }) {
    //   return dispatch('ApiConversation/threadList/up_changeBulkIsMute', { tids, isMute }, { root: true });
    // },
    // up_updateThreadListActivityStatus({ dispatch }, { tids, status }) {
    //   return dispatch('ApiConversation/threadList/up_updateThreadListActivityStatus', { tids, status }, { root: true });
    // },
    up_currentThreadAndHeld({ dispatch }, tid) {
      console.error('🚀 ~ file: thread.js ~ line 441 ~ up_currentThreadAndHeld ~ tid', tid);
      return dispatch('ApiContact/contactPlatformOverview/up_currentThreadAndHeld', tid, { root: true });
    },
    resetThreadList({ dispatch }) {
      return dispatch('ApiConversation/threadList/reset', {}, { root: true });
    },
  },
};
