<template>
  <div>
    <div class="buttons is-right end">
      <b-button
        v-if="alreadyInThisCrmList.length < selectedCps.length"
        label="Next step"
        type="is-primary"
        @click="$emit('next')"
      />
      <b-button
        v-else
        label="All people already in this list"
        type="is-primary"
        disabled
      />
    </div>

    <article>
      <p class="multibar row-vcentered">
        <span class="start text">
          People already in THIS list, that won't be changed
        </span>
      </p>

      <br>

      <UbuSearchbar
        v-model="search"
        @clear="search = ''"
      />

      <br>

      <b-table
        :data="alreadyInThisCrmList"
        paginated
        pagination-size="is-small"
      >
        <b-table-column
          v-slot="{ row }"
          field="username"
          label="Username"
          sortable
        >
          <UbuPlatformCard
            :platform-name="row.contactPlatform.platformName.toLowerCase()"
            :text="row.username"
            font-size="20px"
          />
        </b-table-column>

        <template #empty>
          <p>You have selected no accounts to already in this list.</p>
        </template>
      </b-table>
    </article>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TheAddToCrmListMixin from '../mixins/TheAddToCrmList.mixin';

export default {
  name: 'TheAddToCrmListAlreadyInCrmList',
  mixins: [TheAddToCrmListMixin],
  data() {
    return {
      search: '',
    };
  },
  mounted() {
    if (!this.alreadyInThisCrmList.length) {
      this.goToTabByTabPath('root.addToCrmList.alreadyInCrmList.toKeep');
    }
  },
  methods: {
    ...mapActions({
      goToTabByTabPath: 'TheCRMBulk/goToTabByTabPath',
    }),
  },
};
</script>
