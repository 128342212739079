<template>
  <!-- eslint-disable max-len -->
  <section class="ubuWizardView ubuSidePanel ubuPanel-xl ubuChannelAdd">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        to="/setting/channel"
        class="end is-simple-icon is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <header>
      <p class="text is-3 has-text-secondary">Connect channel</p>
      <h1 class="title is-1">Add SMS channel</h1>
    </header>

    <br>

    <UbuNoData
      title="Activate SMS channel"
      subtitle="You need to contact sales in order to activate this feature and start sending SMSes to your community."
      action
      button-is="event"
      event-name="activeSms"
      type="is-primary"
      label="Contact sales"
      @activeSms="activeSms()"
    />
  </section>
</template>

<script>
export default {
  name: 'TheSettingChannelsAddSms',
  methods: {
    activeSms() {
      this.$intercom.showNewMessage('Hi ! I would like to add a SMS channel to my account please ! 😍');
    },
  },
};
</script>
