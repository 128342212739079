<template>
  <div
    class="ubuSettingView start"
  >
    <header class="start">
      <h2
        v-if="detailledUser"
        class="subtitle is-1 has-text-secondary m-0"
      >
        {{ detailledUser.firstName }} {{ detailledUser.lastName }}
      </h2>
      <div class="multibar">
        <h1 class="start title is-1">Users role</h1>
      </div>
    </header>

    <br>
    <DevDetails :cnt="{name: 'detailledUser', obj: detailledUser}" />
    <DevDetails :cnt="{name: 'userRoleList', obj: userRoleList}" />
    <DevDetails :cnt="{name: 'userRole', obj: userRole}" />
    <template v-if="detailledUser">
      <section>
        <p class="text has-text-secondary">Current role of user: </p>
        <p class="text">{{ detailledUser.userRoleUrid }}</p>
      </section>

      <br>

      <section v-if="isNotTheSameUser">
        <p class="text has-text-secondary">Select a new user role: </p>

        <br>

        <b-field
          v-if="userRoleList"
          label="role"
          horizontal
        >
          <b-select
            v-model="userRole"
            :placeholder="`Current role: ${detailledUser.userRoleUrid}`"
            required
          >
            <option
              v-for="role in userRoleFiltered"
              :key="role.urid"
              :value="role"
            >
              {{ role.label }}
            </option>
          </b-select>
        </b-field>

        <br>

        <div class="multibar">
          <div class="buttons end">
            <b-button
              label="Cancel"
              type="is-ghost"
              :disabled="userRole ? false : true"
              @click="userRole = null"
            />
            <b-button
              label="Save"
              type="is-primary"
              :disabled="userRole ? false : true"
              @click="updateUserRole()"
            />
          </div>
        </div>
      </section>

      <UbuNoData
        v-else
        title="You can't change your own role..."
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheUsersUserRole',
  data() {
    return {
      userRole: null,
    };
  },
  computed: {
    ...mapGetters({
      detailledUser: 'TheUsers/detailledUser',
      userRoleList: 'TheUsers/userRoleList',
      myAuthenticate: 'TheUsers/myAuthenticate',
    }),
    currentUid() {
      return this.$route.params.uid;
    },
    userRoleFiltered() {
      const roleUnnused = [this.detailledUser.userRoleUrid];

      return this.userRoleList.reduce((acc, role) => {
        if (!roleUnnused.includes(role.label)) acc.push(role);
        return acc;
      }, []);
    },
    isNotTheSameUser() {
      if (Number(this.detailledUser.uid) === Number(this.myAuthenticate.uid)) return false;
      return true;
    },
  },
  mounted() {
    return Promise.all([
      this.getDetailledUser({ payload: { uid: this.currentUid } }),
      this.reportUserRole({}),
    ]);
  },
  methods: {
    ...mapActions({
      getDetailledUser: 'TheUsers/getDetailledUser',
      reportUserRole: 'TheUsers/reportUserRole',
      setRole: 'TheUsers/setRole',
      up_userRole: 'TheUsers/up_userRole',
    }),
    updateUserRole() {
      this.$buefy.dialog.confirm({
        title: 'Change roles',
        message: 'Are you sure you want to <b>change roles</b>? This action will modify all the user\'s rights on the application.',
        confirmText: 'Change role',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.setRole({
          payload: {
            uid: this.currentUid,
            urid: this.userRole.urid,
          },
        })
          .then(this.up_userRole({
            userUid: this.currentUid,
            userRoleUrid: this.userRole.urid,
          })),
      });
    },
  },
};
</script>
