<template>
  <div
    :class="['ubuContactCard has-border-bottom', {
      'is-hoverable': hoverable,
      'is-focusable': focusable,
    }]"
  >
    <div class="profile">
      <div
        v-if="imageSrc || icon"
        class="avatar"
      >
        <b-image
          v-if="imageSrc"
          rounded
          :src="imageSrc"
          :placeholder="'https://via.placeholder.com/140x140.png'"
          :src-fallback="'https://via.placeholder.com/140x140.png'"
          alt="une image"
          lazy
          v-on="$listeners"
        />
        <b-icon
          pack="ubu"
          :icon="icon.name"
          :class="`has-text-color-${icon.color}`"
        />
      </div>
      <div class="info">
        <div class="text is-3">{{ payload.title }}</div>
        <template v-if="!openText">
          <div class="is-flex is-align-items-center">
            <p class="text is-3 has-text-secondary">{{ truncateMediaText(payload.content) }}</p>
            <b-button
              v-if="isTruncate"
              label="See more"
              type="is-ghost"
              @click="openText = true"
            />
          </div>
        </template>
        <template v-else>
          <p class="text is-3 has-text-secondary">{{ payload.content }}</p>
        </template>
      </div>
    </div>
    <div
      v-if="actions"
      class="actions"
    >
      <b-button
        v-if="actions.button"
        :type="actions.button.type"
        :label="actions.button.label"
        size="is-small"
        @click="$emit(actions.button.emit)"
      />

      <b-dropdown
        v-if="actions.dropdown"
        aria-role="list"
        position="is-bottom-left"
        :mobile-modal="false"
      >
        <template #trigger>
          <b-button
            clickable
            class="is-tertiary"
            icon-left="ubu-more-vertical"
            icon-pack="ubu"
          />
        </template>

        <b-dropdown-item
          v-for="(item, index) in actions.dropdown.items"
          :key="index"
          :class="item.class"
          v-bind="item.attr"
          @click="$emit(item.emit)"
        >
          {{ item.name }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuListCard',
  props: {
    payload: {
      type: Object,
      default: null,
    },
    actions: {
      type: Object,
      default: null,
    },
    imageSrc: {
      type: String,
      default: '',
    },
    icon: {
      type: Object,
      default: null,
    },
    hoverable: {
      type: Boolean,
      default: true,
    },
    focusable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      openText: false,
      isTruncate: false,
      length: 45,
      ending: '...',
      activeItem: null,
    };
  },
  methods: {
    truncateMediaText(content) {
      if (content.length > this.length) {
        this.isTruncate = true;
        return content.substring(0, this.length - this.ending.length) + this.ending;
      }
      return content;
    },
  },
};
</script>
