<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="mt-5">
      <p class="text start has-text-secondary">
        Get started
      </p>
      <h1 class="title is-1">
        Add your phone number
      </h1>

      <br>

      <form @submit.prevent="getStarted()">
        <b-field :label="`Phone number ${isAdminOnAccount ? '*' : '(optional)'}`">
          <vue-tel-input
            v-model="newPhoneNumber.phone"
            default-country="fr"
            :preferred-countries="preferredCountries"
            :input-options="inputOptions"
            :auto-format="false"
            invalid-msg="Invalid phone number"
            valid-characters-only
            @country-changed="changeCodeCountry($event)"
            @validate="validPhoneNumberMethod($event)"
          />
        </b-field>

        <br>

        <div
          v-if="isAdminOnAccount !== null"
          class="buttons is-right"
        >
          <b-button
            v-if="!isAdminOnAccount"
            type="is-ghost"
            label="Skip"
            @click="skipStep()"
          />
          <b-button
            type="is-primary"
            label="Get started"
            native-type="submit"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable func-names */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheAuthenticateGetStarted',
  data() {
    return {
      validPhoneNumber: false,
      newPhoneNumber: {
        phone: '',
        dialCode: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUserDetail: 'TheAuthenticateFirstConnexion/currentUserDetail',
      isAdminOnAccount: 'TheAuthenticateFirstConnexion/isAdminOnAccount',
    }),
    preferredCountries() {
      return ['fr', 'us', 'br', 'gb', 'jp'];
    },
    inputOptions() {
      return {
        placeholder: 'Enter a phone number',
        showDialCode: true,
        required: true,
        mode: 'national',
        styleClasses: [
          {
            'is-danger': this.validPhoneNumber === false,
            'is-success': this.validPhoneNumber === true,
          },
        ],
        readonly: this.isPreview,
      };
    },
  },
  watch: {
    'newPhoneNumber.phone': function (newVal) {
      const { dialCode } = this.newPhoneNumber;
      if (!newVal.includes(dialCode)) {
        this.newPhoneNumber.phone = `${dialCode} `;
      }
    },
  },
  methods: {
    ...mapActions({
      updateUserDetail: 'TheAuthenticateFirstConnexion/updateUserDetail',
      up_authenticateOneTime: 'TheAuthenticateFirstConnexion/up_authenticateOneTime',
    }),
    changeCodeCountry({ dialCode }) {
      this.newPhoneNumber.dialCode = `+${dialCode}`;
    },
    validPhoneNumberMethod({ valid, formatted }) {
      this.validPhoneNumber = valid;
      this.newPhoneNumber.phone = formatted.replace(/ /g, '');

      const { phone, dialCode } = this.newPhoneNumber;
      const dialCodeLength = dialCode.length + 1;

      if (!phone.includes(dialCode) || phone.length < dialCodeLength) this.validPhoneNumber = false;
    },
    skipStep() {
      window.location.reload();
    },
    getStarted() {
      const { phone, dialCode } = this.newPhoneNumber;

      // Longueur du dialcode + un espace pour correspondre a l'insertion du watcher
      const dialCodeLength = dialCode.length + 1;

      if (this.validPhoneNumber && phone.includes(dialCode) && phone.length > dialCodeLength) {
        const payload = { ...this.currentUserDetail, phone };

        this.updateUserDetail({ payload })
          .then(() => this.up_authenticateOneTime())
          .then(() => { window.location.reload(); });
      }
    },
  },
};
</script>
