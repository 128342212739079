export default {
  data() {
    return {
      lastPageFetched: 0,
      pagesToRemove: [1],
    };
  },
  computed: {
    itemByPage() {
      return this[this.listName].reduce((acc, item, index) => {
        const page = index > 0 ? Math.floor(index / this.perPage) : 0;
        if (!acc[page]) {
          acc[page] = [item[this.listIndex]];
          return acc;
        }
        acc[page].push(item[this.listIndex]);
        return acc;
      }, {});
    },
    idsToRemove() {
      const idsToRemove = {};

      if (this.itemList.length > 0 && this.pagesToRemove.length > 0) {
        this.pagesToRemove.forEach((page) => {
          idsToRemove[page] = this.itemByPage[page];
        });
      }
      return idsToRemove;
    },
    nbPages() {
      return Math.ceil(this.itemList.length / this.perPage);
    },
  },
  methods: {
    handlePages(pageToRecover, pageToRemove) {
      if (pageToRecover !== null) {
        if (Array.isArray(pageToRecover)) {
          this.pagesToRemove = this.pagesToRemove.filter((page) => !pageToRecover.includes(page));
        } else this.pagesToRemove = this.pagesToRemove.filter((page) => pageToRecover !== page);
      }
      if (!Array.isArray(pageToRemove) && pageToRemove >= 0) this.pagesToRemove.push(pageToRemove);
      else if (Array.isArray(pageToRemove)) this.pagesToRemove.push(...pageToRemove);
    },
    fetchNewPage() {
      this.httpQuery.pagin[0] = this.page + 1;
      return this[this.listFetcher]({
        payload: this.payload,
        httpQuery: this.httpQuery,
      })
        .then(() => {
          const myPage = this.page;
          const pageToRemove = myPage + 1 - this.pageRange;
          this.handlePages(myPage + 1, pageToRemove);
          this.page = myPage + 1;
          this.lastPageFetched = myPage + 1;
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    changePageToNext() {
      const currentPage = this.page; // p 5
      const pageTo = currentPage + 1; // p 6
      this.page = pageTo;
      const pageHasNotBeenRemoved = !Object.keys(this.idsToRemove).flat().includes(String(pageTo));
      let pageToRecover = pageTo; // p 6
      let pageToRemove = pageTo - this.pageRange; // p 6 - 3 = p 3
      if (pageHasNotBeenRemoved) {
        pageToRecover = currentPage + this.pageRange; // p 5 + 3 = p 8
        pageToRemove = currentPage; // p 5
        this.page = pageToRecover; // p 8
      }
      this.handlePages(pageToRecover, pageToRemove);
    },
    changePageToPrevious() {
      const currentPage = this.page; // p 6
      const pageTo = currentPage - 1; // p 5
      this.page = pageTo;
      const pageHasNotBeenRemoved = !Object.keys(this.idsToRemove).flat().includes(String(pageTo));
      let pageToRecover = pageTo; // p 5
      let pageToRemove = pageTo + this.pageRange; // p 5 + 3 = p 8
      if (pageHasNotBeenRemoved) {
        pageToRecover = currentPage - this.pageRange; // p 5 - 3 = p 2
        pageToRemove = currentPage; // p 5
        this.page = pageToRecover; // p 2
      }
      this.handlePages(pageToRecover, pageToRemove);
    },
    changePageToFirst() {
      const pagesToRecover = [0];
      const pagesToRemove = this.range(1, this.nbPages - 1);
      this.scrollDiabled = true;
      this.page = 0;
      this.handlePages(pagesToRecover, pagesToRemove);
      this.scrollDiabled = false;
    },
    range(min, max) {
      return Array.from({ length: max - min + 1 }, (_, i) => min + i);
    },
  },
};
