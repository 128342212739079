<template>
  <b-field>
    <b-input
      :ref="refName"
      v-model="localSearch"
      :placeholder="placeholder"
      :loading="loading"
      :disabled="disabled"
      :expanded="expanded"
      type="search"
      icon-pack="ubu"
      icon="ubu-search"
      icon-right="ubu-cross"
      icon-right-clickable
      @icon-right-click="$emit('clear')"
      @input="$emit('input', $event)"
      @keydown.enter.native="$emit('pressEnter')"
    />
  </b-field>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'UbuSearchbar',
  model: {
    prop: 'search',
  },
  props: {
    search: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Search...',
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      refName: uuidv4(),
    };
  },
  computed: {
    localSearch: {
      get() { return this.search || ''; },
      set(val) { this.$emit('input', val); },
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs[this.refName].focus();
    }
  },
};
</script>
