<!-- eslint-disable max-len -->
<template>
  <UbuDropdown :scrollable="true">
    <template #button>
      <div
        v-if="tribeMember.memberGroupMgid"
        class="ubuChip"
      >
        <b-icon
          pack="ubu"
          :icon="_memberGroupList[tribeMember.memberGroupMgid].icon"
        />
        <span class="text-truncate">{{ _memberGroupList[tribeMember.memberGroupMgid].name }}</span>
      </div>
      <div
        v-else
        class="ubuChip"
      >
        None
      </div>
    </template>

    <template #content>
      <b-dropdown-item
        aria-role="listitem"
        class="is-flex-direction-row is-align-items-center"
        @click="updateTribeMember(null)"
      >
        <span>None</span>
      </b-dropdown-item>

      <b-dropdown-item
        v-for="memberGroup in _memberGroupList"
        :key="memberGroup.mgid"
        aria-role="listitem"
        :class="['is-flex-direction-row is-align-items-center', { 'is-active': memberGroup.mgid === tribeMember.memberGroupMgid }]"
        @click="updateTribeMember(memberGroup.mgid)"
      >
        <b-icon
          pack="ubu"
          :icon="memberGroup.icon"
        />
        <span class="text-truncate">{{ memberGroup.name }}</span>
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
/* eslint-disable max-len */

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheMemberGroupPopover',
  model: {
    prop: 'tribeMember',
  },
  props: {
    tribeMember: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      _tribeMemberList: 'TheTribe/tribeMemberList',
      _memberGroupList: 'TheTribe/memberGroupList',
      _campaignList: 'TheTribe/campaignList',
    }),
  },
  methods: {
    ...mapActions({
      _updateTribeMember: 'TheTribe/updateTribeMember',
    }),

    updateTribeMember(mgid) {
      if (mgid === this.tribeMember.memberGroupMgid) return undefined;

      return this._updateTribeMember({
        payload: { tid: this.tribeMember.tribeTid, tribeMemberList: [{ ...this.tribeMember, memberGroupMgid: mgid }] },
      });
    },
  },
};
</script>
