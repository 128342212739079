<template>
  <section
    class="ubuMessageWrapper"
    :isMe="isMe"
  >
    <div class="ubuMessageCard">
      <div class="ubuMessageCard-content">
        <div class="subtext cloud60">Shared a profile</div>
        <p v-if="message.text">{{ message.text }}</p>
      </div>
      <UbuThreadMessageFooter
        :message="message"
        :is-me="isMe"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'UbuThreadMessageProfileShare',
  props: {
    isMe: {
      type: Boolean,
      default: false,
      require: true,
    },
    message: {
      type: Object,
      default: () => {},
      require: true,
    },
  },
};
</script>
