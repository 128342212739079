/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';
import { updateObjectRelationIfExists } from '../../$utils/dataMapper';

export default {
  namespaced: true,
  state: {
    leadList: {},
    leadHasSalesUserAssigned: {},
  },
  mutations: {
    SET_LEAD_LIST(state, newDatas) { state.leadList = newDatas; },
    UPDATE_LEAD_LIST(state, newDatas) {
      const myLeadList = Object.values(state.leadList).concat(newDatas).arrayMapper('lid');
      state.leadList = myLeadList;
    },
    DELETE_LEAD(state, newData) {
      state.leadList = Object.values(state.leadList).filter(({ lid }) => lid !== newData.lid).arrayMapper('lid');
    },
    UPDATE_LEAD_HAS_SALES_USER_ASSIGNED(state, newDatas) {
      state.leadHasSalesUserAssigned = updateObjectRelationIfExists(state.leadHasSalesUserAssigned, newDatas);
    },
  },
  getters: {
    getterLeadList: (state) => state.leadList,
    getterLeadHasSalesUserAssigned: (state) => state.leadHasSalesUserAssigned,
  },
  actions: {
    reportLeads({ commit, rootGetters }) {
      if (!isActionAllowed(rootGetters, 'LEAD.REPORT')) return undefined;

      return clark.api.lead
        .report({ payload: {}, httpQuery: { appFriendly: 'true' } })
        .then(({ leadHasSalesUserAssigned, ...leadList }) => {
          commit('SET_LEAD_LIST', leadList);

          const salesContactHasLead = Object.values(leadList).reduce((acc, lead) => {
            const { contactUsername, lid } = lead;
            if (!acc[contactUsername]) {
              acc[contactUsername] = [lid];
              return acc;
            }
            acc[contactUsername].push(lid);
            return acc;
          }, {});

          commit('Sales/salesContact/SET_SALES_CONTACT_HAS_LEAD', salesContactHasLead, { root: true });
          commit('Sales/lead/UPDATE_LEAD_HAS_SALES_USER_ASSIGNED', leadHasSalesUserAssigned, { root: true });
        });
    },

    create({ commit, rootGetters }, context) {
      if (!isActionAllowed(rootGetters, 'LEAD.CREATE')) return undefined;

      return clark.api.lead
        .post(context)
        .then((lead) => { commit('UPDATE_LEAD_LIST', lead); });
    },

    update({ commit, rootGetters }, context) {
      if (!isActionAllowed(rootGetters, 'LEAD.UPDATE')) return undefined;

      return clark.api.lead
        .put(context)
        .then((lead) => {
          commit('Sales/reportDetailledLead/UPDATE_LEAD_ON_LIST', lead, { root: true });
          commit('UPDATE_LEAD_LIST', lead);
        });
    },

    delete({ commit, rootGetters }, context) {
      if (!isActionAllowed(rootGetters, 'LEAD.DELETE')) return undefined;

      return clark.api.lead
        .delete(context)
        .then(() => { commit('Sales/reportDetailledLead/DELETE_LEAD_ON_LIST', context.payload, { root: true }); });
    },
  },
};
