<!-- eslint-disable max-len -->
<template>
  <section class="mainPanel p-4">
    <fieldset class="is-bordered">
      <legend>Account</legend>

      <b-field label="Label" horizontal>
        <b-input v-model="localTremendousAccount.label" :readonly="hasAccount" placeholder="Affiliation with Tremendous" />
        <p v-if="!hasAccount" class="control">
          <b-button
            label="Create account"
            type="is-primary"
            @click="createAccount()"
          />
        </p>
      </b-field>

      <b-field v-if="hasAccount" label="Api key" horizontal expanded>
        <b-input v-model="localTremendousAccount.apiKey" type="text" expanded />
        <p class="control">
          <b-button :loading="isChangeApiKeyLoading" label="Save" type="is-primary" @click="changeApiKey()" />
        </p>
      </b-field>
    </fieldset>

    <template v-if="hasAccount">
      <template v-if="_currentTremendousAccount.apiKey">
        <br>

        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Default campaign</p>
            <div class="card-header-icon">
              <b-button
                label="Synchronize with Tremendous"
                icon-pack="ubu"
                icon-left="ubu-refresh"
                type="is-secondary"
                :loading="isRefreshCampaignLoading"
                @click="refreshCampaign()"
              />
            </div>
          </header>
          <div class="card-content">
            <b-field v-if="campaignList.length" label="" horizontal>
              <div>
                <b-field v-for="tc in campaignList" :key="tc.tcid">
                  <b-radio v-model="localDefaultCampaignTcid" :native-value="tc.tcid" @input="changeDefaultCampaign(tc.tcid)">
                    {{ tc.name }}
                  </b-radio>
                </b-field>
              </div>
            </b-field>
          </div>
        </div>

        <br>

        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Default funding source</p>
            <div class="card-header-icon">
              <b-button
                label="Synchronize with Tremendous"
                icon-pack="ubu"
                icon-left="ubu-refresh"
                type="is-secondary"
                :loading="isRefreshFundingSourceLoading"
                @click="refreshFundingSource()"
              />
            </div>
          </header>
          <div class="card-content">
            <b-field v-if="fundingSourceList.length" label="" horizontal>
              <div>
                <b-field v-for="tfs in fundingSourceList" :key="tfs.tfsid">
                  <b-radio v-model="localDefaultFundingSourceTfsid" :native-value="tfs.tfsid" @input="changeDefaultFundingSource(tfs.tfsid)">
                    {{ tfs.label }}
                  </b-radio>
                </b-field>
              </div>
            </b-field>
          </div>
        </div>
      </template>
    </template>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheCrewTremendousSetting',
  data() {
    return {
      isChangeApiKeyLoading: false,
      isRefreshCampaignLoading: false,
      isRefreshFundingSourceLoading: false,
      localTremendousAccount: {
        label: '',
        domain: 'https://testflight.tremendous.com',
        apiKey: null,
        webhookPrivateKey: null,
      },
      localDefaultCampaignTcid: null,
      localDefaultFundingSourceTfsid: null,
    };
  },
  computed: {
    ...mapGetters({
      _currentTremendousAccount: 'TheCrew/currentTremendousAccount',

      _tremendousCampaignList: 'TheCrew/tremendousCampaignList',
      _defaultTremendousCampaignTcid: 'TheCrew/defaultTremendousCampaign',

      _tremendousFundingSourceList: 'TheCrew/tremendousFundingSourceList',
      _defaultTremendousFundingSourceTfsid: 'TheCrew/defaultTremendousFundingSourceTfsid',
    }),

    hasAccount() { return this._currentTremendousAccount && this._currentTremendousAccount.taid; },

    campaignList() { return Object.values(this._tremendousCampaignList); },

    fundingSourceList() { return Object.values(this._tremendousFundingSourceList); },
  },
  mounted() {
    return Promise.resolve()
      .then(() => this._reportTremendousAccount())

      .then(() => {
        if (!this.hasAccount) return;
        this.localTremendousAccount = { ...this._currentTremendousAccount };
      })
      .then(() => {
        if (!this.hasAccount) return undefined;

        return Promise.all([
          this.reportCampaign(),
          this.reportFundingSource(),
        ]);
      });
  },
  methods: {
    ...mapActions({
      _reportTremendousAccount: 'TheCrew/reportTremendousAccount',
      _createTremendousAccount: 'TheCrew/createTremendousAccount',
      _updateTremendousAccount: 'TheCrew/updateTremendousAccount',

      _reportTremendousCampaign: 'TheCrew/reportTremendousCampaign',
      _setDefaultTremendousCampaign: 'TheCrew/setDefaultTremendousCampaign',
      _refreshTremendousCampaign: 'TheCrew/refreshTremendousCampaign',

      _reportTremendousFundingSource: 'TheCrew/reportTremendousFundingSource',
      _refreshTremendousFundingSource: 'TheCrew/refreshTremendousFundingSource',
      _setDefaultTremendousFundingSource: 'TheCrew/setDefaultTremendousFundingSource',
    }),

    createAccount() {
      return this._createTremendousAccount({ payload: this.localTremendousAccount })
        .then(() => {
          this.$buefy.snackbar.open({ message: 'Account created!', ...snackbarSuccess });
          this.localTremendousAccount = { ...this._currentTremendousAccount };
        });
    },

    changeApiKey() {
      this.isChangeApiKeyLoading = true;
      const { isAltered, ...payload } = this.localTremendousAccount;
      return this._updateTremendousAccount({ payload })
        .then(() => {
          this.$buefy.snackbar.open({ message: 'Success!', ...snackbarSuccess });
          this.localTremendousAccount = { ...this._currentTremendousAccount };
        })
        .then(() => Promise.all([
          this.refreshCampaign(),
          this.refreshFundingSource(),
        ]))
        .finally(() => {
          this.isChangeApiKeyLoading = false;
        });
    },

    reportCampaign() {
      if (!this.hasAccount) return undefined;

      return this._reportTremendousCampaign({ payload: { tremendousAccountTaid: this._currentTremendousAccount.taid } })
        .then(() => {
          if (!this._defaultTremendousCampaignTcid) return;
          this.localDefaultCampaignTcid = this._defaultTremendousCampaignTcid;
        });
    },

    reportFundingSource() {
      if (!this.hasAccount) return undefined;

      return this._reportTremendousFundingSource({ payload: { tremendousAccountTaid: this._currentTremendousAccount.taid } })
        .then(() => {
          if (!this._defaultTremendousFundingSourceTfsid) return;
          this.localDefaultFundingSourceTfsid = this._defaultTremendousFundingSourceTfsid;
        });
    },

    refreshCampaign() {
      return Promise.resolve()
        .then(() => { this.isRefreshCampaignLoading = true; })
        .then(() => this._refreshTremendousCampaign({ payload: { tremendousAccountTaid: this._currentTremendousAccount.taid } }))
        .then(() => {
          // this.$buefy.snackbar.open({ message: 'Fetch done!', ...snackbarSuccess });
          this.isRefreshCampaignLoading = false;
        });
    },

    refreshFundingSource() {
      return Promise.resolve()
        .then(() => { this.isRefreshFundingSourceLoading = true; })
        .then(() => this._refreshTremendousFundingSource({ payload: { tremendousAccountTaid: this._currentTremendousAccount.taid } }))
        .then(() => {
          // this.$buefy.snackbar.open({ message: 'Fetch done!', ...snackbarSuccess });
          this.isRefreshFundingSourceLoading = false;
        });
    },

    changeDefaultCampaign(tcid) {
      if (!this.localDefaultCampaignTcid) return undefined;

      this.localDefaultCampaignTcid = tcid;

      const { tcid: tremendousCampaignTcid, tremendousAccountTaid } = this._tremendousCampaignList[this.localDefaultCampaignTcid];

      return this._setDefaultTremendousCampaign({ payload: { tremendousCampaignTcid, tremendousAccountTaid } });
      // .then(() => this.$buefy.snackbar.open({ message: 'Success!', ...snackbarSuccess }));
    },

    changeDefaultFundingSource(tfsid) {
      if (!this.localDefaultFundingSourceTfsid) return undefined;

      this.localDefaultFundingSourceTfsid = tfsid;

      const { tfsid: tremendousFundingSourceTfsid, tremendousAccountTaid } = this._tremendousFundingSourceList[this.localDefaultFundingSourceTfsid];

      return this._setDefaultTremendousFundingSource({ payload: { tremendousFundingSourceTfsid, tremendousAccountTaid } });
      // .then(() => this.$buefy.snackbar.open({ message: 'Success!', ...snackbarSuccess }));
    },
  },
};
</script>
