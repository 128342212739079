/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    tremendousFundingSourceList: {},
    defaultTremendousFundingSourceTfid: null,
  },
  mutations: {
    SET_TREMENDOUS_FUNDING_SOURCE_LIST(state, newData) { state.tremendousFundingSourceList = newData; },
    SET_DEFAULT_TREMENDOUS_FUNDING_SOURCE_TFSID(state, newData) { state.defaultTremendousFundingSourceTfid = newData; },
  },
  getters: {
    getterTremendousFundingSourceList: (state) => state.tremendousFundingSourceList,
    getterdefaultTremendousFundingSourceTfsid: (state) => state.defaultTremendousFundingSourceTfid,
  },
  actions: {
    report({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiTremendous.tremendousFundingSource.report({ payload, httpQuery })
        .then((tremendousFundingSourceList) => {
          if (!tremendousFundingSourceList.length) return;

          commit('SET_TREMENDOUS_FUNDING_SOURCE_LIST', tremendousFundingSourceList.arrayMapper('tfsid'));

          const currentDefault = tremendousFundingSourceList.find(({ isDefault }) => isDefault);

          if (currentDefault) commit('SET_DEFAULT_TREMENDOUS_FUNDING_SOURCE_TFSID', currentDefault.tfsid);
        });
    },

    setDefault({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiTremendous.tremendousFundingSource.setDefault({ payload, httpQuery })
        .then(() => {
          commit('SET_DEFAULT_TREMENDOUS_FUNDING_SOURCE_TFSID', payload.tremendousFundingSourceTfsid);
        });
    },

    refresh({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiTremendous.tremendousFundingSource.refresh({ payload, httpQuery })
        .then((tremendousFundingSourceList) => {
          if (!tremendousFundingSourceList.length) return;

          commit('SET_TREMENDOUS_FUNDING_SOURCE_LIST', tremendousFundingSourceList.arrayMapper('tfsid'));

          const currentDefault = tremendousFundingSourceList.find(({ isDefault }) => isDefault);

          if (currentDefault) commit('SET_DEFAULT_TREMENDOUS_FUNDING_SOURCE_TFSID', currentDefault.tfsid);
        });
    },
  },
};
