/* eslint-disable max-len */

export default {
  namespaced: true,
  state: {
    currentFocusedElement: 'THREAD_LIST',
  },
  mutations: {
    SET_FOCUSED_ELEMENT(state, newElement) { state.currentFocusedElement = newElement; },
  },
  getters: {
    getterFocusedElement: (state) => state.currentFocusedElement,
  },
  actions: {
    up_focusedElement({ commit }, { name }) {
      commit('SET_FOCUSED_ELEMENT', name);
    },
  },
};
