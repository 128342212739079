<template>
  <section class="ubuWizardView ubuSidePanel ubuPanel-xl ubuChannelAdd">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        to="/setting/channel"
        class="end is-simple-icon is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <header>
      <p class="text is-3 has-text-secondary">Manage channel</p>
      <h1 class="title is-1">Manage Meta Channels</h1>
    </header>

    <DevDetails :cnt="{name: 'facebookConnectList', obj: facebookConnectList}" />

    <article>
      <div class="buttons">
        <UbuActionCard
          class="mb-4"
          :label="facebookConnectList ? 'Reconnect Meta' : 'Connect Meta'"
          icon-pack="ubu"
          icon-left="ubu-shutdown"
          expanded
          @click="clickHandler('/setting/channel/connectMeta')"
        />

        <UbuActionCard
          v-if="facebookConnectList"
          class="mb-4"
          label="Manage Meta channels"
          icon-pack="ubu"
          icon-left="ubu-edit"
          expanded
          @click="clickHandler('/setting/channel/selectFacebook')"
        />
      </div>
    </article>

    <br>

    <div class="buttons is-right">
      <b-button
        type="is-ghost"
        label="Need help to connect your channels ?"
        @click="$intercom.showNewMessage('Hi! I need help to connect my Meta channels ! 🙌')"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSettingChannelsAddMeta',
  computed: {
    ...mapGetters({
      account: 'TheSettingWorkspaceSetting/account',
      currentChannelGroup: 'TheSettingWorkspaceSetting/currentChannelGroup',
      facebookConnectList: 'TheSettingWorkspaceSetting/facebookConnectList',
    }),
  },
  mounted() {
    this.reportFacebookConnect();
  },
  methods: {
    ...mapActions({
      getAccount: 'TheSettingWorkspaceSetting/getAccount',
      reportFacebookConnect: 'TheSettingWorkspaceSetting/reportFacebookConnect',
      _connectMetaChannel: 'TheSettingWorkspaceSetting/connectMetaChannel',
      _createChannel: 'TheSettingWorkspaceSetting/createChannel',
      _tryConnectMeta: 'TheSettingWorkspaceSetting/tryConnectMeta',
    }),
    clickHandler(path) {
      return this.$router.push({ path, query: this.$route.query });
    },
  },
};
</script>
