<template>
  <TheTabsPanel
    v-model="localActiveTab"
    @goToPreviousTab="goToPreviousTab()"
    @goToStart="goToTabByTabPath('root')"
    @close="closePanel()"
  >
    <template v-slot:content>
      <b-tab-item value="root">
        <div class="buttons">
          <UbuActionCard
            v-for="item in navigableItems"
            :key="item.label"
            class="mb-4"
            :label="item.label"
            icon-pack="ubu"
            :icon-left="item.icon"
            expanded
            :disabled="item.isDisabled"
            @click="UP_activeTab(tabItems[item.to])"
          />
        </div>
      </b-tab-item>

      <b-tab-item value="root.download">
        <TheDownloadMentions
          v-model="mentionExtrefIdList"
          @done="handleSuccessDownload($event)"
        />
      </b-tab-item>
    </template>
  </TheTabsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheHashtagsBulkPanel',
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      activeTab: 'TheHashtagsBulkPanel/activeTab',
      _checkedRows: 'TheHashtagsBulkPanel/checkedRows',
      tabItems: 'TheHashtagsBulkPanel/tabItems',
      mentionExtrefIdList: 'TheHashtagsBulkPanel/mentionExtrefIdList',
    }),

    localActiveTab: {
      get() { return this.activeTab; },
      set(newValue) { this.UP_activeTab(newValue); },
    },

    localChecked: {
      get() { return this._checkedRows; },
      set(newValue) {
        this.UP_checkedRows(newValue);
        this.$emit('input', newValue);
      },
    },

    navigableItems() {
      return {
        'root.download': {
          to: 'root.download',
          label: 'Download hashtags',
          icon: 'ubu-download',
        },
      };
    },
  },
  mounted() {
    return this.UP_checkedRows(this.checkedRows);
  },
  methods: {
    ...mapActions({
      UP_checkedRows: 'TheHashtagsBulkPanel/UP_checkedRows',
      UP_activeTab: 'TheHashtagsBulkPanel/UP_activeTab',
      UP_resetStore: 'TheHashtagsBulkPanel/UP_resetStore',
      goToTabByTabPath: 'TheHashtagsBulkPanel/goToTabByTabPath',
    }),

    handleSuccessDownload() {
      this.goToTabByTabPath('root');
    },

    closePanel() {
      this.UP_resetStore();
      this.$emit('close');
    },
  },
};
</script>
