<template>
  <b-field grouped>
    <b-numberinput
      v-model="localValues[0]"
      placeholder="Min"
      :controls="false"
      expanded
    />
    <b-numberinput
      v-model="localValues[1]"
      placeholder="Max"
      :controls="false"
      expanded
    />
  </b-field>
</template>

<script>
export default {
  name: 'UbuFilterNumberRange',
  model: {
    prop: 'values',
  },
  props: {
    values: {
      type: Array,
      required: true,
    },
  },
  computed: {
    localValues: {
      get() { return this.values; },
      set(val) { this.$emit('input', val); },
    },
  },
};
</script>
