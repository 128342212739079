export default {
  data() {
    return {
      columns: [
        {
          col: 'title',
          label: 'Name',
          cmp: 'flat',
          cellClass: 'static ellipsis',
          isSortable: true,
          secondCold: 'text',
        },
        {
          col: 'folderName',
          label: 'Folder',
          cmp: 'flat',
          cellClass: 'static ellipsis',
          isSortable: true,
        },
        {
          col: 'createdOn',
          label: 'createdOn',
          cmp: 'date',
          cellClass: 'static',
          isSortable: true,
        },
        {
          col: 'updatedOn',
          label: 'updatedOn',
          cmp: 'date',
          cellClass: 'static',
          isSortable: true,
        },
      ],
    };
  },
};
