import { clark } from '@dailyplanet/providers';
// import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  actions: {
    create(_, { payload, httpQuery }) {
      // if (!isActionAllowed(rootGetters, 'ACTIVITY_MENTION.REPORT')) return undefined;

      return clark.api.channelGroup.connectMetaChannel.post({ payload, httpQuery });
    },
  },
};
