import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    apiKeyList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiKey/getterApiKeyList': apiKeyList } = rootGetters;

      return apiKeyList;
    },
  },
  actions: {
    reportActiveApiKey({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiKey/report',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid } },
        textNotifier: 'report api key list',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report api key list.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },

    requestNewApiKey({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const { 'Authenticate/getterAuthenticate': authenticate } = rootGetters;
      console.log('🚀 ~ file: apiKey.js:73 ~ revokeApiKey ~ authenticate', authenticate);

      const action = {
        dispatch,
        target: 'ApiKey/create',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            userUid: authenticate.uid,
            ...payload,
          },
        },
        textNotifier: 'request new api key',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to request new api key.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },

    revokeApiKey({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const { 'Authenticate/getterAuthenticate': authenticate } = rootGetters;
      console.log('🚀 ~ file: apiKey.js:73 ~ revokeApiKey ~ authenticate', authenticate);

      const action = {
        dispatch,
        target: 'ApiKey/revoke',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'revoke api key',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to revoke api key.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
  },
};
