import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    tremendousAccountList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiTremendous/tremendousAccount/getterTremendousAccountList': tremendousAccountList } = rootGetters;

      return tremendousAccountList;
    },

    currentTremendousAccount: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiTremendous/tremendousAccount/getterCurrentTremendousAccount': currentTremendousAccount } = rootGetters;

      return currentTremendousAccount;
    },
  },
  actions: {
    reportTremendousAccount({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTremendous/tremendousAccount/report',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid } },
        textNotifier: 'report tremendousAccount list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report tremendous account list.', indefinite: true }));
    },

    createTremendousAccount({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTremendous/tremendousAccount/create',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'create tremendousAccount',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to create tremendous account.', indefinite: true }));
    },

    updateTremendousAccount({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTremendous/tremendousAccount/update',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'update tremendousAccount',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to update tremendous account.', indefinite: true }));
    },
  },
};
