import dailyMention from './dailyMention.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    dailyMention,
  },
};
