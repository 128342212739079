<template>
  <b-autocomplete
    ref="searchInput"
    v-model="model.value"
    :data="options"
    :placeholder="placeholder"
    clearable
    :required="required"
    :loading="loading"
    :open-on-focus="openOnFocus"
    :field="field"
    @input="$update('value', model.value);"
    @blur="$update('selected', model.value)"
    @select="option => {$update('selected', option); $emit('select', option)}"
  >
    <template
      v-if="header"
      #header
    >
      <b-button
        :tag="tag"
        :type="type"
        :label="label"
        :to="to"
      />
    </template>
  </b-autocomplete>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuAutocomplete',
  mixins: [VModelMixin],
  model: {
    prop: 'model',
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    placeholder: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    openOnFocus: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.searchInput.focus();
    }
  },
};
</script>
