<template>
  <section
    class="ubuMessageWrapper"
    :isMe="isMe"
  >
    <div
      class="ubuMessageCard info mb-2"
    >
      <div class="ubuMessageCard-content">
        <div
          v-if="platformName === 'INSTAGRAM'"
          class="subtext cloud60"
        >
          This message was deleted on Instagram.
        </div>
        <div
          v-else-if="platformName === 'FACEBOOK'"
          class="subtext cloud60"
        >
          This message was deleted on Facebook.
        </div>
        <div
          v-else
          class="subtext cloud60"
        >
          This message was deleted.
        </div>
      </div>
      <UbuThreadMessageFooter :message="message" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'UbuThreadMessageDeletedMessage',
  props: {
    isMe: {
      type: Boolean,
      default: false,
      require: true,
    },
    message: {
      type: Object,
      default: () => {},
      require: true,
    },
    platformName: {
      type: String,
      default: 'INSTAGRAM',
    },
  },
};
</script>
