// import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';
import ErrorBuilder from '@dailyplanet/utils/Errors';
import {
  dispatchAndForward,
  dispatchFailure,
  // snackBarFailure,
} from '@dailyplanet/data-stores/$utils/helpers';

const titleForComposeMessage = (cpListLength) => {
  if (cpListLength === 1) return 'Let\'s get creative';
  return `Sending Instagram message to ${cpListLength} people separately`;
};

const titleForAddTag = (cpListLength) => {
  if (cpListLength === 1) return 'Keep track of your request, tag this conversation!';
  return 'Keep track of your request, tag these conversations!';
};

export default {
  namespaced: true,
  getters: {
    ...getters.channelGroup,
    ...getters.threadAndParticipant,
    ...getters.threadGroup,

    tabItemsForTheMentionBulk: (_state, _getters, _, rootGetters) => ({
      'root.askUgc': {
        tab: 'root.askUgc',
        title: titleForComposeMessage(rootGetters['TheMentionBulk/contactPlatformList'].length),
        subtext: 'Request usage rights',
      },
      'root.askUgc.addTag': {
        tab: 'root.askUgc.addTag',
        title: titleForAddTag(rootGetters['TheMentionBulk/contactPlatformList'].length),
        subtext: 'Request usage rights',
      },
      'root.askUgc.done': {
        tab: 'root.askUgc.done',
        title: 'Summary',
        subtext: 'Request usage rights',
      },
    }),

    tabItemsForTheInfluencePeopleBulk: (_state, _getters, _, rootGetters) => ({
      'root.askUgc': {
        tab: 'root.askUgc',
        title: titleForComposeMessage(rootGetters['TheInfluencePeopleBulk/contactPlatformList'].length),
        subtext: 'Request usage rights',
      },
      'root.askUgc.addTag': {
        tab: 'root.askUgc.addTag',
        title: titleForAddTag(rootGetters['TheInfluencePeopleBulk/contactPlatformList'].length),
        subtext: 'Request usage rights',
      },
      'root.askUgc.done': {
        tab: 'root.askUgc.done',
        title: 'Summary',
        subtext: 'Request usage rights',
      },
    }),
  },
  actions: {
    ...actions.channelGroup,
    ...actions.threadAndParticipant,
    ...actions.threadGroup,

    createTasks({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'AskUgcPermission/create',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Ask UGC Permission create',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'requestUGCRights' }, { root: true }))
        .catch((error) => {
          console.log('🚀 ~ file: TheAskUgcRight.store.js ~ line 52 ~ createTasks ~ error', error);
          // Snackbar.open({
          //   message: 'Unable to get contact.',
          //   ...snackBarFailure,
          // });

          dispatchFailure(action);
          throw new ErrorBuilder(error);
        });
    },
  },
};
