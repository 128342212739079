/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import activityMention from './activityMention';
import favorite from './favorite';
import downloadMention from './downloadMention';
import autoReport from './autoReport';
import campaignMention from './campaignMention';

export default {
  activityMention,
  favorite,
  downloadMention,
  autoReport,
  campaignMention,
};
