<template>
  <TheTabsPanel
    v-model="localActiveTab"
    @goToPreviousTab="goToPreviousTab()"
    @goToStart="goToTabByTabPath('root')"
    @close="closePanel()"
  >
    <template #content>
      <b-tab-item value="root">
        <article>
          <div class="buttons">
            <UbuActionCard
              label="Download mentions"
              icon-pack="ubu"
              icon-left="ubu-download"
              expanded
              @click="downloadTaskSelected(localChecked)"
            />

            <UbuActionCard
              v-for="item in navigableItems"
              :key="item.label"
              class="mb-4"
              :label="item.label"
              icon-pack="ubu"
              :icon-left="item.icon"
              expanded
              :disabled="item.isDisabled"
              @click="handleNavigation(item)"
            />

            <UbuActionCard
              label="Add these mentions to favorites"
              icon-pack="ubu"
              icon-left="ubu-important"
              expanded
              @click="bulkAddFav()"
            />
          </div>
        </article>
      </b-tab-item>

      <b-tab-item value="root.downloadMentions">
        <TheDownloadMentions
          v-model="localChecked"
          @done="handleSuccessDownload($event)"
        />
      </b-tab-item>

      <TheSendInstantMessage
        v-model="localChecked"
        :active-tab="localActiveTab"
        :tab-items="tabItems"
        @update:activeTab="UP_changeToNewTab($event)"
      />

      <TheNewAddToCampaign
        v-model="localChecked"
        :active-tab="localActiveTab"
        :tab-items="tabItems"
        @update:activeTab="UP_changeToNewTab($event)"
      />

      <TheAddToTribe
        v-model="localChecked"
        :active-tab.sync="localActiveTab"
        :tab-items="tabItems"
      />
    </template>
  </TheTabsPanel>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';
import DownloadTaskMixin from '@dailyplanet/mixins/DownloadTaskMixin';

export default {
  name: 'TheActivityMentionBulkPanel',
  mixins: [DownloadTaskMixin],
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      activeTab: 'TheActivityMentionBulkPanel/activeTab',
      _checkedRows: 'TheActivityMentionBulkPanel/checkedRows',
      tabItems: 'TheActivityMentionBulkPanel/tabItems',
    }),

    localActiveTab: {
      get() { return this.activeTab; },
      set(newValue) { this.UP_activeTab(newValue); },
    },

    localChecked: {
      get() { return this._checkedRows; },
      set(newValue) {
        this.UP_checkedRows(newValue);
        this.$emit('input', newValue);
      },
    },

    navigableItems() {
      return {
        'root.sendMessage': {
          to: 'root.sendMessage',
          icon: 'ubu-compose',
          label: 'Send instant message',
        },
        'root.addToCampaign': {
          to: 'root.addToCampaign',
          icon: 'ubu-influence',
          label: 'Add to campaign',
        },
        'root.addToTribe': {
          to: 'root.addToTribe',
          icon: 'ubu-tribe',
          label: 'Add to tribe',
        },
      };
    },
  },
  watch: {
    checkedRows() {
      this.UP_checkedRows(this.checkedRows);
    },
  },
  mounted() {
    return this.UP_checkedRows(this.checkedRows);
  },
  methods: {
    ...mapActions({
      UP_checkedRows: 'TheActivityMentionBulkPanel/UP_checkedRows',
      UP_activeTab: 'TheActivityMentionBulkPanel/UP_activeTab',
      UP_resetStore: 'TheActivityMentionBulkPanel/UP_resetStore',
      goToPreviousTab: 'TheActivityMentionBulkPanel/goToPreviousTab',
      goToTabByTabPath: 'TheActivityMentionBulkPanel/goToTabByTabPath',
      UP_changeToNewTab: 'TheActivityMentionBulkPanel/UP_changeToNewTab',
      _favMentions: 'TheMention/favMentions',
    }),

    closePanel() {
      this.UP_resetStore();
      this.$emit('close');
    },

    handleNavigation(item) {
      this.UP_activeTab(this.tabItems[item.to]);
    },

    bulkAddFav() {
      const publicationsToFav = this.localChecked.filter(({ favorite }) => !favorite.publicationPid);

      return this._favMentions({
        payload: { pids: publicationsToFav.map(({ pid }) => pid) },
        mutations: [{
          target: 'ApiPublication/activityMention/UP_favMentionList',
          payload: {
            pids: publicationsToFav.map(({ pid }) => pid),
            shouldFav: true,
          },
        }],
      })
        .then(() => this.notifySuccessFav(publicationsToFav.length));
    },

    notifySuccessFav(count) {
      this.goToTabByTabPath('root');
      const message = count === 0 ? 'All mentions were added to favorites' : `${count} mentions were added to favorites`;

      return this.$buefy.snackbar.open({ message, ...snackbarSuccess });
    },
  },
};
</script>
