<template>
  <div
    :class="['UbuInfluenceMentionsNoContent', {
      'no-border': !border
    }]"
  >
    <div>
      <p
        v-if="title"
        class="subtitle"
      >
        {{ title }}
      </p>
      <p
        v-if="subtitle"
        class="text is-3 has-text-secondary"
      >
        {{ subtitle }}
      </p>
      <template
        v-if="action"
      >
        <br>
        <b-button
          v-if="buttonIs === 'link'"
          tag="router-link"
          :to="to"
          :type="type"
          :label="label"
        />
        <b-button
          v-if="buttonIs === 'event'"
          :type="type"
          :label="label"
          @click="$emit(eventName)"
        />
      </template>
      <template v-if="info">
        <br>
        <b-button
          tag="router-link"
          type="is-ghost"
          :label="labelInfo"
          :to="toInfo"
          icon-pack="ubu"
          icon-right="ubu-link"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UbuNoData',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    action: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: '',
    },
    toInfo: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelInfo: {
      type: String,
      default: '',
    },
    buttonIs: {
      type: String,
      default: '',
    },
    eventName: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
