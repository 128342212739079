import { actions } from '@dailyplanet/data-stores/commonViewStores';

/* eslint-disable max-len */
export default {
  namespaced: true,
  getters: {},
  actions: {
    ...actions.account,
    ...actions.user,
  },
};
