<template>
  <TheSimplePanel>
    <template #header>
      <h1 class="title is-1">Change password</h1>
    </template>

    <template #content>
      <form>
        <UbuEditPasswordForm
          v-model="newPasswordEdit"
        />

        <br>

        <div class="multibar">
          <b-button
            label="Save"
            type="is-primary"
            class="end"
            @click="editPassword()"
          />
        </div>
      </form>
    </template>
  </TheSimplePanel>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheUsersUserDetailsPanelEditPassword',
  props: {
    detailledUser: { type: Object, default: () => {} },
  },
  data() {
    return {
      newPasswordEdit: {
        newPassword: null,
        confirmPassword: null,
      },
    };
  },
  computed: {
    currentUid() {
      return this.$route.params.uid;
    },
    currentAuthenticateAid() {
      return this.detailledUser.authenticates.find(({ revokedOn }) => revokedOn === null);
    },
  },
  methods: {
    ...mapActions({
      updateChangePasswordFromKara: 'TheUsers/updateChangePasswordFromKara',
    }),
    editPassword() {
      const { newPassword, confirmPassword } = this.newPasswordEdit;
      const { aid, passName } = this.currentAuthenticateAid;

      if (newPassword !== confirmPassword) return;

      const payload = {
        aid,
        passName,
        userUid: this.currentUid,
        passPhrase: newPassword,
        oneTime: true,
      };

      this.updateChangePasswordFromKara({ payload })
        .then(() => { this.$router.push(`/users/user/${this.currentUid}/details`); });
    },
  },
};
</script>
