/* eslint-disable max-len */
import ubuLead from './ubuLead.store';
import salesUser from './salesUser.store';
import salesUserAssign from './salesUserAssign.store';
import salesContact from './salesContact.store';
import lead from './lead.store';
import reportDetailledLead from './reportDetailledLead.store';
import salesContactSuggestion from './salesContactSuggestion.store';
import scrapingZone from './scrapingZone.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
  },
  modules: {
    ubuLead,
    salesUser,
    salesUserAssign,
    salesContact,
    lead,
    reportDetailledLead,
    salesContactSuggestion,
    scrapingZone,
  },
};
