<template>
  <section>
    <p
      v-if="shouldIDisplaySeparator(index)"
      class="dateSeparator subtext has-text-secondary mb-4 mt-4"
    >
      {{ getDay(localItem.itemDate) }}
    </p>
    <div
      v-if="localItem.component || localItem.loading"
      class="ubuMessageRow multibar end"
    >
      <!-- <UbuThreadMessageSkeleton
        v-if="localItem.loading"
        :class="[{'start': localItem.isMe, 'end': !localItem.isMe}]"
        :message="item"
      /> -->

      <div
        :class="['is-flex', {
          'end': localItem.isMe && localItem.isMessage,
          'start': !localItem.isMe && localItem.isMessage
        }]"
      >
        <component
          :is="localItem.component"
          :is-me="localItem.isMe"
          :message="localItem"
          :user-list="userList"
          :campaign-list="campaignList"
          :window-width="windowWidth"
          @openModalNote="openModalNote()"
        />

        <UbuLikeButton
          v-if="threadDetails
            && threadDetails.thread.platformName === 'INSTAGRAM'
            && localItem.isMessage && !localItem.isMe"
          :message="item"
          @likeMessage="likeMessage($event)"
        />

        <b-dropdown
          v-if="localItem.type === 'TEXT'"
          :position="localItem.isMe ? 'is-bottom-left' : 'is-bottom-right'"
          class="dropdown-moreActions"
          scrollable
          max-height="300px"
          append-to-body
        >
          <template #trigger>
            <b-button
              type="is-tertiary"
              icon-pack="ubu"
              icon-right="ubu-more-vertical"
            />
          </template>

          <!-- <b-dropdown-item
                  v-if="localItem.isMe"
                  paddingless
                >
                  <b-button
                    label="Delete message"
                    type="is-ghost is-borderless"  USE IT WHEN DELETE MSG WILL BE IMPLEMENTED
                    icon-pack="ubu"
                    icon-left="ubu-trash"
                    disabled
                  />
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="localItem.isMe"
                  separator
                /> -->

          <b-dropdown-item
            custom
            class="multibar row-vcentered"
            paddingless
          >
            <b-icon
              class="start"
              pack="ubu"
              icon="ubu-translate"
            />
            <b class="start ml-1">Translate</b>
          </b-dropdown-item>

          <b-dropdown-item separator />

          <b-dropdown-item paddingless>
            <b-button
              label="Reset to original language"
              type="is-ghost is-borderless"
              @click="$emit('revertTranslate', item)"
            />
          </b-dropdown-item>

          <b-dropdown-item
            v-for="(language, i) in optionLanguages"
            :key="i"
            @click="$emit('translate', { ...item, targetLang: language.value })"
          >
            <div class="multibar row-vcentered">
              <b-image
                class="is-18x18 start"
                :src="language.flag"
              />
              <span class="ml-1 start">{{ language.label }}</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <UbuModalEditNote
      v-if="isModalNoteOpen"
      :key="localItem.originalId"
      v-model="localItem.text"
      :is-modal-note-open="isModalNoteOpen"
      @save="saveNote()"
      @delete="deleteNote()"
      @close="isModalNoteOpen = false"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */

import { mapActions, mapGetters } from 'vuex';

const ukFlag = require('@dailyplanet/assets/svg/UK.svg');
const frenchFlag = require('@dailyplanet/assets/svg/France.svg');
const germanyFlag = require('@dailyplanet/assets/svg/Germany.svg');
const italyFlag = require('@dailyplanet/assets/svg/Italy.svg');
const japanFlag = require('@dailyplanet/assets/svg/Japan.svg');
const netherlandFlag = require('@dailyplanet/assets/svg/Netherlands.svg');
const spainFlag = require('@dailyplanet/assets/svg/Spain.svg');
const portugueseFlag = require('@dailyplanet/assets/svg/Portuguese.svg');

export default {
  name: 'TheMessageRow',
  props: {
    item: {
      type: Object,
      required: true,
    },
    itemList: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    hasMoreToLoad: {
      type: Boolean,
      default: false,
    },
    threadDetails: {
      type: Object,
      required: true,
    },
    windowWidth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      optionLanguages: [
        { label: 'French', value: 'fr', flag: frenchFlag },
        { label: 'English', value: 'en-US', flag: ukFlag },
        { label: 'Germany', value: 'de', flag: germanyFlag },
        { label: 'Spanish', value: 'es', flag: spainFlag },
        { label: 'Italian', value: 'it', flag: italyFlag },
        { label: 'Japanese', value: 'ja', flag: japanFlag },
        { label: 'Portuguese', value: 'pt-br', flag: portugueseFlag },
        { label: 'Dutch', value: 'nl', flag: netherlandFlag },
      ],
      isModalNoteOpen: false,
      showConfirmDelete: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoadingMoreMsgsList: 'TheInbox/isLoadingMoreMessagesList',
      privateNoteList: 'TheInbox/privateNoteList',
      currentUser: 'TheAuthenticate/myAuthenticate',
      userList: 'User/getterUserList',
      campaignList: 'TheInbox/campaignList',
      threadGroupList: 'ThreadGroup/getterThreadGroupList',
      contactGroupList: 'ContactGroup/getterContactGroupList',
      crmListList: 'ChannelGroup/crmList/getterCrmListList',
      contactPlatformOverview: 'TheContactPlatformPanel/contactPlatformOverview',
    }),

    localItem: {
      get() {
        const item = {
          ...this.item,
          isMessage: !['PRIVATE_NOTE', 'LOGBOOK'].includes(this.item.type),
          component: this.messageCmpt(this.item),
        };

        if (item.type === 'LOGBOOK') {
          item.user = item.userUid ? this.userList[item.userUid] : null;
          item.threadGroup = item.threadGroupTgid ? this.threadGroupList[item.threadGroupTgid] : null;
          item.contactGroup = item.contactGroupCgid ? this.contactGroupList[item.contactGroupCgid] : null;
          item.crmList = item.crmListClid ? this.crmListList[item.crmListClid] : null;
          item.contactPlatform = this.contactPlatformOverview.contactPlatform;
        }

        if (item.type === 'PRIVATE_NOTE') {
          item.user = item.userUid ? this.userList[item.userUid] : null;
        }

        return item;
      },
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      loadMoreMessages: 'TheInbox/loadMoreMessages',
      setIsLoadingMoreMessagesList: 'TheInbox/up_isLoadingMoreMessagesList',
      likeMessage: 'TheInbox/likeMessage',
      updatePrivateNote: 'TheInbox/updatePrivateNote',
      deletePrivateNote: 'TheInbox/deletePrivateNote',
    }),
    messageCmpt({ type }) {
      const words = type.split('_');

      const lib = words.reduce((acc, word) => {
        const firstLetter = word.slice(0, 1);
        const messageType = word.slice(1, word.length).toLowerCase();
        // eslint-disable-next-line no-param-reassign
        acc += `${firstLetter}${messageType}`;

        return acc;
      }, '');

      return `UbuThreadMessage${lib}`;
    },
    getDay(day) {
      return this.$moment(day).calendar(null, {
        sameDay: '[Today] ☀️',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'YYYY-MM-DD',
      });
    },
    shouldIDisplaySeparator(index) {
      return index === this.itemList.length - 1 || this.$moment(this.itemList[index + 1].itemDate).format('YY-MM-DD') !== this.$moment(this.itemList[index].itemDate).format('YY-MM-DD');
    },

    openModalNote() {
      this.isModalNoteOpen = !this.isModalNoteOpen;
    },

    saveNote() {
      // need to restructure a REST format payload to update
      const preparedPayload = {
        pnid: this.localItem.originalId,
        channelGroupCgid: this.localItem.channelGroupCgid,
        contactCid: this.localItem.contactCid,
        contactPlatformCpid: this.localItem.contactPlatformCpid,
        userUid: this.currentUser.uid,
        text: this.localItem.text,
        createdOn: this.localItem.itemDate,
        updatedOn: this.$moment().format(),
        deletedOn: null,
      };

      this.updatePrivateNote({
        payload: preparedPayload,
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_updatePrivateNote',
          payload: {
            note: { ...preparedPayload, updatedOn: this.$moment().format() },
            cpids: [preparedPayload.contactPlatformCpid],
          },
        }],
      })
        .then(() => { this.isModalNoteOpen = false; });
    },

    deleteNote() {
      this.deletePrivateNote({
        payload: { pnid: this.localItem.originalId },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_removePrivateNote',
          payload: {
            note: this.localItem,
            cpids: [this.localItem.contactPlatformCpid],
          },
        }],
      })
        .then(() => { this.isModalNoteOpen = false; });
    },
  },
};
</script>
