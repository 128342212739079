import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    create(_, { payload, httpQuery = {} }) {
      return clark.api.apiAnalytics.shortLink.post({ payload, httpQuery })
        .then((shortLink) => shortLink);
    },

    patch(_, { payload, httpQuery = {} }) {
      return clark.api.apiAnalytics.shortLink.patch({ payload, httpQuery })
        .then((shortLink) => shortLink);
    },
  },
};
