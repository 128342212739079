/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const segmentFilter = {
  get: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/segmentFilter/${payload.sfid}`, method: 'GET', payload, httpQuery, target }),
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/segmentFilter`, method: 'REPORT', payload, httpQuery, target }),
  create: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/segmentFilter`, method: 'POST', payload, httpQuery, target }),
  update: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/segmentFilter/${payload.sfid}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/segmentFilter/${payload.sfid}`, method: 'DELETE', payload, httpQuery, target }),
};

export default segmentFilter;
