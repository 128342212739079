/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    favikonProfile: null,
  },
  mutations: {
    SET_FAVIKON_PROFILE(state, newData) { state.favikonProfile = newData; },
    RESET_STORE(state) {
      state.favikonProfile = null;
    },
  },
  getters: {
    getterFavikonProfile: (state) => state.favikonProfile,
  },
  actions: {
    get({ commit, rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'FAVIKON_PROFILE.GET')) return undefined;

      return clark.api.favikonProfile.get({ payload, httpQuery })
        .then((favikonProfile) => {
          commit('SET_FAVIKON_PROFILE', favikonProfile);

          return favikonProfile;
        });
    },
  },
};
