import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheMessageList from './smarts/message/TheMessageList';
import TheMessageRow from './smarts/message/TheMessageRow';

// import TheInboxSidebar from './smarts/TheInboxSidebar';
import TheThreadListFilters from './smarts/thread/TheThreadListFilters';
import TheThreadPanel from './smarts/thread/TheThreadPanel';
import TheThreadList from './smarts/thread/TheThreadList';
import TheThreadListCard from './smarts/thread/TheThreadListCard';

import TheThreadBulkTag from './smarts/threadBulk/TheThreadBulkTag';
import TheThreadBulkLabel from './smarts/threadBulk/TheThreadBulkLabel';
import TheThreadBulkAppoint from './smarts/threadBulk/TheThreadBulkAppoint';
import TheThreadBulkPanel from './smarts/threadBulk/TheThreadBulkPanel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheMessageList);
    registerComponent(Vue, TheMessageRow);

    registerComponent(Vue, TheThreadListFilters);
    registerComponent(Vue, TheThreadPanel);
    registerComponent(Vue, TheThreadList);
    registerComponent(Vue, TheThreadListCard);

    registerComponent(Vue, TheThreadBulkPanel);
    registerComponent(Vue, TheThreadBulkTag);
    registerComponent(Vue, TheThreadBulkLabel);
    registerComponent(Vue, TheThreadBulkAppoint);
  },
};

use(Plugin);

export default Plugin;

export {
  TheMessageList,
  TheMessageRow,

  TheThreadListFilters,
  TheThreadPanel,
  TheThreadList,
  TheThreadListCard,

  TheThreadBulkPanel,
  TheThreadBulkTag,
  TheThreadBulkLabel,
  TheThreadBulkAppoint,
};
