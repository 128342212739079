<template>
  <section
    class="ubuMessageWrapper"
    :isMe="isMe"
  >
    <UbuThreadMessageContentWrapper
      v-if="isNotExpired"
      :message="message"
      subtext="Mentioned you in their story"
      :is-me="isMe"
      :platform-name="platformName"
    />
    <div
      v-else
      class="ubuMessageCard"
    >
      <div class="ubuMessageCard-content">
        <div class="subtext cloud60">{{ mentionMsg }} (expired)</div>
        <p v-if="message.text">{{ message.text }}</p>
      </div>
      <UbuThreadMessageFooter :message="message" />
    </div>
  </section>
</template>

<script>
import MessageMixin from '@dailyplanet/mixins/MessageMixin';

export default {
  name: 'UbuThreadMessageStoryMention',
  mixins: [MessageMixin],
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: () => {},
    },
    platformName: {
      type: String,
      default: 'INSTAGRAM',
    },
  },
  computed: {
    mentionMsg() {
      if (this.isMe) {
        return 'Mentioned them in your story';
      }
      return 'Mentioned you in their story';
    },
    isNotExpired() {
      return this.$moment().utc().diff(this.$moment(this.message.itemDate).utc(), 'hours') < 24;
    },
  },
};
</script>
