<template>
  <div>
    <b-field label="Title">
      <b-input
        ref="input"
        v-model="localTemplate.title"
        placeholder="My template title..."
        @input="$update('title', $event)"
      />
    </b-field>
    <b-field label="Text">
      <UbuSimpleTextarea
        v-model="localTemplate.text"
        :max-length="1000"
        placeholder="Type some text..."
        emoji-picker-position="is-top-left"
        @input="$update('text', $event)"
      />
    </b-field>
  </div>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuEditTemplateForm',
  mixins: [VModelMixin],
  model: {
    prop: 'template',
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localTemplate: {
      get() { return { ...this.template }; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
  mounted() {
    this.$refs.input.focus();
  },
};
</script>
