export default {
  data() {
    return {
      columns: [
        {
          col: 'createdOn', label: 'date', visible: true, cmp: 'date', isSortable: true,
        },
        {
          col: 'stateRename', label: 'state', visible: true, cmp: 'flat', isSortable: false,
        },
        {
          col: 'featureRename', label: 'feature', visible: true, cmp: 'feature', isSortable: false,
        },
      ],
    };
  },
};
