/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    protoList: {},
  },
  mutations: {
    SET_PROTO_LIST(state, newData) { state.protoList = newData; },
  },
  getters: {
    getterProtoList: (state) => state.protoList,
  },
  actions: {
    report({ commit, rootGetters }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PROTO_LIST.REPORT')) return undefined;

      return clark.api.apiInfluence.protoList.report({ payload, httpQuery })
        .then((protoLists) => {
          commit('SET_PROTO_LIST', protoLists.arrayMapper('plid'));
        });
    },
    allow({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'PROTO_LIST.ALLOW')) return undefined;

      return clark.api.apiInfluence.protoList.allow({ payload, httpQuery })
        .then((protoList) => {
          commit('SET_PROTO_LIST', { ...getters.getterProtoList, [protoList.plid]: protoList });
        });
    },
    reject({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'PROTO_LIST.ALLOW')) return undefined;

      return clark.api.apiInfluence.protoList.reject({ payload, httpQuery })
        .then(() => {
          commit('SET_PROTO_LIST', Object.values(getters.getterProtoList).filter(({ plid }) => plid !== payload.plid).arrayMapper('plid'));
        });
    },
  },
};
