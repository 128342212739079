/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    userRoleList: (_state, _getters, _rootState, rootGetters) => {
      const { 'UserRole/getterUserRoleList': userRoleList } = rootGetters;

      return userRoleList;
    },
  },
  actions: {
    reportUserRole({ dispatch }) {
      const action = {
        dispatch,
        target: 'UserRole/report',
        context: { },
        textNotifier: 'Successfully report user role.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report user role.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
  },
};
