/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  actions: {
    updateEmailFromSelina({ commit, rootGetters }, { payload }) {
      return clark.api.changeEmail.put({ payload, httpQuery: {} })
        .then((authenticate) => {
          const { 'Authenticate/getterAuthenticate': myAuthenticate } = rootGetters;
          const { 'User/userDetail/getterCurrentUserDetail': currentUserDetail } = rootGetters;

          commit('User/userDetail/SET_USER_DETAIL', { ...currentUserDetail, email: authenticate.passName }, { root: true });
          commit('Authenticate/SET_AUTHENTICATE', { ...myAuthenticate, passName: authenticate.passName }, { root: true });
        });
    },

    updateEmailFromKara(_, { payload }) {
      return clark.api.changeEmail.put({ payload, httpQuery: {} });
    },
  },
};
