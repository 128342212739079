import memberGroup from './memberGroup.store';
import mention from './mention.store';
import threadCounters from './threadCounters.store';
import tribe from './tribe.store';
import tribeMember from './tribeMember.store';
import tribeAnalytics from './tribeAnalytics.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    memberGroup,
    mention,
    threadCounters,
    tribe,
    tribeMember,
    tribeAnalytics,
  },
};
