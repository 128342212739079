<template>
  <div class="menu-item-list">
    <UbuMenuItem
      v-for="(item) in visibleItems"
      :key="item.path && item.path.path ? item.path.path : item.path"
      :item="item"
    />
    <b-collapse
      v-if="groupedActiveMenuItems.length > separator"
      :open="false"
      position="is-bottom"
      animation="slide"
      class="menu-item-collapse"
    >
      <template #trigger="props">
        <b-button
          tag="button"
          expanded
          class="is-navigation is-collapse-btn"
        >
          <div class="multibar row-vcentered">
            <span class="start icon-left">
              <b-icon
                pack="mdi"
                :icon="props.open ? 'arrow-up' : 'arrow-down'"
                size="is-small"
              />
            </span>
            <span class="start itemMenuText has-text-tertiary">
              {{ props.open ? 'Show less' : 'Show more' }}
            </span>
          </div>
        </b-button>
      </template>
      <UbuMenuItem
        v-for="(item) in collapseItems"
        :key="item.path && item.path.path ? item.path.path : item.path"
        :item="item"
      />
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'UbuMenuItemList',
  props: {
    groupedActiveMenuItems: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      separator: 3,
    };
  },
  computed: {
    sortedItems() {
      if (!this.groupedActiveMenuItems.length) return [];

      const array = this.groupedActiveMenuItems;
      return array
        .sort((a, b) => Number(b.counter) - Number(a.counter))
        .sort((a, b) => Number(b.hasBadgeActive) - Number(a.hasBadgeActive));
    },

    visibleItems() {
      return this.sortedItems.slice(0, this.separator);
    },

    collapseItems() {
      return this.sortedItems.slice(this.separator);
    },
  },
};
</script>
