<template>
  <section class="ubuContactPanel ubuPanel-xl scrollable-y">
    <header>
      <div class="multibar">
        <div class="buttons end">
          <b-button
            class="is-tertiary"
            icon-pack="ubu"
            icon-left="ubu-cross"
            tag="router-link"
            to="/setting/tribe"
          />
        </div>
      </div>
      <span class="text is-3 has-text-secondary">Tribe form</span>
      <h1 class="title is-1">
        Form settings
      </h1>
    </header>

    <br>

    <DevDetails :cnt="{name: `'tribeForm'`, obj: tribeForm}" />
    <DevDetails :cnt="{name: `'payload'`, obj: payload}" />

    <div class="content">
      <b-field label="Form title">
        <b-input v-model="payload.title" />
      </b-field>

      <br>

      <b-field label="Link to your terms of use">
        <b-input v-model="payload.termsOfUseUrl" />
      </b-field>

      <br>

      <b-field label="Link to your privacy policy">
        <b-input v-model="payload.privacyUrl" />
      </b-field>

      <br>

      <template v-if="file">
        <div class="tags">
          <span class="tag is-primary">
            {{ file.name }}
            <button
              class="delete is-small"
              type="button"
              @click="deleteDropFile()"
            />
          </span>
        </div>
      </template>

      <b-field label="Form image">
        <b-upload
          v-model="file"
          accept=".jpg, .jpeg, .png"
          drag-drop
          expanded
        >
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon
                  pack="ubu"
                  icon="ubu-download"
                  size="is-large"
                />
              </p>
              <p>Drop your picture here</p>
            </div>
          </section>
        </b-upload>
      </b-field>

      <br>

      <div class="multibar">
        <div class="buttons end">
          <b-button
            label="Save & publish"
            type="is-primary"
            :disabled="isDisabled"
            @click="onSaveTribeForm"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSettingsTribeFormSettings',
  data() {
    return {
      payload: {
        title: null,
        text: null,
        background: null,
        alternateProfilePicture: null,
        termsOfUseUrl: null,
        privacyUrl: null,
      },
      file: null,
    };
  },
  computed: {
    ...mapGetters({
      tribeForm: 'TheSettingTribeForm/tribeForm',
    }),
    isDisabled() {
      if (!this.tribeForm) return false;
      if (!this.payload) return false;

      if (Object.keys(this.tribeForm).length && Object.keys(this.payload).length) {
        const isSameTitle = this.tribeForm.title === this.payload.title;
        const isSameTermsOfUseUrl = this.tribeForm.termsOfUseUrl === this.payload.termsOfUseUrl;
        const isSamePrivacyUrl = this.tribeForm.privacyUrl === this.payload.privacyUrl;
        const isSameText = this.tribeForm.text === this.payload.text;
        const isSameBackground = this.tribeForm.background === this.payload.background;
        const isSameAlternateProfilePicture = this.tribeForm.alternateProfilePicture === this.payload.alternateProfilePicture;

        if (
          isSameTitle
          && isSameText
          && isSameBackground
          && isSameAlternateProfilePicture
          && isSameTermsOfUseUrl
          && isSamePrivacyUrl
        ) return true;
      }
      if (Object.keys(this.payload).length) {
        if (this.payload.title || this.payload.background) return false;
      }

      return true;
    },
  },
  watch: {
    file(newVal) {
      if (newVal) this.payload.background = newVal.name;
      else this.payload.background = newVal;
    },
    /* eslint-disable func-names */
    'payload.title': function (newVal) {
      if (newVal !== null && newVal.length === 0) this.payload.title = null;
    },
    'payload.text': function (newVal) {
      if (newVal !== null && newVal.length === 0) this.payload.text = null;
    },
  },
  mounted() {
    this.getTribeForm()
      .then(() => {
        if (!this.tribeForm) return;
        if (Object.keys(this.tribeForm).length) {
          this.payload = { ...this.tribeForm };
        }
      });
  },
  methods: {
    ...mapActions({
      getTribeForm: 'TheSettingTribeForm/getTribeForm',
      createTribeForm: 'TheSettingTribeForm/createTribeForm',
      updateTribeForm: 'TheSettingTribeForm/updateTribeForm',
    }),
    deleteDropFile() {
      this.payload.background = null;
      if (this.file) this.file = null;
    },
    onSaveTribeForm() {
      const optionsBase = { duration: 2000, position: 'is-top', type: 'is-success' };

      if (!Object.keys(this.tribeForm).length) {
        this.createTribeForm({ payload: this.payload })
          .then(() => {
            this.$buefy.snackbar.open({
              message: 'Tribe form created !',
              ...optionsBase,
            });
          });
      } else {
        this.updateTribeForm({ payload: this.payload })
          .then(() => {
            this.$buefy.snackbar.open({
              message: 'Tribe form updated !',
              ...optionsBase,
            });
          });
      }

      this.$router.push('/setting/tribe/');
    },
  },
};
</script>
