<template>
  <component :is="`UbuIcon${cpo.contactPlatform.platformName.capitalize()}`" />
</template>

<script>
export default {
  name: 'UbuTablePlatform',
  props: {
    cpo: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
