<template>
  <TheSimplePanel>
    <template #header>
      <h1 class="title is-1">Edit name</h1>
    </template>

    <template #content>
      <UbuEditNameForm
        v-model="newName"
        @editName="editName()"
      />
    </template>
  </TheSimplePanel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheUsersUserDetailsPanelEditName',
  props: {
    detailledUser: { type: Object, default: () => {} },
  },
  data() {
    return {
      newName: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUserDetail: 'TheUsers/currentUserDetail',
    }),
    currentUid() {
      return this.$route.params.uid;
    },
    currentAuthenticate() {
      return this.detailledUser.authenticates.find(({ revokedOn }) => revokedOn === null);
    },
  },
  mounted() {
    this.newName = {
      firstName: this.detailledUser.firstName,
      lastName: this.detailledUser.lastName,
    };
  },
  methods: {
    ...mapActions({
      updateUserDetail: 'TheUsers/updateUserDetail',
      up_detailledUser: 'TheUsers/up_detailledUser',
      up_detailledUserList: 'TheUsers/up_detailledUserList',
    }),
    editName() {
      const payload = {
        ...this.currentUserDetail,
        ...this.newName,
        uid: this.currentUid,
        userUid: this.currentUid,
        email: this.currentAuthenticate.passName,
      };

      this.updateUserDetail({ payload })
        .then(() => {
          this.up_detailledUser({ payload });
          this.up_detailledUserList({ payload });
        })
        .then(() => { this.$router.push(`/users/user/${this.currentUid}/details`); });
    },
  },
};
</script>
