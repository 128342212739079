import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuFilterNumberRange from './UbuFilterNumberRange';
import UbuDropdownFiltersSelectorByGroup from './UbuDropdownFiltersSelectorByGroup';
import UbuFiltersSelectOperator from './UbuFiltersSelectOperator';
import UbuFilterByType from './UbuFilterByType';
import UbuFilterRadio from './UbuFilterRadio';
import UbuFilterInput from './UbuFilterInput';
import UbuFilterCheckbox from './UbuFilterCheckbox';
import UbuFilterDateRange from './UbuFilterDateRange';

import UbuButtonSwitchToInput from './UbuButtonSwitchToInput';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuFilterNumberRange);
    registerComponent(Vue, UbuDropdownFiltersSelectorByGroup);
    registerComponent(Vue, UbuFiltersSelectOperator);
    registerComponent(Vue, UbuFilterByType);
    registerComponent(Vue, UbuFilterRadio);
    registerComponent(Vue, UbuFilterInput);

    registerComponent(Vue, UbuFilterCheckbox);
    registerComponent(Vue, UbuFilterDateRange);

    registerComponent(Vue, UbuButtonSwitchToInput);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuFilterNumberRange,
  UbuDropdownFiltersSelectorByGroup,
  UbuFiltersSelectOperator,
  UbuFilterByType,
  UbuFilterRadio,
  UbuFilterInput,
  UbuFilterCheckbox,
  UbuFilterDateRange,

  UbuButtonSwitchToInput,
};
