<template>
  <b-tab-item value="root.label">
    <UbuSearchbar
      v-model="search"
      @clear="search = ''"
    />

    <br>

    <div class="buttons">
      <UbuActionCard
        v-for="cg in filteredLabelList"
        :key="cg.cgid"
        :class="`mb-4 has-text-color-${cg.color}`"
        :label="cg.label"
        icon-pack="ubu"
        icon-left="ubu-custom-contact"
        expanded
        @click="bulkApply(cg.cgid)"
      >
        <template v-slot:left-content>
          <div :class="`end multibar has-text-color-${cg.color}`">
            <b-icon
              v-if="!cg.cidsWithoutLabel.length"
              pack="ubu"
              icon="ubu-success"
              size="is-medium"
            />

            <b-icon
              v-else
              pack="ubu"
              icon="ubu-in-progress"
              class="has-text-secondary"
              size="is-medium"
            />
          </div>
        </template>
      </UbuActionCard>
    </div>
  </b-tab-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheAddToLabel',
  mixins: [FilterEngineMixin],
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Object,
      required: true,
    },
    tabItems: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      contactGroupList: 'TheAddToLabel/contactGroupList',
    }),

    localChecked: {
      get() { return this.checkedRows; },
      set(value) { this.$emit('input', value); },
    },

    unassignedByLabel() {
      const cgList = Object.values(this.contactGroupList);

      return cgList.map((cg) => ({
        ...cg,
        cidsWithoutLabel: this.getCidsWithoutLabel(cg.cgid),
      }));
    },

    filteredLabelList() {
      const cgList = this.unassignedByLabel;

      if (!cgList.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByLabelName = this.search;

      return this.filterEngine(cgList, filtersToApply);
    },

  },
  methods: {
    ...mapActions({
      _assignContactGroup: 'TheAddToLabel/_assignContactGroup',
      _unassignContactGroup: 'TheAddToLabel/_unassignContactGroup',

      UP_upsertCustom: 'TheAddToLabel/UP_upsertCustom',
    }),

    getCidsWithoutLabel(cgid) {
      const toBeLabeled = this.localChecked.reduce((acc, row) => {
        if (!acc[row.contact.cid]) {
          if (!row.labels) return { ...acc, [row.contact.cid]: true };

          if (!row.labels.find((id) => String(id) === String(cgid))) {
            return { ...acc, [row.contact.cid]: true };
          }
        }
        return acc;
      }, {});

      return Object.keys(toBeLabeled);
    },

    bulkApply(cgid) {
      const mappedList = this.unassignedByLabel.arrayMapper('cgid');

      // reduce it to not double loop
      const cids = this.localChecked.map(({ contact: { cid } }) => cid);
      const cpids = this.localChecked.map(({ cpid }) => cpid);

      if (!mappedList[cgid].cidsWithoutLabel.length) {
        return this._unassignContactGroup({ payload: { cgid, rows: this.localChecked } })
          .then(() => this.updateStores(cgid, cids, cpids))
          .then(() => {
            this.$buefy.snackbar.open({
              message: `${cids.length} people removed from contact label`,
              type: 'is-success',
              position: 'is-top',
            });
            this.$emit('done');
          });
      }

      return this._assignContactGroup({ payload: { cgid, rows: this.localChecked } })
        .then(() => this.updateStores(cgid, cids, cpids))
        .then(() => {
          this.$buefy.snackbar.open({
            message: `${cids.length} people added to contact label`,
            type: 'is-success',
            position: 'is-top',
          });
          this.$emit('done');
        });
    },

    updateStores(cgid, cids, cpids) {
      return Promise.all([
        this.UP_upsertCustom({ payload: { key: 'labels', itemId: cgid, cpids } }),
      ]);
    },
  },
};
</script>
