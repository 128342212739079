import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../../$utils/helpers';

const moment = require('moment');

/* eslint-disable max-len */
export default {
  getters: {
    templateList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiConversation/template/getterTemplateList': templateList } = rootGetters;

      return templateList;
    },
  },
  actions: {
    reportTemplate({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/template/report',
        context: { payload: { cgid: currentChannelGroup.cgid } },
        textNotifier: 'report template list',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to get template list.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    createTemplate({ dispatch, rootGetters }, { payload: { title, text, ...payload } }) {
      console.log('🚀 ~ file: template.js ~ line 39 ~ createTemplate ~ payload', payload);
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/template/create',
        context: {
          payload: {
            templateFolderTfid: null,
            ...payload,
            title: title.trim(),
            text: text.trim(),
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
            platformName: 'INSTAGRAM',
            createdOn: moment().format(),
            updatedOn: null,
          },
        },
        textNotifier: 'Template successfully created.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };
      console.log('🚀 ~ file: template.js ~ line 42 ~ createTemplate ~ action', action.context);

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'createTemplate' }, { root: true }))
        .catch(() => {
          Snackbar.open({
            message: 'Unable to create template.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },

    updateTemplate({ dispatch, rootGetters }, { payload: { channelGroupCgid, ...payload } }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/template/update',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
            platformName: 'INSTAGRAM',
            updatedOn: moment().format(),
          },
        },
        textNotifier: 'Template successfully updated.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to update template.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },

    deleteTemplate({ dispatch, rootGetters }, { payload: { tid } }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/template/delete',
        context: {
          payload: {
            tid,
            cgid: currentChannelGroup.cgid,
          },
        },
        textNotifier: 'Template successfully delete.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to delete template.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
  },
};
