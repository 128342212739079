<!-- eslint-disable max-len -->
<template>
  <section>
    <b-tab-item value="root.addToCampaign">
      <UbuSearchbar
        v-if="activeTab.tab === 'root.addToCampaign'"
        v-model="search"
        @clear="search = ''"
      />

      <br>

      <div class="buttons">
        <UbuActionCard
          v-for="camp in campaignListFiltered"
          :key="camp.cid"
          :class="`mb-4 has-text-color-${camp.color}`"
          :label="camp.name"
          icon-pack="ubu"
          icon-left="ubu-custom-influence"
          expanded
          has-loading
          :is-loading="String(loadingCampaignActivities) === String(camp.cid)"
          @click="selectCampaignAndContinue(camp)"
        />
      </div>
    </b-tab-item>

    <b-tab-item value="root.addToCampaign.reachMethod">
      <TheAddToCampaignReachMethod
        v-if="activeTab.tab === 'root.addToCampaign.reachMethod'"
        @select="selectReachMethod($event)"
      />
    </b-tab-item>

    <b-tab-item :value="pathAlreadyInCampaign">
      <TheAddToCampaignAlreadyInCampaign
        v-if="activeTab.tab === pathAlreadyInCampaign"
        @next="handleNextAlreadyInCampaign()"
      />
    </b-tab-item>

    <b-tab-item :value="`${pathAlreadyInCampaign}.toSend`">
      <TheAddToCampaignToSendMessage
        v-if="activeTab.tab === `${pathAlreadyInCampaign}.toSend`"
        @next="gotTo('.toSend.message')"
      />
    </b-tab-item>

    <b-tab-item :value="`${pathAlreadyInCampaign}.toSend.message`">
      <TheAddToCampaignMessage
        v-if="activeTab.tab === `${pathAlreadyInCampaign}.toSend.message`"
        @submit="handleComposeMessage()"
      />
    </b-tab-item>

    <b-tab-item :value="`${pathAlreadyInCampaign}.toScan`">
      <TheAddToCampaignToScan
        v-if="activeTab.tab === `${pathAlreadyInCampaign}.toScan`"
        @next="gotTo('.summary')"
      />
    </b-tab-item>

    <b-tab-item :value="`${pathAlreadyInCampaign}.summary`">
      <TheAddToCampaignSummary @next="applyBulkAndContinue(`${pathAlreadyInCampaign}.done`)" />
    </b-tab-item>

    <b-tab-item :value="`${pathAlreadyInCampaign}.done`">
      <b-message
        v-if="canDisplaySuccess"
        type="is-success"
      >
        <div class="multibar row-vcentered">
          <b-icon
            pack="ubu"
            icon="ubu-success"
            size="is-medium"
            class="start"
          />
          <span class="start">
            Success! People are being added to your campaign.
          </span>
        </div>
      </b-message>

      <UbuMessagePeopleWarning
        v-if="warningWhileWizardSendInstagram.length"
        :text="`Instagram messages won’t be sent to ${warningWhileWizardSendInstagram.length} people as they are already in this campaign.`"
        :contact-platform-list="warningWhileWizardSendInstagram"
      />

      <UbuMessagePeopleWarning
        v-if="warningWhileWizardAwaitingMention.length"
        :text="`Scan mentions status won’t be set to ${warningWhileWizardAwaitingMention.length} people as they are already in this campaign.`"
        :contact-platform-list="warningWhileWizardAwaitingMention"
      />

      <b-message
        v-if="errorWhileWizard"
        type="is-danger"
      >
        <p class="text is-3">
          {{ errorWhileWizard }}
        </p>

        <div
          class="multibar row-vcentered mt-2 mb-2"
        >
          <b-button
            type="is-ghost"
            class="start"
            label="Download payload"
            @click="downloadPayload()"
          />
        </div>
      </b-message>
    </b-tab-item>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import download from 'downloadjs';

import TheAddToCampaignMixin from '../mixins/TheAddToCampaign.mixin';

export default {
  name: 'TheNewAddToCampaign',
  mixins: [TheAddToCampaignMixin],
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Object,
      required: true,
    },
    tabItems: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      pathAlreadyInCampaign: 'root.addToCampaign.reachMethod.alreadyInCampaign',
      loadingCampaignActivities: null,
      finalizedPayload: null,
      errorWhileWizard: null,
      warningWhileWizardSendInstagram: [],
      warningWhileWizardAwaitingMention: [],
    };
  },
  computed: {
    ...mapGetters({
      templateList: 'TheCRM/templateList',
      activeCampaignList: 'TheCRM/activeCampaignList',

      selectedCps: 'TheNewAddToCampaign/selectedCps',
      newBulkAction: 'TheNewAddToCampaign/newBulkAction',
      instagramersToSend: 'TheNewAddToCampaign/instagramersToSend',
      removedToScan: 'TheNewAddToCampaign/removedToScan',
    }),

    localChecked: {
      get() { return this.checkedRows; },
      set(value) { this.$emit('input', value); },
    },

    localActiveTab: {
      get() { return this.activeTab; },
      set(val) { this.$emit('update:activeTab', val); },
    },

    canDisplaySuccess() {
      if (this.errorWhileWizard) return false;

      const shouldBeCreated = this.instagramersToSend.length + this.removedToScan.length;
      const created = this.warningWhileWizardSendInstagram.length + this.warningWhileWizardAwaitingMention.length;

      if (created === shouldBeCreated) return false;

      return true;
    },
  },
  beforeDestroy() {
    return this.UP_resetAddToCampaignStore();
  },

  methods: {
    ...mapActions({
      _reportActivities: 'TheNewAddToCampaign/reportActivities',
      UP_selectedCps: 'TheNewAddToCampaign/UP_selectedCps',
      UP_newBulkAction: 'TheNewAddToCampaign/UP_newBulkAction',
      UP_resetAddToCampaignStore: 'TheNewAddToCampaign/UP_resetStore',
      UP_resetAddToCampaignWorkflowState: 'TheNewAddToCampaign/UP_resetWorkflowState',

      bulkAddToCampaign: 'TheNewAddToCampaign/bulkAddToCampaign',
    }),

    UP_changeToNewTab(activeTab) {
      this.$emit('update:activeTab', activeTab);
    },

    selectCampaignAndContinue(campaign) {
      this.loadingCampaignActivities = campaign.cid;
      return Promise.resolve()
        .then(() => this._reportActivities({
          payload: { cid: campaign.cid },
          httpQuery: { cpids: this.localChecked.map(({ cpid }) => cpid) },
        }))
        .then(({ response: retrievedActivities }) => {
          const preparedChecked = this.localChecked.map((row) => ({
            ...row,
            ...retrievedActivities.reduce((acc, activity) => {
              // Filter activities from other CP
              if (String(activity.contactPlatformCpid) !== String(row.cpid)) return acc;
              // If at least one activity exists on this campaign, we can't add CP again
              if (String(activity.campaignCid) === String(campaign.cid)) acc.canBeAddedToCampaign = false;
              // activities with endedOn goes to pasts
              if (activity.endedOn) acc.pasts.push(activity);
              // Finally an activity without endedOn is the active
              if (!activity.endedOn) acc.active = activity;
              return acc;
            }, { active: null, pasts: [], canBeAddedToCampaign: true }),
          }));
          this.UP_selectedCps(preparedChecked);
          this.UP_resetAddToCampaignWorkflowState();
          this.UP_changeToNewTab(this.tabItems['root.addToCampaign.reachMethod']);
          this.UP_newBulkAction({ cid: campaign.cid });
          this.loadingCampaignActivities = null;
        });
    },

    gotTo(ext) {
      return this.UP_changeToNewTab(this.tabItems[`${this.pathAlreadyInCampaign}${ext}`]);
    },

    selectReachMethod(reachMethod) {
      this.UP_resetAddToCampaignWorkflowState();
      this.UP_newBulkAction({ reachMethod });

      // If no selected people is active on another campaign, we skip the step
      if (!this.alreadyActiveInOtherCampaign.length) {
        if (this.newBulkAction.reachMethod === 'COLD_OUTREACH') return this.gotTo('.toSend');
        if (this.newBulkAction.reachMethod === 'WITHOUT_MESSAGE') return this.gotTo('.toScan');
      }

      return this.UP_changeToNewTab(this.tabItems[this.pathAlreadyInCampaign]);
    },

    handleNextAlreadyInCampaign() {
      if (this.newBulkAction.reachMethod === 'COLD_OUTREACH') return this.gotTo('.toSend');

      return this.gotTo('.toScan');
    },

    handleComposeMessage() {
      if (!this.toScans.length) return this.gotTo('.summary');

      return this.gotTo('.toScan');
    },

    applyBulkAndContinue(tabToGo) {
      return this.sendBulkAddToCampaign()
        .then((activities) => {
          this.warnErrorsWhileWizard(activities);
          this.UP_changeToNewTab(this.tabItems[tabToGo]);
        })
        .catch((e) => {
          this.UP_changeToNewTab(this.tabItems[tabToGo]);
          switch (e) {
            default:
              this.errorWhileWizard = 'An unexpected error has occured during process, please contact support !';
              break;
          }
        });
    },

    warnErrorsWhileWizard(activities) {
      const cpsSendInstagram = this.instagramersToSend;
      const cpsAwaitingMention = this.removedToScan;
      if (activities.length === (cpsSendInstagram.length + cpsAwaitingMention.length)) return;
      const mappedActivities = activities.arrayMapper('contactPlatformCpid');
      this.warningWhileWizardSendInstagram = Object.values(cpsSendInstagram.reduce((acc, row) => {
        if (!mappedActivities[row.cpid]) {
          acc[row.cpid] = row;
        }
        return acc;
      }, {}));

      this.warningWhileWizardAwaitingMention = Object.values(cpsAwaitingMention.reduce((acc, row) => {
        if (!mappedActivities[row.cpid]) {
          acc[row.cpid] = row;
        }
        return acc;
      }, {}));
    },

    sendBulkAddToCampaign() {
      const payload = {
        cid: this.newBulkAction.cid,
        sendInstagram: null,
        awaitingMention: null,
      };
      if (this.instagramersToSend.length) {
        payload.sendInstagram = {
          cpids: this.instagramersToSend.map(({ cpid }) => cpid),
          message: this.newBulkAction.message,
          timezone: this.newBulkAction.timezone,
        };
      }
      if (this.removedToScan.length) {
        payload.awaitingMention = {
          cpids: this.removedToScan.map(({ cpid }) => cpid),
        };
      }

      this.finalizedPayload = payload;

      return this.bulkAddToCampaign({ payload });
    },

    downloadPayload() {
      download(JSON.stringify(this.finalizedPayload, null, 2), 'test.json', 'text/plain');
    },
  },
};
</script>
