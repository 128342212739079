<template>
  <section>
    <div class="is-centered buttons expandable p-2">
      <b-button
        v-for="(tab, index) in tabs"
        :key="index"
        tag="router-link"
        type="is-simple"
        :to="tab.to"
        :active="$route.path.includes(tab.to)"
        icon-pack="ubu"
        :icon-left="tab.icon"
        :label="tab.label"
      />
    </div>

    <br>

    <router-view />
  </section>
</template>

<script>
/* eslint-disable max-len */

export default {
  name: 'TheCampaignAnalytic',
  data() {
    return {
      tabs: [
        {
          label: 'Campaign', icon: 'ubu-influence', to: `/campaign/${this.$route.params.campaignCid}/analytics/activities`, active: true,
        },
        {
          label: 'Mentions', icon: 'ubu-mentions', to: `/campaign/${this.$route.params.campaignCid}/analytics/mentions`, active: false,
        },
        {
          label: 'Sales', icon: 'ubu-shop', to: `/campaign/${this.$route.params.campaignCid}/analytics/sales`, active: false,
        },
      ],
    };
  },
};
</script>
