/* eslint-disable max-len */
import moment from 'moment';
// import ErrorBuilder from '@dailyplanet/utils/Errors';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
// eslint-disable-next-line object-curly-newline
import { dispatchAndForward, genericErrorHandler, genericThenHandler } from '../$utils/helpers';

export default {
  getters: {
    contactGroupList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ContactGroup/getterContactGroupList': contactGroupList } = rootGetters;

      return contactGroupList;
    },
  },
  actions: {
    reportContactGroup({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ContactGroup/report',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid } },
        textNotifier: 'report contact groups',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report Labels.', indefinite: true }));
    },

    createContactGroup({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ContactGroup/create',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
            createdOn: moment().format(),
            deletedOn: null,
          },
        },
        textNotifier: 'create contact groups',
      };

      return dispatchAndForward(action)
        .then(({ response: contactGroup }) => {
          dispatch('Segment/segmentTrack', { event: 'createLabel' }, { root: true });

          return contactGroup;
        })
        .catch(genericErrorHandler(action, { message: 'Unable to create Label.', indefinite: true }));
    },

    updateContactGroup({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ContactGroup/update',
        context: {
          payload: {
            ...payload,
            channelGroupCgid: currentChannelGroup.cgid,
          },
        },
        textNotifier: 'Labels successfully updated.',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to update Label.', indefinite: true }));
    },

    deleteContactGroup({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ContactGroup/delete',
        context: {
          payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload },
        },
      };

      return dispatchAndForward(action)
        .then(() => {
          Snackbar.open({
            message: 'Label successfully deleted.',
            type: 'is-success',
            position: 'is-top',
          });
        })
        .catch((error) => {
          let message;

          switch (error.message) {
            case 'E_UNPROCESSABLE_API_CONTACT_CONTACT_GROUP_CANNOT_DELETE_USED_CONTACT_GROUP':
              message = 'Unable to delete Label. Several influencers use it, please unlabel them before retrying to delete this label.';
              break;
            case 'E_NOT_FOUND_API_CONTACT_CONTACT_GROUP_CONTACT_GROUP_CGID':
              message = 'Need to reload the page.';
              break;
            default: message = 'Unexpected error'; break;
          }

          Snackbar.open({
            message,
            type: 'is-danger',
            position: 'is-top',
            indefinite: true,
          });
        });
    },

    _assignContactGroup({ dispatch, rootGetters }, { payload: { rows, cgid }, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ContactGroup/assign/create',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, cgid, cids: rows.map(({ contact }) => contact.cid) } },
        textNotifier: 'assign label groups',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to assign Label.', indefinite: true }));
    },
    _unassignContactGroup({ dispatch, rootGetters }, { payload: { rows, cgid }, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ContactGroup/assign/delete',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, cgid, cids: rows.map(({ contact }) => contact.cid) } },
        textNotifier: 'unassign label groups',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to unassign Label.', indefinite: true }));
    },
  },
};
