/* eslint-disable max-len */
export default {
  state: {
    activeTab: {
      tab: 'root',
      title: 'What do you want to do ?',
      subtext: 'Bulk actions',
    },
    checkedRows: [],
    visitedTabList: ['root'],
  },
  mutations: {
    SET_ACTIVE_TAB(state, newData) { state.activeTab = newData; },
    SET_CHECKED_ROWS(state, newData) { state.checkedRows = newData; },
    SET_VISITED_TAB_LIST(state, newData) { state.visitedTabList.push(newData); },
    RESET_STORE(state) {
      state.activeTab = {
        tab: 'root',
        title: 'What do you want to do ?',
        subtext: 'Bulk actions',
      };
      state.checkedRows = [];
      state.visitedTabList = ['root'];
    },
    RESET_NAVIGATION(state) {
      state.activeTab = {
        tab: 'root',
        title: 'What do you want to do ?',
        subtext: 'Bulk actions',
      };
      state.visitedTabList = ['root'];
    },
  },
  getters: {
    activeTab: (state) => state.activeTab,
    checkedRows: (state) => state.checkedRows,
    visitedTabList: (state) => state.visitedTabList,
  },
  actions: {
    UP_activeTab({ commit }, newData) { commit('SET_ACTIVE_TAB', newData); },
    UP_changeToNewTab({ commit, getters }, newData) {
      commit('SET_VISITED_TAB_LIST', getters.activeTab.tab);
      commit('SET_ACTIVE_TAB', newData);
    },
    UP_checkedRows({ commit }, newData) { commit('SET_CHECKED_ROWS', newData); },
    UP_resetStore({ commit }) { commit('RESET_STORE'); },
    UP_resetNavigation({ commit }) { commit('RESET_NAVIGATION'); },
    UP_visitedTabList({ commit }, newData) { commit('SET_VISITED_TAB_LIST', newData); },

    goToPreviousTab({ commit, getters }) {
      const { activeTab, tabItems } = getters;
      if (activeTab.tab === 'root') return;
      const tabToGo = activeTab.tab.split('.').slice(0, -1).join('.');
      commit('SET_ACTIVE_TAB', tabItems[tabToGo]);
    },
    goToPreviousVisitedTab({ commit, getters }) {
      const { activeTab, tabItems, visitedTabList } = getters;

      if (activeTab.tab === 'root') return;

      const lastTabVisitedIsRoot = visitedTabList.length === 1;
      // si on a plus que 'root' dans notre array on prend le dernier et on le retire
      // si on a que 'root' on le garde dans l'array et on redirect dessus
      const tabToGo = lastTabVisitedIsRoot ? 'root' : visitedTabList.pop();

      commit('SET_ACTIVE_TAB', tabItems[tabToGo]);
    },
    goToTabByTabPath({ commit, getters }, tabToGo) {
      const { tabItems } = getters;
      commit('SET_ACTIVE_TAB', tabItems[tabToGo]);
    },
  },
};
