<template>
  <section id="TheWorkspace">
    <TheMonitoringTaskSubMenu
      :http-query.sync="httpQuery"
      :selected-channel-group.sync="selectedChannelGroup"
      :columns.sync="columns"
      :filters.sync="filters"
      @update:httpQuery="refreshTaskList($event)"
      @update:selectedChannelGroup="page = 1;"
    />

    <div class="has-coreview-spaced kara">
      <div class="ubuSettingView">
        <b-table
          :data="computeMode ? tasksComputeds : filteredDatas"
          :paginated="true"
          :per-page="perPage"
          :total="filteredDatas.length"
          :current-page="page"
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          pagination-size="is-small"
          hoverable
          sticky-checkbox
          sticky-header
          class="table-full-height"
          :loading="loading"
          :row-class="(row, index) => rowColor(row, index)"
          :opened-detailed="defaultOpenedDetails"
          :detailed="true"
          detail-key="tid"
          @sort="onSort"
          @page-change="page = $event"
        >
          <!-- <b-table-column
            v-slot="props"
            :visible="true"
            label="Action"
            field="action"
            sticky
            header-class="first-col"
            cell-class="first-col"
          >
            <b-button
              class="is-ghost"
              label="Open Details"
              @click="selectedTask = props.row"
            />
          </b-table-column> -->

          <b-table-column
            v-for="column in columns"
            :key="column.col"
            :visible="column.visible"
            :label="column.label"
            :field="column.col"
            :sortable="column.isSortable"
            :sticky="column.sticky"
            :cell-class="column.cellClass"
          >
            <template
              v-slot="props"
            >
              <template v-if="column.cmp === 'flat'">
                <b-tooltip
                  v-if="column.tooltip"
                  :label="props.row[column.col]"
                  multilined
                  append-to-body
                  size="is-large"
                  position="is-bottom"
                >
                  <span class="text-truncate">
                    {{ props.row[column.col] || '-' }}
                  </span>
                </b-tooltip>
                <span
                  v-else
                  class="text-truncate"
                >
                  {{ props.row[column.col] || '-' }}
                </span>
              </template>

              <template v-if="column.cmp === 'date'">
                <span v-if="props.row[column.col]">
                  {{ $moment(props.row[column.col]).format(formatDate) }}
                </span>
                <span v-else>-</span>
              </template>
            </template>
          </b-table-column>

          <b-table-column
            v-slot="props"
            :visible="true"
            label="Action"
            field="action"
            centered
          >
            <div class="buttons is-centered">
              <b-button
                v-if="props.row.status !== 'CANCELED'"
                label="Cancel Activity"
                type="is-danger"
                size="is-small"
                :loading="retryIsLoading.includes(props.row.tid)"
                :disabled="cancelTaskIsLoading.length > 0"
                @click="confirmCancelTask(props.row)"
              />
              <b-button
                v-if="props.row.state === 'SCHEDULED' && props.row.triggerOn"
                label="Retry"
                type="is-warning"
                size="is-small"
                :loading="retryIsLoading.includes(props.row.tid)"
                :disabled="retryIsLoading.length > 0"
                @click="confirmRetryTask(props.row)"
              />
            </div>
          </b-table-column>

          <template #detail="props">
            <!-- <pre>{{ props }}</pre> -->
            <details
              class="JsonReader"
              open
            >
              <summary>
                selectedTask
              </summary>

              <JsonReader :obj="props.row" />
            </details>
          </template>
        </b-table>
      </div>
      <TheMonitoringTaskPanel
        v-if="selectedTask"
        v-model="selectedTask"
        @close="selectedTask = null"
      />
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import SendHooksEngine from '@dailyplanet/utils/sendhook.engine';
import TheMonitoringTaskMixin from './_mixins/theMonitoringTask.mixins';

export default {
  name: 'TheMonitoringTask',
  mixins: [TheMonitoringTaskMixin],
  data() {
    return {
      loading: false,
      selected: {},
      total: 0,
      page: 1,
      perPage: 50,
      sortField: 'tid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      checkedRows: [],
      defaultOpenedDetails: [],
      search: '',
      inputSearch: '',
      cancelTaskIsLoading: [],
      retryIsLoading: [],
      selectedChannelGroup: null,
      self: this,
      computeMode: false,
      tasksComputeds: null,
      selectedTask: null,

      filters: [],
      httpQuery: { toExecute: true },
      /* eslint-disable object-curly-newline */
      columns: {
        tid: { col: 'tid', label: 'tid', visible: false, isSortable: true, cmp: 'flat' },
        channelGroupCgid: { col: 'channelGroupCgid', label: 'cgid', visible: false, isSortable: true, cmp: 'flat' },
        state: { col: 'state', label: 'Task state', visible: true, isSortable: true, cmp: 'flat' },
        name: { col: 'name', label: 'Workspace', visible: true, isSortable: true, cmp: 'flat' },
        username: { col: 'username', label: 'Target', visible: true, isSortable: true, cmp: 'flat' },
        campaignCid: { col: 'campaignCid', label: 'Campaign Id', visible: false, isSortable: true, cmp: 'flat' },
        status: { col: 'status', label: 'Act. status', visible: true, isSortable: true, cmp: 'flat' },
        cpid: { col: 'cpid', label: 'cpid', visible: false, isSortable: true, cmp: 'flat' },
        timezone: { col: 'timezone', label: 'timezone', visible: false, isSortable: true, cmp: 'flat' },
        createdOn: { col: 'createdOn', label: 'Created On', visible: true, isSortable: true, cmp: 'date' },
        updatedOn: { col: 'updatedOn', label: 'Updated On', visible: true, isSortable: true, cmp: 'date' },
        triggerOn: { col: 'triggerOn', label: 'Trigger On', visible: true, isSortable: true, cmp: 'date' },
        estimatedOn: { col: 'estimatedOn', label: 'Estimated On', visible: false, isSortable: true, cmp: 'date' },
        error: { col: 'error', label: 'Error', visible: true, isSortable: true, cmp: 'flat' },
      },
      /* eslint-enable object-curly-newline */
    };
  },
  mounted() {
    this.filters = ['Tasks Scheduled'];
    return this.refreshTaskList();
  },
  methods: {
    ...mapActions({
      _reportTasks: 'TheMonitoring/reportTask',
      UP_task: 'TheMonitoring/UP_task',
    }),
    refreshTaskList() {
      this.loading = true;
      return this._reportTasks({ httpQuery: this.httpQuery })
        .then(() => {
          this.loading = false;
        });
    },
    onSort(sortField, sortOrder) {
      this.sortField = sortField;
      this.sortOrder = sortOrder;
    },
    rowColor(row) {
      const warnStatus = ['CANCELED'];
      const warnState = ['CANCELED', 'CANCEL'];
      if (row.error) {
        return 'is-danger';
      }
      if (warnStatus.includes(row.status) && warnState.includes(row.status)) {
        return 'is-cancel';
      }
      if (['DONE'].includes(row.state) && ['SCHEDULED'].includes(row.status)) {
        return 'is-warn';
      }
      if (['DONE'].includes(row.state) && !['SCHEDULED', 'CANCELED'].includes(row.status)) {
        return 'is-success';
      }
      if (!['DONE', ...warnState].includes(row.state) && !['SCHEDULED', 'CANCELED'].includes(row.status)) {
        return 'is-fade';
      }
      return '';
    },
    computeAwaiting(cgid) {
      this.computeMode = true;
      this.columns.estimatedOn.visible = true;
      const tasks = this.tasks.filter(({ channelGroupCgid }) => channelGroupCgid === cgid);
      const tasksComputeds = [];
      const dones = [];
      const recursive = ([task, ...rest]) => {
        if (!rest || !rest.length) return undefined;
        const lastSendOn = dones[dones.length - 1] || task.triggerOn;
        const thisDay = this.$moment(lastSendOn).format('YY-MM-DD');
        const sentToday = dones.filter((date) => date.format('YY-MM-DD') === thisDay).length;
        const next = SendHooksEngine.run({
          sentToday,
          lastSendOn,
          customerTimezone: task.timezone,
          limitSendByDay: 70,
        });
        dones.push(next);
        tasksComputeds.push({ ...task, estimatedOn: task.triggerOn ? null : next.format() });
        return recursive(rest);
      };

      recursive(tasks);
      console.log('🚀 ~ file: TheMonitoringTask.vue ~ line 361 ~ computeAwaiting ~ tasksComputeds', tasksComputeds);
      this.tasksComputeds = tasksComputeds;
    },
  },
};
</script>

<style lang="scss">
  tr.is {
    &-success { color: var(--success) !important; }
    &-cancel { color: var(--darkgrey) !important; }
    &-fade { color: var(--color9) !important; }
    &-warn { color: var(--warning) !important; }
    &-danger { color: var(--danger) !important; }
  }
</style>
