export default {
  state: {
    home: {
      menuItems: [
        {
          path: '/home',
          text: 'What\'s New',
          icon: 'ubu-home',
        },
        {
          path: '/admin',
          text: 'Account Dashboard',
          icon: 'ubu-account',
        },
        {
          path: '/users',
          text: 'Users Dashboard',
          icon: 'ubu-emoji',
        },
        {
          path: '/shopify',
          text: 'Shopify Dashboard',
          icon: 'ubu-money',
        },
        {
          path: '/crews',
          text: 'Crews Dashboard',
          icon: 'ubu-emoji',
        },
        {
          path: '/rewards',
          text: 'Rewards Dashboard',
          icon: 'ubu-overview',
        },
      ],
    },
    navigation: {
      menuItems: [
        {
          path: '/settings/theme',
          text: 'Theme',
        },
      ],
    },
    user: {
      menuItems: [
        {
          path: '/user/signin',
          text: 'Sign In',
          icon: 'ubu-account',
        },
        {
          path: '/user/detailledUser',
          text: 'Users',
          icon: 'ubu-account',
        },
      ],
    },
    sales: {
      menuItems: [
        {
          path: '/ubuLead/dashboard',
          text: 'Dashboard',
          icon: 'ubu-overview',
        },

        {
          path: '/ubuLead/leadsGeneration/scrap',
          text: 'Scraping',
          icon: 'ubu-overview',
        },

        {
          path: '/ubuLead/leadsGeneration/suggestions',
          text: 'Suggestions',
          icon: 'ubu-overview',
        },

        {
          path: '/ubuLead/administration',
          text: 'Administration',
          icon: 'ubu-overview',
        },
      ],
    },
  },
};
