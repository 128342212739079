import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    currentBrand: {},
  },
  mutations: {
    SET_CURRENT_BRAND(state, newData) { state.currentBrand = newData; },
  },
  getters: {
    getterCurrentBrand: (state) => state.currentBrand,
  },
  actions: {
    get({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiCrew.brand.get({ payload, httpQuery })
        .then((brand) => {
          commit('SET_CURRENT_BRAND', brand);
          return brand;
        });
    },

    create({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiCrew.brand.post({ payload, httpQuery })
        .then((brand) => {
          commit('SET_CURRENT_BRAND', brand);
          return brand;
        });
    },

    update({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiCrew.brand.put({ payload, httpQuery })
        .then((brand) => {
          commit('SET_CURRENT_BRAND', brand);
          return brand;
        });
    },
  },
};
