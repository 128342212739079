<template>
  <div class="campaignColumn">
    <b-icon
      pack="ubu"
      icon="ubu-custom-influence"
      :class="`has-text-color-${color} mr-3`"
    />
    <div class="campaignColumnContent">
      <p class="subtitle is-3 m-0">{{ name }}</p>
      <p class="subtext has-text-secondary">
        {{ $moment(date).format('YYYY-MM-DD') }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuTableCampaign',
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
};
</script>
