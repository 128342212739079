/* eslint-disable max-len */
import { actions } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    isAllCustomFetched: false,
    isAllCustomAsked: false,
  },
  getters: {
    isAllCustomFetched: (state) => state.isAllCustomFetched,
    isAllCustomAsked: (state) => state.isAllCustomAsked,
  },
  mutations: {
    SET_ALL_CUSTOMS_FETCHED(state) { state.isAllCustomFetched = true; },
    SET_ALL_CUSTOMS_ASKED(state) { state.isAllCustomAsked = true; },
    RESET_STORE(state) {
      state.isAllCustomFetched = false;
    },
  },
  actions: {
    allCustoms(params) {
      if (params.getters.isAllCustomAsked) return undefined;
      params.commit('SET_ALL_CUSTOMS_ASKED');

      return Promise.allSettled([
        actions.account.getAccount(params, {
          httpQuery: {
            channelAndChannelGroupList: true,
            accountMembers: true,
            accountEvent: true,
            hasFeature: true,
          },
        }),
        actions.campaign.reportCampaign(params),

        actions.channelGroup.reportCrmList(params, { httpQuery: {} }),
        actions.channelGroup.reportUserList(params),
        actions.channelGroup.reportContactLocation(params),
        actions.channelGroup.reportContactLanguage(params),

        actions.contactGroup.reportContactGroup(params),

        actions.influence.reportActivityStatus(params),

        actions.memberGroup.reportMemberGroupList(params, { httpQuery: {} }),

        actions.segmentFilter.reportSegmentFilter(params),

        actions.template.reportTemplate(params),
        actions.templateFolder.reportTemplateFolder(params),

        actions.threadGroup.reportThreadGroup(params),

        actions.tribe.reportTribeList(params, { httpQuery: { memberGroupPosition: true } }),

        actions.brand.getBrand(params),

      ])
        .then(() => params.dispatch('fetchExtensions'))
        .then(() => params.commit('SET_ALL_CUSTOMS_FETCHED'));
    },

    fetchExtensions(params) {
      return Promise.all([
        actions.shop.reportShopList(params, { httpQuery: { folderList: true } }),
      ]);
    },

    hardRefreshAllCustoms(params) {
      return Promise.allSettled([
        actions.account.getAccount(params, {
          httpQuery: {
            channelAndChannelGroupList: true,
            accountMembers: true,
            accountEvent: true,
            hasFeature: true,
          },
        }),
        actions.campaign.reportCampaign(params),
        actions.channelGroup.reportCrmList(params, { httpQuery: {} }),
        actions.channelGroup.reportUserList(params),
        actions.channelGroup.reportContactLocation(params),
        actions.channelGroup.reportContactLanguage(params),
        actions.template.reportTemplate(params),
        actions.contactGroup.reportContactGroup(params),
        actions.influence.reportActivityStatus(params),
        actions.threadGroup.reportThreadGroup(params),
        actions.memberGroup.reportMemberGroupList(params, { httpQuery: {} }),
        actions.segmentFilter.reportSegmentFilter(params),
        actions.templateFolder.reportTemplateFolder(params),
        actions.tribe.reportTribeList(params, { httpQuery: { memberGroupPosition: true } }),
        actions.brand.getBrand(params),
      ])
        .then(() => params.dispatch('fetchExtensions'))
        .then(() => params.commit('SET_ALL_CUSTOMS_FETCHED'));
    },

    clearStores({ commit }) {
      commit('RESET_STORE');
      commit('ApiInfluence/campaign/RESET_STORE', null, { root: true });
      commit('ApiConversation/template/RESET_STORE', null, { root: true });
      commit('ContactGroup/RESET_STORE', null, { root: true });
      commit('ThreadGroup/RESET_STORE', null, { root: true });
    },
  },
};
