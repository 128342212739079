import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheSendInstantMessage from './smarts/TheSendInstantMessage';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheSendInstantMessage);
  },
};

use(Plugin);

export default Plugin;

export {
  TheSendInstantMessage,
};
