/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import { clark, s3 } from '@dailyplanet/providers';
import moment from 'moment';
import { hasAccessToChannel } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    messageList: null,
    messageListTotal: null,
    currentMessageThreadTid: null,
    holdedMessage: {},
    isLoadingMoreMessagesList: {},
  },
  mutations: {
    SET_MESSAGE_LIST(state, newData) { state.messageList = newData; },
    RESET_MESSAGE_LIST(state) { state.messageList = {}; }, // look if we can delete this or replace by RESET_STORE
    SET_MESSAGE_LIST_TOTAL(state, newData) { state.messageListTotal = newData; },
    RESET_STORE(state) {
      state.messageList = null;
      state.messageListTotal = null;
      state.currentMessageThreadTid = null;
      state.holdedMessage = {};
    },
    MERGE_HOLDED(state, tid) { state.messageList = { ...state.messageList, ...state.holdedMessage[tid] }; },
    SET_CURRENT_MESSAGE_THREADTID(state, tid) { state.currentMessageThreadTid = tid; },
    SET_HOLDED(state, newData) { state.holdedMessage = newData; },
    SET_IS_LOADING_MORE_MESSAGES(state, newData) { state.isLoadingMoreMessagesList = newData; },
  },
  getters: {
    getterMessageList: (state) => state.messageList,
    getterMessageListTotal: (state) => state.messageListTotal,
    getterHoldedMessage: (state) => state.holdedMessage,
    // getterCurrentThreadTid: (state) => state.currentThreadTid,
    getterIsLoadingMoreMessagesList: (state) => state.isLoadingMoreMessagesList,
    getterCurrentMessageThreadTid: (state) => state.currentMessageThreadTid,
  },
  actions: {
    report({ commit, getters, rootGetters }, { payload, httpQuery }) {
      const { tid } = payload;
      if (!hasAccessToChannel(rootGetters, payload.cid)) return undefined;

      return clark.api.channel.thread.message.report({ payload, httpQuery })
        .then(({ results, total }) => {
          if (httpQuery.pagin[0] === 0) {
            commit('SET_MESSAGE_LIST', results.arrayMapper('customId'));
          } else {
            commit('SET_MESSAGE_LIST', { ...getters.getterMessageList, ...results.arrayMapper('customId') });
          }

          commit('SET_MESSAGE_LIST_TOTAL', total);
          if (getters.getterHoldedMessage[tid]) commit('MERGE_HOLDED', tid);
          return results;
        });
    },
    create({ commit, getters, rootGetters }, { payload, httpQuery }) {
      if (!hasAccessToChannel(rootGetters, payload.cid)) return undefined;

      const { cpid, messageType, ...rest } = payload;

      return clark.api.channel.thread.message.post({ payload: rest, httpQuery })
        .then((messageList) => {
          commit('SET_MESSAGE_LIST', { ...getters.getterMessageList, ...messageList });
          if (getters.getterHoldedMessage[payload.tid]) commit('SET_HOLDED', payload.tid);
          return messageList;
        });
    },
    loadMore({ rootGetters }, { payload, httpQuery }) {
      if (!hasAccessToChannel(rootGetters, payload.cid)) return undefined;

      return clark.api.channel.thread.message.loadMore({ payload, httpQuery });
    },
    imageMetadata({ rootGetters }, { payload, httpQuery }) {
      if (!hasAccessToChannel(rootGetters, payload.cid)) return undefined;
      return clark.api.channel.thread.message.imageMetadata({ payload, httpQuery }).then((imageInfo) => imageInfo);
    },
    various({ rootGetters }, { payload, httpQuery }) {
      if (!hasAccessToChannel(rootGetters, payload.cid)) return undefined;

      return s3.api.uploadImage.various({ payload, httpQuery });
    },
    like({ rootGetters }, { payload, httpQuery }) {
      if (!hasAccessToChannel(rootGetters, payload.cid)) return undefined;

      return clark.api.channel.thread.message.like({ payload, httpQuery });
    },
    reset({ commit }) {
      commit('RESET_MESSAGE_LIST');
    },
    setHolded({ commit, getters }, { payload }) {
      const { tid, error, retry } = payload;
      const message = {
        component: 'UbuThreadMessageText',
        customId: 'SENDING#MESSAGE',
        isMe: true,
        itemDate: moment().format(),
        text: payload.message,
        threadTid: payload.tid,
        type: 'TEXT',
      };
      if (error) {
        if (error.message === 'E_EXTERNAL_ERROR_FACEBOOK_CONNECTOR') message.errorDescription = error.body.error.message;
        else message.errorDescription = 'Uh oh something unexpected has occured, please ask support !';
      }
      if (retry) {
        message.retry = retry;
      }
      const holdedMsg = { ...getters.getterHoldedMessage, ...{ [tid]: { [message.customId]: message } } };
      commit('SET_CURRENT_MESSAGE_THREADTID', tid);
      commit('SET_HOLDED', holdedMsg);
      commit('MERGE_HOLDED', tid);
    },
    deleteHolded({ commit, getters }, { payload }) {
      const holdedMsgKeysToKeep = Object.keys(getters.getterHoldedMessage).filter((msgKey) => msgKey !== payload.tid);
      const holdedMsg = Object.keys(getters.getterHoldedMessage)
        .filter((key) => holdedMsgKeysToKeep.includes(key))
        .reduce((obj, key) => ({
          ...obj,
          [key]: getters.getterHoldedMessage[key],
        }), {});
      const msgList = Object.values(getters.getterMessageList).filter((msg) => msg.customId !== 'SENDING#MESSAGE').arrayMapper('customId');
      commit('SET_HOLDED', holdedMsg);
      commit('SET_MESSAGE_LIST', msgList);
    },

    updateMessageSocket({ commit, getters }, content) {
      console.log('🚀 ~ file: thread.message.store.js ~ line 127 ~ updateMessageSocket ~ content', content);
      const messageList = content.messageList[0];
      commit('SET_MESSAGE_LIST', { ...getters.getterMessageList, [messageList.customId]: messageList });
    },

    newMessageSocket({ dispatch, commit, getters }, content) {
      // console.log('🚀 ~ file: thread.message.store.js ~ line 90 ~ newMessageSocket ~ content', content);
      dispatch('deleteHolded', { payload: { tid: content.tid } });
      commit('SET_MESSAGE_LIST', { ...getters.getterMessageList, ...content.messageList.arrayMapper('customId') });
      commit('SET_MESSAGE_LIST_TOTAL', getters.getterMessageListTotal + content.messageList.length);
    },
    deleteMessageSocket({ commit, getters }, content) {
      const customId = `${content.mid}#MESSAGE`;
      const newMessageList = Object.values(getters.getterMessageList).filter((m) => m.customId !== customId);

      commit('SET_MESSAGE_LIST', newMessageList.arrayMapper('customId'));
      commit('SET_MESSAGE_LIST_TOTAL', newMessageList.length);
    },
    up_isLoadingMoreMessagesList({ commit, getters }, { isLoading, tid }) {
      const isLoadingList = getters.getterIsLoadingMoreMessagesList;
      commit('SET_IS_LOADING_MORE_MESSAGES', { ...isLoadingList, [tid]: isLoading });
    },
  },
};
