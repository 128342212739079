<template>
  <b-tooltip
    class="ubuMenuItem"
    v-bind="item.tooltip"
    :active="!!item.tooltip && !!item.tooltip.label"
    append-to-body
  >
    <b-button
      :tag="item.path ? 'router-link' : 'button'"
      :disabled="item.disabled"
      :to="item.path"
      expanded
      :class="['is-navigation', {
        'is-tab': isTab,
        'router-link-active': isActive,
      }]"
      @click.native="handlerEvent(item.event)"
    >
      <div class="multibar row-vcentered">
        <div
          v-if="iconLoading"
          class="start icon-left"
        >
          <b-skeleton
            width="18px"
          />
        </div>
        <span
          v-else-if="item.icon"
          class="start icon-left"
        >
          <b-icon
            pack="ubu"
            :icon="item.icon"
            size="is-medium"
            :class="`has-text-color-${item.iconColor}`"
          />
        </span>
        <div
          v-else-if="item.platform"
          class="start platform-left"
        >
          <component
            :is="`UbuIcon${item.platform.capitalize()}`"
            font-size="17"
          />
        </div>

        <div
          v-if="textLoading"
          class="start itemMenuText ml-2"
          style="width: 100%;"
        >
          <b-skeleton
            width="100%"
          />
        </div>
        <span
          v-else-if="item.text"
          class="start itemMenuText text-truncate"
        >
          {{ item.text }}
        </span>

        <span
          v-if="item.hasBadge"
          class="end badge"
        >
          <b-icon
            icon="circle"
            :class="['badge', {
              'is-active': item.hasBadgeActive,
            }]"
            size="is-small"
          />
        </span>

        <b-taglist
          v-if="item.disclaimer"
          class="end itemMenuDisclaimer"
        >
          <b-tag type="is-primary">{{ item.disclaimer }}</b-tag>
        </b-taglist>

        <div
          v-if="counterLoading"
          class="ml-2 end counter"
        >
          <b-skeleton
            width="30px"
          />
        </div>
        <span
          v-else-if="item.counter"
          class="end counter mr-2"
        >
          {{ item.counter }}
        </span>

        <span
          v-if="item.hasAlert"
          class="end badge"
        >
          <b-icon
            icon="circle"
            class="badge is-active"
            size="is-small"
          />
        </span>
      </div>
    </b-button>
  </b-tooltip>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UbuMenuItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    isTab: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconLoading() {
      return this.item.loading || this.item.iconLoading;
    },
    textLoading() {
      return this.item.loading || this.item.textLoading;
    },
    counterLoading() {
      return this.item.loading || this.item.counterLoading;
    },
    isActive() {
      const { activePathList } = this.item;
      if (!activePathList) return false;
      return !!this.$route.matched.find(({ path }) => activePathList.includes(path));
    },
  },
  methods: {
    ...mapActions({
      emitSegmentEvent: 'TheMenu/emitSegmentEvent',
    }),
    handlerEvent(event) {
      if (!event) return;

      if (event === 'clickOnCampaign') this.emitSegmentEvent({ event: 'clickOnInfluenceCampaign' });
    },
  },
};
</script>
