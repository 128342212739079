<template>
  <article>
    <p
      class="text is-3 has-text-secondary"
      style="display: inline-flex; align-items: center;"
    >
      <span>
        {{ mentionItem.publication.createdOn | toDate }}
      </span>

      <span class="ml-1">|</span>

      <span class="ml-1">
        {{ mentionItem.mention.platformName | capitalize }}
      </span>

      <span class="ml-1">
        {{ mentionItem.mention.mentionType | lowercase }}
      </span>

      <span
        v-if="currentMediaRedirectUrl"
        class="ml-1"
      >|</span>

      <b-button
        v-if="currentMediaRedirectUrl"
        class="ml-1 is-ghost p-0"
        tag="a"
        label="View original post"
        :href="currentMediaRedirectUrl"
        target="blank"
        icon-pack="ubu"
        icon-right="ubu-link"
      />
    </p>

    <br>

    <p class="text is-3 has-text-secondary">{{ mentionItem.publication.text }}</p>
  </article>
</template>

<script>
export default {
  name: 'UbuMentionModalDetails',
  model: {
    prop: 'mentionItem',
  },
  props: {
    mentionItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentMediaRedirectUrl() {
      if (this.mentionItem.mention.mentionType !== 'POST') return null;
      if (this.mentionItem.mention.platformName !== 'INSTAGRAM') return null;
      if (!this.mentionItem.publication.link) return null;

      return this.mentionItem.publication.type === null
        ? `https://www.instagram.com/p/${this.mentionItem.publication.link}`
        : this.mentionItem.publication.link;
    },
  },
};
</script>
