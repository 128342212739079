/* eslint-disable max-len */
import { dispatchAndForward, genericErrorHandler } from '../../$utils/helpers';

export default {
  getters: {
    threadAndParticipantList: (_state, _getters, _rootState, rootGetters) => {
      const {
        'ChannelGroup/threadAndParticipant/getterThreadAndParticipantList': threadAndParticipantList,
      } = rootGetters;

      return threadAndParticipantList;
    },
  },
  actions: {
    reportThreadAndParticipant({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/threadAndParticipant/report',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'report threadAndParticipant list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to retrieve threads from influencers.', indefinite: true }));
    },
  },
};
