<template>
  <div
    v-if="account"
    class="ubuSettingView start"
  >
    <header class="start">
      <h2
        class="subtitle is-1 has-text-secondary m-0"
      >
        {{ account.owner.toUpperCase() }}
      </h2>
      <div class="multibar">
        <h1 class="start title is-1">Account history</h1>
      </div>
    </header>
    <DevDetails :cnt="{name: 'account', obj: account}" />
    <DevDetails :cnt="{name: 'accountEvent', obj: accountEvent}" />

    <br>

    <template v-for="item in displayInfoOptions">
      <section
        v-if="item"
        :key="item.text"
        class="pt-3 pb-3"
      >
        <div class="multibar">
          <div class="start">
            <p class="subtext has-text-secondary">{{ item.text }} :</p>
            <p class="text">{{ item.data ? item.data : '-' }}</p>
          </div>
        </div>

        <hr class="is-l">
      </section>
    </template>
    <br>

    <div class="">
      <TheTable
        v-if="accountDateSort && accountDateSort.length"
        :lead-list="accountDateSort"
        :columns="Object.values(columns)"
        :loading="loading"
        :total="accountDateSort.length"
        :page="page + 1"
        :per-page.sync="accountEvent.length"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :default-sort-order="defaultSortOrder"
        :selected.sync="localSelected"
        :checked-rows.sync="checkedRows"
        detail-key="aeid"
        :checkable="false"
        :sticky-header="false"
        :backend-sorting="false"
      />

      <UbuNoData
        v-else
        class="accountHistory"
        title="No features have been added to this account"
        subtitle="You can add rights at any time"
        action
        button-is="link"
        :to="`/admin/account/${currentAid}/details/addFeatures`"
        type="is-primary"
        label="Add features"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';
import adminAccountHistoryMixin from '../../$mixins/TheAdminHistory.columns.mixins';

export default {
  name: 'TheAdminAccountHistory',
  mixins: [adminAccountHistoryMixin],
  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      page: 0,
      perPage: 50,
      sortField: 'createdOn',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      checkedRows: [],
      isSwitchedCustom: 'Sort date ascending',
    };
  },
  computed: {
    ...mapGetters({
      accountEvent: 'TheAdmin/accountEvent',
      account: 'TheAdmin/account',
    }),
    displayInfoOptions() {
      if (!this.account) return [];
      const { aid, owner, state } = this.account;

      return [
        { text: 'Account Id', data: aid },
        { text: 'Owner', data: owner },
        { text: 'State', data: state },
      ];
    },
    accountDateSort() {
      const data = this.accountEvent.map(({ featureName, accountState, ...rest }) => ({
        ...rest,
        featureName,
        accountState,
        featureRename: featureName
          ? featureName.split('_').map((el) => el.toLowerCase()).join(' ').capitalize()
          : null,
        stateRename: accountState ? accountState.capitalize() : null,
      }));
      // const data = this.accountEvent;

      return data.sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
    },
    currentAid() {
      return this.$route.params.aid;
    },
    localSelected: {
      get() { return this.selected; },
      set(newValue) { this.$emit('update:selected', newValue); },
    },
  },
};
</script>
