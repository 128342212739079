/* eslint-disable max-len */
import { actions } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    activeTab: {
      tab: 'root',
      title: 'How do you want to add people ?',
      subtext: 'Add people',
    },
  },
  mutations: {
    SET_ACTIVE_TAB(state, newData) { state.activeTab = newData; },
    RESET_STORE(state) {
      state.activeTab = {
        tab: 'root',
        title: 'How do you want to add people ?',
        subtext: 'Add people',
      };
    },
  },
  getters: {
    activeTab: (state) => state.activeTab,

    tabItems: (state, getters, _rootState, rootGetters) => ({
      root: {
        tab: 'root',
        title: 'How do you want to add people ?',
        subtext: 'Add people',
      },
      'root.upload': {
        tab: 'root.upload',
        title: '',
        subtext: 'Add people',
      },
      'root.chrome': {
        tab: 'root.chrome',
        title: '',
        subtext: 'Add people',
      },
      ...rootGetters['TheAddPeopleManually/tabItemsForTheAddPeoplePanel'],
      ...rootGetters['TheAddPeopleProto/tabItemsForTheAddPeoplePanel'],
    }),
  },
  actions: {
    ...actions.segment,

    UP_activeTab({ commit }, newData) { commit('SET_ACTIVE_TAB', newData); },

    UP_changeToNewTab({ commit }, newData) {
      commit('SET_ACTIVE_TAB', newData);
    },
    UP_resetStore({ commit }) { commit('RESET_STORE'); },

    goToPreviousTab({ commit, getters }) {
      const { activeTab, tabItems } = getters;
      if (activeTab.tab === 'root') return;
      const tabToGo = activeTab.tab.split('.').slice(0, -1).join('.');
      commit('SET_ACTIVE_TAB', tabItems[tabToGo]);
    },

    goToTabByTabPath({ commit, getters }, tabToGo) {
      const { tabItems } = getters;
      commit('SET_ACTIVE_TAB', tabItems[tabToGo]);
    },
  },
};
