<template>
  <section
    class="ubuSettingView start"
  >
    <div class="multibar">
      <h1 class="start title is-1">Accounts</h1>
    </div>

    <br>

    <b-field grouped>
      <b-field>
        <b-dropdown
          append-to-body
          aria-role="list"
          position="is-bottom-left"
        >
          <template #trigger="{ active }">
            <b-button
              type="is-primary"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            >
              {{ currentCategorie.label }}
            </b-button>
          </template>

          <b-dropdown-item
            v-for="categorie in categorieList"
            :key="categorie.id"
            aria-role="listitem"
            @click="currentCategorie = categorie"
          >
            {{ categorie.label }}
          </b-dropdown-item>
        </b-dropdown>
      </b-field>

      <b-field expanded>
        <b-input
          v-model="inputSearch"
          placeholder="Search an account..."
          type="search"
          icon-pack="ubu"
          icon="ubu-search"
          icon-right="ubu-cross"
          icon-right-clickable
          @icon-right-click="inputSearch = null"
        />
      </b-field>
    </b-field>
    <br>

    <DevDetails :cnt="{name: 'accountList', obj: accountList}" />
    <DevDetails :cnt="{name: 'filteredAccountList', obj: filteredAccountList}" />
    <TheTable
      v-if="filteredAccountList.length"
      :lead-list="filteredAccountList"
      :columns="Object.values(columns)"
      :loading="loading"
      :total="filteredAccountList.length"
      :page="page + 1"
      :per-page.sync="perPage"
      :sort-field="sortField"
      :sort-order="sortOrder"
      :default-sort-order="defaultSortOrder"
      :selected.sync="localSelected"
      :checked-rows.sync="checkedRows"
      :detail-key="'aid'"
      :checkable="false"
      :sticky-header="false"
      @update:checkedRows="changeCheckedRows($event)"
      @update:selected="localSelected = $event"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters } from 'vuex';
import { debounce } from '@dailyplanet/utils/helpers';
import adminAccountsColumnsMixin from '../../$mixins/TheAdminAccounts.columns.mixins';

export default {
  name: 'TheAdminAccounts',
  mixins: [adminAccountsColumnsMixin],
  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      page: 0,
      perPage: 50,
      sortField: 'aid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      checkedRows: [],
      search: '',
      inputSearch: '',
      currentCategorie: { id: 'account', label: 'Account' },
      categorieList: [
        { id: 'account', label: 'Account' },
        { id: 'channel', label: 'Channels' },
        { id: 'channelGroup', label: 'Channels Group' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      accountList: 'TheAdmin/accountList',
      accountChannelGroupList: 'TheAdmin/accountChannelGroupList',
      accountChannelList: 'TheAdmin/accountChannelList',
    }),
    filteredAccountList() {
      if (!this.accountList) return [];
      if (!this.search) return Object.values(this.accountList);

      // find by ID
      if (Number.isInteger(Number(this.search))) {
        if (this.currentCategorie.id === 'account' && this.accountList[this.search]) return [this.accountList[this.search]];
        if (this.currentCategorie.id === 'channelGroup' && this.accountChannelGroupList[this.search]) return [this.accountChannelGroupList[this.search].account];
        if (this.currentCategorie.id === 'channel' && this.accountChannelList[this.search]) return [this.accountChannelList[this.search].account];
      }

      // find by name
      const filterdArray = [];
      if (this.currentCategorie.id === 'account') {
        Object.values(this.accountList).forEach((account) => {
          if (account.owner.toLowerCase().includes(this.search.toLowerCase())) filterdArray.push(account);
        });
        return filterdArray;
      }
      if (this.currentCategorie.id === 'channelGroup') {
        Object.values(this.accountChannelGroupList).forEach(({ channelGroup, account }) => {
          if (filterdArray.find(({ aid }) => aid === account.aid)) return;
          if (channelGroup.name.toLowerCase().includes(this.search.toLowerCase())) filterdArray.push(account);
        });
        return filterdArray;
      }
      if (this.currentCategorie.id === 'channel') {
        Object.values(this.accountChannelList).forEach(({ channel, account }) => {
          if (channel.name.toLowerCase().includes(this.search.toLowerCase())) filterdArray.push(account);
        });
        return filterdArray;
      }

      return [];
    },
    localSelected: {
      get() { return this.selected; },
      set(newValue) { this.$emit('update:selected', newValue); },
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 500),
  },
  mounted() {
    this.$emit('reset');
  },
  methods: {
    findChanelGroupMatch(account) {
      return account.channelGroupList.filter((channelGroup) => {
        const cgid = Number(this.search);

        if (Number.isInteger(cgid) && channelGroup.cgid === cgid) return true;
        if (channelGroup.name.toLowerCase().includes(this.search.toLowerCase())) return true;
        return false;
      });
    },
    findChanelMatch(account) {
      return account.channelGroupList.filter((channelGroup) => channelGroup.channelList.filter((channel) => {
        const cid = Number(this.search);

        if (Number.isInteger(cid) && channel.cid === cid) return true;
        if (channel.name.toLowerCase().includes(this.search.toLowerCase())) return true;
        return false;
      }).length);
    },
  },
};
</script>
