<template>
  <section class="mainPanel">
    <header class="mainPanelHeader multibar row-vcentered">
      <h1 class="title is-2 start">How to use Api Key ?</h1>
    </header>

    <div class="section">
      <p class="multibar row-vcentered">
        <span class="start">You must request an api key</span>

        <b-button
          class="start"
          type="is-ghost is-borderless"
          tag="router-link"
          to="/api/list"
          icon-pack="ubu"
          icon-left="ubu-link"
        />
      </p>

      <br>

      <p>You must add this key to your HTTP request headers:</p>

      <br>

      <div class="code-hightlighters">
        <span class="has-text-secondary">headers: { x-api-key: 'myApiKey' }</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheApiKeyHowToUseKey',
};
</script>

<style lang="scss">
.code-hightlighters {
  background-color: var(--givry);
  padding: 28px;
  border-radius: 12px;
  border: 1px solid var(--darkgrey60);
  // display: block;
}
</style>
