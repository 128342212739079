/* eslint-disable max-len */
import {
  dispatchAndForward,
  genericErrorHandler,
  // genericThenHandler,
} from '../../$utils/helpers';

export default {
  getters: {
    threadList: (_state, _getters, _rootState, rootGetters) => {
      const {
        'ApiConversation/threadList/getterThreadList': threadList,
        'ApiContact/contactPlatformOverview/getterContactPlatformOverviewList': contactPlatformOverviewList,
      } = rootGetters;

      return Object.values(threadList).map((cpid) => contactPlatformOverviewList[cpid]);
    },
    threadTidsSelectedInBulk: (_state, _getters, _rootState, rootGetters) => {
      const {
        'ApiContact/contactPlatformOverview/getterHeldThreadsTids': threadTidsSelectedInBulk,
      } = rootGetters;

      return threadTidsSelectedInBulk;
    },
    heldThreads: (_state, _getters, _rootState, rootGetters) => {
      const {
        'ApiContact/contactPlatformOverview/getterHeldThreads': heldThreads,
      } = rootGetters;

      return heldThreads;
    },
    bulkHeldThreads: (_state, _getters, _rootState, rootGetters) => {
      const {
        'ApiContact/contactPlatformOverview/getterHeldThreads': heldThreads,
        'ApiContact/contactPlatformOverview/getterHeldThreadsTids': threadTidsSelectedInBulk,
      } = rootGetters;

      return Object.values(heldThreads).filter(({ thread }) => threadTidsSelectedInBulk.includes(thread.tid));
    },
  },
  actions: {
    reportThreadList({ dispatch, rootGetters }, { httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const { search, ...restHttpQuery } = httpQuery;
      const cleanedSearch = search && search.length ? search.trim() : null;

      const parsedhttpQuery = { ...restHttpQuery };
      if (cleanedSearch && cleanedSearch.length > 3) {
        parsedhttpQuery.search = cleanedSearch;
        parsedhttpQuery.pagin = '0,50';
      }

      const action = {
        dispatch,
        target: 'ApiConversation/threadList/report',
        context: { payload: { cgid: currentChannelGroup.cgid }, httpQuery: parsedhttpQuery },
        textNotifier: 'report threadList',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report threadList.', indefinite: true }));
    },

    resetThreadList({ dispatch }) {
      const action = {
        dispatch,
        target: 'ApiConversation/threadList/reset',
        context: {},
        textNotifier: 'reset threadList',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to reset threadList.', indefinite: true }));
    },

    removeThreadFromThreadList({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'ApiConversation/threadList/removeThread',
        context: { payload },
        textNotifier: 'remove thread from threadList',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable remove thread from threadList.', indefinite: true }));
    },

    UP_toggleHeld({ dispatch }, tid) {
      // console.log('🚀 ~ file: threadList.js ~ line 68 ~ UP_toggleHeld ~ tid', tid);
      return dispatch('ApiContact/contactPlatformOverview/UP_toggleHeld', tid, { root: true });
    },
    UP_resetBulkHeldThreads({ dispatch }) {
      // console.log('🚀 ~ file: threadList.js ~ line 68 ~ UP_resetBulkHeldThreads');
      return dispatch('ApiContact/contactPlatformOverview/UP_resetBulkHeldThreads', null, { root: true });
    },
    UP_bulkHeldAllThreads({ dispatch }, threadList) {
      // console.log('🚀 ~ file: threadList.js ~ line 68 ~ UP_resetBulkHeldThreads');
      return dispatch('ApiContact/contactPlatformOverview/UP_bulkHeldAllThreads', threadList, { root: true });
    },
  },
};
