<template>
  <section>
    <b-field
      class="file is-primary"
      :class="{'has-name': !!file}"
    >
      <div class="img" />
      <b-upload
        v-model="file"
        class="file-label is-primary ml-3"
      >
        <span class="file-label">Edit workspace picture</span>
        <span
          v-if="file"
          class="file-name"
        >
          {{ file.name }}
        </span>
      </b-upload>
    </b-field>
    <b-field label="Name">
      <b-input v-model="name" />
    </b-field>
  </section>
</template>

<script>
export default {
  name: 'UbuEditWorkspace',
  data() {
    return {
      file: null,
      name: null,
    };
  },
};
</script>

<style lang="scss">
.img {
  width: 40px;
  height: 40px;
  background-color: #424242;
}
</style>
