<template>
  <section id="TheTribePeople">
    <TheCampaignCartouche
      v-model="filters"
      :allowed-filters="allowedFilters"
      :non-displayable-filters="nonDisplayableFilters"
      @input="editFilters($event)"
      @reset="setToALL_STATUS()"
    >
      <template #secondLineStart>
        <UbuDropdownCampaignActivityStatus
          v-if="selectedStatus"
          v-model="selectedStatus"
          class="start"
          :status-list="statusListWithPopulation"
          @input="handleSelectStatusFilter($event)"
        />
      </template>

      <template #secondLineEnd>
        <b-button
          label="Export"
          type="is-primary"
          icon-pack="ubu"
          icon-left="ubu-download"
          class="end"
          @click="isExportPanelOpen = true"
        />
      </template>
    </TheCampaignCartouche>

    <router-view
      v-model="checkedRows"
      :class="['mainPanelTable', { hasFilter: filters.length }]"
      :filters.sync="filters"
      :allowed-filters="allowedFilters"
      @selectContactPlatform="selectContactPlatform($event)"
      @unselectContactPlatform="selectedContactPlatform = null"
    />

    <UbuBulkActionPanelTrigger
      :bulk-is-open="bulkIsOpen"
      :checked-rows-length="localChecked.length"
      @cancel="bulkIsOpen = false; checkedRows = {};"
      @open="bulkIsOpen = true;"
    />

    <TheCampaignBulkPanel
      v-if="isBackgroundLocked"
      v-model="localChecked"
      @close="bulkIsOpen = false; checkedRows = {};"
    />

    <TheContactPlatformPanel
      v-if="selectedContactPlatform"
      v-model="selectedContactPlatform"
      is-large
      @close="selectedContactPlatform = null;"
    />

    <TheActivityPeopleExportPanel
      v-if="isExportPanelOpen"
      :campaign-cid="Number(campaign.cid)"
      @close="isExportPanelOpen = null"
    />

    <div
      v-if="isBackgroundLocked"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheCampaignPeople',
  data() {
    return {
      show: true,
      bulkIsOpen: false,
      selectedStatus: null,
      selectedContactPlatform: null,
      isExportPanelOpen: false,
      checkedRows: {},
      filters: [],
      allowedFilters: [
        'activity.status',
        'activity.createdOn',
        'contactPlatform.username',
        'contactPlatform.followerCount',
        'contactPlatformStatistic.lastMessageOn',
        'contactPlatformStatistic.hasThread',
        'contact.publicEmail',
        'campaignActivity.totalMentions',
        'campaignActivity.totalOrders',
        'campaignActivity.totalRevenues',
        'campaignActivity.totalCoupons',
        'tribeMemberList.tribeTid',
        'crewUser.state',
      ],
      nonDisplayableFilters: [
        'activity.status',
        'contactPlatform.username',
      ],
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'TheCampaign/currentCampaign',
      activityStatusListFiltered: 'TheCampaign/activityStatusListFiltered',
    }),

    isBackgroundLocked() {
      if (this.bulkIsOpen && this.localChecked.length) return true;
      return false;
    },

    statusListWithPopulation() {
      if (!this.campaign.population) return [];
      const population = this.campaign.population.arrayMapper('status');
      return [{ label: 'All people', status: 'ALL_STATUS', count: Number(this.campaign.overview.totalPeople) }]
        .concat(this.activityStatusListFiltered.map((status) => (population[status.status]
          ? { ...status, count: Number(population[status.status].count) }
          : { ...status, count: 0 })));
    },

    localChecked() { return Object.values(this.checkedRows); },
  },
  mounted() {
    this.setToALL_STATUS();
  },
  methods: {
    ...mapActions({
      getAndSetCurrentContactPlatformOverview: 'TheContactPlatformPanel/getAndSetCurrentContactPlatformOverview',
    }),

    handleSelectStatusFilter(status) {
      const filterStatus = this.filters.find(({ key }) => key === 'activity.status');
      if (filterStatus && status === 'ALL_STATUS') {
        this.filters = this.filters.filter(({ key }) => key !== 'activity.status');
        return undefined;
      }
      if (filterStatus && status !== 'ALL_STATUS') {
        this.filters = this.filters.filter(({ key }) => key !== 'activity.status');
        this.filters.push({ key: 'activity.status', values: [status], operator: 'is' });
        return undefined;
      }
      if (!filterStatus && status !== 'ALL_STATUS') {
        this.filters.push({ key: 'activity.status', values: [status], operator: 'is' });
        return undefined;
      }
      return undefined;
    },

    selectContactPlatform(cpid) {
      this.selectedContactPlatform = cpid;
      return this.getAndSetCurrentContactPlatformOverview({ payload: { cpid } });
    },

    updateCheckedRows(rows) {
      this.checkedRows = rows;
    },

    setToALL_STATUS() {
      this.selectedStatus = 'ALL_STATUS';
    },

    editFilters(newFilters) {
      if (this.selectedStatus !== 'ALL_STATUS') {
        newFilters.push({ key: 'activity.status', values: [this.selectedStatus], operator: 'is' });
      }
      this.filters = newFilters;
    },
  },
};
</script>
