<template>
  <section>
    <DevDetails :cnt="{name: 'link', obj: link}" />
    <div class="multibar">
      <h2 class="start title is-1">User detail</h2>
      <div class="end buttons">
        <b-button
          class="is-primary"
          label="Create user"
          icon-pack="ubu"
          icon-left="ubu-add"
          @click="$emit('createUser')"
        />
      </div>
    </div>

    <br>
    <form>
      <div
        v-for="item in informations"
        :key="item.section"
      >
        <div
          v-if="item"
          class="card"
        >
          <div class="card-content">
            <div class="multibar">
              <h3 class="start subtitle is-2">
                {{ item.section }}
              </h3>
            </div>

            <br>

            <fieldset>
              <div
                v-for="element in item.elements"
                :key="element.name"
              >
                <b-field
                  v-if="element"
                  :label="element.name"
                >
                  <span
                    v-if="element.input === 'text' || element.input === 'password'"
                  >
                    {{ element.value }}
                  </span>
                  <b-icon
                    v-if="element.input === 'switch'"
                    pack="ubu"
                    icon="ubu-circle-divider"
                    :class="[{
                      'has-text-success': element.value,
                      'has-text-danger': !element.value,
                    }]"
                  />
                </b-field>

                <br>
              </div>
            </fieldset>
          </div>
        </div>

        <br>
      </div>

      <div
        v-if="link.aid"
        class="card"
      >
        <div class="card-content">
          <div class="multibar">
            <h3 class="start subtitle is-2">
              User account linked informations
            </h3>
          </div>

          <br>

          <fieldset>
            <b-field label="Role">
              <span>{{ role.capitalize() }}</span>
            </b-field>

            <br>

            <b-field
              v-if="account.selected && account.selected.owner"
              label="Account name"
            >
              <span>{{ account.selected.owner.capitalize() }}</span>
            </b-field>

            <template v-if="Object.values(formatChannelsChannelGroups).length">
              <br>

              <b-field label="Give access to:">
                <div class="is-flex is-flex-direction-column">
                  <b-collapse
                    v-for="channelGroup in Object.values(formatChannelsChannelGroups)"
                    :key="channelGroup.value"
                    class="mb-3"
                  >
                    <template #trigger="props">
                      <div class="trigger multibar is-flex mb-3">
                        <b-icon
                          class="start has-text-color-5"
                          pack="ubu"
                          icon="ubu-check"
                        />
                        <p class="start">
                          {{ channelGroup.name }}
                        </p>

                        <b-icon
                          class="start"
                          pack="ubu"
                          :icon="props.open ? 'ubu-arrow-down' : 'ubu-arrow-up'"
                        />
                      </div>
                    </template>

                    <div class="is-flex is-flex-direction-column ml-5">
                      <template v-for="child in channelGroup.children">
                        <b-checkbox
                          v-if="child"
                          :key="child.value"
                          :true-value="false"
                          disabled
                        >
                          <div
                            class="trigger multibar row-vcentered"
                          >
                            <div class="start mr-1">
                              <component
                                :is="`UbuIcon${child.platformName.capitalize()}`"
                                font-size="24px"
                              />
                            </div>
                            <p class="start">
                              {{ child.name }}
                            </p>
                          </div>
                        </b-checkbox>
                      </template>
                    </div>
                  </b-collapse>
                </div>
              </b-field>

              <br>

              <b-field label="Default workspace">
                <span>{{ defaultChannelGroup.name.capitalize() }}</span>
              </b-field>
            </template>
          </fieldset>
        </div>
      </div>

      <br>

      <div class="buttons is-right">
        <b-button
          class="is-primary"
          label="Create user"
          icon-pack="ubu"
          icon-left="ubu-add"
          @click="$emit('createUser')"
        />
      </div>
    </form>
  </section>
</template>

<script>
/* eslint-disable max-len */
import iconsMixins from '@dailyplanet/cross-addons/crossCommon/$mixins/dataIcons.mixins';

export default {
  name: 'UbuSummaryAddUser',
  mixins: [iconsMixins],
  props: {
    userDetail: {
      type: Object,
      required: true,
    },
    authenticate: {
      type: Object,
      required: true,
    },
    link: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    defaultChannelGroupSelected: {
      type: Number,
      required: true,
    },
    cgids: {
      type: Array,
      required: true,
    },
    cids: {
      type: Array,
      required: true,
    },
    account: {
      type: Object,
      required: true,
    },
  },
  computed: {
    informations() {
      if (!Object.values(this.userDetail).length) return [];
      if (!this.userDetail.userRole) return [];
      if (!Object.values(this.authenticate).length) return [];

      return [
        {
          section: 'User informations',
          elements: [
            {
              name: 'First name', placeholder: 'First name', value: this.userDetail.firstName, input: 'text',
            },
            {
              name: 'Last name', placeholder: 'Last name', value: this.userDetail.lastName, input: 'text',
            },
            {
              name: 'User role', placeholder: 'User role', value: this.userDetail.userRole.label, input: 'text',
            },
            {
              name: 'Is ubu', placeholder: 'Is ubu', value: this.userDetail.isUbu, input: 'switch',
            },
          ],
        },
        {
          section: 'Authenticate informations',
          elements: [
            {
              name: 'Pass name', placeholder: 'Pass name', value: this.authenticate.passName, input: 'text',
            },
            {
              name: 'Pass phrase', placeholder: 'Pass phrase', value: this.authenticate.passPhrase, input: 'password',
            },
          ],
        },
      ];
    },
    defaultChannelGroup() {
      return this.cgids.find((channelGroup) => Number(channelGroup.value) === Number(this.defaultChannelGroupSelected));
    },
    formatChannelsChannelGroups() {
      if (!this.link.aid) return {};

      return this.cgids.reduce((acc, {
        value: cgid, name,
      }) => {
        if (!acc[cgid]) {
          acc[cgid] = {
            key: 'cgid',
            value: cgid,
            name,
            picture: null,
            initials: 'U',
            isActive: true,
            isOpen: true,
            children: this.cids.reduce((acc2, channel) => {
              if (channel.channelGroupCgid === cgid) {
                acc2.push({
                  cid: channel.value,
                  channelGroupCgid: channel.channelGroupCgid,
                  platformName: channel.platformName,
                  contactPlatformCpid: channel.contactPlatformCpid,
                  name: channel.name,
                  sensitive: null,
                  key: 'cid',
                  value: channel.value,
                  pack: 'ubu',
                  ...this.icons[channel.platformName],
                  isActive: true,
                });
              }
              return acc2;
            }, []),
          };
        }
        return acc;
      }, {});
    },
  },
};
</script>
