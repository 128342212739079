<template>
  <section>
    <article class="flex wrap space-evenly p-4">
      <UbuAnalyticCard
        v-for="(analytic, index) in analyticCards"
        :key="index"
        class="is-horizontal mb-2"
        style="width: 350px;"
        :name="analytic.name"
        :value="analytic.value"
        :icon="analytic.icon"
        :color="analytic.color"
        :symbol="analytic.symbol"
        :currency-before="analytic.currencyBefore"
      />
    </article>

    <UbuChartMixed
      v-if="sales"
      class=""
      chart-id="orders"
      :data="sales"
      title="Sales per day"
    />

    <br>

    <div
      class="flex wrap"
      style="justify-content: space-evenly;"
    >
      <UbuChartDoughnut
        v-if="countries"
        style="width: 30%;"
        class=""
        chart-id="countries"
        :data="countries"
        title="Countries distribution"
      />

      <UbuChartBar
        v-if="cities"
        v-model="shouldDisplayOthersCities"
        style="width: 50%;"
        class=""
        chart-id="cities"
        :data="cities"
        title="Location distribution"
      />
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';
import { nFormatter } from '@dailyplanet/utils/formate';

const parsedNumber = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

export default {
  name: 'TheAnalyticsAffiliationChart',
  mixins: [TableUtilsMixin],
  props: {
    filters: {
      type: Array,
      required: true,
    },
    allowedFilters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      shouldDisplayOthersCities: false,
    };
  },
  computed: {
    ...mapGetters({
      orderList: 'TheAnalytics/orderList',
      _currentShop: 'TheAnalytics/currentShop',
    }),

    selectedShopSid() {
      return this._currentShop.sid;
    },

    analyticCards() {
      if (!this.orderList) return null;

      const cards = [];

      cards.push({
        name: 'Total orders', color: 4, icon: 'ubu-shop', value: Object.values(this.orderList).length,
      });

      cards.push({
        name: 'Total revenue', color: 5, icon: 'ubu-shop', symbol: '$', currencyBefore: true, value: Object.values(this.orderList).reduce((acc, { amountUsd }) => Number(acc + (amountUsd / 100)), 0),
      });

      return cards;
    },

    sales() {
      if (!this.orderList) return null;

      const sales = Object.values(this.orderList).reduce((acc, order) => {
        const createdOn = this.$moment(order.createdOn).format('YY-MM-DD');
        if (!acc[createdOn]) {
          acc[createdOn] = {
            revenue: parsedNumber(Number(order.amountUsd) / 100),
            orders: 1,
            date: createdOn,
          };
          return acc;
        }
        acc[createdOn].revenue += parsedNumber(Number(order.amountUsd) / 100);
        acc[createdOn].orders += 1;
        return acc;
      }, {});

      const { orderList, revenueList, dateList } = Object.values(sales)
        .reduce((acc, { date, orders, revenue }) => {
          acc.revenueList.push(revenue);
          acc.dateList.push(date);
          acc.orderList.push(orders);
          return acc;
        }, { orderList: [], revenueList: [], dateList: [] });

      const datas = {
        labels: dateList,
        datasets: [
          {
            type: 'bar',
            label: 'Revenue',
            yAxisID: 'A',
            data: revenueList,
            borderColor: ['rgba(107, 206, 61, 0.4)'],
            backgroundColor: ['rgba(107, 206, 61, 0.6)'],
            tension: 0.4,
          },
          {
            type: 'bar',
            label: 'Orders',
            yAxisID: 'B',
            data: orderList,
            borderColor: ['rgba(255, 164, 113, 0.4)'],
            backgroundColor: ['rgba(255, 164, 113, 0.6)'],
            tension: 0.4,
          },
        ],
      };

      return datas;
    },
    countries() {
      if (!this.orderList) return null;

      const datas = Object.values(this.orderList).reduce((acc, order) => {
        if (!acc[order.countryCode]) {
          acc[order.countryCode] = {
            value: 1,
            countryCode: order.countryCode,
          };
          return acc;
        }
        acc[order.countryCode].value += 1;
        return acc;
      }, {});

      const bestTen = Object.values(datas)
        .sort((a, b) => b.value - a.value)
        .reduce((acc, data) => {
          acc.sum += data.value;
          if (acc.first9.length < 8) {
            acc.first9.push(data);
            return acc;
          }
          acc.others += data.value;
          return acc;
        }, { first9: [], others: 0, sum: 0 });

      return {
        labels: [...bestTen.first9.map(({ countryCode, value }) => `${countryCode} (${Math.round((value * 100) / bestTen.sum)}%)`), `others (${Math.round((bestTen.others * 100) / bestTen.sum)}%)`],
        datasets: [{
          data: [...bestTen.first9.map(({ value }) => value), bestTen.others],
          backgroundColor: [
            'rgba(255, 98, 164, 0.6)',
            'rgba(255, 96, 96, 0.6)',
            'rgba(255, 164, 113, 0.6)',
            'rgba(255, 198, 112, 0.6)',
            'rgba(107, 206, 61, 0.6)',
            'rgba(115, 218, 138, 0.6)',
            'rgba(67, 210, 193, 0.6)',
            'rgba(60, 166, 242, 0.6)',
            'rgba(111, 109, 227, 0.6)',
            'rgba(185, 141, 240, 0.6)',
            'rgba(248, 130, 236, 0.6)',
            'rgba(77, 77, 77, 0.6)',
          ],
        }],
      };
    },
    cities() {
      if (!this.orderList) return null;

      const datas = Object.values(this.orderList).reduce((acc, order) => {
        if (!acc[order.city]) {
          acc[order.city] = {
            value: 1,
            city: order.city,
          };
          return acc;
        }
        acc[order.city].value += 1;
        return acc;
      }, {});

      const bestTen = Object.values(datas)
        .sort((a, b) => b.value - a.value)
        .reduce((acc, data) => {
          if (acc.first9.length < 9) {
            acc.first9.push(data);
            return acc;
          }
          acc.others += data.value;
          return acc;
        }, { first9: [], others: 0 });

      const cities = bestTen.first9.map(({ city }) => city);
      const values = bestTen.first9.map(({ value }) => value);

      if (this.shouldDisplayOthersCities && bestTen.others > 0) {
        cities.push('others');
        values.push(bestTen.others);
      }

      return {
        labels: cities,
        datasets: [{
          data: values,
          backgroundColor: ['rgba(255, 164, 113, 0.6)'],
          maxBarThickness: 150,
        }],
      };
    },
  },
  watch: {
    filters() {
      return this.reportOrders();
    },

    selectedShopSid() {
      return this.reportOrders();
    },
  },
  mounted() {
    this.reportOrders();
  },
  methods: {
    ...mapActions({
      _reportOrderList: 'TheAnalytics/reportOrderList',
    }),

    reportOrders() {
      const filtersToUse = [...this.filters];

      return this._reportOrderList({
        payload: { shopSid: this.selectedShopSid },
        httpQuery: {
          linkedOnly: true,
          filters: this.prepareFiltersToRequest(this.allowedFilters, filtersToUse),
        },
      });
    },
  },
};
</script>
