import { use, registerComponent } from '@dailyplanet/utils/plugins';

/* eslint-disable no-multi-spaces */

import UbuAddPeopleDone from './UbuAddPeopleDone';
import UbuAddPeopleProtoSelectList from './UbuAddPeopleProtoSelectList';
import UbuCreateCRMListForm from './UbuCreateCRMListForm';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuAddPeopleDone);
    registerComponent(Vue, UbuAddPeopleProtoSelectList);
    registerComponent(Vue, UbuCreateCRMListForm);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuAddPeopleDone,
  UbuAddPeopleProtoSelectList,
  UbuCreateCRMListForm,
};
