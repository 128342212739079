import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheTextarea from './TheTextarea';
import TheTextareaTemplateButton from './smarts/TheTextareaTemplateButton';
import TheTextareaEmojiButton from './smarts/TheTextareaEmojiButton';
import TheTextareaAffiliationButton from './smarts/TheTextareaAffiliationButton';
import TheCustomisableTextarea from './smarts/TheCustomisableTextarea';
import TheTextareaSendButton from './smarts/TheTextareaSendButton';
import TheTextareaCrewInviteButton from './smarts/TheTextareaCrewInviteButton';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheTextarea);
    registerComponent(Vue, TheTextareaTemplateButton);
    registerComponent(Vue, TheTextareaEmojiButton);
    registerComponent(Vue, TheTextareaAffiliationButton);
    registerComponent(Vue, TheCustomisableTextarea);
    registerComponent(Vue, TheTextareaSendButton);
    registerComponent(Vue, TheTextareaCrewInviteButton);
  },
};

use(Plugin);

export default Plugin;

export {
  TheTextarea,
  TheTextareaTemplateButton,
  TheTextareaEmojiButton,
  TheTextareaAffiliationButton,
  TheCustomisableTextarea,
  TheTextareaSendButton,
  TheTextareaCrewInviteButton,
};
