<template>
  <div class="multibar">
    <div class="start multibar row-vcentered">
      <UbuAvatar
        class="start initials mr-3"
        :size="18"
        :initial-name="`${user.firstName[0]}${user.lastName[0]}`.toUpperCase()"
      />

      <p class="start text is-3 text-truncate has-text-secondary">
        {{ user.firstName }} {{ user.lastName }}
      </p>
    </div>

    <b-icon
      v-if="isChecked"
      :class="`has-text-color-success end`"
      pack="ubu"
      icon="ubu-check"
    />
  </div>
</template>

<script>
export default {
  name: 'UbuDropdownUserRow',
  props: {
    user: {
      type: Object,
      required: true,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
