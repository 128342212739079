/* eslint-disable no-multi-spaces */
import TheSetting from './TheSetting';
import TheSettingTheme from './TheSettingTheme';

export default [
  {
    path: '/setting',
    name: 'Settings',
    component: TheSetting,
    meta: {
      title: 'Settings',
      hasMenu: true,
      viewStoreNavigation: 'TheSetting',
      requireAuth: true,
    },
    children: [
      {
        path: '/setting/theme',
        name: 'TheSettingTheme',
        component: TheSettingTheme,
        meta: {
          title: 'Theme',
          hasMenu: true,
          viewStoreNavigation: 'TheSetting',
          requireAuth: true,
        },
      },
    ],
  },
];
