/* eslint-disable max-len */
// import { clark } from '@dailyplanet/providers';
// import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    logbookList: null,
  },
  mutations: {
    SET_LOGBOOK_LIST(state, newData) { state.logbookList = { ...state.logbookList, ...newData }; },
    RESET_STORE(state) {
      state.logbookList = null;
    },
  },
  getters: {
    getterLogbookList: (state) => state.logbookList,
  },
  actions: {},
};
