/* eslint-disable max-len */
import BulkActionEngineStore from '@dailyplanet/data-stores/engine-stores/bulkAction';

export default {
  namespaced: true,
  state: {
    ...BulkActionEngineStore.state,
  },
  mutations: {
    ...BulkActionEngineStore.mutations,
  },
  getters: {
    ...BulkActionEngineStore.getters,

    tabItems: (state, getters, _rootState, rootGetters) => ({
      root: {
        tab: 'root',
        title: 'Bulk actions',
        subtext: 'Bulk actions',
      },
      'root.assignLabel.done': {
        tab: 'root.assignLabel.done',
        title: 'Labels',
        subtext: 'Labels',
      },
      'root.assignTag.done': {
        tab: 'root.assignTag.done',
        title: 'Tags',
        subtext: 'Tags',
      },
      'root.assignUser.done': {
        tab: 'root.assignUser.done',
        title: 'Assignee',
        subtext: 'Assignee',
      },

      ...rootGetters['TheSendInstantMessage/tabItemsForTheInboxBulk'],
      ...rootGetters['TheAddToCampaign/tabItemsForTheCrmBulk'],
      ...rootGetters['TheAddToTribe/tabItemsForTheCrmBulk'],
      // ...rootGetters['TheBulkAssignUser/tabItemsForTheInboxBulk'],
      // ...rootGetters['TheBulkTag/tabItemsForTheInboxBulk'],
      // ...rootGetters['TheBulkAddToCampaign/tabItemsForTheInboxBulk'],
      // ...rootGetters['TheBulkAddToTribe/tabItemsForTheInboxBulk'],
    }),
  },
  actions: {
    ...BulkActionEngineStore.actions,
  },
};
