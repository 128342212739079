<template>
  <div
    id="TheMenu"
    :class="['ubuMenu', {
      'is-reduced': isReduced
    }]"
  >
    <b-collapse
      v-if="currentApp === 'selina'"
      v-model="isChannelSelectorOpen"
      animation="slide"
      class="start channelSelector"
    >
      <template #trigger="props">
        <b-button
          class="is-navigation"
          expanded
        >
          <div class="multibar row-vcentered">
            <UbuAvatar
              :initial-name="currentChannelGroup.name.charAt(0).capitalize()"
              class="start ml-1"
              :size="18"
              :profile-picture="currentChannelGroup.picture"
              :is-size="16"
              is-channel
            />
            <span
              class="channelName start text is-3 ml-2 text-truncate"
            >
              {{ currentChannelGroup.name }}
            </span>

            <b-icon
              class="end"
              pack="ubu"
              :icon="props.open ? 'ubu-arrow-up' : 'ubu-arrow-down'"
            />
          </div>
        </b-button>
      </template>
      <b-button
        v-for="(cg) in myChannelGroups.filter((cg) => cg.cgid !== currentChannelGroup.cgid)"
        :key="cg.cgid"
        expanded
        class="is-navigation"
        @click="changeChannelGroup(cg)"
      >
        <div class="multibar row-vcentered">
          <UbuAvatar
            :initial-name="cg.name.charAt(0).capitalize()"
            class="ml-1 start"
            :size="18"
            :profile-picture="cg.picture"
            :is-size="16"
            is-channel
          />
          <span class="start ml-2 text-truncate">
            {{ cg.name }}
          </span>
        </div>
      </b-button>
      <b-button
        expanded
        class="is-navigation"
        @click="openIntercom()"
      >
        <div class="multibar row-vcentered">
          <span class="start icon-left">
            <b-icon
              pack="ubu"
              icon="ubu-add"
              class="start"
              size="is-medium"
            />
          </span>
          <span class="start ml-1 text-truncate">
            Add workspace
          </span>
        </div>
      </b-button>
    </b-collapse>

    <hr class="start">

    <div class="start menu-item-row menu-item-burger-reduced">
      <UbuMenuItem :item="{ icon: 'ubu-menu' }" />
    </div>
    <div class="start menu-item-row menu-item-burger">
      <UbuMenuItem
        v-for="(item) in apps[currentApp]"
        :key="item.path"
        :item="item"
        is-tab
      />
    </div>

    <hr class="start">

    <template v-if="menuItems && menuItems.length">
      <div class="start menu-item-list">
        <UbuMenuItem
          v-for="(item) in menuItems"
          :key="item.path && item.path.path ? item.path.path : item.path"
          :item="item"
        />
      </div>

      <hr class="start">
    </template>

    <template v-if="activeMenuItems && activeMenuItems.length">
      <div class="start menu-item-list">
        <template v-for="item in activeMenuItems">
          <UbuMenuItem
            v-if="item && !item.isEnded"
            :key="item.path && item.path.path ? item.path.path : item.path"
            :item="item"
          />
        </template>
      </div>

      <hr class="start">
    </template>

    <template v-if="groupedActiveMenuItems && Object.keys(groupedActiveMenuItems).length">
      <div
        v-for="group in Object.keys(groupedActiveMenuItems)"
        :key="group"
        class="start"
      >
        <UbuMenuItemList
          :grouped-active-menu-items="groupedActiveMenuItems[group]"
        />

        <hr
          v-if="groupedActiveMenuItems[group] && groupedActiveMenuItems[group].length"
          class="start"
        >
      </div>
    </template>
    <span class="end">
      <div class="multibar row-vcentered">
        <!-- <span class="end">
          {{ windowWidth }}
        </span> -->
        <b-button
          v-if="currentEnv === 'dev'"
          icon-pack="mdi"
          :icon-left="isActive ? 'unicorn-variant' : 'emoticon-poop'"
          class="end is-ghost is-large is-borderless"
          @click="toggleActiveDevDetails(!isActive)"
        />

        <b-button
          v-if="currentEnv === 'dev'"
          icon-pack="mdi"
          icon-left="table-tennis"
          :class="['end', 'is-large', 'is-outlined', { 'is-success': socketIsConnected}]"
          @click="connect(!socketIsConnected)"
        />
      </div>
    </span>
    <!-- <span class="end">
      <TheNotifierLast class="start" />
    </span> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SocketMixin from '@dailyplanet/mixins/SocketMixin';

export default {
  name: 'TheMenu',
  mixins: [SocketMixin],
  data() {
    return {
      isChannelSelectorOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      windowWidth: 'TheMenu/getterWindowWidth',
      isReduced: 'TheMenu/getterIsReduced',
      menuItems: 'TheMenu/menuItems',
      activeMenuItems: 'TheMenu/activeMenuItems',
      groupedActiveMenuItems: 'TheMenu/groupedActiveMenuItems',
      myAuthenticate: 'TheMenu/myAuthenticate',
      myChannelGroups: 'TheMenu/myChannelGroups',
      myCurrentChannelGroup: 'TheMenu/currentChannelGroup',
      isActive: 'DevDetails/isActive',
      isPrivateDisconnected: 'TheAuthenticate/isPrivateDisconnected',
      accountFeatures: 'TheAuthenticate/accountFeatures',
    }),
    apps() {
      return {
        selina: [
          {
            path: '/home',
            icon: 'ubu-home',
            activePathList: [
              '/setting',
            ],
            tooltip: {
              position: 'is-bottom',
              label: 'Home',
              type: 'is-info',
            },
            hasAlert: this.accountFeatures('UBU_META_EXTENSION') && this.isPrivateDisconnected,
          },
          {
            path: '/inbox',
            icon: 'ubu-inbox',
            tooltip: {
              position: 'is-bottom',
              label: 'Inbox',
              type: 'is-info',
            },
          },
          {
            path: '/activity',
            icon: 'ubu-activity',
            tooltip: {
              position: 'is-bottom',
              label: 'Activity',
              type: 'is-info',
            },
          },
          {
            path: '/influence',
            icon: 'ubu-influence',
            activePathList: [
              '/crm',
              '/audit',
            ],
            tooltip: {
              position: 'is-bottom',
              label: 'Community',
              type: 'is-info',
            },
          },
          {
            path: '/comments',
            icon: 'ubu-comment',
            tooltip: {
              position: 'is-bottom',
              label: 'Comments',
              type: 'is-info',
            },
          },
        ],
        kara: [
          {
            path: '/home',
            icon: 'ubu-home',
            tooltip: {
              position: 'is-bottom',
              label: 'Home',
              type: 'is-info',
            },
          },
          {
            path: '/monitoring',
            icon: 'ubu-overview',
            tooltip: {
              position: 'is-bottom',
              label: 'Monitoring',
              type: 'is-info',
            },
          },
          {
            path: '/setting',
            icon: 'ubu-settings',
            tooltip: {
              position: 'is-bottom',
              label: 'Settings',
              type: 'is-info',
            },
          },
        ],
      };
    },

    currentChannelGroup: {
      get() { return this.myCurrentChannelGroup; },
      // set(channelGroup) { this.up_currentChannelGroup(channelGroup); },
    },

    currentApp() {
      return process.env.VUE_APP_NAME;
    },
    currentEnv() {
      return process.env.VUE_APP_MODE;
    },
  },

  mounted() {
    this.UP_windowWidth(window.innerWidth);
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    ...mapActions({
      UP_windowWidth: 'TheMenu/UP_windowWidth',
      UP_route: 'TheMenu/UP_route',
      UP_channelGroup: 'TheMenu/UP_channelGroup',
      toggleActiveDevDetails: 'DevDetails/toggleActiveDevDetails',
      clearStores: 'sequence/clearStores',
    }),
    onResize() {
      return this.UP_windowWidth(window.innerWidth);
    },
    changeChannelGroup(cg) {
      return Promise.resolve()
        .then(() => this.UP_channelGroup(cg))
        // socket & stores are clean up by refresh browser
        .then(() => { window.location.assign('/'); });
    },
    openIntercom() {
      this.$intercom.showNewMessage('Hi! I would like to add a new workspace! 🙌');
    },
  },
};
</script>
