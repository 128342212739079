<template>
  <div>
    <div class="multibar row-vcentered">
      <span class="start subtext has-text-secondary">{{ title }}</span>
      <b-switch
        v-if="localIsSwitched !== null"
        v-model="localIsSwitched"
        class="end subtext has-text-secondary"
        size="is-small"
      >
        Show others
      </b-switch>
    </div>

    <canvas :id="chartId" />
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import { optionBarSimple, calculateStepYAxis } from '../_utils/chartJSParams';

export default {
  name: 'UbuChartBar',
  model: {
    prop: 'isSwitched',
  },
  props: {
    chartId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      required: true,
    },
    isSwitched: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      baseOptions: optionBarSimple,
      calculateStepYAxis,
    };
  },
  computed: {
    localIsSwitched: {
      get() { return this.isSwitched; },
      set(val) { return this.$emit('input', val); },
    },
  },
  watch: {
    data() {
      if (this.currentChart) this.currentChart.destroy();

      const ctx = document.getElementById(this.chartId);

      this.baseOptions.options.scales.y.ticks.stepSize = this.calculateStepYAxis(this.data);

      this.currentChart = new Chart(ctx, { ...this.baseOptions, data: this.data });
    },
  },
  mounted() {
    const ctx = document.getElementById(this.chartId);

    this.baseOptions.options.scales.y.ticks.stepSize = this.calculateStepYAxis(this.data);

    this.currentChart = new Chart(ctx, { ...this.baseOptions, data: this.data });
  },
};
</script>
