<template>
  <section
    v-if="checkedRowsLength"
    class="ubuBulkAction"
  >
    <div class="ubuBulkActionContent">
      <div class="content">
        <div class="ubuBulkActionContentSelected">
          <slot name="details" />
          <p>{{ checkedRowsLength }} selected</p>
        </div>
        <div class="divider-horizontal" />
        <div class="buttons">
          <b-button
            type="is-secondary"
            label="Cancel"
            @click="$emit('cancel')"
          />
          <b-button
            type="is-primary"
            label="Bulk actions"
            :disabled="bulkIsOpen"
            @click="$emit('open')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UbuBulkActionPanelTrigger',
  props: {
    bulkIsOpen: {
      type: Boolean,
      default: false,
    },
    checkedRowsLength: {
      type: Number,
      default: 0,
    },
  },
};
</script>
