<template>
  <section class="ubuEditCustom is-detailed">
    <form @submit.prevent="updateContactGroup()">
      <UbuEditCustomForm
        v-model="localCustom"
        type="CONTACT LABEL"
      />

      <br>

      <b-field label="ACTIONS">
        <div class="buttons">
          <b-button
            type="is-success"
            icon-pack="ubu"
            native-type="submit"
            label="Save changes"
            :disabled="!canSave"
          >
            Save changes
          </b-button>

          <b-button
            type="is-danger"
            icon-pack="ubu"
            icon-left="ubu-trash"
            @click="deleteContactGroup(item)"
          >
            Delete
          </b-button>
        </div>
      </b-field>
    </form>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSettingLabelDetails',
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      custom: {
        label: null,
        color: '1',
      },
    };
  },
  computed: {
    ...mapGetters({
      contactGroupList: 'TheSettingWorkspaceSetting/contactGroupList',
    }),
    localCustom: {
      get() { return this.custom.label ? this.custom : this.item; },
      set(val) { this.custom = val; },
    },
    canSave() {
      const { id, name, ...restCustom } = this.localCustom;
      if (JSON.stringify(restCustom) === JSON.stringify(this.contactGroupList[this.item.cgid])) return false;
      return true;
    },

  },
  methods: {
    ...mapActions({
      _updateContactGroup: 'TheSettingWorkspaceSetting/updateContactGroup',
      _deleteContactGroup: 'TheSettingWorkspaceSetting/deleteContactGroup',
    }),
    updateContactGroup() {
      return this._updateContactGroup({
        payload: {
          cgid: this.item.id,
          color: this.localCustom.color,
          label: this.localCustom.label,
          createdOn: this.item.createdOn,
          deletedOn: this.item.deletedOn,
        },
      });
    },
    deleteContactGroup({ id }) {
      this._deleteContactGroup({ payload: { cgid: Number(id) } });
    },
  },
};
</script>
