<template>
  <div>
    <b-field label="List name">
      <b-input
        v-model="localCrmList.name"
        placeholder="My list..."
        required
        expanded
        @input="$update('name', $event)"
      />
    </b-field>

    <br>

    <UbuColorPickerInline
      v-model="localCrmList.color"
      class="mb-2"
      @input="$update('color', String($event))"
    />
  </div>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuCRMListForm',
  mixins: [VModelMixin],
  model: {
    prop: 'crmList',
  },
  props: {
    crmList: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localCrmList: {
      get() { return { ...this.crmList }; },
      set(val) { this.$emit('input', val); },
    },
  },
};
</script>
