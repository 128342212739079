<template>
  <b-tab-item>
    <section class="ubuWizardView">
      <header>
        <p class="arianne">Add workspace - Step 2/2</p>

        <h1 class="title">Add channels</h1>
      </header>

      <p class="subtext has-text-secondary">
        You need to connect at least one channel to finish creating your workspace.
        Don’t worry, you can still add other channels later!
      </p>

      <br>

      <UbuActionCard
        v-for="item in platformList"
        :key="item.name"
        class="mb-4"
        :label="item.name"
        :social-icon="item.icon"
        expanded
      />

      <br>

      <div class="buttons is-right">
        <b-button
          label="Back"
          type="is-secondary"
          tag="router-link"
          to="/settings/organization"
        />

        <b-button
          label="Create new workspace"
          type="is-info"
          @click="choiceChannel()"
        />
      </div>
    </section>
  </b-tab-item>
</template>

<script>

export default {
  name: 'UbuAddWorkspaceChannelTab',
  model: {
    prop: 'newWorkspace',
  },
  props: {
    newWorkspace: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      platformList: [
        { name: 'Instagram', icon: 'instagram' },
        { name: 'Facebook', icon: 'facebook' },
        { name: 'TikTok', icon: 'tik-tok' },
      ],
    };
  },
};
</script>

<style>

</style>
