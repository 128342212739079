import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheTemplateManager from './TheTemplateManager';
import TheTemplateManagerSimple from './TheTemplateManagerSimple';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheTemplateManager);
    registerComponent(Vue, TheTemplateManagerSimple);
  },
};

use(Plugin);

export default Plugin;

export {
  TheTemplateManager,
  TheTemplateManagerSimple,
};
