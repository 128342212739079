import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuInvitationLink from './UbuInvitationLink';
import UbuTemplateForm from './UbuTemplateForm';
import UbuUserInfosForm from './UbuUserInfosForm';
import UbuUserRightsForm from './UbuUserRightsForm';
import UbuUserReviewForm from './UbuUserReviewForm';
import UbuOrganizationMembers from './UbuOrganizationMembers';
import UbuEditWorkspace from './UbuEditWorkspace';
import UbuAddWorkspaceInfoTab from './UbuAddWorkspaceInfoTab';
import UbuAddWorkspaceChannelTab from './UbuAddWorkspaceChannelTab';
import UbuWorkspaceInfoForm from './UbuWorkspaceInfoForm';
import UbuGoogleDriveConnectRow from './UbuGoogleDriveConnectRow';
import UbuPrivateConnectRow from './UbuPrivateConnectRow';
import UbuShopifyConnectRow from './UbuShopifyConnectRow';
import UbuEditCustomForm from './UbuEditCustomForm';
import UbuEditTemplateForm from './UbuEditTemplateForm';
import UbuCardHome from './UbuCardHome';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuCardHome);
    registerComponent(Vue, UbuInvitationLink);
    registerComponent(Vue, UbuTemplateForm);
    registerComponent(Vue, UbuUserInfosForm);
    registerComponent(Vue, UbuUserRightsForm);
    registerComponent(Vue, UbuUserReviewForm);
    registerComponent(Vue, UbuOrganizationMembers);
    registerComponent(Vue, UbuEditWorkspace);
    registerComponent(Vue, UbuAddWorkspaceInfoTab);
    registerComponent(Vue, UbuAddWorkspaceChannelTab);
    registerComponent(Vue, UbuWorkspaceInfoForm);
    registerComponent(Vue, UbuGoogleDriveConnectRow);
    registerComponent(Vue, UbuPrivateConnectRow);
    registerComponent(Vue, UbuShopifyConnectRow);
    registerComponent(Vue, UbuEditCustomForm);
    registerComponent(Vue, UbuEditTemplateForm);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuCardHome,
  UbuInvitationLink,
  UbuTemplateForm,
  UbuUserInfosForm,
  UbuUserRightsForm,
  UbuUserReviewForm,
  UbuOrganizationMembers,
  UbuEditWorkspace,
  UbuAddWorkspaceInfoTab,
  UbuAddWorkspaceChannelTab,
  UbuWorkspaceInfoForm,
  UbuGoogleDriveConnectRow,
  UbuPrivateConnectRow,
  UbuShopifyConnectRow,
  UbuEditCustomForm,
  UbuEditTemplateForm,
};
