<template>
  <fieldset>
    <b-field
      horizontal
      label="Website URL"
    >
      <b-input
        v-model="newShop.baseUrl"
        required
      />
    </b-field>
    <b-field
      horizontal
      label="Shop extref ID"
    >
      <b-input
        v-model="newShop.extrefId"
        required
      />
    </b-field>
    <b-field
      horizontal
      label="Shop platform"
    >
      <b-input
        v-model="newShop.type"
        required
      />
    </b-field>
    <b-field
      horizontal
      label="API Version"
    >
      <b-input
        v-model="newShop.apiVersion"
        required
      />
    </b-field>
    <b-field
      horizontal
      label="API Key"
    >
      <b-input
        v-model="newShop.apiKey"
        required
      />
    </b-field>
    <b-field
      horizontal
      label="Password"
    >
      <b-input
        v-model="newShop.password"
        required
      />
    </b-field>
    <b-field
      horizontal
      label="Shared Secret"
    >
      <b-input
        v-model="newShop.sharedSecret"
        required
      />
    </b-field>
  </fieldset>
</template>

<script>
export default {
  name: 'UbuKaraShopifyInformationsShop',
  model: {
    prop: 'newShop',
  },
  props: {
    newShop: { type: Object, default: () => {} },
  },
};
</script>
