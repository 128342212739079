<template>
  <section class="ubuSearchInstagramUser">
    <b-message type="is-classic">
      <div class="multibar row-vcentered">
        <b-icon
          pack="ubu"
          icon="ubu-information"
          class="start mr-4"
          size="is-large"
        />
        <div class="start">
          <p class="text mb-2">Important 👇</p>
          <p class="subtext is-3 mb-2">
            Some Instagram accounts have blocked the possibility to view their community.
            Please check that the account you want hasn’t blocked that feature by clicking
            on “Followers” on their Instagram page, and making sure all of their followers
            are visible and the list is not limited.
          </p>
          <p class="subtext is-3">
            <span>If their followers list is limited, </span>
            <a
              class="has-text-primary no-decoration"
              @click="openIntercom()"
            >
              contact support
            </a>
            <span> to get similar accounts.</span>
          </p>
        </div>
      </div>
    </b-message>
    <b-input
      v-model="localSearch"
      :loading="isLoading"
      class="mb-5"
      placeholder="Search Instagram username..."
      type="search"
      icon="ubu-search"
      icon-pack="ubu"
      icon-right="ubu-cross"
      icon-right-clickable
      @icon-right-click="$emit('input', '')"
    />
    <div
      v-for="user in users"
      :key="user.username"
      :class="['ubuSearchInstagramUser-card', 'multibar', 'row-vcentered', 'spaced-right', 'mb-4', {
        'isDisable': isAlreadyInProtolist(user) || onlyPublic && user.isPrivate,
      }]"
    >
      <UbuThreadListUserPicture
        class="start"
        :profile-picture="`https://io.getubu.com/profile_pic_insta/${user.extrefPk}`"
        :initial-name="user.username"
      />

      <div class="start">
        <div class="text is-3">
          {{ user.name }}
          <b-icon
            v-if="!!user.isCertified"
            pack="ubu"
            icon="ubu-success"
          />
        </div>
        <div class="subtext">
          @{{ user.username }}
        </div>
      </div>

      <div
        v-if="isAlreadyInProtolist(user)"
        class="disableText end"
      >
        <p class="text has-text-secondary">{{ disableText }}</p>
      </div>
      <div
        v-else-if="onlyPublic && user.isPrivate"
        class="disableText end"
      >
        <p class="subtext">
          Private account
        </p>
      </div>

      <div class="end">
        <b-button
          label="select"
          :disabled="isAlreadyInProtolist(user) || (onlyPublic && user.isPrivate)"
          @click="$emit('select', user)"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UbuSearchInstagramUser',
  model: {
    prop: 'search',
  },
  props: {
    search: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    disableUsername: {
      type: Array,
      default: () => [],
    },
    disableText: {
      type: String,
      default: '',
    },
    onlyPublic: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  computed: {
    localSearch: {
      get() { return this.search; },
      set(val) { this.$emit('input', val); },
    },
  },
  methods: {
    isAlreadyInProtolist(user) {
      if (!this.disableUsername.length) return false;
      return this.disableUsername.includes(user.username);
    },
    openIntercom() {
      this.$intercom.showNewMessage('Hello, I need help to request a new list of people, the account I wanted to target hide their list of followers.');
    },
  },
};
</script>
