<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="multibar is-align-items-center">
      <b-button
        v-if="activeTab > 0 && activeTab < 3"
        class="start"
        icon-pack="ubu"
        icon-left="ubu-arrow-left"
        label="Previous step"
        type="is-ghost"
        @click="activeTab = activeTab - 1"
      />
      <b-button
        :to="routeForPreviousButton"
        class="end is-large is-simple-icon"
        icon-pack="ubu"
        icon-left="ubu-cross"
        tag="router-link"
      />
    </div>

    <b-tabs
      v-model="activeTab"
      class="no-headers"
    >
      <b-tab-item>
        <UbuUserInfosForm
          v-model="newUser"
          @next="activeTab += 1"
        />
      </b-tab-item>

      <b-tab-item>
        <UbuUserRightsForm
          v-model="role"
          :editable-item="editableItem"
          @updateGroup="updateGroup($event)"
          @next="activeTab += 1"
          @back="activeTab -= 1"
        />
      </b-tab-item>

      <b-tab-item>
        <UbuUserReviewForm
          :key="activeTab"
          :user="newUser"
          :role="role"
          :editable-item="localEditable"
          :error="error"
          @back="activeTab -= 1"
          @addMember="addMember()"
          @removeError="error = false"
        />
      </b-tab-item>

      <b-tab-item>
        <section class="ubuWizardView">
          <header>
            <p class="has-text-secondary">Add member</p>

            <h1 class="title">Success !</h1>
          </header>

          <p class="has-text-secondary">
            Added members will receive their login information via email.
          </p>

          <br>

          <div class="buttons is-right">
            <b-button
              label="Add new member"
              type="is-secondary"
              @click="addNewMember()"
            />
            <b-button
              label="Back to workspace"
              type="is-primary"
              tag="router-link"
              :to="routeForPreviousButton"
            />
          </div>
        </section>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
/* eslint-disable max-len */
import iconsMixins from '@dailyplanet/cross-addons/crossCommon/$mixins/dataIcons.mixins';
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

import moment from 'moment';

export default {
  name: 'TheSettingAddMember',
  mixins: [iconsMixins],
  data() {
    return {
      activeTab: 0,
      error: false,
      newUser: {
        extra: null,
        isUbu: true,
        authenticate: {
          passName: null,
          passPhrase: uuidv4(),
          method: 'UBU',
          createdOn: null,
          updatedOn: null,
          allowedOn: null,
          revokedOn: null,
        },
        userDetail: {
          firstName: null,
          lastName: null,
          picture: null,
          email: null,
          phone: null,
        },
        setting: {
          theme: 'AUTO',
          defaultChannelGroupCgid: null,
          defaultAccountAid: null,
        },
      },
      role: 'member',
      editableItem: {},
    };
  },
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheSettingWorkspaceSetting/myAuthenticate',
      account: 'TheSettingWorkspaceSetting/account',
    }),

    channelGroups() {
      if (!this.account || !this.account.channelGroupList) return this.setEditableItem({});

      const channelGroups = this.account.channelGroupList.reduce((acc, {
        cgid, name, picture, channelList,
      }) => {
        acc[cgid] = {
          channelGroup: {
            cgid,
            name,
            picture,
            isActive: false,
            initials: name.split(' ').slice(0, 2).map((el) => el.charAt(0).toUpperCase()).join(' '),
            value: cgid,
          },
          channelList: channelList.reduce((channels, channel) => {
            if (channel.channelGroupCgid === cgid && !channels[channel.cid]) {
              return {
                ...channels,
                [channel.cid]: {
                  ...channel,
                  isActive: false,
                },
              };
            }

            return channels;
          }, {}),
        };
        return acc;
      }, {});

      return this.setEditableItem(channelGroups);
    },
    localEditable() {
      return this.channelGroups;
    },
    routeForPreviousButton() {
      const { path } = this.$route;
      const pathList = path
        // transform string to array
        .split('/')
        // remove empty [0] and last element [-1]
        .slice(1, -1);

      return { path: `/${pathList.join('/')}`, query: this.$route.query };
    },
  },
  methods: {
    ...mapActions({
      createUser: 'TheSettingOrganizationSetting/createUser',
      reportAccountMemberView: 'TheSettingOrganizationSetting/reportAccountMemberView',
      // getAccount: 'TheSettingsOrganization/getAccount',
    }),

    setEditableItem(newData) {
      this.editableItem = newData;
      return newData;
    },

    updateGroup(event) {
      this.editableItem[event.channelGroup.cgid] = event;
    },

    addMember() {
      const { setting: { defaultAccountAid: aid } } = this.myAuthenticate;

      this.error = false;

      const authenticate = new Array({
        ...this.newUser.authenticate,
        createdOn: moment().format(),
        updatedOn: moment().format(),
        oneTime: true,
      });

      const channelGroups = Object.values(this.channelGroups)
        .reduce((acc, { channelGroup, channelList }) => {
          if (!channelGroup.isActive) return acc;

          const data = {
            cgid: channelGroup.cgid,
            channels: Object.values(channelList).filter(({ isActive }) => isActive)
              .map((channel) => ({ cid: channel.cid })),
          };

          // if (data.channels.length)
          acc.push(data);

          return acc;
        }, []);

      const payload = {
        extra: null,
        isUbu: true,
        lockedOn: null,
        userDetail: {
          ...this.newUser.userDetail,
          email: this.newUser.authenticate.passName,
        },
        authenticate,
        setting: {
          ...this.newUser.setting,
          defaultAccountAid: aid,
        },
        isAdminOnAccount: this.role === 'admin' ? aid : null,
        link: {
          aid,
          channelGroups,
        },
        hasRole: [{ userRoleUrid: 'ACCOUNT_USER' }],
      };

      this.createUser({ payload, httpQuery: { isStub: true } })
        .then(() => { this.activeTab += 1; })
        .then(() => this.reportAccountMemberView({}))
        .catch(() => { this.error = true; });
    },

    addNewMember() {
      this.activeTab = 0;
      this.error = false;
      this.role = 'member';
      this.newUser = {
        extra: null,
        isUbu: true,
        authenticate: {
          passName: null,
          passPhrase: uuidv4(),
          method: 'UBU',
          createdOn: null,
          updatedOn: null,
          allowedOn: null,
          revokedOn: null,
        },
        userDetail: {
          firstName: null,
          lastName: null,
          picture: null,
          email: null,
          phone: null,
        },
        setting: {
          theme: 'AUTO',
          defaultChannelGroupCgid: null,
          defaultAccountAid: null,
        },
      };
    },
  },
};
</script>
