/* eslint-disable max-len */
export default {
  namespaced: true,
  getters: {
    tabItemsForTheTribeBulk: () => ({
      'root.sendMessage': {
        tab: 'root.sendMessage',
        title: 'Compose',
        subtext: 'Send message',
      },
      'root.sendMessage.done': {
        tab: 'root.sendMessage.done',
        title: 'Complete',
        subtext: 'Send message',
      },
    }),
    tabItemsForTheInboxBulk: () => ({
      'root.sendMessage': {
        tab: 'root.sendMessage',
        title: 'Compose',
        subtext: 'Send message',
      },
      'root.sendMessage.done': {
        tab: 'root.sendMessage.done',
        title: 'Complete',
        subtext: 'Send message',
      },
    }),
  },
};
