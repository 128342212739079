<template>
  <TheCampaignChip
    v-if="cpo.lastActivity && !cpo.lastActivity.endedOn"
    :cpo="cpo"
  />

  <div
    v-else
    class="ubuCustomChip"
  >
    -
  </div>
</template>

<script>
export default {
  name: 'UbuTableChipLastCampaign',
  props: {
    cpo: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
