<template>
  <b-table
    :data="datas"
    class="table-row-clickable"
    @click="$emit('editTemplate', $event)"
  >
    <b-table-column
      v-slot="props"
      field="title"
      label="Template"
      :sortable="true"
    >
      <p
        class="text-truncate"
        style="max-width: 400px;"
      >
        {{ props.row.title }}
      </p>

      <p
        class="text-truncate subtext is-3 has-text-secondary"
        style="max-width: 400px;"
      >
        {{ props.row.text }}
      </p>
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="folderName"
      label="Folder"
      :sortable="true"
    >
      <p
        v-if="props.row.folderName"
        class="text-truncate"
        style="max-width: 400px;"
      >
        {{ props.row.folderName }}
      </p>
      <p v-else>
        -
      </p>
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="updatedOn"
      label="Last edited"
      :sortable="true"
    >
      <p v-if="props.row.updatedOn">
        {{ props.row.updatedOn | toDate }}
      </p>
      <p v-else>
        -
      </p>
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="createdOn"
      label="Date created"
      :sortable="true"
    >
      <p>
        {{ props.row.createdOn | toDate }}
      </p>
    </b-table-column>

    <template #empty>
      <p class="has-text-secondary">No template found...</p>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'TheTemplateManagerSimpleTable',
  model: {
    prop: 'datas',
  },
  props: {
    datas: {
      type: Array,
      required: true,
    },
  },
};
</script>
