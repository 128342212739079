<template>
  <section>
    <div class="multibar">
      <h2 class="start title is-3">Confirmation shop details</h2>

      <div class="end buttons">
        <b-button
          class="is-primary"
          label="Add shopify shop"
          icon-pack="ubu"
          icon-left="ubu-add"
          @click="$emit('addShop')"
        />
      </div>
    </div>

    <br>

    <form>
      <div
        v-for="item in informations"
        :key="item.section"
      >
        <div
          v-if="item"
          class="card"
        >
          <div class="card-content">
            <div class="multibar">
              <h3 class="start subtitle is-2">
                {{ item.section }}
              </h3>
            </div>

            <br>

            <fieldset>
              <div
                v-for="element in item.elements"
                :key="element.name"
              >
                <b-field
                  v-if="element"
                  :label="element.name"
                >
                  <b-input
                    v-if="element.input === 'text' || element.input === 'password'"
                    v-model="element.value"
                    :placeholder="element.placeholder"
                    :type="element.input"
                    :password-reveal="element.input === 'password' ? true : false"
                    readonly
                    required
                  />
                  <b-switch
                    v-if="element.input === 'switch'"
                    v-model="element.value"
                    disabled
                    required
                  />
                </b-field>

                <br>
              </div>
            </fieldset>
          </div>
        </div>

        <br>
      </div>

      <br>

      <div class="buttons is-right">
        <b-button
          class="is-primary"
          label="Add shopify shop"
          icon-pack="ubu"
          icon-left="ubu-add"
          @click="$emit('addShop')"
        />
      </div>
    </form>
  </section>
</template>

<script>
export default {
  name: 'UbuKaraShopifyShopConfirmation',
  model: {
    prop: 'newShop',
  },
  props: {
    newShop: { type: Object, default: () => {} },
    channelGroup: { type: Object, default: () => {} },
    account: { type: [Object, String], default: () => {} },
  },
  computed: {
    informations() {
      if (typeof this.account !== 'object') return [];
      if (!Object.values(this.channelGroup).length) return [];
      if (!Object.values(this.newShop).length) return [];

      return [
        {
          section: 'Account selected',
          elements: [
            {
              name: 'Account ID', placeholder: 'Account ID', value: this.account.aid, input: 'text',
            },
            {
              name: 'Account owner', placeholder: 'Account owner', value: this.account.owner, input: 'text',
            },
            {
              name: 'Account state', placeholder: 'Account state', value: this.account.state, input: 'text',
            },
          ],
        },
        {
          section: 'Workspace selected',
          elements: [
            {
              name: 'Workspace ID', placeholder: 'Workspace ID', value: this.channelGroup.cgid, input: 'text',
            },
            {
              name: 'Workspace name', placeholder: 'Workspace name', value: this.channelGroup.name, input: 'text',
            },
          ],
        },
        {
          section: 'Shopify shop informations',
          elements: [
            {
              name: 'Website URL', placeholder: 'Website URL', value: this.newShop.baseUrl, input: 'text',
            },
            {
              name: 'Shop extref ID', placeholder: 'Shop extref ID', value: this.newShop.extrefId, input: 'text',
            },
            {
              name: 'Shop platform', placeholder: 'Shop platform', value: this.newShop.type, input: 'text',
            },
            {
              name: 'API Version', placeholder: 'API Version', value: this.newShop.apiVersion, input: 'text',
            },
            {
              name: 'API Key', placeholder: 'API Key', value: this.newShop.apiKey, input: 'text',
            },
            {
              name: 'Password', placeholder: 'Password', value: this.newShop.password, input: 'text',
            },
            {
              name: 'Shared Secret', placeholder: 'Shared Secret', value: this.newShop.sharedSecret, input: 'text',
            },
          ],
        },
      ];
    },
  },
};
</script>
