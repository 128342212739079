<template>
  <b-modal
    v-model="localIsModalOpen"
    full-screen
    scroll="keep"
    custom-class="ubuMentionModal"
    :can-cancel="['escape']"
    @close="localIsModalOpen = false"
  >
    <div
      v-if="currentMention"
      class="coreModal flex"
    >
      <UbuMentionModalMediaPanel
        v-model="currentMention"
        @prev="prev()"
        @next="next()"
      />
      <TheTabsPanel
        v-model="localActiveTab"
        @goToPreviousTab="goToPreviousTab()"
        @goToStart="goToTabByTabPath('root')"
        @close="localIsModalOpen = false"
      >
        <template #customHeader>
          <div
            v-if="activeTab.tab === 'root'"
            class="multibar row-vcentered"
          >
            <div class="start">
              <UbuAvatar
                :profile-picture="currentMention.contactPlatform.profilePicture"
                :initial-name="currentMention.contactPlatform.username.charAt(0).capitalize()"
              />
            </div>
            <div class="start ml-2 multibar column p-0">
              <p class="start text is-3">
                {{ currentMention.contactPlatform.username }}
              </p>
              <p class="end subtext is-3 has-text-secondary">
                {{ currentMention.contactPlatform.followerCount | nFormatter }} followers
              </p>
            </div>
          </div>
        </template>

        <template #content>
          <b-tab-item value="root">
            <UbuMentionModalDetails v-model="currentMention" />

            <br>

            <UbuMentionModalNavigation
              v-model="currentMention"
              @downloadMentions="downloadTaskSelected({ [currentMention.pid]: currentMention })"
              @changeTab="handleNavigation(navigableItems[$event])"
              @toggleFavorite="toggleFavorite()"
            />
            <UbuMentionModalAnalytics v-model="currentMention" />
          </b-tab-item>

          <b-tab-item value="root.downloadMentions">
            <TheDownloadMentions
              v-model="allExtrefIds"
              @done="goToTabByTabPath('root')"
            />
          </b-tab-item>

          <TheSendInstantMessage
            :checked-rows="[currentMention]"
            :active-tab="localActiveTab"
            :tab-items="tabItems"
            :dropdown-position="{
              emoji: 'is-top-right',
              template: 'is-top-right',
            }"
            @update:activeTab="gotToTabByTabObject($event)"
          />
        </template>
      </TheTabsPanel>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import DownloadTaskMixin from '@dailyplanet/mixins/DownloadTaskMixin';

export default {
  name: 'TheMentionModal',
  mixins: [DownloadTaskMixin],
  model: {
    prop: 'isModalOpen',
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    mentionList: {
      type: Array,
      required: true,
    },
    inititalMention: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: null,
      activeTab: {
        tab: 'root',
        title: 'What do you want to do ?',
        subtext: 'Bulk actions',
      },

      message: '',
      tgid: null,
    };
  },
  computed: {
    ...mapGetters({
      accountFeatures: 'TheMention/accountFeatures',
    }),
    currentMention() {
      // console.log('🚀 ~ file: TheMentionModal.vue ~ line 96 ~ currentMention ~ this.mentionList[this.activeIndex]', this.mentionList[this.activeIndex]);
      return this.mentionList[this.activeIndex];
    },
    allMedias() {
      const { carousel, mainMedia } = this.currentMention;

      return [mainMedia, ...carousel];
    },
    allExtrefIds() {
      return this.allMedias.map(({ extrefId }) => ({ extrefId }));
    },
    localIsModalOpen: {
      get() { return this.isModalOpen; },
      set(newValue) { this.$emit('input', newValue); },
    },
    localActiveTab: {
      get() { return { ...this.activeTab }; },
      set(newValue) {
        console.log('🚀 ~ file: TheMentionModal.vue ~ line 121 ~ set ~ newValue', newValue);
      },
    },
    tabItems() {
      return {
        root: {
          tab: 'root',
          title: 'What do you want to do ?',
          subtext: 'Bulk actions',
        },
        'root.downloadMentions': {
          tab: 'root.downloadMentions',
          title: 'Enter your email to receive your download link',
          subtext: `Download mentions | ${this.allMedias.length} mentions selected`,
        },
        'root.sendMessage': {
          tab: 'root.sendMessage',
          title: 'Compose',
          subtext: 'Send message',
        },
        'root.sendMessage.done': {
          tab: 'root.sendMessage.done',
          title: 'Complete',
          subtext: 'Send message',
        },
      };
    },
    navigableItems() {
      return {
        'root.downloadMentions': {
          label: 'Download mentions',
          to: 'root.downloadMentions',
          requiredFeature: 'UBU_META_EXTENSION',
          segmentEvent: 'downloadMentionNoFeature',
        },
        'root.sendMessage': {
          label: 'Compose message',
          to: 'root.sendMessage',
        },
      };
    },
  },
  mounted() {
    this.activeIndex = this.mentionList.findIndex(({ mid }) => mid === this.inititalMention.mid);
  },
  methods: {
    ...mapActions({
      _favMentions: 'TheMention/favMentions',
      _unfavMention: 'TheMention/unfavMention',
      emitSegmentEvent: 'TheMention/emitSegmentEvent',
    }),
    goToPreviousTab() {
      if (this.activeTab.tab === 'root') return;
      const tabToGo = this.activeTab.tab.split('.').slice(0, -1).join('.');
      if (tabToGo === 'root') {
        this.message = '';
        this.tgid = null;
      }
      this.activeTab = this.tabItems[tabToGo];
    },
    handleNavigation(item) {
      if (item.requiredFeature && !this.accountFeatures(item.requiredFeature)) {
        this.emitSegmentEvent({ event: item.segmentEvent });
        this.$intercom.showNewMessage('Hi! I would like to activate the Ubu\'s Meta Extension! 🙌');

        return;
      }

      this.activeTab = { ...this.tabItems[item.to] };
    },
    gotToTabByTabObject(event) {
      this.activeTab = event;
    },
    goToTabByTabPath(path) {
      this.activeTab = this.tabItems[path];
    },
    prev() {
      if (this.activeIndex === 0) this.activeIndex = this.mentionList.length - 1;
      else this.activeIndex -= 1;
    },
    next() {
      if (this.activeIndex === this.mentionList.length - 1) this.activeIndex = 0;
      else this.activeIndex += 1;
    },
    toggleFavorite() {
      // if (this.currentMention.favorite.publicationPid) return this._unfavMention({ payload: { pid: this.currentMention.pid } });
      // return this._favMentions({ payload: { pids: [this.currentMention.pid] } });
      if (this.currentMention.favorite.publicationPid) {
        return this._unfavMention({
          payload: { pid: this.currentMention.pid },
          mutations: [{
            target: 'ApiContact/contactPlatformOverview/UP_favMentionList',
            payload: {
              pids: [this.currentMention.pid],
              shouldFav: false,
              cpids: [this.currentMention.cpid],
            },
          }],
        });
      }
      return this._favMentions({
        payload: { pids: [this.currentMention.pid] },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_favMentionList',
          payload: {
            pids: [this.currentMention.pid],
            shouldFav: true,
            cpids: [this.currentMention.cpid],
          },
        }],
      });
    },
  },
};
</script>

<style >
.is-active .al img {
    filter: grayscale(0%);
}
.al img {
    filter: grayscale(100%);
}

</style>
