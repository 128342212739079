<template>
  <b-field grouped>
    <b-input
      v-model="localValue[0]"
      placeholder=""
      expanded
    />
  </b-field>
</template>

<script>
export default {
  name: 'UbuFilterInput',
  model: {
    prop: 'values',
  },
  props: {
    values: {
      type: Array,
      required: true,
    },
  },
  computed: {
    localValue: {
      get() { return this.values; },
      set(val) { this.$emit('input', val); },
    },
  },
};
</script>
