<template>
  <section class="mainPanel">
    <template v-if="_currentShop && _currentShop.sid">
      <TheAffiliationCartouche
        v-model="filters"
        :selected-shop-sid.sync="_currentShop.sid"
        :allowed-filters="allowedFilters"
        :non-displayable-filters="nonDisplayableFilters"
        @input="filters = $event"
      >
        <template #actionsEnd>
          <b-button
            label="Add affiliation"
            :disabled="!_currentShop || !_currentShop.sid"
            type="is-primary"
            icon-pack="ubu"
            icon-left="ubu-add"
            class="end ml-2"
            @click="isCreateAffiliationCodeOpen = true"
          />

          <b-button
            label="Export"
            :disabled="!_currentShop || !_currentShop.sid"
            type="is-primary"
            icon-pack="ubu"
            icon-left="ubu-download"
            class="end ml-2"
            @click="isExportPanelOpen = true"
          />
        </template>
      </TheAffiliationCartouche>

      <TheAffiliationCodeOverviewTable
        v-if="_currentShop.sid"
        :filters.sync="filters"
        :allowed-filters="allowedFilters"
        @selectContactPlatform="selectContactPlatform($event)"
        @editAffiliation="openEditAffiliationCodePanel($event)"
      />

      <TheContactPlatformPanel
        v-if="selectedContactPlatform"
        v-model="selectedContactPlatform"
        is-large
        @close="selectedContactPlatform = null;"
      />

      <TheEditAffiliationCodePanel
        v-if="selectedAffiliationCode"
        :affiliation-code="selectedAffiliationCode"
        @close="selectedAffiliationCode = null"
      />

      <TheCreateAffiliationCodePanel
        v-if="isCreateAffiliationCodeOpen"
        @close="isCreateAffiliationCodeOpen = null"
      />

      <TheAffiliationCodeExportPanel
        v-if="isExportPanelOpen"
        @close="isExportPanelOpen = null"
      />

      <div
        v-if="isBackgroundLocked"
        class="overlayWithLock"
      />
    </template>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSettingCoupon',
  data() {
    return {
      selectedContactPlatform: null,
      selectedAffiliationCode: null,
      isCreateAffiliationCodeOpen: false,
      isExportPanelOpen: false,

      filters: [],
      allowedFilters: [
        'affiliationCode.type',
        'affiliationCode.affiliationCodeFolderAcfid',
        'affiliationCode.campaignCid',
        'totalOrders',
        'totalRevenue',
        'affiliationCode.createdOn',
        'search',
        'shortLink.clickCount',
      ],
      nonDisplayableFilters: ['search'],
    };
  },
  computed: {
    ...mapGetters({
      _currentShop: 'TheSettingWorkspaceSetting/currentShop',
      _affiliationCodeOverviewList: 'TheSettingWorkspaceSetting/affiliationCodeOverviewList',
      _totalAffiliationCodeOverview: 'TheSettingWorkspaceSetting/totalAffiliationCodeOverview',
      _campaignList: 'TheSettingWorkspaceSetting/campaignList',
      _affiliationCodeFolderList: 'TheSettingWorkspaceSetting/affiliationCodeFolderList',
    }),

    isBackgroundLocked() {
      return this.selectedAffiliationCode || this.isCreateAffiliationCodeOpen || this.isExportPanelOpen;
    },
  },
  methods: {
    ...mapActions({
      reportAffiliationCodeOverviewList: 'TheSettingWorkspaceSetting/reportAffiliationCodeOverviewList',
      getAndSetCurrentContactPlatformOverview: 'TheContactPlatformPanel/getAndSetCurrentContactPlatformOverview',
    }),

    selectContactPlatform(cpid) {
      this.selectedContactPlatform = cpid;
      return this.getAndSetCurrentContactPlatformOverview({ payload: { cpid } });
    },

    openEditAffiliationCodePanel(affiliationCode) { this.selectedAffiliationCode = affiliationCode; },
  },
};
</script>
