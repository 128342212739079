<template>
  <div class="inboxSidebar">
    <TheThreadListFilters
      v-model="httpQuery"
      :loading="isLoading"
      @input="updateHttpQueryIfChanged($event)"
    />
    <TheThreadList
      v-model="httpQuery"
      :page.sync="page"
      :loading="isLoading"
    />
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import SocketMixin from '@dailyplanet/mixins/SocketMixin';
import ThreadListMixin from '@dailyplanet/mixins/ThreadListMixin';

export default {
  name: 'TheInboxSidebar',
  mixins: [SocketMixin, ThreadListMixin],
  data() {
    return {
      isLoading: false,
      httpQuery: {
        pagin: [0, 50],
      },
      page: 0,
      // perPage: 50,
    };
  },
  computed: {
    ...mapGetters({
      currentChannelGroup: 'Authenticate/getterCurrentChannelGroup',
    }),
  },
  mounted() {
    // console.error('🚀 ~ file: TheInboxSidebar.vue ~ line 101 ~ mounted ~ mounted');
    const newHttpQuery = {
      open: 'true',
      pagin: [0, 50],
      sortOptions: 'newest',
      displayOptions: 'all',
    };

    const {
      threadByCampaigns,
      threadByTags,
      threadByLabels,
      threadByAppoints,
      threadByTribes,
      inboxType,
    } = this.$route.params;

    if (this.$route.meta.isCommonThreadBox) {
      if (inboxType !== 'all' && !threadByAppoints) newHttpQuery.displayOptions = 'received';
      // if (this.httpQuery.search) delete newHttpQuery.open;
    } else {
      delete newHttpQuery.open;
    }

    if (threadByCampaigns) newHttpQuery.threadByCampaigns = threadByCampaigns;
    if (threadByTags) newHttpQuery.threadByTags = threadByTags;
    if (threadByLabels) newHttpQuery.threadByLabels = threadByLabels;
    if (threadByAppoints) newHttpQuery.threadByAppoints = threadByAppoints;
    if (threadByTribes) newHttpQuery.threadByTribes = threadByTribes;
    if (inboxType) newHttpQuery[inboxType] = 'true';

    this.httpQuery = newHttpQuery;
    // this.loadThreadList(this.httpQuery)
    //   .then(() => this.updateRouteWithQueries({ path: this.$route.path, queries: this.httpQuery }));
    return Promise.resolve()
      .then(() => this.updateRouteWithQueries({ path: this.$route.path, queries: this.httpQuery }))
      .then(() => this.loadThreadList(this.httpQuery));
  },

  beforeDestroy() {
    this.unsubscribeBoxes(this.httpQuery);
  },
  methods: {
    ...mapActions({
      reportThreadList: 'TheInbox/reportThreadList',
      resetThreadList: 'TheInbox/resetThreadList',
      _UP_resetBulkHeldThreads: 'TheInbox/UP_resetBulkHeldThreads',
      up_currentThreadAndHeld: 'TheInbox/up_currentThreadAndHeld',
    }),
    updateHttpQueryIfChanged(queries) {
      const { open, sortOptions, displayOptions } = queries;

      const {
        threadByCampaigns,
        threadByTags,
        threadByLabels,
        threadByAppoints,
        threadByTribes,
        inboxType,
      } = this.$route.params;

      const isInCustomBox = !!threadByTags || !!threadByLabels || !!threadByCampaigns || !!threadByTribes;

      // const { pagin, ...restHttpQuery } = this.httpQuery;
      if (JSON.stringify(queries) !== JSON.stringify({ open: this.httpQuery.open, sortOptions: this.httpQuery.sortOptions, displayOptions: this.httpQuery.displayOptions })) {
        this.httpQuery = {
          ...this.httpQuery,
          sortOptions,
          displayOptions,
        };

        if (!this.httpQuery.search) {
          if (threadByCampaigns) this.httpQuery.threadByCampaigns = threadByCampaigns;
          if (threadByTags) this.httpQuery.threadByTags = threadByTags;
          if (threadByLabels) this.httpQuery.threadByLabels = threadByLabels;
          if (threadByAppoints) this.httpQuery.threadByAppoints = threadByAppoints;
          if (threadByTribes) this.httpQuery.threadByTribes = threadByTribes;
          if (inboxType) this.httpQuery[inboxType] = 'true';
        }

        if (open) this.httpQuery.open = open;
        if (!open && !isInCustomBox) this.httpQuery.displayOptions = 'all'; // override for showAll button
        this.page = 0;

        return Promise.resolve()
          .then(() => this.updateRouteWithQueries({ path: this.$route.path, queries: this.httpQuery }))
          .then(() => this.loadThreadList(this.httpQuery));
      }
      return Promise.resolve();
    },
    updateRouteWithQueries({ path, queries }) {
      this.$router.push({ path, query: queries }).catch((err) => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== 'NavigationDuplicated'
          && !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // But print any other errors to the console
          console.log(err);
        }
      });
    },

    loadThreadList(httpQuery) {
      // show all (open kept in url, removed from backend request)
      return this.resetThreadList()
        .then(() => this._UP_resetBulkHeldThreads())
        .then(() => this.subscribeBoxes(httpQuery))
        .then(() => { this.isLoading = true; })
        .then(() => this.reportThreadList({ httpQuery }))
        .then(() => {
          this.isLoading = false;
          this.$emit('threadListInitialized');

          // this.$nextTick(() => {
          if (!this.threadList.length) return undefined;

          const routeTid = this.$route.params.tid;

          // No thread in url we select the first thread on thread list
          if (!routeTid) return this.goToThread(this.threadList[0]);

          const matchingThread = this.threadList.find(({ tid }) => String(tid) === String(routeTid));

          // There is a thread in url but it doesn't exists in current thread list, we select the first thread on thread list
          // if (routeTid && !matchingThread) return this.triggerClickOnThread(this.threadList[0].tid);

          // There is a thread in url, it exists in current thread list, and it is different than the thread we are currently looking, we select this one
          if (!matchingThread) return this.goToThread(this.threadList[0]);
          if (matchingThread && routeTid !== matchingThread.tid) return this.goToThread(matchingThread);
          if (matchingThread && routeTid === matchingThread.tid) return this.up_currentThreadAndHeld(routeTid);

          return undefined;
          // });
        });
    },
    subscribeBoxes(query) {
      const { cgid } = this.currentChannelGroup;
      const boxes = [];

      if (query.all) boxes.push('threadBox.common.all');
      if (query.primary) boxes.push('threadBox.common.primary');
      if (query.importants) boxes.push('threadBox.common.important');
      if (query.opportunities) boxes.push('threadBox.common.opportunity');
      if (query.mentions) boxes.push('threadBox.common.mention');

      if (query.threadByTags) boxes.push(`threadBox.tag.${query.threadByTags}`);
      if (query.threadByLabels) boxes.push(`threadBox.label.${query.threadByLabels}`);
      if (query.threadByCampaigns) boxes.push(`threadBox.campaign.${query.threadByCampaigns}`);
      if (query.threadByAppoints) boxes.push(`threadBox.appoint.${query.threadByAppoints}`);

      this.bulkSubscribeStream(cgid, boxes);

      return boxes;
    },
    unsubscribeBoxes(query) {
      const { cgid } = this.currentChannelGroup;
      const boxes = [];

      if (query.inboxType) boxes.push(`threadBox.common.${query.inboxType}`);

      if (query.all) boxes.push('threadBox.common.all');
      if (query.primary) boxes.push('threadBox.common.primary');
      if (query.importants) boxes.push('threadBox.common.important');
      if (query.opportunities) boxes.push('threadBox.common.opportunity');
      if (query.mentions) boxes.push('threadBox.common.mention');

      if (query.threadByTags) boxes.push(`threadBox.tag.${query.threadByTags}`);
      if (query.threadByLabels) boxes.push(`threadBox.label.${query.threadByLabels}`);
      if (query.threadByCampaigns) boxes.push(`threadBox.campaign.${query.threadByCampaigns}`);
      if (query.threadByAppoints) boxes.push(`threadBox.appoint.${query.threadByAppoints}`);

      this.bulkUnsubscribeStream(cgid, boxes);
    },
  },
};
</script>
