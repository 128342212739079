import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    autoReportTask: null,
  },
  mutations: {
    SET_AUTO_REPORT_TASK(state, newData) { state.autoReportTask = newData; },
    RESET_STORE(state) {
      state.autoReportTask = null;
    },
  },
  getters: {
    getterAutoReportTask: (state) => state.autoReportTask,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'AUTO_REPORT.REPORT_SUBSCRIBED')) return undefined;

      return clark.api.apiPublication.autoReport.report({
        payload,
        httpQuery: { ...httpQuery, isStub: true },
      })
        .then((taskList) => {
          const { __response__ } = taskList;

          if (!__response__.length) return;

          const { _id, params, status } = __response__[0];

          const preparedTask = {
            tid: _id,
            ...params,
            status,
          };

          commit('SET_AUTO_REPORT_TASK', preparedTask);
        });
    },

    create({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'AUTO_REPORT.SUBSCRIBE')) return undefined;

      return clark.api.apiPublication.autoReport.post({ payload, httpQuery })
        .then(() => {
          const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

          return dispatch('report', { payload: { cgid: currentChannelGroup.cgid } });
        });
    },

    delete({ commit, rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'AUTO_REPORT.UNSUBSCRIBE')) return undefined;

      return clark.api.apiPublication.autoReport.delete({ payload, httpQuery })
        .then(() => {
          commit('SET_AUTO_REPORT_TASK', null);
        });
    },
  },
};
