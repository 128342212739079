<template>
  <section class="ubuSettingView">
    <div class="multibar row-vcentered">
      <h1 class="start title is-2">Tags</h1>

      <b-button
        class="end"
        type="is-primary"
        label="Add new tag"
        tag="router-link"
        to="/setting/tag/addTag"
      />
    </div>

    <br>

    <UbuSearchbar
      v-model="search"
      placeholder="Search tag..."
      @clear="search = ''"
    />

    <br>

    <UbuCustomTable
      v-if="customList && customList.length"
      :customs="customList"
      icon-name="ubu-custom-tag"
    >
      <template #detail="props">
        <TheSettingTagDetails
          :key="`detail-${props.row.id}`"
          :item="props.row"
          :has-inbox-feature="_hasInboxFeature"
          @hasPanelOpen="hasPanelOpen = !hasPanelOpen"
        />
      </template>
    </UbuCustomTable>

    <UbuNoData
      v-else
      :title="contentNoData.title"
      :subtitle="contentNoData.subtitle"
      class="SettingTagNoData"
      :action="contentNoData.action"
      :button-is="contentNoData.buttonIs"
      :to="contentNoData.to"
      :type="contentNoData.type"
      :label="contentNoData.label"
      :event-name="contentNoData.eventName"
      @resetSearch="search = ''"
    />

    <router-view name="addTag" />

    <div
      v-if="$route.meta.lockBody || hasPanelOpen"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheSettingTag',
  mixins: [FilterEngineMixin],
  data() {
    return {
      search: '',
      hasPanelOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      threadGroupList: 'TheSettingWorkspaceSetting/threadGroupList',
      accountFeatures: 'TheSettingWorkspaceSetting/accountFeatures',
    }),
    customList() {
      const threadGroupList = Object.values(this.threadGroupList)
        .map((r) => ({ ...r, id: r.tgid, name: r.label }));

      if (!threadGroupList || !threadGroupList.length) return [];

      const filtersToApply = {};
      if (this.search) filtersToApply.searchByLabelName = this.search;

      return this.filterEngine(threadGroupList, filtersToApply)
        .sort((a, b) => (this.$moment(b.createdOn) - this.$moment(a.createdOn)));
    },
    _hasInboxFeature() {
      return this.accountFeatures('INBOX');
    },
    contentNoData() {
      if (this.search) {
        return {
          title: 'We cannot find any tags that match your search',
          subtitle: 'You can create this tag at any time, or do a new search',
          action: true,
          buttonIs: 'event',
          eventName: 'resetSearch',
          type: 'is-primary',
          label: 'Reset search',
        };
      }

      return {
        title: 'No tag were found... Yet!',
        subtitle: 'You can create a tag at any time!',
        action: true,
        buttonIs: 'link',
        to: '/setting/tag/addTag',
        type: 'is-primary',
        label: 'Add new tag',
      };
    },

  },
};
</script>
