/* eslint-disable max-len */

import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    activitiesStats: null,
    mentionsStats: null,
    salesStats: null,
  },
  mutations: {
    SET_ACTIVITIES_STATS(state, newData) { state.activitiesStats = newData; },
    SET_MENTIONS_STATS(state, newData) { state.mentionsStats = newData; },
    SET_SALESS_STATS(state, newData) { state.salesStats = newData; },
    RESET_STORE(state) {
      state.activitiesStats = null;
      state.mentionsStats = null;
      state.salesStats = null;
    },
  },
  getters: {
    getter_activitiesStats: (state) => state.activitiesStats,
    getter_mentionsStats: (state) => state.mentionsStats,
    getter_salesStats: (state) => state.salesStats,
  },
  actions: {
    campaignAnalytics({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CAMPAIGN_ANALYTICS.REPORT')) return undefined;

      return clark.api.apiInfluence.campaignAnalytics.report({ payload, httpQuery })
        .then((analytics) => {
          if (httpQuery.activitiesStats) commit('SET_ACTIVITIES_STATS', analytics);
          if (httpQuery.mentionsStats) commit('SET_MENTIONS_STATS', analytics);
          if (httpQuery.salesStats) commit('SET_SALESS_STATS', analytics);

          return analytics;
        });
    },
  },
};
