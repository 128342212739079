/* eslint-disable max-len */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    accountMemberViewList: {},
  },
  mutations: {
    SET_ACCOUNT_MEMBER_VIEW_LIST(state, newData) { state.accountMemberViewList = newData; },
    RESET_STORE(state) {
      state.accountMemberViewList = null;
    },
  },
  getters: {
    getterAccountMemberViewList: (state) => state.accountMemberViewList,
  },
  actions: {
    report({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'ACCOUNT_MEMBER_VIEW.REPORT')) return undefined;

      return clark.api.account.accountMemberView.report({ payload, httpQuery: {} })
        .then((accountMemberViewList) => { commit('SET_ACCOUNT_MEMBER_VIEW_LIST', accountMemberViewList.arrayMapper('uid')); });
    },
    up_linkChannelGroup({ commit, getters }, { toBeLinked, uid }) {
      const newAccountMemberViewList = getters.getterAccountMemberViewList;

      if (!newAccountMemberViewList[uid].activeChannelGroup) newAccountMemberViewList[uid].activeChannelGroup = toBeLinked;
      else toBeLinked.forEach((cgid) => newAccountMemberViewList[uid].activeChannelGroup.push(cgid));

      commit('SET_ACCOUNT_MEMBER_VIEW_LIST', newAccountMemberViewList);
    },
    up_unlinkChannelGroup({ commit, getters }, { toBeUnlinked, uid }) {
      const newAccountMemberViewList = getters.getterAccountMemberViewList;

      const activeChannelGroup = newAccountMemberViewList[uid].activeChannelGroup.reduce((acc, cgid) => {
        if (toBeUnlinked.includes(cgid)) return acc;
        acc.push(cgid);
        return acc;
      }, []);

      newAccountMemberViewList[uid].activeChannelGroup = activeChannelGroup;
      commit('SET_ACCOUNT_MEMBER_VIEW_LIST', newAccountMemberViewList);
    },
    up_linkChannel({ commit, getters }, { toBeLinked, uid }) {
      const newAccountMemberViewList = getters.getterAccountMemberViewList;

      if (!newAccountMemberViewList[uid].activeChannel) newAccountMemberViewList[uid].activeChannel = toBeLinked;
      else toBeLinked.forEach((cid) => newAccountMemberViewList[uid].activeChannel.push(cid));

      commit('SET_ACCOUNT_MEMBER_VIEW_LIST', newAccountMemberViewList);
    },
    up_unlinkChannel({ commit, getters }, { toBeUnlinked, uid }) {
      const newAccountMemberViewList = getters.getterAccountMemberViewList;

      const activeChannel = newAccountMemberViewList[uid].activeChannel.reduce((acc, cid) => {
        if (toBeUnlinked.includes(cid)) return acc;
        acc.push(cid);
        return acc;
      }, []);

      newAccountMemberViewList[uid].activeChannel = activeChannel;
      commit('SET_ACCOUNT_MEMBER_VIEW_LIST', newAccountMemberViewList);
    },
  },
};
