/* eslint-disable max-len */
import deepcopy from 'deepcopy';

export default {
  state: {
    currentViewThread: {},
    heldThreads: {},
    heldThreadsCpids: [],
    heldThreadsTids: [],
  },
  mutations: {
    MERGE_CURRENT_HELD(state) {
      const { cpid } = state.currentViewThread;

      const heldThread = deepcopy(state.heldThreads[cpid]);
      console.log('🚀 ~ file: heldThreads.js ~ line 16 ~ MERGE_CURRENT_HELD ~ heldThread', heldThread);

      if (!heldThread) return;
      if (state.heldThreadsTids.includes(heldThread.tid)) return;

      state.contactPlatformOverviewList = { ...state.contactPlatformOverviewList, [heldThread.cpid]: heldThread };

      const newHeldThreads = Object.values(state.heldThreads).filter((row) => row && String(row.cpid) !== String(cpid)).arrayMapper('cpid');
      console.log('🚀 ~ file: heldThreads.js ~ line 23 ~ MERGE_CURRENT_HELD ~ newHeldThreads', newHeldThreads);

      state.heldThreads = newHeldThreads;
      state.heldThreadsCpids = state.heldThreadsCpids.filter((row) => String(row) !== String(cpid));
    },

    SET_CURRENT_HELD(state, { cpid, tid }) {
      console.log('🚀 ~ file: heldThreads.js ~ line 30 ~ SET_CURRENT_HELD ~ tid', tid);
      console.log('🚀 ~ file: heldThreads.js ~ line 30 ~ SET_CURRENT_HELD ~ cpid', cpid);
      state.currentViewThread = { cpid, tid };
      console.log('🚀 ~ file: heldThreads.js ~ line 34 ~ SET_CURRENT_HELD ~ state.contactPlatformOverviewList[cpid]', state.contactPlatformOverviewList[cpid]);
      state.heldThreads = { ...state.heldThreads, [cpid]: state.contactPlatformOverviewList[cpid] };
      console.log('🚀 ~ file: heldThreads.js ~ line 32 ~ SET_CURRENT_HELD ~ state.heldThreads', state.heldThreads);
      state.heldThreadsCpids = [...state.heldThreadsCpids, cpid];
    },

    UPDATE_HELDS(state, heldThreads) {
      console.log('🚀 ~ file: heldThreads.js ~ line 36 ~ UPDATE_HELDS ~ heldThreads', heldThreads);
      state.heldThreads = heldThreads;
    },

    ADD_HELD_FROM_BULK(state, cpid) {
      state.heldThreads = { ...state.heldThreads, [cpid]: deepcopy(state.contactPlatformOverviewList[cpid]) };
      state.heldThreadsCpids = state.heldThreadsCpids.concat([cpid]);
    },

    MERGE_HELD_FROM_BULK(state, cpid) {
      const { heldThreads } = state;
      const toBeMerged = deepcopy(heldThreads[cpid]);
      console.log('🚀 ~ file: heldThreads.js ~ line 35 ~ MERGE_HELD_FROM_BULK ~ toBeMerged', toBeMerged);
      if (!toBeMerged) return;
      state.contactPlatformOverviewList = { ...state.contactPlatformOverviewList, [cpid]: toBeMerged };
    },

    REMOVE_HELD_FROM_BULK(state, cpid) {
      const { heldThreads, heldThreadsCpids } = state;
      const newHeldThreads = Object.values(heldThreads).filter((row) => row && String(row.cpid) !== String(cpid)).arrayMapper('cpid');
      console.log('🚀 ~ file: heldThreads.js ~ line 61 ~ REMOVE_HELD_FROM_BULK ~ newHeldThreads', newHeldThreads);

      state.heldThreads = newHeldThreads;
      state.heldThreadsCpids = heldThreadsCpids.filter((row) => String(row) !== String(cpid));
    },

    UPDATE_BULK_HELD_THREADS_TID(state, tid) {
      state.heldThreadsTids = state.heldThreadsTids.includes(tid) ? state.heldThreadsTids.filter((row) => row !== tid) : state.heldThreadsTids.concat(tid);
    },

    SET_BULK_HELD_THREADS(state, threadList) {
      const cpids = Object.values(threadList);
      const tids = Object.keys(threadList);
      state.heldThreads = cpids.map((cpid) => state.contactPlatformOverviewList[cpid]).arrayMapper('cpid');
      state.heldThreadsCpids = cpids;
      state.heldThreadsTids = tids;
    },

    RESET_BULK_HELD_THREADS(state, cpids) {
      state.heldThreadsTids = [];
      state.heldThreadsCpids = state.heldThreadsCpids.filter((cpid) => !cpids.includes(cpid));
      const heldThreadsWithoutBulks = Object.values(state.heldThreads).filter((row) => (row ? cpids.includes(row.cpid) : row)).arrayMapper('cpid');
      state.heldThreads = heldThreadsWithoutBulks;
      state.contactPlatformOverviewList = { ...state.contactPlatformOverviewList, ...heldThreadsWithoutBulks };
    },
  },
  getters: {
    getterHeldThreads: (state) => state.heldThreads,
    getterHeldThreadsCpids: (state) => state.heldThreadsCpids,
    getterHeldThreadsTids: (state) => state.heldThreadsTids,
  },
  actions: {
    // eslint-disable-next-line object-curly-newline
    // up_currentThreadAndHeld({ dispatch, commit, rootGetters, getters }, tid) {
    //   const {
    //     getterHeldThreadsTids: tids,
    //     getterHeldThreads,
    //   } = getters;
    //   const { 'ApiConversation/threadList/getterThreadList': getterThreadList } = rootGetters;
    //   const previousTid = Object.values(getterHeldThreads).find((row) => !tids.concat(tid).includes(row.tid));
    //   const currentCpid = getterThreadList[tid];
    //   const previousCpid = getterThreadList[previousTid.tid];

    //   return dispatch('ApiContact/contactPlatformOverview/_mergeHeld', previousCpid, { root: true })
    //     .then(() => dispatch('ApiContact/contactPlatformOverview/_removeHeld', previousCpid, { root: true }))
    //     .then(() => {
    //       commit('ADD_HELD_FROM_BULK', currentCpid);
    //       commit('ApiConversation/threadList/SET_CURRENT_THREADTID', tid, { root: true });
    //       commit('ApiContact/contactPlatformOverview/SET_CURRENT_CONTACT_PLATFORM_OVERVIEW', currentCpid, { root: true });
    //       return currentCpid;
    //     });
    // },
    // eslint-disable-next-line object-curly-newline
    up_currentThreadAndHeld({ commit, rootGetters }, tid) {
      const { 'ApiConversation/threadList/getterThreadList': getterThreadList } = rootGetters;
      const cpid = getterThreadList[tid];

      commit('MERGE_CURRENT_HELD');
      commit('SET_CURRENT_HELD', { tid, cpid });
      commit('ApiConversation/threadList/SET_CURRENT_THREADTID', tid, { root: true });
      commit('ApiContact/contactPlatformOverview/SET_CURRENT_CONTACT_PLATFORM_OVERVIEW', cpid, { root: true });
      return cpid;
    },

    // eslint-disable-next-line object-curly-newline
    UP_toggleHeld({ commit, rootGetters, getters, dispatch }, tid) {
      const { getterHeldThreadsTids } = getters;
      const { 'ApiConversation/threadList/getterThreadList': getterThreadList } = rootGetters;

      commit('UPDATE_BULK_HELD_THREADS_TID', tid);

      const cpid = getterThreadList[tid];
      if (getterHeldThreadsTids.includes(tid)) {
        return dispatch('ApiContact/contactPlatformOverview/_mergeHeld', cpid, { root: true })
          .then(() => dispatch('ApiContact/contactPlatformOverview/_removeHeld', cpid, { root: true }));
      }

      commit('ADD_HELD_FROM_BULK', cpid);
      return undefined;
    },
    _mergeHeld({ commit }, cpid) {
      commit('MERGE_HELD_FROM_BULK', cpid);
    },
    _removeHeld({ commit }, cpid) {
      commit('REMOVE_HELD_FROM_BULK', cpid);
    },

    UP_resetBulkHeldThreads({ commit, getters, rootGetters }) {
      const { 'ApiConversation/threadList/getterThreadList': getterThreadList } = rootGetters;
      const { getterHeldThreadsTids: tids } = getters;

      const cpids = tids.map((tid) => getterThreadList[tid]);
      commit('RESET_BULK_HELD_THREADS', cpids);
    },

    UP_bulkHeldAllThreads({ commit, rootGetters }, threadList) {
      console.log('🚀 ~ file: heldThreads.js ~ line 156 ~ UP_bulkHeldAllThreads ~ threadList', threadList);
      // const { 'ApiConversation/threadList/getterThreadList': getterThreadList } = rootGetters;

      commit('SET_BULK_HELD_THREADS', threadList);
    },
  },
};
