<template>
  <b-menu-item
    :key="el.ordinal"
    @click="$emit(el.event, el)"
  >
    <template
      v-if="el.type"
      #label
    >
      <div class="multibar row-vcentered text-truncate small">
        <span>{{ el.username }}</span>
      </div>
    </template>

    <template
      v-else
      #label
    >
      <div class="multibar row-vcentered">
        <b-icon
          v-if="el.iconLeft"
          :pack="el.iconPackLeft"
          :icon="el.iconLeft"
          :class="`start has-text-color-${el.color}`"
        />

        <span
          v-if="el.text"
          :class="`start has-text-color-${el.color} text-truncate small`"
        >
          {{ el.text }}
        </span>

        <b-icon
          v-if="el.iconRight && !el.isSelected"
          :pack="el.iconPackRight"
          :icon="el.iconRight"
          :class="`end`"
        />

        <b-icon
          v-if="el.iconRight && el.isSelected"
          :pack="el.iconPackRight"
          icon="ubu-check"
          :class="`end has-text-color-${el.color}`"
        />
      </div>
    </template>
  </b-menu-item>
</template>

<script>
export default {
  name: 'PopoverItemClickable',
  model: {
    prop: 'el',
  },
  props: {
    el: {
      type: Object,
      required: true,
    },
  },
};
</script>
