<template>
  <div class="multibar row-vcentered">
    <b-icon
      class="start"
      pack="ubu"
      :icon="status.icon"
    />

    <span class="start text-truncate">{{ status.label.capitalize() }}</span>
  </div>
</template>

<script>
export default {
  name: 'UbuDropdownStatusRow',
  props: {
    status: {
      type: Object,
      required: true,
    },
  },
};
</script>
