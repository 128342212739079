<template>
  <b-menu-item
    expanded
    class="popover popover-item-title"
  >
    <div class="is-flex is-align-items-center">
      <b-icon
        v-if="iconLeft"
        pack="ubu"
        :icon="iconLeft.icon"
        class="popover-icon-left"
      />
      <span>
        {{ name }}
      </span>
    </div>
    <b-icon
      v-if="iconRight"
      pack="ubu"
      :icon="iconRight.icon"
    />
  </b-menu-item>
</template>

<script>
export default {
  name: 'UbuPopoverItemTitle',
  props: {
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    iconLeft: {
      type: Object,
      default: () => {},
    },
    iconRight: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
