<template>
  <fieldset>
    <b-field
      label="block type"
      horizontal
    >
      <b-field>
        <b-radio-button
          v-model="template.type"
          native-value="regular"
          type="is-success"
          @input="updateType($event)"
        >
          <!-- <b-icon icon="close" /> -->
          <span>Regular</span>
        </b-radio-button>

        <b-radio-button
          v-model="template.type"
          native-value="conditionnal"
          type="is-info"
          @input="updateType($event)"
        >
          <!-- <b-icon icon="check" /> -->
          <span>Conditionnal</span>
        </b-radio-button>
      </b-field>
    </b-field>

    <b-field
      v-if="template.type === 'conditionnal'"
      label="condition"
      horizontal
    >
      <b-field>
        <b-select
          v-model="template.condition.key"
        >
          <option value="followers">followers</option>
          <option value="growth">growth</option>
          <option value="engagement">engagement</option>
        </b-select>
        <b-select
          v-model="template.condition.sign"
        >
          <option value=">">over</option>
          <option value=">=">over or equal</option>
          <option value="=">equal</option>
          <option value="<=">under or equal</option>
          <option value="<">under</option>
        </b-select>
        <b-input
          v-model="template.condition.value"
          :step="template.condition.key === 'followers' ? '1' : '0.01'"
          type="number"
        />
      </b-field>
    </b-field>
    <b-field
      label="variables"
      horizontal
    >
      <div class="multibar is-spaced">
        <b-field class="start">
          <b-select
            v-model="selectedVariable"
            class="has-addon"
            placeholder="select variable"
          >
            <option
              v-for="(variable) in Object.keys(variables)"
              :key="variable"
              :value="variable"
            >
              {{ variable }}
            </option>
          </b-select>
          <p class="control">
            <b-button
              type="is-success"
              label="add"
              @click="addVariableInterpolation(selectedVariable)"
            />
          </p>
        </b-field>
        <b-field class="start">
          <b-button
            type="is-success"
            :label="`{{ myName }}`"
            @click="addMyNameInterpolation()"
          />
        </b-field>
      </div>
    </b-field>

    <b-field
      label="text"
      horizontal
    >
      <b-input
        v-model="template.text"
        type="textarea"
        @blur="getCaretPosition($event)"
      />
    </b-field>
  </fieldset>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuKaraTemplateForm',
  mixins: [VModelMixin],
  model: {
    prop: 'template',
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
    ordinalMax: {
      type: Number,
      required: true,
    },
    variables: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cursor: 0,
      selectedVariable: null,
    };
  },
  methods: {
    getCaretPosition(event) {
      this.cursor = event.target.selectionStart;
    },
    addVariableInterpolation(variable) {
      const templateArray = this.template.text.split('');
      templateArray.splice(this.cursor, 0, `{{ ${variable} }}`);
      this.template.text = templateArray.join('');
      // this.$refs.input.focus();
    },
    addMyNameInterpolation() {
      const templateArray = this.template.text.split('');
      templateArray.splice(this.cursor, 0, '{{ myName }}');
      this.template.text = templateArray.join('');
      // this.$refs.input.focus();
    },
    updateType(type) {
      const { condition, ...template } = this.template;
      if (type === 'regular') return this.$emit('input', { ...template, type });
      return this.$emit('input', {
        ...template,
        type,
        condition: { key: null, sign: null, value: 0 },
      });
    },
  },
};
</script>
