/* eslint-disable max-len */
import { getters, actions } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    route: null,
    isReduced: false,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, newData) { state.windowWidth = newData; },
    UPDATE_ROUTE(state, newData) { state.route = newData; },
    UPDATE_IS_REDUCED(state, newData) { state.isReduced = newData; },
  },
  getters: {
    ...getters.authenticate,
    getterWindowWidth: (state) => state.windowWidth,
    getterIsReduced: (state) => state.isReduced,
    getterRoute: (state) => state.route,
    menuItems: (state, _getters, _rootState, rootGetters) => {
      const currentRoute = state.route;
      if (!currentRoute) return [];
      if (!currentRoute.meta || !currentRoute.meta.viewStoreNavigation) return [];

      const getter = `${currentRoute.meta.viewStoreNavigation}/navigation`;

      const { [getter]: getterNavigation } = rootGetters;

      /**
       * used to display red circle on Settings menuItem when Meta Extension is disconnected
       */
      if (getter === 'TheSetting/navigation') {
        const {
          'Account/getterIsDisconnectedToPrivate': isPrivateDisconnected,
          'Account/getterAccountFeatures': accountFeatures,
        } = rootGetters;

        return getterNavigation.menuItems.map((item) => {
          if ('hasAlert' in item) {
            return { ...item, hasAlert: accountFeatures.UBU_META_EXTENSION && isPrivateDisconnected };
          }

          return item;
        });
      }

      return getterNavigation.menuItems;
    },
    activeMenuItems: (state, _getters, _rootState, rootGetters) => {
      const currentRoute = state.route;
      if (!currentRoute) return [];
      if (!currentRoute.meta || !currentRoute.meta.viewStoreNavigation) return [];
      // console.log('🚀 ~ file: TheMenu.store.js ~ line 19 ~ currentRoute', currentRoute);
      const getter = `${currentRoute.meta.viewStoreNavigation}/activeMenuItems`;

      const {
        [getter]: getterNavigation,
      } = rootGetters;

      if (!getterNavigation || !getterNavigation.length) return [];

      return getterNavigation;
    },
    groupedActiveMenuItems: (state, _getters, _rootState, rootGetters) => {
      const currentRoute = state.route;
      if (!currentRoute) return [];
      if (!currentRoute.meta || !currentRoute.meta.viewStoreNavigation) return [];
      // console.log('🚀 ~ file: TheMenu.store.js ~ line 19 ~ currentRoute', currentRoute);
      const getter = `${currentRoute.meta.viewStoreNavigation}/groupedActiveMenuItems`;

      const {
        [getter]: getterNavigation,
      } = rootGetters;

      if (!getterNavigation || !Object.keys(getterNavigation).length) return {};

      return getterNavigation;
    },
  },
  actions: {
    ...actions.authenticate,
    ...actions.user,
    ...actions.segment,

    UP_windowWidth({ commit }, windowWidth) {
      commit('TheMenu/UPDATE_WINDOW_WIDTH', windowWidth, { root: true });
      commit('TheMenu/UPDATE_IS_REDUCED', windowWidth <= 1320, { root: true });
    },
    UP_route({ commit }, route) {
      commit('TheMenu/UPDATE_ROUTE', route, { root: true });
    },
    UP_channelGroup({ dispatch }, channelGroup) {
      return Promise.resolve()
        .then(() => dispatch('TheMenu/up_currentChannelGroup', channelGroup, { root: true }))
        .then(() => dispatch('TheMenu/setDefaultChannelGroup', { payload: { cgid: channelGroup.cgid } }, { root: true }))
        .then(() => dispatch('TheMenu/up_currentChannelGroupList', channelGroup, { root: true }));
      // .then(() => dispatch('Sequence/clearStores', null, { root: true }))
      // .then(() => dispatch('Sequence/hardRefreshAllCustoms', null, { root: true }));
    },
  },
};
