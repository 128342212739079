<template>
  <div class="container">
    <fieldset class="is-bordered">
      <h3 class="subtitle is-3">Format and select your file 👇</h3>
      <p class="has-text-secondary mb-4">
        Your file should contain a single column, with no header and no space,
        containing codes that have been activated within your Shopify store.
        Learn more
      </p>
      <b-field
        v-if="params.delimiters.length > 1"
        class=""
        grouped
      >
        <b-field>
          <b-select
            v-model="currentDelimiter"
            style="width:10em"
            placeholder="Select a delimiter"
          >
            <option
              v-for="option in params.delimiters"
              :key="option.code"
              :value="option.code"
            >
              {{ option.label }}
            </option>
          </b-select>
        </b-field>
        <b-field
          class="file "
        >
          <b-upload
            v-model="file"
            accept=".csv"
            validation-message="Please select a file"
            class="file-label is-primary"
            :disabled="!currentDelimiter"
            expanded
            @input="$event ? handleFileUpload($event) : undefined"
          >
            <a class="button is-fullwidth">
              <span>Select file</span>
            </a>
          </b-upload>
        </b-field>
      </b-field>
      <b-field
        v-else
        class="file"
      >
        <b-upload
          v-model="file"
          accept=".csv"
          validation-message="Please select a file CSV"
          class="file-label is-primary"
          :disabled="!currentDelimiter"
          expanded
          @input="$event ? handleFileUpload($event) : undefined"
        >
          <a class="button is-fullwidth">
            <span>Select file</span>
          </a>
        </b-upload>
      </b-field>
    </fieldset>
    <br>

    <template v-if="file && file.name">
      <fieldset class="is-bordered">
        <h3 class="subtitle is-3">Map your data 👇</h3>
        <b-table
          :data="currentDataTable.data"
          scrollable
          width="480"
          sticky-header
          hoverable
        >
          <b-table-column
            v-for="(column, index) in currentDataTable.columns"
            :key="index"
            :field="column.field"
          >
            <template #header>
              <b-select
                v-if="params.columns[index] && params.columns[index].values.length > 0"
                v-model="column.columnName"
                width="150px"
                :placeholder="`Select a column name`"
              >
                <option
                  v-for="option in params.columns[index].values"
                  :key="option.key"
                  :value="option.key"
                >
                  {{ option.label }}
                </option>
              </b-select>
            </template>
            <template
              v-slot="props"
            >
              {{ props.row && props.row[`${column.field}`] ? props.row[`${column.field}`] : "-" }}
            </template>
          </b-table-column>
        </b-table>
        <b-message
          v-show="isLimitExceded"
          type="is-warning"
        >
          <p>
            Importing {{ params.maxRows }} codes. Remaining codes can be added in another import.
          </p>
        </b-message>
      </fieldset>
      <br>

      <slot name="moreActions" />

      <div class="buttons">
        <b-button
          type="is-primary"
          expanded
          @click="uploadData()"
        >
          Import discount codes
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TheUploadFromCSV',
  props: {
    params: {
      type: Object,
      default: () => ({
        maxRows: 100,
        // delimiters: [{ label: ';', code: ';' }, { label: '\\n', code: '\n' }], // if more 1 show select
        delimiters: [{ label: ';', code: ';' }],
        columns: [{
          values: [{
            label: 'Code',
            key: 'code',
          }, {
            label: 'Id',
            key: 'extrefId',
          },
          ],
        }],
      }),
    },
  },
  data() {
    return {
      currentData: [],
      currentDelimiter: this.params.delimiters[0].code,
      isLimitExceded: false,
      file: {},
      lines: [],
    };
  },
  computed: {
    currentDataTable() {
      return this.currentData;
    },
  },
  methods: {
    uploadData() {
      const columnNames = this.currentData.columns.map((d) => d.columnName);
      const result = this.lines.reduce((acc, line, idx) => {
        let cellule = {};
        if ((idx + 1) <= this.params.maxRows) {
          // only 2 columns
          const twoOnlyColumn = line.split(this.currentDelimiter)
            .splice(0, 2)
            .filter((el) => el.length !== 0);
          twoOnlyColumn.forEach((item, index) => {
            cellule = {
              ...cellule,
              [columnNames[index]]: item,
            };
          });

          acc.push(Object.keys(cellule).length === 0 ? { [null]: '' } : cellule);
        }
        return acc;
      }, []);
      this.$emit('input', result);
      return result;
    },

    handleFileUpload(file) {
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.readAsText(file);

        reader.onerror = () => {
          reader.abort();
          reject(new DOMException('Problem parsing input file.'));
        };

        reader.onload = () => {
          this.clearData();

          if (!reader.result) return resolve(reader.result);

          // Get the rows
          this.lines = reader.result.split(/(?:\r\n|\n)+/);

          this.currentData = this.lines.reduce((acc, line, idx) => {
            let cellule = null;
            if ((idx + 1) > this.params.maxRows) {
              this.isLimitExceded = true;
            }

            if ((idx + 1) <= this.params.maxRows) {
              this.isLimitExceded = false;
              // only 2 columns
              const twoOnlyColumn = line.split(this.currentDelimiter)
                .splice(0, 2)
                .filter((el) => el.length !== 0);

              twoOnlyColumn.forEach((item, index) => {
                cellule = {
                  ...cellule,
                  [`${index}column`]: item,
                };

                acc.columns[index] = {
                  field: `${index}column`,
                  columnName: this.params.columns[index]
                  && this.params.columns[index].values.length === 1
                    ? this.params.columns[index].values[0].key
                    : null,
                };
              });

              acc.data.push(cellule);
            }
            return acc;
          }, { data: [], columns: [] });

          return resolve(this.currentData);
        };
      });
    },

    clearData() {
      this.currentData = [];
    },
  },
};
</script>
