import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  actions: {
    tryConnectMeta(_, { payload, httpQuery }) {
      return clark.api.channelGroup.tryConnectMeta.post({ payload, httpQuery });
    },
  },
};
