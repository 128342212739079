<template>
  <aside class="ubuSidePanel ubuPanel-xl">
    <div class="multibar row-vcentered">
      <b-button
        v-if="activeTab.tab !== 'root' && !activeTab.tab.includes('done')"
        class="start is-ghost is-borderless"
        icon-pack="ubu"
        icon-left="ubu-arrow-left"
        label="Previous step"
        @click="$emit('goToPreviousTab')"
      />
      <b-button
        v-if="activeTab.tab.includes('done')"
        class="start is-ghost is-borderless"
        icon-pack="ubu"
        icon-left="ubu-arrow-left"
        label="Back to home"
        @click="$emit('goToStart')"
      />

      <b-button
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
        @click="$emit('close')"
      />
    </div>
    <div class="ubuSidePanel-content">
      <section>
        <header v-if="!hasCustomHeaderSlot">
          <p class="subtext">
            {{ activeTab.subtext }}
          </p>
          <h1 class="title is-1">
            {{ activeTab.title }}
          </h1>
        </header>

        <slot
          v-else
          name="customHeader"
        />

        <br>

        <b-tabs
          v-model="activeTab.tab"
          class="no-header"
          :animated="false"
        >
          <slot name="content" />
        </b-tabs>
      </section>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'TheTabsPanel',
  model: {
    prop: 'activeTab',
  },
  props: {
    activeTab: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasCustomHeaderSlot() {
      return this.$slots.customHeader;
    },
  },
};
</script>
