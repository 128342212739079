<template>
  <div>
    <template v-for="route in routes">
      <router-link
        v-if="route.name"
        :key="route.name"
        class="no-padding"
        :to="route.path"
      >
        <b-menu-item
          :label="route.name"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
import routes from './routes';

export default {
  name: 'TheUbuLeadNavigation',
  data() {
    return {
      routes,
    };
  },
};
</script>
