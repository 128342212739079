import { dispatchAndForward, genericErrorHandler } from '../../$utils/helpers';

const moment = require('moment');

export default {
  namespaced: true,
  getters: {
    templateFolderList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiConversation/templateFolder/getterTemplateFolderList': getterTemplateFolderList } = rootGetters;

      return getterTemplateFolderList;
    },
  },
  actions: {
    reportTemplateFolder({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/templateFolder/report',
        context: { payload: { cgid: currentChannelGroup.cgid } },
        textNotifier: 'Report all Template Folder',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report template folder list.', indefinite: true }));
    },

    createTemplateFolder({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/templateFolder/create',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
            createdOn: moment().format(),
            updatedOn: null,
          },
          httpQuery,
        },
        textNotifier: 'Create Template Folder',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to create template folder.', indefinite: true }));
    },

    updateTemplateFolder({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/templateFolder/update',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
            updatedOn: moment().format(),
          },
          httpQuery,
        },
        textNotifier: 'Update Template Folder',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to update template folder.', indefinite: true }));
    },

    deleteTemplateFolder({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/templateFolder/delete',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Delete Template Folder',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to delete template folder.', indefinite: true }));
    },
  },
};
