<template>
  <section>
    <template v-for="[groupName, filters] in Object.entries(splitedByGroup)">
      <b-dropdown-item
        v-if="filters.length"
        :key="groupName"
        custom
        class="has-text-secondary"
      >
        <fieldset class="fieldset">
          <legend class="is-subtext">{{ groupName }}</legend>
        </fieldset>
      </b-dropdown-item>

      <b-dropdown-item
        v-for="filter in filters"
        :key="filter.key"
        :class="[{ 'is-active': !!activeFilters.find(({ key }) => key === filter.key) }]"
        @click="$emit('select', filter)"
      >
        <p>{{ filter.entity }}</p>
      </b-dropdown-item>
    </template>
  </section>
</template>

<script>
export default {
  name: 'UbuDropdownFiltersSelectorByGroup',
  props: {
    filterList: {
      type: Array,
      default: () => [],
    },
    activeFilters: {
      type: Array,
      default: () => [],
    },
    nonDisplayableFilters: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    splitedByGroup() {
      return this.filterList.reduce((acc, filter) => {
        if (this.nonDisplayableFilters.includes(filter.key)) return acc;
        if (!acc[filter.group]) return acc;

        acc[filter.group].push(filter);

        return acc;
      }, {
        // change declaration order to change render order
        Primary: [],
        About: [],
        Statistics: [],
        Attributes: [],
        Crew: [],
        Campaigns: [],
        Mentions: [],
        Sales: [],
        'Last interactions': [],
      });
    },
  },
};
</script>
