<template>
  <section
    id="TheReward"
    class="mainPanel"
  >
    <template v-if="accountFeatures('CREW')">
      <header
        id="TheRewardHeader"
        class="mainPanelHeader multibar row-vcentered"
      >
        <h1 :class="`start title is-2 multibar row-vcentered`">
          <b-icon
            pack="ubu"
            icon="ubu-love"
          />
          <span
            :class="`ml-2`"
            class="ml-2"
          >
            Crew & Rewards
          </span>
        <!-- <b-icon
          class="ml-2 clickable text-color-0"
          pack="ubu"
          icon="ubu-settings"
          @click.native="openRewardSettingsPanel = !openRewardSettingsPanel"
        /> -->
        </h1>

        <nav class="end buttons expandable">
          <b-button
            v-for="item in navigation"
            :key="item.path"
            tag="router-link"
            type="is-simple"
            :to="item.path"
            :active="$route.fullPath.includes(item.path)"
          >
            {{ item.label }}
          </b-button>
        </nav>
      </header>

      <router-view />
    </template>

    <UbuExtensionDisconnected
      v-else
      class="p-4"
      :has-extension="false"
      feature="crew"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters } from 'vuex';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';

export default {
  name: 'TheReward',
  mixins: [TableUtilsMixin],
  data() {
    return {
      // openRewardSettingsPanel: false,
    };
  },
  computed: {
    ...mapGetters({
      accountFeatures: 'TheInbox/accountFeatures',
    }),
    navigation() {
      const basePath = '/crew';
      return [
        {
          label: 'Rewards',
          path: `${basePath}/reward`,
        },
        {
          label: 'Brand Settings',
          path: `${basePath}/crewSetting`,
        },
        {
          label: 'Tremendous Settings',
          path: `${basePath}/tremendousSetting`,
        },
      ];
    },
  },
  // mounted() {
  //   this.reportDatas();
  // },
  // methods: {
  //   ...mapActions({
  //     _reportAffiliationRewardList: 'TheReward/reportAffiliationRewardList',
  //     _reviewAffiliationReward: 'TheReward/reviewAffiliationReward',
  //     getAndSetCurrentContactPlatformOverview: 'TheContactPlatformPanel/getAndSetCurrentContactPlatformOverview',
  //   }),

  //   changePeriod(period) {
  //     this.period = period;
  //     this.reportDatas();
  //   },

  //   selectContactPlatform(cpid) {
  //     this.selectedContactPlatform = cpid;
  //     return this.getAndSetCurrentContactPlatformOverview({ payload: { cpid } });
  //   },

  //   setReviews(arids) {
  //     this.isReviewingLoading = true;

  //     return this._reviewAffiliationReward({        payload: { arids }      })
  //       .then(() => {
  //         this.isReviewingLoading = false;
  //       });
  //   },

  //   reportDatas() {
  //     this.loading = true;

  //     return this._reportAffiliationRewardList({
  //       payload: { period: this.period },
  //       httpQuery: {
  //         pagin: `${this.page - 1},${this.perPage}`,
  //         sorting: `${this.sortField},${this.sortOrder}`,
  //         // filters: this.prepareFiltersToRequest(this.allowedFilters, this.filters),
  //       },
  //     })
  //       .then(({ total }) => {
  //         this.total = total;
  //         this.loading = false;
  //       });
  //   },
  // },
};
</script>
