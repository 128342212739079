/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  actions: {
    patch({ dispatch }, { payload }) {
      return clark.api.apiConversation.threadGroup.autoTag.patch({ payload, httpQuery: {} })
        .then((response) => {
          dispatch('ThreadGroup/up_threadGroupList', response, { root: true });
          return response;
        });
    },
    delete({ dispatch }, { payload }) {
      return clark.api.apiConversation.threadGroup.autoTag.delete({ payload, httpQuery: {} })
        .then((response) => {
          dispatch('ThreadGroup/up_threadGroupList', response, { root: true });
          return response;
        });
    },
  },
  modules: {},
};
