<template>
  <section>
    <b-notification
      v-model="notification"
      class="UbuNotification"
      :closable="false"
      :type="type"
    >
      <div class="content">
        <b-taglist
          v-if="tag"
        >
          <b-tag :type="tagType">
            {{ tagLabel }}
          </b-tag>
        </b-taglist>

        <b-icon
          v-if="icon"
          pack="ubu"
          :icon="icon"
          size="is-large"
          class="mr-3"
        />

        <p
          :class="[{
            'has-text-primary': contentType === 'primary',
            'has-text-secondary': contentType === 'secondary',
            'has-text-danger': contentType === 'danger',
            'has-text-success': contentType === 'success',
            'has-text-warning': contentType === 'warning',
          }]"
        >
          {{ content }}
          <b-button
            v-if="iconLeft"
            label="What is a Tribe ?"
            tag="router-link"
            to=""
            type="is-ghost"
            icon-pack="ubu"
            :icon-left="iconLeft"
          />
          <b-button
            v-if="iconRight"
            label="What is a Tribe ?"
            tag="router-link"
            to=""
            type="is-ghost"
            icon-pack="ubu"
            :icon-right="iconRight"
          />
        </p>
      </div>
      <div
        v-if="close"
        class="close"
      >
        <b-button
          type="is-tertiary"
          icon-pack="ubu"
          icon-left="ubu-cross"
          @click="$emit('close')"
        />
      </div>
    </b-notification>
  </section>
</template>

<script>
export default {
  name: 'UbuHelper',
  model: {
    prop: 'notification',
  },
  props: {
    notification: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'is-secondary',
    },
    tag: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    tagType: {
      type: String,
      default: 'is-primary',
    },
    tagLabel: {
      type: String,
      default: 'New',
    },
    link: {
      type: Boolean,
      default: false,
    },
    linkLabel: {
      type: String,
      default: 'redirection',
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: 'No content !',
    },
    close: {
      type: Boolean,
      default: false,
    },
    contentType: {
      type: String,
      default: 'secondary',
    },
  },
};
</script>
