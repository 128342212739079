<template>
  <div
    v-if="hasAccess"
    class="flex ubuMentionFilters"
  >
    <UbuDateRangePicker
      v-if="hasTimeFilter"
      :max-date="maxDate"
      :disabled="backendFiltersAreDisabled"
      :option-date-to-use="optionDateToUse"
      @changeDate="$emit('changeDate', $event)"
    />

    <UbuSearchbar
      v-model="localSearch"
      class="mentionsSearchBar ml-2 mr-2"
      placeholder="Search by username, caption, keyword..."
      :disabled="frontendFiltersAreDisabled"
      @clear="localSearch = ''"
    />

    <UbuCRMFilter
      v-model="httpQuery"
      class="flex"
      :dropdowns-selectors="dropdownsSelectors"
      :is-loading="false"
      :disabled="frontendFiltersAreDisabled"
    />

    <b-button
      class="ml-2"
      type="is-dashed no-focus"
      :active="localShowFavorites"
      :disabled="frontendFiltersAreDisabled"
      @click="localShowFavorites = !localShowFavorites"
    >
      Favorites
    </b-button>
  </div>
</template>

<script>

export default {
  name: 'UbuMentionFilters',
  model: {
    prop: 'filters',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    hasTimeFilter: {
      type: Boolean,
      default: false,
    },
    hasAccess: {
      type: Boolean,
      default: true,
    },
    backendFiltersAreDisabled: {
      type: Boolean,
      default: false,
    },
    frontendFiltersAreDisabled: {
      type: Boolean,
      default: false,
    },
    dropdownsSelectors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      optionDateToUse: ['currentWeek', 'previousWeek', 'currentMonth', 'previousMonth'],
    };
  },
  computed: {
    localSearch: {
      get() { return this.filters.search; },
      set(val) { this.$emit('input', { ...this.filters, search: val }); },
    },
    localType: {
      get() { return this.filters.type; },
      set(val) { this.$emit('input', { ...this.filters, type: val }); },
    },
    localShowFavorites: {
      get() { return this.filters.showFavorites; },
      set(val) { this.$emit('input', { ...this.filters, showFavorites: val }); },
    },
    httpQuery: {
      get() { return this.filters.httpQuery; },
      set(val) { this.$emit('input', { ...this.filters, httpQuery: val }); },
    },
    maxDate() {
      return this.$moment().toDate();
    },
  },
};
</script>
