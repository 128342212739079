/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '@dailyplanet/providers/engine';

const target = 'clark';

const threadCounters = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/tribe/${payload.tid}/threadCounters`, method: 'REPORT', payload, httpQuery, target }),
};

export default threadCounters;
