import { render, staticRenderFns } from "./UbuEditCustomForm.vue?vue&type=template&id=fef2e5ec&"
import script from "./UbuEditCustomForm.vue?vue&type=script&lang=js&"
export * from "./UbuEditCustomForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports