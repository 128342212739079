/* eslint-disable max-len */
import { getters, actions } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
  },

  mutations: {
  },

  getters: {
    ...getters.contactGroup,

    tabItemsForTheCrmBulk: () => ({
      'root.label': {
        tab: 'root.label',
        title: 'Add contact label',
        subtext: 'Bulk actions',
      },
    }),
  },

  actions: {
    ...actions.contactGroup,
    ...actions.contactPlatformOverview,
  },
};
