import { dispatchAndForward, genericErrorHandler, genericThenHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {

  },
  actions: {
    downloadMention({ dispatch, rootGetters }, { payload, httpQuery, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiPublication/downloadMention/download',
        context: {
          payload: { cgid: currentChannelGroup.cgid, ...payload },
          httpQuery,
        },
        textNotifier: 'download mentions',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to download mention.', indefinite: true }));
    },
  },
};
