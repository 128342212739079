<!-- eslint-disable vue/no-template-target-blank -->
<template>
  <TheTabsPanel
    v-model="activeTab"
    class="ubuWizardView"
    @goToPreviousTab="goToPreviousTab()"
    @goToStart="closePanel()"
    @close="closePanel()"
  >
    <template #content>
      <b-tab-item value="root">
        <div class="buttons">
          <UbuActionCard
            v-for="item in navigableItems"
            :key="item.tab"
            class="mb-4"
            :label="item.label"
            icon-pack="ubu"
            :icon-left="item.icon"
            expanded
            @click="goToTabByTabPath(item.to)"
          />
        </div>
      </b-tab-item>

      <b-tab-item value="root.reconnect">
        <template v-if="activeTab.tab === 'root.reconnect'">
          <b-message type="is-classic">
            Having trouble connecting your Shopify store?
            <a
              class="has-text-primary no-decoration"
              href="https://app.intercom.com/a/apps/vkrekt62/articles/articles/6046554/show"
              target="_blank"
            >
              Watch our tutorial
            </a> or
            <a
              class="has-text-primary no-decoration"
              @click="openIntercom()"
            >
              contact support.
            </a>
          </b-message>

          <UbuShopifyConnexionForm
            v-model="localShop"
            @connexion="reconnexion()"
          />
        </template>
      </b-tab-item>

      <b-tab-item value="root.edit">
        <form v-if="activeTab.tab === 'root.edit'" @submit.prevent="edit()">
          <b-field label="Commission rate in %" horizontal>
            <b-numberinput
              v-model="localCommissionRate"
              step="1"
              min="0"
              max="100"
              :controls="false"
            />
          </b-field>

          <div class="buttons is-right">
            <b-button
              type="is-primary"
              native-type="submit"
              label="Save"
            />
          </div>
        </form>
      </b-tab-item>
    </template>
  </TheTabsPanel>
</template>

<script>
import { mapActions } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheManagerShopPanel',
  props: {
    shop: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: {
        tab: 'root',
        title: 'Manage shop',
        subtext: 'Shop',
      },
      tabItems: {
        root: {
          tab: 'root',
          title: 'Manage shop',
          subtext: 'Shop',
        },
        'root.reconnect': {
          tab: 'root.reconnect',
          title: 'Reconnect shop',
          subtext: 'Shop',
        },
        'root.edit': {
          tab: 'root.edit',
          title: 'Edit shop',
          subtext: 'Shop',
        },
      },

      localShop: null,
    };
  },
  computed: {
    navigableItems() {
      return {
        'root.reconnect': {
          to: 'root.reconnect',
          icon: 'ubu-shutdown',
          label: 'Reconnect',
        },
        'root.edit': {
          to: 'root.edit',
          icon: 'ubu-edit',
          label: 'Edit shop',
        },
      };
    },
    localCommissionRate: {
      get() { return this.parsedNumber(this.localShop.commissionRate * 100); },
      set(val) { this.localShop.commissionRate = this.parsedNumber(val / 100); },
    },
  },
  mounted() {
    this.localShop = { ...this.shop };
  },
  methods: {
    ...mapActions({
      _reconnectShop: 'TheShopify/reconnectShop',
      _patchShop: 'TheShopify/patchShop',
    }),

    parsedNumber(num) { return Math.round((num + Number.EPSILON) * 100) / 100; },

    goToPreviousTab() {
      if (this.activeTab.tab === 'root') return;

      const tabToGo = this.activeTab.tab.split('.').slice(0, -1).join('.');

      if (tabToGo === 'root') this.resetPayload();

      this.activeTab = this.tabItems[tabToGo];
    },

    goToTabByTabPath(tabToGo) { this.activeTab = this.tabItems[tabToGo]; },

    backHome() {
      this.activeTab = this.tabItems.root;
      this.resetPayload();
    },

    resetPayload() { this.localShop = { ...this.shop }; },

    closePanel() { this.$emit('close'); },

    reconnexion() {
      // used to remove extra / at the end url and http(s)://
      const parsedUrl = /(?:https?:\/\/)?([^/#?]+)(?:.*)$/.exec(this.localShop.baseUrl)[1];

      const payload = {
        sid: this.shop.sid,
        baseUrl: parsedUrl.trim(),
        extrefId: this.localShop.extrefId.trim(),
        apiVersion: this.localShop.apiVersion.trim(),
        apiKey: this.localShop.apiKey.trim(),
        password: this.localShop.password.trim(),
        sharedSecret: this.localShop.sharedSecret.trim(),
        type: 'SHOPIFY',
        isConnected: true,
      };

      return this._reconnectShop({ payload })
        .then(() => {
          this.$buefy.snackbar.open({ ...snackbarSuccess, message: 'Successfully connected shopify store.' });
          this.closePanel();
        });
    },

    edit() {
      const { folderList, ...shopREST } = this.localShop;

      return this._patchShop({ payload: shopREST })
        .then(() => {
          this.$buefy.snackbar.open({ ...snackbarSuccess, message: 'Success!' });
          this.closePanel();
        });
    },

    openIntercom() {
      this.$intercom.showNewMessage('Hello, I need help to connect my shopify store please.');
    },
  },
};
</script>
