<template>
  <div
    v-if="getterNotifBoardIsOpen"
    class="multibar column"
  >
    <!-- <div class="start">
      <DevDetails :cnt="{ name: 'getterNotifierStack', obj: getterNotifierStack}" />
      <DevDetails :cnt="{ name: 'getterArrayNotifs', obj: getterArrayNotifs}" />
    </div> -->
    <div class="buttons start is-centered">
      <b-button
        type="is-primary"
        class="is-reverted"
        label="clean all notifications"
        icon-left="delete"
        @click="deleteAllNotifications()"
      />
      <b-button
        type="is-link"
        class="is-reverted"
        label="close"
        icon-left="close"
        @click="toggleNotifBoard()"
      />
    </div>
    <br class="start">
    <div
      v-for="(notif) in getterArrayNotifs"
      :key="notif.nid"
      class="start notifs-block"
    >
      <b-field expanded>
        <p class="control">
          <b-button
            v-if="notif.state === 'created'"
            type="is-info"
            class="is-reverted"
            loading
          />
          <b-button
            v-if="notif.state === 'done'"
            type="is-success"
            class="is-reverted"
            icon-left="check"
          />
          <b-button
            v-if="notif.state === 'fail'"
            type="is-danger"
            class="is-reverted"
            icon-left="close"
          />
        </p>
        <p class="control">
          <b-button
            v-if="notif.state === 'created'"
            type="is-info"
            class="is-reverted"
            :label="notif.textNotifier"
            expanded
          />
          <b-button
            v-if="notif.state === 'done'"
            type="is-success"
            class="is-reverted"
            :label="notif.textNotifier"
            expanded
          />
          <b-button
            v-if="notif.state === 'fail'"
            type="is-danger"
            class="is-reverted"
            :label="notif.textNotifier"
            expanded
          />
        </p>
        <p class="control">
          <b-button
            v-if="notif.state === 'done'"
            type="is-success"
            icon-left="sync"
            class="is-reverted"
            :disabled="!notif.retry.onSuccess"
            @click="retry(notif)"
          />
          <b-button
            v-if="notif.state === 'fail'"
            type="is-danger"
            icon-left="sync"
            class="is-reverted"
            :disabled="!notif.retry.onFailure"
            @click="retry(notif)"
          />
        </p>
        <p class="control">
          <b-button
            type="is-primary"
            icon-left="delete"
            class="is-reverted"
            @click="deleteNotification(notif.nid)"
          />
        </p>
        <p
          v-if="notif.comment"
          class="control"
        >
          <b-button
            type="is-info"
            class="is-reverted"
            :icon-left="detailledNotifs === notif.nid ? 'eye-off' : 'eye'"
            @click="toggleDetailledNotifs(notif.nid)"
          />
        </p>
      </b-field>
      <p
        v-if="detailledNotifs === notif.nid"
        class="notif-info"
      >
        {{ notif.comment }}
      </p>
    </div>
  </div>
</template>

<script>
import NotifierMixin from './$mixins/NotifierMixin';

export default {
  name: 'TheNotifierBoard',
  mixins: [NotifierMixin],
  data() {
    return {
      detailledNotifs: null,
    };
  },
  methods: {
    toggleDetailledNotifs(nid) {
      if (this.detailledNotifs === nid) {
        this.detailledNotifs = null;
      } else {
        this.detailledNotifs = nid;
      }
    },
  },
};
</script>

<style lang="scss">
.notifs-block {
  margin-bottom: 10px;
  & .notif-info {
    background-color: var(--mercury);
    border-radius: 10px;
    padding: 5px;
  }
}
</style>
