<template>
  <section>
    <header>
      <p class="subtext">
        Bulk actions
      </p>
      <h1 class="title is-1">
        What do you want to do ?
      </h1>
    </header>

    <br>
    <article>
      <div class="buttons">
        <UbuActionCard
          v-for="item in options.routerViews"
          :key="item.label"
          class="mb-4"
          tag="router-link"
          :to="item.to"
          :label="item.label"
          icon-pack="ubu"
          :icon-left="item.icon"
          expanded
          :disabled="item.isDisabled"
          :info="item.info"
        >
          <template #info>
            <p>
              {{ item.infoContent }}
            </p>
          </template>
        </UbuActionCard>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'TheBulkActionsPanelHome',
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
};
</script>
