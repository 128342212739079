/* eslint-disable max-len */

export default {
  actions: {
    UP_upsertThreadInThreadList({ commit, getters }, { payload }) {
      const {
        getterContactPlatformOverviewList,
        getterHeldThreads,
      } = getters;

      const heldThreadsList = Object.values(getterHeldThreads)
        .find((row) => row && String(payload.cpid) === String(row.cpid));
      // console.warn('🚀 ~ file: customs.js ~ line 34 ~ UP_upsertCustom ~ heldThreadsList', heldThreadsList);

      if (heldThreadsList) {
        commit('UPDATE_HELDS', { ...getterHeldThreads, [payload.cpid]: { ...getterHeldThreads[payload.cpid], ...payload } });
        return;
      }

      commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', { ...getterContactPlatformOverviewList, [payload.cpid]: { ...getterContactPlatformOverviewList[payload.cpid], ...payload } });
    },

    UP_updateThreadState({ commit, getters }, { payload }) {
      console.log('🚀 ~ file: thread.js ~ line 24 ~ UP_updateThreadState ~ payload', payload);
      const {
        getterContactPlatformOverviewList,
        getterHeldThreads,
        getterHeldThreadsCpids,
      } = getters;

      const reducer = (array) => array.reduce((acc, row) => {
        acc[row.cpid] = row;

        if (acc[row.cpid].thread) acc[row.cpid].thread[payload.key] = payload.value;

        return acc;
      }, {});

      const contactPlatformOverviewList = Object.values(getterContactPlatformOverviewList)
        .filter((row) => row && row.cpid && payload.cpids.includes(row.cpid) && !getterHeldThreadsCpids.includes(row.cpid));
      console.log('🚀 ~ file: customs.js ~ line 23 ~ UP_upsertCustom ~ contactPlatformOverviewList', contactPlatformOverviewList);

      if (contactPlatformOverviewList.length) {
        commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
          ...getterContactPlatformOverviewList,
          ...reducer(contactPlatformOverviewList),
        });
      }

      const heldThreadsList = Object.values(getterHeldThreads)
        .filter((row) => row && row.cpid && payload.cpids.includes(row.cpid));
      console.log('🚀 ~ file: customs.js ~ line 34 ~ UP_upsertCustom ~ heldThreadsList', heldThreadsList);

      if (heldThreadsList.length) {
        commit('UPDATE_HELDS', {
          ...getterHeldThreads,
          ...reducer(heldThreadsList),
        });
      }
    },

    UP_updateThreadDone({ commit, getters }, { payload }) {
      console.log('🚀 ~ file: thread.js ~ line 62 ~ UP_updateThreadDone ~ payload', payload);
      const {
        getterContactPlatformOverviewList,
        getterHeldThreads,
        getterHeldThreadsCpids,
      } = getters;

      const reducer = (array) => array.reduce((acc, row) => {
        console.log('🚀 ~ file: thread.js ~ line 71 ~ reducer ~ row', row);
        acc[row.cpid] = row;

        if (acc[row.cpid].thread) {
          acc[row.cpid].thread.isRead = true;
          acc[row.cpid].thread.isOpen = false;
        }
        if (acc[row.cpid].tags) {
          acc[row.cpid].tags = acc[row.cpid].tags.filter((tgid) => Number(tgid) !== Number(payload.tgid));
        }

        return acc;
      }, {});

      const contactPlatformOverviewList = Object.values(getterContactPlatformOverviewList)
        .filter((row) => row && row.cpid && payload.cpids.includes(row.cpid) && !getterHeldThreadsCpids.includes(row.cpid));
      console.log('🚀 ~ file: thread.js ~ line 83 ~ UP_updateThreadDone ~ contactPlatformOverviewList', contactPlatformOverviewList);

      if (contactPlatformOverviewList.length) {
        commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
          ...getterContactPlatformOverviewList,
          ...reducer(contactPlatformOverviewList),
        });
      }

      const heldThreadsList = Object.values(getterHeldThreads)
        .filter((row) => row && row.cpid && payload.cpids.includes(row.cpid));
      console.log('🚀 ~ file: thread.js ~ line 94 ~ UP_updateThreadDone ~ heldThreadsList', heldThreadsList);

      if (heldThreadsList.length) {
        commit('UPDATE_HELDS', {
          ...getterHeldThreads,
          ...reducer(heldThreadsList),
        });
      }
    },

    UP_updateActivityState({ commit, getters }, { payload }) {
      const {
        getterContactPlatformOverviewList,
        getterHeldThreads,
        getterHeldThreadsCpids,
      } = getters;

      const reducer = (array) => array.reduce((acc, row) => {
        acc[row.cpid] = row;

        if (acc[row.cpid].lastActivity) acc[row.cpid].lastActivity.status = payload.value;

        return acc;
      }, {});

      const contactPlatformOverviewList = Object.values(getterContactPlatformOverviewList)
        .filter((row) => row && row.cpid && payload.cpids.includes(row.cpid) && !getterHeldThreadsCpids.includes(row.cpid));
      console.log('🚀 ~ file: customs.js ~ line 23 ~ UP_upsertCustom ~ contactPlatformOverviewList', contactPlatformOverviewList);

      if (contactPlatformOverviewList.length) {
        commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
          ...getterContactPlatformOverviewList,
          ...reducer(contactPlatformOverviewList),
        });
      }

      const heldThreadsList = Object.values(getterHeldThreads)
        .filter((row) => row && row.cpid && payload.cpids.includes(row.cpid));
      console.log('🚀 ~ file: customs.js ~ line 34 ~ UP_upsertCustom ~ heldThreadsList', heldThreadsList);

      if (heldThreadsList.length) {
        commit('UPDATE_HELDS', {
          ...getterHeldThreads,
          ...reducer(heldThreadsList),
        });
      }
    },

    UP_upsertAppoint({ commit, getters }, { payload }) {
      console.log('🚀 ~ file: thread.js ~ line 44 ~ UP_upsertAppoint ~ payload', payload);
      const {
        getterContactPlatformOverviewList,
        getterHeldThreads,
        getterHeldThreadsCpids,
      } = getters;

      const reducer = (array) => array.reduce((acc, row) => {
        acc[row.cpid] = row;

        acc[row.cpid][payload.key] = payload.value;

        return acc;
      }, {});

      const contactPlatformOverviewList = Object.values(getterContactPlatformOverviewList)
        .filter((row) => row && row.cpid && payload.cpids.includes(row.cpid) && !getterHeldThreadsCpids.includes(row.cpid));
      console.log('🚀 ~ file: customs.js ~ line 23 ~ UP_upsertCustom ~ contactPlatformOverviewList', contactPlatformOverviewList);

      if (contactPlatformOverviewList.length) {
        commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
          ...getterContactPlatformOverviewList,
          ...reducer(contactPlatformOverviewList),
        });
      }

      const heldThreadsList = Object.values(getterHeldThreads)
        .filter((row) => row && row.cpid && payload.cpids.includes(row.cpid));
      console.log('🚀 ~ file: customs.js ~ line 34 ~ UP_upsertCustom ~ heldThreadsList', heldThreadsList);

      if (heldThreadsList.length) {
        commit('UPDATE_HELDS', {
          ...getterHeldThreads,
          ...reducer(heldThreadsList),
        });
      }
    },
  },
};
