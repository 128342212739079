import activity from './activity.store';
import activityOverview from './activityOverview.store';
import bulkActivity from './bulkActivity.store';
import campaign from './campaign.store';
import protoList from './protoList.store';
import reportDetailledCampaign from './reportDetailledCampaign.store';
import threadCounters from './threadCounters.store';
import campaignAnalytics from './campaignAnalytics.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    activity,
    activityOverview,
    bulkActivity,
    campaign,
    protoList,
    reportDetailledCampaign,
    threadCounters,
    campaignAnalytics,
  },
};
