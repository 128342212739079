/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import Errors from '@dailyplanet/utils/Errors';
import changePassword from './changePassword.store';
import changeEmail from './changeEmail.store';

export default {
  namespaced: true,
  state: {
    authenticate: null,
    currentChannelGroup: null,
    currentChannelGroupList: null,
  },
  mutations: {
    SET_AUTHENTICATE(state, newData) { state.authenticate = newData; },
    SET_CURRENT_CHANNEL_GROUP(state, newData) { state.currentChannelGroup = newData; },
    SET_CURRENT_CHANNEL_GROUP_LIST(state, newData) { state.currentChannelGroupList = newData; },
  },
  getters: {
    getterAuthenticate: (state) => state.authenticate,
    getterCurrentChannelGroup: (state) => state.currentChannelGroup,
    getterCurrentChannelGroupList: (state) => state.currentChannelGroupList,
  },
  actions: {
    up_authenticate({ commit }, __authenticate__) {
      const authenticate = __authenticate__;
      const { setting, channelGroupList } = authenticate;
      const arrayMappedChannelGroupList = channelGroupList.arrayMapper('cgid');
      commit('SET_AUTHENTICATE', authenticate);
      commit('User/userDetail/SET_USER_DETAIL', authenticate.userDetail, { root: true });
      commit('User/userSetting/SET_THEME', authenticate.setting.theme || 'AUTO', { root: true });

      if (channelGroupList && channelGroupList.length) {
        commit('SET_CURRENT_CHANNEL_GROUP', arrayMappedChannelGroupList[setting.defaultChannelGroupCgid] || channelGroupList[0]);
        commit('SET_CURRENT_CHANNEL_GROUP_LIST', arrayMappedChannelGroupList);
      }
    },

    connectUser({ commit }, { payload }) {
      return clark.api.authenticate.post({ payload, httpQuery: {} })
        .then((authenticate) => {
          const { setting, channelGroupList } = authenticate;
          const arrayMappedChannelGroupList = channelGroupList.arrayMapper('cgid');
          commit('SET_AUTHENTICATE', authenticate);
          commit('User/userDetail/SET_USER_DETAIL', authenticate.userDetail, { root: true });
          commit('User/userSetting/SET_THEME', authenticate.setting.theme || 'AUTO', { root: true });

          if (channelGroupList && channelGroupList.length) {
            commit('SET_CURRENT_CHANNEL_GROUP', arrayMappedChannelGroupList[setting.defaultChannelGroupCgid] || channelGroupList[0]);
            commit('SET_CURRENT_CHANNEL_GROUP_LIST', arrayMappedChannelGroupList);
          }
        })
        .catch((error) => {
          commit('SET_AUTHENTICATE', null);
          throw new Errors(error);
        });
    },

    reconnectUser({ commit }) {
      return clark.api.authenticate.get({ payload: {}, httpQuery: {} })
        .then((authenticate) => {
          const { setting, channelGroupList } = authenticate;
          const arrayMappedChannelGroupList = channelGroupList.arrayMapper('cgid');
          commit('SET_AUTHENTICATE', authenticate);
          commit('User/userDetail/SET_USER_DETAIL', authenticate.userDetail, { root: true });
          commit('User/userSetting/SET_THEME', authenticate.setting.theme || 'AUTO', { root: true });

          if (channelGroupList && channelGroupList.length) {
            commit('SET_CURRENT_CHANNEL_GROUP', arrayMappedChannelGroupList[setting.defaultChannelGroupCgid] || channelGroupList[0]);
            commit('SET_CURRENT_CHANNEL_GROUP_LIST', arrayMappedChannelGroupList);
          }
        })
        .catch((error) => {
          commit('SET_AUTHENTICATE', null);
          throw new Errors(error);
        });
    },

    disconnectUser({ commit }) {
      return clark.api.authenticate.delete({ payload: {}, httpQuery: {} })
        .then(() => {
          commit('SET_AUTHENTICATE', null);
          commit('SET_CURRENT_CHANNEL_GROUP', null);
          commit('SET_CURRENT_CHANNEL_GROUP_LIST', null);
          commit('User/userDetail/SET_USER_DETAIL', null, { root: true });
        })
        .catch((error) => {
          commit('SET_AUTHENTICATE', null);
          throw new Errors(error);
        });
    },

    up_authenticateOneTime({ commit, getters }) {
      const { authenticate } = getters;
      commit('SET_AUTHENTICATE', { ...authenticate, oneTime: false });
    },
  },
  modules: {
    changePassword,
    changeEmail,
  },
};
