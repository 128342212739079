/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    shopList: {},
    currentShop: null,
  },
  mutations: {
    SET_SHOP_LIST(state, newData) { state.shopList = newData; },
    SET_CURRENT_SHOP(state, newData) { state.currentShop = newData; },
  },
  getters: {
    getterShopList: (state) => state.shopList,
    getterCurrentShop: (state) => state.currentShop,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.shop.report({ payload, httpQuery })
        .then((shopList) => {
          if (!shopList.length) return;

          const [first] = shopList;

          commit('SET_SHOP_LIST', shopList.arrayMapper('sid'));
          commit('SET_CURRENT_SHOP', first);

          if (httpQuery.folderList) {
            shopList.forEach(({ folderList }) => {
              const { 'ApiAffiliation/affiliationCodeFolder/getterAffiliationCodeFolderList': getterFolderList } = rootGetters;

              commit('ApiAffiliation/affiliationCodeFolder/SET_AFFILIATION_CODE_FOLDER_LIST', {
                ...getterFolderList,
                ...folderList.arrayMapper('acfid'),
              }, { root: true });
            });
          }
        });
    },

    create({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.shop.post({ payload, httpQuery })
        .then((shop) => {
          const { getterShopList } = getters;

          commit('SET_SHOP_LIST', { ...getterShopList, [shop.sid]: shop });

          return shop;
        });
    },

    update({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.shop.put({ payload, httpQuery })
        .then((shop) => {
          const { getterShopList } = getters;

          commit('SET_SHOP_LIST', { ...getterShopList, [shop.sid]: shop });

          return shop;
        });
    },

    patch({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.shop.patch({ payload, httpQuery })
        .then((shop) => {
          const { getterShopList } = getters;

          commit('SET_SHOP_LIST', { ...getterShopList, [shop.sid]: shop });

          return shop;
        });
    },

    UP_currentShop({ commit }, newData) {
      commit('SET_CURRENT_SHOP', newData);
    },
  },
  modules: {},
};
