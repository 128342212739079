import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getterAuthenticate: 'TheNotifier/getterAuthenticate',
      getterLatestNotif: 'TheNotifier/getterLatestNotif',
      getterLatestNid: 'TheNotifier/getterLatestNid',
      getterNotifierStack: 'TheNotifier/getterNotifierStack',
      getterArrayNotifs: 'TheNotifier/getterArrayNotifs',
      getterNotifBoardIsOpen: 'TheNotifier/getterNotifBoardIsOpen',
    }),
  },
  methods: {
    ...mapActions({
      pushNotif: 'TheNotifier/pushNotif',
      setLatestDone: 'TheNotifier/setLatestDone',
      setLatestFail: 'TheNotifier/setLatestFail',
      setNotifDone: 'TheNotifier/setNotifDone',
      setNotifFail: 'TheNotifier/setNotifFail',
      deleteAllNotifications: 'TheNotifier/deleteAllNotifications',
      toggleNotifBoard: 'TheNotifier/toggleNotifBoard',
      retry: 'TheNotifier/retry',
      deleteNotification: 'TheNotifier/deleteNotification',
      deleteSuccessNotifications: 'TheNotifier/deleteSuccessNotifications',
      addCommentInNotif: 'TheNotifier/addCommentInNotif',
    }),
  },
};
