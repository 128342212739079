/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../../engine';

const target = 'clark';

const manageWebhook = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/manageShopifyShop/${payload.ssid}/manageWebhook`, method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/manageShopifyShop/${payload.ssid}/manageWebhook`, method: 'POST', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/manageShopifyShop/${payload.ssid}/manageWebhook`, method: 'DELETE', payload, httpQuery, target }),
};

export default manageWebhook;
