/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '@dailyplanet/providers/engine';

const target = 'clark';

const tribeMember = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/tribe/${payload.tid}/tribeMember`, method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/tribe/${payload.tid}/tribeMember`, method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/tribe/${payload.tid}/tribeMember`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/tribe/${payload.tid}/tribeMember`, method: 'DELETE', payload, httpQuery, target }),
};

export default tribeMember;
