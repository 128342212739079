import moment from 'moment';

import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
  },
  actions: {
    createShortLink({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAnalytics/shortLink/create',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            createdOn: moment().format(),
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'Create shortLink',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to create short link.', indefinite: true }));
    },

    patchShortLink({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAnalytics/shortLink/patch',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'patch shortLink',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to edit short link.', indefinite: true }));
    },
  },
};
