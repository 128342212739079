<template>
  <section class="ubuMessageWrapper is-note">
    <div class="ubuMessageCard">
      <div class="ubuMessageCard-content multibar editNoteContainer">
        <p>
          {{ message.text }}
        </p>

        <b-icon
          class="ml-2 editNote end"
          pack="ubu"
          icon="ubu-edit"
          size="is-small"
          @click.native="$emit('openModalNote')"
        />
      </div>

      <div class="ubuMessageCard-footer">
        <UbuAvatar
          v-if="userLastName && userFirstName"
          class="initials mr-1"
          :size="18"
          :initial-name="`${userFirstName[0]}${userLastName[0]}`.toUpperCase()"
        />
        <span class="subtext has-text-secondary">
          {{ userFirstName }} {{ userLastName }} at {{ $moment(message.itemDate).format('HH:mm') }}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UbuThreadMessagePrivateNote',
  props: {
    message: {
      type: Object,
      default: () => {},
      require: true,
    },
  },
  computed: {
    userFirstName() {
      if (!this.message.user) return '';

      return this.message.user.userDetail.firstName;
    },

    userLastName() {
      if (!this.message.user) return '';

      return this.message.user.userDetail.lastName;
    },
  },
};
</script>
