<template>
  <b-table
    class="ubuTableSetting"
    :data="Object.values(allChannelGroupAccount)"
    :opened-detailed="defaultOpenedDetails"
    detailed
    detail-key="uid"
    :has-detailed-visible="() => false"
    hoverable
    :is-row-selectable="() => true"
    :show-header="false"
    @click="toggleOpenedDetails($event)"
  >
    <b-table-column
      v-for="column in columns"
      :key="column.col"
      v-slot="props"
      :label="column.label"
      :field="column.col"
      :sortable="column.isSortable"
      :cell-class="column.cellClass"
    >
      <template v-if="column.cmp === 'img'">
        <UbuAvatar
          :initial-name="props.row.initials"
          :size="40"
        />
      </template>

      <template v-if="column.cmp === 'flat'">
        <p class="text is-3">{{ props.row[column.col] }}</p>

        <p class="ellipsis subtext is-3 has-text-secondary">
          {{ props.row.userDetail.email }}
        </p>
      </template>

      <template v-if="column.cmp === 'tag'">
        <b-tag
          v-if="props.row[column.tag.condition]"
          :type="column.tag.color"
        >
          {{ column.tag.text }}
        </b-tag>
      </template>

      <template v-if="column.cmp === 'action'">
        <div class="multibar">
          <b-icon
            class="end"
            pack="ubu"
            :icon="defaultOpenedDetails.includes(props.row.uid)
              ? 'ubu-arrow-down'
              : 'ubu-arrow-up'"
          />
        </div>
      </template>
    </b-table-column>
    <template #detail="props">
      <legend class="has-text-secondary subtitle is-3">RIGHTS</legend>
      <b-field>
        <b-switch
          v-model="props.row.isAdmin"
          @input="checkIsAdmin($event, props.row.uid)"
        >
          Admin
        </b-switch>
      </b-field>
      <br>
      <DevDetails :cnt="{name: 'props.row.editableItem', obj: props.row.editableItem}" />

      <legend class="has-text-secondary subtitle is-3">WORKSPACES</legend>
      <div
        v-for="(row) in props.row.editableItem"
        :key="row.channelGroup.cgid"
      >
        <UbuChannelGroupAndChannelSelector
          :model="JSON.stringify(row)"
          @updateGroup="$emit('updateGroup', {
            event: $event,
            uid: props.row.uid,
          })"
        />
      </div>
    </template>
  </b-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UbuOrganizationMembers',
  props: {
    allChannelGroupAccount: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    myAuthenticate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultOpenedDetails: [],
    };
  },
  computed: {
    ...mapGetters({ account: 'TheSettingOrganizationSetting/account' }),
  },
  methods: {
    ...mapActions({
      setAdmin: 'TheSettingOrganizationSetting/setAdmin',
      setNotAdmin: 'TheSettingOrganizationSetting/setNotAdmin',
      up_setAdmin: 'TheSettingOrganizationSetting/up_setAdmin',
    }),
    toggleOpenedDetails(user) {
      if (this.defaultOpenedDetails.includes(user.uid)) {
        this.defaultOpenedDetails = this.defaultOpenedDetails.filter((uid) => uid !== user.uid);
      } else {
        this.defaultOpenedDetails.push(user.uid);
      }
    },
    checkIsAdmin(isSwitched, uid) {
      const payload = { aid: this.account.aid, uid };

      return isSwitched
        ? this.setAdmin({ payload })
          .then(() => this.up_setAdmin({ payload: { ...payload, isAdmin: isSwitched } }))
        : this.setNotAdmin({ payload })
          .then(() => this.up_setAdmin({ payload: { ...payload, isAdmin: isSwitched } }));
    },
  },
};
</script>
