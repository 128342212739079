/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

import ShortcutMixin from '@dailyplanet/mixins/ShortcutMixin';
import ThreadListMixin from '@dailyplanet/mixins/ThreadListMixin';

export default {
  mixins: [ThreadListMixin, ShortcutMixin],
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isButtonLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      _cpo: 'TheContactPlatformPanel/contactPlatformOverview',
      accountFeatures: 'TheTextarea/accountFeatures',
      holdedMessage: 'TheInbox/holdedMessage',
      userList: 'TheTextarea/userList',
      myAuthenticate: 'TheTextarea/myAuthenticate',
    }),

    localMessage: {
      get() { return this.message; },
      set(val) { this.$emit('input', val); },
    },

    isLoading() {
      return this.isButtonLoading;
    },

    currentUser() {
      return this.userList[this.myAuthenticate.uid];
    },

    canSendMessage() {
      const { tid } = this._cpo;
      // In the test condition we check if the string contains at least one character of non white-space
      return ((this.localMessage.length && /\S/.test(this.localMessage)) || this.file) && !this.holdedMessage[tid];
    },

    sendButtonMainAction() {
      /* eslint-disable object-curly-newline */
      if (!this._cpo) return { label: 'Send', callback: this.sendMessage, event: this.localMessage, disabled: !this.localMessage.length || this.search.length };

      if (this.isPrivateNote) return { label: 'Post note', callback: this.createPrivateNote, event: this.localMessage, disabled: !this.canSendMessage };

      if (this.canSendMessage) return { label: 'Send', callback: this.sendMessage, event: this.localMessage };

      if (this.$route.params.threadByCampaigns) return { label: 'Send', callback: this.sendMessage, event: this.localMessage, disabled: true };
      if (this.$route.params.threadByTags) return { label: 'Done', callback: this.changeThreadStatus, event: 'THREAD_DONE' };
      if (this._cpo.thread.isOpen) return { label: 'Close', callback: this.changeThreadStatus, event: 'THREAD_CLOSED' };
      if (!this._cpo.thread.isOpen) return { label: 'Open', callback: this.changeThreadStatus, event: 'THREAD_OPENED' };

      return undefined;
      /* eslint-enable object-curly-newline */
    },

    tooltipMainAction() {
      if (!this.sendButtonMainAction) return undefined;
      return {
        label: this.sendButtonMainAction.label,
        chips: [
          { icon: this.isMac ? 'apple-keyboard-command' : null, label: this.isMac ? null : 'Ctrl' },
          { label: 'Enter' },
        ],
      };
    },

    sendButtonSecondaryActions() {
      /* eslint-disable object-curly-newline */
      if (!this._cpo || this.$route.params.threadByCampaigns) return [];
      const { tid, thread: { isOpen } } = this._cpo;

      const sendEmojiAndClose = { isEmojis: true, label: 'Send emoji & close', callback: this.sendAndClose, type: 'sendEmojiAndClose' };
      const sendEmojiAndDone = { isEmojis: true, label: 'Send emoji & done', callback: this.sendAndDone, type: 'sendEmojiAndDone' };
      const sendAndClose = { label: 'Send & close', callback: this.sendAndClose, type: 'sendAndClose', event: this.localMessage };
      const sendAndDone = { label: 'Send & done', callback: this.sendAndDone, type: 'sendAndDone', event: this.localMessage };

      const actions = [];

      if (this.isPrivateNote) return [];
      if (this.canSendMessage && isOpen) return [sendAndClose];
      if (this.canSendMessage && this.$route.params.threadByTags) return [sendAndDone];

      if (!this.canSendMessage) {
        if (this.$route.params.threadByTags) actions.push(sendEmojiAndDone);
        if (isOpen && !this.isTagsInbox && !this.holdedMessage[tid]) actions.push(sendEmojiAndClose);
        return actions;
      }

      return actions;
      /* eslint-enable object-curly-newline */
    },

    tooltipSecondaryActions() {
      if (!this.sendButtonSecondaryActions) return undefined;
      return {
        chips: [
          { icon: this.isMac ? 'apple-keyboard-command' : null, label: this.isMac ? null : 'Ctrl' },
          { icon: 'apple-keyboard-shift' },
          { label: 'Enter' },
        ],
      };
    },
  },
  methods: {
    ...mapActions({
      _bulkThreadsState: 'TheTextarea/_bulkThreadsState',
      _sendMessage: 'TheInbox/sendMessage',
      _createPrivateNote: 'TheTextarea/createPrivateNote',
      _uploadS3Image: 'TheInbox/various',
      _emitSegmentEvent: 'TheInbox/emitSegmentEvent',
      // postReachMessage: 'TheTextarea/postReachMessage',
      up_focusedElement: 'TheInbox/up_focusedElement',
      setHoldedMessage: 'TheInbox/setHoldedMessage',
      up_currentThreadAndHeld: 'TheInbox/up_currentThreadAndHeld',
    }),
    createPrivateNote(note) {
      const { contactPlatform: { contactCid, cpid } } = this._cpo;

      if (!this.localMessage.length) return undefined;

      return this._createPrivateNote({
        payload: {
          contactCid,
          contactPlatformCpid: cpid,
          userUid: this.currentUser.uid,
          text: note,
        },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_createPrivateNote',
          payload: {
            cpids: [cpid],
          },
        }],
      })
        .then(() => {
          this.$emit('clearMessage');
          this.localMessage = '';
        });
    },

    uploadAndSendImage() {
      if (!Object.prototype.hasOwnProperty.call(this.fileInfo, 'uploadFileRequest')) return;

      const { cpid, tid, thread: { channelCid: cid } } = this._cpo;

      const { method, url, headers } = this.fileInfo.uploadFileRequest;

      const payload = {
        cid,
        tid,
        cpid,
        messageType: 'IMAGE',
      };

      // eslint-disable-next-line object-curly-newline
      this._uploadS3Image({ payload: { data: this.file, method, url, headers, cid } })
        .then(() => {
          if (!this.fileInfo.downloadFileRequest || !this.fileInfo.downloadFileRequest.url.length > 0) return undefined;
          return this._sendMessage({
            payload: {
              ...payload,
              imageUrl: this.fileInfo.downloadFileRequest.url,
            },
          });
        })
        .finally(() => {
          this.$emit('clearFile');
          this.up_focusedElement({ name: 'THREAD_LIST' });
          this.isButtonLoading = false;
        });
    },

    sendTextMessage(message) {
      const { cpid, tid, thread: { channelCid: cid } } = this._cpo;

      const payload = {
        cid,
        tid,
        cpid,
        text: message,
        messageType: 'TEXT',
      };

      this.isButtonLoading = true;

      return Promise.resolve()
        .then(() => this.setHoldedMessage({ payload: { message, tid } }))
        .then(() => this._sendMessage({ payload }))
        .catch((error) => {
          this.setHoldedMessage({ payload: { message, tid, error } });

          throw error;
        })
        .finally(() => {
          this.up_focusedElement({ name: 'THREAD_LIST' });
          this.isButtonLoading = false;
        });
    },

    sendMessage(message) {
      this.$emit('clearMessage');
      this.localMessage = '';

      if (this.file !== null) {
        return this.uploadAndSendImage();
      }
      return this.sendTextMessage(message);
    },

    changeThreadStatus(event) {
      // block close action if user doesnt have feature INBOX
      if (event === 'THREAD_CLOSED' && !this.accountFeatures('INBOX')) {
        this._emitSegmentEvent({ event: 'closeThreadNoFeature' });
        this.$intercom.showNewMessage('Hi! I would like to activate the INBOX module! 🙌');

        return null;
      }

      const { tid, cpid } = this._cpo;

      const payload = { event, tids: [tid] };
      const mutations = [];

      if (event === 'THREAD_DONE') {
        payload.tgid = this.$route.params.threadByTags;

        mutations.push(...[
          {
            target: 'ApiContact/contactPlatformOverview/UP_updateThreadDone',
            payload: {
              tgid: this.$route.params.threadByTags,
              cpids: [cpid],
            },
          },
        ]);
      }

      if (['THREAD_OPENED', 'THREAD_CLOSED'].includes(event)) {
        mutations.push({
          target: 'ApiContact/contactPlatformOverview/UP_updateThreadState',
          payload: {
            key: 'isOpen',
            value: event === 'THREAD_OPENED',
            cpids: [cpid],
          },
        });
      }

      if (['THREAD_READ'].includes(event)) {
        mutations.push({
          target: 'ApiContact/contactPlatformOverview/UP_updateThreadState',
          payload: {
            key: 'isRead',
            value: true,
            cpids: [cpid],
          },
        });
      }

      this.buttonLoading = true;
      return Promise.resolve()
        .then(() => this.goToNextThread())
        .then(() => this._bulkThreadsState({ payload, mutations }))
        .then(() => {
          this.buttonLoading = false;
          if (!this.$route.query.search) {
            this.up_focusedElement({ name: 'THREAD_LIST' });
          }
        })
        .catch(() => { this.isButtonLoading = false; });
    },

    goToNextThread() {
      console.log('🚀 ~ file: TheMainTextarea.mixin.js ~ line 272 ~ goToNextThread ~ this.nextThread', this.nextThread);
      if (this.nextThread) {
        return this.$route.path !== this.nextThreadPath
          ? this.$router.push({ path: this.nextThreadPath, query: this.$route.query })
          : null;
      }
      console.log('🚀 ~ file: TheMainTextarea.mixin.js ~ line 278 ~ goToNextThread ~ this.previousThread', this.previousThread);
      if (this.previousThread) {
        return this.$route.path !== this.previousThreadPath
          ? this.$router.push({ path: this.previousThreadPath, query: this.$route.query })
          : null;
      }

      this.up_currentThreadAndHeld(null);

      return this.$router.push({ path: this.currentRoute, query: this.$route.query });
    },

    sendAndClose(message) {
      return this.sendMessage(message)
        .then(() => this.changeThreadStatus('THREAD_CLOSED'));
    },

    sendAndDone(message) {
      return this.sendMessage(message)
        .then(() => this.changeThreadStatus('THREAD_DONE'));
    },
  },
};
