<template>
  <section id="TheWorkspace">
    <UbuAsideMenu :sidebar-options="sidebarOptions" />

    <div class="has-coreview-spaced kara">
      <DevDetails :cnt="{name: 'selected', obj: selected}" />
      <router-view
        name="addShopify"
        :selected.sync="selected"
        @reset="selected = {}"
      />
      <router-view
        name="allShopify"
        :selected.sync="selected"
        @reset="selected = {}"
      />
      <router-view
        name="shopifyShop"
        :selected.sync="selected"
      />
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions } from 'vuex';

export default {
  name: 'TheShopify',
  data() {
    return {
      selected: {},
      routes: [
        {
          title: 'GLOBAL WORKFLOW',
          ordinal: 0,
          items: [
            {
              text: 'All Shops',
              path: '/shopify/all-shops',
            },
            {
              text: 'Add Shop',
              path: '/shopify/add-shop',
            },
          ],
        },
      ],
    };
  },
  computed: {
    sidebarOptions() {
      const commonRoutes = this.routes;

      if ((!Object.values(this.selected).length || !this.selected.sid) && !this.$route.params.sid) return commonRoutes;

      const basePath = !this.$route.params.sid
        ? `/shopify/shop/${this.selected.sid}`
        : `/shopify/shop/${this.$route.params.sid}`;

      const accountRoutes = [
        {
          title: 'SHOPIFY WORKFLOW',
          ordinal: 1,
          items: [
            {
              text: 'Details Shopify shop',
              path: `${basePath}/details`,
            },
            {
              text: 'Manage webhooks',
              path: `${basePath}/manage-webhooks`,
            },
          ],
        },
      ];

      return commonRoutes.concat(accountRoutes);
    },
  },
  watch: {
    selected(newVal) {
      if (newVal.sid) {
        this.$router.push({
          path: `/shopify/shop/${newVal.sid}/details`,
        });
      }
    },
  },
  mounted() {
    return Promise.all([
      this.reportManageShop({}),
    ]);
  },
  methods: {
    ...mapActions({
      reportManageShop: 'TheShop/reportManageShop',
    }),
  },
};
</script>
