/* eslint-disable max-len */
import BulkActionEngineStore from '@dailyplanet/data-stores/engine-stores/bulkAction';

export default {
  namespaced: true,
  state: {
    ...BulkActionEngineStore.state,
  },
  mutations: {
    ...BulkActionEngineStore.mutations,
  },
  getters: {
    ...BulkActionEngineStore.getters,

    mentionExtrefIdList: (state) => Object.values(state.checkedRows
      .flatMap(({ mentions }) => mentions.reduce((acc, { mainMedia, carousel }) => {
        acc.push({ extrefId: mainMedia.extrefId });
        if (carousel && carousel.length) acc.push(...carousel.map(({ extrefId }) => ({ extrefId })));
        return acc;
      }, []))
      .arrayMapper('extrefId')),

    contactPlatformList: (state) => state.checkedRows
      // eslint-disable-next-line object-curly-newline
      .map(({ cid, cpid, username, profilePicture, platformName }) => ({ cid, cpid, username, profilePicture, platformName })),

    tabItems: (state, getters, _rootState, rootGetters) => ({
      root: {
        tab: 'root',
        title: 'What do you want to do ?',
        subtext: 'Bulk actions',
      },
      'root.downloadMentions': {
        tab: 'root.downloadMentions',
        title: 'Enter your email to receive your download link',
        subtext: 'Download mentions',
      },
      'root.label': {
        tab: 'root.label',
        title: 'Add to contact label',
        subtext: `${getters.contactPlatformList.length} people selected`,
      },
      ...rootGetters['TheAskUgcPermission/tabItemsForTheMentionBulk'],
    }),
  },
  actions: {
    ...BulkActionEngineStore.actions,
  },
};
