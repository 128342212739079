/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const bulkActivity = {
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}/bulkActivity`, method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}/bulkActivity`, method: 'PUT', payload, httpQuery, target }),
  patch: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}/bulkActivity`, method: 'PATCH', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}/bulkActivity`, method: 'DELETE', payload, httpQuery, target }),
};

export default bulkActivity;
