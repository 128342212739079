// import lib from './lib';
import smarts from './smarts';
// import smarts from './smarts';

export default {
  // eslint-disable-next-line
  // lib,
  smarts,
  // smarts,
};
