/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    cityList: [],
  },
  mutations: {
    SET_CITY_LIST(state, newData) { state.cityList = newData; },
    RESET_STORE(state) {
      state.cityList = [];
    },
  },
  getters: {
    getterCityList: (state) => state.cityList,
  },
  actions: {
    report({ commit }, { payload, httpQuery }) {
      return clark.api.city.report({ payload, httpQuery })
        .then((cityList) => {
          commit('SET_CITY_LIST', cityList);
          return cityList;
        });
    },
  },
};
