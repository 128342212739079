<template>
  <div>
    <b-collapse
      v-for="group in Object.values(editableItem)"
      :key="group.value"
      class="ubuSwitchGroup"
      animation="slide"
      :open="group.isOpen"
      @update:open="editableItem[group.value].isOpen = $event"
    >
      <template #trigger="props">
        <div class="trigger multibar">
          <b-icon
            v-if="group.isActive"
            class="start has-text-color-5"
            pack="ubu"
            icon="ubu-check"
          />
          <b-icon
            v-else
            class="start has-text-color-2"
            pack="ubu"
            icon="ubu-cross"
          />

          <b-image
            class="start"
            :src="group.picture"
          />

          <p class="start">
            {{ group.name }}
          </p>

          <b-icon
            class="start"
            pack="ubu"
            :icon="props.open ? 'ubu-arrow-down' : 'ubu-arrow-up'"
          />
        </div>
      </template>
      <UbuSwitchSelectorChildren
        :group="group"
        :auto-mode="autoMode"
        :read-only="readOnly"
        @toggleActiveChannelGroup="group.isActive = $event"
        @updateGroup="$emit('updateGroup', $event)"
      />
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'UbuSwitchSelector',
  props: {
    editableItem: {
      type: Object,
      required: true,
    },
    autoMode: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
