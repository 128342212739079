/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  actions: {
    download({ rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'DOWNLOAD_MENTION.DOWNLOAD')) return undefined;

      return clark.api.apiPublication.downloadMention.post({ payload, httpQuery });
    },
  },
};
