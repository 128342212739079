<template>
  <section class="ubuSettingView">
    <div class="multibar row-vcentered">
      <h1 class="start title is-2">Profile</h1>

      <b-button
        class="end"
        type="is-primary"
        label="Logout"
        @click="disconnect()"
      />
    </div>

    <br>

    <section>
      <div class="multibar">
        <!-- <b-image
          v-if="currentUserDetail.picture"
          class="start is-80"
          lazy
          rounded
          :src="currentUserDetail.picture"
          alt="Profile picture"
          :placeholder="'https://via.placeholder.com/140x140.png'"
          :src-fallback="'https://via.placeholder.com/140x140.png'"
        /> -->

        <UbuAvatar
          class="start initials"
          :size="80"
          :initial-name="initials"
        />

        <!-- <b-upload
          v-model="file"
          class="start a-self-center ml-5"
          accept="image/*"
          @input="uploadImage($event)"
        >
          <a class="is-link">Edit profile picture</a>
        </b-upload> -->
      </div>

      <br>

      <hr class="is-l">

      <div class="subtext is-3 has-text-secondary">First name</div>

      <div class="text is-3">{{ currentUserDetail.firstName }}</div>

      <b-button
        class="is-ghost no-padding is-borderless"
        label="Edit name"
        tag="router-link"
        to="/setting/profile/editName"
      />

      <hr class="is-l">

      <div class="subtext is-3 has-text-secondary">Last name</div>

      <div class="text is-3">{{ currentUserDetail.lastName }}</div>

      <b-button
        class="is-ghost no-padding is-borderless"
        label="Edit name"
        tag="router-link"
        to="/setting/profile/editName"
      />

      <router-view
        name="editName"
        path="/setting/profile/"
      />

      <hr class="is-l">

      <template v-if="currentUserDetail.phone">
        <div class="subtext is-3 has-text-secondary">Phone number</div>

        <div
          v-if="currentUserDetail.phone"
          class="text is-3"
        >
          {{ currentUserDetail.phone }}
        </div>

        <hr class="is-l">
      </template>

      <div class="subtext is-3 has-text-secondary">Email</div>

      <div class="text is-3">{{ myAuthenticate.passName }}</div>

      <div class="buttons">
        <b-button
          class="is-ghost no-padding is-borderless"
          label="Edit email"
          tag="router-link"
          to="/setting/profile/editEmail"
        />
      </div>

      <router-view
        name="editEmail"
        path="/setting/profile/"
      />

      <hr class="is-l">

      <div class="subtext is-3 has-text-secondary">Password</div>

      <div class="text is-3">************</div>

      <div class="buttons">
        <b-button
          class="is-ghost no-padding is-borderless"
          label="Change password"
          tag="router-link"
          to="/setting/profile/editPassword"
        />
      </div>

      <router-view
        name="editPassword"
        path="/setting/profile/"
      />

      <hr class="is-l">

      <div class="subtext is-3 has-text-secondary">Organization role</div>

      <div class="text is-3">{{ getMyRole }}</div>

      <hr class="is-l">

      <div class="subtext is-3 has-text-secondary">Authorized access</div>

      <template v-for="row in accountChannelGroupChannelWorkspace">
        <UbuChannelGroupAndChannelSelector
          v-if="row"
          :key="row.channelGroup.cgid"
          :model="JSON.stringify(row)"
        />
      </template>
    </section>
  </section>
</template>

<script>
/* eslint-disable max-len */
import iconsMixins from '@dailyplanet/cross-addons/crossCommon/$mixins/dataIcons.mixins';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheSettingProfile',
  mixins: [iconsMixins],
  data() {
    return {
      file: null,
    };
  },
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheSettingUserSetting/myAuthenticate',
      currentUserDetail: 'TheSettingUserSetting/currentUserDetail',
      account: 'TheSettingUserSetting/account',
    }),
    initials() {
      return `${this.currentUserDetail.firstName[0]}${this.currentUserDetail.lastName[0]}`.toUpperCase();
    },

    getMyRole() {
      if (!this.myAuthenticate.accountList) return 'Undefined';
      console.log(this.myAuthenticate);
      const user = this.myAuthenticate.accountList[0].hasUser
        .find((u) => u.userUid === this.currentUserDetail.userUid);

      return user.isAdmin ? 'Admin' : 'Member';
    },

    accountChannelGroupChannelWorkspace() {
      if (!this.account) return [];

      return this.myAuthenticate.channelGroupList.reduce((acc, {
        cgid, name, picture,
      }) => {
        if (acc[cgid]) return acc;

        const initials = name.charAt(0).toUpperCase();
        const immutable = true;

        const channelList = this.myAuthenticate.channelList.reduce((acc2, {
          cid, name: _name, platformName, channelGroupCgid,
        }) => {
          if (Number(cgid) !== Number(channelGroupCgid)) return acc2;

          acc2.push({
            cid, name: _name, platformName, channelGroupCgid, immutable,
          });

          return acc2;
        }, []).arrayMapper('cid');

        acc[cgid] = {
          channelGroup: {
            cgid, name, picture, initials, immutable,
          },
          channelList,
        };

        return acc;
      }, {});
    },
  },
  methods: {
    ...mapActions({
      logout: 'TheSettingUserSetting/logOut',
    }),

    disconnect() {
      this.logout()
        .then(() => { window.location.assign('/auth/login'); });
    },

    uploadImage(event) {
      console.log('🚀 ~ file: TheSettingUserSetting.vue ~ line 54 ~ uploadImage ~ event', event);
    },
  },
};
</script>
