<template>
  <div @keydown.prevent="shortcutHandlerTable($event, localChecked)">
    <b-table
      ref="table"
      :data="activityOverviewList"
      class="table-row-clickable table-full-height"
      hoverable
      sticky-header
      :loading="loading"
      :detail-key="detailKey"

      sticky-checkbox
      checkable
      :checked-rows.sync="localChecked"
      :custom-is-checked="(a, b) => {
        return a[detailKey] === b[detailKey];
      }"

      :selected.sync="selected"
      focusable

      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      pagination-size="is-small"

      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"

      @page-change="pageChange"
      @sort="onSort"
    >
      <b-table-column
        v-for="column in columns"
        :key="column.field"
        :field="column.field"
        :label="column.label"
        :sortable="column.isSortable"
        :visible="column.visible"
        :custom-sort="(a, b, isAsc) => customSort(a, b, isAsc, column)"
      >
        <template
          v-if="column.cmp === 'UbuTablePlatform'"
          #header
        >
          <b-icon
            pack="ubu"
            icon="ubu-organic"
          />
        </template>

        <template v-slot="props">
          <component
            :is="column.cmp"
            :cpo="props.row"
            :field="column.field"
            :column="column"
            @selectContactPlatform="$emit('selectContactPlatform', $event)"
          />
        </template>
      </b-table-column>

      <template #empty>
        <p class="has-text-secondary">No campaign members yet!</p>
      </template>
    </b-table>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';

export default {
  name: 'TheCampaignPeopleDetailed',
  mixins: [TableUtilsMixin],
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Object,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    allowedFilters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      detailKey: 'aid',
      page: 1,
      perPage: 50,
      total: 0,
      sortField: 'campaignActivity.aid',
      sortOrder: 'asc',
      loading: false,
      selected: null,
      /* eslint-disable object-curly-newline */
      /* eslint-disable key-spacing */
      /* eslint-disable no-multi-spaces */
      columns: {
        platformName:     { cmp: 'UbuTablePlatform',                field: 'contactPlatform.platformName',                  label: 'Platform',          isSortable: false },
        username:         { cmp: 'UbuTableProfile',                 field: 'contactPlatform.username',                      label: 'Username',          isSortable: true },
        status:           { cmp: 'UbuTableActivityStatusPopover',   field: 'campaignActivity.status',                       label: 'Status',            isSortable: true },
        followerCount:    { cmp: 'UbuTableNumber',                  field: 'contactPlatform.followerCount',                 label: 'Followers',         isSortable: true },
        totalMentions:    { cmp: 'UbuTableNumber',                  field: 'campaignActivityStatistic.totalMentions',       label: 'Mentions',          isSortable: true },
        totalRevenuesUsd: { cmp: 'UbuTableNumberDecimalFormatter',  field: 'campaignActivityStatistic.totalRevenues',       label: 'Revenue',           isSortable: true, before: '$' },
        totalCoupons:     { cmp: 'UbuTableNumber',                  field: 'campaignActivityStatistic.totalCoupons',        label: 'Coupons',           isSortable: true },
        totalOrders:      { cmp: 'UbuTableNumber',                  field: 'campaignActivityStatistic.totalOrders',         label: 'Orders',            isSortable: true },
        'crewUser.state': { cmp: 'UbuTableCrewState',               field: 'crewUser.state',                                label: 'Crew state',        isSortable: true },
        createdOn:        { cmp: 'UbuTableDate',                    field: 'campaignActivity.createdOn',                    label: 'Date added',        isSortable: true },
        lastMessageOn:    { cmp: 'UbuTableDate',                    field: 'contactPlatformStatistic.lastMessageOn',        label: 'Last message',      isSortable: true },
        tribes:           { cmp: 'UbuTableChipTribe',               field: 'tribes',                                        label: 'Tribes',            isSortable: false, visible: true },
        publicEmail:      { cmp: 'UbuTableSimple',                  field: 'contact.publicEmail',                           label: 'Public Email',      isSortable: true },
      },
      /* eslint-enable key-spacing */
      /* eslint-enable no-multi-spaces */
      /* eslint-enable object-curly-newline */
    };
  },
  computed: {
    ...mapGetters({
      _activityOverviewList: 'TheCampaign/activityOverviewList',
    }),

    activityOverviewList() {
      if (!this.filters.length) return this._activityOverviewList;
      const filterableStatus = this.filters.find(({ key }) => key === 'activity.status');
      if (!filterableStatus) return this._activityOverviewList;
      return this._activityOverviewList.filter((row) => row.campaignActivity.status === filterableStatus.values[0]);
    },

    localChecked: {
      get() { return Object.values(this.checkedRows); },
      set(members) {
        this.$emit('input', members.arrayMapper('aid'));
      },
    },
  },
  watch: {
    filters(newValue) {
      if (newValue) {
        return this._resetActivityOverviewList()
          .then(() => this.reportDatas())
          .then(() => {
            // eslint-disable-next-line prefer-destructuring
            this.selected = this._activityOverviewList[0] || null;
          });
      }
      return undefined;
    },

    _activityOverviewList() {
      if (!Object.keys(this.localChecked).length) return;
      this.localChecked = this._activityOverviewList
        .filter((row) => this.checkedRows[row[this.detailKey]]);
    },
  },
  mounted() {
    this.$emit('input', {}); // reset checkedRows
    return this._resetActivityOverviewList()
      .then(() => this.reportDatas())
      .then(() => {
        // eslint-disable-next-line prefer-destructuring
        this.selected = this._activityOverviewList[0] || null;
        if (this.$refs && this.$refs.table) this.$refs.table.focus();
      });
  },
  methods: {
    ...mapActions({
      _resetActivityOverviewList: 'ApiInfluence/activityOverview/UP_resetActivityOverviewList',
      _reportActivityOverview: 'TheCampaign/reportActivityOverview',
    }),
    reportDatas() {
      this.loading = true;

      return this._reportActivityOverview({
        payload: { cid: this.$route.params.campaignCid },
        httpQuery: {
          pagin: `${this.page - 1},${this.perPage}`,
          sorting: `${this.sortField},${this.sortOrder}`,
          filters: this.prepareFiltersToRequest(this.allowedFilters, this.filters),
        },
      })
        .then(({ total }) => {
          this.total = total;
          this.loading = false;
        });
    },
  },
};
</script>
