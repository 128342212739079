<template>
  <section
    class="ubuMessageWrapper"
    :isMe="isMe"
  >
    <div class="messageRow is-justify-content-flex-end">
      <div
        :class="[{
          'columns is-vcentered is-justify-content-flex-end mr-0': isMe && !message.originalId}]"
      >
        <div
          :class="[
            'ubuMessageCard',
            {'has-text-secondary': !message.originalId && !message.errorDescription},
            {'bg-color-2': !message.originalId && message.errorDescription}
          ]"
        >
          <div class="ubuMessageCard-content">
            <template
              v-for="(text, index) in formatedTextArray"
            >
              <span
                v-if="!text.isLink"
                :key="index"
              >{{ text.value }}</span>
              <a
                v-else
                :key="index"
                :href="text.url"
                target="_blank"
              >{{ text.value }}</a>
            </template>
          </div>
          <UbuThreadMessageFooter :message="message" />
        </div>
        <b-button
          v-if="isMe && !message.originalId && message.errorDescription"
          clickable
          :loading="isLoading"
          icon-pack="ubu"
          icon-right="ubu-refresh"
          type="is-tertiary"
          class="is-flex is-vcentered ml-2"
          @click="retrySendingMessage()"
        />
      </div>
      <div
        v-if="!message.originalId && !message.errorDescription"
        class="has-text-secondary is-size-7 has-text-right mt-1"
      >
        Sending...
      </div>
      <div
        v-else-if="!message.originalId && message.errorDescription"
        class="has-text-danger is-size-7 has-text-right mt-1 errorBox"
      >
        <b-tooltip
          class="errorMsg"
          :label="message.errorDescription"
          append-to-body
        >
          <div class="ellipsis">Error while sending. {{ message.errorDescription }}</div>
        </b-tooltip>
      </div>
    </div>
    <b-modal
      v-model="isRetryModalActive"
      @close="$emit('close')"
    >
      <div class="modal-card">
        <header class="modal-card-head multibar">
          <div class="start modal-card-title">Error while sending message</div>
          <div class="end buttons">
            <b-button
              type="is-secondary"
              label="Ok"
              @click="isRetryModalActive = !isRetryModalActive"
            />

            <b-button
              type="is-primary"
              tag="button"
              label="Contact support"
              @click="openIntercom()"
            />
          </div>
        </header>
        <div class="modal-card-body">
          <div v-if="canUsePrivateApi">
            Oops, an error occured!
            Please check that your Ubu's Instagram Extension
            is correctly configured and contact our support.
          </div>
          <div v-else>
            Instagram is unable to retry sending the message.
            You can contact our support in order to activate
            your Ubu's Instagram Extension to retry to send your message.
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CommonMixin from '@dailyplanet/mixins/CommonMixin';

export default {
  name: 'UbuThreadMessageText',
  mixins: [CommonMixin],
  props: {
    isMe: {
      type: Boolean,
      default: false,
      require: true,
    },
    message: {
      type: Object,
      default: () => {},
      require: true,
    },
  },
  data() {
    return {
      isRetryModalActive: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      threadDetails: 'TheInbox/threadDetails',
      myChannelPrivate: 'TheInbox/myChannelPrivate',
    }),
    canUsePrivateApi() {
      return this.myChannelPrivate && this.myChannelPrivate.platformName === 'INSTAGRAM' && this.myChannelPrivate.token;
    },
    formatedTextArray() {
      let textToUse = '';

      if (this.message.text) textToUse = this.message.text;
      // l'ordre des if à son importance, on veut en priorité utiliser la key translatedText (if exist)
      if (this.message.translatedText) textToUse = this.message.translatedText;

      return this.strToLinkArray(textToUse);
    },
  },
  methods: {
    ...mapActions({
      sendMessage: 'TheInbox/sendMessage',
      postReachMessage: 'TheTextarea/postReachMessage',
      deleteHoldedMessage: 'TheTextarea/deleteHoldedMessage',
      setHoldedMessage: 'TheInbox/setHoldedMessage',
    }),
    retrySendingMessage() {
      const {
        thread: {
          channelCid: cid,
          tid,
        }, contactPlatform: {
          cpid,
          username,
        },
      } = this.threadDetails;
      this.isLoading = true;
      if (this.canUsePrivateApi) {
        return this.postReachMessage({
          payload: {
            cid: this.myChannelPrivate.cid, to: username, message: this.message.text,
          },
        })
          .then(() => {
            this.deleteHoldedMessage({ payload: { tid } });
            this.isLoading = false;
          })
          .catch((error) => {
            this.setHoldedMessage({
              payload: {
                message: this.message.text, tid, error: error.message, retry: 'private',
              },
            });
            this.isRetryModalActive = true;
            this.isLoading = false;
            throw error;
          });
      }

      this.setHoldedMessage({ payload: { message: this.message.text, tid } });
      return this.sendMessage({
        payload: {
          cid, tid, cpid, text: this.message.text,
        },
      })
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.setHoldedMessage({
            payload: {
              message: this.message.text, tid, error: error.message, retry: 'instagram',
            },
          });
          this.isRetryModalActive = true;
          this.isLoading = false;
          throw error;
        });
    },
    openIntercom() {
      this.isRetryModalActive = false;
      if (this.canUsePrivateApi) {
        this.$intercom.showNewMessage('Hi! I can\'t retry to send a message with the Ubu\'s Meta Extension! 😢');
      } else {
        this.$intercom.showNewMessage('Hi! I would like to activate the Ubu\'s Meta Extension! 🙌');
      }
    },
  },
};
</script>
