export default {
  data() {
    return {
      columns: {
        plid: {
          col: 'plid', label: 'plid', visible: false, isSortable: true, cmp: 'flat',
        },
        targetUsername: {
          col: 'targetUsername', label: 'Username', visible: true, isSortable: true, cmp: 'flat',
        },
        resultUsernameCount: {
          col: 'resultUsernameCount', label: 'Count usernames', visible: false, isSortable: true, cmp: 'flat',
        },
        createdOn: {
          col: 'createdOn', label: 'Created On', visible: true, isSortable: true, cmp: 'date',
        },
        updatedOn: {
          col: 'updatedOn', label: 'Updated On', visible: true, isSortable: true, cmp: 'date',
        },
        allowedOn: {
          col: 'allowedOn', label: 'Allowed On', visible: true, isSortable: true, cmp: 'date',
        },
      },
    };
  },
};
