<template>
  <div
    id="TheSetting"
    class="has-coreview-spaced kara"
  >
    <div class="ubuSettingView">
      <header>
        <div class="multibar">
          <p class="start title">Settings</p>
          <div class="buttons end">
            <b-button
              class="end"
              type="is-primary"
              label="Logout"
              @click="disconnect()"
            />
          </div>
        </div>
      </header>
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheSetting',
  computed: {
    routeName() {
      return this.$route.meta.title;
    },
  },
  methods: {
    ...mapActions({
      logout: 'TheSetting/logOut',
    }),

    disconnect() {
      this.logout()
        .then(() => { window.location.assign('/auth/login'); });
    },
  },
};
</script>
