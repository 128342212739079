import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions({
      createAccountLinkChannel: 'TheWorkspaceCommon/createAccountLinkChannel',
      createAccountLinkChannelGroup: 'TheWorkspaceCommon/createAccountLinkChannelGroup',
      createAccountUnlinkChannel: 'TheWorkspaceCommon/createAccountUnlinkChannel',
      createAccountUnlinkChannelGroup: 'TheWorkspaceCommon/createAccountUnlinkChannelGroup',
      up_linkChannelGroupUser: 'TheWorkspaceCommon/up_linkChannelGroupUser',
      up_unlinkChannelGroupUser: 'TheWorkspaceCommon/up_unlinkChannelGroupUser',
      up_linkChannelUser: 'TheWorkspaceCommon/up_linkChannelUser',
      up_unlinkChannelUser: 'TheWorkspaceCommon/up_unlinkChannelUser',
    }),
    updateGroup({
      event: { children, ...channelGroup }, uid, account = null,
    }) {
      return Promise.resolve()
        .then(() => this.updateChannelGroupUser(children, uid, channelGroup, account))
        .then(() => this.updateChannelUser(children, uid, account));
    },
    updateChannelGroupUser(newChildren, uid, _, account = null) {
      const userInitialData = account.find((user) => user.uid === uid);

      console.log('userInitialData', userInitialData);

      const actualActives = userInitialData.activeChannelGroup || [];

      const _new = newChildren.reduce((acc, cg) => {
        if (cg.isActive && !actualActives.includes(cg.channelGroupCgid)) {
          if (!acc.toBeLinked.includes(cg.channelGroupCgid)) {
            return { ...acc, toBeLinked: [...acc.toBeLinked, cg.channelGroupCgid] };
          }
          return acc;
        }
        if (!cg.isActive && actualActives.includes(cg.channelGroupCgid)) {
          const findChannelActive = newChildren.find((c) => c.isActive);
          if (!findChannelActive && !acc.toBeUnlinked.includes(cg.channelGroupCgid)) {
            return { ...acc, toBeUnlinked: [...acc.toBeUnlinked, cg.channelGroupCgid] };
          }
          return acc;
        }
        return acc;
      }, { toBeLinked: [], toBeUnlinked: [] });

      return Promise.all(
        _new.toBeLinked.map((cgid) => this.createAccountLinkChannelGroup({
          payload: { aid: userInitialData.aid, uid, cgid },
        })
          .then(() => {
            this.up_linkChannelGroupUser({
              toBeLinked: _new.toBeLinked, aid: userInitialData.aid, uid,
            });
          }))
          .concat(_new.toBeUnlinked.map((cgid) => this.createAccountUnlinkChannelGroup({
            payload: { aid: userInitialData.aid, uid, cgid },
          })
            .then(() => {
              this.up_unlinkChannelGroupUser({
                toBeUnlinked: _new.toBeUnlinked, aid: userInitialData.aid, uid,
              });
            }))),
      );
    },
    updateChannelUser(newChildren, uid, account = null) {
      const userInitialData = account.find((user) => user.uid === uid);

      const actualActives = userInitialData.activeChannel || [];

      const _new = newChildren.reduce((acc, channel) => {
        if (channel.isActive && !actualActives.includes(channel.cid)) {
          return { ...acc, toBeLinked: [...acc.toBeLinked, channel.cid] };
        }
        if (!channel.isActive && actualActives.includes(channel.cid)) {
          return { ...acc, toBeUnlinked: [...acc.toBeUnlinked, channel.cid] };
        }
        return acc;
      }, { toBeLinked: [], toBeUnlinked: [] });

      return Promise.all(
        _new.toBeLinked.map((cid) => this.createAccountLinkChannel({
          payload: { aid: userInitialData.aid, uid, cid },
        })
          .then(() => {
            this.up_linkChannelUser({
              toBeLinked: _new.toBeLinked, aid: userInitialData.aid, uid,
            });
          }))
          .concat(_new.toBeUnlinked.map((cid) => this.createAccountUnlinkChannel({
            payload: { aid: userInitialData.aid, uid, cid },
          })
            .then(() => {
              this.up_unlinkChannelUser({
                toBeUnlinked: _new.toBeUnlinked, aid: userInitialData.aid, uid,
              });
            }))),
      );
    },
  },
};
