import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    tremendousFundingSourceList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiTremendous/tremendousFundingSource/getterTremendousFundingSourceList': tremendousFundingSourceList } = rootGetters;

      return tremendousFundingSourceList;
    },

    defaultTremendousFundingSourceTfsid: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiTremendous/tremendousFundingSource/getterdefaultTremendousFundingSourceTfsid': defaultTremendousFundingSourceTfsid } = rootGetters;

      return defaultTremendousFundingSourceTfsid;
    },
  },
  actions: {
    reportTremendousFundingSource({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTremendous/tremendousFundingSource/report',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'report tremendousFundingSource list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report tremendous funding source list.', indefinite: true }));
    },

    setDefaultTremendousFundingSource({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTremendous/tremendousFundingSource/setDefault',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'update tremendousFundingSource list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to update tremendous funding source.', indefinite: true }));
    },

    refreshTremendousFundingSource({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTremendous/tremendousFundingSource/refresh',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'refresh tremendousFundingSource list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to refresh tremendous funding source list.', indefinite: true }));
    },
  },
};
