<template>
  <div class="ubuCampaignCard multibar row-vcentered">
    <div>
      <b-icon
        :class="`ubu-custom-influence text-color-${campaign.color}`"
        size="is-large"
      />
    </div>
    <div>
      <p class="subtitle no-margin">{{ campaign.name }}</p>
      <p
        v-if="showDate"
        class="subtext has-text-secondary"
      >
        {{ $moment(campaign.createdOn).format('YYYY-MM-DD') }}
      </p>
    </div>

    <slot name="actions" />
  </div>
</template>

<script>
export default {
  name: 'UbuCampaignCard',
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    showDate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
