import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheRewardBulkPanel from './TheRewardBulkPanel';
import TheCrewCartouche from './TheCrewCartouche';
import TheRewardMarkAsPaidPanel from './TheRewardMarkAsPaidPanel';
import TheRewardDetailsPanel from './TheRewardDetailsPanel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheRewardBulkPanel);
    registerComponent(Vue, TheCrewCartouche);
    registerComponent(Vue, TheRewardMarkAsPaidPanel);
    registerComponent(Vue, TheRewardDetailsPanel);
  },
};

use(Plugin);

export default Plugin;

export {
  TheRewardBulkPanel,
  TheCrewCartouche,
  TheRewardMarkAsPaidPanel,
  TheRewardDetailsPanel,
};
