import { clark } from '../../../providers';

import connectMetaChannel from './connectMetaChannel.store';
import channel from './channel.store';
import contact from './contact.store';
import counter from './counter.store';
import crmList from './crmList.store';
import contactPrivateMetadata from './contactPrivateMetadata.store';
import tryConnectMeta from './tryConnectMeta.store';
import searchContactPlatformUsername from './searchContactPlatformUsername.store';
import reportDetailledActivityContactPanel from './reportDetailledActivityContactPanel.store';
import logbook from './logbook.store';
import user from './user.store';
import bulkThreadsState from './bulkThreadsState';
import exportAnalytics from './exportAnalytics.store';
import instagramAccountNewFollowers from './instagramAccountNewFollowers.store';
import instagramAccountImpressions from './instagramAccountImpressions.store';
import analyticsInbox from './analyticsInbox.store';
import hashtag from './hashtag.store';
import accountAnalytics from './accountAnalytics.store';
import followersEngagementAnalytics from './followersEngagementAnalytics.store';
import searchInstagramUsername from './searchInstagramUsername.store';
import contactLocation from './contactLocation.store';
import contactLanguage from './contactLanguage.store';
import bulkThreadAppoint from './bulkThreadAppoint.store';
import threadAndParticipant from './threadAndParticipant.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    update(_, { payload }) {
      return clark.api.channelGroup.put({ payload, httpQuery: {} });
    },
    create(_, { payload, httpQuery = {} }) {
      return clark.api.channelGroup.post({ payload, httpQuery });
    },
  },
  modules: {
    channel,
    connectMetaChannel,
    tryConnectMeta,
    contact,
    counter,
    crmList,
    contactPrivateMetadata,
    searchContactPlatformUsername,
    reportDetailledActivityContactPanel,
    logbook,
    user,
    bulkThreadsState,
    exportAnalytics,
    instagramAccountNewFollowers,
    instagramAccountImpressions,
    analyticsInbox,
    hashtag,
    accountAnalytics,
    followersEngagementAnalytics,
    searchInstagramUsername,
    contactLocation,
    contactLanguage,
    bulkThreadAppoint,
    threadAndParticipant,
  },
};
