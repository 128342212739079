<template>
  <b-tooltip
    size="is-large"
    :position="tooltipPosition"
    :class="overrideTooltipPosition"
  >
    <template v-slot:content>
      <span v-if="symbol">{{ symbol }}</span>
      <span>{{ stat | nFormatter }} {{ statType }}</span>
    </template>
    <template>
      <div :class="`ubuCardDisplayStat bg-color-${color}`">
        <b-icon
          pack="ubu"
          :icon="icon"
        />
      </div>
    </template>
  </b-tooltip>
</template>

<script>
export default {
  name: 'UbuDisplayStat',
  props: {
    stat: {
      type: [Number, String],
      required: true,
    },
    statType: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'ubu-money',
    },
    tooltipPosition: {
      type: String,
      default: 'is-bottom',
    },
    overrideTooltipPosition: {
      type: String,
      default: 'is-bottom-left',
    },
    color: {
      type: Number,
      default: 5,
    },
    symbol: {
      type: String,
      default: null,
    },
  },
};
</script>
