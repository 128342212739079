/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    activityStatusList: {},
  },
  mutations: {
    SET_ACTIVITY_STATUS_LIST(state, newData) { state.activityStatusList = newData; },
    RESET_STORE(state) {
      state.activityStatusList = {};
    },
  },
  getters: {
    getterActivityStatusList: (state) => state.activityStatusList,
  },
  actions: {
    report({ commit }, { payload, httpQuery }) {
      return clark.api.apiInfluence.activityStatus.report({ payload, httpQuery })
        .then((activityStatusList) => {
          commit('SET_ACTIVITY_STATUS_LIST', activityStatusList.arrayMapper('status'));
        });
    },
  },
  modules: {},
};
