<template>
  <TheSimplePanel
    closing-way="emit"
    @close="$emit('close')"
  >
    <template #header>
      <h3 class="subtext has-text-secondary">Auto-report</h3>
      <h1 class="title is-1">Setup your auto-report</h1>
    </template>

    <template #content>
      <b-message
        title=""
        type="is-classic"
        :closable="false"
      >
        You will receive an email every Sunday with a
        report of all your mentions of the week.
      </b-message>

      <br>

      <form @submit.prevent="saveAutoReport()">
        <b-field label="Get weekly report">
          <b-switch
            v-model="newAutoReport.isActive"
          />
        </b-field>

        <br>

        <b-field label="Emails">
          <b-input
            v-model="newAutoReport.email"
            placeholder="One email per line..."
            type="textarea"
            :required="newAutoReport.isActive"
          />
        </b-field>

        <br>

        <div class="buttons is-right">
          <b-button
            native-type="submit"
            label="Save"
            class="is-primary"
          />
        </div>
      </form>
    </template>
  </TheSimplePanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheAutoReportPanel',
  data() {
    return {
      newAutoReport: {
        email: '',
        isActive: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      _autoReportTask: 'TheAutoReportPanel/autoReportTask',
    }),
  },
  mounted() {
    this._reportAutoReportTask()
      .then(() => {
        if (!this._autoReportTask) return;
        const { recipients } = this._autoReportTask;

        this.newAutoReport.isActive = true;
        this.newAutoReport.email = recipients.join('\n');
      });
  },
  methods: {
    ...mapActions({
      _reportAutoReportTask: 'TheAutoReportPanel/reportAutoReportTask',
      _createAutoReportTask: 'TheAutoReportPanel/createAutoReportTask',
      _deleteAutoReportTask: 'TheAutoReportPanel/deleteAutoReportTask',
    }),

    saveAutoReport() {
      // if !isActive && !(a task exists), we just return
      if (!this.newAutoReport.isActive && !this._autoReportTask) return undefined;

      // if !isActive && a task exists, we desactive autoReport
      if (!this.newAutoReport.isActive && this._autoReportTask) {
        const { tid } = this._autoReportTask;

        return this._deleteAutoReportTask({ payload: { tid } })
          .then(() => {
            this.newAutoReport.email = '';

            this.$buefy.snackbar.open({
              message: 'Auto-report successfully disabled.',
              ...snackbarSuccess,
            });
          });
      }

      // else create task && repopulate email & isActive fields by fresh data
      if (this.newAutoReport.isActive) {
        return this._createAutoReportTask({ payload: { recipients: this.newAutoReport.email.replaceAll(' ', '').split('\n') } })
          .then(() => {
            if (!this._autoReportTask) return;

            const { recipients } = this._autoReportTask;

            this.newAutoReport.isActive = true;
            this.newAutoReport.email = recipients.join('\n');

            this.$buefy.snackbar.open({
              message: 'Auto-report successfully subscribed.',
              ...snackbarSuccess,
            });
          });
      }
      return undefined;
    },
  },
};
</script>
