<template>
  <b-table
    ref="table"
    :data="scrapingZoneList"
    paginated
    :per-page="20"
    :opened-detailed="defaultOpenedDetails"
    detailed
    detail-key="szid"
    :show-detail-icon="true"
    :default-sort="['szid']"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    :debounce-search="1000"
    class="scrollable"
    @check="$emit('checkRow', $event)"
    @check-all="$emit('checkAllRows', $event)"
  >
    <b-table-column
      v-slot="props"
      sortable
      searchable
      field="szid"
      label="name"
    >
      {{ props.row.szid }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      sortable
      searchable
      field="label"
      label="label"
    >
      {{ props.row.label }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      sortable
      field="createdOn"
      label="createdOn"
    >
      {{ $moment(props.row.createdOn).format('YY-MM-DD') }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      sortable
      field="updatedOn"
      label="updatedOn"
    >
      {{ $moment(props.row.updatedOn).format('YY-MM-DD') }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      sortable
      field="terminatedOn"
      label="terminatedOn"
    >
      {{ props.row.terminatedOn ?
        $moment(props.row.terminatedOn).format('YY-MM-DD') : props.row.terminatedOn }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      field="action"
      label="action"
    >
      <b-button
        type="is-danger"
        icon-left="delete"
        @click="$emit('deleteScrapingZone', props.row.szid)"
      />
    </b-table-column>

    <!-- <template #detail="props">
      <UbuSalesContactTableDetail
        v-model="props.row"
        @updateContact="$emit('updateContact', $event)"
        @createLead="$emit('createLead', $event)"
        @updateLead="$emit('updateLead', $event)"
      />
    </template> -->
  </b-table>
</template>

<script>
import CommonMixin from '@dailyplanet/mixins/CommonMixin';

export default {
  name: 'UbuScrapingZoneTable',
  mixins: [CommonMixin],
  props: {
    scrapingZoneList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      defaultOpenedDetails: [],
    };
  },
  methods: {
    sortInt8: (key) => (a, b, isAsc) => (isAsc
      ? Number(a[key]) > Number(b[key])
      : Number(b[key]) > Number(a[key])),
  },
};
</script>
