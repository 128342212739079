var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-menu-item',{class:'popover popover-item-custom' +
      [_vm.disabled ? ' is-disabled' : ''] +
      [_vm.hovered ? ' is-hovered' : ''] +
      [_vm.focused ? ' is-focused' : ''],attrs:{"expanded":""},on:{"click":function($event){return _vm.$emit('getDatas', _vm.event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"is-flex is-align-items-center"},[(_vm.iconLeft)?_c('b-icon',{class:'popover-icon-left' +
            [_vm.color && !_vm.disabled ? ` text-color-${_vm.color}` : ''] +
            [_vm.color && _vm.disabled ? ` text-color-${_vm.color}-40` : ''],attrs:{"pack":"ubu","icon":_vm.iconLeft.icon}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.name)+" ")])],1),(_vm.validated)?_c('b-icon',{class:[_vm.color && !_vm.disabled ? ` text-color-${_vm.color}` : ''] +
          [_vm.color && _vm.disabled ? ` text-color-${_vm.color}-40` : ''],attrs:{"pack":"ubu","icon":"ubu-check"}}):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }