/* eslint-disable max-len */
import { actions } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  getters: {},
  actions: {
    ...actions.campaign,
    ...actions.bulkActivity,
  },
};
