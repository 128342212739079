<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="buttons">
      <b-button
        v-if="activeTab === 0"
        class="is-ghost is-borderless"
        icon-pack="ubu"
        icon-left="ubu-arrow-left"
        label="Back to workspace"
        tag="router-link"
        to="/setting/organization"
      />

      <!-- <b-button
        v-else
        class="is-ghost is-borderless"
        icon-pack="ubu"
        icon-left="ubu-arrow-left"
        label="Previous step"
        @click="activeTab =-1"
      /> -->
    </div>

    <DevDetails :cnt="{name: 'newWorkspace', obj: newWorkspace}" />

    <b-tabs
      v-model="activeTab"
      class="no-headers"
    >
      <UbuAddWorkspaceInfoTab
        v-model="newWorkspace"
        @create="createChannelGroup()"
      />

      <!-- <UbuAddWorkspaceChannelTab v-model="newWorkspace" /> -->

      <b-tab-item>
        <section class="ubuWizardView">
          <header>
            <p class="arianne">Add workspace - Step 2/2</p>

            <h1 class="title">Workspace added</h1>
          </header>

          <div class="buttons is-right">
            <b-button
              class="is-secondary"
              label="Back"
              tag="router-link"
              to="/setting/organization"
            />

            <b-button
              label="Link channel"
              type="is-primary"
              :disabled="!createdChannelGroup"
              @click="redirectToLinkChannel()"
            />
          </div>
        </section>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheSettingAddWorkspace',
  data() {
    return {
      activeTab: 0,
      newWorkspace: {
        name: null,
        picture: null,
      },
      createdChannelGroup: null,
    };
  },
  methods: {
    ...mapActions({
      _createChannelGroup: 'TheSettingOrganizationSetting/createChannelGroup',
      UP_channelGroup: 'TheMenu/UP_channelGroup',
    }),
    nextTab() {
      this.activeTab += 1;
    },
    createChannelGroup() {
      this._createChannelGroup({ payload: { ...this.newWorkspace } })
        .then(({ response }) => {
          this.createdChannelGroup = response;
          this.nextTab();
        });
    },
    redirectToLinkChannel() {
      if (!this.createdChannelGroup) return undefined;

      return this.UP_channelGroup(this.createdChannelGroup)
        .then(() => { this.$router.push('/setting/channel'); });
    },
  },
};
</script>
