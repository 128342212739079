<template>
  <form @submit.prevent="checkValidity()">
    <fieldset class="is-bordered">
      <h3 class="subtitle is-3">First, select a folder 👇</h3>

      <p class="subtext is-3 has-text-secondary">
        Folders help you keep your codes organized as you wish (types, values, campaigns, rewards etc...)
      </p>

      <br>

      <b-field>
        <TheAffiliationCodeFolderDropdown
          v-model="discount.affiliationCodeFolderAcfid"
          :can-create="true"
        />
      </b-field>
    </fieldset>

    <br>

    <fieldset class="is-bordered">
      <h3 class="subtitle is-3">Code name</h3>

      <b-field>
        <b-input
          ref="inputCodeName"
          v-model="localDiscount.code"
          placeholder="SUMMER10"
          required
          pattern="[A-Z0-9]*"
          validation-message="Only uppercase and numbers are allowed, without spaces"
          @input="$update('code', $event)"
        />
      </b-field>

      <p
        v-if="codeAlreadyExists"
        class="inputHelper help is-danger"
      >
        A discount code name must be unique on your shop.
        This name is already taken.
      </p>
    </fieldset>

    <br>

    <fieldset class="is-bordered">
      <h3 class="subtitle is-3">Code type</h3>

      <b-field 
        v-for="item in typeList"
        :key="item.value"
      >
        <b-radio 
          v-model="localDiscount.type"
          :native-value="item.value"
          @input="$update('type', $event)"
          >
          {{ item.label }}
        </b-radio>
      </b-field>
    </fieldset>

    <br>

    <fieldset
      v-if="localDiscount.type === 'basic'"
      class="is-bordered"
    >
      <h3 class="subtitle is-3">Code value</h3>

       <b-field 
        v-for="item in amountTypeList"
        :key="item.value"
      >
        <b-radio 
          v-model="localDiscount.amountType"
          :native-value="item.value"
          @input="$update('amountType', $event)"
          >
          {{ item.label }}
        </b-radio>
      </b-field>

      <br>

      <b-field
        v-if="localDiscount.amountType === 'percentage'"
        label="Coupon value"
        expanded
        grouped
      >
        <b-numberinput
          v-model="localDiscount.amount"
          min="0"
          :max="100"
          placeholder="10"
          required
          expanded
          step="0.01"
          :controls="false"
          @wheel.native="preventScroll($event)"
          @input="$update('amount', $event)"
        />
        <p class="control">
          <b-button label="%" />
        </p>
      </b-field>

      <b-field
        v-else
        label="Value"
      >
        <b-numberinput
          v-model="localDiscount.amount"
          min="0"
          placeholder="10"
          required
          expanded
          step="0.01"
          :controls="false"
          @wheel.native="preventScroll($event)"
          @input="$update('amount', $event)"
        />
      </b-field>
    </fieldset>

    <br>

    <fieldset class="is-bordered">
      <h3 class="subtitle is-3">Limits & conditions</h3>

      <b-field>
        <b-switch
          v-model="localDiscount.hasUsageLimit"
          class="has-text-secondary"
          @input="$update('hasUsageLimit', $event)"
        >
          Limit number of times this discount can be used in total
        </b-switch>
      </b-field>

      <b-field
        v-if="localDiscount.hasUsageLimit"
        class="is-child"
      >
        <b-numberinput
          v-model="localDiscount.usageLimit"
          placeholder="1"
          :controls="false"
          :required="localDiscount.hasUsageLimit"
          @input="$update('usageLimit', $event)"
        />
      </b-field>

      <br>
      
      <b-field>
        <b-switch
          v-model="localDiscount.appliesOncePerCustomer"
          class="has-text-secondary"
          @input="$update('appliesOncePerCustomer', $event)"
        >
          Only one use per customer
        </b-switch>
      </b-field>

      <br>

      <b-field>
        <b-switch
          v-model="localDiscount.hasMinPrice"
          class="has-text-secondary"
          @input="$update('hasMinPrice', $event)"
        >
          Only with minimum purchase price
        </b-switch>
      </b-field>

      <b-field
        v-if="localDiscount.hasMinPrice"
        class="is-child"
      >
        <b-numberinput
          v-model="localDiscount.prerequisiteSubtotalMin"
          placeholder="0.00"
          :controls="false"
          :required="localDiscount.hasMinPrice"
          @input="$update('prerequisiteSubtotalMin', $event)"
        />
      </b-field>
    </fieldset>

    <br>

    <fieldset class="is-bordered">
      <h3 class="subtitle is-3">Activation date</h3>

      <b-field grouped>
        <b-field
          label="Activation date and time"
          expanded
        >
          <b-datetimepicker
            ref="startsAtPicker"
            v-model="localDiscount.startsAt"
            placeholder="Select"
            horizontal-time-picker
            position="is-top-left"
            append-to-body
            :datepicker="{ 'nearby-selectable-month-days': true }"
            :min-datetime="this.$moment().toDate()"
            @input="$update('startsAt', $event); $refs.startsAtPicker.toggle();"
          />
        </b-field>

        <b-field
          label="End date and time (optional)"
          expanded
        >
          <b-datetimepicker
            ref="endsAtPicker"
            v-model="localDiscount.endsAt"
            placeholder="Select"
            horizontal-time-picker
            position="is-top-left"
            append-to-body
            :datepicker="{ 'nearby-selectable-month-days': true }"
            :min-datetime="this.$moment(localDiscount.startsAt).toDate()"
            icon-pack="ubu"
            icon-right="ubu-cross"
            icon-right-clickable
            @icon-right-click="$update('endsAt', null);"
            @input="$update('endsAt', $event); $refs.endsAtPicker.toggle();"
          />
        </b-field>
      </b-field>
    </fieldset>

    <br>

    <div class="buttons is-right">
      <b-button
        label="Create discount"
        type="is-primary"
        native-type="submit"
        :loading="isLoading"
      />
    </div>
  </form>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';
import { snackbarError } from '@dailyplanet/utils/helpers';

export default {
  name: 'UbuShopifyCreateDiscountForm',
  mixins: [VModelMixin],
  model: {
    prop: 'discount',
  },
  props: {
    discount: {
      type: Object,
      required: true,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeList: [
        { label: 'Amount off order', value: 'basic' },
        { label: 'Free Shipping', value: 'shipping' },
      ],
      amountTypeList: [
        { label: 'Percentage', value: 'percentage' },
        { label: 'Fixed value', value: 'static' },
      ],
    };
  },
  computed: {
    localDiscount: {
      get() { return { ...this.discount }; },
      set(val) { this.$emit('input', val); },
    },

    codeAlreadyExists() {
      return this.errorMessage === 'E_UNPROCESSABLE_CHANNEL_GROUP_SSHOP_DISCOUNT_CODE_CODE_ALREADY_EXISTS';
    },
  },
  methods: {
    checkValidity() {
      if (!this.localDiscount.startsAt) {
        this.$buefy.snackbar.open({
          ...snackbarError,
          message: 'You must select an activation date.',
        });

        return;
      }

      this.$emit('create');
    },

    preventScroll(event) {
      // nativement au scroll dans le numberinput on update la value
      // on veut bloquer cette feature, pour ça on force le blur au scroll dedans
      event.target.blur();
    },
  },
};
</script>
