<template>
  <b-tab-item value="root.assignUser.done">
    <header>
      <UbuSearchbar
        v-model="search"
        class="start mb-4"
        placeholder="Search..."
        @clear="search = ''"
      />
    </header>
    <article>
      <UbuActionCard
        v-for="user in filteredList"
        :key="user.uid"
        :class="`mb-4`"
        :label="`${user.userDetail.firstName} ${user.userDetail.lastName}`"
        icon-pack="ubu"
        :icon-right="!user.threadWithoutUser.length ? 'ubu-close' : ''"
        @click="assignUser(user)"
      />
    </article>
  </b-tab-item>
</template>

<script>
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';
import pChain from '@dailyplanet/utils/PromiseChain';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheThreadBulkAppoint',
  mixins: [FilterEngineMixin],
  props: {
    checkedRows: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      activeTab: 'root',
      search: '',
      keyName: 'userUid',
      icon: 'ubu-assign',

      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      userList: 'TheInbox/userList',
      windowWidth: 'TheMenu/getterWindowWidth',
      accountFeatures: 'TheInbox/accountFeatures',
      currentChannelGroup: 'Authenticate/getterCurrentChannelGroup',
    }),

    unassignedByAppoint() {
      const userList = Object.values(this.userList);

      return userList.map((user) => ({
        ...user,
        threadWithoutUser: this.checkedRows.reduce((acc, { tid, hasUser }) => {
          if (!hasUser || (hasUser.userUid !== user.uid)) acc.push(tid);
          return acc;
        }, []),
      }));
    },

    _filterCollection() {
      return {
        searchByUser(raw) {
          return (m) => {
            const search = raw.toLowerCase();
            const { userDetail } = m;
            const firstName = (userDetail.firstName || '').toLowerCase();
            const lastName = (userDetail.lastName || '').toLowerCase();
            if (firstName.includes(search) || lastName.includes(search)) return m;
            return undefined;
          };
        },
      };
    },

    filteredList() {
      const userList = this.unassignedByAppoint;

      if (!userList.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByUser = this.search;

      return this.filterEngine(userList, filtersToApply);
    },

  },
  methods: {
    ...mapActions({
      _setThreadAppoint: 'TheInbox/_setThreadAppoint',
      _unsetThreadAppoint: 'TheInbox/_unsetThreadAppoint',
    }),
    updateFilter(search) {
      if (!search) this.reset();
      else this.search = search;
    },

    assignUser(item) {
      if (!this.accountFeatures('INBOX')) {
        this.segmentTrack('assignThreadNoFeature');
        this.$intercom.showNewMessage('Hi! I would like to activate the INBOX module! 🙌');

        return undefined;
      }

      const mappedCheckeds = this.checkedRows.arrayMapper('tid');

      const toBeAdded = item.threadWithoutUser.map((tid) => mappedCheckeds[tid]);

      if (toBeAdded.length) {
        return pChain(
          toBeAdded.map(({ tid, cpid }) => this._setThreadAppoint({
            payload: { userUid: item.uid, tid },
            mutations: [
              {
                target: 'ApiContact/contactPlatformOverview/UP_upsertAppoint',
                payload: {
                  key: 'hasUser',
                  value: {
                    threadTid: tid,
                    userUid: item.uid,
                    channelGroupCgid: this.currentChannelGroup.cgid,
                  },
                  cpids: [cpid],
                },
              },
            ],
          })),
        );
      }

      return pChain(this.checkedRows.map(({ tid, cpid }) => this._unsetThreadAppoint({
        payload: { userUid: item.uid, tid },
        mutations: [
          {
            target: 'ApiContact/contactPlatformOverview/UP_upsertAppoint',
            payload: {
              key: 'hasUser',
              value: null,
              cpids: [cpid],
            },
          },
        ],
      })));
    },

    reset() {
      this.search = '';
      this.activeTab = 'root';
    },
  },
};
</script>
