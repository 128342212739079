<template>
  <!-- eslint-disable max-len -->
  <b-table
    class="ubuTableSetting"
    :data="allChannelGroupAccount"
    :opened-detailed="defaultOpenedDetails"
    detailed
    detail-key="cgid"
    :has-detailed-visible="() => false"
    hoverable
    :is-row-selectable="() => true"
    :show-header="false"
    @click="toggleOpenedDetails($event)"
  >
    <b-table-column
      v-for="column in columns"
      :key="column.col"
      v-slot="props"
      :label="column.label"
      :field="column.col"
      :cell-class="column.cellClass"
    >
      <template v-if="column.cmp === 'img'">
        <UbuAvatar
          :initial-name="props.row.channelGroup.initials"
          :profile-picture="props.row.channelGroup.picture"
          :size="40"
          is-channel
        />
      </template>

      <template v-if="column.cmp === 'flat'">
        <p class="text is-3 text-truncate">{{ props.row[column.nested][column.col] }}</p>

        <router-link
          class="has-text-primary subtext is-3"
          :to="`/setting/organization/editWorkspace/${props.row.cgid}`"
        >
          Edit workspace
        </router-link>
      </template>

      <template v-if="column.cmp === 'action'">
        <div
          class="multibar"
        >
          <b-icon
            class="end"
            pack="ubu"
            :icon="defaultOpenedDetails.includes(props.row.cgid)
              ? 'ubu-arrow-down'
              : 'ubu-arrow-up'"
          />
        </div>
      </template>
    </b-table-column>

    <template #detail="props">
      <template v-if="Object.values(props.row.channelList).length">
        <legend class="has-text-secondary">CHANNELS</legend>

        <br>

        <div class="is-flex is-flex-direction-column">
          <div
            v-for="(channel, i) in Object.values(props.row.channelList)"
            :key="i"
          >
            <div class="multibar">
              <div class="start is-flex">
                <component
                  :is="`UbuIcon${channel.platformName.capitalize()}`"
                  font-size="20px"
                />
                <p class="ml-2 text is-3 has-text-secondary">
                  {{ channel.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>

      <UbuNoData
        v-else
        :title="
          `No channel in this workspace... Yet ! 🤪`
        "
        subtitle="To add a new channel, log in to your workspace, then go to the Home tab => Settings => Workspace settings / Channels"
        :border="false"
      />
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'UbuOrganizationWorkspace',
  props: {
    allChannelGroupAccount: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      defaultOpenedDetails: [],
    };
  },
  methods: {
    toggleOpenedDetails(workspace) {
      if (this.defaultOpenedDetails.includes(workspace.cgid)) {
        this.defaultOpenedDetails = this.defaultOpenedDetails
          .filter((cgid) => cgid !== workspace.cgid);
      } else {
        this.defaultOpenedDetails.push(workspace.cgid);
      }
    },
  },
};
</script>
