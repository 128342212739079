/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    accountMemberViewList: null,
    accountMemberView: null,
  },
  mutations: {
    SET_ACCOUNT_MEMBER_VIEW_LIST(state, newData) { state.accountMemberViewList = newData; },
    SET_ACCOUNT_MEMBER_VIEW(state, newData) { state.accountMemberView = newData; },
    RESET_STORE(state) {
      state.accountMemberViewList = null;
      state.accountMemberView = null;
    },
  },
  getters: {
    getterAccountMemberViewList: (state) => state.accountMemberViewList,
    getterAccountMemberView: (state) => state.accountMemberView,
  },
  actions: {
    report({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'ACCOUNT_MEMBER_VIEW.REPORT')) return undefined;

      return clark.api.user.accountMemberView.report({ payload, httpQuery: {} })
        .then((accountMemberViewList) => { commit('SET_ACCOUNT_MEMBER_VIEW_LIST', accountMemberViewList.arrayMapper('aid')); });
    },
    get({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'ACCOUNT_MEMBER_VIEW.GET')) return undefined;

      return clark.api.user.accountMemberView.get({ payload, httpQuery: {} })
        .then((accountMemberView) => { commit('SET_ACCOUNT_MEMBER_VIEW', accountMemberView); });
    },
    up_linkChannelGroupUser({ commit, getters }, { toBeLinked, aid }) {
      const newAccountMemberViewList = getters.getterAccountMemberViewList;

      if (!newAccountMemberViewList[aid].activeChannelGroup) newAccountMemberViewList[aid].activeChannelGroup = toBeLinked;
      else toBeLinked.forEach((cgid) => newAccountMemberViewList[aid].activeChannelGroup.push(cgid));

      commit('SET_ACCOUNT_MEMBER_VIEW_LIST', newAccountMemberViewList);
    },
    up_unlinkChannelGroupUser({ commit, getters }, { toBeUnlinked, aid }) {
      const newAccountMemberViewList = getters.getterAccountMemberViewList;

      const activeChannelGroup = newAccountMemberViewList[aid].activeChannelGroup.reduce((acc, cgid) => {
        if (toBeUnlinked.includes(cgid)) return acc;
        acc.push(cgid);
        return acc;
      }, []);

      newAccountMemberViewList[aid].activeChannelGroup = activeChannelGroup;
      commit('SET_ACCOUNT_MEMBER_VIEW_LIST', newAccountMemberViewList);
    },
    up_linkChannelUser({ commit, getters }, { toBeLinked, aid }) {
      const newAccountMemberViewList = getters.getterAccountMemberViewList;

      if (!newAccountMemberViewList[aid].activeChannel) newAccountMemberViewList[aid].activeChannel = toBeLinked;
      else toBeLinked.forEach((cid) => newAccountMemberViewList[aid].activeChannel.push(cid));

      commit('SET_ACCOUNT_MEMBER_VIEW_LIST', newAccountMemberViewList);
    },
    up_unlinkChannelUser({ commit, getters }, { toBeUnlinked, aid }) {
      const newAccountMemberViewList = getters.getterAccountMemberViewList;
      const activeChannel = newAccountMemberViewList[aid].activeChannel.reduce((acc, cid) => {
        if (toBeUnlinked.includes(cid)) return acc;
        acc.push(cid);
        return acc;
      }, []);

      newAccountMemberViewList[aid].activeChannel = activeChannel;
      commit('SET_ACCOUNT_MEMBER_VIEW_LIST', newAccountMemberViewList);
    },
  },
};
