import { use, registerComponent } from '@dailyplanet/utils/plugins';

import JsonReader from './JsonReader';
import UbuCustomButton from './UbuCustomButton';
import GlobalTooltip from './GlobalTooltip';
import UbuAnimatedLogo from './UbuAnimatedLogo';
import UbuModal from './UbuModal';
import UbuModalEditNote from './UbuModalEditNote';

import UbuCollapseChannelGroup from './UbuCollapseChannelGroup';
import UbuIconColorPicker from './UbuIconColorPicker';
import UbuPopoverItemTitle from './UbuPopoverItemTitle';
import UbuPopoverItemSimple from './UbuPopoverItemSimple';
import UbuPopoverItemSelect from './UbuPopoverItemSelect';
import UbuPopoverItemSearch from './UbuPopoverItemSearch';
import UbuPopoverItemTime from './UbuPopoverItemTime';
import UbuPopoverItemCustom from './UbuPopoverItemCustom';
import UbuRealPopover from './UbuRealPopover';
import UbuColorPicker from './UbuColorPicker';
import UbuDragAndDropUploader from './UbuDragAndDropUploader';
import UbuNote from './UbuNote';
import UbuNoteFilter from './UbuNoteFilter';
import UbuAddCustomInline from './UbuAddCustomInline';
import UbuEditCustom from './UbuEditCustom';
import UbuDateRangePicker from './UbuDateRangePicker';
import UbuCustomTable from './UbuCustomTable';
import UbuCustomButtonTable from './UbuCustomButtonTable';
import UbuSearchInstagramUser from './UbuSearchInstagramUser';
import UbuExtensionDisconnected from './UbuExtensionDisconnected';
import UbuSkeleton from './UbuSkeleton';
import UbuSkeletonPipeline from './UbuSkeletonPipeline';
import UbuInlinePlatform from './UbuInlinePlatform';
import PopoverItemCreator from './PopoverItemCreator';
import PopoverItemClickable from './PopoverItemClickable';
import PopoverItemSearch from './PopoverItemSearch';
import UbuDownloadMentions from './UbuDownloadMentions';
import UbuDownloadMentionsForm from './UbuDownloadMentionsForm';
import UbuSearchbar from './UbuSearchbar';

/**
 * CARD
 */
import UbuContactCard from './card/UbuContactCard';
import UbuThreadCard from './card/UbuThreadCard';
import UbuListCard from './card/UbuListCard';
import UbuAnalyticCard from './card/UbuAnalyticCard';
import UbuMentionCard from './card/UbuMentionCard';
import UbuPlatformCard from './card/UbuPlatformCard';
import UbuActionCard from './card/UbuActionCard';
import UbuContactPlatformCard from './card/UbuContactPlatformCard';
import UbuTemplateCard from './card/UbuTemplateCard';
import UbuCustomCard from './card/UbuCustomCard';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, JsonReader);
    registerComponent(Vue, UbuCustomButton);
    registerComponent(Vue, GlobalTooltip);
    registerComponent(Vue, UbuAnimatedLogo);
    registerComponent(Vue, UbuModal);
    registerComponent(Vue, UbuCollapseChannelGroup);
    registerComponent(Vue, UbuIconColorPicker);
    registerComponent(Vue, UbuPopoverItemTitle);
    registerComponent(Vue, UbuPopoverItemSimple);
    registerComponent(Vue, UbuPopoverItemSelect);
    registerComponent(Vue, UbuPopoverItemSearch);
    registerComponent(Vue, UbuPopoverItemTime);
    registerComponent(Vue, UbuPopoverItemCustom);
    registerComponent(Vue, UbuRealPopover);
    registerComponent(Vue, UbuColorPicker);
    registerComponent(Vue, UbuDragAndDropUploader);
    registerComponent(Vue, UbuNote);
    registerComponent(Vue, UbuNoteFilter);
    registerComponent(Vue, UbuAddCustomInline);
    registerComponent(Vue, UbuEditCustom);
    registerComponent(Vue, UbuDateRangePicker);
    registerComponent(Vue, UbuCustomTable);
    registerComponent(Vue, UbuCustomButtonTable);
    registerComponent(Vue, UbuSearchInstagramUser);
    registerComponent(Vue, UbuExtensionDisconnected);
    registerComponent(Vue, UbuSkeleton);
    registerComponent(Vue, UbuSkeletonPipeline);
    registerComponent(Vue, UbuInlinePlatform);
    registerComponent(Vue, PopoverItemCreator);
    registerComponent(Vue, PopoverItemClickable);
    registerComponent(Vue, PopoverItemSearch);
    registerComponent(Vue, UbuDownloadMentions);
    registerComponent(Vue, UbuDownloadMentionsForm);
    registerComponent(Vue, UbuSearchbar);
    registerComponent(Vue, UbuModalEditNote);

    /**
     * CARD
     */
    registerComponent(Vue, UbuContactCard);
    registerComponent(Vue, UbuThreadCard);
    registerComponent(Vue, UbuListCard);
    registerComponent(Vue, UbuTemplateCard);
    registerComponent(Vue, UbuPlatformCard);
    registerComponent(Vue, UbuCustomCard);
    registerComponent(Vue, UbuActionCard);
    registerComponent(Vue, UbuMentionCard);
    registerComponent(Vue, UbuAnalyticCard);
    registerComponent(Vue, UbuContactPlatformCard);
  },
};

use(Plugin);

export default Plugin;

export {
  JsonReader,
  UbuCustomButton,
  GlobalTooltip,
  UbuAnimatedLogo,
  UbuModal,
  UbuCollapseChannelGroup,
  UbuIconColorPicker,
  UbuPopoverItemTitle,
  UbuPopoverItemSimple,
  UbuPopoverItemSelect,
  UbuPopoverItemSearch,
  UbuPopoverItemTime,
  UbuPopoverItemCustom,
  UbuRealPopover,
  UbuColorPicker,
  UbuDragAndDropUploader,
  UbuNote,
  UbuNoteFilter,
  UbuAddCustomInline,
  UbuEditCustom,
  UbuDateRangePicker,
  UbuCustomTable,
  UbuCustomButtonTable,
  UbuSearchInstagramUser,
  UbuExtensionDisconnected,
  UbuSkeleton,
  UbuSkeletonPipeline,
  UbuInlinePlatform,
  PopoverItemCreator,
  PopoverItemClickable,
  PopoverItemSearch,
  UbuDownloadMentions,
  UbuDownloadMentionsForm,
  UbuSearchbar,
  UbuModalEditNote,

  /**
   * CARD
   */
  UbuContactCard,
  UbuThreadCard,
  UbuListCard,
  UbuTemplateCard,
  UbuPlatformCard,
  UbuCustomCard,
  UbuActionCard,
  UbuMentionCard,
  UbuAnalyticCard,
  UbuContactPlatformCard,
};
