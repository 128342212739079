<template>
  <div class="ubuPlatformCard">
    <b-icon
      :icon="`ubu-custom-${iconName}`"
      pack="ubu"
      :class="`start has-text-color-${color}`"
      :font-size="fontSize"
    />
    <span class="start text-truncate">
      {{ text }}
    </span>
    <b-icon
      v-if="actionIconName"
      :class="`actionIcon end has-text-color-${actionIconColor}`"
      pack="ubu"
      :icon="actionIconName"
      :font-size="fontSize"
      @click.native="$emit('actionIconClick', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'UbuCustomCard',
  props: {
    iconName: {
      type: String,
      default: 'influence',
    },
    text: {
      type: String,
      default: 'Followers',
    },
    color: {
      type: String,
      default: '1',
    },
    fontSize: {
      type: String,
      default: '20px',
    },
    actionIconName: {
      type: String,
      default: null,
    },
    actionIconColor: {
      type: String,
      default: '1',
    },
  },
};
</script>
