import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheSendNewMessagePanel from './TheSendNewMessagePanel';
import TheBulkAssignLabelButtons from './TheBulkAssignLabelButtons';
import TheBulkActionsDownloadMentions from './TheBulkActionsDownloadMentions';
import TheComposeMessage from './smarts/TheComposeMessage';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheSendNewMessagePanel);
    registerComponent(Vue, TheBulkAssignLabelButtons);
    registerComponent(Vue, TheBulkActionsDownloadMentions);

    registerComponent(Vue, TheComposeMessage);
  },
};

use(Plugin);

export default Plugin;

export {
  TheSendNewMessagePanel,
  TheBulkAssignLabelButtons,
  TheBulkActionsDownloadMentions,
  TheComposeMessage,
};
