<template>
  <section>
    <b-tab-item value="root.removeFromCampaign">
      <b-message type="is-classic">
        You will still be able to view their messages, stats and mentions,
        but all new mentions and orders will stop being tracked inside this campaign.
      </b-message>

      <div class="buttons is-right">
        <b-button
          label="Cancel"
          type="is-secondary"
          @click="$emit('goTo', 'root')"
        />
        <b-button
          type="is-danger"
          label="Remove from campaign"
          class="is-reverted"
          icon-pack="ubu"
          icon-left="ubu-trash"
          @click="deleteBulkActivity()"
        />
      </div>
    </b-tab-item>

    <b-tab-item value="root.removeFromCampaign.done">
      <b-message type="is-success">
        <div class="multibar row-vcentered">
          <b-icon
            pack="ubu"
            icon="ubu-success"
            size="is-medium"
            class="start"
          />
          <div class="start">
            <p class="">
              Success! It may take a few seconds for people to be removed from your campaign.
            </p>
          </div>
        </div>
      </b-message>
    </b-tab-item>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheBulkRemoveFromCampaign',
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Object,
      required: true,
    },
    tabItems: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      currentCampaignCid: 'TheBulkRemoveFromCampaign/currentCampaignCid',
    }),

    onlyActiveList() {
      return this.checkedRows.filter(({ campaignActivity: { endedOn } }) => !endedOn);
    },
  },
  methods: {
    ...mapActions({
      _deleteBulkActivity: 'TheBulkRemoveFromCampaign/deleteBulkActivity',
    }),

    UP_changeToNewTab(activeTab) {
      this.$emit('update:activeTab', activeTab);
    },

    deleteBulkActivity() {
      this._deleteBulkActivity({
        payload: {
          cid: this.currentCampaignCid,
          aids: this.onlyActiveList.map(({ aid }) => aid),
        },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_endCampaignActivity',
          payload: {
            cpids: this.onlyActiveList.map(({ cpid }) => cpid),
          },
        }],
      })
        .then(() => {
          this.UP_changeToNewTab(this.tabItems['root.removeFromCampaign.done']);
        });
    },
  },
};
</script>
