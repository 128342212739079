/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    latestUpdateList: [],
  },
  mutations: {
    SET_LATEST_UPDATE_LIST(state, newData) { state.latestUpdateList = newData; },
    RESET_STORE(state) {
      state.latestUpdateList = [];
    },
  },
  getters: {
    getterLatestUpdateList: (state) => state.latestUpdateList,
  },
  actions: {
    report({ commit }, { payload, httpQuery }) {
      return clark.api.latestUpdate.report({ payload, httpQuery })
        .then((latestUpdateList) => {
          commit('SET_LATEST_UPDATE_LIST', latestUpdateList);

          return latestUpdateList;
        });
    },
  },
};
