/**
 * API insertMissing utms
 * we need to find utm that already exists to give their upid into dataset
 * if they dont exist just prepare dataset
 */
const prepareUtmToInsertMissing = (utmToInsert, utmList, ssid) => Object.entries(utmToInsert)
  .reduce((acc, row) => {
    const [key, value] = row;

    const alreadyExistUtm = utmList
      .find(({ type, value: _value }) => type === key && _value === value);

    if (value.length && alreadyExistUtm) return [...acc, alreadyExistUtm];

    if (value.length) return [...acc, { shopifyShopSsid: ssid, type: key, value }];

    return acc;
  }, []);

/**
 * Used to get utms from a link & split by type
 */
const utmParamsFromLink = (link) => {
  if (!link.includes('?')) return { source: '', medium: '', campaign: '' };

  // if link has query
  const [, queries] = link.split('?');
  const splitedQueries = queries.split('&');

  // return utms splited by type
  return splitedQueries.reduce((acc, row) => {
    const [key, value] = row.split('=');
    const validKeys = ['source', 'medium', 'campaign'];

    if (validKeys.includes(key)) acc[key] = value;

    return acc;
  }, { source: '', medium: '', campaign: '' });
};

export {
  prepareUtmToInsertMissing,
  utmParamsFromLink,
};
