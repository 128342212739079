<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        :to="`/shopify/shop/${currentSid}/details`"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <h1 class="title is-1">Edit Shop</h1>

    <br>

    <form>
      <UbuEditManageShopifyShopForm
        v-model="currentShop"
      />

      <br>

      <div class="buttons is-right">
        <b-button
          label="Save"
          type="is-primary"
          @click="editShop()"
        />
      </div>
    </form>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheShopDetailsEditPanel',
  model: {
    prop: 'currentShop',
  },
  props: {
    currentShop: { type: Object, default: () => {} },
  },
  computed: {
    currentSid() {
      return this.$route.params.sid;
    },
  },
  methods: {
    ...mapActions({
      updateManageShop: 'TheShop/updateManageShop',
    }),
    editShop() {
      this.updateManageShop({ payload: this.currentShop })
        .then(({ response: shopifyShop }) => {
          if (shopifyShop) this.$router.push(`/shopify/shop/${this.currentSid}/details`);
        });
    },
  },
};
</script>
