// import Vue from 'vue';
import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuAutocomplete from './UbuAutocomplete';
import UbuVectorIcon from './UbuVectorIcon';
import UbuNoData from './UbuNoData';
import UbuAvatar from './UbuAvatar';
import UbuThreadListUserPicture from './UbuThreadListUserPicture';
import UbuScroller from './UbuScroller';
import UbuScrollerV2 from './UbuScrollerV2';
import UbuLikeButton from './UbuLikeButton';
import UbuIconInstagram from './UbuIconInstagram';
import UbuIconTiktok from './UbuIconTiktok';
import UbuIconYoutube from './UbuIconYoutube';
import UbuIconFacebook from './UbuIconFacebook';
import UbuIconSms from './UbuIconSms';
import UbuIconShopify from './UbuIconShopify';
import UbuColorPickerInline from './UbuColorPickerInline';
import UbuHelper from './UbuHelper';
import UbuTableSkeleton from './UbuTableSkeleton';
import UbuSimpleTextarea from './UbuSimpleTextarea';
import UbuIconMeta from './UbuIconMeta';
import UbuBanner from './UbuBanner';
import UbuFavButton from './UbuFavButton';
import UbuBulkActionPanelTrigger from './UbuBulkActionPanelTrigger';
import UbuChartLine from './UbuChartLine';
import UbuChartBar from './UbuChartBar';
import UbuChartDoughnut from './UbuChartDoughnut';
import UbuChartMixed from './UbuChartMixed';
import UbuChartCustom from './UbuChartCustom';
import UbuDropdown from './UbuDropdown';
import UbuDropdownBreadcrumbs from './UbuDropdownBreadcrumbs';
import UbuDropdownFilterForm from './UbuDropdownFilterForm';
import UbuActionCard from './UbuActionCard';

import UbuSearchbar from './UbuSearchbar';
import UbuSubMenuDrawer from './UbuSubMenuDrawer';
import UbuNoData2 from './UbuNoData2';
import UbuMessagePeopleWarning from './UbuMessagePeopleWarning';
import {
  UbuTableShortenNumber,
  UbuTableCampaign,
  UbuTableTrueFalseMarker,
} from './TheTable';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuAutocomplete);
    registerComponent(Vue, UbuVectorIcon);
    registerComponent(Vue, UbuNoData);
    registerComponent(Vue, UbuAvatar);
    registerComponent(Vue, UbuThreadListUserPicture);
    registerComponent(Vue, UbuScroller);
    registerComponent(Vue, UbuScrollerV2);
    registerComponent(Vue, UbuLikeButton);
    registerComponent(Vue, UbuIconInstagram);
    registerComponent(Vue, UbuIconTiktok);
    registerComponent(Vue, UbuIconYoutube);
    registerComponent(Vue, UbuIconFacebook);
    registerComponent(Vue, UbuIconSms);
    registerComponent(Vue, UbuIconShopify);
    registerComponent(Vue, UbuColorPickerInline);
    registerComponent(Vue, UbuHelper);
    registerComponent(Vue, UbuTableSkeleton);
    registerComponent(Vue, UbuSimpleTextarea);
    registerComponent(Vue, UbuIconMeta);
    registerComponent(Vue, UbuBanner);
    registerComponent(Vue, UbuFavButton);
    registerComponent(Vue, UbuBulkActionPanelTrigger);
    registerComponent(Vue, UbuChartLine);
    registerComponent(Vue, UbuChartBar);
    registerComponent(Vue, UbuChartDoughnut);
    registerComponent(Vue, UbuChartMixed);
    registerComponent(Vue, UbuChartCustom);
    registerComponent(Vue, UbuSearchbar);

    registerComponent(Vue, UbuDropdown);
    registerComponent(Vue, UbuDropdownBreadcrumbs);
    registerComponent(Vue, UbuDropdownFilterForm);
    registerComponent(Vue, UbuActionCard);

    registerComponent(Vue, UbuTableShortenNumber);
    registerComponent(Vue, UbuSubMenuDrawer);
    registerComponent(Vue, UbuNoData2);
    registerComponent(Vue, UbuMessagePeopleWarning);
    registerComponent(Vue, UbuTableCampaign);
    registerComponent(Vue, UbuTableTrueFalseMarker);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuAutocomplete,
  UbuVectorIcon,
  UbuNoData,
  UbuAvatar,
  UbuThreadListUserPicture,
  UbuScroller,
  UbuScrollerV2,
  UbuLikeButton,
  UbuIconFacebook,
  UbuIconTiktok,
  UbuIconYoutube,
  UbuIconInstagram,
  UbuIconSms,
  UbuIconShopify,
  UbuColorPickerInline,
  UbuHelper,
  UbuTableSkeleton,
  UbuSimpleTextarea,
  UbuIconMeta,
  UbuBanner,
  UbuFavButton,
  UbuBulkActionPanelTrigger,
  UbuChartLine,
  UbuChartBar,
  UbuChartDoughnut,
  UbuChartMixed,
  UbuChartCustom,

  UbuDropdown,
  UbuDropdownBreadcrumbs,
  UbuDropdownFilterForm,
  // UbuActionCard,

  UbuTableShortenNumber,
  UbuSubMenuDrawer,
  UbuNoData2,
  UbuMessagePeopleWarning,
  UbuTableCampaign,
  UbuTableTrueFalseMarker,
};
