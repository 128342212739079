<!-- eslint-disable max-len -->
<template>
  <article class="ubuContactPlatformPanelItem">
    <div class="multibar row-vcentered">
      <div
        class="start clickable multibar row-vcentered"
        @click="copyToClipBoard(affiliationCode.code)"
      >
        <h3 class="start">{{ affiliationCode.code }}</h3>

        <b-icon
          class="start"
          pack="ubu"
          icon="ubu-copy"
        />
      </div>

      <div class="end buttons multibar row-vcentered">
        <b-icon
          class="clickable"
          size="overide-custom"
          icon="link-variant"
          @click.native="copyToClipBoard(linkToUse)"
        />
        <b-icon
          class="clickable"
          pack="ubu"
          icon="ubu-edit"
          @click.native="$emit('edit', affiliationCode)"
        />
      </div>
    </div>

    <h2 class="subtitle is-3 has-text-secondary">
      {{ affiliationCodeFolderList[affiliationCode.affiliationCodeFolderAcfid].title }}
    </h2>

    <div class="tile is-ancestor is-vertical">
      <div class="tile is-parent is-horizontal">
        <div class="tile is-child is-4">
          <p class="subtext is-3 has-text-secondary pb-4">Revenue 💰</p>

          <p class="text is-3">
            <span v-if="affiliationCode.totalRevenue">$</span>
            {{ affiliationCode.totalRevenue | nDecimalFormatter }}
          </p>
        </div>

        <div class="tile is-child is-4">
          <p class="subtext is-3 has-text-secondary pb-4">Orders 🛍</p>

          <p class="text is-3">
            {{ affiliationCode.totalOrders | nFormatter }}
          </p>
        </div>

        <div class="tile is-child is-4">
          <p class="subtext is-3 has-text-secondary pb-4">Click count 🖱</p>

          <p class="text is-3">
            {{ codeHasShortLink ? affiliationCode.shortLink.clickCount : 0 | nFormatter }}
          </p>
        </div>
      </div>
    </div>

    <div class="tile is-ancestor is-vertical">
      <div class="tile is-parent is-horizontal">
        <div class="tile is-child is-4">
          <p class="subtext is-3 has-text-secondary pb-4">Type ℹ️</p>

          <p class="text is-3">
            {{ affiliationCode.type | capitalize }}
          </p>
        </div>

        <div class="tile is-child is-4">
          <p class="subtext is-3 has-text-secondary pb-4">Campaign 🚀</p>

          <div
            v-if="affiliationCode.campaignCid"
            class="multibar row-vcentered"
          >
            <b-icon
              pack="ubu"
              icon="ubu-custom-influence"
              :class="`start text-color-${campaignList[affiliationCode.campaignCid].color}`"
              size="is-small"
            />

            <p class="start text text-truncate is-3 ml-2">
              {{ campaignList[affiliationCode.campaignCid].name }}
            </p>
          </div>

          <span v-else>-</span>
        </div>
        <div class="tile is-child is-4">
          <p class="subtext is-3 has-text-secondary pb-4">Commission 💵</p>

          <p v-if="affiliationCode.commissionRate" class="text is-3">
            {{ affiliationCode.commissionRate * 100 }}%
          </p>
          <b-tooltip v-else-if="shopList[affiliationCode.shopSid].commissionRate" label="Default shop commission">
            <p class="text is-3 has-text-primary">
              {{ shopList[affiliationCode.shopSid].commissionRate * 100 }}%
            </p>
          </b-tooltip>
          <p v-else>-</p>
        </div>
      </div>
    </div>

    <b-collapse v-if="affiliationCode.orderList.length" :open="false">
      <template #trigger="props">
        <b-button
          :label="!props.open ? 'Show orders details' : 'Hide orders details'"
          type="is-ghost is-borderless"
        />
      </template>

      <div class="notification is-secondary">
        <table>
          <tbody>
            <tr v-for="order in sortedOrderList" :key="order.oid">
              <td class="subtext is-3 p-2">Revenue: ${{ order.amountUsd | nDecimalFormatter }}</td>
              <td class="subtext is-3 p-2">Paid on: {{ order.createdOn | toDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-collapse>
  </article>
</template>

<script>
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'UbuContactPlatformPanelAffiliationDetailed',
  props: {
    affiliationCode: {
      type: Object,
      required: true,
    },
    campaignList: {
      type: Object,
      required: true,
    },
    affiliationCodeFolderList: {
      type: Object,
      required: true,
    },
    shopList: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sortedOrderList() {
      if (!this.affiliationCode.orderList.length) return [];

      return [...this.affiliationCode.orderList]
        .sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
    },

    codeHasShortLink() {
      return !!this.affiliationCode.shortLinkSlid;
    },

    linkToUse() {
      if (this.codeHasShortLink) {
        const { subdomain, domain, slid } = this.affiliationCode.shortLink;
        return `https://${subdomain}.${domain}/${slid}`;
      }

      return this.affiliationCode.link;
    },
  },
  methods: {
    copyToClipBoard(item) {
      navigator.clipboard.writeText(item);

      this.$buefy.snackbar.open({
        message: 'Copied to clipboard.',
        ...snackbarSuccess,
      });
    },
  },
};
</script>

<style lang="scss">
.icon {
  &.overide-custom {
    & .mdi-24px.mdi-set, .mdi-24px.mdi:before {
      font-size: 16px;
    }
  }
}
</style>
