var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ubuSettingView start"},[_c('DevDetails',{attrs:{"cnt":{name: 'userAccountMemberViewList', obj: _vm.userAccountMemberViewList}}}),_c('DevDetails',{attrs:{"cnt":{
      name: 'allChannelGroupAccountNewVersion', obj: _vm.allChannelGroupAccountNewVersion
    }}}),_c('header',{staticClass:"start"},[(_vm.detailledUser)?_c('h2',{staticClass:"subtitle is-1 has-text-secondary m-0"},[_vm._v(" "+_vm._s(_vm.detailledUser.firstName)+" "+_vm._s(_vm.detailledUser.lastName)+" ")]):_vm._e(),_vm._m(0)]),_c('br'),(_vm.allChannelGroupAccountNewVersion && _vm.allChannelGroupAccountNewVersion.length)?[_vm._l((_vm.allChannelGroupAccountNewVersion),function(account){return [(account)?_c('b-table',{key:account.aid,staticClass:"ubuTableSetting",attrs:{"data":[account],"opened-detailed":_vm.defaultOpenedDetails,"detailed":"","detail-key":"aid","has-detailed-visible":() => false,"hoverable":"","is-row-selectable":() => true,"show-header":false},on:{"click":function($event){return _vm.toggleOpenedDetails($event)}},scopedSlots:_vm._u([{key:"detail",fn:function(props){return [_c('DevDetails',{attrs:{"cnt":{name: 'props.row', obj: props.row}}}),_c('legend',{staticClass:"has-text-secondary subtitle is-3"},[_vm._v("CHANNELS")]),_vm._l((props.row.editableItem),function(row){return _c('div',{key:row.channelGroup.cgid},[_c('UbuChannelGroupAndChannelSelector',{attrs:{"model":JSON.stringify(row)},on:{"updateGroup":function($event){return _vm.updateGroupNextGen({
                event: $event,
                uid: props.row.uid,
                aid: props.row.aid,
                userMode: true
              })}}})],1)})]}}],null,true)},_vm._l((_vm.columns),function(column){return _c('b-table-column',{key:column.col,attrs:{"label":column.label,"field":column.col,"sortable":column.isSortable,"cell-class":column.cellClass},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(column.cmp === 'flat')?[_c('p',{staticClass:"ellipsis text is-3 mb-1"},[_vm._v(_vm._s(props.row[column.col]))])]:_vm._e(),(column.cmp === 'tag')?[(props.row[column.tag.condition])?_c('b-tag',{attrs:{"type":column.tag.color}},[_vm._v(" "+_vm._s(column.tag.text)+" ")]):_vm._e()]:_vm._e(),(column.cmp === 'action')?[_c('div',{staticClass:"multibar"},[_c('b-icon',{staticClass:"end",attrs:{"pack":"ubu","icon":_vm.defaultOpenedDetails.includes(props.row.uid)
                  ? 'ubu-arrow-down'
                  : 'ubu-arrow-up'}})],1)]:_vm._e()]}}],null,true)})}),1):_vm._e()]})]:[_vm._m(1)]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"multibar"},[_c('h1',{staticClass:"start title is-1"},[_vm._v("Users accounts")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"multibar"},[_c('p',{staticClass:"title is-3 has-text-secondary"},[_vm._v(" This user is not linked to an account... Yet ! 👾 ")])])
}]

export { render, staticRenderFns }