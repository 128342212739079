// import Vue from 'vue';
import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheTagChip from './TheTagChip';
import TheLabelChip from './TheLabelChip';
import TheCrmListChip from './TheCrmListChip';
import TheTribeChip from './TheTribeChip';
import TheCampaignChip from './TheCampaignChip';
// import UbuDropdownSortOptions from './UbuDropdownSortOptions';
// import UbuDropdownBreadcrumbs from './UbuDropdownBreadcrumbs';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheTagChip);
    registerComponent(Vue, TheLabelChip);
    registerComponent(Vue, TheCrmListChip);
    registerComponent(Vue, TheTribeChip);
    registerComponent(Vue, TheCampaignChip);
    // registerComponent(Vue, UbuDropdownSortOptions);
    // registerComponent(Vue, UbuDropdownBreadcrumbs);
  },
};

use(Plugin);

export default Plugin;

export {
  TheTagChip,
  TheLabelChip,
  TheCrmListChip,
  TheTribeChip,
  TheCampaignChip,
  // UbuDropdownSortOptions,
  // UbuDropdownBreadcrumbs,
};
