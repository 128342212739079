import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    contactPlatformOverviewList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiContact/contactPlatformOverview/getterContactPlatformOverviewList': contactPlatformOverviewList } = rootGetters;

      return contactPlatformOverviewList;
    },

    contactPlatformOverview: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiContact/contactPlatformOverview/getterContactPlatformOverview': contactPlatformOverview } = rootGetters;

      return contactPlatformOverview;
    },

    contactPlatformOverviewUnhelded: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiContact/contactPlatformOverview/getterContactPlatformOverviewUnhelded': contactPlatformOverview } = rootGetters;

      return contactPlatformOverview;
    },

    totalPeople: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiContact/contactPlatformOverview/getterTotalPeople': totalPeople } = rootGetters;

      return totalPeople;
    },
  },
  actions: {
    reportContactPlatformOverviewList({ dispatch, rootGetters }, { httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPlatformOverview/report',
        context: { payload: { cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Report contactPlatformOverview list',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report contactPlatformOverview list.', indefinite: true }));
    },

    getContactPlatformOverview({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPlatformOverview/get',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Get contactPlatformOverview',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to get contactPlatformOverview.', indefinite: true }));
    },

    getAndSetCurrentContactPlatformOverview({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPlatformOverview/getAndSetCurrent',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Get contactPlatformOverview',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to get contactPlatformOverview.', indefinite: true }));
    },

    setCurrentContactPlatformOverview({ dispatch }, cpid) {
      console.log('🚀 ~ file: contactPlatformOverview.js ~ line 63 ~ setCurrentContactPlatformOverview ~ cpid', cpid);
      // const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPlatformOverview/UP_currentContactPlatformOverview',
        context: cpid,
        textNotifier: 'Get contactPlatformOverview',
      };

      return dispatchAndForward(action);
      // .then(({ response }) => response)
      // .catch(genericErrorHandler(action, { message: 'Unable to get contactPlatformOverview.', indefinite: true }));
    },

    reportContactPlatformMentionList({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPlatformOverview/reportMention',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Report contactPlatformMention list',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report contactPlatformMention list.', indefinite: true }));
    },

    resetContactPlatformMentionList({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPlatformOverview/UP_restMentionList',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Reset contactPlatformMention list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to reset contactPlatformMention list.', indefinite: true }));
    },

    reportContactNotesForPanel({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPlatformOverview/reportContactNotesForPanel',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Report contactNotesForPanel list',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report contactNotesForPanel list.', indefinite: true }));
    },

    reportContactPlatformAffiliation({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPlatformOverview/reportContactPlatformAffiliation',
        context: { payload: { ...payload, channelGroupCgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Report contact platform affiliation list',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report affiliation list.', indefinite: true }));
    },

    UP_upsertCustom({ dispatch }, { payload }) {
      dispatch('ApiContact/contactPlatformOverview/UP_upsertCustom', { payload }, { root: true });
    },
    // up_currentThreadAndHeld({ dispatch }, tid) {
    //   dispatch('ApiContact/contactPlatformOverview/up_currentThreadAndHeld', tid, { root: true });
    // },
    updateSheetNote({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPlatformOverview/patchSheetNote',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid } },
        textNotifier: 'update sheetNote',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to update note.', indefinite: true }));
    },
  },
};
