import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    manageShopifyShopList: null,
    manageShopifyShop: null,
  },
  mutations: {
    SET_MANAGE_SHOPIFY_SHOP_LIST(state, newData) { state.manageShopifyShopList = newData; },
    SET_MANAGE_SHOPIFY_SHOP(state, newData) { state.manageShopifyShop = newData; },
  },
  getters: {
    getterManageShopifyShopList: (state) => state.manageShopifyShopList,
    getterManageShopifyShop: (state) => state.manageShopifyShop,
  },
  actions: {
    report({ commit }, { payload = {}, httpQuery = {} }) {
      return clark.api.manageShopifyShop.report({ payload, httpQuery })
        .then((manageShopifyShop) => {
          commit('SET_MANAGE_SHOPIFY_SHOP_LIST', manageShopifyShop);
        });
    },
    get({ commit }, { payload = {}, httpQuery = {} }) {
      return clark.api.manageShopifyShop.get({ payload, httpQuery })
        .then((manageShopifyShop) => {
          commit('SET_MANAGE_SHOPIFY_SHOP', manageShopifyShop);
        });
    },
    create({ commit, getters }, { payload = {}, httpQuery = {} }) {
      return clark.api.manageShopifyShop.post({ payload, httpQuery })
        .then((manageShopifyShop) => {
          const shopifyShopList = getters.getterManageShopifyShopList;
          shopifyShopList.push(manageShopifyShop);

          commit('SET_MANAGE_SHOPIFY_SHOP_LIST', shopifyShopList);
          return manageShopifyShop;
        });
    },
    update({ commit, getters }, { payload = {}, httpQuery = {} }) {
      return clark.api.manageShopifyShop.put({ payload, httpQuery })
        .then((manageShopifyShop) => {
          const shopifyShopList = getters.getterManageShopifyShopList;
          const index = shopifyShopList
            .findIndex((shop) => Number(shop.ssid) === Number(manageShopifyShop.ssid));

          shopifyShopList[index] = { ...manageShopifyShop };

          commit('SET_MANAGE_SHOPIFY_SHOP_LIST', shopifyShopList);
          return manageShopifyShop;
        });
    },
    delete({ commit, getters }, { payload = {}, httpQuery = {} }) {
      return clark.api.manageShopifyShop.delete({ payload, httpQuery })
        .then(() => {
          const shopifyShopList = getters.getterManageShopifyShopList;
          const newShopifyShopList = shopifyShopList.reduce((acc, shop) => {
            if (Number(shop.ssid) === Number(payload.ssid)) return acc;
            acc.push(shop);
            return acc;
          }, []);

          commit('SET_MANAGE_SHOPIFY_SHOP_LIST', newShopifyShopList);

          return true;
        });
    },
  },
};
