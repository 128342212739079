<template>
  <div
    :class="['UbuInfluenceMentionsNoContent', {
      'no-border': !isBordered
    }]"
  >
    <div>
      <p
        v-if="title"
        class="subtitle"
      >
        {{ title }}
      </p>
      <p
        v-if="subtitle"
        class="text is-3 has-text-secondary"
      >
        {{ subtitle }}
      </p>
      <slot name="action" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuNoData2',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    isBordered: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
