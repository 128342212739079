/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  actions: {
    update(_, { payload, httpQuery = {} }) {
      return clark.api.forgotPassword.put({ payload, httpQuery });
    },

    create(_, { payload, httpQuery = {} }) {
      return clark.api.forgotPassword.post({ payload, httpQuery });
    },
  },
};
