/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { analyticsCatalog } from '../$catalog/analytics';

export default {
  namespaced: true,
  getters: {
    ...getters.shopify,
    ...getters.account,
    ...getters.campaign,
    ...getters.order,
    ...getters.shop,
    analyticsInboxList: (_state, _getters, _rootState, rootGetters) => {
      const {
        'ChannelGroup/analyticsInbox/getterAnalyticsInboxList': analyticsInbox,
        'ThreadGroup/getterThreadGroupList': threadGroupList,
        'User/getterUserList': userList,
      } = rootGetters;

      // return {};
      if (!threadGroupList) return {};

      return analyticsInbox.reduce((acc, { event, count, id }) => {
        const data = {
          event,
          group: `${event}${id}`,
          value: Number(count),
          color: 9,
          icon: 'ubu-messages',
          type: 'overview',
        };

        if (event.includes('SENT_')) {
          data.group = 'SENT_MESSAGE';
          data.name = 'Sent messages';
        }
        if (event.includes('RECEIVED_')) {
          data.group = 'RECEIVED_MESSAGE';
          data.name = 'Received messages';
        }
        if (event === 'RECEIVED_STORY_REACT') {
          data.group = 'RECEIVED_STORY_REACT';
          data.name = 'Received story reactions';
        }
        if (event === 'RECEIVED_STORY_MENTION') {
          data.group = 'RECEIVED_STORY_MENTION';
          data.name = 'Received story mentions';
        }
        if (event === 'THREAD_GROUP_ASSIGN') {
          data.name = threadGroupList[id].label;
          data.color = Number(threadGroupList[id].color);
          data.icon = 'ubu-custom-tag';
          data.type = 'tags';
        }
        if (event === 'THREAD_APPOINTMENT') {
          data.name = `${userList[id].userDetail.firstName} ${userList[id].userDetail.lastName}`;
          data.color = 1;
          data.icon = 'ubu-assign';
          data.type = 'assignments';
        }

        if (!acc[data.group]) acc[data.group] = data;
        else { acc[data.group].value += data.value; }

        return acc;
      }, {});
    },
    accountAnalytics: (_state, _getters, _rootState, rootGetters) => {
      const {
        'ChannelGroup/accountAnalytics/getterAccountAnalytics': accountAnalytics,
      } = rootGetters;

      const { currentAnalytics, previousAnalytics } = accountAnalytics;

      if (!currentAnalytics || !previousAnalytics) return {};

      const reducedAnalyticsData = Object.entries(currentAnalytics).reduce((acc, row) => {
        const [key, value] = row;
        const valueSecondRange = previousAnalytics[key];

        if (analyticsCatalog[key] && analyticsCatalog[key].type === 'account') {
          const isOverview = analyticsCatalog[key].subtype.find(({ type: t }) => t === 'overview');
          const isFollowers = analyticsCatalog[key].subtype.find(({ type: t }) => t === 'followers');
          const isEngagement = analyticsCatalog[key].subtype.find(({ type: t }) => t === 'engagement');
          const isVisibility = analyticsCatalog[key].subtype.find(({ type: t }) => t === 'visibility');
          const isCallToAction = analyticsCatalog[key].subtype.find(({ type: t }) => t === 'callToAction');

          if (isOverview) {
            acc.overview.push({
              ...analyticsCatalog[key],
              value,
              size: isOverview.size,
              ordinal: isOverview.ordinal,
            });

            acc.overview = acc.overview.sort((a, b) => a.ordinal - b.ordinal);
          }
          if (isFollowers) {
            acc.followers.push({
              ...analyticsCatalog[key],
              value,
              lastPeriodvalue: valueSecondRange,
              size: isFollowers.size,
              ordinal: isFollowers.ordinal,
              growthPercent: valueSecondRange ? ((value - valueSecondRange)
                     / valueSecondRange) * 100 : 0,
            });

            acc.followers = acc.followers.sort((a, b) => a.ordinal - b.ordinal);
          }
          if (isEngagement) {
            acc.engagement.push({
              ...analyticsCatalog[key],
              value,
              lastPeriodvalue: valueSecondRange,
              size: isEngagement.size,
              ordinal: isEngagement.ordinal,
              growthPercent: valueSecondRange ? ((value - valueSecondRange)
                     / valueSecondRange) * 100 : 0,
            });

            acc.engagement = acc.engagement.sort((a, b) => a.ordinal - b.ordinal);
          }
          if (isVisibility) {
            acc.visibility.push({
              ...analyticsCatalog[key],
              value,
              lastPeriodvalue: valueSecondRange,
              size: isVisibility.size,
              ordinal: isVisibility.ordinal,
              growthPercent: valueSecondRange ? ((value - valueSecondRange)
                     / valueSecondRange) * 100 : 0,
            });

            acc.visibility = acc.visibility.sort((a, b) => a.ordinal - b.ordinal);
          }
          if (isCallToAction) {
            acc.callToAction.push({
              ...analyticsCatalog[key],
              value,
              lastPeriodvalue: valueSecondRange,
              size: isCallToAction.size,
              ordinal: isCallToAction.ordinal,
              growthPercent: valueSecondRange ? ((value - valueSecondRange)
                     / valueSecondRange) * 100 : 0,
            });

            acc.callToAction = acc.callToAction.sort((a, b) => a.ordinal - b.ordinal);
          }
          return acc;
        }
        return acc;
      }, {
        daysConnected: currentAnalytics.daysConnected,
        overview: [],
        followers: [],
        engagement: [],
        visibility: [],
        callToAction: [],
        posts: [],
        stories: [],
      });

      reducedAnalyticsData.posts = currentAnalytics.medias.filter((m) => !m.isStory);
      reducedAnalyticsData.stories = currentAnalytics.medias.filter((m) => m.isStory);

      return reducedAnalyticsData;
    },
    followersEngagementAnalytics: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/followersEngagementAnalytics/getterFollowersEngagementAnalytics': followersEngagementAnalytics } = rootGetters;
      return followersEngagementAnalytics;
    },
  },
  actions: {
    ...actions.shopify,
    ...actions.campaign,
    ...actions.channelGroup,
    ...actions.segment,
    ...actions.order,

    reportAnalyticsInbox({ dispatch, rootGetters }, { httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/analyticsInbox/report',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
          },
          httpQuery,
        },
        textNotifier: 'report analytics inbox',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log(e);
          Snackbar.open({
            message: 'Unable to report analytics inbox.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
    getAccountAnalytics({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/accountAnalytics/get',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'get account analytics',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log(e);
          Snackbar.open({
            message: 'Unable to get account analytics.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
    getFollowersEngagementAnalytics({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/followersEngagementAnalytics/get',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'get followers engagement analytics',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to get followers engagement analytics.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
  },
};
