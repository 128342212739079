/* eslint-disable max-len */
import { dispatchAndForward, genericErrorHandler, genericThenHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    campaignThreadCounters: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiInfluence/threadCounters/getterActivityStatusListWithCounters': statusWithCounters } = rootGetters;

      return statusWithCounters;
    },
  },
  actions: {
    reportCampaignThreadCounters({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiInfluence/threadCounters/report',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'report influence thread counters',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to get influence thread counters.', indefinite: true }));
    },
  },
};
