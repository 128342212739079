<template>
  <UbuDropdown
    :close-on-click="false"
    :scrollable="true"
    :is-disabled="isNotAllowed"
    position="is-bottom-right"
    append-to-body
    @open="isOpen = true"
    @close="close()"
  >
    <template #button>
      <b-field
        :class="['button ubuSegmentItem', {
          isNotAllowed: isNotAllowed,
        }]"
      >
        <button
          class="p-0 button is-secondary multibar row-vcentered start mainPart"
        >
          <b-icon
            class="start"
            pack="ubu"
            icon="ubu-filter"
          />
          <p
            v-if="!isNotAllowed"
            class="start"
          >
            <span class="text is-1">{{ `${genericCatalog[filter.key].entity} ` }}</span>
            <span class="text is-3">{{ formatLabel(filter) }}</span>
          </p>
          <b-tooltip
            v-else
            append-to-body
            position="is-bottom"
            size="is-small"
          >
            <template #content>
              This filter has no effect here!
            </template>
            <p class="start">
              <span class="text is-1">{{ `${genericCatalog[filter.key].entity} ` }}</span>
              <span class="text is-3">{{ formatLabel(filter) }}</span>
            </p>
          </b-tooltip>
        </button>
        <p
          v-if="filter.removable !== false"
          class="control"
        >
          <b-button
            class="pr-1 end"
            icon-pack="ubu"
            icon-right="ubu-cross"
            @click.native="$emit('deleteFilter')"
          />
        </p>
      </b-field>
    </template>

    <template
      #header
    >
      <UbuDropdownBreadcrumbs
        class="mb-2"
        :breadcrumbs-options="breadcrumbs"
        :current-breadcrumb="breadcrumbs[breadcrumbs.length - 1]"
        @goBack="useBreadcrumb($event)"
      />
    </template>

    <template #content>
      <b-tabs
        v-if="isOpen"
        v-model="activeTab"
        class="no-header paddingless"
      >
        <b-tab-item
          value="root"
          label="Operator"
        >
          <b-dropdown-item custom>
            <UbuFiltersSelectOperator
              v-if="localFilter && localFilter.operator"
              v-model="localFilter.operator"
              :filter="localFilter"
              :options="operatorOptions"
            />
          </b-dropdown-item>
        </b-tab-item>

        <b-tab-item value="root.selectValue">
          <b-dropdown-item
            custom
            paddingless
          >
            <UbuFilterDateRange
              v-if="localFilter.type === 'rangeDate'"
              v-model="localFilter.values"
              :option-date-to-use="optionDateToUse"
              @input="$update('values', $event)"
            />
            <UbuFilterNumberRange
              v-if="localFilter.type === 'rangeNumber'"
              v-model="localFilter.values"
              @input="$update('values', $event)"
            />
            <UbuFilterRadio
              v-if="localFilter.type === 'radio'"
              v-model="localFilter.values"
              :options="options"
              @input="$update('values', [$event])"
            />
            <UbuFilterCheckbox
              v-if="localFilter.type === 'checkbox'"
              v-model="localFilter.values"
              :options="options"
              @input="$update('values', $event)"
            />
            <UbuFilterInput
              v-if="filter.type === 'input'"
              v-model="localFilter.values"
              @input="$update('values', $event)"
            />
          </b-dropdown-item>
        </b-tab-item>
      </b-tabs>
    </template>

    <template #action>
      <b-dropdown-item
        class="mt-2"
        custom
        paddingless
      >
        <div class="buttons">
          <b-button
            v-if="activeTab === 'root'"
            label="Select"
            type="is-secondary"
            icon-pack="ubu"
            icon-right="ubu-arrow-right"
            expanded
            :disabled="!localFilter.operator"
            @click.stop="nextSelectOperator()"
          />

          <b-button
            v-if="activeTab === 'root.selectValue'"
            label="Apply"
            type="is-primary"
            expanded
            :disabled="!localFilter.values.length"
            @click.stop="applyFilter()"
          />
        </div>
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';
import templatizeMixin from '@dailyplanet/cross-addons/filters/_mixins/templatize.mixin';
import radioFilterMixin from '@dailyplanet/cross-addons/filters/_mixins/radioFilter.mixin';

export default {
  name: 'UbuDropdownFilterForm',
  mixins: [VModelMixin, templatizeMixin, radioFilterMixin],
  model: {
    prop: 'filter',
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    isNotAllowed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // Functionnal
      activeTab: 'root',
      isOpen: false,

      operatorOptions: [
        { label: 'is', value: 'is' },
        { label: 'is not', value: 'is not' },
        { label: 'is one of', value: 'is one of' },
        { label: 'is empty', value: 'is empty' },
        { label: 'is between', value: 'is between' },
      ],

      localFilter: {},

      breadcrumbs: [],

      optionDateToUse: {
        currentWeek: {
          subtype: 'currentWeek',
          label: 'Last 7 days',
          rangeDate: [this.$moment().subtract({ week: 1 }).toDate(), this.$moment().toDate()],
        },
        currentMonth: {
          subtype: 'currentMonth',
          label: 'Last 30 days',
          rangeDate: [this.$moment().subtract({ month: 1 }).toDate(), this.$moment().toDate()],
        },
      },
    };
  },
  computed: {
    options() {
      return this.genericCatalog[this.filter.key].list;
    },
  },
  mounted() {
    this.localFilter = { ...this.filter, values: [...this.filter.values] };
    if (!this.localFilter.operator) {
      this.activeTab = 'root.selectValue';
      this.breadcrumbs.push({ label: this.genericCatalog[this.filter.key].entity, tabToGo: 'root.selectValue' });
    } else {
      this.activeTab = 'root';
      this.breadcrumbs.push({ label: 'Operator', tabToGo: 'root' });
    }
  },
  methods: {
    applyFilter() {
      this.$emit('handle', this.localFilter);
    },

    nextSelectOperator() {
      this.activeTab = 'root.selectValue';
      this.breadcrumbs.push({ label: this.genericCatalog[this.filter.key].entity, tabToGo: 'root.selectValue' });
    },

    useBreadcrumb({ item, index }) {
      this.activeTab = item.tabToGo;
      this.breadcrumbs = this.breadcrumbs.filter((_, i) => i <= index);
      this.search = '';
    },

    close() {
      this.isOpen = false;
      this.breadcrumbs = [];

      if (!this.localFilter.operator) {
        this.activeTab = 'root.selectValue';
        this.breadcrumbs.push({ label: this.genericCatalog[this.filter.key].entity, tabToGo: 'root.selectValue' });
      } else {
        this.activeTab = 'root';
        this.breadcrumbs.push({ label: 'Operator', tabToGo: 'root' });
      }
    },
  },
};
</script>
