/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import {
  dispatchAndForward,
  dispatchFailure,
  dispatchComment,
  snackBarFailure,
  snackBarSuccess,
} from '../$utils/helpers';

export default {
  getters: {},
  actions: {
    searchForSuggestions({ dispatch }, payload) {
      const action = {
        dispatch,
        target: 'Sales/salesContact/suggestion/searchForSuggestions',
        context: { payload },
        textNotifier: 'search for suggestions',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .then(({ response }) => {
          console.log('🚀 ~ file: TheSuggestions.store.js ~ line 28 ~ .then ~ response', response);

          const {
            suggestionsAlreadyInDatabase,
            suggestionsNotFound,
            suggestionsToBeInsertedInDatabase,
          } = response;

          const comment = `Search for suggestions found and insert ${suggestionsToBeInsertedInDatabase.length} new contacts,
            ${suggestionsAlreadyInDatabase.length} was already in database,
            ${suggestionsNotFound.length} was scheduleds to be inserted later in database`;

          Snackbar.open({
            message: comment,
            ...snackBarSuccess,
            duration: 10000,
          });

          return dispatchComment({ ...action, comment });
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheUbuSalesContactSuggestion.store.js ~ line 12 ~ searchForSuggestions ~ error', error);
          Snackbar.open({
            message: 'Unable to search for suggestions.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
  },
};
