<!-- eslint-disable max-len -->
<template>
  <section>
    <b-tab-item value="root.addManually">
      <form @submit.prevent="UP_changeToNewTab(tabItems['root.addManually.selectList'])">
        <div>
          <b-field label="Source">
            <b-select
              v-model="newCrmList.platformName"
              placeholder="Select a source"
              type="is-secondary"
              required
              @input="$update('platformName', $event)"
            >
              <option
                v-for="source in sourceList"
                :key="source.label"
                :value="source.value"
                :disabled="source.disabled"
              >
                {{ source.label }}
              </option>
            </b-select>
          </b-field>

          <br>

          <b-field label="Usernames">
            <b-input
              v-model.trim="newCrmList.usernames"
              placeholder="Type or copy & paste Instagram usernames, one per line..."
              type="textarea"
              required
            />
          </b-field>

          <br>

          <div class="buttons is-right">
            <b-button
              native-type="submit"
              class="is-primary"
              label="Next"
            />
          </div>
        </div>
      </form>
    </b-tab-item>

    <b-tab-item value="root.addManually.selectList">
      <div class="multibar row-vcentered">
        <UbuSearchbar
          v-model="search"
          class="start w-100 mr-2"
          @clear="search = ''"
        />

        <b-button
          label="New list"
          class="start"
          icon-pack="ubu"
          icon-left="ubu-add"
          type="is-primary"
          @click="UP_changeToNewTab(tabItems['root.addManually.selectList.createList'])"
        />
      </div>

      <br>

      <div class="buttons">
        <UbuActionCard
          v-for="list in crmListList"
          :key="list.clid"
          :class="`mb-4 has-text-color-${list.color}`"
          :label="list.name"
          icon-pack="ubu"
          icon-left="ubu-custom-lists"
          expanded
          @click="fillListAndGoNext(list)"
        />
      </div>
    </b-tab-item>

    <b-tab-item value="root.addManually.selectList.createList">
      <UbuCreateCRMListForm
        v-if="activeTab.tab === 'root.addManually.selectList.createList'"
        v-model="newCrmList"
        :crm-list-list="crmListList"
        @submit="createAndFillListAndGoNext()"
      />
    </b-tab-item>

    <b-tab-item value="root.addManually.selectList.done">
      <UbuAddPeopleDone
        label-success="Please note that it can take up to a few hours for people to be added to your CRM."
        :is-loading="isLoading"
        @close="$emit('close')"
      />
    </b-tab-item>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheAddPeopleManually',
  props: {
    activeTab: {
      type: Object,
      required: true,
    },
    tabItems: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newCrmList: {
        name: null,
        color: '1',
        scrapParams: null,
        platformName: 'INSTAGRAM',
        usernames: '',
      },
      sourceList: [
        {
          label: 'Instagram',
          value: 'INSTAGRAM',
        },
        {
          label: 'TikTok (Coming soon!)',
          value: 'TIK-TOK',
          disabled: true,
        },
        {
          label: 'Youtube (Coming soon!)',
          value: 'YOUTUBE',
          disabled: true,
        }],
      search: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      crmListList: 'TheCRM/crmListList',
    }),

    localActiveTab: {
      get() { return this.activeTab; },
      set(val) { this.$emit('update:activeTab', val); },
    },
  },

  methods: {
    ...mapActions({
      _createCrmList: 'TheCRM/createCrmList',
      _fillList: 'TheCRM/fillList',
      _getCrmList: 'TheCRM/getCrmList',
    }),

    UP_changeToNewTab(activeTab) {
      this.$emit('update:activeTab', activeTab);
    },

    fillListAndGoNext(list) {
      this.isLoading = true;

      const { clid } = list;
      const { platformName, usernames } = this.newCrmList;

      const usernamesCleaned = this.cleanUsernameList(usernames);

      return Promise.resolve()
        .then(() => this._fillList({ payload: { clid, platformName, usernames: usernamesCleaned } }))
        .then(() => {
          this.UP_changeToNewTab(this.tabItems['root.addManually.selectList.done']);
          this.isLoading = false;
        });
    },

    createList() {
      const { name, color, scrapParams } = this.newCrmList;

      return this._createCrmList({ payload: { name, color: String(color), scrapParams } });
    },

    createAndFillListAndGoNext() {
      this.isLoading = true;
      const { platformName, usernames } = this.newCrmList;
      const usernamesCleaned = this.cleanUsernameList(usernames);

      return Promise.resolve()
        .then(() => this.createList())
        .then(({ clid }) => this._fillList({ payload: { clid, platformName, usernames: usernamesCleaned } }))
        .then(() => {
          this.UP_changeToNewTab(this.tabItems['root.addManually.selectList.done']);
          this.isLoading = false;
        });
    },

    cleanUsernameList(usernames) {
      /**
       * extract username from url insta and @username
       */
      // eslint-disable-next-line prefer-regex-literals
      const rx = RegExp(/^(?:@|(?:https?:\/\/)?(?:www\.)?instagr(?:\.am|am\.com)\/)?((?:\w|[.-])+)\/?$/);

      return usernames
        .replaceAll(' ', '\n')
        .replaceAll(',', '\n')
        .replaceAll(';', '\n')
        .replaceAll('\'', '')
        .replaceAll(/\n+/g, '\n')
        .trim()
        .toLowerCase()
        .split('\n')
        .reduce((acc, username) => {
          const match = rx.exec(username);

          if (match) {
            if (!acc.includes(match[1])) acc.push(match[1]);
          }
          return acc;
        }, []);
    },
  },
};
</script>
