<!--
<template>
  <section
    class="ubuMessageWrapper"
    :isMe="isMe"
  >
    <div class="ubuMessageCard">
      <div class="ubuMessageCard-content">
        <div class="subtext cloud60">{{ repliedMsg }}</div>
        <a
          class="button is-ghost link-start mt-2"
          :href="messageLink ? messageLink : 'https://www.instagram.com/direct/inbox/'"
          target="_blank"
        >
          View on Instagram
        </a>
        <p v-if="message.text">{{ message.text }}</p>
      </div>
      <UbuThreadMessageFooter :message="message" />
    </div>
  </section>
</template>

<script>
import MessageMixin from '@dailyplanet/mixins/MessageMixin';

export default {
  name: 'UbuThreadMessageStoryReply',
  mixins: [MessageMixin],
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    repliedMsg() {
      if (this.isMe) {
        return 'Replied to their story';
      }
      return 'Replied to your story';
    },
  },
};
</script>
-->

<template>
  <section
    class="ubuMessageWrapper"
    :isMe="isMe"
  >
    <UbuThreadMessageContentWrapper
      v-if="isNotExpired"
      :message="message"
      :subtext="repliedMsg"
      :is-me="isMe"
      :platform-name="platformName"
    />
    <div
      v-else
      class="ubuMessageCard"
    >
      <div class="ubuMessageCard-content">
        <div class="subtext cloud60">{{ repliedMsg }} (expired)</div>
        <p v-if="message.text">{{ message.text }}</p>
      </div>
      <UbuThreadMessageFooter :message="message" />
    </div>
  </section>
</template>

<script>
import MessageMixin from '@dailyplanet/mixins/MessageMixin';

export default {
  name: 'UbuThreadMessageStoryReply',
  mixins: [MessageMixin],
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: () => {},
    },
    platformName: {
      type: String,
      default: 'INSTAGRAM',
    },
  },
  computed: {
    repliedMsg() {
      if (this.isMe) {
        return 'Replied to their story';
      }
      return 'Replied to your story';
    },
    isNotExpired() {
      return this.$moment().utc().diff(this.$moment(this.message.itemDate).utc(), 'hours') < 24;
    },
  },
};
</script>
