<template>
  <div class="ubuTextareaTop">
    <div
      v-if="!reachMode"
      class="multibar mb-3"
    >
      <b-button
        class="start mr-2"
        type="is-simple"
        :active="!isPrivateNote"
        @mousedown="$emit('toggleNote', false)"
      >
        Message
      </b-button>
      <b-button
        class="start"
        type="is-simple"
        :active="isPrivateNote"
        @mousedown="$emit('toggleNote', true)"
      >
        Private Note
      </b-button>
    </div>

    <b-field
      v-if="file && !isPrivateNote && !reachMode"
    >
      <b-tag
        type="is-primary"
        closable
        attached
        aria-close-label="Close tag"
        size="is-medium"
        @close="$emit('deleteFile')"
      >
        {{ file.name }}
      </b-tag>
    </b-field>

    <div
      v-if="templateListFiltered && !isPrivateNote"
      class="templateListRelative"
    >
      <div class="templateList">
        <b-tag
          v-for="(template, index) in templateListFiltered"
          :key="index"
          type="is-light"
          class="mr-1"
          @click.native="$emit('selectTemplate', template)"
        >
          /{{ template.title }}
        </b-tag>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuTextareaTop',
  props: {
    isPrivateNote: {
      type: Boolean,
      default: false,
    },
    file: {
      type: File,
      default: null,
    },
    templateListFiltered: {
      type: Array,
      default: null,
    },
    reachMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
