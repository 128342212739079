/* eslint-disable no-multi-spaces */
import TheMonitorRewards from './TheMonitorRewards';

import TheReward from './views/TheReward';

export default [
  {
    path: '/rewards',
    redirect: '/rewards/all-reward',
  },
  {
    path: '/rewards',
    name: 'rewards',
    component: TheMonitorRewards,
    meta: {
      title: 'Rewards',
      hasMenu: true,
      viewStoreNavigation: 'TheMonitorRewards',
      requireAuth: true,
    },
    children: [
      {
        path: '/rewards/all-reward',
        name: 'Monitor Rewards',
        components: { allUsers: TheReward },
        meta: {
          title: 'Rewards',
          hasMenu: true,
          viewStoreNavigation: 'TheUsers',
          requireAuth: true,
        },
      },
    ],
  },
];
