<template>
  <b-image
    v-if="profilePicture && !errorPicture && !isSelected"
    :class="['ubuThreadListUserPicture ubu-rounded', {
      'is-16x16': isSize === 16,
      'is-24x24': isSize === 24,
      'is-32x32': isSize === 32,
      'is-40x40': isSize === 40,
      'is-48x48': isSize === 48,
      'is-64x64': isSize === 64,
      'is-96x96': isSize === 96,
      'is-128x128': isSize === 128,
    }, {'is-clickable': clickable}]"
    :custom-class="`image is-rounded ${classSize}`"
    :src="profilePicture"
    :alt="
      `${initialName.capitalize()}'s profile picture`
    "
    @error="errorPicture = true"
  />
  <b-image
    v-else-if="isSelected"
    :class="['ubuThreadListUserPicture', {'is-clickable': clickable}]"
    custom-class="image is-rounded is-selected"
    ratio="1by1"
    :style="`width: ${size}px; height: ${size}px;`"
    :src="selectedImgLink"
    :alt="
      `${initialName.capitalize()}'s profile picture`
    "
    @error="errorPicture = true"
  />
  <b-image
    v-else
    :class="['ubuUserPicture ubu-rounded', {
      'is-16x16': isSize === 16,
      'is-24x24': isSize === 24,
      'is-32x32': isSize === 32,
      'is-40x40': isSize === 40,
      'is-48x48': isSize === 48,
      'is-64x64': isSize === 64,
      'is-96x96': isSize === 96,
      'is-128x128': isSize === 128,
    }, {'is-clickable': clickable}]"
    :custom-class="`image is-rounded ${classSize}`"
    :src="require(`@/assets/ubu_avatar_fallback/avatar_image_${randomAvatar}.png`)"
    alt="Avatar profile picture"
    :style="`width: ${size}px; height: ${size}px; font-size: ${size * .4}px`"
  />
  <!-- <div
    v-else
    :class="['ubuThreadListUserPicture', {'is-clickable': clickable}]"
  >
    <div
      :style="`width: ${size}px; height: ${size}px; font-size: ${size * .4}px`"
      class="avatar"
    >
      <b-icon
        pack="mdi"
        icon="account"
      />
    </div>
  </div> -->
</template>
<script>
export default {
  name: 'UbuThreadListUserPicture',
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    initialName: {
      type: String,
      default: '',
    },
    isSize: {
      type: Number,
      default: 40,
    },
    size: {
      type: Number,
      default: 40,
    },
    profilePicture: {
      type: String,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isAvatar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      selectedImgLink: require('../../../assets/pictos/is_ok.svg'),
      errorPicture: false,
    };
  },
  computed: {
    color() {
      return Math.floor(this.seed(this.initialName) * (12 - 1) + 1);
    },
    classSize() {
      return `is-${this.isSize}x${this.isSize}`;
    },
    randomAvatar() {
      return Math.floor(this.seed(this.initialName) * (20 - 1) + 1);
    },
  },
  methods: {
    seed(str) {
      if (!str) return 0;
      let nb = 0;
      for (let i = 0; i < str.length; i += 1) {
        nb += str.charCodeAt(i);
      }
      const x = Math.sin(nb / str.length) * 10000;
      return x - Math.floor(x);
    },
  },
};
</script>
