<template>
  <b-icon
    :class="['ubuIconCircleStatus start small', {
      'has-text-color-5': isActive,
      'has-text-color-2': !isActive,
    }]"
    pack="ubu"
    icon="ubu-circle-divider"
  />
</template>

<script>
export default {
  name: 'UbuIconCircleStatus',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
