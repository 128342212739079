<template>
  <form @submit.prevent="$emit('editEmail')">
    <fieldset>
      <b-field label="Current email">
        <b-input
          :value="currentEmail"
          placeholder="Current Email ..."
          required
          readonly="readonly"
        />
      </b-field>

      <b-field label="New email">
        <b-input
          v-model="localModel.newEmail"
          required
          placeholder="New email ..."
          @input="$update('newEmail', $event)"
        />
      </b-field>

      <b-field label="Confirm new email">
        <b-input
          v-model="localModel.confirmEmail"
          required
          placeholder="Confirm new email ..."
          @input="$update('confirmEmail', $event)"
        />
      </b-field>
    </fieldset>

    <br>

    <div class="buttons is-right">
      <b-button
        native-type="submit"
        label="Save"
        class="is-primary"
      />
    </div>
  </form>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuEditEmailForm',
  mixins: [VModelMixin],
  model: {
    prop: 'model',
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentEmail: {
      type: String,
      required: true,
    },
  },
  computed: {
    localModel: {
      get() { return { ...this.model }; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
};
</script>
