<!-- eslint-disable max-len -->
<template>
  <section style="overflow-y: auto; height: calc(100vh - 85px)">
    <form class="p-2" @submit.prevent="createOrUpdate()">
      <b-message
        v-if="!_currentBrand"
        type="is-warning"
        :closable="false"
      >
        You haven't created your brand yet. <br>
        Do this to start inviting people to your Crew!
      </b-message>

      <b-field label="Name *" horizontal>
        <b-input v-model="localCurrentBrand.name" required />
      </b-field>

      <b-field v-if="localCurrentBrand.name" label="Profile picture *" horizontal expanded>
        <div class="flex column">
          <p class="subtext has-text-secondary mb-2">
            The brand logo should be a square, for better resolution the size must be 260 x 260 at least.
          </p>

          <b-upload
            v-model="file"
            class="multibar row-vcentered"
            :disabled="fileUploadLoading"
            accept=".jpg, .png, .svg"
            :required="!localCurrentBrand.profilePicture"
            @input="$event ? handleFileUpload($event) : undefined"
          >
            <span class="button start">
              <b-icon class="file-icon" icon="upload" />
              <span class="file-label">Click to upload</span>
              <b-icon v-if="fileUploadLoading" class="spining" pack="ubu" icon="ubu-refresh" />
            </span>

            <img
              v-if="localCurrentBrand.profilePicture"
              :src="localCurrentBrand.profilePicture"
              class="start ml-4 is-rounded"
              alt="brand-logo"
              style="width: 58px; height: 58px; object-fit: fill; background-color: var(--seashellpeach); border-color: 1px solid var(--darkgrey); border-radius: 50%;"
            />
          </b-upload>
        </div>
      </b-field>

      <b-field label="Brand description" horizontal>
        <b-input v-model="localCurrentBrand.brandDescription" placeholder="Short description about your brand" required />
      </b-field>

      <b-field label="Affiliation description" horizontal>
        <b-input v-model="localCurrentBrand.affiliationDescription" placeholder="Short description about your affiliate program" required />
      </b-field>

      <b-field label="Website url" horizontal>
        <b-input v-model="localCurrentBrand.websiteUrl" />
      </b-field>

      <b-field label="Shop url" horizontal>
        <b-input v-model="localCurrentBrand.shopUrl" />
      </b-field>

      <b-field label="Default shop" horizontal>
        <div class="flex column">
          <p class="subtext has-text-secondary mb-2">
            This is your default shop, it will be used for commission rate and default coupons folder.
          </p>

          <UbuDropdown
            position="is-bottom-right"
            @open="isOpen = true"
            @close="isOpen = false;"
          >
            <template #button>
              <b-button
                :label="selectedShop ? selectedShop.baseUrl : 'Select a shop'"
                icon-pack="ubu"
                icon-left="ubu-shop"
                type="is-secondary"
              />
            </template>

            <template v-if="isOpen" #search>
              <b-dropdown-item custom>
                <UbuSearchbar
                  v-model="searchShop"
                  @clear="searchShop = ''"
                />
              </b-dropdown-item>
            </template>

            <template #content>
              <b-dropdown-item
                v-for="shop in shopListFiltered"
                :key="shop.sid"
                @click="patchBrandDefaultOptions({ shopSid: shop.sid, affiliationCodeFolderAcfid: null })"
              >
                {{ shop.baseUrl }}
              </b-dropdown-item>
            </template>
          </UbuDropdown>
        </div>
      </b-field>

      <b-field label="Code folder" horizontal>
        <div class="flex column">
          <p class="subtext has-text-secondary mb-2">
            This is your default folder from where crew user
            will pick an existing code.
          </p>

          <UbuDropdown
            position="is-bottom-right"
            :scrollable="true"
            :is-disabled="!selectedShop"
            @open="isOpen = true"
            @close="isOpen = false"
          >
            <template #button>
              <b-button
                :label="selectedFolder ? selectedFolder.title : 'Select a folder'"
                icon-pack="ubu"
                icon-left="ubu-folder"
                type="is-secondary"
              />
            </template>

            <template v-if="isOpen" #search>
              <b-dropdown-item custom>
                <UbuSearchbar
                  v-model="searchFolder"
                  @clear="searchFolder = ''"
                />
              </b-dropdown-item>
            </template>

            <template #content>
              <b-dropdown-item
                v-for="folder in folderListFiltered"
                :key="folder.acfid"
                @click="patchBrandDefaultOptions({ affiliationCodeFolderAcfid: folder.acfid })"
              >
                {{ folder.title }}
              </b-dropdown-item>
            </template>
          </UbuDropdown>
        </div>
      </b-field>

      <b-field label="Reward options" horizontal>
        <div class="flex column">
          <p class="subtext has-text-secondary mb-2">
            Reward options will be offered in Crew App to your Crew Member when they want to claim a reward.
          </p>
          <b-switch
            v-model="localCurrentBrand.config.customReward"
            :true-value="true"
            :false-value="false"

            @input="patchBrandDefaultOptions({ config: { ...localCurrentBrand.config, customReward: $event } })"
          >
            Manual reward such as coupons, gift cards...
          </b-switch>

          <br>

          <b-switch
            v-model="localCurrentBrand.config.tremendousReward"
            :true-value="true"
            :false-value="false"

            :disabled="!canAutomatizeWithTremendous"
            @input="patchBrandDefaultOptions({ config: { ...localCurrentBrand.config, tremendousReward: $event } })"
          >
            Automatized via Tremendous
          </b-switch>

          <b-message v-if="!localCurrentBrand.config.customReward && !localCurrentBrand.config.tremendousReward" type="is-warning" class="mt-4">
            <p>You haven't selected any reward type option.</p>
            <p>Your crew members won't be able to be rewarded.</p>
          </b-message>
        </div>
      </b-field>

      <div class="buttons is-right">
        <b-button :label="_currentBrand ? 'Save changes' : 'Create'" type="is-primary" native-type="submit" />
      </div>
    </form>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

import UploadFileMixin from '@dailyplanet/mixins/UploadFileMixin';

import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin'; import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheCrewSetting',
  mixins: [FilterEngineMixin, UploadFileMixin],
  data() {
    return {
      searchShop: '',
      searchFolder: '',
      isOpen: false,
      localCurrentBrand: {
        name: '',
        shopSid: null,
        affiliationCodeFolderAcfid: null,
        profilePicture: null,
        brandDescription: null,
        affiliationDescription: null,
        websiteUrl: null,
        shopUrl: null,
        createdOn: null,
        updatedOn: null,
        config: {
          customReward: false,
          tremendousReward: false,
        },
      },

      file: null,
      fileKey: null,
      fileUploadLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      _currentTremendousAccount: 'TheCrew/currentTremendousAccount',
      _currentBrand: 'TheSettingWorkspaceSetting/currentBrand',
      _shopList: 'TheShopDropdown/shopList',
      _affiliationCodeFolderList: 'TheAffiliationCodeFolderDropdown/affiliationCodeFolderList',
    }),

    selectedShop() {
      return this.localCurrentBrand.shopSid ? this._shopList[this.localCurrentBrand.shopSid] : null;
    },

    selectedFolder() {
      return this.localCurrentBrand.affiliationCodeFolderAcfid ? this._affiliationCodeFolderList[this.localCurrentBrand.affiliationCodeFolderAcfid] : null;
    },

    canAutomatizeWithTremendous() {
      return !!(this._currentTremendousAccount && this._currentTremendousAccount.apiKey);
    },

    _filterCollection() {
      return {
        searchByBaseUrl(rawSearch) {
          const search = rawSearch.toLowerCase();
          return (m) => (m.baseUrl.toLowerCase().includes(search) ? m : undefined);
        },
        byShopSid(shopSid) {
          return (m) => (m.shopSid === shopSid ? m : undefined);
        },
      };
    },

    folderListFiltered() {
      const folderList = Object.values(this._affiliationCodeFolderList);

      if (!folderList.length) return [];

      const filtersToApply = {};

      if (this.selectedShop) filtersToApply.byShopSid = this.selectedShop.sid;
      if (this.searchFolder) filtersToApply.searchByTitle = this.searchFolder;

      return this.filterEngine(folderList, filtersToApply)
        .sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
    },

    shopListFiltered() {
      const shopList = Object.values(this._shopList);

      if (!shopList.length) return [];

      const filtersToApply = {};

      if (this.searchShop) filtersToApply.searchByBaseUrl = this.searchShop;

      return this.filterEngine(shopList, filtersToApply);
    },
  },
  mounted() {
    this.localCurrentBrand = {
      ...this.localCurrentBrand,
      ...this._currentBrand,
      config: {
        customReward: false,
        tremendousReward: false,
        ...this._currentBrand?.config,
      },
    };

    return this._reportTremendousAccount();
  },
  methods: {
    ...mapActions({
      _reportTremendousAccount: 'TheCrew/reportTremendousAccount',
      _createBrand: 'TheSettingWorkspaceSetting/createBrand',
      _updateBrand: 'TheSettingWorkspaceSetting/updateBrand',
    }),

    patchBrandDefaultOptions(toEdit) {
      const payload = { ...this.localCurrentBrand, ...toEdit };
      this.localCurrentBrand = payload;
    },

    createOrUpdate() {
      return Promise.resolve()
        .then(() => {
          if (!this._currentBrand) return this._createBrand({ payload: { ...this.localCurrentBrand, createdOn: this.$moment().format() } });

          return this._updateBrand({ payload: this.localCurrentBrand });
        })
        .then(() => this.$buefy.snackbar.open({ message: 'Success.', ...snackbarSuccess }));
    },

    handleFileUpload(event) {
      console.log('🚀 ~ file: TheCrewSetting.vue:287 ~ handleFileUpload ~ event', event);

      this.fileKey = encodeURIComponent(`crewBrand/${this.localCurrentBrand.name}`);
      this.fileUploadLoading = true;
      return this.uploadFile(event, this.fileKey, true)
        .then((link) => {
          console.log('🚀 ~ file: TheCrewSetting.vue:293 ~ .then ~ link', link);

          this.fileUploadLoading = false;
          this.localCurrentBrand.profilePicture = `https://io.getubu.com/profile_pic_insta/${this.fileKey}`;
          return link;
        })
        .catch((error) => {
          let message;
          switch (error) {
            case 'MAX_SIZE_MEDIA_8MB': message = 'This file is not supported by meta, media must be under 8MB.'; break;
            case 'MAX_SIZE_MEDIA_25MB': message = 'This file is not supported by meta, media must be under 25MB.'; break;
            case 'UNSUPPORTED_MEDIA': message = 'This file is not supported by meta, media must be (.png or .jpeg).'; break;

            default: message = 'Something unexpected occur, please retry or contact support.'; break;
          }
          this.$buefy.snackbar.open({ ...this.$snackbarFailure, message, infinite: true });
          this.fileUploadLoading = false;
          this.fileKey = null;
          this.file = null;
        });
    },
    removeMedia() {
      this.file = null;
      this.fileKey = null;
      this.localCurrentBrand.profilePicture = null;
      // this.$emit('input', {
      //   ...this.localMediaPayload,
      //   url: null,
      //   fileKey: null,
      //   type: null,
      // });
    },
  },
};
</script>
