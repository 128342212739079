<template>
  <section id="TheInbox">
    <router-view
      :key="box"
      name="inboxSidebar"
      @threadListInitialized="isThreadListInitialized = true"
    />

    <router-view
      v-if="isThreadListInitialized"
      name="inboxMainPanel"
      @openTemplateManager="showTemplateManager = true"
    />

    <TheSettingAddTemplate
      v-if="showTemplateManager"
      closing-way="emit"
      @close="showTemplateManager = false"
    />

    <div
      v-if="showTemplateManager"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions } from 'vuex';
import SocketMixin from '@dailyplanet/mixins/SocketMixin';

export default {
  name: 'TheInbox',
  mixins: [SocketMixin],
  data() {
    return {
      isLoading: false,
      isThreadListInitialized: false,
      showTemplateManager: false,
    };
  },
  computed: {
    box() {
      const {
        threadByCampaigns,
        threadByTags,
        threadByLabels,
        threadByAppoints,
        inboxType,
      } = this.$route.params;

      if (threadByCampaigns) return `threadByCampaigns-${threadByCampaigns}`;
      if (threadByTags) return `threadByTags-${threadByTags}`;
      if (threadByLabels) return `threadByLabels-${threadByLabels}`;
      if (threadByAppoints) return `threadByAppoints-${threadByAppoints}`;
      if (inboxType) return `inboxType-${inboxType}`;
      return undefined;
    },
  },
  beforeMount() {
    this.subscribeInboxStream();
    return this._resetContactPlatformOverviewList();
  },
  mounted() {
    // console.warn('🚀 ~ file: TheInbox.vue ~ line 108 ~ mounted ~ mounted');
    if (this.$route.name.includes('Tribe')) this.emitSegmentEvent({ event: 'clickOnTribeMessage' });
    else if (this.$route.name.includes('Influence')) this.emitSegmentEvent({ event: 'clickOnInfluenceMessage' });
    else this.emitSegmentEvent({ event: 'clickOnInbox' });
    this.isLoading = true;

    this.getCounter({ httpQuery: {} })
      .then(() => {
        this.isLoading = false;
      });
  },
  beforeDestroy() {
    this.unsubscribeInboxStream();
  },
  // beforeRouteUpdate(to, from, next) {
  //   console.warn('🚀 ~ file: TheInbox.vue ~ line 108 ~ beforeRouteUpdate ~ beforeRouteUpdate');
  //   return next();
  // },

  methods: {
    ...mapActions({
      _resetContactPlatformOverviewList: 'ApiContact/contactPlatformOverview/resetContactPlatformOverviewList',
      getCounter: 'TheInbox/getCounter',
      emitSegmentEvent: 'TheInbox/emitSegmentEvent',
    }),
  },
};
</script>
