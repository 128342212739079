<template>
  <p v-if="value && Number(value) !== 0">
    <span v-if="column.before">{{ column.before }}</span>
    <span>{{ value | nDecimalFormatter }}</span>
    <span v-if="column.after">{{ column.after }}</span>
  </p>
  <p v-else>-</p>
</template>

<script>
import TableColumnMixin from '../_mixins/UbuTableColumn.mixin';

export default {
  name: 'UbuTableNumberDecimalFormatter',
  mixins: [TableColumnMixin],
  props: {
    cpo: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: String,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
  },
};
</script>
