/* eslint-disable max-len */
import { actions, gettersSelected } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  getters: {
    navigation: (state) => state.navigation,
    ...gettersSelected('activity', ['activityStatusListFiltered']),
    ...gettersSelected('activityOverview', ['activityOverviewList']),
    ...gettersSelected('campaign', ['campaignList', 'currentCampaignCid', 'currentCampaign']),
    ...gettersSelected('campaignAnalytics', ['activitiesStats', 'mentionsStats', 'salesStats']),
    ...gettersSelected('campaignMention', ['campaignMentionList']),
  },
  actions: {
    ...actions.activityOverview,
    ...actions.campaign,
    ...actions.campaignAnalytics,
    ...actions.campaignMention,
    ...actions.segment,
  },
};
