import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheCampaignCartouche from './TheCampaignCartouche';
import TheCampaignBulkPanel from './TheCampaignBulkPanel';
import TheBulkChangeStatus from './TheBulkChangeStatus';
import TheBulkRemoveFromCampaign from './TheBulkRemoveFromCampaign';
import TheCampaignSettingsPanel from './TheCampaignSettingsPanel';
import TheCreateCampaignPanel from './TheCreateCampaignPanel';
import TheActivityPeopleExportPanel from './TheActivityPeopleExportPanel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheCampaignCartouche);
    registerComponent(Vue, TheCampaignBulkPanel);
    registerComponent(Vue, TheBulkChangeStatus);
    registerComponent(Vue, TheBulkRemoveFromCampaign);
    registerComponent(Vue, TheCampaignSettingsPanel);
    registerComponent(Vue, TheCreateCampaignPanel);
    registerComponent(Vue, TheActivityPeopleExportPanel);
  },
};

use(Plugin);

export default Plugin;

export {
  TheCampaignCartouche,
  TheCampaignBulkPanel,
  TheBulkChangeStatus,
  TheBulkRemoveFromCampaign,
  TheCampaignSettingsPanel,
  TheCreateCampaignPanel,
  TheActivityPeopleExportPanel,
};
