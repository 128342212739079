import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    currentTheme: (_state, _getters, _rootState, rootGetters) => {
      const { 'Settings/theme/getterTheme': currentTheme } = rootGetters;

      return currentTheme;
    },
  },
  actions: {
    changeTheme({ dispatch }, theme) {
      const action = {
        dispatch,
        target: 'Settings/theme/update',
        context: { payload: { theme } },
        textNotifier: 'change theme',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to change theme.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
  },
};
