/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    apiKeyList: {},
  },
  mutations: {
    SET_API_KEY_LIST(state, newData) { state.apiKeyList = newData; },
    RESET_STORE(state) {
      state.apiKeyList = {};
    },
  },
  getters: {
    getterApiKeyList: (state) => state.apiKeyList,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'API_KEY.REPORT')) return undefined;

      return clark.api.apiKey.report({ payload, httpQuery })
        .then((apiKeyList) => {
          commit('SET_API_KEY_LIST', apiKeyList.arrayMapper('akid'));

          return apiKeyList;
        });
    },

    create({ commit, rootGetters, getters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'API_KEY.REPORT')) return undefined;

      const { getterApiKeyList } = getters;

      return clark.api.apiKey.post({ payload, httpQuery })
        .then((apiKey) => {
          const updated = {
            ...getterApiKeyList,
            [apiKey.akid]: apiKey,
          };
          commit('SET_API_KEY_LIST', updated);

          return apiKey;
        });
    },

    revoke({ commit, rootGetters, getters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'API_KEY.REPORT')) return undefined;

      const { getterApiKeyList } = getters;

      return clark.api.apiKey.delete({ payload, httpQuery })
        .then(() => {
          const updated = Object.values(getterApiKeyList).filter(({ akid }) => akid !== payload.akid).arrayMapper('akid');
          commit('SET_API_KEY_LIST', updated);
        });
    },
  },
};
