<template>
  <div
    :class="['ubuFavButton', {
      'is-active': active,
    }]"
  >
    <b-button
      type="is-tertiary"
      @click="$emit('click', $event)"
    >
      <b-icon
        pack="mdi"
        :icon="active ? 'star' : 'star-outline'"
        size="is-medium"
      />
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'UbuFavButton',
  model: {
    prop: 'color',
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
};
</script>
