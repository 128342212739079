/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

// const tribeMemberMapper = (tribeMemberList) => tribeMemberList.map(({ contactPlatform, contactPlatformStatistic, ...rest }) => {
//   const { contact, ...restContactPlatform } = contactPlatform;
//   if (contactPlatformStatistic) {
//     const { thread, activity, ...restStatistic } = contactPlatformStatistic;
//     return {
//       ...rest,
//       contactPlatform: restContactPlatform,
//       contact,
//       thread,
//       activity,
//       statistic: restStatistic,
//     };
//   }
//   return {
//     ...rest,
//     contactPlatform: restContactPlatform,
//     contact,
//     thread: null,
//     activity: null,
//     statistic: {
//       threadTid: null,
//       activityAid: null,
//       totalMentions: 0,
//       totalPosts: 0,
//       totalStories: 0,
//       totalPrints: 0,
//       totalOrders: 0,
//       totalRevenuesUsd: 0,
//       totalCoupons: 0,
//       lastMentionOn: null,
//       lastCampaignOn: null,
//       lastMessageOn: null,
//       lastSaleOn: null,
//     },
//   };
// });

export default {
  namespaced: true,
  state: {
    tribeMemberList: {},
  },
  mutations: {
    SET_TRIBE_MEMBER_LIST(state, newData) { state.tribeMemberList = newData; },
    RESET_TRIBE_MEMBER_LIST(state) { state.tribeMemberList = {}; },
  },
  getters: {
    getterTribeMemberList: (state) => state.tribeMemberList,
  },
  actions: {
    resetTribeMemberList({ commit }) {
      console.log('🚀 ~ file: tribeMember.store.js ~ line 56 ~ resetTribeMemberList ~ resetTribeMemberList');
      commit('RESET_TRIBE_MEMBER_LIST');
    },

    report({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.REPORT')) return undefined;

      return clark.api.apiTribe.tribeMember.report({ payload, httpQuery })
        .then(({ results: tribeMemberList, total }) => {
          commit('SET_TRIBE_MEMBER_LIST', { ...getters.getterTribeMemberList, ...tribeMemberList.arrayMapper('tmid') });
          return total;
        });
    },

    create({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.CREATE')) return undefined;

      return clark.api.apiTribe.tribeMember.post({ payload, httpQuery })
        .then((tribeMemberList) => {
          commit('SET_TRIBE_MEMBER_LIST', { ...getters.getterTribeMemberList, ...tribeMemberList.arrayMapper('tmid') });
          return tribeMemberList;
        });
    },

    update({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.UPDATE')) return undefined;

      return clark.api.apiTribe.tribeMember.put({ payload, httpQuery })
        .then((tribeMemberList) => {
          // console.log('🚀 ~ file: tribeMember.store.js ~ line 84 ~ .then ~ tribeMemberList', tribeMemberList);

          const mappedTribeMemberList = tribeMemberList.arrayMapper('tmid');
          const updatedTribeMemberList = Object.values(getters.getterTribeMemberList)
            .reduce((acc, row) => {
              if (!mappedTribeMemberList[row.tmid]) return acc;
              acc[row.tmid] = { ...row, ...mappedTribeMemberList[row.tmid] };
              return acc;
            }, {});

          commit('SET_TRIBE_MEMBER_LIST', { ...getters.getterTribeMemberList, ...updatedTribeMemberList });
          return tribeMemberList;
        });
    },

    delete({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.DELETE')) return undefined;

      return clark.api.apiTribe.tribeMember.delete({ payload, httpQuery })
        .then(() => {
          commit('SET_TRIBE_MEMBER_LIST', Object.values(getters.getterTribeMemberList).filter(({ tmid }) => !payload.tmids.includes(tmid)).arrayMapper('tmid'));
        });
    },
  },
};
