<template>
  <UbuDropdown
    :scrollable="true"
  >
    <template #button>
      <b-button
        icon-pack="ubu"
        icon-right="ubu-more-vertical"
        type="is-tertiary"
        class="ml-2"
      />
    </template>

    <template #content>
      <b-dropdown-item
        aria-role="listitem"
        class="is-flex-direction-row is-align-items-center"
        @click="changeThreadStatus(_cpo.thread.isOpen ? 'THREAD_CLOSED' : 'THREAD_OPENED')"
      >
        <b-icon
          pack="ubu"
          :icon="_cpo.thread.isOpen ? 'ubu-close' : 'ubu-open'"
        />
        <span>{{ _cpo.thread.isOpen ? 'Close' : 'Open' }}</span>
      </b-dropdown-item>

      <b-dropdown-item
        :disabled="_cpo.thread.isRead"
        aria-role="listitem"
        class="is-flex-direction-row is-align-items-center"
        @click="changeThreadStatus('THREAD_READ')"
      >
        <b-icon
          pack="ubu"
          icon="ubu-eye"
        />
        <span>Read</span>
      </b-dropdown-item>

      <b-dropdown-item
        v-if="isTagThreadBox"
        aria-role="listitem"
        class="is-flex-direction-row is-align-items-center"
        @click="changeThreadStatus('THREAD_DONE')"
      >
        <b-icon
          pack="ubu"
          icon="ubu-check"
        />
        <span>Done</span>
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
import TheMainTextarea from '@dailyplanet/selina-addons/textarea/_mixins/TheMainTextarea.mixin';

export default {
  name: 'TheMoreButtonPopover',
  mixins: [TheMainTextarea],
  computed: {
    isTagThreadBox() {
      return !!this.$route.params.threadByTags;
    },
  },
};
</script>
