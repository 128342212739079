const hashtagsAnalytics = {
  prints: {
    name: 'Prints',
    color: 3,
    icon: 'ubu-eye',
  },
  likeCount: {
    name: 'Likes',
    color: 9,
    icon: 'ubu-like',
  },
  commentCount: {
    name: 'Comments',
    color: 9,
    icon: 'ubu-comment',
  },
  engagement: {
    name: 'Post engagement',
    color: 9,
    icon: 'ubu-organic',
    symbol: '%',
  },
  followerCount: {
    name: 'Followers',
    color: 12,
    icon: 'ubu-love',
  },
};

export {
  // eslint-disable-next-line import/prefer-default-export
  hashtagsAnalytics,
};
