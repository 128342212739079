<template>
  <div>
    <b-taglist
      v-for="([serverName, isConnected]) in Object.entries(connectedServers)"
      :key="serverName"
      attached
      class="no-margin"
    >
      <b-tag type="is-dark">{{ serverName }}</b-tag>
      <b-tag
        v-if="isConnected"
        type="is-success"
      >
        Connected
      </b-tag>
      <b-tag
        v-else
        type="is-danger"
      >
        Connected
      </b-tag>
    </b-taglist>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheServersState',
  data() {
    return {
      connectedServers: {
        barbara: false,
        clark: false,
      },
    };
  },
  mounted() {
    this.isClarkConnected()
      .then((response) => {
        this.connectedServers.clark = response;
      });
    this.isBarbaraConnected()
      .then((response) => {
        this.connectedServers.barbara = response;
      });
  },
  methods: {
    ...mapActions({
      isClarkConnected: 'TheServers/isClarkConnected',
      isBarbaraConnected: 'TheServers/isBarbaraConnected',
    }),
  },
};
</script>
