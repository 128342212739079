<template>
  <div class="multibar">
    <div class="start multibar row-vcentered">
      <b-icon
        v-if="icon"
        :class="`has-text-color-${custom.color} mr-1 start`"
        pack="ubu"
        :icon="icon"
      />

      <span class="text-truncate start">{{ custom.label }}</span>
    </div>

    <b-icon
      v-if="isChecked"
      :class="`has-text-color-${custom.color} end`"
      pack="ubu"
      icon="ubu-check"
    />
  </div>
</template>

<script>
export default {
  name: 'UbuDropdownCustomRow',
  props: {
    custom: {
      type: Object,
      required: true,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>
