/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const order = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/shop/${payload.shopSid}/order`, method: 'REPORT', payload, httpQuery, target }),
};

export default order;
