<template>
  <aside class="ubuSidePanel ubuPanel-xl">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        :to="`/admin/account/${currentAid}/history`"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <h2 class="title is-1">Add features</h2>

    <br>

    <section v-if="featuresNotAdded && featuresNotAdded.length">
      <form>
        <fieldset>
          <fieldset>
            <b-field
              v-for="feature in featuresNotAdded"
              :key="feature.name"
              :label="feature.label"
              horizontal
              class="featureCheckbox"
            >
              <b-checkbox
                v-model="features"
                :native-value="feature"
              />
            </b-field>
          </fieldset>
        </fieldset>

        <br>

        <div class="multibar">
          <div class="end buttons">
            <b-button
              label="Save"
              type="is-primary"
              :disabled="features.length ? false : true"
              @click="addFeatures()"
            />
          </div>
        </div>
      </form>

      <template v-if="error">
        <br>

        <UbuHelper
          type="is-danger"
          :content="error"
          content-type="is-danger"
        />
      </template>
    </section>

    <section v-else>
      <UbuNoData
        title="All features have already been added"
        class="addFeatureSidePanelNoData"
      />
    </section>
  </aside>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheAdminAccountHistoryAddFeatures',
  props: {
    events: { type: Array, default: () => [] },
  },
  data() {
    return {
      features: [],
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      _featureList: 'TheAdmin/featureList',
      accountEvent: 'TheAdmin/accountEvent',
    }),
    currentAid() {
      console.log(this.accountEvent);
      return this.$route.params.aid;
    },
    featuresNotAdded() {
      const allFeatures = this.events.map(({ featureName }) => featureName);

      return Object.values(this._featureList).reduce((acc, row) => {
        if (!allFeatures.includes(row.name)) {
          acc.push({
            ...row,
            label: row.label.split('_').join(' ').capitalize(),
          });
        }
        return acc;
      }, []);
    },
  },
  mounted() {
    if (!Object.values(this._featureList).length) this.reportFeatureList({});
  },
  methods: {
    ...mapActions({
      reportFeatureList: 'TheAdmin/reportFeatureList',
      setFeatures: 'TheAdmin/setFeatures',
    }),
    addFeatures() {
      if (!this.features.length) {
        this.error = 'No features selected';
      } else {
        this.error = null;
        const payload = { aid: this.currentAid, features: this.features };

        this.setFeatures({ payload })
          .then(() => {
            this.$router.push(`/admin/account/${this.currentAid}/history`);
          });
      }
    },
  },
};
</script>
