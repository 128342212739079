<template>
  <b-tab-item value="root">
    <template v-if="assigneds.length">
      <b-dropdown-item
        class="no-hover-background"
        @click="$emit('unassignAll')"
      >
        <span class="subtext has-text-primary">Remove all</span>
      </b-dropdown-item>

      <b-dropdown-item
        v-for="custom in assigneds"
        :key="custom[keyName]"
        @click="$emit('unselectCustom', custom)"
      >
        <UbuDropdownCustomRow
          :custom="custom"
          :icon="iconName"
          :is-checked="true"
        />
      </b-dropdown-item>

      <b-dropdown-item separator />
    </template>

    <b-dropdown-item
      v-for="custom in unassigneds"
      :key="custom[keyName]"
      @click="$emit('selectCustom', custom)"
    >
      <UbuDropdownCustomRow
        :custom="custom"
        :icon="iconName"
        :is-checked="false"
      />
    </b-dropdown-item>

    <b-dropdown-item
      v-if="!assigneds.length && !unassigneds.length"
      custom
      class="has-text-secondary"
    >
      No item with this name in this workspace...
    </b-dropdown-item>

    <b-dropdown-item
      v-if="searchLabel"
      custom
    >
      <b-button
        :label="createButtonLabel"
        type="is-ghost is-borderless"
        icon-pack="ubu"
        icon-left="ubu-add"
        expanded
        class="has-text-left"
        style="padding-left: 5px;"
        @click.stop="$emit('changeTab', 'root.create')"
      />
    </b-dropdown-item>
  </b-tab-item>
</template>

<script>
export default {
  name: 'UbuDropdownCustomSelector',
  props: {
    keyName: {
      type: String,
      required: true,
    },
    searchLabel: {
      type: String,
      default: null,
    },
    assigneds: {
      type: Array,
      default: () => ([]),
    },
    unassigneds: {
      type: Array,
      default: () => ([]),
    },
    iconName: {
      type: String,
      default: 'ubu-custom-tag',
    },
    buttonLabel: {
      type: String,
      default: 'Create',
    },
  },
  computed: {
    createButtonLabel() {
      return `${this.buttonLabel} " ${this.searchLabel} "`;
    },
  },
};
</script>
