<template>
  <TheSimplePanel
    closing-way="emit"
    @close="$emit('close')"
  >
    <template #header>
      <h3 class="subtext has-text-secondary">Reward</h3>
      <h1 class="title is-1">Manually mark as paid reward</h1>
    </template>
    <template #content>
      <div v-if="!reward.paidOn">
        <UbuTableContactPlatform class="ml-2" :cpo="reward" />
        <br>
        <table class="table simple">
          <tbody>
            <tr v-for="(tr, i) in trs" :key="i">
              <td v-for="(td, index) in tr" :key="td" :class="index === 0 ? 'label' : ''">
                {{ td }}
              </td>
            </tr>
          </tbody>
        </table>

        <b-message type="is-info">
          <p>You can mark as paid this reward, and setup a manual payment.</p>
          <p>
            Dates "reward asked on" and "paid on", will be set to now.
            The reward type will be "Manual"
          </p>
          <p>The reminder field will help you to keep information about this transaction.</p>
        </b-message>

        <form @submit.prevent="markAsPaidReward()">
          <b-field label="Reminder">
            <b-input
              v-model="content"
              maxlength="1000"
              type="textarea"
              required
              placeholder="I setup a discount code and send it on Monday..."
            />
          </b-field>
          <b-field>
            <b-button
              :loading="isMarkAsPaidLoading"
              expanded
              type="is-primary"
              native-type="submit"
              label="Save"
            />
          </b-field>
        </form>
      </div>
      <b-message v-else type="is-warning">
        <p>This reward was already marked as paid.</p>
        <p>If you need to change that, please contact support.</p>
      </b-message>
    </template>
  </TheSimplePanel>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheRewardMarkAsPaidPanel',
  props: {
    reward: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isMarkAsPaidLoading: false,
      content: null,
    };
  },
  computed: {
    ...mapGetters({
      userList: 'User/getterUserList',
    }),
    trs() {
      return [
        [
          'Total revenue generated',
          `$${this.reward.totalRevenue / 100}`,
        ],
        [
          'Reward amount',
          `$${this.reward.totalCommissionFee}`,
        ],
        [
          'Approved on',
          this.$moment(this.reward.reviewedOn).format('YYYY/MM/DD'),
        ],
        [
          'Approved by',
          `${this.userList[this.reward.reviewerUid]?.userDetail?.firstName} ${this.userList[this.reward.reviewerUid]?.userDetail?.lastName}`,
        ],
        [
          'Reward asked on',
          this.reward.rewardAskedOn ? this.$moment(this.reward.rewardAskedOn).format('YYYY/MM/DD') : '-',
        ],
      ];
    },
  },
  methods: {
    ...mapActions({
      _markAsPaidReward: 'TheCrew/markAsPaidReward',
    }),
    markAsPaidReward() {
      this.isMarkAsPaidLoading = true;

      return this._markAsPaidReward({ payload: { arid: this.reward.arid, content: this.content, mode: 'MANUAL' } })
        .then(() => {
          this.isMarkAsPaidLoading = false;
          this.$buefy.snackbar.open({ ...this.$snackbarSuccess, message: 'successfully marked as paid' });
          this.$emit('close');
        });
    },
  },
};
</script>
