import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  getters: {
    ...getters.channelGroup,
    ...getters.contactPlatformOverview,
    ...getters.contactPrivateMetadata,
    ...getters.privateNote,
    ...getters.shopify,
    ...getters.threadGroup,
    ...getters.campaign,
    ...getters.contact,
    ...getters.contactGroup,
    ...getters.authenticate,
    ...getters.user,
    ...getters.account,
    ...getters.shop,
    ...getters.affiliationCode,
    ...getters.affiliationCodeFolder,
    detailedActivityList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/reportDetailledActivityContactPanel/getterDetailedActivityList': detailedActivityList } = rootGetters;

      return detailedActivityList;
    },
  },
  actions: {
    ...actions.authenticate,
    ...actions.channelGroup,
    ...actions.favorite,
    ...actions.contactPlatformOverview,
    ...actions.contactPrivateMetadata,
    ...actions.activity,
    ...actions.privateNote,
    ...actions.contact,
    ...actions.shopify,
    ...actions.favikon,
    // ...actions.affiliationCode,
    reportDetailledActivityContactPanel({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/reportDetailledActivityContactPanel/report',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'report detailed activity',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 880 ~ reportDetailledActivityContactPanel ~ e', e);
          Snackbar.open({
            message: 'Unable to get detailed activity.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
  },
};
