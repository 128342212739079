/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';

/* eslint-disable max-len */
export default {
  namespaced: true,
  getters: {
    contactPrivateMetadataList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/contactPrivateMetadata/getterContactPrivateMetadataList': contactPrivateMetadataList } = rootGetters;

      return contactPrivateMetadataList;
    },
    tribeForm: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/tribe/getterTribe': tribeForm } = rootGetters;

      return tribeForm;
    },
  },
  actions: {
    reportContactPrivateMetadata({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/contactPrivateMetadata/report',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
          },
          httpQuery: {},
        },
        textNotifier: 'report campaign mention',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report filter group list.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
    getTribeForm({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/tribe/get',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
          },
          httpQuery: {},
        },
        textNotifier: 'get tribe form',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to get tribe form.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
    createTribeForm({ dispatch, rootGetters }, { payload }) {
      console.log('🚀 ~ file: TheSettingTribeForm.store.js ~ line 79 ~ createTribeForm ~ payload', payload);
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/tribe/create',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery: {},
        },
        textNotifier: 'create tribe form',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to create tribe form.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
    updateTribeForm({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/tribe/update',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery: {},
        },
        textNotifier: 'update tribe form',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to update tribe form.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
  },
};
