/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const affiliationReward = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/affiliationReward`, method: 'REPORT', payload, httpQuery, target }),
  totalCommissionByPeriod: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/affiliationReward/${payload.period}`, method: 'GET', payload, httpQuery, target }),
  setReviews: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/affiliationReward/`, method: 'PUT', payload, httpQuery, target }),
  markAsPaid: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/affiliationReward/${payload.arid}`, method: 'PUT', payload, httpQuery, target }),

  reportReward: ({ payload, httpQuery }) => builder({ url: '/api/monitor/reward/', method: 'REPORT', payload, httpQuery, target }),
  rebuildBalances: ({ payload, httpQuery }) => builder({ url: `/api/monitor/reward/${payload.arid}`, method: 'PUT', payload, httpQuery, target }),
};

export default affiliationReward;
