/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    crmListList: null,
  },
  mutations: {
    SET_CRM_LIST_LIST(state, newData) { state.crmListList = newData; },
    RESET_STORE(state) {
      state.crmListList = null;
    },
  },
  getters: {
    getterCrmListList: (state) => state.crmListList,
  },
  actions: {
    get({ commit, getters: { getterCrmListList }, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CRM_LIST.GET')) return undefined;

      return clark.api.channelGroup.crmList.get({ payload, httpQuery })
        .then((crmList) => {
          commit('SET_CRM_LIST_LIST', { ...getterCrmListList, [crmList.clid]: crmList });
          return crmList;
        });
    },

    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CRM_LIST.REPORT')) return undefined;

      return clark.api.channelGroup.crmList.report({ payload, httpQuery })
        .then((crmListList) => {
          commit('SET_CRM_LIST_LIST', crmListList.arrayMapper('clid'));
          return crmListList;
        });
    },

    create({ commit, rootGetters, getters: { getterCrmListList } }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CRM_LIST.CREATE')) return undefined;

      return clark.api.channelGroup.crmList.post({ payload, httpQuery })
        .then((crmList) => {
          commit('SET_CRM_LIST_LIST', { ...getterCrmListList, [crmList.clid]: crmList });

          return crmList;
        });
    },

    update({ commit, rootGetters, getters: { getterCrmListList } }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CRM_LIST.UPDATE')) return undefined;

      return clark.api.channelGroup.crmList.put({ payload, httpQuery })
        .then((crmList) => {
          commit('SET_CRM_LIST_LIST', { ...getterCrmListList, [crmList.clid]: crmList });

          return crmList;
        });
    },

    delete({ commit, rootGetters, getters: { getterCrmListList } }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CRM_LIST.DELETE')) return undefined;

      return clark.api.channelGroup.crmList.delete({ payload, httpQuery })
        .then(() => {
          commit('SET_CRM_LIST_LIST', {
            ...Object.values(getterCrmListList).filter(({ clid }) => clid !== payload.clid).arrayMapper('clid'),
          });
        });
    },

    fillList({ rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'CRM_LIST.FILL_LIST')) return undefined;

      return clark.api.channelGroup.crmList.fillList.post({ payload });
    },

    fillListByContactPlatform({ rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'CRM_LIST.FILL_LIST_BY_CONTACT_PLATFORM')) return undefined;

      return clark.api.channelGroup.crmList.fillListByContactPlatform.post({ payload });
    },

    removeFromList({ rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'CRM_LIST.REMOVE_FROM_LIST')) return undefined;

      return clark.api.channelGroup.crmList.removeFromList.delete({ payload });
    },
  },
};
