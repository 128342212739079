import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      _memberGroupList: 'TheFilters/memberGroupList',
    }),
    memberGroupList() {
      return Object.values(this._memberGroupList)
        .map(({ name, mgid }) => ({ label: name, value: mgid }));
    },
  },
};
