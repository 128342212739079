/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    accountStateList: {},
  },
  mutations: {
    SET_ACCOUNT_STATE_LIST(state, newData) { state.accountStateList = newData; },
    RESET_STORE(state) {
      state.accountStateList = {};
    },
  },
  getters: {
    getterAccountStateList: (state) => state.accountStateList,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'ACCOUNT_STATE.REPORT')) return undefined;

      return clark.api.accountState.report({ payload, httpQuery })
        .then((accountStateList) => {
          commit('SET_ACCOUNT_STATE_LIST', accountStateList.arrayMapper('state'));
        });
    },
  },
  modules: {},
};
