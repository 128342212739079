<template>
  <b-modal
    v-model="localIsModalNoteOpen"
    :width="400"
    class="ubuModalNote"
    has-modal-card
    @close="$emit('close')"
  >
    <div class="card">
      <div class="card-header" />

      <div class="card-content">
        <div class="content">
          <b-input
            ref="input"
            v-model="localText"
            type="textarea"
          />
        </div>
      </div>

      <div class="card-footer">
        <b-button
          v-if="!showConfirmDelete"
          class="mr-1"
          label="Delete"
          icon-pack="ubu"
          icon-left="ubu-trash"
          type="is-transparent"
          expanded
          @click="askConfirmDelete()"
        />

        <b-button
          v-else
          class="mr-1"
          label="Confirm delete"
          icon-pack="ubu"
          icon-left="ubu-trash"
          type="is-primary"
          expanded
          @click="$emit('delete')"
        />

        <b-button
          class="ml-1"
          label="Save"
          type="is-primary"
          expanded
          @click="$emit('save')"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'UbuModalEditNote',
  model: {
    prop: 'text',
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    isModalNoteOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showConfirmDelete: false,
    };
  },
  computed: {
    localText: {
      get() { return this.text; },
      set(val) { this.$emit('input', val); },
    },
    localIsModalNoteOpen: {
      get() { return this.isModalNoteOpen; },
      set() { this.$emit('close'); },
    },
  },
  mounted() {
    this.showConfirmDelete = false;
    this.$refs.input.focus();
  },
  methods: {
    askConfirmDelete() {
      this.showConfirmDelete = true;
    },
  },
};
</script>
