<template>
  <section v-if="link">
    <h1 class="title is-1">Click to receive your new password by email</h1>

    <br>

    <div class="buttons is-right">
      <b-button
        label="Reset password"
        type="is-primary"
        @click="sendPassword()"
      />
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheAuthenticateSendNewPassword',
  data() {
    return {
      link: null,
    };
  },
  mounted() {
    this.link = this.$route.params.link;
  },
  methods: {
    ...mapActions({
      resetAndSendPassword: 'TheAuthenticateForgotPassword/resetAndSendPassword',
    }),

    sendPassword() {
      return this.resetAndSendPassword({
        payload: { link: this.link },
        httpQuery: { isStub: true },
      })
        .then(() => { this.$router.push('/auth/login'); })
        .catch((e) => {
          console.log('🚀 ~ file: TheAuthenticateSendNewPassword.vue ~ line 45 ~ sendPassword ~ e', e);
        });
    },
  },
};
</script>
