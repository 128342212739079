import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    facebookConnectList: null,
  },
  mutations: {
    SET_FACEBOOK_CONNECT_LIST(state, newData) { state.facebookConnectList = newData; },
    RESET_STORE(state) {
      state.facebookConnectList = null;
    },
  },
  getters: {
    getterFacebookConnectList: (state) => state.facebookConnectList,
  },
  actions: {
    report({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'FACEBOOK_CONNECT.REPORT')) return undefined;

      return clark.api.account.facebookConnect.report({ payload, httpQuery: {} })
        .then((facebookConnectList) => {
          commit('SET_FACEBOOK_CONNECT_LIST', facebookConnectList[0]);
        });
    },

    create({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'FACEBOOK_CONNECT.CREATE')) return undefined;

      return clark.api.account.facebookConnect.post({ payload, httpQuery: {} })
        .then((facebookConnectList) => {
          commit('SET_FACEBOOK_CONNECT_LIST', facebookConnectList);
        });
    },
  },
};
