/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  getters: {
    navigation: (state) => state.navigation,
    ...getters.tremendousAccount,
    ...getters.tremendousCampaign,
    ...getters.tremendousFundingSource,
    ...getters.affiliationReward,
  },
  actions: {
    ...actions.affiliationReward,
    ...actions.tremendousAccount,
    ...actions.tremendousCampaign,
    ...actions.tremendousFundingSource,
  },
};
