<template>
  <section>
    <b-tab-item value="root.changeStatus">
      <div class="buttons">
        <UbuActionCard
          v-for="item in statusListFiltered"
          :key="item.label"
          class="mb-4"
          :label="item.label.capitalize()"
          icon-pack="ubu"
          :icon-left="item.icon"
          expanded
          @click="bulkChangeStatus(item.status)"
        />
      </div>
    </b-tab-item>

    <b-tab-item value="root.changeStatus.done">
      <b-message type="is-success">
        <div class="multibar row-vcentered">
          <b-icon
            pack="ubu"
            icon="ubu-success"
            size="is-medium"
            class="start"
          />
          <div class="start">
            <p class="">
              Success! It may take a few seconds for statuses to be changed.
            </p>
          </div>
        </div>
      </b-message>
    </b-tab-item>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheBulkChangeStatus',
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Object,
      required: true,
    },
    tabItems: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      currentCampaignCid: 'TheBulkChangeStatus/currentCampaignCid',
      activityStatusListFiltered: 'TheBulkChangeStatus/activityStatusListFiltered',
    }),

    localActiveTab: {
      get() { return this.activeTab; },
      set(val) { this.$emit('update:activeTab', val); },
    },

    onlyActiveList() {
      return this.checkedRows.filter(({ campaignActivity: { endedOn } }) => !endedOn);
    },

    statusListFiltered() {
      const unusableStatus = ['UNSCHEDULED', 'SCHEDULED', 'CANCELED', 'DELIVERED'];

      return this.activityStatusListFiltered.reduce((acc, activityStatus) => {
        if (!unusableStatus.includes(activityStatus.status)) acc.push(activityStatus);
        return acc;
      }, []);
    },
  },
  methods: {
    ...mapActions({
      _bulkChangeStatus: 'TheBulkChangeStatus/bulkChangeStatus',
    }),

    UP_changeToNewTab(activeTab) {
      this.$emit('update:activeTab', activeTab);
    },

    bulkChangeStatus(status) {
      return this._bulkChangeStatus({
        payload: {
          cid: this.currentCampaignCid,
          aids: this.onlyActiveList.map(({ aid }) => aid),
          status,
        },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_updateCampaignActivity',
          payload: {
            cpids: this.onlyActiveList.map(({ cpid }) => cpid),
            status,
          },
        }],
      })
        .then(() => { this.UP_changeToNewTab(this.tabItems['root.changeStatus.done']); });
    },
  },
};
</script>
