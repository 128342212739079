export default {
  computed: {
    commonFilterCollection() {
      return {
        /**
       * @method  minFollowerCount
       * @params  {Number|any}   rawMin  minimum to be compared to
       * @returns {Function}
       * */
        minFollowerCount(rawMin) {
          const min = Number(rawMin);
          return (m) => (Number(m.followerCount) >= min ? m : undefined);
        },

        /**
       * @method  maxFollowerCount Count
       * @params  {Number}   rawMax  maximum to be compared to
       * @returns {Function}
       * */
        maxFollowerCount(rawX) {
          const x = Number(rawX);
          return (m) => (Number(m.followerCount) <= x ? m : undefined);
        },

        /**
       * @method  hasLabels Count
       * @params  {[Number]}   cgids
       * @returns {Function}
       * */
        hasLabels(...rawCgids) {
          return this.hasCustoms(rawCgids, 'labels', 'cgid');
        },

        /**
       * @method  hasCustoms Count
       * @params  {[Number]}   cgids
       * @returns {Function}
       * */
        hasCustoms(rawIds, listKey, idKey) {
          if (!rawIds || !rawIds.length) return (m) => m;
          const ids = rawIds.map((id) => Number(id));
          return (m) => (m[listKey]
            && m[listKey].find((r) => ids.includes(Number(r[idKey]))) ? m : undefined);
        },

        /**
       * @method  searchByUsername Count
       * @params  {Number}   rawMax  maximum to be compared to
       * @returns {Function}
       * */
        searchByUsername(rawSearch) {
          const search = rawSearch.toLowerCase();
          return (m) => (m.username.toLowerCase().includes(search) ? m : undefined);
        },

        /**
       * @method  searchByText Count
       * @params  {Number}   rawMax  maximum to be compared to
       * @returns {Function}
       * */
        searchByText(rawSearch) {
          const search = rawSearch.toLowerCase();
          return (m) => (m.text.toLowerCase().includes(search) ? m : undefined);
        },

        /**
       * @method  searchByName Count
       * @params  {String}
       * @returns {Function}
       * */
        searchByName(rawSearch) {
          const search = rawSearch.toLowerCase();
          return (m) => (m.name.toLowerCase().includes(search) ? m : undefined);
        },

        /**
       * @method  searchByTitle
       * @params  {String}
       * @returns {Function}
       * */
        searchByTitle(rawSearch) {
          const search = rawSearch.toLowerCase();
          return (m) => (m.title.toLowerCase().includes(search) ? m : undefined);
        },

        /**
       * @method  searchByLabelName Count
       * @params  {Number}   rawMax  maximum to be compared to
       * @returns {Function}
       * */
        searchByLabelName(rawSearch) {
          const search = rawSearch.toLowerCase();
          return (m) => (m.label.toLowerCase().includes(search) ? m : undefined);
        },

        /**
       * @method  searchByCode Count
       * @params  {Number}   rawMax  maximum to be compared to
       * @returns {Function}
       * */
        searchByCode(raw) {
          const search = raw.toLowerCase();
          return (m) => (m.code.toLowerCase().includes(search) ? m : undefined);
        },

        /**
       * @method  searchByContactPlatformCpid Count
       * @params  {Number}   rawMax  maximum to be compared to
       * @returns {Function}
       * */
        searchByContactPlatformCpid(rawContactPlatformCpid) {
          const search = String(rawContactPlatformCpid);
          return (m) => (String(m.contactPlatformCpid) === search ? m : undefined);
        },

        /**
       * @method  filterByAffiliateCode Count
       * @params  {Number}   rawMax  maximum to be compared to
       * @returns {Function}
       * */
        filterByAffiliateCode() {
          return (m) => (m.contactPlatformCpid || m.campaignCid ? m : undefined);
        },

        /**
       * @method  filterByTemplateFolder Count
       * @params  {Number}   rawMax  maximum to be compared to
       * @returns {Function}
       * */
        filterByTemplateFolder(raw) {
          console.log('🚀 ~ file: FilterEngineMixin.js ~ line 121 ~ filterByTemplateFolder ~ raw', raw);
          const search = Number(raw);
          return (m) => (Number(m.templateFolderTfid) === search ? m : undefined);
        },
      };
    },
  },
  methods: {
    filterEngine(data, filterOpts) {
      const filtersCollection = { ...this.commonFilterCollection, ...this._filterCollection };
      const filters = Object.entries(filterOpts)
        .map(([name, filterParams]) => filtersCollection[name](
          ...(Array.isArray(filterParams) ? filterParams : [filterParams]),
        ));

      return data.filter((row) => filters.reduce((res, filterFunction) => {
        if (res === undefined) return res;

        return filterFunction(row);
      }, []));
    },
  },
};
