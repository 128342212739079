/* eslint-disable object-curly-newline */

import builder from '../../engine';

const target = 'clark';

const tokenLink = {
  get: ({ payload, httpQuery }) => builder({ url: `/api/tokenLink/${payload.tokenLink}`, method: 'GET', payload, httpQuery, target }),
};

export default tokenLink;
