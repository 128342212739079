<template>
  <b-dropdown
    aria-role="list"
    :position="dropdownPosition"
    max-height="400px"
    scrollable
    class="overflowX-hidden"
    @active-change="focusInput()"
  >
    <template #trigger>
      <b-button
        v-if="simpleButton"
        :icon-left="customDefault.icon"
        class="is-icon"
        type="is-secondary"
        icon-pack="ubu"
      />
      <UbuCustomButton
        v-else
        :custom-default="customDefault"
        :customs="customs"
        :active-tooltip="activeTooltip"
      />
    </template>
    <b-menu class="ubuDropdownModal-s">
      <b-menu-list>
        <template v-for="(el, index) in Object.values(sortedElements)">
          <component
            :is="el.cmp"
            :key="el.ordinal"
            :ref="'item-'+index"
            v-model="sortedElements[el.ordinal]"
            :item-search="sortedElements[0]"
            v-on="$attrs"
            @input="$emit('input', $event)"
            @createItem="$emit('createItem', $event)"
            @selectItem="$emit('selectItem', $event)"
            @update:PopoverItemCreator="$emit('update:PopoverItemCreator', $event)"
          />
        </template>
      </b-menu-list>
    </b-menu>
  </b-dropdown>
</template>

<script>
export default {
  name: 'UbuRealPopover',
  props: {
    fullList: {
      type: Array,
      required: true,
    },
    customs: {
      type: Array,
      required: true,
    },
    customDefault: {
      type: Object,
      required: true,
    },
    simpleButton: {
      type: Boolean,
      default: false,
    },
    activeTooltip: {
      type: Boolean,
      default: true,
    },
    dropdownPosition: {
      type: String,
      default: 'is-bottom-left',
    },
  },
  data() {
    return {
      isDisplayed: false,
    };
  },
  computed: {
    filteredElements() {
      let list = [...this.fullList]
        .map((row) => (row.ordinal === -1 ? { ...row, ordinal: this.fullList.length } : row));

      const filterItem = list.find(({ cmp }) => cmp === 'PopoverItemSearch');

      if (filterItem && filterItem.model && filterItem.filter) {
        const { itemType, onKey } = filterItem.filter;

        list = list.reduce((acc, item) => ((item[onKey] && item[onKey].includes(filterItem.model))
          || item.cmp !== itemType ? acc.concat(item) : acc), []);
      }

      return list;
    },
    sortedElements() {
      const list = this.filteredElements.map((item) => {
        if (this.customs.find((custom) => item.id === custom[item.itemKeyName])) {
          return ({
            ...item,
            isSelected: true,
          });
        }
        return item;
      });

      return list.sort((a, b) => a.ordinal - b.ordinal).arrayMapper('ordinal');
    },
  },
  methods: {
    input(event) {
      console.log('🚀 ~ file: TheRealPopover.vue ~ line 159 ~ input ~ event', event);
      const listWithoutEl = [...this.fullList].filter(({ ordinal }) => ordinal !== event.ordinal);
      this.fullList = [...listWithoutEl, event];
    },
    focusInput() {
      this.$nextTick(() => {
        this.isDisplayed = !this.isDisplayed;
        if (this.isDisplayed) {
          // Needs a better way to fetch the actual searchInput
          const searchInputField = this.$refs['item-0'][0].$children[0].$children[0].$children[0];
          searchInputField.focus();
        }
      });
    },
  },
};
</script>
