<template>
  <div
    class="ubuChip"
    @click="$emit('openMention', cpo)"
  >
    Open
  </div>
</template>

<script>
export default {
  name: 'UbuTableChipMention',
  props: {
    cpo: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
