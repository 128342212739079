<template>
  <!-- eslint-disable max-len -->
  <div
    class="ubuSettingView start"
  >
    <header
      v-if="detailledUser"
      class="start"
    >
      <h2 class="subtitle is-1 has-text-secondary m-0">{{ detailledUser.firstName }} {{ detailledUser.lastName }}</h2>
      <div class="multibar row-vcentered">
        <h1 class="start title is-1">Users Details</h1>
        <div
          class="buttons end"
        >
          <b-button
            v-if="isAnOtherUser && !detailledUser.lockedOn"
            label="Lock user"
            type="is-primary"
            @click="lockUser()"
          />
          <b-button
            v-else-if="isAnOtherUser"
            label="Unlock user"
            type="is-secondary"
            @click="unlockUser()"
          />
          <b-button
            label="Admin Login"
            type="is-success"
            @click="createLink()"
          />
        </div>
      </div>
    </header>

    <br>

    <DevDetails :cnt="{name: 'detailledUser', obj: detailledUser}" />
    <template v-if="detailledUser">
      <section class="pt-3 pb-3">
        <div class="multibar">
          <b-image
            class="start is-80"
            lazy
            rounded
            src=""
            alt="Profile picture"
            :placeholder="'https://via.placeholder.com/140x140.png'"
            :src-fallback="'https://via.placeholder.com/140x140.png'"
          />

          <b-upload
            v-model="file"
            class="start a-self-center ml-5"
            accept="image/*"
            @input="uploadImage($event)"
          >
            <a class="is-link">Edit profil picture</a>
          </b-upload>
        </div>

        <hr class="is-l">
      </section>
      <section class="pt-3 pb-3">
        <p class="subtext has-text-secondary">User ID: </p>
        <p class="text">{{ detailledUser.uid }}</p>

        <hr class="is-l">
      </section>
      <section class="pt-3 pb-3">
        <p class="subtext has-text-secondary">Is Ubu team: </p>
        <div class="text">{{ detailledUser.isUbu }}</div>

        <hr class="is-l">
      </section>
      <section class="pt-3 pb-3">
        <p class="subtext has-text-secondary">Role: </p>
        <p class="text">{{ detailledUser.userRoleUrid }}</p>

        <hr class="is-l">
      </section>
      <template v-for="item in displayInfoOptions">
        <section
          v-if="item"
          :key="item.text"
          class="pt-3 pb-3"
        >
          <div class="multibar">
            <div class="start">
              <p class="subtext has-text-secondary">{{ item.text }} :</p>
              <p class="text">{{ item.data ? item.data : '-' }}</p>
            </div>
            <b-button
              v-if="item.labelButton"
              class="is-borderless end"
              :type="item.type"
              :label="item.labelButton"
              tag="router-link"
              :to="item.to"
            />
          </div>

          <router-view
            :name="item.router"
            :path="`/users/user/${currentUid}/details/`"
            :detailled-user="detailledUser"
            target-other-user
            @refresh="refresh()"
          />

          <hr class="is-l">
        </section>
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheUsersUserDetails',
  props: {
    selected: { type: Object, required: true },
  },
  data() {
    return {
      file: null,
      details: {
        firstName: null,
        lastName: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      detailledUser: 'TheUsers/detailledUser',
      myAuthenticate: 'TheUsers/myAuthenticate',
    }),
    isAnOtherUser() {
      if (this.myAuthenticate.uid === this.detailledUser.uid) return false;
      return true;
    },
    currentUid() {
      return this.$route.params.uid;
    },
    currentAuthenticateAid() {
      if (!Object.values(this.detailledUser).length) return null;
      return this.detailledUser.authenticates.find(({ revokedOn }) => revokedOn === null);
    },
    displayInfoOptions() {
      if (!this.detailledUser) return false;
      const { passName: email } = this.currentAuthenticateAid;

      return [
        {
          text: 'First name', data: this.detailledUser.firstName, labelButton: 'Edit', type: 'is-ghost', to: `/users/user/${this.currentUid}/details/editName`, router: 'editName',
        },
        {
          text: 'Last name', data: this.detailledUser.lastName, labelButton: 'Edit', type: 'is-ghost', to: `/users/user/${this.currentUid}/details/editName`, router: 'editName',
        },
        {
          text: 'Phone number', data: this.detailledUser.phone, labelButton: 'Edit', type: 'is-ghost', to: `/users/user/${this.currentUid}/details/editPhone`, router: 'editPhone',
        },
        {
          text: 'Email', data: email, labelButton: 'Edit', type: 'is-ghost', to: `/users/user/${this.currentUid}/details/editEmail`, router: 'editEmail',
        },
        {
          text: 'Password', data: '************', labelButton: 'Edit', type: 'is-ghost', to: `/users/user/${this.currentUid}/details/editPassword`, router: 'editPassword',
        },
      ];
    },
  },
  mounted() {
    return Promise.all([
      this.getDetailledUser({ payload: { uid: this.currentUid } }),
    ]);
  },
  methods: {
    ...mapActions({
      getDetailledUser: 'TheUsers/getDetailledUser',
      up_changeEmail: 'TheUsers/up_changeEmail',
      _lockUser: 'TheUsers/lockUser',
      _unlockUser: 'TheUsers/unlockUser',
      _createLink: 'TheUsers/createLink',
    }),
    uploadImage(event) {
      console.log('🚀 ~ file: TheSettingUserSetting.vue ~ line 54 ~ uploadImage ~ event', event);
    },
    refresh() {
      this.getDetailledUser({ payload: { uid: this.currentUid } });
    },
    createLink() {
      this._createLink({ payload: { uid: this.currentUid } })
        .then(({ link }) => window.open(`${process.env.VUE_APP_SELINA_ADMIN_LOGIN_BASE_URL}/adminLogin/${link}`, '_blank'));
    },
    lockUser() {
      this.$buefy.dialog.confirm({
        title: 'Block user',
        message: 'You are about to block the user. He will no longer be able to interact with the application. Are you sure you want to continue ?',
        confirmText: 'Yes',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this._lockUser({ payload: { uid: this.currentUid } }),
      });
    },
    unlockUser() {
      this._unlockUser({ payload: { uid: this.currentUid } });
    },
  },
};
</script>
