<template>
  <!-- eslint-disable max-len -->
  <div
    class="ubuSettingView start"
  >
    <DevDetails :cnt="{name: 'userAccountMemberViewList', obj: userAccountMemberViewList}" />
    <DevDetails
      :cnt="{
        name: 'allChannelGroupAccountNewVersion', obj: allChannelGroupAccountNewVersion
      }"
    />
    <header class="start">
      <h2
        v-if="detailledUser"
        class="subtitle is-1 has-text-secondary m-0"
      >
        {{ detailledUser.firstName }} {{ detailledUser.lastName }}
      </h2>
      <div class="multibar">
        <h1 class="start title is-1">Users accounts</h1>
      </div>
    </header>

    <br>

    <template v-if="allChannelGroupAccountNewVersion && allChannelGroupAccountNewVersion.length">
      <template
        v-for="account in allChannelGroupAccountNewVersion"
      >
        <b-table
          v-if="account"
          :key="account.aid"
          class="ubuTableSetting"
          :data="[account]"
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="aid"
          :has-detailed-visible="() => false"
          hoverable
          :is-row-selectable="() => true"
          :show-header="false"
          @click="toggleOpenedDetails($event)"
        >
          <b-table-column
            v-for="column in columns"
            :key="column.col"
            v-slot="props"
            :label="column.label"
            :field="column.col"
            :sortable="column.isSortable"
            :cell-class="column.cellClass"
          >
            <template v-if="column.cmp === 'flat'">
              <p class="ellipsis text is-3 mb-1">{{ props.row[column.col] }}</p>
            </template>

            <template v-if="column.cmp === 'tag'">
              <b-tag
                v-if="props.row[column.tag.condition]"
                :type="column.tag.color"
              >
                {{ column.tag.text }}
              </b-tag>
            </template>

            <template v-if="column.cmp === 'action'">
              <div class="multibar">
                <b-icon
                  class="end"
                  pack="ubu"
                  :icon="defaultOpenedDetails.includes(props.row.uid)
                    ? 'ubu-arrow-down'
                    : 'ubu-arrow-up'"
                />
              </div>
            </template>
          </b-table-column>

          <template #detail="props">
            <DevDetails
              :cnt="{name: 'props.row', obj: props.row}"
            />
            <legend class="has-text-secondary subtitle is-3">CHANNELS</legend>
            <div
              v-for="(row) in props.row.editableItem"
              :key="row.channelGroup.cgid"
            >
              <UbuChannelGroupAndChannelSelector
                :model="JSON.stringify(row)"
                @updateGroup="updateGroupNextGen({
                  event: $event,
                  uid: props.row.uid,
                  aid: props.row.aid,
                  userMode: true
                })"
              />
            </div>
          </template>
        </b-table>
      </template>
    </template>
    <template v-else>
      <div class="multibar">
        <p class="title is-3 has-text-secondary">
          This user is not linked to an account... Yet ! 👾
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import iconsMixins from '@dailyplanet/cross-addons/crossCommon/$mixins/dataIcons.mixins';
import linkUnlinkChannelChannelGroup from '@dailyplanet/cross-addons/workspace/$mixins/linkUnlinkChannelChannelGroup.mixins';
import TheUsersUserAccountColumns from '../$mixins/usersUserAccount.columns.columns.mixins';

export default {
  name: 'TheUsersUserAccounts',
  mixins: [
    TheUsersUserAccountColumns,
    iconsMixins,
    linkUnlinkChannelChannelGroup,
  ],
  data() {
    return {
      defaultOpenedDetails: [],
      accountMemberViewList: null,
    };
  },
  computed: {
    ...mapGetters({
      userAccountMemberViewList: 'TheUsers/userAccountMemberViewList',
      detailledUser: 'TheUsers/detailledUser',
    }),
    currentUid() {
      return this.$route.params.uid;
    },
    allChannelGroupAccountNewVersion() {
      if (!this.userAccountMemberViewList) return [];

      return this.userAccountMemberViewList.map(({
        activeChannelGroup, activeChannel, channelGroupList = [], channelList = [], ...member
      }) => ({
        ...member,
        ...member.userDetail,
        fullName: `${member.userDetail.firstName} ${member.userDetail.lastName}`,
        isMe: false,
        channelGroupList: channelGroupList || [],
        channelList: channelList || [],
        editableItem: channelGroupList.reduce((acc, { cgid, name, picture }) => {
          if (!acc[cgid]) {
            acc[cgid] = {
              channelGroup: {
                cgid,
                name,
                picture,
                initials: name.charAt(0).toUpperCase(),
                isActive: activeChannelGroup.includes(cgid),
              },
              channelList: channelList.reduce((channels, channel) => {
                if (channel.channelGroupCgid === cgid && !channels[channel.cid]) {
                  return {
                    ...channels,
                    [channel.cid]: {
                      ...channel,
                      isActive: activeChannel.includes(channel.cid),
                    },
                  };
                }

                return channels;
              }, {}),
            };
            return acc;
          }
          return acc;
        }, {}),
      }));
    },
  },
  mounted() {
    return Promise.all([
      this.reportUserAccountMemberView({ payload: { uid: this.currentUid } }),
      this.getDetailledUser({ payload: { uid: this.currentUid } }),
    ]);
  },
  methods: {
    ...mapActions({
      getDetailledUser: 'TheUsers/getDetailledUser',
      reportUserAccountMemberView: 'TheUsers/reportUserAccountMemberView',
    }),
    toggleOpenedDetails(user) {
      if (this.defaultOpenedDetails.includes(user.aid)) {
        this.defaultOpenedDetails = this.defaultOpenedDetails.filter((aid) => aid !== user.aid);
      } else {
        this.defaultOpenedDetails.push(user.aid);
      }
    },
    // initUpdateGroup({ event, uid, row }) {
    //   const account = this.userAccountMemberViewList
    //     .find((ac) => ac.aid === row.aid);

    //   this.updateGroup({ event, uid, account: [account] });
    // },
  },
};
</script>
