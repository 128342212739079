import { actions, getters as _getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    activeTab: {
      tab: 'root',
      label: 'All',
    },
    selectedTemplateFolder: {
      label: 'All',
      tfid: null,
    },
  },
  mutations: {
    SET_ACTIVE_TAB(state, newData) { state.activeTab = newData; },
    SET_SELECTED_TEMPLATE_FOLDER(state, newData) { state.selectedTemplateFolder = newData; },
    RESET_STORE(state) {
      state.activeTab = {
        tab: 'root',
        label: 'All',
      };
    },
  },
  getters: {
    ..._getters.template,
    ..._getters.templateFolder,

    activeTab: (state) => state.activeTab,
    selectedTemplateFolder: (state) => state.selectedTemplateFolder,

    tabItems: (state) => ({
      root: {
        tab: 'root',
        label: 'All',
      },
      'root.selectedTemplateFolder': {
        tab: 'root.selectedTemplateFolder',
        label: `${state.selectedTemplateFolder.label}`,
      },
    }),
  },
  actions: {
    ...actions.template,
    ...actions.templateFolder,

    UP_activeTab({ commit }, newData) {
      commit('SET_ACTIVE_TAB', newData);
    },
    UP_resetStore({ commit }) {
      commit('RESET_STORE');
    },
    goToPreviousTab({ commit, getters }) {
      const { activeTab, tabItems } = getters;

      if (activeTab.tab === 'root') return;

      const tabToGo = activeTab.tab.split('.').slice(0, -1).join('.');
      commit('SET_ACTIVE_TAB', tabItems[tabToGo]);
    },
    goToTabByTabPath({ commit, getters }, tabToGo) {
      const { tabItems } = getters;
      commit('SET_ACTIVE_TAB', tabItems[tabToGo]);
    },

    UP_selectedTemplateFolder({ commit }, selectedTemplateFolder) {
      commit('SET_SELECTED_TEMPLATE_FOLDER', selectedTemplateFolder);
    },
  },
};
