<template>
  <section>
    <template v-if="hasError">
      <UbuHelper
        type="is-danger"
        :content="hasError"
        content-type="danger"
      />

      <br>

      <div class="buttons is-right">
        <b-button
          label="Contact support"
          class="is-primary"
          @click="askSupport()"
        />
      </div>

      <br>
    </template>

    <template v-else-if="isSuccess">
      <UbuHelper
        type="is-success"
        :content="message"
        content-type="success"
      />

      <br>
    </template>

    <form
      v-else
      @submit.prevent="downloadMentions()"
    >
      <b-field label="Email">
        <b-input
          v-model="localEmail"
          type="email"
          required
        />
      </b-field>

      <br>

      <div class="buttons is-right">
        <b-button
          native-type="submit"
          label="Get download link"
          class="is-primary"
        />
      </div>
    </form>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheDownloadMentions',
  model: {
    prop: 'mentionExtrefIdList',
  },
  props: {
    mentionExtrefIdList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isError: false,
      isSuccess: false,
      message: null,
      email: null,
      maxDownloadableMentions: 500,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'TheAuthenticate/myAuthenticate',
    }),
    localEmail: {
      get() { return this.email || this.currentUser.passName; },
      set(val) { this.email = val; },
    },
    hasError() {
      if (this.isError) return this.message;
      if (!this.mentionExtrefIdList.length || this.mentionExtrefIdList.length > this.maxDownloadableMentions) return `It is not possible to download the mentions because the selection condition is not met. You must select between 1 and ${this.maxDownloadableMentions} mentions. You are trying to upload ${this.mentionExtrefIdList.length} mention${this.mentionExtrefIdList > 1 ? 's' : ''}`;
      return null;
    },
  },
  methods: {
    ...mapActions({
      _downloadMention: 'TheMention/downloadMention',
    }),
    askSupport() {
      this.$intercom.showNewMessage('Hello ! I need to download all my mention but there are more than 1000... 🙌');
    },
    downloadMentions() {
      this._downloadMention({
        payload: {
          medias: this.mentionExtrefIdList,
          recipients: [this.localEmail],
        },
        httpQuery: { isStub: true },
      })
        .then(() => {
          this.$buefy.snackbar.open({
            ...snackbarSuccess,
            message: 'Success! You will receive an email shortly.',
          });
          this.isSuccess = true;
          this.message = 'Success! Please note that it can take up to a few minutes before you receive your email. Contact support if you still haven’t received it.';
        })
        .catch(({ message }) => {
          this.isError = true;
          switch (message) {
            case 'E_BAD_REQUEST_CHANNEL_GROUP_DOWNLOAD_MENTION_SCHEMA':
              this.message = 'An error has occurred due to the data being sent. Please contact support'; break;
            case 'E_BAD_REQUEST_CHANNEL_GROUP_DOWNLOAD_MENTION_MUST_BE_LESS_THAN_1000_ENTRIES':
              this.message = 'You can\'t download more than 1000 mentions at once... Download your mentions in multiple batches or contact support'; break;
            default:
              this.message = 'An unknown error has occurred. Please contact support'; break;
          }
        });
    },
  },
};
</script>
