/* eslint-disable no-multi-spaces */

// const TheError = () => import(/* webpackChunkName: "addonError" */ './TheError');
// const TheError404 = () => import(/* webpackChunkName: "addonError" */ './TheError404');
// const TheError408 = () => import(/* webpackChunkName: "addonError" */ './TheError408');
import TheError from './TheError';
import TheError404 from './TheError404';
import TheError408 from './TheError408';

export default [
  {
    path: '/error',
    name: 'Error',
    component: TheError,
    // meta: {
    //   auth: true,
    // },
    children: [
      {
        path: '/error/404',
        name: 'Error404',
        component: TheError404,
      },
      {
        path: '/error/408',
        name: 'Error408',
        component: TheError408,
      },
    ],
  },
];
