var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UbuCustomChip',{attrs:{"model":{
    title: 'Active campaign',
    defaultIcon: 'ubu-custom-influence',
    count: 1,
    listItem: [{
      icon: 'ubu-custom-influence',
      color: _vm._campaignList[_vm.cpo.lastActivity.campaignCid].color,
      label: _vm._campaignList[_vm.cpo.lastActivity.campaignCid].name,
      status: _vm.cpo.lastActivity.status,
    }]
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }