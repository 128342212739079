<template>
  <b-input
    v-model="localText"
    class="ubuInput"
    :placeholder="placeholder"
    icon-pack="ubu"
    :icon-right="hasChanges ? iconRight : ''"
    :icon-right-clickable="hasChanges"
    :custom-class="customClass"
    :readonly="readonly"
    :maxlength="maxlength"
    :has-counter="!!maxlength"
    @icon-right-click="$emit('send', myText)"
    @keydown.native="keydownTextAreaHandler($event)"
  />
</template>

<script>
export default {
  name: 'UbuSingleInput',
  model: {
    prop: 'text',
  },
  props: {
    text: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: 'ubu-send',
    },
    maxlength: {
      type: [Number, String],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myText: '',
    };
  },
  computed: {
    hasChanges() {
      if (!this.text && (!this.myText || !this.myText.length)) return false;
      return this.myText !== this.text;
    },
    localText: {
      get() {
        return this.text;
      },
      set(newValue) { this.myText = newValue; },
    },
    isMac() {
      return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    },
  },
  mounted() {
    this.myText = this.text;
  },
  methods: {
    keydownTextAreaHandler(event) {
      const ctrlPressed = this.isMac ? event.metaKey : event.ctrlKey;
      const ctrlEnterPressed = ctrlPressed && event.keyCode === 13;
      const ctrlShiftEnterPressed = ctrlEnterPressed && event.shiftKey;
      // const escPressed = event.keyCode === 27;

      if (ctrlEnterPressed && !event.shiftKey && this.hasChanges) return this.$emit('send', this.myText);
      if (!ctrlShiftEnterPressed) return undefined;

      return undefined;
    },
  },
};
</script>
