import { render, staticRenderFns } from "./TheCustomisableTextarea.vue?vue&type=template&id=5082612c&"
import script from "./TheCustomisableTextarea.vue?vue&type=script&lang=js&"
export * from "./TheCustomisableTextarea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports