<template>
  <div>
    <!-- eslint-disable max-len -->
    <div class="mt-5">
      <p class="text start has-text-secondary">
        Get started
      </p>
      <h1 class="title is-1">
        Set your new password
      </h1>

      <br>

      <form @submit.prevent="editPassword()">
        <fieldset>
          <b-field label="New password">
            <b-input
              v-model="newPasswordEdit.newPassword"
              type="password"
              password-reveal
              required
              placeholder="New password ..."
            />
          </b-field>

          <br>

          <b-field
            label="Confirm new password"
            type="is-danger"
            :message="passwordVerification
              ? 'Passwords don\'t match.'
              : ''"
          >
            <b-input
              v-model="newPasswordEdit.confirmPassword"
              type="password"
              password-reveal
              required
              placeholder="Confirm new password ..."
            />
          </b-field>
        </fieldset>

        <br>

        <p class="has-text-secondary">
          By clicking "Save" below you agree to <b>Ubu</b>'s
          <a
            target="_blank"
            href="https://ubu-design.notion.site/Ubu-General-Terms-and-Conditions-of-Use-c2b0e0e59b7544ab84e654b73d35523a"
            class="is-primary"
          >
            General Terms and Conditions of User
          </a>.
        </p>

        <div class="buttons is-right">
          <b-button
            type="is-primary"
            label="Save"
            native-type="submit"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheAuthenticateSetNewPassword',
  data() {
    return {
      newPasswordEdit: {
        newPassword: null,
        confirmPassword: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheAuthenticateFirstConnexion/myAuthenticate',
    }),
    passwordVerification() {
      const { confirmPassword, newPassword } = this.newPasswordEdit;
      if (confirmPassword && newPassword && confirmPassword !== newPassword) return true;
      return false;
    },
  },
  methods: {
    ...mapActions({
      updateChangePasswordFromSelina: 'TheAuthenticateFirstConnexion/updateChangePasswordFromSelina',
    }),
    editPassword() {
      const { newPassword, confirmPassword } = this.newPasswordEdit;

      if (newPassword !== confirmPassword) return;

      const payload = {
        aid: this.myAuthenticate.aid,
        userUid: this.myAuthenticate.uid,
        passName: this.myAuthenticate.passName,
        passPhrase: newPassword,
        oneTime: false,
      };

      this.updateChangePasswordFromSelina({ payload })
        .then(() => { this.$router.push('/auth/first-connexion/get-started'); });
    },
  },
};
</script>
