<template>
  <section
    id="CrewAdmin"
    class="ubuSettingView start"
  >
    <div class="multibar">
      <h1 class="start title is-1">Crew Admin</h1>
    </div>

    <br>

    <aside class="mainPanelFilters">
      <div class="multibar row-vcentered">
        <UbuSearchbar
          v-model="inputSearch"
          class="start mr-2"
          placeholder="Search a crew user..."
          @clear="clearSearchbar()"
        />

        <TheFilterDropdown
          v-model="filters"
          :filter-list="filtersCatalog"
          :non-displayable-filters="nonDisplayableFilters"
          :selected-segment-filter.sync="selectedSegmentFilter"
          class="start mr-2"
        />

        <template v-if="filters.length">
          <b-button
            class="start"
            label="Clear filters"
            type="is-ghost is-borderless"
            @click="deleteAllFilters()"
          />
        </template>
      </div>

      <template v-if="filters.length">
        <div class="activeFilterList">
          <UbuDropdownFilterForm
            v-for="(filter) in filters.filter(({ key }) => !nonDisplayableFilters.includes(key)) "
            :key="filter.id"
            v-model="filtersMapped[filter.id]"
            class="mb-1"
            :is-not-allowed="!allowedFilters.includes(filter.key)"
            :catalog-filter="genericCatalog"
            @handle="handleFilterUpdate($event)"
            @deleteFilter="deleteFilter(filter.id)"
          />
        </div>
      </template>
    </aside>

    <br>

    <div class="mainPanelTable">
      <b-table
        ref="table"
        :data="crewAdminList"
        :detail-key="detailKey"
        detailed
        :opened-details="openedDetails"
        class="table-row-clickable table-full-height"
        hoverable
        sticky-header
        focusable
        paginated
        backend-pagination
        :total="crewAdminTotal"
        :per-page="perPage"
        pagination-size="is-small"
        sticky-checkbox
        checkable
        :checked-rows.sync="checkedRows"
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @page-change="pageChange"
        @sort="onSort"
      >
        <b-table-column
          v-for="column in columns"
          :key="column.field"
          :field="column.field"
          :label="column.label"
          :sortable="column.isSortable"
          :visible="column.visible"
          :custom-sort="(a, b, isAsc) => customSort(a, b, isAsc, column)"
        >
          <template #default="props">
            <component
              :is="column.cmp"
              :cpo="props.row"
              :field="column.field"
              :column="column"
            />
          </template>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Actions"
          :visible="columns.actions.visible"
          numeric
        >
          <b-button
            v-if="props.row.crewUser.state === 'BLOCKED'"
            type="is-success"
            @click="confirmCrewUserState(props.row.crewUser, 'VERIFIED')"
          >
            <span>UnBlock</span>
          </b-button>
          <b-button
            v-else
            type="is-danger"
            @click="confirmCrewUserState(props.row.crewUser, 'BLOCKED')"
          >
            <span>Block</span>
          </b-button>
        </b-table-column>

        <template #empty>
          <p class="has-text-secondary">No crew admin user</p>
        </template>
        <template #detail="props">
          <div
            open
            class="JsonReader"
          >
            <JsonReader :obj="props.row || {}" />
          </div>
        </template>
      </b-table>

      <UbuBulkActionPanelTrigger
        :bulk-is-open="bulkIsOpen"
        :checked-rows-length="checkedRows.length"
        @cancel="bulkIsOpen = false; checkedRows = [];"
        @open="bulkIsOpen = true;"
      />
      <TheCrewAdminBulk
        v-if="bulkIsOpen && checkedRows.length"
        @confirmCrewUserState="confirmCrewUserState(checkedRows, $event)"
        @close="closeBulkAndRefresh()"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import templatizeMixin from '@dailyplanet/cross-addons/filters/_mixins/templatize.mixin';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';
import { debounce } from '@dailyplanet/utils/helpers';
import pChain from '@dailyplanet/utils/PromiseChain';

export default {
  name: 'TheCrewAdmin',
  mixins: [TableUtilsMixin, templatizeMixin],
  props: {},
  data() {
    return {
      filters: [],
      allowedFilters: [
        'contactPlatform.username',
        'channelGroup.name',
        'crewUser.state',
        'crewUser.createdOn',
      ],
      openedDetails: [],
      nonDisplayableFilters: [],
      selectedSegmentFilter: null,
      checkedRows: [],
      bulkIsOpen: false,
      inputSearch: '',
      page: 1,
      perPage: 50,
      detailKey: 'crewUserCuid',
      sortField: 'crewUser.createdOn',
      sortOrder: 'asc',
      /* eslint-disable object-curly-newline */
      columns: {
        channelGroupName: { cmp: 'UbuTableSimple', field: 'channelGroup.name', label: 'Workspace', isSortable: true, visible: true },
        // channelGroupCgid: { cmp: 'UbuTableNumber', field: 'channelGroup.cgid', label: 'Cgid', isSortable: true, visible: true },
        crewUserEmail: { cmp: 'UbuTableSimple', field: 'crewUser.email', label: 'Email', isSortable: true, visible: true },
        // crewUserCuid: { cmp: 'UbuTableSimple', field: 'crewUserCuid', label: 'Cuid', isSortable: true, visible: true },
        crewUserLastName: { cmp: 'UbuTableSimple', field: 'crewUser.lastName', label: 'Lastname', isSortable: true, visible: true },
        crewUserFirstName: { cmp: 'UbuTableSimple', field: 'crewUser.firstName', label: 'Firstname', isSortable: true, visible: true },
        // contactPlatformCpid: { cmp: 'UbuTableSimple', field: 'contactPlatformCpid', label: 'Cpid', isSortable: true, visible: true },
        contactPlatformUserName: { cmp: 'UbuTableSimple', field: 'contactPlatform.username', label: 'Username', isSortable: true, visible: true },
        contactPlatformPlatform: { cmp: 'UbuTablePlatform', field: 'contactPlatform.platformName', label: 'Platform', isSortable: true, visible: true },
        crewUserCreatedOn: { cmp: 'UbuTableDate', field: 'crewUser.createdOn', label: 'Created on', isSortable: true, visible: true },
        crewUserState: { cmp: 'UbuTableSimple', field: 'crewUser.state', label: 'State', isSortable: true, visible: true },
        actions: { visible: true },
      },
    };
  },

  computed: {
    ...mapGetters({
      crewAdminList: 'TheCrews/crewAdminList',
      crewAdminTotal: 'TheCrews/crewAdminTotal',
    }),

    sorting() {
      return `${this.sortField},${this.sortOrder}`;
    },
    filtersCatalog() {
      return Object.values(this.genericCatalog)
        .filter(({ key }) => this.allowedFilters.includes(key));
    },

    filtersMapped() {
      return this.filters.arrayMapper('id');
    },
  },
  watch: {
    filters() { return this.reportDatas(); },
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (searchTxt) {
      if (searchTxt.length > 3) {
        this.reportDatas(searchTxt);
      }
      if (searchTxt.length === 0) {
        this.reportDatas('');
      }
    }, 500),
  },
  mounted() {
    return this.reportDatas();
  },
  methods: {
    ...mapActions({
      _reportCrewAdmin: 'TheCrews/reportCrewAdmin',
      _updateStateCrewUser: 'TheCrews/updateStateCrewUser',
    }),

    clearSearchbar() {
      this.inputSearch = '';
      // this.filters = [];
    },
    deleteFilter(event) {
      const filters = this.filters.filter(({ id }) => id !== event);
      this.filters = filters;
    },
    deleteAllFilters() {
      this.filters = [];
      this.selectedSegmentFilter = null;
    },
    handleFilterUpdate(filter) {
      // replace old filter by new & redefine filters array
      const newFilters = {
        ...this.filters.arrayMapper('id'),
        [filter.id]: filter,
      };

      this.filters = Object.values(newFilters);
    },
    closeBulkAndRefresh() {
      this.bulkIsOpen = false;
      this.checkedRows = [];

      this.reportDatas();
    },
    reportDatas(searchTxt) {
      const filtersToUse = [...this.filters];

      return this._reportCrewAdmin({
        payload: { tribeTid: this.$route.params.tribeTid },
        httpQuery: {
          userSearch: searchTxt || '',
          pagin: `${this.page - 1},${this.perPage}`,
          sorting: this.sorting,
          filters: this.prepareFiltersToRequest(this.allowedFilters, filtersToUse),
        },
      });
    },
    confirmCrewUserState(crewUser, state) {
      const stateConfirm = state === 'BLOCKED' ? 'Block' : 'Unblock';

      this.$buefy.dialog.confirm({
        title: `crew user ${stateConfirm}`,
        message: `Are you sure you want to <b>${stateConfirm}</b> this crew user?`,
        confirmText: `${stateConfirm}`,
        type: state === 'BLOCKED' ? 'is-danger' : 'is-success',
        hasIcon: true,
        onConfirm: () => {
          if (crewUser.length) {
            pChain(crewUser.map((cU) => this.updateCrewUser(cU.crewUser, state)))
              .then(() => {
                const message = stateConfirm === 'BLOCKED'
                  ? 'All checked Crew Users UNBLOCKED state for All channel!'
                  : 'All checked Crew Users BLOCKED state for all channel!';

                this.$buefy.snackbar.open({
                  type: 'is-success',
                  position: 'is-bottom-right',
                  message,
                });
              });
          } else {
            this.updateCrewUser(crewUser, state)
              .then(() => {
                const message = stateConfirm === 'BLOCKED'
                  ? `Crew User ${crewUser.email} UNBLOCKED state for All channel!`
                  : `Crew User ${crewUser.email} BLOCKED state for all channel!`;

                this.$buefy.snackbar.open({
                  type: 'is-success',
                  position: 'is-bottom-right',
                  message,
                });
              });
          }
        },
      });
    },

    updateCrewUser(crewUser, state) {
      return this._updateStateCrewUser({
        payload: {
          ...crewUser,
          updatedOn: this.$moment().format(),
          state,
        },
      });
    },
  },
};
</script>
<style>
  .crewAdmin.mainPanelTable.tabler-wrapper{
height: 100%;
  }
</style>
