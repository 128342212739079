/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    noteList: {},
    total: null,
  },
  mutations: {
    SET_NOTELIST(state, newData) { state.noteList = { ...state.noteList, ...newData }; },
    UNSET_NOTE_LIST(state, newData) { state.noteList = Object.values(state.noteList).filter(({ customId }) => customId !== newData).arrayMapper('customId'); },
    RESET_NOTELIST(state) { state.noteList = {}; },
    SET_NOTELISTTOTAL(state, newData) { state.total = newData; },
    RESET_STORE(state) {
      state.noteList = {};
      state.total = null;
    },
  },
  getters: {
    getterNoteList: (state) => state.noteList,
    getterNoteListTotal: (state) => state.total,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CHANNEL_GROUP_CONTACT_NOTE.REPORT')) return undefined;

      return clark.api.channelGroup.contact.note.report({ payload, httpQuery })
        .then(({ results, total }) => {
          commit('SET_NOTELIST', results.arrayMapper('customId'));
          commit('SET_NOTELISTTOTAL', total);
        });
    },
    reset({ commit }) {
      commit('RESET_NOTELIST');
    },
  },
};
