import TheCrew from './TheCrew';
import TheCrewReward from './views/TheCrewReward';
import TheCrewSetting from './views/TheCrewSetting';
import TheCrewTremendousSetting from './views/TheCrewTremendousSetting';

export default [
  {
    path: '/crew',
    redirect: '/crew/reward',
  },
  {
    path: '/crew',
    name: 'Rewards',
    component: TheCrew,
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheInfluence',
    },
    children: [
      {
        path: '/crew/reward',
        name: 'Crew - Rewards',
        component: TheCrewReward,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheInfluence',
        },
      },
      {
        path: '/crew/crewSetting',
        name: 'Crew - CrewSetting',
        component: TheCrewSetting,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheInfluence',
        },
      },
      {
        path: '/crew/tremendousSetting',
        name: 'Crew - TremendousSetting',
        component: TheCrewTremendousSetting,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheInfluence',
        },
      },
    ],
  },
];
