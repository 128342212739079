<template>
  <section class="mainPanel">
    <header class="mainPanelHeader multibar row-vcentered">
      <h1 class="title is-2 start">Affiliation</h1>

      <nav class="end buttons expandable">
        <b-button
          v-for="item in navigation"
          :key="item.path"
          tag="router-link"
          type="is-simple"
          :to="item.path"
          :active="$route.fullPath.includes(item.path)"
        >
          {{ item.label }}
        </b-button>
      </nav>
    </header>

    <router-view />
  </section>
</template>

<script>
export default {
  name: 'TheSettingShopAndCoupon',
  computed: {
    navigation() {
      return [
        {
          label: 'Shop',
          path: '/setting/affiliation/shop',
        },
        {
          label: 'Coupon',
          path: '/setting/affiliation/coupon',
        },
        {
          label: 'Folder',
          path: '/setting/affiliation/folder',
        },
      ];
    },
  },
};
</script>
