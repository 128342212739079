<template>
  <b-tab-item value="root.assignLabel.done">
    <header>
      <UbuSearchbar
        v-model="search"
        class="start mb-4"
        placeholder="Search..."
        @clear="search = ''"
      />
    </header>
    <article>
      <UbuActionCard
        v-for="label in filteredList"
        :key="label.cgid"
        :class="`mb-4 has-text-color-${label.color}`"
        :label="label.label"
        icon-pack="ubu"
        icon-left="ubu-custom-contact"
        :icon-right="!label.threadWithoutLabel.length ? 'ubu-close' : ''"
        @click="selectLabel(label)"
      />
    </article>
  </b-tab-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheThreadBulkLabel',
  mixins: [FilterEngineMixin],
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      contactGroupList: 'TheInbox/contactGroupList',
    }),

    unassignedByLabel() {
      const labelList = Object.values(this.contactGroupList);

      return labelList.map((label) => ({
        ...label,
        threadWithoutLabel: this.checkedRows.reduce((acc, { tid, labels }) => {
          if (!labels.includes(label.cgid)) acc.push(tid);
          return acc;
        }, []),
      }));
    },

    filteredList() {
      const labelList = this.unassignedByLabel;

      if (!labelList.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByLabelName = this.search;

      return this.filterEngine(labelList, filtersToApply);
    },
  },
  methods: {
    ...mapActions({
      _assignContactGroup: 'TheLabelPopover/_assignContactGroup',
      _unassignContactGroup: 'TheLabelPopover/_unassignContactGroup',
    }),

    selectLabel(label) {
      if (!label.threadWithoutLabel.length) {
        return this._unassignContactGroup({
          payload: { cgid: label.cgid, rows: this.checkedRows },
          mutations: [
            {
              target: 'ApiContact/contactPlatformOverview/UP_removeCustom',
              payload: {
                key: 'labels',
                itemId: label.cgid,
                cpids: this.checkedRows.map(({ contactPlatform }) => contactPlatform.cpid),
              },
            },
          ],
        });
      }

      return this._assignContactGroup({
        payload: { cgid: label.cgid, rows: this.checkedRows },
        mutations: [
          {
            target: 'ApiContact/contactPlatformOverview/UP_addCustom',
            payload: {
              key: 'labels',
              itemId: label.cgid,
              cpids: this.checkedRows.map(({ contactPlatform }) => contactPlatform.cpid),
            },
          },
        ],
      });
    },
  },
};
</script>
