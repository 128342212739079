/* eslint-disable no-multi-spaces */
import TheAuthenticate from '@dailyplanet/cross-addons/authentification/TheAuthenticate';
import TheAuthenticateLogin from '@dailyplanet/cross-addons/authentification/TheAuthenticateLogin';
import TheAuthenticateSendToken from '@dailyplanet/cross-addons/authentification/TheAuthenticateSendToken';
import TheAuthenticateSendNewPassword from '@dailyplanet/cross-addons/authentification/TheAuthenticateSendNewPassword';

import TheAuthenticateFirstConnexion from '@dailyplanet/cross-addons/authentification/TheAuthenticateFirstConnexion';
import TheAuthenticateSetNewPassword from '@dailyplanet/cross-addons/authentification/TheAuthenticateSetNewPassword';
import TheAuthenticateGetStarted from '@dailyplanet/cross-addons/authentification/TheAuthenticateGetStarted';

export default [
  {
    path: '/auth',
    redirect: 'Login',
  },
  {
    path: '/auth/first-connexion',
    redirect: '/auth/first-connexion/new-password',
  },
  {
    path: '/auth',
    component: TheAuthenticate,
    children: [
      {
        path: '/auth/login',
        name: 'Authenticate - Login',
        component: TheAuthenticateLogin,
      },
      {
        path: '/auth/forgotPassword',
        name: 'Send token',
        component: TheAuthenticateSendToken,
      },
      {
        path: '/auth/forgotPassword/:link',
        name: 'Reset password',
        component: TheAuthenticateSendNewPassword,
      },
      {
        path: '/auth/first-connexion',
        name: 'Authenticate - First connexion',
        component: TheAuthenticateFirstConnexion,
        meta: {
          requireAuth: true,
        },
        children: [
          {
            path: '/auth/first-connexion/new-password',
            name: 'Authenticate - First connexion - New password',
            meta: {
              requireAuth: true,
            },
            component: TheAuthenticateSetNewPassword,
          },
          {
            path: '/auth/first-connexion/get-started',
            name: 'Authenticate - First connexion - Get started',
            meta: {
              requireAuth: true,
            },
            component: TheAuthenticateGetStarted,
          },
        ],
      },
    ],
  },
];
