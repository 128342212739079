<template>
  <b-field>
    <b-datepicker
      v-model="localValues"
      class="has-options paddingless"
      range
      :close-on-click="false"
      inline
    >
      <div class="option-list">
        <p
          v-for="item in optionDateList"
          :key="item.subtype"
          :class="['option', { 'is-active': selected && selected.subtype === item.subtype }]"
          @click="selectOption(item)"
        >
          {{ item.label }}
        </p>
      </div>
    </b-datepicker>
  </b-field>
</template>

<script>
export default {
  name: 'UbuFilterDateRange',
  model: {
    prop: 'values',
  },
  props: {
    values: {
      type: Array,
      required: true,
    },
    optionDateToUse: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      customDate: {
        subtype: 'custom',
        label: 'Custom range',
        rangeDate: [],
      },

      selected: null,
    };
  },
  computed: {
    localValues: {
      get() {
        return this.values.length > 0 ? [
          this.$moment(this.values[0]).toDate(), this.$moment(this.values[1]).toDate()]
          : [];
      },
      set(val) {
        this.optionDateList.custom.rangeDate = val;
        this.selected = this.customDate;

        this.$emit('input', val.length > 0
          ? [this.$moment(val[0]).startOf('day'), this.$moment(val[1]).endOf('day')] : val);
      },
    },

    optionDateList() {
      return { ...this.optionDateToUse, custom: this.customDate };
    },
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.$emit('input', option.rangeDate);
    },
  },
};
</script>
