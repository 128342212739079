/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';
import contactNote from './contactNote.store';

export default {
  namespaced: true,
  state: {
    contact: null,
  },
  mutations: {
    SET_CONTACT(state, newData) { state.contact = newData; },
    RESET_STORE(state) {
      state.contact = null;
    },
  },
  getters: {
    getterContact: (state) => state.contact,
  },
  actions: {
    get({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CONTACT.GET')) return undefined;

      return clark.api.channelGroup.contact.get({ payload, httpQuery })
        .then((contact) => {
          const { logBookList = [], privateNoteList = [] } = contact;

          commit('SET_CONTACT', contact);
          commit('ChannelGroup/logbook/SET_LOGBOOK_LIST', logBookList.arrayMapper('lid'), { root: true });
          commit('ChannelGroup/privateNote/SET_PRIVATE_NOTE_LIST', privateNoteList.arrayMapper('pnid'), { root: true });
        });
    },
  },
  modules: {
    contactNote,
  },
};
