<template>
  <aside class="ubuSidePanel ubuPanel-xl">
    <div
      v-if="isWizardApplied"
      class="multibar row-vcentered"
    >
      <b-button
        v-if="routeForPreviousButton.path && activeTab === 0"
        class="start is-ghost is-borderless"
        icon-pack="ubu"
        icon-left="ubu-arrow-left"
        label="Previous step"
        tag="router-link"
        :to="routeForPreviousButton"
      />

      <b-button
        v-else-if="routeForPreviousButton.path && activeTab >= 1"
        class="start is-ghost is-borderless"
        icon-pack="ubu"
        icon-left="ubu-arrow-left"
        label="Previous step"
        @click="activeTab -= 1"
      />

      <b-button
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
        @click="handleClose()"
      />
      <!-- :to="options.closeBulk" -->
      <!-- tag="router-link" -->
    </div>
    <div class="ubuSidePanel-content">
      <DevDetails :cnt="{name: `'checkedRows' ${checkedRows.length}`, obj: checkedRows}" />

      <router-view
        v-for="router in options.routerViews"
        :key="router.name"
        v-model="localChecked"
        :name="router.name"
        :options="router.options"
        :active-tab.sync="activeTab"
        :current-campaign-cid="options.currentCampaignCid"
        :close-bulk="options.closeBulk"
        @isWizardApplied="_isWizardApplied($event)"
        @refresh="$emit('refresh')"
        @closePanel="closeBulkPanel()"
      />
    </div>
  </aside>
</template>

<script>
export default {
  name: 'TheBulkActionsPanel',
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      isWizardApplied: true,
    };
  },
  computed: {
    routeForPreviousButton() {
      const { path } = this.$route;
      const pathList = path
        // transform string to array
        .split('/')
        // remove empty [0] and last element [-1]
        .slice(1, -1);

      return { path: `/${pathList.join('/')}` };
    },
    localChecked: {
      get() { return this.checkedRows; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
  mounted() {
    if (!this.checkedRows.length) {
      this.$router.push({
        path: this.options.closeBulk,
      });
    }
  },
  methods: {
    closeBulkPanel() {
      this.$router.push({
        path: this.options.closeBulk,
      });
    },
    _isWizardApplied(e) {
      this.isWizardApplied = e;
    },
    handleClose() {
      this.$router.push(this.options.closeBulk);
      this.$emit('close');
    },
  },
};
</script>
