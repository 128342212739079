<template>
  <div>
    <DevDetails :cnt="{name: 'itemList', obj: itemList}" />
    <div
      ref="scroller"
      class="scroller"
      @wheel="onScroll()"
    >
      <slot
        v-if="loading"
        name="loading"
      />
      <template
        v-for="(itemPage, indexPage) in pagesArray"
        v-else
        :id="indexPage"
      >
        <slot
          v-if="lastPageIndex === itemPage.page"
          name="end"
        />
        <div
          :id="reversed ? 'endPage-'+itemPage.page : 'startPage-'+itemPage.page"
          :key="reversed ? 'endPage-'+itemPage.page : 'startPage-'+itemPage.page"
          :ref="reversed ? 'endPage-'+itemPage.page : 'startPage-'+itemPage.page"
          class="observable"
          style="height: 1px;"
        />
        <div
          v-for="{item, indexItem} in itemPage.chunk"
          :id="item.customId || null"
          :key="indexPage+'-'+indexItem"
        >
          <slot
            :item="item"
            :index="indexItem"
            name="item"
          />
        </div>
        <div
          :id="reversed ? 'startPage-'+itemPage.page : 'endPage-'+itemPage.page"
          :key="reversed ? 'startPage-'+itemPage.page : 'endPage-'+itemPage.page"
          :ref="reversed ? 'startPage-'+itemPage.page : 'endPage-'+itemPage.page"
          class="observable"
          style="height: 1px;"
        />
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */

export default {
  name: 'UbuScrollerV2',
  props: {
    itemList: {
      type: Array,
      required: true,
    },
    itemCountTotal: {
      type: Number,
      default: 0,
    },
    reversed: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 20,
    },
    pageRange: {
      type: Number,
      default: 3,
    },
    scrollRange: {
      type: Number,
      default: 200,
    },
    lastPageFetched: {
      type: Number,
      required: true,
    },
    previousLastItemId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      previousScrollDirection: this.reversed ? 'up' : 'down',
      observer: null,
      mutationObserver: null,
      sizeObserver: null,
      pagesArray: [],
      isLoadingPage: false,
      scrollInitiated: false,
    };
  },
  computed: {
    regularScrollDirection() {
      return (!this.reversed && this.previousScrollDirection === 'down') || (this.reversed && this.previousScrollDirection === 'up');
    },
    lastPageIndex() {
      return Math.ceil(this.itemCountTotal / this.perPage) - 1;
    },
  },
  watch: {
    page(val) {
      this.updatePagesArray();
      this.$nextTick(() => {
        /*
          Si on est en mode reversed et qu'on scroll vers le haut, il faut que le endPage precédant se retrouve en haut du scroller
         */
        let block;
        if (this.reversed) block = this.regularScrollDirection ? 'start' : 'end';
        else block = this.regularScrollDirection ? 'end' : 'start';

        let previousLastObsRef = null;
        if (this.previousLastItemId !== null) {
          previousLastObsRef = document.getElementById(this.previousLastItemId);
        } else {
          let obsPrefixToScrollTo = this.regularScrollDirection ? 'end' : 'start'; // Car on ne peut plus utiliser block qui indique seulement si on veut etre en start ou end du scroller en fonction de this.reversed       let pageToScrollTo;
          let pageToScrollTo;
          if (val === 0 && this.pagesArray.length === 1) {
            pageToScrollTo = 0;
            block = this.reversed ? 'end' : 'start';
            obsPrefixToScrollTo = this.reversed ? 'start' : 'end';
            this.scrollInitiated = false;
          } else pageToScrollTo = this.regularScrollDirection ? val - 1 : val + 1;
          // eslint-disable-next-line prefer-destructuring
          previousLastObsRef = this.$refs[`${obsPrefixToScrollTo}Page-${pageToScrollTo}`][0];
        }

        const { offsetTop, offsetHeight } = this.$refs.scroller;
        if (block === 'start') this.$refs.scroller.scrollTop = previousLastObsRef.offsetTop - offsetTop;
        else this.$refs.scroller.scrollTop = previousLastObsRef.offsetTop - offsetHeight - offsetTop;

        this.isLoadingPage = false;
      });
    },
    itemList() {
      if (!this.isLoadingPage) {
        this.updatePagesArray();
      }
    },
  },
  beforeDestroy() {
    if (this.observer !== null) this.observer.disconnect();
    if (this.mutationObserver !== null) this.mutationObserver.disconnect();
    if (this.sizeObserver !== null) this.sizeObserver.disconnect();
  },
  updated() {
    if (this.itemList.length > 0) {
      if (this.reversed && !this.scrollInitiated) {
        this.scrollToBottom();
      }

      const observables = document.getElementsByClassName('observable');
      this.observer = new IntersectionObserver(
        this.onElementObserved,
        {
          root: this.$refs.scroller,
          threshold: 0,
        },
      );
      observables.forEach((observable) => {
        this.observer.observe(observable);
      });
    }
  },
  mounted() {
    const config = {
      attributes: true,
      childList: true,
      subtree: true,
    };
    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation && this.$refs.scroller) {
          const amIAtEndOfList = this.$refs.scroller.scrollTop + this.$refs.scroller.clientHeight >= this.$refs.scroller.scrollHeight;
          if (this.reversed && !amIAtEndOfList && !this.scrollInitiated) {
            this.scrollToBottom();
          }
        }
      });
    });
    this.sizeObserver = new ResizeObserver((() => {
      if (this.$refs.scroller) {
        const wasAtEndOfList = this.$refs.scroller.scrollTop + this.$refs.scroller.clientHeight + this.scrollRange >= this.$refs.scroller.scrollHeight;
        if (this.reversed && wasAtEndOfList) {
          this.scrollToBottom();
        }
      }
    }));
    this.sizeObserver.observe(this.$refs.scroller);
    this.mutationObserver.observe(this.$refs.scroller, config);
  },
  methods: {
    getPageToDisplay(observableId) {
      const observableLocation = observableId.split('-')[0];
      const observablePageRangeIndex = Number(observableId.split('-')[1]);

      const pageQuantity = Math.ceil(this.itemCountTotal / this.perPage);
      const pagesArrayPreviousIndex = this.reversed ? this.pagesArray.length - 1 : 0;
      const pagesArrayNextIndex = this.reversed ? 0 : this.pagesArray.length - 1;
      if (observableLocation === 'startPage' && observablePageRangeIndex - 1 >= 0 && observablePageRangeIndex === this.pagesArray[pagesArrayPreviousIndex].page) return 'previous';
      if (observableLocation === 'endPage' && observablePageRangeIndex + 1 < pageQuantity && observablePageRangeIndex === this.pagesArray[pagesArrayNextIndex].page) return 'next';
      return '';
    },
    updatePagesArray() {
      const newPageArray = [];
      const nbPages = Math.ceil(this.itemList.length / this.perPage);
      let x = nbPages;

      for (let i = 0; i < nbPages; i += 1) {
        const start = i * this.perPage;
        const end = this.perPage + (i * this.perPage);
        const chunk = this.itemList.slice(start, end).map((item, index) => ({
          item,
          indexItem: i === 0 ? index : i * this.perPage + index,
        }));

        let pageToPush = null;

        if (this.regularScrollDirection) {
          pageToPush = this.page + 1 - x;
          x -= 1;
        } else pageToPush = this.page + i;

        newPageArray.push({ page: pageToPush, chunk: this.reversed ? chunk.reverse() : chunk });
      }

      this.pagesArray = this.reversed ? newPageArray.reverse() : newPageArray;
    },
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) return;
        const observableId = target.getAttribute('id');
        const pageToDisplay = this.getPageToDisplay(observableId);

        if (pageToDisplay === 'previous' && !this.isLoadingPage) {
          this.isLoadingPage = true;
          // get previous page (if exists, recover if not in pageBuffer)
          this.observer.unobserve(target);
          this.$emit('changePageToPrevious');
          this.previousScrollDirection = this.reversed ? 'down' : 'up';
        }
        if (pageToDisplay === 'next' && !this.isLoadingPage) {
          this.isLoadingPage = true;
          this.previousScrollDirection = this.reversed ? 'up' : 'down';
          if (this.lastPageFetched === this.page) {
            // get next page (if exists, fetch or recover if not in pageBuffer)
            this.observer.unobserve(target);
            this.$emit('fetchNewPage');
          } else {
            this.$emit('changePageToNext');
          }
        }
      });
    },
    onScroll() {
      if (this.scrollInitiated) return;
      this.scrollInitiated = true;
    },
    scrollToBottom() {
      this.$refs.scroller.scrollTop = this.$refs.scroller.scrollHeight;
    },
  },
};
</script>
