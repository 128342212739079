<template>
  <div class="buttons">
    <UbuActionCard
      v-for="button in buttons"
      :key="button.label"
      :label="button.label"
      icon-pack="ubu"
      :social-icon="button.icon"
      expanded
      :disabled="!button.count"
      @click="$emit('select', button.reachMethod)"
    >
      <template v-slot:left-content>
        <div class="end has-text-secondary multibar">
          <span v-if="button.count > 0">{{ button.count }} people</span>
          <span v-else>No people matching criterias</span>

          <b-tooltip
            class="ml-2"
            position="is-left"
            type="is-light"
            append-to-body
          >
            <b-icon
              pack="ubu"
              icon="ubu-information"
            />

            <template v-slot:content>
              <p
                v-for="label in button.tooltipText"
                :key="label"
              >
                {{ label }}
              </p>
            </template>
          </b-tooltip>
        </div>
      </template>
    </UbuActionCard>

    <br>

    <template v-for="group in cantBeAdded">
      <UbuMessagePeopleWarning
        v-if="group.list.length"
        :key="group.reachMethod"
        :text="group.label"
        :contact-platform-list="group.list"
        class="w-100 mb-2"
      />
    </template>
  </div>
</template>

<script>
import TheAddToCampaignMixin from '../mixins/TheAddToCampaign.mixin';

export default {
  name: 'TheAddToCampaignReachMethod',
  mixins: [TheAddToCampaignMixin],
  computed: {
    buttons() {
      return [
        {
          label: 'Cold outreach',
          icon: 'instagram',
          reachMethod: 'COLD_OUTREACH',
          count: this.peopleReachableOnThisCampaign.length,
          tooltipText: [
            'Recruit new ambassadors.',
            'Contact via Instagram.',
            'Export emails from your campaign.',
            'Links blocked by Instagram in mass messages.',
            'Limited to 70 messages per day for contacts you’ve',
            'never talked to before and 1 message per minute for',
            'contacts you’ve already talked to before.',
          ],
        },
        {
          label: 'Add without message',
          icon: '',
          reachMethod: 'WITHOUT_MESSAGE',
          count: this.peopleAddableOnThisCampaign.length,
          tooltipText: [
            'People will be added but no messages will be sent.',
            'You can export their emails from your campaign.',
          ],
        },
      ];
    },

    cantBeAdded() {
      return [
        {
          label: 'People already in campaign',
          icon: '',
          reachMethod: 'WITHOUT_MESSAGE',
          list: this.peopleNotAddableOnThisCampaign.map(({ contactPlatform }) => contactPlatform),
        },
        {
          label: 'People who can\'t be cold outreached',
          icon: 'instagram',
          reachMethod: 'COLD_OUTREACH',
          list: this.peopleNotReachableOnThisCampaign.map(({ contactPlatform }) => contactPlatform),
        },
      ];
    },
  },
};
</script>
