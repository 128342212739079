/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    searchInstagramUsername: null,
  },
  mutations: {
    SET_SEARCH_INSTAGRAM_USERNAME_LIST(state, newData) { state.searchInstagramUsername = newData; },
    RESET_STORE(state) {
      state.searchInstagramUsername = [];
    },
  },
  getters: {
    getterSearchInstagramUsername: (state) => state.searchInstagramUsername,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'SEARCH_INSTAGRAM_USERNAME.REPORT')) return undefined;

      return clark.api.channelGroup.searchInstagramUsername.report({ payload, httpQuery })
        .then((searchInstagramUsername) => {
          commit('SET_SEARCH_INSTAGRAM_USERNAME_LIST', searchInstagramUsername);
        });
    },
  },
};
