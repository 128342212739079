<template>
  <aside class="mainPanelFilters">
    <slot name="topLine" />

    <div class="multibar row-vcentered">
      <slot name="secondLineStart" />

      <UbuSearchbar
        v-model="localSearch"
        class="start"
        placeholder="Search..."
        :disabled="isDisabled"
        @clear="clearSearchbar()"
        @pressEnter="addSearchbarFilter()"
      />

      <TheFilterDropdown
        v-model="localFilters"
        :filter-list="filtersCatalog"
        :non-displayable-filters="nonDisplayableFilters"
        :selected-segment-filter.sync="selectedSegmentFilter"
        :is-disabled="isDisabled"
        class="start ml-2"
      />

      <template v-if="filters.length">
        <b-button
          class="start ml-4"
          label="Clear filters"
          type="is-ghost is-borderless"
          @click="deleteAllFilters()"
        />

        <TheSegmentFilterManager
          v-model="selectedSegmentFilter"
          class="start"
          :filters="filters"
        />
      </template>
      <div class="end">
        <b-button
          type="is-secondary"
          icon-pack="ubu"
          icon-left="ubu-filter"
          label="Show paid"
          @click="setFilters(filtersDynamic)"
        />
      </div>
      <slot name="secondLineEnd" />
    </div>
    <template v-if="localFilters.length">
      <div class="activeFilterList">
        <UbuDropdownFilterForm
          v-for="(filter) in localFilters"
          :key="filter.id"
          v-model="localFiltersMapped[filter.id]"
          class="mb-1"
          :is-not-allowed="!allowedFilters.includes(filter.key)"
          :catalog-filter="genericCatalog"
          @handle="handle($event)"
          @deleteFilter="deleteFilter(filter.id)"
        />
      </div>
    </template>
  </aside>
</template>

<script>
import { mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

import templatizeMixin from '@dailyplanet/cross-addons/filters/_mixins/templatize.mixin';

export default {
  name: 'TheCrewCartouche',
  mixins: [templatizeMixin],
  model: {
    prop: 'filters',
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    allowedFilters: {
      type: Array,
      required: true,
    },
    nonDisplayableFilters: {
      type: Array,
      default: () => ([]),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localSearch: '',
      selectedSegmentFilter: null,
      filtersDynamic: [
        {
          id: uuidv4(),
          key: 'affiliationReward.paidOn',
          type: 'rangeDate',
          operator: 'is empty',
          allowedOperators: ['is between', 'is empty'],
          values: [null],
        },
        {
          id: uuidv4(),
          key: 'affiliationReward.paidType',
          type: 'radio',
          label: undefined,
          operator: undefined,
          allowedOperators: undefined,
          values: ['MANUAL'],
        },
      ],
    };
  },
  computed: {
    // ...mapGetters({
    //   _tribeMemberList: 'TheCampaign/tribeMemberList',
    //   // tribeList: 'TheCampaign/tribeList',
    // }),

    // // tribe() {
    // //   return this.tribeList[this.$route.params.tribeTid];
    // // },

    // tribeMemberList() {
    //   return Object.values(this._tribeMemberList);
    // },

    localFilters: {
      get() {
        return this.filters
          .filter(({ key }) => !this.nonDisplayableFilters.includes(key))
          .map(({ values, list, ...rest }) => ({
            ...rest,
            values: values.map((value) => value),
            list: list ? list.map((l) => ({ ...l })) : null,
          }));
      },
      set(values) {
        this.$emit('input', values);
      },
    },

    localFiltersMapped() {
      return this.filters.arrayMapper('id');
    },

    filtersCatalog() {
      return Object.values(this.genericCatalog)
        .filter(({ key }) => this.allowedFilters.includes(key));
    },
  },
  methods: {
    ...mapActions({
      _resetTribeMemberList: 'ApiTribe/tribeMember/resetTribeMemberList',
    }),
    clearSearchbar() {
      this.localFilters = this.localFilters.filter(({ key }) => key !== 'contactPlatform.username');
      this.localSearch = '';
    },

    setFilters(filters) {
      this.localFilters = [
        ...this.localFilters.filter(({ key }) => !filters.some((filter) => filter.key === key)),
        ...filters,
      ];
    },

    addSearchbarFilter() {
      const filters = this.localFilters.arrayMapper('key');
      delete filters['contactPlatform.username'];
      this.localFilters = Object.values(filters).concat({ key: 'contactPlatform.username', values: [this.localSearch] });
    },

    deleteAllFilters() {
      this.localFilters = [];
      this.selectedSegmentFilter = null;
      this.$emit('reset');
    },

    handle(event) {
      const filters = {
        ...this.localFilters.arrayMapper('id'),
        [event.id]: event,
      };
      this.$emit('input', Object.values(filters));
    },

    deleteFilter(event) {
      const filters = this.filters.filter(({ id }) => id !== event);
      this.$emit('input', filters);
    },
  },
};
</script>
