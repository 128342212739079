<template>
  <div
    ref="top"
    class="ubuAddToCampaignMessage"
  >
    <b-message
      v-if="messageContainsUrl"
      type="is-danger"
    >
      Links are not supported by Instagram when sending messages in bulk.
      Please remove all links to send your messages.
    </b-message>

    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Message
        </p>

        <div class="buttons card-header-icon">
          <b-button
            label="Save as template"
            type="is-secondary"
            @click="saveTemplate()"
          />

          <b-button
            type="is-primary"
            label="Send"
            :disabled="isDisabled"
            @click="checkValidity()"
          />
        </div>
      </header>

      <b-field class="card-content">
        <b-input
          ref="textarea"
          v-model.trim="localMessage"
          placeholder="Type your message here..."
          type="textarea"
          maxlength="1000"
        />
      </b-field>
    </div>

    <br>

    <UbuTemplateCard
      v-for="template in templateListSorted"
      :key="template.tid"
      class="mb-4"
      :template="template"
      :clickable="true"
      @click="selectTemplate(template)"
    />
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheAddToCampaignMessage',
  data() {
    return {
      messageContainsUrl: false,
      localMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      newBulkAction: 'TheNewAddToCampaign/newBulkAction',
      templateList: 'TheTemplateManager/templateList',
    }),

    templateListSorted() {
      return Object.values(this.templateList).sort((a, b) => b.tid - a.tid);
    },

    isDisabled() {
      return !this.localMessage || !this.localMessage.length || this.messageContainsUrl;
    },

    localBulkAction: {
      get() { return this.newBulkAction; },
      set(newValue) { this.UP_newBulkAction(newValue); },
    },
  },
  mounted() {
    this.$nextTick(() => { this.$refs.textarea.focus(); });
  },
  methods: {
    ...mapActions({
      createTemplate: 'TheTemplateManager/createTemplate',

      UP_newBulkAction: 'TheNewAddToCampaign/UP_newBulkAction',
    }),

    selectTemplate({ text: message }) {
      this.localMessage = message;
      // autoscroll to top when selecting a template card
      this.$refs.top.scrollIntoView();
      this.$refs.textarea.focus();
    },

    saveTemplate() {
      this.createTemplate({
        payload: {
          text: this.localMessage,
          title: `Campaign template #${Object.values(this.templateList).length + 1}`,
        },
      })
        .then(() => {
          this.$buefy.snackbar.open({
            ...snackbarSuccess,
            message: 'Template successfully created.',
          });
        });
    },

    checkValidity() {
      const reg = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

      console.log('🚀 ~ file: TheAddToCampaignMessage.vue ~ line 137 ~ checkValidity ~ this.localMessage.match(reg)', !!this.localMessage.match(reg));
      this.messageContainsUrl = !!this.localMessage.match(reg);
      if (this.isDisabled) {
        return;
      }
      this.localBulkAction = { ...this.localBulkAction, message: this.localMessage };
      this.$emit('submit');
    },
  },

};
</script>
