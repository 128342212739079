<template>
  <TheTabsPanel
    v-model="activeTab"
    @goToPreviousTab="goToPreviousTab()"
    @goToStart="closePanel()"
    @close="closePanel()"
  >
    <template #content>
      <b-tab-item value="root">
        <div class="buttons">
          <UbuActionCard
            v-for="(item, index) in navigableItems"
            :key="index"
            class="mb-4"
            :label="item.label"
            icon-pack="ubu"
            :icon-left="item.icon"
            expanded
            @click="goToTabByTabPath(item.to);"
          />
        </div>
      </b-tab-item>

      <b-tab-item value="root.contactPlatformCpid">
        <template v-if="activeTab.tab === 'root.contactPlatformCpid'">
          <UbuContactPlatformCard
            v-if="affiliationCode.contactPlatformCpid"
            :contact-platform="affiliationCode.contactPlatform"
          >
            <template #actions>
              <b-button
                class="end"
                label="Remove"
                type="is-primary"
                @click="editContactPlatformRelation(null)"
              />
            </template>
          </UbuContactPlatformCard>

          <div v-else>
            <b-input
              v-model="username"
              :loading="isFetching"
              class="mb-2"
              type="search"
              pattern="^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$"
              validation-message="Only valid username are allowed"
              placeholder="Search any influencer from your CRM..."
              icon="ubu-search"
              icon-pack="ubu"
              icon-right="ubu-cross"
              icon-right-clickable
              @icon-right-click="username = ''"
              @input="isFetching = true"
            />

            <UbuContactPlatformCard
              v-for="cp in usernameSearchResult"
              :key="cp.cpid"
              class="mb-2"
              :contact-platform="cp"
            >
              <template #actions>
                <b-button
                  class="end"
                  label="Link"
                  type="is-secondary"
                  @click="editContactPlatformRelation(cp.cpid)"
                />
              </template>
            </UbuContactPlatformCard>
          </div>
        </template>
      </b-tab-item>

      <b-tab-item value="root.campaignCid">
        <template v-if="activeTab.tab === 'root.campaignCid'">
          <UbuCampaignCard
            v-if="affiliationCode.campaignCid"
            :campaign="_campaignList[affiliationCode.campaignCid]"
          >
            <template #actions>
              <b-button
                class="end"
                label="Remove"
                type="is-primary"
                @click="editCampaignRelation(null)"
              />
            </template>
          </UbuCampaignCard>

          <div v-else>
            <UbuSearchbar
              v-model="search"
              class="mb-2"
              @clear="search = ''"
            />

            <UbuCampaignCard
              v-for="camp in filteredActiveCampaignList"
              :key="camp.cid"
              class="mb-2"
              :campaign="camp"
            >
              <template #actions>
                <b-button
                  class="end"
                  label="Link"
                  type="is-secondary"
                  @click="editCampaignRelation(camp.cid)"
                />
              </template>
            </UbuCampaignCard>
          </div>
        </template>
      </b-tab-item>

      <b-tab-item value="root.commission">
        <form v-if="activeTab.tab === 'root.commission'" @submit.prevent="editCommissionRate()">
          <b-field label="Commission rate in %" horizontal>
            <b-numberinput
              v-model="localCommissionRate"
              step="1"
              min="0"
              max="100"
              :controls="false"
            />
          </b-field>

          <br>

          <div class="buttons is-right">
            <b-button
              type="is-primary"
              native-type="submit"
              label="Save"
            />
          </div>
        </form>
      </b-tab-item>

      <b-tab-item value="root.description">
        <form v-if="activeTab.tab === 'root.description'" @submit.prevent="editDescription()">
          <b-field label="Coupon description">
            <b-input
              v-model="localAffiliation.description"
              type="textarea"
              placeholder="10% Off for each order over $50"
              maxlength="200"
            />
          </b-field>

          <br>

          <div class="buttons is-right">
            <b-button
              type="is-primary"
              native-type="submit"
              label="Save"
            />
          </div>
        </form>
      </b-tab-item>
    </template>
  </TheTabsPanel>
</template>

<!-- eslint-disable max-len -->
<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from '@dailyplanet/utils/helpers';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheEditAffiliationCodePanel',
  mixins: [FilterEngineMixin],
  props: {
    affiliationCode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: {
        tab: 'root',
        title: 'Edit affiliation',
        subtext: '',
      },
      tabItems: {
        root: {
          tab: 'root',
          title: 'Edit affiliation',
          subtext: '',
        },
        'root.contactPlatformCpid': {
          tab: 'root.contactPlatformCpid',
          title: 'Edit influencer',
          subtext: 'Edit affiliation',
        },
        'root.campaignCid': {
          tab: 'root.campaignCid',
          title: 'Edit campaign',
          subtext: 'Edit affiliation',
        },
        'root.commission': {
          tab: 'root.commission',
          title: 'Edit commission rate',
          subtext: 'Edit affiliation',
        },
        'root.description': {
          tab: 'root.description',
          title: 'Edit coupon description',
          subtext: 'Edit affiliation',
        },
      },
      search: '',
      username: '',
      usernameSearchResult: [],
      isFetching: false,

      localAffiliation: null,
    };
  },
  computed: {
    ...mapGetters({
      _affiliationCodeOverviewList: 'TheEditAffiliationCodePanel/affiliationCodeOverviewList',
      _campaignList: 'TheEditAffiliationCodePanel/campaignList',
      _activeCampaignList: 'TheEditAffiliationCodePanel/activeCampaignList',
      _searchContactPlatformUsernameList: 'TheEditAffiliationCodePanel/searchContactPlatformUsernameList',
    }),

    navigableItems() {
      return {
        'root.contactPlatformCpid': {
          label: 'Edit influencer',
          to: 'root.contactPlatformCpid',
          icon: 'ubu-account',
        },
        'root.campaignCid': {
          label: 'Edit campaign',
          to: 'root.campaignCid',
          icon: 'ubu-influence',
        },
        'root.commission': {
          label: 'Edit commission rate',
          to: 'root.commission',
          icon: 'ubu-discount',
        },
        'root.description': {
          label: 'Edit coupon description',
          to: 'root.description',
          icon: 'ubu-search',
        },
      };
    },

    affiliationCodeREST() {
      const {
        contactPlatform,
        contactPlatformStatistic,
        totalOrders,
        totalRevenue,
        orderList,
        shortLink,
        ...affiliationCodeREST
      } = this.affiliationCode;

      return affiliationCodeREST;
    },

    filteredActiveCampaignList() {
      const activeCampaignList = Object.values(this._activeCampaignList);

      if (!activeCampaignList.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByName = this.search;

      return this.filterEngine(activeCampaignList, filtersToApply);
    },

    localCommissionRate: {
      get() { return this.parsedNumber(this.localAffiliation.commissionRate * 100); },
      set(val) { this.localAffiliation.commissionRate = this.parsedNumber(val / 100); },
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    username: debounce(function (newVal) {
      this.usernameSearchResult = [];
      if (!newVal || !newVal.length || newVal.length < 3) {
        this.isFetching = false;
        return;
      }
      if (!newVal.match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/)) {
        this.isFetching = false;
        return;
      }
      this.isFetching = true;
      this.reportSearchContactPlatformUsername({ payload: { username: newVal } })
        .then(() => {
          this.usernameSearchResult = this._searchContactPlatformUsernameList;
          this.isFetching = false;
        });
    }, 1000),

    _affiliationCodeOverviewList() {
      this.$emit('input', this._affiliationCodeOverviewList[this.affiliationCode.acid]);
    },
  },
  mounted() {
    this.localAffiliation = { ...this.affiliationCodeREST };
  },
  methods: {
    ...mapActions({
      updateAffiliationCodeList: 'TheEditAffiliationCodePanel/updateAffiliationCodeList',
      reportSearchContactPlatformUsername: 'TheEditAffiliationCodePanel/reportSearchContactPlatformUsername',
      _patchAffiliationCode: 'TheEditAffiliationCodePanel/patchAffiliationCode',
    }),

    parsedNumber(num) { return Math.round((num + Number.EPSILON) * 100) / 100; },

    goToPreviousTab() {
      if (this.activeTab.tab === 'root') return;

      const tabToGo = this.activeTab.tab.split('.').slice(0, -1).join('.');
      this.activeTab = this.tabItems[tabToGo];
    },

    goToTabByTabPath(tabToGo) { this.activeTab = this.tabItems[tabToGo]; },

    closePanel() { this.$emit('close'); },

    editCampaignRelation(campaignCid) {
      const payload = {
        sid: this.affiliationCode.shopSid,
        affiliationCodeList: [{ ...this.affiliationCodeREST, campaignCid }],
      };

      return this.updateAffiliationCodeList({ payload, httpQuery: { contactPlatform: true } })
        .then(() => { this.goToTabByTabPath('root'); this.$emit('refresh'); });
    },

    editContactPlatformRelation(contactPlatformCpid) {
      const payload = {
        sid: this.affiliationCode.shopSid,
        affiliationCodeList: [{ ...this.affiliationCodeREST, contactPlatformCpid }],
      };

      return this.updateAffiliationCodeList({ payload, httpQuery: { contactPlatform: true } })
        .then(() => { this.goToTabByTabPath('root'); this.$emit('refresh'); });
    },

    editCommissionRate() {
      return this._patchAffiliationCode({ payload: { sid: this.affiliationCode.shopSid, ...this.localAffiliation } })
        .then(() => { this.goToTabByTabPath('root'); });
    },

    editDescription() {
      return this._patchAffiliationCode({ payload: { sid: this.affiliationCode.shopSid, ...this.localAffiliation } })
        .then(() => { this.goToTabByTabPath('root'); });
    },
  },
};
</script>
