<template>
  <div style="height: calc(100vh - 200px); overflow-y: auto;">
    <article class="flex wrap space-evenly p-4">
      <UbuAnalyticCard
        v-for="(analytic, index) in analyticCards"
        :key="index"
        class="is-horizontal mb-2"
        style="width: 350px;"
        :name="analytic.name"
        :value="analytic.value"
        :icon="analytic.icon"
        :color="analytic.color"
      />
    </article>

    <br>

    <article class="flex wrap space-evenly">
      <div class="graphContainers">
        <span class="text is-3 has-text-secondary">Mentions distribution</span>

        <UbuChartDoughnut
          v-if="mentionsDistribution"
          class=""
          chart-id="mentionsDistribution"
          :data="mentionsDistribution"
        />
      </div>

      <div class="graphContainers">
        <span class="text is-3 has-text-secondary">Mentions by day</span>

        <UbuChartMixed
          v-if="mentionsByDay"
          class=""
          chart-id="mentionsByDay"
          :data="mentionsByDay"
        />
      </div>
    </article>
  </div>
</template>

<script>
/* eslint-disable max-len */

import { mapGetters, mapActions } from 'vuex';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';

export default {
  name: 'TheTribeAnalyticMentions',
  mixins: [TableUtilsMixin],
  model: {
    prop: 'httpQuery',
  },
  props: {
    httpQuery: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      mentionsStats: 'TheTribeAnalytics/mentionsStats',
    }),

    analyticCards() {
      if (!this.mentionsStats) return null;

      const cards = [];

      const mentionners = Object.keys(this.mentionsStats.mentionsByContactPlatform);

      cards.push({
        name: 'Total mentions', color: 9, icon: 'ubu-mentions', value: this.mentionsStats.mentions.length,
      });

      cards.push({
        name: 'Total posts', color: 11, icon: 'ubu-mentions', value: this.mentionsStats.byType.POST,
      });

      cards.push({
        name: 'Total stories', color: 12, icon: 'ubu-mentions', value: this.mentionsStats.byType.STORY,
      });

      cards.push({
        name: 'Total expected reach', color: 12, icon: 'ubu-organic', value: this.mentionsStats?.totalReach,
      });

      cards.push({
        name: 'Total people who mentionned you', color: 12, icon: 'ubu-account', value: mentionners.length,
      });

      cards.push({
        name: 'Average mentions by people', color: 9, icon: 'ubu-overview', value: Math.round(this.mentionsStats.mentions.length / mentionners.length),
      });

      return cards;
    },

    mentionsDistribution() {
      if (!this.mentionsStats) return null;
      return {
        labels: ['posts', 'stories'],
        datasets: [{
          data: [this.mentionsStats.byType.POST, this.mentionsStats.byType.STORY],
          maintainAspectRatio: false,
          backgroundColor: [
            'rgba(248, 130, 236, 0.6)',
            'rgba(255, 98, 164, 0.6)',
          ],
        }],
      };
    },

    mentionsByDay() {
      if (!this.mentionsStats) return null;
      const counts = Object.values(this.mentionsStats.byDate).reduce((acc, { POST, STORY }) => {
        acc.posts.data.push(POST);
        acc.stories.data.push(STORY);
        return acc;
      }, {
        posts: {
          type: 'bar',
          label: 'Posts',
          // yAxisID: 'A',
          data: [],
          backgroundColor: ['rgba(248, 130, 236, 0.6)'],
          stack: 'stack1',
          order: 3,
        },
        stories: {
          type: 'bar',
          label: 'Stories',
          // yAxisID: 'A',
          data: [],
          backgroundColor: ['rgba(255, 98, 164, 0.6)'],
          stack: 'stack1',
          order: 3,
        },

      });

      return {
        type: 'bar',
        labels: Object.keys(this.mentionsStats.byDate),
        datasets: Object.values(counts),
      };
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'httpQuery.createdOn': function () {
      this.reportDatas();
    },
  },
  mounted() {
    return this.reportDatas();
  },
  methods: {
    ...mapActions({
      _report_mentionsStats: 'TheTribeAnalytics/report_mentionsStats',
    }),

    reportDatas() {
      return this._report_mentionsStats({
        payload: { tribeTid: this.$route.params.tribeTid },
        httpQuery: this.httpQuery,
      });
    },
  },
};
</script>
