<template>
  <UbuCustomChip
    :model="{
      title: 'Tribes',
      defaultIcon: 'ubu-custom-tribe',
      count: cpo[customsName].length > 1 ? cpo[customsName].length : null,
      listItem: cpo[customsName].map((tribeMember) => ({
        icon: _tribeList[tribeMember.tribeTid].icon,
        color: _tribeList[tribeMember.tribeTid].color,
        label: _tribeList[tribeMember.tribeTid].name,
        status: tribeMember.memberGroupMgid
          ? _memberGroupList[tribeMember.memberGroupMgid].name
          : 'Open',
      }))
    }"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheTribeChip',
  props: {
    cpo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      customsName: 'tribeMemberList',
    };
  },
  computed: {
    ...mapGetters({
      _tribeList: 'TheCRM/tribeList',
      _memberGroupList: 'TheCRM/memberGroupList',
    }),
  },
};
</script>
