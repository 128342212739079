<template>
  <fieldset class="fieldset">
    <legend>Results filters</legend>

    <b-field
      label="Leads matching filters"
      horizontal
      grouped
    >
      <p class="control">
        <b-button
          :type="resultsCount > 0 ? 'is-success' : 'is-danger'"
          :label="String(resultsCount)"
          class="is-reverted is-not-clickable"
        />
      </p>
    </b-field>

    <b-field
      label="Zone & Language"
      horizontal
      grouped
    >
      <UbuAutocomplete
        v-model="localZoneFilter"
        :options="filteredZonePreset"
        placeholder="Zone"
      />

      <UbuAutocomplete
        v-model="localLanguageFilter"
        :options="filteredLanguagePreset"
        placeholder="Language"
      />

      <UbuAutocomplete
        v-model="localScrapingZoneFilter"
        :options="filteredScrapinZonePreset"
        placeholder="Scraping zone"
      />
    </b-field>

    <b-field
      label="Date selection"
      horizontal
      grouped
    >
      <b-field
        v-for="([key, model]) in Object.entries(dateFilters)"
        :key="key"
      >
        <b-select
          v-model="model.when"
          placeholder="moment"
        >
          <option value="before">before</option>
          <option
            value="after"
            selected
          >
            after
          </option>
        </b-select>
        <b-datepicker
          v-model="model.date"
          expanded
          :placeholder="model.label"
        />
        <p class="control">
          <b-button
            type="is-danger"
            icon-left="delete"
            class="is-reverted"
            @click="model.when = null; model.date = null"
          />
        </p>
      </b-field>
    </b-field>

    <b-field
      label="Stats selection"
      horizontal
      grouped
    >
      <b-field
        v-for="([key, model]) in Object.entries(numbersFilters)"
        :key="key"
      >
        <p class="control">
          <b-button
            type="is-light"
            :label="key"
            class="is-reverted is-not-clickable"
          />
        </p>
        <b-input
          v-model="model.min"
          size="is-small"
          placeholder="min"
          @input="model.min = model.min.replace(',', '.')"
        />
        <b-input
          v-model="model.max"
          size="is-small"
          placeholder="max"
          @input="model.min = model.min.replace(',', '.')"
        />
        <p class="control">
          <b-button
            type="is-danger"
            icon-left="delete"
            class="is-reverted"
            @click="model.min = null; model.max = null"
          />
        </p>
      </b-field>
    </b-field>

    <b-field
      label="Results pagination"
      horizontal
    >
      <b-input
        v-model="localPagin"
        size="is-small"
        placeholder="pagination"
        step="1"
      />
    </b-field>

    <b-field
      label="Columns to display"
      horizontal
    >
      <div class="buttons">
        <template v-for="([name, { visible, is }], index) in Object.entries(columns)">
          <b-button
            v-if="visible && is === 'contact'"
            :key="index"
            :label="name"
            :active="visible"
            class="is-success"
            size="is-small"
            @click="columns[name].visible = !visible"
          />
          <b-button
            v-else-if="visible && is === 'lead'"
            :key="index"
            :label="name"
            :active="visible"
            class="is-link"
            size="is-small"
            @click="columns[name].visible = !visible"
          />
          <b-button
            v-else
            :key="index"
            :label="name"
            :active="visible"
            class="is-dark"
            size="is-small"
            @click="columns[name].visible = !visible"
          />
        </template>
      </div>
    </b-field>
  </fieldset>
</template>

<script>
import GeoMixin from '@dailyplanet/mixins/GeoMixin';

export default {
  name: 'UbuLeadsDashboardFiltersForm',
  mixins: [GeoMixin],
  props: {
    resultsCount: {
      type: Number,
      required: true,
    },
    pagin: {
      type: Number,
      required: true,
    },
    columns: {
      type: Object,
      required: true,
    },
    dateFilters: {
      type: Object,
      required: true,
    },
    numbersFilters: {
      type: Object,
      required: true,
    },
    zoneFilter: {
      type: Object,
      required: true,
    },
    languageFilter: {
      type: Object,
      required: true,
    },
    scrapingZoneFilter: {
      type: Object,
      required: true,
    },
    scrapingZoneList: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    localPagin: {
      get() { return this.pagin; },
      set(newValue) { this.$emit('update:pagin', Number(newValue)); },
    },
    localZoneFilter: {
      get() { return this.zoneFilter; },
      set(newValue) { this.$emit('update:zoneFilter', newValue); },
    },
    localLanguageFilter: {
      get() { return this.languageFilter; },
      set(newValue) { this.$emit('update:languageFilter', newValue); },
    },
    localScrapingZoneFilter: {
      get() { return this.scrapingZoneFilter; },
      set(newValue) { this.$emit('update:scrapingZoneFilter', newValue); },
    },
  },
};
</script>
