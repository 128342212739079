/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    tremendousCampaignList: {},
    defaultTremendousCampaignTcid: null,
  },
  mutations: {
    SET_TREMENDOUS_CAMPAIGN_LIST(state, newData) { state.tremendousCampaignList = newData; },
    SET_DEFAULT_TREMENDOUS_CAMPAIGN_TCID(state, newData) { state.defaultTremendousCampaignTcid = newData; },
  },
  getters: {
    getterTremendousCampaignList: (state) => state.tremendousCampaignList,
    getterDefaultTremendousCampaignTcid: (state) => state.defaultTremendousCampaignTcid,
  },
  actions: {
    report({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiTremendous.tremendousCampaign.report({ payload, httpQuery })
        .then((tremendousCampaignList) => {
          if (!tremendousCampaignList.length) return;

          commit('SET_TREMENDOUS_CAMPAIGN_LIST', tremendousCampaignList.arrayMapper('tcid'));

          const currentDefault = tremendousCampaignList.find(({ isDefault }) => isDefault);

          if (currentDefault) commit('SET_DEFAULT_TREMENDOUS_CAMPAIGN_TCID', currentDefault.tcid);
        });
    },

    setDefault({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiTremendous.tremendousCampaign.setDefault({ payload, httpQuery })
        .then(() => {
          commit('SET_DEFAULT_TREMENDOUS_CAMPAIGN_TCID', payload.tremendousCampaignTcid);
        });
    },

    refresh({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiTremendous.tremendousCampaign.refresh({ payload, httpQuery })
        .then((tremendousCampaignList) => {
          if (!tremendousCampaignList.length) return;

          commit('SET_TREMENDOUS_CAMPAIGN_LIST', tremendousCampaignList.arrayMapper('tcid'));

          const currentDefault = tremendousCampaignList.find(({ isDefault }) => isDefault);

          if (currentDefault) commit('SET_DEFAULT_TREMENDOUS_CAMPAIGN_TCID', currentDefault.tcid);
        });
    },
  },
};
