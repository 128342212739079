/* eslint-disable no-multi-spaces */
import TheCrews from './TheCrews';

import TheCrewAdmin from './views/TheCrewAdmin';

export default [
  {
    path: '/crews',
    redirect: '/crews/all-crewAdmin',
  },
  {
    path: '/crews',
    name: 'crews',
    component: TheCrews,
    meta: {
      title: 'Users',
      hasMenu: true,
      viewStoreNavigation: 'TheCrews',
      requireAuth: true,
    },
    children: [
      {
        path: '/crews/all-crewAdmin',
        name: 'Crew - Admin',
        components: { allUsers: TheCrewAdmin },
        meta: {
          title: 'Users',
          hasMenu: true,
          viewStoreNavigation: 'TheUsers',
          requireAuth: true,
        },
      },
    ],
  },
];
