import adminLogin from './adminLogin.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    adminLogin,
  },
};
