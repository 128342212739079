<template>
  <div>
    <p class="has-text-secondary">
      Do you want to remove them from their current campaign and move them to this one?
    </p>

    <div class="buttons is-right">
      <b-button
        v-if="alreadyInOtherCampaignAndWillBeMoved.length"
        label="Remove and add to new campaign"
        type="is-primary"
        @click="$emit('next')"
      />

      <b-button
        v-else
        label="Next"
        type="is-primary"
        @click="$emit('next')"
      />
    </div>

    <p class="multibar row-vcentered">
      <span class="start text">
        People already in OTHER campaign
      </span>
    </p>

    <br>

    <UbuSearchbar
      v-model="search"
      @clear="search = ''"
    />

    <br>

    <b-table
      :data="alreadyActiveInOtherCampaign"
      checkable
      :checked-rows.sync="localChecked"
      paginated
      pagination-size="is-small"
    >
      <b-table-column
        v-slot="{ row }"
        field="username"
        label="Username"
      >
        <UbuPlatformCard
          :platform-name="row.contactPlatform.platformName.toLowerCase()"
          :text="row.contactPlatform.username"
          font-size="20px"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        field="activeCampaigns"
        label="Current campaign"
      >
        <p
          v-if="row.active"
          class="multibar"
        >
          <b-icon
            pack="ubu"
            icon="ubu-custom-influence"
            :class="`has-text-color-${activeCampaignList[row.active.campaignCid].color} start`"
          />
          <span class="start">{{ activeCampaignList[row.active.campaignCid].name }}</span>
        </p>
      </b-table-column>

      <template #empty>
        <p class="text has-text-secondary">
          You don't select contacts matching these criterias.
        </p>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TheAddToCampaignMixin from '../mixins/TheAddToCampaign.mixin';

export default {
  name: 'TheAddToCampaignAlreadyInCampaign',
  mixins: [TheAddToCampaignMixin],
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      tabItems: 'TheCRMBulk/tabItems',
      activeTab: 'TheCRMBulk/activeTab',

      activeCampaignList: 'TheCRM/activeCampaignList',
      selectedCps: 'TheNewAddToCampaign/selectedCps',
      newBulkAction: 'TheNewAddToCampaign/newBulkAction',
      alreadyInOtherCampaignAndWillBeMoved: 'TheNewAddToCampaign/alreadyInOtherCampaignAndWillBeMoved',
    }),

    localChecked: {
      get() { return this.alreadyInOtherCampaignAndWillBeMoved; },
      set(newValue) { this.UP_alreadyInOtherCampaignAndWillBeMoved(newValue); },
    },

  },
  mounted() {
    if (!this.alreadyInOtherCampaignAndWillBeMoved.length) {
      this.localChecked = this.alreadyActiveInOtherCampaign;
    }
  },
  methods: {
    ...mapActions({
      UP_alreadyInOtherCampaignAndWillBeMoved: 'TheNewAddToCampaign/UP_alreadyInOtherCampaignAndWillBeMoved',
    }),
  },
};
</script>
