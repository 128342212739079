/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

import iqdataReport from './iqdataReport.store';

export default {
  namespaced: true,
  actions: {
    affiliationCode({ rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      return clark.api.apiDownload.affiliationCode({ payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery });
    },
    tribeMember({ rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      return clark.api.apiDownload.tribeMember({ payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery });
    },
    activity({ rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      return clark.api.apiDownload.activity({ payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery });
    },
    downloadTask({ rootGetters }, { payload, httpQuery = {} }) {
      const { 'TheAuthenticate/myChannelInstagram': myChannelInstagram } = rootGetters;
      return clark.api.apiDownload.downloadTask({ payload: { channelCid: myChannelInstagram.cid, ...payload }, httpQuery });
    },
  },
  modules: {
    iqdataReport,
  },
};
