<template>
  <div class="drawer">
    <div class="row start">
      <div
        v-for="i in 6"
        :key="i"
        :class="['ubuInfluencePanel overflow-hidden no-border', {
          'is-empty': i >= 5,
        }]"
      >
        <b-skeleton
          width="100%"
          height="calc(100vh - 328px)"
        />
      </div>
    </div>
    <div
      class="row end"
      is-closed="true"
    >
      <div
        v-for="i in 4"
        :key="i"
        class="ubuInfluencePanel overflow-hidden no-border"
      >
        <b-skeleton
          width="100%"
          height="58px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuSkeletonPipeline',
};
</script>
