<template>
  <div id="TheAuthenticateForgotPassword">
    <div v-if="!isSent">
      <p class="title is-1">
        Enter your email to change your password.
      </p>

      <br>

      <form @submit.prevent="sendEmail()">
        <b-field label="Email">
          <b-input
            v-model="email"
            required
            placeholder="name@company.com"
          />
        </b-field>
        <br>

        <div class="buttons is-right">
          <div class="buttons">
            <b-button
              type="is-primary"
              expanded
              label="Send email"
              native-type="submit"
            />
          </div>
        </div>
      </form>
    </div>

    <div v-else>
      <p class="title is-1">
        We sent you an email from which you can change your password.
      </p>

      <br>

      <div class="buttons is-right">
        <div class="buttons">
          <b-button
            tag="router-link"
            to="/auth/login"
            type="is-primary"
            expanded
            label="Back to login"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheAuthenticateSendToken',
  data() {
    return {
      email: '',
      isSent: false,
    };
  },
  methods: {
    ...mapActions({
      createAndSendToken: 'TheAuthenticateForgotPassword/createAndSendToken',
    }),

    sendEmail() {
      const payload = {
        passName: this.email,
        method: 'UBU',
      };

      return this.createAndSendToken({ payload, httpQuery: { isStub: true } })
        .then(() => { this.isSent = true; })
        .catch(() => { this.isSent = false; });
    },
  },
};
</script>
