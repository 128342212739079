<template>
  <TheSimplePanel
    closing-way="emit"
    @close="$emit('close')"
  >
    <template #header>
      <div class="multibar row-vcentered">
        <b-icon
          icon="ubu-custom-tag"
          pack="ubu"
          :class="`start has-text-color-${custom.color}`"
        />
        <p class="start text is-3 has-text-secondary"> {{ custom.label }} </p>
      </div>
      <h1 class="title is-2">Auto-tag with keywords</h1>
    </template>

    <template #content>
      <b-field
        label="Add keywords (press 'SPACE' to validate)"
      >
        <b-taginput
          v-model="localCustom.keywords"
          placeholder="Add a keyword"
          aria-close-label="Delete this keyword"
          :confirm-keys="[',', 'Tab', 'Enter', ' ']"
          :before-adding="checkValidity"
          @input="$update('keywords', $event)"
        />
      </b-field>

      <div class="buttons is-right">
        <b-button
          label="Remove all"
          :disabled="!localCustom.keywords || !localCustom.keywords.length"
          type="is-secondary"
          @click="$update('keywords', [])"
        />

        <b-button
          label="Save changes"
          :disabled="!canSave"
          type="is-success"
          @click="updateKeywords()"
        />
      </div>
    </template>
  </TheSimplePanel>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';
import VModelMixin from '@dailyplanet/mixins/VModelMixin';
import NavigationMixin from '@dailyplanet/mixins/NavigationMixin';
import { snackbarError } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheSettingTagEditAutoTagPanel',
  mixins: [VModelMixin, NavigationMixin],
  model: {
    prop: 'custom',
  },
  props: {
    custom: { type: Object, required: true },
  },
  computed: {
    ...mapGetters({
      threadGroupList: 'TheSettingWorkspaceSetting/threadGroupList',
    }),
    localCustom: {
      get() { return { ...this.custom }; },
      set(val) { this.$emit('input', val); },
    },
    canSave() {
      if (JSON.stringify(this.localCustom.keywords) === JSON.stringify(this.threadGroupList[this.custom.tgid].keywords)) return false;
      return true;
    },
  },
  methods: {
    ...mapActions({
      updateThreadGroupKeywords: 'TheSettingWorkspaceSetting/updateThreadGroupKeywords',
      deleteThreadGroupAutoTag: 'TheSettingWorkspaceSetting/deleteThreadGroupAutoTag',
      emitSegmentEvent: 'TheInbox/emitSegmentEvent',
    }),
    updateKeywords() {
      const { keywords, tgid } = this.localCustom;
      return Promise.resolve()
        .then(() => {
          if (keywords && keywords.length) return this.updateThreadGroupKeywords({ payload: { tgid, keywords } });
          return this.deleteThreadGroupAutoTag({ payload: { tgid } });
        })
        .then((updatedThreadGroup) => {
          if (keywords && keywords.length) {
            this.emitSegmentEvent({ event: 'addKeywordToTag' });
            this.emitSegmentEvent({ event: 'activeAutoTag' });
          }
          this.$emit('input', updatedThreadGroup);
        })
        .then(() => this.$emit('close'));
    },
    checkValidity(tagToAdd) {
      /**
       * if tag has more than one word dont input KW
       */
      if (tagToAdd.indexOf(' ') !== -1) {
        this.$buefy.snackbar.open({ message: 'Use only one word by keyword', ...snackbarError });

        return false;
      }

      return true;
    },
  },
};
</script>
