// const _state = () => ({
//   theme: localStorage.getItem('ubuTheme') || 'auto',
// });

// console.log('🚀 ~ file: index.js ~ line 9 ~ localStorage.getItem(ubuTheme)', localStorage.getItem('ubuTheme'));
export default {
  namespaced: true,
  state: {
    theme: localStorage.getItem('ubuTheme') || 'AUTO',
  },
  getters: {
    getterTheme: (state) => state.theme,
  },
  actions: {
    update({ commit }, { payload }) {
      if (['LIGHT', 'DARK', 'AUTO'].includes(payload.theme)) {
        commit('SET_THEME', payload.theme);
        localStorage.setItem('ubuTheme', payload.theme);
      }
    },
  },
  mutations: {
    SET_THEME(state, theme) { state.theme = theme; },
  },
};
