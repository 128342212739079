/* eslint-disable camelcase */
import moment from 'moment';

const analyticsMediasFormater = ({ medias, followerCount, username }) => {
  const mediasWithoutDuplicate = medias.reduce((acc, media) => {
    if (acc.find((data) => data.fb_id === media.fb_id)) {
      const duplicates = acc.filter((data) => data.fb_id === media.fb_id);
      const mostCompleteObj = duplicates.reduce((prev, current) => (
        (Object.keys(prev).length > Object.keys(current).length) ? prev : current));
      if (Object.keys(media).length > Object.keys(mostCompleteObj).length) acc.push(media);
    } else acc.push(media);
    return acc;
  }, []);

  return mediasWithoutDuplicate.map((m) => ({
    mediaUrl: `https://io.getubu.com/media/${m.id}`,
    thumbnailUrl: `https://io.getubu.com/instaMedia/${m.id}/thumbnail`,
    totalInteractions: m.engagement || null,
    commentCount: m.comments_count || 0,
    likeCount: m.like_count || 0,
    savedCount: m.saved || 0,
    impressions: m.impressions || 0,
    reach: m.reach || 0,
    date: moment(m.ts).format('YYYY-MM-DD'),
    timestamp: m.ts,
    mediaType: m.media_type || 'IMAGE',
    isStory: m.product_type === 'story',
    tapsForward: m.taps_forward || null,
    tapsBack: m.taps_back || null,
    exits: m.exits || null,
    replies: m.replies || null,
    engagement: m.engagementFollowersRatio * 100,
    permalink: m.permalink || null,
    text: m.caption || null,
    created_on: m.ts,
    createdOn: m.ts,
    followerCount,
    username,
  })).map((m) => ({
    ...m,
    mid: m.mediaUrl,
    mainMedia: {
      url: m.mediaUrl,
      isVideo: !!(m.mediaType !== 'IMAGE' && m.mediaType !== 'CAROUSEL_ALBUM'),
      type: m.mediaType === 'IMAGE' || m.mediaType === 'CAROUSEL_ALBUM' ? 'IMAGE' : 'VIDEO',
    },
    engagement: m.engagement || (((m.totalInteractions
      || (m.likeCount + m.commentCount + m.savedCount)) / followerCount) * 100),
    engagementOnReach: m.reach ? (((m.totalInteractions
        || (m.likeCount + m.commentCount + m.savedCount)) / m.reach) * 100) : null,
    reachRate: m.reach ? ((m.reach / followerCount) * 100) : null,
    likesOnReach: m.reach ? ((m.likeCount / m.reach) * 100) : null,
    commentsOnReach: m.reach ? ((m.commentCount / m.reach) * 100) : null,
    savesOnReach: m.reach ? ((m.savedCount / m.reach) * 100) : null,
    completion: m.exits && m.tapsForward
    && m.impressions ? ((m.exits + m.tapsForward) / m.impressions) * 100 : null,
    viewPerUser: m.reach && m.impressions ? m.impressions / m.reach : null,
  })).reduce((acc, media) => {
    const analyticsDataList = [];
    if (media.likeCount) {
      analyticsDataList.push(
        {
          name: 'Likes',
          color: 2,
          icon: 'ubu-like',
          value: media.likeCount,
        },
      );
    }
    if (media.commentCount) {
      analyticsDataList.push(
        {
          name: 'Comments',
          color: 8,
          icon: 'ubu-comment',
          value: media.commentCount,
        },
      );
    }
    if (media.savedCount) {
      analyticsDataList.push(
        {
          name: 'Saves',
          color: 4,
          icon: 'ubu-bookmark',
          value: media.savedCount,
        },
      );
    }
    if (media.engagement) {
      analyticsDataList.push(
        {
          name: 'Engagement',
          color: 9,
          icon: 'ubu-influence',
          symbol: '%',
          value: media.engagement,
        },
      );
    }
    if (media.engagementOnReach) {
      analyticsDataList.push(
        {
          name: 'Engagement on reach',
          color: 9,
          icon: 'ubu-influence',
          symbol: '%',
          value: media.engagementOnReach,
        },
      );
    }
    if (media.reach) {
      analyticsDataList.push(
        {
          name: 'Reach',
          color: 5,
          icon: 'ubu-account',
          value: media.reach,
        },
      );
    }
    if (media.reachRate) {
      analyticsDataList.push(
        {
          name: 'Reach rate',
          color: 5,
          icon: 'ubu-account',
          symbol: '%',
          value: media.reachRate,
        },
      );
    }
    if (media.impressions) {
      analyticsDataList.push(
        {
          name: 'Prints',
          color: 7,
          icon: 'ubu-eye',
          value: media.impressions,
        },
      );
    }
    if (media.viewPerUser) {
      analyticsDataList.push(
        {
          name: 'Views per user',
          color: 7,
          icon: 'ubu-eye',
          value: media.viewPerUser,
        },
      );
    }
    if (media.likesOnReach) {
      analyticsDataList.push(
        {
          name: 'Likes on reach',
          color: 2,
          icon: 'ubu-like',
          symbol: '%',
          value: media.likesOnReach,
        },
      );
    }
    if (media.commentsOnReach) {
      analyticsDataList.push(
        {
          name: 'Comments on reach',
          color: 8,
          icon: 'ubu-comment',
          symbol: '%',
          value: media.commentsOnReach,
        },
      );
    }
    if (media.savesOnReach) {
      analyticsDataList.push(
        {
          name: 'Saves on reach',
          color: 4,
          icon: 'ubu-bookmark',
          symbol: '%',
          value: media.savesOnReach,
        },
      );
    }
    if (media.exits) {
      analyticsDataList.push(
        {
          name: 'Exits',
          color: 8,
          icon: 'ubu-information',
          value: media.exits,
        },
      );
    }
    if (media.completion) {
      analyticsDataList.push(
        {
          name: 'Completion',
          color: 8,
          icon: 'ubu-information',
          symbol: '%',
          value: media.completion,
        },
      );
    }
    if (media.tapsForward) {
      analyticsDataList.push(
        {
          name: 'Taps Forward',
          color: 9,
          icon: 'ubu-influence',
          value: media.tapsForward,
        },
      );
    }
    if (media.tapsBack) {
      analyticsDataList.push(
        {
          name: 'Taps Back',
          color: 9,
          icon: 'ubu-influence',
          value: media.tapsBack,
        },
      );
    }
    if (media.replies) {
      analyticsDataList.push(
        {
          name: 'Replies',
          color: 7,
          icon: 'ubu-replies',
          value: media.replies,
        },
      );
    }

    acc.push({ ...media, analyticsDataList });
    return acc;
  }, []).sort((a, b) => b.timestamp - a.timestamp);
};

const analyticsVisibilityCalculator = (medias) => {
  if (medias && medias.length) {
    const mediasMetrics = medias.reduce((acc, row) => {
      const keyToUse = ['like_count', 'comments_count', 'reach', 'impressions'];

      if (row.product_type === 'story') {
        if (!acc.storiesReach) acc.storiesReach = row.reach || 0;
        else acc.storiesReach += row.reach || 0;

        if (!acc.storiesPrint) acc.storiesPrint = row.impressions || 0;
        else acc.storiesPrint += row.impressions || 0;
      } else {
        if (!acc.postsReach) acc.postsReach = row.reach || 0;
        else acc.postsReach += row.reach || 0;

        if (!acc.postsPrint) acc.postsPrint = row.impressions || 0;
        else acc.postsPrint += row.impressions || 0;
      }

      keyToUse.forEach((key) => {
        if (row[key]) {
          if (!acc[key]) {
            acc[key] = row[key] || 0;
            return acc;
          }
          acc[key] += row[key] || 0;
          return acc;
        }
        return acc;
      });

      return acc;
    }, {});

    const nbPosts = medias.filter((m) => m.product_type !== 'story').length;
    const nbStories = medias.filter((m) => m.product_type === 'story').length;

    return {
      ...mediasMetrics,
      commentsPerPostAverage: Math.round(mediasMetrics.comments_count / nbPosts),
      reachByPostAverage: Math.round(mediasMetrics.postsReach / nbPosts),
      likesPerPostAverage: Math.round(mediasMetrics.like_count / nbPosts),
      reachByStoryAverage: Math.round(mediasMetrics.storiesReach / nbStories),
      printByPostAverage: Math.round(mediasMetrics.postsPrint / nbPosts),
      printByStoryAverage: Math.round(mediasMetrics.storiesPrint / nbStories),
      postsReach: mediasMetrics.postsReach,
      postsPrint: mediasMetrics.postsPrint,
      storiesReach: mediasMetrics.storiesReach,
      storiesPrint: mediasMetrics.storiesPrint,
    };
  }
  return {
    like_count: 0,
    comments_count: 0,
    reach: 0,
    commentsPerPostAverage: 0,
    reachByPostAverage: 0,
    likesPerPostAverage: 0,
    reachByStoryAverage: 0,
    printByPostAverage: 0,
    printByStoryAverage: 0,
    postsReach: 0,
    postsPrint: 0,
    storiesReach: 0,
    storiesPrint: 0,
  };
};

const analyticsFormater = ({ analytics, username = '' }) => {
  const { new_followers, medias, final_followers } = analytics;
  let { followers_difference } = analytics;

  if (followers_difference > new_followers) followers_difference = new_followers;

  return {
    ...analyticsVisibilityCalculator(medias),
    ...analytics,
    daysConnected: analytics.dayArray,
    followers_difference,
    medias: analyticsMediasFormater({ medias, followerCount: final_followers, username }),
  };
};

export {
  // eslint-disable-next-line
  analyticsMediasFormater,
  analyticsVisibilityCalculator,
  analyticsFormater,
};
