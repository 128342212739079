/* eslint-disable max-len */
import { barbara, clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    isClarkConnected() {
      return clark.api.ping({ payload: {}, httpQuery: {} })
        .then(() => true)
        .catch(() => false);
    },
    isBarbaraConnected() {
      return barbara.api.ping({ payload: {}, httpQuery: {} })
        .then(() => true)
        .catch(() => false);
    },
  },
  modules: {},
};
