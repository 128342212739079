/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    currentUserDetail: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/userDetail/getterCurrentUserDetail': currentUserDetail } = rootGetters;

      return currentUserDetail;
    },
    userDetailTargeted: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/userDetail/getterUserDetailTargeted': userDetailTargeted } = rootGetters;

      return userDetailTargeted;
    },
  },
  actions: {
    updateUserDetail({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { uid } = rootGetters['Authenticate/getterAuthenticate'];

      const action = {
        dispatch,
        target: 'User/userDetail/update',
        context: { payload: { uid, ...payload }, httpQuery },
        textNotifier: 'update user details',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: message.js ~ line 30 ~ updateUserDetail ~ e', e);
          Snackbar.open({
            message: 'Unable to update user details.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
  },
};
