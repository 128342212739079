/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    affiliationRewardList: {},
    totalAffiliationRewardList: null,
    totalsByPeriod: {},
    rewardList: {},
    totalRewardList: 0,
  },
  mutations: {
    SET_AFFILIATION_REWARD_LIST(state, newData) { state.affiliationRewardList = newData; },
    SET_TOTAL_AFFILIATION_REWARD_LIST(state, newData) { state.totalAffiliationRewardList = newData; },
    SET_TOTALS_BY_PERIOD(state, newData) { state.totalsByPeriod = newData; },
    SET_REWARD_LIST(state, newData) { state.rewardList = newData; },
    SET_REWARD_TOTAL(state, newData) { state.totalRewardList = newData; },
  },
  getters: {
    getterAffiliationRewardList: (state) => state.affiliationRewardList,
    getterTotalAffiliationRewardList: (state) => state.totalAffiliationRewardList,
    getterTotalsByPeriod: (state) => state.totalsByPeriod,
    getterTotalRewardList: (state) => state.totalRewardList,
    getterRewardList: (state) => state.rewardList,
  },
  actions: {
    UP_resetAffiliationRewardList({ commit }) {
      commit('SET_AFFILIATION_REWARD_LIST', {});
      commit('SET_TOTAL_AFFILIATION_REWARD_LIST', null);
      // commit('SET_TOTALS_BY_PERIOD', {});
    },

    reportReward({ commit }, { payload, httpQuery }) {
      return clark.api.apiAffiliation.affiliationReward.reportReward({ payload, httpQuery })
        .then((rewards) => {
          commit('SET_REWARD_LIST', rewards.results.arrayMapper('arid'));
          commit('SET_REWARD_TOTAL', rewards.total);
        });
    },

    report({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.affiliationReward.report({ payload, httpQuery })
        .then(({ total, results }) => {
          commit('SET_AFFILIATION_REWARD_LIST', results.arrayMapper('arid'));
          commit('SET_TOTAL_AFFILIATION_REWARD_LIST', total);
          return { total, results };
        });
    },

    rebuildBalances({ commit, getters }, { payload, httpQuery }) {
      return clark.api.apiAffiliation.affiliationReward.rebuildBalances({ payload, httpQuery })
        .then((rewardRebuildeds) => {
          const { getterRewardList } = getters;
          const rewards = Object.values({ ...getterRewardList }).filter((r) => r.arid !== payload.arid);

          const rewardMapped = rewards.arrayMapper('arid');
          if (rewardRebuildeds.length > 0) {
            commit('SET_REWARD_LIST', {
              ...rewardMapped,
              ...rewardRebuildeds.reduce((acc, rebuilded) => ({
                ...acc,
                [rebuilded.arid]: {
                  ...rebuilded,
                  contactPlatform: getters.getterRewardList[payload.arid]?.contactPlatform,
                  channelGroup: getters.getterRewardList[payload.arid]?.channelGroup,
                },
              }), {}),
            });
          } else {
            commit('SET_REWARD_LIST', {
              ...rewardMapped,
            });
          }
        });
    },

    getTotalCommissionByPeriod({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.affiliationReward.totalCommissionByPeriod({ payload, httpQuery })
        .then((totals) => {
          commit('SET_TOTALS_BY_PERIOD', { ...getters.getterTotalsByPeriod, [totals.period]: totals });
          return totals;
        });
    },

    setReviews({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.affiliationReward.setReviews({ payload, httpQuery })
        .then((affiliationRewardList) => {
          if (!affiliationRewardList.length) return undefined;

          const newRewardList = affiliationRewardList.arrayMapper('arid');

          const { getterAffiliationRewardList } = getters;

          const list = Object.values(getterAffiliationRewardList).reduce((acc, row) => {
            if (!newRewardList[row.arid]) return { ...acc, [row.arid]: row };

            return { ...acc, [row.arid]: { ...row, ...newRewardList[row.arid] } };
          }, {});

          commit('SET_AFFILIATION_REWARD_LIST', list);

          return newRewardList;
        });
    },

    markAsPaid({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.affiliationReward.markAsPaid({ payload, httpQuery })
        .then((affiliationReward) => {
          const { getterAffiliationRewardList } = getters;

          commit('SET_AFFILIATION_REWARD_LIST', { ...getterAffiliationRewardList, [payload.arid]: { ...getterAffiliationRewardList[payload.arid], ...affiliationReward } });

          return affiliationReward;
        });
    },
  },
};
