/* eslint-disable max-len */
import { getters as _getters, actions } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    selectedList: null,

    activeTab: {
      tab: 'root',
      title: 'Manage your lists',
      subtext: 'Lists',
    },
  },
  mutations: {
    SET_SELECTED_LIST(state, newData) { state.selectedList = newData; },
    SET_ACTIVE_TAB(state, newData) { state.activeTab = newData; },

    RESET_STORE(state) {
      state.selectedList = null;

      state.activeTab = {
        tab: 'root',
        title: 'Manage your lists',
        subtext: 'Lists',
      };
    },
  },
  getters: {
    ..._getters.account,

    selectedList: (state) => state.selectedList,
    activeTab: (state) => state.activeTab,

    tabItems: (state, getters) => ({
      root: {
        tab: 'root',
        title: 'Manage your lists',
        subtext: 'Lists',
      },
      'root.createList': {
        tab: 'root.createList',
        title: 'Create list',
        subtext: 'Lists',
      },
      'root.editList': {
        tab: 'root.editList',
        title: 'Edit your list',
        subtext: `Lists | ${getters.selectedList ? getters.selectedList.name : ''}`,
      },
    }),
  },
  actions: {
    ...actions.segment,

    UP_selectedList({ commit }, newData) { commit('SET_SELECTED_LIST', newData); },

    UP_activeTab({ commit }, newData) { commit('SET_ACTIVE_TAB', newData); },

    UP_changeToNewTab({ commit }, newData) {
      commit('SET_ACTIVE_TAB', newData);
    },
    UP_resetStore({ commit }) { commit('RESET_STORE'); },

    goToPreviousTab({ commit, getters }) {
      const { activeTab, tabItems } = getters;
      if (activeTab.tab === 'root') return;
      const tabToGo = activeTab.tab.split('.').slice(0, -1).join('.');
      commit('SET_ACTIVE_TAB', tabItems[tabToGo]);
    },

    goToTabByTabPath({ commit, getters }, tabToGo) {
      const { tabItems } = getters;
      commit('SET_ACTIVE_TAB', tabItems[tabToGo]);
    },
  },
};
