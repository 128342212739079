<template>
  <div id="TheAuthenticateLogin">
    <div class="mt-5">
      <h1 class="title is-1">
        Welcome back!
      </h1>

      <br>

      <form @keyup.enter="connectUser({ ...newAuthentication, method: 'UBU' })">
        <UbuAuthenticateLogInForm v-model="newAuthentication" />

        <br>

        <b-button
          type="is-primary"
          expanded
          label="Login"
          @click="connectUser({ ...newAuthentication, method: 'UBU' })"
        />

        <br>

        <div class="multibar row-vcentered ycentered">
          <span class="text is-3 has-text-secondary mr-1">Having trouble logging in?</span>
          <b-button
            type="is-ghost"
            class="no-padding-horizontal"
            label="Contact support"
            @click="openIntercom()"
          />
        </div>
        <div class="multibar row-vcentered ycentered">
          <span class="text is-3 has-text-secondary mr-1">Don’t have an account?</span>
          <b-button
            type="is-ghost"
            class="no-padding-horizontal"
            label="Request demo"
            tag="a"
            href="https://www.getubu.com/request-access"
            target="_blank"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { snackbarError } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheAuthenticateLogin',
  data() {
    return {
      newAuthentication: {
        passName: '',
        passPhrase: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheAuthenticate/myAuthenticate',
    }),
  },
  mounted() {
    this.reconnectUser()
      .then(() => {
        this.$buefy.snackbar.open({
          type: 'is-success',
          position: 'is-bottom-right',
          message: `Welcome back ${this.myAuthenticate.userDetail.firstName} !`,
        });
        this.$router.push('/home');
      });
  },
  methods: {
    ...mapActions({
      logIn: 'TheAuthenticate/logIn',
      reconnectUser: 'TheAuthenticate/reconnect',
      disconnectUser: 'TheAuthenticate/logOut',
    }),
    isRouteActive(route) {
      return route === this.$route.path;
    },

    connectUser(payload) {
      return this.logIn({ payload })
        .then(() => {
          this.$buefy.snackbar.open({
            type: 'is-success',
            position: 'is-bottom-right',
            message: `Welcome back ${this.myAuthenticate.userDetail.firstName} !`,
          });
          this.$router.replace('/home');
        })
        .catch((error) => {
          const snackar = {
            message: 'Invalid login. Please try again !',
            ...snackbarError,
            duration: 3000,
          };
          console.log('🚀 ~ file: TheAuthenticateLogin.vue ~ line 90 ~ connectUser ~ snackar', snackar);

          switch (error.message) {
            case 'E_NOT_FOUND_AUTHENTICATE_NO_AUTHS_FOR_PASSNAME_AND_METHOD': snackar.message = 'Email or password is incorrect...'; break;
            case 'E_BAD_REQUEST_AUTHENTICATE_USER_AUTH_PASSPHRASE_DONT_MATCH_PASSWORD': snackar.message = 'Email or password is incorrect...'; break;
            case 'E_UNPROCESSABLE_AUTHENTICATE_USER_IS_LOCKED': snackar.message = 'Unable to connect. Your authenticate is locked'; break;
            default: snackar.message = 'Invalid login. Please try again !'; break;
          }
          this.$buefy.snackbar.open(snackar);
        });
    },
    openIntercom() {
      this.$intercom.showNewMessage('Hello, I’m having trouble logging into my Ubu account 😢');
    },
  },
};
</script>
