var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('DevDetails',{attrs:{"cnt":{name: 'localModel', obj: _vm.localModel}}}),_c('b-collapse',{staticClass:"ubuSwitchGroup",class:[{
      'is-not-active': !_vm.localModel.channelGroup.isActive && _vm.preview,
      'preview': _vm.preview,
    }],attrs:{"animation":"slide"}},[[_c('div',{staticClass:"trigger multibar"},[(!_vm.localModel.channelGroup.immutable)?_c('b-switch',{staticClass:"start",attrs:{"size":"is-small","disabled":_vm.readOnly},on:{"input":function($event){_vm.reactive ? _vm.emitTurnOn({ action: 'onChannelGroup', state: $event }) : _vm.turnOffAllChannel($event)}},model:{value:(_vm.localModel.channelGroup.isActive),callback:function ($$v) {_vm.$set(_vm.localModel.channelGroup, "isActive", $$v)},expression:"localModel.channelGroup.isActive"}},[_c('UbuInfoSelector',{attrs:{"picture":_vm.localModel.channelGroup.picture,"name":_vm.localModel.channelGroup.name,"initials":_vm.localModel.channelGroup.initials}})],1):_c('UbuInfoSelector',{staticClass:"start",attrs:{"picture":_vm.localModel.channelGroup.picture,"name":_vm.localModel.channelGroup.name,"initials":_vm.localModel.channelGroup.initials}}),_c('b-button',{staticClass:"start",attrs:{"type":"is-ghost","icon-pack":"ubu","icon-left":_vm.isOpen ? 'ubu-arrow-down' : 'ubu-arrow-up'},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}})],1)],(_vm.isOpen)?_c('div',{staticClass:"switchGroup-content"},[(Object.values(_vm.localModel.channelList).length)?_vm._l((_vm.localModel.channelList),function(channel){return _c('div',{key:channel.cid,class:['switchGroup-content-group', {
            'is-not-active': !_vm.localModel.channelList[channel.cid].isActive && _vm.preview
          }]},[_c('div',{staticClass:"switchGroup-content-separator"}),_c('b-field',{staticClass:"switchGroup-content-items"},[(!_vm.localModel.channelList[channel.cid].immutable)?_c('b-switch',{attrs:{"size":"is-small","disabled":_vm.readOnly},on:{"input":function($event){_vm.reactive
                ? _vm.emitTurnOn({ action: 'onChannel', state: $event, cid: channel.cid })
                : _vm.turnOnChannelAndChannelGroup(channel.cid, $event)}},model:{value:(_vm.localModel.channelList[channel.cid].isActive),callback:function ($$v) {_vm.$set(_vm.localModel.channelList[channel.cid], "isActive", $$v)},expression:"localModel.channelList[channel.cid].isActive"}},[_c('UbuInfoSelector',{attrs:{"type":"channel","name":_vm.localModel.channelGroup.name,"platform-name":channel.platformName}})],1):_c('UbuInfoSelector',{attrs:{"type":"channel","name":_vm.localModel.channelGroup.name,"platform-name":channel.platformName}})],1)],1)}):[_c('span',{staticClass:"has-text-secondary text is-3",staticStyle:{"margin-left":"66px"}},[_vm._v(" This workspace doesn't have any channels connected. ")])],(_vm.findOneChannelActive)?[_c('span',{staticClass:"has-text-secondary text is-3",staticStyle:{"margin-left":"66px"}},[_vm._v(" No channel selected... ")])]:_vm._e()],2):_vm._e(),(_vm.isOpen && !_vm.localModel.channelGroup.immutable && !_vm.reactive)?_c('div',{staticClass:"multibar"},[_c('b-button',{staticClass:"start",attrs:{"type":"is-ghost","label":"Save changes","disabled":!_vm.hasChange},on:{"click":function($event){return _vm.$emit('updateGroup', _vm.newState)}}})],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }