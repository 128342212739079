<template>
  <section>
    <b-field
      expanded
      grouped
    >
      <UbuSearchbar
        v-model="localSearch"
        @clear="localSearch = ''"
      />

      <p class="control">
        <b-button
          label="Request new list"
          type="is-primary"
          :loading="isLoading"
          @click="$emit('request')"
        />
      </p>
    </b-field>

    <br>

    <div
      v-if="isLoading"
      class="media-content"
    >
      <div class="content">
        <p
          v-for="index in 10"
          :key="index"
          class="mb-4"
        >
          <b-skeleton height="50px" />
        </p>
      </div>
    </div>

    <UbuNoData
      v-else-if="!filteredProtoLists.length"
      title="No list for this account... Yet! 🤓"
      subtitle="Click “Request new list to scrap profiles from a new Instagram account."
      type="is-primary"
    />

    <template v-else>
      <div class="ubuAddPeopleAddFromProtoListWizard_protoListsList">
        <UbuActionCard
          v-for="protoList in currentProtoLists"
          :key="protoList.targetUsername"
          class="mb-4"
          :disabled="disabledProtoList(protoList)"
          expanded
          :label="protoList.targetUsername"
          :left-text="leftButtonText(protoList)"
          :display-left-text="true"
          @click="$emit('select', protoList)"
        />
      </div>

      <br>

      <b-pagination
        v-model="currentPage"
        :per-page="protoListsPerPage"
        :total="filteredProtoLists.length"
        :range-before="5"
        :range-after="5"
      />
    </template>
  </section>
</template>

<script>
export default {
  name: 'UbuAddPeopleProtoSelectList',
  model: {
    prop: 'search',
  },
  props: {
    search: {
      type: String,
      required: true,
    },
    protoLists: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      protoListsPerPage: 30,
    };
  },
  computed: {
    localSearch: {
      get() { return this.search; },
      set(val) { this.$emit('input', val); },
    },

    currentProtoLists() {
      const start = (this.currentPage - 1) * this.protoListsPerPage;
      const end = this.currentPage * this.protoListsPerPage;
      return this.filteredProtoLists.slice(start, end);
    },

    filteredProtoLists() {
      if (!this.localSearch) return this.protoLists;

      return this.protoLists.reduce((acc, protoList) => {
        const { targetUsername } = protoList;
        if (!targetUsername.includes(this.localSearch)) return acc;

        return [...acc, protoList];
      }, []);
    },
  },
  methods: {
    disabledProtoList(protoList) {
      return !protoList.updatedOn && protoList.resultUsernameCount < 1;
    },
    leftButtonText(protoList) {
      return protoList.resultUsernameCount > 0 ? `${protoList.resultUsernameCount} people` : 'awaiting';
    },
  },
};
</script>
