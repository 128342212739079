export default {
  data() {
    return {
      testItems: [
        {
          customId: '4517#MESSAGE',
          type: 'POST_SHARE',
          originalId: '4517',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: null,
          link: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=18051313792141551&signature=AbwjS1M2Bx3lWl_KND5vBmUt7qB8gDzfj9XZDujFOCuQXoHK-cAXTMqlZIHQebbMLMcDXkJ28odcLFMTpXeYTexN-1q30yeqV94jXi8iEwE--cpcdKFTuw5DAkQNPXZNc9dPav46AIYJjgnEwHqAg0MaxH6wUUbDd3YHSx6JzwxftwqM',
          medias: null,
        },
        {
          customId: '4513#MESSAGE',
          type: 'TEXT',
          originalId: '4513',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: 'Salut ça va ?',
          link: null,
          medias: null,
        },
        {
          customId: '4520#MESSAGE',
          type: 'STORY_REACT',
          originalId: '4520',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: '🔥',
          link: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=17910035927309805&signature=AbyKwGWeKagbkqARq9BdokunT_QiNcDjpaJ0Lz_PfCr8ZSsIgDjSKhdl4diiKNUNigbhkpRVI4ta2O-X1itfz9uwTfoWTKnUunTYAQ9Sm54ZcYc-mzMLOHYncvNpTPRD_C8LaP-DBa9wr4h_E3yo_WDG0Vi_5VXbInWsjfLSTA0b3Yjg',
          medias: null,
        },
        {
          customId: '4519#MESSAGE',
          type: 'STORY_REPLY',
          originalId: '4519',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: 'réponse à la story',
          link: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=18001250989403663&signature=AbwinN2WTE-40zfXl_D8MVTXEL-IK-qyxoxIIcwWp0wXx5C5FM7-rpapZ5cevo1IJipIAA4FM1tQIvBK4vSuvIk_t3YS7CMGfDcDbXvEuxBI54EUDIN_LWVHvrRtykiB9egn7IqS9-b1rHst5t4haJJDSRbio9NQnnRaZ-ZUMxTANQ6f',
          medias: null,
        },
        {
          customId: '4518#MESSAGE',
          type: 'STORY_MENTION',
          originalId: '4518',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-11T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: null,
          link: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=17956332244708594&signature=AbwlJZ-m79XhDjvTfWY4NxtxXt3hXeX07Lyn_5gVLLRVGHzVsTXTpLDzVsFXLTEo6U14LNxFqWi5iE20UVC2NLLFM1YTYPCgdB0r7k2Mc0SStpTWF8EztzVOKLK4pWl4TxcpJOJcgBg-wP_U0-eMo9vv0zgh5sL4PVIv16dSPTvojDVk',
          medias: null,
        },
        {
          customId: '4516#MESSAGE',
          type: 'MEDIA_AUDIO',
          originalId: '4516',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: null,
          link: null,
          medias: [
            {
              mid: 361,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=17930210729009847&signature=Aby7hbZrBJXczgTcflpAeyWfm4v91Jiz505i1E5Q-u_irlWDMxBb3x6wq2frMetsi10nfUiIJZcileRKEepu0IL9Jwrt_IUJxzYOCPibeJgFo3DJF9DZ4kzTcHWFN_WaBI44-PnHRoU2AH3FsiOW1MDB1_6Xd5sb6wuHXLcP-by95Ukt',
              type: 'AUDIO',
              hasBucket: false,
            },
          ],
        },
        {
          customId: '4515#MESSAGE',
          type: 'MEDIA_VIDEO',
          originalId: '4515',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: null,
          link: null,
          medias: [
            {
              mid: 360,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=17964816523530095&signature=Abzgj3SbnC1iJ0nAtrO8jmxjjd87XgvugWGJdTWA2Kh3JxHVlCnjgDqWd8i59QYMWraS6nh_r5iDz6AIb-faBZ2PhHA1ttgPIfe7gVR0D_kwaIYVXvxL-547ipFZXHQHzcjQGo27IHWVZL9egRvyrwlVlC6qVoF8koTM7HqplPydX7WA',
              type: 'VIDEO',
              hasBucket: false,
            },
          ],
        },
        {
          customId: '4514#MESSAGE',
          type: 'MEDIA_IMAGE',
          originalId: '4514',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: null,
          link: null,
          medias: [
            {
              mid: 359,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=18001250989403663&signature=AbwinN2WTE-40zfXl_D8MVTXEL-IK-qyxoxIIcwWp0wXx5C5FM7-rpapZ5cevo1IJipIAA4FM1tQIvBK4vSuvIk_t3YS7CMGfDcDbXvEuxBI54EUDIN_LWVHvrRtykiB9egn7IqS9-b1rHst5t4haJJDSRbio9NQnnRaZ-ZUMxTANQ6f',
              type: 'IMAGE',
              hasBucket: false,
            },
          ],
        },
        {
          customId: '4513#MESSAGE',
          type: 'MEDIA_IMAGE',
          originalId: '4514',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: null,
          link: null,
          medias: [
            {
              mid: 361,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=17896613204535838&signature=Abx69YrKPXvnmjlecsUndtBkfuRLF8uHe9tsN0LrfJn8EmJBIWj7VhgWBWxyMKNSdyqkqICk6fj8VGloQti0OIsZ8KEzw56ZwNsp-klB-DLgRA6IXUmMagtu7RCnnkKqabvGE53gDvhPd2qY6rXqbBb3ITHPadt0jvNV2QXFex5jZJo_',
              type: 'IMAGE',
              hasBucket: false,
            },
          ],
        },
        {
          customId: '4513#MESSAGE',
          type: 'MEDIA_IMAGE',
          originalId: '4514',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: null,
          link: null,
          medias: [
            {
              mid: 361,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=17896613204535838&signature=Abx69YrKPXvnmjlecsUndtBkfuRLF8uHe9tsN0LrfJn8EmJBIWj7VhgWBWxyMKNSdyqkqICk6fj8VGloQti0OIsZ8KEzw56ZwNsp-klB-DLgRA6IXUmMagtu7RCnnkKqabvGE53gDvhPd2qY6rXqbBb3ITHPadt0jvNV2QXFex5jZJo_',
              type: 'IMAGE',
              hasBucket: false,
            },
            {
              mid: 362,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=17896613204535838&signature=Abx69YrKPXvnmjlecsUndtBkfuRLF8uHe9tsN0LrfJn8EmJBIWj7VhgWBWxyMKNSdyqkqICk6fj8VGloQti0OIsZ8KEzw56ZwNsp-klB-DLgRA6IXUmMagtu7RCnnkKqabvGE53gDvhPd2qY6rXqbBb3ITHPadt0jvNV2QXFex5jZJo_',
              type: 'IMAGE',
              hasBucket: false,
            },
            {
              mid: 363,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=17896613204535838&signature=Abx69YrKPXvnmjlecsUndtBkfuRLF8uHe9tsN0LrfJn8EmJBIWj7VhgWBWxyMKNSdyqkqICk6fj8VGloQti0OIsZ8KEzw56ZwNsp-klB-DLgRA6IXUmMagtu7RCnnkKqabvGE53gDvhPd2qY6rXqbBb3ITHPadt0jvNV2QXFex5jZJo_',
              type: 'IMAGE',
              hasBucket: false,
            },
          ],
        },
        {
          customId: '4513#MESSAGE',
          type: 'MEDIA_VIDEO',
          originalId: '4514',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: null,
          link: null,
          medias: [
            {
              mid: 361,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=18051313792141551&signature=AbwjS1M2Bx3lWl_KND5vBmUt7qB8gDzfj9XZDujFOCuQXoHK-cAXTMqlZIHQebbMLMcDXkJ28odcLFMTpXeYTexN-1q30yeqV94jXi8iEwE--cpcdKFTuw5DAkQNPXZNc9dPav46AIYJjgnEwHqAg0MaxH6wUUbDd3YHSx6JzwxftwqM',
              type: 'VIDEO',
              hasBucket: false,
            },
            {
              mid: 362,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=18051313792141551&signature=AbwjS1M2Bx3lWl_KND5vBmUt7qB8gDzfj9XZDujFOCuQXoHK-cAXTMqlZIHQebbMLMcDXkJ28odcLFMTpXeYTexN-1q30yeqV94jXi8iEwE--cpcdKFTuw5DAkQNPXZNc9dPav46AIYJjgnEwHqAg0MaxH6wUUbDd3YHSx6JzwxftwqM',
              type: 'VIDEO',
              hasBucket: false,
            },
            {
              mid: 363,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=18051313792141551&signature=AbwjS1M2Bx3lWl_KND5vBmUt7qB8gDzfj9XZDujFOCuQXoHK-cAXTMqlZIHQebbMLMcDXkJ28odcLFMTpXeYTexN-1q30yeqV94jXi8iEwE--cpcdKFTuw5DAkQNPXZNc9dPav46AIYJjgnEwHqAg0MaxH6wUUbDd3YHSx6JzwxftwqM',
              type: 'VIDEO',
              hasBucket: false,
            },
          ],
        },
        {
          customId: '4512#MESSAGE',
          type: 'MEDIA_VIDEO',
          originalId: '4514',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: null,
          link: null,
          medias: [
            {
              mid: 360,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=17918512316228237&signature=AbzgciIzy0992qVdgfH6ifBi7Fi5mB_v1sntQFBGN_6HMUmsPxUgrpAi71QcrFqJt0yEAOu89lNU4VnOIzkez8gRruabbOGlVaTzo4Gat-xuy44I0N3tHiQ14Xvrwxa9yVan0SuhGsvw0urwGoHpBdgNX7CBfaBXF6qzQqTIuU2mZa9u',
              type: 'VIDEO',
              hasBucket: false,
            },
          ],
        },
        {
          customId: '4512#MESSAGE',
          type: 'STORY_SHARE',
          originalId: '4514',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: null,
          link: 'https://instagram.com/stories/cindyguitars/2784555049803870441?utm_source=ig_story_item_share&utm_medium=share_sheet',
          medias: null,
        },
        {
          customId: '4512#MESSAGE',
          type: 'UNSUPPORTED',
          originalId: '4514',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: null,
          link: null,
          medias: null,
        },
        {
          customId: '4583#MESSAGE',
          type: 'MEDIA_IMAGE',
          originalId: '4594',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: null,
          link: null,
          medias: [
            {
              mid: 361,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=18051313792141551&signature=AbwjS1M2Bx3lWl_KND5vBmUt7qB8gDzfj9XZDujFOCuQXoHK-cAXTMqlZIHQebbMLMcDXkJ28odcLFMTpXeYTexN-1q30yeqV94jXi8iEwE--cpcdKFTuw5DAkQNPXZNc9dPav46AIYJjgnEwHqAg0MaxH6wUUbDd3YHSx6JzwxftwqM',
              type: 'VIDEO',
              hasBucket: false,
            },
            {
              mid: 362,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=17896613204535838&signature=Abx69YrKPXvnmjlecsUndtBkfuRLF8uHe9tsN0LrfJn8EmJBIWj7VhgWBWxyMKNSdyqkqICk6fj8VGloQti0OIsZ8KEzw56ZwNsp-klB-DLgRA6IXUmMagtu7RCnnkKqabvGE53gDvhPd2qY6rXqbBb3ITHPadt0jvNV2QXFex5jZJo_',
              type: 'IMAGE',
              hasBucket: false,
            },
          ],
        },
        {
          customId: '4593#MESSAGE',
          type: 'MEDIA_VIDEO',
          originalId: '4594',
          event: null,
          channelGroupCgid: 96,
          contactCid: '144',
          contactPlatformCpid: '1369',
          isMe: false,
          userUid: null,
          itemDate: '2022-04-08T17:18:51.000Z',
          accountAid: null,
          channelCid: 96,
          threadTid: '1229',
          campaignCid: null,
          activityAid: null,
          contactGroupCgid: null,
          threadGroupTgid: null,
          shopifyShopSsid: null,
          referralCodeRcid: null,
          discountCodeDcid: null,
          contactPrivateMetadataCpmid: null,
          crmListClid: null,
          mentionMid: null,
          text: null,
          link: null,
          medias: [
            {
              mid: 361,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=18051313792141551&signature=AbwjS1M2Bx3lWl_KND5vBmUt7qB8gDzfj9XZDujFOCuQXoHK-cAXTMqlZIHQebbMLMcDXkJ28odcLFMTpXeYTexN-1q30yeqV94jXi8iEwE--cpcdKFTuw5DAkQNPXZNc9dPav46AIYJjgnEwHqAg0MaxH6wUUbDd3YHSx6JzwxftwqM',
              type: 'VIDEO',
              hasBucket: false,
            },
            {
              mid: 362,
              url: 'https://lookaside.fbsbx.com/ig_messaging_cdn/?asset_id=17896613204535838&signature=Abx69YrKPXvnmjlecsUndtBkfuRLF8uHe9tsN0LrfJn8EmJBIWj7VhgWBWxyMKNSdyqkqICk6fj8VGloQti0OIsZ8KEzw56ZwNsp-klB-DLgRA6IXUmMagtu7RCnnkKqabvGE53gDvhPd2qY6rXqbBb3ITHPadt0jvNV2QXFex5jZJo_',
              type: 'IMAGE',
              hasBucket: false,
            },
          ],
        },
      ],
    };
  },
};
