const analyticsCatalog = {
  final_followers: {
    name: 'Total followers',
    type: 'account',
    icon: 'ubu-account',
    color: 12,
    subtype: [
      { ordinal: 1, type: 'overview', size: 25 },
      { ordinal: 1, type: 'followers', size: 33.33 },
    ],
  },
  mean_engagement_total: {
    name: 'Engagement rate',
    type: 'account',
    icon: 'ubu-love',
    color: 9,
    symbol: '%',
    subtype: [
      { ordinal: 2, type: 'overview', size: 25 },
      { ordinal: 1, type: 'engagement', size: 50 },
    ],
  },
  reach: {
    name: 'Total reach',
    type: 'account',
    icon: 'ubu-eye',
    color: 4,
    subtype: [
      { ordinal: 3, type: 'overview', size: 25 },
      { ordinal: 2, type: 'visibility', size: 33 },
    ],
  },
  website_clicks: {
    name: 'Website clicks',
    type: 'account',
    icon: 'ubu-influence',
    color: 8,
    subtype: [
      { ordinal: 3, type: 'overview', size: 25 },
      { ordinal: 2, type: 'callToAction', size: 20 },
    ],
  },
  followers_difference: {
    name: 'Followers evolution',
    type: 'account',
    icon: 'ubu-account',
    color: 12,
    subtype: [
      { ordinal: 2, type: 'followers', size: 33.33 },
    ],
  },
  new_followers: {
    name: 'New followers',
    type: 'account',
    icon: 'ubu-account',
    color: 12,
    subtype: [
      { ordinal: 2, type: 'followers', size: 33.33 },
    ],
  },
  mean_engagement: {
    name: 'Engagement rate for choosen period',
    type: 'account',
    icon: 'ubu-love',
    color: 9,
    symbol: '%',
    subtype: [
      { ordinal: 2, type: 'engagement', size: 50 },
    ],
  },
  like_count: {
    name: 'Likes',
    type: 'account',
    icon: 'ubu-love',
    color: 9,
    subtype: [
      { ordinal: 3, type: 'engagement', size: 25 },
    ],
  },
  likesPerPostAverage: {
    name: 'Avg likes per post',
    type: 'account',
    icon: 'ubu-love',
    color: 9,
    subtype: [
      { ordinal: 4, type: 'engagement', size: 25 },
    ],
  },
  comments_count: {
    name: 'Comments',
    type: 'account',
    icon: 'ubu-love',
    color: 9,
    subtype: [
      { ordinal: 5, type: 'engagement', size: 25 },
    ],
  },
  commentsPerPostAverage: {
    name: 'Avg comments per post',
    type: 'account',
    icon: 'ubu-love',
    color: 9,
    subtype: [
      { ordinal: 6, type: 'engagement', size: 25 },
    ],
  },
  impressions: {
    name: 'Total prints',
    type: 'account',
    icon: 'ubu-eye',
    color: 4,
    subtype: [
      { ordinal: 1, type: 'visibility', size: 33 },
    ],
  },
  profile_views: {
    name: 'Profile views',
    type: 'account',
    icon: 'ubu-eye',
    color: 4,
    subtype: [
      { ordinal: 3, type: 'visibility', size: 33 },
    ],
  },
  postsReach: {
    name: 'Reach generated by your posts',
    type: 'account',
    icon: 'ubu-eye',
    color: 4,
    subtype: [
      { ordinal: 4, type: 'visibility', size: 25 },
    ],
  },
  storiesReach: {
    name: 'Reach generated by your stories',
    type: 'account',
    icon: 'ubu-eye',
    color: 4,
    subtype: [
      { ordinal: 4, type: 'visibility', size: 25 },
    ],
  },
  reachByPostAverage: {
    name: 'Avg reach per post',
    type: 'account',
    icon: 'ubu-eye',
    color: 4,
    subtype: [
      { ordinal: 5, type: 'visibility', size: 25 },
    ],
  },
  reachByStoryAverage: {
    name: 'Avg reach per story',
    type: 'account',
    icon: 'ubu-eye',
    color: 4,
    subtype: [
      { ordinal: 6, type: 'visibility', size: 25 },
    ],
  },
  postsPrint: {
    name: 'Print generated by your posts',
    type: 'account',
    icon: 'ubu-eye',
    color: 4,
    subtype: [
      { ordinal: 5, type: 'visibility', size: 25 },
    ],
  },
  storiesPrint: {
    name: 'Print generated by your stories',
    type: 'account',
    icon: 'ubu-eye',
    color: 4,
    subtype: [
      { ordinal: 4, type: 'visibility', size: 25 },
    ],
  },
  printByPostAverage: {
    name: 'Avg print per post',
    type: 'account',
    icon: 'ubu-eye',
    color: 4,
    subtype: [
      { ordinal: 5, type: 'visibility', size: 25 },
    ],
  },
  printByStoryAverage: {
    name: 'Avg print per story',
    type: 'account',
    icon: 'ubu-eye',
    color: 4,
    subtype: [
      { ordinal: 6, type: 'visibility', size: 25 },
    ],
  },
  get_directions_clicks: {
    name: 'Get direction clicks',
    type: 'account',
    icon: 'ubu-influence',
    color: 8,
    subtype: [
      { ordinal: 2, type: 'callToAction', size: 20 },
    ],
  },
  email_contacts: {
    name: 'Email clicks',
    type: 'account',
    icon: 'ubu-influence',
    color: 8,
    subtype: [
      { ordinal: 3, type: 'callToAction', size: 20 },
    ],
  },
  phone_call_clicks: {
    name: 'Phone call clicks',
    type: 'account',
    icon: 'ubu-influence',
    color: 8,
    subtype: [
      { ordinal: 4, type: 'callToAction', size: 20 },
    ],
  },
  text_message_clicks: {
    name: 'Text message clicks',
    type: 'account',
    icon: 'ubu-influence',
    color: 8,
    subtype: [
      { ordinal: 5, type: 'callToAction', size: 20 },
    ],
  },
};

export {
  // eslint-disable-next-line import/prefer-default-export
  analyticsCatalog,
};
