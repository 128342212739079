<template>
  <fieldset>
    <b-field
      v-for="({field, label, isRequired, type, placeholder}, index) in automatedField"
      :key="index"
      :label="label"
      :type="{ 'is-danger': typeof lead[field] === 'string' && lead[field].length < 1 }"
      :message="{
        'This field must be reset if you don\'t want to fullified it':
          typeof lead[field] === 'string' && lead[field].length < 1
      }"
      horizontal
    >
      <b-field>
        <b-input
          v-model="lead[field]"
          :required="isRequired"
          :type="type"
          expanded
          :placeholder="placeholder"
          @input="$update(field, $event)"
          @blur="$update(field, lead[field] ? lead[field].trim() : lead[field])"
        />
        <p class="control">
          <b-button
            type="is-danger is-outlined"
            icon-left="delete"
            label="reset"
            @click="$update(field, null)"
          />
        </p>
      </b-field>
    </b-field>
    <b-field
      label="isRisky"
      horizontal
    >
      <b-switch
        v-model="lead.isRisky"
        @input="$update('isRisky',$event)"
      />
    </b-field>
  </fieldset>
</template>

<script>
/* eslint-disable object-curly-newline */
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuLeadForm',
  mixins: [VModelMixin],
  model: {
    prop: 'lead',
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      automatedField: [
        { field: 'linkedInUrl', label: 'url', placeholder: 'https://www.linkedin.com/in/paul-albou-a43b21b1/', type: 'url', isRequired: false },
        { field: 'firstName', label: 'first name', placeholder: 'Paul', type: 'string', isRequired: false },
        { field: 'lastName', label: 'last name', placeholder: 'Albou', type: 'string', isRequired: false },
        { field: 'position', label: 'position', placeholder: 'CEO', type: 'string', isRequired: false },
        { field: 'email', label: 'email', placeholder: 'paul@getubu.com', type: 'email', isRequired: false },
        { field: 'phone', label: 'phone', placeholder: '+336XXXXXXXXX', type: 'string', isRequired: false },
      ],
    };
  },
};
</script>

<style>

</style>
