<template>
  <section v-if="checkedRows.length">
    <UbuDownloadMentionsForm
      v-model="email"
      :message="message"
      :mentions-length="mentionsToDownload.length"
      @download="downloadAll()"
      @setupIntercom="setupIntercom()"
      @closePanel="$emit('closePanel')"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters } from 'vuex';
import downloadMentionsMixins from '@dailyplanet/cross-addons/crossCommon/$mixins/downloadMentions.mixins';

export default {
  name: 'TheBulkActionsDownloadMentions',
  mixins: [downloadMentionsMixins],
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    // closeBulk: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {
      email: '',
      message: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'TheAuthenticate/myAuthenticate',
    }),
    localChecked: {
      get() { return this.checkedRows; },
      set(value) { this.$emit('input', value); },
    },
    // localActiveTab: {
    //   get() { return this.activeTab; },
    //   set(val) { this.$emit('update:activeTab', val); },
    // },
    currentUserEmail() {
      return this.currentUser.passName;
    },
    mentionsToDownload() {
      return this.checkedRows.reduce((acc, media) => {
        if (media.isHashtag) {
          acc.push({ mainMedia: { extrefId: media.id } });

          return acc;
        }

        if (media.campaignCid !== this.currentCampaignCid) return acc;

        acc.push(...media.mentions);

        return acc;
      }, []);
    },
  },
  mounted() {
    this.email = this.currentUserEmail;
  },
  methods: {
    setupIntercom() {
      this.$intercom.showNewMessage('Hello ! I need to download all my mention but there are more than 1000... 🙌');
    },
    downloadAll() {
      Promise.resolve()
        .then(() => this.downloadMentions(this.mentionsToDownload, this.email))
        .then(() => this.$emit('done'));
    },
  },
};
</script>
