<template>
  <TheSimplePanel
    closing-way="emit"
    @close="$emit('close')"
  >
    <template #header>
      <h1 class="title is-1">Bulk action</h1>
    </template>
    <template #content>
      <div class="buttons">
        <b-button
          type="is-success "
          class="ubuActionCard mb-4 button is-fullwidth"
          @click="$emit('confirmCrewUserState', 'VERIFIED'); closePanel()"
        >
          <span>UnBlock</span>
        </b-button>
        <br>
        <b-button
          type="is-danger "
          class="ubuActionCard mb-4 button is-fullwidth"
          @click="$emit('confirmCrewUserState', 'BLOCKED'); closePanel()"
        >
          <span>Block</span>
        </b-button>
      </div>
    </template>
  </TheSimplePanel>
</template>

<script>
export default {
  name: 'TheCrewAdminBulk',
  model: {
  },
  props: {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    closePanel() {
      this.$emit('close');
    },
  },
};
</script>
