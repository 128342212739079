export default {
  computed: {
    isMac() {
      return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    },
  },
  methods: {
    isCtrlPressed(event) {
      return this.isMac ? event.metaKey : event.ctrlKey;
    },
    isToBottomPressed(event) {
      return event.keyCode === 74 || event.keyCode === 38;
    },
    isToTopPressed(event) {
      return event.keyCode === 75 || event.keyCode === 40;
    },
    isEnterPressed(event) {
      return event.keyCode === 13;
    },
    isIPressed(event) {
      return event.keyCode === 73;
    },
    isEscapePressed(event) {
      return event.keyCode === 27;
    },
    isShiftPressed(event) {
      return event.shiftKey;
    },
    parseEvent(event) {
      if (this.isCtrlPressed(event) && event.keyCode == 67) window.document.execCommand('copy');
      if (this.isCtrlPressed(event) && event.keyCode == 86) window.document.execCommand('paste');
      if (this.isCtrlPressed(event) && event.keyCode == 88) window.document.execCommand('cut');

      return {
        ctrlIsPressed: this.isCtrlPressed(event),
        toBottomIsPressed: this.isToBottomPressed(event),
        toTopIsPressed: this.isToTopPressed(event),
        enterIsPressed: this.isEnterPressed(event),
        iIsPressed: this.isIPressed(event),
        escapeIsPressed: this.isEscapePressed(event),
        shiftIsPressed: this.isShiftPressed(event),
      };
    },
  },
};
