<template>
  <b-menu-item
    expanded
    :class="
      'popover popover-item-custom' +
        [disabled ? ' is-disabled' : ''] +
        [hovered ? ' is-hovered' : ''] +
        [focused ? ' is-focused' : '']
    "
    @click="$emit('getDatas', event)"
  >
    <template #label>
      <div class="is-flex is-align-items-center">
        <b-icon
          v-if="iconLeft"
          pack="ubu"
          :icon="iconLeft.icon"
          :class="
            'popover-icon-left' +
              [color && !disabled ? ` text-color-${color}` : ''] +
              [color && disabled ? ` text-color-${color}-40` : '']
          "
        />
        <span>
          {{ name }}
        </span>
      </div>
      <b-icon
        v-if="validated"
        pack="ubu"
        icon="ubu-check"
        :class="
          [color && !disabled ? ` text-color-${color}` : ''] +
            [color && disabled ? ` text-color-${color}-40` : '']
        "
      />
    </template>
  </b-menu-item>
</template>

<script>
export default {
  name: 'UbuPopoverItemCustom',
  props: {
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hovered: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: Object,
      default: () => {},
    },
    iconRight: {
      type: Object,
      default: () => {},
    },
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>
