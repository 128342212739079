import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    contactPrivateMetadataList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiContact/contactPrivateMetadata/getterContactPrivateMetadataList': contactPrivateMetadataList } = rootGetters;

      return contactPrivateMetadataList;
    },
    contactPrivateMetadata: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiContact/contactPrivateMetadata/getterContactPrivateMetadata': contactPrivateMetadata } = rootGetters;

      return contactPrivateMetadata;
    },
  },
  actions: {
    reportContactPrivateMetadataList({ dispatch, rootGetters }, { payload = {}, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPrivateMetadata/report',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Report contactPrivateMetadata list',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report contactPrivateMetadata list.', indefinite: true }));
    },

    getContactPrivateMetadata({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPrivateMetadata/get',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Get contactPrivateMetadata',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, null));
    },

    createContactPrivateMetadata({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPrivateMetadata/create',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Get contactPrivateMetadata',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to create contactPrivateMetadata.', indefinite: true }));
    },

    updateContactPrivateMetadata({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPrivateMetadata/update',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Get contactPrivateMetadata',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to update contactPrivateMetadata.', indefinite: true }));
    },

    deleteContactPrivateMetadata({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiContact/contactPrivateMetadata/delete',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Get contactPrivateMetadata',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to delete contactPrivateMetadata.', indefinite: true }));
    },
  },
};
