<template>
  <UbuCustomChip
    :model="{
      title: 'Tags',
      defaultIcon: 'ubu-custom-tag',
      count: cpo[customsName].length > 1 ? cpo[customsName].length : null,
      listItem: cpo[customsName].map((id) => ({
        icon: 'ubu-custom-tag',
        color: customs[id].color,
        label: customs[id].label,
      }))
    }"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheTagChip',
  props: {
    cpo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      customsName: 'tags',
    };
  },
  computed: {
    ...mapGetters({
      customs: 'TheTagPopover/threadGroupList',
    }),
  },
};
</script>
