const getUsername = (media, platform) => {
  if (platform === 'instagram') return media.username || media.user ? media.user.username : '';
  if (platform === 'tiktok') return media.author || '';

  return '';
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getUsername,
};
