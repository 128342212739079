/* eslint-disable max-len */
import { dispatchAndForward, genericErrorHandler, genericThenHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  actions: {
    bulkAddToCampaign({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiInfluence/bulkActivity/create',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid } },
        textNotifier: 'bulk add to campaign with send message',
      };

      return dispatchAndForward(action)
        .then(({ response }) => {
          dispatch('Segment/segmentTrack', { event: 'addToCampaign' }, { root: true });
          return response;
        })
        .catch(genericErrorHandler(action, { message: 'Unable to bulk add influencers to campaign.', indefinite: true }));
    },

    bulkChangeStatus({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiInfluence/bulkActivity/changeStatus',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid } },
        textNotifier: 'bulk change activity status',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .then(() => {
          let event = 'changeActivityStatus';
          if (payload.status === 'SUCCESS') event = 'changeActivityStatusToSuccess';

          return dispatch('Segment/segmentTrack', { event }, { root: true });
        })
        .catch(genericErrorHandler(action, { message: 'Unable to bulk change activity status.', indefinite: true }));
    },

    deleteBulkActivity({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiInfluence/bulkActivity/delete',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid, // DONT PUT THIS AFTER ...payload. MonitorTasks use it with its own cgid.
            ...payload,
          },
        },
        textNotifier: 'delete bulk activity',
      };
      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to bulk delete activity.', indefinite: true }));
    },
  },
};
