/* eslint-disable max-len */
import moment from 'moment';

export default {
  actions: {
    UP_updateCampaignActivity({ commit, getters }, { payload }) {
      const contactPlatformOverviewList = Object.values(getters.getterContactPlatformOverviewList).filter(({ cpid }) => payload.cpids.includes(cpid));

      commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
        ...getters.getterContactPlatformOverviewList,
        ...contactPlatformOverviewList.reduce((acc, row) => {
          acc[row.cpid] = row;
          acc[row.cpid].campaignActivity.status = payload.status;
          return acc;
        }, {}),
      });
    },

    UP_endCampaignActivity({ commit, getters }, { payload }) {
      const contactPlatformOverviewList = Object.values(getters.getterContactPlatformOverviewList).filter(({ cpid }) => payload.cpids.includes(cpid));

      commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
        ...getters.getterContactPlatformOverviewList,
        ...contactPlatformOverviewList.reduce((acc, row) => {
          acc[row.cpid] = row;
          acc[row.cpid].campaignActivity.endedOn = moment().format();
          return acc;
        }, {}),
      });
    },
  },
};
