<template>
  <b-dropdown
    :position="dropdownPosition"
    scrollable
    max-height="250px"
    @active-change="autoFocusSearch($event)"
  >
    <template #trigger>
      <b-tooltip
        label="Templates"
        position="is-top"
      >
        <b-button
          icon-left="ubu-template"
          icon-pack="ubu"
          type="is-tertiary-icon"
        />
      </b-tooltip>
    </template>
    <div class="textareaDropdownTemplates_list">
      <b-dropdown-item custom>
        <b-field expanded>
          <b-input
            ref="searchAutoFocus"
            v-model="templateInputSearch"
            icon-pack="ubu"
            icon="ubu-search"
            placeholder="Search..."
            icon-right="ubu-cross"
            icon-right-clickable
            expanded
            @icon-right-click="templateInputSearch = ''"
          />
        </b-field>
      </b-dropdown-item>
      <b-dropdown-item
        v-for="(template, index) in filteredTemplateList"
        :key="index"
        @click="$emit('insertText', template.text)"
      >
        <div class="ml-3">
          <span class="text-truncate text is-3">{{ template.title }}</span>
          <span class="text-truncate has-text-secondary-60 subtext is-3">
            {{ template.text }}
          </span>
        </div>
      </b-dropdown-item>
    </div>

    <b-dropdown-item>
      <b-button
        class="is-ghost no-padding is-borderless"
        label="Add template"
        icon-pack="ubu"
        icon-left="ubu-add"
        @click="$emit('openTemplateManager'); up_textImported(message)"
      />
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapActions } from 'vuex';
import TheTextareaTemplateButtonMixin from '../_mixins/TheTextareaTemplateButton.mixin';

export default {
  name: 'TheTextareaTemplateButton',
  mixins: [TheTextareaTemplateButtonMixin],
  model: {
    prop: 'message',
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    dropdownPosition: {
      type: String,
      default: 'is-bottom-left',
    },
  },
  methods: {
    ...mapActions({
      up_textImported: 'TheAddTemplatePanel/up_textImported',
    }),
  },
};
</script>
