import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheServersNavigation from './TheServersNavigation';
import TheServersState from './TheServersState';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheServersNavigation);
    registerComponent(Vue, TheServersState);
  },
};

use(Plugin);

export default Plugin;

export {
  TheServersNavigation,
  TheServersState,
};
