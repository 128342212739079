<template>
  <section class="ubuWizardView">
    <header>
      <div class="multibar arianne">
        <p class="text start is-3 has-text-secondary">Add member | Step 2/3</p>
      </div>

      <h1 class="title">Member's rights</h1>
    </header>

    <form @submit.prevent="$emit('next')">
      <fieldset>
        <b-field label="Role">
          <b-select
            v-model="localRole"
            required
            placeholder="Select a role"
          >
            <option value="member">Member</option>

            <option value="admin">Admin</option>
          </b-select>
        </b-field>

        <b-field label="Giving access to">
          <div
            v-for="(row) in editableItem"
            :key="row.channelGroup.cgid"
          >
            <UbuChannelGroupAndChannelSelector
              :model="JSON.stringify(row)"
              reactive
              @updateGroup="$emit('updateGroup', $event)"
            />
          </div>
        </b-field>

        <br>

        <div class="buttons is-right">
          <b-button
            class="is-primary"
            label="Next"
            native-type="submit"
          />
        </div>
      </fieldset>
    </form>
  </section>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuUserRightsForm',
  mixins: [VModelMixin],
  model: {
    prop: 'role',
  },
  props: {
    role: {
      type: String,
      required: true,
    },
    editableItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localRole: {
      get() { return this.role; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
};
</script>
