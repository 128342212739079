<template>
  <section
    v-if="account"
    class="ubuSettingView start"
  >
    <header class="start">
      <h2 class="subtitle is-1 has-text-secondary m-0">{{ account.owner.toUpperCase() }}</h2>
      <div class="multibar">
        <h1 class="start title is-1">Account channels</h1>
        <div class="buttons end">
          <b-button
            tag="router-link"
            :to="`/admin/account/${currentAid}/channels/addWorkspace`"
            label="Add workspace"
            type="is-primary"
          />
        </div>
      </div>
    </header>

    <br>

    <DevDetails :cnt="{name: 'account', obj: account}" />
    <DevDetails
      :cnt="{
        name: 'allChannelGroupAccount',
        obj: allChannelGroupAccount}"
    />
    <UbuViewChannelsAccount
      :all-channel-group-account="allChannelGroupAccount"
      :columns="columnsUbuOrganizationWorkspace"
    />

    <router-view name="addChannelSms" />
    <router-view name="detail" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TheAdminChannels from '../../$mixins/TheAdminChannels.columns.mixins';

export default {
  name: 'TheAdminAccountChannels',
  mixins: [TheAdminChannels],
  computed: {
    ...mapGetters({
      account: 'TheAdmin/account',
    }),
    currentAid() {
      return this.$route.params.aid;
    },
    allChannelGroupAccount() {
      if (!this.account
        || !Object.values(this.account).length
        || !this.account.channelGroupList) return {};

      const platforms = ['INSTAGRAM', 'FACEBOOK', 'TIKTOK', 'YOUTUBE', 'SMS'];
      const platformsCanAdd = ['SMS'];

      return this.account.channelGroupList.reduce((acc, channelGroup) => {
        if (!acc[channelGroup.cgid]) {
          acc[channelGroup.cgid] = {
            cgid: channelGroup.cgid,
            name: channelGroup.name,
            picture: channelGroup.picture,
            children: platforms.reduce((channels, platform) => {
              const hasPlatformInChildren = channelGroup.channelList
                .find((channel) => platform === channel.platformName);

              channels.push({
                cid: hasPlatformInChildren ? hasPlatformInChildren.cid : null,
                platformName: platform,
                isConnected: !!hasPlatformInChildren,
                name: hasPlatformInChildren && hasPlatformInChildren.contactPlatform
                  ? hasPlatformInChildren.contactPlatform.username
                  : null,
                addChannel: !!platformsCanAdd.includes(platform),
                cmp: `${platform.toLowerCase()}`,
                displayDetail: !!platformsCanAdd.includes(platform),
              });
              return channels;
            }, []),
          };
          return acc;
        }
        return acc;
      }, {});
    },
  },
  mounted() {
    return Promise.all([
      this.getAccount({
        payload: { aid: this.currentAid },
        httpQuery: { channelAndChannelGroupList: true },
      }),
    ]);
  },
  methods: {
    ...mapActions({
      getAccount: 'TheAdmin/getAccount',
    }),
  },
};
</script>
