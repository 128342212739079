<template>
  <article class="ubuContactPlatformPanelItem">
    <header class="multibar row-vcentered">
      <UbuAvatar
        class="start"
        :profile-picture="contactPlatformOverview.contactPlatform.profilePicture"
        :initial-name="contactPlatformOverview.contactPlatform.username[0]"
        :platform-name="contactPlatformOverview.contactPlatform.platformName"
        :size="40"
        :is-size="40"
      />
      <div class="start multibar column p-0 ml-2">
        <h2 class="title is-3 has-text-centered">
          <span class="noCutOnText">
            {{ contactPlatformOverview.contactPlatform.username }}
          </span>
          <b-button
            v-if="link"
            :href="link"
            tag="a"
            target="_blank"
            icon-left="ubu-link"
            icon-pack="ubu"
            type="is-tertiary-icon"
            class="clickable"
          />
        </h2>
        <p
          v-if="contactPlatformOverview.contact.publicEmail"
          class="text is-2 has-text-centered"
        >
          <span class="noCutOnText">
            {{ contactPlatformOverview.contact.publicEmail }}
          </span>
          <b-icon
            class="clickable"
            icon="ubu-copy"
            pack="ubu"
            @click.native="copyToClipBoard()"
          />
        </p>
      </div>

      <b-button
        id="ubuContactPlatformPanelCloseButtom"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
        @click="$emit('close')"
      />
    </header>
  </article>
</template>

<script>
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'UbuContactPlatformPanelHeader',
  model: {
    prop: 'contactPlatformOverview',
  },
  props: {
    contactPlatformOverview: {
      type: Object,
      required: true,
    },
  },
  computed: {
    link() {
      switch (this.contactPlatformOverview.contactPlatform.platformName) {
        case 'INSTAGRAM': return `https://www.instagram.com/${this.contactPlatformOverview.contactPlatform.username}`;
        case 'TIKTOK': return `https://www.tiktok.com/@${this.contactPlatformOverview.contactPlatform.username}`;
        default: return null;
      }
    },
  },
  methods: {
    copyToClipBoard() {
      navigator.clipboard.writeText(this.contactPlatformOverview.contact.publicEmail);
      this.$buefy.snackbar.open({
        message: 'Email copied to clipboard.',
        ...snackbarSuccess,
      });
    },
  },
};
</script>
