<template>
  <TheTabsPanel
    v-model="localActiveTab"
    @goToPreviousTab="goToPreviousTab($event)"
    @goToStart="goToTabByTabPath('root')"
    @close="closePanel()"
  >
    <template #content>
      <b-tab-item value="root">
        <div class="buttons">
          <UbuActionCard
            v-for="item in navigableItems"
            :key="item.label"
            class="mb-4"
            :label="item.label"
            icon-pack="ubu"
            :icon-left="item.icon"
            expanded
            :disabled="item.isDisabled"
            :left-text="item.isDisabled ? 'Coming soon' : ''"
            @click="handleNavigation(item)"
          />

          <UbuActionCard
            class="mb-4"
            label="Request list of people"
            icon-pack="ubu"
            icon-left="ubu-lists"
            expanded
            @click="redirectToTypeForm()"
          >
            <template #rightItem>
              <b-icon
                class="end"
                pack="ubu"
                icon="ubu-link"
              />
            </template>
          </UbuActionCard>
        </div>
      </b-tab-item>

      <TheAddPeopleManually
        :active-tab="activeTab"
        :tab-items="tabItems"
        @update:activeTab="UP_changeToNewTab($event)"
        @close="closePanel()"
      />

      <TheAddPeopleProto
        :active-tab="activeTab"
        :tab-items="tabItems"
        @update:activeTab="UP_changeToNewTab($event)"
        @close="closePanel()"
      />
    </template>
  </TheTabsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheNewAddPeoplePanel',
  data() {
    return {
      baseTypeFormUrl: 'https://form.typeform.com/to/kenl3mUp#client=?name?&client_email=?email?',
    };
  },
  computed: {
    ...mapGetters({
      tabItems: 'TheNewAddPeoplePanel/tabItems',
      activeTab: 'TheNewAddPeoplePanel/activeTab',

      currentUserDetail: 'TheCRM/currentUserDetail',
      accountFeatures: 'TheCRM/accountFeatures',
      currentChannelGroup: 'TheCRM/currentChannelGroup',
    }),

    localActiveTab: {
      get() { return this.activeTab; },
      set(newValue) { this.UP_activeTab(newValue); },
    },

    navigableItems() {
      return {
        'root.addManually': {
          to: 'root.addManually',
          label: 'Add manually',
          icon: 'ubu-edit',
          // requiredFeature: 'CAMPAIGNS',
        },
        'root.upload': {
          to: 'root.upload',
          label: 'Upload .csv',
          icon: 'ubu-upload',
          isDisabled: true,
        },
        'root.chrome': {
          to: 'root.chrome',
          label: 'Add from chrome extension',
          icon: 'ubu-influence',
          isDisabled: true,
          // requiredFeature: 'CRM',
        },
      };
    },
  },
  methods: {
    ...mapActions({
      emitSegmentEvent: 'TheNewAddPeoplePanel/emitSegmentEvent',
      UP_activeTab: 'TheNewAddPeoplePanel/UP_activeTab',
      UP_resetStore: 'TheNewAddPeoplePanel/UP_resetStore',
      goToPreviousTab: 'TheNewAddPeoplePanel/goToPreviousTab',
      goToTabByTabPath: 'TheNewAddPeoplePanel/goToTabByTabPath',
      UP_changeToNewTab: 'TheNewAddPeoplePanel/UP_changeToNewTab',
    }),

    closePanel() {
      this.UP_resetStore();
      this.$emit('close');
    },

    handleNavigation(item) {
      if (!item.requiredFeature) return this.UP_activeTab(this.tabItems[item.to]);

      if (this.accountFeatures(item.requiredFeature)) {
        return this.UP_activeTab(this.tabItems[item.to]);
      }

      if (item.requiredFeature === 'CAMPAIGNS') this.emitSegmentEvent({ event: 'addToCampaignNoFeature' });
      if (item.requiredFeature === 'CRM') this.emitSegmentEvent({ event: 'addToCRMListNoFeature' });

      // eslint-disable-next-line max-len
      this.$intercom.showNewMessage(`Hi! I would like to activate the ${item.requiredFeature} module! 🙌`);
      return null;
    },

    redirectToTypeForm() {
      const { name } = this.currentChannelGroup;
      const { email } = this.currentUserDetail;

      const url = this.baseTypeFormUrl.replace('?name?', name).replace('?email?', email);

      window.open(url, '_blank');
    },
  },
};
</script>

<style>

</style>
