/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    featureList: {},
  },
  mutations: {
    SET_FEATURE_LIST(state, newData) { state.featureList = newData; },
    RESET_STORE(state) {
      state.featureList = {};
    },
  },
  getters: {
    getterFeatureList: (state) => state.featureList,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'ACCOUNT_STATE.REPORT')) return undefined;

      return clark.api.feature.report({ payload, httpQuery })
        .then((featureList) => {
          commit('SET_FEATURE_LIST', featureList.arrayMapper('name'));
        });
    },
  },
  modules: {},
};
