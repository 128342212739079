<template>
  <fieldset>
    <b-field label="New password">
      <b-input
        v-model="localModel.newPassword"
        type="password"
        password-reveal
        required
        placeholder="New password ..."
        @input="$update('newPassword', $event)"
      />
    </b-field>

    <br>

    <b-field label="Confirm new password">
      <b-input
        v-model="localModel.confirmPassword"
        required
        type="password"
        password-reveal
        placeholder="Confirm new password ..."
        @input="$update('confirmPassword', $event)"
      />
    </b-field>
  </fieldset>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuEditPasswordForm',
  mixins: [VModelMixin],
  model: {
    prop: 'model',
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localModel: {
      get() { return { ...this.model }; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
};
</script>
