<template>
  <div style="height: calc(100vh - 200px); overflow-y: auto;">
    <article class="flex wrap space-evenly p-4">
      <UbuAnalyticCard
        v-for="(analytic, index) in analyticCards"
        :key="index"
        class="is-horizontal mb-2"
        style="width: 350px;"
        :name="analytic.name"
        :value="analytic.value"
        :icon="analytic.icon"
        :color="analytic.color"
        :symbol="analytic.symbol"
        :currency-before="analytic.currencyBefore"
      />
    </article>

    <br>

    <article class="flex wrap space-evenly">
      <div>
        <div class="graphContainers">
          <span class="text is-3 has-text-secondary">Sales by code</span>

          <UbuChartCustom
            v-if="salesByCode"
            class=""
            chart-id="salesByCode"
            :data="salesByCode.data"
            :options="salesByCode.options"
          />
        </div>

        <br>

        <div class="flex column centered has-text-centered">
          <span class="text is-3 has-text-secondary">Details by code</span>

          <br>

          <b-table
            ref="table"
            :data="tableByCodeDatas"
            class="table-row-clickable"
            hoverable
            sticky-header
            :loading="loading"
            :detail-key="byCodeDetailKey"

            focusable
            detailed
            :opened-detailed="byCodeOpenedDetailed"

            paginated
            :total="total"
            :per-page="perPage"
            pagination-size="is-small"

            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"

            @page-change="pageChange"
          >
            <b-table-column
              v-for="column in byCodeColumns"
              :key="column.field"
              :field="column.field"
              :label="column.label"
              :sortable="column.isSortable"
              :visible="column.visible"
              :custom-sort="(a, b, isAsc) => customSort(a, b, isAsc, column)"
            >
              <template
                v-if="column.cmp === 'UbuTablePlatform'"
                #header
              >
                <b-icon
                  pack="ubu"
                  icon="ubu-organic"
                />
              </template>

              <template v-slot="props">
                <component
                  :is="column.cmp"
                  :cpo="props.row"
                  :field="column.field"
                  :column="column"
                  @selectContactPlatform="$emit('selectContactPlatform', $event)"
                />
              </template>
            </b-table-column>

            <template
              slot="detail"
              slot-scope="props"
            >
              <table class="w-100">
                <tr>
                  <th>date</th>
                  <th>amount</th>
                </tr>
                <tr
                  v-for="item in props.row.detailed"
                  :key="item.orderOid"
                >
                  <td>{{ item.createdOn | toDate }}</td>
                  <td>${{ item.amountUsd | nFormatter }}</td>
                </tr>
              </table>
            </template>

            <template #empty>
              <p class="has-text-secondary">No tribe members yet!</p>
            </template>
          </b-table>
        </div>
      </div>
      <div>
        <div class="graphContainers">
          <span class="text is-3 has-text-secondary">Sales by day</span>

          <UbuChartCustom
            v-if="salesByDay"
            class=""
            chart-id="salesByDay"
            :data="salesByDay.data"
            :options="salesByDay.options"
          />
        </div>

        <br>

        <div class="flex column centered has-text-centered">
          <span class="text is-3 has-text-secondary">Details by people</span>

          <br>

          <b-table
            ref="table"
            :data="tableByPeopleDatas"
            class="table-row-clickable"
            hoverable
            sticky-header
            :loading="loading"
            :detail-key="byPeopleDetailKey"

            focusable
            detailed
            :opened-detailed="byCodeOpenedDetailed"

            paginated
            :total="total"
            :per-page="perPage"
            pagination-size="is-small"

            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"

            @page-change="pageChange"
          >
            <b-table-column
              v-for="column in byPeopleColumns"
              :key="column.field"
              :field="column.field"
              :label="column.label"
              :sortable="column.isSortable"
              :visible="column.visible"
              :custom-sort="(a, b, isAsc) => customSort(a, b, isAsc, column)"
            >
              <template
                v-if="column.cmp === 'UbuTablePlatform'"
                #header
              >
                <b-icon
                  pack="ubu"
                  icon="ubu-organic"
                />
              </template>

              <template v-slot="props">
                <component
                  :is="column.cmp"
                  :cpo="props.row"
                  :field="column.field"
                  :column="column"
                  @selectContactPlatform="$emit('selectContactPlatform', $event)"
                />
              </template>
            </b-table-column>

            <template
              slot="detail"
              slot-scope="props"
            >
              <table class="w-100">
                <tr>
                  <th>date</th>
                  <th>amount</th>
                </tr>
                <tr
                  v-for="item in props.row.detailed"
                  :key="item.orderOid"
                >
                  <td>{{ item.createdOn | toDate }}</td>
                  <td>${{ item.amountUsd | nFormatter }}</td>
                </tr>
              </table>
            </template>

            <template #empty>
              <p class="has-text-secondary">No tribe members yet!</p>
            </template>
          </b-table>
        </div>
      </div>
    </article>

    <br>

    <article class="flex wrap space-evenly">
      <div class="graphContainers">
        <span class="text is-3 has-text-secondary">Sales by country</span>
        <UbuChartCustom
          v-if="salesByCountry"
          class=""
          chart-id="salesByCountry"
          :data="salesByCountry.data"
          :options="salesByCountry.options"
        />
      </div>
      <div class="graphContainers">
        <span class="text is-3 has-text-secondary">Sales by city</span>
        <UbuChartCustom
          v-if="salesByCity"
          class=""
          chart-id="salesByCity"
          :data="salesByCity.data"
          :options="salesByCity.options"
        />
      </div>
    </article>
  </div>
</template>

<script>
/* eslint-disable max-len */

import { mapGetters, mapActions } from 'vuex';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';
import { nFormatter } from '@dailyplanet/utils/formate';

export default {
  name: 'TheCampaignAnalyticSales',
  mixins: [TableUtilsMixin],
  data() {
    return {
      byCodeDetailKey: 'code',
      byPeopleDetailKey: 'cpid',
      page: 1,
      perPage: 50,
      total: 0,
      sortField: 'totalRevenue',
      sortOrder: 'desc',
      loading: false,
      /* eslint-disable object-curly-newline */
      /* eslint-disable key-spacing */
      /* eslint-disable no-multi-spaces */
      byCodeColumns: {
        code:             { cmp: 'UbuTableSimple',           field: 'code',             label: 'Code',               isSortable: true },
        type:             { cmp: 'UbuTableSimple',           field: 'type',             label: 'Type',               isSortable: true },
        username:         { cmp: 'UbuTableSimple',           field: 'username',         label: 'People',             isSortable: true },
        peopleOnCampaign: { cmp: 'UbuTableChipLastCampaign', field: 'peopleOnCampaign', label: 'People on campaign', isSortable: true },
        totalOrders:      { cmp: 'UbuTableNumber',           field: 'totalOrders',      label: 'Orders',             isSortable: true },
        totalRevenue:     { cmp: 'UbuTableNumber',           field: 'totalRevenue',     label: 'Revenue',            isSortable: true, before: '$' },
      },
      byPeopleColumns: {
        username:         { cmp: 'UbuTableSimple',           field: 'username',         label: 'People',             isSortable: true },
        peopleOnCampaign: { cmp: 'UbuTableChipLastCampaign', field: 'peopleOnCampaign', label: 'People on campaign', isSortable: true },
        totalOrders:      { cmp: 'UbuTableNumber',           field: 'totalOrders',      label: 'Orders',             isSortable: true },
        totalRevenue:     { cmp: 'UbuTableNumber',           field: 'totalRevenue',     label: 'Revenue',            isSortable: true, before: '$' },
      },

      byCodeOpenedDetailed: [],
    };
  },
  computed: {
    ...mapGetters({
      salesStats: 'TheCampaign/salesStats',
    }),
    tableByCodeDatas() {
      if (!this.salesStats) return [];

      return Object.values(this.salesStats.salesByCode).reduce((acc, details) => {
        acc.push({
          code: details.code,
          type: details.type.toLowerCase(),
          username: details.username,
          createdOn: details.createdOn,
          totalOrders: details.count,
          totalRevenue: details.amount,
          peopleOnCampaign: details.activity && details.activity.campaignCid,
          detailed: details.sales,
        });
        return acc;
      }, []);
    },
    tableByPeopleDatas() {
      if (!this.salesStats) return [];

      return Object.entries(this.salesStats.salesByContactPlatform).reduce((acc, [cpid, details]) => {
        acc.push({
          cpid,
          username: details.username,
          createdOn: details.createdOn,
          totalOrders: details.count,
          totalRevenue: details.amount,
          peopleOnCampaign: details.activity && details.activity.campaignCid,
          detailed: details.sales,
        });
        return acc;
      }, []);
    },
    analyticCards() {
      if (!this.salesStats) return null;

      const cards = [];

      const affiliates = Object.keys(this.salesStats.salesByContactPlatform);
      const usedCodes = Object.keys(this.salesStats.byCode).reduce((acc) => acc + 1, 0);

      cards.push({
        name: 'Total orders', color: 4, icon: 'ubu-shop', value: this.salesStats.sales.length,
      });

      cards.push({
        name: 'Total revenue', color: 5, icon: 'ubu-shop', currencyBefore: true, symbol:'$', value: Object.values(this.salesStats.byDate).reduce((acc, { amount }) => Number(acc + amount), 0),
      });

      cards.push({
        name: 'Affiliated people with sales', color: 12, icon: 'ubu-account', value: affiliates.length,
      });

      cards.push({
        name: 'Total codes used', color: 12, icon: 'ubu-discount', value: usedCodes,
      });

      cards.push({
        name: 'Average order by code', color: 4, icon: 'ubu-discount', value: Math.round(Object.values(this.salesStats.byCode).reduce((acc, { count }) => acc + count, 0) / usedCodes),
      });

      cards.push({
        name: 'Average revenue by code', color: 5, icon: 'ubu-discount', symbol:'$', currencyBefore: true,  value: usedCodes > 0 ? Math.round(Object.values(this.salesStats.byCode).reduce((acc, { amount }) => acc + amount, 0) / usedCodes) : '-',
      });

      return cards;
    },
    salesByDay() {
      if (!this.salesStats) return null;
      const counts = Object.values(this.salesStats.byDate).reduce((acc, { count, amount }) => {
        acc.order.data.push(count);
        acc.revenue.data.push(amount);
        return acc;
      }, {
        revenue: {
          type: 'bar',
          label: 'Revenue',
          yAxisID: 'A',
          data: [],
          borderColor: ['rgba(107, 206, 61, 0.4)'],
          backgroundColor: ['rgba(107, 206, 61, 0.6)'],
        },
        order: {
          type: 'bar',
          label: 'Orders',
          yAxisID: 'B',
          data: [],
          borderColor: ['rgba(255, 164, 113, 0.4)'],
          backgroundColor: ['rgba(255, 164, 113, 0.6)'],
        },

      });

      return {
        data: {
          type: 'scatered',
          labels: Object.keys(this.salesStats.byDate),
          datasets: Object.values(counts),
        },
        options: {
          scales: {
            A: {
              type: 'linear',
              position: 'left',
            },
            B: {
              type: 'linear',
              position: 'right',
              ticks: {
                max: 1,
                min: 0,
              },
            },
          },
        },
      };
    },
    salesByCode() {
      if (!this.salesStats) return null;
      const counts = Object.entries(this.salesStats.byCode).reduce((acc, [code, { count, amount }]) => {
        acc.order.data.push(count);
        acc.revenue.data.push(amount);
        acc.labels.push(code.split('#')[1]);
        return acc;
      }, {
        labels: [],
        revenue: {
          type: 'bar',
          label: 'Revenue',
          yAxisID: 'A',
          data: [],
          borderColor: ['rgba(107, 206, 61, 0.4)'],
          backgroundColor: ['rgba(107, 206, 61, 0.6)'],
        },
        order: {
          type: 'bar',
          label: 'Orders',
          yAxisID: 'B',
          data: [],
          borderColor: ['rgba(255, 164, 113, 0.4)'],
          backgroundColor: ['rgba(255, 164, 113, 0.6)'],
        },

      });

      return {
        data: {
          type: 'scatered',
          labels: counts.labels,
          datasets: [counts.revenue, counts.order],
        },
        options: {
          scales: {
            A: {
              type: 'linear',
              position: 'left',
            },
            B: {
              type: 'linear',
              position: 'right',
              ticks: {
                max: 1,
                min: 0,
              },
            },
          },
        },
      };
    },

    salesByCountry() {
      if (!this.salesStats) return null;
      const counts = Object.entries(this.salesStats.salesByCountry)
        .sort((a, b) => b[1].amount - a[1].amount)
        .reduce((acc, [countryCode, { count, amount }]) => {
          if (acc.labels.length > 9) return acc;
          acc.order.data.push(count);
          acc.revenue.data.push(amount);
          acc.labels.push(countryCode);
          return acc;
        }, {
          labels: [],
          revenue: {
            type: 'bar',
            label: 'Revenue',
            yAxisID: 'A',
            data: [],
            borderColor: ['rgba(107, 206, 61, 0.4)'],
            backgroundColor: ['rgba(107, 206, 61, 0.6)'],
          },
          order: {
            type: 'bar',
            label: 'Orders',
            yAxisID: 'B',
            data: [],
            borderColor: ['rgba(255, 164, 113, 0.4)'],
            backgroundColor: ['rgba(255, 164, 113, 0.6)'],
          },

        });

      return {
        data: {
          type: 'scatered',
          labels: counts.labels,
          datasets: [counts.revenue, counts.order],
        },
        options: {
          scales: {
            A: {
              type: 'linear',
              position: 'left',
            },
            B: {
              type: 'linear',
              position: 'right',
              ticks: {
                max: 1,
                min: 0,
              },
            },
          },
        },
      };
    },

    salesByCity() {
      if (!this.salesStats) return null;
      const counts = Object.entries(this.salesStats.salesByCity)
        .sort((a, b) => b[1].amount - a[1].amount)
        .reduce((acc, [city, { count, amount }]) => {
          if (acc.labels.length > 9) return acc;
          acc.order.data.push(count);
          acc.revenue.data.push(amount);
          acc.labels.push(city);
          return acc;
        }, {
          labels: [],
          revenue: {
            type: 'bar',
            label: 'Revenue',
            yAxisID: 'A',
            data: [],
            borderColor: ['rgba(107, 206, 61, 0.4)'],
            backgroundColor: ['rgba(107, 206, 61, 0.6)'],
          },
          order: {
            type: 'bar',
            label: 'Orders',
            yAxisID: 'B',
            data: [],
            borderColor: ['rgba(255, 164, 113, 0.4)'],
            backgroundColor: ['rgba(255, 164, 113, 0.6)'],
          },
        });

      return {
        data: {
          type: 'scatered',
          labels: counts.labels,
          datasets: [counts.revenue, counts.order],
        },
        options: {
          scales: {
            A: {
              type: 'linear',
              position: 'left',
            },
            B: {
              type: 'linear',
              position: 'right',
              ticks: {
                max: 1,
                min: 0,
              },
            },
          },
        },
      };
    },
  },
  mounted() {
    return this._report_salesStats({ payload: { campaignCid: this.$route.params.campaignCid } });
  },
  methods: {
    ...mapActions({
      _report_salesStats: 'TheCampaign/report_salesStats',
    }),
  },
};
</script>
