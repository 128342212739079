<template>
  <section class="ubuSwitchGroup">
    <DevDetails :cnt="{name: 'localModel', obj: localModel}" />
    <div class="switchGroup-content">
      <div
        v-for="channel in localModel.channelList"
        :key="channel.cid"
        class="switchGroup-content-group"
      >
        <b-field class="switchGroup-content-items">
          <b-switch
            v-model="localModel.channelList[channel.cid].isActive"
            size="is-small"
            :disabled="localModel.channelList[channel.cid].immutable"
            @input="turnOnChannel(channel.cid, $event)"
          >
            <div class="multibar row-vcentered ml-3">
              <component
                :is="`UbuIcon${channel.platformName.capitalize()}`"
                class="start"
              />

              <p class="start ml-2 text-truncate">
                {{ channel.name }}
              </p>
            </div>
          </b-switch>
        </b-field>
      </div>
    </div>
    <div class="multibar">
      <b-button
        class="start"
        type="is-ghost"
        label="Save changes"
        :disabled="!hasChange"
        @click="$emit('updateGroup', newState)"
      />
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
export default {
  name: 'UbuChannelSelector',
  props: {
    model: { type: String, required: true },
  },
  data() {
    return {
      localModel: { ...JSON.parse(this.model) },
    };
  },
  computed: {
    hasChange() {
      return this.model !== JSON.stringify(this.localModel);
    },
    newState() {
      const oldState = JSON.parse(this.model);
      const newState = JSON.parse(JSON.stringify(this.localModel));

      newState.channelGroup = {};

      Object.values(newState.channelList).forEach((channel) => {
        if (oldState.channelList[channel.cid].isActive && !channel.isActive) newState.channelList[channel.cid].toBeUnlinked = true;
        if (!oldState.channelList[channel.cid].isActive && channel.isActive) newState.channelList[channel.cid].toBeLinked = true;
      });

      console.log(newState);

      return newState;
    },
  },
  methods: {
    turnOnChannel(cid, state) {
      this.localModel.channelList[cid].isActive = state;
      if (!state) return;
      this.localModel.channelList[cid].isActive = true;
    },
  },
};
</script>
