<template>
  <div style="height: calc(100vh - 200px); overflow-y: auto;">
    <article class="flex wrap space-evenly p-4">
      <UbuAnalyticCard
        v-for="(analytic, index) in analyticCards"
        :key="index"
        class="is-horizontal mb-2"
        style="width: 350px;"
        :name="analytic.name"
        :value="analytic.value"
        :icon="analytic.icon"
        :color="analytic.color"
      />
    </article>

    <br>

    <article class="flex wrap space-evenly">
      <div class="graphContainers">
        <span class="text is-3 has-text-secondary">Group distribution</span>
        <UbuChartDoughnut
          v-if="membersByGroup"
          class=""
          chart-id="membersByGroup"
          :data="membersByGroup"
        />
      </div>

      <div
        v-if="shouldDisplayMembersByDate"
        class="graphContainers"
      >
        <span class="text is-3 has-text-secondary">People added by day</span>
        <UbuChartBar
          v-if="membersByDate"
          class=""
          chart-id="membersByDate"
          :data="membersByDate"
        />
      </div>
    </article>
  </div>
</template>

<script>
/* eslint-disable max-len */

import { mapGetters, mapActions } from 'vuex';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';

export default {
  name: 'TheTribeAnalyticMembers',
  mixins: [TableUtilsMixin],
  model: {
    prop: 'httpQuery',
  },
  props: {
    httpQuery: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shouldDisplayMembersByDate: true,
    };
  },
  computed: {
    ...mapGetters({
      membersStats: 'TheTribeAnalytics/membersStats',
      memberGroupList: 'TheTribeAnalytics/memberGroupList',
    }),

    analyticCards() {
      if (!this.membersStats) return null;

      const cards = [];

      cards.push({
        name: 'Total people in tribe', color: 12, icon: 'ubu-account', value: this.membersStats.tribeMembers.length,
      });

      cards.push({
        name: 'Total people without group', color: 2, icon: 'ubu-tribe', value: this.membersStats.noGroup,
      });

      return cards;
    },

    membersByGroup() {
      if (!this.membersStats) return null;
      return {
        labels: Object.keys(this.membersStats.byMemberGroupMgid).map((mgid) => this.memberGroupList[mgid].name),
        datasets: [{
          data: Object.values(this.membersStats.byMemberGroupMgid),
          maintainAspectRatio: false,
          backgroundColor: [
            'rgba(255, 98, 164, 0.6)',
            'rgba(255, 96, 96, 0.6)',
            'rgba(255, 164, 113, 0.6)',
            'rgba(255, 198, 112, 0.6)',
            'rgba(107, 206, 61, 0.6)',
            'rgba(115, 218, 138, 0.6)',
            'rgba(67, 210, 193, 0.6)',
            'rgba(60, 166, 242, 0.6)',
            'rgba(111, 109, 227, 0.6)',
            'rgba(185, 141, 240, 0.6)',
            'rgba(248, 130, 236, 0.6)',
            'rgba(77, 77, 77, 0.6)',
          ],
        }],
      };
    },

    membersByDate() {
      if (!this.membersStats) return null;
      return {
        labels: Object.keys(this.membersStats.byDate),
        datasets: [{
          data: Object.values(this.membersStats.byDate),
          backgroundColor: ['rgba(255, 164, 113, 0.6)'],
          maxBarThickness: 150,
        }],
      };
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'httpQuery.createdOn': function () {
      this.reportDatas();
    },
  },
  mounted() {
    return this.reportDatas();
  },
  methods: {
    ...mapActions({
      _report_membersStats: 'TheTribeAnalytics/report_membersStats',
    }),

    reportDatas() {
      return this._report_membersStats({
        payload: { tribeTid: this.$route.params.tribeTid },
        httpQuery: this.httpQuery,
      });
    },
  },
};
</script>
