/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  actions: {
    fetchConversation(_, { payload, httpQuery }) {
      return clark.api.channel.conversation.post({ payload, httpQuery });
    },
  },
};
