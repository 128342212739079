<template>
  <b-tabs v-model="activeTab">
    <b-tab-item label="Update lead">
      <div class="columns">
        <form
          class="column is-4"
          @submit.prevent="$emit('updateLead', localLead)"
        >
          <UbuLeadForm
            v-model="localLead"
          />
          <br>
          <div class="buttons is-right">
            <b-button
              type="is-info"
              native-type="submit"
              label="Update"
            />
          </div>
        </form>
      </div>
    </b-tab-item>

    <b-tab-item label="Dev.">
      <DevDetails
        open
        :cnt="{name: 'row details', obj: row}"
      />
    </b-tab-item>
  </b-tabs>
</template>

<script>
export default {
  name: 'UbuLeadsDashboardTableDetail',
  model: {
    prop: 'row',
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      localLead: {
        lid: this.row.lid,
        contactUsername: this.row.username,
        linkedInUrl: this.row.linkedInUrl,
        firstName: this.row.firstName,
        lastName: this.row.lastName,
        position: this.row.position,
        email: this.row.email,
        phone: this.row.phone,
        isRisky: this.row.isRisky,
        createdOn: this.row.leadCreatedOn,
      },
    };
  },
};
</script>
