/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import builder from '../engine';

import account from './routes/account';
import accountState from './routes/accountState';
import apiKey from './routes/apiKey';
import downloadTask from './routes/downloadTask';
import city from './routes/city';
import channel from './routes/channel';
import channelGroup from './routes/channelGroup';
import feature from './routes/feature';
import tokenLink from './routes/tokenLink';
import user from './routes/user';
import userRole from './routes/userRole';
import apiPlatform from './routes/apiPlatform';
import migrate from './routes/migrate';

import apiDownload from './routes/apiDownload';
import apiUpload from './routes/apiUpload';

// api-authenticate
import apiAuthenticate from './routes/apiAuthenticate';

// api-influence
import apiInfluence from './routes/apiInfluence';

import apiAnalytics from './routes/apiAnalytics';

import monitor from './routes/monitor';

import {
  ubuLead,
  scrapingZone,
  reportDetailledLead,
  salesUser,
  lead,
  salesContact,
} from './routes/apiLex/routes';

import apiTribe from './routes/apiTribe';
import apiAffiliation from './routes/apiAffiliation';
import apiContact from './routes/apiContact';
import apiConversation from './routes/apiConversation';
import apiPublication from './routes/apiPublication';
import apiChannel from './routes/apiChannel';
import apiCrew from './routes/apiCrew';
import apiTremendous from './routes/apiTremendous';

const target = 'clark';

const authenticate = {
  post: ({ payload, httpQuery }) => builder({ url: '/api/authenticate', method: 'POST', payload, httpQuery, target }),
  get: ({ payload, httpQuery }) => builder({ url: '/api/authenticate', method: 'GET', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: '/api/authenticate', method: 'DELETE', payload, httpQuery, target }),
};

const reportDetailledUsers = {
  report: ({ payload, httpQuery }) => builder({ url: '/api/reportDetailledUsers', method: 'REPORT', payload, httpQuery, target }),
  get: ({ payload, httpQuery }) => builder({ url: `/api/reportDetailledUsers/${payload.uid}`, method: 'GET', payload, httpQuery, target }),
};

const changePassword = {
  post: ({ payload, httpQuery }) => builder({ url: `/api/authenticate/${payload.aid}/changePassword`, method: 'POST', payload, httpQuery, target }),
};

const changeEmail = {
  put: ({ payload, httpQuery }) => builder({ url: `/api/authenticate/${payload.aid}/changeEmail`, method: 'PUT', payload, httpQuery, target }),
};

const forgotPassword = {
  post: ({ payload, httpQuery }) => builder({ url: '/api/forgotPassword', method: 'POST', payload, httpQuery }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/forgotPassword/${payload.link}`, method: 'PUT', payload, httpQuery }),
};

const translateText = {
  post: ({ payload, httpQuery }) => builder({ url: '/api/translateText', method: 'POST', payload, httpQuery }),
};

const favikonProfile = {
  get: ({ payload, httpQuery }) => builder({ url: `/api/favikonProfile/${payload.username}`, method: 'GET', payload, httpQuery }),
};

const ping = ({ payload, httpQuery }) => builder({ url: '/ping', method: 'GET', payload, httpQuery, target });

export {
  ping,
  account,
  accountState,
  authenticate,
  apiKey,

  changeEmail,
  changePassword,
  channel,
  channelGroup,
  city,
  downloadTask,
  favikonProfile,
  feature,
  forgotPassword,
  lead,
  reportDetailledLead,
  scrapingZone,
  reportDetailledUsers,
  salesContact,
  salesUser,
  tokenLink,
  ubuLead,
  user,
  userRole,
  migrate,

  apiPlatform,

  // api-conversation
  translateText,

  apiAuthenticate,
  apiDownload,
  apiUpload,

  apiInfluence,

  apiAnalytics,
  apiCrew,
  apiTremendous,

  apiTribe,
  apiAffiliation,
  apiContact,
  apiConversation,
  apiPublication,
  apiChannel,

  // WS Monitor
  monitor,
};
