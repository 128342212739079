import moment from 'moment';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  actions: {
    updateLead({ dispatch }, lead) {
      const payload = { ...lead, updatedOn: moment().format() };

      const action = {
        dispatch,
        target: 'Sales/lead/update',
        context: { payload },
        textNotifier: 'update lead',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        // .then(() => {
        //   Snackbar.open({
        //     message: 'Lead successfully updated.',
        //     type: 'is-success',
        //     position: 'is-top',
        //     duration: 5000,
        //   });
        // })
        .catch(() => {
          Snackbar.open({
            message: 'Unable to update Lead.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },

    createLead({ dispatch }, lead) {
      const payload = {
        ...lead,
        createdOn: moment().format(),
        updatedOn: moment().format(),
      };

      const action = {
        dispatch,
        target: 'Sales/lead/create',
        context: { payload },
        textNotifier: 'create lead',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        // .then(() => {
        //   Snackbar.open({
        //     message: 'Lead successfully created.',
        //     type: 'is-success',
        //     position: 'is-top',
        //     duration: 5000,
        //   });
        // })
        .catch(() => {
          Snackbar.open({
            message: 'Unable to create Lead.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },

    deleteLead({ dispatch }, lead) {
      const { lid } = lead;

      const action = {
        dispatch,
        target: 'Sales/lead/delete',
        context: { payload: { lid } },
        textNotifier: 'delete lead',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        // .then(() => {
        //   Snackbar.open({
        //     message: 'Lead successfully deleted.',
        //     type: 'is-success',
        //     position: 'is-top',
        //     duration: 5000,
        //   });
        // })
        .catch(() => {
          Snackbar.open({
            message: 'Unable to delete Lead.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
  },
};
