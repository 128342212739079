import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  getters: {
    ...getters.activityMention,
    ...getters.favorite,
  },
  actions: {
    ...actions.activityMention,
    ...actions.favorite,
    ...actions.downloadTask,

  },
};
