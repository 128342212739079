<template>
  <div class="ubuAnalytics" style="max-height: calc(100vh - 84px); overflow-y: auto;">
    <template v-if="hasAnalyticsFeature">
      <span class="subtext is-3 has-text-secondary">Compare with:</span>

      <UbuDateRangePicker
        :option-date-to-use="optionDateToUse"
        @changeDate="reportAnalyticsInbox($event.value)"
      />

      <DevDetails :cnt="{name: 'analyticsInboxList', obj: analyticsInboxList}" />

      <h2
        v-if="overviewAnalyticsList.length"
        class="subtitle"
      >
        Overview
      </h2>

      <div
        v-if="loading"
        class="analyticsGrid col-5"
      >
        <UbuAnalyticCard
          v-for="index in 5"
          :key="index"
          loading
        />
      </div>
      <div
        v-else
        class="analyticsGrid col-5"
      >
        <UbuAnalyticCard
          v-for="(analytic, index) in overviewAnalyticsList"
          :key="index"
          :name="analytic.name"
          :value="analytic.value"
          :icon="analytic.icon"
          :color="analytic.color"
        />
      </div>

      <h2
        v-if="tagsAnalyticsList.length"
        class="subtitle"
      >
        Tags
      </h2>

      <div
        v-if="loading"
        class="analyticsGrid col-4"
      >
        <UbuAnalyticCard
          v-for="index in 4"
          :key="index"
          loading
        />
      </div>
      <div
        v-else
        class="analyticsGrid col-4"
      >
        <UbuAnalyticCard
          v-for="(analytic, index) in tagsAnalyticsList"
          :key="index"
          :name="analytic.name"
          :value="analytic.value"
          :icon="analytic.icon"
          :color="analytic.color"
        />
      </div>

      <h2
        v-if="assignmentsAnalyticsList.length"
        class="subtitle"
      >
        Assignments
      </h2>

      <div
        v-if="loading"
        class="analyticsGrid col-4"
      >
        <UbuAnalyticCard
          v-for="index in 4"
          :key="index"
          loading
        />
      </div>
      <div
        v-else
        class="analyticsGrid col-4"
      >
        <UbuAnalyticCard
          v-for="(analytic, index) in assignmentsAnalyticsList"
          :key="index"
          :name="analytic.name"
          :value="analytic.value"
          :icon="analytic.icon"
          :color="analytic.color"
        />
      </div>
    </template>

    <UbuExtensionDisconnected
      v-else
      :has-extension="false"
      feature="analytics"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheAnalyticsInbox',
  props: {
  },
  data() {
    return {
      currentDateRange: [this.$moment().subtract({ days: 6 }), this.$moment()],
      loading: false,
      optionDateToUse: ['currentWeek', 'previousWeek', 'currentMonth', 'previousMonth'],
    };
  },
  computed: {
    ...mapGetters({
      analyticsInboxList: 'TheAnalytics/analyticsInboxList',
      accountFeatures: 'TheAnalytics/accountFeatures',
    }),
    hasAnalyticsFeature() {
      return this.accountFeatures('ANALYTICS');
    },
    overviewAnalyticsList() {
      return Object.values(this.analyticsInboxList).filter(({ type }) => type === 'overview');
    },
    tagsAnalyticsList() {
      return Object.values(this.analyticsInboxList).filter(({ type }) => type === 'tags');
    },
    assignmentsAnalyticsList() {
      return Object.values(this.analyticsInboxList).filter(({ type }) => type === 'assignments');
    },
  },
  mounted() {
    if (!this.hasAnalyticsFeature) return;

    this.reportAnalyticsInbox(this.currentDateRange);
  },
  methods: {
    ...mapActions({
      _reportAnalyticsInbox: 'TheAnalytics/reportAnalyticsInbox',
    }),
    reportAnalyticsInbox(dateRange) {
      this.currentDateRange = dateRange;
      const [dateMin, dateMax] = dateRange;
      this.loading = true;
      this._reportAnalyticsInbox({
        httpQuery: {
          dateRange: [this.$moment(dateMin).format('YYYY-MM-DD'), this.$moment(dateMax).add({ days: 1 }).format('YYYY-MM-DD')],
        },
      }).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>
