<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <pre><code v-html="JsonColorizer.prettyPrint(obj)" /></pre>
</template>

<script>
import JsonColorizer from '../$utils/JsonColorizer';

export default {
  name: 'JsonReader',
  props: {
    obj: {
      type: [Object, Array, String, null, undefined],
      required: true,
    },
  },
  data() {
    return {
      JsonColorizer,
    };
  },
};
</script>

<style lang="scss">
/* pre {
  padding: 10px 20px;
  margin: 20px;
} */
// cyan 00e8c6
// red ee5d43
// orange f39c12
// comment 5f6167

.json-key {
  color: #D5CED9;
}
.json-null {
  color:#c74ded;
}
.json-bool {
  color: #ee5d43;
}
.json-num {
  color: #00e8c6;
}
.json-string {
  color: limegreen;
}
</style>
