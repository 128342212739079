/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    ubuLeadList: {},
  },
  mutations: {
    SET_UBU_LEAD_LIST(state, newDatas) { state.ubuLeadList = newDatas; },
    UPDATE_UBU_LEAD_LIST(state, newDatas) {
      const myUbuLeadList = Object.values(state.ubuLeadList).concat(newDatas).arrayMapper('ulid');
      state.ubuLeadList = myUbuLeadList;
    },
    DELETE_UBU_LEAD(state, newData) {
      state.ubuLeadList = Object.values(state.ubuLeadList).filter(({ ulid }) => ulid !== newData.ulid).arrayMapper('ulid');
    },
  },
  getters: {
    getterUbuLeadList: (state) => state.ubuLeadList,
  },
  actions: {
    reportUbuLeads({ commit, rootGetters }) {
      if (!isActionAllowed(rootGetters, 'UBU_LEAD.REPORT')) return undefined;

      return clark.api.ubuLead
        .report({ payload: {}, httpQuery: { appFriendly: 'true' } })
        .then((ubuLeadList) => {
          commit('SET_UBU_LEAD_LIST', ubuLeadList);
        });
    },
    createUbuLead({ commit }, payload) {
      return clark.api.ubuLead
        .post({ payload, httpQuery: {} })
        .then((ubuLead) => { commit('UPDATE_UBU_LEAD_LIST', ubuLead); });
    },
    updateUbuLead({ commit }, payload) {
      return clark.api.ubuLead
        .put({ payload, httpQuery: {} })
        .then((ubuLead) => { commit('UPDATE_UBU_LEAD_LIST', ubuLead); });
    },
    deleteUbuLead({ commit }, payload) {
      return clark.api.ubuLead
        .delete({ payload, httpQuery: {} })
        .then(() => { commit('DELETE_UBU_LEAD', payload); });
    },
    bulkRefreshLeads({ commit }, payload) {
      return clark.api.ubuLead.bulkRefresh
        .post({ payload, httpQuery: {} })
        .then((ubuLeads) => { commit('UPDATE_UBU_LEAD_LIST', Object.values(ubuLeads)); });
    },
  },
};
