/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const threadGroup = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/threadGroup/`, method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/threadGroup`, method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/threadGroup/${payload.tgid}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/threadGroup/${payload.tgid}`, method: 'DELETE', payload, httpQuery, target }),
  assign: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/threadGroup/${payload.tgid}/assign`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/threadGroup/${payload.tgid}/assign`, method: 'DELETE', payload, httpQuery, target }),
  },
  autoTag: {
    patch: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/threadGroup/${payload.tgid}/autoTag`, method: 'PATCH', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/threadGroup/${payload.tgid}/autoTag`, method: 'DELETE', payload, httpQuery, target }),
  },
};

export default threadGroup;
