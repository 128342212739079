<template>
  <section
    id="TheTribe"
    class="mainPanel"
  >
    <header
      id="TheTribeHeader"
      class="mainPanelHeader multibar row-vcentered"
    >
      <h1 :class="`start title is-2 multibar row-vcentered`">
        <b-icon
          :class="`has-text-color-${tribe.color}`"
          pack="ubu"
          :icon="tribe.icon"
        />
        <span
          :class="`ml-2 has-text-color-${tribe.color}`"
          class="ml-2"
        >
          {{ tribe.name }}
        </span>
        <b-icon
          class="ml-2 clickable text-color-0"
          pack="ubu"
          icon="ubu-settings"
          @click.native="openTribeSettingsPanel = !openTribeSettingsPanel"
        />
      </h1>

      <nav class="end buttons expandable">
        <b-button
          v-for="item in navigation"
          :key="item.path"
          tag="router-link"
          type="is-simple"
          :to="item.path"
          :active="$route.fullPath.includes(item.path)"
        >
          {{ item.label }}
        </b-button>
      </nav>
    </header>

    <router-view
      v-if="!isLoading"
      class="TheTribeBody"
    />

    <TheTribeSettingsPanel
      v-if="openTribeSettingsPanel"
      @closePanel="openTribeSettingsPanel = false"
    />

    <div
      v-if="isBackgroundLocked"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheTribe',
  data() {
    return {
      isLoading: false,
      openTribeSettingsPanel: false,
    };
  },
  computed: {
    ...mapGetters({
      tribeList: 'TheTribe/tribeList',
    }),
    tribe() {
      return this.tribeList[this.$route.params.tribeTid];
    },
    navigation() {
      const basePath = `/tribe/${this.$route.params.tribeTid}`;
      return [
        {
          label: 'People',
          path: `${basePath}/people`,
        },
        {
          label: 'Messages',
          path: `${basePath}/messages/tribe/${this.$route.params.tribeTid}`,
        },
        {
          label: 'Mentions',
          path: `${basePath}/mentions`,
        },
        {
          label: 'Analytics',
          path: `${basePath}/analytics`,
        },
        // {
        //   label: 'Automations',
        //   path: `${basePath}/automations`,
        // },
      ];
    },
    isBackgroundLocked() {
      // if (this.bulkIsOpen && this.copyChecked.length) return true;
      if (this.openTribeSettingsPanel) return true;
      // if (this.contactPlanelSelectedPeople) return true;
      return false;
    },
  },
  beforeMount() {
    return this._resetContactPlatformOverviewList();
  },
  mounted() {
    this.isLoading = true;
    return this.getTribe({
      payload: { tid: this.$route.params.tribeTid },
      httpQuery: { memberGroupPosition: true, overview: true },
    })
      .then(() => {
        this.isLoading = false;
      });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.tribeTid === from.params.tribeTid) return next();

    this.isLoading = true;
    return Promise.resolve()
      .then(() => this._resetTribeMemberList())
      .then(() => this.getTribe({
        payload: { tid: to.params.tribeTid },
        httpQuery: { memberGroupPosition: true, overview: true },
      }))
      .then(() => {
        this.isLoading = false;
        return next();
      });
  },
  beforeDestroy() {
    return Promise.all([
      this._resetTribeMemberList(),
      this._resetTribeMentionList(),
    ]);
  },
  methods: {
    ...mapActions({
      _resetContactPlatformOverviewList: 'ApiContact/contactPlatformOverview/resetContactPlatformOverviewList',
      getTribe: 'TheTribe/getTribe',
      _resetTribeMemberList: 'ApiTribe/tribeMember/resetTribeMemberList',
      _resetTribeMentionList: 'ApiTribe/mention/resetTribeMentionList',
    }),
  },
};
</script>
