/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    affiliationCodeFolderList: {},
  },
  mutations: {
    SET_AFFILIATION_CODE_FOLDER_LIST(state, newData) { state.affiliationCodeFolderList = newData; },
  },
  getters: {
    getterAffiliationCodeFolderList: (state) => state.affiliationCodeFolderList,
  },
  actions: {
    report({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.affiliationCodeFolder.report({ payload, httpQuery })
        .then((affiliationCodeFolderList) => {
          commit('SET_AFFILIATION_CODE_FOLDER_LIST', affiliationCodeFolderList.arrayMapper('acfid'));
          return affiliationCodeFolderList;
        });
    },

    create({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.affiliationCodeFolder.post({ payload, httpQuery })
        .then((affiliationCodeFolder) => {
          const { getterAffiliationCodeFolderList } = getters;

          commit('SET_AFFILIATION_CODE_FOLDER_LIST', { ...getterAffiliationCodeFolderList, [affiliationCodeFolder.acfid]: affiliationCodeFolder });

          return affiliationCodeFolder;
        });
    },

    update({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.affiliationCodeFolder.put({ payload, httpQuery })
        .then((affiliationCodeFolder) => {
          const { getterAffiliationCodeFolderList } = getters;

          commit('SET_AFFILIATION_CODE_FOLDER_LIST', { ...getterAffiliationCodeFolderList, [affiliationCodeFolder.acfid]: affiliationCodeFolder });

          return affiliationCodeFolder;
        });
    },

    delete({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.affiliationCodeFolder.delete({ payload, httpQuery })
        .then(() => {
          const { getterAffiliationCodeFolderList } = getters;

          const filtered = Object.values(getterAffiliationCodeFolderList).filter(({ acfid }) => acfid !== payload.acfid);

          commit('SET_AFFILIATION_CODE_FOLDER_LIST', filtered.arrayMapper('acfid'));
        });
    },
  },
};
