import moment from 'moment';
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    campaignList: {},
    currentCampaignCid: null,
  },
  mutations: {
    SET_CAMPAIGN_LIST(state, newData) { state.campaignList = Object.values(state.campaignList).concat(newData).arrayMapper('cid'); },
    SET_CURRENT_CAMPAIGN_CID(state, newData) { state.currentCampaignCid = newData; },
    UNSET_CAMPAIGN(state, newData) { state.campaignList = Object.values(state.campaignList).filter(({ cid }) => cid !== newData).arrayMapper('cid'); },
    RESET_STORE(state) {
      state.campaignList = {};
      state.currentCampaignCid = null;
    },
  },
  getters: {
    getterCampaignList: (state) => state.campaignList,
    getterCurrentCampaignCid: (state) => state.currentCampaignCid,
  },
  actions: {
    UP_CurrentCampaignCid({ commit }, cid) {
      commit('SET_CURRENT_CAMPAIGN_CID', cid);
    },

    up_campaignList({ commit }, campaign) {
      commit('SET_CAMPAIGN_LIST', campaign);
    },

    end_campaign({ commit, getters }, cid) {
      const { getterCampaignList } = getters;

      const campaign = getterCampaignList[cid];

      commit('SET_CAMPAIGN_LIST', { ...campaign, endedOn: moment().format() });
    },

    get({ commit, rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'CAMPAIGN.GET')) return undefined;

      return clark.api.apiInfluence.campaign.get({ payload, httpQuery })
        .then((campaign) => { commit('SET_CAMPAIGN_LIST', campaign); });
    },

    report({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'CAMPAIGN.REPORT')) return undefined;

      return clark.api.apiInfluence.campaign.report({ payload, httpQuery: {} })
        .then((campaigns) => { commit('SET_CAMPAIGN_LIST', campaigns); });
    },

    create({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'CAMPAIGN.CREATE')) return undefined;

      return clark.api.apiInfluence.campaign.post({ payload, httpQuery: {} })
        .then((campaign) => { commit('SET_CAMPAIGN_LIST', [campaign]); });
    },

    update({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'CAMPAIGN.UPDATE')) return undefined;

      return clark.api.apiInfluence.campaign.put({ payload, httpQuery: {} })
        .then((campaign) => { commit('SET_CAMPAIGN_LIST', [campaign]); });
    },

    delete({ commit, rootGetters, getters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'CAMPAIGN.DELETE')) return undefined;

      const { cid } = payload;

      return clark.api.apiInfluence.campaign.delete({ payload, httpQuery: {} })
        .then(() => {
          commit('SET_CAMPAIGN_LIST', [{ ...getters.getterCampaignList[cid], endedOn: moment().format() }]);
        });
    },
  },
};
