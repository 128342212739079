<template>
  <div class="ubuAnalytics">
    <template v-if="hasAnalyticsFeature">
      <span class="subtext is-3 has-text-secondary">Compare with:</span>

      <UbuDateRangePicker
        :max-date="maxDate"
        :option-date-to-use="optionDateToUse"
        @changeDate="changeDate($event)"
      />

      <br>

      <div class="buttons">
        <b-button
          label="Download analytics"
          type="is-primary"
          @click="openCloseDownloadPanel()"
        />
      </div>

      <b-tooltip
        v-if="messageNotConnected"
        position="is-bottom"
        multilined
        expanded
        size="is-large"
        :label="tooltipDaysConnected"
        style="width: 100%;"
      >
        <b-message
          type="is-danger"
        >
          <p class="text">
            {{ messageNotConnected }}
          </p>
        </b-message>
      </b-tooltip>

      <UbuExportAnalyticsPanel
        v-if="isOpenDownloadPanel"
        v-model="newExport"
        @export="exportAnalytics()"
        @closePanel="closePanel()"
      />
      <div
        style="height: calc(100vh - 280px); overflow-y: auto;"
      >
        <div
          v-if="currentAnalytics.overview
            || followersGrowthFormated
            || engagementRateFormated
            || graphLoading"
        >
          <h2 class="subtitle">Overview</h2>
          <UbuAnalyticsGrid :analytics="currentAnalytics.overview" />
          <div class="analyticsGrid is-graph col-2">
            <UbuChartLine
              v-if="followersGrowthFormated"
              class="ubuAnalyticsGraphCard"
              chart-id="followers-growth"
              title="Followers Growth"
              :data="followersGrowthFormated"
            />

            <UbuChartLine
              v-if="engagementRateFormated"
              class="ubuAnalyticsGraphCard"
              chart-id="engagement-rate"
              title="Engagement rate"
              :data="engagementRateFormated"
            />
          </div>
        </div>

        <div v-if="currentAnalytics.followers">
          <h2 class="subtitle">Followers</h2>
          <UbuAnalyticsGrid :analytics="currentAnalytics.followers" />
        </div>

        <div v-if="currentAnalytics.engagement">
          <h2 class="subtitle">Engagement</h2>
          <UbuAnalyticsGrid :analytics="currentAnalytics.engagement" />
        </div>

        <div v-if="currentAnalytics.posts && currentAnalytics.posts.length">
          <h2 class="subtitle">Posts</h2>
          <div class="mediasScroller">
            <UbuAnalyticsPostCard
              v-for="(item, index) in currentAnalytics.posts"
              :key="index"
              :media="item"
              :loading="item.loading"
              @show="openModalMedia(item)"
            />
          </div>
        </div>

        <div v-if="currentAnalytics.stories && currentAnalytics.stories.length">
          <h2 class="subtitle">Stories</h2>
          <div class="mediasScroller">
            <UbuAnalyticsPostCard
              v-for="(item, index) in currentAnalytics.stories"
              :key="index"
              :media="item"
              is-story
              @show="openModalMedia(item)"
            />
          </div>
        </div>

        <div v-if="currentAnalytics.visibility">
          <h2 class="subtitle">Visibility</h2>
          <UbuAnalyticsGrid :analytics="currentAnalytics.visibility" />
        </div>

        <div v-if="currentAnalytics.callToAction">
          <h2 class="subtitle">Call to actions</h2>
          <UbuAnalyticsGrid :analytics="currentAnalytics.callToAction" />
        </div>

        <TheMentionViewMedia
          v-if="openModal"
          v-model="openModal"
          :mention-list="mediaListForModal"
          :index="modalIndex"
          view="analytics"
          platform-name="INSTAGRAM"
          :mention-type="modalType"
          @close="modalIndex = 0; openModal = false;"
        />
      </div>
    </template>

    <UbuExtensionDisconnected
      v-else
      :has-extension="false"
      feature="analytics"
    />
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import { mapActions, mapGetters } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';
import { analyticsCatalog } from './$catalog/analytics';

export default {
  name: 'TheAnalyticsAccount',
  data() {
    return {
      isOpenDownloadPanel: false,
      openModal: false,
      modalIndex: 0,
      modalType: 'story',
      loading: false,
      graphLoading: false,
      selectedRange: {},
      saveDatePickerCurrentDateRange: {
        minDate: this.$moment().subtract({ week: 1 }).toDate(),
        maxDate: this.$moment().toDate(),
      },
      newExport: {
        minDate: null,
        maxDate: null,
        emails: null,
      },
      optionDateToUse: ['currentWeek', 'previousWeek', 'currentMonth', 'previousMonth'],
    };
  },
  computed: {
    ...mapGetters({
      accountAnalytics: 'TheAnalytics/accountAnalytics',
      followersEngagementAnalytics: 'TheAnalytics/followersEngagementAnalytics',
      currentUser: 'TheAuthenticate/myAuthenticate',
      accountFeatures: 'TheAnalytics/accountFeatures',
    }),
    hasAnalyticsFeature() {
      return this.accountFeatures('ANALYTICS');
    },
    currentUserEmail() {
      return this.currentUser.passName;
    },
    messageNotConnected() {
      if (!this.accountAnalytics || !this.accountAnalytics.daysConnected
      || !this.selectedRange.currentPeriod || this.loading) return null;
      const nbDaysConnected = this.accountAnalytics.daysConnected.length;
      const { minDate, maxDate } = this.selectedRange.currentPeriod;
      const nbDaysPeriod = this.$moment(maxDate).diff(this.$moment(minDate), 'days') + 1;
      const nbDaysMissing = nbDaysPeriod - nbDaysConnected;
      if (nbDaysMissing <= 1) return null;
      if (!nbDaysConnected) return 'You were not connected during this period';
      return 'Some days are missing on this period';
      // return `${nbDaysMissing} day${nbDaysMissing > 1 ? 's' : ''} missing on this period (${nbDaysPeriod} days)`;
    },
    tooltipDaysConnected() {
      if (!this.accountAnalytics || !this.accountAnalytics.daysConnected
      || !this.selectedRange.currentPeriod || this.loading) return null;
      const { daysConnected } = this.accountAnalytics;
      return `Days connected: ${daysConnected.map((date) => this.$moment(date).format('YYYY-MM-DD')).join(', ')}`;
    },
    followersGrowthFormated() {
      if (!this.followersEngagementAnalytics) return null;

      const { dates, followers } = this.followersEngagementAnalytics;

      if (!dates || !followers || !followers.filter((value) => value).length) return null;

      return {
        labels: dates.map((date) => this.$moment(date).format('YYYY-MM-DD')),
        datasets: [{
          data: followers.map((value) => value),
        }],
      };
    },
    engagementRateFormated() {
      if (!this.followersEngagementAnalytics) return null;

      const { dates, engagements } = this.followersEngagementAnalytics;

      if (!dates || !engagements || !engagements.filter((value) => value).length) return null;

      return {
        labels: dates.map((date) => this.$moment(date).format('YYYY-MM-DD')),
        datasets: [{
          data: engagements.map((value) => value),
        }],
      };
    },
    currentAnalytics() {
      if (!this.loading) return this.accountAnalytics;
      const loadingAnalytics = Object.values(analyticsCatalog).reduce((acc, stat) => {
        if (stat.type === 'account') {
          if (stat.subtype.find(({ type: t }) => t === 'overview')) {
            const currentRowSubtypeFromCatalog = stat.subtype.find(({ type: t }) => t === 'overview');

            acc.overview.push({
              ...stat,
              size: currentRowSubtypeFromCatalog.size,
              ordinal: currentRowSubtypeFromCatalog.ordinal,
              loading: true,
            });

            acc.overview = acc.overview.sort((a, b) => a.ordinal - b.ordinal);
          }
          if (stat.subtype.find(({ type: t }) => t === 'followers')) {
            const currentRowSubtypeFromCatalog = stat.subtype.find(({ type: t }) => t === 'followers');

            acc.followers.push({
              ...stat,
              size: currentRowSubtypeFromCatalog.size,
              ordinal: currentRowSubtypeFromCatalog.ordinal,
              loading: true,
            });

            acc.followers = acc.followers.sort((a, b) => a.ordinal - b.ordinal);
          }
          if (stat.subtype.find(({ type: t }) => t === 'engagement')) {
            const currentRowSubtypeFromCatalog = stat.subtype.find(({ type: t }) => t === 'engagement');

            acc.engagement.push({
              ...stat,
              size: currentRowSubtypeFromCatalog.size,
              ordinal: currentRowSubtypeFromCatalog.ordinal,
              loading: true,
            });

            acc.engagement = acc.engagement.sort((a, b) => a.ordinal - b.ordinal);
          }
          if (stat.subtype.find(({ type: t }) => t === 'visibility')) {
            const currentRowSubtypeFromCatalog = stat.subtype.find(({ type: t }) => t === 'visibility');

            acc.visibility.push({
              ...stat,
              size: currentRowSubtypeFromCatalog.size,
              ordinal: currentRowSubtypeFromCatalog.ordinal,
              loading: true,
            });

            acc.visibility = acc.visibility.sort((a, b) => a.ordinal - b.ordinal);
          }
          if (stat.subtype.find(({ type: t }) => t === 'callToAction')) {
            const currentRowSubtypeFromCatalog = stat.subtype.find(({ type: t }) => t === 'callToAction');

            acc.callToAction.push({
              ...stat,
              size: currentRowSubtypeFromCatalog.size,
              ordinal: currentRowSubtypeFromCatalog.ordinal,
              loading: true,
            });

            acc.callToAction = acc.callToAction.sort((a, b) => a.ordinal - b.ordinal);
          }
          return acc;
        }
        return acc;
      }, {
        overview: [],
        followers: [],
        engagement: [],
        visibility: [],
        callToAction: [],
        posts: [],
        stories: [],
      });
      const loadingMedias = Array(5).fill({ loading: true });
      loadingAnalytics.posts.push(...loadingMedias);
      loadingAnalytics.stories.push(...loadingMedias);
      return loadingAnalytics;
    },
    maxDate() {
      return this.$moment().toDate();
    },
    mediaListForModal() {
      return this.modalType === 'story' ? this.accountAnalytics.stories : this.accountAnalytics.posts;
    },
  },
  mounted() {
    if (!this.hasAnalyticsFeature) return;

    this.getAccountAnalytics({
      currentPeriod: {
        minDate: this.$moment().subtract({ week: 1 }),
        maxDate: this.$moment(),
      },
      previousPeriod: {
        minDate: this.$moment().subtract({ week: 2 }),
        maxDate: this.$moment().subtract({ week: 1 }),
      },
    });

    this.newExport.emails = this.currentUser.passName;
  },
  methods: {
    ...mapActions({
      _getAccountAnalytics: 'TheAnalytics/getAccountAnalytics',
      exportAnalyticsXlsx: 'TheAnalytics/exportAnalyticsXlsx',
      getFollowersEngagementAnalytics: 'TheAnalytics/getFollowersEngagementAnalytics',
    }),

    openCloseDownloadPanel() {
      if (!this.isOpenDownloadPanel) {
        this.newExport = {
          ...this.newExport,
          ...this.saveDatePickerCurrentDateRange,
        };
      }
      this.isOpenDownloadPanel = !this.isOpenDownloadPanel;
    },

    changeDate({ value, lastPeriod }) {
      const [min, max] = value;
      const diffBetween = this.$moment(min).diff(max, 'days');
      const formatedDateRange = [this.$moment(min).toDate(), this.$moment(max)
        .add({ days: diffBetween === 0 ? 1 : 0 }).toDate()];
      this.newExport.minDate = formatedDateRange[0];
      this.newExport.maxDate = formatedDateRange[1];
      this.saveDatePickerCurrentDateRange.minDate = formatedDateRange[0];
      this.saveDatePickerCurrentDateRange.maxDate = formatedDateRange[1];
      this.getAccountAnalytics({
        currentPeriod: { minDate: formatedDateRange[0], maxDate: formatedDateRange[1] },
        previousPeriod: { minDate: lastPeriod[0], maxDate: lastPeriod[1] },
      });
    },
    getAccountAnalytics({ currentPeriod, previousPeriod }) {
      this.loading = true;
      this.graphLoading = true;
      this.selectedRange = { currentPeriod, previousPeriod };
      const graphHttpQuery = {
        minDate: this.$moment(currentPeriod.minDate).format('YYYY-MM-DD'),
        maxDate: this.$moment(currentPeriod.maxDate).format('YYYY-MM-DD'),
      };
      Promise.all([
        this._getAccountAnalytics({
          httpQuery: {
            currentPeriod: [this.$moment(currentPeriod.minDate).format('YYYY-MM-DD'),
              this.$moment(currentPeriod.maxDate).format('YYYY-MM-DD')],
            previousPeriod: [this.$moment(previousPeriod.minDate).format('YYYY-MM-DD'),
              this.$moment(previousPeriod.maxDate).format('YYYY-MM-DD')],
            // isStub: true,
          },
        }).then(() => {
          this.loading = false;
        }),
        this.getFollowersEngagementAnalytics({
          httpQuery: { ...graphHttpQuery },
        }).then(() => {
          this.graphLoading = false;
        }),
      ]);
    },
    exportAnalytics() {
      const { emails, minDate, maxDate } = this.newExport;
      const recipients = emails.split('\n').filter((email) => email);
      const payload = {
        minDate: this.$moment(minDate).format('YYYY-MM-DD'),
        maxDate: this.$moment(maxDate).format('YYYY-MM-DD'),
        recipients,
      };

      this.exportAnalyticsXlsx({ payload })
        .then(() => {
          this.$buefy.snackbar.open({
            ...snackbarSuccess,
            message: 'You will receive an email soon!',
          });
        });
    },
    closePanel() {
      this.isOpenDownloadPanel = false;
      this.newExport.emails = this.currentUser.passName;
    },
    openModalMedia(media) {
      this.modalType = media.isStory ? 'story' : 'post';
      this.modalIndex = this.mediaListForModal
        .findIndex((element) => String(element.mediaUrl) === String(media.mediaUrl));
      this.openModal = true;
    },
  },
};
</script>
