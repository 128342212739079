<template>
  <div style="height: calc(100vh - 190px); overflow-y: auto;">
    <article class="flex wrap space-evenly p-4">
      <UbuAnalyticCard
        v-for="(analytic, index) in analyticCards"
        :key="index"
        class="is-horizontal mb-2"
        style="width: 350px;"
        :name="analytic.name"
        :value="analytic.value"
        :icon="analytic.icon"
        :color="analytic.color"
      />
    </article>

    <br>

    <article class="flex wrap space-evenly">
      <div class="graphContainers">
        <span class="text is-3 has-text-secondary">Active VS Ended</span>
        <UbuChartDoughnut
          v-if="activesVsEnded"
          class=""
          chart-id="activesVsEnded"
          :data="activesVsEnded"
        />
      </div>

      <div class="graphContainers">
        <span class="text is-3 has-text-secondary">Status distribution</span>
        <UbuChartDoughnut
          v-if="activitiesByStatus"
          class=""
          chart-id="activitiesByStatus"
          :data="activitiesByStatus"
        />
      </div>

      <div class="graphContainers">
        <span class="text is-3 has-text-secondary">People added by day</span>
        <UbuChartBar
          v-if="activitiesByDate"
          class=""
          chart-id="activitiesByDate"
          :data="activitiesByDate"
        />
      </div>
    </article>
  </div>
</template>

<script>
/* eslint-disable max-len */

import { mapGetters, mapActions } from 'vuex';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';

export default {
  name: 'TheCampaignAnalyticActivities',
  mixins: [TableUtilsMixin],
  computed: {
    ...mapGetters({
      activitiesStats: 'TheCampaign/activitiesStats',
    }),
    analyticCards() {
      if (!this.activitiesStats) return null;

      const cards = [];

      const engagedStatus = ['TALKING', 'IN_PROGRESS', 'SUCCESS', 'FAILURE', 'PRODUCT_SHIPPED', 'AWAITING_MENTION', 'COLLAB_AGREEMENT'];

      const { engaged, notEngaged } = this.activitiesStats.activities.reduce((acc, { status, ended }) => {
        if (engagedStatus.includes(status) && !ended) acc.engaged += 1;
        else acc.notEngaged += 1;
        return acc;
      }, { engaged: 0, notEngaged: 0 });

      cards.push({
        name: 'Total people in campaign', color: 12, icon: 'ubu-account', value: this.activitiesStats.activities.length,
      });

      cards.push({
        name: 'Total people engaged', color: 5, icon: 'ubu-influence', value: engaged,
      });

      cards.push({
        name: 'Total people not engaged', color: 2, icon: 'ubu-influence', value: notEngaged,
      });

      return cards;
    },
    activesVsEnded() {
      if (!this.activitiesStats) return null;
      return {
        labels: ['actives', 'ended'],
        datasets: [{
          data: [this.activitiesStats.actives, this.activitiesStats.ended],
          maintainAspectRatio: false,
          backgroundColor: [
            'rgba(107, 206, 61, 0.6)',
            'rgba(255, 96, 96, 0.6)',
          ],
        }],
      };
    },
    activitiesByStatus() {
      if (!this.activitiesStats) return null;
      return {
        labels: Object.keys(this.activitiesStats.byStatus),
        datasets: [{
          data: Object.values(this.activitiesStats.byStatus),
          maintainAspectRatio: false,
          backgroundColor: [
            'rgba(255, 98, 164, 0.6)',
            'rgba(255, 96, 96, 0.6)',
            'rgba(255, 164, 113, 0.6)',
            'rgba(255, 198, 112, 0.6)',
            'rgba(107, 206, 61, 0.6)',
            'rgba(115, 218, 138, 0.6)',
            'rgba(67, 210, 193, 0.6)',
            'rgba(60, 166, 242, 0.6)',
            'rgba(111, 109, 227, 0.6)',
            'rgba(185, 141, 240, 0.6)',
            'rgba(248, 130, 236, 0.6)',
            'rgba(77, 77, 77, 0.6)',
          ],
        }],
      };
    },
    activitiesByDate() {
      if (!this.activitiesStats) return null;
      return {
        labels: Object.keys(this.activitiesStats.byDate),
        datasets: [{
          data: Object.values(this.activitiesStats.byDate),
          backgroundColor: ['rgba(255, 164, 113, 0.6)'],
          maxBarThickness: 150,
        }],
      };
    },
  },
  mounted() {
    return this._report_activitiesStats({ payload: { campaignCid: this.$route.params.campaignCid } });
  },
  methods: {
    ...mapActions({
      _report_activitiesStats: 'TheCampaign/report_activitiesStats',
    }),
  },
};
</script>
