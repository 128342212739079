<template>
  <fieldset>
    <b-field
      horizontal
      label="Phone number"
    >
      <vue-tel-input
        v-model="newChannel.phoneNumber"
        default-country="fr"
        :preferred-countries="preferredCountries"
        :input-options="inputOptions"
        :auto-format="false"
        invalid-msg="Invalid phone number"
        valid-characters-only
        @country-changed="changeCodeCountry($event)"
        @validate="validPhoneNumberMethod($event)"
      />
    </b-field>
    <b-field
      horizontal
      label="Account SID"
    >
      <b-input
        v-model="newChannel.accountSid"
        required
      />
    </b-field>
    <b-field
      horizontal
      label="Auth token"
    >
      <b-input
        v-model="newChannel.authToken"
        required
      />
    </b-field>
    <b-field
      horizontal
      label="Channel Name"
    >
      <b-input
        v-model="newChannel.name"
        required
      />
    </b-field>
  </fieldset>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuAccountAddChannelStepFormManageChannelSms',
  mixins: [VModelMixin],
  model: {
    prop: 'newChannel',
  },
  props: {
    newChannel: { type: Object, default: () => {} },
  },
  data() {
    return {
      input: null,
      validPhoneNumber: null,
    };
  },
  computed: {
    preferredCountries() {
      return ['fr', 'us', 'br', 'gb', 'jp'];
    },
    inputOptions() {
      return {
        placeholder: 'Enter a Twilio phone number',
        required: true,
        showDialCode: true,
        mode: 'national',
        styleClasses: [
          {
            'is-danger': this.newChannel.validPhoneNumber === false,
            'is-success': this.newChannel.validPhoneNumber === true,
          },
        ],
        // readonly: true
      };
    },
  },
  methods: {
    changeCodeCountry({ dialCode }) {
      this.newChannel.codeCountry = `+${dialCode}`;
    },
    validPhoneNumberMethod({ valid, formatted }) {
      this.newChannel.validPhoneNumber = valid;
      this.newChannel.phoneNumber = formatted.replace(/ /g, '');
    },
  },
};
</script>
