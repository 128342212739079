import contactPlatformOverview from './contactPlatformOverview';
import contactPlatformMention from './contactPlatformMention';
import contactPlatformStatistic from './contactPlatformStatistic';
import contactPrivateMetadata from './contactPrivateMetadata';
import contactPlatformAffiliation from './contactPlatformAffiliation';
import contactGroup from './contactGroup';

export default {
  contactPlatformOverview,
  contactPlatformMention,
  contactPlatformStatistic,
  contactPrivateMetadata,
  contactPlatformAffiliation,
  contactGroup,
};
