/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    post({ rootGetters, commit }, { payload, httpQuery }) {
      return clark.api.account.setFeature.post({ payload, httpQuery })
        .then((features) => {
          const { 'Account/getterAccountEvent': accountEvent } = rootGetters;
          const { 'Account/getterAccountFeature': accountFeature } = rootGetters;

          const newFeatures = features.map(({ accountAid, featureName }) => ({
            accountAid,
            featureName,
          }));

          const newAccountEvent = accountEvent.concat(features);
          const newAccountFeature = accountFeature.concat(newFeatures);

          commit('Account/SET_ACCOUNT_EVENT', newAccountEvent, { root: true });
          commit('Account/SET_ACCOUNT_FEATURE', newAccountFeature, { root: true });
        });
    },
    delete({ rootGetters, commit }, { payload, httpQuery }) {
      return clark.api.account.setFeature.delete({ payload, httpQuery })
        .then((features) => {
          const { 'Account/getterAccountEvent': accountEvent } = rootGetters;
          const { 'Account/getterAccountFeature': accountFeature } = rootGetters;

          const removeFeatures = features.map(({ featureName }) => featureName);

          const newAccountEvent = accountEvent.concat(features);

          const newFeatures = accountFeature.reduce((acc, feature) => {
            if (removeFeatures.includes(feature.featureName)) return acc;
            acc.push(feature);

            return acc;
          }, []);

          commit('Account/SET_ACCOUNT_EVENT', newAccountEvent, { root: true });
          commit('Account/SET_ACCOUNT_FEATURE', newFeatures, { root: true });
        });
    },
  },
  modules: {},
};
