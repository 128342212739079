<template>
  <section v-if="checkedRows.length">
    <header>
      <p class="text has-text-secondary start">Add to list</p>

      <h1 class="title is-1">{{ tabTitle }}</h1>
    </header>

    <br>

    <DevDetails :cnt="{name: 'checkedRows', obj: checkedRows }" />
    <DevDetails :cnt="{name: 'crmListList', obj: crmListList }" />
    <DevDetails :cnt="{name: 'notInList', obj: notInList }" />
    <DevDetails :cnt="{name: 'crmListActives', obj: crmListActives }" />
    <DevDetails :cnt="{name: 'contactPlatformList', obj: contactPlatformList }" />
    <DevDetails :cnt="{name: 'contactPlatformsToAddOnList', obj: contactPlatformsToAddOnList }" />

    <b-tabs
      v-model="localActiveTab"
      class="no-header"
    >
      <UbuBulkAddToListSelectList
        :crm-list-list="crmListList"
        @selectListAndChangeTab="selectListAndChangeTab($event ,localActiveTab)"
      />

      <UbuBulkAddToListAlreadyInList
        :contact-platform-list.sync="alreadyInList"
        @changeTab="changeTab()"
      />

      <UbuBulkAddToListSelectContactPlatform
        :contact-platform-list.sync="notInList"
        :contact-platforms-to-add-on-list.sync="contactPlatformsToAddOnList"
        @changeTab="changeTabAndSetPeopleToAddList()"
      />

      <b-tab-item>
        <div class="buttons is-right">
          <b-button
            label="Close"
            type="is-primary"
            @click="buttonCloseOnSummary()"
          />

          <!-- <b-button
            label="Go to list"
            type="is-primary"
            @click="buttonGoToListOnSummary()"
          /> -->
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheBulkActionsAddToListWizard',
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      listToBulk: null,
      // stillHasToBeAdded: 0,
      // addToListIsDone: false,
      contactPlatformsToAddOnList: [],
    };
  },
  computed: {
    ...mapGetters({
      crmListList: 'TheCRM/crmListList',
    }),
    localChecked: {
      get() { return this.checkedRows; },
      set(value) { this.$emit('input', value); },
    },
    localActiveTab: {
      get() { return this.activeTab; },
      set(val) { this.$emit('update:activeTab', val); },
    },
    contactPlatformList() {
      if (!this.listToBulk) return [];

      return this.localChecked.flatMap(({ contactPlatform }) => contactPlatform);
    },
    crmListActives() {
      if (!this.listToBulk) return [];

      return this.localChecked
        .reduce((acc, { crmLists }) => {
          if (!crmLists) return acc;
          // crmLists is an array into each checked row
          crmLists.forEach(({ clid, cpid }) => {
            if (String(clid) === String(this.listToBulk.clid)) {
              acc.push(cpid);
            }
          });
          return acc;
        }, []);
    },
    alreadyInList() {
      if (!this.crmListActives.length) return [];

      return this.contactPlatformList.filter(({ cpid }) => this.crmListActives.includes(cpid));
    },
    notInList() {
      const notInList = this.contactPlatformList
        .filter(({ cpid }) => !this.crmListActives.includes(cpid));
      this.setContactPlatformsToAddOnListCheckedRows(notInList);

      return notInList;
    },
    tabTitle() {
      let tabTitle;
      switch (this.localActiveTab) {
        case 0: tabTitle = 'Select a list'; break;
        case 1: tabTitle = 'People already in list'; break;
        case 2: tabTitle = 'Who do you want to add ?'; break;
        case 3: tabTitle = 'Success!'; break;

        default: tabTitle = 'Select a list'; break;
      }
      return tabTitle;
    },
  },
  methods: {
    ...mapActions({
      fillListByContactPlatform: 'TheCRM/fillListByContactPlatform',
    }),
    changeTab() {
      this.localActiveTab = this.localActiveTab + 1;
    },
    selectListAndChangeTab(list, currentTab) {
      this.listToBulk = list;
      this.localActiveTab = currentTab + 1;
    },
    setContactPlatformsToAddOnListCheckedRows(cpList) {
      this.contactPlatformsToAddOnList = cpList;
    },
    changeTabAndSetPeopleToAddList() {
      this.localActiveTab = this.localActiveTab + 1;
      this.$emit('isWizardApplied', false);

      return this.fillListByContactPlatform({
        payload: {
          clid: this.listToBulk.clid,
          contactPlatformList: this.contactPlatformsToAddOnList.map(({ cpid }) => ({ cpid })),
        },
      });
    },

    buttonCloseOnSummary() {
      this.$emit('reloadCRM');
      this.$router.push({ path: '/crm', query: this.$route.query });
    },
    // buttonGoToListOnSummary() {
    //   const queries = { ...this.$route.query, crmLists: this.listToBulk.clid };

    //   this.$router.push({ path: '/crm', query: queries });
    //   this.$emit('reloadCRM', queries);
    // },
  },
};
</script>
