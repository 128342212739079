<template>
  <b-field>
    <b-button
      v-if="!inputMode"
      :label="label"
      type="is-ghost is-borderless"
      @click="switchToInput()"
    />

    <b-input
      v-show="inputMode"
      ref="input"
      v-model="localValue"
      :placeholder="label"
      icon-pack="ubu"
      icon-right="ubu-cross"
      icon-right-clickable
      @icon-right-click="switchToButton()"
    />
  </b-field>
</template>

<script>
export default {
  name: 'UbuButtonSwitchToInput',
  model: {
    prop: 'value',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inputMode: false,
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(val) { this.$emit('input', val); },
    },
  },
  methods: {
    switchToInput() {
      this.inputMode = true;
      this.$refs.input.focus();
    },

    switchToButton() {
      this.inputMode = false;
      // clear input on switch
      this.$emit('input', '');
    },
  },
};
</script>
