<template>
  <aside class="ubuSidePanel ubuPanel-xl ">
    <div class="panelHeader flex justify-content-self-between is-flex-direction-row">
      <b-button
        class="start is-ghost is-borderless"
        icon-pack="ubu"
        icon-left="ubu-arrow-left"
        label="Previous step"
        :disabled="!(activeTab >= 1)"
        @click="activeTab -= 1"
      />

      <b-icon
        class="is-align-self-flex-end is-clickable ml-auto"
        pack="ubu"
        icon="ubu-cross"
        size="is-medium"
        @click.native="closePanel()"
      />
    </div>

    <div class="ubuSidePanel-content">
      <div
        v-if="isPrivateDisconnected === false"
        class="content mt-2"
      >
        <b-tabs
          v-model="activeTab"
          class="no-header"
        >
          <b-tab-item class="no-padding">
            <h5 class="subtitle has-text-secondary">New message</h5>

            <h1 class="title is-1">Who do you want to send a message to?</h1>

            <br>

            <div>
              <b-input
                :value="search"
                :loading="isLoading"
                placeholder="Search Instagram username..."
                type="search"
                icon="ubu-search"
                icon-pack="ubu"
                @input="reportInstagramUsernames($event)"
                @select="selectOption($event)"
              />

              <br>

              <div
                v-for="user in users"
                :key="user.username"
                class="multibar row-vcentered ubuInstagramUserSearch"
              >
                <UbuThreadListUserPicture
                  class="start profilePic"
                  :profile-picture="`https://io.getubu.com/profile_pic_insta/${user.extrefPk}`"
                  :initial-name="user.username"
                />

                <div class="start">
                  <div class="text is-3">
                    {{ user.name }}
                    <b-icon
                      v-if="!!user.isCertified"
                      pack="ubu"
                      icon="ubu-success"
                    />
                  </div>

                  <div class="subtext">
                    @{{ user.username }}
                  </div>
                </div>

                <div class="end">
                  <b-button
                    label="select"
                    @click="selectOption(user)"
                  />
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item>
            <h5 class="subtitle has-text-secondary">New message</h5>

            <h1 class="title is-1">Let's get creative!</h1>

            <br>

            <div
              v-if="selected"
              class="multibar row-vcentered ubuInstagramUserSearch"
            >
              <UbuThreadListUserPicture
                class="start profilePic"
                :profile-picture="`https://io.getubu.com/profile_pic_insta/${selected.extrefPk}`"
                :initial-name="selected.username"
              />

              <div class="start">
                <div class="text is-3">
                  {{ selected.name }}
                  <b-icon
                    v-if="!!selected.isCertified"
                    pack="ubu"
                    icon="ubu-success"
                  />
                </div>

                <div class="subtext">
                  @{{ selected.username }}
                </div>
              </div>
            </div>

            <div class="messageArea mt-5">
              <TheTextarea
                v-model="textareaHeight"
                class="start theTextarea"
                :search="selected ? selected.username : null"
                :reach-mode="true"
                :loading="isLoading"
                :dropdown-position="dropdownPosition"
                @openTemplateManager="$emit('openTemplateManager')"
                @reachMessageSent="reachMessageSent($event)"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <h5 class="subtitle has-text-secondary">New message</h5>

            <h1 class="title is-1 mb-6">Let's get creative!</h1>

            <b-notification
              v-if="isSent"
              type="is-success"
              :closable="false"
            >
              <b-icon
                pack="ubu"
                icon="ubu-success"
              />
              Message sent
            </b-notification>

            <b-notification
              v-else
              type="is-danger"
              :closable="false"
            >
              <b-icon
                pack="ubu"
                icon="ubu-failure"
              />
              Message not sent
            </b-notification>
          </b-tab-item>
        </b-tabs>
      </div>

      <div v-else>
        <h5 class="subtitle has-text-secondary">New message</h5>
        <h1 class="title is-1 mb-6">Who do you want to send a message to?</h1>
        <UbuExtensionDisconnected
          :has-extension="isPrivateDisconnected === null ? false : true"
          feature="sendHooks"
        />
      </div>
    </div>
  </aside>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable func-names */
import { mapGetters, mapActions } from 'vuex';
import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive';
import { debounce } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheSendNewMessagePanel',
  directives: {
    autogrow: TextareaAutogrowDirective,
  },
  props: {
    username: {
      type: String,
      default: '',
    },
    profilePicture: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeTab: 0,
      search: '',
      isLoading: false,
      textareaHeight: 500,
      users: [],
      selected: null,
      isSent: null,
    };
  },
  computed: {
    ...mapGetters({
      instagramUsernameList: 'TheThreadPanel/instagramUsernameList',
      isPrivateDisconnected: 'TheSendNewMessagePanel/isPrivateDisconnected',
    }),
    dropdownPosition() {
      return {
        emoji: 'is-bottom-left',
        template: 'is-bottom-right',
        affiliations: 'is-bottom-right',
        translate: 'is-bottom-right',
      };
    },
  },

  mounted() {
    this.search = this.username ? this.username : '';
    if (this.username) {
      this.activeTab += 1;
      this.selected = { username: this.username, name: this.username, profilePicture: this.profilePicture };
    }
  },
  methods: {
    ...mapActions({
      _reportInstagramUsernames: 'TheThreadPanel/reportInstagramUsernames',
    }),
    selectOption(option) {
      this.selected = option;
      this.search = option.username;
      this.activeTab += 1;
    },

    reportInstagramUsernames: debounce(function (name) {
      if (!name.length) {
        this.users = [];
        return;
      }
      this.isLoading = true;
      this._reportInstagramUsernames({
        payload: { username: name },
        httpQuery: { onInstagram: 'true' },
      })
        .then(() => {
          this.users = this.instagramUsernameList;
        })
        .catch((error) => {
          this.users = [];
          throw error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 500),

    closePanel() {
      this.search = '';
      this.$emit('closePanel');
      this.activeTab = 0;
    },

    reachMessageSent(event) {
      this.activeTab += 1;
      this.isSent = event;
    },
  },
};
</script>
