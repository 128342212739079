<template>
  <UbuCustomChip
    :model="{
      title: 'Lists',
      defaultIcon: 'ubu-custom-lists',
      count: cpo[customsName].length > 1 ? cpo[customsName].length : null,
      listItem: cpo[customsName].map((id) => ({
        icon: 'ubu-custom-lists',
        color: customs[id].color,
        label: customs[id].name,
      }))
    }"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheCrmListChip',
  props: {
    cpo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      customsName: 'crmLists',
    };
  },
  computed: {
    ...mapGetters({
      customs: 'TheCrmListFilterDropdown/crmListList',
    }),
  },
};
</script>
