export default {
  computed: {
    value() {
      const nestings = this.field.split('.');
      const value = nestings.reduce((acc, it) => {
        if (acc) return acc[it];
        if (!acc && this.cpo[it]) return this.cpo[it];

        return acc;
      }, undefined);

      return value;
    },
  },
};
