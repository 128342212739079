/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const instagramReach = {
  post: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.cid}/instagramReach`, method: 'POST', payload, httpQuery, target }),
};

export default instagramReach;
