import { mapActions } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  methods: {
    ...mapActions({
      downloadMention: 'TheInfluenceMentions/downloadMention',
    }),
    downloadMentions(mentions, email) {
      // remove isStub when in prod
      this.downloadMention({
        payload: {
          medias: mentions.map(({ mainMedia }) => ({ extrefId: mainMedia.extrefId })),
          recipients: [email],
        },
        httpQuery: { isStub: true },
      })
        .then(() => {
          this.$buefy.snackbar.open({
            ...snackbarSuccess,
            message: 'Success! You will receive an email shortly.',
          });
          this.message = {
            type: 'success',
            content: 'Success! Please note that it can take up to a few minutes before you receive your email. Contact support if you still haven’t received it.',
          };
        })
        .catch(({ message }) => {
          switch (message) {
            case 'E_BAD_REQUEST_CHANNEL_GROUP_DOWNLOAD_MENTION_SCHEMA':
              this.message = {
                type: 'danger',
                content: 'An error has occurred due to the data being sent. Please contact support',
              };
              break;
            case 'E_BAD_REQUEST_CHANNEL_GROUP_DOWNLOAD_MENTION_MUST_BE_LESS_THAN_1000_ENTRIES':
              this.message = {
                type: 'danger',
                content: 'You can\'t download more than 1000 mentions at once... Download your mentions in multiple batches or contact support',
              };
              break;
            default:
              this.message = {
                type: 'danger',
                content: 'An unknown error has occurred. Please contact support',
              };
              break;
          }
        });
    },
  },
};
