import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    create(_, { payload, httpQuery = {} }) {
      return clark.api.apiConversation.threadAppoint.post({ payload, httpQuery });
    },
    delete(_, { payload, httpQuery = {} }) {
      return clark.api.apiConversation.threadAppoint.delete({ payload, httpQuery });
    },
  },
};
