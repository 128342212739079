<template>
  <UbuDropdown
    :close-on-click="false"
    :scrollable="true"
    :position="position"
    @open="isOpen = true"
    @close="reset(); isOpen = false;"
  >
    <template #button>
      <b-tooltip
        position="is-bottom"
        :multilined="!!tribesSplitByAssignment.assigneds.length"
        size="is-large"
        append-to-body
      >
        <template #content>
          <template v-if="tribesSplitByAssignment.assigneds.length">
            <div
              v-for="custom in tribesSplitByAssignment.assigneds"
              :key="custom.tid"
              class="multibar"
            >
              <div class="start multibar row-vcentered">
                <b-icon
                  :class="`has-text-color-${custom.color} mr-1 start`"
                  pack="ubu"
                  :icon="icon"
                />

                <span class="start mr-1">{{ custom.label }}</span>
                <span class="start">- {{ $moment(custom.createdOn).format('YYYY/MM/DD') }}</span>
              </div>
            </div>
          </template>

          <span v-else>Add to Tribe</span>
        </template>
        <UbuCustomButton
          :customs="Object.keys(usedTribes) .map((id) => tribeList[id])"
          :custom-default="{ icon }"
          :active-tooltip="true"
          :window-width="windowWidth"
        />
      </b-tooltip>
    </template>

    <template
      v-if="isOpen"
      #search
    >
      <b-dropdown-item custom>
        <UbuSearchbar
          v-model="search"
          placeholder="Search ..."
          @clear="reset()"
          @input="updateFilter($event)"
        />
      </b-dropdown-item>
    </template>

    <template
      v-if="activeTab !== 'root'"
      #header
    >
      <UbuDropdownBreadcrumbs
        class="mb-2"
        :breadcrumbs-options="breadcrumbs"
        :current-breadcrumb="breadcrumbs[breadcrumbs.length - 1]"
        @goBack="useBreadcrumb($event)"
      />
    </template>

    <template #content>
      <b-tabs
        v-model="activeTab"
        class="no-header paddingless"
      >
        <UbuDropdownCustomSelector
          :key-name="keyName"
          :assigneds="tribesSplitByAssignment.assigneds"
          :unassigneds="tribesSplitByAssignment.unassigneds"
          :icon-name="icon"
          @unassignAll="confirmUnassignAll()"
          @selectCustom="selectTribeAndGoNext($event)"
          @unselectCustom="selectTribeAndGoNext($event)"
          @changeTab="activeTab = $event"
        />

        <b-tab-item value="root.memberGroup">
          <b-dropdown-item custom>
            <template v-if="tribesSplitByAssignment.assigneds.length">
              <b-dropdown-item
                class="no-hover-background"
                @click="removeFromTribe()"
              >
                <span class="subtext has-text-primary">Remove from Tribe</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-for="custom in memberGroupSplitByAssignment.assigneds"
                :key="custom.mgid"
                @click="removeMemberGroup()"
              >
                <UbuDropdownCustomRow
                  :custom="custom"
                  :icon="custom.icon"
                  :is-checked="true"
                />
              </b-dropdown-item>

              <b-dropdown-item separator />
            </template>

            <b-dropdown-item @click="addToTribeWithMemberGroup({ mgid: null })">
              <UbuDropdownCustomRow
                :custom="{ label: 'Manage groups later', color: '0' }"
                icon=""
                :is-checked="false"
              />
            </b-dropdown-item>

            <b-dropdown-item
              v-for="custom in memberGroupSplitByAssignment.unassigneds"
              :key="custom.mgid"
              @click="addToTribeWithMemberGroup(custom)"
            >
              <UbuDropdownCustomRow
                :custom="custom"
                :icon="custom.icon"
                :is-checked="false"
              />
            </b-dropdown-item>

            <b-dropdown-item
              v-if="!memberGroupSplitByAssignment.assigneds.length
                && !memberGroupSplitByAssignment.unassigneds.length"
              custom
              class="has-text-secondary"
            >
              <p>
                No item with this name in this workspace...
              </p>
            </b-dropdown-item>
          </b-dropdown-item>
        </b-tab-item>
      </b-tabs>
    </template>
  </UbuDropdown>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

import pChain from '@dailyplanet/utils/PromiseChain';

export default {
  name: 'TheTribePopover',
  props: {
    payload: {
      type: Object,
      default: () => ({
        // {
        // tid,
        // cid,
        // cpid,
        // tags,
        // },
      }),
    },
    position: {
      type: String,
      default: 'is-bottom-left',
    },
  },
  data() {
    return {
      activeTab: 'root',
      search: '',
      keyName: 'tribeTid',
      icon: 'ubu-custom-tribe',

      isOpen: false,
      breadcrumbs: [],
      selectedTribe: null,
    };
  },
  computed: {
    ...mapGetters({
      tribeList: 'TheTribe/tribeList',
      memberGroupList: 'TheTribe/memberGroupList',
      windowWidth: 'TheMenu/getterWindowWidth',
    }),
    preparedTribeList() {
      return Object.values(this.tribeList).map(({ name, ...rest }) => ({
        label: name,
        name,
        ...rest,
      }));
    },
    preparedMemberGroupList() {
      return Object.values(this.memberGroupList).map(({ name, ...rest }) => ({
        label: name,
        name,
        ...rest,
      }));
    },
    usedTribes() {
      const allItems = this.payload.customs;

      if (!allItems.length) return {};

      return this.preparedTribeList.reduce((acc, tribe) => {
        if (allItems.find((item) => Number(tribe.tid) === Number(item.tribeTid))) {
          acc[tribe.tid] = true;
        }

        return acc;
      }, {});
    },

    usedMemberGroup() {
      const allItems = this.payload.customs;

      if (!allItems.length || !this.selectedTribe) return {};

      return this.preparedMemberGroupList.reduce((acc, memberGroup) => {
        if (allItems.find((item) => Number(this.selectedTribe.tid) === Number(item.tribeTid) && Number(memberGroup.mgid) === Number(item.memberGroupMgid))) {
          acc[memberGroup.mgid] = true;
        }

        return acc;
      }, {});
    },

    tribesSplitByAssignment() {
      const tribesSplitByAssignment = this.preparedTribeList.reduce((acc, tribe) => {
        if (this.search && !tribe.name.toLowerCase()
          .includes(this.search.toLowerCase())) return acc;

        if (this.usedTribes[tribe.tid]) { acc.assigneds.push(tribe); return acc; }

        acc.unassigneds.push(tribe);

        return acc;
      }, {
        assigneds: [],
        unassigneds: [],
      });

      // add sort
      tribesSplitByAssignment.assigneds = tribesSplitByAssignment.assigneds.sort((a, b) => this.$moment(a.createdOn).unix() - this.$moment(b.createdOn).unix());
      return tribesSplitByAssignment;
    },

    memberGroupSplitByAssignment() {
      return this.preparedMemberGroupList.reduce((acc, memberGroup) => {
        if (this.search && !memberGroup.name.toLowerCase()
          .includes(this.search.toLowerCase())) return acc;

        if (this.usedMemberGroup[memberGroup.mgid]) { acc.assigneds.push(memberGroup); return acc; }

        acc.unassigneds.push(memberGroup);

        return acc;
      }, {
        assigneds: [],
        unassigneds: [],
      });
    },

    selectedTribeMember() {
      return this.payload.customs.find(({ tribeTid }) => this.selectedTribe.tid === tribeTid);
    },
  },
  mounted() {
    this.breadcrumbs = [{
      tab: 'root',
      label: 'Tribes',
    }];
  },
  methods: {
    ...mapActions({
      _createTribeMember: 'TheTribe/createTribeMember',
      _updateTribeMember: 'TheTribe/updateTribeMember',
      _deleteTribeMember: 'TheTribe/deleteTribeMember',
    }),
    updateFilter(search) {
      if (!search) this.reset();
      else this.search = search;
    },

    reset() {
      this.search = '';
      this.activeTab = 'root';
      this.breadcrumbs = [{
        tab: 'root',
        label: 'Tribes',
      }];
      // this.isOpen = false;
    },

    selectTribeAndGoNext(tribe) {
      this.selectedTribe = tribe;
      this.activeTab = 'root.memberGroup';
      this.breadcrumbs.push({
        tab: 'root.memberGroup',
        label: tribe.name,
      });
    },

    confirmUnassignAll() {
      this.$buefy.dialog.confirm({
        title: 'Remove all',
        message: 'Are you sure you want to remove this person from their current tribes?',
        confirmText: 'Remove all',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.unassignAll(),
      });
    },

    unassignAll() {
      return pChain(this.payload.customs.map(({ tmid, tribeTid }) => this._deleteTribeMember({
        payload: {
          tmids: [tmid],
          tid: tribeTid,
        },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_removeFromTribe',
          payload: {
            cpids: [this.payload.cpid],
            tmids: [tmid],
          },
        }],
      })));
    },

    removeFromTribe() {
      return this._deleteTribeMember({
        payload: {
          tmids: [this.selectedTribeMember.tmid],
          tid: this.selectedTribeMember.tribeTid,
        },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_removeFromTribe',
          payload: {
            cpids: [this.payload.cpid],
            tmids: [this.selectedTribeMember.tmid],
          },
        }],
      })
        .then(() => this.$buefy.snackbar.open({
          message: 'All members were removed from tribe',
          type: 'is-success',
          position: 'is-top',
        }));
    },

    removeMemberGroup() {
      return this.updateTribeMember({
        tid: this.selectedTribeMember.tribeTid,
        tribeMemberList: [{ ...this.selectedTribeMember, memberGroupMgid: null }],
      });
    },

    addToTribeWithMemberGroup(memberGroup) {
      const tribeMember = this.selectedTribeMember;
      if (tribeMember) {
        return this.updateTribeMember({
          tid: this.selectedTribeMember.tribeTid,
          tribeMemberList: [{ ...this.selectedTribeMember, memberGroupMgid: memberGroup.mgid }],
        });
      }
      return this._createTribeMember({
        payload: {
          tid: this.selectedTribe.tid,
          tribeMemberList: [{
            tribeTid: this.selectedTribe.tid,
            memberGroupMgid: memberGroup.mgid,
            contactPlatformCpid: this.payload.cpid,
            createdOn: this.$moment().format(),
          }],
        },
        httpQuery: {
          detail: true,
        },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_createTribeMember',
          payload: {
            cpids: [this.payload.cpid],
          },
        }],
      });
    },

    updateTribeMember({ tid, tribeMemberList }) {
      return this._updateTribeMember({
        payload: { tid, tribeMemberList },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_updateTribeMember',
          payload: {
            cpids: [this.payload.cpid],
            tribeMemberList,
          },
        }],
      });
    },

    useBreadcrumb({ item, index }) {
      this.activeTab = item.tab;
      this.breadcrumbs = this.breadcrumbs.filter((_, i) => i <= index);
    },
  },
};
</script>
