<template>
  <div
    :class="['ubuContactCard has-border-bottom', {
      'is-hoverable': hoverable,
      'is-focusable': focusable,
    }]"
  >
    <div class="profile">
      <div
        v-if="picture"
        class="avatar"
      >
        <b-image
          v-if="actions.imageSrc"
          rounded
          :src="actions.imageSrc"
          :placeholder="'https://via.placeholder.com/140x140.png'"
          :src-fallback="'https://via.placeholder.com/140x140.png'"
          alt="une image"
          lazy
          v-on="$listeners"
        />
        <UbuAvatar
          v-else
          :initial-name="initials"
        />
      </div>
      <div class="info">
        <div class="text">{{ contact.firstName }} {{ contact.lastName }}</div>
        <div
          v-if="contact.email"
          class="subtext"
        >
          {{ contact.email }}
        </div>
      </div>
    </div>
    <div
      v-if="actions"
      class="actions"
    >
      <div v-if="actions.date">
        <b-tag type="is-dashed">
          {{ $moment(actions.date).format('HH:mm') }}
        </b-tag>
      </div>

      <b-button
        v-if="actions.button"
        :type="actions.button.type"
        :label="actions.button.label"
        size="is-small"
        @click="$emit(actions.button.emit)"
      />

      <b-dropdown
        v-if="actions.dropdown"
        aria-role="list"
        position="is-bottom-left"
        :mobile-modal="false"
      >
        <template #trigger>
          <b-button
            clickable
            class="is-tertiary"
            icon-left="ubu-more-vertical"
            icon-pack="ubu"
          />
        </template>

        <b-dropdown-item
          v-for="(item, index) in actions.dropdown.items"
          :key="index"
          :class="item.class"
          v-bind="item.attr"
          @click="$emit(item.emit)"
        >
          {{ item.name }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuContactCard',
  props: {
    contact: {
      type: Object,
      default: null,
    },
    actions: {
      type: Object,
      default: null,
    },
    hoverable: {
      type: Boolean,
      default: true,
    },
    focusable: {
      type: Boolean,
      default: true,
    },
    picture: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    initials() {
      return `${this.contact.firstName[0]}${this.contact.lastName[0]}`.toUpperCase();
    },
  },
};
</script>
