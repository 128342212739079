<template>
  <article class="multibar row-vcentered ml-3">
    <template v-if="type === 'channelGroup'">
      <b-image
        v-if="picture"
        class="start selectorPicture"
        :src="picture"
        :style="`width:${size}px;height:${size}px;`"
      />

      <UbuAvatar
        v-else
        class="start"
        :initial-name="initials"
        :size="size"
      />

      <p class="end ml-2 text-truncate">
        {{ name }}
      </p>
    </template>

    <template v-else>
      <component
        :is="`UbuIcon${platformName.capitalize()}`"
        class="start"
      />

      <p class="start ml-2 text-truncate">
        {{ name }}
      </p>
    </template>
  </article>
</template>

<script>
export default {
  name: 'UbuInfoSelector',
  props: {
    type: { type: String, default: 'channelGroup' },
    picture: { type: String, default: null },
    name: { type: String, default: '' },
    initials: { type: String, default: '' },
    platformName: { type: String, default: '' },
    size: { type: Number, default: 24 },
  },
};
</script>
