<template>
  <UbuDropdown
    position="is-bottom-right"
    @open="isOpen = true"
    @close="isOpen = false;"
  >
    <template v-slot:button>
      <b-button
        :label="_currentShop ? _currentShop.baseUrl : 'Select a shop'"
        icon-pack="ubu"
        icon-left="ubu-shop"
        :icon-right="iconRight"
        :type="buttonType"
      />
    </template>

    <template
      v-if="isOpen"
      v-slot:search
    >
      <b-dropdown-item custom>
        <UbuSearchbar
          v-model="search"
          @clear="search = ''"
        />
      </b-dropdown-item>
    </template>

    <template v-slot:content>
      <b-dropdown-item
        v-for="shop in shopListFiltered"
        :key="shop.sid"
        @click="changeShop(shop)"
      >
        {{ shop.baseUrl }}
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheShopDropdown',
  mixins: [FilterEngineMixin],
  props: {
    buttonType: {
      type: String,
      default: 'is-secondary',
    },
    iconRight: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: '',
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      _shopList: 'TheShopDropdown/shopList',
      _currentShop: 'TheShopDropdown/currentShop',
    }),

    _filterCollection() {
      return {
        searchByBaseUrl(rawSearch) {
          const search = rawSearch.toLowerCase();
          return (m) => (m.baseUrl.toLowerCase().includes(search) ? m : undefined);
        },
      };
    },

    shopListFiltered() {
      const shopList = Object.values(this._shopList);

      if (!shopList.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByBaseUrl = this.search;

      return this.filterEngine(shopList, filtersToApply);
    },
  },
  methods: {
    ...mapActions({
      UP_currentShop: 'TheShopDropdown/UP_currentShop',
    }),

    changeShop(shop) {
      this.UP_currentShop(shop);
      this.search = '';
    },
  },
};
</script>
