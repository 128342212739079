/* eslint-disable max-len */
import { actions, gettersSelected, getters } from '@dailyplanet/data-stores/commonViewStores';

// const mainQuery = {
//   displayOptions: 'received',
//   sortOptions: 'newest',
//   open: 'true',
// };

// const allQuery = {
//   displayOptions: 'all',
//   sortOptions: 'newest',
//   open: 'true',
// };

// const appointQuery = {
//   displayOptions: 'all',
//   sortOptions: 'newest',
//   open: 'true',
// };

// const customQuery = {
//   displayOptions: 'all',
//   sortOptions: 'newest',
// };

export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [],
    },
    bulkMode: false,
    threadsSelectedInBulk: [],
    mapThreadsSelectedInBulk: {},
  },
  getters: {
    navigation: (state) => state.navigation,
    ...gettersSelected('activity', ['activityStatusListFiltered']),
    ...getters.authenticate,
    ...getters.campaign,
    ...getters.channelGroup,
    ...getters.privateNote,
    ...getters.message,
    ...getters.thread,
    ...getters.contactGroup,
    ...getters.threadGroup,
    ...getters.user,
    ...getters.account,
    ...getters.shortcut,
    ...getters.threadList,
    ...getters.tribe,
    ...getters.influence,
    ...gettersSelected('campaignThreadCounters', ['campaignThreadCounters']),
    // ...getters.threadAndParticipant,
    // ...getters.template,
    activeMenuItems: (_state, _getters) => {
      const { counterList } = _getters;

      const grouped = ['primary', 'mentions', 'opportunities', 'importants', 'all'];

      const settings = {
        primary: {
          text: 'Primary',
          icon: 'ubu-inbox',
          path: { path: '/inbox/common/primary' },
          ordinal: 0,
        },
        mentions: {
          text: 'Mentions',
          icon: 'ubu-mentions',
          path: { path: '/inbox/common/mentions' },
          ordinal: 3,
        },
        opportunities: {
          text: 'Opportunities',
          icon: 'ubu-opportunities',
          path: { path: '/inbox/common/opportunities' },
          ordinal: 2,
        },
        importants: {
          text: 'Important',
          icon: 'ubu-important',
          path: { path: '/inbox/common/importants' },
          ordinal: 1,
        },
        all: {
          text: 'All',
          icon: 'ubu-messages',
          path: { path: '/inbox/common/all' },
          ordinal: 6,
        },
      };

      if (!Object.values(counterList).length) {
        return Object.values(settings).map((menuItem) => ({
          ...menuItem,
          counterLoading: true,
        }))
          .sort((a, b) => a.ordinal - b.ordinal);
      }

      return Object.entries(counterList)
        .filter(([key]) => grouped.includes(key))
        .map(([key, value]) => ({
          ...settings[key],
          hasBadge: true,
          hasBadgeActive: value.hasUnread,
          counter: value.count || '0',
        }))
        .sort((a, b) => a.ordinal - b.ordinal);
    },
    groupedActiveMenuItems: (_state, _getters, _rootState, rootGetters) => {
      const { counterList } = _getters;
      const {
        'Sequence/isAllCustomFetched': isAllCustomFetched,
        'Authenticate/getterAuthenticate': myAuthenticate,
      } = rootGetters;

      const {
        tags: tagsCounters,
        labels: labelsCounters,
        campaigns: campaignsCounters,
        appoints: appointCounters,
      } = counterList;

      if (!isAllCustomFetched || (!tagsCounters && !labelsCounters && !campaignsCounters && !appointCounters)) {
        const loadingItem = {
          loading: true,
        };
        const loadingItemList = [loadingItem, loadingItem];
        return {
          appoint: [loadingItem], tags: loadingItemList, labels: loadingItemList, campaigns: loadingItemList,
        };
      }

      const campaigns = Object.entries(campaignsCounters).map(([cid, rest]) => ({
        path: { path: `/inbox/campaign/${cid}` },
        text: rest.details.name,
        icon: 'ubu-custom-influence',
        iconColor: rest.details.color,
        hasBadge: true,
        hasBadgeActive: rest.hasUnread,
        counter: rest.count,
      }));

      const tags = Object.entries(tagsCounters).map(([tgid, rest]) => ({
        path: { path: `/inbox/tag/${tgid}` },
        text: rest.details.label,
        icon: 'ubu-custom-tag',
        iconColor: rest.details.color,
        hasBadge: true,
        hasBadgeActive: rest.hasUnread,
        counter: rest.count,
      }));

      const labels = Object.entries(labelsCounters).map(([cgid, rest]) => ({
        path: { path: `/inbox/label/${cgid}` },
        text: rest.details.label,
        icon: 'ubu-custom-contact',
        iconColor: rest.details.color,
        hasBadge: true,
        hasBadgeActive: rest.hasUnread,
        counter: rest.count,
      }));

      const appoint = [{
        path: { path: `/inbox/appoint/${myAuthenticate.uid}` },
        text: 'Assigned to me',
        icon: 'ubu-assign',
        iconColor: '',
        hasBadge: true,
        hasBadgeActive: appointCounters[myAuthenticate.uid] ? appointCounters[myAuthenticate.uid].hasUnread : false,
        counter: appointCounters[myAuthenticate.uid] ? appointCounters[myAuthenticate.uid].count : '0',
      }];

      return {
        appoint, tags, labels, campaigns,
      };
    },
    getterBulkMode: (state) => state.bulkMode,
    getterThreadsSelectedInBulk: (state) => state.threadsSelectedInBulk,
    getterMapThreadsSelectedInBulk: (state) => state.mapThreadsSelectedInBulk,
  },
  mutations: {
    UPDATE_BULK_MODE(state, newData) { state.bulkMode = newData; },
    UPDATE_THREADS_SELECTED_IN_BULK(state, newData) {
      state.threadsSelectedInBulk = newData;
      state.mapThreadsSelectedInBulk = newData.arrayMapper('tid');
    },
  },
  actions: {
    // ...actions.campaign,
    ...actions.campaignThreadCounters,
    ...actions.channelGroup,
    ...actions.privateNote,
    ...actions.message,
    ...actions.thread,
    ...actions.contactGroup,
    ...actions.threadGroup,
    ...actions.shortcut,
    ...actions.segment,
    ...actions.threadList,
    ...actions.tribe,
    ...actions.memberGroup,
  },
};
