<template>
  <div>
    <DevDetails :cnt="{name: 'itemList', obj: itemList}" />
    <DevDetails :cnt="{name: 'userList', obj: userList}" />
    <div
      id="scroller"
      ref="scroller"
      class="scroller"
      @wheel="onScroll"
    >
      <b-button
        v-if="hasMoreToLoad && amIOnLastPage && type === 'message' && platformName !== 'FACEBOOK'"
        class="loadMore"
        type="is-secondary"
        :loading="isLoadingMoreItems"
        @click="loadMore()"
      >
        <b-icon
          pack="mdi"
          size="is-small"
          icon="refresh"
        />
        <span>Load more</span>
      </b-button>
      <template
        v-for="(item, index) in itemList"
        :id="'item'+item[keyField]"
      >
        <div
          v-if="item"
          :key="item[keyField]"
        >
          <p
            v-if="shouldIDisplaySeparator(index) && !item.loading"
            class="dateSeparator subtext has-text-secondary mb-4 mt-4"
          >
            {{ getDay(item[dateKey]) }}
          </p>
          <div
            v-if="(item.component || item.loading) && type && type === 'message'"
            class="ubuMessageRow"
          >
            <UbuThreadMessageSkeleton
              v-if="item.loading"
              :class="[{'start': item.isMe, 'end': !item.isMe}]"
              :message="item"
            />

            <div
              v-else
              :class="['is-flex', {
                'end': item.isMe && item.isMessage,
                'start': !item.isMe && item.isMessage
              }]"
            >
              <component
                :is="item.component"
                :is-me="item.isMe"
                :message="item"
                :user-list="userList"
                :campaign-list="campaignList"
                :window-width="windowWidth"
                :platform-name="platformName"
              />
              <UbuLikeButton
                v-if="threadDetails
                  && threadDetails.thread.platformName === 'INSTAGRAM'
                  && item.isMessage && !item.isMe"
                :message="item"
                @likeMessage="likeMessage($event)"
              />

              <b-dropdown
                v-if="item.type === 'TEXT'"
                :position="item.isMe ? 'is-top-left' : 'is-top-right'"
                class="dropdown-moreActions"
                scrollable
                max-height="300px"
                append-to-body
              >
                <template #trigger>
                  <b-button
                    type="is-tertiary"
                    icon-pack="ubu"
                    icon-right="ubu-more-vertical"
                  />
                </template>

                <!-- <b-dropdown-item
                  v-if="item.isMe"
                  paddingless
                >
                  <b-button
                    label="Delete message"
                    type="is-ghost is-borderless"  USE IT WHEN DELETE MSG WILL BE IMPLEMENTED
                    icon-pack="ubu"
                    icon-left="ubu-trash"
                    disabled
                  />
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="item.isMe"
                  separator
                /> -->

                <b-dropdown-item
                  custom
                  class="multibar row-vcentered"
                  paddingless
                >
                  <b-icon
                    class="start"
                    pack="ubu"
                    icon="ubu-translate"
                  />
                  <b class="start ml-1">Translate</b>
                </b-dropdown-item>

                <b-dropdown-item separator />

                <b-dropdown-item paddingless>
                  <b-button
                    label="Reset to original language"
                    type="is-ghost is-borderless"
                    @click="$emit('revertTranslate', item)"
                  />
                </b-dropdown-item>

                <b-dropdown-item
                  v-for="(language, i) in optionLanguages"
                  :key="i"
                  @click="handleTranslate(item, language)"
                >
                  <div class="multibar row-vcentered">
                    <b-image
                      class="is-18x18 start"
                      :src="language.flag"
                    />
                    <span class="ml-1 start">{{ language.label }}</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div v-if="!item.component && !item.loading && type && type === 'note'">
            <UbuNoteFilter
              v-if="item"
              :key="item[itemKey]"
              :item="item"
              :user-list="userList"
              :campaign-list="campaignList"
              :window-width="windowWidth"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
/* eslint-disable max-len */

const ukFlag = require('@dailyplanet/assets/svg/UK.svg');
const frenchFlag = require('@dailyplanet/assets/svg/France.svg');
const germanyFlag = require('@dailyplanet/assets/svg/Germany.svg');
const italyFlag = require('@dailyplanet/assets/svg/Italy.svg');
const japanFlag = require('@dailyplanet/assets/svg/Japan.svg');
const netherlandFlag = require('@dailyplanet/assets/svg/Netherlands.svg');
const spainFlag = require('@dailyplanet/assets/svg/Spain.svg');
const portugueseFlag = require('@dailyplanet/assets/svg/Portuguese.svg');

export default {
  name: 'UbuScroller',
  props: {
    itemList: {
      type: Array,
      required: true,
    },
    threadDetails: {
      type: Object,
      default: () => ({}),
    },
    campaignList: {
      type: Object,
      required: true,
    },
    itemCountTotal: {
      type: Number,
      default: 0,
    },
    dateKey: {
      type: String,
      required: true,
    },
    itemKey: {
      type: String,
      required: true,
    },
    keyField: {
      type: String,
      required: true,
    },
    reversed: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    pageRange: {
      type: Number,
      required: true,
    },
    lastPageFetched: {
      type: Number,
      required: true,
    },
    hasMoreToLoad: {
      type: Boolean,
      default: false,
    },
    userList: {
      type: Object,
      default: () => {},
    },
    windowWidth: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      previousScrollTopValue: 0,
      previousScrollDirection: this.reversed ? 'up' : 'down',
      scrollRange: 200,
      initialized: false,
      timer: null,
      observer: null,
      sizeObserver: null,
      previousLastItem: null,
      scrollInitiated: false,
      optionLanguages: [
        { label: 'French', value: 'fr', flag: frenchFlag },
        { label: 'English', value: 'en-US', flag: ukFlag },
        { label: 'Germany', value: 'de', flag: germanyFlag },
        { label: 'Spanish', value: 'es', flag: spainFlag },
        { label: 'Italian', value: 'it', flag: italyFlag },
        { label: 'Japanese', value: 'ja', flag: japanFlag },
        { label: 'Portuguese', value: 'pt-br', flag: portugueseFlag },
        { label: 'Dutch', value: 'nl', flag: netherlandFlag },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isLoadingMoreMsgsList: 'TheInbox/isLoadingMoreMessagesList',
      accountFeatures: 'TheInbox/accountFeatures',
    }),
    amIOnLastPage() {
      return Math.floor(this.itemCountTotal / this.perPage) === this.page;
    },
    amIScrollingToBottom() {
      return this.$refs.scroller.scrollTop > this.previousScrollTopValue;
    },
    amIScrollingToTop() {
      return this.$refs.scroller.scrollTop < this.previousScrollTopValue;
    },
    canIFetchPage() {
      const direction = this.reversed ? 'down' : 'up';
      return (this.page !== 0 && this.previousScrollDirection === direction) || (this.page >= this.pageRange && this.previousScrollDirection !== direction);
    },
    isLoadingMoreItems() {
      if (this.type === 'message') {
        const isLoading = this.isLoadingMoreMsgsList[this.$route.params.tid] ? this.isLoadingMoreMsgsList[this.$route.params.tid] : false;

        return isLoading;
      }

      return false;
    },
    lastItem() {
      if (this.itemList.length) {
        const lastItem = this.itemList.find((item) => item[this.keyField] === 0);

        return lastItem;
      }
      return null;
    },
    platformName() {
      if (!this.threadDetails || !this.threadDetails.thread) return null;
      return this.threadDetails.thread.platformName;
    },
  },
  watch: {
    scrollInitiated(val) {
      if (val && this.observer) this.observer.disconnect();
    },
    isLoadingMoreMsgsList: {
      handler(val) {
        if (this.type === 'message' && val[this.$route.params.tid] === false) {
          this.scrollOnePage();
        }
      },
      deep: true,
    },
    lastItem: {
      handler(val) {
        if (val && this.previousLastItem === null) this.previousLastItem = val;
        if (val && this.previousLastItem.text !== val.text && this.type === 'message') {
          this.previousLastItem = val;
          if (val.isMe || val.type === 'PRIVATE_NOTE') this.scrollToBottom(true);
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    if (this.sizeObserver) {
      this.sizeObserver.disconnect();
    }
  },
  mounted() {
    const config = {
      attributes: true,
      childList: true,
      subtree: true,
    };
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation) {
          const amIAtEndOfList = this.$refs.scroller.scrollTop + this.$refs.scroller.clientHeight >= this.$refs.scroller.scrollHeight;
          if (this.reversed && !amIAtEndOfList && !this.scrollInitiated) {
            this.scrollToBottom();
          }
        }
      });
    });
    this.sizeObserver = new ResizeObserver((() => {
      const wasAtEndOfList = this.$refs.scroller.scrollTop + this.$refs.scroller.clientHeight + 200 >= this.$refs.scroller.scrollHeight;
      if (this.reversed && wasAtEndOfList) {
        this.scrollToBottom();
      }
    }));
    this.sizeObserver.observe(this.$refs.scroller);
    this.observer.observe(this.$refs.scroller, config);
  },
  methods: {
    ...mapActions({
      loadMoreMessages: 'TheInbox/loadMoreMessages',
      setIsLoadingMoreMessagesList: 'TheInbox/up_isLoadingMoreMessagesList',
      likeMessage: 'TheInbox/likeMessage',
      emitSegmentEvent: 'TheInbox/emitSegmentEvent',
    }),
    handleTranslate(message, language) {
      // if (!this.accountFeatures('INBOX')) {
      //   this.emitSegmentEvent({ event: 'translateTextNoFeature' });
      //   this.$intercom.showNewMessage('Hi! I would like to activate the INBOX module! 🙌');

      //   return;
      // }

      this.$emit('translate', { ...message, targetLang: language.value });
    },
    scrollOnePage() {
      const { scrollTop, scrollHeight } = this.$refs.scroller;
      if (this.reversed) {
        this.$refs.scroller.scrollTop = scrollHeight / this.pageRange;
      } else {
        this.$refs.scroller.scrollTop = scrollTop - scrollHeight / this.pageRange;
      }
    },
    getDay(day) {
      return this.$moment(day).calendar(null, {
        sameDay: '[Today] ☀️',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'YYYY-MM-DD',
      });
    },
    shouldIDisplaySeparator(index) {
      return index === 0 || this.$moment(this.itemList[index][this.dateKey]).format('YY-MM-DD') !== this.$moment(this.itemList[index - 1][this.dateKey]).format('YY-MM-DD');
    },
    scrollToBottom(newMessage = false) {
      if (newMessage) this.$emit('changePageToFirst');
      this.previousScrollTopValue = this.$refs.scroller.scrollHeight;
      this.$refs.scroller.scrollTop = this.$refs.scroller.scrollHeight;
    },
    onScroll() {
      if (!this.scrollInitiated) this.scrollInitiated = true;
      // Reversed mode
      if (this.reversed) {
        return this.onScrollReversed();
      }
      // Normal mode
      return this.onScrollRegular();
    },
    onScrollReversed() {
      if (!this.$refs.scroller) return undefined;
      const { scrollTop, scrollHeight } = this.$refs.scroller;
      const amIInBufferRange = this.$refs.scroller.scrollTop < this.scrollRange;
      const amIAtEndOfList = Math.ceil(this.$refs.scroller.scrollTop + this.$refs.scroller.clientHeight) >= this.$refs.scroller.scrollHeight;
      if (!this.isLoading && !this.amIOnLastPage && this.page === this.lastPageFetched && amIInBufferRange) {
        this.$emit('fetchNewPage');
        this.previousScrollTopValue = scrollTop;
        this.previousScrollDirection = 'up';
        return undefined;
      }
      if (!this.isLoading && this.amIScrollingToTop && amIInBufferRange && !this.amIOnLastPage) {
        // console.log('🚀 ~ file: UbuScroller.vue ~ line 215 ~ onScrollReversed ~ changePageToNext');
        this.$emit('changePageToNext');
        this.previousScrollTopValue = scrollTop;
        this.previousScrollDirection = 'up';
        return undefined;
      }
      if (!this.isLoading && this.amIScrollingToBottom && amIAtEndOfList && !amIInBufferRange && this.canIFetchPage) {
        // console.log('🚀 ~ file: UbuScroller.vue ~ line 215 ~ onScrollReversed ~ changePageToPrevious');
        this.$emit('changePageToPrevious');
        this.$refs.scroller.scrollTop = scrollTop - scrollHeight / this.pageRange;
        this.previousScrollTopValue = scrollTop;
        this.previousScrollDirection = 'down';
        return undefined;
      }
      this.previousScrollTopValue = scrollTop;
      return undefined;
    },
    onScrollRegular() {
      if (!this.$refs.scroller) return undefined;
      const { scrollTop, scrollHeight } = this.$refs.scroller;
      const amIInBufferRange = this.$refs.scroller.scrollTop < this.scrollRange;
      const amIAtEndOfList = Math.ceil(this.$refs.scroller.scrollTop + this.$refs.scroller.clientHeight) >= this.$refs.scroller.scrollHeight;
      if (!this.isLoading && !this.amIOnLastPage && this.page === this.lastPageFetched && amIAtEndOfList) {
        // console.log('🚀 ~ file: UbuScroller.vue ~ line 215 ~ onScrollRegular ~ fetchNewPage');
        this.$emit('fetchNewPage');
        this.previousScrollTopValue = scrollTop;
        this.previousScrollDirection = 'down';
        return undefined;
      }
      if (!this.isLoading && this.amIScrollingToBottom && !amIInBufferRange && !this.amIOnLastPage && amIAtEndOfList) {
        // console.log('🚀 ~ file: UbuScroller.vue ~ line 215 ~ onScrollRegular ~ changePageToNext');
        this.$emit('changePageToNext');
        this.$refs.scroller.scrollTop = scrollHeight / this.pageRange;
        this.previousScrollTopValue = scrollTop;
        this.previousScrollDirection = 'down';
        return undefined;
      }
      if (!this.isLoading && this.amIScrollingToTop && amIInBufferRange && this.canIFetchPage) {
        // console.log('🚀 ~ file: UbuScroller.vue ~ line 215 ~ onScrollRegular ~ changePageToPrevious');
        this.$emit('changePageToPrevious');
        this.previousScrollTopValue = scrollTop;
        this.previousScrollDirection = 'up';
        return undefined;
      }
      this.previousScrollTopValue = scrollTop;
      return undefined;
    },
    loadMore() {
      const { channelCid, tid } = this.$route.params;
      this.setIsLoadingMoreMessagesList({ isLoading: true, tid });
      this.loadMoreMessages({ payload: { cid: parseInt(channelCid, 10), tid: parseInt(tid, 10) } })
        .catch(() => {
          this.setIsLoadingMoreMessagesList({ isLoading: false, tid });
        });
    },
  },
};
</script>
