<template>
  <b-menu-item
    :key="el.ordinal"
  >
    <template #label>
      <b-field expanded>
        <b-input
          v-model="el.model"
          :icon-pack="el.iconPackLeft"
          :icon="el.iconLeft"
          expanded
          @input="$update('model', $event)"
        />
      </b-field>
    </template>
  </b-menu-item>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'PopoverItemSearch',
  mixins: [VModelMixin],
  model: {
    prop: 'el',
  },
  props: {
    el: {
      type: Object,
      required: true,
    },
  },
};
</script>
