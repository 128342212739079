/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    manageWebhookList: null,
  },
  mutations: {
    SET_MANAGE_WEBHOOK_LIST(state, newData) { state.manageWebhookList = newData; },
  },
  getters: {
    getterManageWebhookList: (state) => state.manageWebhookList,
  },
  actions: {
    report({ commit }, { payload = {}, httpQuery = {} }) {
      return clark.api.apiAffiliation.manageShop.manageWebhook.report({ payload, httpQuery })
        .then((manageWebhookList) => {
          commit('SET_MANAGE_WEBHOOK_LIST', manageWebhookList);
        });
    },
    create(_, { payload = {}, httpQuery = {} }) {
      return clark.api.apiAffiliation.manageShop.manageWebhook.post({ payload, httpQuery })
        .then((manageWebhook) => manageWebhook);
    },
    delete({ commit, getters }, { payload = {}, httpQuery = {} }) {
      return clark.api.apiAffiliation.manageShop.manageWebhook.delete({ payload, httpQuery })
        .then(() => {
          const getterMWList = getters.getterManageWebhookList;

          const index = getterMWList.findIndex((manageWebhook) => manageWebhook.wid === payload.wid);
          getterMWList.splice(index, 1);

          commit('SET_MANAGE_WEBHOOK_LIST', getterMWList);
        });
    },
  },
};
