<template>
  <section class="ubuTagSelector">
    <div class="multibar">
      <b-button
        class="end"
        type="is-primary"
        label="Finish"
        :disabled="!tgid"
        :loading="isWizardLoading"
        @click="$emit('next')"
      />
    </div>

    <br>

    <b-field>
      <b-input
        v-model="localTagSearch"
        placeholder="Search or create tag .."
        icon-pack="ubu"
        icon="ubu-search"
        icon-right="ubu-cross"
        icon-right-clickable
        class="is-simple"
        expanded
        @icon-right-click="localTagSearch = '';"
      />
      <p class="control">
        <b-button
          type="is-ghost"
          label="+ create tag"
          @click="createTagFormIsOpen = !createTagFormIsOpen;"
        />
      </p>
    </b-field>

    <div
      v-for="tag in filteredTagList"
      :key="tag.tgid"
      :class="[`has-text-color-${tag.color} ubuTagSelector-row`, {
        'hasBorderBottom': tgid === tag.tgid
      }]"
      @click="$emit('input', tag.tgid)"
    >
      <b-icon
        pack="ubu"
        icon="ubu-custom-tag"
        class="start text is-3"
      />

      <span class="start ml-2 text is-3">{{ tag.label }}</span>

      <b-icon
        v-if="tgid === tag.tgid"
        pack="ubu"
        icon="ubu-close"
        class="end text is-3"
      />
    </div>

    <UbuEditCustom
      v-if="createTagFormIsOpen"
      :custom="newTag"
      :can-close="true"
      @editCustom="createThreadGroup($event)"
      @cancel="createTagFormIsOpen = false"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheAskUgcPermissionTag',
  model: {
    prop: 'tgid',
  },
  props: {
    tgid: {
      type: Number,
      default: null,
    },
    isWizardLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newTag: {
        name: '',
        color: '1',
      },
      tagSeach: '',
      createTagFormIsOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      tagList: 'TheAskUgcPermission/threadGroupList',
    }),
    localTagSearch: {
      get() { return this.tagSeach; },
      set(newValue) {
        this.tagSeach = newValue.trim();
        this.newTag.name = newValue.trim();
      },
    },
    filteredTagList() {
      return [...Object.values(this.tagList)]
        .filter(({ label }) => label.toLowerCase().includes(this.tagSeach.toLowerCase()))
        .sort((a) => (a.tgid === this.tgid ? -1 : 1));
    },
  },
  methods: {
    ...mapActions({
      _createThreadGroup: 'TheAskUgcPermission/createThreadGroup',
    }),
    createThreadGroup(newTag) {
      return this._createThreadGroup({
        payload: {
          color: newTag.color,
          label: newTag.name,
        },
      })
        .then((threadGroup) => this.$emit('input', threadGroup.tgid))
        // .then(() => this.segmentTrack('createTagFormIsOpen'))
        .then(() => { this.createTagFormIsOpen = false; });
    },
  },
};
</script>
