<template>
  <!-- eslint-disable max-len -->
  <section class="ubuWizardView ubuSidePanel ubuPanel-xl ubuChannelAdd">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        to="/setting/channel"
        class="end is-simple-icon is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <header>
      <p class="text is-3 has-text-secondary">Connect channel</p>
      <h1 class="title is-1">Add YouTube channel</h1>
    </header>

    <br>

    <div class="content">
      <UbuHelper
        content="YouTube is for tracking hashtag mentions. Please enter your YouTube username and YouTube ID to start tracking."
      />

      <br>

      <form>
        <b-field grouped>
          <b-field
            expanded
          >
            <template #label>
              <div class="labelEdit">
                <span>Username</span>
                <b-icon
                  pack="ubu"
                  icon="ubu-information"
                />
              </div>
            </template>
            <b-input
              v-model="newYoutubeChannel.username"
              placeholder="username"
            />
          </b-field>
          <b-field
            expanded
          >
            <template #label>
              <div class="labelEdit">
                <span>ID</span>
                <a
                  href=""
                  class="has-text-primary"
                >
                  Find my Youtube ID
                </a>
              </div>
            </template>
            <b-input v-model="newYoutubeChannel.youtubeId" />
          </b-field>
        </b-field>

        <br>

        <div class="buttons is-right">
          <b-button
            native-type="submit"
            label="Add channel"
            class="is-primary"
          />
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheSettingChannelsAddYoutube',
  data() {
    return {
      newYoutubeChannel: {
        username: null,
        youtubeId: null,
      },
    };
  },
  methods: {
    ...mapActions({
      _createChannel: 'TheSettingWorkspaceSetting/createChannel',
    }),
    createChannel() {
      console.log(this.newYoutubeChannel);
    },
  },
};
</script>
