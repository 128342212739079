<template>
  <div class="ubuHashtagsGalleryView">
    <div
      v-masonry
      transition-duration=".25s"
      item-selector=".masonry-item"
      class="masonry-container"
      horizontal-order="true"
      fit-width="true"
    >
      <div
        v-for="media in listPaginated"
        :key="media.id"
        v-masonry-tile
        class="masonry-item"
      >
        <UbuMentionsHashtagsMediaCard
          :media="media"
          :is-loading="false"
          @openMediaModal="openMediaModal($event)"
        />
      </div>
    </div>

    <div class="multibar">
      <b-pagination
        v-model="currentPage"
        class="end"
        :per-page="mediasPerPage"
        size="is-small"
        :total="mediaList.length"
        :range-before="2"
        :range-after="2"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'TheHashtagsGalleryView',
  props: {
    search: {
      type: String,
      default: '',
    },
    mediaList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      mediasPerPage: 25,
    };
  },
  computed: {
    listPaginated() {
      const start = (this.currentPage - 1) * this.mediasPerPage;
      const end = this.currentPage * this.mediasPerPage;

      return this.mediaList.slice(start, end);
    },
  },
  methods: {
    openMediaModal(media) {
      this.$emit('openMediaModal', { index: media.id, payload: this.mediaList });
    },
  },
};
</script>
