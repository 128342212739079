import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    currentUserDetail: null,
    userDetailTargeted: null,
  },
  getters: {
    getterCurrentUserDetail: (state) => state.currentUserDetail,
    getterUserDetailTargeted: (state) => state.userDetailTargeted,
  },
  actions: {
    update({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'USER_DETAIL.UPDATE')) return undefined;

      return clark.api.user.userDetail.put({ payload, httpQuery: {} })
        .then((userDetail) => {
          commit('SET_USER_DETAIL', userDetail);
        });
    },
  },
  mutations: {
    SET_USER_DETAIL(state, newData) { state.currentUserDetail = newData; },
    SET_USER_DETAIL_TARGETED(state, newData) { state.userDetailTargeted = newData; },
    RESET_STORE(state) {
      state.currentUserDetail = null;
      state.userDetailTargeted = null;
    },
  },
};
