/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import assign from './assign.store';

export default {
  namespaced: true,
  state: {
    contactGroupList: {},
  },
  mutations: {
    SET_CONTACT_GROUP_LIST(state, newData) { state.contactGroupList = Object.values(state.contactGroupList).concat(newData).arrayMapper('cgid'); },
    UNSET_CONTACT_GROUP(state, newData) { state.contactGroupList = Object.values(state.contactGroupList).filter(({ cgid }) => cgid !== newData).arrayMapper('cgid'); },
    RESET_STORE(state) {
      state.contactGroupList = {};
    },
  },
  getters: {
    getterContactGroupList: (state) => state.contactGroupList,
  },
  actions: {
    up_contactGroupList({ commit }, contactGroup) {
      commit('SET_CONTACT_GROUP_LIST', [contactGroup]);
    },

    remove_contactGroup({ commit }, cgid) {
      commit('UNSET_CONTACT_GROUP', cgid);
    },

    report({ commit }, { payload }) {
      return clark.api.apiContact.contactGroup.report({ payload, httpQuery: {} })
        .then((contactGroup) => { commit('SET_CONTACT_GROUP_LIST', contactGroup); });
    },

    create({ commit }, { payload }) {
      return clark.api.apiContact.contactGroup.post({ payload, httpQuery: {} })
        .then((contactGroup) => {
          commit('SET_CONTACT_GROUP_LIST', [contactGroup]);
          return contactGroup;
        });
    },

    update({ commit }, { payload }) {
      return clark.api.apiContact.contactGroup.put({ payload, httpQuery: {} })
        .then((contactGroup) => { commit('SET_CONTACT_GROUP_LIST', [contactGroup]); });
    },

    delete({ commit }, { payload }) {
      return clark.api.apiContact.contactGroup.delete({ payload, httpQuery: {} })
        .then(() => { commit('UNSET_CONTACT_GROUP', payload.cgid); });
    },

  },
  modules: {
    assign,
  },
};
