<template>
  <section>
    <b-tab-item value="root.sendMessage">
      <template v-if="localActiveTab.tab === 'root.sendMessage'">
        <b-notification
          v-if="!availableToSend.length"
          type="is-secondary"
          :closable="false"
        >
          <p class="has-text-secondary">No existing conversation with all selected people.</p>

          <br>

          <p class="has-text-secondary">
            Instagram requires you to have an existing
            conversation with them to perform this action.
          </p>

          <br>

          <p class="has-text-secondary">
            However, you can contact them via Instagram to create a conversation,
            or outreach them in campaigns with the Ubu meta extension.
            <a
              class="has-text-primary no-decoration"
              :href="intercomRedirectUrl"
              target="_blank"
            >
              See how
            </a>
          </p>
        </b-notification>

        <UbuMessagePeopleWarning
          v-if="notAvailableToSend.length"
          :text="`Messages won’t be sent to ${notAvailableToSend.length} people
             as you don't have an active thread with them.`"
          :contact-platform-list="notAvailableToSend"
        />

        <TheCustomisableTextarea
          v-if="availableToSend.length"
          v-model="messageToSend"
          :dropdown-position="dropdownPosition"
          :autofocus="true"
          button-label="Send"
          @next="send()"
        />
      </template>
    </b-tab-item>

    <b-tab-item value="root.sendMessage.done">
      <UbuSendInstantMessageNotification
        v-for="item in sendList"
        :key="item.username"
        v-model="sendList[item.username]"
      />
    </b-tab-item>

    <TheSettingAddTemplate
      v-if="showTemplateManager"
      closing-way="emit"
      @close="showTemplateManager = false"
    />
  </section>
</template>

<script>
import pChain from '@dailyplanet/utils/PromiseChain';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSendInstantMessage',
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Object,
      required: true,
    },
    tabItems: {
      type: Object,
      required: true,
    },
    dropdownPosition: {
      type: Object,
      default: () => ({
        emoji: 'is-bottom-right',
        template: 'is-bottom-right',
      }),
    },
  },
  data() {
    return {
      messageToSend: '',
      showTemplateManager: false,
      intercomRedirectUrl: 'https://intercom.help/getubu/en/articles/6249362-how-can-i-cold-outreach-my-influencers',
      sendList: null,
    };
  },
  computed: {
    ...mapGetters({
      currentChannelList: 'TheInbox/currentChannelList',
    }),
    localChecked: {
      get() { return this.checkedRows; },
      set(value) { this.$emit('input', value); },
    },

    localActiveTab: {
      get() { return this.activeTab; },
      set(val) { this.$emit('update:activeTab', val); },
    },

    availableToSend() {
      return this.localChecked.filter((row) => !!row.contactPlatformStatistic.threadTid);
    },
    notAvailableToSend() {
      return this.localChecked
        .filter((row) => !row.contactPlatformStatistic.threadTid)
        .map(({ contactPlatform }) => contactPlatform);
    },
    messageContainInviteCrew() {
      return this.messageToSend.includes('{{ Invite on Crew }}');
    },
  },
  methods: {
    ...mapActions({
      sendMessage: 'TheInbox/sendInstantMessage',
      _inviteCrewUser: 'TheTextarea/inviteCrewUser',
    }),

    UP_changeToNewTab(activeTab) {
      this.$emit('update:activeTab', activeTab);
    },

    parseMessageAndInjectLink(text, link) {
      console.log('🚀 ~ file: TheSendInstantMessage.vue:143 ~ parseMessageAndInjectLink ~ link', link);
      if (!link) return text;
      return text.replace('{{ Invite on Crew }}', link);
    },

    send() {
      const { messageToSend } = this;
      return Promise.resolve()
        .then(() => {
          const channelList = this.currentChannelList.arrayMapper('platformName');

          this.sendList = this.localChecked.reduce((acc, row) => {
            if (!row.contactPlatformStatistic.threadTid) return acc;

            const channel = channelList[row.contactPlatform.platformName];

            if (!channel) {
              return acc;
              // acc[row.contactPlatform.username] = {
              //   ...row,
              //   username: row.contactPlatform.username,
              //   type: 'is-danger',
              //   icon: 'ubu-warning',
              //   errorMessage: 'No channel for this thread',
              // };
            }

            acc[row.contactPlatform.username] = {
              ...row,
              channelCid: channel.cid,
              username: row.contactPlatform.username,
              icon: 'ubu-in-progress',
              type: 'is-secondary',
            };

            return acc;
          }, {});
        })
        .then(() => this.UP_changeToNewTab(this.tabItems['root.sendMessage.done']))
        .then(() => pChain(
          Object.values(this.sendList)
            .filter(({ channelCid }) => channelCid)
            .map((row) => {
              const {
                channelCid,
                contactPlatformStatistic: { threadTid, contactPlatformCpid },
                username,
              } = row;
              return Promise.resolve()
                .then(() => {
                  if (!this.messageContainInviteCrew) return null;
                  return this._inviteCrewUser({ payload: { contactPlatformCpid } });
                })
                .then((token) => {
                  let link = null;
                  if (token) link = `${process.env.VUE_APP_TALIA_BASE_URL}/signup/${token}`;
                  const text = this.parseMessageAndInjectLink(messageToSend, link);

                  return this.sendMessage({
                    payload: {
                      cid: channelCid,
                      tid: threadTid,
                      text,
                    },
                  });
                })
                .then(() => {
                  this.sendList[username] = { ...row, type: 'is-success', icon: 'ubu-success' };
                  this.$forceUpdate();
                })
                .catch(({ body, message }) => {
                  let errorMessage = body && body.error ? body.error.message : message;
                  switch (errorMessage) {
                    case 'E_UNPROCESSABLE_API_INSTAGRAM_CONVERSATION_THREAD_MUST_HAVE_A_VALID_REVERSE_PARTICIPANT':
                      errorMessage = 'Unable to found Instagram IGSID to send message.';
                      break;

                    default:
                      break;
                  }
                  this.sendList[username] = {
                    ...row, type: 'is-danger', icon: 'ubu-warning', errorMessage,
                  };
                  this.$forceUpdate();
                });
            }),
        ))
        .then(() => { this.messageToSend = ''; });
    },
  },
};
</script>
