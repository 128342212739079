<template>
  <TheSimplePanel>
    <template #header>
      <h1 class="title is-1">Change password</h1>
    </template>

    <template #content>
      <form>
        <UbuEditPasswordForm
          v-model="newPasswordEdit"
        />

        <br>

        <div class="multibar">
          <b-button
            label="Save"
            type="is-primary"
            class="end"
            @click="editPassword()"
          />
        </div>
      </form>
    </template>
  </TheSimplePanel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheSettingProfilePanelEditPassword',
  data() {
    return {
      newPasswordEdit: {
        newPassword: null,
        confirmPassword: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheSettingUserSetting/myAuthenticate',
    }),
  },
  methods: {
    ...mapActions({
      updateChangePasswordFromSelina: 'TheSettingUserSetting/updateChangePasswordFromSelina',
    }),
    editPassword() {
      const { newPassword: passPhrase, confirmPassword } = this.newPasswordEdit;
      const { aid, passName, userDetail: { userUid } } = this.myAuthenticate;

      if (passPhrase !== confirmPassword) return;

      const payload = {
        aid,
        passName,
        userUid,
        passPhrase,
        oneTime: false,
      };

      this.updateChangePasswordFromSelina({ payload })
        .then(() => { this.$router.push('/setting/profile'); });
    },
  },
};
</script>
