/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    suggestions: {},
  },
  mutations: {
    SET_SUGGESTIONS_LIST(state, newDatas) { state.suggestions = newDatas; },
  },
  getters: {
    getterSuggestionsList: (state) => state.suggestions,
  },
  actions: {
    searchForSuggestions({ commit, rootGetters }, context) {
      if (!isActionAllowed(rootGetters, 'SUGGESTION.CREATE')) return undefined;

      return clark.api.salesContact.suggestion
        .post(context)
        .then((suggestions) => {
          commit('SET_SUGGESTIONS_LIST', suggestions);
          return suggestions;
        });
    },
  },
};
