/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    templateList: {},
  },
  mutations: {
    SET_TEMPLATE_LIST(state, newData) { state.templateList = Object.values(state.templateList).concat(newData).arrayMapper('tid'); },
    UNSET_TEMPLATE(state, newData) { state.templateList = Object.values(state.templateList).filter(({ tid }) => Number(tid) !== Number(newData)).arrayMapper('tid'); },
    UNSET_TEMPLATE_FOLDER_TFID(state, tfid) {
      state.templateList = Object.values(state.templateList)
        .map((t) => {
          if (Number(t.templateFolderTfid) === Number(tfid)) return { ...t, templateFolderTfid: null };
          return t;
        })
        .arrayMapper('tid');
    },
    RESET_STORE(state) {
      state.templateList = {};
    },
  },
  getters: {
    getterTemplateList: (state) => state.templateList,
  },
  actions: {
    up_templateList({ commit }, template) {
      commit('SET_TEMPLATE_LIST', [template]);
    },

    remove_template({ commit }, tid) {
      commit('UNSET_TEMPLATE', tid);
    },

    report({ commit }, { payload }) {
      return clark.api.channelGroup.template.report({ payload, httpQuery: {} })
        .then((templateList) => { commit('SET_TEMPLATE_LIST', templateList); });
    },
    create({ commit }, { payload }) {
      return clark.api.channelGroup.template.post({ payload, httpQuery: {} })
        .then((template) => {
          commit('SET_TEMPLATE_LIST', [template]);
          return template;
        });
    },
    update({ commit }, { payload }) {
      return clark.api.channelGroup.template.put({ payload, httpQuery: {} })
        .then((template) => {
          commit('SET_TEMPLATE_LIST', [template]);
          return template;
        });
    },
    delete({ commit }, { payload }) {
      return clark.api.channelGroup.template.delete({ payload, httpQuery: {} })
        .then(() => {
          commit('UNSET_TEMPLATE', payload.tid);
        });
    },
  },
  modules: {},
};
