<template>
  <TheTabsPanel
    v-model="activeTab"
    @goToPreviousTab="activeTab = tabItems['root']"
    @close="closePanel()"
  >
    <template v-slot:content>
      <b-tab-item value="root">
        <article>
          <div class="buttons">
            <UbuActionCard
              label="Campaign information"
              icon-pack="ubu"
              icon-left="ubu-settings"
              expanded
              left-text="click to continue"
              @click="activeTab = tabItems['root.campaignInfos']"
            />
            <UbuActionCard
              label="End Campaign"
              icon-pack="ubu"
              icon-left="ubu-close"
              expanded
              left-text="click to continue"
              @click="endCampaign()"
            />
          </div>
        </article>
      </b-tab-item>

      <b-tab-item value="root.campaignInfos">
        <form
          v-if="activeTab.tab === 'root.campaignInfos'"
          @submit.prevent="updateCampaign()"
        >
          <fieldset>
            <b-field
              label="Name"
              class="mb-2"
            >
              <b-input
                v-model="localCampaign.name"
                placeholder="Campaign name..."
                :minlength="3"
                required
                expanded
              />
            </b-field>

            <br>

            <b-field label="Color">
              <UbuColorPickerInline
                v-model="localCampaign.color"
                @input="localCampaign.color = String($event)"
              />
            </b-field>

            <br>

            <div class="buttons is-right">
              <!-- <b-button
                v-if="localModel.tid"
                type="is-secondary"
                label="Delete Tribe"
                icon-pack="ubu"
                icon-left="ubu-trash"
                @click="confirmDelete()"
              /> -->

              <b-button
                type="is-primary"
                label="Save"
                native-type="submit"
              />
            </div>
          </fieldset>
        </form>
      </b-tab-item>
    </template>
  </TheTabsPanel>
</template>

<script>
/* eslint-disable max-len */
import { mapActions } from 'vuex';

export default {
  name: 'TheCampaignSettingsPanel',
  model: {
    prop: 'campaign',
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: {
        tab: 'root',
        title: 'Settings',
        subtext: 'Campaign',
      },
      localCampaign: {},
    };
  },
  computed: {
    campaignColor: {
      get() {
        return {
          pack: 'ubu',
          icon: 'ubu-custom-influence',
          color: this.campaign.color,
        };
      },
      set({ color }) {
        this.localCampaign.color = color;
      },
    },

    hasChanges() {
      return JSON.stringify(this.campaign) !== JSON.stringify(this.localCampaign);
    },

    tabItems() {
      return {
        root: {
          tab: 'root',
          title: 'Settings',
          subtext: 'Campaign',
        },
        'root.campaignInfos': {
          tab: 'root.campaignInfos',
          title: 'Edit Campaign information',
          subtext: 'Campaign',
        },
      };
    },
  },
  mounted() {
    this.localCampaign = { ...this.campaign };
  },
  methods: {
    ...mapActions({
      _updateCampaign: 'TheCampaign/updateCampaign',
      _endCampaign: 'TheCampaign/endCampaign',

      UP_tribe: 'TheTribe/UP_tribe',
    }),

    closePanel() {
      this.$emit('closePanel');
    },

    updateCampaign() {
      return this._updateCampaign({ payload: this.localCampaign })
        .then(() => {
          this.activeTab = this.tabItems.root;
        });
    },

    endCampaign() {
      this.$buefy.dialog.confirm({
        title: 'End a campaign',
        message: 'Do you really want to end this campaign? You will still be able to view data related to this campaign after it\'s ended.',
        confirmText: 'End campaign',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this._endCampaign({ payload: { cid: this.$route.params.campaignCid } })
          .then(() => {
            this.$buefy.snackbar.open({
              type: 'is-success',
              position: 'is-top',
              message: `Campaign ${this.localCampaign.name} is ended!`,
            });
            this.$router.push('/influence');
          }),
      });
    },
  },
};
</script>
