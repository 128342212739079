<template>
  <section class="ubuContactPlatformPanelTab">
    <UbuContactPlatformPanelItemTogglable
      v-for="item in sortedActivityList"
      :key="item.activity.aid"
    >
      <template #headerContent>
        <div class="start multibar row-vcentered">
          <b-icon
            :class="`start has-text-color-${campaignList[item.activity.campaignCid].color}`"
            pack="ubu"
            icon="ubu-custom-influence"
          />
          <h3
            class="start ellipsis text is-2"
            style="max-width: 300px;"
          >
            {{ campaignList[item.activity.campaignCid].name }}
          </h3>
        </div>

        <div class="start multibar row-vcentered">
          <UbuIconCircleStatus :is-active="item.activity.endedOn ? false : true" />
          <h3
            class="start ellipsis subtext is-2"
            style="max-width: 300px;"
          >
            {{ item.activity.status | humanize }}
          </h3>
        </div>
      </template>

      <template #content>
        <div class="contentContainer">
          <div class="tile is-child">
            <p class="subtext is-3 mb-2">Mentions 🏞</p>
            <p class="text is-2">{{ item.totalMentions }}</p>
          </div>
          <div class="tile is-child">
            <p class="subtext is-3 mb-2">Revenue 💰</p>
            <p class="text is-2">${{ item.totalRevenuesUsd | nDecimalFormatter }}</p>
          </div>
          <div class="tile is-child">
            <p class="subtext is-3 mb-2">Orders 🛍</p>
            <p class="text is-2">{{ item.totalOrders }}</p>
          </div>

          <p class="subtext is-3 mb-2">Note</p>
          <UbuSingleInput
            v-model="item.activity.sheetNote"
            placeholder="Campaign notes..."
            custom-class="ubuNoteInput"
            :readonly="!!item.activity.endedOn"
            :maxlength="255"
            @send="updateSheetNote({
              activity: item.activity,
              sheetNote: $event
            })"
          />
        </div>
      </template>
    </UbuContactPlatformPanelItemTogglable>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheContactPlatformPanelInfluenceTab',
  computed: {
    ...mapGetters({
      contactPlatformOverview: 'TheContactPlatformPanel/contactPlatformOverview',
      detailedActivityList: 'TheContactPlatformPanel/detailedActivityList',
      campaignList: 'TheContactPlatformPanel/campaignList',
    }),
    sortedActivityList() {
      return Object.values(this.detailedActivityList)
        .sort((a, b) => this.$moment(b.activity.createdOn).unix() - this.$moment(a.activity.createdOn).unix());
    },
  },
  methods: {
    ...mapActions({
      _updateActivity: 'TheContactPlatformPanel/updateActivity',
    }),
    updateSheetNote({ activity, sheetNote }) {
      return this._updateActivity({
        payload: {
          ...activity,
          cid: activity.campaignCid,
          sheetNote,
        },
      });
    },
  },
};
</script>
