<template>
  <div
    :class="['ubuNote editNoteContainer', {
      'is-private_note': type === 'PRIVATE_NOTE',
    }]"
  >
    <p :class="[{'text': isPrivateNote}, {'bottomInfos subtext': !isPrivateNote}]">
      <span v-if="position === 'left'">
        {{ text }}
      </span>
      <b-tooltip
        v-if="routerLink"
        :label="routerLink.text"
        multilined
        append-to-body
        position="is-top"
        class="has-flex-trigger"
      >
        <router-link
          :to="routerLink.to"
          :class="['noteLink with-ellipsis', {
            'ml-1': position === 'left',
            'mr-1': position === 'right'
          }]"
        >
          {{ routerLink.text }}
        </router-link>
      </b-tooltip>
      <span v-if="position === 'right'">
        {{ text }}
      </span>
    </p>
    <b-icon
      v-if="isPrivateNote"
      class="ml-2 editNote end"
      pack="ubu"
      icon="ubu-edit"
      size="is-small"
      @click.native="$emit('openModalNote')"
    />

    <div class="divider-vertical" />

    <div class="bottomInfos subtext">
      <span>
        {{ ubuUser && ubuUser.userDetail ? ubuUser.userDetail.firstName + ' ' : 'Ubu ' }}
        {{ ubuUser && ubuUser.userDetail
          ? ubuUser.userDetail.lastName
          : 'Bot' }} at {{ formatedDate }}
      </span>
    </div>
    <div
      v-if="platform"
      class="ubuNotePlatform"
    >
      <component
        :is="`UbuIcon${platform.capitalize()}`"
        font-size="21px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuNote',
  props: {
    text: {
      type: String,
      default: '',
    },
    textTruncate: {
      type: String,
      default: '',
    },
    routerLink: {
      type: Object,
      default: null,
    },
    ubuUser: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    platform: {
      type: String,
      default: null,
    },
    isPrivateNote: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: 'left',
    },
    type: {
      type: String,
      required: true,
    },
    windowWidth: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    initials() {
      const user = this.ubuUser;
      if (!user || !user.userDetail) return 'BOT';
      return `${user.userDetail.firstName[0]}${user.userDetail.lastName[0]}`.toUpperCase();
    },
    formatedDate() {
      return this.$moment(this.date).format('HH:mm');
    },
    responsive() {
      const { windowWidth } = this;

      if (windowWidth > 1469) return false;
      return true;
    },
  },
};
</script>
