import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  actions: {
    update({ rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'THREAD_STATE.UPDATE')) return undefined;

      return clark.api.channelGroup.bulkThreadsState.put({ payload, httpQuery });
    },
  },
};
