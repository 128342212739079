import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheTribeSettingsPanel from './smarts/TheTribeSettingsPanel';
import TheTribeCartouche from './smarts/TheTribeCartouche';
import TheTribePeopleColumn from './smarts/TheTribePeopleColumn';
import TheTribePeopleColumnRow from './smarts/TheTribePeopleColumnRow';
import TheTribeBulkPanel from './smarts/TheTribeBulkPanel';
import TheBulkAssignMemberGroup from './smarts/TheBulkAssignMemberGroup';
import TheTribePeopleColumnRowDropdrown from './smarts/TheTribePeopleColumnRowDropdrown';
import TheTribePeopleAddPeoplePanel from './smarts/TheTribePeopleAddPeoplePanel';
import TheTribeMemberExportPanel from './smarts/TheTribeMemberExportPanel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheTribeSettingsPanel);
    registerComponent(Vue, TheTribeCartouche);
    registerComponent(Vue, TheTribePeopleColumn);
    registerComponent(Vue, TheTribePeopleColumnRow);
    registerComponent(Vue, TheTribeBulkPanel);
    registerComponent(Vue, TheBulkAssignMemberGroup);
    registerComponent(Vue, TheTribePeopleColumnRowDropdrown);
    registerComponent(Vue, TheTribePeopleAddPeoplePanel);
    registerComponent(Vue, TheTribeMemberExportPanel);
  },
};

use(Plugin);

export default Plugin;

export {
  TheTribeSettingsPanel,
  TheTribeCartouche,
  TheTribePeopleColumn,
  TheTribePeopleColumnRow,
  TheTribeBulkPanel,
  TheBulkAssignMemberGroup,
  TheTribePeopleColumnRowDropdrown,
  TheTribePeopleAddPeoplePanel,
  TheTribeMemberExportPanel,
};
