/* eslint-disable quotes */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const apiTremendous = {
  tremendousAccount: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/tremendousAccount`, method: 'REPORT', payload, httpQuery, target }),
    post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/tremendousAccount`, method: 'POST', payload, httpQuery, target }),
    put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/tremendousAccount/${payload.taid}`, method: 'PUT', payload, httpQuery, target }),
  },
  tremendousCampaign: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/tremendousAccount/${payload.tremendousAccountTaid}/tremendousCampaign`, method: 'REPORT', payload, httpQuery, target }),
    setDefault: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/tremendousAccount/${payload.tremendousAccountTaid}/tremendousCampaign/${payload.tremendousCampaignTcid}`, method: 'PUT', payload, httpQuery, target }),

    refresh: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/tremendousAccount/${payload.tremendousAccountTaid}/refreshCampaign`, method: 'POST', payload, httpQuery, target }),
  },
  tremendousFundingSource: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/tremendousAccount/${payload.tremendousAccountTaid}/tremendousFundingSource`, method: 'REPORT', payload, httpQuery, target }),
    setDefault: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/tremendousAccount/${payload.tremendousAccountTaid}/tremendousFundingSource/${payload.tremendousFundingSourceTfsid}`, method: 'PUT', payload, httpQuery, target }),

    refresh: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/tremendousAccount/${payload.tremendousAccountTaid}/refreshFundingSource`, method: 'POST', payload, httpQuery, target }),
  },
};

export default apiTremendous;
