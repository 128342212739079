import moment from 'moment';

/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // handle_socket({ dispatch }, event) {
    //   const { content: { tid, event: eventType } } = event;

    //   if (eventType === 'THREAD_OPENED' || eventType === 'THREAD_CLOSED') {
    //     const isOpen = eventType !== 'THREAD_CLOSED';
    //     dispatch('ApiConversation/threadList/up_changeBulkIsOpen', { tids: [tid], isOpen }, { root: true });
    //   }
    //   if (eventType === 'THREAD_DONE') {
    //     dispatch('ApiConversation/threadList/up_changeBulkIsDone', { tids: [tid] }, { root: true });
    //   }
    //   if (eventType === 'THREAD_READ' || eventType === 'THREAD_UNREAD') {
    //     const isRead = eventType !== 'THREAD_UNREAD';
    //     dispatch('ApiConversation/threadList/up_changeBulkIsRead', { tids: [tid], isRead }, { root: true });
    //   }
    //   if (eventType === 'THREAD_MUTE' || eventType === 'THREAD_UNMUTED') {
    //     const isRead = eventType !== 'THREAD_UNMUTED';
    //     dispatch('ApiConversation/threadList/up_changeBulkIsRead', { tids: [tid], isRead }, { root: true });
    //   }
    //   if (eventType === 'NEW_THREAD' || eventType === 'NEW_MESSAGE') {
    //     // const { thread, ...rest } = event.content.thread;
    //     const payload = { tid: event.content.tid, refreshedThread: { ...event.content.thread } };
    //     dispatch('ApiConversation/threadList/UP_refreshThreadOnSocket', payload, { root: true });
    //   }
    // },
    handle_socket({ dispatch, rootGetters }, event) {
      const { content: { tid, event: eventType } } = event;
      const { 'ApiConversation/threadList/getterThreadList': getterThreadList } = rootGetters;

      const cpid = getterThreadList[tid];

      if (!cpid) return undefined;

      if (eventType === 'NEW_THREAD' || eventType === 'NEW_MESSAGE') {
        const payload = event.content.thread;
        return dispatch('ApiConversation/threadList/UP_refreshThreadOnSocket', payload, { root: true });
      }

      return undefined;
    },
    socket_notifyOnAssignUnassignCustom({ dispatch }, { payload }) {
      console.log('🚀 ~ file: index.js ~ line 50 ~ socket_notifyOnAssignUnassignCustom ~ payload', payload);
      const { threadList } = payload;

      return Promise.all(threadList.map((thread) => dispatch('ApiConversation/threadList/UP_refreshThreadOnSocket', thread, { root: true })));
    },
    socket_threadChangeState({ dispatch, rootGetters }, event) {
      const { content: { tid, event: eventType, tgid } } = event;
      const { 'ApiConversation/threadList/getterThreadList': getterThreadList } = rootGetters;

      const cpid = getterThreadList[tid];
      console.log('🚀 ~ file: index.js ~ line 54 ~ socket_threadChangeState ~ cpid', cpid);

      if (!cpid) return undefined;

      switch (eventType) {
        case 'THREAD_OPENED': return dispatch('ApiContact/contactPlatformOverview/UP_updateThreadState', { payload: { cpids: [cpid], key: 'isOpen', value: true } }, { root: true });
        case 'THREAD_CLOSED': return dispatch('ApiContact/contactPlatformOverview/UP_updateThreadState', { payload: { cpids: [cpid], key: 'isOpen', value: false } }, { root: true });
        case 'THREAD_READ': return dispatch('ApiContact/contactPlatformOverview/UP_updateThreadState', { payload: { cpids: [cpid], key: 'isRead', value: true } }, { root: true });
        case 'THREAD_UNREAD': return dispatch('ApiContact/contactPlatformOverview/UP_updateThreadState', { payload: { cpids: [cpid], key: 'isRead', value: false } }, { root: true });
        case 'THREAD_MUTE': return dispatch('ApiContact/contactPlatformOverview/UP_updateThreadState', { payload: { cpids: [cpid], key: 'isMute', value: true } }, { root: true });
        case 'THREAD_UNMUTED': return dispatch('ApiContact/contactPlatformOverview/UP_updateThreadState', { payload: { cpids: [cpid], key: 'isMute', value: false } }, { root: true });
        case 'THREAD_DONE': return dispatch('ApiContact/contactPlatformOverview/UP_updateThreadDone', { payload: { cpids: [cpid], tgid } }, { root: true });

        default: return undefined;
      }
    },
    handle_newMessage({ dispatch, rootGetters }, content) {
      const {
        'ApiConversation/threadList/getterCurrentThreadTid': currentThreadTid,
        // 'ApiConversation/threadList/getterThreadList': getterThreadList,
      } = rootGetters;

      // const cpid = getterThreadList[content.tid];

      // if (!cpid) return undefined;

      return dispatch('ApiConversation/threadList/UP_refreshThreadOnSocket', content.refreshedThread, { root: true })
        .then(() => {
          if (currentThreadTid && String(currentThreadTid) === String(content.tid)) {
            return dispatch('Channel/thread/message/newMessageSocket', content, { root: true });
          }
          return undefined;
        });

      // if (currentThreadTid && String(currentThreadTid) === String(content.tid)) {
      //   return dispatch('ApiConversation/threadList/UP_refreshThreadOnSocket', content.refreshedThread, { root: true })
      //     .then(() => dispatch('Channel/thread/message/newMessageSocket', content, { root: true }));
      // }
      // if (threadListTids.find((tid) => String(tid) === String(content.tid))) {
      //   return dispatch('ApiConversation/threadList/UP_refreshThreadOnSocket', content.refreshedThread, { root: true });
      // }
      // return dispatch('ApiConversation/threadList/removeThread', { payload: { tid: content.tid } }, { root: true });
    },
    handle_updateMessage({ dispatch, rootGetters }, content) {
      const {
        'ApiConversation/threadList/getterCurrentThreadTid': currentThreadTid,
      } = rootGetters;

      if (currentThreadTid && String(currentThreadTid) === String(content.tid)) {
        dispatch('Channel/thread/message/updateMessageSocket', content, { root: true });
      }
    },
    handle_deleteActivity({ dispatch, rootGetters }, context) {
      const {
        threadByCampaigns,
        content: { aid, cid },
      } = context;

      if (threadByCampaigns && Number(threadByCampaigns) === Number(cid)) {
        const { 'ApiConversation/threadList/getterThreadList': _threadList } = rootGetters;

        const tids = Object.values(_threadList).reduce((acc, row) => {
          const { activity: { aid: _aid }, tid } = row;

          if (Number(aid) === Number(_aid)) acc.push(tid);

          return acc;
        }, []);

        dispatch('ApiConversation/threadList/up_removeThreadTidInThreadList', { tids }, { root: true });
      }

      dispatch('Campaign/reportDetailledCampaign/up_activityFromCampaignDetailled', {
        aid,
        updatedOn: moment().format(),
        endedOn: moment().format(),
      }, { root: true });
    },
  },
  modules: {},
};
