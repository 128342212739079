<template>
  <section class="ubuCollapseChannelGroup">
    <div
      v-if="!isAdmin"
      class="ubuContactCard has-border-bottom"
    >
      <div class="profile">
        <UbuAvatar
          class="avatar"
          :initial-name="initials"
        />
        <div class="info">
          <div class="text">{{ channelGroup.name }}</div>
          <div class="subtext">Member</div>
        </div>
      </div>
    </div>
    <b-collapse
      v-else-if="isAdmin"
      class="card"
      animation="slide"
      :open="isOpen"
      @open="isOpen = true"
      @close="isOpen = false"
    >
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
        >
          <div class="card-header-title">
            <div class="ubuContactCard">
              <div class="profile">
                <UbuAvatar
                  class="avatar"
                  :initial-name="initials"
                />
                <div class="info">
                  <div class="text">{{ channelGroup.name }}</div>
                  <a
                    class="subtext"
                    href="/workspace"
                  >Manage your channels</a>
                </div>
              </div>
            </div>
          </div>
          <a class="card-header-icon">
            <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'"
            />
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <p>
            Channels
          </p>
        </div>
        <div class="content">
          <p>
            Extensions
          </p>
          <div
            v-for="(extension,index) in channelGroup.extensions"
            :key="index"
          >
            {{ extension.name }}
          </div>
        </div>
        <hr>
      </div>
    </b-collapse>
  </section>
</template>

<script>
export default {
  name: 'UbuCollapseChannelGroup',
  props: {
    isAdmin: {
      type: Boolean,
      default: true,
    },
    channelGroup: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    initials() {
      return `${this.channelGroup.name[0]}`;
    },
  },
};
</script>
