var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UbuCustomChip',{attrs:{"model":{
    title: 'Tribes',
    defaultIcon: 'ubu-custom-tribe',
    count: _vm.cpo[_vm.customsName].length > 1 ? _vm.cpo[_vm.customsName].length : null,
    listItem: _vm.cpo[_vm.customsName].map((tribeMember) => ({
      icon: _vm._tribeList[tribeMember.tribeTid].icon,
      color: _vm._tribeList[tribeMember.tribeTid].color,
      label: _vm._tribeList[tribeMember.tribeTid].name,
      status: tribeMember.memberGroupMgid
        ? _vm._memberGroupList[tribeMember.memberGroupMgid].name
        : 'Open',
    }))
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }