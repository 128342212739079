export default {
  data() {
    return {
      icons: {
        INSTAGRAM: {
          icon: 'ubu-social-instagram',
          iconPaths: 2,
        },
        FACEBOOK: {
          icon: 'ubu-social-facebook',
          iconPaths: 2,
        },
        TIKTOK: {
          icon: 'ubu-social-tiktok',
          iconPaths: 7,
        },
        SMS: {
          icon: 'ubu-social-sms',
          iconPaths: 0,
        },
      },
    };
  },
};
