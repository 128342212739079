import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuThreadButton from './UbuThreadButton';
import UbuCRMListForm from './UbuCRMListForm';
import UbuCRMHeader from './UbuCRMHeader';

// CRMFilter
import UbuCRMFilter from './CRMFilter/UbuCRMFilter';
import UbuCRMFilterNumberRange from './CRMFilter/UbuCRMFilterNumberRange';
import UbuCRMFilterDateRange from './CRMFilter/UbuCRMFilterDateRange';
import UbuCRMFilterRadio from './CRMFilter/UbuCRMFilterRadio';
import UbuCRMFilterCheckbox from './CRMFilter/UbuCRMFilterCheckbox';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuThreadButton);
    registerComponent(Vue, UbuCRMListForm);
    registerComponent(Vue, UbuCRMHeader);

    // CRMFilter
    registerComponent(Vue, UbuCRMFilter);
    registerComponent(Vue, UbuCRMFilterNumberRange);
    registerComponent(Vue, UbuCRMFilterDateRange);
    registerComponent(Vue, UbuCRMFilterRadio);
    registerComponent(Vue, UbuCRMFilterCheckbox);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuThreadButton,
  UbuCRMListForm,
  UbuCRMHeader,

  // CRMFilter
  UbuCRMFilter,
  UbuCRMFilterNumberRange,
  UbuCRMFilterDateRange,
  UbuCRMFilterRadio,
  UbuCRMFilterCheckbox,
};
