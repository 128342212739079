<template>
  <UbuDropdown
    position="is-bottom-right"
    :scrollable="true"
    @open="isOpen = true"
    @close="isOpen = false; reset()"
  >
    <template v-slot:button>
      <b-button
        :label="selectedAcfid ? _affiliationCodeFolderList[selectedAcfid].title : 'Select a folder'"
        icon-pack="ubu"
        :icon-left="selectedAcfid ? 'ubu-folder' : ''"
        type="is-secondary"
      />
    </template>

    <template
      v-if="isOpen"
      v-slot:search
    >
      <b-dropdown-item custom>
        <UbuSearchbar
          v-model="search"
          class="start"
          :placeholder="placeholder"
          @clear="reset()"
        />
      </b-dropdown-item>
    </template>

    <template v-slot:content>
      <b-tabs
        v-model="activeTab"
        class="no-header paddingless"
      >
        <b-tab-item value="root">
          <b-dropdown-item v-if="selectedAcfid">
            <div class="multibar row-vcentered">
              <b-icon
                class="start"
                pack="ubu"
                icon="ubu-folder"
              />

              <p class="text start">{{ _affiliationCodeFolderList[selectedAcfid].title }}</p>

              <b-icon
                class="end"
                pack="ubu"
                icon="ubu-check"
              />
            </div>
          </b-dropdown-item>

          <b-dropdown-item
            v-for="folder in folderListFiltered"
            :key="folder.acfid"
            @click="selectFolderAcfid(folder.acfid)"
          >
            <div class="multibar row-vcentered">
              <b-icon
                class="start"
                pack="ubu"
                icon="ubu-folder"
              />

              <p class="text start">{{ folder.title }}</p>
            </div>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="!folderListFiltered.length && !selectedAcfid"
            custom
            class="has-text-secondary"
          >
            No folder with this name in this workspace...
          </b-dropdown-item>

          <b-dropdown-item
            v-if="search"
            custom
          >
            <b-button
              :label="createButtonLabel"
              type="is-ghost is-borderless"
              icon-pack="ubu"
              icon-left="ubu-add"
              expanded
              class="has-text-left"
              style="padding-left: 5px;"
              @click="goToCreateTab()"
            />
          </b-dropdown-item>
        </b-tab-item>

        <b-tab-item value="root.create">
          <b-field
            label="Description"
            class="mb-2"
          >
            <b-input
              ref="descriptionInput"
              v-model="description"
              placeholder="Type some text..."
              type="textarea"
            />
          </b-field>

          <b-button
            label="Create"
            expanded
            type="is-primary"
            @click="create()"
          />
        </b-tab-item>
      </b-tabs>
    </template>
  </UbuDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheAffiliationCodeFolderDropdown',
  mixins: [FilterEngineMixin],
  model: {
    prop: 'selectedAcfid',
  },
  props: {
    selectedAcfid: {
      type: Number,
      default: null,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      description: '',
      isOpen: false,
      activeTab: 'root',
    };
  },
  computed: {
    ...mapGetters({
      _affiliationCodeFolderList: 'TheAffiliationCodeFolderDropdown/affiliationCodeFolderList',
      _currentShop: 'TheAffiliationCodeFolderDropdown/currentShop',
    }),

    placeholder() {
      return this.canCreate ? 'Search or create' : 'Search';
    },

    createButtonLabel() {
      return `Create folder "${this.search}"`;
    },

    folderListFiltered() {
      const folderList = Object.values(this._affiliationCodeFolderList);

      if (!folderList.length) return [];

      const filtersToApply = {
        byShopSid: this._currentShop.sid,
      };

      if (this.search) filtersToApply.searchByTitle = this.search;
      if (this.selectedAcfid) filtersToApply.removeSelected = this.selectedAcfid;

      return this.filterEngine(folderList, filtersToApply)
        .sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
    },

    _filterCollection() {
      return {
        removeSelected(acfid) {
          return (m) => (m.acfid !== acfid ? m : undefined);
        },
        byShopSid(shopSid) {
          return (m) => (m.shopSid === shopSid ? m : undefined);
        },
      };
    },
  },
  methods: {
    ...mapActions({
      createAffiliationCodeFolder: 'TheAffiliationCodeFolderDropdown/createAffiliationCodeFolder',
    }),

    selectFolderAcfid(acfid) {
      this.$emit('input', acfid);
    },

    goToCreateTab() {
      this.activeTab = 'root.create';
      this.$refs.descriptionInput.focus();
    },

    create() {
      return this.createAffiliationCodeFolder({
        payload: {
          sid: this._currentShop.sid,
          shopSid: this._currentShop.sid,
          title: this.search,
          description: this.description.length ? this.description : '',
          createdOn: this.$moment().format(),
        },
      })
        .then(({ response }) => {
          this.$emit('input', response.acfid);
          this.reset();
        });
    },

    reset() {
      this.search = '';
      this.description = '';
      this.activeTab = 'root';
    },
  },
};
</script>
