<template>
  <form @submit.prevent="$emit('connexion')">
    <b-field label="Website URL">
      <b-input
        v-model="localShop.baseUrl"
        placeholder="https://www.mewebsite.com"
        required
      />
      <!-- @input="$update('baseUrl', $event)" -->
    </b-field>

    <b-field label="Shopify store url">
      <b-input
        v-model="localShop.extrefId"
        placeholder="mywebsite.myshopify.com"
        required
      />
      <!-- @input="$update('extrefId', $event)" -->
    </b-field>

    <b-field label="API key">
      <b-input
        v-model="localShop.apiKey"
        placeholder="ab12cd34ef56gh78"
        required
      />
      <!-- @input="$update('apiKey', $event)" -->
    </b-field>

    <b-field label="Admin API access token / Password">
      <b-input
        v-model="localShop.password"
        placeholder="shppa_f77824ee4zed..."
        type=""
        required
      />
      <!-- @input="$update('password', $event)" -->
    </b-field>

    <b-field label="Shared Secret">
      <b-input
        v-model="localShop.sharedSecret"
        placeholder="shpss_7a8d581e8ezd..."
        required
      />
      <!-- @input="$update('sharedSecret', $event)" -->
    </b-field>

    <b-field label="Webhook version">
      <b-input
        v-model="localShop.apiVersion"
        placeholder="2022-01"
        required
      />
      <!-- @input="$update('apiVersion', $event)" -->
    </b-field>

    <br>

    <div class="buttons is-right">
      <b-button
        type="is-primary"
        native-type="submit"
        label="Connect shopify store"
      />
    </div>
  </form>
</template>

<script>
// import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuShopifyConnexionForm',
  // mixins: [VModelMixin],
  model: {
    prop: 'shopifyShop',
  },
  props: {
    shopifyShop: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localShop: {
      get() { return this.shopifyShop; },
      set(val) { this.$emit('input', val); },
    },
  },
};
</script>
