/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import builder from '../engine';

const webhook = {
  report: ({ payload, httpQuery }) => builder({ url: '/shopify/webhook', method: 'REPORT', payload, httpQuery }),
};

export {
  webhook,
};
