/* eslint-disable max-len */

export default {
  actions: {
    UP_upsertCustom({ commit, getters }, { payload }) {
      console.log('🚀 ~ file: customs.js ~ line 6 ~ UP_upsertCustom ~ payload', payload);
      const {
        getterContactPlatformOverviewList,
        getterHeldThreads,
        getterHeldThreadsCpids,
      } = getters;

      const reducer = (array) => array.reduce((acc, row) => {
        acc[row.cpid] = row;

        acc[row.cpid][payload.key] = acc[row.cpid][payload.key].includes(payload.itemId)
          ? acc[row.cpid][payload.key].filter((id) => id !== payload.itemId)
          : [...acc[row.cpid][payload.key], payload.itemId];

        return acc;
      }, {});

      const contactPlatformOverviewList = Object.values(getterContactPlatformOverviewList)
        .filter(({ cpid }) => payload.cpids.includes(cpid) && !getterHeldThreadsCpids.includes(cpid));
      console.log('🚀 ~ file: customs.js ~ line 23 ~ UP_upsertCustom ~ contactPlatformOverviewList', contactPlatformOverviewList);

      if (contactPlatformOverviewList.length) {
        commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
          ...getterContactPlatformOverviewList,
          ...reducer(contactPlatformOverviewList),
        });
      }

      const heldThreadsList = Object.values(getterHeldThreads)
        .filter(({ cpid }) => payload.cpids.includes(cpid));
      console.log('🚀 ~ file: customs.js ~ line 34 ~ UP_upsertCustom ~ heldThreadsList', heldThreadsList);

      if (heldThreadsList.length) {
        commit('UPDATE_HELDS', {
          ...getterHeldThreads,
          ...reducer(heldThreadsList),
        });
      }
    },

    UP_addCustom({ commit, getters }, { payload }) {
      console.log('🚀 ~ file: customs.js ~ line 6 ~ UP_upsertCustom ~ payload', payload);
      const {
        getterContactPlatformOverviewList,
        getterHeldThreads,
        getterHeldThreadsCpids,
      } = getters;

      const reducer = (array) => array.reduce((acc, row) => {
        acc[row.cpid] = row;

        if (acc[row.cpid][payload.key].includes(payload.itemId)) return acc;

        acc[row.cpid][payload.key] = [...acc[row.cpid][payload.key], payload.itemId];

        return acc;
      }, {});

      const contactPlatformOverviewList = Object.values(getterContactPlatformOverviewList)
        .filter(({ cpid }) => payload.cpids.includes(cpid) && !getterHeldThreadsCpids.includes(cpid));
      console.log('🚀 ~ file: customs.js ~ line 23 ~ UP_upsertCustom ~ contactPlatformOverviewList', contactPlatformOverviewList);

      if (contactPlatformOverviewList.length) {
        commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
          ...getterContactPlatformOverviewList,
          ...reducer(contactPlatformOverviewList),
        });
      }

      const heldThreadsList = Object.values(getterHeldThreads)
        .filter(({ cpid }) => payload.cpids.includes(cpid));
      console.log('🚀 ~ file: customs.js ~ line 34 ~ UP_upsertCustom ~ heldThreadsList', heldThreadsList);

      if (heldThreadsList.length) {
        commit('UPDATE_HELDS', {
          ...getterHeldThreads,
          ...reducer(heldThreadsList),
        });
      }
    },

    UP_removeCustom({ commit, getters }, { payload }) {
      console.log('🚀 ~ file: customs.js ~ line 6 ~ UP_upsertCustom ~ payload', payload);
      const {
        getterContactPlatformOverviewList,
        getterHeldThreads,
        getterHeldThreadsCpids,
      } = getters;

      const reducer = (array) => array.reduce((acc, row) => {
        acc[row.cpid] = row;

        if (!acc[row.cpid][payload.key].includes(payload.itemId)) return acc;

        acc[row.cpid][payload.key] = acc[row.cpid][payload.key].filter((id) => id !== payload.itemId);

        return acc;
      }, {});

      const contactPlatformOverviewList = Object.values(getterContactPlatformOverviewList)
        .filter(({ cpid }) => payload.cpids.includes(cpid) && !getterHeldThreadsCpids.includes(cpid));
      console.log('🚀 ~ file: customs.js ~ line 23 ~ UP_upsertCustom ~ contactPlatformOverviewList', contactPlatformOverviewList);

      if (contactPlatformOverviewList.length) {
        commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
          ...getterContactPlatformOverviewList,
          ...reducer(contactPlatformOverviewList),
        });
      }

      const heldThreadsList = Object.values(getterHeldThreads)
        .filter(({ cpid }) => payload.cpids.includes(cpid));
      console.log('🚀 ~ file: customs.js ~ line 34 ~ UP_upsertCustom ~ heldThreadsList', heldThreadsList);

      if (heldThreadsList.length) {
        commit('UPDATE_HELDS', {
          ...getterHeldThreads,
          ...reducer(heldThreadsList),
        });
      }
    },
  },
};
