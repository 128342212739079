<template>
  <section id="templateManager">
    <b-table
      :data="templateListArray"
      paginated
      pagination-size="is-small"
      :opened-detailed="openedDetails"
      detailed
      custom-detail-row
      detail-key="tid"
      :show-detail-icon="false"
    >
      <DevDetails :cnt="{name: 'localRadio', obj: localRadio}" />

      <b-table-column
        searchable
        field="title"
      >
        <template #subheading>
          <form @submit.prevent="createTemplate()">
            <template v-if="localRadio">
              <div class="multibar row-vcentered">
                <b-radio
                  v-model="localRadio"
                  class="start"
                  native-value="custom"
                >
                  Message
                </b-radio>

                <div class="end">
                  <b-button
                    native-type="submit"
                    label="Save as campaign template"
                    type="is-secondary"
                  />
                </div>
              </div>

              <br>

              <b-field>
                <b-input
                  v-model="newTemplate.text"
                  placeholder="Text..."
                  type="textarea"
                  maxlength="1000"
                  @input="$emit('input', $event)"
                />
              </b-field>
            </template>

            <template v-if="openCreateTemplateForm">
              <UbuTemplateForm
                v-model="newTemplate"
                :template-list="templateList"
                :show-controls="true"
              />

              <br>

              <div class="buttons">
                <b-button
                  native-type="submit"
                  value="+ Add template"
                  type="is-ghost is-borderless"
                />
              </div>
            </template>
          </form>
        </template>

        <template #searchable="{ filters, column }">
          <b-input
            v-model="filters[column.field]"
            placeholder="Search by title..."
            expanded
            type="search"
            icon-pack="ubu"
            icon="ubu-search"
            icon-right="ubu-cross"
            icon-right-clickable
            @icon-right-click="filters[column.field] = null"
          />
        </template>

        <template v-slot="props">
          <div class="multibar row-vcentered">
            <b-radio
              v-if="localRadio"
              v-model="localRadio"
              class="start"
              :native-value="`${props.row.tid}`"
            >
              <UbuListCard
                class="start"
                :hoverable="false"
                :focusable="false"
                :payload="{title: props.row.title, content: props.row.text}"
                @editTemplate="isUpdatable = props.row.tid"
                @deleteTemplate="deleteTemplate(props.row.tid)"
              />
            </b-radio>

            <UbuListCard
              v-else
              class="start"
              :hoverable="false"
              :focusable="false"
              :payload="{title: props.row.title, content: props.row.text}"
              @editTemplate="isUpdatable = props.row.tid"
              @deleteTemplate="deleteTemplate(props.row.tid)"
            />

            <b-icon
              class="arrowIcon end"
              pack="ubu"
              :icon="openedDetails.find(
                (tid) => tid === props.row.tid) ? 'ubu-arrow-up' : 'ubu-arrow-down'"
              @click.native="props.toggleDetails(props.row)"
            />
          </div>
        </template>
      </b-table-column>

      <template #detail="props">
        <div class="ubuEditCustom is-detailed">
          <UbuTemplateForm
            v-model="templateList[props.row.tid]"
            class="start"
            :template-list="templateList"
            :show-controls="true"
          />

          <br>

          <b-field label="ACTIONS">
            <b-button
              label="Save"
              type="is-success"
              icon-pack="ubu"
              icon-left="ubu-close"
              class="mr-2"
              @click="editTemplate(props.row)"
            />

            <b-button
              label="Delete"
              type="is-danger"
              icon-pack="ubu"
              icon-left="ubu-trash"
              @click="deleteTemplate(props.row.tid)"
            />
          </b-field>
        </div>
      </template>

      <template #empty>
        <div class="has-text-centered">No records</div>
      </template>
    </b-table>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheTemplateManager',
  model: {
    prop: 'customText',
  },
  props: {
    radio: {
      type: String,
      default: null,
    },
    openCreateTemplateForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newTemplate: {
        title: '',
        text: '',
      },
      isUpdatable: null,
      openedDetails: [],
    };
  },
  computed: {
    ...mapGetters({
      templateList: 'TheTemplateManager/templateList',
    }),
    localRadio: {
      get() { return this.radio; },
      set(val) { this.$emit('update:radio', val); },
    },
    templateListArray() {
      return Object.values(this.templateList);
    },
  },
  methods: {
    ...mapActions({
      _createTemplate: 'TheTemplateManager/createTemplate',
      updateTemplate: 'TheTemplateManager/updateTemplate',
      _deleteTemplate: 'TheTemplateManager/deleteTemplate',
    }),
    // createTemplate() {
    //   // if we use localRadio and we want to save as new template
    //   // we need to give default template title
    //   if (this.localRadio) {
    //     this.newTemplate.title = `Campaign template #${this.templateListArray.length + 1}`;
    //   }

    //   this._createTemplate({ payload: this.newTemplate })
    //     .then(() => {
    //       this.newTemplate = {
    //         title: null,
    //         text: null,
    //       };
    //       this.$emit('closeForm');
    //     });
    // },
    editTemplate(payload) {
      this.updateTemplate({ payload })
        .then(() => { this.isUpdatable = null; });
    },
    deleteTemplate(tid) {
      this._deleteTemplate({ payload: { tid } })
        .then(() => { this.isUpdatable = null; });
    },
  },
};
</script>
