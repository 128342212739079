<template>
  <b-tabs v-model="activeTab">
    <!-- <b-tab-item label="Detail">
      <form @submit.prevent="$emit('updateUbuLead', localUser)">
        <UbuKaraUbuLeadForm
          v-model="localUser"
        />
        <br>
        <div class="buttons is-right">
          <b-button
            type="is-info"
            native-type="submit"
          >
            Update
          </b-button>
        </div>
      </form>
    </b-tab-item> -->

    <b-tab-item label="Rights.">
      <UbuKaraDetailledUsersRights
        :rights="user.rights"
        @removeRight="$emit('removeRight', $event)"
      />
    </b-tab-item>

    <b-tab-item label="Details.">
      <UbuKaraDetailledUsersAuthenticates
        v-model="localUser.authenticates"
        @changeAuthenticate="$emit('changeAuthenticate', $event)"
      />
    </b-tab-item>

    <b-tab-item label="Dev.">
      <DevDetails
        open
        :cnt="{name: 'user details', obj: user}"
      />
    </b-tab-item>
  </b-tabs>
</template>

<script>
export default {
  name: 'UbuKaraDetailledUserTableDetail',
  model: {
    prop: 'user',
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      // localUser: { ...this.user, authenticates: [...this.user.authenticates].arrayMapper('aid') },
    };
  },
  computed: {
    localUser: {
      get() { return { ...this.user, authenticates: [...this.user.authenticates].arrayMapper('aid') }; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
};
</script>
