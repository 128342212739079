import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuLeadForm from './UbuLeadForm';
import UbuLeadsDashboardFiltersForm from './UbuLeadsDashboardFiltersForm';
import UbuLeadsDashboardTable from './UbuLeadsDashboardTable';
import UbuLeadsDashboardTableDetail from './UbuLeadsDashboardTableDetail';
import UbuLeadsDashboardQueryForm from './UbuLeadsDashboardQueryForm';
import UbuSalesContactForm from './UbuSalesContactForm';
import UbuSalesContactTable from './UbuSalesContactTable';
import UbuSalesContactTableDetail from './UbuSalesContactTableDetail';
import UbuScrapingZoneForm from './UbuScrapingZoneForm';
import UbuScrapingZoneTable from './UbuScrapingZoneTable';
import UbuSuggestionsFiltersForm from './UbuSuggestionsFiltersForm';
import UbuSuggestionsQueryForm from './UbuSuggestionsQueryForm';
import UbuKaraTemplateForm from './UbuKaraTemplateForm';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuLeadForm);
    registerComponent(Vue, UbuLeadsDashboardFiltersForm);
    registerComponent(Vue, UbuLeadsDashboardTable);
    registerComponent(Vue, UbuLeadsDashboardTableDetail);
    registerComponent(Vue, UbuLeadsDashboardQueryForm);
    registerComponent(Vue, UbuSalesContactForm);
    registerComponent(Vue, UbuSalesContactTable);
    registerComponent(Vue, UbuSalesContactTableDetail);
    registerComponent(Vue, UbuScrapingZoneForm);
    registerComponent(Vue, UbuScrapingZoneTable);
    registerComponent(Vue, UbuSuggestionsFiltersForm);
    registerComponent(Vue, UbuSuggestionsQueryForm);
    registerComponent(Vue, UbuKaraTemplateForm);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuLeadForm,
  UbuLeadsDashboardFiltersForm,
  UbuLeadsDashboardTable,
  UbuLeadsDashboardTableDetail,
  UbuLeadsDashboardQueryForm,
  UbuSalesContactForm,
  UbuSalesContactTable,
  UbuSalesContactTableDetail,
  UbuScrapingZoneForm,
  UbuScrapingZoneTable,
  UbuSuggestionsFiltersForm,
  UbuSuggestionsQueryForm,
  UbuKaraTemplateForm,
};
