import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheEditAffiliationCodePanel from './smarts/TheEditAffiliationCodePanel';
import TheCreateAffiliationCodePanel from './smarts/TheCreateAffiliationCodePanel';
import TheSetupNewAffiliationCode from './smarts/TheSetupNewAffiliationCode';
import TheCreateAffiliationCodeFromTextarea from './smarts/TheCreateAffiliationCodeFromTextarea';
import TheCreateAffiliationCodeFromCsv from './smarts/TheCreateAffiliationCodeFromCsv';
import TheCreateDiscountCode from './smarts/TheCreateDiscountCode';
import TheAffiliationCartouche from './smarts/TheAffiliationCartouche';
import TheAffiliationCodeOverviewTable from './smarts/TheAffiliationCodeOverviewTable';
import TheAffiliationCodeExportPanel from './smarts/TheAffiliationCodeExportPanel';

import TheCreateAffiliationFolder from './smarts/folder/TheCreateAffiliationFolder';
import TheManageAffiliationCodeFolder from './smarts/folder/TheManageAffiliationCodeFolder';

import TheManagerShopPanel from './smarts/shop/TheManagerShopPanel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheEditAffiliationCodePanel);
    registerComponent(Vue, TheCreateAffiliationCodePanel);
    registerComponent(Vue, TheSetupNewAffiliationCode);
    registerComponent(Vue, TheCreateAffiliationCodeFromTextarea);
    registerComponent(Vue, TheCreateAffiliationCodeFromCsv);
    registerComponent(Vue, TheCreateDiscountCode);
    registerComponent(Vue, TheManageAffiliationCodeFolder);
    registerComponent(Vue, TheAffiliationCartouche);
    registerComponent(Vue, TheAffiliationCodeOverviewTable);
    registerComponent(Vue, TheAffiliationCodeExportPanel);
    registerComponent(Vue, TheCreateAffiliationFolder);
    registerComponent(Vue, TheManagerShopPanel);
  },
};

use(Plugin);

export default Plugin;

export {
  TheEditAffiliationCodePanel,
  TheCreateAffiliationCodePanel,
  TheSetupNewAffiliationCode,
  TheCreateAffiliationCodeFromTextarea,
  TheCreateAffiliationCodeFromCsv,
  TheCreateDiscountCode,
  TheManageAffiliationCodeFolder,
  TheAffiliationCartouche,
  TheAffiliationCodeOverviewTable,
  TheAffiliationCodeExportPanel,
  TheCreateAffiliationFolder,
  TheManagerShopPanel,
};
