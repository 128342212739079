<template>
  <b-menu-item
    v-if="itemSearch && itemSearch.model"
    :key="el.ordinal"
  >
    <template #label>
      <b-field expanded>
        <p class="control">
          <b-button
            type="is-secondary"
            icon-pack="ubu"
            :icon-left="localEl.icon"
            :class="`text-color-${localEl.color}`"
            @click="showPicker = !showPicker"
          />
        </p>

        <b-input
          v-model="itemSearch.model"
          disabled
          expanded
        />

        <p class="control">
          <b-button
            icon-pack="ubu"
            icon-left="ubu-compose"
            class="has-text-color-5"
            @click="$emit(localEl.event, {
              ...localEl, text: itemSearch.model, color: `${localEl.color}`
            })"
          />
        </p>
      </b-field>
      <div
        v-show="showPicker"
        class="icon-color-picker-menu w-100 mt-2"
      >
        <template v-for="color in 12">
          <span
            v-if="color"
            :key="color"
            :class="`UbuColorPicker_color${color}`"
            @click="localEl.color = color; showPicker = false;"
          >
            <b-icon
              :pack="el.pack"
              :icon="el.icon"
              :class="`text-color-${color}`"
              size="is-medium"
            />
          </span>
        </template>
      </div>
    </template>
  </b-menu-item>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'PopoverItemCreator',
  mixins: [VModelMixin],
  model: {
    prop: 'el',
  },
  props: {
    el: {
      type: Object,
      required: true,
    },
    itemSearch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPicker: false,
    };
  },
  computed: {
    localEl: {
      get() { return { ...this.el }; },
      set(newValue) {
        this.$emit('update:PopoverItemCreator', { ...newValue, text: this.itemSearch.model });
      },
    },
  },
};
</script>
