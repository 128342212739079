/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {
    textImported: '',
  },
  getters: {
    getterTextImported: (state) => state.textImported,
  },
  mutations: {
    SET_TEXT_IMPORTED(state, newVal) {
      state.textImported = newVal;
    },
  },
  actions: {
    up_textImported({ commit }, newVal) {
      commit('SET_TEXT_IMPORTED', newVal);
    },
  },
};
