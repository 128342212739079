/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const autoReport = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/autoReport`, method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/autoReport`, method: 'POST', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/autoReport/${payload.tid}`, method: 'DELETE', payload, httpQuery, target }),
};

export default autoReport;
