<template>
  <section id="TheWorkspace">
    <aside class="ubuMenu is-sub-menu">
      <div
        v-for="(item) in routes"
        :key="item.title"
        class="start"
      >
        <div class="menu-item-list">
          <p class="menu-item-title disclaimer is-3 mt-4 mb-4">
            {{ item.title }}
          </p>

          <UbuMenuItem
            v-for="(route) in item.items"
            :key="route.text"
            :item="route"
          />
        </div>
      </div>
    </aside>

    <div class="has-coreview-spaced">
      <router-view />
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheApiKey',
  computed: {
    routes() {
      return [
        {
          title: 'MENU',
          ordinal: 0,
          items: [
            {
              text: 'Active keys',
              path: '/api/list',
            },
          ],
        },
        {
          title: 'DOCUMENTATION',
          ordinal: 1,
          items: [
            {
              text: 'How to use api key ?',
              path: '/api/howToUseApiKey',
            },
            {
              text: 'Orders',
              path: '/api/endpoints',
            },
          ],
        },
      ];
    },
  },
};
</script>
