<template>
  <section
    id="TheThreadPanel"
    class="ubuThreadPanel threadPanel"
  >
    <header class="threadPanelHeader">
      <div
        v-if="isLoading"
        class="subtitle threadPanelHeaderUsername"
      >
        <b-skeleton
          width="125px"
          height="20px"
        />
      </div>
      <p
        v-else-if="_cpo"
        :class="['subtitle threadPanelHeaderUsername', { 'text-truncate': responsive, }]"
      >
        {{ _cpo.contactPlatform.username }}
      </p>

      <div
        v-if="_cpo"
        class="threadPanelHeaderActions"
      >
        <TheActivityDropdown
          v-if="_cpo.lastActivity && showStatusDropdown"
          :custom-button="true"
          :responsive="true"
        />

        <TheAppointDropdown
          class="ml-2"
          :payload="[{
            cpid: _cpo.contactPlatform.cpid,
            tid: _cpo.thread.tid,
            userUid: _cpo.hasUser ? _cpo.hasUser.userUid : null,
          }]"
        />

        <TheTagPopover class="ml-2" />

        <TheLabelPopover class="ml-2" />

        <TheMoreButtonPopover />
      </div>
    </header>

    <TheMessageList
      v-if="_cpo && _cpo.thread"
      :key="_cpo.thread.tid"
      :thread-details="_cpo"
      :loading="isLoading"
      :textarea-height="textareaHeight"
      :header-height="isInfluence ? 151 : 70"
    />

    <TheTextarea
      :height.sync="textareaHeight"
      class="theTextarea"
      :thread-details="_cpo"
      :current-detailled="_cpo"
      @openTemplatePanel="$emit('openTemplatePanel')"
      @hasPanelOpen="$emit('hasPanelOpen', $event)"
      @update:height="textareaHeight = $event;"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters } from 'vuex';

export default {
  name: 'TheThreadPanel',
  data() {
    return {
      textareaHeight: 500,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      _cpo: 'TheContactPlatformPanel/contactPlatformOverview',
      windowWidth: 'TheMenu/getterWindowWidth',
    }),
    responsive() {
      const { windowWidth } = this;

      if (windowWidth > 1469) return false;
      return true;
    },
    isInfluence() {
      const { meta: { viewStoreNavigation } } = this.$route;
      if (viewStoreNavigation === 'TheInfluence') return true;
      return false;
    },
    showStatusDropdown() {
      const { meta: { viewStoreNavigation }, params: { threadByCampaigns } } = this.$route;

      return viewStoreNavigation === 'TheInfluence' || threadByCampaigns;
    },
  },
};
</script>
