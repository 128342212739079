<template>
  <TheTabsPanel
    v-model="activeTab"
    @goToPreviousTab="activeTab = tabItems['root']"
    @close="closePanel()"
  >
    <template v-slot:content>
      <b-tab-item value="root">
        <article>
          <div class="buttons">
            <UbuActionCard
              label="Tribe information"
              icon-pack="ubu"
              icon-left="ubu-settings"
              expanded
              left-text="click to continue"
              @click="activeTab = tabItems['root.tribeInfos']"
            />
            <UbuActionCard
              label="Add new group"
              icon-pack="ubu"
              icon-left="ubu-add"
              expanded
              left-text="click to continue"
              @click="activeTab = tabItems['root.addGroup']"
            />
            <UbuActionCard
              label="Edit groups position"
              icon-pack="ubu"
              icon-left="ubu-adjust"
              expanded
              left-text="click to continue"
              @click="activeTab = tabItems['root.editPosition']"
            />
          </div>
          <!-- <div class="buttons"> -->
          <!-- <draggable
            id="TheTribePeopleColumns"
            v-model="memberGroupColumns"
            draggable=".ubuTribePeopleColumnBtn"
            class="buttons"
            @change="onChange($event)"
          > -->
          <fieldset class="fieldset">
            <legend class="text">Usable groups in this tribe</legend>
            <br>
            <template v-for="(item) in memberGroupList">
              <UbuActionCard
                :key="item.mgid"
                :class="`ubuTribePeopleColumnBtn mb-4
                has-text-color-${memberGroupList[item.mgid].color}`"
                :label="memberGroupList[item.mgid].name"
                icon-pack="ubu"
                :icon-left="memberGroupList[item.mgid].icon"
                expanded
                :disabled="false"
                left-text="click to edit"
                @click="editableMemberGroup = {...memberGroupList[item.mgid]};
                        activeTab = tabItems['root.editGroup']"
              />
            </template>
          </fieldset>
          <!-- </draggable> -->
        </article>
      </b-tab-item>

      <b-tab-item value="root.tribeInfos">
        <UbuTribeForm
          v-model="editableTribe"
          @triggerForm="updateTribe()"
          @delete="deleteTribe()"
        />
      </b-tab-item>

      <b-tab-item value="root.addGroup">
        <UbuMemberGroupForm
          v-model="newMemberGroup"
          @triggerForm="createMemberGroup()"
        />
      </b-tab-item>

      <b-tab-item value="root.editPosition">
        <div v-if="activeTab.tab === 'root.editPosition'">
          <div class="buttons is-right">
            <b-button
              type="is-primary"
              label="Save changes"
              @click="updateTribeWithPosition()"
            />
          </div>

          <br>

          <fieldset class="fieldset">
            <legend class="text">Groups displayed in gallery</legend>
            <br>
            <draggable
              id="TheTribePeopleColumns"
              v-model="memberGroupColumns"
              draggable=".ubuTribePeopleColumnBtn"
              class="buttons"
            >
              <template v-for="(item) in memberGroupColumns">
                <UbuActionCard
                  :key="item.memberGroupMgid"
                  :class="`ubuTribePeopleColumnBtn mb-4
                has-text-color-${memberGroupList[item.memberGroupMgid].color}`"
                  :label="memberGroupList[item.memberGroupMgid].name"
                  icon-pack="ubu"
                  :icon-left="memberGroupList[item.memberGroupMgid].icon"
                  expanded
                  :disabled="false"
                  left-text="click to remove or drag to change position"
                  @click="memberGroupColumns = memberGroupColumns
                    .filter(({ memberGroupMgid }) => memberGroupMgid !== item.memberGroupMgid)"
                />
              </template>
            </draggable>
          </fieldset>

          <br>

          <fieldset class="fieldset">
            <legend class="text">Usable groups in this tribe</legend>
            <br>
            <template v-for="(item) in memberGroupListNotPositionned">
              <UbuActionCard
                :key="item.mgid"
                :class="`ubuTribePeopleColumnBtn mb-4
                has-text-color-${memberGroupList[item.mgid].color}`"
                :label="memberGroupList[item.mgid].name"
                icon-pack="ubu"
                :icon-left="memberGroupList[item.mgid].icon"
                expanded
                :disabled="false"
                left-text="click to add to columns"
                @click="memberGroupColumns = [...memberGroupColumns,
                                              { tribeTid: tribe.tid, memberGroupMgid: item.mgid }]"
              />
            </template>
          </fieldset>
        </div>
      </b-tab-item>

      <b-tab-item value="root.editGroup">
        <UbuMemberGroupForm
          v-if="editableMemberGroup"
          v-model="editableMemberGroup"
          @triggerForm="updateMemberGroup()"
          @delete="deleteMemberGroup()"
        />
      </b-tab-item>
    </template>
  </TheTabsPanel>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import draggable from 'vuedraggable';

export default {
  name: 'TheTribeSettingsPanel',
  components: {
    draggable,
  },
  data() {
    return {
      activeTab: {
        tab: 'root',
        title: 'Settings',
        subtext: 'Tribe',
      },
      editableTribe: {},
      newMemberGroup: {
        name: '',
        color: '0',
        icon: 'ubu-folder',
      },
      editableMemberGroup: null,
      memberGroupColumns: [],
    };
  },
  computed: {
    ...mapGetters({
      tribeList: 'TheTribe/tribeList',
      memberGroupList: 'TheTribe/memberGroupList',
    }),

    localModel: {
      get() { return { ...this.tribeList[this.$route.params.tribeTid] }; },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },

    tabItems() {
      return {
        root: {
          tab: 'root',
          title: 'Settings',
          subtext: 'Tribe',
        },
        'root.tribeInfos': {
          tab: 'root.tribeInfos',
          title: 'Edit Tribe information',
          subtext: 'Tribe',
        },
        'root.addGroup': {
          tab: 'root.addGroup',
          title: 'Add Group',
          subtext: 'Tribe',
        },
        'root.editGroup': {
          tab: 'root.editGroup',
          title: 'Edit Group',
          subtext: 'Tribe',
        },
        'root.editPosition': {
          tab: 'root.editPosition',
          title: 'Edit Groups positions',
          subtext: 'Tribe',
        },
      };
    },

    tribe() {
      return this.tribeList[this.$route.params.tribeTid];
    },

    memberGroupListNotPositionned() {
      const mappedMemberGroupColumns = this.memberGroupColumns.arrayMapper('memberGroupMgid');

      return Object.values(this.memberGroupList).filter(({ mgid }) => !mappedMemberGroupColumns[mgid]);
    },
  },
  mounted() {
    this.editableTribe = { ...this.tribeList[this.$route.params.tribeTid] };
    this.memberGroupColumns = [...this.editableTribe.memberGroupPosition].sort((a, b) => a.index - b.index);
  },
  methods: {
    ...mapActions({
      _updateTribe: 'TheTribe/updateTribe',
      _deleteTribe: 'TheTribe/deleteTribe',

      _createMemberGroup: 'TheTribe/createMemberGroup',
      _updateMemberGroup: 'TheTribe/updateMemberGroup',
      _deleteMemberGroup: 'TheTribe/deleteMemberGroup',

      UP_tribe: 'TheTribe/UP_tribe',
    }),
    upsertTribeMemberGroupPosition(columns) {
      const newColumns = columns.map((column, index) => ({ ...column, index }));
      this.UP_tribe({ ...this.tribe, memberGroupPosition: newColumns });
    },

    closePanel() {
      this.$emit('closePanel');
    },

    updateTribe() {
      const { memberGroupPosition, population, ...restTribe } = this.editableTribe;
      return this._updateTribe({ payload: restTribe })
        .then(() => {
          this.activeTab = this.tabItems.root;
        });
    },

    updateTribeWithPosition() {
      const { memberGroupPosition, population, ...restTribe } = this.editableTribe;

      return this._updateTribe({ payload: { ...restTribe, memberGroupPosition: this.memberGroupColumns.map((column, index) => ({ ...column, index })) } })
        .then(() => {
          this.activeTab = this.tabItems.root;
        });
    },

    deleteTribe() {
      return this._deleteTribe({ payload: { tid: this.$route.params.tribeTid } })
        .then(() => {
          this.$router.push('/tribe');
        });
    },

    createMemberGroup() {
      return this._createMemberGroup({ payload: this.newMemberGroup })
        .then(() => {
          this.activeTab = this.tabItems.root;
          this.newMemberGroup = {
            name: '',
            color: '0',
            icon: 'ubu-folder',
          };
        });
    },

    updateMemberGroup() {
      return this._updateMemberGroup({ payload: this.editableMemberGroup })
        .then(() => {
          this.activeTab = this.tabItems.root;
        });
    },

    deleteMemberGroup() {
      return Promise.resolve()
        .then(() => this.upsertTribeMemberGroupPosition(this.memberGroupColumns.filter(({ memberGroupMgid }) => memberGroupMgid !== this.editableMemberGroup.mgid)))
        .then(() => this._deleteMemberGroup({ payload: { mgid: this.editableMemberGroup.mgid } }))
        .then(() => {
          this.activeTab = this.tabItems.root;
        });
    },
  },
};
</script>
