import moment from 'moment';

import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    segmentFilterList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAnalytics/segmentFilter/getterSegmentFilterList': segmentFilterList } = rootGetters;

      return segmentFilterList;
    },
  },
  actions: {
    getSegmentFilter({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAnalytics/segmentFilter/get',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'Get segmentFilter',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to get segment.', indefinite: true }));
    },

    reportSegmentFilter({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAnalytics/segmentFilter/report',
        context: {
          payload: { cgid: currentChannelGroup.cgid },
        },
        textNotifier: 'Report segmentFilter',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report segment list.', indefinite: true }));
    },

    createSegmentFilter({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAnalytics/segmentFilter/create',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
            createdOn: moment().format(),
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'Create segmentFilter',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to create segment.', indefinite: true }));
    },

    updateSegmentFilter({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAnalytics/segmentFilter/update',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'Update segmentFilter',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to update segment.', indefinite: true }));
    },

    deleteSegmentFilter({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAnalytics/segmentFilter/delete',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'Delete segmentFilter',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to delete segment.', indefinite: true }));
    },
  },
};
