/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    tribeMentionList: {},
  },
  mutations: {
    SET_TRIBE_MENTION_LIST(state, newData) { state.tribeMentionList = newData; },
    RESET_TRIBE_MENTION_LIST(state) { state.tribeMentionList = {}; },
  },
  getters: {
    getterTribeMentionList: (state) => state.tribeMentionList,
  },
  actions: {
    resetTribeMentionList({ commit }) {
      console.log('🚀 ~ file: mention.store.js ~ line 19 ~ resetTribeMentionList ~ resetTribeMentionList');
      commit('RESET_TRIBE_MENTION_LIST');
      commit('ApiTribe/tribeMember/RESET_TRIBE_MEMBER_LIST', null, { root: true });
    },

    UP_fav({ commit, rootGetters, getters }, { pids, shouldFav = true }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const tribeMentionList = { ...getters.getterTribeMentionList };

      const updatedTribeMentionList = Object.values(tribeMentionList)
        .map(({ pid, favorite, ...rest }) => {
          if (!pids.includes(pid)) return { pid, favorite, ...rest };
          return ({
            ...rest,
            pid,
            favorite: shouldFav
              ? {
                channelGroupCgid: currentChannelGroup.cgid,
                publicationPid: pid,
              }
              : {
                channelGroupCgid: null,
                publicationPid: null,
              },
          });
        })
        .arrayMapper('pid');

      commit('SET_TRIBE_MENTION_LIST', updatedTribeMentionList);
    },

    report({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.REPORT')) return undefined;

      const { 'ApiTribe/tribeMember/getterTribeMemberList': getterTribeMemberList } = rootGetters;

      return clark.api.apiTribe.mention.report({ payload, httpQuery })
        .then(({ results, total }) => {
          const { tribeMemberList, tribeMentionList } = results.reduce((acc, row) => {
            const {
              tribeMember,
              contactPlatform,
              contactPlatformStatistic,
              ...rest
            } = row;

            acc.tribeMemberList.push({
              tmid: tribeMember.tmid,
              cpid: tribeMember.contactPlatformCpid,
              tribeMember,
              contactPlatform,
              contactPlatformStatistic,
            });

            acc.tribeMentionList.push({
              tmid: tribeMember.tmid,
              ...rest,
            });
            return acc;
          }, { tribeMemberList: [], tribeMentionList: [] });

          commit('ApiTribe/tribeMember/SET_TRIBE_MEMBER_LIST', { ...getterTribeMemberList, ...tribeMemberList.arrayMapper('tmid') }, { root: true });
          commit('SET_TRIBE_MENTION_LIST', { ...getters.getterTribeMentionList, ...tribeMentionList.arrayMapper('pid') });
          return total;
        });
    },
  },
};
