/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    salesUserList: {},
  },
  mutations: {
    SET_SALES_USER_ASSIGN_LIST(state, newDatas) { state.salesUserList = newDatas; },
    DELETE_SALES_USER_ASSIGN(state, newData) {
      state.salesUserList = Object.values(state.salesUserList).filter(({ userUid }) => userUid !== newData.userUid).arrayMapper('userUid');
    },
  },
  getters: {
    getterSalesUserList: (state) => state.salesUserList,
  },
  actions: {
    create({ commit, rootGetters }, context) {
      console.log('🚀 ~ file: salesUserAssign.store.js ~ line 21 ~ createSalesUserAssign ~ payload', context.payload);
      if (!isActionAllowed(rootGetters, 'SALES_USER_ASSIGN.CREATE')) return undefined;

      return clark.api.salesUser.assign
        .post(context)
        .then((assigneds) => {
          console.log('🚀 ~ file: salesUserAssign.store.js ~ line 26 ~ .then ~ assigneds', assigneds);
          const {
            'Sales/reportDetailledLead/getterDetailledLeadList': leadsList,
            'Sales/salesUser/getterSalesUserList': salesUserList,
          } = rootGetters;

          const leadsWithAssigned = assigneds.map(({ leadLid, userUserUid, assignedOn }) => {
            const currentLead = leadsList[leadLid];
            const { assignedTo = [] } = currentLead;
            return ({
              ...currentLead,
              assignedTo: [...assignedTo || [], { ...salesUserList[userUserUid], assignedOn }],
            });
          });
          console.log('🚀 ~ file: salesUserAssign.store.js ~ line 40 ~ leadsWithAssigned ~ leadsWithAssigned', leadsWithAssigned);
          commit('Sales/reportDetailledLead/UPDATE_DETAILLED_LEAD_LIST', leadsWithAssigned, { root: true });
          return leadsWithAssigned;
        });
    },

    deleteSalesUserAssign({ commit, rootGetters }, payload) {
      if (!isActionAllowed(rootGetters, 'SALES_USER_ASSIGN.DELETE')) return undefined;

      return clark.api.salesUser.assign
        .delete({ payload, httpQuery: {} })
        .then(() => { commit('DELETE_SALES_USER_ASSIGN', payload); });
    },
  },
};
