import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    translate({ commit, rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'TRANSLATE_TEXT.TRANSLATE')) return undefined;

      const toTranslate = {
        text: [payload.text],
        targetLang: payload.targetLang,
      };

      return clark.api.translateText.post({ payload: toTranslate, httpQuery })
      // use promise resolve instead of provider to chain tests
      // return Promise.resolve()
      //   .then(() => ([{ text: 'Translated...........' }]))
        .then((results) => {
          /**
          * if we have customId we try to translate a message & need to update store
          * else just return data to update textarea input field
          */
          if (payload.customId) {
            const { 'Channel/thread/message/getterMessageList': messageList } = rootGetters;

            const translatedMessage = {
              [payload.customId]: {
                ...payload,
                translatedText: results[0].text,
              },
            };

            commit('Channel/thread/message/SET_MESSAGE_LIST', { ...messageList, ...translatedMessage }, { root: true });
          }

          return results;
        });
    },
    revert({ commit, rootGetters }, { payload }) {
      const { 'Channel/thread/message/getterMessageList': messageList } = rootGetters;

      const revertedMessage = {
        [payload.customId]: {
          ...messageList[payload.customId],
          translatedText: null,
        },
      };

      commit('Channel/thread/message/SET_MESSAGE_LIST', { ...messageList, ...revertedMessage }, { root: true });
    },
  },
};
