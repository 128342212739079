<template>
  <section>
    <b-tab-item value="root.addToTribe">
      <UbuSearchbar
        v-model="search"
        @clear="search = ''"
      />

      <br>

      <div class="buttons">
        <UbuActionCard
          v-for="tribe in filteredTribeList"
          :key="tribe.tid"
          :class="`mb-4 has-text-color-${tribe.color}`"
          :label="tribe.name"
          icon-pack="ubu"
          :icon-left="tribe.icon"
          expanded
          @click="selectTribeAndNext(tribe.tid)"
        />
      </div>
    </b-tab-item>

    <b-tab-item value="root.addToTribe.selectGroup">
      <UbuSearchbar
        v-model="search"
        @clear="search = ''"
      />

      <br>

      <div class="buttons">
        <UbuActionCard
          class="mb-4"
          label="Manage group later"
          icon-pack="ubu"
          icon-left=""
          expanded
          @click="apply(null)"
        />

        <UbuActionCard
          v-for="group in filteredGroupList"
          :key="group.mgid"
          :class="`mb-4 has-text-color-${group.color}`"
          :label="group.name"
          icon-pack="ubu"
          :icon-left="group.icon"
          expanded
          @click="apply(group.mgid)"
        />
      </div>
    </b-tab-item>

    <b-tab-item value="root.done">
      <b-message
        v-if="isSuccess && peopleToAdd.length > 0"
        type="is-success"
      >
        <div class="multibar row-vcentered">
          <b-icon
            pack="ubu"
            icon="ubu-success"
            size="is-medium"
            class="start"
          />
          <span class="start">
            Success! {{ peopleToAdd.length }} people added to this Tribe.
          </span>
        </div>
      </b-message>

      <b-message
        v-if="isSuccess && peopleToEdit.length > 0"
        type="is-success"
      >
        <div class="multibar row-vcentered">
          <b-icon
            pack="ubu"
            icon="ubu-success"
            size="is-medium"
            class="start"
          />
          <span class="start">
            Success! {{ peopleToEdit.length }} people changed group from this Tribe.
          </span>
        </div>
      </b-message>

      <!-- <b-message
        v-if="peopleAlreadyInThisTribe"
        type="is-classic"
      >
        <div class="multibar row-vcentered">
          <span class="start">
            {{ peopleAlreadyInThisTribe }} people were already in this Tribe.
          </span>
        </div>
      </b-message> -->

      <b-message
        v-if="errorMessage"
        type="is-danger"
      >
        <p class="text is-3">
          {{ errorMessage }}
        </p>

        <div class="multibar row-vcentered mt-2 mb-2">
          <b-button
            type="is-ghost"
            class="start"
            label="Download"
            @click="downloadPayload()"
          />
        </div>
      </b-message>
    </b-tab-item>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';
import download from 'downloadjs';

export default {
  name: 'TheAddToTribe',
  mixins: [FilterEngineMixin],
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Object,
      required: true,
    },
    tabItems: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      selectedTid: null,

      peopleToAdd: [],
      peopleToEdit: [],

      isSuccess: false,
      errorMessage: null,

      finalizedPayload: null,
    };
  },
  computed: {
    ...mapGetters({
      _tribeList: 'TheAddToTribe/tribeList',
      _memberGroupList: 'TheAddToTribe/memberGroupList',
    }),

    localChecked: {
      get() { return this.checkedRows; },
      set(value) { this.$emit('input', value); },
    },

    filteredTribeList() {
      const tribeList = Object.values(this._tribeList);

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByName = this.search;

      return this.filterEngine(tribeList, filtersToApply);
    },

    filteredGroupList() {
      const groupList = Object.values(this._memberGroupList);

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByName = this.search;

      return this.filterEngine(groupList, filtersToApply);
    },

  },
  methods: {
    ...mapActions({
      _createTribeMember: 'TheAddToTribe/createTribeMember',
      _updateTribeMember: 'TheAddToTribe/updateTribeMember',
    }),

    UP_changeToNewTab(activeTab) {
      this.$emit('update:activeTab', activeTab);
    },

    selectTribeAndNext(tid) {
      this.selectedTid = tid;
      this.UP_changeToNewTab(this.tabItems['root.addToTribe.selectGroup']);
    },

    apply(mgid) {
      this.isSuccess = false;
      this.errorMessage = null;

      this.peopleToAdd = this.localChecked
        // eslint-disable-next-line max-len
        .filter(({ tribeMemberList }) => !tribeMemberList || !tribeMemberList.find(({ tribeTid }) => tribeTid === this.selectedTid));

      this.peopleToEdit = this.localChecked
        // eslint-disable-next-line max-len
        .filter(({ tribeMemberList }) => tribeMemberList && tribeMemberList.find(({ tribeTid }) => tribeTid === this.selectedTid));

      const payloadToAdd = {
        tid: this.selectedTid,
        tribeMemberList: this.peopleToAdd.map(({ cpid }) => ({
          tribeTid: this.selectedTid,
          contactPlatformCpid: cpid,
          memberGroupMgid: mgid,
          createdOn: this.$moment().format(),
        })),
      };

      const payloadToEdit = {
        tid: this.selectedTid,
        tribeMemberList: this.peopleToEdit
          .flatMap(({ tribeMemberList }) => tribeMemberList)
          .reduce((acc, row) => {
            if (row.tribeTid !== this.selectedTid) return acc;
            acc.push({ ...row, memberGroupMgid: mgid });
            return acc;
          }, []),
      };

      this.finalizedPayload = { payloadToAdd, payloadToEdit };

      this.UP_changeToNewTab(this.tabItems['root.done']);

      return Promise.resolve()
        .then(() => {
          if (!payloadToAdd.tribeMemberList.length) return undefined;

          return this._createTribeMember({
            payload: payloadToAdd,
            mutations: [{
              target: 'ApiContact/contactPlatformOverview/UP_createTribeMember',
              payload: {
                cpids: this.peopleToAdd.map(({ cpid }) => cpid),
              },
            }],
          });
        })

        .then(() => {
          if (!payloadToEdit.tribeMemberList.length) return undefined;

          return this._updateTribeMember({
            payload: payloadToEdit,
            mutations: [{
              target: 'ApiContact/contactPlatformOverview/UP_updateTribeMember',
              payload: {
                cpids: this.peopleToEdit.map(({ cpid }) => cpid),
                tribeMemberList: payloadToEdit.tribeMemberList,
              },
            }],
          });
        })

        .then(() => { this.isSuccess = true; })
        .catch(() => { this.errorMessage = 'Error during process, please contact support'; });
    },

    downloadPayload() {
      download(JSON.stringify(this.finalizedPayload, null, 2), 'test.json', 'text/plain');
    },
  },
};
</script>
