import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    tremendousAccountList: {},
    currentTremendousAccount: {},
  },
  mutations: {
    SET_TREMENDOUS_ACCOUNT_LIST(state, newData) { state.tremendousAccountList = newData; },
    SET_CURRENT_TREMENDOUS_ACCOUNT(state, newData) { state.currentTremendousAccount = newData; },
  },
  getters: {
    getterTremendousAccountList: (state) => state.tremendousAccountList,
    getterCurrentTremendousAccount: (state) => state.currentTremendousAccount,
  },
  actions: {
    report({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiTremendous.tremendousAccount.report({ payload, httpQuery })
        .then((tremendousAccountList) => {
          if (!tremendousAccountList.length) return;

          commit('SET_TREMENDOUS_ACCOUNT_LIST', tremendousAccountList.arrayMapper('taid'));
          commit('SET_CURRENT_TREMENDOUS_ACCOUNT', tremendousAccountList[0]);
        });
    },

    create({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiTremendous.tremendousAccount.post({ payload, httpQuery })
        .then((tremendousAccount) => {
          commit('SET_TREMENDOUS_ACCOUNT_LIST', { ...getters.getterTremendousAccountList, [tremendousAccount.taid]: tremendousAccount });
          commit('SET_CURRENT_TREMENDOUS_ACCOUNT', tremendousAccount);
        });
    },

    update({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiTremendous.tremendousAccount.put({ payload, httpQuery })
        .then((tremendousAccount) => {
          commit('SET_TREMENDOUS_ACCOUNT_LIST', { ...getters.getterTremendousAccountList, [tremendousAccount.taid]: tremendousAccount });
          commit('SET_CURRENT_TREMENDOUS_ACCOUNT', tremendousAccount);
        });
    },
  },
};
