/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  actions: {
    export({ rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'EXPORT_ANALYTICS_XLSX.EXPORT')) return undefined;

      return clark.api.channelGroup.exportAnalyticsXlsx.post({ payload, httpQuery });
    },
  },
};
