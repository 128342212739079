<template>
  <div
    id="TheHome"
    class="multibar column"
  />
</template>

<script>
export default {
  name: 'TheHome',
};
</script>

<style lang="scss">
#TheHome {
  // background-image: url('../../../assets/images/login-background-dark.png');
  background-size: cover;
  height: 100%;
  flex-grow: 1;
  // opacity: .4;
  &::after {
    content: ' ';
    display: flex;
    height: 100%;
    background: var(--overlay);
    opacity: .7;
  }
}
</style>
