import { dispatchAndForward, dispatchFailure } from '../$utils/helpers';

export default {
  getters: {},
  actions: {
    emitSegmentEvent({ dispatch }, { event }) {
      const action = {
        dispatch,
        target: 'Segment/segmentTrack',
        context: { event },
        textNotifier: 'emit segment event',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
