<template>
  <UbuContactPlatformPanelItemTogglable
    @isOpen="togglePrivate($event)"
  >
    <template #headerContent>
      <div class="start multibar row-vcentered w-100">
        <h3 class="start text is-3 noCutOnText">Private information</h3>
      </div>
    </template>

    <template #content>
      <b-button
        class="p-0 ml-2"
        type="is-ghost"
        label="Save changes"
        :disabled="!canSaveChanges"
        @click="isUpdating ? updateContactPrivateMetadata() : createContactPrivateMetadata()"
      />
      <form v-if="localContactPrivateMetadata && localContactPrivateMetadata.contactCid">
        <div class="tile is-child">
          <b-field
            label="First name"
            expanded
          >
            <b-input
              v-model="localContactPrivateMetadata.sensitive.firstName"
              placeholder="Carolina"
              expanded
              custom-class="ubuTransparentInput"
              @input="cleanValue('firstName', $event)"
            />
          </b-field>
        </div>

        <div class="tile is-child">
          <b-field
            label="Last name"
            expanded
          >
            <b-input
              v-model="localContactPrivateMetadata.sensitive.lastName"
              placeholder="Rossi"
              expanded
              custom-class="ubuTransparentInput"
              @input="cleanValue('lastName', $event)"
            />
          </b-field>
        </div>

        <div class="tile is-child">
          <b-field
            label="Private email"
          >
            <b-input
              v-model="localContactPrivateMetadata.sensitive.privateEmail"
              type="email"
              placeholder="Enter value..."
              custom-class="ubuTransparentInput"
              @input="cleanValue('privateEmail', $event)"
            />
          </b-field>
        </div>

        <div class="tile is-child">
          <b-field
            label="Birthday"
            grouped
          >
            <b-datepicker
              v-model="localBirthday"
              position="is-top-right"
              :mobile-native="true"
              :max-date="$moment().toDate()"
              :nearby-selectable-month-days="true"
              locale="fr-CA"
              icon-pack="ubu"
              icon="ubu-calendar"
              icon-prev="ubu-arrow-left"
              icon-next="ubu-arrow-right"
              placeholder="Type or select a date..."
              editable
              expanded
              custom-class="ubuTransparentInput"
            />
          </b-field>
        </div>

        <div class="tile is-child">
          <b-field
            label="Location"
          >
            <b-input
              v-model="localContactPrivateMetadata.city"
              placeholder="Enter value..."
              custom-class="ubuTransparentInput"
              @input="cleanValue('city', $event)"
            />
          </b-field>
        </div>

        <div class="tile is-child">
          <b-field
            label="Language"
          >
            <b-input
              v-model="localContactPrivateMetadata.language"
              placeholder="Enter value..."
              custom-class="ubuTransparentInput"
              @input="cleanValue('language', $event)"
            />
          </b-field>
        </div>
      </form>

      <br>
    </template>
  </UbuContactPlatformPanelItemTogglable>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheContactPlatformPanelPrivateMetadata',
  data() {
    return {
      localContactPrivateMetadata: null,
      isUpdating: false,
      isCreating: false,
    };
  },
  computed: {
    ...mapGetters({
      contactPlatformOverview: 'TheContactPlatformPanel/contactPlatformOverview',
      _contactPrivateMetadata: 'TheContactPlatformPanel/contactPrivateMetadata',
      _currentChannelGroup: 'Authenticate/getterCurrentChannelGroup',
    }),
    canSaveChanges() {
      if (!this._contactPrivateMetadata && this.isUpdating) return false;
      if (!this.localContactPrivateMetadata) return false;
      const {
        language = null,
        city = null,
        sensitive = {
          privateEmail: null,
          phone: null,
          localPhone: null,
          firstName: null,
          lastName: null,
          birthday: null,
          gender: null,
        },
      } = this._contactPrivateMetadata || {};
      const { language: _language, city: _city, sensitive: _sensitive } = this.localContactPrivateMetadata;
      return JSON.stringify({ language, city, sensitive }) !== JSON.stringify({ language: _language, city: _city, sensitive: _sensitive });
    },
    localBirthday: {
      get() {
        if (!this.localContactPrivateMetadata.sensitive.birthday) return null;
        return this.$moment(this.localContactPrivateMetadata.sensitive.birthday).toDate();
      },
      set(newValue) {
        this.localContactPrivateMetadata.sensitive.birthday = newValue;
      },
    },
  },
  mounted() {
    this.resetContactPrivateMetadata();
  },
  methods: {
    ...mapActions({
      getFavikonProfile: 'TheContactPlatformPanel/getFavikonProfile',
      _updateSheetNote: 'TheContactPlatformPanel/updateSheetNote',
      _getContactPrivateMetadata: 'TheContactPlatformPanel/getContactPrivateMetadata',
      _createContactPrivateMetadata: 'TheContactPlatformPanel/createContactPrivateMetadata',
      _updateContactPrivateMetadata: 'TheContactPlatformPanel/updateContactPrivateMetadata',
      resetContactPrivateMetadata: 'ApiContact/contactPrivateMetadata/resetContactPrivateMetadata',
    }),
    cleanValue(key, value) {
      const val = value && value.length ? value : null;
      switch (key) {
        case 'firstName': this.localContactPrivateMetadata.sensitive.firstName = val; break;
        case 'lastName': this.localContactPrivateMetadata.sensitive.lastName = val; break;
        case 'privateEmail': this.localContactPrivateMetadata.sensitive.privateEmail = val; break;
        case 'birthday': this.localContactPrivateMetadata.sensitive.birthday = val; break;
        case 'city': this.localContactPrivateMetadata.city = val; break;
        case 'language': this.localContactPrivateMetadata.language = val; break;
        default: break;
      }
    },
    togglePrivate(isOpen) {
      if (isOpen) {
        this.getContactPrivateMetadata();
      }
      this.isEditingForm = false;
    },
    getContactPrivateMetadata() {
      const { contactCid } = this.contactPlatformOverview.contactPlatform;

      return this._getContactPrivateMetadata({ payload: { contactCid } })
        .then((cpm) => {
          this.localContactPrivateMetadata = { ...cpm, sensitive: { ...cpm.sensitive } };
          this.isUpdating = true;
        })
        .catch(() => {
          this.localContactPrivateMetadata = {
            channelGroupCgid: this._currentChannelGroup.cgid,
            contactCid: this.contactPlatformOverview.contactPlatform.contactCid,
            tokenLink: null,
            truncatedName: null,
            website: null,
            language: null,
            country: null,
            city: null,
            interests: null,
            extra: null,
            optinPhone: false,
            optinEmail: false,
            optinAddress: false,
            hashedEmail: null,
            hashedPhone: null,
            hasAddress: false,
            hasGender: false,
            hasBirthday: false,
            sensitive: {
              privateEmail: null,
              phone: null,
              localPhone: null,
              firstName: null,
              lastName: null,
              birthday: null,
              gender: null,
            },
            createdOn: this.$moment().format(),
            updatedOn: this.$moment().format(),
            deleteRequestOn: null,
            staticAge: 0,
          };
          this.isCreating = true;
        });
    },
    createContactPrivateMetadata() {
      const payload = this.localContactPrivateMetadata;

      return this._createContactPrivateMetadata({ payload })
        .then((cpm) => {
          this.localContactPrivateMetadata = { ...cpm, sensitive: { ...cpm.sensitive } };
          this.isUpdating = true;
        });
    },
    updateContactPrivateMetadata() {
      const payload = this.localContactPrivateMetadata;

      return this._updateContactPrivateMetadata({ payload })
        .then((cpm) => {
          this.localContactPrivateMetadata = { ...cpm, sensitive: { ...cpm.sensitive } };
          this.isUpdating = true;
        });
    },
  },
};
</script>
