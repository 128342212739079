/* eslint-disable max-len */
import { getters, actions } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  getters: {
    ...getters.campaign,
  },
  actions: {
    // ...actions.campaign,
  },
};
