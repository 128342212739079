import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        {
          path: '/setting/theme',
          text: 'Theme',
        },
      ],
    },
  },
  getters: {
    navigation: (state) => state.navigation,
    ...getters.theme,
    ...getters.authenticate,
    ...getters.account,
    ...getters.userDetail,
  },
  actions: {
    ...actions.theme,
    ...actions.authenticate,
    ...actions.account,
    ...actions.userDetail,
  },
};
