import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheHashtagsBulkPanel from './smarts/TheHashtagsBulkPanel';
import TheHashtagsCreatePanel from './smarts/TheHashtagsCreatePanel';
import TheMentionCartouche from './smarts/TheMentionCartouche';
import TheAutoReportPanel from './smarts/TheAutoReportPanel';
import TheActivityMentionBulkPanel from './smarts/TheActivityMentionBulkPanel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheHashtagsBulkPanel);
    registerComponent(Vue, TheHashtagsCreatePanel);
    registerComponent(Vue, TheMentionCartouche);
    registerComponent(Vue, TheAutoReportPanel);
    registerComponent(Vue, TheActivityMentionBulkPanel);
  },
};

use(Plugin);

export default Plugin;

export {
  TheHashtagsBulkPanel,
  TheHashtagsCreatePanel,
  TheMentionCartouche,
  TheAutoReportPanel,
  TheActivityMentionBulkPanel,
};
