/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import accountMemberView from './accountMemberView.store';
import userSetting from './userSetting.store';
import userDetail from './userDetail.store';
import setRole from './setRole.store';
import setLocked from './setLocked.store';

export default {
  namespaced: true,
  state: {
    userList: {},
  },
  mutations: {
    SET_USER_LIST(state, newData) { state.userList = newData; },
    RESET_STORE(state) {
      state.userList = {};
    },
  },
  getters: {
    getterUserList: (state) => state.userList,
  },
  actions: {
    get({ commit }, { payload, httpQuery }) {
      return clark.api.user.get({ payload, httpQuery })
        .then(({ userDetail: user }) => {
          commit('User/userDetail/SET_USER_DETAIL_TARGETED', user, { root: true });
        });
    },
    create({ commit, getters: { getterUserList } }, { payload, httpQuery = {} }) {
      return clark.api.user.post({ payload, httpQuery })
        .then(({ newData }) => {
          commit('SET_USER_LIST', { ...getterUserList, [newData.uid]: newData });

          return newData;
        });
    },
    up_userList({ commit, getters: { getterUserList } }, user) {
      commit('SET_USER_LIST', { ...getterUserList, [user.uid]: user });
    },
  },
  modules: {
    accountMemberView,
    userSetting,
    userDetail,
    setRole,
    setLocked,
  },
};
