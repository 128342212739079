import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {

  },
  actions: {
    createDiscountCode({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/discountCode/create',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Create discount code',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to create discount code.', indefinite: true }));
    },
  },
};
