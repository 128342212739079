import Ws from '@dailyplanet/src-websocket';
import { mapGetters, mapActions } from 'vuex';
import { snackBarSuccess } from '@dailyplanet/data-stores/$utils/helpers';

import { SnackbarProgrammatic as Snackbar } from 'buefy';

export default {
  data() {
    return {
      basePath: '',
      socketIsConnected: false,
      syncDiscountCodesPath: '',
      threadBoxAllPath: '',
      allTimeTopics: [],
    };
  },
  computed: {
    ...mapGetters({
      currentChannelGroup: 'Authenticate/getterCurrentChannelGroup',
      // threadList: 'ApiConversation/threadList/getterThreadList',
      threadListTids: 'ApiConversation/threadList/getterThreadListTids',
      getterAuthenticate: 'Authenticate/getterAuthenticate',
      getterSyncDiscountCodeIsLoading: 'ApiAffiliation/syncDiscountCode/getterSyncDiscountCodeIsLoading',
    }),
    myChannelInstagram() {
      if (!this.getterAuthenticate
        || !this.getterAuthenticate.channelList
        || !this.getterAuthenticate.channelList.length
        || !this.currentChannelGroup
        || !this.currentChannelGroup.cgid) return undefined;

      return this.getterAuthenticate.channelList
        .find(({ platformName, channelGroupCgid }) => this.currentChannelGroup.cgid === channelGroupCgid && platformName === 'INSTAGRAM');
    },
  },
  methods: {
    ...mapActions({
      UP_discountCodeList: 'ChannelGroup/discountCode/UP_discountCodeList',
      handle_socket: 'Socket/handle_socket',
      socket_threadChangeState: 'Socket/socket_threadChangeState',
      socket_notifyOnAssignUnassignCustom: 'Socket/socket_notifyOnAssignUnassignCustom',
      handle_newMessage: 'Socket/handle_newMessage',
      handle_updateMessage: 'Socket/handle_updateMessage',
      handle_deleteActivity: 'Socket/handle_deleteActivity',
      // refreshThreadOnSocket: 'ApiConversation/threadList/refreshThreadOnSocket',
      up_counterList: 'ChannelGroup/counter/up_counterList',
      updateMessageSocket: 'Channel/thread/message/updateMessageSocket',
      deleteMessageSocket: 'Channel/thread/message/deleteMessageSocket',
      up_campaignList: 'Campaign/up_campaignList',
      end_campaign: 'Campaign/end_campaign',
      up_activityFromCampaignDetailled: 'Campaign/reportDetailledCampaign/up_activityFromCampaignDetailled',
      up_isDisconnectedToPrivate: 'Account/up_isDisconnectedToPrivate',
      up_connectedToPrivate: 'Account/up_connectedToPrivate',
      setIsLoadingMoreMessagesList: 'TheInbox/up_isLoadingMoreMessagesList',
      emitSegmentEvent: 'TheInbox/emitSegmentEvent',
      up_threadGroupList: 'ThreadGroup/up_threadGroupList',
      remove_threadGroup: 'ThreadGroup/remove_threadGroup',
      up_contactGroupList: 'ContactGroup/up_contactGroupList',
      remove_contactGroup: 'ContactGroup/remove_contactGroup',
      up_templateList: 'ApiConversation/template/up_templateList',
      remove_template: 'ApiConversation/template/remove_template',
      up_newComment: 'r_Comment/SOCKET_newComment',
      up_newCommentFb: 'r_Comment/SOCKET_newCommentFb',
      up_deleteComment: 'r_Comment/SOCKET_deleteComment',
      up_sendCommentError: 'r_Comment/SOCKET_sendCommentError',
      up_closeComments: 'r_Comment/SOCKET_toggleCloseComments',
    }),
    connect(isConnected) {
      if (!this.currentChannelGroup || !this.currentChannelGroup.cgid) return;

      Ws.onconnect = () => {
        window.socketConnected = true;
      };

      if (!window.socketConnected) {
        Ws.connect({});
        this.socketIsConnected = isConnected;
        this.subscribeStream();
      }
    },

    generatePath() {
      this.basePath = `rt.channelGroup.${this.currentChannelGroup.cgid}`;

      this.syncDiscountCodesPath = `${this.basePath}.syncDiscountCodes`;
      this.inboxCountersUpdate = `${this.basePath}.updateCounters`;
      this.connectPrivate = `${this.basePath}.connectPrivate`;
      this.disconnectPrivate = `${this.basePath}.disconnectPrivate`;
      this.loadMoreFinish = `${this.basePath}.loadMoreFinish`;

      this.newMessage = `${this.basePath}.newMessage`;
      this.updatedMessage = `${this.basePath}.updatedMessage`;
      this.deleteMessage = `${this.basePath}.deleteMessage`;

      this.updateThreadsState = `${this.basePath}.updateThreadsState`;
      this.notifyOnAssignUnassignCustom = `${this.basePath}.notifyOnAssignUnassignCustom`;

      this.createCampaign = `${this.basePath}.createCampaign`;
      this.updateCampaign = `${this.basePath}.updateCampaign`;
      this.deleteCampaign = `${this.basePath}.deleteCampaign`;

      this.createActivity = `${this.basePath}.createActivity`;
      this.updateActivity = `${this.basePath}.updateActivity`;
      this.deleteActivity = `${this.basePath}.deleteActivity`;

      this.createThreadGroup = `${this.basePath}.createThreadGroup`;
      this.updateThreadGroup = `${this.basePath}.updateThreadGroup`;
      this.deleteThreadGroup = `${this.basePath}.deleteThreadGroup`;

      this.createContactGroup = `${this.basePath}.createContactGroup`;
      this.updateContactGroup = `${this.basePath}.updateContactGroup`;
      this.deleteContactGroup = `${this.basePath}.deleteContactGroup`;

      this.createTemplate = `${this.basePath}.createTemplate`;
      this.updateTemplate = `${this.basePath}.updateTemplate`;
      this.deleteTemplate = `${this.basePath}.deleteTemplate`;
      // this.threadBoxAllPath = `${this.basePath}.threadBox.*`;
      this.allTimeTopics = [];

      if (this.myChannelInstagram) {
        // REPENTANCE
        this.repentanceBasePath = `rt.channel.${this.myChannelInstagram.cid}`;

        this.newComment = `${this.repentanceBasePath}.newComment`;
        this.deleteComment = `${this.repentanceBasePath}.deleteComment`;
        this.sendCommentError = `${this.repentanceBasePath}.sendCommentError`;
        this.newCommentFb = `${this.repentanceBasePath}.newCommentFb`;
        this.closeComments = `${this.repentanceBasePath}.closeComments`;
      }
    },

    subscribeStream() {
      this.generatePath();

      Ws.subscribeStream(this.syncDiscountCodesPath);
      // Ws.subscribeStream(this.inboxCountersUpdate);
      Ws.subscribeStream(this.connectPrivate);
      Ws.subscribeStream(this.disconnectPrivate);
      // Ws.subscribeStream(this.loadMoreFinish);

      // Ws.subscribeStream(this.newMessage);
      // Ws.subscribeStream(this.updatedMessage);
      // Ws.subscribeStream(this.deleteMessage);

      // Ws.subscribeStream(this.updateThreadsState);
      Ws.subscribeStream(this.notifyOnAssignUnassignCustom);

      Ws.subscribeStream(this.createCampaign);
      Ws.subscribeStream(this.updateCampaign);
      Ws.subscribeStream(this.deleteCampaign);

      Ws.subscribeStream(this.createActivity);
      Ws.subscribeStream(this.updateActivity);
      Ws.subscribeStream(this.deleteActivity);

      Ws.subscribeStream(this.createThreadGroup);
      Ws.subscribeStream(this.updateThreadGroup);
      Ws.subscribeStream(this.deleteThreadGroup);

      Ws.subscribeStream(this.createContactGroup);
      Ws.subscribeStream(this.updateContactGroup);
      Ws.subscribeStream(this.deleteContactGroup);

      Ws.subscribeStream(this.createTemplate);
      Ws.subscribeStream(this.updateTemplate);
      Ws.subscribeStream(this.deleteTemplate);

      if (this.myChannelInstagram) {
        Ws.subscribeStream(this.newComment);
        Ws.subscribeStream(this.deleteComment);
        Ws.subscribeStream(this.sendCommentError);
        Ws.subscribeStream(this.newCommentFb);
        Ws.subscribeStream(this.closeComments);
      }

      this.listeners();

      // Ws.subscribeStream(this.threadBoxAllPath);
    },

    unsubscribeStream() {
      Ws.unSubscribeStream(this.syncDiscountCodesPath);
      // Ws.unSubscribeStream(this.inboxCountersUpdate);
      Ws.unSubscribeStream(this.connectPrivate);
      Ws.unSubscribeStream(this.disconnectPrivate);
      // Ws.unSubscribeStream(this.loadMoreFinish);

      // Ws.unSubscribeStream(this.newMessage);
      // Ws.unSubscribeStream(this.updatedMessage);
      // Ws.unSubscribeStream(this.deleteMessage);

      // Ws.unSubscribeStream(this.updateThreadsState);
      Ws.unSubscribeStream(this.notifyOnAssignUnassignCustom);

      Ws.unSubscribeStream(this.createCampaign);
      Ws.unSubscribeStream(this.updateCampaign);
      Ws.unSubscribeStream(this.deleteCampaign);

      Ws.unSubscribeStream(this.createActivity);
      Ws.unSubscribeStream(this.updateActivity);
      Ws.unSubscribeStream(this.deleteActivity);

      Ws.unSubscribeStream(this.createThreadGroup);
      Ws.unSubscribeStream(this.updateThreadGroup);
      Ws.unSubscribeStream(this.deleteThreadGroup);

      Ws.unSubscribeStream(this.createContactGroup);
      Ws.unSubscribeStream(this.updateContactGroup);
      Ws.unSubscribeStream(this.deleteContactGroup);

      Ws.unSubscribeStream(this.createTemplate);
      Ws.unSubscribeStream(this.updateTemplate);
      Ws.unSubscribeStream(this.deleteTemplate);

      if (this.myChannelInstagram) {
        Ws.unSubscribeStream(this.newComment);
        Ws.unSubscribeStream(this.deleteComment);
        Ws.unSubscribeStream(this.sendCommentError);
        Ws.unSubscribeStream(this.newCommentFb);
        Ws.unSubscribeStream(this.closeComments);
      }

      Object.keys(Ws.streams).forEach((stream) => {
        if (stream.includes(`rt.channelGroup.${this.currentChannelGroup.cgid}.threadBox`)) {
          Ws.unSubscribeStream(stream);
        }
      });
    },

    subscribeInboxStream() {
      console.log('🚀 ~ file: SocketMixin.js:231 ~ subscribeInboxStream ~ subscribeInboxStream:');
      this.generatePath();

      Ws.subscribeStream(this.inboxCountersUpdate);
      Ws.subscribeStream(this.loadMoreFinish);

      Ws.subscribeStream(this.newMessage);
      Ws.subscribeStream(this.updatedMessage);
      Ws.subscribeStream(this.deleteMessage);

      Ws.subscribeStream(this.updateThreadsState);

      this.listeners();

      // Ws.subscribeStream(this.threadBoxAllPath);
    },

    unsubscribeInboxStream() {
      console.log('🚀 ~ file: SocketMixin.js:249 ~ unsubscribeInboxStream ~ unsubscribeInboxStream:');
      Ws.unSubscribeStream(this.inboxCountersUpdate);
      Ws.unSubscribeStream(this.loadMoreFinish);

      Ws.unSubscribeStream(this.newMessage);
      Ws.unSubscribeStream(this.updatedMessage);
      Ws.unSubscribeStream(this.deleteMessage);

      Ws.unSubscribeStream(this.updateThreadsState);

      Object.keys(Ws.streams).forEach((stream) => {
        if (stream.includes(`rt.channelGroup.${this.currentChannelGroup.cgid}.threadBox`)) {
          Ws.unSubscribeStream(stream);
        }
      });
    },

    bulkSubscribeStream(cgid, streams) {
      this.basePath = `rt.channelGroup.${cgid}`;
      streams.forEach((stream) => {
        Ws.subscribeStream(`${this.basePath}.${stream}`);
      });
      this.BulkListeners(streams);
    },

    bulkUnsubscribeStream(cgid, streams) {
      this.basePath = `rt.channelGroup.${cgid}`;
      streams.forEach((stream) => {
        Ws.unSubscribeStream(`${this.basePath}.${stream}`);
      });
    },

    listeners() {
      Ws.on(this.updateThreadsState, (mess) => {
        console.log('🚀 ~ file: SocketMixin.js ~ line 237 ~ Ws.on ~ mess', mess);
        const { tids, tgid, event } = mess.content;
        tids.forEach((tid) => this.socket_threadChangeState({ content: { tid, event, tgid } }));
      });

      Ws.on(this.notifyOnAssignUnassignCustom, (mess) => {
        console.warn('🚀 ~ file: SocketMixin.js ~ line 252 ~ Ws.on ~ mess', mess);
        this.socket_notifyOnAssignUnassignCustom({ payload: mess.content });
      });

      Ws.on(this.syncDiscountCodesPath, (mess) => {
        console.log('🚀 ~ file: SocketMixin.js ~ line 149 ~ Ws.on ~ mess', mess);
        this.UP_discountCodeList(mess.content)
          .then(() => {
            if (!this.getterSyncDiscountCodeIsLoading) return undefined;

            return Snackbar.open({
              ...snackBarSuccess,
              message: 'Sync discount code list is done',
              indefinite: true,
            });
          });
      });
      Ws.on(this.inboxCountersUpdate, () => {
        this.up_counterList();
      });
      Ws.on(this.newMessage, (mess) => {
        this.handle_newMessage(mess.content);
      });
      Ws.on(this.updatedMessage, (mess) => {
        this.handle_updateMessage(mess.content);
      });

      Ws.on(this.deleteMessage, (mess) => {
        if (this.$route.params.tid && this.$route.params.tid === mess.content.tid) {
          this.deleteMessageSocket(mess.content);
          // this.refreshThreadOnSocket(mess.content);
        }
      });
      Ws.on(this.loadMoreFinish, (mess) => {
        this.setIsLoadingMoreMessagesList({ isLoading: false, tid: mess.content.tid });
      });

      /**
       * CUSTOMS
       */
      // TAG
      Ws.on(this.createThreadGroup, (mess) => {
        this.up_threadGroupList(mess.content);
      });
      Ws.on(this.updateThreadGroup, (mess) => {
        this.up_threadGroupList(mess.content);
      });
      Ws.on(this.deleteThreadGroup, (mess) => {
        this.remove_threadGroup(mess.content);
      });

      // LABEL
      Ws.on(this.createContactGroup, (mess) => {
        this.up_contactGroupList(mess.content);
      });
      Ws.on(this.updateContactGroup, (mess) => {
        this.up_contactGroupList(mess.content);
      });
      Ws.on(this.deleteContactGroup, (mess) => {
        this.remove_contactGroup(mess.content);
      });

      // TEMPLATE
      Ws.on(this.createTemplate, (mess) => {
        this.up_templateList(mess.content);
      });
      Ws.on(this.updateTemplate, (mess) => {
        this.up_templateList(mess.content);
      });
      Ws.on(this.deleteTemplate, (mess) => {
        this.remove_template(mess.content);
      });
      /**
       * CUSTOMS
       */

      /**
       * INFLUENCE
       */
      Ws.on(this.createCampaign, (mess) => {
        this.up_campaignList(mess.content);
      });
      Ws.on(this.updateCampaign, (mess) => {
        this.up_campaignList(mess.content);
      });
      Ws.on(this.deleteCampaign, (mess) => {
        this.end_campaign(mess.content);
      });
      Ws.on(this.createActivity, (mess) => {
        console.log('🚀 ~ file: SocketMixin.js ~ line 129 ~ Ws.on ~ createActivity', mess);
      });
      Ws.on(this.updateActivity, (mess) => {
        this.up_activityFromCampaignDetailled(mess.content);
      });
      Ws.on(this.deleteActivity, (mess) => {
        const { threadByCampaigns } = this.$route.params;

        this.handle_deleteActivity({ ...mess, threadByCampaigns });
      });
      /**
       * INFLUENCE
       */

      /**
       * PRIVATE STATE
       */
      Ws.on(this.disconnectPrivate, (mess) => {
        this.up_isDisconnectedToPrivate(mess.content);
        this.emitSegmentEvent({ event: 'disconnectPrivate' });
      });
      Ws.on(this.connectPrivate, (mess) => {
        this.up_connectedToPrivate(mess.content);
        this.emitSegmentEvent({ event: 'connectPrivate' });
      });
      /**
       * PRIVATE STATE
       */

      // Ws.on(this.threadBoxAllPath, (mess) => {
      //   console.log('🚀 ~ file: App.vue ~ line 99 ~ connectSocket ~ mess', mess);
      // });

      /**
       * REPENTANCE
       */
      if (this.myChannelInstagram) {
        Ws.on(this.newComment, (mess) => {
          this.up_newComment(mess.content);
        });
        Ws.on(this.deleteComment, (mess) => {
          this.up_deleteComment(mess.content);
        });
        Ws.on(this.sendCommentError, (mess) => {
          this.up_sendCommentError(mess.content);
        });
        Ws.on(this.newCommentFb, (mess) => {
          this.up_newCommentFb(mess.content);
        });
        Ws.on(this.closeComments, (mess) => {
          this.up_closeComments(mess.content);
        });
      }
    },

    BulkListeners(streams) {
      streams.forEach((stream) => {
        Ws.on(`${this.basePath}.${stream}`, (mess) => {
          if (stream.split('.')[0] === 'threadBox') this.handle_socket(mess);
        });
      });
    },
  },
};
