/* eslint-disable max-len */
export default {
  getters: {
    getterIsTextareaFocused: (_state, _getters, _rootState, rootGetters) => {
      const { 'Shortcut/getterFocusedElement': focusedElement } = rootGetters;

      return focusedElement === 'TEXTAREA';
    },
    getterIsThreadListFocused: (_state, _getters, _rootState, rootGetters) => {
      const { 'Shortcut/getterFocusedElement': focusedElement } = rootGetters;

      return focusedElement === 'THREAD_LIST';
    },
  },
  actions: {
    up_focusedElement({ dispatch }, { name }) {
      return dispatch('Shortcut/up_focusedElement', { name }, { root: true });
    },
  },
};
