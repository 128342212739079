/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import moment from 'moment';
import Errors from '@dailyplanet/utils/Errors';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    activitiesList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiInfluence/activity/getterActivitiesList': activitiesList } = rootGetters;

      return activitiesList; // IS IT USED SOMEWHERE ???
    },
    activityStatusListFiltered(_state, _getters, _rootState, rootGetters) {
      const { 'ActivityStatus/getterActivityStatusList': getterActivityStatusList } = rootGetters;

      const activitiesList = Object.values(getterActivityStatusList);

      if (!activitiesList.length) return [];

      // const unUse = ['UNSCHEDULED'];
      // const statusOrder = {
      //   TALKING: 1, IN_PROGRESS: 2, COLLAB_AGREEMENT: 3, PRODUCT_SHIPPED: 4, AWAITING_MENTION: 5, SUCCESS: 7, FAILURE: 8, CANCELED: 9,
      // };

      return activitiesList
        // .filter((status) => !unUse.includes(status.status))
        .sort((a, b) => a.ordinal - b.ordinal);
    },
  },
  actions: {
    updateActivity({ dispatch, rootGetters }, { payload: { cid, ...activity } }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiInfluence/activity/update',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            cid,
            ...activity,
            updatedOn: moment().format(),
          },
        },
        textNotifier: 'update activity',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => {
          let event = 'changeActivityStatus';
          if (activity.status === 'SUCCESS') event = 'changeActivityStatusToSuccess';

          dispatch('Segment/segmentTrack', { event }, { root: true });
        })
        .catch((e) => {
          Snackbar.open({
            message: 'Unable to update activity.',
            ...snackBarFailure,
          });

          dispatchFailure(action);
          throw new Errors(e);
        });
    },

    reportActivities({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const action = {
        dispatch,
        target: 'ApiInfluence/activity/report',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'report activities',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report activities.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
  },
};
