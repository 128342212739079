/* eslint-disable max-len */
// import moment from 'moment';
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    threadAndParticipantList: {},
  },
  mutations: {
    SET_THREAD_AND_PARTICIPANT_LIST(state, newData) { state.threadAndParticipantList = newData; },
    RESET_STORE(state) {
      state.threadAndParticipantList = {};
    },
  },
  getters: {
    getterThreadAndParticipantList: (state) => state.threadAndParticipantList,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'THREADLIST.REPORT')) return undefined;

      return clark.api.channelGroup.threadAndParticipant.report({ payload, httpQuery })
        .then((threadAndParticipantList) => {
          commit('SET_THREAD_AND_PARTICIPANT_LIST', threadAndParticipantList.arrayMapper('cpid'));
        });
    },
  },
};
