<template>
  <video
    ref="video"
    controls
    muted
    class="UbuInfluenceMentionVideo"
  >
    <source
      :src="url"
      type="video/mp4"
    >
    <b-image
      :src="srcFallback"
      lazy
    />
    Sorry, your browser doesn't support embedded videos.
  </video>
</template>

<script>
export default {
  name: 'UbuMediaVideo',
  props: {
    url: {
      type: String,
      required: true,
    },
    srcFallback: {
      type: String,
      default: '',
    },
  },
};
</script>
