/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {},
  getters: {
    ...getters.template,
    ...getters.templateFolder,
    ...getters.privateNote,
    ...getters.influence,
    ...getters.shopify,
    ...getters.user,
    ...getters.authenticate,
    ...getters.account,
    ...getters.shopify,
    ...getters.shortcut,
    ...getters.message,
  },
  actions: {
    ...actions.template,
    ...actions.templateFolder,
    ...actions.privateNote,
    ...actions.channelGroup,
    ...actions.crm,
    ...actions.shopify,
    ...actions.thread,
    ...actions.shortcut,
    ...actions.message,
    ...actions.segment,
    ...actions.crewUser,
  },
};
