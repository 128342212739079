/* eslint-disable max-len */
import ShortcutMixin from '@dailyplanet/mixins/ShortcutMixin';

export default {
  mixins: [ShortcutMixin],
  data() {
    return {
      defaultSortOrder: 'desc',
    };
  },
  methods: {
    pageChange(page) {
      this.page = page;
      this.reportDatas();
    },

    onSort(sortField, sortOrder) {
      if (`${this.sortField},${this.sortOrder}` !== `${sortField},${sortOrder}`) {
        this.sortField = sortField;
        this.sortOrder = sortOrder;
        this.reportDatas();
      }
    },

    prepareFiltersToRequest(allowedFilters, filtersToUse) {
      const removeUnUsableFiltersAndMap = filtersToUse.reduce((acc, row) => {
        const { operator, key, values } = row;
        const parsedValues = [...values];

        if (allowedFilters.includes(key)) {
          // dans le cas du totalRevenue la value est stockée en centimes dans le back
          // donc on fait un *100 pour avoir des resultats coherents
          if (key.includes('totalRevenue')) {
            parsedValues[0] = values[0] ? values[0] * 100 : null;
            parsedValues[1] = values[1] ? values[1] * 100 : null;
          }
          acc.push({ operator, key, values: parsedValues });
        }

        return acc;
      }, []);

      return JSON.stringify(removeUnUsableFiltersAndMap);
    },

    customSort(rowA, rowB, isAsc, column) {
      const nestings = column.field.split('.');

      const getValue = (item) => nestings.reduce((acc, it) => {
        if (acc) return acc[it];
        if (!acc && item[it]) return item[it];

        return acc;
      }, undefined);

      let a;
      let b;

      if (['UbuTableActivityStatusPopover'].includes(column.cmp)) {
        a = rowA.campaignActivity.status || '';
        b = rowB.campaignActivity.status || '';
        if (isAsc) return a.localeCompare(b);
        if (!isAsc) return b.localeCompare(a);
      }

      if (['UbuTableSimple', 'UbuTableProfile'].includes(column.cmp)) {
        a = getValue(rowA) || '';
        b = getValue(rowB) || '';
        if (isAsc) return a.localeCompare(b);
        if (!isAsc) return b.localeCompare(a);
      }

      if (['UbuTableNumber', 'UbuTableNumberDecimalFormatter', 'UbuTableAffiliationFolder'].includes(column.cmp)) {
        a = Number(getValue(rowA)) || 0;
        b = Number(getValue(rowB)) || 0;
      }

      if (['UbuTableDate'].includes(column.cmp)) {
        a = this.$moment(getValue(rowA)).unix() || 0;
        b = this.$moment(getValue(rowB)).unix() || 0;
      }

      if (isAsc) return a - b;
      if (!isAsc) return b - a;

      return null;
    },

    shortcutHandlerTable(event, list) {
      const {
        ctrlIsPressed,
        enterIsPressed,
        escapeIsPressed,
      } = this.parseEvent(event);

      if (enterIsPressed && ctrlIsPressed) {
        const index = list.findIndex((row) => row[this.detailKey] === this.selected[this.detailKey]);
        if (index >= 0) {
          this.$emit('input', { ...list.filter((_, i) => i !== index).arrayMapper(this.detailKey) });
          return;
        }
        this.$emit('input', { ...this.checkedRows, [this.selected[this.detailKey]]: this.selected });
      }
      if (enterIsPressed && !ctrlIsPressed) {
        this.$emit('selectContactPlatform', this.selected.contactPlatform.cpid);
      }
      if (escapeIsPressed) {
        this.$emit('unselectContactPlatform', null);
      }
    },
  },
};
