/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  getters: {
    ...getters.contactGroup,
    ...getters.thread,
  },
  actions: {
    ...actions.contactGroup,
    ...actions.thread,
  },
};
