<template>
  <div>
    <p class="title is-1">Error during your download... 🙀</p>

    <br>

    <p class="has-text-secondary">Contact support for more informations</p>

    <br>

    <div class="buttons">
      <b-button
        tag="router-link"
        to="/home"
        expanded
        type="is-primary"
        class="no-padding-horizontal"
        label="Go home"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'TheErrorDownload',
};
</script>
