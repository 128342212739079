<template>
  <div>
    <UbuSearchbar
      v-model="search"
      @clear="search = ''"
    />

    <br>

    <div class="buttons">
      <UbuActionCard
        v-for="mg in filteredList"
        :key="mg.mgid"
        :class="`mb-4 has-text-color-${mg.color}`"
        :label="mg.name"
        icon-pack="ubu"
        icon-left="ubu-folder"
        expanded
        @click="bulkAssign(mg.mgid, mg.memberWithoutGroup)"
      >
        <template v-slot:left-content>
          <div :class="`end multibar has-text-color-${mg.color}`">
            <b-icon
              v-if="!mg.memberWithoutGroup.length"
              pack="ubu"
              icon="ubu-success"
              size="is-medium"
            />
            <b-icon
              v-else
              pack="ubu"
              icon="ubu-in-progress"
              class="has-text-secondary"
              size="is-medium"
            />
          </div>
        </template>
      </UbuActionCard>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheBulkAssignMemberGroup',
  mixins: [FilterEngineMixin],
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      memberGroupList: 'TheTribe/memberGroupList',
    }),

    localChecked: {
      get() { return this.checkedRows; },
      set(value) { this.$emit('input', value); },
    },

    unassignedByGroup() {
      const mgList = Object.values(this.memberGroupList);

      return mgList.map((mg) => ({
        ...mg,
        memberWithoutGroup: this.localChecked
          .filter(({ tribeMember }) => tribeMember.memberGroupMgid !== mg.mgid),
      }));
    },

    filteredList() {
      const mgList = this.unassignedByGroup;

      if (!mgList.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByName = this.search;

      return this.filterEngine(mgList, filtersToApply);
    },

  },
  methods: {
    ...mapActions({
      updateTribeMember: 'TheTribe/updateTribeMember',
    }),

    bulkAssign(mgid, memberWithoutGroup) {
      if (!memberWithoutGroup.length) {
        this.$buefy.snackbar.open({
          message: 'All people added to group',
          type: 'is-success',
          position: 'is-top',
        });
        this.$emit('done');
        return undefined;
      }

      const tribeMemberList = memberWithoutGroup.map(({ tribeMember }) => ({ ...tribeMember, memberGroupMgid: mgid }));

      return this.updateTribeMember({ payload: { tid: this.$route.params.tribeTid, tribeMemberList } })
        .then(() => {
          this.$buefy.snackbar.open({
            message: `${tribeMemberList.length} people moved to group ${this.memberGroupList[mgid].name}`,
            type: 'is-success',
            position: 'is-top',
          });
          // this.$emit('done');
        });
    },
  },
};
</script>
