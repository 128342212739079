/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import { mapGetters } from 'vuex';
import { nFormatter } from '@dailyplanet/utils/formate';

export default {
  computed: {
    ...mapGetters({
      _memberGroupList: 'TheFilters/memberGroupList',
      _tribeList: 'TheFilters/tribeList',

      _tagList: 'TheFilters/threadGroupList',
      _labelList: 'TheFilters/contactGroupList',
      _activeCampaignList: 'TheFilters/activeCampaignList',
      _crmListList: 'TheFilters/crmListList',
      _activityStatusListFiltered: 'TheFilters/activityStatusListFiltered',
      _campaignList: 'TheFilters/campaignList',
      _crewUserStateEnumList: 'TheFilters/crewUserStateEnumList',
      _affiliationCodeFolderList: 'TheFilters/affiliationCodeFolderList',
      _currentShop: 'TheFilters/currentShop',

      contactLocationList: 'TheFilters/contactLocationList',
      contactLanguageList: 'TheFilters/contactLanguageList',
    }),
    memberGroupList() {
      return Object.values(this._memberGroupList)
        .map(({ name, mgid }) => ({ label: name, value: mgid }));
    },

    tribeList() {
      return Object.values(this._tribeList)
        .map(({ name, tid, icon, color }) => ({ label: name, value: tid, icon, color }));
    },

    activityStatusListFiltered() {
      return Object.values(this._activityStatusListFiltered)
        .map(({ label, icon, status }) => ({ label, value: status, icon }));
    },

    languageList() {
      return this.contactLanguageList.map(({ language }) => ({ label: language, value: language }));
    },

    locationList() {
      return this.contactLocationList.map(({ location }) => ({ label: location, value: location }));
    },

    tagList() {
      return Object.values(this._tagList).map(({ tgid, label, color }) => ({
        label, value: tgid, icon: 'ubu-custom-tag', color,
      }));
    },

    labelList() {
      return Object.values(this._labelList).map(({ cgid, label, color }) => ({
        label, value: cgid, icon: 'ubu-custom-contact', color,
      }));
    },

    activeCampaignList() {
      return Object.values(this._activeCampaignList).map(({ cid, name, color }) => ({
        label: name, value: cid, icon: 'ubu-custom-influence', color,
      }));
    },

    crmListList() {
      const crmListList = this._crmListList ? this._crmListList : {};
      return Object.values(crmListList).map(({ clid, name, color }) => ({
        label: name, value: clid, icon: 'ubu-custom-lists', color,
      }));
    },
    allCampaignList() {
      return Object.values(this._campaignList).map(({ cid, name, color }) => ({
        label: name, value: cid, icon: 'ubu-custom-influence', color,
      }));
    },
    affiliationCodeFolderListBySelectedShop() {
      return Object.values(this._affiliationCodeFolderList).reduce((acc, row) => {
        if (row.shopSid === this._currentShop.sid) acc.push({ label: row.title, value: row.acfid });
        return acc;
      }, []);
    },
    affiliationCodeTypeList() {
      return [
        { label: 'Discount', value: 'DISCOUNT' },
        { label: 'Referral', value: 'REFERRAL' },
      ];
    },

    genericCatalog() {
      return {
        hasCrmLists: {
          key: 'hasCrmLists',
          entity: 'Lists',
          type: 'checkbox',
          group: 'Primary',
          ordinal: 0,
          operator: 'is one of',
          allowedOperators: ['is one of'],
          list: this.crmListList,
        },
        'contact.publicEmail': {
          key: 'contact.publicEmail',
          entity: 'Public email',
          type: 'radio',
          group: 'About',
          ordinal: 1,
          list: [{ label: 'True', value: true }, { label: 'False', value: false }],
        },
        'contactPlatformStatistic.hasThread': {
          key: 'contactPlatformStatistic.hasThread',
          entity: 'Has thread',
          type: 'radio',
          group: 'About',
          ordinal: 2,
          list: [{ label: 'True', value: true }, { label: 'False', value: false }],
        },
        'contact.type': {
          key: 'contact.type',
          entity: 'Account type',
          type: 'radio',
          group: 'About',
          ordinal: 3,
          list: [{ label: 'Business', value: 'BUSINESS' }, { label: 'Influence', value: 'INFLUENCE' }],
        },
        'contact.language': {
          key: 'contact.language',
          entity: 'Language',
          type: 'checkbox',
          group: 'About',
          ordinal: 4,
          list: this.languageList,
        },
        'contact.location': {
          key: 'contact.location',
          entity: 'Location',
          type: 'checkbox',
          group: 'About',
          ordinal: 5,
          list: this.locationList,
        },
        'contact.age': {
          key: 'contact.age',
          entity: 'Age',
          type: 'rangeNumber',
          group: 'About',
          ordinal: 6,
        },
        'contact.gender': {
          key: 'contact.gender',
          entity: 'Gender',
          type: 'radio',
          group: 'About',
          ordinal: 7,
          list: [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }],
        },
        'contactPlatform.username': {
          key: 'contactPlatform.username',
          entity: 'Username',
          type: 'input',
          group: 'About',
          ordinal: 0,
        },
        'contactPlatform.followerCount': {
          key: 'contactPlatform.followerCount',
          entity: 'Followers',
          type: 'rangeNumber',
          group: 'Statistics',
          ordinal: 20,
        },
        'contactPlatform.engagement': {
          key: 'contactPlatform.engagement',
          entity: 'Engagement',
          type: 'rangeNumber',
          group: 'Statistics',
          ordinal: 21,
        },
        'contactPlatform.growth': {
          key: 'contactPlatform.growth',
          entity: 'Growth',
          type: 'rangeNumber',
          group: 'Statistics',
          ordinal: 22,
        },
        'activity.status': {
          key: 'activity.status',
          entity: 'Status',
          type: 'checkbox',
          group: 'About',
          ordinal: 23,
          // operator: 'is',
          // allowedOperators: ['is'],
          list: this.activityStatusListFiltered,
        },

        'channelGroup.name': {
          key: 'channelGroup.name',
          entity: 'Channel group name',
          type: 'input',
          group: 'About',
          ordinal: 22,
        },

        'crewUser.state': {
          key: 'crewUser.state',
          entity: 'Crew user state',
          type: 'radio',
          group: 'Crew',
          ordinal: 22,
          list: [...this._crewUserStateEnumList, { label: 'None', value: null }],

        },
        'crewUser.createdOn': {
          key: 'crewUser.createdOn',
          entity: 'Crew user createdOn',
          type: 'rangeDate',
          group: 'Crew',
          ordinal: 22,
        },

        hasThreadGroup: {
          key: 'hasThreadGroup',
          entity: 'Tags',
          type: 'checkbox',
          group: 'Attributes',
          ordinal: 23,
          operator: 'is one of',
          allowedOperators: ['is one of', 'is not', 'is empty'],
          list: this.tagList,
        },
        labels: {
          key: 'labels',
          entity: 'Contact labels',
          type: 'checkbox',
          group: 'Attributes',
          ordinal: 24,
          operator: 'is one of',
          allowedOperators: ['is one of', 'is not', 'is empty'],
          list: this.labelList,
        },
        hasContactGroup: {
          key: 'hasContactGroup',
          entity: 'Contact labels',
          type: 'checkbox',
          group: 'Attributes',
          ordinal: 24,
          operator: 'is one of',
          allowedOperators: ['is one of', 'is not', 'is empty'],
          list: this.labelList,
        },
        tribes: {
          key: 'tribes',
          entity: 'Tribes',
          type: 'checkbox',
          group: 'Attributes',
          ordinal: 25,
          operator: 'is one of',
          allowedOperators: ['is one of', 'is not', 'is empty'],
          list: this.tribeList,
        },
        'tribeMemberList.tribeTid': {
          key: 'tribeMemberList.tribeTid',
          entity: 'Tribes',
          type: 'checkbox',
          group: 'Attributes',
          ordinal: 25,
          operator: 'is one of',
          allowedOperators: ['is one of', 'is not', 'is empty'],
          list: this.tribeList,
        },
        'activity.rootActiveCampaign': {
          key: 'activity.rootActiveCampaign',
          entity: 'Active campaign',
          type: 'checkbox',
          group: 'Campaigns',
          ordinal: 30,
          operator: 'is one of',
          allowedOperators: ['is one of', 'is not', 'is empty'],
          list: this.activeCampaignList,
        },
        // 'activity.cpsActiveCampaign': {
        //   key: 'activity.cpsActiveCampaign',
        //   entity: 'Active campaign',
        //   type: 'checkbox',
        //   group: 'Campaigns',
        //   ordinal: 30,
        //   operator: 'is one of',
        //   allowedOperators: ['is one of', 'is not', 'is empty'],
        //   list: this.activeCampaignList,
        // },
        'lastActivity.campaignCid': {
          key: 'lastActivity.campaignCid',
          entity: 'Active campaign',
          type: 'checkbox',
          group: 'Campaigns',
          ordinal: 30,
          operator: 'is one of',
          allowedOperators: ['is one of', 'is not', 'is empty'],
          list: this.activeCampaignList,
        },
        'contactPlatformStatistic.totalMentions': {
          key: 'contactPlatformStatistic.totalMentions',
          entity: 'Total mention count',
          type: 'rangeNumber',
          group: 'Mentions',
          ordinal: 40,
        },
        'contactPlatformStatistic.totalPosts': {
          key: 'contactPlatformStatistic.totalPosts',
          entity: 'Post mention count',
          type: 'rangeNumber',
          group: 'Mentions',
          ordinal: 41,
        },
        'contactPlatformStatistic.totalStories': {
          key: 'contactPlatformStatistic.totalStories',
          entity: 'Story mention count',
          type: 'rangeNumber',
          group: 'Mentions',
          ordinal: 42,
        },
        'contactPlatformStatistic.totalRevenuesUsd': {
          key: 'contactPlatformStatistic.totalRevenuesUsd',
          entity: 'Total revenue generated',
          type: 'rangeNumber',
          group: 'Sales',
          ordinal: 50,
        },
        'contactPlatformStatistic.totalOrders': {
          key: 'contactPlatformStatistic.totalOrders',
          entity: 'Total orders generated',
          type: 'rangeNumber',
          group: 'Sales',
          ordinal: 51,
        },
        'contactPlatformStatistic.totalCoupons': {
          key: 'contactPlatformStatistic.totalCoupons',
          entity: 'Affiliation count',
          type: 'rangeNumber',
          group: 'Sales',
          ordinal: 52,
        },
        'contactPlatformStatistic.lastMentionOn': {
          key: 'contactPlatformStatistic.lastMentionOn',
          entity: 'Last mention date',
          type: 'rangeDate',
          group: 'Last interactions',
          ordinal: 60,
        },
        'contactPlatformStatistic.lastCampaignOn': {
          key: 'contactPlatformStatistic.lastCampaignOn',
          entity: 'Last campaign add date',
          type: 'rangeDate',
          group: 'Last interactions',
          ordinal: 61,
        },
        'contactPlatformStatistic.lastSaleOn': {
          key: 'contactPlatformStatistic.lastSaleOn',
          entity: 'Last sale date',
          type: 'rangeDate',
          group: 'Last interactions',
          ordinal: 62,
        },
        memberGroupMgid: {
          key: 'memberGroupMgid',
          entity: 'Group',
          type: 'checkbox',
          group: 'About',
          ordinal: 0,
          operator: 'is one of',
          allowedOperators: ['is one of', 'is not', 'is empty'],
          list: this.memberGroupList,
        },
        'mention.mentionType': {
          key: 'mention.mentionType',
          entity: 'Mention type',
          type: 'radio',
          group: 'Mentions',
          ordinal: 2,
          removable: false,
          editable: false,
          list: [{ label: 'Post', value: 'POST' }, { label: 'Story', value: 'STORY' }],
        },
        'mainMedia.type': {
          key: 'mainMedia.type',
          entity: 'Media type',
          type: 'radio',
          group: 'Mentions',
          ordinal: 4,
          list: [{ label: 'Video', value: 'VIDEO' }, { label: 'Image', value: 'IMAGE' }],
        },
        'mention.createdOn': {
          key: 'mention.createdOn',
          entity: 'Mention creation date',
          type: 'rangeDate',
          group: 'Last interactions',
          ordinal: 1,
        },
        'publication.createdOn': {
          key: 'publication.createdOn',
          entity: 'Mention creation date',
          type: 'rangeDate',
          group: 'Last interactions',
          ordinal: 1,
        },
        'contactPlatformStatistic.lastMessageOn': {
          key: 'contactPlatformStatistic.lastMessageOn',
          entity: 'Last Message',
          type: 'rangeDate',
          group: 'Last interactions',
          ordinal: 7,
        },
        'contactPlatformStatistic.totalPrints': {
          key: 'contactPlatformStatistic.totalPrints',
          entity: 'Total Prints',
          type: 'rangeNumber',
          group: 'Mentions',
          ordinal: 3,
        },
        'publication.prints': {
          key: 'publication.prints',
          entity: 'Prints',
          type: 'rangeNumber',
          group: 'Mentions',
          ordinal: 3,
        },
        'publication.likes': {
          key: 'publication.likes',
          entity: 'Likes',
          type: 'rangeNumber',
          group: 'Mentions',
          ordinal: 2,
        },
        'publication.comments': {
          key: 'publication.comments',
          entity: 'Comments',
          type: 'rangeNumber',
          group: 'Mentions',
          ordinal: 3,
        },
        'publication.engagement': {
          key: 'publication.engagement',
          entity: 'Post Engagement',
          type: 'rangeNumber',
          group: 'Mentions',
          ordinal: 4,
        },
        'publication.favorite': {
          key: 'publication.favorite',
          entity: 'Favorite',
          type: 'radio',
          list: [{ label: 'True', value: true }],
          group: 'Mentions',
          ordinal: 4,
        },
        'campaignMention.isFav': {
          key: 'campaignMention.isFav',
          entity: 'Favorite',
          type: 'radio',
          list: [{ label: 'True', value: true }],
          group: 'Mentions',
          ordinal: 4,
        },
        // shopify
        'affiliationCode.type': {
          key: 'affiliationCode.type',
          entity: 'Type',
          type: 'checkbox',
          group: 'Sales',
          operator: 'is one of',
          allowedOperators: ['is one of', 'is not'],
          list: this.affiliationCodeTypeList,
          ordinal: 4,
        },
        'affiliationCode.affiliationCodeFolderAcfid': {
          key: 'affiliationCode.affiliationCodeFolderAcfid',
          entity: 'Folder',
          type: 'checkbox',
          group: 'Sales',
          operator: 'is one of',
          allowedOperators: ['is one of', 'is not'],
          list: this.affiliationCodeFolderListBySelectedShop,
          ordinal: 4,
        },
        'affiliationCode.campaignCid': {
          key: 'affiliationCode.campaignCid',
          entity: 'Campaign',
          type: 'checkbox',
          group: 'Sales',
          operator: 'is one of',
          allowedOperators: ['is one of', 'is not', 'is empty'],
          list: this.allCampaignList,
          ordinal: 4,
        },
        totalOrders: {
          key: 'totalOrders',
          entity: 'Total orders',
          type: 'rangeNumber',
          group: 'Sales',
          ordinal: 4,
        },
        totalRevenue: {
          key: 'totalRevenue',
          entity: 'Total revenues',
          type: 'rangeNumber',
          group: 'Sales',
          ordinal: 4,
        },
        'shortLink.clickCount': {
          key: 'shortLink.clickCount',
          entity: 'Click count',
          type: 'rangeNumber',
          group: 'Sales',
          ordinal: 5,
        },
        'order.createdOn': {
          key: 'order.createdOn',
          entity: 'Order paid on',
          type: 'rangeDate',
          group: 'Sales',
          ordinal: 60,
        },
        'affiliationCode.createdOn': {
          key: 'affiliationCode.createdOn',
          entity: 'Coupon created on',
          type: 'rangeDate',
          group: 'Sales',
          ordinal: 61,
        },
        'affiliationCode.linkedOnly': {
          key: 'affiliationCode.linkedOnly',
          entity: 'Only affiliated',
          type: 'radio',
          list: [{ label: 'True', value: true }],
          group: 'Sales',
          ordinal: 61,
        },
        'campaignActivity.totalMentions': {
          key: 'campaignActivity.totalMentions',
          entity: 'Total mention count',
          type: 'rangeNumber',
          group: 'Mentions',
          ordinal: 40,
        },
        'campaignActivity.totalRevenues': {
          key: 'campaignActivity.totalRevenues',
          entity: 'Total revenue generated',
          type: 'rangeNumber',
          group: 'Sales',
          ordinal: 50,
        },
        'campaignActivity.totalOrders': {
          key: 'campaignActivity.totalOrders',
          entity: 'Total orders generated',
          type: 'rangeNumber',
          group: 'Sales',
          ordinal: 51,
        },
        'campaignActivity.totalCoupons': {
          key: 'campaignActivity.totalCoupons',
          entity: 'Affiliation count',
          type: 'rangeNumber',
          group: 'Sales',
          ordinal: 52,
        },
        'activity.createdOn': {
          key: 'activity.createdOn',
          entity: 'Added on',
          type: 'rangeDate',
          group: 'About',
          ordinal: 60,
        },

        'affiliationReward.totalRevenue': {
          key: 'affiliationReward.totalRevenue',
          entity: 'Total revenue generated',
          type: 'rangeNumber',
          group: 'Sales',
          ordinal: 50,
        },
        'affiliationReward.totalCommissionFee': {
          key: 'affiliationReward.totalCommissionFee',
          entity: 'Total commission',
          type: 'rangeNumber',
          group: 'Sales',
          ordinal: 50,
        },
        'affiliationReward.createdOn': {
          key: 'affiliationReward.createdOn',
          entity: 'Generated on',
          type: 'rangeDate',
          group: 'Sales',
          ordinal: 50,
          operator: 'is between',
          allowedOperators: ['is between', 'is empty'],
        },
        'affiliationReward.reviewedOn': {
          key: 'affiliationReward.reviewedOn',
          entity: 'Reviewed on',
          type: 'rangeDate',
          group: 'Sales',
          ordinal: 50,
          operator: 'is between',
          allowedOperators: ['is between', 'is empty'],
        },
        'affiliationReward.rewardAskedOn': {
          key: 'affiliationReward.rewardAskedOn',
          entity: 'Reward asked on',
          type: 'rangeDate',
          group: 'Sales',
          ordinal: 50,
          operator: 'is between',
          allowedOperators: ['is between', 'is empty'],
        },
        'affiliationReward.paidOn': {
          key: 'affiliationReward.paidOn',
          entity: 'Paid on',
          type: 'rangeDate',
          group: 'Sales',
          ordinal: 50,
          operator: 'is between',
          allowedOperators: ['is between', 'is empty'],
        },
        'affiliationReward.paidType': {
          key: 'affiliationReward.paidType',
          entity: 'Reward type',
          type: 'radio',
          list: [{ label: 'Auto', value: 'COMMON' }, { label: 'Manual', value: 'MANUAL' }],
          group: 'Sales',
          ordinal: 61,
        },
      };
    },
  },
  methods: {
    formatLabel(filter) {
      const template = [];

      const catalog = this.genericCatalog[filter.key];

      if (!catalog) return '';

      // template.push(catalog.entity);

      if (catalog.type === 'checkbox') {
        if (filter.operator !== 'is empty') {
          if (filter.values.length > 1) template.push('are');
          else template.push('is');

          const entries = [];
          filter.values.forEach((val) => {
            entries.push(catalog.list.find(({ value }) => value === val).label);
          });

          template.push(entries.join(', '));
        } else {
          template.push('is empty');
        }
      }

      if (catalog.type === 'input') {
        template.push('is', String(filter.values[0]).toLowerCase());
      }

      if (catalog.type === 'radio') {
        template.push('is', String(filter.values[0]).toLowerCase());
      }

      if (catalog.type === 'rangeNumber') {
        const min = filter.values[0] ? nFormatter(filter.values[0]) : null;
        const max = filter.values[1] ? nFormatter(filter.values[1]) : null;

        if (min && max) template.push(`is between ${min} and ${max}`);
        if (min && !max) template.push(`is greater than ${min}`);
        if (!min && max) template.push(`is smaller than ${max}`);
      }

      if (catalog.type === 'rangeDate') {
        if (filter.operator === 'is empty') {
          template.push('is empty');
        } else {
          const min = filter.values[0] ? this.$moment(filter.values[0]).format('YYYY/MM/DD') : null;
          const max = filter.values[1] ? this.$moment(filter.values[1]).format('YYYY/MM/DD') : null;
          if (min && max) template.push(`is between ${min} and ${max}`);
          if (min && !max) template.push(`is after ${min}`);
          if (!min && max) template.push(`is before ${max}`);
        }
      }

      return template.join(' ');
    },
  },
};
