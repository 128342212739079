<template>
  <div
    id="TheAdministration"
    class="card"
  >
    <header class="card-header">
      <p class="card-header-title">
        Scraping Zone
      </p>

      <div class="card-header-icon buttons">
        <b-button
          type="is-success"
          label="Scraping Zone"
          :icon-left="createVisible ? 'minus' : 'plus'"
          @click="createVisible = !createVisible"
        />
      </div>
    </header>

    <div class="card-content">
      <form
        v-show="createVisible"
        @submit.prevent="createScrapingZone(newScrapingZone)"
      >
        <fieldset class="fieldset">
          <legend>Scraping Zone create form</legend>
          <UbuScrapingZoneForm v-model="newScrapingZone" />
          <br>
          <div class="buttons is-right">
            <b-button
              type="is-success"
              native-type="submit"
              label="Create"
            />
          </div>
        </fieldset>
      </form>

      <br>

      <UbuScrapingZoneTable
        :scraping-zone-list="Object.values(scrapingZoneList)"
        @deleteScrapingZone="deleteScrapingZone($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheAdministrationScrapingZone',
  data() {
    return {
      createVisible: false,
      newScrapingZone: {
        szid: null,
        label: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      scrapingZoneList: 'TheSuggestions/scrapingZoneList',
    }),
  },
  mounted() {
    return this.reportScrapingZone();
  },
  methods: {
    ...mapActions({
      reportScrapingZone: 'TheAdministration/reportScrapingZone',
      createScrapingZone: 'TheAdministration/createScrapingZone',
      deleteScrapingZone: 'TheAdministration/deleteScrapingZone',
    }),
    isRouteActive(route) {
      return route === this.$route.path;
    },
  },
};
</script>
