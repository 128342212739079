import { dispatchAndForward, genericErrorHandler, genericThenHandler } from '@dailyplanet/data-stores/$utils/helpers';

/* eslint-disable max-len */
export default {
  getters: {
    mediaZipList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiChannel/dailyMention/getterMediaZipList': mediaZipList } = rootGetters;

      return mediaZipList;
    },
  },
  actions: {
    reportMentionZipList({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiChannel/dailyMention/report',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
          },
        },
        textNotifier: 'report mention zip list',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to report zip list.', indefinite: true }));
    },

    downloadMentionZip({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiChannel/dailyMention/downloadZip',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
          },
        },
        textNotifier: 'download mention zip list',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to download zip list.', indefinite: true }));
    },

    setDailyMention({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiChannel/dailyMention/setDailyMention',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
          },
        },
        textNotifier: 'set daily mention asked on',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable subscribe to daily report.', indefinite: true }));
    },

    unsetDailyMention({ dispatch, rootGetters }, { payload, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiChannel/dailyMention/unsetDailyMention',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
          },
        },
        textNotifier: 'unset daily mention asked on',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable unsubscribe to daily report.', indefinite: true }));
    },
  },
};
