import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    tremendousCampaignList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiTremendous/tremendousCampaign/getterTremendousCampaignList': tremendousCampaignList } = rootGetters;

      return tremendousCampaignList;
    },

    defaultTremendousCampaign: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiTremendous/tremendousCampaign/getterDefaultTremendousCampaignTcid': defaultTremendousCampaign } = rootGetters;

      return defaultTremendousCampaign;
    },
  },
  actions: {
    reportTremendousCampaign({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTremendous/tremendousCampaign/report',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'report tremendousCampaign list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report tremendous campaign list.', indefinite: true }));
    },

    setDefaultTremendousCampaign({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTremendous/tremendousCampaign/setDefault',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'update tremendousCampaign list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to update tremendous campaign list.', indefinite: true }));
    },

    refreshTremendousCampaign({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTremendous/tremendousCampaign/refresh',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'refresh tremendousCampaign list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to refresh tremendous campaign list.', indefinite: true }));
    },
  },
};
