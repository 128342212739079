<template>
  <section id="TheWorkspace">
    <aside class="ubuMenu is-sub-menu">
      <div :class="['start', 'menu-item-list', 'can-close', { 'is-closed': test }]">
        <div
          class="multibar row-vcentered"
          @click="test = !test"
        >
          <p class="start menu-item-title disclaimer is-3">DASHBOARD COLUMNS</p>
          <b-icon
            pack="ubu"
            :icon="test ? 'ubu-arrow-up' : 'ubu-arrow-down'"
            class="end disclaimer is-3"
          />
        </div>

        <br>

        <div class="ubuMenuItem">
          <b-field
            v-for="col in columns"
            :key="col.col"
          >
            <b-switch
              v-model="columns[col.col].visible"
              size="is-small"
            >
              {{ col.label }}
            </b-switch>
          </b-field>
          <hr>
        </div>
      </div>

      <div class="start menu-item-list">
        <p class="menu-item-title disclaimer is-3">PROTOLISTS FILTERS</p>

        <br>

        <div class="ubuMenuItem">
          <b-button
            :active="!filters.length"
            expanded
            class="is-navigation is-spaced"
            @click="filters = []"
          >
            <div class="multibar row-vcentered">
              <span class="start itemMenuText text-truncate">No Filters</span>
            </div>
          </b-button>

          <template v-for="({ filter }) in filterList">
            <b-button
              :key="filter"
              :active="filters.includes(filter)"
              expanded
              class="is-navigation is-spaced"
              @click="filterToggle(filter)"
            >
              <div class="multibar row-vcentered">
                <span class="start itemMenuText text-truncate">{{ filter }}</span>
              </div>
            </b-button>
          </template>
        </div>
      </div>
    </aside>

    <div class="has-coreview-spaced kara">
      <div class="ubuSettingView">
        <DevDetails :cnt="{name: 'rejectIsLoading', obj: rejectIsLoading}" />

        <b-table
          :data="filteredProtoLists"
          :paginated="true"
          :per-page="perPage"
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          pagination-size="is-small"
          hoverable
          sticky-checkbox
          sticky-header
          class="table-full-height"
        >
          <b-table-column
            v-for="column in columns"
            :key="column.col"
            :visible="column.visible"
            :label="column.label"
            :field="column.col"
            :sortable="column.isSortable"
            :sticky="column.sticky"
            :cell-class="column.cellClass"
          >
            <template
              v-slot="props"
            >
              <template v-if="column.cmp === 'flat'">
                <b-tooltip
                  v-if="column.tooltip"
                  :label="props.row[column.col]"
                  multilined
                  append-to-body
                  size="is-large"
                  position="is-bottom"
                >
                  <span class="text-truncate">
                    {{ props.row[column.col] || '-' }}
                  </span>
                </b-tooltip>
                <span
                  v-else
                  class="text-truncate"
                >
                  {{ props.row[column.col] || '-' }}
                </span>
              </template>

              <template v-if="column.cmp === 'date'">
                <span v-if="props.row[column.col]">
                  {{ $moment(props.row[column.col]).format('YYYY-MM-DD HH:mm') }}
                </span>
                <span v-else>-</span>
              </template>
            </template>
          </b-table-column>

          <b-table-column
            v-slot="props"
            :visible="true"
            label="Asked by"
            field="action"
          >
            <template v-for="({name, cgid}) in props.row.askingChannelGroup">
              <b-button
                :key="cgid"
                class="is-ghost"
              >
                {{ name }}
              </b-button>
            </template>
          </b-table-column>

          <b-table-column
            v-slot="props"
            :visible="true"
            label="Action"
            field="action"
            centered
          >
            <div class="buttons is-centered">
              <b-button
                v-if="!props.row.allowedOn"
                label="Allow"
                type="is-success"
                size="is-small"
                :disabled="rejectIsLoading.length > 0"
                @click="allow(props.row)"
              />
              <b-button
                v-if="!props.row.allowedOn"
                label="Reject"
                type="is-danger"
                size="is-small"
                :loading="rejectIsLoading.includes(props.row.plid)"
                :disabled="rejectIsLoading.length > 0"
                @click="confirmRejection(props.row)"
              />
            </div>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TableMixin from './_mixins/theMonitoringProtoList.columns.mixins';

export default {
  name: 'TheMonitoringProtoList',
  mixins: [TableMixin],
  data() {
    return {
      loading: false,
      selected: {},
      total: 0,
      page: 0,
      perPage: 50,
      sortField: 'createdOn',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      checkedRows: [],
      search: '',
      inputSearch: '',
      rejectIsLoading: [],
      filters: [],
      filterList: [
        { filter: 'Updateds', cantBeWith: ['Not Updateds'] },
        { filter: 'Not Updateds', cantBeWith: ['Updateds'] },
        { filter: 'Alloweds', cantBeWith: ['Not Alloweds'] },
        { filter: 'Not Alloweds', cantBeWith: ['Alloweds'] },
      ],
      test: false,
    };
  },
  computed: {
    ...mapGetters({
      protoLists: 'TheMonitoring/protoLists',
    }),
    filteredProtoLists() {
      const { filters } = this;

      return [...this.protoLists]
        .filter((row) => {
          if (filters.length) {
            if (filters.includes('Updateds')) return !!row.updatedOn;
            if (filters.includes('Not Updateds')) return !row.updatedOn;
            if (filters.includes('Alloweds')) return !!row.allowedOn;
            if (filters.includes('Not Alloweds')) return !row.allowedOn;
          }
          return row;
        })
        .sort((a, b) => {
          const itemA = a[this.sortField];
          const itemB = b[this.sortField];

          if (typeof itemA === 'number') {
            if (this.sortOrder === 'desc') return itemB - itemA;
            return itemA - itemB;
          }
          if (this.sortOrder === 'desc') return itemA > itemB ? -1 : 1;
          return itemA < itemB ? -1 : 1;
        });
    },
  },
  mounted() {
    return this._reportProtoList({ httpQuery: { askingChannelGroup: true } });
  },
  methods: {
    ...mapActions({
      _reportProtoList: 'TheMonitoring/reportProtoList',
      _allowProtoList: 'TheMonitoring/allowProtoList',
      _rejectProtoList: 'TheMonitoring/rejectProtoList',
    }),
    onSort([sortField, sortOrder]) {
      this.sortField = sortField;
      this.sortOrder = sortOrder;
    },
    filterToggle(filterName) {
      const mappedFilters = this.filterList.arrayMapper('filter');

      if (this.filters.includes(...mappedFilters[filterName].cantBeWith)) {
        this.filters = this.filters
          .filter((f) => !mappedFilters[filterName].cantBeWith.includes(f));
      }
      if (this.filters.includes(filterName)) {
        this.filters = this.filters.filter((f) => f !== filterName);
        return;
      }
      this.filters.push(filterName);
    },
    allow({ plid }) {
      return this._allowProtoList({ payload: { plid }, httpQuery: { isStub: true } })
        .then(() => this.$buefy.snackbar.open({
          message: 'ProtoList order successfully allowed',
          type: 'is-success',
          position: 'is-top',
        }));
    },
    confirmRejection(protolist) {
      this.$buefy.dialog.confirm({
        title: 'Reject protolist order',
        // eslint-disable-next-line max-len
        message: `Are you sure you want to <b>reject</b> the protolist order with username "${protolist.targetUsername}" ?
        This action cannot be undone and will delete the prolist.`,
        confirmText: 'Reject Protolist',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.reject(protolist),
      });
    },
    reject({ plid }) {
      console.log('🚀 ~ file: TheMonitoringProtoList.vue ~ line 288 ~ reject ~ plid', plid);
      this.rejectIsLoading.push(plid);

      return this._rejectProtoList({ payload: { plid } })
        .then(() => {
          this.rejectIsLoading = this.rejectIsLoading
            .filter((_plid) => Number(plid) !== Number(_plid));
          this.$buefy.snackbar.open({
            message: 'ProtoList order successfully deleted',
            type: 'is-success',
            position: 'is-top',
          });
        });
    },
  },
};
</script>
