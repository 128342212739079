class ChainAccumulator {
  constructor() {
    this.acc = [];
    this.p = Promise.resolve();
    this.then = (fn) => { this.p = this.p.then(fn).then(this.pushAndForward); return this; };
    this.pushAndForward = (r) => { this.acc.push(r); return r; };
    this.end = () => this.p.then(() => this.acc);
  }
}

const pChain = (...ar) => ar.flat()
  .reduce((acc, fn) => acc.then(fn), new ChainAccumulator())
  .end();

export default pChain;
