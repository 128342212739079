<template>
  <b-table
    ref="table"
    :data="detailledUsers"
    paginated
    :per-page="pagin"
    :opened-detailed="defaultOpenedDetails"
    detailed
    detail-key="uid"
    :detail-transition="transitionName"
    :show-detail-icon="true"
    checkable
    :loading="loading"
    :checked-rows.sync="localCheckedRows"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
  >
    <b-table-column
      v-slot="props"
      searchable
      field="uid"
      label="ID"
      :visible="columns.uid.visible"
      width="40"
    >
      {{ props.row.uid }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      searchable
      field="firstName"
      label="First name"
      :visible="columns.firstName.visible"
    >
      {{ props.row.firstName }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      searchable
      field="lastName"
      label="Last name"
      :visible="columns.lastName.visible"
    >
      {{ props.row.lastName }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      searchable
      field="auths"
      label="Email"
      :visible="columns.auths.visible"
    >
      <template
        v-for="(auth, i) in props.row.auths"
      >
        <span :key="i">{{ auth }}</span>
      </template>
    </b-table-column>
    <b-table-column
      v-slot="props"
      searchable
      field="userRoleUrid"
      label="Role"
      :visible="columns.userRoleUrid.visible"
    >
      {{ props.row.userRoleUrid }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      searchable
      field="isUbu"
      label="Ubu"
      :visible="columns.isUbu.visible"
      centered
      width="40"
    >
      <b-icon
        icon="circle"
        :type="props.row.isUbu ? 'is-success' : 'is-danger'"
      />
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Actions"
      :visible="columns.actions.visible"
      numeric
    >
      <b-button
        type="is-danger"
        icon-right="delete"
        @click="confirmCustomDelete(props.row.uid)"
      />
    </b-table-column>
    <template #detail="props">
      <UbuKaraDetailledUserTableDetail
        v-model="props.row"
        @removeRight="$emit('removeRight', $event)"
        @changeAuthenticate="$emit('changeAuthenticate', $event)"
      />
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'UbuKaraDetailledUserTable',
  model: {
    prop: 'detailledUsers',
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Object,
      default: () => {},
    },
    detailledUsers: {
      type: Array,
      required: true,
    },
    checkedRows: {
      type: Array,
      required: true,
    },
    pagin: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      defaultOpenedDetails: [],
    };
  },
  computed: {
    localCheckedRows: {
      get() { return this.checkedRows; },
      set(newValue) { return this.$emit('update:checkedRows', newValue); },
    },
    transitionName() {
      if (this.useTransition) {
        return 'fade';
      }
      return '';
    },
  },
  methods: {
    confirmCustomDelete(uid) {
      this.$buefy.dialog.confirm({
        title: 'Deleting account',
        message: 'Are you sure you want to <b>delete</b> this account? This action cannot be undone.',
        confirmText: 'Delete Account',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$emit('deleteAccount', uid);
          this.$buefy.toast.open('Account deleted!');
        },
      });
    },
  },
};
</script>
