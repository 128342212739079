<template>
  <section id="TheWorkspace">
    <div class="kara">
      <DevDetails :cnt="{ name: 'selected', obj: selected }" />
      <router-view
        name="allUsers"
        :selected.sync="selected"
        @reset="selected = {}"
      />
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */

export default {
  name: 'TheCrews',
  data() {
    return {
      selected: {},
      routes: [
        {
          title: 'GLOBAL WORKFLOW',
          ordinal: 0,
          items: [
            {
              text: 'All Crew Users',
              path: '/crews/all-users',
            },
          ],
        },
      ],
    };
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {

  },
};
</script>
