<template>
  <section class="ubuContactPlatformPanelTab isFixed">
    <article class="ubuContactPlatformPanelItem">
      <header class="multibar row-vcentered">
        <h3 class="start">Mentions</h3>
      </header>

      <div class="tile is-ancestor is-vertical">
        <div class="tile is-parent is-horizontal">
          <template v-for="(_, index) in tiles">
            <div
              :key="index"
              class="tile is-child"
            >
              <p class="subtext is-2 has-text-secondary">{{ tiles[index].label }}</p>
              <p class="text is-2">
                {{ tiles[index].value ? tiles[index].symbolBefore : '' }}
                {{ tiles[index].value | nFormatter }}
                {{ tiles[index].value ? tiles[index].symbolAfter : '' }}
              </p>
            </div>
            <!-- <div
              v-if="tiles[index + 1]"
              class="tile is-child"
            >
              <p class="subtext is-2 has-text-secondary">{{ tiles[index + 1].label }}</p>
              <p class="text is-2">
                {{ tiles[index + 1].value ? tiles[index + 1].symbolBefore : '' }}
                {{ tiles[index + 1].value | nFormatter }}
                {{ tiles[index + 1].value ? tiles[index + 1].symbolAfter : '' }}
              </p>
            </div> -->
          </template>
        </div>
      </div>
    </article>

    <article class="ubuContactPlatformPanelItem">
      <div class="dropdowns">
        <b-button
          :active="mentionType === 'POST'"
          class="is-navigation mr-1"
          @click="mentionType = 'POST'"
        >
          <component
            :is="`UbuIcon${contactPlatformOverview.contactPlatform.platformName.capitalize()}`"
          />
          <span class="text is-3">
            Posts
          </span>
        </b-button>
        <b-button
          :active="mentionType === 'STORY'"
          class="is-navigation ml-1"
          @click="mentionType = 'STORY'"
        >
          <component
            :is="`UbuIcon${contactPlatformOverview.contactPlatform.platformName.capitalize()}`"
          />
          <span class="text is-3">
            Stories
          </span>
        </b-button>
      </div>
    </article>
    <article
      v-if="mentionList.length"
      class="ubuContactPlatformPanelItem forceMinPadding"
    >
      <RecycleScroller
        ref="scroller"
        class="contactPanelScroller"
        :item-size="itemHeight + 8"
        :items="mentionsListSplited"
        key-field="id"
        :emit-update="true"
        @update="onScroll"
      >
        <template
          v-slot="{ item }"
        >
          <div :class="['flex', {'is-story': mentionType === 'STORY'} ]">
            <template v-for="mention in item.group">
              <UbuNewMentionCard
                :key="mention.pid"
                :mention="mention"
                :is-story="mentionType === 'STORY'"
                style="margin: 4px;"
                :content-style="`height: ${itemHeight}px; width: ${itemWidth}px;`"
                @click="openMention(mention)"
                @check="checkCard(mention)"
                @toggleFavorite="toggleFavorite(mention)"
              />
            </template>
          </div>
        </template>
      </RecycleScroller>
    </article>

    <TheMentionModal
      v-if="isModalOpen && currentMention"
      v-model="isModalOpen"
      :mention-list="mentionList"
      :initital-mention="currentMention"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheContactPlatformPanelMentionTab',
  props: {
    isLarge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      isModalOpen: false,
      currentMention: null,
      mentionType: 'STORY',
      detailKey: 'mid',
      page: 0,
      perPage: 10,
      total: 0,
      sortField: 'createdOn',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      itemsByChunks: 2,
      itemHeight: 225 + 8,
      itemWidth: 180 + 8,
      checkedRows: {},
    };
  },
  computed: {
    ...mapGetters({
      contactPlatformOverview: 'TheContactPlatformPanel/contactPlatformOverviewUnhelded',
      // contactPlatformOverviewMentionList: 'TheContactPlatformPanel/contactPlatformOverviewMentionList',
    }),
    mentionList() {
      if (this.loading) return [];
      if (!this.contactPlatformOverview.mentionList) return [];

      return Object.values(this.contactPlatformOverview.mentionList).map(({
        mention, publication, favorite, ...rest
      }) => ({
        mention, contactPlatform: this.contactPlatformOverview.contactPlatform, contactPlatformStatistic: this.contactPlatformOverview.contactPlatformStatistic, publication, favorite, ...rest,
      }))
        .sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
    },

    mentionsListSplited() {
      const splitArrayInChunks = (array, chunkLength) => Array(Math.ceil(array.length / chunkLength))
        .fill()
        .map((_, i) => array.slice(i * chunkLength, i * chunkLength + chunkLength));

      return splitArrayInChunks(this.mentionList, this.itemsByChunks).map((group, index) => ({ id: index, group }));
    },

    checkedMentions() {
      return Object.values(this.checkedRows).map(({ pid }) => pid);
    },

    tiles() {
      return [
        {
          label: 'Posts 🏞',
          value: this.contactPlatformOverview.contactPlatformStatistic.totalPosts,
        },
        {
          label: 'Stories 🕰',
          value: this.contactPlatformOverview.contactPlatformStatistic.totalStories,
        },
        // {
        //   label: 'Prints 👀',
        //   value: this.contactPlatformOverview.contactPlatformStatistic.totalPrints,
        // },
        // {
        //   label: 'Engagement 🎯',
        //   value: this.contactPlatformOverview.contactPlatform.engagement,
        //   symbolAfter: '%',
        // },
        // {
        //   label: 'Likes 💘',
        //   value: this.contactPlatformOverview.contactPlatform.likeCount,
        // },
        // {
        //   label: 'Comments 💬',
        //   value: this.contactPlatformOverview.contactPlatform.commentCount,
        // },
      ];
    },
  },
  watch: {
    // filters(newValue) {
    //   // console.log('🚀 ~ file: TheTribePeopleColumn.vue ~ line 92 ~ filters ~ newValue', newValue);
    //   if (newValue) {
    //     // console.log('🚀 ~ file: TheTribePeopleColumn.vue ~ line 94 ~ filters ~ newValue', newValue);
    //     return this.resetTribeMentionList()
    //       .then(() => this.reportTribeMentionList());
    //   }
    //   return undefined;
    // },
    page(newVal, oldVal) {
      if (newVal > oldVal) {
        return this.reportContactPlatformMentionList();
      }
      return undefined;
    },

    mentionType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loading = true;
        this.page = 0;
        return Promise.resolve()
          .then(() => this._resetContactPlatformMentionList({ payload: { cpids: [this.contactPlatformOverview.contactPlatform.cpid] } }))
          .then(() => this.reportContactPlatformMentionList())
          .then(() => {
            this.compute();
            this.loading = false;
          });
      }
      return undefined;
    },
  },
  mounted() {
    // this.localChecked = {};
    this.loading = true;
    return this.reportContactPlatformMentionList()
      .then(() => {
        this.compute();
        this.loading = false;
      });
    //   .then(() => {
    //   });
  },
  methods: {
    ...mapActions({
      _reportContactPlatformMentionList: 'TheContactPlatformPanel/reportContactPlatformMentionList',
      _resetContactPlatformMentionList: 'TheContactPlatformPanel/resetContactPlatformMentionList',
      _favMentions: 'TheContactPlatformPanel/favMentions',
      _unfavMention: 'TheContactPlatformPanel/unfavMention',
    }),

    toggleFavorite({ favorite, pid }) {
      if (favorite.publicationPid) {
        return this._unfavMention({
          payload: { pid },
          mutations: [{
            target: 'ApiContact/contactPlatformOverview/UP_favMentionList',
            payload: {
              pids: [pid],
              shouldFav: false,
              cpids: [this.contactPlatformOverview.contactPlatform.cpid],
            },
          }],
        });
      }
      return this._favMentions({
        payload: { pids: [pid] },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_favMentionList',
          payload: {
            pids: [pid],
            shouldFav: true,
            cpids: [this.contactPlatformOverview.contactPlatform.cpid],
          },
        }],
      });
    },

    onScroll(startIndex, endIndex) {
      // console.log('🚀 ~ file: TheTribeMentionGallery.vue ~ line 139 ~ onScroll ~ endIndex', endIndex);
      const currentPage = Math.floor((endIndex * this.itemsByChunks) / this.perPage);
      // console.log('🚀 ~ file: TheTribeMentionGallery.vue ~ line 141 ~ onScroll ~ currentPage', currentPage);

      // Changing page
      if (currentPage > this.page) {
        this.page = currentPage;
      }
    },

    compute() {
      const maxUsableWidth = (this.isLarge ? 620 : 400) - 24;
      const minWidthItem = (this.isLarge ? 195 : 145) + 8;
      const displayableItems = Math.floor(maxUsableWidth / minWidthItem);
      const ratioWithoutLoss = 1 + (((maxUsableWidth / minWidthItem) - displayableItems) / displayableItems);
      const itemWidth = minWidthItem * ratioWithoutLoss;

      this.itemWidth = itemWidth - 8;
      this.itemHeight = itemWidth * (5 / 4);
      this.itemsByChunks = displayableItems;
      // this.$forceUpdate();
    },

    openMention(mention) {
      this.isModalOpen = true;
      this.currentMention = mention;
    },

    reportContactPlatformMentionList() {
      return this._reportContactPlatformMentionList({
        payload: { cpids: [this.contactPlatformOverview.contactPlatform.cpid] },
        httpQuery: {
          pagin: `${this.page},${this.perPage}`,
          sorting: `${this.sortField},${this.sortOrder}`,
          filters: JSON.stringify([{ key: 'mention.mentionType', values: [this.mentionType] }]),
        },
      });
    },
  },
};
</script>
