import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    activityOverviewList: {},
  },
  mutations: {
    SET_ACTIVITY_OVERVIEW_LIST(state, { activityOverviewList }) {
      state.activityOverviewList = { ...state.activityOverviewList, ...activityOverviewList };
    },
    RESET_STORE(state) {
      state.activityOverviewList = {};
    },
    RESET_ACTIVITY_OVERVIEW_LIST(state) { state.activityOverviewList = {}; },
  },
  getters: {
    getterActivityOverviewList: (state) => state.activityOverviewList,
  },
  actions: {
    UP_resetActivityOverviewList({ commit }) {
      commit('RESET_ACTIVITY_OVERVIEW_LIST');
    },

    UP_activityOverviewList({ commit }, newData) {
      commit('SET_ACTIVITY_OVERVIEW_LIST', newData);
    },

    report({ commit, rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'REPORT_DETAILLED_CAMPAIGN.REPORT')) return undefined;

      return clark.api.apiInfluence.campaign.overview.report({ payload, httpQuery })
        .then((activityOverview) => {
          const { 'ApiContact/contactPlatformOverview/getterContactPlatformOverviewList': contactPlatformOverviewList } = rootGetters;
          const { results } = activityOverview;

          commit('SET_ACTIVITY_OVERVIEW_LIST', { activityOverviewList: results.reduce((acc, row) => ({ ...acc, [row.aid]: row.cpid }), {}) });
          commit('ApiContact/contactPlatformOverview/SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
            ...contactPlatformOverviewList,
            ...results.reduce((acc, row) => {
              acc[row.cpid] = {
                ...contactPlatformOverviewList[row.cpid],
                ...row,
              };
              return acc;
            }, {}),
          }, { root: true });

          return activityOverview;
        });
    },
  },
};
