<template>
  <div class="ubuContactCard">
    <div class="profile">
      <div class="avatar">
        <UbuThreadListUserPicture
          :profile-picture="contactPlatform.profilePicture"
          :initial-name="contactPlatform.username"
        />
      </div>
      <div class="info">
        <div
          v-if="contactPlatform.name"
          class="multibar row-vcentered text"
        >
          <span class="start">
            @{{ contactPlatform.name }}
          </span>
        </div>
        <div
          :class="['multibar row-vcentered', {
            subtext: !!contactPlatform.name
          }]"
        >
          <component
            :is="`UbuIcon${contactPlatform.platformName.capitalize()}`"
            class="start"
          />
          <span class="start ml-1">
            @{{ contactPlatform.username }}
          </span>
        </div>
      </div>
    </div>

    <slot name="actions" />
  </div>
</template>

<script>
export default {
  name: 'UbuContactPlatformCard',
  model: {
    prop: 'contactPlatform',
  },
  props: {
    contactPlatform: {
      type: Object,
      required: true,
    },
  },
};
</script>
