// /* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import Errors from '@dailyplanet/utils/Errors';
import {
  dispatchAndForward, dispatchFailure, snackBarFailure, snackBarSuccess, genericErrorHandler,
} from '../../$utils/helpers';

export default {
  getters: {
    shopList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/shop/getterShopList': shopList } = rootGetters;

      return shopList;
    },

    currentShop: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/shop/getterCurrentShop': currentShop } = rootGetters;

      return currentShop;
    },
  },
  actions: {
    UP_currentShop({ dispatch }, shop) {
      dispatch('ApiAffiliation/shop/UP_currentShop', shop, { root: true });
    },

    reportShopList({ dispatch, rootGetters }, { httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/shop/report',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Report shop list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report shop list.', indefinite: true }));
    },

    connectShop({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/shop/create',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'connect a shop',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'connectShopify' }, { root: true }))
        .catch(() => {
          Snackbar.open({
            message: 'Unable to connect shop.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    reconnectShop({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/shop/update',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'reconnect a shop',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'connectShopify' }, { root: true }))
        .catch(() => {
          Snackbar.open({
            message: 'Unable to reconnect shop.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },

    patchShop({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/shop/patch',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'patch a shop',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to patch shop.', indefinite: true }));
    },

    reportShopifyCodeList({ dispatch, rootGetters }, { httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/reportShopifyCodeList/report',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'report shop code list',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ reportShopifyReferralOrder ~ e', e);
          Snackbar.open({
            message: 'Unable to get shop code list.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    reportReferralCode({ dispatch, rootGetters }, { payload: { ssid } }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/referralCode/report',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ssid } },
        textNotifier: 'report shop referral code list',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ reportReferralCode ~ e', e);
          Snackbar.open({
            message: 'Unable to report referral code list.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    getReferralCode({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/referralCode/get',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'get shop referral code',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ reportReferralCode ~ e', e);
          Snackbar.open({
            message: 'Unable to get referral code.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    createReferralCode({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/referralCode/create',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'create shop referral code',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ updateReferralCode ~ e', e);
          // Snackbar.open({
          //   message: 'Unable to create referral code.',
          //   ...snackBarFailure,
          // });

          // return dispatchFailure(action);
          dispatchFailure(action);
          throw new Errors(e);
        });
    },
    updateReferralCode({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/referralCode/update',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'update shop referral code',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ updateReferralCode ~ e', e);
          // Snackbar.open({
          //   message: 'Unable to update referral code.',
          //   ...snackBarFailure,
          // });

          // return dispatchFailure(action);
          dispatchFailure(action);
          throw new Errors(e);
        });
    },
    reportDiscountCode({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/discountCode/report',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'report shop discount code list',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ reportDiscountCode ~ e', e);
          Snackbar.open({
            message: 'Unable to report discount code list.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    getDiscountCode({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/discountCode/get',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'get shop discount code',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ reportDiscountCode ~ e', e);
          Snackbar.open({
            message: 'Unable to get discount code.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    createDiscountCode({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/discountCode/create',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'create shop discount code',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then((discountCode) => discountCode)
        .catch((e) => {
          dispatchFailure(action);
          throw new Errors(e);
        });
    },
    updateDiscountCode({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/discountCode/update',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'update shop discount code',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ reportDiscountCode ~ e', e);
          // Snackbar.open({
          //   message: 'Unable to update discount code.',
          //   ...snackBarFailure,
          // });

          // return dispatchFailure(action);
          dispatchFailure(action);
          throw new Errors(e);
        });
    },
    syncDiscountCode({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/syncDiscountCode/create',
        context: {
          payload: {
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'sync shop discount code list',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => {
          Snackbar.open({
            ...snackBarSuccess,
            message: 'Sync discount code list is loading',
          });
        })
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ reportDiscountCode ~ e', e);
          let message = 'Unable to sync discount code list.';

          if (e.message === 'E_REQUIRED_MISSING_PLATFORM_API_SHOPIFY_CONNECTOR_SENSITIVE_UNREADABLE') {
            message = 'It seems that your shop is not connected';
          }

          Snackbar.open({
            message,
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    reportUtmParameter({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/utmParameter/report',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'report utm parameter',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ reportShop ~ e', e);
          Snackbar.open({
            message: 'Unable to get shop utm list.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    createUtmParameter({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/utmParameter/create',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'create utm parameter',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ createUtmParameter ~ e', e);
          Snackbar.open({
            message: 'Unable to create shop utm.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
  },
};
