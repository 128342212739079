/* eslint-disable no-multi-spaces */
import TheRouter from './TheRouter';

export default [
  {
    path: '/router',
    name: 'Router',
    component: TheRouter,
    meta: {
      inSidebar: true,
      sidebarSlot: 'Kara',
      requireAuth: true,
      mainFolder: 'App',
    },
  },
];
