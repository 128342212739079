<template>
  <div
    :class="['ubuTribePeopleColumn', {
      isCollapsed: isCollapsed
    }]"
  >
    <header
      :class="`subtitle is-3 mb-0
    has-text-color-${memberGroup.color} multibar row-vcentered`"
    >
      <b-icon
        class="start ubuTribePeopleColumnToggleCollapse"
        pack="ubu"
        :icon="isCollapsed ? 'ubu-arrow-down' : 'ubu-arrow-right'"
        size="is-small"
        @click.native="toggleCollapse()"
      />

      <b-icon
        v-if="memberGroup.icon"
        class="start"
        pack="ubu"
        :icon="memberGroup.icon"
      />
      <span class="start ml-2">
        {{ memberGroup.name }}
      </span>
      <span class="end ml-2">
        {{ total }}
      </span>
    </header>
    <article>
      <div
        v-if="loading"
        class="start ubuThreadCardLink"
      >
        <UbuThreadCard
          v-for="index in 3"
          :key="index"
          loading
        />
      </div>
      <RecycleScroller
        v-else
        ref="scroller"
        class="scroller"
        :item-size="64"
        :items="tribeMemberList"
        key-field="tmid"
        :emit-update="true"
        @update="onScroll"
      >
        <template
          v-slot="{ item }"
        >
          <TheTribePeopleColumnRow
            :key="item.tmid"
            class="ubuTribeMemberCard"
            :row="item"
            :is-selected="!!localChecked[item.tmid]"
            @select="selectMember($event)"
            @selectContactPlatform="$emit('selectContactPlatform', $event)"
          />
        </template>
      </RecycleScroller>
    </article>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheTribePeopleColumn',
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Object,
      required: true,
    },
    memberGroupMgid: {
      type: Number,
      default: null,
    },
    filters: {
      type: Array,
      required: true,
    },
    notInMemberGroupMgid: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      page: 0,
      perPage: 30,
      total: 0,
      sortField: 'tmid',
      sortOrder: 'desc',
      loading: false,
      isCollapsed: false,
      columnFilter: { key: 'memberGroupMgid', operator: this.memberGroupMgid ? 'is one of' : 'is one of or null', values: this.memberGroupMgid ? [this.memberGroupMgid] : this.notInMemberGroupMgid },
    };
  },
  computed: {
    ...mapGetters({
      _tribeMemberList: 'TheTribe/tribeMemberList',
      _memberGroupList: 'TheTribe/memberGroupList',
    }),
    memberGroup() {
      if (!this.memberGroupMgid) return { color: '0', name: 'Open', icon: null };
      return this._memberGroupList[this.memberGroupMgid];
    },
    tribeMemberList() {
      return Object.values(this._tribeMemberList)
        .filter(({ tribeMember }) => {
          if (this.memberGroupMgid) return tribeMember.memberGroupMgid === this.memberGroupMgid;
          return this.notInMemberGroupMgid.includes(tribeMember.memberGroupMgid) || !tribeMember.memberGroupMgid;
        })
        .sort((a, b) => {
          const { totalRevenuesUsd, totalMentions } = a.contactPlatformStatistic;
          const { totalRevenuesUsd: _totalRevenuesUsd, totalMentions: _totalMentions } = b.contactPlatformStatistic;
          return Number(_totalRevenuesUsd) - (totalRevenuesUsd) || Number(_totalMentions) - (totalMentions);
        });
    },
    localChecked: {
      get() { return { ...this.checkedRows }; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
  watch: {
    filters(newValue) {
      return newValue ? this.reportTribeMemberList() : undefined;
    },
    page(newVal, oldVal) {
      return newVal > oldVal ? this.reportTribeMemberList() : undefined;
    },
    notInMemberGroupMgid(newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.columnFilter.values = newVal;
        this.reportTribeMemberList();
      }
    },
  },
  mounted() {
    this.loading = true;

    return this.reportTribeMemberList()
      .then(() => { this.loading = false; });
  },
  methods: {
    ...mapActions({
      _reportTribeMemberList: 'TheTribe/reportTribeMemberList',
      _updateTribeMember: 'TheTribe/updateTribeMember',
    }),
    selectMember(member) {
      const checkedRowList = { ...this.localChecked };
      if (!checkedRowList[member.tmid]) {
        checkedRowList[member.tmid] = member;
      } else {
        delete checkedRowList[member.tmid];
      }
      this.localChecked = checkedRowList;
    },
    onScroll(startIndex, endIndex) {
      const currentPage = Math.floor(endIndex / this.perPage);

      // Changing page
      if (currentPage > this.page) {
        this.page = currentPage;
      }
    },
    reportTribeMemberList() {
      const filters = this.filters
        .reduce((acc, { key, values, operator }) => {
          if (key === 'memberGroupMgid') return acc;
          acc.push({ key, values, operator });
          return acc;
        }, [this.columnFilter]);

      return this._reportTribeMemberList({
        payload: { tid: this.$route.params.tribeTid },
        httpQuery: {
          pagin: `${this.page},${this.perPage}`,
          columns: 'true',
          filters: JSON.stringify(filters),
        },
      })
        .then((total) => {
          console.log('🚀 ~ file: TheTribePeopleColumn.vue ~ line 187 ~ .then ~ total', total);
          this.total = total;
          this.$forceUpdate();
        });
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>
