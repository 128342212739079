<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        :to="`/admin/account/${currentAid}/channels`"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <h2 class="title is-1">Add channel SMS</h2>

    <br>
    <DevDetails :cnt="{ name: 'accountCurrentChannelGroup', obj: accountCurrentChannelGroup }" />
    <DevDetails :cnt="{ name: 'allContactPlatforms', obj: allContactPlatforms }" />
    <DevDetails :cnt="{ name: 'newChannel', obj: newChannel }" />

    <form v-if="allContactPlatforms">
      <b-steps
        v-model="activeStep"
        :rounded="false"
        :has-navigation="false"
        size="is-small"
      >
        <b-step-item
          label="Contact"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Step 1/3</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="true"
              :prev="false"
            />
          </div>

          <br>

          <div class="multibar">
            <p class="tart has-text-secondary is-3 subtitle">
              We can relate the new channel you're going
              to create to an account on another platform.
            </p>
          </div>

          <br>

          <UbuAccountAddChannelStepContactForm
            v-if="allContactPlatforms"
            v-model="newChannel"
            :all-contact-platforms="allContactPlatforms"
          />
        </b-step-item>
        <b-step-item
          label="Twilio informations"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Step 2/3</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="stepControl2"
            />
          </div>

          <br>

          <UbuAccountAddChannelStepFormManageChannelSms
            v-model="newChannel"
          />
        </b-step-item>
        <b-step-item
          label="Confirmation"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Step 3/3</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="true"
              :next="false"
            />
          </div>

          <br>

          <UbuAccountAddChannelStepConfirmation
            v-model="newChannel"
            @addChannelSms="createChannel()"
          />
        </b-step-item>
        <b-step-item type="is-success">
          <div class="is-flex is-justify-content-end">
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="true"
              :next="false"
              :prev="false"
            />
          </div>

          <br>

          <section>
            <div class="multibar">
              <h2 class="start title is-3">The channel SMS was created successfully ! 🥳</h2>
            </div>

            <br>

            <p class="has-text-secondary">
              To finish adding the channel, please copy the url below to add it to Twilio
            </p>

            <br>

            <b-field
              label="Webhook URL"
              horizontal
            >
              <b-field>
                <b-input
                  v-model="webhookGenerated"
                  required
                  minlength="8"
                  disabled
                  expanded
                  class="generated-webhook-sms"
                  @input="$update('passPhrase', $event)"
                />
                <p class="control">
                  <b-button
                    v-clipboard:copy="webhookGenerated"
                    v-clipboard:success="onCopy"
                    type="is-link"
                    icon-right="content-copy"
                  />
                </p>
              </b-field>
            </b-field>

            <br>

            <div class="multibar">
              <div class="end buttons">
                <b-button
                  label="Quit"
                  type="is-ghost"
                  tag="router-link"
                  :to="`/admin/account/${currentAid}/channels`"
                  @click="initProcessCreate()"
                />
                <b-button
                  label="Ping the channel"
                  type="is-info"
                  @click="localSelected = newUser"
                />
              </div>
            </div>
          </section>
        </b-step-item>
      </b-steps>
    </form>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheAdminAccountAddChannelSms',
  data() {
    return {
      activeStep: 0,
      webhookGenerated: null,
      newChannel: {
        contactCid: null,
        phoneNumber: null,
        accountSid: null,
        authToken: null,
        name: null,
        codeCountry: '+33',
        validPhoneNumber: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      account: 'TheAdmin/account',
      channelSms: 'TheAdmin/channelSms',
    }),
    currentAid() {
      return this.$route.params.aid;
    },
    currentCgid() {
      return this.$route.query.channelGroupCgid;
    },
    currentCid() {
      return this.$route.params.cid;
    },
    accountCurrentChannelGroup() {
      if (!this.account) return null;
      return this.account.channelGroupList.find(({ cgid }) => Number(cgid) === Number(this.currentCgid));
    },
    allContactPlatforms() {
      if (!this.accountCurrentChannelGroup) return [];

      return this.accountCurrentChannelGroup.channelList.reduce((acc, { contactPlatform }) => {
        if (!contactPlatform) return acc;
        acc.push({
          cpid: contactPlatform.cpid,
          contactCid: contactPlatform.contactCid,
          platform: contactPlatform.platformName,
          username: contactPlatform.username,
        });
        return acc;
      }, []);
    },
    stepControl2() {
      if (!this.newChannel.phoneNumber
        || !this.newChannel.accountSid
        || !this.newChannel.authToken
        || !this.newChannel.name
        || !this.newChannel.validPhoneNumber
      ) return false;
      return true;
    },
  },
  methods: {
    ...mapActions({
      getAccount: 'TheAdmin/getAccount',
      createChannelSms: 'TheAdmin/createChannelSms',
      up_accountChannel: 'TheAdmin/up_accountChannel',
    }),
    createChannel() {
      this.createChannelSms({
        payload: {
          phoneNumber: `${this.newChannel.codeCountry}${this.newChannel.phoneNumber}`,
          accountSid: this.newChannel.accountSid,
          channelGroupCgid: this.currentCgid,
          authToken: this.newChannel.authToken,
          contactCid: this.newChannel.contactCid,
          name: this.newChannel.name,
        },
      })
        .then(({ response: channelSms }) => {
          this.up_accountChannel(channelSms)
            .then(() => {
              const { channelGroupList } = this.account;
              const { channelList } = channelGroupList.find(({ cgid }) => Number(cgid) === Number(this.currentCgid));
              const { cid } = channelList.find(({ platformName }) => platformName === 'SMS');

              this.webhookGenerated = `${process.env.VUE_APP_CLARK_BASE_URL}/api/twilio/${cid}/webhookSms`;
              this.activeStep += 1;
            });
        });
    },
    onCopy() {
      this.$buefy.snackbar.open({
        message: 'Copied to clipboard.',
        type: 'is-success',
        position: 'is-top',
        duration: 5000,
      });
    },
  },
};
</script>
