<template>
  <section>
    <b-tab-item value="root.proto">
      <UbuAddPeopleProtoSelectList
        v-if="activeTab.tab === 'root.proto'"
        v-model="targetUsername"
        :proto-lists="protoLists"
        @select="selectProtoAndNext($event)"
        @request="requestNewList()"
      />
    </b-tab-item>

    <b-tab-item value="root.proto.selectProtoTarget">
      <UbuSearchInstagramUser
        v-if="!selectedProto"
        v-model="targetUsername"
        :is-loading="isLoading"
        :disable-username="protoTargetListAlreadyUsed"
        disable-text="List already exist"
        :only-public="true"
        :users="users"
        class="mt-6"
        @input="reportInstagramUsernames($event)"
        @select="selectProtoTargetAndNext($event)"
      />
    </b-tab-item>

    <b-tab-item value="root.proto.createCrmList">
      <UbuCreateCRMListForm
        v-if="activeTab.tab === 'root.proto.createCrmList'"
        v-model="newCrmList"
        :crm-list-list="crmListList"
        @submit="createCrmListAndRelateProto()"
      />
    </b-tab-item>

    <b-tab-item value="root.proto.selectProtoTarget.createCrmList">
      <UbuCreateCRMListForm
        v-if="activeTab.tab === 'root.proto.selectProtoTarget.createCrmList'"
        v-model="newCrmList"
        :crm-list-list="crmListList"
        @submit="createCrmListAndProto()"
      />
    </b-tab-item>

    <b-tab-item value="root.proto.createCrmList.done">
      <UbuAddPeopleDone
        :label-success="labelSuccess"
        :has-error="hasError"
        :is-loading="isLoading"
        @close="$emit('close'); resetAll()"
      />
    </b-tab-item>
  </section>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable func-names */
import { mapActions, mapGetters } from 'vuex';
import { debounce } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheAddPeopleProto',
  props: {
    activeTab: {
      type: Object,
      required: true,
    },
    tabItems: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      selectedProto: null,
      targetUsername: '',
      users: [],
      hasError: false,

      newCrmList: {
        name: '',
        color: '1',
        scrapParams: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      protoLists: 'TheCRM/protoLists',
      crmListList: 'TheCRM/crmListList',

      instagramUsernameList: 'TheThreadPanel/instagramUsernameList',
    }),

    localActiveTab: {
      get() { return this.activeTab; },
      set(val) { this.$emit('update:activeTab', val); },
    },

    protoTargetListAlreadyUsed() {
      return this.protoLists.map(({ targetUsername }) => targetUsername);
    },

    labelSuccess() {
      if (this.selectedProto) return 'Please note that it can take a few minutes to add profiles to your list.';
      return 'Please note that it can take a up to 24 hours to add profiles to your list.';
    },
  },
  mounted() {
    return Promise.resolve()
      .then(() => { this.isLoading = true; })

      .then(() => this.reportProtoList({ payload: {}, httpQuery: {} }))

      .then(() => { this.isLoading = false; });
  },
  methods: {
    ...mapActions({
      reportProtoList: 'TheCRM/reportProtoList',
      createCrmList: 'TheCRM/createCrmList',
      emitSegmentEvent: 'TheCRM/emitSegmentEvent',

      _reportInstagramUsernames: 'TheThreadPanel/reportInstagramUsernames',
    }),

    UP_changeToNewTab(activeTab) {
      this.$emit('update:activeTab', activeTab);
    },

    selectProtoAndNext(proto) {
      this.selectedProto = proto;
      this.UP_changeToNewTab(this.tabItems['root.proto.createCrmList']);
    },

    requestNewList() {
      this.reportInstagramUsernames(this.targetUsername);
      this.UP_changeToNewTab(this.tabItems['root.proto.selectProtoTarget']);
    },

    reportInstagramUsernames: debounce(function (name) {
      if (!name.length) {
        this.users = [];
        return;
      }

      this.isLoading = true;
      this._reportInstagramUsernames({
        payload: { username: name },
        httpQuery: { onInstagram: 'true' },
      })
        .then(() => { this.users = this.instagramUsernameList; })

        .catch((error) => { this.users = []; throw error; })

        .finally(() => { this.isLoading = false; });
    }, 500),

    selectProtoTargetAndNext(option) {
      this.targetUsername = option.username;
      this.UP_changeToNewTab(this.tabItems['root.proto.selectProtoTarget.createCrmList']);
    },

    createCrmListAndRelateProto() {
      this.isLoading = true;

      const { name, color, scrapParams } = this.newCrmList;
      const { plid } = this.selectedProto;

      const payload = {
        name,
        color: String(color),
        scrapParams,
        protoListList: [{ plid }],
      };

      return this.createCrmList({ payload })
        .then(() => {
          this.isLoading = false;
          this.UP_changeToNewTab(this.tabItems['root.proto.createCrmList.done']);
        })
        .catch(() => {
          this.isLoading = false;
          this.hasError = true;
          this.UP_changeToNewTab(this.tabItems['root.proto.createCrmList.done']);
        });
    },

    createCrmListAndProto() {
      this.isLoading = true;

      const { name, color, scrapParams } = this.newCrmList;

      const payload = {
        name,
        color: String(color),
        scrapParams,
        protoListList: [
          {
            platformName: 'INSTAGRAM',
            targetUsername: this.targetUsername,
            resultUsernameList: null,
            resultUsernameCount: null,
            createdOn: this.$moment().format(),
            updatedOn: null,
            params: null,
          },
        ],
      };

      return this.createCrmList({ payload })
        .then(() => {
          this.isLoading = false;
          this.UP_changeToNewTab(this.tabItems['root.proto.createCrmList.done']);
          this.emitSegmentEvent({ event: 'askProtoList' });
        })
        .catch(() => {
          this.isLoading = false;
          this.hasError = true;
          this.UP_changeToNewTab(this.tabItems['root.proto.createCrmList.done']);
        });
    },

    resetAll() {
      this.selectedProto = null;
      this.targetUsername = '';
      this.users = [];
      this.hasError = false;
      this.newCrmList = {
        name: '',
        color: '1',
        scrapParams: null,
      };
    },
  },
};
</script>
