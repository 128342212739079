/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const affiliationCodeFolder = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/shop/${payload.sid}/affiliationCodeFolder`, method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/shop/${payload.sid}/affiliationCodeFolder`, method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/shop/${payload.sid}/affiliationCodeFolder/${payload.acfid}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/shop/${payload.sid}/affiliationCodeFolder/${payload.acfid}`, method: 'DELETE', payload, httpQuery, target }),
};

export default affiliationCodeFolder;
