<template>
  <div
    :class="[ 'ubuAnalyticsPostCard', {'isPost': !isStory, 'isStory': isStory, }]"
  >
    <div
      v-if="loading || !isImageLoaded"
      class="ubuAnalyticsPostCard_skeleton"
    >
      <b-skeleton
        :rounded="false"
        :active="loading || !isImageLoaded"
        height="320px"
      />
    </div>
    <div
      v-if="media && !loading"
      class="ubuAnalyticsPostCard_card"
      @click="$emit('show')"
    >
      <div
        class="ubuAnalyticsPostCard_gradient"
      >
        <b-image
          :class="[ 'ubuAnalyticsPostCard_image', {'showImage': isImageLoaded }]"
          :src="media.thumbnailUrl"
          :src-fallback="srcFallback"
          :ratio="isStory ? '180by320' : '1by1'"
          responsive
          lazy
          @load="onImgLoad()"
          @error="onImgLoad()"
        />
        <!-- <video
          v-else
          ref="video"
          muted
          @loadeddata="onImgLoad()"
        >
          <source
            :src="media.mediaUrl"
            :type="'video/mp4'"
            @error="isError = true;"
          >
          Sorry, your browser doesn't support embedded videos.
        </video> -->
      </div>
      <div class="ubuAnalyticsPostCard_infos">
        <p class="text is-3">{{ media.date }}</p>
        <div class="ubuAnalyticsPostCard_stats subtitle">
          <div
            v-if="formatedLikeCount"
            class="ubuAnalyticsPostCard_statsBlock"
          >
            <b-icon
              pack="ubu"
              icon="ubu-like"
              size="is-medium"
            />
            <span>{{ formatedLikeCount }}</span>
          </div>
          <div
            v-if="formatedCommentCount"
            class="ubuAnalyticsPostCard_statsBlock"
          >
            <b-icon
              pack="ubu"
              icon="ubu-comment"
              size="is-medium"
            />
            <span>{{ formatedCommentCount }}</span>
          </div>
          <div
            v-if="formatedSavedCount"
            class="ubuAnalyticsPostCard_statsBlock"
          >
            <b-icon
              pack="ubu"
              icon="ubu-bookmark"
              size="is-medium"
            />
            <i class="ubu-icons ubu-icons-bookmark-outlined" />
            <span>{{ formatedSavedCount }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shortenNumber } from '@dailyplanet/utils/formate';

const srcFallback = require('@dailyplanet/assets/images/image-placeholder.png');

export default {
  name: 'UbuAnalyticsPostCard',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isStory: {
      type: Boolean,
      default: false,
    },
    media: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isImageLoaded: false,
      isError: false,
      srcFallback,
    };
  },
  computed: {
    formatedLikeCount() {
      if (!this.media.likeCount || this.media.likeCount === 0) return null;
      return shortenNumber(this.media.likeCount);
    },
    formatedCommentCount() {
      if (!this.media.commentCount || this.media.commentCount === 0) return null;
      return shortenNumber(this.media.commentCount);
    },
    formatedSavedCount() {
      if (!this.media.savedCount || this.media.savedCount === 0) return null;
      return shortenNumber(this.media.savedCount);
    },
  },
  methods: {
    onImgLoad() {
      this.isImageLoaded = true;
    },
  },
};
</script>
