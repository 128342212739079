import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    create({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'USER.SET_ROLE')) return undefined;

      return clark.api.user.setRole.post({ payload, httpQuery: {} })
        .then((userSetRole) => {
          console.log('userSetRole', userSetRole);
          // commit('SET_USER_DETAIL', setRole);
        });
    },
  },
  mutations: {},
};
