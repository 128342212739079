<template>
  <fieldset>
    <template v-for="item in displayInputs">
      <b-field
        v-if="item"
        :key="item.text"
        :label="item.text"
        horizontal
      >
        <b-input
          v-if="item.type === 'text'"
          v-model="manageShop[item.data]"
          required
          :placeholder="`${item.text}...`"
        />
        <b-input
          v-if="item.type === 'password'"
          v-model="manageShop[item.data]"
          type="password"
          password-reveal
          required
          :placeholder="`${item.text}...`"
        />
        <b-switch
          v-if="item.type === 'switch'"
        />
      </b-field>

      <br
        v-if="item"
        :key="`${item.text}-br`"
      >
    </template>
  </fieldset>
</template>

<script>
export default {
  name: 'UbuEditManageShopifyShopForm',
  model: {
    prop: 'manageShop',
  },
  props: {
    manageShop: { type: Object, default: () => {} },
  },
  computed: {
    displayInputs() {
      return [
        { text: 'Website URL', data: 'baseUrl', type: 'text' },
        { text: 'Shop Extref ID', data: 'extrefId', type: 'text' },
        { text: 'Shop Platform', data: 'type', type: 'text' },
        { text: 'API Version', data: 'apiVersion', type: 'text' },
        { text: 'API Key', data: 'apiKey', type: 'password' },
        { text: 'Password', data: 'password', type: 'password' },
        { text: 'Shared Secret', data: 'sharedSecret', type: 'password' },
      ];
    },
  },
};
</script>
