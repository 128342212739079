/* eslint-disable func-names */
import { SnackbarProgrammatic as Snackbar } from 'buefy';

const debounce = function (fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const that = this;

    timeoutID = setTimeout(() => {
      fn.apply(that, args);
    }, delay);
  };
};

const errorHandler = (message) => (res) => {
  if (res.error) throw new Error(message);
  return res;
};

const isActionAllowed = (rootGetters, right) => {
  const { rights } = rootGetters['Authenticate/getterAuthenticate'];
  if (!rights[right]) {
    Snackbar.open({
      message: `You are not allowed to do that. ("${right}").`,
      type: 'is-danger',
      position: 'is-top',
      duration: 10000,
    });
    return false;
  }
  return true;
};

const splitArrayInChunks = (array, chunkLength) => Array(Math.ceil(array.length / chunkLength))
  .fill()
  .map((_, i) => array.slice(i * chunkLength, i * chunkLength + chunkLength));

const snackbarSuccess = {
  type: 'is-success',
  position: 'is-top',
  duration: 5000,
};

const snackbarError = {
  type: 'is-danger',
  position: 'is-top',
  duration: 5000,
};

export {
  debounce,
  errorHandler,
  isActionAllowed,
  snackbarSuccess,
  snackbarError,
  splitArrayInChunks,
};
