/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    userRoleList: [],
  },
  mutations: {
    SET_USER_ROLE_LIST(state, newData) { state.userRoleList = newData; },
    RESET_STORE(state) {
      state.userRoleList = [];
    },
  },
  getters: {
    getterUserRoleList: (state) => state.userRoleList,
  },
  actions: {
    report({ commit }) {
      return clark.api.userRole.report({ payload: {}, httpQuery: {} })
        .then((userRoleList) => { commit('SET_USER_ROLE_LIST', userRoleList); });
    },
  },
};
