export default {
  data() {
    return {
      columns: {
        uid: {
          col: 'uid', label: 'uid', visible: true, isSortable: false, cmp: 'flat',
        },
        firstName: {
          col: 'firstName', label: 'First name', visible: true, isSortable: false, cmp: 'flat',
        },
        lastName: {
          col: 'lastName', label: 'Last name', visible: true, isSortable: false, cmp: 'flat',
        },
        email: {
          col: 'email', label: 'Email', visible: true, isSortable: false, cmp: 'flat',
        },
        role: {
          col: 'role', label: 'Role', visible: true, isSortable: false, cmp: 'flat',
        },
        isLocked: {
          col: 'lockedOn', label: 'active', visible: true, isSortable: false, cmp: 'tribe',
        },
        isUbu: {
          col: 'isUbu', label: 'isUbu', visible: true, isSortable: false, cmp: 'isUbu',
        },
        action: {
          col: 'action', label: 'action', visible: true, isSortable: false, cmp: 'openContactPanel', sticky: true,
        },
      },
    };
  },
};
