<template>
  <div
    v-show="tooltipValue"
    id="tooltip-dynamic"
  >
    <div
      :class="
        'arrow' +
          [tooltipPosition === 'left' ? ' left' : ''] +
          [tooltipPosition === 'top' ? ' top' : ''] +
          [tooltipPosition === 'right' ? ' right' : ''] +
          [tooltipPosition === 'bottom' ? ' bottom' : '']
      "
    />
    {{ tooltipValue }}
  </div>
</template>

<script>
import GlobalTooltipMixin from '@dailyplanet/mixins/GlobalTooltipMixin';

export default {
  name: 'GlobalTooltip',
  mixins: [GlobalTooltipMixin],
};
</script>

<style lang="scss">
#tooltip-dynamic {
  position: absolute;
  width: auto;
  height: auto;
  padding: 0.35rem 0.75rem;
  border-radius: 6px;
  font-size: 0.85rem;
  font-weight: 600;
  background-color: var(--givry);
  color: var(--cloudburst);
  box-shadow: 0px 0px 8px var(--box-shadow);
  top: 0;
  left: 0;
  z-index: 38;
  white-space: nowrap;
  .arrow {
    position: inherit;
    &.left {
      left: 100%;
      top: 50%;
      right: auto;
      bottom: auto;
      transform: translateY(-50%);
      border-top: 5px solid transparent;
      border-left: 5px solid #7957d5;
      border-bottom: 5px solid transparent;
      border-left-color: var(--givry);
    }
    &.top {
      left: 50%;
      top: 100%;
      right: auto;
      bottom: auto;
      transform: translateX(-50%);
      border-right: 5px solid transparent;
      border-top: 5px solid #7957d5;
      border-left: 5px solid transparent;
      border-top-color: var(--givry);
    }
    &.right {
      left: auto;
      top: 50%;
      right: 100%;
      bottom: auto;
      transform: translateY(-50%);
      border-top: 5px solid transparent;
      border-right: 5px solid #7957d5;
      border-bottom: 5px solid transparent;
      border-right-color: var(--givry);
    }
    &.bottom {
      left: 50%;
      top: auto;
      right: auto;
      bottom: 100%;
      transform: translateX(-50%);
      border-right: 5px solid transparent;
      border-bottom: 5px solid #7957d5;
      border-left: 5px solid transparent;
      border-bottom-color: var(--givry);
    }
  }
}
</style>
