/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import builder from '../engine';

const target = 'clark';

const adAccount = {
  get: ({ payload, httpQuery }) => builder({ url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/getFacebookAdAccounts`, method: 'GET', payload, httpQuery, target }),
  set: ({ payload, httpQuery }) => builder({ url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/setActiveAdAccountId/${payload.adAccountId}`, method: 'POST', payload, httpQuery, target }),
  getIsFBConnected: ({ payload, httpQuery }) => builder({ url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/isFBConnected`, method: 'GET', payload, httpQuery, target }),
};

const posts = {
  instagramMentions: ({ payload, httpQuery }) => builder({ url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/mentionedMediasDb`, method: 'GET', payload, httpQuery, target }),
  // instagram: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/mediasDB`, method: 'GET', payload, httpQuery, target }),
  // facebook: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/mediasFbDB`, method: 'GET', payload, httpQuery, target }),
  fetchFbOrganic: ({ payload, httpQuery }) => builder({ url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/fetchFbOrganic`, method: 'GET', payload, httpQuery, target }),
  fetchAllAds: ({ payload, httpQuery }) => builder({ url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/fetchAllAds`, method: 'GET', payload, httpQuery, target }),
  // fetchDynamics: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/fetchDynamics`, method: 'GET', payload, httpQuery, target }),
};

const comments = {
  // refreshFacebook: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/commentsLimitedFb/${payload.graphId}`, method: 'GET', payload, httpQuery, target }),
  instagram: ({ payload, httpQuery }) => builder({ url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/commentsDB/${payload.graphId}`, method: 'GET', payload, httpQuery, target }),
  facebook: ({ payload, httpQuery }) => builder({ url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/commentsFbDB/${payload.graphId}`, method: 'GET', payload, httpQuery, target }),
  delete: ({ payload }) => builder({ url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/deleteCommentFb/${payload.commentId}`, method: 'GET', payload, target }),
  // getCommentsInsta: ({ payload }) => builder({ url: `/channel/${payload.cid}/commentsLimited/${payload.mediaFBId}`, method: 'GET' }),
  getMediasDB: ({ payload }) => builder({ method: 'GET', url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/mediasDB`, target }),
  getMedias: ({ payload }) => builder({ method: 'GET', url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/medias/null`, target }),
  testError: ({ payload }) => builder({ method: 'GET', url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/closeComment/undefined/true`, target }),
  // getComments: ({ payload }) => builder({ method: 'GET', url: `/channel/${payload.cid}/commentsDB/${payload.mediaFBId}`, target }),
  fetchMedia: ({ payload }) => {
    console.warn('WARNING THIS ROUTE IS CALL WITHOUT PASSING A CONTEXT');
    return builder({ method: 'GET', url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/instaMedia/${payload.mediaId}`, target });
  },
  deleteComment: (payload) => {
    console.warn('WARNING THIS ROUTE IS CALL WITHOUT PASSING A CONTEXT');
    return builder({ method: 'GET', url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/deleteComment/${payload.commentId}`, target });
  },
  postComment: (payload) => {
    console.warn('WARNING THIS ROUTE IS CALL WITHOUT PASSING A CONTEXT');
    return builder({ method: 'POST', url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/commentReply/${payload.parentId}`, payload: { message: payload.message }, target });
  },

  postRootComment: (payload) => {
    console.warn('WARNING THIS ROUTE IS CALL WITHOUT PASSING A CONTEXT');
    return builder({ method: 'POST', url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/postReply/${payload.postId}`, payload: { message: payload.message }, target });
  },

  postCommentFb: (payload) => {
    console.warn('WARNING THIS ROUTE IS CALL WITHOUT PASSING A CONTEXT');
    return builder({ method: 'POST', url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/commentReplyFb`, payload: { parentId: payload.parentId, targetId: payload.targetId, message: payload.message }, target });
  },

  openCloseThread: (payload) => {
    console.warn('WARNING THIS ROUTE IS CALL WITHOUT PASSING A CONTEXT');
    return builder({ method: 'GET', url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/closeComment${payload.source === 'facebook' ? 'Fb' : ''}/${payload.commentId}/${payload.closed}`, target });
  },

  // closeManyThreads: (payload) => {
  //   console.warn('WARNING THIS ROUTE IS CALL WITHOUT PASSING A CONTEXT');
  //   return builder({ method: 'POST', url: `/channel/${payload.channelId}/closeManyComments${payload.source === 'facebook' ? 'Fb' : ''}/${payload.closed}`, data: { comment_ids: [...payload.threadIds] }, target });
  // },

  closeAllComments: (payload) => {
    console.warn('WARNING THIS ROUTE IS CALL WITHOUT PASSING A CONTEXT');
    return builder({ method: 'POST', url: `/api/repentance/${payload.cid}/channel/${payload.channelUsername}/closeAll${payload.source === 'facebook' ? 'Fb' : ''}Comments/${payload.mediaId}`, target });
  },
};

export {
  adAccount,
  posts,
  comments,
};
