<template>
  <div
    ref="threadList"
    tabindex="0"
    class="threadList"
    @click="setThreadlistFocused()"
    @blur="up_focusedElement({ name: '' })"
    @keydown.prevent="shortcutThreadListNavigation($event)"
  >
    <div
      v-if="loading"
      class="start ubuThreadCardLink"
    >
      <UbuThreadCard
        v-for="index in 3"
        :key="index"
        loading
      />
    </div>
    <RecycleScroller
      v-else
      ref="scroller"
      :item-size="76"
      :items="threadList"
      key-field="tid"
      :emit-update="true"
      class="threadListScroller"
      @update="onScroll"
    >
      <template
        v-slot="{ item }"
      >
        <TheThreadListCard
          :key="item.thread.tid"
          :ref="'thread-'+item.thread.tid"
          :class="[{'router-link-active': String(item.thread.tid) === String($route.params.tid)}]"
          :contact-platform-overview="heldThreads && heldThreads[item.cpid]
            ? heldThreads[item.cpid]
            : item"
          @click="setThreadlistFocused()"
          @blur="up_focusedElement({ name: '' })"
          @selectCard="goToThread(item)"
        />
      </template>
    </RecycleScroller>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import ShortcutMixin from '@dailyplanet/mixins/ShortcutMixin';
import ThreadListMixin from '@dailyplanet/mixins/ThreadListMixin';
import SocketMixin from '@dailyplanet/mixins/SocketMixin';

export default {
  name: 'TheThreadList',
  mixins: [SocketMixin, ThreadListMixin, ShortcutMixin],
  model: {
    prop: 'httpQuery',
  },
  props: {
    httpQuery: {
      type: Object,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // page: 0,
      perPage: 50,
    };
  },

  computed: {
    ...mapGetters({
      contactGroupList: 'TheInbox/contactGroupList',
      threadGroupList: 'TheInbox/threadGroupList',

      currentChannelGroup: 'Authenticate/getterCurrentChannelGroup',

      isFocused: 'TheInbox/getterIsThreadListFocused',
      heldThreads: 'TheInbox/heldThreads',

      threadTidsSelectedInBulk: 'TheInbox/threadTidsSelectedInBulk',

      _cpo: 'TheContactPlatformPanel/contactPlatformOverview',
    }),

    localHttpQuery: {
      get() { return { ...this.httpQuery }; },
      set(newValue) {
        // console.log('🚀 ~ file: TheThreadList.vue ~ line 98 ~ set ~ newValue', newValue);
        this.$emit('input', newValue);
      },
    },
    localPage: {
      get() { return this.page; },
      set(newValue) {
        // console.log('🚀 ~ file: TheThreadList.vue ~ line 104 ~ set ~ newValue', newValue);
        this.$emit('update:page', newValue);
      },
    },
  },
  watch: {
    isFocused(isFocused) {
      if (isFocused) this.$refs.threadList.focus();
    },
  },
  mounted() {
    // console.error('🚀 ~ file: TheThreadList.vue ~ line 101 ~ mounted ~ mounted');
    if (this.isFocused) {
      this.$refs.threadList.focus();
    }
  },
  methods: {
    ...mapActions({
      reportThreadList: 'TheInbox/reportThreadList',
      resetThreadList: 'TheInbox/resetThreadList',
      _UP_resetBulkHeldThreads: 'TheInbox/UP_resetBulkHeldThreads',
      _bulkThreadsState: 'TheTextarea/_bulkThreadsState',

      up_focusedElement: 'TheInbox/up_focusedElement',
      _UP_toggleHeld: 'TheInbox/UP_toggleHeld',
    }),

    UP_toggleHeld() {
      return this._UP_toggleHeld(this.$route.params.tid)
        .then(() => (!this.threadTidsSelectedInBulk.length ? this._UP_resetBulkHeldThreads() : undefined));
    },
    onScroll(startIndex, endIndex) {
      // console.log('🚀 ~ file: TheThreadList.vue ~ line 134 ~ onScroll ~ endIndex', endIndex);
      const currentPage = Math.floor(endIndex / this.perPage);
      // console.log('🚀 ~ file: TheThreadList.vue ~ line 135 ~ onScroll ~ currentPage', currentPage);
      // console.log('🚀 ~ file: TheThreadList.vue ~ line 136 ~ onScroll ~ this.localPage', this.localPage);
      // console.log('🚀 ~ file: TheThreadList.vue ~ line 132 ~ onScroll ~ this.page', this.page);

      // Changing page
      if (currentPage !== this.localPage) {
        // Only on scroll down
        if (currentPage > this.localPage) {
          // console.log('🚀 ~ file: TheThreadList.vue ~ line 144 ~ onScroll ~ currentPage > this.page', currentPage > this.page);
          this.localPage = currentPage;
          this.reportThreadList({
            httpQuery: { ...this.httpQuery, pagin: [currentPage, this.perPage] },
            // httpQuery: this.httpQuery,
          });
        }
      }
    },

    shortcutThreadListNavigation(event) {
      const {
        ctrlIsPressed,
        toBottomIsPressed,
        toTopIsPressed,
        enterIsPressed,
        iIsPressed,
        escapeIsPressed,
      } = this.parseEvent(event);

      if (toBottomIsPressed && this.previousThread) {
        return this.$route.path !== this.previousThreadPath
          ? this.$router.push({ path: this.previousThreadPath, query: this.$route.query })
          : null;
      }
      if (toTopIsPressed && this.nextThread) {
        return this.$route.path !== this.nextThreadPath
          ? this.$router.push({ path: this.nextThreadPath, query: this.$route.query })
          : null;
      }
      if (ctrlIsPressed && enterIsPressed) return this.UP_toggleHeld();
      if (enterIsPressed) return this.up_focusedElement({ name: 'TEXTAREA' });
      if (ctrlIsPressed && escapeIsPressed) return this._UP_resetBulkHeldThreads();
      if (ctrlIsPressed && iIsPressed) this.changeThreadStatus('THREAD_READ');
      return undefined;
    },

    changeThreadStatus(event) {
      const { tid, cpid } = this._cpo;

      const payload = { event, tids: [tid] };
      const mutations = [];

      if (['THREAD_READ'].includes(event)) {
        mutations.push({
          target: 'ApiContact/contactPlatformOverview/UP_updateThreadState',
          payload: {
            key: 'isRead',
            value: true,
            cpids: [cpid],
          },
        });
      }

      this.buttonLoading = true;
      return this._bulkThreadsState({ payload, mutations });
    },

    setThreadlistFocused() {
      if (this.isFocused) this.$refs.threadList.focus();
      return this.up_focusedElement({ name: 'THREAD_LIST' });
    },
  },
};
</script>
