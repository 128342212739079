// import lib from './lib';
import smarts from './smarts';
import * as stores from './stores';

export default {
  // eslint-disable-next-line
  // lib,
  smarts,
  stores,
};
