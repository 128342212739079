/* eslint-disable max-len */
// import { SnackbarProgrammatic as Snackbar } from 'buefy';
// import { dispatchAndForward, dispatchFailure, snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
  },
  getters: {
    ...getters.account,
    ...getters.authenticate,
    ...getters.campaign,
    ...getters.channelGroup,
    ...getters.contactGroup,
    ...getters.contactPlatformOverview,
    ...getters.memberGroup,
    ...getters.template,
    ...getters.protoList,
    ...getters.searchInstagramUsername,
    ...getters.threadGroup,
    ...getters.tribe,
    ...getters.segmentFilter,
    ...getters.userDetail,
  },
  actions: {
    ...actions.activity,
    ...actions.bulkActivity,
    ...actions.campaign,
    ...actions.channelGroup,
    ...actions.contactGroup,
    ...actions.contactPlatformOverview,
    ...actions.protoList,
    ...actions.searchInstagramUsername,
    ...actions.segment,
    ...actions.template,
    ...actions.threadGroup,
    ...actions.segmentFilter,

    UP_checkedRows({ commit }, newData) {
      commit('SET_CHECKED_ROWS', newData);
    },
  },
};
