<template>
  <div class="mainPanel">
    <header class="mainPanelHeader multibar row-vcentered">
      <h1 class="start title is-2">Analytics</h1>

      <!-- <br> -->

      <div class="end buttons expandable">
        <b-button
          v-for="(tab, index) in tabs"
          :key="index"
          tag="router-link"
          type="is-simple"
          :to="tab.to"
          :active="$route.path.includes(tab.to)"
        >
          <component :is="tab.cmpIcon" />
          {{ tab.title }}
        </b-button>
      </div>
    </header>

    <router-view name="accountView" />

    <router-view name="inboxView" />

    <router-view name="affiliationView" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheAnalytics',
  data() {
    return {
      tabs: [
        {
          title: 'Account', cmpIcon: 'UbuIconInstagram', to: '/activity/analytics/account', active: true,
        },
        {
          title: 'Inbox', cmpIcon: 'UbuIconInstagram', to: '/activity/analytics/inbox', active: false,
        },
        {
          title: 'Affiliation', cmpIcon: 'UbuIconShopify', to: '/activity/analytics/affiliation', active: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      accountFeatures: 'TheAnalytics/accountFeatures',
    }),
    hasAnalyticsFeature() {
      return this.accountFeatures('ANALYTICS');
    },
  },
  mounted() {
    if (!this.hasAnalyticsFeature) {
      this.emitSegmentEvent({ event: 'clickOnAnalyticsNoFeature' });

      return;
    }

    this.emitSegmentEvent({ event: 'clickOnAnalytics' });
  },
  methods: {
    ...mapActions({
      emitSegmentEvent: 'TheAnalytics/emitSegmentEvent',
    }),

    isRouteActive(route) {
      return this.$route.path.includes(route);
    },
  },
};
</script>
