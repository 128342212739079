<template>
  <!-- eslint-disable max-len -->
  <div id="TheHome">
    <div id="background" />
    <aside class="ubuPanel-xl ubuSidePanel">
      <header>
        <p class="text is-3 has-text-secondary is-flex">
          <b-image
            class="start mr-2 is-16x16"
            :src="ubuLogo"
          />
          Ubu
        </p>
        <h1 class="title is-1">
          {{ content.title }}
        </h1>
      </header>

      <br>

      <section class="sidePanelContent">
        <UbuHelper
          type="is-secondary"
          :content="content.content"
        />

        <br>

        <div class="multibar">
          <b-button
            type="is-success"
            label="Contact sales"
            class="end"
            @click="contactSales()"
          />
        </div>
      </section>
    </aside>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const ubuLogo = require('@dailyplanet/selina/src/assets/images/ubuLogov2.svg');

export default {
  name: 'TheAccessDenied',
  data() {
    return {
      ubuLogo,
    };
  },
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheAccessDenied/myAuthenticate',
    }),
    stateOfAccount() {
      const {
        accountList,
        setting: { defaultAccountAid },
      } = this.myAuthenticate;

      const { state } = accountList.find(({ aid }) => Number(aid) === Number(defaultAccountAid));
      return state.toLowerCase();
    },
    content() {
      const content = {
        pending: {
          title: 'It\'s time to upgrade your account!',
          content: 'Your account is temporarily locked but no worries, let’s contact sales to keep using Ubu and supercharge your community 😍🚀',
          intercom: 'Hi! I would like to upgrade my account and keep using Ubu! 🙌',
        },
        blocked: {
          // title: 'Your account is blocked!',
          // content: 'Your account is temporarily blocked but no worries, let’s contact sales to keep using Ubu and supercharge your community 😍🚀',
          // intercom: 'Hi! I would like to unlock my account and keep using Ubu! 🙌',
          title: 'It\'s time to upgrade your account!',
          content: 'Your account is temporarily locked but no worries, let’s contact sales to keep using Ubu and supercharge your community 😍🚀',
          intercom: 'Hi! I would like to upgrade my account and keep using Ubu! 🙌',
        },
      };

      return content[this.stateOfAccount];
    },
  },
  methods: {
    contactSales() {
      this.$intercom.showNewMessage(this.content.intercom);
    },
  },
};
</script>
