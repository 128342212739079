import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuKaraSearchAccountForm from './UbuKaraSearchAccountForm';
import UbuSummaryAddUser from './UbuSummaryAddUser';
import UbuKaraUbuTeamForm from './UbuKaraUbuTeamForm';
import UbuKaraDefaultChannelGroupForm from './UbuKaraDefaultChannelGroupForm';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuKaraSearchAccountForm);
    registerComponent(Vue, UbuSummaryAddUser);
    registerComponent(Vue, UbuKaraUbuTeamForm);
    registerComponent(Vue, UbuKaraDefaultChannelGroupForm);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuKaraSearchAccountForm,
  UbuSummaryAddUser,
  UbuKaraUbuTeamForm,
  UbuKaraDefaultChannelGroupForm,
};
