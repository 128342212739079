<template>
  <b-notification
    :type="item.type"
    :closable="false"
    :class="['is-small', {
      notificationLoading: item.icon === 'ubu-in-progress'
    }]"
  >
    <div class="multibar row-vcentered">
      <b-icon
        class="start mr-2"
        pack="ubu"
        :icon="item.icon"
      />

      <span class="start">{{ item.username }}</span>
    </div>
    <p
      v-if="item.errorMessage"
    >
      {{ item.errorMessage }}
    </p>
  </b-notification>
</template>

<script>
export default {
  name: 'UbuSendInstantMessageNotification',
  model: {
    prop: 'item',
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
