import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheAddToCrmList from './smarts/TheAddToCrmList';
import TheAddToCrmListAlreadyInCrmList from './smarts/TheAddToCrmListAlreadyInCrmList';
import TheAddToCrmListToAdd from './smarts/TheAddToCrmListToAdd';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheAddToCrmList);
    registerComponent(Vue, TheAddToCrmListAlreadyInCrmList);
    registerComponent(Vue, TheAddToCrmListToAdd);
  },
};

use(Plugin);

export default Plugin;

export {
  TheAddToCrmList,
  TheAddToCrmListAlreadyInCrmList,
  TheAddToCrmListToAdd,
};
