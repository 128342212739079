<template>
  <section class="ubuWizardView ubuSidePanel ubuPanel-xl ubuChannelAdd">
    <div class="multibar row-vcentered">
      <b-button
        class="start is-ghost"
        icon-pack="ubu"
        icon-left="ubu-arrow-left"
        label="Back"
        tag="router-link"
        to="/setting/channel/addMeta"
      />

      <b-button
        tag="router-link"
        to="/setting/channel"
        class="end is-simple-icon is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <header>
      <p class="text is-3 has-text-secondary">Manage Meta channels</p>
      <h1 class="title is-1">Select a Facebook Page</h1>
    </header>

    <DevDetails :cnt="{name: 'channelList', obj: channelList}" />
    <DevDetails :cnt="{name: 'facebookPageList', obj: facebookPageList}" />

    <article>
      <fieldset
        v-if="routableItemsFromPages.avaiblable.length"
        class="fieldset"
      >
        <legend>AVAILABLE</legend>
        <br>
        <div class="buttons">
          <UbuActionCard
            v-for="item in routableItemsFromPages.avaiblable"
            :key="item.label"
            class="mb-4"
            :label="item.label"
            icon-pack="ubu"
            :social-icon="item.socialIcon"
            info
            :info-tooltip="false"
            :info-label="'id:' + item.id"
            expanded
            :disabled="false"
            @click="clickHandler(item)"
          />
        </div>
      </fieldset>

      <br>

      <fieldset
        v-if="routableItemsFromPages.unavailable.length"
        class="fieldset"
      >
        <legend>UNAVAILABLE</legend>
        <br>
        <div class="buttons">
          <UbuActionCard
            v-for="item in routableItemsFromPages.unavailable"
            :key="item.label"
            class="mb-4"
            :label="item.label"
            icon-pack="ubu"
            :social-icon="item.socialIcon"
            info
            :info-tooltip="false"
            :info-label="item.subtext"
            expanded
            disabled
          />
        </div>
      </fieldset>
    </article>

    <br>

    <div class="buttons is-right">
      <b-button
        type="is-ghost"
        label="Need help to connect your channels ?"
        @click="$intercom.showNewMessage('Hi! I need help to connect my Meta channels ! 🙌')"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSettingChannelsAddMetaSelectFacebook',
  computed: {
    ...mapGetters({
      account: 'TheSettingWorkspaceSetting/account',
      currentChannelGroup: 'TheSettingWorkspaceSetting/currentChannelGroup',
      facebookConnectList: 'TheSettingWorkspaceSetting/facebookConnectList',
    }),
    channelList() {
      if (!this.account) return [];
      const channels = this.account.channelGroupList.flatMap(({ channelList }) => channelList);

      return channels;
    },
    facebookPageList() {
      if (!this.facebookConnectList) return [];

      if (!this.account) return [];

      const { facebookPages } = this.facebookConnectList;

      if (!facebookPages) return [];

      const pageList = this.facebookConnectList.facebookPages
        .reduce((acc, page) => {
          const fbChannel = this.channelList.find(({ extrefId }) => page.extrefPageId === extrefId);

          if (!acc[page.fpid]) {
            acc[page.fpid] = {
              fpid: page.fpid,
              name: page.name,
              id: page.extrefPageId,
              isActive: !!fbChannel,
              channelGroupCgid: fbChannel ? fbChannel.channelGroupCgid : null,
              instagram: page.instagramAccounts || [],
            };
          }
          return acc;
        }, {});

      return Object.values(pageList);
    },
    routableItemsFromPages() {
      return this.facebookPageList
        .map((page) => ({
          label: page.name,
          id: page.id,
          to: { path: `/setting/channel/selectInstagram/${page.fpid}`, query: this.$route.query },
          socialIcon: 'FACEBOOK',
          ...this.isAvaiblable(page),
        }))
        .reduce((acc, row) => {
          if (row.isAvaiblable) acc.avaiblable.push(row);
          else acc.unavailable.push(row);
          return acc;
        }, { avaiblable: [], unavailable: [] });
    },
    channelFacebook() {
      const { cgid } = this.currentChannelGroup;
      return this.channelList.filter(({ platformName, channelGroupCgid }) => channelGroupCgid === cgid && platformName === 'FACEBOOK');
    },
  },
  mounted() {
    this.reportFacebookConnect();
  },
  methods: {
    ...mapActions({
      getAccount: 'TheSettingWorkspaceSetting/getAccount',
      reportFacebookConnect: 'TheSettingWorkspaceSetting/reportFacebookConnect',
      _connectMetaChannel: 'TheSettingWorkspaceSetting/connectMetaChannel',
      _createChannel: 'TheSettingWorkspaceSetting/createChannel',
      _tryConnectMeta: 'TheSettingWorkspaceSetting/tryConnectMeta',
    }),
    clickHandler(item) {
      return this.$router.push(item.to);
    },
    isAvaiblable(page) {
      if (page.isActive) {
        if (Number(page.channelGroupCgid) === Number(this.currentChannelGroup.cgid)) {
          return { isAvaiblable: true, subtext: 'Reconnect' };
        }
        if (Number(page.channelGroupCgid) !== Number(this.currentChannelGroup.cgid)) {
          return { isAvaiblable: false, subtext: 'Used in another workspace' };
        }
      }
      if (this.channelFacebook.length) {
        return { isAvaiblable: false, subtext: 'Another page is already used in this workspace' };
      }
      return { isAvaiblable: true, subtext: 'Connect' };
    },
  },
};
</script>
