<template>
  <!-- <div
    class="ubuMessageCard-image"
  > -->
  <b-carousel
    :autoplay="false"
    animated="fade"
  >
    <b-carousel-item
      v-for="(item, i) in message.medias"
      :key="i"
    >
      <!-- <b-image
          :src="item.url"
          placeholder="@/assets/images/image-placeholder.png"
          src-fallback="@/assets/images/image-placeholder.png"
          :lazy="false"
          ratio="298by280"
          responsive
          @error="$emit('handleError')"
          @click.native="openImage(item)"
        /> -->
      <UbuThreadMessageImage
        v-if="item.type === 'IMAGE'"
        :key="item.mid"
        :message="message"
        :url="item.url"
        @handleError="$emit('handleError')"
      />
      <UbuThreadMessageVideo
        v-if="item.type === 'VIDEO'"
        :key="item.mid"
        :message="message"
        :url="item.url"
        @handleError="$emit('handleError')"
      />
      <!-- <UbuThreadMessageAudio
        v-if="item.type === 'AUDIO'"
        :key="item.mid"
        :message="message"
        @handleError="$emit('handleError')"
      /> -->
    </b-carousel-item>
  </b-carousel>
  <!-- </div> -->
</template>
<script>
export default {
  name: 'UbuThreadMessageCarousel',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  // methods: {
  //   openImage(item) {
  //     window.open(item.url, '_blank');
  //   },
  // },
};
</script>
