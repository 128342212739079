/* eslint-disable max-len */
import menu from './menu';
import scrapingZone from './scrapingZone';
import lead from './lead';
import salesContact from './salesContact';
import reportDetailledLead from './reportDetailledLead';
import salesUser from './salesUser';
import salesContactSuggestion from './salesContactSuggestion';
import theme from './theme';
import account from './account';
import activity from './activity';
import authenticate from './authenticate';
import channelGroup from './channelGroup';
import contactGroup from './contactGroup';
import downloadTask from './downloadTask';
import influence from './influence';
import mention from './mention';
import message from './message';
import threadGroup from './threadGroup';
import user from './user';
import thread from './thread';
import contact from './contact';
import userDetail from './userDetail';
import city from './city';
import helpCenter from './helpCenter';
import latestUpdate from './latestUpdate';
import tokenLink from './tokenLink';
import accountState from './accountState';
import feature from './feature';
import channel from './channel';
import reportDetailledUser from './reportDetailledUser';
import userRole from './userRole';
import shortcut from './shortcut';
import migrate from './migrate';
import searchInstagramUsername from './searchInstagramUsername';
import favikon from './favikon';
import adminLogin from './apiAuthenticate/adminLogin';
import segment from './segment';
import monitor from './monitor';
import apiKey from './apiKey';

import template from './apiConversation/template';
import templateFolder from './apiConversation/templateFolder';
import privateNote from './apiConversation/privateNote';
import threadList from './apiConversation/threadList';

import {
  segmentFilter,
  shortLink,
} from './apiAnalytics';

import {
  brand,
  crewUser,
  crewAdmin,
} from './apiCrew';

import {
  memberGroup,
  mention as tribeMention,
  tribe,
  tribeMember,
  tribeAnalytics,
} from './apiTribe';

import {
  affiliationCode,
  affiliationCodeFolder,
  affiliationReward,
  discountCode,
  order,
  shop,
} from './apiAffiliation';

import {
  contactPlatformOverview,
  contactPrivateMetadata,
} from './apiContact';

import {
  iqdataReport,
} from './apiDownload';

import {
  // analytics,
  activityOverview,
  bulkActivity,
  campaign,
  campaignAnalytics,
  campaignThreadCounters,
  protoList,
} from './apiInfluence';

import {
  campaignMention,
  activityMention,
  autoReport,
  favorite,
  downloadMention,
} from './apiPublication';

import {
  dailyMention,
} from './apiChannel';

import {
  tremendousAccount,
  tremendousCampaign,
  tremendousFundingSource,
} from './apiTremendous';

import threadAndParticipant from './channelGroup/threadAndParticipant';

const commons = [
  { n: 'menu', c: menu },
  { n: 'scrapingZone', c: scrapingZone },
  { n: 'lead', c: lead },
  { n: 'salesContact', c: salesContact },
  { n: 'reportDetailledLead', c: reportDetailledLead },
  { n: 'salesUser', c: salesUser },
  { n: 'salesContactSuggestion', c: salesContactSuggestion },
  { n: 'theme', c: theme },
  { n: 'account', c: account },
  { n: 'activity', c: activity },
  { n: 'authenticate', c: authenticate },
  { n: 'apiKey', c: apiKey },

  { n: 'channelGroup', c: channelGroup },
  { n: 'contactGroup', c: contactGroup },
  { n: 'downloadTask', c: downloadTask },

  { n: 'influence', c: influence },
  { n: 'mention', c: mention },
  { n: 'message', c: message },
  { n: 'threadGroup', c: threadGroup },
  { n: 'template', c: template },
  { n: 'user', c: user },
  { n: 'thread', c: thread },
  { n: 'contact', c: contact },
  { n: 'userDetail', c: userDetail },
  { n: 'city', c: city },
  { n: 'helpCenter', c: helpCenter },
  { n: 'latestUpdate', c: latestUpdate },
  { n: 'tokenLink', c: tokenLink },
  { n: 'accountState', c: accountState },
  { n: 'feature', c: feature },
  { n: 'channel', c: channel },
  { n: 'reportDetailledUser', c: reportDetailledUser },
  { n: 'userRole', c: userRole },
  { n: 'shortcut', c: shortcut },
  { n: 'searchInstagramUsername', c: searchInstagramUsername },
  { n: 'favikon', c: favikon },
  { n: 'adminLogin', c: adminLogin },
  { n: 'migrate', c: migrate },
  { n: 'segment', c: segment },
  { n: 'monitor', c: monitor },
  { n: 'privateNote', c: privateNote },
  { n: 'threadAndParticipant', c: threadAndParticipant },
  { n: 'templateFolder', c: templateFolder },

  { n: 'contactPlatformOverview', c: contactPlatformOverview },
  { n: 'contactPrivateMetadata', c: contactPrivateMetadata },
  { n: 'threadList', c: threadList },

  // API ANALYTICS
  { n: 'segmentFilter', c: segmentFilter },
  { n: 'shortLink', c: shortLink },

  // API AFFILIATION
  { n: 'affiliationCode', c: affiliationCode },
  { n: 'affiliationCodeFolder', c: affiliationCodeFolder },
  { n: 'affiliationReward', c: affiliationReward },
  { n: 'discountCode', c: discountCode },
  { n: 'order', c: order },
  { n: 'shop', c: shop },

  // API PUBLICATION
  { n: 'campaignMention', c: campaignMention },
  { n: 'activityMention', c: activityMention },
  { n: 'autoReport', c: autoReport },
  { n: 'favorite', c: favorite },
  { n: 'downloadMention', c: downloadMention },

  // API INFLUENCE
  { n: 'activityOverview', c: activityOverview },
  { n: 'bulkActivity', c: bulkActivity },
  { n: 'campaign', c: campaign },
  { n: 'campaignAnalytics', c: campaignAnalytics },
  { n: 'campaignThreadCounters', c: campaignThreadCounters },
  { n: 'protoList', c: protoList },

  // API TRIBE
  { n: 'memberGroup', c: memberGroup },
  { n: 'tribe', c: tribe },
  { n: 'tribeMember', c: tribeMember },
  { n: 'tribeMention', c: tribeMention },
  { n: 'tribeAnalytics', c: tribeAnalytics },

  // API CHANNEL
  { n: 'dailyMention', c: dailyMention },

  // API DOWNLOAD
  { n: 'iqdataReport', c: iqdataReport },

  // API CREW
  { n: 'brand', c: brand },
  { n: 'crewUser', c: crewUser },
  { n: 'crewAdmin', c: crewAdmin },

  // API TREMENDOUS
  { n: 'tremendousAccount', c: tremendousAccount },
  { n: 'tremendousCampaign', c: tremendousCampaign },
  { n: 'tremendousFundingSource', c: tremendousFundingSource },
];

const commonState = commons.reduce((acc, { n, c }) => ({ ...acc, [n]: { ...c.state } }), {});
const getters = commons.reduce((acc, { n, c }) => ({ ...acc, [n]: { ...c.getters } }), {});
const actions = commons.reduce((acc, { n, c }) => ({ ...acc, [n]: { ...c.actions } }), {});
const gettersSelected = (commonName, fnNames) => {
  const commonStore = commons.find((common) => common.n === commonName);
  if (!commonStore) throw new Error(`common view store (${commonName}) not found`);
  const _getters = commonStore.c.getters;

  fnNames.forEach((fnName) => {
    if (!_getters[fnName]) throw new Error(`${fnName} getter dont exists in ${commonName} common view store`);
  });

  return Object.entries(_getters)
    .reduce((acc, [fnName, fn]) => (fnNames.includes(fnName) ? { ...acc, [fnName]: fn } : acc), {});
};

export {
  commonState,
  getters,
  actions,
  gettersSelected,
};

export default {
  commonState: commons.reduce((acc, { c }) => ({ ...acc, ...c.state }), {}),
  getters: commons.reduce((acc, { c }) => ({ ...acc, ...c.getters }), {}),
  actions: commons.reduce((acc, { c }) => ({ ...acc, ...c.actions }), {}),
  gettersSelected,
};
