import { mapGetters, mapActions } from 'vuex';

const META_MAX_SIZE_IMG = 1024 * 1024 * 8;
const META_MAX_SIZE_OTHER = 1024 * 1024 * 25;

const allowedFile = (event) => {
  switch (event.type) {
    case 'image/jpeg': return event.size >= META_MAX_SIZE_IMG ? 'MAX_SIZE_MEDIA_8MB' : null;
    case 'image/png': return event.size >= META_MAX_SIZE_IMG ? 'MAX_SIZE_MEDIA_8MB' : null;

    case 'video/mp4': return event.size >= META_MAX_SIZE_OTHER;
    case 'video/ogg': return event.size >= META_MAX_SIZE_OTHER ? 'MAX_SIZE_MEDIA_25MB' : null;
    case 'video/avi': return event.size >= META_MAX_SIZE_OTHER ? 'MAX_SIZE_MEDIA_25MB' : null;
    case 'video/mov': return event.size >= META_MAX_SIZE_OTHER ? 'MAX_SIZE_MEDIA_25MB' : null;
    case 'video/webm': return event.size >= META_MAX_SIZE_OTHER ? 'MAX_SIZE_MEDIA_25MB' : null;

    case 'audio/mp4': return event.size >= META_MAX_SIZE_OTHER ? 'MAX_SIZE_MEDIA_25MB' : null;

    default: return 'UNSUPPORTED_MEDIA';
  }
};

export default {
  computed: {
    ...mapGetters({
      getterProgress: 'ApiUpload/getterProgress',
    }),
  },
  methods: {
    ...mapActions({
      getLink: 'ApiUpload/getLink',
      createLink: 'ApiUpload/createLink',
      upload: 'ApiUpload/upload',
    }),

    uploadFile(event, key, isMeta) {
      if (isMeta) {
        const error = allowedFile(event);
        if (error) return Promise.reject(error);
      }
      return this.createLink({ payload: { key, ContentType: event.type }, httpQuery: { bucket: 'ubu-profile' } })
        .then((uploadLink) => {
          console.log('🚀 ~ file: UploadFileMixin.js:16 ~ .then ~ uploadLink', uploadLink);
          // eslint-disable-next-line object-curly-newline
          return this.upload({ payload: { key, url: uploadLink, data: event, headers: { 'Content-Type': event.type } } });
        })
        .then(() => this.getLink({ payload: { key }, httpQuery: { bucket: 'ubu-profile' } }));
    },
  },
};
