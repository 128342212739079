import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    autoReportTask: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiPublication/autoReport/getterAutoReportTask': task } = rootGetters;

      return task;
    },
  },
  actions: {
    reportAutoReportTask({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiPublication/autoReport/report',
        context: {
          payload: { cgid: currentChannelGroup.cgid },
        },
        textNotifier: 'report auto report task list',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report auto report auto task.', indefinite: true }));
    },

    createAutoReportTask({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiPublication/autoReport/create',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'create auto report task list',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to create auto report task.', indefinite: true }));
    },

    deleteAutoReportTask({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiPublication/autoReport/delete',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'delete auto report task list',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to remove auto report task.', indefinite: true }));
    },
  },
};
