<template>
  <article :class="['mainPanelTable', { hasFilter: filters.length }]">
    <b-table
      ref="table"
      :data="_mappedAffiliationCodeList_"
      class="table-row-clickable table-full-height"
      hoverable
      sticky-header
      :loading="loading"
      :detail-key="detailKey"

      :selected.sync="selected"
      focusable

      paginated
      backend-pagination
      :total="_totalAffiliationCodeOverview"
      :per-page="perPage"
      pagination-size="is-small"

      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"

      @page-change="pageChange"
      @sort="onSort"
    >
      <b-table-column
        v-for="column in columns"
        :key="column.field"
        :field="column.field"
        :label="column.label"
        :sortable="column.isSortable"
        :visible="column.visible"
        :custom-sort="(a, b, isAsc) => customSort(a, b, isAsc, column)"
      >
        <template
          v-if="column.cmp === 'UbuTableCampaign'"
          #default="props"
        >
          <UbuCustomChip
            v-if="props.row.campaignCid"
            :model="{
              title: 'Campaign',
              defaultIcon: 'ubu-custom-influence',
              count: 1,
              listItem: [{
                icon: 'ubu-custom-influence',
                color: _campaignList[props.row.campaignCid].color,
                label: _campaignList[props.row.campaignCid].name,
                status: null,
              }],
            }"
          />

          <div
            v-else
            class="ubuCustomChip"
          >
            -
          </div>
        </template>

        <template
          v-else-if="column.cmp === 'UbuTablePercentage'"
          #default="props"
        >
          <span v-if="props.row.commissionRate">{{ props.row.commissionRate * 100 }}%</span>
          <b-tooltip v-else-if="_currentShop.commissionRate" label="Default shop commission">
            <span class="has-text-primary">{{ _currentShop.commissionRate * 100 }}%</span>
          </b-tooltip>
          <span v-else>-</span>
        </template>

        <template
          v-else-if="column.cmp === 'UbuTableDescription'"
          #default="props"
        >
          <span v-if="props.row.description" class="ellipsis">{{ props.row.description }}</span>
          <span v-else>-</span>
        </template>

        <template
          v-else-if="column.cmp === 'UbuTableAction'"
          #default="props"
        >
          <b-button
            v-if="editable"
            icon-pack="ubu"
            icon-left="ubu-edit"
            @click="$emit('editAffiliation', props.row)"
          />
        </template>

        <template
          v-else-if="column.cmp === 'UbuTableAffiliationFolder'"
          #default="props"
        >
          <div
            v-if="props.row.affiliationCodeFolderAcfid"
            class="ubuChip"
          >
            <b-icon
              pack="ubu"
              icon="ubu-folder"
            />
            {{ _affiliationCodeFolderList[props.row.affiliationCodeFolderAcfid].title }}
          </div>
          <div
            v-else
            class="ubuChip"
          >
            None
          </div>
        </template>

        <template
          v-else
          #default="props"
        >
          <component
            :is="column.cmp"
            :cpo="props.row"
            :field="column.field"
            :column="column"
            @selectContactPlatform="$emit('selectContactPlatform', $event)"
          />
        </template>
      </b-table-column>

      <template #empty>
        <p class="has-text-secondary">No coupons found!</p>
      </template>
    </b-table>
  </article>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';

export default {
  name: 'TheAffiliationCodeOverviewTable',
  mixins: [TableUtilsMixin],
  props: {
    filters: {
      type: Array,
      required: true,
    },
    allowedFilters: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // table
      detailKey: 'acid',
      perPage: 25,
      page: 1,
      selected: null,
      loading: false,
      sortField: 'affiliationCode.createdOn',
      sortOrder: 'desc',
      /* eslint-disable object-curly-newline */
      /* eslint-disable key-spacing */
      /* eslint-disable no-multi-spaces */
      columns: {
        action:                                       { cmp: 'UbuTableAction',                 field: 'action',                                     label: '',                isSortable: false, visible: true },
        'affiliationCode.code':                       { cmp: 'UbuTableSimple',                 field: 'affiliationCode.code',                       label: 'Code',            isSortable: true,  visible: true },
        'affiliationCode.type':                       { cmp: 'UbuTableSimple',                 field: 'affiliationCode.type',                       label: 'Type',            isSortable: true,  visible: true },
        totalOrders:                                  { cmp: 'UbuTableNumber',                 field: 'totalOrders',                                label: 'Total orders',    isSortable: true,  visible: true },
        totalRevenue:                                 { cmp: 'UbuTableNumberDecimalFormatter', field: 'totalRevenue',                               label: 'Total revenues',  isSortable: true,  visible: true, before: '$' },
        'affiliationCode.commissionRate':             { cmp: 'UbuTablePercentage',             field: 'affiliationCode.commissionRate',             label: 'Commission Rate', isSortable: false, visible: true },
        'shortLink.clickCount':                       { cmp: 'UbuTableNumber',                 field: 'shortLink.clickCount',                       label: 'Click count',     isSortable: true, visible: true },
        'contactPlatform.username':                   { cmp: 'UbuTableProfile',                field: 'contactPlatform.username',                   label: 'Username',        isSortable: true,  visible: true },
        'affiliationCode.campaignCid':                { cmp: 'UbuTableCampaign',               field: 'affiliationCode.campaignCid',                label: 'Campaign',        isSortable: false, visible: true },
        'affiliationCode.affiliationCodeFolderAcfid': { cmp: 'UbuTableAffiliationFolder',      field: 'affiliationCode.affiliationCodeFolderAcfid', label: 'Folder',          isSortable: true,  visible: true },
        'affiliationCode.createdOn':                  { cmp: 'UbuTableDate',                   field: 'affiliationCode.createdOn',                  label: 'Created on',      isSortable: true,  visible: true },
        'affiliationCode.description':                { cmp: 'UbuTableDescription',            field: 'affiliationCode.description',                label: 'Description',     isSortable: false, visible: true },
      },
      /* eslint-enable key-spacing */
      /* eslint-enable no-multi-spaces */
      /* eslint-enable object-curly-newline */
    };
  },
  computed: {
    ...mapGetters({
      _affiliationCodeOverviewList: 'TheAffiliationCodeOverviewTable/affiliationCodeOverviewList',
      _totalAffiliationCodeOverview: 'TheAffiliationCodeOverviewTable/totalAffiliationCodeOverview',
      _campaignList: 'TheAffiliationCodeOverviewTable/campaignList',
      _affiliationCodeFolderList: 'TheAffiliationCodeOverviewTable/affiliationCodeFolderList',
      _currentShop: 'TheAffiliationCodeOverviewTable/currentShop',
    }),

    // just to can use UbuTableProfile cmp
    _mappedAffiliationCodeList_() {
      return Object.values(this._affiliationCodeOverviewList).map((code) => ({
        ...code,
        contactPlatformStatistic: {},
      }));
    },

    selectedShopSid() {
      return this._currentShop.sid;
    },
  },
  watch: {
    filters() {
      return this.reportDatas();
    },

    selectedShopSid() {
      return this.reportDatas();
    },
  },
  mounted() {
    return this.reportDatas();
  },
  methods: {
    ...mapActions({
      reportAffiliationCodeOverviewList: 'TheAffiliationCodeOverviewTable/reportAffiliationCodeOverviewList',
      reportAffiliationCodeFolderList: 'TheAffiliationCodeOverviewTable/reportAffiliationCodeFolderList',
    }),

    reportDatas() {
      const filtersToUse = [...this.filters];

      return this.reportAffiliationCodeOverviewList({
        payload: { sid: this.selectedShopSid },
        httpQuery: {
          pagin: `${this.page - 1},${this.perPage}`,
          sorting: `${this.sortField},${this.sortOrder}`,
          filters: this.prepareFiltersToRequest(this.allowedFilters, filtersToUse),
        },
      });
    },
  },
};
</script>
