<template>
  <figure>
    <audio
      controls
      :src="url"
    >
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  </figure>
</template>

<script>
export default {
  name: 'UbuMediaAudio',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
};
</script>
