import { render, staticRenderFns } from "./UbuMediaVideo.vue?vue&type=template&id=2515c5bc&"
import script from "./UbuMediaVideo.vue?vue&type=script&lang=js&"
export * from "./UbuMediaVideo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports