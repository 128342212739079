<template>
  <UbuCustomChip
    :model="{
      title: 'Labels',
      defaultIcon: 'ubu-custom-contact',
      count: cpo[customsName].length > 1 ? cpo[customsName].length : null,
      listItem: cpo[customsName].map((id) => ({
        icon: 'ubu-custom-contact',
        color: customs[id].color,
        label: customs[id].label,
      }))
    }"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheLabelChip',
  props: {
    cpo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      customsName: 'labels',
    };
  },
  computed: {
    ...mapGetters({
      customs: 'TheLabelPopover/contactGroupList',
    }),
  },
};
</script>
