<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="buttons is-right">
      <b-button
        class="is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
        @click="$emit('closePanel')"
      />
    </div>

    <br>

    <UbuDownloadMentionsForm
      v-model="localEmail"
      :message="message"
      :mentions-length="mentionsLength"
      @download="$emit('download')"
      @setupIntercom="setupIntercom()"
      @closePanel="$emit('closePanel')"
    />
  </section>
</template>

<script>
export default {
  name: 'UbuDownloadMentions',
  model: {
    prop: 'email',
  },
  props: {
    email: {
      type: String,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    mentionsLength: {
      type: Number,
      // required: true,
      default: 0,
    },
  },
  computed: {
    localEmail: {
      get() { return this.email; },
      set(val) { this.$emit('input', val); },
    },
    title() {
      if (this.type === 'HASTAGS') return 'Where do you want to receive your medias?';
      return 'Where do you want to receive your mentions ?';
    },
    subtitle() {
      if (this.type === 'HASTAGS') return 'Download medias';
      return 'Download mentions';
    },
  },
  methods: {
    setupIntercom() {
      this.$intercom.showNewMessage('Hello ! I need to download all my mention but there are more than 1000... 🙌');
    },
  },
};
</script>
