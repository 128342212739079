/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {},
  getters: {
    ...getters.campaign,
    ...getters.activity,

    tabItemsForCampaignPeople: () => ({
      'root.changeStatus': {
        tab: 'root.changeStatus',
        title: 'Change status',
        subtext: 'Bulk actions',
      },
      'root.changeStatus.done': {
        tab: 'root.changeStatus.done',
        title: 'Success',
        subtext: 'Bulk actions',
      },
    }),
  },
  actions: {
    ...actions.bulkActivity,
  },
};
