<template>
  <!-- eslint-disable max-len -->
  <b-modal
    v-model="localOpenModal"
    :width="900"
    scroll="keep"
    class="UbuInfluenceMentionModal"
    @close="$emit('closeMedia')"
  >
    <b-button
      v-if="contentMedias.length > 1"
      class="carouselArrow"
      type="is-tertiary"
      icon-pack="ubu"
      icon-left="ubu-arrow-left"
      @click.stop="prev()"
    />
    <b-carousel
      v-click-outside="() => $emit('closeMedia')"
      :indicator="false"
      :autoplay="false"
      :arrow="false"
      animated="fade"
    >
      <b-carousel-item v-if="currentMedia">
        <div class="card is-flex">
          <div class="UbuInfluenceMentionModalMedia card-image">
            <UbuMediaImage
              v-if="currentMedia.mediaType === 'IMAGE' || currentMedia.mediaType === 'CAROUSEL_ALBUM'"
              :key="currentMedia.id"
              :url="currentMedia.mediaUrl"
              :src-fallback="srcFallback"
            />

            <UbuMediaVideo
              v-if="currentMedia.mediaType === 'VIDEO'"
              :key="currentMedia.id"
              :url="currentMedia.mediaUrl"
              :src-fallback="srcFallback"
            />
          </div>
          <div class="UbuInfluenceMentionModalContent card-content">
            <div
              class="media"
            >
              <div
                class="media-header"
              >
                <div
                  class="media-left"
                >
                  <UbuAvatar
                    :profile-picture="currentMedia.userPicture"
                    :initial-name="initialUsername(currentMedia)"
                  />
                  <div class="media-user">
                    <p class="text is-3">
                      {{ platform !== 'YOUTUBE' ? '@' : '' }}{{ currentMedia.username }}
                    </p>

                    <p class="subtext is-3 has-text-secondary">
                      {{ utilsNFormatter(currentMedia.followerCount, 1) }} followers
                    </p>
                  </div>
                </div>

                <div class="buttons gap-8">
                  <b-tooltip
                    label="Download"
                    position="is-bottom"
                  >
                    <b-button
                      tag="a"
                      :href="currentMediaDownloadUrl"
                      target="_blank"
                      download
                      type="is-tertiary"
                      class="mb-0"
                      @click="eventDownload()"
                    >
                      <b-icon
                        pack="ubu"
                        icon="ubu-download"
                        size="is-medium"
                      />
                    </b-button>
                  </b-tooltip>

                  <b-tooltip
                    label="View"
                    position="is-bottom"
                  >
                    <b-button
                      tag="a"
                      :href="
                        currentMedia.permalink ? currentMedia.permalink :
                        `https://www.${platform.toLowerCase()}.com/${currentMedia.username}/`
                      "
                      target="blank"
                      type="is-tertiary"
                      class="mb-0"
                    >
                      <b-icon
                        pack="ubu"
                        icon="ubu-link"
                        size="is-medium"
                      />
                    </b-button>
                  </b-tooltip>
                </div>
              </div>
            </div>

            <br>

            <DevDetails :cnt="{name: 'contentMedia', obj: currentMedia}" />
            <DevDetails :cnt="{name: 'formatAnalyticsInMedia', obj: formatAnalyticsInMedia}" />

            <div class="content subtext has-text-secondary subtext ">
              <div class="content-meta">
                <span>{{ $moment(currentMedia.date).format('YYYY-MM-DD') }}</span>
                <span>|</span>
                <span>{{ platform }}</span>
              </div>

              <br>

              <div
                v-if="currentMedia.caption"
                class="content-body"
              >
                <template v-if="!openText">
                  <p class="text is-3 has-text-secondary">
                    {{ truncateMediaText(currentMedia.caption) }}
                    <b-button
                      v-if="isTruncate"
                      label="Show more"
                      type="is-ghost is-borderless"
                      @click="openText = true"
                    />
                  </p>
                </template>

                <template v-else>
                  <p class="text is-3 has-text-secondary">
                    {{ currentMedia.caption }}
                    <b-button
                      v-if="isTruncate"
                      label="Show less"
                      type="is-ghost is-borderless"
                      @click="openText = false"
                    />
                  </p>
                </template>
              </div>

              <br>

              <div
                :class="`analyticsGrid col-2`"
              >
                <template v-for="(analytic, indexAnalytic) in formatAnalyticsInMedia">
                  <UbuAnalyticCard
                    v-if="analytic"
                    :key="indexAnalytic"
                    :name="analytic.name"
                    :value="analytic.value"
                    :icon="analytic.icon"
                    :color="analytic.color"
                    :symbol="analytic.symbol"
                    :growth-percent="analytic.growthPercent"
                    :last-periodvalue="analytic.lastPeriodvalue"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-item>
    </b-carousel>

    <b-button
      v-if="contentMedias.length > 1"
      class="carouselArrow"
      type="is-tertiary"
      icon-pack="ubu"
      icon-left="ubu-arrow-right"
      @click.stop="next()"
    />
  </b-modal>
</template>

<script>
import { nFormatter } from '@dailyplanet/utils/formate';
import { hashtagsAnalytics } from '../../$catalog/hashtags';

const srcFallback = require('@dailyplanet/assets/images/image-placeholder.png');

export default {
  name: 'UbuHashtagsViewMedia',
  model: {
    prop: 'openModal',
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    contentMedias: {
      type: Array,
      default: () => [],
    },
    index: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isTruncate: false,
      openText: false,
      length: 130,
      ending: '...',
      activeItem: 0,
      errorImg: false,
      srcFallback,
      hashtagsAnalytics,
    };
  },
  computed: {
    localOpenModal: {
      get() { return this.openModal; },
      set(newValue) { this.$emit('update', newValue); },
    },
    platform() {
      return this.currentMedia.platformName.capitalize();
    },
    formatAnalyticsInMedia() {
      if (!this.contentMedias[this.activeItem]) return [];

      const payload = [];

      const {
        prints, views, engagement, followerCount, commentCount, likeCount,
      } = this.contentMedias[this.activeItem];

      const printsAnalytics = {
        ...this.hashtagsAnalytics.prints,
        name: this.platform !== 'INSTAGRAM' ? 'Views' : 'Prints',
        value: this.platform !== 'INSTAGRAM' ? Number(views) : Number(prints) || null,
      };

      const likeCountAnalytics = {
        ...this.hashtagsAnalytics.likeCount,
        value: Number(likeCount) || null,
      };

      const commentCountAnalytics = {
        ...this.hashtagsAnalytics.commentCount,
        value: Number(commentCount) || null,
      };

      const engagementAnalytics = {
        ...this.hashtagsAnalytics.engagement,
        value: Number(engagement) || null,
      };

      const followersAnalytics = {
        ...this.hashtagsAnalytics.followerCount,
        value: Number(followerCount) || null,
      };

      payload.push(
        followersAnalytics,
        engagementAnalytics,
        likeCountAnalytics,
        commentCountAnalytics,
        printsAnalytics,
      );

      return payload.filter((stat) => stat && stat.value);
    },
    currentMedia() {
      return this.contentMedias[this.activeItem];
    },
    currentMediaDownloadUrl() {
      if (!this.currentMedia) return '';
      if (this.currentMedia.carousel && this.currentMedia.carousel.length) {
        return `${this.currentMedia.carousel[0].url}?download=true`;
      }
      return `${this.currentMedia.mediaUrl}?download=true`;
    },
  },
  mounted() {
    this.findByView();
  },
  methods: {
    prev() {
      if (this.activeItem === 0) this.activeItem = this.contentMedias.length - 1;
      else this.activeItem -= 1;
      this.openText = false;
      this.isTruncate = false;
    },
    next() {
      if (this.activeItem === this.contentMedias.length - 1) this.activeItem = 0;
      else this.activeItem += 1;
      this.openText = false;
      this.isTruncate = false;
    },
    truncateMediaText(content) {
      if (content.length > this.length) {
        this.isTruncate = true;
        return content.substring(0, this.length - this.ending.length) + this.ending;
      }
      return content;
    },
    initialUsername(contentMedia) {
      return contentMedia.username.charAt(0).capitalize();
    },
    findByView() {
      this.activeItem = this.contentMedias.findIndex((element) => element.id === this.index);
    },
    utilsNFormatter(num, digit) {
      return nFormatter(num, digit);
    },
  },
};
</script>
