import campaignMention from './campaignMention.store';
import activityMention from './activityMention.store';
import favorite from './favorite.store';
import autoReport from './autoReport.store';
import downloadMention from './downloadMention.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    campaignMention,
    activityMention,
    favorite,
    autoReport,
    downloadMention,
  },
};
