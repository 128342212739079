<template>
  <div
    class="ubuSimpleTextarea"
    :style="`width: ${width}`"
  >
    <textarea-autosize
      ref="textarea"
      v-model="localValue"
      class="text is-3"
      :placeholder="placeholder"
      :maxlength="maxLength"
    />
    <div
      v-if="maxLength"
      class="multibar"
    >
      <p
        :class="['ubuTextarea_counter end text is-3', {
          'has-text-secondary': localValue.length < maxLength - 50,
          'has-text-warning': localValue.length >= maxLength - 25,
          'has-text-danger': localValue.length >= maxLength - 10,
          'hasReachedLimit': localValue.length === maxLength,
        }]"
      >
        {{ localValue.length }} / {{ maxLength }}
      </p>
    </div>
    <div class="multibar row-vcentered">
      <b-dropdown
        :position="emojiPickerPosition"
        append-to-body
        class="start"
      >
        <template #trigger>
          <b-button
            icon-left="ubu-emoji"
            icon-pack="ubu"
            type="is-tertiary-icon"
          />
        </template>

        <b-dropdown-item
          custom
          paddingless
        >
          <VEmojiPicker
            v-click-outside="() => showEmojiPicker = false"
            :dark="isDark"
            :show-search="false"
            :emoji-size="24"
            :emojis-by-row="6"
            @select="selectEmoji($event)"
          />
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';
import ThemeMixin from '@dailyplanet/mixins/ThemeMixin';

export default {
  name: 'UbuSimpleTextarea',
  components: {
    VEmojiPicker,
  },
  mixins: [ThemeMixin],
  model: {
    prop: 'value',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: null,
    },
    width: {
      type: String,
      default: '100%',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    emojiPickerPosition: {
      type: String,
      default: 'is-bottom-left',
    },
  },
  data() {
    return {
      showEmojiPicker: false,
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(newValue) { this.$emit('input', newValue || ''); },
    },
  },
  mounted() {
    if (this.autofocus) this.$refs.textarea.$el.focus();
  },
  methods: {
    selectEmoji(emoji) {
      if (this.localValue.length + emoji.data.length > 1000) return;
      const templateArray = this.localValue.split('');
      const pos = this.$refs.textarea.$el.selectionStart;
      templateArray.splice(pos, 0, emoji.data);
      this.localValue = templateArray.join('');
      this.$nextTick(() => {
        this.$refs.textarea.$el.focus();
        this.$refs.textarea.$el.selectionStart = pos + emoji.data.length;
        this.$refs.textarea.$el.selectionEnd = pos + emoji.data.length;
      });
      this.showEmojiPicker = false;
    },
  },
};
</script>
