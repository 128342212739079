import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

import thread from './thread.store';
import connectPrivate from './connectPrivate.store';
import conversation from './conversation.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    create({ rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'CHANNEL.CREATE')) return undefined;

      return clark.api.channel.post({ payload, httpQuery: {} })
        .then((campaign) => { console.log(campaign); });
    },
  },
  modules: {
    thread,
    connectPrivate,
    conversation,
  },
};
