<template>
  <section>
    <DevDetails :cnt="{name: 'item', obj: item}" />
    <UbuNote
      :key="noteItem.lid"
      :text="noteItem.text"
      :text-truncate="noteItem.textTruncate"
      :ubu-user="noteItem.user || {}"
      :date="noteItem.noteDate"
      :router-link="noteItem.routerLink"
      :platform="noteItem.platformName"
      :is-private-note="noteItem.isPrivateNote"
      :position="noteItem.position"
      :type="item.type ? item.type : ''"
      :window-width="windowWidth"
      @openModalNote="$emit('openModalNote', item)"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */
export default {
  name: 'UbuNoteFilter',
  props: {
    item: {
      type: Object,
      required: true,
    },
    userList: {
      type: Object,
      default: () => {},
    },
    campaignList: {
      type: Object,
      required: true,
    },
    windowWidth: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    nameAssigned() {
      if (!Object.values(this.userList).length) return 'unknown user';
      const user = this.userList[this.item.text];
      if (!user) return '';
      const { firstName, lastName } = user.userDetail;
      return `${firstName} ${lastName}`;
    },
    renemaStatus() {
      return this.item.text.split('_').map((el) => el.capitalize()).join(' ');
    },
    campaignNameShortener() {
      const { name } = this.campaignList[this.item.campaignCid];

      // if (name.length > 10) return `${name.substr(0, 10)}...`;
      return name.capitalize();
    },
    usernameShortener() {
      // const { windowWidth } = this;
      if (!this.item.contactPlatform) return '';

      const { username } = this.item.contactPlatform;

      // if (username.length > 15) {
      //   if (windowWidth <= 1700) return `${username.substr(0, 15)}...`;
      //   if (windowWidth <= 1468) return `${username.substr(0, 8)}...`;
      // }
      return username;
    },
    contactLabelShortener() {
      // const { windowWidth } = this;
      const { label } = this.item.contactGroup;

      // if (label.length > 15) {
      //   if (windowWidth > 1469) return `${label.substr(0, 15)}...`;
      //   if (windowWidth <= 1468) return `${label.substr(0, 8)}...`;
      // }
      return label;
    },
    noteItem() {
      let noteItem = {
        ...this.item,
        platformName: this.item.contactPlatform ? this.item.contactPlatform.platformName : null,
        isPrivateNote: true,
        position: 'left',
      };
      switch (this.item.event) {
        case 'THREAD_READ':
          noteItem = {
            ...this.item,
            text: 'Thread read',
            isPrivateNote: false,
          };
          break;
        case 'THREAD_UNREAD':
          noteItem = {
            ...this.item,
            text: 'Thread unread',
            isPrivateNote: false,
          };
          break;
        case 'THREAD_OPENED':
          noteItem = {
            ...this.item,
            text: 'Thread opened',
            isPrivateNote: false,
          };
          break;
        case 'THREAD_CLOSED':
          noteItem = {
            ...this.item,
            text: 'Thread closed',
            isPrivateNote: false,
          };
          break;
        case 'THREAD_DONE':
          noteItem = {
            ...this.item,
            text: 'Thread done',
            isPrivateNote: false,
          };
          break;
        case 'THREAD_GROUP_ASSIGN':
          noteItem = {
            ...this.item,
            text: 'Added tag',
            routerLink: { to: '/setting/tag', text: `${this.item.threadGroup.label}` },
            isPrivateNote: false,
          };
          break;
        case 'THREAD_GROUP_UNASSIGN':
          noteItem = {
            ...this.item,
            text: 'Removed tag',
            routerLink: { to: '/setting/tag', text: `${this.item.threadGroup.label}` },
            isPrivateNote: false,
          };
          break;
        case 'CONTACT_GROUP_ASSIGN':
          noteItem = {
            ...this.item,
            text: 'Added label',
            routerLink: { to: '/setting/label', text: `${this.contactLabelShortener}` },
            isPrivateNote: false,
          };
          break;
        case 'CONTACT_GROUP_UNASSIGN':
          noteItem = {
            ...this.item,
            text: 'Removed label',
            routerLink: { to: '/setting/label', text: `${this.contactLabelShortener}` },
            isPrivateNote: false,
          };
          break;
        case 'CRM_LIST_ASSIGN':
          noteItem = {
            ...this.item,
            text: 'Added to list',
            routerLink: { to: '/crm', text: `${this.item.crmList.name}` },
            isPrivateNote: false,
          };
          break;
        case 'CRM_LIST_UNASSIGN':
          noteItem = {
            ...this.item,
            text: 'Removed from list',
            routerLink: { to: '/crm', text: `${this.item.crmList.name}` },
            isPrivateNote: false,
          };
          break;
        case 'THREAD_APPOINTMENT':
          noteItem = {
            ...this.item,
            text: 'Assigned',
            routerLink: { to: '/setting/member', text: this.nameAssigned },
            isPrivateNote: false,
          };
          break;
        case 'THREAD_UNAPPOINTMENT':
          noteItem = {
            ...this.item,
            text: 'Unassigned',
            position: 'right',
            routerLink: { to: '/setting/member', text: this.nameAssigned },
            isPrivateNote: false,
          };
          break;
        case 'ACTIVITY_UPDATE':
          noteItem = {
            ...this.item,
            text: `Set campaign status to ${this.renemaStatus}`,
            routerLink: { to: `/influence/${this.item.campaignCid}/people`, text: `${this.campaignNameShortener}` },
            isPrivateNote: false,
          };
          break;
        case 'ACTIVITY_END':
          noteItem = {
            ...this.item,
            text: 'Removed from campaign',
            routerLink: { to: `/influence/${this.item.campaignCid}/people`, text: `${this.campaignNameShortener}` },
            isPrivateNote: false,
          };
          break;
        case 'ACTIVITY_CREATE':
          noteItem = {
            ...this.item,
            text: 'Added to campaign',
            routerLink: { to: `/influence/${this.item.campaignCid}/people`, text: `${this.campaignNameShortener}` },
            isPrivateNote: false,
          };
          break;
        case 'THREAD_AUTO_CLOSED':
          noteItem = {
            ...this.item,
            text: 'Thread auto-closed',
            isPrivateNote: false,
          };
          break;
        case 'THREAD_AUTO_DONE':
          noteItem = {
            ...this.item,
            text: 'Thread auto-done',
            isPrivateNote: false,
          };
          break;
        case 'THREAD_MUTED':
          noteItem = {
            ...this.item,
            text: 'Thread muted',
            isPrivateNote: false,
          };
          break;
        case 'THREAD_UNMUTED':
          noteItem = {
            ...this.item,
            text: 'Thread unmuted',
            isPrivateNote: false,
          };
          break;
        default:
          noteItem = {
            ...this.item,
            platformName: this.item.contactPlatform ? this.item.contactPlatform.platformName : null,
            isPrivateNote: true,
          };
          break;
      }
      return noteItem;
    },
  },
  methods: {
    onScroll(startIndex, endIndex) {
      this.$emit('onScroll', { startIndex, endIndex });
    },
  },
};
</script>
