import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    crewAdminList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiCrew/crewAdmin/getterCrewAdminList': crewAdminList } = rootGetters;
      return crewAdminList;
    },
    crewAdminTotal: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiCrew/crewAdmin/getterCrewAdminTotal': total } = rootGetters;

      return total;
    },
    crewUserStateEnumList() {
      return [
        { label: 'Finalized', value: 'VERIFIED' },
        { label: 'Registered', value: 'PENDING' },
        { label: 'Link sent', value: 'CREATED' },
        { label: 'Blocked', value: 'BLOCKED' },
      ];
    },
  },
  actions: {
    reportCrewAdmin({ dispatch }, { payload = {}, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiCrew/crewAdmin/report',
        context: { payload, httpQuery },
        textNotifier: 'Report crew admin list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report crew admin.', indefinite: true }));
    },

    updateStateCrewUser({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'ApiCrew/crewAdmin/update',
        context: {
          payload: {
            ...payload,
          },
        },
        textNotifier: 'crewUser is successfully blocked.',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to blocked Crew user.', indefinite: true }));
    },

  },
};
