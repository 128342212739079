<template>
  <div>
    <b-loading
      v-model="isImageLoading"
      :is-full-page="false"
    />
    <figure class="image">
      <b-image
        :src="url"
        :alt="alt"
        :placeholder="placeholder"
        :src-fallback="srcFallback"
        lazy
        @load="isImageLoading = false"
        @error="isImageLoading = false"
      />
    </figure>
  </div>
</template>

<script>
export default {
  name: 'UbuMediaImage',
  props: {
    url: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    srcFallback: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isImageLoading: true,
    };
  },
};
</script>
