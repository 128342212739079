/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '@dailyplanet/providers/engine';

const target = 'clark';

const tribe = {
  get: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/tribe/${payload.tid}`, method: 'GET', payload, httpQuery, target }),
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/tribe`, method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/tribe`, method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/tribe/${payload.tid}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/tribe/${payload.tid}`, method: 'DELETE', payload, httpQuery, target }),
  addFromCustom: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/tribe/${payload.tid}/addFromCustom`, method: 'POST', payload, httpQuery, target }),
};

export default tribe;
