<template>
  <div class="ubuInputTextareaContainer">
    <div class="multibar column p-0">
      <div class="start multibar">
        <textarea-autosize
          v-model="localText"
          :class="`start ubuInput ubuInputTextarea ${customClass} text is-3`"
          :placeholder="placeholder"
          :readonly="readonly"
          :maxlength="maxlength"
          @keydown.native="keydownTextAreaHandler($event)"
        />

        <b-icon
          v-if="hasChanges"
          pack="ubu"
          :icon="iconRight"
          class="end clickable"
          @click.native="$emit('send', myText)"
        />
      </div>
      <p
        v-if="myText && maxlength"
        v-show="myText && myText.length >= Number(maxlength - (maxlength / 10))"
        class="end counter disclaimer is-3 has-text-secondary has-text-right"
      >
        {{ myText.length }} / {{ maxlength }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuSingleTextarea',
  model: {
    prop: 'text',
  },
  props: {
    text: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: 'ubu-send',
    },
    maxlength: {
      type: [Number, String],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myText: '',
    };
  },
  computed: {
    hasChanges() {
      if (!this.text && (!this.myText || !this.myText.length)) return false;
      return this.myText !== this.text;
    },
    localText: {
      get() {
        return this.text;
      },
      set(newValue) { this.myText = newValue; },
    },
    isMac() {
      return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    },
  },
  mounted() {
    this.myText = this.text;
  },
  methods: {
    keydownTextAreaHandler(event) {
      const ctrlPressed = this.isMac ? event.metaKey : event.ctrlKey;
      const ctrlEnterPressed = ctrlPressed && event.keyCode === 13;
      const ctrlShiftEnterPressed = ctrlEnterPressed && event.shiftKey;
      // const escPressed = event.keyCode === 27;

      if (ctrlEnterPressed && !event.shiftKey && this.hasChanges) return this.$emit('send', this.myText);
      if (!ctrlShiftEnterPressed) return undefined;

      return undefined;
    },
  },
};
</script>
