<template>
  <TheTabsPanel
    v-model="localActiveTab"
    @goToPreviousTab="goToPreviousTab()"
    @goToStart="goToTabByTabPath('root')"
    @close="closePanel()"
  >
    <template v-slot:content>
      <b-tab-item value="root">
        <article>
          <div class="buttons">
            <UbuActionCard
              v-for="item in navigableItems"
              :key="item.label"
              class="mb-4"
              :label="item.label"
              icon-pack="ubu"
              :icon-left="item.icon"
              expanded
              :disabled="item.isDisabled"
              @click="handleNavigation(item)"
            >
              <template
                v-if="item.to === 'root.askUgc'"
                #rightItem
              >
                <b-taglist class="end">
                  <b-tag type="is-primary">Beta</b-tag>
                </b-taglist>
              </template>
            </UbuActionCard>
            <UbuActionCard
              label="Add these mentions to favorites"
              icon-pack="ubu"
              icon-left="ubu-important"
              expanded
              @click="bulkAddFav()"
            >
              <template #rightItem>
                <b-taglist class="end">
                  <b-tag type="is-primary">Beta</b-tag>
                </b-taglist>
              </template>
            </UbuActionCard>
          </div>
        </article>
      </b-tab-item>

      <b-tab-item value="root.downloadMentions">
        <TheDownloadMentions
          v-model="mentionExtrefIdList"
          @done="handleSuccessDownload($event)"
        />
      </b-tab-item>

      <b-tab-item value="root.label">
        <TheBulkAssignLabelButtons v-model="localChecked" />
      </b-tab-item>

      <TheAskUgcPermission
        v-model="contactPlatformList"
        :active-tab.sync="localActiveTab"
        :tab-items.sync="tabItems"
      />
    </template>
  </TheTabsPanel>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheMentionBulk',
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      accountFeatures: 'TheMention/accountFeatures',
      activeTab: 'TheMentionBulk/activeTab',
      _checkedRows: 'TheMentionBulk/checkedRows',
      tabItems: 'TheMentionBulk/tabItems',
      mentionExtrefIdList: 'TheMentionBulk/mentionExtrefIdList',
      contactPlatformList: 'TheMentionBulk/contactPlatformList',
    }),
    localActiveTab: {
      get() { return this.activeTab; },
      set(newValue) { this.UP_activeTab(newValue); },
    },
    localChecked: {
      get() { return this._checkedRows; },
      set(newValue) {
        this.UP_checkedRows(newValue);
        this.$emit('input', newValue);
      },
    },
    navigableItems() {
      return {
        'root.downloadMentions': {
          label: 'Download mentions',
          to: 'root.downloadMentions',
          icon: 'ubu-download',
          requiredFeature: 'UBU_META_EXTENSION',
          segmentEvent: 'downloadMentionNoFeature',
        },
        'root.askUgc': {
          label: 'Request usage rights',
          to: 'root.askUgc',
          icon: 'ubu-in-progress',
        },
        'root.label': {
          label: 'Add to contact label',
          to: 'root.label',
          icon: 'ubu-contact',
        },
      };
    },
  },
  watch: {
    checkedRows() {
      this.UP_checkedRows(this.checkedRows);
    },
  },
  mounted() {
    return this.UP_checkedRows(this.checkedRows);
  },
  methods: {
    ...mapActions({
      UP_checkedRows: 'TheMentionBulk/UP_checkedRows',
      UP_activeTab: 'TheMentionBulk/UP_activeTab',
      UP_resetStore: 'TheMentionBulk/UP_resetStore',
      goToPreviousTab: 'TheMentionBulk/goToPreviousTab',
      goToTabByTabPath: 'TheMentionBulk/goToTabByTabPath',
      _favMentions: 'TheMention/favMentions',
      emitSegmentEvent: 'TheMention/emitSegmentEvent',
    }),
    closePanel() {
      this.UP_resetStore();
      this.$emit('close');
    },
    handleNavigation(item) {
      if (item.requiredFeature && !this.accountFeatures(item.requiredFeature)) {
        this.emitSegmentEvent({ event: item.segmentEvent });
        this.$intercom.showNewMessage('Hi! I would like to activate the Ubu\'s Meta Extension! 🙌');

        return;
      }

      this.UP_activeTab(this.tabItems[item.to]);
    },
    handleSuccessDownload() {
      this.goToTabByTabPath('root');
    },
    notifySuccessFav(count) {
      this.goToTabByTabPath('root');
      const message = count === 0 ? 'All mentions were added to favorites' : `${count} mentions were added to favorites`;
      return this.$buefy.snackbar.open({
        message,
        type: 'is-success',
        position: 'is-top',
      });
    },
    bulkAddFav() {
      const publicationsToFav = this.localChecked
        .flatMap(({ mentions }) => mentions)
        .filter(({ isFav }) => !isFav);

      this._favMentions({ payload: { pids: publicationsToFav.map(({ publicationPid }) => publicationPid) } })
        .then(() => this.notifySuccessFav(publicationsToFav.length));
    },
  },
};
</script>
