import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheAddPeopleManually from './smarts/TheAddPeopleManually';
import TheAddPeopleProto from './smarts/TheAddPeopleProto';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheAddPeopleManually);
    registerComponent(Vue, TheAddPeopleProto);
  },
};

use(Plugin);

export default Plugin;

export {
  TheAddPeopleManually,
  TheAddPeopleProto,
};
