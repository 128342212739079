/* eslint-disable no-multi-spaces */
import TheUsersUserDetailsPanelEditName from './views/Settings/TheUsersUserDetailsPanelEditName';
import TheUsersUserDetailsPanelEditEmail from './views/Settings/TheUsersUserDetailsPanelEditEmail';
import TheUsersUserDetailsPanelEditPassword from './views/Settings/TheUsersUserDetailsPanelEditPassword';
import TheUsersUserDetailsPanelEditPhone from './views/Settings/TheUsersUserDetailsPanelEditPhone';

import TheUsers from './TheUsers';

import TheUsersAllUsers from './views/TheUsersAllUsers';
import TheUsersAddUser from './views/TheUsersAddUser';
import TheUsersUser from './views/TheUsersUser';
import TheUsersUserAccounts from './views/TheUsersUserAccounts';
import TheUsersUserDetails from './views/TheUsersUserDetails';
// import TheUsersUserThemes from './views/TheUsersUserThemes';
import TheUsersUserRole from './views/TheUsersUserRole';

export default [
  {
    path: '/users',
    redirect: '/users/all-users',
  },
  {
    path: '/users',
    name: 'Users',
    component: TheUsers,
    meta: {
      title: 'Users',
      hasMenu: true,
      viewStoreNavigation: 'TheUsers',
      requireAuth: true,
    },
    children: [
      {
        path: '/users/all-users',
        name: 'Users - All Users',
        components: { allUsers: TheUsersAllUsers },
        meta: {
          title: 'Users',
          hasMenu: true,
          viewStoreNavigation: 'TheUsers',
          requireAuth: true,
        },
      },
      {
        path: '/users/add-user',
        name: 'Users - Add User',
        components: { addUser: TheUsersAddUser },
        meta: {
          title: 'Users',
          hasMenu: true,
          viewStoreNavigation: 'TheUsers',
          requireAuth: true,
        },
      },
      {
        path: '/users/user/:uid/',
        name: 'Users - User',
        components: { usersUser: TheUsersUser },
        meta: {
          title: 'Users',
          hasMenu: true,
          viewStoreNavigation: 'TheUsers',
          requireAuth: true,
        },
        children: [
          {
            path: '/users/user/:uid/details',
            name: 'Users - User - Details',
            component: TheUsersUserDetails,
            meta: {
              title: 'Admin',
              hasMenu: true,
              viewStoreNavigation: 'TheUsers',
              requireAuth: true,
            },
            children: [
              {
                path: '/users/user/:uid/details/editName',
                name: 'Users - User - Details - Edit Name',
                components: { editName: TheUsersUserDetailsPanelEditName },
                meta: {
                  title: 'Users',
                  hasMenu: true,
                  viewStoreNavigation: 'TheUsers',
                  requireAuth: true,
                },
              },
              {
                path: '/users/user/:uid/details/editEmail',
                name: 'Users - User - Details - Edit Email',
                components: { editEmail: TheUsersUserDetailsPanelEditEmail },
                meta: {
                  title: 'Users',
                  hasMenu: true,
                  viewStoreNavigation: 'TheUsers',
                  requireAuth: true,
                },
              },
              {
                path: '/users/user/:uid/details/editPassword',
                name: 'Users - User - Details - Edit Password',
                components: { editPassword: TheUsersUserDetailsPanelEditPassword },
                meta: {
                  title: 'Users',
                  hasMenu: true,
                  viewStoreNavigation: 'TheUsers',
                  requireAuth: true,
                },
              },
              {
                path: '/users/user/:uid/details/editPhone',
                name: 'Users - User - Details - Edit Phone',
                components: { editPhone: TheUsersUserDetailsPanelEditPhone },
                meta: {
                  title: 'Users',
                  hasMenu: true,
                  viewStoreNavigation: 'TheUsers',
                  requireAuth: true,
                },
              },
            ],
          },
          {
            path: '/users/user/:uid/accounts',
            name: 'Users - User - Accounts',
            component: TheUsersUserAccounts,
            meta: {
              title: 'Admin',
              hasMenu: true,
              viewStoreNavigation: 'TheUsers',
              requireAuth: true,
            },
          },
          // {
          //   path: '/users/user/:uid/themes',
          //   name: 'Users - User - Themes',
          //   component: TheUsersUserThemes,
          //   meta: {
          //     title: 'Admin',
          //     hasMenu: true,
          //     viewStoreNavigation: 'TheUsers',
          //     requireAuth: true,
          //   },
          // },
          {
            path: '/users/user/:uid/role',
            name: 'Users - User - Role',
            component: TheUsersUserRole,
            meta: {
              title: 'Admin',
              hasMenu: true,
              viewStoreNavigation: 'TheUsers',
              requireAuth: true,
            },
          },
        ],
      },
    ],
  },
];
