<template>
  <div>
    <b-tag
      v-if="cpo && cpo.crewUser && humanizeState"
      :type="humanizeState.type"
    >
      {{ humanizeState.label }}
    </b-tag>

    <p v-else>-</p>
  </div>
</template>

<script>
export default {
  name: 'UbuTableCrewState',
  props: {
    cpo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    humanizeState() {
      const { state } = this.cpo.crewUser;

      if (state === 'VERIFIED') return { type: 'is-success', label: 'Finalized' };
      if (state === 'PENDING') return { type: 'is-info', label: 'Registered' };
      if (state === 'CREATED') return { type: 'is-warning', label: 'Link sent' };
      if (state === 'BLOCKED') return { type: 'is-danger', label: 'Blocked' };

      return null;
    },
  },
};
</script>
