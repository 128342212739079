<template>
  <TheTribeChip
    v-if="cpo.tribeMemberList && cpo.tribeMemberList.length"
    :cpo="cpo"
  />

  <div
    v-else
    class="ubuCustomChip"
  >
    -
  </div>
</template>

<script>
export default {
  name: 'UbuTableChipTribe',
  props: {
    cpo: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
