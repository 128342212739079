import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  actions: {
    post(_, { payload, httpQuery = {} }) {
      return clark.api.apiConversation.instagramReach.post({ payload, httpQuery });
    },
  },
};
