<template>
  <TheTabsPanel
    v-model="localActiveTab"
    @goToPreviousTab="goToPreviousTab()"
    @goToStart="goToTabByTabPath('root')"
    @close="closePanel()"
  >
    <template #content>
      <b-tab-item value="root">
        <article>
          <div class="buttons">
            <UbuActionCard
              label="Approve these rewards"
              icon-pack="ubu"
              icon-left="ubu-thumb"
              expanded
              class="mb-4"
              @click="setReviews()"
            />
          </div>
        </article>
      </b-tab-item>
    </template>
  </TheTabsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheRewardBulkPanel',
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      activeTab: 'TheRewardBulkPanel/activeTab',
      _checkedRows: 'TheRewardBulkPanel/checkedRows',
      tabItems: 'TheRewardBulkPanel/tabItems',
    }),

    localActiveTab: {
      get() { return this.activeTab; },
      set(newValue) { this.UP_activeTab(newValue); },
    },

    localChecked: {
      get() { return this._checkedRows; },
      set(newValue) {
        this.UP_checkedRows(newValue);
        this.$emit('input', newValue);
      },
    },
  },
  watch: {
    checkedRows() {
      this.UP_checkedRows(this.checkedRows);
    },
  },
  mounted() {
    return Promise.resolve()
      .then(() => this.UP_resetStore())
      .then(() => this.UP_checkedRows(this.checkedRows));
  },
  methods: {
    ...mapActions({
      UP_checkedRows: 'TheRewardBulkPanel/UP_checkedRows',
      UP_activeTab: 'TheRewardBulkPanel/UP_activeTab',
      UP_resetStore: 'TheRewardBulkPanel/UP_resetStore',
      goToPreviousTab: 'TheRewardBulkPanel/goToPreviousTab',
      goToTabByTabPath: 'TheRewardBulkPanel/goToTabByTabPath',
      // emitSegmentEvent: 'TheMention/emitSegmentEvent',
      UP_resetNavigation: 'TheRewardBulkPanel/UP_resetNavigation',
      goToPreviousVisitedTab: 'TheRewardBulkPanel/goToPreviousVisitedTab',
      UP_changeToNewTab: 'TheRewardBulkPanel/UP_changeToNewTab',

      _reviewAffiliationReward: 'TheCrew/reviewAffiliationReward',
    }),

    closePanel() {
      this.UP_resetStore();
      this.$emit('close');
    },

    handleNavigation(item) {
      this.UP_activeTab(this.tabItems[item.to]);
    },

    setReviews() {
      const rewards = Object.values(this.localChecked)
        .filter(({ reviewedOn }) => reviewedOn === null);

      if (!rewards.length) {
        this.$buefy.snackbar.open({
          ...this.$snackBarSuccess,
          message: 'All rewards are already approved.',
        });
        return undefined;
      }

      return this._reviewAffiliationReward({
        payload: { arids: rewards.map(({ arid }) => arid) },
      })
        .then(() => {
          this.$buefy.snackbar.open({
            ...this.$snackBarSuccess,
            message: 'All rewards are successfully approved.',
          });
        });
    },
  },
};
</script>
