import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    pingShop: null,
  },
  mutations: {
    SET_PING_SHOP(state, newData) { state.pingShop = newData; },
  },
  getters: {
    getterPingShop: (state) => state.pingShop,
  },
  actions: {
    get({ commit }, { payload = {}, httpQuery = {} }) {
      return clark.api.apiPlatform.pingShop.get({ payload, httpQuery })
        .then(() => {
          commit('SET_PING_SHOP', { ssid: payload.ssid, connected: true });
          return true;
        })
        .catch(() => {
          commit('SET_PING_SHOP', { ssid: payload.ssid, connected: false });
          return false;
        });
    },
  },
};
