import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      languagesList: 'Geolocation/getterLanguages',
    }),

    languagesLongNameList() {
      return this.languagesList.map(({ display }) => display);
    },

    languagesShortNameList() {
      return this.languagesList.map(({ value }) => value);
    },

    // scrapingZoneNameList() {
    //   return this.scrapingZoneList.map(({ szid }) => szid);
    // },

    filteredZonePreset() {
      if (!this.zoneFilter) return undefined;

      return this.zoneFilter.preset.filter((option) => option
        .toString()
        .toLowerCase()
        .indexOf(this.zoneFilter.value.toLowerCase()) >= 0);
    },

    filteredLanguagePreset() {
      if (!this.languageFilter) return undefined;

      return this.languagesLongNameList.filter((option) => option
        .toString()
        .toLowerCase()
        .indexOf(this.languageFilter.value.toLowerCase()) >= 0);
    },

    filteredScrapinZonePreset() {
      if (!this.scrapingZoneFilter) return undefined;
      // console.log('🚀 ~ file: GeoMixin.js ~ line 46 ~ filteredScrapinZonePreset ~ this.scrapingZoneList', this.scrapingZoneList);

      return this.scrapingZoneList.filter((option) => option
        .toString()
        .toLowerCase()
        .indexOf(this.scrapingZoneFilter.value.toLowerCase()) >= 0);
    },
    selectedScrapingZoneExists() {
      return !!this.scrapingZoneList.find((option) => option === this.scrapingZoneFilter.value);
    },
  },

  methods: {
    findShortNameFromLongName(longName) {
      if (!longName) return longName;
      const language = this.languagesList
        .find(({ display }) => display.toLowerCase() === longName.toLowerCase());
      return language ? language.value : longName;
    },
  },
};
