<template>
  <form @submit.prevent="upsertAffiliation()">
    <fieldset class="is-bordered">
      <h3 class="subtitle is-3">Type</h3>

      <b-field v-for="(type) in affilationCodeType" :key="type.value">
        <b-radio
          v-model="payload.type"
          :native-value="type.value"
          @input="changeCodeType()"
        >
          {{ type.label }}
        </b-radio>
      </b-field>
    </fieldset>

    <br>

    <fieldset class="is-bordered">
      <h3 class="subtitle is-3">Code</h3>

      <b-field grouped>
        <b-field label="Folder">
          <TheAffiliationCodeFolderDropdown v-model="payload.affiliationCodeFolderAcfid" />
        </b-field>

        <b-field v-if="payload.type === 'DISCOUNT'" label="Discount code">
          <TheDiscountCodeDropdown
            v-if="payload.affiliationCodeFolderAcfid"
            v-model="selectedDiscount"
            :selected-affiliation-code-folder-acfid="payload.affiliationCodeFolderAcfid"
            @input="selectDiscountCode()"
          />
        </b-field>

        <b-field v-if="payload.type === 'REFERRAL'" label="Referral code" expanded>
          <b-input
            v-model="referralCode"
            expanded
            placeholder="ellamiller"
            required
            @input="setReferralCode()"
          />
        </b-field>
      </b-field>

      <br>

      <b-field label="Coupon description">
        <b-input
          v-model="payload.description"
          type="textarea"
          placeholder="10% Off for each order over $50"
          maxlength="200"
        />
      </b-field>
    </fieldset>

    <br>

    <fieldset class="is-bordered">
      <h3 class="subtitle is-3">Assign</h3>

      <b-field label="Influencer (optional)">
        <TheSearchContactPlatformDropdown
          v-model="selectedContactPlatform"
          @input="selectContactPlatform($event)"
        />
      </b-field>

      <br>

      <b-field label="Campaign (optional)">
        <TheActiveCampaignDropdown
          v-model="selectedCampaign"
          @input="selectCampaign($event)"
        />
      </b-field>
    </fieldset>

    <br>

    <fieldset class="is-bordered">
      <h3 class="subtitle is-3">Link</h3>

      <b-field label="Destination url">
        <b-input
          v-model="baseUrl"
          placeholder="https://my-website.com/"
          @input="debouncedBuildLink()"
        />
      </b-field>

      <br>

      <b-field label="Url parameters (optional)">
        <b-input
          v-model="params"
          placeholder="discount/MYCODE/"
          @input="debouncedBuildLink()"
        />
      </b-field>

      <br>

      <b-field label="UTM source (optional)">
        <b-input
          v-model="utms.source"
          placeholder="my workspace name"
          expanded
          @input="debouncedBuildLink()"
        />
      </b-field>

      <br>

      <b-field label="UTM medium (optional)">
        <b-input
          v-model="utms.medium"
          placeholder="influencer username"
          expanded
          @input="debouncedBuildLink()"
        />
      </b-field>

      <br>

      <b-field label="UTM campaign (optional)">
        <b-input
          v-model="utms.campaign"
          placeholder="campaign name"
          expanded
          @input="debouncedBuildLink()"
        />
      </b-field>

      <br>

      <b-field>
        <b-switch
          v-model="useShortLink"
          class="has-text-secondary"
          @input="toggleSwitchShortLink($event)"
        >
          Use trackable link
          <b-tooltip
            label="Trackable link let you know how many people has clicked on your link"
            multilined
          >
            <b-icon pack="ubu" icon="ubu-information" />
          </b-tooltip>
        </b-switch>
      </b-field>

      <br>

      <b-message class="is-classic multibar">
        <a v-if="useShortLink" :href="preparedShortLink" target="_blank" rel="noopener noreferrer">
          {{ preparedShortLink }}
        </a>

        <a v-else :href="payload.link" target="_blank" rel="noopener noreferrer">
          {{ payload.link }}
        </a>
      </b-message>
    </fieldset>

    <br>

    <div class="buttons is-right">
      <b-button label="Add affiliation" type="is-success" native-type="submit" />
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';

const {
  VUE_APP_SELINA_SHORT_LINK_SUBDOMAIN: subdomain,
  VUE_APP_SELINA_SHORT_LINK_DOMAIN: domain,
} = process.env;

export default {
  name: 'TheSetupNewAffiliationCode',
  props: {
    affiliationCodeFolderAcfid: {
      type: Number,
      default: null,
    },
    contactPlatform: {
      type: Object,
      default: () => {},
    },
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      affilationCodeType: [
        { label: 'Discount', value: 'DISCOUNT' },
        { label: 'Referral', value: 'REFERRAL' },
      ],
      selectedContactPlatform: null,
      selectedCampaign: null,
      selectedDiscount: null,
      shortLink: {},

      referralCode: '',
      baseUrl: 'getubu.com',
      params: '',
      queries: '',
      link: '',
      utms: {
        source: 'getubu',
        medium: '',
        campaign: '',
      },

      payload: {
        affiliationCodeFolderAcfid: null,
        type: 'DISCOUNT',
        provider: 'MANUAL',
        extrefId: null,
        link: null,
        description: null,
        contactPlatformCpid: null,
        campaignCid: null,
        broadcastBid: null,
        shortLinkSlid: null,
      },

      shortLinkPayload: {
        slid: null,
        subdomain,
        domain,
        link: null,
        clickCount: 0,
      },

      useShortLink: false,

      isDebouncedInput: false,
    };
  },
  computed: {
    ...mapGetters({
      _currentShop: 'TheCreateAffiliationCodePanel/currentShop',
      _affiliationCodeFolderList: 'TheCreateAffiliationCodePanel/affiliationCodeFolderList',
    }),
    preparedShortLink() {
      if (!this.shortLink?.slid || !this.useShortLink) { return ''; }
      return `https://${this.shortLink.subdomain}.${this.shortLink.domain}/${this.shortLink.slid}`;
    },
    selectedDiscountREST() {
      if (!this.selectedDiscount) { return {}; }
      // eslint-disable-next-line object-curly-newline
      const {
        totalOrders,
        totalRevenue,
        contactPlatform,
        shortLink,
        ...discountREST
      } = this.selectedDiscount;

      return discountREST;
    },
  },
  watch: {
    _currentShop() {
      this.setBaseUrl();
    },
  },
  mounted() {
    this.payload.affiliationCodeFolderAcfid = this.affiliationCodeFolderAcfid;
    this.setBaseUrl();

    if (this.contactPlatform) {
      this.selectedContactPlatform = this.contactPlatform;
      this.payload.contactPlatformCpid = this.selectedContactPlatform.cpid;
      this.utms.medium = this.contactPlatform.username;
    }
    if (this.campaign) {
      this.selectedCampaign = this.campaign;
      this.payload.campaignCid = this.selectedCampaign.cid;
      this.utms.campaign = this.campaign.name;
    }
    this.buildLink();
  },
  methods: {
    buildQueries() {
      const queries = Object.entries(this.utms).reduce((acc, [key, value]) => {
        if (value?.length) acc.push(`${key}=${encodeURIComponent(value)}`);
        return acc;
      }, []);

      this.queries = queries.join('&');
    },

    buildLink() {
      this.buildQueries();
      this.untoggleShortLink();

      let baseUrl = '';
      if (this.baseUrl?.length) {
        baseUrl = this.baseUrl.endsWith('/') ? this.baseUrl : `${this.baseUrl}/`;
        baseUrl = baseUrl.startsWith('http') ? baseUrl : `https://${baseUrl}`;
      }

      let params = '';
      if (this.params?.length) {
        params = this.params.endsWith('/') ? this.params : `${this.params}/`;
      }

      this.link = `${baseUrl}${params}?${this.queries}`;
      this.payload.link = `${baseUrl}${params}?${this.queries}`;
    },

    debouncedBuildLink() {
      if (this.isDebouncedInput) return undefined;

      this.isDebouncedInput = true;

      return setTimeout(() => {
        this.buildLink();
        this.isDebouncedInput = false;
      }, 1500);
    },

    setBaseUrl() {
      const { baseUrl } = this._currentShop;
      this.baseUrl = baseUrl.startsWith('http') ? baseUrl : `https://${baseUrl}`;
    },

    changeCodeType() {
      this.selectedDiscount = null;
      this.referralCode = '';
      this.params = '';
      delete this.utms.ref;
      this.payload.description = null;
      this.buildLink();
    },

    selectDiscountCode() {
      if (!this.selectedDiscount) {
        this.params = '';
      } else {
        this.params = `discount/${this.selectedDiscount.code}/`;
        this.payload.description = this.selectedDiscount.description;
      }
      this.buildLink();
    },

    setReferralCode() {
      if (!this.referralCode?.length) {
        delete this.utms.ref;
      } else {
        this.utms.ref = this.referralCode;
      }
      this.buildLink();
    },

    selectCampaign(campaign) {
      this.utms.campaign = campaign?.name || '';
      this.payload.campaignCid = campaign?.cid || null;
      this.buildLink();
    },

    selectContactPlatform(cp) {
      this.utms.medium = cp?.username || '';
      this.buildLink();
    },

    ...mapActions({
      createAffiliationCodeList: 'TheCreateAffiliationCodePanel/createAffiliationCodeList',
      updateAffiliationCodeList: 'TheCreateAffiliationCodePanel/updateAffiliationCodeList',
      _createShortLink: 'TheCreateAffiliationCodePanel/createShortLink',
      _patchShortLink: 'TheCreateAffiliationCodePanel/patchShortLink',
    }),

    upsertAffiliation() {
      const { type } = this.payload;

      if (type === 'DISCOUNT' && !this.selectedDiscount) {
        this.$buefy.snackbar.open({ ...snackBarFailure, message: 'You must select a discount code.' });
        return false;
      }

      if (type === 'REFERRAL') return this.insertReferralCode();
      if (type === 'DISCOUNT') return this.updateDiscountCode();
      return undefined;
    },

    insertReferralCode() {
      const preparedPayload = {
        sid: this._currentShop.sid,
        affiliationCodeList: [
          {
            ...this.payload,
            shopSid: this._currentShop.sid,
            code: this.referralCode,
            createdOn: this.$moment().format(),
          },
        ],
      };

      return this.createAffiliationCodeList({
        payload: preparedPayload,
        httpQuery: { contactPlatform: true },
      })
        .then(() => { this.$emit('closePanel'); });
    },

    updateDiscountCode() {
      const payload = {
        sid: this._currentShop.sid,
        affiliationCodeList: [{
          ...this.selectedDiscountREST,
          contactPlatformCpid: this.payload.contactPlatformCpid,
          campaignCid: this.payload.campaignCid,
          description: this.payload.description,
          link: this.payload.link,
          shortLinkSlid: this.payload.shortLinkSlid,
        }],
      };
      return this.updateAffiliationCodeList({ payload, httpQuery: { contactPlatform: true } })
        .then(() => { this.$emit('closePanel'); });
    },

    toggleSwitchShortLink() {
      const payload = { ...this.shortLinkPayload, ...this.shortLink, link: this.payload.link };

      return payload.slid
        ? this._patchShortLink({ payload })
        : this._createShortLink({ payload })
          .then((shortLink) => {
            this.shortLink = shortLink;
            this.payload.shortLinkSlid = shortLink.slid;
          });
    },

    untoggleShortLink() {
      // auto untoggle shortLinkUse if link is edited
      this.useShortLink = false;
      this.payload.shortLinkSlid = null;
    },
  },
};
</script>
