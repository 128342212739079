<template>
  <b-dropdown-item
    custom
    paddingless
  >
    <b-breadcrumb
      align="is-left"
      size="is-small"
    >
      <b-breadcrumb-item
        v-for="(item, index) in breadcrumbsOptions"
        :key="item.label"
        :active="item.label === currentBreadcrumb.label"
        @click.native.stop="$emit('goBack', {item, index})"
      >
        {{ item.label }}
      </b-breadcrumb-item>
    </b-breadcrumb>
  </b-dropdown-item>
</template>

<script>
export default {
  name: 'UbuDropdownBreadcrumbs',
  props: {
    breadcrumbsOptions: {
      type: Array,
      required: true,
    },
    currentBreadcrumb: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
