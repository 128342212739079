<template>
  <!-- eslint-disable max-len -->
  <b-table
    class="ubuTableSetting"
    :data="Object.values(allChannelGroupAccount)"
    :opened-detailed="defaultOpenedDetails"
    detailed
    detail-key="cgid"
    :has-detailed-visible="() => false"
    hoverable
    :is-row-selectable="() => true"
    :show-header="false"
    @click="toggleOpenedDetails($event)"
  >
    <b-table-column
      v-for="column in columns"
      :key="column.col"
      v-slot="props"
      :label="column.label"
      :field="column.col"
      :cell-class="column.cellClass"
    >
      <template v-if="column.cmp === 'img'">
        <UbuAvatar
          :initial-name="props.row.initials"
          :size="40"
        />
      </template>

      <template v-if="column.cmp === 'flat'">
        <p class="text is-3">{{ props.row[column.col] }}</p>
      </template>

      <template v-if="column.cmp === 'action'">
        <div class="multibar">
          <b-icon
            class="end"
            pack="ubu"
            :icon="defaultOpenedDetails.includes(props.row.value)
              ? 'ubu-arrow-down'
              : 'ubu-arrow-up'"
          />
        </div>
      </template>
    </b-table-column>

    <template #detail="props">
      <DevDetails :cnt="{name: 'children', obj: props.row}" />
      <template
        v-for="{
          cid, platformName, isConnected, name, addChannel, cmp,
        } in props.row.children"
      >
        <div
          v-if="platformName"
          :key="platformName"
        >
          <div
            class="multibar row-vcentered py-3"
          >
            <div class="start mr-1">
              <component
                :is="`UbuIcon${platformName.capitalize()}`"
                font-size="32px"
              />
            </div>
            <div class="start ml-3">
              <p
                :class="['text', {
                  'has-text-secondary': !isConnected
                }]"
              >
                {{ platformName.capitalize() }}
              </p>
              <p class="subtext has-text-secondary">{{ name }}</p>
            </div>

            <div class="buttons end">
              <b-button
                v-if="platformName === 'INSTAGRAM'"
                label="Migration"
                type="is-danger"
                tag="router-link"
                :to="`/admin/account/${currentAid}/channels/${cid}/migrate`"
              />
              <b-button
                v-if="isConnected"
                label="View details"
                type="is-ghost"
                tag="router-link"
                :to="`/admin/account/${currentAid}/channels/${cid}/details/${cmp}`"
              />
              <b-button
                v-if="isConnected"
                label="Ping channel"
                type="is-info"
              />
              <b-button
                v-if="!isConnected && addChannel"
                label="Add channel"
                type="is-primary"
                tag="router-link"
                :to="{
                  path: `/admin/account/${currentAid}/channels/addChannel${platformName.capitalize()}`,
                  query: { channelGroupCgid: props.row.cgid }
                }"
              />
              <b-button
                :label="isConnected ? 'Connected' : 'Not connected'"
                :type="isConnected ? 'is-success' : 'is-secondary'"
              />
            </div>
          </div>

          <hr
            v-if="platformName !== 'SMS'"
            class="is-l"
          >
        </div>
      </template>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'UbuViewChannelsAccount',
  props: {
    allChannelGroupAccount: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      defaultOpenedDetails: [],
    };
  },
  computed: {
    currentAid() {
      return this.$route.params.aid;
    },
  },
  methods: {
    toggleOpenedDetails(workspace) {
      if (this.defaultOpenedDetails.includes(workspace.cgid)) {
        this.defaultOpenedDetails = this.defaultOpenedDetails
          .filter((value) => value !== workspace.cgid);
      } else {
        this.defaultOpenedDetails.push(workspace.cgid);
      }
    },
  },
};
</script>
