<template>
  <TheSimplePanel
    closing-way="emit"
    @close="$emit('close')"
  >
    <template #header>
      <h1 class="title is-1">Daily Mention</h1>
    </template>

    <template #content>
      <b-field label="Subscribe">
        <b-switch
          :value="isActive"
          @input="toggle($event)"
        />
      </b-field>

      <UbuActionCard
        v-for="item in mediaZipList"
        :key="item.link"
        class="mb-2"
        :label="`${$moment(item.date).format('YYYY/MM/DD')} - ${item.itemCount} mentions`"
        expanded
        info
        :info-tooltip="false"
        info-label="Download"
        :disabled="Number(item.itemCount) < 1"
        @click="downloadZip(item.link)"
      />
    </template>
  </TheSimplePanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheDailyMentionPanel',
  data() {
    return {
      /* eslint-disable object-curly-newline */
      /* eslint-disable key-spacing */
      /* eslint-disable no-multi-spaces */
      columns: {
        date:      { cmp: 'UbuTableDate',   field: 'date',      label: 'Date',        isSortable: true  },
        itemCount: { cmp: 'UbuTableNumber', field: 'itemCount', label: 'Total media', isSortable: true  },
        action:    { cmp: 'UbuTableAction', field: 'link',      label: '',            isSortable: false },
      },
      loading: false,
      sortField: 'date',
      sortOrder: 'desc',
    };
  },
  computed: {
    ...mapGetters({
      _myChannelInstagram: 'TheMention/myChannelInstagram',
      _mediaZipList: 'TheMention/mediaZipList',
    }),

    isActive() {
      return !!this._myChannelInstagram.dailyMentionAskedOn;
    },

    mediaZipList() {
      if (!this._mediaZipList || !this._mediaZipList.length) return [];
      return [...this._mediaZipList]
        .sort((a, b) => this.$moment(b.date).unix() - this.$moment(a.date).unix());
    },
  },
  mounted() {
    return this._reportMentionZipList({ payload: { cid: this._myChannelInstagram.cid } });
  },
  methods: {
    ...mapActions({
      _reportMentionZipList: 'TheMention/reportMentionZipList',
      _setDailyMention: 'TheMention/setDailyMention',
      _unsetDailyMention: 'TheMention/unsetDailyMention',
      _downloadMentionZip: 'TheMention/downloadMentionZip',
    }),

    toggle(bool) {
      const payload = { cid: this._myChannelInstagram.cid };

      if (bool) return this._setDailyMention({ payload });

      return this._unsetDailyMention({ payload });
    },

    downloadZip(link) {
      return this._downloadMentionZip({ payload: { link } })
        .then(({ link: _link }) => {
          window.location.assign(_link);
        });
    },
  },
};
</script>
