/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    tribeThreadCounters: {},
  },
  mutations: {
    SET_TRIBE_THREAD_COUNTERS(state, newData) { state.tribeThreadCounters = newData; },
  },
  getters: {
    getterTribeThreadCounters: (state) => state.tribeThreadCounters,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.REPORT')) return undefined;

      return clark.api.apiTribe.threadCounters.report({ payload, httpQuery })
        .then((tribeThreadCounters) => {
          commit('SET_TRIBE_THREAD_COUNTERS', tribeThreadCounters);
          return tribeThreadCounters;
        });
    },
  },
};
