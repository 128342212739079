import { use, registerComponent } from '@dailyplanet/utils/plugins';

// Thread
import UbuThreadMessageActionLog from './thread/UbuThreadMessageActionLog';
import UbuThreadMessageText from './thread/UbuThreadMessageText';
import UbuThreadMessageMediaImage from './thread/UbuThreadMessageMediaImage';
import UbuThreadMessageMediaVideo from './thread/UbuThreadMessageMediaVideo';
import UbuThreadMessageMediaAudio from './thread/UbuThreadMessageMediaAudio';
import UbuThreadMessageStoryReact from './thread/UbuThreadMessageStoryReact';
import UbuThreadMessageStoryMention from './thread/UbuThreadMessageStoryMention';
import UbuThreadMessageStoryReply from './thread/UbuThreadMessageStoryReply';
import UbuThreadMessageStoryShare from './thread/UbuThreadMessageStoryShare';
import UbuThreadMessageSkeleton from './thread/UbuThreadMessageSkeleton';
import UbuThreadMessageFooter from './thread/UbuThreadMessageFooter';
import UbuThreadMessagePrivateNote from './thread/UbuThreadMessagePrivateNote';
import UbuThreadMessageProfileShare from './thread/UbuThreadMessageProfileShare';
import UbuThreadMessagePostShare from './thread/UbuThreadMessagePostShare';
import UbuThreadMessageLogbook from './thread/UbuThreadMessageLogbook';
import UbuThreadMessageContentWrapper from './thread/UbuThreadMessageContentWrapper';
import UbuThreadMessageVideo from './thread/UbuThreadMessageVideo';
import UbuThreadMessageAudio from './thread/UbuThreadMessageAudio';
import UbuThreadMessageImage from './thread/UbuThreadMessageImage';
import UbuThreadMessageCarousel from './thread/UbuThreadMessageCarousel';
import UbuThreadMessageMalformed from './thread/UbuThreadMessageMalformed';
import UbuThreadMessageUnsupported from './thread/UbuThreadMessageUnsupported';
import UbuThreadMessageDeletedMessage from './thread/UbuThreadMessageDeletedMessage';
import UbuThreadBulkActions from './thread/UbuThreadBulkActions';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuThreadMessageActionLog);
    registerComponent(Vue, UbuThreadMessageText);
    registerComponent(Vue, UbuThreadMessageMediaImage);
    registerComponent(Vue, UbuThreadMessageMediaVideo);
    registerComponent(Vue, UbuThreadMessageMediaAudio);
    registerComponent(Vue, UbuThreadMessageStoryReact);
    registerComponent(Vue, UbuThreadMessageStoryMention);
    registerComponent(Vue, UbuThreadMessageStoryReply);
    registerComponent(Vue, UbuThreadMessageStoryShare);
    registerComponent(Vue, UbuThreadMessageSkeleton);
    registerComponent(Vue, UbuThreadMessageFooter);
    registerComponent(Vue, UbuThreadMessagePrivateNote);
    registerComponent(Vue, UbuThreadMessageProfileShare);
    registerComponent(Vue, UbuThreadMessagePostShare);
    registerComponent(Vue, UbuThreadMessageLogbook);
    registerComponent(Vue, UbuThreadMessageContentWrapper);
    registerComponent(Vue, UbuThreadMessageVideo);
    registerComponent(Vue, UbuThreadMessageAudio);
    registerComponent(Vue, UbuThreadMessageImage);
    registerComponent(Vue, UbuThreadMessageCarousel);
    registerComponent(Vue, UbuThreadMessageMalformed);
    registerComponent(Vue, UbuThreadMessageUnsupported);
    registerComponent(Vue, UbuThreadMessageDeletedMessage);

    registerComponent(Vue, UbuThreadBulkActions);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuThreadMessageActionLog,
  UbuThreadMessageText,
  UbuThreadMessageMediaImage,
  UbuThreadMessageMediaVideo,
  UbuThreadMessageMediaAudio,
  UbuThreadMessageStoryReact,
  UbuThreadMessageStoryMention,
  UbuThreadMessageStoryReply,
  UbuThreadMessageStoryShare,
  UbuThreadMessageSkeleton,
  UbuThreadMessageFooter,
  UbuThreadMessagePrivateNote,
  UbuThreadMessageProfileShare,
  UbuThreadMessagePostShare,
  UbuThreadMessageLogbook,
  UbuThreadMessageContentWrapper,
  UbuThreadMessageVideo,
  UbuThreadMessageAudio,
  UbuThreadMessageImage,
  UbuThreadMessageCarousel,
  UbuThreadMessageMalformed,
  UbuThreadMessageUnsupported,
  UbuThreadMessageDeletedMessage,

  UbuThreadBulkActions,
};
