<template>
  <b-modal
    v-model="params.isModalActive"
    @close="$emit('close')"
  >
    <div class="modal-card">
      <form
        v-if="params.isSendable"
        @submit.prevent="$emit(params.emit)"
      >
        <header class="modal-card-head multibar">
          <div class="start modal-card-title">{{ params.card.title }}</div>
          <div class="end buttons">
            <b-button
              type="is-secondary"
              label="Cancel"
              @click="toggleModalActive()"
            />

            <b-button
              type="is-primary"
              native-type="submit"
              :label="params.card.successButton"
            />
          </div>
        </header>
        <div class="modal-card-body">
          <component
            :is="params.formName"
            v-model="localModel"
          />
        </div>
      </form>
      <div v-else>
        <header class="modal-card-head multibar">
          <div class="start modal-card-title">{{ params.card.title }}</div>
          <div class="end buttons">
            <b-button
              type="is-secondary"
              label="Close"
              @click="toggleModalActive()"
            />
          </div>
        </header>
        <div class="modal-card-body">
          <component
            :is="params.formName"
            v-model="localModel"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'UbuModal',
  model: {
    prop: 'model',
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localModel: {
      get() { return { ...this.model }; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
  methods: {
    toggleModalActive() {
      this.$emit('toggleModal', !this.params.isModalActive);
    },
  },
};
</script>
