<template>
  <div>
    <router-link
      v-for="route in routes"
      :key="route.name"
      class="no-padding"
      :to="route.path"
    >
      <b-menu-item
        icon="account"
        :label="route.name"
      />
    </router-link>
  </div>
</template>

<script>
import routes from './routes';

export default {
  name: 'TheUserNavigation',
  data() {
    return {
      routes,
    };
  },
};
</script>
