<template>
  <div class="ubuContactPlatformPanelNote">
    <p class="subtext is-2 has-text-secondary">
      {{ item.user ? item.user.firstName : 'Ubu' }} {{ item.noteDate | humanizeDate }}
    </p>
    <p class="multibar row-vcentered text is-2">
      <span class="start">
        {{ item.text }}
      </span>
      <template v-if="item.icon">
        <b-icon
          :class="`start has-text-color-${getCustomColor}`"
          pack="ubu"
          :icon="item.icon"
        />
        <span class="start">
          {{ getCustomLabel }}
        </span>
      </template>
    </p>
  </div>
</template>

<script>
export default {
  name: 'UbuNoteItemLogbook',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getCustomColor() {
      /* eslint-disable no-multi-spaces */
      switch (this.item.icon) {
        case 'ubu-custom-tag':       return this.item.threadGroup.color;
        case 'ubu-custom-contact':   return this.item.contactGroup.color;
        case 'ubu-custom-lists':     return this.item.crmList.color;
        case 'ubu-custom-influence': return this.item.campaign.color;
        default:                     return '0';
      }
      /* eslint-enable no-multi-spaces */
    },
    getCustomLabel() {
      /* eslint-disable no-multi-spaces */
      switch (this.item.icon) {
        case 'ubu-custom-tag':       return this.item.threadGroup.label;
        case 'ubu-custom-contact':   return this.item.contactGroup.label;
        case 'ubu-custom-lists':     return this.item.crmList.name;
        case 'ubu-custom-influence': return this.item.campaign.name;
        default:                     return '';
      }
      /* eslint-enable no-multi-spaces */
    },
  },
};
</script>
