<template>
  <section class="plain-container">
    <div class="card">
      <header class="card-header is-sticky">
        <p class="card-header-title">
          User dashboard
        </p>
        <div class="card-header-icon buttons">
          <b-button
            type="is-info"
            :icon-left="settingVisible ? 'minus' : 'plus'"
            label="setting"
            @click="settingVisible = !settingVisible"
          />
        </div>
      </header>
      <div
        v-if="settingVisible"
        class="card-content"
      >
        <b-field
          label="Pagination"
          horizontal
        >
          <b-numberinput
            v-model="pagin"
            controls-position="compact"
          />
        </b-field>

        <br>

        <b-field
          label="Columns selection"
          horizontal
        >
          <div class="buttons">
            <template v-for="([name, { visible }], index) in Object.entries(columns)">
              <b-button
                v-if="visible"
                :key="index"
                :label="name"
                :active="visible"
                class="is-info"
                @click="columns[name].visible = !visible"
              />
              <b-button
                v-else
                :key="index"
                :label="name"
                :active="visible"
                class="is-dark"
                @click="columns[name].visible = !visible"
              />
            </template>
          </div>
        </b-field>

        <hr>
      </div>
      <div class="card-content">
        <UbuKaraDetailledUserTable
          v-model="getterDetailledUserList"
          :loading="isLoading"
          :columns="columns"
          :checked-rows.sync="checkedRows"
          :pagin.sync="pagin"
          @update:checkedRows="checkedRows = $event"
          @deleteAccount="deleteAccount"
          @changeAuthenticate="changePassword($event)"
          @removeRight="removeRight"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheDetailledUser',
  data() {
    return {
      settingVisible: false,
      pagin: 20,
      checkedRows: [],
      isLoading: false,
      columns: {
        uid: { visible: true },
        firstName: { visible: true },
        lastName: { visible: true },
        auths: { visible: true },
        userRoleUrid: { visible: true },
        isUbu: { visible: true },
        actions: { visible: true },
      },
    };
  },
  computed: {
    ...mapGetters({
      getterDetailledUserList: 'TheDetailledUser/getterDetailledUserList',
    }),
  },
  mounted() {
    this.isLoading = true;
    this.reportDetailledUser()
      .then(() => { this.isLoading = false; });
  },
  methods: {
    ...mapActions({
      reportDetailledUser: 'TheDetailledUser/reportDetailledUser',
      changePassword: 'TheDetailledUser/changePassword',
      deleteUserAccount: 'TheDetailledUser/deleteUserAccount',
      removeUserRight: 'TheDetailledUser/removeUserRight',
    }),
    deleteAccount(uid) {
      this.deleteUserAccount(uid);
    },
    removeRight(right) {
      this.removeUserRight(right);
    },
  },
};
</script>
