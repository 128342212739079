import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuEditNameForm from './UbuEditNameForm';
import UbuEditEmailForm from './UbuEditEmailForm';
import UbuEditPasswordForm from './UbuEditPasswordForm';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuEditNameForm);
    registerComponent(Vue, UbuEditEmailForm);
    registerComponent(Vue, UbuEditPasswordForm);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuEditNameForm,
  UbuEditEmailForm,
  UbuEditPasswordForm,
};
