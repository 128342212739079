<template>
  <b-button type="is-light">
    <span class="multibar row-vcentered">
      <b-icon
        class="start"
        pack="ubu"
        icon="ubu-inbox"
      />
      <span
        v-if="crmContact.tid"
        class="end"
      >
        Go to Thread
      </span>
      <span
        v-else
        class="end"
      >
        Engage conversation
      </span>
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'UbuThreadButton',
  props: {
    crmContact: {
      type: Object,
      required: true,
    },
  },
};
</script>
