<template>
  <div :class="['ubuEditCustom', {'is-detailed': detailed}]">
    <div
      v-if="canClose"
      class="multibar"
    >
      <b-button
        class="is-tertiary end"
        icon-pack="ubu"
        icon-left="ubu-cross"
        @click="$emit('cancel')"
      />
    </div>

    <b-field label="Name">
      <b-input
        ref="focusIt"
        v-model="localName"
        placeholder="Name..."
      />
    </b-field>
    <b-field label="Color">
      <div
        class="colorList"
      >
        <div
          v-for="colorId in 12"
          :key="colorId"
          :class="[
            'block-color', `bg-color-${colorId}-filled`,
            {'is-active': localColor == colorId }
          ]"
          @click="localColor = colorId"
        >
          <b-icon
            v-if="localColor == colorId"
            pack="ubu"
            icon="ubu-check"
            font-size="24px"
          />
        </div>
      </div>
    </b-field>
    <b-button
      v-if="canSave"
      class="is-ghost"
      @click="editCustom()"
    >
      + save "{{ localName }}"
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'UbuEditCustom',
  props: {
    custom: {
      type: Object,
      default: () => {},
    },
    detailed: {
      type: Boolean,
      default: false,
    },
    canClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorValue: null,
      nameValue: null,
    };
  },
  computed: {
    localName: {
      get() { return this.nameValue || this.custom.name; },
      set(newValue) {
        this.nameValue = newValue;
      },
    },
    localColor: {
      get() { return this.colorValue || this.custom.color; },
      set(newValue) {
        this.colorValue = newValue;
      },
    },
    canSave() {
      if (!this.localName || !this.localColor) return false;
      if ((this.nameValue && this.nameValue !== this.custom.name)
      || (this.colorValue && this.colorValue !== this.custom.color)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.$nextTick(() => { this.$refs.focusIt.focus(); });
  },
  methods: {
    editCustom() {
      this.$emit('editCustom', { ...this.custom, name: this.localName, color: this.localColor.toString() });
    },
  },
};
</script>
