<template>
  <div class="ubuSettingView start">
    <header class="start">
      <h2
        v-if="manageShop"
        class="subtitle is-1 has-text-secondary m-0"
      >
        {{ manageShop.baseUrl }}
      </h2>
      <div class="multibar">
        <h1 class="start title is-1">Shop details</h1>
        <div class="buttons end">
          <b-button
            :label="hide ? 'Show' : 'Hide'"
            type="is-secondary"
            icon-pack="ubu"
            :icon-left="hide ? 'ubu-eye' : 'ubu-eye-closed'"
            @click="hide = !hide"
          />

          <b-button
            label="Edit shop"
            type="is-secondary"
            icon-pack="ubu"
            icon-left="ubu-settings"
            tag="router-link"
            :to="`/shopify/shop/${currentSid}/details/edit`"
          />
          <b-button
            v-if="currentShopIsConnected === 'no action'"
            label="Ping this shop"
            type="is-primary"
            @click="pingCurrentShop()"
          />
          <b-button
            v-else-if="currentShopIsConnected === 'connected'"
            label="Shop connected"
            type="is-success"
            icon-pack="ubu"
            icon-left="ubu-check"
          />
          <b-button
            v-else-if="currentShopIsConnected === 'not connected'"
            label="Shop not connected"
            type="is-danger"
            icon-pack="ubu"
            icon-left="ubu-check"
          />
        </div>
      </div>
    </header>

    <br>
    <DevDetails :cnt="{name: 'manageShop', obj: manageShop}" />
    <DevDetails :cnt="{name: 'pingShop', obj: pingShop}" />

    <template v-if="manageShop && Object.values(manageShop).length">
      <template v-for="item in displayInfoOptions">
        <section
          v-if="item"
          :key="item.text"
          class="pt-3 pb-3"
        >
          <div class="multibar">
            <div class="start">
              <p class="subtext has-text-secondary">{{ item.text }} :</p>
              <p
                v-if="item.type === 'text'"
                class="text"
              >
                {{ item.data }}
              </p>
              <b-icon
                v-if="item.type === 'bool'"
                pack="ubu"
                icon="ubu-circle-divider"
                :class="[{
                  'has-text-success': item.data === true,
                  'has-text-danger': item.data === false,
                }]"
              />
              <template v-if="item.type === 'hide'">
                <p
                  v-if="hide"
                  class="text"
                >
                  *********************
                </p>
                <p
                  v-else
                  class="text"
                >
                  {{ item.data }}
                </p>
              </template>
            </div>
          </div>

          <hr class="is-l">
        </section>
      </template>

      <div class="multibar">
        <div class="buttons end">
          <b-button
            label="Delete Shop"
            type="is-danger"
            icon-pack="ubu"
            icon-left="ubu-trash"
            @click="deleteShop()"
          />
        </div>
      </div>

      <router-view
        v-model="manageShop"
        name="editShopifyShopPanel"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheShopDetails',
  data() {
    return {
      hide: true,
    };
  },
  computed: {
    ...mapGetters({
      manageShop: 'TheShop/manageShop',
      pingShop: 'TheShop/pingShop',
    }),
    currentSid() {
      return this.$route.params.sid;
    },
    currentShopIsConnected() {
      if (!this.pingShop) return 'no action';
      if (Number(this.pingShop.sid) === Number(this.currentSid) && this.pingShop.connected) return 'connected';
      if (Number(this.pingShop.sid) === Number(this.currentSid) && !this.pingShop.connected) return 'not connected';
      return 'no action';
    },
    displayInfoOptions() {
      return [
        { text: 'Shop SID', data: this.manageShop.sid, type: 'text' },
        { text: 'Channel group CGID', data: this.manageShop.channelGroupCgid, type: 'text' },
        { text: 'Website URL', data: this.manageShop.baseUrl, type: 'text' },
        { text: 'Shop platform', data: this.manageShop.type, type: 'text' },
        { text: 'Shop Extref ID', data: this.manageShop.extrefId, type: 'text' },
        { text: 'API Version', data: this.manageShop.apiVersion, type: 'text' },
        { text: 'API Key', data: this.manageShop.apiKey, type: 'hide' },
        { text: 'Password', data: this.manageShop.password, type: 'hide' },
        { text: 'Shared Secret', data: this.manageShop.sharedSecret, type: 'hide' },
        { text: 'Is connected', data: this.manageShop.isConnected, type: 'bool' },
      ];
    },
  },
  mounted() {
    return Promise.all([
      this.getManageShop({ payload: { sid: this.currentSid } }),
    ]);
  },
  methods: {
    ...mapActions({
      ping: 'TheShop/ping',
      getManageShop: 'TheShop/getManageShop',
      updateManageShop: 'TheShop/updateManageShop',
      up_manageShop: 'TheShop/up_manageShop',
      up_manageShopList: 'TheShop/up_manageShopList',
      deleteManageShop: 'TheShop/deleteManageShop',
    }),
    pingCurrentShop() {
      return this.ping({ payload: { sid: this.currentSid } })
        .then(({ response: ping }) => {
          if (ping) {
            this.updateManageShop({
              payload: { ...this.manageShop, isConnected: true },
            })
              .then(() => Promise.all([
                this.up_manageShop({
                  payload: { ...this.manageShop, isConnected: true },
                }),
                this.up_manageShopList({
                  payload: { ...this.manageShop, isConnected: true },
                })]));
          }
          return true;
        });
    },
    deleteShop() {
      return this.$buefy.dialog.confirm({
        title: 'You are about to delete a shop store',
        message: 'Are you sure you want to <b>delete</b> this shop ?<br> <b></b>',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.deleteManageShop({ payload: { sid: this.currentSid } })
            .then(({ response: deleted }) => {
              if (deleted) this.$router.push('/shopify/all-shops');
            });
        },
      });
    },
  },
};
</script>
