<template>
  <section id="TheTribePeople">
    <TheCampaignCartouche
      v-model="filters"
      :allowed-filters="allowedFilters"
      :non-displayable-filters="nonDisplayableFilters"
      @input="filters = $event"
    >
      <template #secondLineEnd>
        <TheSortingDropdown
          v-model="selectedSort"
          class="start ml-2"
          :sort-key-list="sortKeys"
        />

        <b-button
          class="end mr-2"
          :label="checkedMentions.length !== mentionList.length ? 'Select all' : 'Unselect all'"
          type="is-secondary"
          icon-pack="ubu"
          icon-left="ubu-bulk"
          :disabled="isDisabled"
          @click="toggleSelectAll()"
        />
        <b-button
          class="end mr-2"
          label="Download"
          :disabled="checkedMentions.length === 0"
          type="is-secondary"
          icon-pack="ubu"
          icon-left="ubu-download"
          @click="downloadTaskSelected(checkedRows)"
        />
      </template>
    </TheCampaignCartouche>

    <div class="mainPanelTable ubuMentionsGalleryView">
      <div
        v-if="loading"
        :class="['mentionsGrid', { 'is-story': mentionType === 'STORY' }]"
      >
        <UbuMentionCard
          v-for="index in 20"
          :key="index"
          :is-story="mentionType === 'STORY'"
          loading
        />
      </div>
      <div
        v-else
        class="multibar column"
      >
        <div
          class="start"
          style="height: calc(100vh - 250px); overflow-y: auto;"
        >
          <div
            v-if="mentionList.length"
            :class="['mentionsGrid', { 'is-story': mentionType === 'STORY' }]"
          >
            <template v-for="mention in mentionList">
              <UbuNewMentionCard
                :key="mention.pid"
                :mention="mention"
                :is-story="mentionType === 'STORY'"
                :checked="!!checkedRows[mention.pid]"
                :focused="!!checkedRows[mention.pid]"
                checkable
                @selectContactPlatform="$emit('selectContactPlatform', $event)"
                @click="openMention(mention)"
                @check="checkCard(mention)"
                @toggleFavorite="toggleFavorite(mention)"
              />
            </template>
          </div>
        </div>

        <b-pagination
          v-model="page"
          class="end"
          :total="total"
          :per-page="perPage"
          size="is-small"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          @change="pageChange($event)"
        />
      </div>
    </div>

    <UbuBulkActionPanelTrigger
      :bulk-is-open="bulkIsOpen"
      :checked-rows-length="checkedMentions.length"
      @cancel="bulkIsOpen = false; checkedRows = {};"
      @open="bulkIsOpen = true;"
    />

    <TheCampaignBulkPanel
      v-if="isBackgroundLocked"
      v-model="checkedMentions"
      @close="bulkIsOpen = false; checkedRows = {};"
    />

    <TheMentionModal
      v-if="isModalOpen && currentMention"
      v-model="isModalOpen"
      :mention-list="mentionList"
      :initital-mention="currentMention"
    />

    <TheContactPlatformPanel
      v-if="selectedContactPlatform"
      v-model="selectedContactPlatform"
      is-large
      @close="selectedContactPlatform = null;"
    />

    <div
      v-if="isBackgroundLocked"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';
import DownloadTaskMixin from '@dailyplanet/mixins/DownloadTaskMixin';

export default {
  name: 'TheCampaignMention',
  mixins: [TableUtilsMixin, DownloadTaskMixin],
  data() {
    return {
      loading: false,
      isDisabled: false,
      bulkIsOpen: false,
      mentionType: null,

      page: 1,
      perPage: 50,
      total: 0,

      isModalOpen: false,
      currentMention: null,
      optionDateToUse: ['currentWeek', 'previousWeek', 'currentMonth', 'previousMonth'],
      selectedContactPlatform: null,
      checkedRows: {},
      filters: [],
      allowedFilters: [
        'activity.status',
        'activity.cpsActiveCampaign',
        'activity.createdOn',
        'contactPlatform.username',
        'contactPlatform.followerCount',
        'contactPlatformStatistic.hasThread',
        'mention.mentionType',
        'publication.createdOn',
        'campaignMention.isFav',
        'tribeMemberList.tribeTid',
      ],
      nonDisplayableFilters: [
        'contactPlatform.username',
      ],
      selectedSort: {
        sortField: 'publication.createdOn',
        sortOrder: 'desc',
        sortKeyType: 'date',
      },
      sortKeys: {
        'publication.createdOn': { sortKeyType: 'date', sortField: 'publication.createdOn', label: 'Date' },
        'publication.likes': { sortKeyType: 'number', sortField: 'publication.likes', label: 'Likes' },
        'publication.comments': { sortKeyType: 'number', sortField: 'publication.comments', label: 'Comments' },
        'publication.engagement': { sortKeyType: 'number', sortField: 'publication.engagement', label: 'Post Eng.' },
        'contactPlatform.username': { sortKeyType: 'string', sortField: 'contactPlatform.username', label: 'Username' },
        'contactPlatform.followerCount': { sortKeyType: 'number', sortField: 'contactPlatform.followerCount', label: 'Followers' },
      },
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'TheCampaign/currentCampaign',
      // activityStatusListFiltered: 'TheCampaign/activityStatusListFiltered',
      _campaignMentionList: 'TheCampaign/campaignMentionList',
      currentUser: 'TheAuthenticate/myAuthenticate',

    }),

    mentionList() {
      const [parent, child] = this.selectedSort.sortField.split('.');
      const { sortKeyType } = this.sortKeys[this.selectedSort.sortField];
      const campaignList = Object.values(this._campaignMentionList);

      if (!campaignList.length) return [];

      return campaignList.sort((a, b) => {
        if (sortKeyType === 'date') {
          if (this.selectedSort.sortOrder === 'desc') return this.$moment(b[parent][child]).unix() - this.$moment(a[parent][child]).unix();
          return this.$moment(a[parent][child]).unix() - this.$moment(b[parent][child]).unix();
        }
        if (sortKeyType === 'string') {
          if (this.selectedSort.sortOrder === 'desc') return b[parent][child] > a[parent][child] ? 1 : -1;
          return a[parent][child] > b[parent][child] ? 1 : -1;
        }
        if (sortKeyType === 'number') {
          if (this.selectedSort.sortOrder === 'desc') return Number(b[parent][child]) - Number(a[parent][child]);
          return Number(a[parent][child]) - Number(b[parent][child]);
        }

        return a[parent][child] - b[parent][child];
      });
    },

    isBackgroundLocked() {
      if (this.bulkIsOpen && this.checkedMentions.length) return true;
      return false;
    },

    sorting() {
      return `${this.selectedSort.sortField},${this.selectedSort.sortOrder}`;
    },

    checkedMentions() {
      return Object.values(this.checkedRows);
    },
  },
  watch: {
    filters(newValue, oldVal) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldVal)) {
        return this.reportDatas();
      }
      return undefined;
    },
    sorting(newValue, oldVal) {
      if (newValue !== oldVal) {
        return this.reportDatas();
      }
      return undefined;
    },
  },
  beforeMount() {
    return this._resetContactPlatformOverviewList();
  },
  mounted() {
    this.checkedRows = {};
    this.loading = true;
    return this.reportDatas()
      .then(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions({
      _resetContactPlatformOverviewList: 'ApiContact/contactPlatformOverview/resetContactPlatformOverviewList',
      getAndSetCurrentContactPlatformOverview: 'TheContactPlatformPanel/getAndSetCurrentContactPlatformOverview',
      resetCampaignMentionList: 'ApiPublication/campaignMention/UP_resetCampaignMentionList',
      _reportCampaignMentionList: 'TheCampaign/reportCampaignMentionList',
      _favMentions: 'TheMention/favMentions',
      _unfavMention: 'TheMention/unfavMention',
      createDownloadTask: 'TheActivityMention/createDownloadTask',
    }),

    openMention(mention) {
      this.isModalOpen = true;
      this.currentMention = mention;
    },

    checkCard(mention) {
      if (!this.checkedRows[mention.pid]) this.checkedRows = { ...this.checkedRows, [mention.pid]: mention };
      else {
        const checkeds = { ...this.checkedRows };
        delete checkeds[mention.pid];
        this.checkedRows = checkeds;
      }
    },

    selectContactPlatform(cpid) {
      this.selectedContactPlatform = cpid;
      return this.getAndSetCurrentContactPlatformOverview({ payload: { cpid } });
    },

    updateCheckedRows(rows) {
      this.checkedRows = rows;
    },

    reportDatas() {
      return Promise.resolve()
        .then(() => this.resetCampaignMentionList())
        .then(() => this._reportCampaignMentionList({
          payload: { cid: this.$route.params.campaignCid },
          httpQuery: {
            tribe: 'true',
            pagin: `${this.page - 1},${this.perPage}`,
            sorting: this.sorting,
            filters: this.prepareFiltersToRequest(this.allowedFilters, this.filters),
          },
        }))
        .then(({ total }) => {
          this.total = total;
        });
    },

    toggleSelectAll() {
      if (this.checkedMentions.length !== this.mentionList.length) this.checkedRows = this.mentionList.arrayMapper('pid');
      else this.checkedRows = {};
    },

    toggleFavorite(mention) {
      if (mention.favorite.publicationPid) {
        return this._unfavMention({
          payload: { pid: mention.pid },
          mutations: [{
            target: 'ApiContact/contactPlatformOverview/UP_favMentionList',
            payload: {
              pids: [mention.pid],
              shouldFav: false,
              cpids: [mention.cpid],
            },
          }],
        });
      }

      return this._favMentions({
        payload: { pids: [mention.pid] },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_favMentionList',
          payload: {
            pids: [mention.pid],
            shouldFav: true,
            cpids: [mention.cpid],
          },
        }],
      });
    },
  },
};
</script>
