<template>
  <span>
    <template v-if="customs.length >= 1">
      <b-tooltip
        size="is-small"
        multilined
        position="is-bottom"
        class="custom"
        append-to-body
      >
        <template #content>
          <ul class="tooltip-list">
            <li
              v-for="(custom, i) in customs"
              :key="`${custom.name}-${i}`"
              class="is-flex is-align-items-center tooltip-element"
            >
              <b-icon
                pack="ubu"
                :icon="custom.icon"
                :class="`has-text-color-${custom.color}`"
              />
              <span class="">{{ custom.name || custom.label }}</span>
              <!-- :label="customs.map(({ name, label }) => name || label).join(' - ')" -->
            </li>
          </ul>
        </template>
        <div class="multibar">
          <template v-for="(custom, index) in customs">
            <b-icon
              v-if="index < responsiveDisplay"
              :key="index"
              pack="ubu"
              :icon="custom.icon"
              :class="`has-text-color-${custom.color}`"
            />
            <span
              v-if="index === responsiveDisplay"
              :key="index"
            >
              + {{ customs.length - responsiveDisplay }}
            </span>
          </template>
        </div>
      </b-tooltip>
    </template>
    <!-- <template v-else>
      <div class="multibar">
        <b-icon
          pack="ubu"
          :icon="customDefault.icon"
        />
      </div>
    </template> -->
  </span>
</template>

<script>
/* eslint-disable max-len */
export default {
  name: 'UbuCustomButtonTable',
  props: {
    customs: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    responsiveDisplay() {
      const { windowWidth } = this;
      if (windowWidth <= 1200) return 1;
      if (windowWidth >= 1201 && windowWidth <= 1321) return 2;
      return 3;
    },
    nameShortener() {
      const { customs, windowWidth } = this;
      const name = customs[0].label || customs[0].name || customs[0].text;

      if (windowWidth > 1321) return `${name.substr(0, 10)}...`;

      if (this.windowWidth >= 1100 && this.windowWidth < 1321 && name.length > 8) return `${name.substr(0, 8)}...`;
      if (this.windowWidth < 1100) return '';
      return name;
    },
  },
};
</script>
