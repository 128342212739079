<template>
  <section class="ubuSettingView">
    <div class="multibar row-vcentered">
      <h1 class="start title is-2">Contact labels</h1>

      <b-button
        class="end"
        type="is-primary"
        label="Add new contact label"
        tag="router-link"
        to="/setting/label/addContactLabel"
      />
    </div>

    <br>

    <UbuSearchbar
      v-model="search"
      placeholder="Search contact label..."
      @clear="search = ''"
    />

    <br>

    <UbuCustomTable
      v-if="customList && customList.length"
      :customs="customList"
      icon-name="ubu-custom-contact"
    >
      <template v-slot:detail="props">
        <TheSettingLabelDetails
          :key="`detail-${props.row.id}`"
          :item="props.row"
        />
      </template>
    </UbuCustomTable>

    <UbuNoData
      v-else
      :title="contentNoData.title"
      :subtitle="contentNoData.subtitle"
      class="SettingLabelNoData"
      :action="contentNoData.action"
      :button-is="contentNoData.buttonIs"
      :to="contentNoData.to"
      :type="contentNoData.type"
      :label="contentNoData.label"
      :event-name="contentNoData.eventName"
      @resetSearch="search = ''"
    />

    <div
      v-if="$route.meta.lockBody"
      class="overlayWithLock"
    />
    <router-view name="addContactLabel" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheSettingTag',
  mixins: [FilterEngineMixin],
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      contactGroupList: 'TheSettingWorkspaceSetting/contactGroupList',
    }),
    customList() {
      const contactGroupList = Object.values(this.contactGroupList)
        .map((r) => ({ ...r, id: r.cgid, name: r.label }));

      if (!contactGroupList || !contactGroupList.length) return [];

      const filtersToApply = {};
      if (this.search) filtersToApply.searchByLabelName = this.search;

      return this.filterEngine(contactGroupList, filtersToApply)
        .sort((a, b) => (this.$moment(b.createdOn) - this.$moment(a.createdOn)));
    },
    contentNoData() {
      if (this.search) {
        return {
          title: 'We cannot find any contact labels that match your search',
          subtitle: 'You can create this label at any time, or do a new search',
          action: true,
          buttonIs: 'event',
          eventName: 'resetSearch',
          type: 'is-primary',
          label: 'Reset search',
        };
      }

      return {
        title: 'No contact label were found... Yet!',
        subtitle: 'You can create a contact label at any time!',
        action: true,
        buttonIs: 'link',
        to: '/setting/label/addContactLabel',
        type: 'is-primary',
        label: 'Add new contact label',
      };
    },
  },
};
</script>
