/* eslint-disable max-len */

import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';
import { actions, getters, commonState } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        ...commonState.menu.home.menuItems,
      ],
    },
  },
  getters: {
    navigation: (state) => state.navigation,
    ...getters.authenticate,
    ...getters.account,
    ...getters.user,
    ...getters.userDetail,
    ...getters.reportDetailledUser,
    ...getters.userRole,
    ...getters.adminLogin,
  },
  actions: {
    ...actions.reportDetailledUser,
    ...actions.account,
    ...actions.authenticate,
    ...actions.userDetail,
    ...actions.user,
    ...actions.userRole,
    ...actions.adminLogin,
    lockUser({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'User/setLocked/post',
        context: { payload, httpQuery: {} },
        textNotifier: 'lock user.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to lock user.', ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    unlockUser({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'User/setLocked/delete',
        context: { payload, httpQuery: {} },
        textNotifier: 'unlock user.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to unlock user.', ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
  },
};
