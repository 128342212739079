/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    contactPrivateMetadataList: [],
    contactPrivateMetadata: null,
  },
  mutations: {
    SET_CONTACT_PRIVATE_METADATA(state, newData) { state.contactPrivateMetadata = newData; },
    SET_CONTACT_PRIVATE_METADATA_LIST(state, newData) { state.contactPrivateMetadataList = newData; },
    RESET_CONTACT_PRIVATE_METADATA(state) { state.contactPrivateMetadata = null; },
  },
  getters: {
    getterContactPrivateMetadataList: (state) => state.contactPrivateMetadataList,
    getterContactPrivateMetadata: (state) => state.contactPrivateMetadata,
  },
  actions: {
    resetContactPrivateMetadata({ commit }) {
      commit('RESET_CONTACT_PRIVATE_METADATA');
    },

    get({ commit }, { payload }) {
      return clark.api.apiContact.contactPrivateMetadata.get({ payload })
        .then((response) => {
          commit('SET_CONTACT_PRIVATE_METADATA', response);
          return response;
        });
    },

    report({ commit }, { payload }) {
      return clark.api.apiContact.contactPrivateMetadata.report({ payload })
        .then((results) => {
          commit('SET_CONTACT_PRIVATE_METADATA_LIST', results);
          return results;
        });
    },

    update({ commit }, { payload }) {
      return clark.api.apiContact.contactPrivateMetadata.put({ payload })
        .then((contactPrivateMetadata) => {
          commit('SET_CONTACT_PRIVATE_METADATA', contactPrivateMetadata);
          return contactPrivateMetadata;
        });
    },

    create({ commit }, { payload }) {
      return clark.api.apiContact.contactPrivateMetadata.post({ payload })
        .then((contactPrivateMetadata) => {
          commit('SET_CONTACT_PRIVATE_METADATA', contactPrivateMetadata);
          return contactPrivateMetadata;
        });
    },

    delete(_, { payload }) {
      return clark.api.apiContact.contactPrivateMetadata.delete({ payload })
        .then((contactPrivateMetadata) => contactPrivateMetadata);
    },
  },
};
