<template>
  <div>
    <b-dropdown-item
      custom
      class="has-text-secondary"
    >
      <fieldset class="fieldset">
        <legend class="is-subtext">Segments</legend>
      </fieldset>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="!sortedSegmentFilterList.length"
      custom
      class="subtext is-3"
    >
      To create a segment, make sure you have at least one filter applied and click
      <strong>New segment</strong>.
      <a
        class="has-text-primary"
        href="https://intercom.help/getubu/en/articles/6249033-how-can-i-use-filters-in-my-crm-tables"
        target="_blank"
      >
        Learn more
      </a>
    </b-dropdown-item>

    <b-dropdown-item
      v-for="item in sortedSegmentFilterList"
      v-else
      :key="item.sfid"
      custom
    >
      <div class="multibar row-vcentered">
        <b-switch
          v-model="localSelectedSegmentFilter"
          class="start"
          :true-value="item"
          :false-value="null"
          size="is-small"
        >
          {{ item.label }}
        </b-switch>

        <b-icon
          class="end clickable has-text-primary"
          pack="ubu"
          icon="ubu-trash"
          @click.native="deleteSegment(item.sfid)"
        />
      </div>
    </b-dropdown-item>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'TheFilterDropdownSegmentSelector',
  model: {
    prop: 'filters',
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    filterList: {
      type: Array,
      required: true,
    },
    selectedSegmentFilter: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      segmentFilterList: 'TheFilters/segmentFilterList',
    }),

    localFilters: {
      get() { return this.filters; },
      set(values) { this.$emit('input', values); },
    },

    localSelectedSegmentFilter: {
      get() { return this.selectedSegmentFilter; },
      set(val) {
        this.$emit('update:selectedSegmentFilter', { ...val });
        this.fillOrRemoveFiltersBySegment(val);
      },
    },

    sortedSegmentFilterList() {
      return Object.values(this.segmentFilterList)
        .sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
    },

    arrayMappedCatalog() {
      return this.filterList.arrayMapper('key');
    },
  },
  methods: {
    ...mapActions({
      _deleteSegmentFilter: 'TheFilters/deleteSegmentFilter',
    }),
    fillOrRemoveFiltersBySegment(segment) {
      // if unswitch segment, clear filters
      if (!segment) { this.localFilters = []; return; }

      // else use catalog + give id to prepare data to be consummable
      const preparedFilters = segment.filters.map((filter) => ({
        id: uuidv4(),
        ...this.arrayMappedCatalog[filter.key],
        ...filter,
      }));

      this.localFilters = preparedFilters;
    },

    deleteSegment(sfid) {
      this.$buefy.dialog.confirm({
        title: 'Deleting segment',
        message: 'Are you sure you want to <b>delete</b> your segment? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.handleDeleteSegmentFilter(sfid),
      });
    },

    handleDeleteSegmentFilter(sfid) {
      return Promise.resolve()
        .then(() => this._deleteSegmentFilter({ payload: { sfid } }))
        .then(() => {
          if (!this.selectedSegmentFilter) return;

          // unswitch deleted segment if selected
          if (Number(this.selectedSegmentFilter.sfid) === Number(sfid)) {
            this.$emit('update:selectedSegmentFilter', null);
            this.fillOrRemoveFiltersBySegment(null);
          }
        });
    },
  },
};
</script>
