<template>
  <fieldset class="fieldset">
    <legend>Database research</legend>
    <b-field
      label="Search query"
    >
      <b-field>
        <p class="control">
          <b-button
            type="is-dark"
            class="is-reverted is-not-clickable"
            label="Select accounts"
            active
          />
        </p>
        <b-select
          v-model="isVerified"
          placeholder="review state"
        >
          <option value="unVerified">to be reviewed</option>
          <option value="acceptedOnly">already validated</option>
          <option value="rejectedOnly">already rejected</option>
        </b-select>

        <b-select
          v-model="origin"
          placeholder="source"
        >
          <option :value="null">from all sources</option>
          <option value="SUGGESTION">from suggestions</option>
          <option value="MANUAL">from scrap</option>
        </b-select>

        <UbuAutocomplete
          v-model="scrapingZoneFilter"
          :options="filteredScrapinZonePreset"
          placeholder="Scraping Zone"
        />

        <b-select
          v-model="numberVals.key"
          @input="numberVals.value = 0"
        >
          <option value="followers">followers</option>
          <!-- <option value="growth">growth</option> -->
          <option value="engagement">engagement</option>
        </b-select>
        <b-select
          v-model="numberVals.sign"
        >
          <option value=">">Over</option>
          <option value="=">Equal</option>
          <option value="<">Under</option>
        </b-select>
        <b-input
          v-model="numberVals.value"
          :step="numberVals.key === 'followers' ? '1' : '0.01'"
          type="number"
        />

        <p class="control">
          <b-button
            :disabled="isLoading"
            :loading="isLoading"
            type="is-info"
            class="is-reverted"
            label="Research"
            icon-left="magnify"
            @click="buildRequest()"
          />
        </p>
        <p class="control">
          <b-button
            type="is-primary"
            class="is-reverted"
            label="Reset criterias"
            @click="resetFilters()"
          />
        </p>
      </b-field>
    </b-field>
  </fieldset>
</template>

<script>
import GeoMixin from '@dailyplanet/mixins/GeoMixin';

export default {
  name: 'UbuSuggestionsQueryForm',
  mixins: [GeoMixin],
  inject: {
    injectedScrapingZoneList: {
      default: () => ([]),
    },
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    scrapingZoneList: {
      type: Array,
      default() { return this.injectedScrapingZoneList; },
    },
  },
  data() {
    return {
      isVerified: 'unVerified',
      origin: null,
      numberVals: {
        key: 'followers',
        sign: '>',
        value: 10000,
      },

      scrapingZoneFilter: {
        selected: null,
        value: '',
      },
    };
  },
  methods: {
    buildRequest() {
      const httpQuery = {};
      if (this.isVerified) httpQuery[this.isVerified] = true;
      if (this.origin) httpQuery.origin = this.origin;
      if (this.numberVals) httpQuery.numberVals = JSON.stringify(this.numberVals);
      if (this.scrapingZoneFilter.selected) {
        httpQuery.scrapingZone = this.scrapingZoneFilter.selected;
      }

      this.$emit('fetchContacts', httpQuery);
    },
    resetFilters() {
      this.isVerified = 'unVerified';
      this.origin = null;
      this.numberVals = {
        key: 'followers',
        sign: '>',
        value: 10000,
      };
    },
  },
};
</script>
