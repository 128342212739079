<template>
  <div class="columns">
    <div class="column is-8">
      <p class="title is-1">404... We couldn’t find the page you’re looking for 🙀</p>

      <br>

      <div class="buttons">
        <b-button
          tag="router-link"
          to="/home"
          expanded
          type="is-primary"
          class="no-padding-horizontal"
          label="Go home"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TheError404',
};
</script>
