/* eslint-disable max-len */
import BulkActionEngineStore from '@dailyplanet/data-stores/engine-stores/bulkAction';

export default {
  namespaced: true,
  state: {
    ...BulkActionEngineStore.state,
  },
  mutations: {
    ...BulkActionEngineStore.mutations,
  },
  getters: {
    ...BulkActionEngineStore.getters,

    tabItems: (state, getters, _rootState, rootGetters) => ({
      root: {
        tab: 'root',
        title: 'What do you want to do ?',
        subtext: 'Bulk actions',
      },
      'root.downloadMentions': {
        tab: 'root.downloadMentions',
        title: 'Enter your email to receive your download link',
        subtext: 'Download mentions',
      },
      ...rootGetters['TheSendInstantMessage/tabItemsForTheTribeBulk'],
      ...rootGetters['TheAddToCampaign/tabItemsForTheCrmBulk'],
      ...rootGetters['TheAddToTribe/tabItemsForTheCrmBulk'],
    }),
  },
  actions: {
    ...BulkActionEngineStore.actions,
  },
};
