/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    downloadTaskList: [],
    downloadTaskTotal: 0,
    downloadTask: null,
  },
  mutations: {
    SET_DOWNLOAD_TASK_LIST(state, newData) { state.downloadTaskList = newData; },
    SET_DOWNLOAD_TASK_TOTAL(state, newData) { state.downloadTaskTotal = newData; },
    SET_DOWNLOAD_TASK(state, newData) { state.downloadTask = newData; },
  },
  getters: {
    getterDownloadTaskList: (state) => state.downloadTaskList,
    getterDownloadTaskTotal: (state) => state.downloadTaskTotal,
    getterDownloadTask: (state) => state.downloadTask,
  },
  actions: {
    report({ commit }, { payload, httpQuery }) {
      return clark.api.downloadTask.report({ payload, httpQuery })
        .then((downloadTaskList) => {
          commit('SET_DOWNLOAD_TASK_LIST', downloadTaskList.results);
          commit('SET_DOWNLOAD_TASK_TOTAL', downloadTaskList.total);
        });
    },

    create({ commit }, { payload }) {
      return clark.api.downloadTask.post({ payload })
        .then((downloadTaskCreated) => {
          commit('SET_DOWNLOAD_TASK', downloadTaskCreated);
        });
    },
  },
  modules: {},
};
