/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    orderList: null,
  },
  mutations: {
    SET_ORDER_LIST(state, newData) { state.orderList = newData; },
  },
  getters: {
    getterOrderList: (state) => state.orderList,
  },
  actions: {
    report({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.order.report({ payload, httpQuery })
        .then((orderList) => {
          commit('SET_ORDER_LIST', orderList.arrayMapper('oid'));
          return orderList;
        });
    },
  },
};
