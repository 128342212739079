<template>
  <form>
    <UbuCRMListForm v-model="localNewCrmList" />

    <br>

    <div class="buttons is-right">
      <b-button
        class="is-primary"
        label="Create list & add people"
        @click="handleSubmit()"
      />
    </div>
  </form>
</template>

<script>
import { snackbarError } from '@dailyplanet/utils/helpers';

export default {
  name: 'UbuCreateCRMListForm',
  model: {
    prop: 'newCrmList',
  },
  props: {
    newCrmList: {
      type: Object,
      required: true,
    },
    crmListList: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localNewCrmList: {
      get() { return this.newCrmList; },
      set(val) { this.$emit('input', val); },
    },
  },
  methods: {
    handleSubmit() {
      if (!this.localNewCrmList.name.length) return;

      // eslint-disable-next-line max-len
      if (Object.values(this.crmListList).find(({ name }) => name === this.localNewCrmList.name)) {
        this.$buefy.snackbar.open({
          ...snackbarError,
          message: 'This name is already taken. Please choose another one.',
        });

        return;
      }

      this.$emit('submit');
    },
  },
};
</script>
