<template>
  <section>
    <b-field
      horizontal
      label="Account owner"
    >
      <UbuAutocomplete
        v-model="localAccount"
        :options="account.preset"
        :loading="loading"
        :open-on-focus="true"
        tag="router-link"
        header
        type="is-ghost"
        label="Create account"
        to="/admin/add-account"
        field="owner"
        placeholder="Search account by owner"
      />
    </b-field>

    <br>

    <template v-if="account.selected && typeof account.selected === 'object'">
      <div class="multibar">
        <h2 class="start title is-2">Account selected</h2>
        <b-button
          class="end"
          tag="router-link"
          type="is-ghost"
          label="Edit account"
          :to="`/admin/account/${link.aid}/members`"
        />
      </div>

      <br>

      <TheTable
        :lead-list="accountSelected"
        :columns="Object.values(columns)"
        :loading="loading"
        :total="total"
        :page="page"
        :per-page="perPage"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :default-sort-order="defaultSortOrder"
        :selected.sync="localSelected"
        :checked-rows.sync="checkedRows"
        :detail-key="'uid'"
        :checkable="false"
        :sticky-header="false"
      />

      <br>

      <template v-if="channelGroups">
        <div class="multibar">
          <h2 class="start title is-2">Select a workspace</h2>
        </div>

        <br>
        <template v-for="channelGroup in channelGroups.channelGroupList">
          <b-field
            v-if="channelGroup"
            :key="channelGroup.cgid"
          >
            <b-radio
              v-model="localChannelGroupSelected"
              name="channelGroupCgid"
              :native-value="{
                cgid: channelGroup.cgid,
                name: channelGroup.name,
                picture: channelGroup.picture,
              }"
            >
              {{ channelGroup.name }}
            </b-radio>
          </b-field>
        </template>
      </template>
    </template>
    <template v-if="noResult">
      <UbuNoData
        title="No account find... Yet! 🤓"
        :subtitle="`No account find with this name: ${account.value}`"
      />
    </template>
  </section>
</template>

<script>
import iconsMixins from '@dailyplanet/cross-addons/crossCommon/$mixins/dataIcons.mixins';
import ubuKaraSearchAccountColumns from '../$mixins/userKaraSearchAccountForm.columns.mixins';

export default {
  name: 'UbuKaraShopifySearchChannelGroupByAccountForm',
  mixins: [
    ubuKaraSearchAccountColumns,
    iconsMixins,
  ],
  props: {
    options: {
      type: Array,
      default: () => ([]),
    },
    account: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    channelGroups: {
      type: Object,
      default: () => ({}),
    },
    link: {
      type: Object,
      default: () => ({}),
    },
    channelGroupSelected: {
      type: Object,
      default: () => ({}),
    },
    noResult: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: 0,
      total: 0,
      page: 0,
      perPage: 50,
      sortField: 'aid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      checkedRows: [],
      selected: {},
      channelchannelGroupLinked: [],
    };
  },
  computed: {
    localAccount: {
      get() { return this.account; },
      set(newValue) { this.$emit('update:account', newValue); },
    },
    localSelected: {
      get() { return this.selected; },
      set(newValue) { this.$emit('update:selected', newValue); },
    },
    localLink: {
      get() { return this.link; },
      set(newValue) { this.$emit('update:link', newValue); },
    },
    localChannelGroupSelected: {
      get() { return this.channelGroupSelected; },
      set(newValue) { this.$emit('update:channelGroupSelected', newValue); },
    },
    accountSelected() {
      if (!this.account.selected || typeof this.account.selected !== 'object') return [];
      return [
        {
          aid: this.account.selected.aid,
          state: this.account.selected.state,
          owner: this.account.selected.owner,
        },
      ];
    },
  },
};
</script>
