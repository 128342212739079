<template>
  <section
    v-if="_currentShop && _currentShop.sid"
    class="mainPanelTable"
    style="height: calc(100vh - 90px); overflow-y: auto;"
  >
    <TheAffiliationCartouche
      v-model="filters"
      :allowed-filters="allowedFilters"
      :non-displayable-filters="nonDisplayableFilters"
      @input="setFilters($event)"
    >
      <template #actionsEnd>
        <div class="end ubuButtonSwitchView">
          <b-button
            tag="router-link"
            to="/activity/analytics/affiliation/chart"
            icon-pack="ubu"
            icon-left="ubu-overview"
            :active="$route.meta.viewType === 'chart'"
          />

          <b-button
            tag="router-link"
            to="/activity/analytics/affiliation/board"
            icon-pack="ubu"
            icon-left="ubu-list"
            :active="$route.meta.viewType === 'board'"
          />
        </div>
      </template>
    </TheAffiliationCartouche>

    <router-view
      v-if="_currentShop.sid"
      :filters.sync="filters"
      :allowed-filters="allowedFilters"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheAnalyticsAffiliation',
  data() {
    return {
      filters: [{ key: 'affiliationCode.linkedOnly', values: [true] }],
      nonDisplayableFilters: [
        'search',
        'affiliationCode.linkedOnly',
      ],
    };
  },
  computed: {
    ...mapGetters({
      _currentShop: 'TheAnalytics/currentShop',
    }),

    allowedFilters() {
      if (this.$route.meta.viewType === 'board') {
        return [
          'affiliationCode.type',
          'affiliationCode.affiliationCodeFolderAcfid',
          'affiliationCode.campaignCid',
          'totalOrders',
          'totalRevenue',
          'affiliationCode.createdOn',
          'affiliationCode.linkedOnly',
          'search',
          'order.createdOn',
          'shortLink.clickCount',
        ];
      }
      // else chart view
      return [
        'order.createdOn',
        'affiliationCode.linkedOnly',
      ];
    },
  },
  methods: {
    setFilters(filters) {
      // pour que le filtre linkedOnly soit toujours actif
      this.filters = [...filters, { key: 'affiliationCode.linkedOnly', values: [true] }];
    },
  },
};
</script>
