<template>
  <TheTabsPanel
    v-model="localActiveTab"
    @goToPreviousTab="goToPreviousTab($event)"
    @goToStart="goToTabByTabPath('root')"
    @close="closePanel()"
  >
    <template #content>
      <b-tab-item value="root">
        <div class="multibar row-vcentered">
          <UbuSearchbar
            v-model="search"
            class="start w-100 mr-2"
            @clear="search = ''"
          />

          <b-button
            label="New list"
            class="start"
            icon-pack="ubu"
            icon-left="ubu-add"
            type="is-primary"
            @click="goToTabByTabPath('root.createList')"
          />
        </div>

        <br>

        <div class="buttons">
          <UbuActionCard
            v-for="item in crmListListFiltered"
            :key="item.clid"
            :class="`mb-4 has-text-color-${item.color}`"
            :label="item.name"
            icon-pack="ubu"
            icon-left="ubu-custom-lists"
            expanded
            left-text="Click to edit"
            @click="selectList(item)"
          />
        </div>
      </b-tab-item>

      <b-tab-item value="root.createList">
        <template v-if="activeTab.tab === 'root.createList'">
          <UbuCRMListForm v-model="newCRMList" />

          <br>

          <div class="buttons is-right">
            <b-button
              label="Create"
              type="is-primary"
              @click="handleCreateList()"
            />
          </div>
        </template>
      </b-tab-item>

      <b-tab-item value="root.editList">
        <template v-if="activeTab.tab === 'root.editList'">
          <UbuCRMListForm v-model="localSelectedList" />

          <br>

          <div class="buttons is-right">
            <b-button
              label="Delete list"
              type="is-secondary"
              @click="deleteList()"
            />

            <b-button
              label="Save"
              type="is-primary"
              @click="handleEditList()"
            />
          </div>
        </template>
      </b-tab-item>
    </template>
  </TheTabsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackbarError } from '@dailyplanet/utils/helpers';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheManageCRMListPanel',
  mixins: [FilterEngineMixin],
  data() {
    return {
      search: '',
      newCRMList: {
        name: '',
        color: '1',
        scrapParams: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      crmListList: 'TheCRM/crmListList',

      accountFeatures: 'TheManageCRMListPanel/accountFeatures',
      selectedList: 'TheManageCRMListPanel/selectedList',
      activeTab: 'TheManageCRMListPanel/activeTab',
      tabItems: 'TheManageCRMListPanel/tabItems',
    }),

    localActiveTab: {
      get() { return this.activeTab; },
      set(newValue) { this.UP_activeTab(newValue); },
    },

    localSelectedList: {
      get() { return this.selectedList; },
      set(newValue) { this.UP_selectedList(newValue); },
    },

    crmListListFiltered() {
      const crmListList = Object.values(this.crmListList);

      if (!crmListList.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByName = this.search;

      return this.filterEngine(crmListList, filtersToApply);
    },
  },
  methods: {
    ...mapActions({
      _createCrmList: 'TheCRM/createCrmList',
      _updateCrmList: 'TheCRM/updateCrmList',
      _deleteCrmList: 'TheCRM/deleteCrmList',

      emitSegmentEvent: 'TheManageCRMListPanel/emitSegmentEvent',
      UP_selectedList: 'TheManageCRMListPanel/UP_selectedList',
      UP_activeTab: 'TheManageCRMListPanel/UP_activeTab',
      UP_resetStore: 'TheManageCRMListPanel/UP_resetStore',
      goToPreviousTab: 'TheManageCRMListPanel/goToPreviousTab',
      goToTabByTabPath: 'TheManageCRMListPanel/goToTabByTabPath',
    }),

    selectList(list) {
      if (!this.accountFeatures('CRM')) {
        this.emitSegmentEvent({ event: 'createCRMListNoFeature' });
        this.$intercom.showNewMessage('Hi! I would like to activate the CRM module! 🙌');
        return;
      }

      this.UP_selectedList(list);
      this.goToTabByTabPath('root.editList');
    },

    formValidation(payload) {
      if (!payload.name.length) throw Error('Please fill a list name');

      if (Object.values(this.crmListList).find(({ name }) => name === payload.name)) {
        // dans le cas d'un update on doit pouvoir edit juste la color
        // donc il ne faut pas throw si on tente de renvoyer le même name
        if (this.selectedList && this.selectedList.name === payload.name) return;

        throw Error('This name is already taken. Please choose another one.');
      }
    },

    handleCreateList() {
      return Promise.resolve()
        .then(() => this.formValidation(this.newCRMList))
        .then(() => this.createList())

        .catch((e) => {
          this.$buefy.snackbar.open({
            ...snackbarError,
            message: e.message,
          });
        });
    },

    handleEditList() {
      return Promise.resolve()
        .then(() => this.formValidation(this.localSelectedList))
        .then(() => this.editList())

        .catch((e) => {
          this.$buefy.snackbar.open({
            ...snackbarError,
            message: e.message,
          });
        });
    },

    createList() {
      this._createCrmList({ payload: this.newCRMList })
        .then(() => {
          this.goToTabByTabPath('root');
          this.resetViewData();
        });
    },

    editList() {
      this._updateCrmList({
        payload: { ...this.localSelectedList, updatedOn: this.$moment().utc().format() },
      })
        .then(() => {
          this.UP_resetStore();
          this.goToTabByTabPath('root');
        });
    },

    deleteList() {
      this._deleteCrmList({ payload: { clid: this.localSelectedList.clid } })
        .then(() => {
          this.UP_resetStore();
          this.goToTabByTabPath('root');
        });
    },

    closePanel() {
      this.UP_resetStore();
      this.$emit('close');
    },

    resetViewData() {
      this.newCRMList = {
        name: '',
        color: '1',
        scrapParams: null,
      };
    },
  },
};
</script>
