<template>
  <!-- eslint-disable max-len -->
  <section>
    <DevDetails :cnt="{name: 'localModel', obj: localModel}" />
    <b-collapse
      class="ubuSwitchGroup"
      animation="slide"
      :class="[{
        'is-not-active': !localModel.channelGroup.isActive && preview,
        'preview': preview,
      }]"
    >
      <template>
        <div class="trigger multibar">
          <b-switch
            v-if="!localModel.channelGroup.immutable"
            v-model="localModel.channelGroup.isActive"
            size="is-small"
            class="start"
            :disabled="readOnly"
            @input="reactive ? emitTurnOn({ action: 'onChannelGroup', state: $event }) : turnOffAllChannel($event)"
          >
            <UbuInfoSelector
              :picture="localModel.channelGroup.picture"
              :name="localModel.channelGroup.name"
              :initials="localModel.channelGroup.initials"
            />
          </b-switch>

          <UbuInfoSelector
            v-else
            class="start"
            :picture="localModel.channelGroup.picture"
            :name="localModel.channelGroup.name"
            :initials="localModel.channelGroup.initials"
          />

          <b-button
            class="start"
            type="is-ghost"
            icon-pack="ubu"
            :icon-left="isOpen ? 'ubu-arrow-down' : 'ubu-arrow-up'"
            @click="isOpen = !isOpen"
          />
        </div>
      </template>
      <div
        v-if="isOpen"
        class="switchGroup-content"
      >
        <template v-if="Object.values(localModel.channelList).length">
          <div
            v-for="channel in localModel.channelList"
            :key="channel.cid"
            :class="['switchGroup-content-group', {
              'is-not-active': !localModel.channelList[channel.cid].isActive && preview
            }]"
          >
            <div class="switchGroup-content-separator" />

            <b-field class="switchGroup-content-items">
              <b-switch
                v-if="!localModel.channelList[channel.cid].immutable"
                v-model="localModel.channelList[channel.cid].isActive"
                size="is-small"
                :disabled="readOnly"
                @input="reactive
                  ? emitTurnOn({ action: 'onChannel', state: $event, cid: channel.cid })
                  : turnOnChannelAndChannelGroup(channel.cid, $event)"
              >
                <UbuInfoSelector
                  type="channel"
                  :name="localModel.channelGroup.name"
                  :platform-name="channel.platformName"
                />
              </b-switch>
              <UbuInfoSelector
                v-else
                type="channel"
                :name="localModel.channelGroup.name"
                :platform-name="channel.platformName"
              />
            </b-field>
          </div>
        </template>
        <template v-else>
          <span
            class="has-text-secondary text is-3"
            style="margin-left: 66px"
          >
            This workspace doesn't have any channels connected.
          </span>
        </template>
        <template v-if="findOneChannelActive">
          <span
            class="has-text-secondary text is-3"
            style="margin-left: 66px"
          >
            No channel selected...
          </span>
        </template>
      </div>
      <div
        v-if="isOpen && !localModel.channelGroup.immutable && !reactive"
        class="multibar"
      >
        <b-button
          class="start"
          type="is-ghost"
          label="Save changes"
          :disabled="!hasChange"
          @click="$emit('updateGroup', newState)"
        />
      </div>
    </b-collapse>
  </section>
</template>

<script>
/* eslint-disable max-len */
export default {
  name: 'UbuChannelGroupAndChannelSelector',
  props: {
    model: { type: String, required: true },
    reactive: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    open: { type: Boolean, default: false },
    preview: { type: Boolean, default: false },
  },
  data() {
    return {
      localModel: { ...JSON.parse(this.model) },
      isOpen: false,
    };
  },
  computed: {
    hasChange() {
      return this.model !== JSON.stringify(this.localModel);
    },
    newState() {
      const oldState = JSON.parse(this.model);
      const newState = JSON.parse(JSON.stringify(this.localModel));
      if (oldState.channelGroup.isActive && !newState.channelGroup.isActive) newState.channelGroup.toBeUnlinked = true;
      if (!oldState.channelGroup.isActive && newState.channelGroup.isActive) newState.channelGroup.toBeLinked = true;

      Object.values(newState.channelList).forEach((channel) => {
        if (oldState.channelList[channel.cid].isActive && !channel.isActive) newState.channelList[channel.cid].toBeUnlinked = true;
        if (!oldState.channelList[channel.cid].isActive && channel.isActive) newState.channelList[channel.cid].toBeLinked = true;
      });

      return newState;
    },
    findOneChannelActive() {
      if (!this.preview) return false;

      const findOneActive = Object.values(this.localModel.channelList).find(({ isActive }) => isActive);

      if (findOneActive) return false;
      return true;
    },
  },
  mounted() {
    if (this.open) this.isOpen = this.open;
  },
  methods: {
    turnOffAllChannel(state) {
      if (!this.isOpen) this.isOpen = true;
      Object.values(this.localModel.channelList).forEach((channel) => {
        this.localModel.channelList[channel.cid].isActive = state;
      });
      this.localModel.channelGroup.isActive = state;
    },

    turnOnChannelAndChannelGroup(cid, state) {
      this.localModel.channelList[cid].isActive = state;
      if (!state) return;
      this.localModel.channelList[cid].isActive = true;
      this.localModel.channelGroup.isActive = true;
    },

    emitTurnOn({ action, state, cid }) {
      if (!this.isOpen) this.isOpen = true;

      if (action === 'onChannelGroup') {
        Object.values(this.localModel.channelList).forEach((channel) => {
          this.localModel.channelList[channel.cid].isActive = state;
        });
        this.localModel.channelGroup.isActive = state;
      }

      if (action === 'onChannel') {
        this.localModel.channelList[cid].isActive = state;
        // if (!state) return;
        // this.localModel.channelList[cid].isActive = true;
        this.localModel.channelGroup.isActive = true;
      }

      this.$emit('updateGroup', this.localModel);
    },
  },
};
</script>
