var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"ubuContactPlatformPanelItem"},[_vm._m(0),_c('div',{staticClass:"dropdowns"},[_c('TheTribePopover',{attrs:{"payload":{
        tid: _vm.contactPlatformOverview.thread ? _vm.contactPlatformOverview.thread.tid : null,
        cid: _vm.contactPlatformOverview.contactPlatform.contactCid,
        cpid: _vm.contactPlatformOverview.contactPlatform.cpid,
        customs: _vm.contactPlatformOverview.tribeMemberList,
      },"position":"is-bottom-right"}}),_c('TheTagPopover',{attrs:{"is-disabled":!_vm.contactPlatformOverview.thread,"position":"is-bottom-right"}}),_c('TheLabelPopover',{attrs:{"position":"is-bottom-left"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"multibar row-vcentered"},[_c('h3',{staticClass:"start"},[_vm._v("Attributes")])])
}]

export { render, staticRenderFns }