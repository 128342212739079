<template>
  <section
    class="ubuMessageWrapper"
    :isMe="isMe"
  >
    <div class="ubuMessageCard">
      <div class="ubuMessageCard-content">
        <div class="subtext cloud60">{{ storyFrom }}</div>
        <a
          class="button is-ghost link-start mt-2"
          :href="messageLink ? messageLink : 'https://www.instagram.com/direct/inbox/'"
          target="_blank"
        >
          View on Instagram
        </a>
        <p v-if="message.text">{{ message.text }}</p>
      </div>
      <UbuThreadMessageFooter :message="message" />
    </div>
  </section>
</template>

<script>
import MessageMixin from '@dailyplanet/mixins/MessageMixin';

export default {
  name: 'UbuThreadMessageStoryShare',
  mixins: [MessageMixin],
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    storyFrom() {
      if (this.messageLink.length) {
        const storyFrom = this.messageLink.split('/')[4];
        if (storyFrom) {
          return `Shared a story from @${storyFrom}`;
        }
      }
      return 'Shared a story';
    },
  },
};
</script>
