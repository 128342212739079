<template>
  <section class="ubuWizardView">
    <header>
      <div class="multibar arianne">
        <p class="text start is-3 has-text-secondary">Add member | Step 1/3</p>
      </div>

      <h1 class="title">Member's information</h1>
    </header>

    <form @submit.prevent="$emit('next')">
      <fieldset>
        <b-field label="First name">
          <b-input
            v-model="localModel.userDetail.firstName"
            placeholder="First Name ..."
            required
            @input="$update('firstName', $event)"
          />
        </b-field>

        <b-field label="Last name">
          <b-input
            v-model="localModel.userDetail.lastName"
            required
            placeholder="Last Name ..."
            @input="$update('lastName', $event)"
          />
        </b-field>

        <b-field label="Email">
          <b-input
            v-model="localModel.authenticate.passName"
            placeholder="Email ..."
            type="email"
            required
            @input="$update('email', $event)"
          />
        </b-field>

        <!-- <b-field label="Auto generate password">
          <b-input
            :value="localModel.authenticate.passPhrase"
            placeholder="Password ..."
            required
            readonly
            type="password"
            password-reveal
          />
        </b-field> -->

        <br>

        <div class="buttons is-right">
          <b-button
            class="is-primary"
            label="Next"
            native-type="submit"
          />
        </div>
      </fieldset>
    </form>
  </section>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuUserInfosForm',
  mixins: [VModelMixin],
  model: {
    prop: 'model',
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localModel: {
      get() { return { ...this.model }; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
};
</script>
