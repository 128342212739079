<template>
  <form>
    <b-field label="Name">
      <b-input
        :ref="refName"
        v-model="localModel.name"
        required
        placeholder="New Tribe name ..."
        @input="$update('name', $event)"
      />
    </b-field>

    <br>

    <b-field label="Color">
      <UbuColorPickerInline
        v-model="localModel.color"
        @input="$update('color', String($event))"
      />
    </b-field>

    <br>

    <div class="buttons is-right">
      <b-button
        v-if="localModel.tid"
        type="is-secondary"
        label="Delete Tribe"
        icon-pack="ubu"
        icon-left="ubu-trash"
        @click="confirmDelete()"
      />

      <b-button
        type="is-primary"
        label="Save"
        native-type="submit"
        @click="submit()"
      />
    </div>
  </form>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'UbuTribeForm',
  mixins: [VModelMixin],
  model: {
    prop: 'model',
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      refName: uuidv4(),
    };
  },
  computed: {
    localModel: {
      get() { return { ...this.model }; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
  mounted() {
    this.$refs[this.refName].focus();
  },
  methods: {
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: 'Delete Tribe',
        message: 'Are you sure you want to <b>Delete this tribe</b>? This cannot be undone.',
        confirmText: 'Delete Tribe',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('delete'),
      });
    },

    submit() {
      if (!this.localModel.name.length) return;
      this.$emit('triggerForm');
    },
  },
};
</script>
