<!-- eslint-disable max-len -->
<template>
  <div v-if="cpo[column.field] && cpo[column.field].state && cpo[column.field].state === 'CREATED'" class="pastille is-warning" />

  <div v-else-if="cpo[column.field]" class="pastille is-active" />

  <div v-else class="pastille" />
</template>

<script>
export default {
  name: 'UbuTablePastille',
  props: {
    cpo: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
