<template>
  <b-field>
    <b-upload
      v-model="localDropedFile"
      drag-drop
      accept=".csv"
      required
      expanded
    >
      <section class="section">
        <div class="content">
          <p>
            <b-icon
              pack="ubu"
              icon="ubu-download"
              size="is-large"
            />
          </p>
          <p class="upload-text">{{ uploaderText }}</p>
        </div>
      </section>
    </b-upload>
  </b-field>
</template>

<script>
export default {
  name: 'UbuDragAndDropUploader',
  model: {
    prop: 'dropedFile',
  },
  props: {
    dropedFile: {
      type: File,
      default: null,
    },
    uploaderText: {
      type: String,
      default: 'Drop your file here',
    },
    acceptedExtension: {
      type: String,
      default: '',
    },
  },
  computed: {
    localDropedFile: {
      get() { return this.dropedFile; },
      set(val) { this.$emit('input', val); },
    },
  },
};
</script>

<style>

</style>
