<template>
  <!-- eslint-disable max-len -->
  <section class="ubuWizardView">
    <header>
      <div class="multibar arianne">
        <p class="text start is-3 has-text-secondary">Add member | Step 3/3</p>
      </div>

      <h1 class="title">Review</h1>

      <template v-if="error">
        <br>

        <b-notification
          type="is-danger"
          aria-close-label="Close notification"
          role="alert"
          icon-pack="ubu"
          has-icon
          icon="ubu-failure"
          @close="$emit('removeError')"
        >
          An error has occurred.
          <br>
          Please check that all fields are filled in correctly and that you have given access to at least one channel
        </b-notification>
      </template>
    </header>

    <form @submit.prevent="$emit('addMember')">
      <div class="card">
        <div class="card-content">
          <h3 class="subtitle">Member's information</h3>

          <fieldset>
            <b-field label="First name:">
              <span>{{ user.userDetail.firstName }}</span>
            </b-field>

            <b-field label="Last name:">
              <span>{{ user.userDetail.lastName }}</span>
            </b-field>

            <b-field label="Email:">
              <span>{{ user.authenticate.passName }}</span>
            </b-field>
          </fieldset>
        </div>
      </div>

      <br>

      <div class="card">
        <div class="card-content">
          <h3 class="subtitle">Member's rights</h3>

          <fieldset>
            <b-field label="Organization role:">
              <span>{{ role.capitalize() }}</span>
            </b-field>

            <b-field label="Giving access to:">
              <div class="is-flex is-flex-direction-column">
                <div
                  v-for="(row) in editableItem"
                  :key="row.channelGroup.cgid"
                >
                  <UbuChannelGroupAndChannelSelector
                    :model="JSON.stringify(row)"
                    read-only
                    reactive
                    open
                    preview
                  />
                </div>
              </div>
            </b-field>
          </fieldset>
        </div>
      </div>

      <br>

      <div class="buttons is-right">
        <b-button
          class="is-primary"
          label="Add member"
          native-type="submit"
        />
      </div>
    </form>
  </section>
</template>

<script>
/* eslint-disable max-len */
export default {
  name: 'UbuUserReviewForm',
  props: {
    user: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    editableItem: {
      type: Object,
      required: true,
    },
    error: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
