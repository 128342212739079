<template>
  <b-icon
    :class="['start small', {
      'has-text-color-2': isActive,
      'has-text-secondary': !isActive,
    }]"
    pack="ubu"
    icon="ubu-circle-divider"
  />
</template>

<script>
export default {
  name: 'UbuIconCircleState',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
