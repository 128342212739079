<template>
  <aside class="ubuSidePanel ubuPanel-xl">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        :to="`/admin/account/${currentAid}/details`"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <div class="multibar">
      <h2 class="start title is-1">Add features</h2>
      <div class="buttons end">
        <b-button
          type="is-primary"
          label="View history"
          tag="router-link"
          :to="`/admin/account/${currentAid}/history`"
        />
      </div>
    </div>

    <br>

    <DevDetails :cnt="{name: 'accountFeature', obj: accountFeature}" />
    <DevDetails :cnt="{name: '_featureList', obj: _featureList}" />
    <DevDetails :cnt="{name: 'addFeatures', obj: addFeatures}" />
    <DevDetails :cnt="{name: 'removeFeatures', obj: removeFeatures}" />

    <section>
      <div class="is-flex is-justify-content-space-between">
        <section class="card ubuCheckboxSwitch">
          <div class="card-content">
            <h3 class="subtitle is-3">All features</h3>

            <br>

            <form>
              <fieldset>
                <template v-if="featuresToBeAdded.length">
                  <div class="multibar">
                    <div class="buttons end">
                      <b-button
                        type="is-ghost"
                        :label="addFeatures.length !== featuresToBeAdded.length
                          ? 'Select all'
                          : 'Deselect all'"
                        class="end"
                        @click="addFeatures.length !== featuresToBeAdded.length
                          ? selectAll('addFeatures')
                          : addFeatures = []"
                      />
                    </div>
                  </div>

                  <br>

                  <template v-for="feature in allFeatures">
                    <b-field
                      v-if="!feature.selected"
                      :key="feature.name"
                      :label="feature.label"
                      horizontal
                      class="featureCheckbox"
                    >
                      <b-checkbox
                        v-model="addFeatures"
                        :native-value="feature"
                      />
                    </b-field>
                  </template>
                </template>
                <span
                  v-else
                  class="has-text-secondary text"
                >
                  All features was added
                </span>
              </fieldset>
            </form>
          </div>
        </section>

        <div class="is-flex is-flex-direction-column is-justify-content-center">
          <b-tooltip
            label="Add features"
            animated
            position="is-right"
          >
            <b-button
              type="is-primary"
              icon-pack="ubu"
              icon-left="ubu-arrow-right"
              class="m-2"
              :disabled="addFeatures.length ? false : true"
              @click="featuresRequest('addFeatures')"
            />
          </b-tooltip>
          <b-tooltip
            label="Remove features"
            animated
            position="is-left"
          >
            <b-button
              type="is-primary"
              icon-pack="ubu"
              icon-left="ubu-arrow-left"
              class="m-2"
              :disabled="removeFeatures.length ? false : true"
              @click="featuresRequest('removeFeatures')"
            />
          </b-tooltip>
        </div>

        <section class="card ubuCheckboxSwitch">
          <div class="card-content">
            <h3 class="subtitle is-3">Features added to account</h3>

            <br>

            <form>
              <fieldset>
                <template v-if="featuresToBeRemoved.length">
                  <div class="multibar">
                    <div class="buttons end">
                      <b-button
                        type="is-ghost"
                        :label="removeFeatures.length !== featuresToBeRemoved.length
                          ? 'Select all'
                          : 'Deselect all'"
                        class="end"
                        @click="removeFeatures.length !== featuresToBeRemoved.length
                          ? selectAll('removeFeatures')
                          : removeFeatures = []"
                      />
                    </div>
                  </div>

                  <br>

                  <template v-for="feature in allFeatures">
                    <b-field
                      v-if="feature.selected"
                      :key="feature.name"
                      :label="feature.label"
                      horizontal
                      class="featureCheckbox"
                    >
                      <b-checkbox
                        v-model="removeFeatures"
                        :native-value="feature"
                      />
                    </b-field>
                  </template>
                </template>
                <span
                  v-else
                  class="has-text-secondary text is-3"
                >
                  No features was added
                </span>
              </fieldset>
            </form>
          </div>
        </section>
      </div>
      <template v-if="error">
        <br>

        <UbuHelper
          type="is-danger"
          :content="error"
          content-type="is-danger"
        />
      </template>
    </section>
  </aside>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheAdminAccountFeatures',
  data() {
    return {
      addFeatures: [],
      removeFeatures: [],
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      _featureList: 'TheAdmin/featureList',
      accountFeature: 'TheAdmin/accountFeature',
    }),
    currentAid() {
      return this.$route.params.aid;
    },
    featuresToBeAdded() {
      return this.allFeatures.filter((el) => !el.selected);
    },
    featuresToBeRemoved() {
      return this.allFeatures.filter((el) => el.selected);
    },
    allFeatures() {
      const allFeatures = this.accountFeature.map(({ featureName }) => featureName);

      return Object.values(this._featureList).reduce((acc, row) => {
        acc.push({
          ...row,
          selected: !!allFeatures.includes(row.name),
        });
        return acc;
      }, []);
    },
  },
  mounted() {
    if (!Object.values(this._featureList).length) this.reportFeatureList({});
  },
  methods: {
    ...mapActions({
      reportFeatureList: 'TheAdmin/reportFeatureList',
      setFeatures: 'TheAdmin/setFeatures',
      unsetFeatures: 'TheAdmin/unsetFeatures',
    }),
    selectAll(action) {
      this[action] = [];
      this[action] = this[action].concat(action === 'addFeatures'
        ? this.allFeatures.filter((el) => !el.selected)
        : this.allFeatures.filter((el) => el.selected));
    },
    featuresRequest(action) {
      if (!this[action].length) {
        this.error = 'No features selected';
      } else {
        this.error = null;
        const payload = {
          aid: this.currentAid,
          features: this[action].map(({ selected, ...features }) => features),
        };

        switch (action) {
          case 'addFeatures':
            this.$buefy.dialog.confirm({
              title: `Add feature${payload.features.length > 1 ? 's' : ''}`,
              message: `You are about to add ${payload.features.length > 1 ? 'many ' : 'a '}new feature${payload.features.length > 1 ? 's' : ''} to this account. Are you sure you want to continue?`,
              confirmText: 'Yes',
              cancelText: 'No',
              type: 'is-danger',
              hasIcon: true,
              onConfirm: () => this.setFeatures({ payload })
                .then(() => {
                  this.addFeatures = [];
                }),
            });
            break;
          case 'removeFeatures':
            this.$buefy.dialog.confirm({
              title: `Remove feature${payload.features.length > 1 ? 's' : ''}`,
              message: `You are about to remove ${payload.features.length > 1 ? 'many ' : 'a '}feature${payload.features.length > 1 ? 's' : ''} from this account. Are you sure you want to continue?`,
              confirmText: 'Yes',
              cancelText: 'No',
              type: 'is-danger',
              hasIcon: true,
              onConfirm: () => this.unsetFeatures({ payload })
                .then(() => {
                  this.removeFeatures = [];
                }),
            });
            break;
          default:
            this.error = 'Action is not recognised';
            break;
        }
      }
    },
  },
};
</script>
