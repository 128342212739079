/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  getters: {
    ...getters.searchInstagramUsername,
  },
  actions: {
    ...actions.thread,
    ...actions.searchInstagramUsername,
  },
};
