/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const activityMention = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/activityMention`, method: 'REPORT', payload, httpQuery, target }),
};

export default activityMention;
