<template>
  <b-button
    class="ubuActionCard"
    type="button"
    v-bind="$attrs"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <div class="multibar row-vcentered">
      <div
        v-if="socialIcon"
        class="start mr-3"
      >
        <component
          :is="`UbuIcon${socialIcon.capitalize()}`"
          font-size="20px"
        />
      </div>

      <span class="start text">{{ label }}</span>

      <slot name="rightItem" />

      <template v-if="hasLoading && isLoading">
        <b-icon
          v-show="isLoading"
          pack="ubu"
          icon="ubu-refresh"
          size="is-large"
          class="spining end"
        />
      </template>

      <template v-else>
        <span
          v-if="leftText"
          :class="['end subtext leftText', { 'displayed': displayLeftText }]"
        >
          <span :class="leftTextType">{{ leftText }}</span>
        </span>

        <template v-if="info">
          <b-tooltip
            v-if="infoTooltip"
            class="end bulkTooltip"
            position="is-left"
            type="is-light"
          >
            <div class="is-flex has-text-secondary">
              <span>{{ infoLabel }}</span>
              <b-icon
                pack="ubu"
                icon="ubu-information"
              />
            </div>
            <template v-slot:content>
              <slot name="info" />
            </template>
          </b-tooltip>

          <div
            v-else
            class="end is-flex has-text-secondary"
          >
            <span>{{ infoLabel }}</span>
          </div>
        </template>

        <slot
          name="left-content"
          class="end subtext leftText"
        />

        <template v-if="isChecked">
          <b-icon
            pack="ubu"
            icon="ubu-close"
            class="end has-text-success"
          />
        </template>
      </template>
    </div>
  </b-button>
</template>

<script>
export default {
  name: 'UbuActionCard',
  props: {
    label: {
      type: String,
      default: '',
    },
    leftText: {
      type: String,
      default: '',
    },
    leftTextType: {
      type: String,
      default: '',
    },
    socialIcon: {
      type: String,
      default: null,
    },
    info: {
      type: Boolean,
      default: false,
    },
    infoTooltip: {
      type: Boolean,
      default: true,
    },
    displayLeftText: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    infoLabel: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasLoading: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
