/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../engine';

const target = 'clark';

const channelGroup = {
  post: ({ payload, httpQuery }) => builder({ url: '/api/channelGroup/', method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}`, method: 'PUT', payload, httpQuery, target }),
  askUgcPermission: {
    create: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/askUgcPermission`, method: 'POST', payload, httpQuery, target }),
  },
  channel: {
    create: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/channel`, method: 'POST', payload, httpQuery, target }),
    update: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/channel/${payload.cid}`, method: 'PUT', payload, httpQuery, target }),
  },
  connectMetaChannel: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/connectMetaChannel`, method: 'POST', payload, httpQuery, target }),
  },
  tryConnectMeta: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/manageChannel/${payload.cgid}/tryConnectMeta`, method: 'POST', payload, httpQuery, target }),
  },
  contact: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/contact`, method: 'REPORT', payload, httpQuery, target }),
    get: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/contact/${payload.cid}`, method: 'GET', payload, httpQuery, target }),
    note: {
      report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/contact/${payload.cid}/note`, method: 'REPORT', payload, httpQuery, target }),
    },
  },
  contactPrivateMetadata: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/contactPrivateMetadata/`, method: 'REPORT', payload, httpQuery, target }),
    put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/contactPrivateMetadata/${payload.cpmid}`, method: 'PUT', payload, httpQuery, target }),
    post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/contactPrivateMetadata`, method: 'POST', payload, httpQuery, target }),
    get: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/contactPrivateMetadata/${payload.cpmid}`, method: 'GET', payload, httpQuery, target }),
  },
  counter: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/inboxCounters`, method: 'REPORT', payload, httpQuery, target }),
  },
  threadList: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/threadList`, method: 'REPORT', payload, httpQuery, target }),
  },
  crmList: {
    get: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/crmList/${payload.clid}`, method: 'GET', payload, httpQuery, target }),
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/crmList`, method: 'REPORT', payload, httpQuery, target }),
    post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/crmList`, method: 'POST', payload, httpQuery, target }),
    put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/crmList/${payload.clid}`, method: 'PUT', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/crmList/${payload.clid}`, method: 'DELETE', payload, httpQuery, target }),
    fillList: {
      post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/crmList/${payload.clid}/fillList`, method: 'POST', payload, httpQuery, target }),
    },
    fillListByContactPlatform: {
      post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/crmList/${payload.clid}/fillListByContactPlatform`, method: 'POST', payload, httpQuery, target }),
    },
    removeFromList: {
      delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/crmList/${payload.clid}/removeFromList`, method: 'DELETE', payload, httpQuery, target }),
    },
  },
  exportAnalyticsXlsx: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/exportAnalyticsXlsx`, method: 'POST', payload, httpQuery, target }),
  },
  privateNote: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/privateNote`, method: 'POST', payload, httpQuery, target }),
    put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/privateNote/${payload.pnid}`, method: 'PUT', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/privateNote/${payload.pnid}`, method: 'DELETE', payload, httpQuery, target }),
  },
  reportDetailledActivityContactPanel: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/reportDetailledActivityContactPanel/${payload.cpid}`, method: 'REPORT', payload, httpQuery, target }),
    get: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/reportDetailledActivityContactPanel/${payload.aid}`, method: 'GET', payload, httpQuery, target }),
  },
  reportDetailledContactGroupContactPlatform: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/reportDetailledContactGroupContactPlatform`, method: 'REPORT', payload, httpQuery, target }),
  },
  searchContactPlatformUsername: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/searchContactPlatformUsername/${payload.username}`, method: 'REPORT', payload, httpQuery, target }),
  },
  template: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/template`, method: 'REPORT', payload, httpQuery, target }),
    post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/template`, method: 'POST', payload, httpQuery, target }),
    put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/template/${payload.tid}`, method: 'PUT', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/template/${payload.tid}`, method: 'DELETE', payload, httpQuery, target }),
  },
  threadAndParticipant: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/threadAndParticipant`, method: 'REPORT', payload, httpQuery, target }),
  },
  user: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/user`, method: 'REPORT', payload, httpQuery, target }),
  },
  bulkThreadsState: {
    put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/bulkThreadsState`, method: 'PUT', payload, httpQuery, target }),
  },
  bulkThreadAppoint: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/bulkThreadAppoint`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/bulkThreadAppoint`, method: 'DELETE', payload, httpQuery, target }),
  },
  instagramAccountNewFollowers: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/instagramAccountNewFollowers`, method: 'REPORT', payload, httpQuery, target }),
  },
  instagramAccountImpressions: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/instagramAccountImpressions`, method: 'REPORT', payload, httpQuery, target }),
  },
  analyticsInbox: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/analyticsInbox`, method: 'REPORT', payload, httpQuery, target }),
  },
  hashtag: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/hashtag`, method: 'REPORT', payload, httpQuery, target }),
    get: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/hashtag/${payload.hashtag}`, method: 'GET', payload, httpQuery, target }),
    post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/hashtag`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/hashtag/${payload.hashtag}`, method: 'DELETE', payload, httpQuery, target }),
  },
  accountAnalytics: {
    get: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/accountAnalytics`, method: 'GET', payload, httpQuery, target }),
  },
  followersEngagementAnalytics: {
    get: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/followersEngagementAnalytics`, method: 'GET', payload, httpQuery, target }),
  },
  searchInstagramUsername: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/searchInstagramUsername/${payload.username}`, method: 'REPORT', payload, httpQuery, target }),
  },
};

export default channelGroup;
