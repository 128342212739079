<!-- eslint-disable max-len -->
<template>
  <section>
    <b-tab-item value="root.addToCrmList">
      <UbuSearchbar
        v-if="activeTab.tab === 'root.addToCrmList'"
        v-model="search"
        @clear="search = ''"
      />

      <br>

      <div class="buttons">
        <UbuActionCard
          v-for="list in crmListFiltered"
          :key="list.clid"
          :class="`mb-4 has-text-color-${list.color}`"
          :label="list.name"
          icon-pack="ubu"
          icon-left="ubu-custom-lists"
          expanded
          @click="selectListAndContinue(list)"
        />
      </div>
    </b-tab-item>

    <b-tab-item value="root.addToCrmList.alreadyInCrmList">
      <TheAddToCrmListAlreadyInCrmList
        v-if="activeTab.tab === 'root.addToCrmList.alreadyInCrmList'"
        @next="UP_changeToNewTab(tabItems['root.addToCrmList.alreadyInCrmList.toKeep'])"
      />
    </b-tab-item>

    <b-tab-item value="root.addToCrmList.alreadyInCrmList.toKeep">
      <TheAddToCrmListToAdd
        v-if="activeTab.tab === 'root.addToCrmList.alreadyInCrmList.toKeep'"
        @next="addToList()"
      />
    </b-tab-item>
  </section>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import TheAddToCrmListMixin from '../mixins/TheAddToCrmList.mixin';

export default {
  name: 'TheAddToCrmList',
  mixins: [
    TheAddToCrmListMixin,
  ],
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Object,
      required: true,
    },
    tabItems: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      crmListList: 'TheCRM/crmListList',

      newBulkAction: 'TheAddToCrmList/newBulkAction',
      willBeAddedToCrmList: 'TheAddToCrmList/willBeAddedToCrmList',
    }),

    localChecked: {
      get() { return this.checkedRows; },
      set(value) { this.$emit('input', value); },
    },

    localActiveTab: {
      get() { return this.activeTab; },
      set(val) { this.$emit('update:activeTab', val); },
    },

    crmListSorted() {
      return Object.values(this.crmListList)
        .sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
    },
  },
  methods: {
    ...mapActions({
      UP_selectedCps: 'TheAddToCrmList/UP_selectedCps',
      UP_newBulkAction: 'TheAddToCrmList/UP_newBulkAction',
      UP_resetAddToCrmListStore: 'TheAddToCrmList/UP_resetStore',

      fillListByContactPlatform: 'TheCRM/fillListByContactPlatform',
    }),
    UP_changeToNewTab(activeTab) {
      this.$emit('update:activeTab', activeTab);
    },

    selectListAndContinue(list) {
      this.UP_selectedCps(this.localChecked);
      this.UP_newBulkAction({ clid: list.clid });
      this.UP_changeToNewTab(this.tabItems['root.addToCrmList.alreadyInCrmList']);
    },

    addToList() {
      return this.fillListByContactPlatform({
        payload: {
          clid: this.newBulkAction.clid,
          contactPlatformList: this.willBeAddedToCrmList.map(({ cpid }) => ({ cpid })),
        },
      })
        .then(() => {
          this.$buefy.snackbar.open({
            message: 'People added to list',
            type: 'is-success',
            position: 'is-top',
          });

          this.$emit('done');
        });
    },
  },
};
</script>
