/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const monitor = {
  reportTask: ({ payload, httpQuery }) => builder({ url: '/api/monitor/task', method: 'REPORT', payload, httpQuery, target }),
  rescheduleTask: ({ payload, httpQuery }) => builder({ url: `/api/monitor/task/${payload.tid}`, method: 'PUT', payload, httpQuery, target }),
};

export default monitor;
