<template>
  <section
    id="CrewAdmin"
    class="ubuSettingView start"
  >
    <div class="multibar">
      <h1 class="start title is-1">Reward</h1>
    </div>

    <br>

    <aside class="mainPanelFilters">
      <div class="multibar row-vcentered">
        <UbuSearchbar
          v-model="inputSearch"
          class="start mr-2"
          placeholder="Search a user..."
          @clear="clearSearchbar()"
        />

        <TheFilterDropdown
          v-model="filters"
          :filter-list="filtersCatalog"
          :non-displayable-filters="nonDisplayableFilters"
          :selected-segment-filter.sync="selectedSegmentFilter"
          class="start mr-2"
        />

        <template v-if="filters.length">
          <b-button
            class="start"
            label="Clear filters"
            type="is-ghost is-borderless"
            @click="deleteAllFilters()"
          />
        </template>
      </div>

      <template v-if="filters.length">
        <div class="activeFilterList">
          <UbuDropdownFilterForm
            v-for="(filter) in filters.filter(({ key }) => !nonDisplayableFilters.includes(key)) "
            :key="filter.id"
            v-model="filtersMapped[filter.id]"
            class="mb-1"
            :is-not-allowed="!allowedFilters.includes(filter.key)"
            :catalog-filter="genericCatalog"
            @handle="handleFilterUpdate($event)"
            @deleteFilter="deleteFilter(filter.id)"
          />
        </div>
      </template>
    </aside>

    <br>

    <div class="mainPanelTable">
      <b-table
        ref="table"
        :data="mappedReportReward"
        :detail-key="detailKey"
        detailed
        :opened-details="openedDetails"
        class="table-row-clickable table-full-height"
        hoverable
        sticky-header
        focusable
        paginated
        backend-pagination
        :total="totalRewardList"
        :per-page="perPage"
        pagination-size="is-small"
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @page-change="pageChange"
        @sort="onSort"
      >
        <b-table-column
          v-for="column in columns"
          :key="column.field"
          :field="column.field"
          :label="column.label"
          :sortable="column.isSortable"
          :visible="column.visible"
        >
          <template #default="props">
            <div v-if="column.cmp == 'CustomTableDate'">
              <p v-if="props.row.period">{{ $moment(props.row.period).format('YYYY/MM') }}</p>
              <p v-else>-</p>
            </div>

            <div v-else-if="column.cmp == 'CustomTablePastille'">
              <div v-if="props.row.hasIssue" class="pastille" />
              <div v-else>-</div>
            </div>

            <component
              :is="column.cmp"
              v-else
              :cpo="props.row"
              :field="column.field"
              :column="column"
            />
          </template>
        </b-table-column>

        <b-table-column
          v-slot="props"
          label="Actions"
          :visible="columns.actions.visible"
          numeric
        >
          <b-button
            type="is-danger"
            :disabled="props.row.reviewedOn || props.row.rewardAskedOn || props.row.paidOn"
            @click="confirmRebuildBalance(props.row)"
          >
            <span>Rebuild</span>
          </b-button>
        </b-table-column>

        <template #empty>
          <p class="has-text-secondary">No rewards</p>
        </template>

        <template #detail="props">
          <div
            open
            class="JsonReader"
          >
            <JsonReader :obj="props.row || {}" />
          </div>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import templatizeMixin from '@dailyplanet/cross-addons/filters/_mixins/templatize.mixin';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';
import { debounce } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheReward',
  mixins: [TableUtilsMixin, templatizeMixin],
  props: {},
  data() {
    return {
      filters: [],
      allowedFilters: [
        'contactPlatform.username',
        'channelGroup.name',
        'affiliationReward.paidType',
        'totalCommissionFee',
        'totalRevenue',
        'affiliationReward.createdOn',
        'affiliationReward.reviewedOn',
        'affiliationReward.rewardAskedOn',
        'affiliationReward.paidOn',
      ],
      openedDetails: [],
      nonDisplayableFilters: [],
      selectedSegmentFilter: null,
      inputSearch: '',
      page: 1,
      perPage: 50,
      detailKey: 'arid',
      sortField: 'affiliationReward.createdOn',
      sortOrder: 'desc',
      /* eslint-disable object-curly-newline */
      columns: {
        channelGroupName: { field: 'channelGroup.name', col: 'channelGroup.name', cmp: 'UbuTableSimple', label: 'Workspace', visible: true, isSortable: true },
        contactPlatformUserName: { field: 'contactPlatform.username', col: 'contactPlatform.username', label: 'Username', visible: true, isSortable: true, cmp: 'UbuTableSimple' },
        contactPlatformPlatform: { field: 'contactPlatform.platformName', col: 'contactPlatform.platformName', label: 'Platform', visible: true, isSortable: true, cmp: 'UbuTablePlatform' },
        createdOn: { field: 'affiliationReward.createdOn', col: 'createdOn', label: 'Generated On', visible: true, isSortable: true, cmp: 'UbuTableDate' },
        period: { field: 'affiliationReward.period', col: 'createdOn', label: 'Period', visible: true, isSortable: true, cmp: 'CustomTableDate' },
        hasIssue: { field: 'affiliationReward.hasIssue', col: 'hasIssue', label: 'Issue', visible: true, isSortable: true, cmp: 'CustomTablePastille' },
        totalRevenue: { field: 'affiliationReward.totalRevenue', col: 'totalRevenue', label: 'Total Revenue', visible: true, isSortable: true, cmp: 'UbuTableNumberDecimalFormatter', before: '$' },
        totalCommissionFee: { field: 'affiliationReward.totalCommissionFee', col: 'totalCommissionFee', label: 'Total CommissionFee', visible: true, isSortable: true, cmp: 'UbuTableNumber', before: '$' },
        actions: { visible: true },
      },
    };
  },

  computed: {
    ...mapGetters({
      rewardList: 'TheReward/rewardList',
      totalRewardList: 'TheReward/totalRewardList',
    }),
    mappedReportReward() {
      return Object.values(this.rewardList);
    },

    sorting() {
      if (this.sortField === 'period') {
        return `createdOn,${this.sortOrder}`;
      }

      return `${this.sortField},${this.sortOrder}`;
    },
    filtersCatalog() {
      return Object.values(this.genericCatalog)
        .filter(({ key }) => this.allowedFilters.includes(key));
    },

    filtersMapped() {
      return this.filters.arrayMapper('id');
    },
  },
  watch: {
    filters() { return this.reportDatas(); },
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (searchTxt) {
      if (searchTxt.length > 3) {
        this.reportDatas(searchTxt);
      }
      if (searchTxt.length === 0) {
        this.reportDatas('');
      }
    }, 500),
  },
  mounted() {
    return this.reportDatas();
  },
  methods: {
    ...mapActions({
      _reportRewards: 'TheReward/reportReward',
      _rebuildBalances: 'TheReward/rebuildBalances',
    }),

    clearSearchbar() {
      this.inputSearch = '';
    },
    deleteFilter(event) {
      const filters = this.filters.filter(({ id }) => id !== event);
      this.filters = filters;
    },
    deleteAllFilters() {
      this.filters = [];
      this.selectedSegmentFilter = null;
    },
    handleFilterUpdate(filter) {
      // replace old filter by new & redefine filters array
      const newFilters = {
        ...this.filters.arrayMapper('id'),
        [filter.id]: filter,
      };

      this.filters = Object.values(newFilters);
    },
    reportDatas(searchTxt) {
      const filtersToUse = [...this.filters];

      return this._reportRewards({
        payload: {},
        httpQuery: {
          userSearch: searchTxt || '',
          pagin: `${this.page - 1},${this.perPage}`,
          sorting: this.sorting,
          filters: this.prepareFiltersToRequest(this.allowedFilters, filtersToUse),
        },
      });
    },
    confirmRebuildBalance(row) {
      this.$buefy.dialog.confirm({
        title: 'Rebuild Balance',
        message: `Are you sure you want rebuild balance for <b>${row
          .contactPlatform.username}</b> this reward?`,
        confirmText: 'Rebuild Balance',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => {
          this.rebuildBalances(row)
            .then(() => {
              this.$buefy.snackbar.open({
                type: 'is-success',
                position: 'is-bottom-right',
                message: 'Rebuild balance is success!',
              });
            });
        },
      });
    },

    rebuildBalances(row) {
      return this._rebuildBalances({
        payload: {
          arid: row.arid,
        },
      });
    },
  },
};
</script>
