<template>
  <div
    :class="['ubuMentionCard', { 'is-story': isStory}, { 'is-focused': focused}]"
    @click="click($event)"
  >
    <div
      v-if="loading || isMediaLoading"
      class="ubuMentionCard_skeleton"
    >
      <b-skeleton
        height="100%"
      />
    </div>
    <div
      v-if="mention"
      class="ubuMentionCard-content"
      :style="contentStyle"
    >
      <div
        v-if="mention.mainMedia.type === 'VIDEO' && !isMediaLoading"
        :class="['ubuMentionCard-overlayPlayButton', { 'is-playing': isVideoPlaying }]"
      >
        <b-icon
          :icon="isVideoPlaying ? 'pause' : 'play'"
          class="playButton"
          @click.native.stop="togglePlay()"
        />
      </div>
      <div
        class="ubuMentionCard-header multibar"
        @click.stop
      >
        <b-checkbox
          v-if="checkable"
          v-model="localChecked"
          class="ubuMentionCard-checkbox start"
        />
        <UbuFavButton
          v-if="mention.favorite !== undefined"
          class="end"
          :active="!!mention.favorite.publicationPid"
          @click="$emit('toggleFavorite', $event)"
        />
      </div>

      <b-image
        v-if="showFallback"
        :src="srcFallback"
        lazy
        :ratio="isStory ? '4by5' :'4by5'"
      />
      <b-image
        v-else-if="mention.mainMedia.thumbnail && !mention.mainMedia.type === 'VIDEO'"
        :src="mention.mainMedia.thumbnail"
        lazy
        :ratio="isStory ? '4by5' :'4by5'"
        :src-fallback="srcFallback"
        @load="isMediaLoading = false;"
        @error="isMediaLoading = false;"
      />
      <video
        v-else-if="mention.mainMedia.type === 'VIDEO'"
        ref="video"
        muted
        @loadeddata="isMediaLoading = false;"
        @ended="isVideoPlaying = false"
      >
        <source
          :src="mention.mainMedia.url"
          :type="'video/mp4'"
          @error="isMediaLoading = false; showFallback = true;"
        >
        Sorry, your browser doesn't support embedded videos.
      </video>
      <b-image
        v-else
        :src="mention.mainMedia.url"
        :src-fallback="srcFallback"
        lazy
        :ratio="isStory ? '4by5' :'4by5'"
        @load="isMediaLoading = false;"
        @error="isMediaLoading = false;"
      />
      <div
        v-if="mention.mention.mentionType === 'POST'"
        :class="['ubuMentionCard-right', {
          shouldDisplay: focused
        }]"
      >
        <div class="columns has-text-centered w-100">
          <div class="column">
            <p>💘</p>
            <p>{{ mention.publication.likes | nFormatter }}</p>
          </div>
          <div class="column">
            <p>💬</p>
            <p>{{ mention.publication.comments | nFormatter }}</p>
          </div>
          <div class="column">
            <p>🎯</p>
            <p>{{ mention.publication.engagement | nFormatter }}%</p>
          </div>
        </div>
      </div>
      <div class="ubuMentionCard-footer">
        <div class="is-flex is-align-items-center">
          <component
            :is="`UbuIcon${mention.contactPlatform.platformName.capitalize()}`"
            class="start"
            font-size="16px"
          />
          <span
            class="subtext is-3 ml-1 text-truncate"
            @click="$emit('selectContactPlatform', mention.contactPlatform.cpid)"
          >
            @{{ mention.contactPlatform.username }}
          </span>
        </div>
        <div class="content-meta">
          <span
            v-if="mention.publication.createdOn"
            class="text-truncate"
          >
            {{ mention.publication.createdOn | toDate }}
          </span>
          <b-icon
            v-if="mention.publication.createdOn && showType"
            pack="ubu"
            icon="ubu-circle-divider"
            class="small"
          />
          <span v-if="showType">{{ mention.mention.mentionType | capitalize }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const srcFallback = require('@dailyplanet/assets/images/image-placeholder.png');

export default {
  name: 'UbuNewMentionCard',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    mention: {
      type: Object,
      default: null,
    },
    isStory: {
      type: Boolean,
      default: false,
    },
    checkable: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    showType: {
      type: Boolean,
      default: true,
    },
    contentStyle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMediaLoading: true,
      srcFallback,
      showFallback: false,
      isVideoPlaying: false,
    };
  },
  computed: {
    localChecked: {
      get() {
        // console.log('🚀 ~ file: UbuNewMentionCard.vue ~ line 170 ~ get ~ this.mention', this.mention);
        return this.checked;
      },
      set(newValue) {
        this.$emit('check', { val: newValue, mention: this.mention });
      },
    },
  },
  methods: {
    click(event) {
      if (this.loading) return;
      this.$emit('click', event);
    },
    togglePlay() {
      if (this.showFallback) this.click();
      if (!this.$refs.video) return;
      if (this.isVideoPlaying) this.$refs.video.pause();
      else this.$refs.video.play();
      this.isVideoPlaying = !this.isVideoPlaying;
    },
  },
};
</script>
