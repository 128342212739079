/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  getters: {
    ...getters.authenticate,
    ...getters.account,
    ...getters.reportDetailledUser,
    ...getters.userDetail,
  },
  actions: {
    ...actions.authenticate,
    ...actions.reportDetailledUser,
    ...actions.userDetail,
    up_authenticateOneTime({ dispatch }) {
      const action = {
        dispatch,
        target: 'Authenticate/up_authenticateOneTime',
        context: {},
        textNotifier: 'update authenticate',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to update authenticate.', ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
  },
};
