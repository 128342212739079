import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    campaignMentionList: {},
  },
  mutations: {
    SET_CAMPAIGN_MENTION_LIST(state, { campaignMentionList }) {
      state.campaignMentionList = { ...state.campaignMentionList, ...campaignMentionList };
    },
    RESET_STORE(state) {
      state.campaignMentionList = {};
    },
    RESET_CAMPAIGN_MENTION_LIST(state) { state.campaignMentionList = {}; },
  },
  getters: {
    getterCampaignMentionList: (state) => state.campaignMentionList,
  },
  actions: {
    UP_resetCampaignMentionList({ commit }) {
      commit('RESET_CAMPAIGN_MENTION_LIST');
    },

    report({ commit, rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'REPORT_DETAILLED_CAMPAIGN.REPORT')) return undefined;

      return clark.api.apiPublication.campaignMention.report({ payload, httpQuery })
        .then((campaignMention) => {
          const { 'ApiContact/contactPlatformOverview/getterContactPlatformOverviewList': contactPlatformOverviewList } = rootGetters;
          const { results } = campaignMention;

          commit('SET_CAMPAIGN_MENTION_LIST', { campaignMentionList: results.reduce((acc, row) => ({ ...acc, [row.pid]: row.cpid }), {}) });

          const mentionsByCp = results.reduce((acc, row) => {
            const {
              campaignActivity,
              contact,
              contactPlatform,
              contactPlatformStatistic,
              labels,
              tags,
              ...restMention
            } = row;
            if (!acc[row.cpid]) {
              acc[row.cpid] = {
                cpid: restMention.cpid,
                campaignActivity,
                contact,
                contactPlatform,
                contactPlatformStatistic,
                labels,
                tags,
                mentionList: {
                  [restMention.pid]: restMention,
                },
              };
              return acc;
            }
            acc[row.cpid].mentionList[restMention.pid] = restMention;
            return acc;
          }, {});

          commit('ApiContact/contactPlatformOverview/SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
            ...contactPlatformOverviewList,
            ...mentionsByCp,
          }, { root: true });

          return campaignMention;
        });
    },
  },
};
