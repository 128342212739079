import { use, registerComponent } from '@dailyplanet/utils/plugins';

/* eslint-disable no-multi-spaces */

import UbuExportAnalyticsPanel from './UbuExportAnalyticsPanel';

// Analytics
import UbuAnalyticsGrid from './Analytics/UbuAnalyticsGrid';
import UbuAnalyticsPostCard from './Analytics/UbuAnalyticsPostCard';

// Hashtags
import UbuHashtagsFilters from './Hashtags/UbuHashtagsFilters';
import UbuMentionsHashtagsMediaCard from './Hashtags/UbuMentionsHashtagsMediaCard';
import UbuHashtagsViewMedia from './Hashtags/UbuHashtagsViewMedia';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuExportAnalyticsPanel);

    // Analytics
    registerComponent(Vue, UbuAnalyticsGrid);
    registerComponent(Vue, UbuAnalyticsPostCard);

    // Hashtags
    registerComponent(Vue, UbuHashtagsFilters);
    registerComponent(Vue, UbuMentionsHashtagsMediaCard);
    registerComponent(Vue, UbuHashtagsViewMedia);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuExportAnalyticsPanel,

  // Analytics
  UbuAnalyticsGrid,
  UbuAnalyticsPostCard,

  // Hashtags
  UbuHashtagsFilters,
  UbuMentionsHashtagsMediaCard,
  UbuHashtagsViewMedia,
};
