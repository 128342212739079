<template>
  <section class="ubuWizardView ubuSidePanel ubuPanel-xl ubuChannelAdd">
    <header>
      <div class="multibar row-vcentered">
        <b-button
          class="start is-ghost"
          icon-pack="ubu"
          icon-left="ubu-arrow-left"
          label="Back"
          tag="router-link"
          to="/setting/channel/addMeta"
        />
        <b-button
          tag="router-link"
          to="/setting/channel"
          class="end is-simple-icon is-borderless is-large"
          icon-pack="ubu"
          icon-left="ubu-cross"
        />
      </div>
      <p class="text is-3 has-text-secondary">Connect Meta channels</p>

      <h1 class="title is-1">Meta Login</h1>
    </header>

    <DevDetails :cnt="{name: 'currentMode', obj: currentMode}" />

    <br>

    <p>Please Log in with Facebook in order to add a new channel</p>

    <br>

    <p>
      <em>
        The account you are login in should be administrator of a Facebook Page,
        which is linked to an Instagram Business account.
      </em>
    </p>

    <br>

    <div
      class="multibar"
    >
      <div
        class="fb-login-button"
        data-width=""
        data-size="large"
        data-button-type="login_with"
        data-layout="rounded"
        data-auto-logout-link="false"
        data-use-continue-as="true"
        :scope="requiredScopes.join(',')"
        onlogin="checkLoginState();"
      />
    </div>

    <br>

    <p>
      <em>If you already integrated your channel and you want to be
        sure to have new access please delete Ubu from your
        <a
          class="has-text-primary"
          target="_blank"
          href="https://www.facebook.com/settings?tab=business_tools&ref=business_login_reentry"
        >Business Integrations panel</a> and reconnect it
      </em>
    </p>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
// import dataset from './stores/fbConnect.dataset';

export default {
  name: 'TheSettingChannelsAddMetaConnectMeta',
  data() {
    return {
      requiredScopes: [
        'email',
        'pages_show_list',
        'ads_management',
        'instagram_basic',
        'instagram_manage_comments',
        'instagram_manage_insights',
        'instagram_manage_messages',
        'pages_manage_ads',
        'pages_read_user_content',
        'pages_manage_engagement',
        'public_profile',
        'pages_manage_metadata',
        'pages_read_engagement',
        'pages_read_engagement',
        'pages_messaging',
      ],
      FB: null,
      isSDKLoaded: false,
      isInitDone: false,
      // fbConnect: dataset,
    };
  },
  computed: {
    currentMode() {
      return process.env.VUE_APP_MODE;
    },
  },
  created() {
    this.injectSdk();
    window.checkLoginState = this.checkLoginState;
  },
  destroyed() {
    console.log('🚀 ~ file: TheConnectInstagram.vue ~ line 105 ~ destroyed ~ destroyed');
    this.destroySDK();
  },
  methods: {
    // app Ubu 872069696931713
    // app Ubu test 1 2096822140451602
    ...mapActions({
      createFacebookConnect: 'TheSettingWorkspaceSetting/createFacebookConnect',
    }),
    injectSdk() {
      const self = this;
      const fjs = document.getElementsByTagName('script')[0];
      const tag = document.createElement('script');
      tag.id = 'facebook-jssdk';
      tag.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(tag, fjs);

      // eslint-disable-next-line func-names
      window.fbAsyncInit = function () {
        self.init();
      };
    },
    destroySDK() {
      document.getElementsByTagName('script')[0].remove();
      console.log('🚀 ~ file: TheConnectInstagram.vue ~ line 120 ~ destroySDK ~ destroySDK');
      document.getElementById('facebook-jssdk').remove();
      delete window.FB;
      delete window.checkLoginState;
    },
    init() {
      this.FB = window.FB;
      window.FB.init({
        appId: process.env.VUE_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v12.0',
      });
      window.FB.AppEvents.logPageView();
    },
    checkLoginState() {
      window.FB.getLoginStatus((response) => {
        console.log('🚀 ~ file: TheConnectMeta.vue ~ line 170 ~ window.FB.getLoginStatus ~ response', response);
        const { status, authResponse } = response;
        if (status === 'connected') {
          return this.createFacebookConnect({
            payload: { shortLiveToken: authResponse.accessToken },
          })
            .then((res) => {
              console.log('🚀 ~ file: TheConnectMeta.vue ~ line 165 ~ .then ~ res', res);
              this.$buefy.dialog.confirm({
                message: 'Awesome you\'re now connected to Meta.',
                confirmText: 'Let\'s create your channels',
                type: 'is-success',
                onConfirm: () => this.$router.push('/setting/channel/selectFacebook'),
              });
            });
        }

        return this.$buefy.snackbar.open({
          message: 'Unable to connect to Facebook.',
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 5000,
          indefinite: true,
        });
      });
    },
  },
};
</script>
