<template>
  <div
    id="TheLeadsGeneration"
    class="card"
  >
    <header class="card-header">
      <p class="card-header-title">
        Leads Generation
      </p>
      <div class="card-header-icon buttons">
        <b-button
          tag="router-link"
          to="/sales/ubuLead/leadsGeneration/scrap"
          type="is-link"
          label="Scraping"
          :outlined="!isRouteActive('/sales/ubuLead/leadsGeneration/scrap')"
        />
        <b-button
          tag="router-link"
          to="/sales/ubuLead/leadsGeneration/suggestions"
          type="is-link"
          label="Suggestions"
          :outlined="!isRouteActive('/sales/ubuLead/leadsGeneration/suggestions')"
        />
        <b-button
          v-if="isRouteActive('/sales/ubuLead/leadsGeneration/suggestions')"
          type="is-info"
          :icon-left="settingVisible ? 'minus' : 'plus'"
          label="results filters"
          @click="settingVisible = !settingVisible"
        />
      </div>
    </header>

    <router-view :setting-visible="settingVisible" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheLeadsGeneration',
  data() {
    return {
      settingVisible: false,
    };
  },
  mounted() {
    return this.reportScrapingZone();
  },
  methods: {
    ...mapActions({
      reportScrapingZone: 'TheLeadsGeneration/reportScrapingZone',
    }),
    isRouteActive(route) {
      return route === this.$route.path;
    },
  },
};
</script>

<style lang="scss">
#TheLeadsGeneration {
  // max-width: calc(100vw - 261px);
  // height: calc(100vh - 55.5px);
  // max-height: calc(100vh - 55.5px);
  // overflow-y: auto;
}
</style>
