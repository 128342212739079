import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

/* eslint-disable max-len */
export default {
  namespaced: true,
  getters: {
    ...getters.theme,
  },
  actions: {
    ...actions.theme,
  },
};
