<template>
  <div>
    <h1 class="title is-1">Edit state account</h1>

    <br>

    <form @submit.prevent="$emit('editEmail')">
      <fieldset>
        <b-field label="State of account">
          <b-select
            v-model="newAccount.state"
            :placeholder="account.state"
          >
            <option
              v-for="state in statesOptions"
              :key="state"
              :value="state"
            >
              {{ state }}
            </option>
          </b-select>
        </b-field>
      </fieldset>

      <br>

      <div class="buttons is-right">
        <b-button
          label="Save"
          class="is-primary"
          :disabled="newAccount.state ? false : true"
          @click="$emit('updateAccount')"
        />
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: 'UbuAccountDetailsEdit',
  model: {
    prop: 'newAccount',
  },
  props: {
    newAccount: { type: Object, required: true },
    account: { type: Object, required: true },
  },
  computed: {
    statesOptions() {
      const states = ['PAYING', 'PENDING', 'TRIAL'];

      if (!states.includes(this.account.state)) return states;
      return states.filter((state) => state !== this.account.state);
    },
  },
};
</script>
