var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"multibar row-vcentered"},[_c('UbuVectorIcon',{staticClass:"start mr-2",attrs:{"icon":{
      pack: 'ubu',
      icon: `ubu-social-shopify`,
      iconPaths: 3,
      fontSize: '35'
    }}}),_c('div',{staticClass:"start"},[_c('h3',{staticClass:"text is-3"},[_vm._v("Shopify "),_c('b-tag',{attrs:{"type":"is-primary"}},[_vm._v("Beta")])],1),(Object.values(_vm.shopifyShopList).length)?_vm._l((_vm.shopifyShopList),function(shop){return _c('div',{key:shop.sid,staticClass:"multibar row-vcentered"},[_c('b-icon',{class:['start', {
            'has-text-success': shop.isConnected,
            'has-text-primary': !shop.isConnected,
          }],attrs:{"icon":"circle-small"}}),_c('p',{staticClass:"subtext is-3 has-text-secondary start text-truncate"},[_vm._v(_vm._s(shop.extrefId))]),_c('b-button',{staticClass:"start",attrs:{"label":"Reconnect store","type":"is-ghost is-borderless"},on:{"click":function($event){return _vm.$emit('openShopifyConnectPanel', shop)}}})],1)}):_vm._e()],2),_c('b-button',{staticClass:"end",attrs:{"label":"Connect a store","type":"is-secondary"},on:{"click":function($event){return _vm.emitEvent()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }