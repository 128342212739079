<template>
  <div id="app">
    <div
      v-if="routeHasMenu"
      class=" is-narrow"
    >
      <!-- <TheSidebar /> -->
      <TheMenu />
    </div>
    <div
      id="coreView"
      :class="['', {
        'is-reduced': !isReduced,
        'has-menu': routeHasMenu
      }]"
    >
      <div class="multibar multibar-column">

        <!-- <router-view /> -->
        <router-view v-if="allowAccessTo" />
        <div
          v-else
        >
          <div class="multibar column">
            <div class="multibar">
              <b-message
                :title="refuseAccess.title"
                type="is-warning"
                :closable="false"
              >
                <p
                  v-for="(reason, index) in refuseAccess.reason"
                  :key="index"
                >
                  {{ reason }}
                </p>
              </b-message>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="TheNotifierBoard">
      <TheNotifierBoard />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import SocketMixin from '@dailyplanet/mixins/SocketMixin.kara';

const {
  VUE_APP_BUILD_DATE: appBuildDate,
  VUE_APP_VERSION: appVersion,
} = process.env;

// eslint-disable-next-line no-console
console.log(`
                                  ,,,,
        ,]░░░░░╥    ███▌     ███▌ ║███
       ]░░░░░░░░╦   ███▌     ███▌ ║███
       ]░░░░░░░░░   ███▌     ███▌ ║██████████   ████    ████
 .╥]░░░░░░░░░░░░░   ███▌     ███▌ ║████▀▀▀████▌ ████    ████
.░░░░░░░░░░░░░░░░   ███▌     ███▌ ║████    ████ ████    ████
]░░░░░░░░░░░░░░░░   ████▄  ▄████  ║████   ▄████ ║███▄  ▄████
 ╨]░░░░░░░░░░░░░░    ▀█████████   ║██████████▀   ▀██████████
    ''''''''''''        ╙╙╙"-     '└└└ └╙╙"        ╙╙╙└ '└└'

App version ${appVersion} builded on ${moment(appBuildDate).format('YYYY-MM-DD HH:mm:ss')}
`);

export default {
  name: 'App',
  mixins: [SocketMixin],
  data() {
    return {
      refuseAccess: {
        title: 'Connexion Required',
        reason: ['You must be connected to access.'],
      },
      segmentRequiredOnce: false,
    };
  },
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheAuthenticate/myAuthenticate',
      isReduced: 'TheMenu/getterIsReduced',
      currentUser: 'TheAuthenticate/myAuthenticate',
    }),
    routeHasMenu() {
      return this.$route.meta.hasMenu;
    },
    allowAccessTo() {
      const { meta } = this.$route;

      if (meta && meta.requireAuth) {
        if (!this.myAuthenticate) return false;

        if (process.env.VUE_APP_MODE !== 'dev') {
          // if (this.socketIsConnected === false) {
          //   this.connect(true)
          // }

          if (!this.segmentRequiredOnce){
              this.bootSegment();
            }
        }

        if (meta.requiredRights) {
          const { rights } = this.myAuthenticate;
          const matchedRights = Object.keys(rights)
          // eslint-disable-next-line max-len
            .reduce((acc, right) => (meta.requiredRights.includes(right) ? [...acc, right] : acc), []);

          const willAllow = matchedRights.length === meta.requiredRights.length;
          if (!willAllow) {
            this.updateRefuseAccess({
              title: 'Rights Required',
              reason: [
                `You don't have required right(s) to access this page. (${this.$route.name})`,
                `${meta.requiredRights.join(', ')}`,
                'Contact administrator.',
              ],
            });
          }
          return willAllow;
        }
      }
      return true;
    },
  },
  mounted() {
    document.title = 'Ubu Admin';
  },
  methods: {
    updateRefuseAccess(refuseAccess) {
      this.refuseAccess = refuseAccess;
    },
    bootSegment() {
      this.segmentRequiredOnce = true;

      const account = this.currentUser.accountList.find(({ aid }) => aid === this.currentUser.setting.defaultAccountAid);
      
      // const lastEvent = this.accountEvent.reduce((acc, row) => {
      //   if (!acc) { acc = row; return acc; }
      //   if (this.$moment(row.createdOn).isAfter(this.$moment(acc.createdOn))) { acc = row; return acc; }
        
      //   return acc;
      // }, {});

      return this.$segment.identify(this.currentUser.uid, {
        firstName: this.currentUser.userDetail.firstName,
        lastName: this.currentUser.userDetail.lastName,
        email: this.currentUser.userDetail.email,
        userUid: this.currentUser.uid,
        accountAid: account.aid,
        accountName: account.owner,
        state: account.state,
        linkToKara: `https://omega.getubu.com/users/user/${this.currentUser.uid}/details`,
        // stateLastUpdated: lastEvent ? lastEvent.accountState : null,
        phoneNumber: this.currentUser.userDetail.phone,
      })
    },
  },
};
</script>

<style lang="scss">
// @import '@/styles/colors';
// @import '@/styles/common';
// @import '@/styles/fonts';
// @import '@/styles/ellipsis';
// @import '@/styles/forms';
// @import '@/styles/utils';
// @import '@/styles/ui';
// @import '@/styles/variables';
// @import '@/assets/ubu-icons/style';
// @import '@/styles/buefy';
// @import '@/styles/custom/custom';
@import '../../modules/Ubuefy/scss/index';

html, body {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: var(--seashellpeach);
}

// html {
//   background-color: var(--soapstone) !important;
// }

// html,
// body,
// #app {
//   height: 100vh;
//   max-height: 100vh;
//   overflow: auto;
//   width: 100%;
//   padding: 0;
//   margin: 0;
//   color: var(--cloudburst);
// }

#app {
  overflow-y: auto !important;
  background-color: var(--soapstone);
  color: var(--cloudburst);
  & > .column {
    padding: 0;
  }
  & #coreView {
    padding: 0;
    // width: 100%;
    // height: calc(100vh - 52.5px);
    &.has-menu {
      @include default-bk {
        margin-left: 240px;
      }
      @include small-bk {
        margin-left: 70px;
      }
    }
    // & > .multibar > *:not(:first-child) {
    //   margin-top: 55px;
    // }
  }
  & #TheNotifierBoard {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 20;
    background-color: var(--soapstone);
    height: 100vh;
    max-height: 100vh;
    border-left: 1px solid var(--givry);
    & > div {
      padding: 5px;
    }
    max-width: 300px;
  }
}
// ::-webkit-scrollbar {
//     /* width: 5px; */
//     width: 5px;
//     margin-left: -5px;
// }
// ::-webkit-scrollbar-track {
//     background: transparent;
//     /* width: 5px; */
//     width: 0px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #4e567426;
//   border-radius: 50px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #4e5674;
// }

// .plain-container {
//   // width: 100%;
//   height: calc(100vh - 52.5px);
//   // & > .multibar.column {
//   //   height: inherit;
//   // }
// }
</style>
