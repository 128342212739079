<template>
  <article class="ubuContactPlatformPanelItem">
    <header class="multibar row-vcentered">
      <h3 class="start">Crew</h3>

      <span v-if="crewUser && crewUser.state === 'CREATED'" class="has-text-secondary">
        PENDING
      </span>

      <div :class="`end pastille ${pastilleClass}`" />
    </header>

    <div v-if="crewUser && crewUser.state !== 'CREATED'" class="contentContainer">
      <div class="multibar row-vcentered">
        <p class="start mr-2 has-text-secondary">Full name:</p>

        <p class="start">{{ crewUser.firstName }} {{ crewUser.lastName }}</p>
      </div>
      <div class="multibar row-vcentered">
        <p class="start mr-2 has-text-secondary">Email:</p>

        <p class="start">{{ crewUser.email }}</p>

        <b-icon
          pack="ubu"
          icon="ubu-copy"
          class="start clickable"
          @click.native="copyToClipBoard(crewUser.email)"
        />
      </div>
    </div>
  </article>
</template>

<script>
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'UbuContactPlatformPanelCrewUser',
  props: {
    contactPlatformOverview: {
      type: Object,
      required: true,
    },
  },
  computed: {
    crewUser() {
      return this.contactPlatformOverview.crewUser;
    },

    pastilleClass() {
      if (this.crewUser && this.crewUser.state === 'CREATED') return 'is-warning';
      if (this.crewUser) return 'is-active';
      return '';
    },
  },
  methods: {
    copyToClipBoard(value) {
      navigator.clipboard.writeText(value);

      this.$buefy.snackbar.open({
        message: 'Copied to clipboard.',
        ...snackbarSuccess,
      });
    },
  },
};
</script>
