/* eslint-disable max-len */
import theme from './theme';

export default {
  namespaced: true,

  modules: {
    theme,
  },
};
