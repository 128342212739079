import channelSms from './channelSms.store';
import threadAppoint from './threadAppoint.store';
import instagramReach from './instagramReach.store';
import privateNote from './privateNote.store';
import translateText from './translateText.store';
import template from './template.store';
import templateFolder from './templateFolder.store';
import threadList from './threadList.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    channelSms,
    threadAppoint,
    instagramReach,
    privateNote,
    template,
    templateFolder,
    threadList,
    translateText,
  },
};
