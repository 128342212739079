<template>
  <section>
    <DevDetails :cnt="{name: 'labelListToAdd', obj: labelListToAdd }" />

    <header>
      <p class="text has-text-secondary">Add contact label</p>

      <h1 class="title is-1">{{ tabTitle }}</h1>
    </header>

    <br>

    <b-tabs
      v-model="localActiveTab"
      class="no-header"
    >
      <b-tab-item>
        <div class="buttons is-right">
          <b-button
            label="Next"
            type="is-primary"
            :disabled="!labelListToAdd.length"
            @click="localActiveTab += 1"
          />
        </div>

        <UbuActionCard
          v-for="cg in contactGroupList"
          :key="cg.cgid"
          :class="`mb-4 has-text-color-${cg.color}`"
          :label="cg.label"
          icon-pack="ubu"
          icon-left="ubu-custom-contact"
          expanded
          :is-checked="!!labelListToAdd.find(({ cgid }) => cgid === cg.cgid)"
          @click="selectToAddOrRemove(cg)"
        />
      </b-tab-item>

      <b-tab-item>
        <div class="buttons is-right">
          <b-button
            v-if="hasContactToAddToLabel"
            label="Add selected"
            type="is-primary"
            :disabled="!labelListToAdd.length"
            @click="bulkAssignAndChangeTab()"
          />
          <b-button
            v-else
            label="Done"
            type="is-primary"
            @click="buttonRedirectToCrm()"
          />
        </div>

        <UbuHelper
          v-if="!hasContactToAddToLabel"
          content="All selected contacts are already linked to the selected contact labels"
          type="is-success"
          content-type="success"
          icon="ubu-information"
          class="mb-5"
        />

        <form>
          <b-field>
            <b-input
              v-model="inputSearch"
              placeholder="Search..."
              type="search"
              icon="ubu-search"
              icon-pack="ubu"
              icon-right="ubu-cross"
              icon-right-clickable
              @icon-right-click="inputSearch = ''"
            />
          </b-field>
        </form>

        <br>

        <template v-if="Object.values(summaries).length">
          <div class="multibar row-vcentered">
            <b-icon
              pack="ubu"
              icon="ubu-information"
              class="start"
            />
            <span class="start text">
              People already registered in the contact labels
            </span>
          </div>

          <br>

          <b-collapse
            v-for="(item, index) in Object.values(summaries)"
            :key="index"
            anmiation="fade"
            :open="isOpen == index"
            class="mb-4"
            @open="isOpen = index"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-expanded="props.open"
              >
                <div class="multibar w-100">
                  <div class="start">
                    <b-icon
                      pack="ubu"
                      icon="ubu-custom-contact"
                      :class="`has-text-color-${item.color}`"
                    />
                    <span class="title is-3">{{ item.label }}</span>
                  </div>
                  <div class="end">
                    <b-icon
                      pack="ubu"
                      :icon="props.open ? 'ubu-arrow-up': 'ubu-arrow-down'"
                    />
                  </div>
                </div>
              </div>
            </template>

            <br>

            <b-tabs
              type="is-toggle-rounded"
              class="ubuTabs"
            >
              <template v-for="(platform, i) in platforms">
                <b-tab-item
                  v-if="platform.visible"
                  :key="i"
                  label="Pictures"
                  icon="google-photos"
                  class="mb-3"
                >
                  <template #header>
                    <component
                      :is="`UbuIcon${platform.value.capitalize()}`"
                      class="start"
                      font-size="20"
                    />
                    <span class="ml-3">{{ platform.value.capitalize() }}</span>
                  </template>

                  <template v-if="item[platform.value]">
                    <UbuPlatformCard
                      v-for="username in item[platform.value]"
                      :key="`${i}_${username}_${platform.value}_UbuPlatformCard`"
                      :platform-name="platform.value"
                      :text="username"
                      font-size="20px"
                      class="ml-3"
                    />
                  </template>
                  <UbuNoData
                    v-else
                    :subtitle="`No selected contact is linked to ${platform.value.capitalize()} platform`"
                  />
                </b-tab-item>
              </template>
            </b-tabs>
          </b-collapse>
        </template>
        <UbuNoData
          v-else
          class="noContactToBulkAddtoContactLabel"
          :title="noData.title"
          :subtitle="noData.subtitle"
          action
          :button-is="noData.buttonIs"
          :type="noData.type"
          :label="noData.label"
          :event-name="noData.eventName"
          @resetSearch="inputSearch = ''"
          @addSelected="bulkAssignAndChangeTab()"
        />
      </b-tab-item>

      <b-tab-item>
        <div class="buttons is-right">
          <b-button
            label="Close"
            type="is-primary"
            @click="buttonRedirectToCrm()"
          />
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { debounce } from '@dailyplanet/utils/helpers';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheBulkActionsAddToContactLabel',
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      labelListToAdd: [],
      isOpen: false,
      inputSearch: '',
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      contactGroupList: 'TheCRM/contactGroupList',
    }),
    localChecked: {
      get() { return this.checkedRows; },
      set(value) { this.$emit('input', value); },
    },
    localActiveTab: {
      get() { return this.activeTab; },
      set(val) { this.$emit('update:activeTab', val); },
    },
    tabTitle() {
      let title;

      switch (this.activeTab) {
        case 0: title = 'Add to contact label'; break;
        case 1: title = 'Summary'; break;
        case 2: title = 'Success!'; break;

        default: title = 'Add to contact label'; break;
      }

      return title;
    },
    goBackToLabel() {
      const nameOfCurrentPath = this.$route.name.split(' - ')[0];
      return `Go back to ${nameOfCurrentPath}`;
    },
    prepareData() {
      return this.labelListToAdd.reduce((acc, cg) => {
        const toBeLabeled = this.localChecked.reduce((a, row) => {
          // contact has no tag
          if (!row.labels) {
            return a.concat([row.cid]);
          }
          // contact has tag but not this
          if (!row.labels.find((label) => String(label.cgid) === String(cg.cgid))) {
            return a.concat([row.cid]);
          }

          acc.rejected.push(...row.contactPlatform.reduce((acc2, el) => {
            const username = el.username.toLowerCase();
            if (this.search) {
              if (username.includes(this.search.toLowerCase())) { acc2.push(({ ...el, assigned: { ...cg } })); }
              return acc2;
            }

            acc2.push(({ ...el, assigned: { ...cg } }));
            return acc2;
          }, []));

          return a;
        }, []);

        if (!toBeLabeled.length) return acc;

        // regroup cid that can be assigned, by cgid
        acc[cg.cgid] = {
          cids: toBeLabeled,
        };

        return acc;
      }, {
        rejected: [],
      });
    },
    platforms() {
      return [
        { value: 'INSTAGRAM', visible: true },
        { value: 'FACEBOOK', visible: true },
        { value: 'TIKTOK', visible: false },
        { value: 'YOUTUBE', visible: false },
        { value: 'SMS', visible: false },
      ];
    },
    noData() {
      if (this.search) {
        return {
          title: 'No contacts matching your search',
          subtitle: '',
          action: true,
          buttonIs: 'event',
          eventName: 'resetSearch',
          type: 'is-primary',
          label: 'Reset search',

        };
      }
      return {
        title: 'Nothing to report',
        subtitle: 'All selected contacts are not related to selected the contact labels',
        action: true,
        buttonIs: 'event',
        eventName: 'addSelected',
        type: 'is-primary',
        label: 'Add selected',
      };
    },
    hasContactToAddToLabel() {
      const { rejected, ...prepareData } = this.prepareData;
      return Object.values(prepareData).length;
    },
    summaries() {
      const { rejected } = this.prepareData;

      return rejected.reduce((acc, row) => {
        const { assigned, platformName, username } = row;

        if (!acc[assigned.cgid]) {
          acc[assigned.cgid] = {
            ...assigned,
            [platformName]: [username],
          };
          return acc;
        }

        if (!acc[assigned.cgid][platformName]) {
          acc[assigned.cgid][platformName] = [username];
          return acc;
        }

        acc[assigned.cgid][platformName].push(username);

        return acc;
      }, {});
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      if (!newVal) this.search = '';

      if (!newVal || !newVal.length || newVal.length < 3) return;

      this.search = newVal;
    }, 250),
  },
  methods: {
    ...mapActions({
      assignContactGroup: 'TheCRM/assignContactGroup',
    }),
    selectToAddOrRemove(label) {
      const alreadyIn = this.labelListToAdd.findIndex(({ cgid }) => cgid === label.cgid);

      if (!this.labelListToAdd.length || alreadyIn < 0) this.labelListToAdd.push(label);
      else this.labelListToAdd.splice(alreadyIn, 1);
    },
    changeTab(currentTab) {
      this.$emit('isWizardApplied', false);
      this.localActiveTab = currentTab + 1;
    },
    bulkAssignAndChangeTab() {
      /**
       * for each label in labelListToAdd we must check if
       * contactPlat from iteration is not already linked
       *
       * we must promise all for each cgid give cids available
       *
       */

      const { prepareData: { rejected, ...prepareData } } = this;

      return Promise.resolve()
        .then(() => {
          // forEach label assign contact available
          Object.entries(prepareData).forEach(([key, value]) => this.assignContactGroup({
            payload: { cgid: key, cids: value.cids },
          }));
        })
        .then(() => { this.changeTab(this.activeTab); });
    },
    buttonRedirectToCrm() {
      this.$emit('reloadCRM');
      this.$router.push({ path: `/${this.$route.path.split('/')[1]}`, query: this.$route.query });
    },
  },
};
</script>
