<template>
  <TheSimplePanel>
    <template #header>
      <h1 class="title">Edit workspace</h1>
    </template>

    <template #content>
      <form @submit.prevent="$emit('nextTab')">
        <UbuWorkspaceInfoForm
          v-if="localWorkspaceToEdit"
          v-model="localWorkspaceToEdit"
        />
        <br>

        <div class="content">
          <div class="buttons is-right">
            <b-button
              label="Cancel"
              type="is-secondary"
              tag="router-link"
              to="/setting/organization"
            />

            <b-button
              label="Save change"
              type="is-primary"
              native-type="submit"
              @click="requestUpdateChannelGroup"
            />
          </div>
        </div>
      </form>
    </template>
  </TheSimplePanel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheSettingEditWorkspace',
  data() {
    return {
      localWorkspaceToEdit: null,
      success: false,
    };
  },
  computed: {
    ...mapGetters({
      myChannelGroups: 'TheSettingWorkspaceSetting/myChannelGroups',
    }),
    currentCgid() {
      return this.$route.params.cgid;
    },
    channelGroupToEdit() {
      return this.myChannelGroups.find(({ cgid }) => Number(cgid) === Number(this.currentCgid));
    },
  },
  mounted() {
    this.localWorkspaceToEdit = { ...this.channelGroupToEdit };
  },
  methods: {
    ...mapActions({
      updateChannelGroup: 'TheSettingOrganizationSetting/updateChannelGroup',
    }),
    requestUpdateChannelGroup() {
      const { picture, ...rest } = this.localWorkspaceToEdit;

      const optionsBase = {
        duration: 3000,
        position: 'is-top',
        type: 'is-success',
      };

      this.updateChannelGroup({
        payload: {
          ...rest,
          picture: picture ? picture.name : null,
        },
      })
        .then(() => {
          this.$buefy.snackbar.open({
            message: 'Channel group was updated !',
            ...optionsBase,
          });
        });

      this.$router.push('/setting/organization');
    },
  },
};
</script>
