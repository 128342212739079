import Errors from '@dailyplanet/utils/Errors';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, genericErrorHandler, snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {},
  actions: {
    tryFetchReportByUsername({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiDownload/iqdataReport/tryFetchReportByUsername',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'try fetch iqdata report',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to get influencer infos by PDF.', indefinite: true }));
    },

    createReport({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiDownload/iqdataReport/createReport',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'create iqdata report',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch((e) => {
          let message = 'Unable to get influencer infos by PDF.';
          if (e.message === 'E_EXTERNAL_ERROR_IQDATA_RETRY_LATER') message = 'We are updating data for this account. Try again in a few minutes, please.';
          if (e.message === 'E_EXTERNAL_ERROR_IQDATA_TARGET_NOT_FOUND') message = 'Can\t find this influencer...';

          Snackbar.open({ ...snackBarFailure, message, indefinite: true });
          throw new Errors(e);
        });
    },
  },
};
