<template>
  <section>
    <b-field class="control">
      <b-input
        v-clipboard:copy="invitation.url"
        v-clipboard:success="onCopy"
        :value="invitation.url"
        readonly
        icon-right="content-copy"
      />
    </b-field>
    <p class="subtext has-text-secondary">
      It will expired in 2 hours.
      Sharing this link with your teammates will give them access to this channel.
    </p>
  </section>
</template>

<script>
export default {
  name: 'UbuInvitationLink',
  model: {
    prop: 'invitation',
  },
  props: {
    invitation: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onCopy() {
      this.$buefy.snackbar.open({
        message: 'Copied to clipboard.',
        type: 'is-success',
        position: 'is-top',
        duration: 5000,
      });
    },
  },
};
</script>
