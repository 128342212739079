<template>
  <div class="columns is-centered">
    <b-table
      class="column is-4"
      :data="rights.map((rid) => ({ rid }))"
      detail-key="rid"
      paginated
      :per-page="10"
    >
      <b-table-column
        v-slot="{ row: { rid } }"
        field="rid"
        label="ID"
        searchable
      >
        {{ rid }}
      </b-table-column>

      <b-table-column
        v-slot="{ row: { rid } }"
        label="Actions"
        numeric
      >
        <b-button
          type="is-danger"
          icon-right="delete"
          @click="confirmRightDelete(rid)"
        />
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'UbuKaraDetailledUsersRights',
  props: {
    rights: {
      type: Array,
      required: true,
    },
  },
  methods: {
    confirmRightDelete(rid) {
      this.$buefy.dialog.confirm({
        title: 'Deleting rigth',
        // eslint-disable-next-line max-len
        message: `Are you sure you want to <b>delete</b> this right (${rid}) ? This action cannot be undone.`,
        confirmText: 'Delete rigth',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$emit('removeRight', rid);
          this.$buefy.toast.open('rigth deleted!');
        },
      });
    },
  },
};
</script>
