/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    scrapingZoneList: {},
  },
  mutations: {
    SET_SCRAPING_ZONE_LIST(state, newDatas) { state.scrapingZoneList = newDatas; },
    UPDATE_SCRAPING_ZONE_LIST(state, newDatas) {
      const myScrapingZoneList = Object.values(state.scrapingZoneList).concat(newDatas).arrayMapper('szid');
      state.scrapingZoneList = myScrapingZoneList;
    },
    DELETE_SCRAPING_ZONE(state, newData) {
      state.scrapingZoneList = Object.values(state.scrapingZoneList).filter(({ szid }) => szid !== newData.szid).arrayMapper('szid');
    },
  },
  getters: {
    getterScrapingZoneList: (state) => state.scrapingZoneList,
  },
  actions: {
    report({ commit, rootGetters }) {
      if (!isActionAllowed(rootGetters, 'SCRAPING_ZONE.REPORT')) return undefined;

      return clark.api.scrapingZone
        .report({ payload: {}, httpQuery: { appFriendly: 'true' } })
        .then((scrapingZoneList) => {
          commit('SET_SCRAPING_ZONE_LIST', scrapingZoneList);
        });
    },

    create({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'SCRAPING_ZONE.CREATE')) return undefined;

      return clark.api.scrapingZone
        .post({ payload, httpQuery: {} })
        .then((scrapingZone) => { commit('UPDATE_SCRAPING_ZONE_LIST', scrapingZone); });
    },

    update({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'SCRAPING_ZONE.UPDATE')) return undefined;

      return clark.api.scrapingZone
        .put({ payload, httpQuery: {} })
        .then((scrapingZone) => { commit('UPDATE_SCRAPING_ZONE_LIST', scrapingZone); });
    },

    delete({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'SCRAPING_ZONE.DELETE')) return undefined;

      return clark.api.scrapingZone
        .delete({ payload, httpQuery: {} })
        .then(() => { commit('DELETE_SCRAPING_ZONE', payload); });
    },
  },
};
