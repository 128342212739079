import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      templateSearch: '',
      templateInputSearch: '',
    };
  },
  computed: {
    ...mapGetters({
      templateList: 'TheTextarea/templateList',
    }),
    filteredTemplateList() {
      if (!this.templateList || !Object.values(this.templateList).length) return {};
      if (!this.templateSearch) return this.templateList;

      return Object.values(this.templateList).reduce((acc, row) => {
        if (row.title.toLowerCase().includes(this.templateSearch.toLowerCase())) acc.push(row);
        return acc;
      }, []).arrayMapper('tid');
    },
  },
  watch: {
    templateInputSearch(newVal) {
      this.templateSearch = newVal;
    },
  },
  methods: {
    fillTemplate() {
      Object.values(this.templateList).forEach((template) => {
        if (this.localMessage.includes(`/${template.title}`)) {
          this.localMessage = this.localMessage.replace(`/${template.title}`, template.text.trim());
        }
      });
    },
    replaceTemplateTitle(template) {
      const indexOfLastSlash = this.localMessage.lastIndexOf('/');
      const title = this.localMessage.substring(indexOfLastSlash + 1);
      this.localMessage = this.localMessage.replace(`/${title}`, template.text);
    },
    autoFocusSearch(isOpen) {
      if (isOpen) this.$refs.searchAutoFocus.focus();
    },
  },
};
