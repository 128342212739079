<template>
  <div class="mainPanelFilters">
    <div class="multibar row-vcentered">
      <TheShopDropdown class="start mr-2" />

      <UbuSearchbar
        v-model="search"
        class="start"
        placeholder="Search code or username..."
        :disabled="isFiltersDisabled"
        @clear="clearSearchbar()"
        @pressEnter="addSearchbarFilter()"
      />

      <TheFilterDropdown
        v-model="localFilters"
        :filter-list="filtersCatalog"
        :non-displayable-filters="nonDisplayableFilters"
        :selected-segment-filter.sync="selectedSegmentFilter"
        :is-disabled="isFiltersDisabled"
        class="start ml-2"
      />

      <slot name="actionsEnd" />
    </div>

    <div class="activeFilterList">
      <UbuDropdownFilterForm
        v-for="filter in localFilters"
        :key="filter.id"
        v-model="filtersMapped[filter.id]"
        :is-not-allowed="!allowedFilters.includes(filter.key)"
        :catalog-filter="genericCatalog"
        @handle="handleFilterUpdate($event)"
        @deleteFilter="deleteFilter(filter.id)"
      />
    </div>
  </div>
</template>

<script>
import templatizeMixin from '@dailyplanet/cross-addons/filters/_mixins/templatize.mixin';
import { mapGetters } from 'vuex';

export default {
  name: 'TheAffiliationCartouche',
  mixins: [templatizeMixin],
  model: {
    prop: 'filters',
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    selectedShopSid: {
      type: Number,
      default: null,
    },
    allowedFilters: {
      type: Array,
      required: true,
    },
    nonDisplayableFilters: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      search: '',
      selectedSegmentFilter: null,
    };
  },
  computed: {
    ...mapGetters({
      _currentShop: 'TheAffiliationCodeOverviewTable/currentShop',
    }),

    localFilters: {
      get() {
        return this.filters
          .filter(({ key }) => !this.nonDisplayableFilters.includes(key))
          .map(({ values, list, ...rest }) => ({
            ...rest,
            values: values.map((value) => value),
            list: list ? list.map((l) => ({ ...l })) : null,
          }));
      },
      set(values) { this.$emit('input', values); },
    },

    isFiltersDisabled() {
      return !this._currentShop.sid;
    },

    filtersMapped() {
      return this.localFilters.arrayMapper('id');
    },

    filtersCatalog() {
      return Object.values(this.genericCatalog)
        .filter(({ key }) => this.allowedFilters.includes(key));
    },
  },
  methods: {
    addSearchbarFilter() {
      const filters = this.localFilters.arrayMapper('key');
      delete filters.search;
      this.localFilters = Object.values(filters).concat({ key: 'search', values: [this.search] });
    },

    clearSearchbar() {
      this.localFilters = this.localFilters.filter(({ key }) => key !== 'search');
      this.search = '';
    },

    handleFilterUpdate(filter) {
      // replace old filter by new & redefine filters array
      const newFilters = {
        ...this.localFilters.arrayMapper('id'),
        [filter.id]: filter,
      };

      this.localFilters = Object.values(newFilters);
    },

    deleteFilter(event) {
      const filters = this.localFilters.filter(({ id }) => id !== event);
      this.$emit('input', filters);
    },
  },
};
</script>
