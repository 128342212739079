<template>
  <div class="switchGroup-content">
    <div class="switchGroup-content-group">
      <div class="switchGroup-content-separator" />

      <div class="switchGroup-content-items">
        <template v-for="child in localChildren">
          <b-checkbox
            :key="child.value"
            v-model="localChildren[child.value].isActive"
            :true-value="true"
            :false-value="false"
            :disabled="readOnly"
            @input="childHandler($event)"
          >
            <div
              class="trigger multibar row-vcentered"
            >
              <b-image
                v-if="child.picture"
                class="start"
                :src="child.picture"
              />
              <b-icon
                v-else-if="child.icon && !child.iconPaths"
                class="start"
                :icon="child.icon"
                pack="ubu"
              />
              <div
                v-else-if="child.icon && child.iconPaths"
                class="start mr-1"
              >
                <component
                  :is="`UbuIcon${child.platformName.capitalize()}`"
                  font-size="24px"
                />
              </div>
              <p class="start text-truncate">
                {{ child.name }}
              </p>
            </div>
          </b-checkbox>
        </template>
        <div
          v-if="group.isOpen && !autoMode"
          class="multibar"
        >
          <b-button
            class="start"
            type="is-ghost"
            label="Save changes"
            :disabled="!childrenHasChanges()"
            @click="$emit('updateGroup', {...group, children: Object.values(localChildren)})"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import deepcopy from 'deepcopy';

export default {
  name: 'UbuSwitchSelectorChildren',
  props: {
    group: {
      type: Object,
      required: true,
    },
    autoMode: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localChildren: deepcopy(this.group.children.arrayMapper('value')),
    };
  },
  methods: {
    childHandler() {
      const hasChildActive = Object.values(this.localChildren).find(({ isActive }) => isActive);
      if (hasChildActive) {
        this.$emit('toggleActiveChannelGroup', true);
      } else {
        this.$emit('toggleActiveChannelGroup', false);
      }

      if (this.autoMode) {
        this.$emit('updateGroup', { ...this.group, children: Object.values(this.localChildren) });
      }
    },
    childrenHasChanges() {
      const { children: originals } = this.group;

      const hasChanges = !!Object.values(this.localChildren).find(({ value, isActive }) => {
        if (originals.find((original) => original.value === value
        && original.isActive !== isActive)) {
          return true;
        }
        return false;
      });
      return hasChanges;
    },
  },
};
</script>
