<template>
  <fieldset>
    <b-field
      label="name"
      horizontal
    >
      <b-input
        v-model="scrapingZone.szid"
        required
        @input="$update('szid', $event)"
      />
    </b-field>
    <b-field
      label="description"
      horizontal
    >
      <b-input
        v-model="scrapingZone.label"
        @input="$update('label', $event)"
      />
    </b-field>
  </fieldset>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuScrapingZoneForm',
  mixins: [VModelMixin],
  model: {
    prop: 'scrapingZone',
  },
  props: {
    scrapingZone: {
      type: Object,
      required: true,
    },
  },
};
</script>
