/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  getters: {
    ...getters.threadGroup,
    ...getters.thread,
  },
  actions: {
    ...actions.threadGroup,
    ...actions.thread,
    ...actions.segment,
  },
};
