/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const contactGroup = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/contactGroup/`, method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/contactGroup`, method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/contactGroup/${payload.cgid}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/contactGroup/${payload.cgid}`, method: 'DELETE', payload, httpQuery, target }),
  assign: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/contactGroup/${payload.cgid}/assign`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/contactGroup/${payload.cgid}/assign`, method: 'DELETE', payload, httpQuery, target }),
  },
};

export default contactGroup;
