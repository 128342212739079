<template>
  <div id="TheFirstConnexion">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'TheAuthenticateFirstConnexion',
};
</script>
