<template>
  <section>
    <div
      :class="['banner', {
        'img-overlay': hasBanner === 'overlay',
        'img-mentions': hasBanner === 'mentions',
        'img-sendHooks': hasBanner === 'sendHooks',
        'img-shopify': hasBanner === 'shopify',
        'img-audit': hasBanner === 'audit',
        'img-top': hasPosition === 'top',
        'img-center': hasPosition === 'center',
        'img-bottom': hasPosition === 'bottom',
      }]"
    >
      <slot name="content" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'UbuBanner',
  props: {
    banner: { type: String, default: 'overlay' },
    position: { type: String, default: 'center' },
  },
  computed: {
    hasBanner() {
      const banner = ['overlay', 'mentions', 'sendHooks', 'shopify', 'audit'];

      if (!banner.includes(this.banner)) {
        console.error(`Only the following banners are available: ${banner.join(', ')}`);
      }

      return this.banner;
    },
    hasPosition() {
      const position = ['top', 'center', 'bottom'];

      if (!position.includes(this.position)) {
        console.error(`Only the following position are available: ${position.join(', ')}`);
      }

      return this.position;
    },
  },
};
</script>
