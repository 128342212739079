<template>
  <section
    id="TheCampaign"
    class="mainPanel"
    style="overflow: auto"
  >
    <header
      id="TheCampaignHeader"
      class="mainPanelHeader multibar row-vcentered"
    >
      <h1 class="start title is-2">Campaigns</h1>

      <div class="buttons end">
        <b-button
          type="is-primary"
          icon-pack="ubu"
          icon-left="ubu-add"
          label="New Campaign"
          @click="openNewCampaignPanel = !openNewCampaignPanel"
        />
      </div>
    </header>

    <aside class="mainPanelFilters">
      <div class="multibar row-vcentered">
        <b-button
          v-for="tab in ['active', 'ended']"
          :key="tab"
          :label="tab.capitalize()"
          :active="tab === activeTab"
          class="is-simple start"
          @click="activeTab = tab"
        />

        <UbuSearchbar
          v-model="search"
          class="start ml-2"
          placeholder="Search..."
          @clear="search = ''"
        />
      </div>
    </aside>

    <article class="mainPanelTable">
      <b-table
        v-if="campaignListFiltered.length"
        :data="campaignListFiltered"
        class="table-full-height"
        detail-key="cid"
        :show-header="false"
      >
        <b-table-column
          key="name"
          field="name"
        >
          <template v-slot="props">
            <UbuCampaignCard
              style="height: 70px"
              :campaign="props.row"
              :show-date="true"
            >
              <template #actions>
                <b-button
                  class="end mr-2"
                  type="is-primary"
                  label="Open campaign"
                  @click="goToCampaign(props.row)"
                />
                <b-button
                  class="end"
                  type="is-secondary"
                  icon-pack="ubu"
                  icon-left="ubu-trash"
                  @click="endCampaign(props.row)"
                />
              </template>
            </UbuCampaignCard>
          </template>
        </b-table-column>
      </b-table>
    </article>

    <TheCreateCampaignPanel
      v-if="openNewCampaignPanel"
      @close="openNewCampaignPanel = false"
    />

    <div
      v-if="isBackgroundLocked"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import filterEngineMixins from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheCampaigns',
  mixins: [filterEngineMixins],
  data() {
    return {
      openNewCampaignPanel: false,
      activeTab: 'active',
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      _campaignList: 'TheCampaign/campaignList',
    }),

    _filterCollection() {
      return {
        campaignIsEnded(isEnded) {
          if (isEnded) return (m) => (m.endedOn ? m : undefined);
          return (m) => (!m.endedOn ? m : undefined);
        },
      };
    },

    campaignListFiltered() {
      const campaignList = Object.values(this._campaignList);
      if (!campaignList.length) return [];

      const filtersToApply = {
        campaignIsEnded: this.activeTab !== 'active',
      };
      if (this.search) filtersToApply.searchByName = this.search;

      return this.filterEngine(campaignList, filtersToApply)
        .sort((a, b) => (this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix()));
    },

    isBackgroundLocked() {
      if (this.openNewCampaignPanel) return true;
      return false;
    },
  },

  methods: {
    ...mapActions({
      _createCampaign: 'TheCampaign/createCampaign',
      _endCampaign: 'TheCampaign/endCampaign',
      _emitSegmentEvent: 'TheCampaign/emitSegmentEvent',
    }),

    closePanel() {
      this.$emit('closePanel');
    },

    goToCampaign({ cid }) {
      this._emitSegmentEvent({ event: 'clickOnInfluenceCampaign' });
      this.$router.push(`/campaign/${cid}/people`);
    },

    createCampaign() {
      return this._createCampaign({ payload: this.newCampaign })
        .then(() => { this.openNewCampaignPanel = false; });
    },

    endCampaign({ cid, name }) {
      this.$buefy.dialog.confirm({
        title: 'End a campaign',
        message: 'Do you really want to end this campaign? You will still be able to view data related to this campaign after it\'s ended.',
        confirmText: 'End campaign',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this._endCampaign({ payload: { cid } })
          .then(() => {
            this.$buefy.snackbar.open({
              type: 'is-success',
              position: 'is-top',
              message: `Campaign ${name} is ended!`,
            });
          }),
      });
    },
  },
};
</script>
