<template>
  <div
    id="ubuTemplateCard"
    :class="['card', { 'clickable': clickable }]"
    @click="clickOnCard()"
  >
    <header class="card-header">
      <p class="card-header-title">
        {{ template.title }}
      </p>
    </header>

    <div class="card-content">
      <div class="content has-text-secondary">
        {{ template.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuTemplateCard',
  props: {
    template: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickOnCard() {
      if (!this.clickable) return;
      this.$emit('click');
    },
  },
};
</script>
