<template>
  <div class="ubuColorPickerInline">
    <div
      v-for="colorId in 12"
      :key="colorId"
      :class="[
        'block-color', `bg-color-${colorId}-filled`,
        {'is-active': localColor == colorId }
      ]"
      @click="localColor = colorId"
    >
      <b-icon
        v-if="localColor == colorId"
        pack="ubu"
        icon="ubu-check"
        font-size="24px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuColorPickerInline',
  model: {
    prop: 'color',
  },
  props: {
    color: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    localColor: {
      get() { return this.color; },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>
