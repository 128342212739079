<template>
  <b-menu-item
    v-show="active"
    expanded
    :class="
      'popover popover-item-simple' +
        [type ? ` is-${type}` : ''] +
        [disabled ? ' is-disabled' : ''] +
        [hovered ? ' is-hovered' : ''] +
        [focused ? ' is-focused' : '']
    "
    @click="$emit('getDatas', event.name)"
  >
    <template #label>
      <div class="is-flex is-align-items-center">
        <b-icon
          v-if="iconLeft"
          pack="ubu"
          :icon="iconLeft.icon"
          class="popover-icon-left"
        />
        <span>
          {{ name }}
        </span>
      </div>
      <b-icon
        v-if="iconRight"
        pack="ubu"
        :icon="iconRight.icon"
      />
    </template>
  </b-menu-item>
</template>

<script>
export default {
  name: 'UbuPopoverItemSimple',
  props: {
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hovered: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: Object,
      default: () => {},
    },
    iconRight: {
      type: Object,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setData() {
      alert(this.name);
    },
  },
};
</script>
