<template>
  <b-dropdown
    :position="dropdownPosition"
    paddingless
  >
    <template #trigger>
      <b-tooltip
        label="Emojis"
        position="is-top"
      >
        <b-button
          icon-left="ubu-emoji"
          icon-pack="ubu"
          type="is-tertiary-icon"
        />
      </b-tooltip>
    </template>

    <b-dropdown-item
      custom
      paddingless
    >
      <VEmojiPicker
        :dark="isDark"
        :show-search="true"
        :emoji-size="24"
        :emojis-by-row="6"
        @select="$emit('insertText', $event.data)"
      />
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import ThemeMixin from '@dailyplanet/mixins/ThemeMixin';
import { VEmojiPicker } from 'v-emoji-picker';
import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive';

export default {
  name: 'TheTextareaEmojiButton',
  directives: {
    autogrow: TextareaAutogrowDirective,
  },
  components: {
    VEmojiPicker,
  },
  mixins: [ThemeMixin],
  props: {
    dropdownPosition: {
      type: String,
      default: 'is-bottom-left',
    },
  },
};
</script>
