/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  actions: {
    patchSheetNote({ commit, getters }, { payload }) {
      return clark.api.apiContact.contactPlatformStatistic.patch({ payload })
        .then((contactPlatformStatistic) => {
          const contactPlatformOverview = Object.values(getters.getterContactPlatformOverviewList).find(({ cpid }) => payload.cpid === cpid);
          contactPlatformOverview.contactPlatformStatistic = contactPlatformStatistic;

          commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
            ...getters.getterContactPlatformOverviewList,
            [payload.cpid]: contactPlatformOverview,
          });
        });
    },
  },
};
