<template>
  <b-dropdown
    aria-role="list"
    class="UbuDropDownColorPicker"
  >
    <template #trigger>
      <b-button
        type="is-secondary"
        icon-pack="ubu"
        :icon-left="item.icon"
        :class="`text-color-${item.color}`"
      />
    </template>
    <div class="icon-color-picker-menu">
      <template v-for="color in 12">
        <span
          v-if="color"
          :key="color"
          :class="`UbuColorPicker_color${color}`"
          @click="$update('color', color.toString())"
        >
          <b-icon
            :pack="item.pack"
            :icon="item.icon"
            :class="`text-color-${color}`"
            size="is-medium"
          />
        </span>
      </template>
    </div>
  </b-dropdown>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuIconColorPicker',
  mixins: [VModelMixin],
  model: {
    prop: 'item',
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
