import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    crewAdminList: [],
    crewAdminTotal: 0,
  },
  mutations: {
    SET_CREW_ADMIN_LIST(state, newData) { state.crewAdminList = newData; },
    SET_CREW_ADMIN_TOTAL(state, newData) { state.crewAdminTotal = newData; },
  },
  getters: {
    getterCrewAdminList: (state) => state.crewAdminList,
    getterCrewAdminTotal: (state) => state.crewAdminTotal,
  },
  actions: {
    report({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiCrew.crewAdmin.report({ payload, httpQuery })
        .then((crewAdminList) => {
          commit('SET_CREW_ADMIN_LIST', crewAdminList.results);
          commit('SET_CREW_ADMIN_TOTAL', crewAdminList.total);
          return crewAdminList;
        });
    },
    update({ commit, getters }, { payload }) {
      return clark.api.apiCrew.crewAdmin.put({ payload })
        .then((crewUser) => {
          const crewAdminList = getters.getterCrewAdminList;

          const crewAdminListRefresh = crewAdminList.reduce((acc, row) => {
            if (row.crewUserCuid === payload.cuid) {
              acc.push({
                ...row,
                crewUser,
              });
              return acc;
            }

            acc.push(row);

            return acc;
          }, []);

          commit('SET_CREW_ADMIN_LIST', crewAdminListRefresh);

          return crewUser;
        });
    },
  },
};
