<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        :to="`/admin/account/${currentAid}/channels`"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <h2 class="title is-1">Details channel Youtube</h2>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheAdminAccountChannelDetailsYoutube',
  computed: {
    ...mapGetters({
      account: 'TheAdmin/account',
    }),
    currentAid() {
      return this.$route.params.aid;
    },
    currentCid() {
      return this.$route.params.cid;
    },
    channelYoutube() {
      if (!this.account) return null;

      const platform = this.account.channelGroupList
        .reduce((acc, { channelList }) => {
          acc.push(...channelList);
          return acc;
        }, [])
        .arrayMapper('cid');

      return platform[this.currentCid];
    },
  },
  mounted() {
    if (!this.channelYoutube) {
      this.$router.push({
        path: `/admin/account/${this.currentAid}/channels`,
      });
    }
  },
};
</script>
