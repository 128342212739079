<template>
  <section
    class="ubuMessageWrapper"
    :isMe="isMe"
  >
    <div
      class="ubuMessageCard info mb-2"
    >
      <div
        v-if="platformName === 'INSTAGRAM'"
        class="ubuMessageCard-content"
      >
        <div class="subtext cloud60">Instagram API doesn't support this message.</div>
        <a
          class="button is-ghost link-start mt-2"
          href="https://www.instagram.com/direct/inbox/"
          target="_blank"
        >
          View on Instagram
        </a>
      </div>
      <div
        v-else-if="platformName === 'FACEBOOK'"
        class="ubuMessageCard-content"
      >
        <div class="subtext cloud60">Facebook API doesn't support this message.</div>
        <a
          class="button is-ghost link-start mt-2"
          href="https://www.facebook.com/messages"
          target="_blank"
        >
          View on Facebook
        </a>
      </div>
      <div
        v-else
        class="ubuMessageCard-content"
      >
        <div class="subtext cloud60">This message is unsupported.</div>
      </div>
      <UbuThreadMessageFooter :message="message" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'UbuThreadMessageMalformed',
  props: {
    isMe: {
      type: Boolean,
      default: false,
      require: true,
    },
    message: {
      type: Object,
      default: () => {},
      require: true,
    },
    platformName: {
      type: String,
      default: 'INSTAGRAM',
    },
  },
};
</script>
