export default {
  computed: {
    routeForPreviousButton() {
      const { path } = this.$route;
      const pathList = path
        // transform string to array
        .split('/')
        // remove empty [0] and last element [-1]
        .slice(1, -1);

      return { path: `/${pathList.join('/')}`, query: this.$route.query };
    },
  },
  methods: {
    goToPreviousPath() {
      this.$router.push(this.routeForPreviousButton);
    },
  },
};
