<template>
  <section id="TheTribePeople">
    <TheTribeCartouche
      v-model="filters"
      :allowed-filters="allowedFilters"
      :non-displayable-filters="nonDisplayableFilters"
      @input="filters = $event"
      @reset="setToALL_STATUS()"
    >
      <template #secondLineStart>
        <UbuDropdownCampaignActivityStatus
          v-model="selectedStatus"
          class="start"
          :status-list="memberGroupMgidListWithPopulation"
          @input="handleSelectStatusFilter($event)"
        />
      </template>
      <template #secondLineEnd>
        <b-button
          label="Export"
          type="is-primary"
          icon-pack="ubu"
          icon-left="ubu-download"
          class="end"
          @click="isExportPanelOpen = true"
        />

        <b-button
          class="end ml-2"
          icon-pack="ubu"
          icon-left="ubu-add"
          type="is-primary"
          label="Add people"
          @click="isAddPeoplePanelOpen = !isAddPeoplePanelOpen"
        />
      </template>
    </TheTribeCartouche>

    <router-view
      v-model="checkedRows"
      :class="['mainPanelTable', { hasFilter: filters.length }]"
      :filters.sync="filters"
      :allowed-filters="allowedFilters"
      @selectContactPlatform="selectContactPlatform($event)"
      @unselectContactPlatform="selectedContactPlatform = null"
    />

    <UbuBulkActionPanelTrigger
      :bulk-is-open="bulkIsOpen"
      :checked-rows-length="localChecked.length"
      @cancel="bulkIsOpen = false; checkedRows = {};"
      @open="bulkIsOpen = true;"
    />

    <TheTribeBulkPanel
      v-if="isBackgroundLocked"
      v-model="localChecked"
      @close="bulkIsOpen = false; checkedRows = {};"
    />

    <TheTribePeopleAddPeoplePanel
      v-if="isAddPeoplePanelOpen"
      v-model="localChecked"
      @close="closeAddFromCustomPanel()"
    />

    <TheContactPlatformPanel
      v-if="selectedContactPlatform"
      v-model="selectedContactPlatform"
      is-large
      @close="selectedContactPlatform = null;"
    />

    <TheTribeMemberExportPanel
      v-if="isExportPanelOpen"
      :tribe-tid="tribe.tid"
      @close="isExportPanelOpen = null"
    />

    <div
      v-if="isBackgroundLocked"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheTribePeople',
  data() {
    return {
      selectedStatus: 'ALL_STATUS',
      show: true,
      bulkIsOpen: false,
      isExportPanelOpen: false,
      isAddPeoplePanelOpen: false,
      selectedContactPlatform: null,
      checkedRows: {},
      filters: [],
      allowedFilters: [
        'memberGroupMgid',
        'lastActivity.campaignCid',
        'contactPlatform.followerCount',
        'contactPlatformStatistic.lastMessageOn',
        'contactPlatformStatistic.lastMentionOn',
        'contactPlatformStatistic.lastCampaignOn',
        'contactPlatformStatistic.lastSaleOn',
        // 'contactPlatformStatistic.totalPrints',
        'contactPlatformStatistic.totalMentions',
        'contactPlatformStatistic.totalOrders',
        'contactPlatformStatistic.totalRevenuesUsd',
        'contactPlatformStatistic.hasThread',
        'contactPlatform.username',
        'crewUser.state',

      ],
      nonDisplayableFilters: [
        'contactPlatform.username',
        'memberGroupMgid',
      ],
    };
  },
  computed: {
    ...mapGetters({
      tribeList: 'TheTribe/tribeList',
      _memberGroupList: 'TheTribe/memberGroupList',
    }),

    tribe() {
      return this.tribeList[this.$route.params.tribeTid];
    },

    isBackgroundLocked() {
      if (this.bulkIsOpen && this.localChecked.length) return true;
      if (this.isAddPeoplePanelOpen) return true;
      // if (this.contactPlanelSelectedPeople) return true;
      return false;
    },

    memberGroupMgidListWithPopulation() {
      if (!this.tribe.population) return [];

      const resultsDropdown = this.tribe.population.reduce((acc, population) => {
        acc[0].count += Number(population.count);

        if (!population.memberGroupMgid) {
          acc.push({
            ...population,
            label: 'No Group',
            icon: null,
            status: 'NO_GROUP',
          });
          return acc;
        }

        acc.push({
          ...population,
          icon: this._memberGroupList[population.memberGroupMgid].icon,
          label: this._memberGroupList[population.memberGroupMgid].name,
          status: this._memberGroupList[population.memberGroupMgid].mgid,
        });
        return acc;
      }, [{
        status: 'ALL_STATUS',
        label: 'all Group',
        count: 0,
      }]);

      return resultsDropdown;
    },
    localChecked() { return Object.values(this.checkedRows); },
  },
  watch: {
    filters(newValue, oldValue) {
      if (oldValue.length > 0 && newValue.length === 0) {
        this.selectedStatus = 'ALL_STATUS';
      }
    },
  },
  beforeMount() {
    return this._resetTribeMemberList();
  },
  methods: {
    ...mapActions({
      getAndSetCurrentContactPlatformOverview: 'TheContactPlatformPanel/getAndSetCurrentContactPlatformOverview',
      _resetTribeMemberList: 'ApiTribe/tribeMember/resetTribeMemberList',
    }),

    handleSelectStatusFilter(status) {
      // const filterStatus = this.filters.find(({ key }) => key === 'memberGroupMgid');

      if (status === 'ALL_STATUS') {
        this.selectedStatus = 'ALL_STATUS';
        this.filters = this.filters.filter(({ key }) => key !== 'memberGroupMgid');
        return undefined;
      }

      if (status === 'NO_GROUP') {
        this.selectedStatus = 'NO_GROUP';
        this.filters = this.filters.filter(({ key }) => key !== 'memberGroupMgid');
        this.filters.push({ key: 'memberGroupMgid', values: [status], operator: 'is empty' });
        return undefined;
      }

      if (status !== 'NO_GROUP' && status !== 'ALL_STATUS') {
        this.selectedStatus = status;
        this.filters = this.filters.filter(({ key }) => key !== 'memberGroupMgid');
        this.filters.push({ key: 'memberGroupMgid', values: [status], operator: 'is' });
        return undefined;
      }
      return undefined;
    },

    setToALL_STATUS() {
      this.selectedStatus = 'ALL_STATUS';
    },
    selectContactPlatform(cpid) {
      this.selectedContactPlatform = cpid;
      return this.getAndSetCurrentContactPlatformOverview({ payload: { cpid } });
    },

    updateCheckedRows(rows) {
      this.checkedRows = rows;
    },

    closeAddFromCustomPanel() {
      this.isAddPeoplePanelOpen = false;

      // force reload list
      this.filters = [...this.filters];
    },
  },
};
</script>
