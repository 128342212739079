/* eslint-disable max-len */
import { actions, gettersSelected } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  getters: {
    ...gettersSelected('activity', ['activityStatusListFiltered']),
  },
  actions: {
    ...actions.bulkActivity,
  },
};
