/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    userList: null,
  },
  mutations: {
    SET_USER_LIST(state, newData) { state.userList = newData; },
    RESET_STORE(state) {
      state.userList = null;
    },
  },
  getters: {
    getterUserList: (state) => state.userList,
  },
  actions: {
    report({ commit }, { payload, httpQuery }) {
      return clark.api.channelGroup.user.report({ payload, httpQuery })
        .then((userList) => { commit('SET_USER_LIST', userList); });
    },
  },
  modules: {},
};
