<template>
  <div class="ubuContactPlatformPanelNote isNote">
    <div class="multibar row-vcentered mb-1">
      <p
        class="start subtext is-2 has-text-secondary"
        style="line-height: 16px;"
      >
        {{ item.user ? item.user.firstName : '' }} {{ item.noteDate | humanizeDate }}
      </p>
      <b-icon
        class="end clickable displayOnHover"
        pack="ubu"
        icon="ubu-trash"
        size="is-small"
        @click.native="confirmDelete(item);"
      />
    </div>

    <p class="text is-2">
      <UbuSingleTextarea
        :key="item.customId"
        v-model="item.text"
        placeholder="Type a note..."
        custom-class="ubuTransparentInput p-0"
        icon-right="ubu-edit"
        :maxlength="255"
        @send="$emit('updateNote', {...item, text: $event})"
      />
    </p>
  </div>
</template>

<script>
export default {
  name: 'UbuNoteItemPrivate',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    confirmDelete(item) {
      this.$buefy.dialog.confirm({
        title: 'Delete note',
        message: 'Are you sure you want to <b>Delete this note</b>? This cannot be undone.',
        confirmText: 'Delete Note',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('deleteNote', item),
      });
    },
  },
};
</script>
