/* eslint-disable max-len */
import moment from 'moment';
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  actions: {
    updatePasswordFromSelina({ commit, rootGetters }, { payload }) {
      return clark.api.changePassword.post({ payload, httpQuery: {} })
        .then((authenticate) => {
          const { 'Authenticate/getterAuthenticate': myAuthenticate } = rootGetters;
          commit('Authenticate/SET_AUTHENTICATE', { ...myAuthenticate, aid: authenticate.aid }, { root: true });
        });
    },

    updatePasswordFromKara({ commit, rootGetters }, { payload }) {
      return clark.api.changePassword.post({ payload, httpQuery: {} })
        .then((authenticate) => {
          const { 'DetailledUser/getterDetailledUser': detailledUser } = rootGetters;

          const newAuthenticatesDetailledUser = detailledUser.authenticates;
          const index = newAuthenticatesDetailledUser.findIndex(({ revokedOn }) => revokedOn === null);

          newAuthenticatesDetailledUser[index].revokedOn = moment().format();
          newAuthenticatesDetailledUser.push(authenticate);

          commit('DetailledUser/SET_DETAILLED_USER', { ...detailledUser, authenticates: newAuthenticatesDetailledUser }, { root: true });
        });
    },
  },
};
