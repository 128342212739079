import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuMentionFilters from './UbuMentionFilters';
import UbuMentionModalMedia from './UbuMentionModalMedia';
import UbuMentionModalHeaderButtons from './UbuMentionModalHeaderButtons';
import UbuNewMentionCard from './UbuNewMentionCard';

// TheMentionModalComponents
import UbuMentionModalAnalytics from './TheMentionModal/UbuMentionModalAnalytics';
import UbuMentionModalDetails from './TheMentionModal/UbuMentionModalDetails';
import UbuMentionModalMediaPanel from './TheMentionModal/UbuMentionModalMediaPanel';
import UbuMentionModalNavigation from './TheMentionModal/UbuMentionModalNavigation';

import UbuMediaAudio from './UbuMediaAudio';
import UbuMediaCarousel from './UbuMediaCarousel';
import UbuMediaImage from './UbuMediaImage';
import UbuMediaVideo from './UbuMediaVideo';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuMentionFilters);
    registerComponent(Vue, UbuMentionModalMedia);
    registerComponent(Vue, UbuMentionModalHeaderButtons);
    registerComponent(Vue, UbuNewMentionCard);

    // TheMentionModalComponents
    registerComponent(Vue, UbuMentionModalAnalytics);
    registerComponent(Vue, UbuMentionModalDetails);
    registerComponent(Vue, UbuMentionModalMediaPanel);
    registerComponent(Vue, UbuMentionModalNavigation);

    registerComponent(Vue, UbuMediaAudio);
    registerComponent(Vue, UbuMediaCarousel);
    registerComponent(Vue, UbuMediaImage);
    registerComponent(Vue, UbuMediaVideo);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuMentionFilters,
  UbuMentionModalMedia,
  UbuMentionModalHeaderButtons,
  UbuNewMentionCard,

  // TheMentionModalComponents
  UbuMentionModalAnalytics,
  UbuMentionModalDetails,
  UbuMentionModalMediaPanel,
  UbuMentionModalNavigation,

  UbuMediaAudio,
  UbuMediaCarousel,
  UbuMediaImage,
  UbuMediaVideo,
};
