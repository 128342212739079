<template>
  <TheSimplePanel
    :closing-way="closingWay"
    @close="close()"
  >
    <template #header>
      <h1 class="title is-2">Add new template</h1>
    </template>

    <template #content>
      <form @submit.prevent="createTemplate()">
        <b-field grouped>
          <b-field
            class="start w-100 mr-2"
            label="Name"
            expanded
          >
            <b-input
              ref="input"
              v-model="newTemplate.title"
              placeholder="My template ..."
            />
          </b-field>

          <b-field
            class="w-100"
            label="Folder"
            expanded
          >
            <UbuDropdown
              :close-on-click="true"
              :expanded="expanded"
              :scrollable="true"
              @open="isOpen = true"
              @close="reset(); isOpen = false;"
            >
              <template #button>
                <b-button
                  type="is-secondary"
                  expanded
                >
                  <div class="multibar">
                    <span class="start">{{ selectFolderButtonLabel }}</span>

                    <b-icon
                      pack="ubu"
                      icon="ubu-arrow-down"
                      class="end"
                    />
                  </div>
                </b-button>
              </template>

              <template
                v-if="isOpen"
                #search
              >
                <b-dropdown-item custom>
                  <UbuSearchbar
                    v-model="search"
                    @clear="reset()"
                  />
                </b-dropdown-item>
              </template>

              <template #content>
                <b-dropdown-item
                  v-for="templateFolder in customList"
                  :key="templateFolder.label"
                  @click="selectFolder(templateFolder.tfid)"
                >
                  <div class="multibar row-vcentered">
                    <span class="start">{{ templateFolder.label }}</span>

                    <b-icon
                      v-if="newTemplate.templateFolderTfid === templateFolder.tfid"
                      class="end"
                      pack="ubu"
                      icon="ubu-check"
                    />
                  </div>
                </b-dropdown-item>
              </template>
            </UbuDropdown>
          </b-field>
        </b-field>
        <b-field>
          <UbuSimpleTextarea
            v-model="newTemplate.text"
            :max-length="1000"
            placeholder="Type some text..."
          />
        </b-field>

        <div class="buttons">
          <b-button
            type="is-success"
            native-type="submit"
            label="Create"
            :disabled="!canSave"
          >
            Create
          </b-button>
        </div>
      </form>
    </template>
  </TheSimplePanel>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';
import NavigationMixin from '@dailyplanet/mixins/NavigationMixin';

export default {
  name: 'TheSettingAddTemplate',
  mixins: [NavigationMixin, FilterEngineMixin],
  props: {
    closingWay: {
      type: String,
      default: 'route',
    },
  },
  data() {
    return {
      expanded: true,
      isOpen: false,
      search: '',
      newTemplate: {
        text: '',
        title: '',
        templateFolderTfid: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      templateFolderList: 'TheTemplateManager/templateFolderList',
      _textImported: 'TheAddTemplatePanel/getterTextImported',
    }),
    canSave() {
      const { text, title } = this.newTemplate;
      if ((text && text.length) && (title && title.length)) return true;
      return false;
    },

    _filterCollection() {
      return {
        /**
       * @method  search Count
       * @params  {Number}   rawMax  maximum to be compared to
       * @returns {Function}
       * */
        searchByLabel(rawSearch) {
          const search = rawSearch.toLowerCase();

          const _searchByLabel = (raw) => {
            const text = raw.toLowerCase();
            return text.includes(search);
          };
          return (m) => (_searchByLabel(m.label) ? m : undefined);
        },
      };
    },
    customList() {
      const templateFolderList = Object.values(this.templateFolderList)
        .map((r) => ({ ...r, id: r.tid, name: r.title }));

      if (!templateFolderList || !templateFolderList.length) return [];

      const filtersToApply = {};
      if (this.search) filtersToApply.searchByLabel = this.search;

      return this.filterEngine(templateFolderList, filtersToApply);
    },

    selectFolderButtonLabel() {
      return this.newTemplate.templateFolderTfid
        ? this.templateFolderList[this.newTemplate.templateFolderTfid].label
        : 'Select';
    },
  },
  mounted() {
    this.$refs.input.focus();

    if (this._textImported) this.newTemplate.text = this._textImported;
  },
  methods: {
    ...mapActions({
      _createTemplate: 'TheTemplateManager/createTemplate',
      _up_textImported: 'TheAddTemplatePanel/up_textImported',
    }),
    reset() {
      this.search = '';
      this._up_textImported('');
    },
    createTemplate() {
      this._createTemplate({ payload: this.newTemplate })
        .then(() => {
          this.newTemplate = {
            title: '',
            text: '',
            templateFolderTfid: null,
          };

          switch (this.closingWay) {
            case 'emit': return this.$emit('close');
            case 'route': return this.goToPreviousPath();
            default: return this.goToPreviousPath();
          }
        });
    },
    selectFolder(tfid) {
      if (this.newTemplate.templateFolderTfid === tfid) {
        this.newTemplate.templateFolderTfid = null;

        return;
      }

      this.newTemplate.templateFolderTfid = tfid;
    },
    close() {
      this.reset();
      this.$emit('close');
    },
  },
};
</script>
