<template>
  <b-field
    v-show="active"
    :class="
      'popover-input-search' +
        [hovered ? ' is-hovered' : ''] +
        [focused ? ' is-focused' : '']
    "
  >
    <b-input
      v-model="input"
      :placeholder="name"
      type="search"
      icon-pack="ubu"
      icon="ubu-search"
      icon-right="ubu-cross"
      icon-right-clickable
      :disabled="disabled"
      :value="event.value"
      @input="$emit('getDatas', $event)"
      @icon-right-click="clearIconClick"
    />
  </b-field>
</template>

<script>
export default {
  name: 'UbuPopoverItemSearch',
  props: {
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hovered: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    event: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: '',
    };
  },
  computed: {
    localModel: {
      get() { return { ...this.model }; },
      set(newValue) { this.$emit('getData', newValue); },
    },
  },
  methods: {
    clearIconClick() {
      this.input = '';
      this.$emit('getDatas', '');
    },
  },
};
</script>
