<template>
  <UbuDropdown
    :expanded="true"
    position="is-bottom-right"
  >
    <template #button>
      <button
        v-if="selectedItem"
        class="button flex w-100"
        expanded
      >
        <div class="multibar row-vcentered w-100">
          <UbuDropdownStatusRow
            class="start"
            :status="selectedItem"
          />

          <UbuIconCircleState
            :is-active="selectedItem.hasUnread"
            class="end"
          />

          <span class="end counter">
            {{ selectedItem.threadCount }}
          </span>

          <b-icon
            class="end"
            size="is-small"
            pack="ubu"
            icon="ubu-arrow-down"
          />
        </div>
      </button>
    </template>

    <template #content>
      <b-dropdown-item
        v-for="item in statusList"
        :key="item.status"
        :class="{'is-active': selectedStatus === item.status}"
        @click="$emit('select', item.status)"
      >
        <div class="multibar row-vcentered">
          <UbuDropdownStatusRow
            class="start"
            :status="item"
          />

          <UbuIconCircleState
            :is-active="item.hasUnread"
            class="end"
          />

          <span class="end counter">
            {{ item.threadCount }}
          </span>
        </div>
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
export default {
  name: 'UbuDropdownThreadActivityStatus',
  props: {
    statusList: {
      type: Array,
      required: true,
    },
    selectedStatus: {
      type: [String, Number],
      default: 'ALL_STATUS',
    },
  },
  computed: {
    selectedItem() {
      if (this.selectedStatus === undefined) return this.statusList.find(({ status }) => status === 'ALL_STATUS');
      return this.statusList.find(({ status }) => status === this.selectedStatus);
    },
  },
};
</script>
