import { dispatchAndForward, genericErrorHandler } from '../$utils/helpers';

export default {
  getters: {
    tasks: (_state, _getters, _rootState, rootGetters) => {
      const { 'Monitor/getterTasks': tasks } = rootGetters;

      return Object.values(tasks)
        .map((row) => {
          const r = Object.entries(row);
          return r.reduce((acc, [key, value]) => {
            if (typeof Number(value) === 'number' && !Number.isNaN(Number(value))) {
              return { ...acc, [key]: Number(value) };
            }
            return { ...acc, [key]: value };
          }, {});
        });
    },
  },
  actions: {
    reportTask({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Monitor/reportTask',
        context: { payload, httpQuery },
        textNotifier: 'Monitor Tasks',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report tasks.', indefinite: true }));
    },

    rescheduleTask({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Monitor/rescheduleTask',
        context: { payload, httpQuery },
        textNotifier: 'Reschedule Task',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to reschedule task.', indefinite: true }));
    },
  },
};
