<template>
  <router-view @reset="$emit('reset')" />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheAdminAccount',
  mounted() {
    const payload = { aid: this.$route.params.aid };
    const httpQuery = {
      channelAndChannelGroupList: true,
      accountEvent: true,
      hasFeature: true,
    };

    return Promise.resolve()
      .then(() => this.getAccount({ payload, httpQuery }))
      .then(() => this.reportAccountMemberView({ payload }));
  },
  methods: {
    ...mapActions({
      getAccount: 'TheAdmin/getAccount',
      reportAccountMemberView: 'TheAdmin/reportAccountMemberView',
    }),
  },
};
</script>
