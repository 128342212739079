/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    followersEngagementAnalytics: {},
  },
  mutations: {
    SET_FOLLOWERS_ENGAGEMENT_ANALYTICS(state, newData) { state.followersEngagementAnalytics = newData; },
  },
  getters: {
    getterFollowersEngagementAnalytics: (state) => state.followersEngagementAnalytics,
  },
  actions: {
    get({ commit, rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'ACCOUNT_ANALYTICS.GET')) return undefined;

      return clark.api.channelGroup.followersEngagementAnalytics.get({ payload, httpQuery })
        .then((response) => { commit('SET_FOLLOWERS_ENGAGEMENT_ANALYTICS', response); });
    },
  },
};
