<template>
  <div class="ubuSettingView ubuTheme">
    <h1 class="start title is-2">Themes</h1>

    <br>

    <div class="multibar row-vcentered">
      <b-radio
        v-model="radioButton"
        native-value="LIGHT"
        type="is-primary"
        class="start"
      >
        <img
          class="has-shadow ml-1 mr-2"
          src="../../../assets/pictos/theme-light.svg"
        >
      </b-radio>
      <div class="start">
        <h3 class="subtitle mb-1">Classic</h3>
        <p class="text is-3 has-text-secondary">
          Cute and elegant, just like you 😇
        </p>
      </div>
    </div>

    <hr class="is-l">

    <div class="multibar row-vcentered">
      <b-radio
        v-model="radioButton"
        native-value="DARK"
        type="is-primary"
        class="start"
      >
        <img
          class="has-shadow ml-1 mr-2"
          src="../../../assets/pictos/theme-dark.svg"
        >
      </b-radio>
      <div class="start">
        <h3 class="subtitle mb-1">Dark</h3>
        <p class="text is-3 has-text-secondary">
          The dark side of the force is a pathway to many abilities... 😈
        </p>
      </div>
    </div>

    <hr class="is-l">

    <div class="multibar row-vcentered">
      <b-radio
        v-model="radioButton"
        native-value="AUTO"
        type="is-primary"
        class="start"
      >
        <img
          class="has-shadow ml-1 mr-2"
          src="../../../assets/pictos/theme-auto.svg"
        >
      </b-radio>
      <div class="start">
        <h3 class="subtitle mb-1">Auto</h3>
        <p class="text is-3 has-text-secondary">
          Let your routine guide you through your day 💪
        </p>
      </div>
    </div>

    <hr class="is-l">
  </div>
</template>

<script>
import ThemeMixin from '@dailyplanet/mixins/ThemeMixin';

export default {
  name: 'TheSettingTheme',
  mixins: [ThemeMixin],
  computed: {
    radioButton: {
      get() { return this.theme; },
      set(newValue) { this.theme = newValue; },
    },
  },
};
</script>
