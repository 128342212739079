<template>
  <section

    id="TheAccount"
    class="ubuSettingView start"
  >
    <div class="multibar">
      <h1 class="start title is-1">Add Account</h1>
    </div>

    <br>

    <DevDetails :cnt="{name: 'accountStateList', obj: accountStateList}" />
    <DevDetails :cnt="{name: 'featureList', obj: featureList}" />
    <DevDetails :cnt="{name: 'userRoleList', obj: userRoleList}" />
    <DevDetails :cnt="{name: 'userDetail', obj: userDetail}" />
    <DevDetails :cnt="{name: 'features', obj: features}" />

    <form v-if="isFetched">
      <b-steps
        v-model="activeStep"
        :rounded="false"
        :has-navigation="false"
        vertical
        label-position="right"
        size="is-small"
      >
        <b-step-item
          label="Account"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Add account - Step 1/4 - Account Informations</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="step1Complete"
              :prev="false"
            />
          </div>

          <br>

          <fieldset class="fieldset">
            <br>

            <b-field
              label="owner"
              horizontal
            >
              <b-input
                v-model="account.owner"
                required
              />
            </b-field>

            <b-field
              label="state"
              horizontal
            >
              <b-select
                v-model="account.state"
                required
              >
                <option
                  v-for="state in Object.values(accountStateList)"
                  :key="state.state"
                  :value="state.state"
                >
                  {{ state.state }}
                </option>
              </b-select>
            </b-field>
          </fieldset>
        </b-step-item>

        <b-step-item
          label="Features"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Add account - Step 2/5 - Account features</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="step2Complete"
            />
          </div>

          <fieldset class="fieldset">
            <br>

            <b-field
              v-for="feature in featureList"
              :key="feature.name"
              :label="feature.label"
              horizontal
            >
              <b-checkbox
                v-model="features"
                :native-value="feature"
              />
            </b-field>
          </fieldset>
        </b-step-item>

        <b-step-item
          label="Workspace"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Add account - Step 3/5 - Workspace Informations</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="step3Complete"
            />
          </div>

          <br>

          <fieldset class="fieldset">
            <br>

            <b-field
              label="Workspace Name"
              horizontal
            >
              <b-input
                v-model="workspace.name"
                required
              />
            </b-field>
          </fieldset>
        </b-step-item>

        <b-step-item
          label="User"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Add account - Step 4/5 - User Informations</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="step4Complete"
            />
          </div>

          <br>

          <fieldset class="fieldset">
            <br>

            <UbuKaraUserUserDetailForm
              v-model="userDetail"
              :user-role-list="userRoleListFiltered"
            />

            <br>

            <UbuKaraUserAuthenticateForm
              v-model="authenticate"
            />
            <br>
          </fieldset>
        </b-step-item>

        <b-step-item
          label="Summary"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Add user - Step 5/5 - Summary</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="true"
              :next="false"
            />
          </div>

          <br>

          <UbuKaraSummaryAddAccount
            :account="account"
            :user-detail="userDetail"
            :authenticate="authenticate"
            :is-ubu="userDetail.isUbu"
            :features="features"
            @addAccount="createAccount()"
          />
        </b-step-item>
        <b-step-item
          type="is-success"
        >
          <section>
            <div class="multibar">
              <h2 class="start title is-1">The account was created successfuly ! 🥳</h2>
            </div>

            <br>

            <div class="multibar">
              <div class="end buttons">
                <b-button
                  label="Create an other account"
                  type="is-ghost"
                  @click="initProcessCreate()"
                />
                <b-button
                  label="Open details"
                  type="is-primary"
                  @click="localSelected = newAccount"
                />
              </div>
            </div>
          </section>
        </b-step-item>
      </b-steps>
    </form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'TheAdminAddAccount',
  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isFetched: true,
      activeStep: 0,
      account: {
        owner: null,
        state: 'TRIAL',
      },
      features: [],
      workspace: {
        name: null,
      },
      userDetail: {
        firstName: null,
        lastName: null,
        phone: null,
        userRole: null,
        isUbu: false,
      },
      authenticate: {
        passName: null,
        passPhrase: uuidv4(),
      },
    };
  },
  computed: {
    ...mapGetters({
      accountStateList: 'TheAdmin/accountStateList',
      _featureList: 'TheAdmin/featureList',
      userRoleList: 'TheAdmin/userRoleList',
      accountList: 'TheAdmin/accountList',
    }),
    localSelected: {
      get() { return this.selected; },
      set(newValue) { this.$emit('update:selected', newValue); },
    },
    featureList() {
      return Object.values(this._featureList).map((feature) => ({
        ...feature,
        label: feature.label.split('_').join(' ').capitalize(),
      }));
    },
    step1Complete() {
      const { owner, state } = this.account;
      if (owner && state) return true;
      return false;
    },
    step2Complete() {
      return this.features.length > 0;
    },
    step3Complete() {
      const { name } = this.workspace;
      if (name) return true;
      return false;
    },
    step4Complete() {
      const { firstName, lastName } = this.userDetail;
      const { passName, passPhrase } = this.authenticate;
      if (firstName && lastName && passName && passPhrase) return true;
      return false;
    },
    newAccount() {
      if (!Object.values(this.accountList).length) return null;
      const accountPopped = Object.values(this.accountList).pop();

      return accountPopped;
    },
    userRoleListFiltered() {
      if (!this.userRoleList.length) return [];
      const roleAccepted = ['ACCOUNT_ADMIN', 'ACCOUNT_MANAGER', 'ACCOUNT_USER', 'ACCOUNT_CONTRIBUTOR'];
      return this.userRoleList.reduce((acc, userRole) => {
        if (!roleAccepted.includes(userRole.urid)) return acc;

        acc.push(userRole);
        return acc;
      }, []);
    },
  },
  mounted() {
    this.$emit('reset');
    this.isFetched = false;
    return Promise.all([
      this.reportAccountStateList({}),
      this.reportFeatureList({}),
      this.reportUserRole({}),
    ])
      .then(() => {
        this.isFetched = true;
        this.userDetail.userRole = this.userRoleList.find(({ urid }) => urid === 'ACCOUNT_ADMIN');
      });
  },
  methods: {
    ...mapActions({
      reportAccountStateList: 'TheAdmin/reportAccountStateList',
      reportFeatureList: 'TheAdmin/reportFeatureList',
      _createAccount: 'TheAdmin/createAccount',
      reportUserRole: 'TheAdmin/reportUserRole',
    }),
    createAccount() {
      const { owner, state } = this.account;
      const {
        userRole, isUbu, ...userDetail
      } = this.userDetail;
      const { passPhrase, passName } = this.authenticate;
      this._createAccount({
        payload: {
          owner: owner.toLowerCase().trim(),
          state,
          channelGroupList: [{ name: this.workspace.name.trim(), picture: null }],
          user: {
            isUbu,
            urid: userRole.urid,
            userDetail: { ...userDetail },
            authenticate: { passPhrase, passName },
          },
          features: this.features,
        },
        httpQuery: { isStub: true },
      })
        .then(({ response: account }) => {
          if (account) this.activeStep += 1;
        });
    },
    initProcessCreate() {
      this.isFetched = true;
      this.activeStep = 0;
      this.account = { owner: null, state: 'TRIAL' };
      this.features = [];
      this.workspace = { name: null };
      this.isUb = false;
      this.userDetail = {
        firstName: null,
        lastName: null,
        phone: '+33',
        userRole: null,
        isUbu: false,
      };
      this.authenticate = { passName: null, passPhrase: uuidv4() };
    },
  },
};
</script>
