/* eslint-disable max-len */
// import allowedAddons from '../conf/addons';
import dataStores from '@dailyplanet/data-stores';

const appAddons = [
  'admin',
  'common',
  'error',
  'authenticate',
  'dataStores',
  'home',
  'router',
  'servers',
  // 'ubuLead',
  'monitoring',
  'users',
  'kara',
  'user',
  'setting',
  'shopify',
  'crews',
  'rewards',
];

const crossAddons = [
  'authentification',
  'crossCommon',
  'dev',
  'notifier',
  'menu',
  'workspace',
  'settings',
  'sidePanel',
  'table',
  'filters',
];

const appAddonsFolder = require.context('@dailyplanet/kara-addons', true, /.js$/);
const crossAddonsFolder = require.context('@dailyplanet/cross-addons', true, /.js$/);

const addons = (thisAddons, from) => thisAddons.keys()
  .reduce((acc, path) => {
    const [myAddon] = path.split('/').slice(1);
    const allowedAddons = from === 'cross' ? crossAddons : appAddons;
    if (allowedAddons.includes(myAddon) && !acc[myAddon]) {
      // eslint-disable-next-line
      const addon = require(`@dailyplanet/${from}-addons/${myAddon}`);
      if (addon.default) {
        acc[myAddon] = addon.default;
        return acc;
      }
      Object.entries(addon).forEach(([addonName, addonCore]) => {
        // console.log('🚀 ~ file: addons.js ~ line 38 ~ Object.entries ~ addonName', addonName);
        // console.log('🚀 ~ file: addons.js ~ line 38 ~ Object.entries ~ addonCore', addonCore);
        acc[addonName] = addonCore;
      });
      return acc;
    }
    return acc;
  }, { dataStores });

const register = (addonsList, key) => Object.entries(addonsList)
  .reduce((acc, [addonKey, addonValue]) => {
    if (appAddons.concat(crossAddons).includes(addonKey) && addonValue[key]) acc.push(addonValue[key]);
    return acc;
  }, []);

const routes = { ...register({ ...addons(appAddonsFolder, 'kara'), ...addons(crossAddonsFolder, 'cross') }, 'routes') };

const stores = { ...register({ ...addons(appAddonsFolder, 'kara'), ...addons(crossAddonsFolder, 'cross') }, 'stores') };

const lib = { ...register({ ...addons(appAddonsFolder, 'kara'), ...addons(crossAddonsFolder, 'cross') }, 'lib') };

const smarts = { ...register({ ...addons(appAddonsFolder, 'kara'), ...addons(crossAddonsFolder, 'cross') }, 'smarts') };

export {
  routes,
  stores,
  lib,
  smarts,
};
