<template>
  <fieldset>
    <b-field
      horizontal
      label="Is ubu ?"
    >
      <b-switch
        v-model="userDetail.isUbu"
        @input="$update('isUbu', $event)"
      />
    </b-field>
  </fieldset>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuKaraUbuTeamForm',
  mixins: [VModelMixin],
  model: {
    prop: 'userDetail',
  },
  props: {
    userDetail: {
      type: Object,
      required: true,
    },
  },
};
</script>
