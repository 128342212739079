/* eslint-disable no-multi-spaces */
import TheHome from './TheHome';

export default [
  {
    path: '/home',
    name: 'Home',
    component: TheHome,
    meta: {
      title: 'Home',
      hasMenu: true,
      requireAuth: true,
      viewStoreNavigation: 'TheHome',
    },
  },
];
