<template>
  <div class="multibar row-vcentered multibar-spaced">
    <component :is="`UbuIcon${platformName.capitalize()}`" />

    <span>{{ name }}</span>

    <b-tag
      v-if="id"
      class="is-dark"
    >
      {{ id }}
    </b-tag>
  </div>
</template>

<script>
export default {
  name: 'UbuInlinePlatform',
  props: {
    platformName: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: '',
    },
  },
};
</script>
