/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const activity = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}/activity`, method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}/activity`, method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}/activity/${payload.aid}`, method: 'PUT', payload, httpQuery, target }),
  patch: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}/activity/${payload.aid}`, method: 'PATCH', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.cid}/activity/${payload.aid}`, method: 'DELETE', payload, httpQuery, target }),
};

export default activity;
