import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  actions: {
    create({ rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'THREAD_APPOINT.CREATE')) return undefined;

      return clark.api.channelGroup.bulkThreadAppoint.post({ payload });
    },
    delete({ rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'THREAD_APPOINT.DELETE')) return undefined;

      return clark.api.channelGroup.bulkThreadAppoint.delete({ payload });
    },
  },
};
