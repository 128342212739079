<template>
  <b-dropdown
    aria-role="list"
    class="UbuColorPicker"
  >
    <template #trigger>
      <b-field>
        <b-button
          :class="['block-color-selector', `bg-color-${color}-filled`]"
        />
        <span class="subtext is-3 has-text-primary legend">Edit color</span>
      </b-field>
    </template>
    <div class="icon-color-picker-menu">
      <template v-for="colorId in 12">
        <span
          :key="colorId"
          :class="`UbuColorPicker_color${colorId}`"
          @click="$emit('input', colorId.toString())"
        >
          <div
            :class="['block-color', `bg-color-${colorId}-filled`]"
          />
        </span>
      </template>
    </div>
  </b-dropdown>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuColorPicker',
  mixins: [VModelMixin],
  model: {
    prop: 'color',
  },
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>
