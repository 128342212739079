<template>
  <TheSimplePanel
    closing-way="emit"
    @close="$emit('close')"
  >
    <template #header>
      <div class="multibar row-vcentered">
        <b-icon
          icon="ubu-custom-tag"
          pack="ubu"
          :class="`start has-text-color-${custom.color}`"
        />
        <p class="start text is-3 has-text-secondary"> {{ custom.label }} </p>
      </div>
      <h1 class="title is-2">Remove tag automatically after a number of days of inactivity</h1>
    </template>

    <template #content>
      <b-field
        label="Duration of inactivity"
        grouped
      >
        <b-field
          label="days"
          class="mt-2 mr-4"
        >
          <b-numberinput
            v-model="localAutoflushDuration.days"
            style="width: 200px"
            controls-alignment="right"
            :min="0"
            @input="$update('autoflushDuration', { ...localAutoflushDuration, days: $event })"
          />
        </b-field>
        <b-field
          label="hours"
          class="mt-2"
        >
          <b-numberinput
            v-model="localAutoflushDuration.hours"
            style="width: 200px"
            controls-alignment="right"
            :min="0"
            @input="$update('autoflushDuration', { ...localAutoflushDuration, hours: $event })"
          />
        </b-field>
      </b-field>

      <div class="buttons is-right">
        <b-button
          label="Remove automation"
          type="is-danger"
          :disabled="!localCustom.hasAutoflush"
          @click="removeAutomation()"
        />

        <b-button
          label="Save changes"
          :disabled="!canSave"
          type="is-success"
          @click="setAutomation()"
        />
      </div>
    </template>
  </TheSimplePanel>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';
import NavigationMixin from '@dailyplanet/mixins/NavigationMixin';
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'TheSettingTagEditAutoflushPanel',
  mixins: [VModelMixin, NavigationMixin],
  model: {
    prop: 'custom',
  },
  props: {
    custom: { type: Object, required: true },
  },
  data() {
    return {
      days: null,
      hours: null,
      isSaved: false,
    };
  },
  computed: {
    ...mapGetters({
      threadGroupList: 'TheSettingWorkspaceSetting/threadGroupList',
    }),
    localCustom: {
      get() {
        return {
          ...this.custom,
          autoflushDuration: this.custom.autoflushDuration
            ? { ...this.custom.autoflushDuration }
            : null,
        };
      },
      set(val) { this.$emit('input', val); },
    },
    localAutoflushDuration: {
      get() {
        if (!this.localCustom.autoflushDuration) return { days: 0, hours: 0 };
        return this.localCustom.autoflushDuration;
      },
    },
    canSave() {
      if (JSON.stringify(this.localCustom.autoflushDuration) === JSON.stringify(this.threadGroupList[this.custom.tgid].autoflushDuration)) return false;
      return true;
    },
  },
  methods: {
    ...mapActions({
      _updateThreadGroup: 'TheSettingWorkspaceSetting/updateThreadGroup',
      reportThreadGroup: 'TheSettingWorkspaceSetting/reportThreadGroup',
      emitSegmentEvent: 'TheInbox/emitSegmentEvent',
    }),
    removeAutomation() {
      return this._updateThreadGroup({
        payload: {
          tgid: this.custom.tgid,
          createdOn: this.custom.createdOn,
          deletedOn: this.custom.deletedOn,
          label: this.custom.label,
          color: this.custom.color,
          hasAutotag: this.custom.hasAutotag,
          hasAutoclose: this.custom.hasAutoclose,
          hasAutoflush: false,
          autoflushDuration: null,
        },
      })
        .then((updatedThreadGroup) => {
          this.$emit('input', updatedThreadGroup);
        })
        .then(() => this.$emit('close'));
    },
    setAutomation() {
      return this._updateThreadGroup({
        payload: {
          tgid: this.custom.tgid,
          createdOn: this.custom.createdOn,
          deletedOn: this.custom.deletedOn,
          label: this.custom.label,
          color: this.custom.color,
          hasAutotag: this.custom.hasAutotag,
          hasAutoclose: this.custom.hasAutoclose,
          hasAutoflush: true,
          autoflushDuration: this.$moment.duration(this.localCustom.autoflushDuration).toISOString(),
        },
      })
        .then((updatedThreadGroup) => {
          this.emitSegmentEvent({ event: 'activeAutoFlush' });
          this.$emit('input', updatedThreadGroup);
        })
        .then(() => this.$emit('close'));
    },
  },
};
</script>
