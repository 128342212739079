<template>
  <div class="multibar ubuTextareaBottomTools">
    <b-dropdown
      :position="dropdownPosition.emoji"
      append-to-body
      paddingless
      class="start"
    >
      <template #trigger>
        <b-tooltip
          label="Emojis"
          position="is-top"
        >
          <b-button
            icon-left="ubu-emoji"
            icon-pack="ubu"
            type="is-tertiary-icon"
          />
        </b-tooltip>
      </template>

      <b-dropdown-item
        custom
        paddingless
      >
        <VEmojiPicker
          :dark="isDark"
          :show-search="true"
          :emoji-size="24"
          :emojis-by-row="6"
          @select="selectEmoji($event)"
        />
      </b-dropdown-item>
    </b-dropdown>

    <b-tooltip
      v-if="!reachMode"
      :label="attachmentsTooltip"
      position="is-top"
    >
      <b-upload
        v-model="localFile"
        :multiple="false"
        :disabled="platformName === 'FACEBOOK' || message.length > 0"
        accept="image/png, image/jpeg, image/svg"
        :class="['button is-tertiary-icon', {
          disabled: platformName === 'FACEBOOK' || message.length > 0,
        }]"
      >
        <b-icon
          pack="ubu"
          icon="ubu-image"
        />
      </b-upload>
    </b-tooltip>

    <TheTemplateDropdown
      :position="dropdownPosition.template"
      append-to-body
      @insertText="$emit('insertText', $event)"
      @openTemplatePanel="$emit('openTemplatePanel'); up_textImported(message)"
    />

    <!-- <TheTextareaAffiliationButton
      :contact-details="contactDetails"
      :reach-mode="reachMode"
      :dropdown-position="dropdownPosition.affiliations"
      @insertText="$emit('insertText', $event)"
      @hasPanelOpen="$emit('hasPanelOpen', $event)"
    /> -->

    <b-dropdown
      class="dropdown-translate"
      :position="dropdownPosition.translate"
      scrollable
      append-to-body
      max-height="250px"
    >
      <template #trigger>
        <b-tooltip
          label="Translate"
          position="is-top"
        >
          <b-button
            icon-left="ubu-translate"
            icon-pack="ubu"
            type="is-tertiary-icon"
          />
        </b-tooltip>
      </template>

      <b-dropdown-item
        custom
        paddingless
      >
        <div class="multibar row-vcentered">
          <b-icon
            class="start"
            pack="ubu"
            icon="ubu-translate"
          />
          <span class="start ml-1">Translate</span>
        </div>
      </b-dropdown-item>

      <b-dropdown-item separator />

      <b-dropdown-item paddingless>
        <b-button
          label="Reset to original"
          type="is-ghost is-borderless"
          @click="$emit('revertTranslate')"
        />
      </b-dropdown-item>

      <b-dropdown-item
        v-for="(language, index) in optionLanguages"
        :key="index"
        @click="handleTranslate(language)"
      >
        <div class="multibar row-vcentered">
          <b-image
            class="is-18x18 start"
            :src="language.flag"
          />
          <span class="ml-1 start">{{ language.label }}</span>
        </div>
      </b-dropdown-item>
    </b-dropdown>

    <!-- @TODO TRIBE de-commenter quand on remet la feature -->
    <!-- <b-dropdown
      class="ubuTextareaDropdownAffiliation"
      position="is-top-right"
    >
      <template #trigger>
        <b-button
          v-if="!reachMode"
          icon-left="ubu-affiliation"
          icon-pack="ubu"
          type="is-tertiary-icon"
        />
      </template>
      <p class="text is-3 has-text-secondary">{{ tribeDescription }}</p>
      <hr class="no-margin">
      <b-dropdown-item aria-role="listitem">
        <b-button
          class="is-ghost no-padding is-borderless"
          label="Insert tribe form link"
          expanded
          @click="$emit('insertTribeFormLink')"
        />
      </b-dropdown-item>
    </b-dropdown> -->

    <TheTextareaCrewInviteButton
      :cpid="threadDetails.cpid"
      @insertText="$emit('insertText', $event)"
    />
  </div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';
import ThemeMixin from '@dailyplanet/mixins/ThemeMixin';
import { mapActions } from 'vuex';

const ukFlag = require('@dailyplanet/assets/svg/UK.svg');
const frenchFlag = require('@dailyplanet/assets/svg/France.svg');
const germanyFlag = require('@dailyplanet/assets/svg/Germany.svg');
const italyFlag = require('@dailyplanet/assets/svg/Italy.svg');
const japanFlag = require('@dailyplanet/assets/svg/Japan.svg');
const netherlandFlag = require('@dailyplanet/assets/svg/Netherlands.svg');
const spainFlag = require('@dailyplanet/assets/svg/Spain.svg');
const portugueseFlag = require('@dailyplanet/assets/svg/Portuguese.svg');

export default {
  name: 'UbuTextareaBottomTools',
  components: {
    VEmojiPicker,
  },
  mixins: [ThemeMixin],
  model: {
    prop: 'file',
  },
  props: {
    file: {
      type: File,
      default: null,
    },
    threadDetails: {
      type: Object,
      required: true,
    },
    templateList: {
      type: Object,
      default: () => {},
    },
    shopifyCodeList: {
      type: Array,
      default: () => [],
    },
    reachMode: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    accountFeatures: {
      type: Function,
      required: true,
    },
    platformName: {
      type: String,
      default: null,
    },
    dropdownPosition: {
      type: Object,
      default: () => ({
        emoji: 'is-top-left',
        template: 'is-top-right',
        affiliations: 'is-top-right',
        translate: 'is-top-right',
      }),
    },
  },
  data() {
    return {
      isDropdownTemplateOpen: false,
      inputSearch: '',
      search: '',
      inputSearchShopifyCode: '',
      openEmoji: false,
      closing: false,
      optionLanguages: [
        { label: 'French', value: 'fr', flag: frenchFlag },
        { label: 'English', value: 'en-US', flag: ukFlag },
        { label: 'Germany', value: 'de', flag: germanyFlag },
        { label: 'Spanish', value: 'es', flag: spainFlag },
        { label: 'Italian', value: 'it', flag: italyFlag },
        { label: 'Japanese', value: 'ja', flag: japanFlag },
        { label: 'Portuguese', value: 'pt-br', flag: portugueseFlag },
        { label: 'Dutch', value: 'nl', flag: netherlandFlag },
      ],
      tribeDescription: 'Send a form to your closest influencers to invite them to join your Tribe. Get their opt-in and other valuable information.',
    };
  },
  computed: {
    localFile: {
      get() { return this.file; },
      set(value) {
        this.$emit('input', value);
        this.$emit('checkImageMetadata', value);
      },
    },
    attachmentsTooltip() {
      if (this.platformName === 'FACEBOOK') return 'Sending images on Facebook is coming soon.';
      return this.message.length > 0 ? 'Images must be sent separately' : 'Attachments';
    },
    filtreTemplates() {
      if (!this.templateList || !Object.values(this.templateList).length) return {};

      return Object.values(this.templateList).reduce((acc, row) => {
        if (!this.search) {
          acc.push(row);
          return acc;
        }

        const filter = row.title.toLowerCase().includes(this.search.toLowerCase());

        if (filter) acc.push(row);

        return acc;
      }, []).arrayMapper('tid');
    },

    contactDetails() {
      return {
        ...this.threadDetails.contact,
        contactPlatform: this.threadDetails.contactPlatform,
      };
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch(newVal) {
      this.search = newVal;
    },
  },
  mounted() {
    document.body.addEventListener('click', (e) => {
      const { className } = e.target;

      if (!className.includes('button')) this.closeEmojiPicker();
    });
  },
  methods: {
    ...mapActions({
      emitSegmentEvent: 'TheInbox/emitSegmentEvent',
      up_textImported: 'TheAddTemplatePanel/up_textImported',
    }),

    selectEmoji(event) {
      if (event && event.data) this.$emit('insertText', event.data);
      this.closeEmojiPicker();
    },
    closeEmojiPicker() {
      this.closing = true;
      setTimeout(() => {
        this.openEmoji = false;
        this.closing = false;
      }, 200);
    },
    handleTranslate(language) {
      if (!this.accountFeatures('INBOX')) {
        this.emitSegmentEvent({ event: 'translateTextNoFeature' });
        this.$intercom.showNewMessage('Hi! I would like to activate the INBOX module! 🙌');

        return;
      }

      this.$emit('translateText', language.value);
    },
    toogleTemplateDropdown(isOpen) {
      this.isDropdownTemplateOpen = isOpen;
    },
  },
};
</script>
