/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    templateFolderList: {},
  },
  mutations: {
    SET_TEMPLATE_FOLDER_LIST(state, newData) { state.templateFolderList = { ...state.templateFolderList, ...newData }; },
    UNSET_TEMPLATE_FOLDER(state, newData) { state.templateFolderList = Object.values(state.templateFolderList).filter(({ tfid }) => Number(tfid) !== Number(newData)).arrayMapper('tfid'); },
  },
  getters: {
    getterTemplateFolderList: (state) => state.templateFolderList,
  },
  actions: {
    report({ commit }, { payload, httpQuery }) {
      return clark.api.apiConversation.templateFolder.report({ payload, httpQuery })
        .then((templateFolderList) => {
          commit('SET_TEMPLATE_FOLDER_LIST', templateFolderList.arrayMapper('tfid'));
          return templateFolderList;
        });
    },

    create({ commit }, { payload, httpQuery }) {
      return clark.api.apiConversation.templateFolder.post({ payload, httpQuery })
        .then((templateFolder) => {
          commit('SET_TEMPLATE_FOLDER_LIST', { [templateFolder.tfid]: templateFolder });
          return templateFolder;
        });
    },

    update({ commit }, { payload, httpQuery }) {
      return clark.api.apiConversation.templateFolder.put({ payload, httpQuery })
        .then((templateFolder) => {
          commit('SET_TEMPLATE_FOLDER_LIST', { [templateFolder.tfid]: templateFolder });
          return templateFolder;
        });
    },

    delete({ commit }, { payload }) {
      return clark.api.apiConversation.templateFolder.delete({ payload, httpQuery: {} })
        .then(() => {
          commit('UNSET_TEMPLATE_FOLDER', payload.tfid);
          commit('ApiConversation/template/UNSET_TEMPLATE_FOLDER_TFID', payload.tfid, { root: true });
        });
    },
  },
};
