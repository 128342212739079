/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  actions: {
    reportContactPlatformAffiliation({ commit, getters, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'AFFILIATION_CODE.REPORT')) return undefined;

      return clark.api.apiContact.contactPlatformAffiliation.report({ payload, httpQuery })
        .then((affiliationCodeList) => {
          const contactPlatformOverview = Object.values(getters.getterContactPlatformOverviewList).find(({ cpid }) => payload.cpid === cpid);

          commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
            ...getters.getterContactPlatformOverviewList,
            [payload.cpid]: {
              ...contactPlatformOverview,
              affiliationList: affiliationCodeList.arrayMapper('acid'),
            },
          });

          return affiliationCodeList;
        });
    },

    UP_affiliationCode({ commit, getters }, { payload, response }) {
      if (!response || !payload.cpid) return;

      const contactPlatformOverview = Object.values(getters.getterContactPlatformOverviewList).find(({ cpid }) => payload.cpid === cpid);

      const affiliationMapped = response.arrayMapper('acid');

      commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
        ...getters.getterContactPlatformOverviewList,
        [payload.cpid]: {
          ...contactPlatformOverview,
          affiliationList: {
            ...contactPlatformOverview.affiliationList,
            ...affiliationMapped,
          },
        },
      });
    },

    UP_removeAffiliationCode({ commit, getters }, { payload, response }) {
      // after remove cpid affiliation we must remove code from CPO nesting
      if (!response || !payload.cpid) return;

      const contactPlatformOverview = Object.values(getters.getterContactPlatformOverviewList).find(({ cpid }) => payload.cpid === cpid);

      const affiliationMapped = Object.values(contactPlatformOverview.affiliationList).reduce((acc, row) => {
        if (row.acid === payload.acid) return acc;
        acc[row.acid] = row;
        return acc;
      }, {});

      commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
        ...getters.getterContactPlatformOverviewList,
        [payload.cpid]: {
          ...contactPlatformOverview,
          affiliationList: affiliationMapped,
        },
      });
    },
  },
};
