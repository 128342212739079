/* eslint-disable quotes */
/* eslint-disable object-curly-newline */

import builder from '../../engine';

const target = 'clark';

const account = {
  get: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}`, method: 'GET', payload, httpQuery, target }),
  report: ({ payload, httpQuery }) => builder({ url: `/api/account/`, method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/account/`, method: 'POST', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}`, method: 'DELETE', payload, httpQuery, target }),
  link: {
    channel: {
      post: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}/link/${payload.uid}/channel/${payload.cid}`, method: 'POST', payload, httpQuery, target }),
    },
    channelGroup: {
      post: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}/link/${payload.uid}/channelGroup/${payload.cgid}`, method: 'POST', payload, httpQuery, target }),
    },
  },
  unlink: {
    channel: {
      post: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}/unlink/${payload.uid}/channel/${payload.cid}`, method: 'POST', payload, httpQuery, target }),
    },
    channelGroup: {
      post: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}/unlink/${payload.uid}/channelGroup/${payload.cgid}`, method: 'POST', payload, httpQuery, target }),
    },
  },
  facebookConnect: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}/metaConnect/`, method: 'REPORT', payload, httpQuery, target }),
    post: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}/metaConnect/`, method: 'POST', payload, httpQuery, target }),
  },
  accountMemberView: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}/accountMemberView/`, method: 'REPORT', payload, httpQuery, target }),
  },
  setAdmin: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}/setAdmin/${payload.uid}`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}/setAdmin/${payload.uid}`, method: 'DELETE', payload, httpQuery, target }),
  },
  changeState: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}/changeState/${payload.state}`, method: 'POST', payload, httpQuery, target }),
  },
  setFeature: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}/setFeature`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/api/account/${payload.aid}/setFeature`, method: 'DELETE', payload, httpQuery, target }),
  },
};

export default account;
