/* eslint-disable max-len */
// import moment from 'moment';
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

// import heldThreads from '../../ApiContact/contactPlatformOverview.store/heldThreads';

export default {
  namespaced: true,
  state: {
    threadList: {},
    threadBoxList: {},
    currentThreadTid: null,
    // ...heldThreads.state,
  },
  mutations: {
    SET_THREAD_LIST(state, { threadList }) {
      state.threadList = { ...state.threadList, ...threadList };
    },
    SET_THREADBOX_LIST(state, threadBoxList) { state.threadBoxList = threadBoxList; },
    REMOVE_THREAD_FROM_THREAD_LIST(state, tid) {
      state.threadList = Object.values(state.threadList).filter((thread) => String(thread.tid) !== String(tid)).arrayMapper('tid');
    },
    RESET_THREAD_LIST(state) { state.threadList = {}; }, // look if we can delete this or replace by RESET_STORE
    RESET_STORE(state) {
      state.threadList = {};
      state.currentThreadTid = null;
      state.holdedThread = {};
    },
    SET_CURRENT_THREADTID(state, tid) { state.currentThreadTid = tid; },
    // ...heldThreads.mutations,
  },
  getters: {
    getterThreadList: (state) => state.threadList,
    getterThreadListTids: (state) => Object.keys(state.threadList),
    getterThreadBoxList: (state) => state.threadBoxList,
    getterCurrentThreadTid: (state) => state.currentThreadTid,
    // ...heldThreads.getters,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'THREADLIST.REPORT')) return undefined;

      return clark.api.channelGroup.threadList.report({ payload, httpQuery })
        .then((threadList) => {
          const { 'ApiContact/contactPlatformOverview/getterContactPlatformOverviewList': contactPlatformOverviewList } = rootGetters;
          const { results } = threadList;

          commit('SET_THREAD_LIST', { threadList: results.reduce((acc, row) => ({ ...acc, [row.tid]: row.cpid }), {}) });
          commit('ApiContact/contactPlatformOverview/SET_CONTACT_PLATFORM_OVERVIEW_LIST', { ...contactPlatformOverviewList, ...results.arrayMapper('cpid') }, { root: true });

          return threadList;
        });
    },
    reset({ commit }) {
      commit('RESET_STORE');
      commit('ApiContact/contactPlatformOverview/RESET_CONTACT_PLATFORM_OVERVIEW_LIST', null, { root: true });
    },
    removeThread({ commit }, { payload: { tid } }) { commit('REMOVE_THREAD_FROM_THREAD_LIST', tid); },

    UP_refreshThreadOnSocket({ commit, dispatch }, payload) {
      console.warn('🚀 ~ file: index.js ~ line 75 ~ UP_refreshThreadOnSocket ~ payload', payload);
      const { cpid, tid } = payload;

      commit('SET_THREAD_LIST', { threadList: { [tid]: cpid } });
      dispatch('ApiContact/contactPlatformOverview/UP_upsertThreadInThreadList', { payload }, { root: true });
    },

    up_removeThreadTidInThreadList({ getters, commit }, { tids }) {
      const threadList = getters.getterThreadList;

      const newThreadList = Object.values(threadList).reduce((acc, thread) => {
        if (tids.includes(thread.tid)) return acc;
        acc.push(thread);

        return acc;
      }, []).arrayMapper('tid');

      commit('ApiConversation/threadList/RESET_THREAD_LIST', null, { root: true });
      commit('ApiConversation/threadList/SET_THREAD_LIST', { threadList: newThreadList }, { root: true });
    },
  },
};
