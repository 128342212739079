/* eslint-disable max-len */
// import { clark } from '@dailyplanet/providers';
// import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {},
};
