<template>
  <aside class="ubuSidePanel ubuPanel-xl">
    <div class="multibar">
      <b-button
        class="end is-ghost is-borderless is-large is-simple-icon"
        icon-pack="ubu"
        icon-left="ubu-cross"
        @click="$emit('close')"
      />
    </div>

    <br>

    <header>
      <p class="text is-3 has-text-secondary">Create campaign</p>
      <h1 class="title">Create a new campaign</h1>
    </header>

    <br>

    <template v-if="error">
      <UbuHelper
        :type="`is-${error.type}`"
        :content-type="error.type"
        :content="error.message"
      />

      <br>
    </template>

    <form @submit.prevent="createCampaign()">
      <fieldset>
        <b-field>
          <b-input
            ref="inputCampaignName"
            v-model="newCampaign.name"
            required
            placeholder="My campaign..."
          />
        </b-field>

        <br>

        <b-field label="Color">
          <UbuColorPickerInline v-model="newCampaign.color" />
        </b-field>
      </fieldset>

      <br>

      <div class="multibar">
        <b-button
          class="end"
          type="is-primary"
          label="Create campaign"
          native-type="submit"
        />
      </div>
    </form>
  </aside>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheCreateCampaignPanel',
  data() {
    return {
      error: null,
      newCampaign: {
        name: null,
        color: 1,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.inputCampaignName) this.$refs.inputCampaignName.focus();
    });
  },
  methods: {
    ...mapActions({
      _createCampaign: 'TheCampaign/createCampaign',
    }),
    createCampaign() {
      this.error = null;
      const { name, color } = this.newCampaign;

      this._createCampaign({ payload: { name, color: color.toString() } })
        .then(() => {
          this.newCampaign = { name: null, color: 1 };
          this.$emit('close');
        })
        .catch(({ message }) => {
          switch (message) {
            case 'E_BAD_REQUEST_CAMPAIGN_SCHEMA':
              this.error = { type: 'danger', message: 'An error has occurred due to the data sent. Please contact system support' };
              break;
            default:
              this.error = { type: 'danger', message: 'Unexpected error. Please contact system support.' };
              break;
          }
        });
    },
  },
};
</script>
