<template>
  <div
    class="ubuThreadCard"
    @click="$emit('selectCard')"
  >
    <div
      class="avatarContainer"
      @click.stop="UP_toggleHeld()"
    >
      <UbuAvatar
        class=""
        :profile-picture="item.contactPlatform.profilePicture"
        :initial-name="item.contactPlatform.username"
        :platform-name="item.contactPlatform.platformName"
        :size="40"
        :is-size="40"
        clickable
        :is-selected="threadTidsSelectedInBulk.includes(item.tid)"
      />
    </div>
    <div class="bodyContainer">
      <div class="multibar row-vcentered">
        <p class="start text is-3 text-truncate">
          {{ item.contact.firstName }}
          {{ item.contact.lastName }}
          {{ item.contactPlatform.username }}
        </p>

        <div
          v-if="item.thread && item.thread.updatedOn"
          class="end"
        >
          <div class="multibar row-vcentered">
            <div
              v-if="!item.thread.isRead"
              class="end"
              @click.stop="readThread()"
            >
              <b-tooltip
                type="is-info"
                animated
                position="is-left"
                class="markAsRead is-clickable badge"
              >
                <UbuIconCircleStatus />
                <template v-slot:content>
                  <div class="multibar row-vcentered">
                    <span>Mark as read</span>
                    <b-tag
                      type="is-light"
                      class="ml-1"
                    >
                      <b-icon
                        v-if="isMac"
                        icon="apple-keyboard-command"
                        size="is-small"
                      />
                      <span v-else>Ctrl</span>
                    </b-tag>
                    <b-tag
                      type="is-light"
                      class="ml-1"
                    >
                      <span>i</span>
                    </b-tag>
                  </div>
                </template>
              </b-tooltip>
            </div>
            <div v-else-if="item.lastMessage.isMe">
              <b-icon
                icon="ubu-reply"
                pack="ubu"
                class="mr-1"
                size="is-small"
              />
            </div>
            <span
              :class="['end disclaimer is-3', {
                'isUnread': !item.thread.isRead,
              }]"
            >
              {{ dateFormatter }}
            </span>
          </div>
        </div>
      </div>

      <div
        class="multibar row-vcentered"
        style="max-width: 196px;"
      >
        <template v-if="customChipList.length">
          <template v-for="(chip, index) in customChipList">
            <UbuCustomChip
              v-if="chip.listItem.length > 0"
              :key="chip.defaultIcon"
              v-model="customChipList[index]"
              class="start mr-1"
            />
          </template>
        </template>
        <p
          v-if="item.lastMessage"
          :class="['start subtext is-3 ellipsis', {
            'has-text-secondary': item.lastMessage.isMe
          }]"
        >
          {{ lastMessageTextParser }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import iconsMixins from '@dailyplanet/cross-addons/crossCommon/$mixins/dataIcons.mixins';
import ShortcutMixin from '@dailyplanet/mixins/ShortcutMixin';

export default {
  name: 'TheThreadListCard',
  mixins: [iconsMixins, ShortcutMixin],
  props: {
    contactPlatformOverview: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      userList: 'TheInbox/userList',
      campaignList: 'TheInbox/campaignList',
      tribeList: 'TheTribe/tribeList',
      _labelList: 'TheInbox/contactGroupList',
      _tagList: 'TheInbox/threadGroupList',
      threadTidsSelectedInBulk: 'TheInbox/threadTidsSelectedInBulk',
    }),
    item() {
      return this.contactPlatformOverview;
    },
    tribes() {
      return this.item.tribeMemberList.map((tbe) => this.tribeList[tbe.tribeTid]);
    },
    labels() {
      return this.item.labels.map((id) => this._labelList[id]);
    },
    tags() {
      return this.item.tags.map((id) => this._tagList[id]);
    },
    campaign() {
      return this.item.lastActivity && !this.item.lastActivity.endedOn ? this.campaignList[this.item.lastActivity.campaignCid] : null;
    },
    assign() {
      return this.item.hasUser ? this.userList[this.item.hasUser.userUid] : null;
    },
    customChipList() {
      return [{
        title: 'Assignee',
        // classColor: 'text-color-3',
        defaultIcon: 'ubu-assign',
        count: null,
        listItem: this.assign ? [{
          icon: null,
          color: '0',
          label: `${this.assign.userDetail.firstName} ${this.assign.userDetail.lastName}`,
        }] : [],
      },
      {
        title: 'Labels',
        // classColor: 'text-color-2',
        defaultIcon: 'ubu-custom-contact',
        count: this.labels.length > 1 ? this.labels.length : null,
        listItem: this.labels.map((label) => ({
          icon: 'ubu-custom-contact',
          color: label.color,
          label: label.label,
        })),
      },
      {
        title: 'Tags',
        // classColor: 'has-text-color-8',
        defaultIcon: 'ubu-custom-tag',
        count: this.tags.length > 1 ? this.tags.length : null,
        listItem: this.tags.map((tag) => ({
          icon: 'ubu-custom-tag',
          color: tag.color,
          label: tag.label,
        })),
      },
      {
        title: 'Tribes',
        // classColor: 'has-text-color-4',
        defaultIcon: 'ubu-custom-tribe',
        count: this.tribes.length > 1 ? this.tribes.length : null,
        listItem: this.tribes.map((tribe) => ({
          icon: tribe.icon,
          color: tribe.color,
          label: tribe.name,
        })),
      },
      {
        title: 'Active campaign',
        // classColor: 'text-color-9',
        defaultIcon: 'ubu-custom-influence',
        count: null,
        listItem: this.campaign ? [{
          icon: 'ubu-custom-influence',
          color: this.campaign.color,
          label: this.campaign.name,
        }] : [],
      }];
    },
    // matchingUser() {
    //   if (this.item.hasUser) {
    //     const matchingUser = Object.values(this.userList)
    //       .find((user) => user.uid === this.item.hasUser[0]);

    //     if (matchingUser) return matchingUser.userDetail.firstName;
    //   }
    //   return '';
    // },
    // isMac() {
    //   return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    // },

    lastMessageTextParser() {
      if (this.item.lastMessage) {
        const pronounPersoMention = this.item.lastMessage.isMe ? 'them' : 'you';
        const pronounPossMention = this.item.lastMessage.isMe ? 'your' : 'their';
        const pronounPossStory = this.item.lastMessage.isMe ? 'their' : 'your';
        switch (this.item.lastMessage.messageType) {
          case 'MEDIA_AUDIO':
            return 'Shared a voice message';
          case 'MEDIA_IMAGE':
          case 'MEDIA_VIDEO':
            return 'Shared a media';
          case 'POST_SHARE':
          case 'FELIX_SHARE':
          case 'CLIP':
            return 'Shared a post';
          case 'PRODUCT_SHARE':
            return 'Shared a product';
          case 'PROFILE_SHARE':
            return 'Shared a profile';
          case 'STORY_SHARE':
            return 'Shared a story';
          case 'DELETED_MESSAGE':
            return 'Deleted message';
          case 'STORY_MENTION':
            return `Mentioned ${pronounPersoMention} in ${pronounPossMention} story`;
          case 'POST_MENTION':
            return `Mentioned ${pronounPersoMention} in ${pronounPossMention} post`;
          case 'STORY_REACT':
            return `Reacted to ${pronounPossStory} story`;
          case 'UNSUPPORTED':
          case 'MALFORMED':
            return 'Shared an unsupported message';
          case 'ACTION_LOG':
            return this.item.lastMessage.isMe ? 'You liked a message' : 'Liked a message';
          case 'TEXT':
          case 'LINK':
          case 'ERROR_WHILE_SENDING':
            return this.item.lastMessage.text;
          default:
            return this.item.lastMessage.text;
        }
      }
      return this.item.lastMessage.text;
    },
    dateFormatter() {
      const renameDate = [
        { init: 'a year', replace: '1y' },
        { init: 'years', replace: 'y' },
        { init: 'a month', replace: '1mo' },
        { init: 'months', replace: 'mo' },
        { init: 'a day', replace: '1d' },
        { init: 'days', replace: 'd' },
        { init: 'an hour', replace: '1h' },
        { init: 'hours', replace: 'h' },
        { init: 'a minute', replace: '1min' },
        { init: 'minutes', replace: 'min' },
        { init: 'a few seconds', replace: '1s' },
        { init: 'Invalid date', replace: '' },
      ];

      const date = this.$moment().from(this.$moment(this.item.lastMessage ? this.item.lastMessage.createdOn : null), true);
      const dateTarget = renameDate.find(({ init }) => date.includes(init));

      return date.replace(dateTarget.init, dateTarget.replace);
    },
  },

  methods: {
    ...mapActions({
      _bulkThreadsState: 'TheInbox/_bulkThreadsState',
      up_changeCurrentThreadIsRead: 'TheThreadPanel/up_changeCurrentThreadIsRead',
      _UP_toggleHeld: 'TheInbox/UP_toggleHeld',
      _UP_resetBulkHeldThreads: 'TheInbox/UP_resetBulkHeldThreads',
    }),
    readThread() {
      return this._bulkThreadsState({
        payload: {
          event: 'THREAD_READ',
          tids: [this.contactPlatformOverview.tid],
        },
        mutations: [
          {
            target: 'ApiContact/contactPlatformOverview/UP_updateThreadState',
            payload: {
              key: 'isRead',
              value: true,
              cpids: [this.contactPlatformOverview.cpid],
            },
          },
        ],
      });
    },
    UP_toggleHeld() {
      return this._UP_toggleHeld(this.item.tid)
        .then(() => (!this.threadTidsSelectedInBulk.length ? this._UP_resetBulkHeldThreads() : undefined));
    },
    changeThreadStatus(event) {
      const { tid } = this.thread;

      const payload = {
        event,
        tids: [tid],
      };

      return this.bulkThreadsState({ payload })
        .then(() => this.up_changeCurrentThreadIsRead({ tid, isRead: true }))
        .catch((e) => {
          console.log('FAILURE', e);
        });
    },
  },
};
</script>
