<template>
  <b-tab-item>
    <section class="ubuWizardView">
      <header>
        <p class="arianne">Add workspace - Step 1/2</p>

        <h1 class="title">Workspace Information</h1>
      </header>

      <DevDetails :cnt="{name: 'localNewWorkspace', obj: localNewWorkspace}" />

      <form @submit.prevent="$emit('create')">
        <UbuWorkspaceInfoForm v-model="localNewWorkspace" />

        <br>

        <div class="buttons is-right">
          <b-button
            label="Cancel"
            type="is-secondary"
            tag="router-link"
            to="/setting/organization"
          />

          <b-button
          label="Add workspace"
            type="is-primary"
            native-type="submit"
          />
        </div>
      </form>
    </section>
  </b-tab-item>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuAddWorkspaceInfoTab',
  mixins: [VModelMixin],
  model: {
    prop: 'newWorkspace',
  },
  props: {
    newWorkspace: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localNewWorkspace: {
      get() { return this.newWorkspace; },
      set(val) { this.$emit('input', val); },
    },
  },
};
</script>

<style>

</style>
