/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import axios from 'axios';

import ErrorBuilder from '@dailyplanet/utils/Errors';

import { store } from './index';

axios.defaults.withCredentials = true;

const connectors = {
  barbara: () => axios.create({
    baseURL: process.env.VUE_APP_BARBARA_BASE_URL,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' },
  }),
  clark: () => {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_CLARK_BASE_URL,
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
    });

    instance.interceptors.response.use((response) => {
      // console.log('🚀 ~ file: engine.js ~ line 7 ~ store', store);
      // console.log('🚀 ~ file: engine.js:37 ~ instance.interceptors.response.use ~ response', response);
      const { data, ...rest } = response;
      const { __authenticate__, __response__ } = data;
      // console.log('🚀 ~ file: engine.js ~ line 29 ~ instance.interceptors.response.use ~ __response__', __response__);
      if (!__authenticate__) return Promise.resolve({ data: __response__, ...rest });
      return store && store.dispatch
        ? store.dispatch('Authenticate/up_authenticate', __authenticate__, { root: true })
          .then(() => ({ data: __response__, ...rest }))
        : Promise.resolve({ data: __response__, ...rest });
    }, (error) => Promise.reject(error));

    return instance;
  },
  clarkDl: () => {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_CLARK_BASE_URL,
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
    });

    return instance;
  },

  s3: ({ headers, callback }) => {
    const instance = axios.create({
      // baseURL: process.env.VUE_APP_CLARK_BASE_URL,
      // withCredentials: true,
      headers,
      onUploadProgress: (progressEvent) => callback(progressEvent.loaded),
    });

    return instance;
  },
};

const internals = {
  // eslint-disable-next-line arrow-body-style
  parseResponse: ({ data }) => {
    return data;
  },
  // eslint-disable-next-line arrow-body-style
  parseError: (error) => {
    // console.log('🚀 ~ file: engine.js ~ line 33 ~ error.response', error.response);

    // Here we handle error sent with res.json
    if (error.response.data.__response__) {
      throw new ErrorBuilder({
        message: error.response.data.__response__.code,
        body: error.response.data.__response__.body.data ? error.response.data.__response__.body.data : error.response.data.__response__.body,
        status: error.response.status,
        statusText: error.response.statusText,
      });
    }

    // Used for errors returned with res.send
    throw new ErrorBuilder({
      message: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    });
  },
  httpQueries: (context) => {
    if (context.target === 's3') return context.url;
    let httpQuery;

    let session = 'thomas@manacorp.eu';

    if (process.env.session) {
      session = `${process.env.session}@getubu.com`;
    }

    if (process.env.VUE_APP_MODE === 'dev') {
      httpQuery = { ...context.httpQuery, testSession: session };
    } else {
      httpQuery = context.httpQuery || {};
    }

    const queries = Object.entries(httpQuery);
    const httpQueries = queries
      .reduce((acc, [key, val], index) => {
        if (index > 0 && index <= (queries.length - 1)) {
          acc += val && typeof val !== 'boolean' ? `&${key}=${val}` : `&${key}`;
        } else {
          acc += val && typeof val !== 'boolean' ? `?${key}=${val}` : `?${key}`;
        }
        return acc;
      }, '');

    return `${context.url}${httpQueries}`;
  },
};

const requestBuilder = (context) => {
  const { payload: data, target = 'clark', method } = context;
  const url = internals.httpQueries(context);
  return connectors[target](context).request({ url, data, method });
};

const builder = (context) => requestBuilder(context)
  .then(internals.parseResponse)
  .catch(internals.parseError);

export default builder;
