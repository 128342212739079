<template>
  <section class="multibar row-vcentered">
    <b-image
      class="start mr-2 is-40x40"
      :src="ubuLogo"
    />

    <div class="start">
      <h3 class="text is-3">Ubu's Meta Extension</h3>

      <div class="multibar row-vcentered has-text-secondary">
        <b-icon
          pack="ubu"
          icon="ubu-circle-divider"
          :class="['small',
                   state.classIcon
          ]"
        />
        <span class="subtext is-3 mr-3">
          {{ state.status }}
        </span>
        |
        <b-button
          label="How to reconnect extension"
          icon-pack="ubu"
          icon-right="ubu-link"
          type="is-ghost is-borderless"
          @click="goToExternalIntercom()"
        />
        |
        <b-button
          label="Contact support"
          type="is-ghost is-borderless"
          @click="openIntercom()"
        />
      </div>
    </div>
  </section>
</template>

<script>
const ubuLogo = require('@dailyplanet/selina/src/assets/images/ubuLogov2.svg');

export default {
  name: 'UbuPrivateConnectRow',
  props: {
    extensionState: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ubuLogo,
    };
  },
  computed: {
    state() {
      if (this.extensionState === 'CONNECTED') {
        return {
          status: 'Connected',
          classIcon: 'has-text-success',
          classButton: 'is-success',
          labelButton: 'Reconnect',
          emitButton: () => this.$emit('openConnexionPanel'),
        };
      }
      if (this.extensionState === 'DISCONNECTED') {
        return {
          status: 'Disconnected',
          classIcon: 'has-text-danger',
          classButton: 'is-secondary',
          labelButton: 'Reconnect',
          emitButton: () => this.$emit('openConnexionPanel'),
        };
      }

      return {
        status: 'Disconnected',
        classIcon: 'has-text-danger',
        classButton: 'is-secondary',
        labelButton: 'Connect',
        emitButton: () => {
          this.$emit('contactSupport', 'Ubu\'s Instagram');
        },
      };
    },
  },
  methods: {
    goToExternalIntercom() {
      window.open('https://intercom.help/getubu/en/articles/6201440-chrome-extension-set-up', '_blank');
    },

    openIntercom() {
      this.$intercom.showNewMessage('Hi! I would like to reconnect my Ubu\'s Meta Extension! 🙌');
    },
  },
};
</script>
