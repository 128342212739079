/* eslint-disable default-case */
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      navIsDark: window.matchMedia('(prefers-color-scheme: dark)').matches,
    };
  },
  computed: {
    isDark() {
      return this.theme === 'DARK' || (this.theme === 'AUTO' && this.navIsDark);
    },
    ...mapGetters({
      currentTheme: 'TheSettingUserSetting/currentTheme',
    }),
    theme: {
      get() { return this.currentTheme; },
      set(theme) { this.changeTheme(theme); },
    },
  },
  mounted() {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        this.navIsDark = e.matches;
      });

    if (this.isDark) document.documentElement.classList.add('theme--dark');
    else document.documentElement.classList.remove('theme--dark');
  },
  methods: {
    ...mapActions({
      changeTheme: 'TheSettingUserSetting/changeTheme',
    }),
    switchTheme() {
      this.theme = this.isDark ? 'LIGHT' : 'DARK';
    },
  },
  watch: {
    isDark(isDark) {
      if (isDark) {
        document.documentElement.classList.add('theme--dark');
      } else {
        document.documentElement.classList.remove('theme--dark');
      }
    },
  },
};
