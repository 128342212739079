/* eslint-disable max-len */
import { getters, actions } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
  },

  mutations: {
  },

  getters: {
    ...getters.tribe,
    ...getters.memberGroup,

    tabItemsForTheCrmBulk: () => ({
      'root.addToTribe': {
        tab: 'root.addToTribe',
        title: 'Select a tribe',
        subtext: 'Bulk actions',
      },
      'root.addToTribe.selectGroup': {
        tab: 'root.addToTribe.selectGroup',
        title: 'What group do you want to add them to?',
        subtext: 'Bulk actions',
      },
      'root.done': {
        tab: 'root.done',
        title: 'Summary',
        subtext: 'Bulk actions',
      },
    }),
  },

  actions: {
    ...actions.tribeMember,
  },
};
