import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheTable from './TheTable';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheTable);
  },
};

use(Plugin);

export default Plugin;

export {
  TheTable,
};
