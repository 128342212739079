/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    contactLanguageList: [],
  },
  mutations: {
    SET_CONTACT_LANGUAGE_LIST(state, newData) { state.contactLanguageList = newData; },
    RESET_STORE(state) {
      state.contactLanguageList = [];
    },
  },
  getters: {
    getterContactLanguageList: (state) => state.contactLanguageList,
  },
  actions: {
    report({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'CONTACT.REPORT')) return undefined;

      return clark.api.channelGroup.contact.report({ payload, httpQuery: { onlyLanguage: true } })
        .then((languageList) => {
          commit('SET_CONTACT_LANGUAGE_LIST', languageList);
        });
    },
  },
};
