<template>
  <div class="inboxBulkPanel">
    <header class="inboxBulkPanelHeader">
      <div class="multibar row-vcentered top">
        <span class="start">{{ threadTidsSelectedInBulk.length }} selected</span>

        <b-button
          class="start"
          label="Select all"
          :disabled="localActiveTab.tab.includes('root.')"
          type="is-ghost is-borderless"
          @click="selectAllBulkAction()"
        />

        <span class="start has-text-secondary">|</span>

        <b-button
          class="start"
          label="Unselect all"
          type="is-ghost is-borderless"
          @click="quitBulkAction()"
        />
      </div>

      <UbuThreadBulkActions
        v-if="localActiveTab.tab === 'root'"
        :thread-state-is-open="threadStateIsOpen"
        :has-unread="hasUnread"
        @click="selectAction($event)"
        @readUnread="readUnread()"
        @openClose="openClose()"
      />

      <div
        v-else
        class="multibar row-vcentered"
      >
        <b-breadcrumb
          class="start"
          align="is-left"
          size="is-small"
        >
          <b-breadcrumb-item
            v-for="(item, index) in breadcrumbs"
            :key="item.label"
            :class="['text is-3', { disabled: onLastTab && item.disabledOnLastTab }]"
            :active="item.tabToGo === breadcrumbs[breadcrumbs.length - 1].tabToGo"
            @click="useBreadcrumb(item, index)"
          >
            {{ item.label }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </header>

    <b-tabs
      v-model="localActiveTab.tab"
      class="no-header"
    >
      <b-tab-item value="root" />

      <TheThreadBulkTag :checked-rows="bulkHeldThreads" />
      <TheThreadBulkLabel :checked-rows="bulkHeldThreads" />
      <TheThreadBulkAppoint :checked-rows="bulkHeldThreads" />

      <TheSendInstantMessage
        :checked-rows="bulkHeldThreads"
        :active-tab="localActiveTab"
        :tab-items="tabItems"
        :dropdown-position="{
          emoji: 'is-bottom-right',
          template: 'is-bottom-right',
        }"
        @update:activeTab="UP_changeToNewTab($event)"
        @addBreadcrumb="breadcrumbs.push($event)"
      />

      <!-- <TheBulkAssignUser
        v-model="checkedThreadToAppoint"
        @apply="refreshCheckedList()"
      /> -->

      <TheAddToTribe
        v-model="bulkHeldThreads"
        :active-tab="localActiveTab"
        :tab-items="tabItems"
        @update:activeTab="UP_changeToNewTab($event)"
        @addBreadcrumb="breadcrumbs.push($event)"
      />

      <TheNewAddToCampaign
        :checked-rows="bulkHeldThreads"
        :active-tab="localActiveTab"
        :tab-items="tabItems"
        @update:activeTab="UP_changeToNewTab($event)"
        @addBreadcrumb="breadcrumbs.push($event)"
      />
    </b-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ThreadListMixin from '@dailyplanet/mixins/ThreadListMixin';

export default {
  name: 'TheThreadBulkPanel',
  mixins: [ThreadListMixin],
  data() {
    return {
      breadcrumbs: [{ label: 'Bulk actions', tabToGo: 'root', disabledOnLastTab: false }],
    };
  },
  computed: {
    ...mapGetters({
      activeTab: 'TheThreadBulkPanel/activeTab',
      tabItems: 'TheThreadBulkPanel/tabItems',
      threadTidsSelectedInBulk: 'TheInbox/threadTidsSelectedInBulk',
      accountFeatures: 'TheInbox/accountFeatures',

      bulkHeldThreads: 'TheInbox/bulkHeldThreads',
    }),

    localActiveTab: {
      get() { return this.activeTab; },
      set(newValue) { this.UP_activeTab(newValue); },
    },

    // checkedThreadToLabel() {
    //   return this.checkedThreads.map((t) => ({ ...t, cid: t.contactPlatform.contactCid }));
    // },

    // checkedThreadToAppoint() {
    //   return this.checkedThreads
    //     .map(({ tid, appoints }) => ({ tid, userUid: appoints.length ? appoints[0] : null }));
    // },

    onLastTab() {
      return this.localActiveTab.tab.includes('.done');
    },

    threadStateIsOpen() {
      const { open } = this.$route.query;

      if (!open) return undefined;

      return open === 'true';
    },

    hasUnread() {
      return !!this.bulkHeldThreads.find((row) => row.thread.isRead === false);
    },
  },
  mounted() {
    return Promise.resolve()
      .then(() => this.UP_resetStore())
      .then(() => this.UP_checkedRows(this.checkedRows));
  },
  beforeDestroy() {
    this.quitBulkAction();
  },
  methods: {
    ...mapActions({
      UP_activeTab: 'TheThreadBulkPanel/UP_activeTab',
      UP_resetStore: 'TheThreadBulkPanel/UP_resetStore',
      UP_checkedRows: 'TheThreadBulkPanel/UP_checkedRows',
      goToTabByTabPath: 'TheThreadBulkPanel/goToTabByTabPath',
      UP_changeToNewTab: 'TheThreadBulkPanel/UP_changeToNewTab',
      emitSegmentEvent: 'TheInbox/emitSegmentEvent',

      UP_resetBulkHeldThreads: 'TheInbox/UP_resetBulkHeldThreads',
      UP_bulkHeldAllThreads: 'TheInbox/UP_bulkHeldAllThreads',
      up_changeBulkIsRead: 'TheInbox/up_changeBulkIsRead',

      _bulkThreadsState: 'TheTextarea/_bulkThreadsState',
    }),

    useBreadcrumb(item, index) {
      // disable breadcrumb when flow is finish
      // only back to 1st step is allowed
      if (this.onLastTab && item.disabledOnLastTab) return;

      this.UP_changeToNewTab(this.tabItems[item.tabToGo]);
      this.breadcrumbs = this.breadcrumbs.filter((_, i) => i <= index);
    },

    selectAction(tab) {
      this.UP_changeToNewTab(this.tabItems[tab]);
      // eslint-disable-next-line max-len
      this.breadcrumbs.push({ label: this.tabItems[tab].title, tabToGo: tab, disabledOnLastTab: true });
    },

    selectAllBulkAction() {
      console.log('🚀 ~ file: TheThreadBulkPanel.vue ~ line 195 ~ threadList ~ this.threadList', this.threadList);
      const threadList = this.threadList.reduce((acc, row) => {
        acc[row.tid] = row.cpid;
        return acc;
      }, {});
      console.log('🚀 ~ file: TheThreadBulkPanel.vue ~ line 198 ~ threadList ~ threadList', threadList);
      return this.UP_bulkHeldAllThreads(threadList);
    },

    quitBulkAction() {
      return Promise.resolve()
        .then(() => this.UP_resetStore())
        .then(() => this.UP_resetBulkHeldThreads());
    },

    openClose() {
      // block bulk close if account doesnt have feature INBOX
      if (!this.threadStateIsOpen && !this.accountFeatures('INBOX')) {
        this.emitSegmentEvent({ event: 'closeThreadNoFeature' });
        this.$intercom.showNewMessage('Hi! I would like to activate the INBOX module! 🙌');

        return null;
      }

      const { cpids, tids } = this.bulkHeldThreads.reduce((acc, { cpid, tid }) => {
        acc.cpids.push(cpid);
        acc.tids.push(tid);
        return acc;
      }, { cpids: [], tids: [] });

      return this._bulkThreadsState({
        payload: {
          event: this.threadStateIsOpen ? 'THREAD_CLOSED' : 'THREAD_OPENED',
          tids,
        },
        mutations: [
          {
            target: 'ApiContact/contactPlatformOverview/UP_updateThreadState',
            payload: {
              key: 'isOpen',
              value: !this.threadStateIsOpen,
              cpids,
            },
          },
        ],
      })
        .then(() => {
          this.$buefy.snackbar.open({
            type: 'is-success',
            position: 'is-bottom-right',
            message: `${tids.length} thread${tids.length > 1 ? 's' : ''} ${this.threadStateIsOpen ? 'closed' : 'opened'}!`,
          });
        });
    },

    readUnread() {
      const { cpids, tids } = this.bulkHeldThreads.reduce((acc, { cpid, tid }) => {
        acc.cpids.push(cpid);
        acc.tids.push(tid);
        return acc;
      }, { cpids: [], tids: [] });

      return this._bulkThreadsState({
        payload: {
          event: this.hasUnread ? 'THREAD_READ' : 'THREAD_UNREAD',
          tids,
        },
        mutations: [
          {
            target: 'ApiContact/contactPlatformOverview/UP_updateThreadState',
            payload: {
              key: 'isRead',
              value: this.hasUnread,
              cpids,
            },
          },
        ],
      });
    },

    refreshCheckedList() {
      const tids = this.checkedThreads.map(({ tid }) => tid);

      this.update_ThreadsSelectedInBulkAfterBulkActions(tids);
    },
  },
};
</script>
