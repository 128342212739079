<template>
  <div
    class="card cardHome"
    @click="handleClick()"
  >
    <div class="card-image">
      <b-image
        :src="image"
        lazy
      />
    </div>
    <div class="card-content">
      <h3 class="subtitle is-1 card-subtitle m-0">{{ title }}</h3>

      <p class="text card-text m-0">{{ subTitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuCardHome',
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      require: true,
      required: true,
    },
    subTitle: {
      type: String,
      require: true,
      required: true,
    },
    path: {
      type: String,
      require: true,
      required: true,
    },
  },
  methods: {
    handleClick() {
      if (!this.path) return;

      this.$router.push(this.path);
    },
  },
};
</script>
