<template>
  <UbuShopifyCreateDiscountForm
    v-model="newDiscount"
    :selected-shop-sid="_currentShop.sid"
    :error-message="errorMessage"
    :is-loading="isLoading"
    @create="createFlow()"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheCreateDiscountCode',
  data() {
    return {
      newDiscount: {
        code: null,
        type: 'basic',
        amountType: 'percentage',
        amount: null,
        appliesOncePerCustomer: false,
        hasMinPrice: false,
        prerequisiteSubtotalMin: 0,
        startsAt: this.$moment().toDate(),
        endsAt: null,
        affiliationCodeFolderAcfid: null,
        hasUsageLimit: false,
        usageLimit: 1,
      },
      errorMessage: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      _currentShop: 'TheCreateDiscountCode/currentShop',
      _affiliationCodeFolderList: 'TheCreateDiscountCode/affiliationCodeFolderList',
    }),
  },
  mounted() {
    // auto-select a folder from selected shop
    this.newDiscount.affiliationCodeFolderAcfid = Object
      .values(this._affiliationCodeFolderList)
      .find(({ shopSid }) => shopSid === this._currentShop.sid).acfid;
  },
  methods: {
    ...mapActions({
      createDiscountCode: 'TheCreateDiscountCode/createDiscountCode',
      createAffiliationCodeList: 'TheCreateDiscountCode/createAffiliationCodeList',
    }),

    createDiscount() {
      const { 
        hasMinPrice,
        prerequisiteSubtotalMin,
        hasUsageLimit,
        usageLimit,
        affiliationCodeFolderAcfid,
        ...discount 
      } = this.newDiscount;

      const payload = { sid: this._currentShop.sid, title: discount.code, ...discount }

      if (hasMinPrice) payload.prerequisiteSubtotalMin = prerequisiteSubtotalMin;
      if (hasUsageLimit) payload.usageLimit = usageLimit;

      return this.createDiscountCode({ payload });
    },

    createAffiliationCode(discountExtrefId) {
      const payload = {
        sid: this._currentShop.sid,
        affiliationCodeList: [{
          shopSid: this._currentShop.sid,
          contactPlatformCpid: null,
          campaignCid: null,
          affiliationCodeFolderAcfid: this.newDiscount.affiliationCodeFolderAcfid,
          type: 'DISCOUNT',
          code: this.newDiscount.code,
          extrefId: discountExtrefId,
          link: `${this._currentShop.baseUrl}/discount/${this.newDiscount.code}`,
          provider: 'MANUAL',
          createdOn: this.$moment().toDate(),
        }],
      };

      return this.createAffiliationCodeList({ payload });
    },

    createFlow() {
      this.isLoading = true;

      return Promise.resolve()
        .then(() => this.createDiscount())
        .then(({ response }) => this.createAffiliationCode(response.id))
        .then(() => {
          this.isLoading = false;
          this.$emit('changeTab');
          this.resetData();

          this.$buefy.snackbar.open({
            ...snackbarSuccess,
            message: 'Discount code was successfully created.',
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.errorMessage = error.message;
        });
    },

    resetData() {
      this.newDiscount = {
        code: null,
        type: null,
        amountType: null,
        amount: null,
        appliesOncePerCustomer: false,
        hasMinPrice: false,
        prerequisiteSubtotalMin: 0,
        startsAt: this.$moment().toDate(),
        endsAt: null,
        affiliationCodeFolderAcfid: null,
        hasUsageLimit: false,
        usageLimit: 1,
      };
    },
  },
};
</script>
