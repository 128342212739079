import contactPlatformOverview from './contactPlatformOverview.store';
import contactPrivateMetadata from './contactPrivateMetadata.store';
// import mention from './mention.store';
// import tribe from './tribe.store';
// import tribeMember from './tribeMember.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    contactPlatformOverview,
    contactPrivateMetadata,
    // mention,
    // tribe,
    // tribeMember,
  },
};
