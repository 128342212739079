<template>
  <div>
    <router-view
      :selected="selected"
      @update:selected="localSelected = $event"
      @reset="$emit('reset')"
    />
  </div>
</template>

<script>
export default {
  name: 'TheUsersUser',
  props: {
    selected: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localSelected: {
      get() { return this.selected; },
      set(newValue) { this.$emit('update:selected', newValue); },
    },
  },
};
</script>
