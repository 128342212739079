var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"multibar row-vcentered"},[_c('UbuVectorIcon',{staticClass:"start mr-2",attrs:{"icon":{
      pack: 'ubu',
      icon: `ubu-social-google-drive`,
      iconPaths: 4,
      fontSize: '38'
    }}}),_vm._m(0),_c('b-button',{staticClass:"end",attrs:{"label":"Connect","type":"is-info"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"start"},[_c('h3',{staticClass:"text is-3"},[_vm._v("Google Drive")]),_c('p',{staticClass:"subtext is-3 has-text-secondary start"},[_vm._v("admin@wooliebrand.com")])])
}]

export { render, staticRenderFns }