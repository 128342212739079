<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        :to="`/admin/account/${currentAid}/channels`"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <h2 class="title is-1">Details channel Sms</h2>

    <br>
    <DevDetails :cnt="{ name: 'account', obj: account }" />
    <DevDetails :cnt="{ name: 'channelSms', obj: channelSms }" />

    <form v-if="account">
      <fieldset>
        <b-field
          label="Channel Cid"
          horizontal
        >
          <b-input
            v-model="channelSms.cid"
            readonly
          />
        </b-field>

        <br>

        <b-field
          label="Channel group cgid"
          horizontal
        >
          <b-input
            v-model="channelSms.channelGroupCgid"
            readonly
          />
        </b-field>

        <br>

        <b-field
          label="Contact platform Cpid"
          horizontal
        >
          <b-input
            v-model="channelSms.contactPlatformCpid"
            readonly
          />
        </b-field>

        <br>

        <b-field
          label="Platform name"
          horizontal
        >
          <b-input
            v-model="channelSms.platformName"
            readonly
          />
        </b-field>

        <br>

        <b-field
          label="Name"
          horizontal
        >
          <b-input
            v-model="channelSms.name"
            readonly
          />
        </b-field>

        <br>

        <b-field
          label="Username"
          horizontal
        >
          <b-input
            v-model="channelSms.contactPlatform.username"
            readonly
          />
        </b-field>

        <br>

        <b-field
          label="Is verified ?"
          horizontal
        >
          <b-switch
            v-model="channelSms.contactPlatform.isVerified"
            disabled
          />
        </b-field>
      </fieldset>
    </form>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheAdminAccountChannelDetailsSms',
  computed: {
    ...mapGetters({
      account: 'TheAdmin/account',
    }),
    currentAid() {
      return this.$route.params.aid;
    },
    currentCid() {
      return this.$route.params.cid;
    },
    channelSms() {
      if (!this.account) return null;

      const platform = this.account.channelGroupList
        .reduce((acc, { channelList }) => {
          acc.push(...channelList);
          return acc;
        }, [])
        .arrayMapper('cid');

      return platform[this.currentCid];
    },
  },
  mounted() {
    if (!this.channelSms) {
      this.$router.push({
        path: `/admin/account/${this.currentAid}/channels`,
      });
    }
  },
};
</script>
