/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    instagramAccountNewFollowersList: [],
  },
  mutations: {
    SET_INTAGRAM_ACCOUNT_NEW_FOLLOWERS_LIST(state, newData) { state.instagramAccountNewFollowersList = newData; },
  },
  getters: {
    getterInstagramAccountNewFollowersList: (state) => state.instagramAccountNewFollowersList,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PRIVATE_ANALYTICS.REPORT')) return undefined;

      return clark.api.channelGroup.instagramAccountNewFollowers.report({ payload, httpQuery })
        .then(({ __response__: newFollowers }) => { commit('SET_INTAGRAM_ACCOUNT_NEW_FOLLOWERS_LIST', newFollowers); });
    },
  },
};
