/* eslint-disable no-extend-native */
/* eslint-disable func-names */

Array.prototype.arrayMapper = function (key) {
  return this.reduce((acc, entry) => {
    acc[entry[key]] = entry;
    return acc;
  }, {});
};

String.prototype.capitalize = function () {
  const [firstLetter, ...rest] = this;
  return firstLetter.toUpperCase() + rest.join('').toLowerCase();
};
