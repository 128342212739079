<template>
  <section>
    <div class="multibar">
      <h2 class="start title is-1">Account detail</h2>

      <div class="end buttons">
        <b-button
          label="Create account"
          type="is-primary"
          icon-pack="ubu"
          icon-left="ubu-add"
          @click="$emit('addAccount')"
        />
      </div>
    </div>

    <br>

    <form>
      <div
        v-for="item in informations"
        :key="item.section"
      >
        <div
          v-if="item"
          class="card"
        >
          <div class="card-content">
            <div class="multibar">
              <h3 class="start subtitle is-2">
                {{ item.section }}
              </h3>
            </div>

            <br>

            <fieldset>
              <div
                v-for="element in item.elements"
                :key="element.name"
              >
                <b-field
                  v-if="element"
                  :label="element.name"
                >
                  <span v-if="element.input === 'text' || element.input === 'password'">
                    {{ element.value }}
                  </span>
                  <b-icon
                    v-if="element.input === 'switch'"
                    pack="ubu"
                    icon="ubu-circle-divider"
                    :class="[{
                      'has-text-success': element.value,
                      'has-text-danger': !element.value,
                    }]"
                  />
                </b-field>

                <br>
              </div>
            </fieldset>
          </div>
        </div>

        <br>
      </div>

      <br>

      <div class="buttons is-right">
        <b-button
          label="Create account"
          type="is-primary"
          icon-pack="ubu"
          icon-left="ubu-add"
          @click="$emit('addAccount')"
        />
      </div>
    </form>
  </section>
</template>

<script>
export default {
  name: 'UbuKaraSummaryAddAccount',
  props: {
    account: { type: Object, default: () => {} },
    userDetail: { type: Object, default: () => {} },
    authenticate: { type: Object, default: () => {} },
    features: { type: Array, default: () => [] },
    isUbu: { type: Boolean, default: false },
  },
  computed: {
    informations() {
      if (!Object.values(this.account).length) return [];
      if (!Object.values(this.userDetail).length) return [];
      if (!this.userDetail.userRole) return [];
      if (!Object.values(this.authenticate).length) return [];

      return [
        {
          section: 'Account informations',
          elements: [
            {
              name: 'Account owner name', placeholder: 'Account owner name', value: this.account.owner, input: 'text',
            },
            {
              name: 'Account state', placeholder: 'Account state', value: this.account.state, input: 'text',
            },
          ],
        },
        {
          section: 'Features account',
          elements: this.features.reduce((acc, row, index) => {
            acc.push({
              name: `Feature n°${index + 1}`, placeholder: 'Feature', value: row.label.split('_').join(' '), input: 'text',
            });
            return acc;
          }, []),
        },
        {
          section: 'User informations',
          elements: [
            {
              name: 'First name', placeholder: 'First name', value: this.userDetail.firstName, input: 'text',
            },
            {
              name: 'Last name', placeholder: 'Last name', value: this.userDetail.lastName, input: 'text',
            },
            {
              name: 'User role', placeholder: 'User role', value: this.userDetail.userRole.label, input: 'text',
            },
            {
              name: 'Is ubu team ?', placeholder: 'Is ubu team ?', value: this.isUbu, input: 'switch',
            },
          ],
        },
        {
          section: 'Authenticate',
          elements: [
            {
              name: 'Pass name', placeholder: 'Pass name', value: this.authenticate.passName, input: 'text',
            },
            {
              name: 'Pass phrase', placeholder: 'Pass phrase', value: this.authenticate.passPhrase, input: 'password',
            },
          ],
        },
      ];
    },
  },
};
</script>
