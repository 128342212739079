<template>
  <div
    v-if="loading"
    class="ubuThreadCard"
  >
    <div class="start">
      <b-skeleton
        circle
        width="44px"
        height="44px"
        class="avatar"
      />
    </div>
    <div
      class="info start ml-2"
      style="width: 100%;"
    >
      <b-skeleton width="90%" />
      <b-skeleton width="70%" />
    </div>
    <div>
      <b-skeleton width="20px" />
    </div>
  </div>
  <div
    v-else
    class="ubuThreadCard"
    @click="$emit('selectCard')"
  >
    <div class="profile">
      <div class="avatar">
        <!-- <b-image
          v-if="contactPlatform.profilePicture"
          rounded
          :src="contactPlatform.profilePicture"
          :placeholder="'https://via.placeholder.com/140x140.png'"
          :src-fallback="'https://via.placeholder.com/140x140.png'"
          alt="une image"
          lazy
          v-on="$listeners"
        /> -->
        <UbuAvatar
          :profile-picture="contactPlatform.profilePicture"
          :initial-name="contactPlatform.username[0]"
        />
      </div>
      <div class="info">
        <div class="multibar">
          <div
            v-if="labelsToDisplay"
            class="start customs multibar row-vcentered"
          >
            <b-icon
              v-for="label in labelsToDisplay"
              :key="label.tgid"
              :icon="`ubu-custom-contact`"
              pack="ubu"
              :class="`has-text-color-${label.color}`"
            />
            <span
              v-if="labelCount"
              class="subtext is-3"
            >{{ labelCount }}</span>
          </div>
          <p class="start subtext is-3 mb-1">
            {{ contact.firstName }} {{ contact.lastName }} {{ contactPlatform.username }}
          </p>
        </div>
        <div class="infoBottom">
          <div class="platform">
            <component
              :is="`UbuIcon${contactPlatform.platformName.capitalize()}`"
            />
          </div>
          <div
            v-if="tagsToDisplay"
            class="customs multibar row-vcentered"
          >
            <b-icon
              v-for="tag in tagsToDisplay"
              :key="tag.tgid"
              :icon="`ubu-custom-tag`"
              pack="ubu"
              :class="`has-text-color-${tag.color}`"
            />
            <span
              v-if="tagCount"
              class="subtext is-3"
            >
              {{ tagCount }}
            </span>
          </div>
          <div
            v-if="thread.lastMessage && thread.lastMessage.text"
            class="subtext is-3"
          >
            {{ thread.lastMessage.text }}
          </div>
          <div
            v-else-if="thread.lastMessage && thread.lastMessage.messageType"
            class="subtext is-3"
          >
            {{ thread.lastMessage.messageType }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="thread && thread.updatedOn"
      class="threadDetail"
    >
      <div class="start multibar">
        <span class="disclaimer">
          {{ showDate }}
        </span>
      </div>
      <div class="end multibar">
        <b-icon
          v-if="thread.lastMessage.senderContactPlatformCpid === thread.channel.contactPlatformCpid"
          class="end"
          pack="ubu"
          icon="ubu-send"
        />
        <b-icon
          v-else
          class="end"
          pack="ubu"
          icon="ubu-divider-circle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import iconsMixins from '@dailyplanet/cross-addons/crossCommon/$mixins/dataIcons.mixins';

export default {
  name: 'UbuThreadCard',
  mixins: [iconsMixins],
  props: {
    contactPlatform: {
      type: Object,
      default: () => ({}),
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    thread: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showDate() {
      const date = this.$moment().from(this.$moment(this.thread.lastMessage.createdOn), true);
      return date.replace(' months', 'mo').replace('a year', '1y');
    },
    loadSocialIcon() {
      return `ubu-social-${this.contactPlatform.platformName.toLowerCase()}`;
    },
    labelsToDisplay() {
      return this.thread.labels ? this.thread.labels.slice(0, 3) : null;
    },
    labelCount() {
      const { labels } = this.thread;
      return labels && labels.length > 3 ? `+${labels.length - 3}` : null;
    },
    tagsToDisplay() {
      return this.thread.tags ? this.thread.tags.slice(0, 3) : null;
    },
    tagCount() {
      const { tags } = this.thread;
      return tags && tags.length > 3 ? `+${tags.length - 3}` : null;
    },
  },
};
</script>
