/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const campaignAnalytics = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/campaign/${payload.campaignCid}/campaignAnalytics`, method: 'REPORT', payload, httpQuery, target }),
};

export default campaignAnalytics;
