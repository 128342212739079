/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {},
  getters: {
    ...getters.campaign,
    ...getters.affiliationCode,
    ...getters.affiliationCodeFolder,
    ...getters.shop,
  },
  actions: {
    ...actions.affiliationCode,
    ...actions.affiliationCodeFolder,
  },
};
