<template>
  <div>
    <b-field
      v-for="(item) in options"
      :key="item.value"
    >
      <b-checkbox
        v-model="localValues"
        :native-value="item.value"
        class="mb-4"
      >
        <div class="multibar row-vcentered">
          <template v-if="item.icon">
            <b-icon
              pack="ubu"
              :icon="item.icon"
              :class="`has-text-color-${item.color}`"
            />
          </template>

          <span>{{ item.label }}</span>
        </div>
      </b-checkbox>
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'UbuFilterCheckbox',
  model: {
    prop: 'values',
  },
  props: {
    values: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    localValues: {
      get() { return this.values; },
      set(val) { this.$emit('input', val); },
    },
  },
};
</script>
