import tremendousAccount from './tremendousAccount.store';
import tremendousCampaign from './tremendousCampaign.store';
import tremendousFundingSource from './tremendousFundingSource.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    tremendousAccount,
    tremendousCampaign,
    tremendousFundingSource,
  },
};
