<template>
  <section class="ubuSettingView start">
    <div class="multibar">
      <h1 class="start title is-1">Add shop</h1>
    </div>

    <br>

    <DevDetails :cnt="{ name: 'account', obj: account }" />
    <DevDetails :cnt="{ name: 'newShop', obj: newShop }" />
    <DevDetails :cnt="{ name: 'channelGroupSelected', obj: channelGroupSelected }" />
    <DevDetails :cnt="{ name: 'shopList', obj: shopList }" />

    <form>
      <b-steps
        v-model="activeStep"
        :rounded="false"
        :has-navigation="false"
        vertical
        label-position="right"
        size="is-small"
      >
        <b-step-item
          label="Select an account"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Add shop - Step 1/3</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="stepControl1"
              :prev="false"
            />
          </div>

          <br>

          <UbuKaraShopifySearchChannelGroupByAccountForm
            :account="account"
            :loading="searchLoading"
            :channel-groups="_account"
            :link="link"
            :channel-group-selected="channelGroupSelected"
            :no-result="noResult"
            @update:account="account = $event"
            @update:channelGroupSelected="channelGroupSelected = $event"
            @updateGroup="updateGroup($event)"
          />
        </b-step-item>

        <b-step-item
          label="Shop informations"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Add shop - Step 2/3</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="stepControl2"
            />
          </div>

          <br>
          <UbuKaraShopifyInformationsShop
            v-model="newShop"
          />
        </b-step-item>

        <b-step-item
          label="Confirmation"
          type="is-primary"
        >
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="has-text-secondary">Add shop - Step 3/3</p>
            <UbuSteperControls
              :active-step.sync="activeStep"
              :next-is-allowed="false"
              :prev="true"
              :next="false"
            />
          </div>

          <br>
          <UbuKaraShopifyShopConfirmation
            v-model="newShop"
            :channel-group="channelGroupSelected"
            :account="account.selected"
            @addShop="addShop()"
          />
        </b-step-item>

        <b-step-item
          type="is-success"
        >
          <section>
            <div class="multibar">
              <h2 class="start title is-1">The shop was created successfuly ! 🥳</h2>
            </div>

            <br>

            <div class="multibar">
              <div class="end buttons">
                <b-button
                  label="Create an other shop"
                  type="is-ghost"
                  @click="initProcessCreate()"
                />
                <b-button
                  label="Open details"
                  type="is-primary"
                  @click="localSelected = shopCreated"
                />
              </div>
            </div>
          </section>
        </b-step-item>
      </b-steps>
    </form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheShopAdd',
  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeStep: 0,
      searchLoading: false,
      noResult: false,
      account: {
        preset: [],
        selected: null,
        value: '',
      },
      newShop: {
        baseUrl: null,
        extrefId: null,
        apiVersion: null,
        type: null,
        apiKey: null,
        password: null,
        sharedSecret: null,
        isConnected: false,
      },
      link: { aid: null },
      channelGroupSelected: {},
      shopCreated: null,
    };
  },
  computed: {
    ...mapGetters({
      accountList: 'TheShop/accountList',
      _account: 'TheShop/account',
      shopList: 'TheShop/shopList',
    }),
    localSelected: {
      get() { return this.selected; },
      set(newValue) { this.$emit('update:selected', newValue); },
    },
    stepControl1() {
      if (Object.values(this.channelGroupSelected).length) return true;
      return false;
    },
    stepControl2() {
      const findNull = Object.values(this.newShop).find((el) => el === null);

      if (findNull === undefined) return true;
      return false;
    },
    newShopFormated() {
      if (!Object.values(this.channelGroupSelected).length) return false;
      const { cgid } = this.channelGroupSelected;
      const payload = {
        cgid,
        channelGroupCgid: cgid,
        ...this.newShop,
      };

      return payload;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'account.value': debounce(function (newVal) {
      this.account.preset = [];
      this.noResult = false;
      if (!newVal || !newVal.length || newVal.length < 3) {
        this.searchLoading = false;
        this.channelGroupSelected = {};
        return;
      }
      if (!newVal.match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/)) {
        this.searchLoading = false;
        this.channelGroupSelected = {};
        return;
      }

      this.searchLoading = true;
      this.reportAccountList({ httpQuery: { accountName: newVal.toLowerCase() } })
        .then(() => {
          if (!Object.values(this.accountList).length) {
            this.noResult = true;
            this.searchLoading = false;
            return;
          }
          this.link.aid = Object.values(this.accountList)[0].aid;
          this.account.preset = Object.values(this.accountList);
          this.searchLoading = false;
        });
    }, 1000),
    // eslint-disable-next-line func-names
    'account.selected': debounce(function (newVal) {
      this.channelGroups = null;
      if (!newVal || typeof newVal !== 'object') return;

      // this.searchLoading = true;
      this.getAccount({
        payload: { aid: newVal.aid },
        httpQuery: { channelAndChannelGroupList: true },
      });
    }, 1000),
  },
  mounted() {
    this.$emit('reset');
  },
  methods: {
    ...mapActions({
      reportAccountList: 'TheUsers/reportAccountList',
      getAccount: 'TheShop/getAccount',
      // createShop: 'TheShop/createShop',
      createManageShop: 'TheShop/createManageShop',
    }),
    initProcessCreate() {
      this.activeStep = 0;
      this.account = {
        preset: [],
        selected: null,
        value: '',
      };
      this.link = { aid: null };
      this.channelGroupSelected = {};
    },
    addShop() {
      const { cgid } = this.channelGroupSelected;

      this.createManageShop({
        payload: {
          channelGroupCgid: cgid,
          ...this.newShop,
        },
      })
        .then(({ response: shop }) => {
          if (shop) {
            this.shopCreated = shop;
            this.activeStep += 1;
          }
        });
    },
  },
};
</script>
