/* eslint-disable max-len */
export default {
  actions: {
    UP_removeFromTribe({ commit, getters }, { payload }) {
      const contactPlatformOverviewList = Object.values(getters.getterContactPlatformOverviewList).filter(({ cpid }) => payload.cpids.includes(cpid));

      commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
        ...getters.getterContactPlatformOverviewList,
        ...contactPlatformOverviewList.reduce((acc, row) => {
          acc[row.cpid] = row;
          acc[row.cpid].tribeMemberList = acc[row.cpid].tribeMemberList.filter(({ tmid }) => !payload.tmids.includes(tmid));
          return acc;
        }, {}),
      });
    },

    UP_updateTribeMember({ commit, getters }, { payload }) {
      const {
        getterContactPlatformOverviewList,
        getterHeldThreads,
        getterHeldThreadsCpids,
      } = getters;

      const mappedTribeMemberList = payload.tribeMemberList.arrayMapper('tmid');

      const reducer = (array) => array.reduce((acc, row) => {
        acc[row.cpid] = row;
        acc[row.cpid].tribeMemberList = acc[row.cpid].tribeMemberList.map((tm) => {
          if (mappedTribeMemberList[tm.tmid]) return mappedTribeMemberList[tm.tmid];
          return tm;
        });
        return acc;
      }, {});

      const contactPlatformOverviewList = Object.values(getterContactPlatformOverviewList)
        .filter(({ cpid }) => payload.cpids.includes(cpid) && !getterHeldThreadsCpids.includes(cpid));

      if (contactPlatformOverviewList.length) {
        commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
          ...getters.getterContactPlatformOverviewList,
          ...reducer(contactPlatformOverviewList),
        });
      }

      const heldThreadsList = Object.values(getterHeldThreads)
        .filter(({ cpid }) => payload.cpids.includes(cpid));

      if (heldThreadsList.length) {
        commit('UPDATE_HELDS', {
          ...getterHeldThreads,
          ...reducer(heldThreadsList),
        });
      }
    },

    UP_createTribeMember({ commit, getters }, { payload, response }) {
      if (!response || !response.length) return;

      const {
        getterContactPlatformOverviewList,
        getterHeldThreads,
        getterHeldThreadsCpids,
      } = getters;

      // eslint-disable-next-line object-curly-newline
      const tribeMemberList = response.map(({ tribeMember: { tmid, memberGroupMgid, tribeTid, createdOn, contactPlatformCpid } }) => ({
        tmid, memberGroupMgid, tribeTid, createdOn, contactPlatformCpid,
      }));

      const reducer = (array) => array.reduce((acc, row) => {
        acc[row.cpid] = row;
        if (!acc[row.cpid].tribeMemberList) acc[row.cpid].tribeMemberList = [];
        acc[row.cpid].tribeMemberList.push(...tribeMemberList.filter((tm) => tm.contactPlatformCpid === row.cpid));
        return acc;
      }, {});

      const contactPlatformOverviewList = Object.values(getterContactPlatformOverviewList)
        .filter(({ cpid }) => payload.cpids.includes(cpid) && !getterHeldThreadsCpids.includes(cpid));

      if (contactPlatformOverviewList.length) {
        commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
          ...getters.getterContactPlatformOverviewList,
          ...reducer(contactPlatformOverviewList),
        });
      }

      const heldThreadsList = Object.values(getterHeldThreads)
        .filter(({ cpid }) => payload.cpids.includes(cpid));

      if (heldThreadsList.length) {
        commit('UPDATE_HELDS', {
          ...getterHeldThreads,
          ...reducer(heldThreadsList),
        });
      }
    },
  },
};
