/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {},

  mutations: {},

  getters: {
    tabItemsForTheAddPeoplePanel: () => ({
      'root.addManually': {
        tab: 'root.addManually',
        title: 'Add manually',
        subtext: 'Add people',
      },
      'root.addManually.selectList': {
        tab: 'root.addManually.selectList',
        title: 'Where do you want to add them ?',
        subtext: 'Add people',
      },
      'root.addManually.selectList.createList': {
        tab: 'root.addManually.selectList.createList',
        title: 'Create list',
        subtext: 'Add people',
      },
      'root.addManually.selectList.done': {
        tab: 'root.addManually.selectList.done',
        title: 'Success',
        subtext: 'Add people',
      },
    }),
  },

  actions: {},
};
