<template>
  <div @keydown.prevent="shortcutHandlerTable($event, localChecked)">
    <b-table
      ref="table"
      :data="tribeMemberList"
      class="table-row-clickable table-full-height"
      hoverable
      sticky-header
      :loading="loading"
      :detail-key="detailKey"

      sticky-checkbox
      checkable
      :checked-rows.sync="localChecked"
      :custom-is-checked="(a, b) => {
        return a[detailKey] === b[detailKey];
      }"

      :selected.sync="selected"
      focusable

      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      pagination-size="is-small"

      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"

      @page-change="pageChange"
      @sort="onSort"
    >
      <b-table-column
        v-for="column in columns"
        :key="column.field"
        :field="column.field"
        :label="column.label"
        :sortable="column.isSortable"
        :visible="column.visible"
        :custom-sort="(a, b, isAsc) => customSort(a, b, isAsc, column)"
      >
        <template
          v-if="column.cmp === 'UbuTablePlatform'"
          #header
        >
          <b-icon
            pack="ubu"
            icon="ubu-organic"
          />
        </template>

        <template v-slot="props">
          <component
            :is="column.cmp"
            :cpo="props.row"
            :field="column.field"
            :column="column"
            @selectContactPlatform="$emit('selectContactPlatform', $event)"
          />
        </template>
      </b-table-column>

      <template #empty>
        <p class="has-text-secondary">No tribe members yet!</p>
      </template>
    </b-table>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';

export default {
  name: 'TheTribePeopleDetailed',
  mixins: [TableUtilsMixin],
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Object,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    allowedFilters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      detailKey: 'tmid',
      page: 1,
      perPage: 50,
      total: 0,
      sortField: 'tmid',
      sortOrder: 'asc',
      loading: false,
      selected: null,

      /* eslint-disable object-curly-newline */
      /* eslint-disable key-spacing */
      /* eslint-disable no-multi-spaces */
      columns: {
        platformName:     { cmp: 'UbuTablePlatform',           field: 'contactPlatform.platformName',              label: 'Platform',          isSortable: false },
        username:         { cmp: 'UbuTableProfile',            field: 'contactPlatform.username',                  label: 'Username',          isSortable: true },
        followerCount:    { cmp: 'UbuTableNumber',             field: 'contactPlatform.followerCount',             label: 'Followers',         isSortable: true },
        totalMentions:    { cmp: 'UbuTableNumber',             field: 'contactPlatformStatistic.totalMentions',    label: 'Mentions',          isSortable: true },
        totalOrders:      { cmp: 'UbuTableNumber',             field: 'contactPlatformStatistic.totalOrders',      label: 'Orders',            isSortable: true },
        totalRevenuesUsd: { cmp: 'UbuTableNumber',             field: 'contactPlatformStatistic.totalRevenuesUsd', label: 'Revenue',           isSortable: true, before: '$' },
        totalCoupons:     { cmp: 'UbuTableNumber',             field: 'contactPlatformStatistic.totalCoupons',     label: 'Coupons',           isSortable: true },
        'crewUser.state': { cmp: 'UbuTableCrewState',           field: 'crewUser.state',                    label: 'Crew state',        isSortable: true },
        createdOn:        { cmp: 'UbuTableDate',               field: 'tribeMember.createdOn',                     label: 'Date added',        isSortable: true },
        lastMessageOn:    { cmp: 'UbuTableDate',               field: 'contactPlatformStatistic.lastMessageOn',    label: 'Last message',      isSortable: true },
        lastMentionOn:    { cmp: 'UbuTableDate',               field: 'contactPlatformStatistic.lastMentionOn',    label: 'Last mention',      isSortable: true },
        memberGroupMgid:  { cmp: 'UbuTableMemberGroupPopover', field: 'tribeMember.memberGroupMgid',               label: 'Group',             isSortable: true },
        activeCampaign:   { cmp: 'UbuTableChipLastCampaign',   field: 'lastActivity.campaignCid',                  label: 'Active campaign',   isSortable: true },
        publicEmail:      { cmp: 'UbuTableSimple',             field: 'contact.publicEmail',                       label: 'Public Email',      isSortable: true },
      },
      /* eslint-enable object-curly-newline */
    };
  },
  computed: {
    ...mapGetters({
      _tribeMemberList: 'TheTribe/tribeMemberList',
      _memberGroupList: 'TheTribe/memberGroupList',
      _campaignList: 'TheTribe/campaignList',
    }),

    tribeMemberList() {
      return Object.values(this._tribeMemberList);
    },

    localChecked: {
      get() { return Object.values(this.checkedRows); },
      set(members) {
        this.$emit('input', members.arrayMapper('tmid'));
      },
    },
  },
  watch: {
    filters(newValue) {
      if (newValue) {
        return this._resetTribeMemberList()
          .then(() => this.reportDatas())
          .then(() => {
            // eslint-disable-next-line prefer-destructuring
            this.selected = this.tribeMemberList[0] || null;
          });
      }
      return undefined;
    },

    _tribeMemberList() {
      if (!Object.keys(this.localChecked).length) return;
      this.localChecked = Object.values(this._tribeMemberList)
        .filter((row) => this.checkedRows[row[this.detailKey]]);
    },
  },
  mounted() {
    this.$emit('input', {}); // reset checkedRows
    return Promise.resolve()
      .then(() => this.reportDatas())
      .then(() => {
        // eslint-disable-next-line prefer-destructuring
        this.selected = this.tribeMemberList[0] || null;
        if (this.$refs && this.$refs.table) this.$refs.table.focus();
      });
  },
  methods: {
    ...mapActions({
      _resetTribeMemberList: 'ApiTribe/tribeMember/resetTribeMemberList',
      _reportTribeMemberList: 'TheTribe/reportTribeMemberList',
    }),
    reportDatas() {
      this.loading = true;

      return this._reportTribeMemberList({
        payload: { tid: this.$route.params.tribeTid },
        httpQuery: {
          pagin: `${this.page - 1},${this.perPage}`,
          sorting: `${this.sortField},${this.sortOrder}`,
          filters: this.prepareFiltersToRequest(this.allowedFilters, this.filters),
        },
      })
        .then((total) => {
          this.total = total;
          this.loading = false;
        });
    },
  },
};
</script>
