<!-- eslint-disable max-len -->
<template>
  <section class="ubuContactPlatformPanelTab">
    <article class="ubuContactPlatformPanelItem">
      <header class="multibar row-vcentered p-0">
        <h3 class="start">Overview</h3>
      </header>

      <div class="tile is-ancestor is-vertical">
        <template v-for="(_, index) in tiles">
          <div
            v-if="index % 2 !== 1"
            :key="index"
            class="tile is-parent is-horizontal"
          >
            <div class="tile is-child">
              <p class="subtext is-2 has-text-secondary">{{ tiles[index].label }}</p>
              <p class="text is-2">
                {{ tiles[index].value ? tiles[index].symbolBefore : '' }}
                {{ tiles[index].value | nFormatter }}
                {{ tiles[index].value ? tiles[index].symbolAfter : '' }}
              </p>
            </div>
            <div
              v-if="tiles[index + 1]"
              class="tile is-child"
            >
              <p class="subtext is-2 has-text-secondary">{{ tiles[index + 1].label }}</p>
              <p class="text is-2">
                {{ tiles[index + 1].value ? tiles[index + 1].symbolBefore : '' }}
                {{ tiles[index + 1].value | nFormatter }}
                {{ tiles[index + 1].value ? tiles[index + 1].symbolAfter : '' }}
              </p>
            </div>
          </div>
        </template>
      </div>
      <b-tooltip
        class="w-100"
        position="is-bottom"
        size="small"
      >
        <template #content>
          <span v-if="Number(contactPlatformOverview.contactPlatform.followerCount) < 5000">
            Minimum of 5k followers is required
          </span>
        </template>

        <b-button
          expanded
          :label="'Generate report'"
          icon-pack="ubu"
          :disabled="Number(contactPlatformOverview.contactPlatform.followerCount) < 5000"
          :loading="isLoading"
          icon-left="ubu-overview"
          type="is-secondary"
          @click.native="getReport()"
        />
      </b-tooltip>
    </article>

    <UbuContactPlatformPanelCustoms v-model="contactPlatformOverview" />

    <UbuContactPlatformPanelCrewUser :contact-platform-overview="contactPlatformOverview" />

    <article
      v-if="localCurrentCampaign && localActivity"
      class="ubuContactPlatformPanelItem"
    >
      <header class="multibar row-vcentered">
        <h3 class="start noCutOnText">Last Campaign</h3>
        <p
          class="end subtext is-3 displayOnHover clickable noCutOnText"
          @click="$emit('changeTab', 'root.influence');"
        >
          View all campaigns
        </p>
      </header>

      <div class="start multibar row-vcentered pl-2">
        <b-icon
          :class="`start has-text-color-${localCurrentCampaign.color}`"
          pack="ubu"
          icon="ubu-custom-influence"
        />
        <h3 class="start text is-2 mb-2">{{ localCurrentCampaign.name }}</h3>
      </div>
      <div class="start">
        <TheActivityDropdown
          :custom-button="true"
          position="is-top-right"
          tooltip-position="is-right"
        />
      </div>
    </article>

    <TheContactPlatformPanelPrivateMetadata :key="contactPlatformOverview.cpid" />

    <article class="ubuContactPlatformPanelItem">
      <div class="ubuContactPlatformPanelNote isNote">
        <UbuSingleTextarea
          :key="contactPlatformOverview.cpid"
          v-model="localSheetNote"
          placeholder="Contact note..."
          custom-class="ubuTransparentInput p-0"
          :maxlength="255"
          @send="updateSheetNote($event)"
        />
      </div>
    </article>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheContactPlatformPanelOverviewTab',
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      contactPlatformOverview: 'TheContactPlatformPanel/contactPlatformOverview',
      campaignList: 'TheContactPlatformPanel/campaignList',
      _currentChannelGroup: 'TheContactPlatformPanel/currentChannelGroup',
    }),
    localSheetNote() {
      return this.contactPlatformOverview.contactPlatformStatistic ? this.contactPlatformOverview.contactPlatformStatistic.sheetNote : null;
    },
    localActivity() {
      return this.contactPlatformOverview.lastActivity ? this.contactPlatformOverview.lastActivity : null;
    },
    localCurrentCampaign() {
      return this.localActivity ? this.campaignList[this.localActivity.campaignCid] : null;
    },
    tiles() {
      return [
        {
          label: 'Followers 😍',
          value: this.contactPlatformOverview.contactPlatform.followerCount,
        },
        {
          label: 'Engagement 🎯',
          value: this.contactPlatformOverview.contactPlatform.engagement,
          symbolAfter: '%',
        },
        {
          label: 'Likes (avg) 💘',
          value: this.contactPlatformOverview.contactPlatform.likeCount,
        },
        {
          label: 'Comments (avg) 💬',
          value: this.contactPlatformOverview.contactPlatform.commentCount,
        },
        {
          label: 'Growth 🚀',
          value: this.contactPlatformOverview.contactPlatform.growth,
          symbolAfter: '%',
        },
        {
          label: 'Mentions 🏞',
          value: this.contactPlatformOverview.contactPlatformStatistic.totalMentions,
        },
        {
          label: 'Revenue 💰',
          value: this.contactPlatformOverview.contactPlatformStatistic.totalRevenuesUsd,
          symbolBefore: '$',
        },
        {
          label: 'Orders 🛍',
          value: this.contactPlatformOverview.contactPlatformStatistic.totalOrders,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      getFavikonProfile: 'TheContactPlatformPanel/getFavikonProfile',
      _updateSheetNote: 'TheContactPlatformPanel/updateSheetNote',
      _createReport: 'TheInfluenceAudit/createReport',
      _tryFetchReportByUsername: 'TheInfluenceAudit/tryFetchReportByUsername',
      UP_activeTab: 'TheContactPlatformPanel/UP_activeTab',
    }),

    updateSheetNote(sheetNote) {
      return this._updateSheetNote({
        payload: {
          cpid: this.contactPlatformOverview.cpid,
          sheetNote,
        },
      });
    },

    getReport() {
      const { username } = this.contactPlatformOverview.contactPlatform;
      this.isLoading = true;

      return Promise.resolve()
        .then(() => this._tryFetchReportByUsername({ payload: { username } }))
        .then((res) => {
          // report is still stored from iqdata
          if (res?.id) return window.open(`${process.env.VUE_APP_CLARK_BASE_URL}/dl/channelGroup/${this._currentChannelGroup.cgid}/iqdataReport/${res.id}`);

          // need to create a new report
          return this.createReportAndRedirectOnPDF(username);
        })
        .then(() => { this.isLoading = false; })
        .catch(() => { this.isLoading = false; });
    },

    createReportAndRedirectOnPDF(username) {
      return this._createReport({ payload: { username } })
        .then((res) => {
          this.isLoading = false;
          window.open(`${process.env.VUE_APP_CLARK_BASE_URL}/dl/channelGroup/${this._currentChannelGroup.cgid}/iqdataReport/${res.extrefId}`);
        });
    },
  },
};
</script>
