/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  getters: {
    ...getters.campaign,
    ...getters.contactGroup,
    ...getters.channelGroup,
    ...getters.account,
    ...getters.authenticate,
    ...getters.dailyMention,
  },
  actions: {
    ...actions.downloadMention,
    ...actions.channelGroup,
    ...actions.favorite,
    ...actions.segment,
    ...actions.dailyMention,
  },
};
