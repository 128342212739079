<template>
  <aside class="ubuMenu is-sub-menu">
    <UbuSubMenuDrawer
      v-model="subMenuDropdownIsClosed.columnSelector"
      :title="'DASHBOARD COLUMNS'"
    >
      <template #content>
        <b-field
          v-for="col in columns"
          :key="col.col"
        >
          <b-switch
            v-model="localColumns[col.col].visible"
            size="is-small"
          >
            {{ col.label }}
          </b-switch>
        </b-field>
      </template>
    </UbuSubMenuDrawer>

    <UbuSubMenuDrawer
      v-model="subMenuDropdownIsClosed.toExecute"
      :title="'BACKEND QUERY'"
    >
      <template #content>
        <b-button
          :active="localHttpQuery.toExecute"
          expanded
          class="is-navigation is-spaced"
          @click="localHttpQuery = { toExecute:true }"
        >
          <div class="multibar row-vcentered">
            <span class="start itemMenuText text-truncate">Only Actives</span>
          </div>
        </b-button>
        <b-button
          :active="!Object.values(localHttpQuery).length"
          expanded
          class="is-navigation is-spaced"
          @click="localHttpQuery = {}"
        >
          <div class="multibar row-vcentered">
            <span class="start itemMenuText text-truncate">All Tasks</span>
          </div>
        </b-button>
      </template>
    </UbuSubMenuDrawer>

    <UbuSubMenuDrawer
      v-model="subMenuDropdownIsClosed.frontendFilters"
      :title="'FRONTEND FILTERS'"
    >
      <template #content>
        <b-button
          :active="!localFilters.length"
          expanded
          class="is-navigation is-spaced"
          @click="localFilters = []"
        >
          <div class="multibar row-vcentered">
            <span class="start itemMenuText text-truncate">No Filters</span>
          </div>
        </b-button>

        <template v-for="(f) in filterList">
          <b-button
            :key="f.filter"
            :active="localFilters.includes(f.filter)"
            expanded
            class="is-navigation is-spaced"
            @click="filterToggle(f.filter)"
          >
            <div class="multibar row-vcentered">
              <span
                :class="['start', 'itemMenuText', {
                  'is-danger': f.color && f.color === 'danger',
                  'is-warning': f.color && f.color === 'warning',
                  'is-fade': f.color && f.color === 'fade',
                }]"
              >{{ f.filter }}</span>
            </div>
          </b-button>
        </template>
      </template>
    </UbuSubMenuDrawer>

    <UbuSubMenuDrawer
      v-model="subMenuDropdownIsClosed.workspaces"
      :title="'WORKSPACES'"
    >
      <template #content>
        <b-input
          v-model="searchChannelGroup"
          placeholder="Search..."
          class="mb-2"
          expanded
          type="search"
          icon-pack="ubu"
          icon="ubu-search"
          icon-right="ubu-cross"
          icon-right-clickable
          @icon-right-click="searchChannelGroup = ''"
        />

        <b-button
          class="is-navigation is-spaced"
          expanded
          :active="localSelectedChannelGroup === null"
          @click="localSelectedChannelGroup = null"
        >
          <div class="multibar row-vcentered">
            <span class="start itemMenuText text-truncate">All Workspaces</span>
          </div>
        </b-button>

        <b-button
          v-for="(r) in filteredChannelGroups"
          :key="r.cgid"
          class="is-navigation is-spaced"
          expanded
          :active="localSelectedChannelGroup === r.cgid"
          @click="localSelectedChannelGroup !== r.cgid ? localSelectedChannelGroup = r.cgid : null"
        >
          <div class="multibar row-vcentered">
            <span class="start itemMenuText text-truncate">{{ r.name }}</span>
          </div>
        </b-button>
      </template>
    </UbuSubMenuDrawer>
  </aside>
</template>

<script>
import TheMonitoringTaskMixin from '../_mixins/theMonitoringTask.mixins';

export default {
  name: 'TheMonitoringTaskSubMenu',
  mixins: [TheMonitoringTaskMixin],
  // model: {
  //   prop: 'selectedChannelGroup',
  // },
  props: {
    httpQuery: {
      type: Object,
      default: () => ({}),
    },
    selectedChannelGroup: {
      type: Number,
      default: null,
    },
    columns: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    localHttpQuery: {
      get() { return { ...this.httpQuery }; },
      set(val) { this.$emit('update:httpQuery', val); },
    },
    localColumns: {
      get() { return { ...this.columns }; },
      set(val) { this.$emit('update:columns', val); },
    },
    localSelectedChannelGroup: {
      get() { return this.selectedChannelGroup; },
      set(val) { this.$emit('update:selectedChannelGroup', val); },
    },
    localFilters: {
      get() { return [...this.filters]; },
      set(val) {
        this.$emit('update:filters', val);
      },
    },
  },
  methods: {
    filterToggle(filterName) {
      const mappedFilters = this.filterList.arrayMapper('filter');
      const { cantBeWith } = mappedFilters[filterName];

      if (this.filters.includes(filterName)) {
        this.localFilters = this.filters.filter((f) => f !== filterName);
      } else {
        this.localFilters = [...this.localFilters, filterName];
      }
      this.localFilters = [...this.localFilters, filterName].filter((f) => !cantBeWith.includes(f));
    },
  },
};
</script>
