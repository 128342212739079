/* eslint-disable max-len */
import { actions } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    selectedCps: [],
    newBulkAction: {},
    alreadyInOtherCampaignAndWillBeMoved: [],
    instagramersToSend: [],
    removedToScan: [],
  },

  mutations: {
    SET_SELECTED_CONTACT_PLATFORMS(state, newData) { state.selectedCps = newData; },
    SET_NEW_BULK_ACTION(state, newData) { state.newBulkAction = newData; },
    SET_alreadyInOtherCampaignAndWillBeMoved(state, newData) { state.alreadyInOtherCampaignAndWillBeMoved = newData; },
    SET_instagramersToSend(state, newData) { state.instagramersToSend = newData; },
    SET_removedToScan(state, newData) { state.removedToScan = newData; },

    RESET_STORE(state) {
      state.selectedCps = [];
      state.newBulkAction = {};
      state.alreadyInOtherCampaignAndWillBeMoved = [];
      state.instagramersToSend = [];
      state.removedToScan = [];
    },

    RESET_WORKFLOW_STATE(state) {
      state.alreadyInOtherCampaignAndWillBeMoved = [];
      state.instagramersToSend = [];
      state.removedToScan = [];
    },
  },

  getters: {
    selectedCps: (state) => state.selectedCps,
    newBulkAction: (state) => state.newBulkAction,

    alreadyInOtherCampaignAndWillBeMoved: (state) => state.alreadyInOtherCampaignAndWillBeMoved,
    alreadyInOtherCampaignAndWillBeMovedMapped: (state) => [...state.alreadyInOtherCampaignAndWillBeMoved].arrayMapper('cpid'),

    instagramersToSend: (state) => state.instagramersToSend,
    instagramersToSendMapped: (state) => state.instagramersToSend.arrayMapper('cpid'),

    removedToScan: (state) => state.removedToScan,
    removedToScanMapped: (state) => state.removedToScan.arrayMapper('cpid'),

    tabItemsForTheCrmBulk: () => ({
      'root.addToCampaign': {
        tab: 'root.addToCampaign',
        title: 'Select a campaign',
        subtext: 'Add to campaign',
      },
      'root.addToCampaign.reachMethod': {
        tab: 'root.addToCampaign.reachMethod',
        title: 'How do you want to reach them?',
        subtext: 'Add to campaign',
      },
      'root.addToCampaign.reachMethod.alreadyInCampaign': {
        tab: 'root.addToCampaign.reachMethod.alreadyInCampaign',
        title: 'People already in campaign',
        subtext: 'Add to campaign',
      },
      'root.addToCampaign.reachMethod.alreadyInCampaign.toSend': {
        tab: 'root.addToCampaign.reachMethod.alreadyInCampaign.toSend',
        title: 'Who do you want to send a message to?',
        subtext: 'Add to campaign',
      },
      'root.addToCampaign.reachMethod.alreadyInCampaign.toSend.message': {
        tab: 'root.addToCampaign.reachMethod.alreadyInCampaign.toSend.message',
        title: 'Sending Instagram messages',
        subtext: 'Add to campaign',
      },
      'root.addToCampaign.reachMethod.alreadyInCampaign.toScan': {
        tab: 'root.addToCampaign.reachMethod.alreadyInCampaign.toScan',
        title: 'Who do you want to scan mentions?',
        subtext: 'Add to campaign',
      },
      'root.addToCampaign.reachMethod.alreadyInCampaign.summary': {
        tab: 'root.addToCampaign.reachMethod.alreadyInCampaign.summary',
        title: 'Summary',
        subtext: 'Add to campaign',
      },
      'root.addToCampaign.reachMethod.alreadyInCampaign.done': {
        tab: 'root.addToCampaign.reachMethod.alreadyInCampaign.done',
        title: 'Success!',
        subtext: 'Add to campaign',
      },
    }),
  },

  actions: {
    ...actions.activity,
    ...actions.bulkActivity,

    UP_selectedCps({ commit }, newData) {
      commit('SET_SELECTED_CONTACT_PLATFORMS', newData);
    },
    UP_resetStore({ commit }) {
      commit('RESET_STORE');
    },
    UP_resetWorkflowState({ commit }) {
      commit('RESET_WORKFLOW_STATE');
    },
    UP_newBulkAction({ commit, getters }, newBulkAction) {
      commit('SET_NEW_BULK_ACTION', { ...getters.newBulkAction, ...newBulkAction });
    },
    UP_alreadyInOtherCampaignAndWillBeMoved({ commit }, checkedRows) {
      commit('SET_alreadyInOtherCampaignAndWillBeMoved', checkedRows);
    },
    UP_instagramersToSend({ commit }, checkedRows) {
      commit('SET_instagramersToSend', checkedRows);
    },
    UP_removedToScan({ commit }, checkedRows) {
      commit('SET_removedToScan', checkedRows);
    },
  },
};
