/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    tasks: {},
  },
  mutations: {
    SET_MONITOR_TASKS(state, newData) { state.tasks = newData; },
    RESET_STORE(state) {
      state.tasks = {};
    },
  },
  getters: {
    getterTasks: (state) => state.tasks,
  },
  actions: {
    reportTask({ commit }, { payload, httpQuery }) {
      return clark.api.monitor.reportTask({ payload, httpQuery })
        .then((tasks) => {
          commit('SET_MONITOR_TASKS', tasks.arrayMapper('tid'));
        });
    },

    rescheduleTask({ commit, getters }, { payload, httpQuery }) {
      return clark.api.monitor.rescheduleTask({ payload, httpQuery })
        .then((task) => {
          commit('SET_MONITOR_TASKS', {
            ...getters.getterTasks,
            [task.tid]: {
              ...getters.getterTasks[task.tid],
              updatedOn: task.updatedOn,
              triggerOn: task.triggerOn,
              error: task.error,
              state: task.state,
            },
          });
        });
    },

    UP_task({ commit, getters }, task) {
      commit('SET_MONITOR_TASKS', { ...getters.getterTasks, [task.tid]: task });
    },
  },
};
