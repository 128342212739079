/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    salesUserList: {},
  },
  mutations: {
    SET_SALES_USER_LIST(state, newDatas) { state.salesUserList = newDatas; },
    UPDATE_SALES_USER_LIST(state, newDatas) {
      const mySalesUserList = Object.values(state.salesUserList).concat(newDatas).arrayMapper('userUid');
      state.salesUserList = mySalesUserList;
    },
    DELETE_SALES_USER(state, newData) {
      state.salesUserList = Object.values(state.salesUserList).filter(({ userUid }) => userUid !== newData.userUid).arrayMapper('userUid');
    },
  },
  getters: {
    getterSalesUserList: (state) => state.salesUserList,
  },
  actions: {
    report({ commit, rootGetters }) {
      if (!isActionAllowed(rootGetters, 'SALES_USER.REPORT')) return undefined;

      return clark.api.salesUser
        .report({ payload: {}, httpQuery: { appFriendly: 'true' } })
        .then((salesUserList) => {
          commit('SET_SALES_USER_LIST', salesUserList);
        });
    },

    create({ commit, rootGetters }, payload) {
      if (!isActionAllowed(rootGetters, 'SALES_USER.CREATE')) return undefined;

      return clark.api.salesUser
        .post({ payload, httpQuery: {} })
        .then((salesUser) => { commit('UPDATE_SALES_USER_LIST', salesUser); });
    },

    update({ commit, rootGetters }, payload) {
      if (!isActionAllowed(rootGetters, 'SALES_USER.UPDATE')) return undefined;

      return clark.api.salesUser
        .put({ payload, httpQuery: {} })
        .then((salesUser) => { commit('UPDATE_SALES_USER_LIST', salesUser); });
    },

    delete({ commit, rootGetters }, payload) {
      if (!isActionAllowed(rootGetters, 'SALES_USER.DELETE')) return undefined;

      return clark.api.salesUser
        .delete({ payload, httpQuery: {} })
        .then(() => { commit('DELETE_SALES_USER', payload); });
    },
  },
};
