import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {
    getterDetailledUserList: (_state, _getters, _rootState, rootGetters) => {
      const { 'DetailledUser/getterDetailledUserList': detailledUserList } = rootGetters;

      return detailledUserList;
    },
    getterDetailledUser: (_state, _getters, _rootState, rootGetters) => {
      const { 'DetailledUser/getterDetailledUser': detailledUser } = rootGetters;

      return detailledUser;
    },
  },
  actions: {
    reportDetailledUser({ dispatch }, httpQuery) {
      const action = {
        dispatch,
        target: 'DetailledUser/report',
        context: { httpQuery },
        textNotifier: 'fetch user details',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report User Details.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },

    changePassword({ dispatch }, authenticate) {
      const action = {
        dispatch,
        target: 'Authenticate/changePassword/post',
        context: { payload: { ...authenticate } },
        textNotifier: 'change authenticate',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('reportDetailledUser'))
        .catch(() => {
          Snackbar.open({
            message: 'Unable to change authenticate.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },

    deleteUserAccount(_, uid) {
      console.log('Actions store: ', uid);
    },
    removeUserRight(_, right) {
      console.log('Actions store: ', right);
    },
  },
  modules: {},
};
