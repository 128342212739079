<template>
  <b-button
    :type="customs.length === 1 ? `is-custom-${customs[0].color}` : 'is-secondary'"
    :disabled="isDisabled"
  >
    <template v-if="customs.length > 1">
      <!-- <b-tooltip
        :label="customs.map(({ name, label }) => name || label).join(' - ')"
        size="is-small"
        multilined
        append-to-body
        position="is-bottom"
        :active="activeTooltip"
      > -->
      <div class="multibar row-vcentered">
        <b-icon
          pack="ubu"
          :icon="customs[0].icon || customDefault.icon"
          :class="`start has-text-color-${customs[0].color}`"
        />
        <p class="start text is-3">
          +{{ customs.length - 1 }}
        </p>
      </div>
      <!-- </b-tooltip> -->
    </template>
    <template v-else>
      <div class="multibar row-vcentered">
        <b-icon
          pack="ubu"
          :icon="customs.length === 1 && customs[0].icon ? customs[0].icon : customDefault.icon"
        />
      </div>
    </template>
  </b-button>
</template>

<script>
/* eslint-disable max-len */
export default {
  name: 'UbuCustomButton',
  props: {
    customDefault: {
      type: Object,
      default: () => ({
        icon: 'ubu-custom-tag',
      }),
    },
    customs: {
      type: Array,
      default: () => ([]),
    },
    activeTooltip: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
