/* eslint-disable max-len */
import Errors from '@dailyplanet/utils/Errors';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  actions: {
    forgotPassword({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ForgotPassword/create',
        context: {
          payload: {
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'Reset password',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((error) => {
          Snackbar.open({
            message: 'Unable to reset password. Check the entered email',
            ...snackBarFailure,
          });
          dispatchFailure(action);
          throw new Errors(error);
        });
    },
    createAndSendToken({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ForgotPassword/create',
        context: {
          payload,
          httpQuery,
        },
        textNotifier: 'Create & send token ',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((error) => {
          Snackbar.open({
            message: 'Unable to reset password. Check the entered email.',
            ...snackBarFailure,
          });
          dispatchFailure(action);
          throw new Errors(error);
        });
    },

    resetAndSendPassword({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ForgotPassword/update',
        context: {
          payload,
          httpQuery,
        },
        textNotifier: 'Reset & send password',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((error) => {
          Snackbar.open({
            message: 'Unable to reset password.',
            ...snackBarFailure,
          });
          dispatchFailure(action);
          throw new Errors(error);
        });
    },
  },
};
