/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '@dailyplanet/providers/engine';

const target = 'clark';

const contactPlatformMention = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/contactPlatformMention/${payload.cpids}`, method: 'REPORT', payload, httpQuery, target }),
};

export default contactPlatformMention;
