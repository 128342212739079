/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {},
  getters: {
    ...getters.template,
    ...getters.templateFolder,
  },
  actions: {
    ...actions.template,
    ...actions.templateFolder,
  },
};
