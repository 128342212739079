import { SnackbarProgrammatic as Snackbar } from 'buefy';
import ErrorBuilder from '@dailyplanet/utils/Errors';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

/* eslint-disable max-len */
export default {
  getters: {
    userList: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/getterUserList': userList } = rootGetters;

      return userList;
    },
    userAccountMemberViewList: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/accountMemberView/getterAccountMemberViewList': _accountMemberViewList } = rootGetters;

      if (!_accountMemberViewList) return undefined;
      const accountMemberViewList = Object.values(_accountMemberViewList);

      return accountMemberViewList ? accountMemberViewList.map(({
        activeChannelGroup,
        activeChannel,
        channelGroupList,
        channelList,
        ...member
      }) => ({
        ...member,
        activeChannelGroup: activeChannelGroup || [],
        activeChannel: activeChannel || [],
        channelGroupList: channelGroupList || [],
        channelList: channelList || [],
      })) : [];
    },
    userAccountMemberView: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/accountMemberView/getterAccountMemberView': _accountMemberViewList } = rootGetters;
      if (!_accountMemberViewList) return undefined;
      const accountMemberViewList = Object.values(_accountMemberViewList);

      return accountMemberViewList ? accountMemberViewList.map(({
        activeChannelGroup,
        activeChannel,
        channelGroupList,
        channelList,
        ...member
      }) => ({
        ...member,
        activeChannelGroup: activeChannelGroup || [],
        activeChannel: activeChannel || [],
        channelGroupList: channelGroupList || [],
        channelList: channelList || [],
      })) : [];
    },
  },
  actions: {
    getUser({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'User/get',
        context: {
          payload,
          httpQuery,
        },
        textNotifier: 'User get.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          Snackbar.open({
            message: 'Unable to get user.',
            ...snackBarFailure,
          });

          dispatchFailure(action);
          throw new ErrorBuilder(e);
        });
    },
    createUser({ dispatch }, { payload, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/create',
        context: {
          payload,
          httpQuery,
        },
        textNotifier: 'User successfully created.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'inviteTeammate' }, { root: true }))
        .catch((e) => {
          Snackbar.open({
            message: 'Unable to create user.',
            ...snackBarFailure,
          });

          dispatchFailure(action);
          throw new ErrorBuilder(e);
        });
    },
    reportUserAccountMemberView({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/accountMemberView/report',
        context: { payload, httpQuery },
        textNotifier: 'report account member',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: account.js ~ line 32 ~ reportAccountMemberView ~ e', e);
          Snackbar.open({
            message: 'Unable to report account member list.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    // getUserAccountMemberView({ dispatch }, { payload = {}, httpQuery = {} }) {
    //   const action = {
    //     dispatch,
    //     target: 'User/accountMemberView/get',
    //     context: { payload, httpQuery },
    //     textNotifier: 'get account member',
    //     retry: {
    //       onSuccess: false,
    //       onFailure: false,
    //     },
    //   };

    //   return dispatchAndForward(action)
    //     .catch((e) => {
    //       console.log('🚀 ~ file: account.js ~ line 32 ~ reportAccountMemberView ~ e', e);
    //       Snackbar.open({
    //         message: 'Unable to get account member list.',
    //         ...snackBarFailure,
    //       });

    //       return dispatchFailure(action);
    //     });
    // },
    setRole({ dispatch }, { payload, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/setRole/create',
        context: { payload, httpQuery },
        textNotifier: 'Set a role to user',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: account.js ~ line 32 ~ reportAccountMemberView ~ e', e);
          Snackbar.open({
            message: 'Unable to set a role to user.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    setDefaultChannelGroup({ dispatch }, { payload, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/userSetting/setDefaultChannelGroup',
        context: { payload, httpQuery },
        textNotifier: 'Set default workspace',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: account.js ~ line 32 ~ reportAccountMemberView ~ e', e);
          Snackbar.open({
            message: 'Unable to set default workspace.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    up_linkChannelGroupUser({ dispatch }, {
      toBeLinked, aid, uid, cgid,
    }) {
      return dispatch('User/accountMemberView/up_linkChannelGroupUser', {
        toBeLinked, aid, uid, cgid,
      }, { root: true });
    },
    up_unlinkChannelGroupUser({ dispatch }, {
      toBeUnlinked, aid, uid, cgid,
    }) {
      return dispatch('User/accountMemberView/up_unlinkChannelGroupUser', {
        toBeUnlinked, aid, uid, cgid,
      }, { root: true });
    },
    up_linkChannelUser({ dispatch }, {
      toBeLinked, aid, uid, cid,
    }) {
      return dispatch('User/accountMemberView/up_linkChannelUser', {
        toBeLinked, aid, uid, cid,
      }, { root: true });
    },
    up_unlinkChannelUser({ dispatch }, {
      toBeUnlinked, aid, uid, cid,
    }) {
      return dispatch('User/accountMemberView/up_unlinkChannelUser', {
        toBeUnlinked, aid, uid, cid,
      }, { root: true });
    },
  },
};
