export default {
  data() {
    return {
      columns: [
        { col: 'action', cmp: 'action', cellClass: 'static' },
        // {
        //   col: 'image',
        //   label: 'Picture',
        //   cmp: 'img',
        //   cellClass: 'static',
        // },
        {
          col: 'fullName',
          label: 'fullName',
          cmp: 'flat',
          cellClass: 'static ellipsis',
          secondCold: 'email',
        },
        {
          col: 'isAdmin',
          label: 'isAdmin',
          cmp: 'tag',
          tag: {
            condition: 'isAdmin',
            color: 'is-primary',
            text: 'Admin',
          },
          cellClass: 'static',
        },
        {
          col: 'isMe',
          label: 'isMe',
          cmp: 'tag',
          tag: {
            condition: 'isMe',
            color: 'is-info',
            text: 'Me',
          },
          cellClass: 'static',
        },
        {
          col: 'button',
          cmp: 'button',
          button: {
            type: 'is-success',
            label: 'Admin login',
          },
          cellClass: 'fullWidth',
        },
      ],
    };
  },
};
