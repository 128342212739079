/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {
    catalog: {
      login: 'Logins',
      logout: 'Logout',
      closeThread: 'Close thread Inbox',
      openThread: 'Open thread Inbox',
      doneThread: 'Done thread Inbox',
      closeComment: 'Close a comment',
      inviteTeammate: 'Invite teammate',
      tagMessage: 'Tag message',
      labelMessage: 'Add label to a contact',
      assignThread: 'Assign thread',
      addKeywordToTag: 'Add keyword to a tag',
      downloadMention: 'Download mention(s)',
      subscribeAutoReport: 'Subscribe to auto report',
      exportAnalytics: 'Click on export analytics',
      tribeFromCompleted: 'tribe form completed',
      addYoutubeChannel: 'Add Youtube account',
      addTikTokChannel: 'Add TikTok account',
      connectShopify: 'Connect Shopify',
      createCRMFilter: 'Create CRM filters',
      saveCRMFilter: 'Save CRM filters',
      askProtoList: 'Ask proto list',
      addToCRMList: 'Add ambassador(s) to a CRM list',
      sendMessage: 'Send message',
      sendComment: 'Send comment',
      activeAutoTag: 'Active autotag',
      activeAutoClose: 'Active autoclose',
      activeAutoFlush: 'Active autoflush',
      reportFavikon: 'Generate report favikon',
      editContactPrivateMetadata: 'Edit contact private informations',
      translateText: 'Translate text',
      changeActivityStatusToSuccess: 'Change activity status to success',
      requestUGCRights: 'Request UGC Rights',
      addFavoriteMention: 'Use favorite mention',

      disconnectPrivate: 'Ubu extension disconnected',
      connectPrivate: 'Ubu extension connected',

      createCampaign: 'Create campaign',
      createTemplate: 'Create a template',
      createList: 'Create a CRM list',
      createAffiliation: 'Create shopify affiliation',
      createTag: 'Create a tag',
      createLabel: 'Create a label',
      createTribeForm: 'Create tribe form',
      createHashtag: 'Create hashtag request',

      deleteHashtag: 'Delete hashtag',

      addToCampaign: 'Add ambassador(s) to a campaign',
      changeActivityStatus: 'Change activity status',

      clickOnAPost: 'Click on a post',
      clickOnAStory: 'Click on a story',
      clickOnInfluence: 'Click on Influence',
      clickOnInfluenceCampaign: 'Click on Influence campaign',
      clickOnInfluenceAnalytics: 'Click on Analytics campaign',
      clickOnCRM: 'Click on CRM',
      clickOnAudit: 'Click on Audit',
      clickOnAnalytics: 'Click on Activity/Analytics',
      clickOnMention: 'Click on Activity/Mentions',
      clickOnComment: 'Click on Comments',
      clickOnInbox: 'Click on Inbox',
      clickOnTribeMessage: 'Click on Tribe Message',
      clickOnInfluenceMessage: 'Click on Influence Message',
      clickOnContactPanelNote: 'Click on contact panel notes',
      clickOnHashtags: 'Click on Hashtags',

      clickOnAnalyticsNoFeature: 'Click on Activity Analytics (NO FEATURE)',
      clickOnInfluenceAnalyticsNoFeature: 'Click on Analytics campaign (NO FEATURE)',
      connectPrivateNoFeature: 'Ubu extension connexion (NO FEATURE)',
      sendReachNoFeature: 'Send reach (NO FEATURE)',
      clickOnCommentNoFeature: 'Click on Comments (NO FEATURE)',
      createCampaignNoFeature: 'Create campaign (NO FEATURE)',
      addToCampaignNoFeature: 'Add ambassador(s) to a campaign (NO FEATURE)',
      addToCRMListNoFeature: 'Add ambassador(s) to a CRM list (NO FEATURE)',
      createCRMListNoFeature: 'Create a CRM list (NO FEATURE)',
      favikonNoFeature: 'generate favikon profile (NO FEATURE)',
      updateAffiliationNoFeature: 'Edit shopify affiliation (NO FEATURE)',
      createAffiliationNoFeature: 'Create shopify affiliation (NO FEATURE)',
      connectShopifyNoFeature: 'Connect shopify store (NO FEATURE)',
      activeAutoCloseNoFeature: 'Active autoclose (NO FEATURE)',
      activeAutoFlushNoFeature: 'Active autoflush (NO FEATURE)',
      activeAutoTagNoFeature: 'Active autotag (NO FEATURE)',
      closeThreadNoFeature: 'Close thread Inbox (NO FEATURE)',
      assignThreadNoFeature: 'Assign thread (NO FEATURE)',
      downloadMentionNoFeature: 'Download mentions (NO FEATURE)',
      autoreportNoFeature: 'Auto report mentions (NO FEATURE)',
      translateTextNoFeature: 'Translate text (NO FEATURE)',
      tagMessageNoFeature: 'Tag message (NO FEATURE)',
      clickOnHashtagsNoFeature: 'Click on Hashtags (NO FEATURE)',
      clickOnStoryMentionNoFeature: 'Click on story Mentions (NO FEATURE)',
    },
  },
  mutations: {},
  getters: {
    catalog: (state) => state.catalog,
  },
  actions: {
    segmentTrack({ getters }, { event, properties = {} }) {
      if (process.env.VUE_APP_MODE !== 'dev') {
        const { catalog } = getters;
        return window.analytics.track(catalog[event], properties);
      }
      return undefined;
    },
  },
};
