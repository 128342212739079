<template>
  <div>
    <b-field
      v-for="(item) in options"
      :key="item.value"
    >
      <b-radio
        v-model="localValues"
        :native-value="item.value"
        class="mb-4"
      >
        <span>{{ item.label }}</span>
      </b-radio>
    </b-field>
  </div>
</template>

<script>

export default {
  name: 'UbuFilterRadio',
  model: {
    prop: 'values',
  },
  props: {
    values: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    localValues: {
      get() { return this.values[0]; },
      set(val) { this.$emit('input', [val]); },
    },
  },
};
</script>
