<template>
  <UbuDropdown
    :close-on-click="false"
    :scrollable="true"
    :position="position"
    :append-to-body="false"
    class="has-min-height-400"
    @open="isOpen = true"
    @close="close()"
  >
    <template #button>
      <b-tooltip
        label="Templates"
        position="is-top"
      >
        <b-button
          icon-left="ubu-template"
          icon-pack="ubu"
          type="is-tertiary-icon"
        />
      </b-tooltip>
    </template>

    <template
      v-if="localActiveTab.tab !== 'root'"
      #header
    >
      <UbuDropdownBreadcrumbs
        class="mb-2"
        :breadcrumbs-options="breadcrumbs"
        :current-breadcrumb="breadcrumbs[breadcrumbs.length - 1]"
        @goBack="useBreadcrumb($event)"
      />
    </template>

    <template
      v-if="isOpen"
      #search
    >
      <b-dropdown-item
        custom
        paddingless
        class="multibar"
      >
        <UbuSearchbar
          v-if="isOpen"
          v-model="search"
          :class="['w-100', {
            start: localActiveTab.tab === 'root',
            'mr-2': localActiveTab.tab === 'root',
          }]"
          @clear="search = ''"
        />
        <b-button
          v-if="localActiveTab.tab === 'root'"
          class="end is-secondary"
          icon-pack="ubu"
          icon-left="ubu-add"
          @click.stop="$emit('openTemplatePanel');"
        />
      </b-dropdown-item>
    </template>

    <template
      v-if="isOpen"
      #content
    >
      <b-tabs
        v-if="isOpen"
        v-model="localActiveTab.tab"
        class="no-header paddingless"
      >
        <b-tab-item value="root">
          <template v-if="templateFolderList">
            <b-dropdown-item
              v-for="templateFolder in templateFolderList"
              :key="templateFolder.tfid"
              class="multibar has-text-color-1"
              style="display: flex; flex-direction: row"
              @click="
                UP_selectedTemplateFolder(templateFolder);
                goToTabByTabPath('root.selectedTemplateFolder');
                addToBreadcrumbs(tabItems['root.selectedTemplateFolder'])
              "
            >
              <p class="start a-self-center text-truncate">
                {{ templateFolder.label }}
              </p>

              <b-icon
                class="end is-ghost is-small is-tertiary is-borderless"
                pack="ubu"
                icon="ubu-arrow-right"
              />
            </b-dropdown-item>
          </template>

          <b-dropdown-item
            v-for="template in filteredTemplateListByFolder(null)"
            :key="`${template.tid}#TEMPLATE`"
            class="w-100"
            @click="$emit('insertText', template.text); close();"
          >
            <p class="text text-truncate has-text-color-1">{{ template.title }}</p>
            <p class="subtext has-text-secondary text-truncate">{{ template.text }}</p>
          </b-dropdown-item>
        </b-tab-item>

        <b-tab-item value="root.selectedTemplateFolder">
          <b-dropdown-item
            v-for="template in filteredTemplateListByFolder(selectedTemplateFolder.tfid)"
            :key="`${template.tid}#TEMPLATE`"
            @click="$emit('insertText', template.text); close();"
          >
            <p class="text text-truncate has-text-color-1">{{ template.title }}</p>
            <p class="subtext has-text-secondary text-truncate">{{ template.text }}</p>
          </b-dropdown-item>
        </b-tab-item>
      </b-tabs>
    </template>
  </ubudropdown>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheTemplateDropdown',
  props: {
    position: {
      type: String,
      default: 'is-top-right',
    },
  },
  data() {
    return {
      isOpen: true,
      search: '',
      breadcrumbs: [],
    };
  },
  computed: {
    ...mapGetters({
      templateFolderList: 'TheTemplateDropdown/templateFolderList',
      templateList: 'TheTemplateDropdown/templateList',

      activeTab: 'TheTemplateDropdown/activeTab',
      tabItems: 'TheTemplateDropdown/tabItems',
      selectedTemplateFolder: 'TheTemplateDropdown/selectedTemplateFolder',
    }),

    localActiveTab: {
      get() {
        return this.activeTab;
      },
      set(newValue) {
        this.UP_activeTab(newValue);
      },
    },

    filteredTemplateList() {
      if (!this.search.trim()) return Object.values(this.templateList);
      return Object.values(this.templateList).filter(({ title }) => title.toLowerCase().includes(this.search.toLowerCase()));
    },
  },
  mounted() {
    this.breadcrumbs = [this.activeTab];
  },
  methods: {
    ...mapActions({
      UP_activeTab: 'TheTemplateDropdown/UP_activeTab',
      UP_resetStore: 'TheTemplateDropdown/UP_resetStore',
      goToPreviousTab: 'TheTemplateDropdown/goToPreviousTab',
      goToTabByTabPath: 'TheTemplateDropdown/goToTabByTabPath',
      UP_selectedTemplateFolder: 'TheTemplateDropdown/UP_selectedTemplateFolder',
    }),
    close() {
      this.search = '';
      this.UP_resetStore();
      this.breadcrumbs = [this.activeTab];
      this.isOpen = false;
    },

    filteredTemplateListByFolder(tfid) {
      if (!tfid) return this.filteredTemplateList.filter(({ templateFolderTfid }) => templateFolderTfid === null);
      return this.filteredTemplateList.filter(({ templateFolderTfid }) => templateFolderTfid === tfid);
    },

    useBreadcrumb({ item, index }) {
      this.localActiveTab = item;
      this.breadcrumbs = this.breadcrumbs.filter((_, i) => i <= index);
    },

    addToBreadcrumbs(item) {
      this.breadcrumbs.push(item);
    },
  },
};
</script>
