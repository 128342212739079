export default {
  data() {
    return {
      columns: {
        aid: {
          col: 'aid', label: 'Aid', visible: true, isSortable: false, cmp: 'flat',
        },
        owner: {
          col: 'owner', label: 'Owner', visible: true, isSortable: false, cmp: 'flat',
        },
        state: {
          col: 'state', label: 'State', visible: true, isSortable: false, cmp: 'flat',
        },
      },
    };
  },
};
