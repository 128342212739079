import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheTabsPanel from './smarts/TheTabsPanel';
import TheSimplePanel from './smarts/TheSimplePanel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheTabsPanel);
    registerComponent(Vue, TheSimplePanel);
  },
};

use(Plugin);

export default Plugin;

export {
  TheTabsPanel,
  TheSimplePanel,
};
