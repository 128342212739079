<template>
  <section>
    <div class="multibar">
      <h2 class="start title is-3">Confirmation channel SMS details</h2>
    </div>

    <br>

    <form>
      <fieldset>
        <b-field
          v-if="newChannel.contactCid"
          horizontal
          label="Contact ID"
        >
          <b-input
            v-model="newChannel.contactCid"
            placeholder="Generated a new contact"
            required
            readonly
          />
        </b-field>

        <b-field
          horizontal
          label="Workspace ID"
        >
          <b-input
            v-model="currentCgid"
            placeholder="Channel group CGID"
            required
            readonly
          />
        </b-field>

        <b-field
          horizontal
          label="Channel Name"
        >
          <b-input
            v-model="newChannel.name"
            placeholder="Name"
            required
            readonly
          />
        </b-field>

        <b-field
          horizontal
          label="Phone number"
        >
          <b-input
            v-model="phoneNumberFormated"
            placeholder="Phone number"
            required
            readonly
          />
        </b-field>

        <b-field
          horizontal
          label="Account SID"
        >
          <b-input
            v-model="newChannel.accountSid"
            placeholder="Account SID"
            required
            readonly
          />
        </b-field>

        <b-field
          horizontal
          label="Auth token"
        >
          <b-input
            v-model="newChannel.authToken"
            placeholder="Auth token"
            required
            readonly
          />
        </b-field>
      </fieldset>

      <br>

      <div class="buttons is-right">
        <b-button
          class="is-primary"
          label="Create channel SMS"
          icon-pack="ubu"
          icon-left="ubu-add"
          @click="confirmation()"
        />
      </div>
    </form>
  </section>
</template>

<script>
export default {
  name: 'UbuAccountAddChannelStepConfirmation',
  model: {
    prop: 'newChannel',
  },
  props: {
    newChannel: { type: Object, default: () => {} },
  },
  computed: {
    phoneNumberFormated() {
      return this.newChannel.phoneNumber;
    },
    currentCgid() {
      return this.$route.query.channelGroupCgid;
    },
  },
  methods: {
    confirmation() {
      this.$buefy.dialog.confirm({
        title: 'Create channel SMS',
        message: 'Are you sure of the information you entered ?',
        confirmText: 'Create channel',
        type: 'is-primary',
        hasIcon: true,
        onConfirm: () => this.$emit('addChannelSms'),
      });
    },
  },
};
</script>
