<template>
  <section class="ubuExtensionDisconnected">
    <div v-if="hasExtension === true">
      <div class="disconnectedCard multibar column ycentered p-6">
        <h2 class="title is-6">{{ features[feature].disconnectedTitle }}</h2>
        <br>
        <div class="subtext">{{ features[feature].disconnectedText }}</div>
        <br>
        <b-button
          class="is-primary"
          label="Reconnect"
          tag="router-link"
          :to="`/setting/extension`"
        />
      </div>
    </div>
    <div
      v-else-if="hasExtension === false"
      :class="['has-no-extension', `${feature}Background`]"
    >
      <h2 class="title mb-3">{{ features[feature].desactivatedTitle }}</h2>
      <template v-for="(line, index) in features[feature].desactivatedText">
        <div :key="index">
          <p>
            {{ line }}
          </p>
          <br>
        </div>
      </template>
      <div class="buttons">
        <b-button
          type="is-primary"
          tag="button"
          label="Contact sales"
          @click="openIntercom()"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UbuExtensionDisconnected',
  props: {
    hasExtension: {
      type: Boolean,
      required: true,
    },
    feature: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      features: {
        sendHooks: {
          disconnectedTitle: 'Ubu’s Meta Extension is disconnected.',
          disconnectedText: 'Reconnect it to start sending cold outreach messages.',
          desactivatedTitle: 'Send outreach messages',
          desactivatedText: [
            'This powerful feature requires a third-party integration.',
            'Please contact sales to request access.',
          ],
          requiredFeature: 'Ubu\'s Instagram',
        },
        protoList: {
          disconnectedTitle: 'Ubu’s Meta Extension is disconnected.',
          disconnectedText: 'Reconnect it to start requesting list.',
          desactivatedTitle: 'Request list of people',
          desactivatedText: [
            'This powerful feature requires a third-party integration.',
            'Please contact sales to request access.',
          ],
          requiredFeature: 'Ubu\'s Instagram',
        },
        mentions: {
          desactivatedTitle: 'Track mentions from Instagram posts and stories',
          desactivatedText: [
            'This powerful feature requires a third-party integration.',
            'Please contact sales to request access.',
          ],
          requiredFeature: 'MENTIONS',
        },
        shopify: {
          disconnectedTitle: 'Shopify extension is not connected.',
          disconnectedText: 'Connect it to manage affiliate links and track sales.',
          desactivatedTitle: 'Send affiliate links and track your sales',
          desactivatedText: [
            'This powerful feature requires a third-party integration.',
            'Please contact sales to request access.',
          ],
          requiredFeature: 'SHOPIFY',
        },
        analytics: {
          desactivatedTitle: 'Track your growth',
          desactivatedText: [
            'This powerful feature requires a third-party integration.',
            'Please contact sales to request access.',
          ],
          requiredFeature: 'ANALYTICS',
        },
        hashtags: {
          desactivatedTitle: 'Follow your favorite hashtags',
          desactivatedText: [
            'This powerful feature requires a third-party integration.',
            'Please contact sales to request access.',
          ],
          requiredFeature: 'HASHTAGS',
        },
        crew: {
          // desactivatedTitle: 'Follow your favorite hashtags',
          // desactivatedText: [
          //   'This powerful feature requires a third-party integration.',
          //   'Please contact sales to request access.',
          // ],
          requiredFeature: 'CREW',
        },
      },
    };
  },
  methods: {
    openIntercom() {
      // eslint-disable-next-line max-len
      this.$intercom.showNewMessage(`Hi! I would like to activate the ${this.features[this.feature].requiredFeature} module! 🙌`);
    },
  },
};
</script>
