import { mapActions, mapGetters } from 'vuex';

import { snackbarSuccess, snackbarError } from '@dailyplanet/utils/helpers';

export default {
  computed: {
    ...mapGetters({
      currentUser: 'TheAuthenticate/myAuthenticate',
    }),
  },
  methods: {
    ...mapActions({
      createDownloadTask: 'TheActivityMention/createDownloadTask',
    }),
    downloadTaskSelected(checkedRows) {
      const files = Object.values(checkedRows).reduce((acc, row) => {
        const {
          mention, contactPlatform, mainMedia, carousel,
        } = row;
        // insert mainMedia first item in array
        carousel.unshift(mainMedia);

        // make a payload downloadTask
        acc.push(...carousel.map(({ url, extrefId }) => ({
          name: `${contactPlatform.username}_${this.$moment(mention.createdOn).format('YYYYMMDD')}_${extrefId}`,
          url,
          prefix: mention.mentionType,
        })));
        return acc;
      }, []);

      this.isLoading = true;
      this.createDownloadTask({
        payload: {
          userUid: this.currentUser.uid,
          state: 'AWAITING',
          files,
          hash: null,
          postTreatment: null,
        },
      })
        .then(() => {
          this.isLoading = false;

          this.$buefy.snackbar.open({
            ...snackbarSuccess,
            message: 'We are preparing your medias, you will receive an email as soon as they are ready!',
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.$buefy.snackbar.open({
            ...snackbarError,
            message: 'Unable to download medias, please contact support.',
          });
        });
    },
  },
};
