var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UbuCustomChip',{attrs:{"model":{
    title: 'Lists',
    defaultIcon: 'ubu-custom-lists',
    count: _vm.cpo[_vm.customsName].length > 1 ? _vm.cpo[_vm.customsName].length : null,
    listItem: _vm.cpo[_vm.customsName].map((id) => ({
      icon: 'ubu-custom-lists',
      color: _vm.customs[id].color,
      label: _vm.customs[id].name,
    }))
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }