<template>
  <section
    id="TheTribe"
    class="mainPanel"
  >
    <header
      id="TheTribeHeader"
      class="mainPanelHeader multibar row-vcentered"
    >
      <h1 class="start title is-2">Tribes</h1>

      <div class="buttons end">
        <b-button
          type="is-primary"
          icon-pack="ubu"
          icon-left="ubu-add"
          label="New Tribe"
          @click="openNewTribePanel = !openNewTribePanel"
        />
      </div>
    </header>

    <section
      id="TheTribesList"
    >
      <article
        v-for="tribe in sortedTribeList"
        :key="tribe.tid"
        class="ubuTribeOverviewCard clickable"
        @click="$router.push(`/tribe/${tribe.tid}/people/detailed`)"
      >
        <header
          :class="`multibar row-vcentered
        has-text-color-${tribe.color} bg-color-${tribe.color}`"
        >
          <b-icon
            class="start"
            pack="ubu"
            :icon="tribe.icon"
          />
          <span :class="`subtitle is-3 start ml-2 mb-0 has-text-color-${tribe.color}`">
            {{ tribe.name }}
          </span>

          <span class="onHover text end mr-1">
            open
          </span>
          <b-icon
            class="onHover end"
            pack="ubu"
            icon="ubu-next-Arrow"
          />
        </header>
        <div class="columns">
          <div class="column">
            <div class="flex column y-centered x-centered">
              <span class="subtext is-3 mb-1 noCutOnText">People 🤓</span>
              <span class="subtitle">
                {{ tribe.overview && tribe.overview.totalPeople || '-' | nFormatter }}
              </span>
            </div>
          </div>
          <div class="column">
            <div class="flex column y-centered x-centered">
              <span class="subtext is-3 mb-1 noCutOnText">Mentions 🏞</span>
              <span class="subtitle">
                {{ tribe.overview && tribe.overview.totalMentions || '-' | nFormatter }}
              </span>
            </div>
          </div>
          <div class="column">
            <div class="flex column y-centered x-centered">
              <span class="subtext is-3 mb-1 noCutOnText">Orders 🛍</span>
              <span class="subtitle">
                {{ tribe.overview && tribe.overview.totalOrders || '-' | nFormatter }}
              </span>
            </div>
          </div>
          <div class="column">
            <div class="flex column y-centered x-centered">
              <span class="subtext is-3 mb-1 noCutOnText">Revenue 💰</span>
              <span class="subtitle">
                {{ tribe.overview && Number(tribe.overview.totalRevenuesUsd) > 0 ? '$' : '' }}{{ tribe.overview && tribe.overview.totalRevenuesUsd || '-' | nDecimalFormatter }}
              </span>
            </div>
          </div>
        </div>
      </article>
    </section>

    <TheSimplePanel
      v-if="openNewTribePanel"
      closing-way="emit"
      @close="openNewTribePanel = false;"
    >
      <template #header>
        <h1 class="title is-1">Add Tribe</h1>
      </template>

      <template #content>
        <UbuTribeForm
          v-model="newTribe"
          @triggerForm="createTribe()"
        />
      </template>
    </TheSimplePanel>

    <div
      v-if="isBackgroundLocked"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheTribes',
  data() {
    return {
      openNewTribePanel: false,
      newTribe: {
        name: '',
        color: '0',
        icon: 'ubu-custom-tribe',
      },
      columns: [{ id: '1', index: 0 }, { id: '2', index: 1 }, { id: '3', index: 2 }],
      draggingColumn: null,
      draggingColumnIndex: null,
    };
  },
  computed: {
    ...mapGetters({
      tribeList: 'TheTribe/tribeList',
    }),

    isBackgroundLocked() {
      if (this.openNewTribePanel) return true;
      return false;
    },

    sortedTribeList() {
      const sorted = Object.values(this.tribeList).sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
      return sorted;
    },

    sortedColumns() {
      const sorted = [...this.columns].sort((a, b) => Number(a.index) - Number(b.index));
      return sorted;
    },
  },
  mounted() {
    return this._reportTribeList({ httpQuery: { memberGroupPosition: true, overview: true } });
  },

  methods: {
    ...mapActions({
      _createTribe: 'TheTribe/createTribe',
      _reportTribeList: 'TheTribe/reportTribeList',
    }),

    closePanel() {
      this.$emit('closePanel');
    },

    createTribe() {
      return this._createTribe({ payload: this.newTribe })
        .then(() => {
          this.openNewTribePanel = false;
        });
    },
  },
};
</script>
