<template>
  <div class="ubuSettingView start">
    <div class="multibar">
      <h1 class="start title is-1">Theme</h1>
    </div>

    <br>

    <template v-for="theme in allThemes">
      <div
        v-if="theme"
        :key="theme.value"
      >
        <div class="multibar row-vcentered">
          <b-field
            class="start no-margin"
            horizontal
          >
            <b-radio
              v-model="radioButton"
              :native-value="theme.value"
              type="is-primary"
            >
              <img
                class="has-shadow"
                :src="theme.img"
              >
            </b-radio>
          </b-field>
          <div class="start">
            <div class="title is-3 is-spaced">
              {{ theme.title }}
            </div>
            <p class="subtitle is-6">
              {{ theme.description }}
            </p>
          </div>
        </div>

        <br>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TheUsersTheme',
  data() {
    return {
      radioButton: 'AUTO',
    };
  },
  computed: {
    allThemes() {
      const themes = ['LIGHT', 'DARK', 'AUTO'];
      return themes.reduce((acc, theme) => {
        let description;

        if (theme === 'LIGHT') description = 'Cute and elegant, just like you 😇';
        if (theme === 'DARK') description = 'The dark side of the force is a pathway to many abilities... 😈';
        if (theme === 'AUTO') description = 'Let your routine guide you through your day 💪';

        acc.push({
          value: theme,
          /* eslint-disable-next-line */
          img: require(`../../../assets/pictos/theme-${theme.toLowerCase()}.svg`),
          title: theme.capitalize(),
          description,
        });
        return acc;
      }, []);
    },
  },
};
</script>
