/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    progress: {},
  },
  mutations: {
    SET_PROGRESS(state, newData) { state.progress = newData; },
  },
  getters: {
    getterProgress: (state) => state.progress,
  },
  actions: {
    getLink({ rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      return clark.api.apiUpload.getLink({ payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery });
    },
    createLink({ rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      return clark.api.apiUpload.createLink({ payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery });
    },
    upload({ commit }, { payload, httpQuery = {} }) {
      const callback = (progress) => {
        commit('SET_PROGRESS', { [payload.key]: Number((progress * 100) / payload.data.size) });
      };
      return clark.api.apiUpload.upload({ payload, httpQuery, callback });
    },
  },
};
