import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuTableChipLastCampaign from './UbuTableChipLastCampaign';
import UbuTableChipMention from './UbuTableChipMention';
import UbuTableChipCrmList from './UbuTableChipCrmList';
import UbuTableChipTribe from './UbuTableChipTribe';
import UbuTableChipTag from './UbuTableChipTag';
import UbuTableChipLabel from './UbuTableChipLabel';
import UbuTablePlatform from './UbuTablePlatform';
import UbuTableContactPlatform from './UbuTableContactPlatform';
import UbuTableProfile from './UbuTableProfile';
import UbuTableNumber from './UbuTableNumber';
import UbuTableDate from './UbuTableDate';
import UbuTableSimple from './UbuTableSimple';
import UbuTableMemberGroupPopover from './UbuTableMemberGroupPopover';
import UbuTableActivityStatusPopover from './UbuTableActivityStatusPopover';
import UbuTableNumberDecimalFormatter from './UbuTableNumberDecimalFormatter';
import UbuTablePastille from './UbuTablePastille';
import UbuTableCrewState from './UbuTableCrewState';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuTableChipLastCampaign);
    registerComponent(Vue, UbuTableChipMention);
    registerComponent(Vue, UbuTableChipCrmList);
    registerComponent(Vue, UbuTableChipTribe);
    registerComponent(Vue, UbuTableChipTag);
    registerComponent(Vue, UbuTableChipLabel);
    registerComponent(Vue, UbuTablePlatform);
    registerComponent(Vue, UbuTableContactPlatform);
    registerComponent(Vue, UbuTableProfile);
    registerComponent(Vue, UbuTableNumber);
    registerComponent(Vue, UbuTableDate);
    registerComponent(Vue, UbuTableSimple);
    registerComponent(Vue, UbuTableMemberGroupPopover);
    registerComponent(Vue, UbuTableActivityStatusPopover);
    registerComponent(Vue, UbuTableNumberDecimalFormatter);
    registerComponent(Vue, UbuTablePastille);
    registerComponent(Vue, UbuTableCrewState);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuTableChipLastCampaign,
  UbuTableChipMention,
  UbuTableChipCrmList,
  UbuTableChipTribe,
  UbuTableChipTag,
  UbuTableChipLabel,
  UbuTablePlatform,
  UbuTableContactPlatform,
  UbuTableProfile,
  UbuTableNumber,
  UbuTableDate,
  UbuTableSimple,
  UbuTableMemberGroupPopover,
  UbuTableActivityStatusPopover,
  UbuTableNumberDecimalFormatter,
  UbuTablePastille,
  UbuTableCrewState,
};
