<template>
  <div class="multibar">
    <b-icon
      :class="[{
        'is-danger': !value,
        'is-success': value,
      }]"
      pack="mdi"
      icon="checkbox-blank-circle"
      size="is-small"
    />
  </div>
</template>

<script>

export default {
  name: 'UbuTableTrueFalseMarker',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
