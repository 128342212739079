<template>
  <div>
    <UbuDropdown
      class="filterDropdown"
      :close-on-click="false"
      position="is-bottom-right"
      scrollable
      :is-disabled="isDisabled"
      @open="isOpen = true"
      @close="reset()"
    >
      <template #button>
        <b-button
          label="Filters"
          type="is-secondary"
          icon-pack="ubu"
          icon-left="ubu-filter"
          :disabled="isDisabled"
        />
      </template>

      <template
        v-if="activeTab !== 'root'"
        #header
      >
        <UbuDropdownBreadcrumbs
          class="mb-2"
          :breadcrumbs-options="breadcrumbs"
          :current-breadcrumb="breadcrumbs[breadcrumbs.length - 1]"
          @goBack="useBreadcrumb($event)"
        />
      </template>

      <template #search>
        <b-dropdown-item
          v-if="showSearchbar"
          custom
          paddingless
          class="mb-2"
        >
          <UbuSearchbar
            v-if="isOpen"
            v-model="search"
            @clear="search = ''"
          />
        </b-dropdown-item>
      </template>

      <template #content>
        <b-tabs
          v-model="activeTab"
          class="paddingless no-margin no-header"
        >
          <b-tab-item value="root">
            <TheFilterDropdownSegmentSelector
              v-model="localFilters"
              :filter-list="filterList"
              :selected-segment-filter.sync="localSelectedSegmentFilter"
            />

            <UbuDropdownFiltersSelectorByGroup
              :active-filters="localFilters"
              :filter-list="filteredFilterList"
              :non-displayable-filters="nonDisplayableFilters"
              @select="selectFilterAndChangeTab($event)"
            />
          </b-tab-item>

          <b-tab-item
            value="root.selectOperator"
            label="Operator"
          >
            <b-dropdown-item custom>
              <UbuFiltersSelectOperator
                v-if="selectedFilter && selectedFilter.operator"
                v-model="selectedFilter.operator"
                :filter="selectedFilter"
                :options="operatorOptions"
              />
            </b-dropdown-item>
          </b-tab-item>

          <b-tab-item value="root.selectValue">
            <b-dropdown-item
              custom
              paddingless
            >
              <UbuFilterByType
                v-if="activeTab === 'root.selectValue'"
                v-model="selectedFilter"
                :search="search"
              />
            </b-dropdown-item>
          </b-tab-item>
        </b-tabs>
      </template>

      <template
        v-if="showAction"
        #action
      >
        <b-dropdown-item
          class="mt-2"
          custom
          paddingless
        >
          <div class="buttons">
            <b-button
              v-if="activeTab === 'root.selectOperator'"
              label="Select"
              type="is-secondary"
              icon-pack="ubu"
              icon-right="ubu-arrow-right"
              expanded
              :disabled="!selectedFilter.operator"
              @click.stop="nextSelectOperator()"
            />

            <b-button
              v-if="activeTab === 'root.selectValue'"
              label="Apply"
              type="is-primary"
              expanded
              :disabled="!selectedFilter.values.length"
              @click.stop="applyFilter()"
            />
          </div>
        </b-dropdown-item>
      </template>
    </UbuDropdown>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheFilterDropdown',
  mixins: [FilterEngineMixin],
  model: {
    prop: 'filters',
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    filterList: {
      type: Array,
      required: true,
    },
    nonDisplayableFilters: {
      type: Array,
      default: () => ([]),
    },
    selectedSegmentFilter: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 'root',
      breadcrumbs: [{ label: 'Filters', tabToGo: 'root' }],
      search: '',
      isOpen: false,

      selectedFilter: null,

      operatorOptions: [
        { label: 'is', value: 'is' },
        { label: 'is not', value: 'is not' },
        { label: 'is one of', value: 'is one of' },
        { label: 'is empty', value: 'is empty' },
        { label: 'is between', value: 'is between' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      threadGroupList: 'TheFilters/threadGroupList',
      contactLocationList: 'TheFilters/contactLocationList',
      contactLanguageList: 'TheFilters/contactLanguageList',
      segmentFilterList: 'TheFilters/segmentFilterList',
    }),

    localFilters: {
      get() { return this.filters; },
      set(values) { this.$emit('input', values); },
    },

    localSelectedSegmentFilter: {
      get() { return this.selectedSegmentFilter; },
      set(val) {
        this.$emit('update:selectedSegmentFilter', val);
      },
    },

    sortedSegmentFilterList() {
      return Object.values(this.segmentFilterList)
        .sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
    },

    _filterCollection() {
      return {
        /**
       * @method  searchByEntityName Count
       * @params  {Number}   rawMax  maximum to be compared to
       * @returns {Function}
       * */
        searchByEntityName(rawSearch) {
          const search = rawSearch.toLowerCase();
          return (m) => (m.entity.toLowerCase().includes(search) ? m : undefined);
        },
      };
    },

    filteredFilterList() {
      const filtersToApply = {};

      if (this.search) filtersToApply.searchByEntityName = this.search;

      return this.filterEngine(this.filterList, filtersToApply);
    },

    showSearchbar() {
      return this.activeTab === 'root'
      || (this.activeTab === 'root.selectValue' && this.selectedFilter && this.selectedFilter.list);
    },

    showAction() {
      return this.activeTab === 'root.selectOperator' || this.activeTab === 'root.selectValue';
    },

    arrayMappedCatalog() {
      return this.filterList.arrayMapper('key');
    },

  },
  methods: {
    ...mapActions({
      deleteSegmentFilter: 'TheFilters/deleteSegmentFilter',
    }),

    selectFilterAndChangeTab(filter) {
      this.selectedFilter = { ...filter, values: [] };
      this.search = '';

      if (filter.operator) {
        this.activeTab = 'root.selectOperator';
        this.breadcrumbs.push({ label: filter.entity, tabToGo: 'root.selectOperator' });
        return;
      }

      this.activeTab = 'root.selectValue';
      this.breadcrumbs.push({ label: filter.entity, tabToGo: 'root.selectValue' });
    },

    applyFilter() {
      // make this to loose reference
      const copy = {
        ...this.selectedFilter,
        values: this.selectedFilter.values.map((v) => v),
      };

      const preparedFilter = {
        id: uuidv4(),
        key: copy.key,
        label: copy.label,
        // entity: copy.entity,
        type: copy.type,
        values: copy.values,
        operator: copy.operator,
        allowedOperators: copy.allowedOperators,
      };

      this.localFilters = [...this.localFilters, preparedFilter];
      this.reset();
    },

    reset() {
      this.breadcrumbs = [{ label: 'Filters', tabToGo: 'root' }];
      this.activeTab = 'root';
      this.search = '';
      this.isOpen = false;
    },

    useBreadcrumb({ item, index }) {
      this.activeTab = item.tabToGo;
      this.breadcrumbs = this.breadcrumbs.filter((_, i) => i <= index);
      this.search = '';
    },

    removeAFilter(index) {
      this.localFilters.splice(index, 1);
    },

    nextSelectOperator() {
      if (this.selectedFilter.operator === 'is empty') {
        this.selectedFilter.values = [null];
        this.applyFilter();
        return;
      }
      this.activeTab = 'root.selectValue';
      this.breadcrumbs.push({ label: 'Select', tabToGo: 'root.selectValue' });
    },
  },
};
</script>
