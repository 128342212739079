<template>
  <fieldset>
    <b-field label="Email">
      <b-input
        v-model="authentication.passName"
        required
        placeholder="name@company.com"
        @input="$update('passName', $event)"
      />
    </b-field>

    <br>

    <div class="multibar row-vcentered">
      <span class="start text is-3 has-text-secondary">Password</span>
      <b-button
        tag="router-link"
        to="/auth/forgotPassword"
        type="is-ghost"
        class="no-padding-horizontal end"
        label="Forgot password?"
      />
    </div>
    <b-input
      v-model="authentication.passPhrase"
      type="password"
      required
      placeholder="*********"
      password-reveal
      @input="$update('passPhrase', $event)"
    />
  </fieldset>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuAuthenticateLogInForm',
  mixins: [VModelMixin],
  model: {
    prop: 'authentication',
  },
  props: {
    authentication: {
      type: Object,
      required: true,
    },
  },
};
</script>
