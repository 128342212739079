import TheShopify from './TheShopify';

import TheShopAll from './views/TheShopAll';
import TheShopAdd from './views/TheShopAdd';
import TheShopRoute from './views/TheShopRoute';
import TheShopManageWebhooks from './views/TheShopManageWebhooks';
import TheShopManageWebhooksCreate from './views/TheShopManageWebhooksCreate';
import TheShopDetails from './views/TheShopDetails';

import TheShopDetailsEditPanel from './views/editShop/TheShopDetailsEditPanel';

export default [
  {
    path: '/shopify',
    redirect: '/shopify/all-shops',
  },
  {
    path: '/shopify',
    name: 'Shopify',
    component: TheShopify,
    meta: {
      title: 'Shopify',
      hasMenu: true,
      viewStoreNavigation: 'TheShopify',
      requireAuth: true,
    },
    children: [
      {
        path: '/shopify/all-shops',
        name: 'Shopify - All Shopify',
        components: { allShopify: TheShopAll },
        meta: {
          title: 'Shopify',
          hasMenu: true,
          viewStoreNavigation: 'TheShopify',
          requireAuth: true,
        },
      },
      {
        path: '/shopify/add-shop',
        name: 'Shopify - Add Shop',
        components: { addShopify: TheShopAdd },
        meta: {
          title: 'Shopify',
          hasMenu: true,
          viewStoreNavigation: 'TheShopify',
          requireAuth: true,
        },
      },
      {
        path: '/shopify/shop/:sid',
        name: 'Shopify - Shop',
        components: { shopifyShop: TheShopRoute },
        meta: {
          title: 'Shopify',
          hasMenu: true,
          viewStoreNavigation: 'TheShopify',
          requireAuth: true,
        },
        children: [
          {
            path: '/shopify/shop/:sid/details',
            name: 'Shopify - Shop - Details Shopify shop',
            component: TheShopDetails,
            meta: {
              title: 'Shopify',
              hasMenu: true,
              viewStoreNavigation: 'TheShopify',
              requireAuth: true,
            },
            children: [
              {
                path: '/shopify/shop/:sid/details/edit',
                name: 'Shopify - Shop - Details Shopify Shop - Edit panel',
                components: { editShopifyShopPanel: TheShopDetailsEditPanel },
                meta: {
                  title: 'Shopify',
                  hasMenu: true,
                  viewStoreNavigation: 'TheShopify',
                  requireAuth: true,
                },
              },
            ],
          },
          {
            path: '/shopify/shop/:sid/manage-webhooks',
            name: 'Shopify - Shop - Manage Webhooks',
            component: TheShopManageWebhooks,
            meta: {
              title: 'Shopify',
              hasMenu: true,
              viewStoreNavigation: 'TheShopify',
              requireAuth: true,
            },
            children: [
              {
                path: '/shopify/shop/:sid/manage-webhooks/create',
                name: 'Shopify - Shop - Manage Webhooks - Create',
                components: { addManageWebhook: TheShopManageWebhooksCreate },
                meta: {
                  title: 'Shopify',
                  hasMenu: true,
                  viewStoreNavigation: 'TheShopify',
                  requireAuth: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
