var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{class:['mainPanelTable', { hasFilter: _vm.filters.length }]},[_c('b-table',{ref:"table",staticClass:"table-row-clickable table-full-height",attrs:{"data":_vm._mappedAffiliationCodeList_,"hoverable":"","sticky-header":"","loading":_vm.loading,"detail-key":_vm.detailKey,"selected":_vm.selected,"focusable":"","paginated":"","backend-pagination":"","total":_vm._totalAffiliationCodeOverview,"per-page":_vm.perPage,"pagination-size":"is-small","default-sort-direction":_vm.defaultSortOrder,"default-sort":[_vm.sortField, _vm.sortOrder]},on:{"update:selected":function($event){_vm.selected=$event},"page-change":_vm.pageChange,"sort":_vm.onSort},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"has-text-secondary"},[_vm._v("No coupons found!")])]},proxy:true}])},_vm._l((_vm.columns),function(column){return _c('b-table-column',{key:column.field,attrs:{"field":column.field,"label":column.label,"sortable":column.isSortable,"visible":column.visible,"custom-sort":(a, b, isAsc) => _vm.customSort(a, b, isAsc, column)},scopedSlots:_vm._u([(column.cmp === 'UbuTableCampaign')?{key:"default",fn:function(props){return [(props.row.campaignCid)?_c('UbuCustomChip',{attrs:{"model":{
            title: 'Campaign',
            defaultIcon: 'ubu-custom-influence',
            count: 1,
            listItem: [{
              icon: 'ubu-custom-influence',
              color: _vm._campaignList[props.row.campaignCid].color,
              label: _vm._campaignList[props.row.campaignCid].name,
              status: null,
            }],
          }}}):_c('div',{staticClass:"ubuCustomChip"},[_vm._v(" - ")])]}}:(column.cmp === 'UbuTablePercentage')?{key:"default",fn:function(props){return [(props.row.commissionRate)?_c('span',[_vm._v(_vm._s(props.row.commissionRate * 100)+"%")]):(_vm._currentShop.commissionRate)?_c('b-tooltip',{attrs:{"label":"Default shop commission"}},[_c('span',{staticClass:"has-text-primary"},[_vm._v(_vm._s(_vm._currentShop.commissionRate * 100)+"%")])]):_c('span',[_vm._v("-")])]}}:(column.cmp === 'UbuTableDescription')?{key:"default",fn:function(props){return [(props.row.description)?_c('span',{staticClass:"ellipsis"},[_vm._v(_vm._s(props.row.description))]):_c('span',[_vm._v("-")])]}}:(column.cmp === 'UbuTableAction')?{key:"default",fn:function(props){return [(_vm.editable)?_c('b-button',{attrs:{"icon-pack":"ubu","icon-left":"ubu-edit"},on:{"click":function($event){return _vm.$emit('editAffiliation', props.row)}}}):_vm._e()]}}:(column.cmp === 'UbuTableAffiliationFolder')?{key:"default",fn:function(props){return [(props.row.affiliationCodeFolderAcfid)?_c('div',{staticClass:"ubuChip"},[_c('b-icon',{attrs:{"pack":"ubu","icon":"ubu-folder"}}),_vm._v(" "+_vm._s(_vm._affiliationCodeFolderList[props.row.affiliationCodeFolderAcfid].title)+" ")],1):_c('div',{staticClass:"ubuChip"},[_vm._v(" None ")])]}}:{key:"default",fn:function(props){return [_c(column.cmp,{tag:"component",attrs:{"cpo":props.row,"field":column.field,"column":column},on:{"selectContactPlatform":function($event){return _vm.$emit('selectContactPlatform', $event)}}})]}}],null,true)})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }