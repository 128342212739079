<template>
  <!-- eslint-disable max-len -->
  <section>
    <header>
      <p class="text is-3 has-text-secondary">Download mentions | {{ mentionsLength }} mentions selected</p>
      <h1 class="title is-1">Enter your email to receive your download link</h1>
    </header>

    <br>

    <template v-if="!mentionsLength || mentionsAreAboveTheLimit">
      <UbuHelper
        type="is-danger"
        :content="`It is not possible to download the mentions because the selection condition is not met. You must select between 1 and ${limit} mentions. You are trying to upload ${mentionsLength} mention${mentionsLength > 1 ? 's' : ''}`"
        content-type="danger"
      />

      <br>
    </template>

    <template v-if="message.type && message.type === 'danger'">
      <UbuHelper
        type="is-danger"
        :content="message.content"
        content-type="danger"
      />

      <br>
    </template>

    <form
      v-if="message.type !== 'success'"
      @submit.prevent="$emit('download')"
    >
      <b-field label="Email">
        <b-input
          v-model="localEmail"
          type="email"
          required
        />
      </b-field>

      <br>

      <div class="buttons is-right">
        <b-button
          v-if="message.type && message.type === 'danger'"
          label="Contact support"
          class="is-primary"
          @click="$emit('setupIntercom')"
        />
        <b-button
          v-if="!mentionsLength || mentionsAreAboveTheLimit"
          disabled
          label="Get download link"
          class="is-primary"
        />
        <b-button
          v-else
          native-type="submit"
          label="Get download link"
          class="is-primary"
        />
      </div>
    </form>
    <template v-else>
      <UbuHelper
        type="is-secondary"
        :content="message.content"
        content-type="secondary"
        icon="ubu-success"
      />

      <br>

      <div class="multibar">
        <b-button
          label="Done"
          class="is-primary end"
          @click="$emit('closePanel')"
        />
      </div>
    </template>
  </section>
</template>

<script>
export default {
  name: 'UbuDownloadMentionsForm',
  model: {
    prop: 'email',
  },
  props: {
    email: {
      type: String,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    mentionsLength: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      limit: 500,
    };
  },
  computed: {
    localEmail: {
      get() { return this.email; },
      set(val) { this.$emit('input', val); },
    },
    mentionsAreAboveTheLimit() {
      if (this.mentionsLength > this.limit) return true;
      return false;
    },
  },
};
</script>
