<template>
  <div class="ubuWorkspaceTribeForm">
    <UbuHelper
      v-model="notification"
      tag
      close
      tag-label="New"
      icon-left="ubu-link"
      content="Send a form to your closest influencers to invite them to join your Tribe !"
    />

    <br>

    <header class="ubuWorkspaceTribeFormHeader">
      <h1 class="title is-2">Tribe form</h1>

      <div class="buttons">
        <b-button
          type="is-secondary"
          icon-pack="ubu"
          icon-left="ubu-settings"
          label="Settings"
          tag="router-link"
          to="/setting/tribe/setting"
        />

        <b-button
          type="is-secondary"
          label="View tribe"
          tag="router-link"
          to="/tribe/people"
        />

        <b-button
          type="is-primary"
          label="Preview form"
          tag="router-link"
          to="/previewTribeForm"
        />
      </div>
    </header>

    <br>

    <section class="ubuWorkspaceTribeFormTable">
      <div class="multibar">
        <b-field class="start">
          <b-input
            v-model="search"
            placeholder="Search..."
            type="search"
            icon-pack="ubu"
            icon="ubu-search"
            icon-right="ubu-cross"
            icon-right-clickable
            @icon-right-click="search = ''"
          />
        </b-field>
      </div>

      <br>

      <DevDetails :cnt="{name: `'contactPrivateMetadataList'`, obj: contactPrivateMetadataList}" />

      <TheTable
        v-if="contactPrivateMetadataList"
        id="TheWorkspaceTribeFormTable"
        :loading="loading"
        :columns="Object.values(columns)"
        :lead-list="filteredContactPrivateMetadataList"
        :total="total"
        :page="page"
        :per-page.sync="perPage"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :default-sort-order="defaultSortOrder"
        :selected.sync="selected"
        :checked-rows.sync="checkedRows"
        :checkable="false"
        :sticky-header="false"
        detail-key="cpid"
        @viewMedia="eventViewMedia($event)"
        @onPageChange="onPageChange($event)"
        @onSort="onSort($event)"
        @update:selected="changeSelected($event)"
        @update:checkedRows="changeCheckedRows($event)"
      />
    </section>

    <router-view
      name="formSetting"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from '@dailyplanet/utils/helpers';
import TheWorkspaceTribeFormColumn from '../$mixins/TheWorkspaceTribeFormColumn.columns.mixins';

export default {
  name: 'TheSettingsTribeForm',
  mixins: [TheWorkspaceTribeFormColumn],
  data() {
    return {
      inputSearch: '',
      search: '',
      activeNotification: true,
      notification: true,
      loading: false,
      total: 0,
      page: 0,
      perPage: 50,
      sortField: 'cpmid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      selected: {},
      checkedRows: [],
    };
  },
  computed: {
    ...mapGetters({
      contactPrivateMetadataList: 'TheSettingTribeForm/contactPrivateMetadataList',
    }),
    filteredContactPrivateMetadataList() {
      const contactPrivateMetadatas = Object.values(this.contactPrivateMetadataList);

      if (!contactPrivateMetadatas.length) return contactPrivateMetadatas;
      if (!this.search) return contactPrivateMetadatas;

      return contactPrivateMetadatas.reduce((acc, cpm) => {
        const platforms = cpm.contactPlatform.map((cp) => cp.platformName);

        const searchByFirstName = cpm.firstName
          ? cpm.firstName.toLowerCase().includes(this.search.toLowerCase())
          : false;
        const searchByLastName = cpm.lastName
          ? cpm.lastName.toLowerCase().includes(this.search.toLowerCase())
          : false;

        if (cpm.firstName && searchByFirstName) {
          acc.push(cpm);
          return acc;
        }
        if (cpm.lastName && searchByLastName) {
          acc.push(cpm);
          return acc;
        }

        platforms.find((platform) => {
          if (cpm[platform].username.toLowerCase().includes(this.search.toLowerCase())) {
            acc.push(cpm);
            return acc;
          }
          return undefined;
        });

        return acc;
      }, []);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      if (!newVal || !newVal.length || newVal.length < 3) return;
      if (!newVal.match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/)) return;

      this.search = newVal;
    }, 500),
  },
  mounted() {
    this.reportContactPrivateMetadata();
  },
  methods: {
    ...mapActions({
      reportContactPrivateMetadata: 'TheSettingTribeForm/reportContactPrivateMetadata',
    }),
    sort([field, order]) {
      this.sortField = field;
      this.sortOrder = order;
      this.httpQuery.sorting = [field, order];
      return this.loadAsyncData();
    },
    onSort([field, order]) {
      if (!this.checkedRows.length) return this.sort([field, order]);
      return this.$buefy.dialog.confirm({
        message: 'By sorting you will lose your selection, Are you sure you want to continue?',
        confirmText: 'Yes',
        type: 'is-danger',
        iconPack: 'fa',
        hasIcon: true,
        onConfirm: () => this.sort([field, order]),
      });
    },
    changeSelected(row) {
      this.selected = row;
      this.$router.replace({
        path: '/crm/contactPanel',
        query: this.$route.query,
      });
    },
    changeCheckedRows(rows) {
      this.checkedRows = rows;
    },
  },
};
</script>
