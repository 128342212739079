/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import moment from 'moment';
import ErrorBuilder from '@dailyplanet/utils/Errors';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    contact: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/contact/getterContact': contact } = rootGetters;

      return contact;
    },
    contactLocationList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/contactLocation/getterContactLocationList': contactLocationList } = rootGetters;

      return contactLocationList;
    },
    contactLanguageList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/contactLanguage/getterContactLanguageList': contactLanguageList } = rootGetters;

      return contactLanguageList;
    },
    userList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/user/getterUserList': userList } = rootGetters;

      return userList;
    },
    logbookList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/logbook/getterLogbookList': logbookList } = rootGetters;

      return logbookList;
    },
    counterList: (_state, _getters, _rootState, rootGetters) => {
      const {
        'ChannelGroup/counter/getterCounterList': counterList,
        'ContactGroup/getterContactGroupList': contactGroupList,
        'ThreadGroup/getterThreadGroupList': threadGroupList,
        'ApiInfluence/campaign/getterCampaignList': campaignList,
        'ChannelGroup/user/getterUserList': userList = [],
      } = rootGetters;
      console.log('🚀 ~ file: channelGroup.js ~ line 44 ~ campaignList', campaignList);
      const mappedUserList = userList ? userList.arrayMapper('uid') : [].arrayMapper('uid');

      if (!counterList) return {};

      const enrich = (type, id) => {
        switch (type) {
          case 'tags': return threadGroupList[id];
          case 'labels': return contactGroupList[id];
          case 'campaigns': return campaignList[id];
          case 'appoints': return mappedUserList[id];
          default: return {};
        }
      };

      const reduce = counterList.reduce((acc, row) => {
        const {
          type,
          id: customId,
          count,
          hasUnread,
        } = row;
        const grouped = ['tags', 'labels', 'campaigns', 'appoints'];

        if (!grouped.includes(type)) {
          acc[type] = { count, hasUnread };
          return acc;
        }
        if (grouped.includes(type) && customId !== 0) acc[type][customId] = { count, hasUnread, details: enrich(type, customId) };
        return acc;
      }, {
        primary: {},
        mentions: {},
        opportunities: {},
        importants: {},
        all: {},
        tags: {},
        labels: {},
        campaigns: {},
        appoints: {},
      });
      // console.log('🚀 ~ file: channelGroup.js ~ line 63 ~ reduce ~ reduce', reduce);
      return reduce;
    },
    crmListList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/crmList/getterCrmListList': getterCrmListList } = rootGetters;
      return getterCrmListList;
    },
    searchContactPlatformUsernameList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/searchContactPlatformUsername/getterSearchContactPlatformUsernameList': seachContactPlatformUsernameList } = rootGetters;

      return seachContactPlatformUsernameList;
    },
    shopifyShopList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/shopifyShop/getterShopifyShopList': shopifyShopList } = rootGetters;

      return shopifyShopList;
    },
    shopList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/shop/getterShopList': shopList } = rootGetters;

      return shopList;
    },
    autoReportTask: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/autoReport/getterAutoReportTask': task } = rootGetters;

      return task;
    },
  },
  actions: {
    updateChannelGroup({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/update',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery: {},
        },
        textNotifier: 'Update channel group',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((error) => {
          Snackbar.open({
            message: 'Unable to update channel group.',
            ...snackBarFailure,
          });

          dispatchFailure(action);
          throw new ErrorBuilder(error);
        });
    },
    createChannelGroup({ dispatch, rootGetters }, { payload }) {
      const { 'Account/getterAccount': account } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/create',
        context: {
          payload: {
            aid: account.aid,
            ...payload,
          },
        },
        textNotifier: 'Create channel group',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((error) => {
          Snackbar.open({
            message: 'Unable to create channel group.',
            ...snackBarFailure,
          });

          dispatchFailure(action);
          throw new ErrorBuilder(error);
        });
    },

    getContact({ dispatch, rootGetters }, { payload: { cid }, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/contact/get',
        context: { payload: { cgid: currentChannelGroup.cgid, cid }, httpQuery },
        textNotifier: 'get contact',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ getCounter ~ e', e);
          Snackbar.open({
            message: 'Unable to get contact.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },

    reportContactLocation({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/contactLocation/report',
        context: { payload: { cgid: currentChannelGroup.cgid } },
        textNotifier: 'report contact location list',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ getCounter ~ e', e);
          Snackbar.open({
            message: 'Unable to get contact location list.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    reportContactLanguage({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/contactLanguage/report',
        context: { payload: { cgid: currentChannelGroup.cgid } },
        textNotifier: 'report contact language list',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ getCounter ~ e', e);
          Snackbar.open({
            message: 'Unable to get contact language list.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    reportUserList({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/user/report',
        context: { payload: { cgid: currentChannelGroup.cgid } },
        textNotifier: 'report user list',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ getCounter ~ e', e);
          Snackbar.open({
            message: 'Unable to get user list.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    getCounter({ dispatch, rootGetters }, { httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/counter/report',
        context: { payload: { cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'report counters',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 30 ~ getCounter ~ e', e);
          Snackbar.open({
            message: 'Unable to report counters.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },

    getCrmList({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/crmList/get',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'get crmLists',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(({ response: crmList }) => crmList)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 194 ~ getCrmList ~ e', e);
          Snackbar.open({
            message: 'Unable to get crmlist.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },

    reportCrmList({ dispatch, rootGetters }, { httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/crmList/report',
        context: { payload: { cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'report crmLists',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
      // .then(({ response: crmList }) => crmList)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 221 ~ reportCrmList ~ e', e);
          Snackbar.open({
            message: 'Unable to report crmlists.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },

    createCrmList({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/crmList/create',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            createdOn: moment().utc().format(),
            updatedOn: moment().utc().format(),
            channelGroupCgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'create crmList',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(({ response: crmList }) => {
          dispatch('Segment/segmentTrack', { event: 'createList' }, { root: true });

          return crmList;
        })
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 231 ~ createCrmList ~ e', e);
          Snackbar.open({
            message: 'Unable to create crm lists.',
            ...snackBarFailure,
          });

          dispatchFailure(action);
          throw new ErrorBuilder(e);
        });
    },

    updateCrmList({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/crmList/update',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'update crmList',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        // eslint-disable-next-line arrow-body-style
        .then(({ response: crmList }) => {
          // dispatch('Segment/segmentTrack', { event: 'createList' }, { root: true });

          return crmList;
        })
        .catch((e) => {
          Snackbar.open({
            message: 'Unable to update crm list.',
            ...snackBarFailure,
          });

          dispatchFailure(action);
          throw new ErrorBuilder(e);
        });
    },

    deleteCrmList({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/crmList/delete',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'delete crmList',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        // eslint-disable-next-line arrow-body-style
        .then(({ response: crmList }) => {
          // dispatch('Segment/segmentTrack', { event: 'createList' }, { root: true });

          return crmList;
        })
        .catch((e) => {
          Snackbar.open({
            message: 'Unable to delete crm list.',
            ...snackBarFailure,
          });

          dispatchFailure(action);
          throw new ErrorBuilder(e);
        });
    },

    fillList({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/crmList/fillList',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'fill crmList',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'addToCRMList' }, { root: true }))
        .catch(() => {
          Snackbar.open({
            message: 'Unable to fill crm lists.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },

    fillListByContactPlatform({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/crmList/fillListByContactPlatform',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'fill crmList by contactPlatform',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'addToCRMList' }, { root: true }))
        .catch(() => {
          Snackbar.open({
            message: 'Unable to fill crm lists by contactPlatform.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },

    removeFromList({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/crmList/removeFromList',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'remove from crmList',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 511 ~ removeFromList ~ e', e);
          Snackbar.open({
            message: 'Unable to remove contact from crm lists.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },

    reportSearchContactPlatformUsername({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/searchContactPlatformUsername/report',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'report searched contactPlatformUsername list',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: channelGroup.js ~ line 309 ~ reportThreadGroupContactPlatform ~ e', e);
          Snackbar.open({
            message: 'Unable to get contact list.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },

    bulkThreadAppoint({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/bulkThreadAppoint/create',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'update threads appoints',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'assignThread' }, { root: true }))
        .catch((e) => {
          Snackbar.open({
            message: 'Unable to update threads appoints.',
            ...snackBarFailure,
          });
          dispatchFailure(action);
          throw new ErrorBuilder(e);
        });
    },

    bulkThreadAppointDelete({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/bulkThreadAppoint/delete',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
        },
        textNotifier: 'update threads appoints',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          Snackbar.open({
            message: 'Unable to update threads appoints.',
            ...snackBarFailure,
          });
          dispatchFailure(action);
          throw new ErrorBuilder(e);
        });
    },

    exportAnalyticsXlsx({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/exportAnalytics/export',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'export analytics xlsx',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'exportAnalytics' }, { root: true }))
        .catch(() => {
          Snackbar.open({
            message: 'Unable to export analytics xlsx.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
  },
};
