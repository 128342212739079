<template>
  <div>
    <b-message
      v-if="messageContainsUrl"
      type="is-danger"
    >
      Links are not supported by Instagram when sending messages in bulk.
      Please remove all links to send your messages.
    </b-message>

    <b-input
      ref="textarea"
      v-model.trim="localMessage"
      placeholder="Type your message here..."
      type="textarea"
      maxlength="1000"
    />

    <div class="multibar row-vcentered ubuTextareaBottomTools">
      <div class="start">
        <TheTextareaEmojiButton
          :dropdown-position="dropdownPosition.emoji"
          @insertText="insertText($event)"
        />

        <TheTextareaTemplateButton
          v-model="localMessage"
          :dropdown-position="dropdownPosition.template"
          @openTemplateManager="showTemplateManager = true;"
          @insertText="localMessage = $event"
        />
      </div>

      <b-button
        class="end"
        type="is-primary"
        label="Next"
        :disabled="!localMessage.length"
        @click="$emit('next')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheComposeMessage',
  model: {
    prop: 'message',
  },
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      textareaHeight: 800,
      dropdownPosition: {
        emoji: 'is-bottom-left',
        template: 'is-bottom-left',
      },
      messageContainsUrl: false,
    };
  },
  computed: {
    ...mapGetters({
      templateList: 'TheTemplateManager/templateList',
    }),

    templateListSorted() {
      return Object.values(this.templateList).sort((a, b) => b.tid - a.tid);
    },

    isDisabled() {
      return !this.localMessage
      || !this.localMessage.length
      || this.messageContainsUrl;
    },

    localMessage: {
      get() { return this.message; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
  watch: {
    message() {
      // eslint-disable-next-line max-len
      const reg = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
      if (this.message.match(reg)) {
        this.messageContainsUrl = true;
      } else this.messageContainsUrl = false;
    },
  },
  mounted() {
    this.$refs.textarea.focus();
  },
  methods: {
    ...mapActions({
      createTemplate: 'TheTemplateManager/createTemplate',
    }),

    selectTemplate({ text: message }) {
      this.localMessage = message;
      // autoscroll to top when selecting a template card
      this.$refs.top.scrollIntoView();
      this.$refs.textarea.focus();
    },

    saveTemplate() {
      this.createTemplate({
        payload: {
          text: this.localMessage,
          title: `Campaign template #${Object.values(this.templateList).length + 1}`,
        },
      })
        .then(() => {
          this.$buefy.snackbar.open({
            ...snackbarSuccess,
            message: 'Template successfully created.',
          });
        });
    },

    checkValidity() {
      if (this.isDisabled) {
        return;
      }
      this.$emit('submit');
    },
  },
};
</script>
