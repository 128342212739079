import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    create(_, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.discountCode.post({ payload, httpQuery })
        .then((discountCode) => discountCode);
    },
  },
};
