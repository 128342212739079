/* eslint-disable no-multi-spaces */
import TheMonitoring from './TheMonitoring';
import TheMonitoringTask from './TheMonitoringTask';
import TheMonitoringProtoList from './TheMonitoringProtoList';

export default [
  {
    path: '/monitoring',
    name: 'Monitoring',
    component: TheMonitoring,
    meta: {
      title: 'Monitoring',
      hasMenu: true,
      viewStoreNavigation: 'TheMonitoring',
      requireAuth: true,
    },
    children: [
      {
        path: '/monitoring/tasks',
        name: 'Monitoring - Tasks',
        component: TheMonitoringTask,
        meta: {
          title: 'Monitoring - Tasks',
          hasMenu: true,
          viewStoreNavigation: 'TheMonitoring',
          requireAuth: true,
        },
      },
      {
        path: '/monitoring/protolists',
        name: 'Monitoring - ProtoLists',
        component: TheMonitoringProtoList,
        meta: {
          title: 'Monitoring - ProtoLists',
          hasMenu: true,
          viewStoreNavigation: 'TheMonitoring',
          requireAuth: true,
        },
      },
    ],
  },
];
