<template>
  <form @submit.prevent="$emit('editName')">
    <fieldset>
      <b-field label="First Name">
        <b-input
          v-model="localModel.firstName"
          placeholder="First Name ..."
          required
          @input="$update('firstName', $event)"
        />
      </b-field>

      <b-field label="Last Name">
        <b-input
          v-model="localModel.lastName"
          required
          placeholder="Last Name ..."
          @input="$update('lastName', $event)"
        />
      </b-field>
    </fieldset>

    <br>

    <div class="buttons is-right">
      <b-button
        native-type="submit"
        label="Save"
        class="is-primary"
      />
    </div>
  </form>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuEditNameForm',
  mixins: [VModelMixin],
  model: {
    prop: 'model',
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localModel: {
      get() { return { ...this.model }; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
};
</script>
