<template>
  <div>
    <b-field>
      <!-- <p
        v-show="false"
        class="control"
      >
        <b-button
          type="is-dark"
          :label="`${timerCount}`"
        />
      </p> -->
      <p class="control">
        <b-button
          type="is-dark"
          :icon-left="getterNotifBoardIsOpen ? 'eye' : 'eye-off'"
          @click="toggleNotifBoard()"
        />
      </p>
      <!-- <p
        v-if="getterArrayNotifs.length && getterLatestNotif"
        class="control"
      >
        <b-button
          v-if="getterLatestNotif.state === 'created'"
          type="is-info"
          class="is-reverted"
          loading
        />
        <b-button
          v-if="getterLatestNotif.state === 'done'"
          type="is-success"
          class="is-reverted"
          icon-left="check"
        />
        <b-button
          v-if="getterLatestNotif.state === 'fail'"
          type="is-danger"
          class="is-reverted"
          icon-left="close"
        />
      </p> -->
      <p
        v-if="getterArrayNotifs.length && getterLatestNotif"
        class="control"
      >
        <b-button
          v-if="getterLatestNotif.state === 'created'"
          type="is-info"
          class="is-reverted"
          :label="getterLatestNotif.textNotifier"
        />
        <b-button
          v-if="getterLatestNotif.state === 'done'"
          type="is-success"
          class="is-reverted"
          :label="getterLatestNotif.textNotifier"
        />
        <b-button
          v-if="getterLatestNotif.state === 'fail'"
          type="is-danger"
          class="is-reverted"
          :label="getterLatestNotif.textNotifier"
        />
      </p>
    </b-field>
  </div>
</template>

<script>
import NotifierMixin from './$mixins/NotifierMixin';

export default {
  name: 'TheNotifierLast',
  mixins: [NotifierMixin],
  data() {
    return {
      timerCount: 60 * 10,
    };
  },
  computed: {
    timer() {
      return this.timerCount;
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount -= 1;
          }, 1000);
        }
        if (value === 0) {
          this.resetAllNotifications();
        }
      },
      immediate: true,
    },
  },
  methods: {
    resetAllNotifications() {
      return this.deleteSuccessNotifications()
        .then(() => { this.timerCount = 60 * 10; });
    },
  },
};
</script>
