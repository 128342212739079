<template>
  <div
    class="ubuMessageCard-image"
  >
    <b-image
      :placeholder="src"
      :src-fallback="src"
      :src="url ? url : messageLink"
      :lazy="false"
      ratio="298by280"
      responsive
      @error="$emit('handleError')"
      @click.native="openImage()"
      @load="canPlay()"
    />
  </div>
</template>
<script>
import MessageMixin from '@dailyplanet/mixins/MessageMixin';

const imgPlaceholder = require('@dailyplanet/assets/images/image-placeholder.png');

export default {
  name: 'UbuThreadMessageImage',
  mixins: [MessageMixin],
  props: {
    message: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      src: imgPlaceholder,
    };
  },
  methods: {
    openImage() {
      const link = this.url ? this.url : this.messageLink;
      window.open(link, '_blank');
    },
    canPlay() {
      this.$emit('mediaIsReady');
    },
  },
};
</script>
