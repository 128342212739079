/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {},
  getters: {
    ...getters.memberGroup,
    ...getters.tribe,
    ...getters.tribeMember,
    ...getters.tribeMention,
    ...getters.campaign,
    ...getters.threadGroup,
    ...getters.contactGroup,
  },
  actions: {
    ...actions.memberGroup,
    ...actions.tribe,
    ...actions.tribeMember,
    ...actions.tribeMention,
  },
};
