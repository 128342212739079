import {
  dispatchAndForward, dispatchFailure, Errors, genericErrorHandler,
} from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    affiliationCodeFolderList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/affiliationCodeFolder/getterAffiliationCodeFolderList': affiliationCodeFolderList } = rootGetters;

      return affiliationCodeFolderList;
    },
  },
  actions: {
    reportAffiliationCodeFolderList({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationCodeFolder/report',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Report affiliation code folder',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report coupon folder list.', indefinite: true }));
    },

    createAffiliationCodeFolder({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationCodeFolder/create',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Create affiliation code folder',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to create coupon folder.', indefinite: true }));
    },

    updateAffiliationCodeFolder({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationCodeFolder/update',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Update affiliation code folder',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to edit coupon folder.', indefinite: true }));
    },

    deleteAffiliationCodeFolder({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationCodeFolder/delete',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Delete affiliation code folder',
      };

      return dispatchAndForward(action)
      //   .catch(genericErrorHandler(action, { message: 'Unable to delete coupon folder.', indefinite: true }));
        .catch((e) => {
          dispatchFailure(action);
          throw new Errors(e);
        });
    },
  },
};
