export default {
  data() {
    return {
      columns: [
        {
          col: 'image', label: 'Picture', cmp: 'img', cellClass: 'static',
        },
        {
          col: 'fullName', label: 'fullName', cmp: 'flat', cellClass: 'static ellipsis',
        },
        {
          col: 'isAdmin',
          label: 'isAdmin',
          cmp: 'tag',
          tag: {
            condition: 'isAdmin',
            color: 'is-primary',
            text: 'Admin',
          },
          cellClass: 'static',
        },
        {
          col: 'isMe',
          label: 'isMe',
          cmp: 'tag',
          tag: {
            condition: 'isMe',
            color: 'is-info',
            text: 'Me',
          },
          cellClass: 'static',
        },
        { col: 'action', cmp: 'action', cellClass: 'fullWidth' },
      ],
      columnsUbuOrganizationWorkspace: [
        {
          col: 'image', label: 'Picture', cmp: 'img', cellClass: 'static ellipsis', nested: 'channelGroup',
        },
        {
          col: 'name', label: 'Channel Group', cmp: 'flat', cellClass: 'static ellipsis', nested: 'channelGroup',
        },
        { col: 'action', cmp: 'action', cellClass: 'fullWidth ellipsis' },
      ],
    };
  },
};
