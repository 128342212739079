/* eslint-disable quotes */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const ubuLead = {
  report: ({ payload, httpQuery }) => builder({ url: '/api/ubuLead', method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: '/api/ubuLead', method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/ubuLead/${payload.ulid}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/ubuLead/${payload.ulid}`, method: 'DELETE', payload, httpQuery, target }),
  bulkRefresh: {
    post: ({ payload, httpQuery }) => builder({ url: '/api/ubuLead/bulk/refresh', method: 'POST', payload, httpQuery, target }),
  },
};

const scrapingZone = {
  report: ({ payload, httpQuery }) => builder({ url: '/api/scrapingZone', method: 'REPORT', payload, httpQuery, target: 'clark' }),
  post: ({ payload, httpQuery }) => builder({ url: '/api/scrapingZone', method: 'POST', payload, httpQuery, target: 'clark' }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/scrapingZone/${payload.szid}`, method: 'PUT', payload, httpQuery, target: 'clark' }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/scrapingZone/${payload.szid}`, method: 'DELETE', payload, httpQuery, target: 'clark' }),
};

const reportDetailledLead = {
  report: ({ payload, httpQuery }) => builder({ url: '/api/reportDetailledLead/', method: 'REPORT', payload, httpQuery, target }),
};

const salesUser = {
  report: ({ payload, httpQuery }) => builder({ url: '/api/salesUser', method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: '/api/salesUser', method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/salesUser/${payload.userUid}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/salesUser/${payload.userUid}`, method: 'DELETE', payload, httpQuery, target }),
  assign: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/salesUser/${payload.userUid}/assign`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/api/salesUser/${payload.userUid}/assign`, method: 'DELETE', payload, httpQuery, target }),
  },
};

const lead = {
  report: ({ payload, httpQuery }) => builder({ url: '/api/lead', method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: '/api/lead', method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/lead/${payload.lid}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/lead/${payload.lid}`, method: 'DELETE', payload, httpQuery, target }),
};

const salesContact = {
  report: ({ payload, httpQuery }) => builder({ url: '/api/salesContact', method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: '/api/salesContact', method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/salesContact/${payload.username}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/salesContact/${payload.username}`, method: 'DELETE', payload, httpQuery, target }),
  suggestion: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/salesContact/${payload.username}/suggestion`, method: 'POST', payload, httpQuery, target }),
  },
};

export {
  ubuLead,
  scrapingZone,
  reportDetailledLead,
  salesUser,
  lead,
  salesContact,
};
