<template>
  <div
    id="TheUserSignIn"
    class="plain-container"
  >
    <div class="multibar column">
      <div class="multibar">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              Sign In
            </p>
          </header>

          <form
            v-if="isConnected"
            class="card-content"
            @submit.prevent="createUser()"
          >
            <UbuKaraUserUserDetailForm
              v-model="localUserDetail"
            />
            <br>
            <UbuKaraUserAuthenticateForm
              v-model="localAuthenticate"
            />
            <br>
            <div class="buttons is-right">
              <b-button
                type="is-primary"
                native-type="submit"
              >
                SignIn
              </b-button>
            </div>
          </form>

          <div
            v-else
            class="card-content"
          >
            <b-message
              title="Connexion Required"
              type="is-warning"
              :closable="false"
            >
              You must be connected to signIn.
            </b-message>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheUserSignIn',
  computed: {
    ...mapGetters({
      isConnected: 'TheAuthenticate/myAuthenticate',
      getterUserDetail: 'TheUserSignIn/getterUserDetail',
      getterAuthenticate: 'TheUserSignIn/getterAuthenticate',
    }),
    localUserDetail: {
      get() { return { ...this.getterUserDetail }; },
      set(newValue) { this.up_userDetail(newValue); },
    },
    localAuthenticate: {
      get() { return { ...this.getterAuthenticate }; },
      set(newValue) { this.up_authenticate(newValue); },
    },
  },
  methods: {
    ...mapActions({
      up_userDetail: 'TheUserSignIn/up_userDetail',
      up_authenticate: 'TheUserSignIn/up_authenticate',
      createUser: 'TheUserSignIn/createUser',
    }),
  },
};
</script>

<style lang="scss">
#TheUserSignIn {
  & .card {
    min-width: 600px;
  }
}
</style>
