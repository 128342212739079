<template>
  <section>
    <TheAffiliationCodeOverviewTable
      :filters.sync="filters"
      :allowed-filters="allowedFilters"
      :editable="false"
      @selectContactPlatform="selectContactPlatform($event)"
    />

    <TheContactPlatformPanel
      v-if="selectedContactPlatform"
      v-model="selectedContactPlatform"
      is-large
      @close="selectedContactPlatform = null;"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheAnalyticsAffiliationBoard',
  props: {
    filters: {
      type: Array,
      required: true,
    },
    allowedFilters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedContactPlatform: null,
    };
  },
  methods: {
    ...mapActions({
      getAndSetCurrentContactPlatformOverview: 'TheContactPlatformPanel/getAndSetCurrentContactPlatformOverview',
    }),

    selectContactPlatform(cpid) {
      this.selectedContactPlatform = cpid;
      return this.getAndSetCurrentContactPlatformOverview({ payload: { cpid } });
    },
  },
};
</script>
