<template>
  <section class="mainPanel">
    <div class="mainPanelFilters">
      <div class="multibar row-vcentered">
        <TheShopDropdown class="start" />

        <b-button
          label="Create folder"
          icon-pack="ubu"
          icon-left="ubu-add"
          type="is-primary"
          class="end"
          @click="isPanelCreateOpen = !isPanelCreateOpen"
        />
      </div>
    </div>

    <article class="mainPanelTable">
      <b-table
        ref="table"
        :data="folderListFiltered"
        class="table-row-clickable table-full-height"
        hoverable
        sticky-header
        :detail-key="detailKey"
      >
        <b-table-column
          v-for="column in columns"
          :key="column.field"
          :field="column.field"
          :label="column.label"
          :sortable="column.isSortable"
          :visible="column.visible"
        >
          <template v-if="column.cmp === 'UbuTableAction'" #default="props">
            <b-button
              icon-pack="ubu"
              icon-left="ubu-edit"
              @click="openEditPanel(props.row)"
            />
          </template>

          <template v-else #default="props">
            <component
              :is="column.cmp"
              :cpo="props.row"
              :field="column.field"
              :column="column"
            />
          </template>
        </b-table-column>

        <template #empty>
          <p class="has-text-secondary">No folder found!</p>
        </template>
      </b-table>
    </article>

    <TheCreateAffiliationFolder v-if="isPanelCreateOpen" @close="isPanelCreateOpen = false" />

    <TheManageAffiliationCodeFolder
      v-if="isPanelEditOpen"
      :selected-folder="selectedFolder"
      @close="isPanelEditOpen = false; selectedFolder = null"
    />

    <div
      v-if="isPanelCreateOpen || isPanelEditOpen"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheSettingAffiliationFolder',
  mixins: [FilterEngineMixin],
  data() {
    return {
      isPanelCreateOpen: false,
      isPanelEditOpen: false,
      selectedFolder: null,
      // table
      detailKey: 'acfid',
      /* eslint-disable object-curly-newline */
      /* eslint-disable key-spacing */
      /* eslint-disable no-multi-spaces */
      columns: {
        action:      { cmp: 'UbuTableAction', field: 'action',      label: '',            isSortable: false,  visible: true },
        title:       { cmp: 'UbuTableSimple', field: 'title',       label: 'Title',       isSortable: false,  visible: true },
        description: { cmp: 'UbuTableSimple', field: 'description', label: 'Description', isSortable: false,  visible: true },
        createdOn:   { cmp: 'UbuTableDate',   field: 'createdOn',   label: 'Created on',  isSortable: false,  visible: true },
      },
      /* eslint-enable key-spacing */
      /* eslint-enable no-multi-spaces */
      /* eslint-enable object-curly-newline */
    };
  },
  computed: {
    ...mapGetters({
      _currentShop: 'TheCreateAffiliationCodePanel/currentShop',
      _affiliationCodeFolderList: 'TheManageAffiliationCodeFolder/affiliationCodeFolderList',
    }),

    folderListFiltered() {
      const folderList = Object.values(this._affiliationCodeFolderList);

      if (!folderList.length) return [];

      const filtersToApply = { byShopSid: this._currentShop.sid };

      return this.filterEngine(folderList, filtersToApply);
    },

    _filterCollection() {
      return {
        byShopSid(shopSid) { return (m) => (m.shopSid === shopSid ? m : undefined); },
      };
    },
  },
  methods: {
    openEditPanel(folder) {
      this.isPanelEditOpen = true;
      this.selectedFolder = folder;
    },
  },
};
</script>
