<template>
  <div>
    <UbuHelper
      v-if="hasError"
      type="is-danger"
      content-type="danger"
      :content="labelError"
      icon="ubu-failure"
    />

    <UbuHelper
      v-else
      type="is-secondary"
      content-type="secondary"
      :content="labelSuccess"
    />

    <br>

    <div class="buttons is-right">
      <b-button
        label="Close"
        type="is-primary"
        :loading="isLoading"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuAddPeopleDone',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    labelSuccess: {
      type: String,
      default: 'Success!',
    },
    labelError: {
      type: String,
      default: 'Something wrong appear.',
    },
  },
};
</script>
