<template>
  <div
    class="threadListFilter"
  >
    <div
      v-if="isCommonBox"
      class="buttons buttons-cover"
    >
      <b-button
        label="Open"
        type="is-simple"
        :active="httpQuery.open === 'true'"
        :disabled="Boolean(httpQuery.search)"
        @click="$update('open', 'true')"
      />
      <b-button
        label="Closed"
        type="is-simple"
        :active="httpQuery.open === 'false'"
        :disabled="Boolean(httpQuery.search)"
        @click="$update('open', 'false')"
      />
      <b-button
        label="+ Show all"
        type="is-dashed no-focus"
        :active="httpQuery.open === null || httpQuery.open === undefined"
        :disabled="Boolean(httpQuery.search)"
        @click="updateWithoutOpen()"
      />
    </div>

    <b-field
      v-else-if="isOnTribes"
      class="mb-2"
    >
      <UbuDropdownThreadActivityStatus
        :selected-status="localHttpQuery.memberGroup"
        :status-list="dropdownTribeStatusItems"
        @select="handleTribeFilter($event)"
      />
    </b-field>

    <b-field
      v-else-if="isOnCampaigns"
      class="mb-2"
    >
      <UbuDropdownThreadActivityStatus
        :selected-status="localHttpQuery.activityStatus"
        :status-list="dropdownCampaignStatusItems"
        @select="handleActivityStatusFilter($event)"
      />
    </b-field>

    <div class="buttons buttons-cover">
      <UbuSearchbar
        v-model="search"
        placeholder="Search..."
        :loading="loading"
        :autofocus="false"
        @clear="search = ''"
      />

      <b-tooltip
        position="is-bottom"
        label="Filter"
      >
        <b-button
          icon-pack="ubu"
          icon-left="ubu-filter"
          class="is-icon"
          :type="displayFiltersDropdowns ? 'is-primary' : 'is-secondary'"
          :disabled="Boolean($route.query.search)"
          @click="displayFiltersDropdowns = !displayFiltersDropdowns"
        />
      </b-tooltip>
      <b-tooltip
        position="is-bottom"
        label="Bulk actions"
      >
        <b-button
          icon-pack="ubu"
          icon-left="ubu-bulk"
          class="is-icon"
          type="is-secondary"
          :disabled="threadList.length ? false : true"
          @click="toggleBulkAction()"
        />
      </b-tooltip>
      <b-tooltip
        position="is-bottom"
        label="New message"
      >
        <b-button
          tag="a"
          icon-pack="ubu"
          icon-left="ubu-compose"
          class="is-icon"
          type="is-secondary"
          href="https://www.instagram.com/direct/new/"
          target="_blank"
        />
      </b-tooltip>
    </div>

    <b-field
      v-if="displayFiltersDropdowns"
      grouped
    >
      <b-field
        class="filterField"
        label="Only show"
        expanded
      >
        <UbuDropdownSortOptions
          :options="displayOptions"
          :selected-option="localHttpQuery.displayOptions"
          default-option="received"
          @select="$update('displayOptions', $event)"
        />
      </b-field>

      <b-field
        class="filterField"
        label="Sort by"
        expanded
      >
        <UbuDropdownSortOptions
          :options="sortOptions"
          :selected-option="localHttpQuery.sortOptions"
          default-option="newest"
          @select="$update('sortOptions', $event)"
        />
      </b-field>
    </b-field>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import VModelMixin from '@dailyplanet/mixins/VModelMixin';
import ThreadListMixin from '@dailyplanet/mixins/ThreadListMixin';
import { debounce } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheThreadListFilters',
  mixins: [VModelMixin, ThreadListMixin],
  model: {
    prop: 'httpQuery',
  },
  props: {
    httpQuery: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sendMessagePanel: false,
      search: '',
      displayFiltersDropdowns: false,
      minCharacterBeforeSearch: 3,
      savedHttpQuery: {},
      displayOptions: {
        all: { value: 'all', label: 'All' },
        received: { value: 'received', label: 'Received' },
        sent: { value: 'sent', label: 'Sent' },
        unread: { value: 'unread', label: 'Unread' },
        withLabel: { value: 'withLabel', label: 'Label(s)' },
        withoutLabel: { value: 'withoutLabel', label: 'No label(s)' },
        withTag: { value: 'withTag', label: 'Tag(s)' },
        withoutTag: { value: 'withoutTag', label: 'No tag(s)' },
        withAssign: { value: 'withAssign', label: 'Assignee' },
        withoutAssign: { value: 'withoutAssign', label: 'No assignee' },
        withTribe: { value: 'withTribe', label: 'Tribe' },
        withoutTribe: { value: 'withoutTribe', label: 'No tribe' },
      },
      sortOptions: {
        newest: { value: 'newest', label: 'Newest' },
        oldest: { value: 'oldest', label: 'Oldest' },
        followers: { value: 'followers', label: 'Most followers' },
      },
    };
  },
  computed: {
    ...mapGetters({
      // campaignList: 'TheInbox/campaignList',
      activityStatusListFiltered: 'TheInbox/activityStatusListFiltered',
      _memberGroupList: 'TheTribe/memberGroupList',
      currentChannelGroup: 'Authenticate/getterCurrentChannelGroup',
      myChannelPrivate: 'TheInbox/myChannelPrivate',

      accountFeatures: 'TheInbox/accountFeatures',
      threadTidsSelectedInBulk: 'TheInbox/threadTidsSelectedInBulk',

      campaignThreadCounters: 'TheInbox/campaignThreadCounters',
      tribeThreadCounters: 'TheInbox/tribeThreadCounters',
    }),

    localHttpQuery: {
      get() { return { ...this.httpQuery }; },
      set(newValue) { this.$emit('input', newValue); },
    },
    hasUbuMetaExtensionFeature() {
      return this.accountFeatures('UBU_META_EXTENSION');
    },
    isOnCampaigns() {
      return this.$route.params.threadByCampaigns;
    },
    isOnTribes() {
      return this.$route.params.threadByTribes;
    },
    isCommonBox() {
      return this.$route.meta.isCommonThreadBox;
    },
    currentPath() {
      return this.$route.path;
    },
    statusListArrayMapped() {
      return this.activityStatusListFiltered.arrayMapper('status');
    },
    dropdownCampaignStatusItems() {
      const counters = [];

      const countersByStatus = Object.values(this.campaignThreadCounters).reduce((acc, row) => {
        if (['UNSCHEDULED', 'CANCEL', 'SCHEDULED'].includes(row.status)) return acc;
        // console.log('🚀 ~ file: TheThreadListFilters.vue ~ line 226 ~ countersByStatus ~ this.statusListArrayMapped[row.status]', row.status, this.statusListArrayMapped[row.status]);
        acc.push({
          ...row,
          ...this.statusListArrayMapped[row.status],
          threadCount: row.activityCount,
          hasUnread: !!row.unreadCount,
          hasOpen: !!row.openCount,
        });
        return acc;
      }, []);

      counters.push(...countersByStatus);

      counters.push(countersByStatus.reduce((allStatus, item) => {
        /* eslint-disable no-param-reassign */
        if (item.threadCount > 0) allStatus.threadCount += item.threadCount;
        if (item.hasUnread && !allStatus.hasUnread) allStatus.hasUnread = true;
        if (item.hasOpen && !allStatus.hasOpen) allStatus.hasOpen = true;
        /* eslint-enable no-param-reassign */

        return allStatus;
      }, {
        status: 'ALL_STATUS',
        label: 'all statuses',
        icon: null,
        threadCount: 0,
        hasUnread: false,
        hasOpen: false,
        ordinal: 0,
      }));

      return counters.sort((a, b) => a.ordinal - b.ordinal);
    },
    dropdownTribeStatusItems() {
      const counters = [];

      const countersByStatus = Object.values(this.tribeThreadCounters).reduce((acc, row) => {
        if (!row.memberGroupMgid) {
          acc.push({
            ...row,
            label: 'No Group',
            icon: null,
            status: 'NO_GROUP',
            threadCount: row.threadCount,
            hasUnread: !!row.unreadCount,
            hasOpen: !!row.openCount,
          });
          return acc;
        }

        acc.push({
          ...row,
          icon: this._memberGroupList[row.memberGroupMgid].icon,
          label: this._memberGroupList[row.memberGroupMgid].name,
          status: this._memberGroupList[row.memberGroupMgid].mgid,
          threadCount: row.threadCount,
          hasUnread: !!row.unreadCount,
          hasOpen: !!row.openCount,
        });
        return acc;
      }, []);

      counters.push(...countersByStatus);

      counters.push(countersByStatus.reduce((allGroups, item) => {
        /* eslint-disable no-param-reassign */
        if (item.threadCount > 0) allGroups.threadCount += item.threadCount;
        if (item.hasUnread && !allGroups.hasUnread) allGroups.hasUnread = true;
        if (item.hasOpen && !allGroups.hasOpen) allGroups.hasOpen = true;
        /* eslint-enable no-param-reassign */

        return allGroups;
      }, {
        status: 'ALL_STATUS',
        label: 'all',
        icon: null,
        threadCount: 0,
        hasUnread: false,
        hasOpen: false,
      }));

      return counters;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    search: debounce(function (val) {
      if (val.length < this.minCharacterBeforeSearch) {
        const { search, ...rest } = this.localHttpQuery;
        this.localHttpQuery = rest;
        return undefined;
      }
      return this.buildSearchQuery();
    }, 1000),
    resetSearch(val) {
      if (val) this.search = '';
    },
  },
  mounted() {
    if (this.$route.params.threadByCampaigns) {
      this._reportCampaignThreadCounters({ payload: { cid: this.$route.params.threadByCampaigns } });
    }
    if (this.$route.params.threadByTribes) {
      this._reportTribeThreadCounters({ payload: { tid: this.$route.params.threadByTribes } });
    }
  },
  methods: {
    ...mapActions({
      _reportCampaignThreadCounters: 'TheInbox/reportCampaignThreadCounters',
      _reportTribeThreadCounters: 'TheInbox/reportTribeThreadCounters',

      _UP_toggleHeld: 'TheInbox/UP_toggleHeld',
      _UP_resetBulkHeldThreads: 'TheInbox/UP_resetBulkHeldThreads',
    }),
    toggleBulkAction() {
      if (!this.threadTidsSelectedInBulk.length) {
        return this._UP_toggleHeld(this.threadList[0].tid);
      }
      return this._UP_resetBulkHeldThreads();
    },
    updateWithoutOpen() {
      const { open, ...rest } = this.localHttpQuery;
      this.$emit('input', rest);
    },
    handleTribeFilter(status) {
      const { memberGroup, ...rest } = this.localHttpQuery;
      if (status === 'ALL_STATUS') return this.$emit('input', rest);
      if (status === 'NO_GROUP') return this.$update('memberGroup', 'NO_GROUP');
      return this.$update('memberGroup', status);
    },
    handleActivityStatusFilter(status) {
      const { activityStatus, ...rest } = this.localHttpQuery;
      if (status === 'ALL_STATUS') return this.$emit('input', rest);
      return this.$update('activityStatus', status);
    },
    buildSearchQuery() {
      this.localHttpQuery = {
        all: 'true',
        displayOptions: 'all',
        sortOptions: 'newest',
        pagin: this.httpQuery.pagin,
        search: this.search,
      };

      return undefined;
    },
  },
};
</script>
