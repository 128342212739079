/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  actions: {
    create({ rootGetters, dispatch }, { payload }) {
      if (!isActionAllowed(rootGetters, 'FAVORITE_PUBLICATION.CREATE')) return undefined;

      return clark.api.apiPublication.favorite.post({ payload })
        .then(() => {
          dispatch('Segment/segmentTrack', { event: 'addFavoriteMention' }, { root: true });
        });
    },
    delete({ rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'FAVORITE_PUBLICATION.DELETE')) return undefined;

      return clark.api.apiPublication.favorite.delete({ payload });
    },
  },
};
