<template>
  <b-dropdown
    ref="dropdown"
    v-model="httpQueryRow"
    aria-role="list"
    class="is-fullWidth"
    position="is-bottom-right"
    :disabled="disabled"
  >
    <template #trigger>
      <b-button
        v-if="httpQueryRow && httpQueryRow.length"
        type="is-dashed"
        focused
      >
        {{ colDetail.label }}
      </b-button>
      <b-button
        v-else
        type="is-dashed"
      >
        {{ colDetail.label }}
      </b-button>
    </template>
    <b-dropdown-item
      aria-role="listitem"
      custom
    >
      <div>
        <b-field
          grouped
          class="no-shrink no-margin"
        >
          <b-field
            label="Date range"
            expanded
          >
            <b-datepicker
              v-model="localHttpQueryRow"
              placeholder="Click to select..."
              range
              expanded
              nearby-selectable-month-days
              :max-date="this.$moment().toDate()"
              icon-pack="ubu"
              icon="ubu-calendar"
              position="is-top-left"
            />
          </b-field>
        </b-field>

        <br>

        <b-field grouped>
          <b-field expanded>
            <p class="control">
              <b-button
                expanded
                type="is-secondary"
                label="Reset"
                @click="$emit('reset')"
              />
            </p>
          </b-field>

          <b-field expanded>
            <p class="control">
              <b-button
                expanded
                type="is-primary"
                label="Done"
                @click="closeDropdown()"
              />
            </p>
          </b-field>
        </b-field>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuCRMFilterDateRange',
  mixins: [VModelMixin],
  model: {
    prop: 'httpQueryRow',
  },
  props: {
    httpQueryRow: {
      type: Array,
      required: true,
    },
    colDetail: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localHttpQueryRow: {
      get() {
        const [start, end] = this.httpQueryRow;
        return [this.$moment(start).toDate(), this.$moment(end).toDate()];
      },
      set(newValue) {
        const [start, end] = newValue;
        this.$emit('input', [this.$moment(start).format('YYYY-MM-DD'), this.$moment(end).format('YYYY-MM-DD')]);
      },
    },
  },
  methods: {
    closeDropdown() {
      this.$refs.dropdown.isActive = false;
    },
  },
};
</script>
