<template>
  <section>
    <UbuFilterNumberRange
      v-if="filter.type === 'rangeNumber'"
      v-model="localFilter.values"
    />

    <UbuFilterCheckbox
      v-if="filter.type === 'checkbox'"
      v-model="localFilter.values"
      :options="filteredList"
    />

    <UbuFilterRadio
      v-if="filter.type === 'radio'"
      v-model="localFilter.values"
      :options="localFilter.list"
    />

    <UbuFilterDateRange
      v-if="filter.type === 'rangeDate'"
      v-model="localFilter.values"
      :option-date-to-use="optionDateToUse"
    />

    <UbuFilterInput
      v-if="filter.type === 'input'"
      v-model="localFilter.values"
    />
  </section>
</template>

<script>
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuFilterByType',
  mixins: [FilterEngineMixin, VModelMixin],
  model: {
    prop: 'filter',
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      optionDateToUse: {
        currentWeek: {
          subtype: 'currentWeek',
          label: 'Last 7 days',
          rangeDate: [this.$moment().subtract({ week: 1 }).toDate(), this.$moment().toDate()],
        },
        currentMonth: {
          subtype: 'currentMonth',
          label: 'Last 30 days',
          rangeDate: [this.$moment().subtract({ month: 1 }).toDate(), this.$moment().toDate()],
        },
      },
    };
  },
  computed: {
    localFilter: {
      get() { return this.filter; },
      set(val) { this.$emit('input', val); },
    },

    filteredList() {
      const { list } = this.localFilter;

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByLabelName = this.search;

      return this.filterEngine(list, filtersToApply);
    },
  },
};
</script>
