import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuSendInstantMessageNotification from './UbuSendInstantMessageNotification';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuSendInstantMessageNotification);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuSendInstantMessageNotification,
};
