<template>
  <section id="TheWorkspace">
    <UbuAsideMenu :sidebar-options="sidebarOptions" />
    <div class="has-coreview-spaced kara">
      <DevDetails :cnt="{name: 'selected', obj: selected}" />
      <router-view
        name="addAccount"
        :selected.sync="selected"
        @reset="selected = {}"
      />

      <router-view
        name="adminAccount"
        :selected.sync="selected"
        @reset="selected = {}"
      />

      <router-view
        name="adminAccounts"
        :selected.sync="selected"
        @reset="selected = {}"
      />
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheAdmin',
  data() {
    return {
      selected: {},
      routes: [
        {
          title: 'GLOBAL WORKFLOW',
          ordinal: 0,
          items: [
            {
              text: 'All Accounts',
              path: '/admin/accounts',
            },
            {
              text: 'Add Account',
              path: '/admin/add-account',
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      accountList: 'TheAdmin/accountList',
    }),
    sidebarOptions() {
      const commonRoutes = this.routes;
      if ((!Object.values(this.selected).length || !this.selected.aid) && !this.$route.params.aid) return commonRoutes;

      const basePath = !this.$route.params.aid
        ? `/admin/account/${this.selected.aid}`
        : `/admin/account/${this.$route.params.aid}`;

      const accountRoutes = [
        {
          title: 'ACCOUNT WORKFLOW',
          ordinal: 1,
          items: [
            {
              text: 'Details',
              path: `${basePath}/details`,
            },
            {
              text: 'Members',
              path: `${basePath}/members`,
            },
            {
              text: 'Channels',
              path: `${basePath}/channels`,
            },
            {
              text: 'History',
              path: `${basePath}/history`,
            },
          ],
        },
      ];
      return commonRoutes.concat(accountRoutes);
    },
  },
  watch: {
    selected(newVal) {
      if (newVal.aid) {
        this.$router.push({
          path: `/admin/account/${newVal.aid}/details`,
        });
      }
    },
  },
  mounted() {
    return Promise.all([
      this.reportAccountList({
        httpQuery: {
          channelAndChannelGroupList: true,
        },
      }),
    ]);
  },
  methods: {
    ...mapActions({
      reportAccountList: 'TheAdmin/reportAccountList',
    }),
    reset() {
      console.log('hello mother fucker');
    },
  },
};
</script>
