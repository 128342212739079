import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuSteperControls from './UbuSteperControls';
import UbuKaraSummaryAddAccount from './UbuKaraSummaryAddAccount';
import UbuViewChannelsAccount from './UbuViewChannelsAccount';
import UbuAccountAddChannelStepContactForm from './UbuAccountAddChannelStepContactForm';
import UbuAccountAddChannelStepFormManageChannelSms from './UbuAccountAddChannelStepFormManageChannelSms';
import UbuAccountAddChannelStepConfirmation from './UbuAccountAddChannelStepConfirmation';
import UbuAccountDetailsEdit from './UbuAccountDetailsEdit';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuSteperControls);
    registerComponent(Vue, UbuKaraSummaryAddAccount);
    registerComponent(Vue, UbuViewChannelsAccount);
    registerComponent(Vue, UbuAccountAddChannelStepContactForm);
    registerComponent(Vue, UbuAccountAddChannelStepFormManageChannelSms);
    registerComponent(Vue, UbuAccountAddChannelStepConfirmation);
    registerComponent(Vue, UbuAccountDetailsEdit);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuSteperControls,
  UbuKaraSummaryAddAccount,
  UbuViewChannelsAccount,
  UbuAccountAddChannelStepContactForm,
  UbuAccountAddChannelStepFormManageChannelSms,
  UbuAccountAddChannelStepConfirmation,
  UbuAccountDetailsEdit,
};
