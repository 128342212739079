<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        :to="{ path: `/users/user/${currentUid}/details` }"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <h1 class="title is-1">
      Change phone number
    </h1>

    <br>

    <form @submit.prevent="updatePhoneNumber()">
      <b-field label="Phone number">
        <vue-tel-input
          v-model="newPhoneNumber.phone"
          default-country="fr"
          :preferred-countries="preferredCountries"
          :input-options="inputOptions"
          :auto-format="false"
          invalid-msg="Invalid phone number"
          valid-characters-only
          @country-changed="changeCodeCountry($event)"
          @validate="validPhoneNumberMethod($event)"
        />
      </b-field>

      <br>

      <div class="buttons is-right">
        <b-button
          v-if="userDetailTargeted && userDetailTargeted.phone"
          type="is-ghost"
          label="Delete phone number"
          @click="deletePhone()"
        />
        <b-button
          type="is-primary"
          label="Save"
          native-type="submit"
        />
      </div>
    </form>
  </section>
</template>

<script>
/* eslint-disable func-names */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheUsersUserDetailsPanelEditPhone',
  data() {
    return {
      validPhoneNumber: false,
      newPhoneNumber: {},
    };
  },
  computed: {
    ...mapGetters({
      userDetailTargeted: 'TheUsers/userDetailTargeted',
    }),
    currentUid() {
      return this.$route.params.uid;
    },
    preferredCountries() {
      return ['fr', 'us', 'br', 'gb', 'jp'];
    },
    inputOptions() {
      return {
        placeholder: 'Enter a phone number',
        showDialCode: true,
        required: true,
        mode: 'national',
        styleClasses: [
          {
            'is-danger': this.validPhoneNumber === false,
            'is-success': this.validPhoneNumber === true,
          },
        ],
        readonly: this.isPreview,
      };
    },
  },
  watch: {
    'newPhoneNumber.phone': function (newVal) {
      const { dialCode } = this.newPhoneNumber;
      if (!newVal.includes(dialCode)) {
        this.newPhoneNumber.phone = `${dialCode} `;
      }
    },
  },
  mounted() {
    this.getUser({ payload: { uid: this.currentUid }, httpQuery: { userDetail: 'true' } })
      .then(() => {
        this.newPhoneNumber = {
          phone: this.userDetailTargeted.phone || '',
          dialCode: '+33',
        };
      });
  },
  methods: {
    ...mapActions({
      updateUserDetail: 'TheUsers/updateUserDetail',
      getUser: 'TheUsers/getUser',
      up_detailledUser: 'TheUsers/up_detailledUser',
    }),
    changeCodeCountry({ dialCode }) {
      this.newPhoneNumber.dialCode = `+${dialCode}`;
    },
    validPhoneNumberMethod({ valid, formatted }) {
      this.validPhoneNumber = valid;
      this.newPhoneNumber.phone = formatted.replace(/ /g, '');

      const { phone, dialCode } = this.newPhoneNumber;
      const dialCodeLength = dialCode.length + 1;

      if (!phone.includes(dialCode) || phone.length < dialCodeLength) this.validPhoneNumber = false;
    },
    updatePhoneNumber() {
      const { phone, dialCode } = this.newPhoneNumber;

      // Longueur du dialcode + un espace pour correspondre a l'insertion du watcher
      const dialCodeLength = dialCode.length + 1;

      if (this.validPhoneNumber && phone.includes(dialCode) && phone.length > dialCodeLength) {
        const payload = {
          ...this.userDetailTargeted,
          uid: this.userDetailTargeted.userUid,
          phone: phone.split(' ').join(''),
        };

        Promise.resolve()
          .then(() => this.updateUserDetail({ payload }))
          .then(() => this.up_detailledUser({ payload }))
          .then(() => this.$router.push(`/users/user/${this.currentUid}/details`));
      }
    },
    deletePhone() {
      const payload = {
        ...this.userDetailTargeted,
        uid: this.userDetailTargeted.userUid,
        phone: null,
      };

      this.$buefy.dialog.confirm({
        title: 'Delete phone number',
        message: 'You are about to delete the phone number of this user. Are you sure you want to continue ?',
        confirmText: 'Yes',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => Promise.resolve()
          .then(() => this.updateUserDetail({ payload }))
          .then(() => this.up_detailledUser({ payload }))
          .then(() => this.$router.push(`/users/user/${this.currentUid}/details`)),
      });
    },
  },
};
</script>
