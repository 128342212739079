<template>
  <div class="ubuHashtags">
    <header class="multibar mb-3">
      <h1 class="start title is-2">Hashtags</h1>

      <div class="buttons end">
        <b-button
          type="is-primary"
          label="New hashtag"
          icon-pack="ubu"
          icon-left="ubu-add"
          :disabled="!accountFeatures('HASHTAGS')"
          @click="handleCreateHashtag()"
        />
      </div>
    </header>

    <template v-if="accountFeatures('HASHTAGS')">
      <div class="buttons">
        <b-button
          v-for="(hashtag, index) in hashtagsList"
          :key="index"
          type="is-simple"
          :loading="loading"
          :active="isButtonActive(hashtag)"
          @click="getHashtag(hashtag.hashtag)"
        >
          #{{ hashtag.hashtag }}
        </b-button>
      </div>

      <template v-if="mediasFromCurrentHashtag.length">
        <UbuHashtagsFilters
          v-model="search"
          @delete="deleteHashtag()"
          @downloadAll="isOpenDownloadPanel = !isOpenDownloadPanel"
        />

        <br>

        <router-view
          name="galleryView"
          :search="search"
          :media-list="listFiltered"
          @openMediaModal="toggleModalMedia(true, $event)"
        />

        <router-view
          name="detailedView"
          :search="search"
          :media-list="listFiltered"
          @openMediaModal="toggleModalMedia(true, $event)"
        />

        <UbuHashtagsViewMedia
          v-if="contentMedia"
          v-model="openModal"
          :content-medias="contentMedia.payload"
          :index="contentMedia.index"
          @openPanel="openModal = true"
          @closeMedia="toggleModalMedia(false, null)"
        />

        <UbuDownloadMentions
          v-if="isOpenDownloadPanel"
          v-model="email"
          :message="{}"
          :mentions-length="listFiltered.length"
          @download="downloadAll()"
          @closePanel="closeDownloadPanel()"
        />
      </template>

      <div v-else>
        <UbuNoData
          title="We’re fetching the posts with this hashtag!"
          subtitle="Please note that it can take up to a few hours before you see the first posts."
          action
          button-is="event"
          event-name="contactSupport"
          type="is-primary"
          label="Contact support"
          @contactSupport="openIntercom()"
        />
      </div>

      <TheHashtagsCreatePanel
        v-if="isCreateHashtagPanelOpen"
        @close="isCreateHashtagPanelOpen = false"
      />

      <div
        v-if="isCreateHashtagPanelOpen || isOpenDownloadPanel"
        class="overlayWithLock"
      />
    </template>

    <template v-else>
      <br>

      <UbuExtensionDisconnected
        :has-extension="false"
        feature="hashtags"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackbarError, snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheHashtags',
  data() {
    return {
      loading: false,
      search: '',
      contentMedia: null,
      openModal: false,
      email: '',
      isOpenDownloadPanel: false,
      isCreateHashtagPanelOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      hashtagsList: 'TheHashtags/hashtagsList',
      currentHashtag: 'TheHashtags/currentHashtag',
      mediasFromCurrentHashtag: 'TheHashtags/mediasFromCurrentHashtag',
      currentUser: 'TheAuthenticate/myAuthenticate',
      accountFeatures: 'TheHashtags/accountFeatures',
    }),
    listFiltered() {
      if (!this.search.length) return this.mediasFromCurrentHashtag;

      return this.mediasFromCurrentHashtag.filter(({
        username,
        caption,
      }) => username.toLowerCase().includes(this.search.toLowerCase())
      || caption.toLowerCase().includes(this.search.toLowerCase()));
    },
    currentUserEmail() {
      return this.currentUser.passName;
    },
  },
  mounted() {
    if (!this.accountFeatures('HASHTAGS')) {
      this.emitSegmentEvent({ event: 'clickOnHashtagsNoFeature' });
      this.$intercom.showNewMessage('Hi! I would like to activate the HASHTAGS module! 🙌');

      return null;
    }

    return Promise.resolve()
      .then(() => this.reportHashtags({ httpQuery: { active: true } }))
      .then(() => {
        if (!Object.values(this.hashtagsList).length) return;

        const firstFromList = Object.values(this.hashtagsList)[0].hashtag;

        this.getHashtag(firstFromList);
      })
      .then(() => {
        this.email = this.currentUserEmail;
        this.emitSegmentEvent({ event: 'clickOnHashtags' });
      });
  },
  methods: {
    ...mapActions({
      reportHashtags: 'TheHashtags/reportHashtags',
      _getHashtag: 'TheHashtags/getHashtag',
      _deleteHashtag: 'TheHashtags/deleteHashtag',
      downloadMention: 'TheInfluenceMentions/downloadMention',
      emitSegmentEvent: 'TheHashtags/emitSegmentEvent',
    }),
    openIntercom() {
      this.$intercom.showNewMessage('Hi! I’m having trouble fetching posts for my hashtag! 🙌');
    },
    isButtonActive(hashtag) {
      if (!this.currentHashtag) return false;
      return hashtag.hashtag === this.currentHashtag.name;
    },
    getHashtag(name) {
      this.loading = true;

      this._getHashtag({ payload: { hashtag: name } })
        .then(() => { this.loading = false; });
    },
    toggleModalMedia(toggle, event) {
      this.openModal = toggle;
      this.contentMedia = event;
    },
    deleteHashtag() {
      this.$buefy.dialog.confirm({
        title: 'Deleting Hashtag',
        message: `Are you sure you want to delete <b>#${this.currentHashtag.name}</b> hashtag?`,
        confirmText: 'Delete Hashtag',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this._deleteHashtag({ payload: { hashtag: this.currentHashtag.name } })
            .then(() => {
              this.$buefy.snackbar.open({
                ...snackbarSuccess,
                message: 'Successfully deleted hashtag.',
              });

              this.contentMedia = [];

              if (!Object.values(this.hashtagsList).length) return;

              const firstFromList = Object.values(this.hashtagsList)[0].hashtag;

              this.getHashtag(firstFromList);
            });
        },
      });
    },
    closeDownloadPanel() {
      this.isOpenDownloadPanel = false;
    },
    downloadAll() {
      if (this.mediasFromCurrentHashtag.length > 1000) {
        this.$buefy.snackbar.open({
          ...snackbarError,
          message: 'You can\'t download more than 1000 medias at once... Download your medias in multiple batches or contact support',
          duration: 7000,
          cancelText: 'OK',
          actionText: 'Contact support',
          onAction: () => {
            this.$intercom.showNewMessage('Hello ! I need to download all my hashtags medias but there are more than 1000... 🙌');
          },
        });

        return;
      }

      const preparedPayload = {
        medias: this.listFiltered.map((media) => ({ extrefId: media.id })),
        recipients: [this.email],
      };

      this.downloadMention({ payload: preparedPayload })
        .then(() => {
          this.$buefy.snackbar.open({
            ...snackbarSuccess,
            message: 'The request to download medias is success!',
          });

          this.isOpenDownloadPanel = false;
        });
    },
    handleCreateHashtag() {
      if (Object.keys(this.hashtagsList).length >= 5) {
        this.$buefy.snackbar.open({
          ...snackbarError,
          message: 'You can\'t subscribe to more than 5 hashtags',
        });

        return;
      }

      this.isCreateHashtagPanelOpen = true;
    },
  },
};
</script>
