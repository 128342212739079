<template>
  <section
    class="ubuMessageWrapper"
    :isMe="isMe"
  >
    <UbuThreadMessageContentWrapper
      :message="message"
      :subtext="message.medias.length === 1 ? 'Shared video' : 'Shared medias'"
      :is-me="isMe"
      :platform-name="platformName"
    />
  </section>
</template>

<script>
export default {
  name: 'UbuThreadMessageMediaVideo',
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: () => {},
    },
    platformName: {
      type: String,
      default: 'INSTAGRAM',
    },
  },
};
</script>
