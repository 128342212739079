import TheAccessDenied from './TheAccessDenied';

export default [
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: TheAccessDenied,
    meta: {
      requireAuth: true,
      viewStoreNavigation: 'TheAccessDenied',
    },
  },
];
