<template>
  <section class="multibar row-vcentered">
    <!-- <DevDetails :cnt="{name: 'shopifyShopList', obj: shopifyShopList}" /> -->

    <UbuVectorIcon
      class="start mr-2"
      :icon="{
        pack: 'ubu',
        icon: `ubu-social-shopify`,
        iconPaths: 3,
        fontSize: '35'
      }"
    />

    <div class="start">
      <h3 class="text is-3">Shopify <b-tag type="is-primary">Beta</b-tag></h3>

      <template v-if="Object.values(shopifyShopList).length">
        <div
          v-for="shop in shopifyShopList"
          :key="shop.sid"
          class="multibar row-vcentered"
        >
          <b-icon
            icon="circle-small"
            :class="['start', {
              'has-text-success': shop.isConnected,
              'has-text-primary': !shop.isConnected,
            }]"
          />

          <p class="subtext is-3 has-text-secondary start text-truncate">{{ shop.extrefId }}</p>

          <b-button
            label="Reconnect store"
            type="is-ghost is-borderless"
            class="start"
            @click="$emit('openShopifyConnectPanel', shop)"
          />
          <!-- </div> -->
        </div>
      </template>
    </div>

    <b-button
      class="end"
      label="Connect a store"
      type="is-secondary"
      @click="emitEvent()"
    />
  </section>
</template>

<script>
export default {
  name: 'UbuShopifyConnectRow',
  props: {
    shopifyShopList: {
      type: Object,
      default: () => {},
    },
    hasFeature: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    emitEvent() {
      return this.hasFeature
        ? this.$emit('openShopifyConnectPanel')
        : this.$emit('contactSupport', 'SHOPIFY');
    },
  },
};
</script>
