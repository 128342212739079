/* eslint-disable max-len */
// import { actions } from '@dailyplanet/data-stores/commonViewStores';
import BulkActionEngineStore from '@dailyplanet/data-stores/engine-stores/bulkAction';

export default {
  namespaced: true,
  state: {
    ...BulkActionEngineStore.state,
  },
  mutations: {
    ...BulkActionEngineStore.mutations,
  },
  getters: {
    ...BulkActionEngineStore.getters,

    mentionExtrefIdList: (state) => state.checkedRows.map((media) => ({
      extrefId: media.id,
    })),

    tabItems: () => ({
      root: {
        tab: 'root',
        title: 'What do you want to do ?',
        subtext: 'Bulk actions',
      },
      'root.download': {
        tab: 'root.download',
        title: 'Download',
        subtext: 'Bulk actions',
      },
    }),
  },
  actions: {
    // ...actions.segment,
    ...BulkActionEngineStore.actions,

    UP_resetStore({ commit }) {
      commit('RESET_STORE');
    },

    UP_checkedRows({ commit }, newData) {
      commit('SET_CHECKED_ROWS', newData);
    },
  },
};
