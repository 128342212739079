<template>
  <section
    :class="['ubuMessageCard', {
      'start': !isMe,
      'end' :isMe
    }]"
  >
    <UbuThreadMessageVideo
      v-if="isVideo && !isInError && messageLink && !isCarousel"
      :class="['', {'is-hidden': !mediaIsReady}]"
      :message="message"
      :content-type="contentType ? contentType : 'video'"
      :format="format ? format : 'mp4'"
      @handleError="handleError()"
      @mediaIsReady="setMediaIsReady()"
    />
    <UbuThreadMessageImage
      v-if="isImage && !isInError && messageLink && !isCarousel"
      :class="['', {'is-hidden': !mediaIsReady}]"
      :message="message"
      @handleError="handleError()"
      @mediaIsReady="setMediaIsReady()"
    />
    <UbuThreadMessageCarousel
      v-if="isCarousel && !isInError"
      :message="message"
      @handleError="handleError()"
    />
    <div
      v-if="subtext || message.text || (isAudio && !isInError)"
      class="ubuMessageCard-content"
    >
      <div
        v-if="messageLink && isInError"
        class="subtext cloud60 mb-1"
      >
        <span v-if="platformName === 'INSTAGRAM'">This media was deleted by Instagram API.</span>
        <span v-else-if="platformName === 'FACEBOOK'">This media was deleted by Facebook API.</span>
        <span v-else>This media was deleted</span>
      </div>
      <UbuThreadMessageAudio
        v-if="isAudio && !isInError && messageLink && !isCarousel"
        :class="['', {'is-hidden': !mediaIsReady}]"
        :message="message"
        :content-type="contentType ? contentType : 'audio'"
        :format="format ? format : 'mp4'"
        @handleError="handleError()"
        @mediaIsReady="setMediaIsReady()"
      />
      <div class="subtext cloud60">{{ subtext }}</div>
      <!-- <a
        class="button is-ghost link-start mt-2"
        :href="messageLink ? messageLink : 'https://www.instagram.com/direct/inbox/'"
        target="_blank"
      >
        {{ messageLink ? 'Open media' : 'View on Instagram' }}
      </a> -->
      <a
        v-if="isInError && platformName === 'INSTAGRAM'"
        class="button is-ghost link-start mt-2"
        href="https://www.instagram.com/direct/inbox/"
        target="_blank"
      >
        View on Instagram
      </a>
      <a
        v-else-if="isInError && platformName === 'FACEBOOK'"
        class="button is-ghost link-start mt-2"
        href="https://www.facebook.com/messages"
        target="_blank"
      >
        View on Facebook
      </a>
      <p
        v-if="message.text"
        class="m-2"
      >
        {{ message.text }}
      </p>
    </div>
    <UbuThreadMessageFooter :message="message" />
  </section>
</template>

<script>
import MessageMixin from '@dailyplanet/mixins/MessageMixin';

export default {
  name: 'UbuThreadMessageContentWrapper',
  mixins: [MessageMixin],
  props: {
    message: {
      type: Object,
      default: () => {},
    },
    subtext: {
      type: String,
      default: '',
    },
    isMe: {
      type: Boolean,
      required: true,
    },
    platformName: {
      type: String,
      default: 'INSTAGRAM',
    },
  },
  data() {
    return {
      contentType: null,
      format: null,
      isInError: false,
      forceImage: false,
      forceAudio: false,
      mediaIsReady: false,
    };
  },
  computed: {
    isVideo() {
      return Boolean(((this.message.medias
      && this.message.medias.length === 1
      && this.message.medias[0].url
      && this.message.medias[0].type === 'VIDEO'
      && !this.isAudio && !this.isImage)
      || (this.message.link && this.contentType === 'video'))
      || this.forceVideo);
    },
    isAudio() {
      return Boolean(((this.message.link && this.contentType === 'audio')
      || (this.message.medias
      && this.message.medias.length === 1
      && this.message.medias[0].url
      && this.message.medias[0].type === 'AUDIO'
      && !this.isVideo && !this.isImage))
      || this.forceAudio);
    },
    isImage() {
      return Boolean(((this.message.medias
      && this.message.medias.length === 1
      && this.message.medias[0].url
      && this.message.medias[0].type === 'IMAGE'
      && !this.isVideo && !this.isAudio)
      || (this.message.link && this.contentType === 'image'))
      || this.forceImage);
    },
    isCarousel() {
      return Boolean(this.message.medias
      && this.message.medias.length > 1
      && this.message.medias[0].url);
    },
    forceVideo() {
      if (!this.forceAudio && !this.forceImage && !this.isInError && this.message.type !== 'MEDIA_AUDIO') return true;
      return false;
    },
  },
  methods: {
    handleError() {
      if (this.forceVideo) {
        this.forceAudio = true;
      } else if (this.forceAudio) {
        this.forceAudio = false;
        this.forceImage = true;
      } else if (this.forceImage) {
        this.forceImage = false;
        this.isInError = true;
      }
    },
    setMediaIsReady() {
      this.mediaIsReady = true;
    },
  },
};
</script>
