<!-- eslint-disable max-len -->

<template>
  <section
    id="TheNewCRM"
    class="mainPanel"
  >
    <UbuCRMHeader
      :total-people="totalPeople"
      @openCRMListManager="isManageCRMListPanelOpen = !isManageCRMListPanelOpen"
      @openAddPeople="isAddPeoplePanelOpen = !isAddPeoplePanelOpen"
    />

    <aside class="mainPanelFilters">
      <div class="multibar row-vcentered">
        <UbuSearchbar
          v-model="searchByUsername"
          class="start mr-2"
          @clear="clearSearchbar()"
          @pressEnter="addSearchbarFilter()"
        />

        <!-- <b-button
          class="start mr-2"
          label="Search"
          type="is-primary"
          @click="reportDatas()"
        /> -->

        <TheFilterDropdown
          v-model="filters"
          :filter-list="filtersCatalog"
          :non-displayable-filters="nonDisplayableFilters"
          :selected-segment-filter.sync="selectedSegmentFilter"
          class="start mr-2"
        />

        <template v-if="filters.length">
          <b-button
            class="start"
            label="Clear filters"
            type="is-ghost is-borderless"
            @click="deleteAllFilters()"
          />

          <TheSegmentFilterManager
            v-model="selectedSegmentFilter"
            class="start"
            :filters="filters"
          />
        </template>

        <UbuColumnToggle
          v-model="columns"
          class="end"
          position="is-bottom-left"
        />
      </div>

      <template v-if="filters.length">
        <div class="activeFilterList">
          <UbuDropdownFilterForm
            v-for="(filter) in filters.filter(({ key }) => !nonDisplayableFilters.includes(key)) "
            :key="filter.id"
            v-model="filtersMapped[filter.id]"
            class="mb-1"
            :is-not-allowed="!allowedFilters.includes(filter.key)"
            :catalog-filter="genericCatalog"
            @handle="handleFilterUpdate($event)"
            @deleteFilter="deleteFilter(filter.id)"
          />
        </div>
      </template>
    </aside>

    <article
      :class="['mainPanelTable', {
        hasFilter: filters.length,
      }]"
      @keydown.prevent="keyupThreadListHandler($event)"
    >
      <b-table
        ref="table"
        :data="tableDataList"
        class="table-row-clickable table-full-height"
        hoverable
        sticky-header
        :loading="loading"
        :detail-key="detailKey"

        sticky-checkbox
        checkable
        :checked-rows.sync="checkedRows"
        :custom-is-checked="(a, b) => {
          return a[detailKey] === b[detailKey];
        }"

        :selected.sync="selected"
        focusable

        paginated
        backend-pagination
        :total="totalPeople"
        :per-page="perPage"
        pagination-size="is-small"

        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"

        @page-change="pageChange"
        @sort="onSort"
      >
        <b-table-column
          v-for="column in columns"
          :key="column.field"
          :field="column.field"
          :label="column.label"
          :sortable="column.isSortable"
          :visible="column.visible"
          :custom-sort="(a, b, isAsc) => customSort(a, b, isAsc, column)"
        >
          <template
            v-if="column.cmp === 'UbuTablePlatform'"
            #header
          >
            <b-icon
              pack="ubu"
              icon="ubu-organic"
            />
          </template>

          <template #default="props">
            <component
              :is="column.cmp"
              :cpo="props.row"
              :field="column.field"
              :column="column"
              @selectContactPlatform="openContactPlatformPanel($event)"
            />
          </template>
        </b-table-column>

        <template #empty>
          <p class="has-text-secondary">No people yet!</p>
        </template>
      </b-table>
    </article>

    <UbuBulkActionPanelTrigger
      :bulk-is-open="bulkIsOpen"
      :checked-rows-length="checkedRows.length"
      @cancel="bulkIsOpen = false; checkedRows = [];"
      @open="bulkIsOpen = true;"
    />

    <TheCRMBulk
      v-if="bulkIsOpen && checkedRows.length"
      v-model="checkedRows"
      @close="closeBulkAndRefresh()"
    />

    <TheContactPlatformPanel
      v-if="selectedContactPlatform"
      v-model="selectedContactPlatform"
      is-large
      @close="selectedContactPlatform = null;"
    />

    <TheNewAddPeoplePanel
      v-if="isAddPeoplePanelOpen"
      @close="isAddPeoplePanelOpen = false;"
    />

    <TheManageCRMListPanel
      v-if="isManageCRMListPanelOpen"
      @close="isManageCRMListPanelOpen = false"
    />

    <div
      v-if="isBackgroundLocked"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';
import templatizeMixin from '@dailyplanet/cross-addons/filters/_mixins/templatize.mixin';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';

export default {
  name: 'TheCRM',
  mixins: [TableUtilsMixin, templatizeMixin],
  data() {
    return {
      // table
      detailKey: 'cpid',
      page: 1,
      perPage: 50,
      sortField: 'contactPlatformStatistic.contactPlatformCpid',
      sortOrder: 'desc',
      checkedRows: [],
      loading: false,
      selected: null,

      // global
      searchByUsername: '',
      selectedContactPlatform: null,
      filters: [],
      allowedFilters: [
        'hasCrmLists',
        'contact.publicEmail',
        'contactPlatformStatistic.hasThread',
        'contact.language',
        'contactPlatform.username',
        'contactPlatform.followerCount',
        'contactPlatform.engagement',
        'hasThreadGroup',
        'hasContactGroup',
        'tribeMemberList.tribeTid',
        'lastActivity.campaignCid',
        'contactPlatformStatistic.totalMentions',
        'contactPlatformStatistic.totalPosts',
        'contactPlatformStatistic.totalStories',
        'contactPlatformStatistic.totalRevenuesUsd',
        'contactPlatformStatistic.totalOrders',
        'contactPlatformStatistic.totalCoupons',
        'contactPlatformStatistic.lastMentionOn',
        'contactPlatformStatistic.lastCampaignOn',
        'contactPlatformStatistic.lastSaleOn',
        'crewUser.state',
      ],
      nonDisplayableFilters: [
        'contactPlatform.username',
      ],

      // segmentFilter
      selectedSegmentFilter: null,

      // panels
      bulkIsOpen: false,
      isAddPeoplePanelOpen: false,
      isManageCRMListPanelOpen: false,

      /* eslint-disable object-curly-newline */
      /* eslint-disable key-spacing */
      /* eslint-disable no-multi-spaces */
      columns: {
        'contactPlatform.platformName':              { cmp: 'UbuTablePlatform',         field: 'contactPlatform.platformName',              label: 'Platform',          isSortable: false, visible: true },
        'contactPlatform.username':                  { cmp: 'UbuTableProfile',          field: 'contactPlatform.username',                  label: 'Username',          isSortable: true,  visible: true },
        'contactPlatform.followerCount':             { cmp: 'UbuTableNumber',           field: 'contactPlatform.followerCount',             label: 'Followers',         isSortable: true,  visible: true },
        'contactPlatform.engagement':                { cmp: 'UbuTableNumber',           field: 'contactPlatform.engagement',                label: 'Engagement',        isSortable: true,  visible: true,  after: '%' },
        'contact.language':                          { cmp: 'UbuTableSimple',           field: 'contact.language',                          label: 'Language',          isSortable: false, visible: true },
        'contactPlatformStatistic.totalMentions':    { cmp: 'UbuTableNumber',           field: 'contactPlatformStatistic.totalMentions',    label: 'Mentions',          isSortable: true,  visible: true },
        'contactPlatformStatistic.totalRevenuesUsd': { cmp: 'UbuTableNumber',           field: 'contactPlatformStatistic.totalRevenuesUsd', label: 'Revenue',           isSortable: true,  visible: true,  before: '$' },
        tribes:                                      { cmp: 'UbuTableChipTribe',        field: 'tribes',                                    label: 'Tribes',            isSortable: false, visible: true },
        labels:                                      { cmp: 'UbuTableChipLabel',        field: 'labels',                                    label: 'Labels',            isSortable: false, visible: true },
        crmLists:                                    { cmp: 'UbuTableChipCrmList',      field: 'crmLists',                                  label: 'Lists',             isSortable: false, visible: true },
        lastActivity:                                { cmp: 'UbuTableChipLastCampaign', field: 'lastActivity',                              label: 'Active campaign',   isSortable: false, visible: true },
        'crewUser.state':                            { cmp: 'UbuTableCrewState',        field: 'crewUser.state',                            label: 'Crew state',        isSortable: false, visible: true },
        'contactPlatformStatistic.lastCampaignOn':   { cmp: 'UbuTableDate',             field: 'contactPlatformStatistic.lastCampaignOn',   label: 'Last campaign add', isSortable: true,  visible: true },
        'contactPlatformStatistic.lastMentionOn':    { cmp: 'UbuTableDate',             field: 'contactPlatformStatistic.lastMentionOn',    label: 'Last mention',      isSortable: true,  visible: true },
        'contactPlatformStatistic.lastMessageOn':    { cmp: 'UbuTableDate',             field: 'contactPlatformStatistic.lastMessageOn',    label: 'Last message',      isSortable: true,  visible: true },
        'contactPlatformStatistic.lastSaleOn':       { cmp: 'UbuTableDate',             field: 'contactPlatformStatistic.lastSaleOn',       label: 'Last sale',         isSortable: true,  visible: true },
        'contact.publicEmail':                       { cmp: 'UbuTableSimple',           field: 'contact.publicEmail',                       label: 'Public Email',      isSortable: true,  visible: true },
      },
      /* eslint-enable key-spacing */
      /* eslint-enable no-multi-spaces */
      /* eslint-enable object-curly-newline */
    };
  },
  computed: {
    ...mapGetters({
      contactPlatformOverviewList: 'TheCRM/contactPlatformOverviewList',
      totalPeople: 'TheCRM/totalPeople',

      _tagList: 'TheCRM/threadGroupList',
      _labelList: 'TheCRM/contactGroupList',
      _campaignList: 'TheCRM/campaignList',
      _crmListList: 'TheCRM/crmListList',
      _tribeList: 'TheCRM/tribeList',
      _memberGroupList: 'TheCRM/memberGroupList',
    }),

    isBackgroundLocked() {
      if ((this.bulkIsOpen && this.checkedRows.length) || this.isAddPeoplePanelOpen) return true;
      return false;
    },

    filtersMapped() {
      return this.filters.arrayMapper('id');
    },

    tableDataList() {
      return Object.values(this.contactPlatformOverviewList);
    },

    filtersCatalog() {
      return Object.values(this.genericCatalog)
        .filter(({ key }) => this.allowedFilters.includes(key));
    },
  },
  watch: {
    filters() { return this.reportDatas(); },
  },
  beforeMount() {
    return this._resetContactPlatformOverviewList();
  },
  mounted() {
    return Promise.resolve()
      .then(() => this.resetContactPlatformOverviewList())
      .then(() => this.reportDatas())
      .then(() => {
        // eslint-disable-next-line prefer-destructuring
        this.selected = this.tableDataList[0] || null;
        if (this.$refs && this.$refs.table) this.$refs.table.focus();
      });
  },
  methods: {
    ...mapActions({
      _resetContactPlatformOverviewList: 'ApiContact/contactPlatformOverview/resetContactPlatformOverviewList',
      reportContactPlatformOverviewList: 'TheCRM/reportContactPlatformOverviewList',
      setCurrentContactPlatformOverview: 'TheCRM/setCurrentContactPlatformOverview',

      resetContactPlatformOverviewList: 'ApiContact/contactPlatformOverview/resetContactPlatformOverviewList',
    }),

    reportDatas() {
      this.loading = true;

      const filtersToUse = [...this.filters];

      return Promise.resolve()
        .then(() => this.resetContactPlatformOverviewList())
        .then(() => this.reportContactPlatformOverviewList({
          httpQuery: {
            pagin: `${this.page - 1},${this.perPage}`,
            sorting: `${this.sortField},${this.sortOrder}`,
            filters: this.prepareFiltersToRequest(this.allowedFilters, filtersToUse),
          },
        }))
        .then(() => { this.loading = false; });
    },

    closeBulkAndRefresh() {
      this.bulkIsOpen = false;
      this.checkedRows = [];

      this.reportDatas();
    },

    openContactPlatformPanel(cpid) {
      this.selectedContactPlatform = cpid;
      this.setCurrentContactPlatformOverview(cpid);
    },

    deleteFilter(event) {
      const filters = this.filters.filter(({ id }) => id !== event);
      this.filters = filters;
    },

    deleteAllFilters() {
      this.filters = [];
      this.selectedSegmentFilter = null;
    },

    addSearchbarFilter() {
      const filters = this.filters.arrayMapper('key');
      delete filters['contactPlatform.username'];
      this.filters = Object.values(filters).concat({ key: 'contactPlatform.username', values: [this.searchByUsername] });
      // this.reportDatas();
    },

    clearSearchbar() {
      this.searchByUsername = '';
      this.filters = this.filters.filter(({ key }) => key !== 'contactPlatform.username');
      // this.reportDatas();
    },

    keyupThreadListHandler(event) {
      const {
        ctrlIsPressed,
        enterIsPressed,
        escapeIsPressed,
      } = this.parseEvent(event);

      if (enterIsPressed && ctrlIsPressed) {
        const index = this.checkedRows
          .findIndex((row) => row[this.detailKey] === this.selected[this.detailKey]);
        if (index >= 0) {
          this.checkedRows.splice(index, 1);
          return;
        }
        // make this to dont push more than one twice the same row
        const toCheck = {
          ...this.checkedRows.arrayMapper('cpid'),
          [this.selected.cpid]: this.selected,
        };

        this.checkedRows = Object.values(toCheck);
        return;
      }
      if (enterIsPressed && !ctrlIsPressed) {
        this.openContactPlatformPanel(this.selected.contactPlatform.cpid);
      }

      if (escapeIsPressed) {
        this.selectedContactPlatform = null;
      }
    },

    handleFilterUpdate(filter) {
      // replace old filter by new & redefine filters array
      const newFilters = {
        ...this.filters.arrayMapper('id'),
        [filter.id]: filter,
      };

      this.filters = Object.values(newFilters);
    },
  },
};
</script>
