<template>
  <b-tab-item value="root.create">
    <b-dropdown-item custom>
      <UbuColorPickerInline
        v-model="custom.color"
        class="mb-2"
        @input="$update('color', String($event))"
      />

      <b-button
        :label="label"
        type="is-primary"
        :disabled="!custom.color"
        expanded
        @click.stop="$emit('create')"
      />
    </b-dropdown-item>
  </b-tab-item>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuDropdownCustomCreator',
  mixins: [VModelMixin],
  model: {
    prop: 'custom',
  },
  props: {
    custom: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
