<template>
  <!-- eslint-disable max-len -->
  <div
    class="ubuCRMFilter"
    grouped
  >
    <DevDetails :cnt="{name: 'localHttpQuery', obj: httpQuery}" />
    <template
      v-for="{ 
        key,
        label,
        col,
        icon,
        list,
        itemName, 
        type,
        iconNested = false, 
        step = 1,
        canBeNegative = false,
        searchableKey = null,
      } in dropdownsSelectors"
    >
      <p
        v-if="type === 'list'"
        :key="col"
        class="control filter-button"
      >
        <DevDetails :cnt="{name: 'localHttpQuery[col]', obj: localHttpQuery[col]}" />
        <UbuCRMFilterCheckbox
          v-model="localHttpQuery[col]"
          :key-name="key"
          :searchableKey="searchableKey"
          :label="label"
          :icon="icon"
          :list="list"
          :item-name="itemName"
          :type="type"
          :disabled="disabled"
          :icon-nested="iconNested"
          @input="$update(col, $event)"
          @reset="$update(col, [])"
        />
      </p>

      <p
        v-if="type === 'numberRange' && localHttpQuery[col]"
        :key="col"
        class="control filter-button"
      >
        <UbuCRMFilterNumberRange
          v-model="localHttpQuery[col]"
          :col-detail="{ label, col }"
          :is-loading="isLoading"
          :step="step"
          :disabled="disabled"
          :can-be-negative="canBeNegative"
          @input="$update(col, $event)"
          @reset="$update(col, [])"
        />
      </p>

      <p
        v-if="type === 'dateRange'"
        :key="col"
        class="control filter-button"
      >
        <UbuCRMFilterDateRange
          v-model="localHttpQuery[col]"
          :col-detail="{ label, col }"
          :is-loading="isLoading"
          :disabled="disabled"
          @input="$update(col, $event)"
          @reset="$update(col, [])"
        />
      </p>

      <p
        v-if="type === 'radio'"
        :key="col"
        class="control filter-button"
      >
        <UbuCRMFilterRadio
          v-model="localHttpQuery[col]"
          :col-detail="{ label, col }"
          :is-loading="isLoading"
          :list="list"
          :disabled="disabled"
          @input="$update(col, $event)"
          @reset="$update(col, '')"
        />
      </p>
    </template>
  </div>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuCRMFilter',
  mixins: [VModelMixin],
  model: {
    prop: 'httpQuery',
  },
  props: {
    disabled: { type: Boolean, default: false },
    dropdownsSelectors: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    httpQuery: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localHttpQuery: {
      get() { return { ...this.httpQuery }; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
};
</script>
