<template>
  <b-tooltip
    label="Invite to Crew"
    position="is-top"
  >
    <b-button
      icon-left="ubu-love"
      icon-pack="ubu"
      type="is-tertiary-icon"
      @click="invite()"
    />
  </b-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackbarSuccess, snackbarError } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheTextareaCrewInviteButton',
  props: {
    cpid: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      _currentBrand: 'TheSettingWorkspaceSetting/currentBrand',
      accountFeatures: 'TheInbox/accountFeatures',
    }),
  },
  methods: {
    ...mapActions({
      _inviteCrewUser: 'TheTextarea/inviteCrewUser',
    }),

    invite() {
      if (!this.accountFeatures('CREW')) {
        return this.$buefy.snackbar.open({
          ...snackbarError,
          message: 'This feature will coming soon. Contact support to have more informations',
        });
      }

      if (!this._currentBrand) {
        return this.$buefy.snackbar.open({
          message: 'You must create your brand to use this feature.',
          position: 'is-top',
          duration: 8000,
          type: 'is-warning',
          actionText: 'Create my Brand!',
          onAction: () => { this.$router.push('/crew/crewSetting'); },
        });
      }

      return this._inviteCrewUser({ payload: { contactPlatformCpid: this.cpid } })
        .then((token) => {
          const link = `${process.env.VUE_APP_TALIA_BASE_URL}/signup/${token}`;
          // navigator.clipboard.writeText(link);

          // this.$buefy.snackbar.open({
          //   message: 'Success! Link copied to clipboard.',
          //   ...snackbarSuccess,
          // });

          this.$emit('insertText', link);
        });
    },
  },
};
</script>
