<template>
  <section>
    <div class="p-2 multibar row-vcentered">
      <UbuDateRangePicker
        class="mr-2"
        :max-date="maxDate"
        :option-date-to-use="optionDateToUse"
        @changeDate="changeDate($event)"
      />

      <b-button
        v-for="(tab, index) in tabs"
        :key="index"
        class=""
        tag="router-link"
        type="is-simple"
        :to="tab.to"
        :active="$route.path.includes(tab.to)"
        icon-pack="ubu"
        :icon-left="tab.icon"
        :label="tab.label"
      />
    </div>

    <br>

    <router-view v-model="httpQuery" />
  </section>
</template>

<script>
/* eslint-disable max-len */

export default {
  name: 'TheTribeAnalytics',
  data() {
    return {
      tabs: [
        {
          label: 'Members', icon: 'ubu-tribe', to: `/tribe/${this.$route.params.tribeTid}/analytics/members`,
        },
        {
          label: 'Mentions', icon: 'ubu-mentions', to: `/tribe/${this.$route.params.tribeTid}/analytics/mentions`,
        },
        {
          label: 'Sales', icon: 'ubu-shop', to: `/tribe/${this.$route.params.tribeTid}/analytics/sales`,
        },
      ],
      maxDate: this.$moment().toDate(),
      optionDateToUse: ['currentWeek', 'previousWeek', 'currentMonth', 'previousMonth'],
      httpQuery: {
        createdOn: [this.$moment().subtract({ days: 7 }).startOf('day').format('YYYY-MM-DD HH:mm:ss'), this.$moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')],
      },
    };
  },
  methods: {
    changeDate(dateRange) {
      const [min, max] = dateRange.value;
      this.httpQuery.createdOn = `${this.$moment(min).startOf('day').format('YYYY-MM-DD HH:mm:ss')},${this.$moment(max).endOf('day').format('YYYY-MM-DD HH:mm:ss')}`;
    },
  },
};
</script>
