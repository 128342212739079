import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    theme: localStorage.getItem('ubuTheme') || 'AUTO',
  },
  getters: {
    getterTheme: (state) => state.theme,
  },
  actions: {
    setTheme({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'USER.SET_THEME')) return undefined;

      if (!['LIGHT', 'DARK', 'AUTO'].includes(payload.theme)) return undefined;

      const { uid } = rootGetters['Authenticate/getterAuthenticate'];

      return clark.api.user.setTheme.post({ payload: { ...payload, uid }, httpQuery: {} })
        .then(() => {
          commit('SET_THEME', payload.theme);
          localStorage.setItem('ubuTheme', payload.theme);
        });
    },
    setDefaultChannelGroup({ rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'SET_DEFAULT_CHANNEL_GROUP.PATCH')) return undefined;

      const { uid } = rootGetters['Authenticate/getterAuthenticate'];

      return clark.api.user.setDefaultChannelGroup
        .post({ payload: { ...payload, uid }, httpQuery });
    },
  },
  mutations: {
    SET_THEME(state, theme) { state.theme = theme; },
  },
};
