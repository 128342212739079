import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

/* eslint-disable max-len */
export default {
  namespaced: true,
  getters: {
    ...getters.account,
    ...getters.authenticate,
    ...getters.channelGroup,
    // ...getters.shopify,
    ...getters.shop,
    ...getters.threadGroup,
    ...getters.contactGroup,
    ...getters.template,
    ...getters.templateFolder,
    ...getters.campaign,
    ...getters.contact,
    ...getters.channel,

    ...getters.shop,
    ...getters.affiliationCode,
    ...getters.affiliationCodeFolder,
    ...getters.downloadTask,
    ...getters.brand,
  },
  actions: {
    ...actions.account,
    ...actions.channelGroup,
    // ...actions.shopify,
    ...actions.shop,
    ...actions.threadGroup,
    ...actions.contactGroup,
    ...actions.template,
    ...actions.templateFolder,
    ...actions.campaign,
    ...actions.contact,
    ...actions.channel,
    ...actions.segment,

    ...actions.shop,
    ...actions.affiliationCode,
    ...actions.downloadTask,
    ...actions.brand,
  },
};
