import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

/* eslint-disable max-len */
export default {
  getters: {
    tokenLink: (_state, _getters, _rootState, rootGetters) => {
      const { 'TokenLink/getterTokenLink': tokenLink } = rootGetters;

      return tokenLink;
    },
  },
  actions: {
    getTokenLink({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'TokenLink/get',
        context: {
          payload,
          httpQuery,
        },
        textNotifier: 'Token link successfully fetched.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          let message = 'Unable to get link informations.';
          if (e.message === 'E_NOT_FOUND_ENTITIE') message = 'This link does not exists.';
          if (e.message === 'E_ALREADY_USED_ENTITIE') message = 'This link has expired.';
          Snackbar.open({
            message,
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
  },
};
