/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

import LinkChannel from './Link/Channel';
import LinkChannelGroup from './Link/ChannelGroup';
import UnlinkChannel from './Unlink/Channel';
import UnlinkChannelGroup from './Unlink/ChannelGroup';
import facebookConnect from './facebookConnect.store';
import accountMemberView from './accountMemberView.store';
import setAdmin from './setAdmin';
import setFeature from './setFeature';
import changeState from './changeState';

export default {
  namespaced: true,
  state: {
    account: null,
    accountList: null,
    accountEvent: [],

    // DEPRECATED, on va utiliser accountFeatures à la place
    accountFeature: [],

    accountFeatures: {
      INBOX: false,
      CAMPAIGNS: false,
      MENTIONS: false,
      HASHTAGS: false,
      SHOPIFY: false,
      TRIBE: false,
      CRM: false,
      ACTIVITY: false,
      ANALYTICS: false,
      UBU_META_EXTENSION: false,
      COMMENTS: false,
      CREW: false,
    },
    isPrivateDisconnected: false,
  },
  mutations: {
    SET_ACCOUNT(state, newData) { state.account = newData; },
    SET_ACCOUNT_LIST(state, newData) { state.accountList = newData; },
    SET_IS_DISCONNECTED_TO_PRIVATE(state, newData) { state.isPrivateDisconnected = newData; },
    RESET_STORE(state) {
      state.account = null;
      state.accountList = null;
      state.accountEvent = [];
      state.accountFeature = [];
    },
    SET_ACCOUNT_EVENT(state, newData) { state.accountEvent = newData; },

    // DEPRECATED, on va utiliser SET_ACCOUNT_FEATURES à la place
    SET_ACCOUNT_FEATURE(state, newData) { state.accountFeature = newData; },

    SET_ACCOUNT_FEATURES(state, newData) { state.accountFeatures = { ...state.accountFeatures, ...newData }; },
  },
  getters: {
    getterAccount: (state) => state.account,
    getterAccountList: (state) => state.accountList,
    getterAccountChannelGroupList: (state) => {
      if (!state.accountList) return null;
      return Object.values(state.accountList).reduce((acc, account) => {
        account.channelGroupList.forEach((channelGroup) => {
          acc[channelGroup.cgid] = {
            channelGroup,
            account,
          };
        });
        return acc;
      }, {});
    },
    getterAccountChannelList: (state) => {
      if (!state.accountList) return null;
      return Object.values(state.accountList).reduce((acc, account) => {
        account.channelGroupList.forEach((channelGroup) => {
          channelGroup.channelList.forEach((channel) => {
            acc[channel.cid] = {
              channel,
              account,
            };
          });
        });
        return acc;
      }, {});
    },
    getterAccountEvent: (state) => state.accountEvent,

    // DEPRECATED, on va utiliser getterAccountFeatures à la place
    getterAccountFeature: (state) => state.accountFeature,

    getterAccountFeatures: (state) => state.accountFeatures,
    getterIsDisconnectedToPrivate: (state) => state.isPrivateDisconnected,
  },
  actions: {

    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'ACCOUNT.REPORT')) return undefined;

      return clark.api.account.report({ payload, httpQuery })
        .then((accountList) => {
          commit('SET_ACCOUNT_LIST', accountList.arrayMapper('aid'));
        });
    },

    up_isDisconnectedToPrivate({ commit }, channel) {
      const noAvailableToken = !channel.token;

      commit('SET_IS_DISCONNECTED_TO_PRIVATE', noAvailableToken);
    },

    up_connectedToPrivate({ dispatch, getters } /* channel */) {
      /**
       * Used to refresh account after socket event 'connectPrivate'
       */
      const { getterAccount } = getters;

      return dispatch('get', {
        payload: { aid: getterAccount.aid },
        httpQuery: {
          channelAndChannelGroupList: true,
          accountMembers: true,
          accountEvent: true,
          hasFeature: true,
        },
      });
    },

    get({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'ACCOUNT.GET')) return undefined;

      return clark.api.account.get({ payload, httpQuery })
        .then((account) => {
          commit('SET_ACCOUNT', account);

          if (account.userList) commit('User/SET_USER_LIST', account.userList.arrayMapper('uid'), { root: true });
          if (account.hasEvent) commit('SET_ACCOUNT_EVENT', account.hasEvent);

          // DEPRECATED, on va utiliser SET_ACCOUNT_FEATURES à la place
          if (account.hasFeature) commit('SET_ACCOUNT_FEATURE', account.hasFeature);

          if (account.hasFeature) {
            commit('SET_ACCOUNT_FEATURES', account.hasFeature.reduce((acc, row) => {
              if (!acc[row.featureName]) acc[row.featureName] = true;
              return acc;
            }, {}));
          }

          const { getterAccountFeatures } = getters;

          const hasFeaturePrivateExtension = getterAccountFeatures.UBU_META_EXTENSION;

          // if account doesnt have feature set is deco to true
          if (!hasFeaturePrivateExtension) return commit('SET_IS_DISCONNECTED_TO_PRIVATE', true);

          const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

          const channelGroup = account.channelGroupList.find(({ cgid }) => cgid === currentChannelGroup.cgid);

          if (channelGroup) {
            const instaChannel = channelGroup.channelList.find(({ platformName }) => platformName === 'INSTAGRAM');

            if (!instaChannel || instaChannel.token) commit('SET_IS_DISCONNECTED_TO_PRIVATE', false);
            else commit('SET_IS_DISCONNECTED_TO_PRIVATE', true);
          }

          return undefined;
        });
    },

    create({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'ACCOUNT.CREATE')) return undefined;
      const accountList = getters.getterAccountList;

      return clark.api.account.post({ payload, httpQuery })
        .then(({ newData }) => {
          commit('SET_ACCOUNT_LIST', { ...accountList, [newData.aid]: newData });
          return newData;
        });
    },

    delete({ rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'ACCOUNT.DELETE')) return undefined;

      return clark.api.account.delete({ payload, httpQuery })
        .then(() => true);
    },
  },
  modules: {
    LinkChannel,
    UnlinkChannel,
    LinkChannelGroup,
    UnlinkChannelGroup,
    facebookConnect,
    accountMemberView,
    setAdmin,
    setFeature,
    changeState,
  },
};
