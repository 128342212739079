<template>
  <TheTabsPanel
    v-model="activeTab"
    @goToPreviousTab="goToPreviousTab()"
    @goToStart="goToTabByTabPath('root')"
    @close="closePanel()"
  >
    <template v-slot:content>
      <b-tab-item value="root">
        <article>
          <div class="buttons">
            <UbuActionCard
              v-for="item in navigableItems"
              :key="item.label"
              class="mb-4"
              :label="item.label"
              icon-pack="ubu"
              :icon-left="item.icon"
              expanded
              :disabled="item.isDisabled"
              @click="goToTabByTabPath(item.to)"
            />
          </div>
        </article>
      </b-tab-item>

      <b-tab-item value="root.importFromLabel">
        <div class="buttons">
          <UbuActionCard
            v-for="item in Object.values(contactGroupList)
              .sort((a, b) => $moment(b.createdOn).unix() - $moment(a.createdOn).unix())"
            :key="item.cgid"
            :class="`mb-4 has-text-color-${item.color}`"
            :label="item.label"
            icon-pack="ubu"
            icon-left="ubu-custom-contact"
            expanded
            @click="selectAndContinue(
              item.cgid, 'CONTACT_GROUP', `root.importFromLabel`)"
          />
        </div>
      </b-tab-item>

      <b-tab-item value="root.importFromTag">
        <div class="buttons">
          <UbuActionCard
            v-for="item in Object.values(threadGroupList)
              .sort((a, b) => $moment(b.createdOn).unix() - $moment(a.createdOn).unix())"
            :key="item.tgid"
            :class="`mb-4 has-text-color-${item.color}`"
            :label="item.label"
            icon-pack="ubu"
            icon-left="ubu-custom-tag"
            expanded
            @click="selectAndContinue(
              item.tgid, 'THREAD_GROUP', `root.importFromTag`)"
          />
        </div>
      </b-tab-item>

      <b-tab-item value="root.importFromCampaign">
        <div class="buttons">
          <UbuActionCard
            v-for="item in Object.values(campaignList)
              .sort((a, b) => $moment(b.createdOn).unix() - $moment(a.createdOn).unix())"
            :key="item.cid"
            :class="`mb-4 has-text-color-${item.color}`"
            :label="item.name"
            icon-pack="ubu"
            icon-left="ubu-custom-influence"
            expanded
            @click="selectAndContinue(
              item.cid, 'CAMPAIGN', `root.importFromCampaign`)"
          />
        </div>
      </b-tab-item>

      <b-tab-item
        v-for="type in Object.keys(tabItems).filter((name) => name.includes('selectGroup'))"
        :key="type"
        :value="type"
      >
        <div class="buttons">
          <UbuActionCard
            class="mb-4"
            label="Manage group later"
            expanded
            @click="finalize(null)"
          />

          <UbuActionCard
            v-for="item in memberGroupList"
            :key="item.mgid"
            :class="`mb-4 has-text-color-${item.color}`"
            :label="item.name"
            icon-pack="ubu"
            icon-left="ubu-folder"
            expanded
            @click="finalize(item.mgid)"
          />
        </div>
      </b-tab-item>

      <b-tab-item value="root.done">
        <b-message
          v-if="canDisplaySuccess"
          type="is-success"
        >
          <div class="multibar row-vcentered">
            <b-icon
              pack="ubu"
              icon="ubu-success"
              size="is-medium"
              class="start"
            />
            <span class="start">
              Success! People are added to your Tribe.
            </span>
          </div>
        </b-message>
        <b-message
          v-if="errorWhileWizard"
          type="is-danger"
        >
          <p class="text is-3">
            {{ errorWhileWizard }}
          </p>

          <div class="multibar row-vcentered mt-2 mb-2">
            <b-button
              type="is-ghost"
              class="start"
              label="Download"
              @click="downloadPayload()"
            />
          </div>
        </b-message>
      </b-tab-item>
    </template>
  </TheTabsPanel>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import download from 'downloadjs';

export default {
  name: 'TheTribePeopleAddPeoplePanel',
  data() {
    return {
      canDisplaySuccess: null,
      errorWhileWizard: null,
      activeTab: {
        tab: 'root',
        title: 'How do you want to add people?',
        subtext: 'Add people',
      },
      visitedTabList: ['root'],
      payload: {},
      tabItems: {
        root: {
          tab: 'root',
          title: 'How do you want to add people?',
          subtext: 'Add people',
        },
        'root.importFromLabel': {
          tab: 'root.importFromLabel',
          title: 'Select a label',
          subtext: 'Add people | Import from label',
        },
        'root.importFromTag': {
          tab: 'root.importFromTag',
          title: 'Select a tag',
          subtext: 'Add people | Import from tag',
        },
        'root.importFromCampaign': {
          tab: 'root.importFromCampaign',
          title: 'Select a campaign',
          subtext: 'Add people | Import from campaign',
        },
        'root.importFromLabel.selectGroup': {
          tab: 'root.importFromLabel.selectGroup',
          title: 'What group do you want to add them to?',
          subtext: 'Add people | Import from label',
        },
        'root.importFromTag.selectGroup': {
          tab: 'root.importFromTag.selectGroup',
          title: 'What group do you want to add them to?',
          subtext: 'Add people | Import from tag',
        },
        'root.importFromCampaign.selectGroup': {
          tab: 'root.importFromCampaign.selectGroup',
          title: 'What group do you want to add them to?',
          subtext: 'Add people | Import from campaign',
        },
        'root.done': {
          tab: 'root.done',
          title: 'Summary',
          subtext: 'Add people',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      campaignList: 'TheTribe/campaignList',
      threadGroupList: 'TheTribe/threadGroupList',
      contactGroupList: 'TheTribe/contactGroupList',
      memberGroupList: 'TheTribe/memberGroupList',
    }),
    navigableItems() {
      return {
        'root.importFromLabel': {
          to: 'root.importFromLabel',
          icon: 'ubu-contact',
          label: 'Import from label',
        },
        'root.importFromTag': {
          to: 'root.importFromTag',
          icon: 'ubu-tag',
          label: 'Import from tag',
        },
        'root.importFromCampaign': {
          to: 'root.importFromCampaign',
          icon: 'ubu-influence',
          label: 'Import from campaign',
        },
      };
    },
  },
  methods: {
    ...mapActions({
      addFromCustom: 'TheTribe/addFromCustom',
      UP_contactPlatformOverviewList: 'TheTribe/UP_contactPlatformOverviewList',
    }),

    closePanel() {
      this.$emit('close');
    },
    selectAndContinue(id, type, previousPath) {
      this.payload = { type, id };
      this.activeTab = this.tabItems[`${previousPath}.selectGroup`];
    },
    finalize(mgid) {
      this.payload = { ...this.payload, mgid, tid: this.$route.params.tribeTid };
      this.activeTab = this.tabItems['root.done'];

      this.addFromCustom({
        payload: this.payload,
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_createTribeMember',
          payload: {
            cpids: [this.payload.cpid],
          },
        }],
      })
        .then(() => {
          this.canDisplaySuccess = true;
        })
        .catch((error) => {
          this.errorWhileWizard = error;
        });
    },
    goToPreviousTab() {
      if (this.activeTab.tab === 'root') return;
      const tabToGo = this.activeTab.tab.split('.').slice(0, -1).join('.');
      this.activeTab = this.tabItems[tabToGo];
    },
    goToPreviousVisitedTab() {
      if (this.activeTab.tab === 'root') return;

      const lastTabVisitedIsRoot = this.visitedTabList.length === 1;
      // si on a plus que 'root' dans notre array on prend le dernier et on le retire
      // si on a que 'root' on le garde dans l'array et on redirect dessus
      const tabToGo = lastTabVisitedIsRoot ? 'root' : this.visitedTabList.pop();

      this.activeTab = this.tabItems[tabToGo];
    },
    goToTabByTabPath(tabToGo) {
      this.activeTab = this.tabItems[tabToGo];
    },
    downloadPayload() {
      download(JSON.stringify(this.payload, null, 2), 'ubu-addPeopleToTribe.json', 'text/plain');
    },
  },
};
</script>
