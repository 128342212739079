var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('b-notification',{staticClass:"UbuNotification",attrs:{"closable":false,"type":_vm.type},model:{value:(_vm.notification),callback:function ($$v) {_vm.notification=$$v},expression:"notification"}},[_c('div',{staticClass:"content"},[(_vm.tag)?_c('b-taglist',[_c('b-tag',{attrs:{"type":_vm.tagType}},[_vm._v(" "+_vm._s(_vm.tagLabel)+" ")])],1):_vm._e(),(_vm.icon)?_c('b-icon',{staticClass:"mr-3",attrs:{"pack":"ubu","icon":_vm.icon,"size":"is-large"}}):_vm._e(),_c('p',{class:[{
          'has-text-primary': _vm.contentType === 'primary',
          'has-text-secondary': _vm.contentType === 'secondary',
          'has-text-danger': _vm.contentType === 'danger',
          'has-text-success': _vm.contentType === 'success',
          'has-text-warning': _vm.contentType === 'warning',
        }]},[_vm._v(" "+_vm._s(_vm.content)+" "),(_vm.iconLeft)?_c('b-button',{attrs:{"label":"What is a Tribe ?","tag":"router-link","to":"","type":"is-ghost","icon-pack":"ubu","icon-left":_vm.iconLeft}}):_vm._e(),(_vm.iconRight)?_c('b-button',{attrs:{"label":"What is a Tribe ?","tag":"router-link","to":"","type":"is-ghost","icon-pack":"ubu","icon-right":_vm.iconRight}}):_vm._e()],1)],1),(_vm.close)?_c('div',{staticClass:"close"},[_c('b-button',{attrs:{"type":"is-tertiary","icon-pack":"ubu","icon-left":"ubu-cross"},on:{"click":function($event){return _vm.$emit('close')}}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }