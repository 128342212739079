// import Vue from 'vue';
import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuIconCircleStatus from './UbuIconCircleStatus';
import UbuIconCircleState from './UbuIconCircleState';
import UbuSingleInput from './UbuSingleInput';
import UbuSingleTextarea from './UbuSingleTextarea';
import UbuCustomChip from './UbuCustomChip';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuIconCircleStatus);
    registerComponent(Vue, UbuIconCircleState);
    registerComponent(Vue, UbuSingleInput);
    registerComponent(Vue, UbuSingleTextarea);
    registerComponent(Vue, UbuCustomChip);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuIconCircleStatus,
  UbuIconCircleState,
  UbuSingleInput,
  UbuSingleTextarea,
  UbuCustomChip,
};
