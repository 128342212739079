<template>
  <section>
    <TheTabsPanel
      v-model="activeTab"
      @goToPreviousTab="activeTab = tabItems.root"
      @close="$emit('close')"
    >
      <template v-slot:content>
        <b-tab-item value="root">
          <div class="multibar mb-6">
            <UbuSearchbar
              v-model="search"
              placeholder="Search or create ..."
              class="start mr-2 w-100"
              @clear="search = ''"
            />

            <b-button
              label="Create"
              type="is-primary"
              icon-pack="ubu"
              icon-left="ubu-add"
              :disabled="!search.length"
              @click="createTemplateFolder()"
            />
          </div>
          <p
            v-if="!filteredTemplateFolderList.length"
            class="has-text-secondary"
          >
            No folders... Yet!
            <br>
            Create folders to better organize your templates.
          </p>

          <div
            v-else
            class="buttons"
          >
            <UbuActionCard
              v-for="templateFolder in filteredTemplateFolderList"
              :key="templateFolder.tfid"
              class="mb-4"
              :label="templateFolder.label"
              left-text="Click to edit"
              expanded
              @click="selectFolder(templateFolder)"
            />
          </div>
        </b-tab-item>

        <b-tab-item value="root.editTemplateFolder">
          <div v-if="selectedTemplateFolder && activeTab.tab === 'root.editTemplateFolder'">
            <b-field>
              <b-field class="mr-2 w-100">
                <b-input
                  v-model="selectedTemplateFolder.label"
                  class="is-classic"
                  expanded
                />
              </b-field>

              <div class="buttons no-wrap">
                <b-button
                  type="is-secondary"
                  icon-pack="ubu"
                  icon-left="ubu-trash"
                  label="Delete"
                  @click="deleteTemplateFolder()"
                />

                <b-button
                  type="is-primary"
                  label="Save"
                  :disabled="!canUpdate"
                  @click="updateTemplateFolder()"
                />
              </div>
            </b-field>
          </div>
        </b-tab-item>
      </template>
    </TheTabsPanel>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSettingTemplateFolderPanel',
  data() {
    return {
      search: '',
      selectedTemplateFolder: null,
      activeTab: {
        tab: 'root',
        title: 'Manage folder',
        subtext: 'Folder',
      },
      tabItems: {
        root: {
          tab: 'root',
          title: 'Manage folder',
          subtext: 'Folder',
        },
        'root.editTemplateFolder': {
          tab: 'root.editTemplateFolder',
          title: 'Edit folder',
          subtext: 'Folder',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      templateFolderList: 'TheSettingWorkspaceSetting/templateFolderList',
    }),

    filteredTemplateFolderList() {
      const templateFolderList = Object.values(this.templateFolderList);
      if (!this.search) return templateFolderList;
      return templateFolderList.filter(({ label }) => label.includes(this.search));
    },

    canUpdate() {
      if (!this.selectedTemplateFolder) return false;
      if (!this.templateFolderList[this.selectedTemplateFolder.tfid]) return false;
      const { label } = this.selectedTemplateFolder;
      if (label !== this.templateFolderList[this.selectedTemplateFolder.tfid].label) return true;
      return false;
    },
  },
  methods: {
    ...mapActions({
      _createTemplateFolder: 'TheSettingWorkspaceSetting/createTemplateFolder',
      _updateTemplateFolder: 'TheSettingWorkspaceSetting/updateTemplateFolder',
      _deleteTemplateFolder: 'TheSettingWorkspaceSetting/deleteTemplateFolder',
    }),

    selectFolder(templateFolder) {
      this.selectedTemplateFolder = { ...templateFolder };
      this.activeTab = this.tabItems['root.editTemplateFolder'];
    },

    createTemplateFolder() {
      if (!this.search || !this.search.trim()) return;

      this._createTemplateFolder({
        payload: {
          color: 0,
          label: this.search.trim(),
        },
      })
        .then(() => { this.search = ''; });
    },

    deleteTemplateFolder() {
      return this.$buefy.dialog.confirm({
        title: 'You are about to delete a folder',
        message: 'Are you sure you want to <b>delete</b> this folder ?<br> <b>Templates inside this folder WONT be deleted.</b>',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this._deleteTemplateFolder({ payload: { tfid: this.selectedTemplateFolder.tfid } })
            .then(() => {
              this.selectedTemplateFolder = null;
            })
            .then(() => {
              this.activeTab = this.tabItems.root;
            });
        },
      });
    },

    updateTemplateFolder() {
      this._updateTemplateFolder({ payload: this.selectedTemplateFolder })
        .then(() => { this.activeTab = this.tabItems.root; });
    },
  },
};
</script>
