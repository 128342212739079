import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheSettingTagDetails from './workspaceSetting/TheSettingTag/TheSettingTagDetails';
import TheSettingTagEditAutoTagPanel from './workspaceSetting/TheSettingTag/TheSettingTagEditAutoTagPanel';
import TheSettingTagEditAutoflushPanel from './workspaceSetting/TheSettingTag/TheSettingTagEditAutoflushPanel';

import TheSettingLabelDetails from './workspaceSetting/TheSettingLabel/TheSettingLabelDetails';

import TheSettingTemplateDetails from './workspaceSetting/TheSettingTemplate/TheSettingTemplateDetails';
import TheSettingAddTemplate from './workspaceSetting/TheSettingTemplate/TheSettingAddTemplate';
import TheSettingEditTemplate from './workspaceSetting/TheSettingTemplate/TheSettingEditTemplate';
import TheSettingTemplateFolderPanel from './workspaceSetting/TheSettingTemplate/TheSettingTemplateFolderPanel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheSettingTagDetails);
    registerComponent(Vue, TheSettingTagEditAutoTagPanel);
    registerComponent(Vue, TheSettingTagEditAutoflushPanel);
    registerComponent(Vue, TheSettingLabelDetails);
    registerComponent(Vue, TheSettingTemplateDetails);
    registerComponent(Vue, TheSettingAddTemplate);
    registerComponent(Vue, TheSettingEditTemplate);
    registerComponent(Vue, TheSettingTemplateFolderPanel);
  },
};

use(Plugin);

export default Plugin;

export {
  TheSettingTagDetails,
  TheSettingTagEditAutoTagPanel,
  TheSettingTagEditAutoflushPanel,
  TheSettingLabelDetails,
  TheSettingTemplateDetails,
  TheSettingAddTemplate,
  TheSettingEditTemplate,
  TheSettingTemplateFolderPanel,
};
