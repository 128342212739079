<template>
  <details
    v-if="isActive"
    class="JsonReader"
  >
    <summary>
      {{ cnt.name }}
    </summary>

    <JsonReader :obj="cnt.obj || {}" />
  </details>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DevDetails',
  props: {
    cnt: {
      type: Object,
      default: () => ({ name: 'NOT SETTED NAME', obj: {} }),
    },
  },
  computed: {
    ...mapGetters({
      isActive: 'DevDetails/isActive',
    }),
  },
};
</script>

<style lang="scss">
.JsonReader {
  overflow-y: auto;
  max-height: 100vh;
  & summary {
    background-color: #050505;
    color: whitesmoke;
  }
  & pre {
    background-color: #121212;
    color: whitesmoke;
    white-space: break-spaces;
  }
}
</style>
