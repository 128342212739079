const moment = require('moment-timezone');

const randomInc = Math.random() * (3 - 1) + 1;
const timeBetween = moment.duration(5 + randomInc, 'minutes');

const nextStartingDay = (date) => moment(date).add(1, 'day').startOf('day').add(8, 'h');
const currentEndingDay = (date) => moment(date).startOf('day').add(20, 'h');
const currentStartingDay = (date) => moment(date).startOf('day').add(8, 'h');

/**
 * Return a moment for next time the message should be sent.
 * The message can only be sent during the customer's working day,
 * which is defined in their timezone.
 *
 * @param sentToday number of messages sent today
 * @param lastSendOn the UTC timestamp of the last time the message was sent
 * @param customerTimezone the timezone the customer is in
 * @param limitSendByDay the maximum number of messages we can send each day
 * @returns {moment.Moment} the next time we should try sending the message
 */
const run = ({
  sentToday,
  lastSendOn,
  customerTimezone = 'Europe/Paris',
  limitSendByDay = 70,
}) => {
  const lastSendOnInTimezone = moment.utc(lastSendOn).tz(customerTimezone);

  if (sentToday >= limitSendByDay) return nextStartingDay(lastSendOnInTimezone).utc();

  const endOfDay = currentEndingDay(lastSendOnInTimezone);

  const startOfDay = currentStartingDay(lastSendOnInTimezone);

  const nextSendOn = moment(lastSendOnInTimezone).add(timeBetween);

  if (nextSendOn.isBetween(startOfDay, endOfDay)) {
    return nextSendOn.utc();
  }

  return nextStartingDay(lastSendOnInTimezone).utc();
};

module.exports = { run };
