<template>
  <section class="multibar">
    <UbuSearchbar
      v-model="localSearch"
      class="start"
      placeholder="Search..."
      @clear="$emit('input', '')"
    />

    <div class="buttons end">
      <b-button
        tag="router-link"
        :to="to"
        type="is-secondary"
        :label="routeParamsView === 'gallery' ? 'Detailed View' : 'Gallery View'"
        icon-pack="ubu"
        icon-left="ubu-list"
      />

      <b-button
        type="is-secondary"
        label="Download all"
        icon-pack="ubu"
        icon-left="ubu-download"
        @click="$emit('downloadAll')"
      />

      <b-button
        type="is-secondary"
        label="Delete hashtag"
        icon-pack="ubu"
        icon-left="ubu-trash"
        @click="$emit('delete')"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'UbuHashtagsFilters',
  model: {
    prop: 'search',
  },
  props: {
    search: {
      type: String,
      required: true,
    },
  },
  computed: {
    localSearch: {
      get() { return this.search; },
      set(val) { this.$emit('input', val); },
    },
    routeParamsView() {
      return this.$route.meta.view;
    },
    to() {
      return `/activity/hashtags/${this.routeParamsView === 'gallery' ? 'detailed-view' : 'gallery-view'}`;
    },
  },
};
</script>
