<template>
  <div class="multibar row-vcentered ubuTribeMemberCard">
    <UbuAvatar
      class="start"
      :profile-picture="row.contactPlatform.profilePicture"
      :initial-name="row.contactPlatform.username[0]"
      :platform-name="row.contactPlatform.platformName"
      :size="40"
      :is-size="40"
      clickable
      :is-selected="isSelected"
      @click.native="$emit('select', row)"
    />
    <span
      class="start ml-2 text-truncate clickable"
      @click="$emit('selectContactPlatform', row.contactPlatform.cpid)"
    >
      {{ row.contactPlatform.username }}
    </span>

    <UbuDisplayStat
      v-if="row.contactPlatformStatistic && row.contactPlatformStatistic.totalMentions"
      class="end mr-2"
      :stat="row.contactPlatformStatistic.totalMentions"
      stat-type="mentions"
      icon="ubu-mentions"
      :color="9"
      tooltip-position="is-left"
    />

    <UbuDisplayStat
      v-if="row.contactPlatformStatistic && row.contactPlatformStatistic.totalRevenuesUsd"
      class="end mr-2"
      :stat="row.contactPlatformStatistic.totalRevenuesUsd"
      stat-type="revenue"
      icon="ubu-money"
      :color="5"
      symbol="$"
      tooltip-position="is-left"
    />

    <TheTribePeopleColumnRowDropdrown
      :key="row.tmid"
      v-model="row"
      class="end"
    />
  </div>
</template>

<script>
export default {
  name: 'TheTribePeopleColumnRow',
  model: {
    prop: 'row',
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
