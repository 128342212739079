<template>
  <b-tab-item value="root.assignTag.done">
    <header>
      <UbuSearchbar
        v-model="search"
        class="start mb-4"
        placeholder="Search..."
        @clear="search = ''"
      />
    </header>
    <article>
      <UbuActionCard
        v-for="tag in filteredList"
        :key="tag.tgid"
        :class="`mb-4 has-text-color-${tag.color}`"
        :label="tag.label"
        icon-pack="ubu"
        icon-left="ubu-custom-tag"
        :icon-right="!tag.threadWithoutTag.length ? 'ubu-close' : ''"
        @click="selectTag(tag)"
      />
    </article>
  </b-tab-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheThreadBulkTag',
  mixins: [FilterEngineMixin],
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      threadGroupList: 'TheInbox/threadGroupList',
    }),

    unassignedByTag() {
      const tagList = Object.values(this.threadGroupList);

      return tagList.map((tag) => ({
        ...tag,
        threadWithoutTag: this.checkedRows.reduce((acc, { tid, tags }) => {
          if (!tags.includes(tag.tgid)) acc.push(tid);
          return acc;
        }, []),
      }));
    },

    filteredList() {
      const tagList = this.unassignedByTag;

      if (!tagList.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByLabelName = this.search;

      return this.filterEngine(tagList, filtersToApply);
    },
  },
  methods: {
    ...mapActions({
      _assignThreadGroup: 'TheTagPopover/_assignThreadGroup',
      _unassignThreadGroup: 'TheTagPopover/_unassignThreadGroup',
    }),

    selectTag(tag) {
      if (!tag.threadWithoutTag.length) {
        return this._unassignThreadGroup({
          payload: { tgid: tag.tgid, rows: this.checkedRows },
          mutations: [
            {
              target: 'ApiContact/contactPlatformOverview/UP_removeCustom',
              payload: {
                key: 'tags',
                itemId: tag.tgid,
                cpids: this.checkedRows.map(({ contactPlatform }) => contactPlatform.cpid),
              },
            },
          ],
        });
      }

      return this._assignThreadGroup({
        payload: { tgid: tag.tgid, rows: this.checkedRows },
        mutations: [
          {
            target: 'ApiContact/contactPlatformOverview/UP_addCustom',
            payload: {
              key: 'tags',
              itemId: tag.tgid,
              cpids: this.checkedRows.map(({ contactPlatform }) => contactPlatform.cpid),
            },
          },
        ],
      });
    },
  },
};
</script>
