import segmentFilter from './segmentFilter.store';
import shortLink from './shortLink.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    segmentFilter,
    shortLink,
  },
};
