var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',[_c('b-field',{attrs:{"label":"location","horizontal":""}},[_c('UbuAutocomplete',{attrs:{"options":_vm.filteredZonePreset,"placeholder":"Type a geographical zone"},on:{"input":function($event){return _vm.updateZoneOnAutocomplete($event)}},model:{value:(_vm.zoneFilter),callback:function ($$v) {_vm.zoneFilter=$$v},expression:"zoneFilter"}})],1),_c('b-field',{attrs:{"label":"language","horizontal":""}},[_c('UbuAutocomplete',{attrs:{"options":_vm.filteredLanguagePreset,"placeholder":"Type a language"},on:{"input":function($event){return _vm.updateLanguageOnAutocomplete($event)}},model:{value:(_vm.languageFilter),callback:function ($$v) {_vm.languageFilter=$$v},expression:"languageFilter"}})],1),_c('b-field',{attrs:{"label":"scraping zone","horizontal":"","message":[
      {
        'This scraping zone does not exists ! You must create it before using':
          _vm.scrapingZoneFilter.selected && !_vm.selectedScrapingZoneExists
      }
    ]}},[_c('UbuAutocomplete',{attrs:{"options":_vm.filteredScrapinZonePreset,"placeholder":"Type a scraping zone","required":""},on:{"input":function($event){return _vm.updateScrapingZoneOnAutocomplete($event)}},model:{value:(_vm.scrapingZoneFilter),callback:function ($$v) {_vm.scrapingZoneFilter=$$v},expression:"scrapingZoneFilter"}})],1),_c('b-field',{attrs:{"label":"review","horizontal":"","message":[
      { 'You change scraping zone, it must be reviewed again later': _vm.isVerifiedIsLocked }
    ]}},[_c('b-field',[_c('b-radio-button',{attrs:{"native-value":null,"type":"is-dark"},on:{"input":function($event){return _vm.$update('isVerified',$event)}},model:{value:(_vm.salesContact.isVerified),callback:function ($$v) {_vm.$set(_vm.salesContact, "isVerified", $$v)},expression:"salesContact.isVerified"}},[_c('span',[_vm._v("Not Reviewed")])]),_c('b-radio-button',{attrs:{"native-value":true,"type":"is-success"},on:{"input":function($event){return _vm.$update('isVerified',$event)}},model:{value:(_vm.salesContact.isVerified),callback:function ($$v) {_vm.$set(_vm.salesContact, "isVerified", $$v)},expression:"salesContact.isVerified"}},[_c('b-icon',{attrs:{"icon":"check"}}),_c('span',[_vm._v("Validate")])],1),_c('b-radio-button',{attrs:{"native-value":false,"type":"is-danger"},on:{"input":function($event){return _vm.$update('isVerified',$event)}},model:{value:(_vm.salesContact.isVerified),callback:function ($$v) {_vm.$set(_vm.salesContact, "isVerified", $$v)},expression:"salesContact.isVerified"}},[_c('b-icon',{attrs:{"icon":"close"}}),_c('span',[_vm._v("Reject")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }