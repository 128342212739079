/* eslint-disable max-len */
// import moment from 'moment';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    // noteList: (_state, _getters, _rootState, rootGetters) => {
    //   const {
    //     'User/getterUserList': userList,
    //     'ThreadGroup/getterThreadGroupList': threadGroupList,
    //     'ChannelGroup/contact/contactNote/getterNoteList': noteList,
    //     'ContactGroup/getterContactGroupList': contactGroupList,
    //     'ChannelGroup/crmList/getterCrmListList': crmListList,
    //   } = rootGetters;

    //   return Object.values(noteList).map((note) => ({
    //     ...note,
    //     user: note.userUid ? userList[note.userUid] : null,
    //     threadGroup: note.threadGroupTgid ? threadGroupList[note.threadGroupTgid] : null,
    //     contactGroup: note.contactGroupCgid ? contactGroupList[note.contactGroupCgid] : null,
    //     crmList: note.crmListClid ? crmListList[note.crmListClid] : null,
    //   }))
    //     .sort((a, b) => (moment(b.noteDate) - moment(a.noteDate)));
    // },
    noteList: (_state, _getters, _rootState, rootGetters) => {
      const {
        // 'User/getterUserList': userList,
        // 'ThreadGroup/getterThreadGroupList': threadGroupList,
        'ChannelGroup/contact/contactNote/getterNoteList': noteList,
        // 'ContactGroup/getterContactGroupList': contactGroupList,
        // 'ChannelGroup/crmList/getterCrmListList': crmListList,
      } = rootGetters;

      return Object.values(noteList);
    },
    noteListTotal: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/contact/contactNote/getterNoteListTotal': noteListTotal } = rootGetters;

      return noteListTotal;
    },
  },
  actions: {
    getNoteList({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/contact/contactNote/report',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'report contactNote',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report contactNote.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
    resetNoteList({ dispatch }) {
      const action = {
        dispatch,
        target: 'ChannelGroup/contact/contactNote/reset',
        textNotifier: 'reset notes',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: contact.js ~ line 30 ~ resetNoteList ~ e', e);
          Snackbar.open({
            message: 'Unable to reset notes.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
  },
};
