<template>
  <b-field grouped>
    <b-input
      v-model="localSegmentFilter.label"
      placeholder="New segment"
      class="is-simple start"
      @input="updateSegmentFilterLabel($event)"
    />

    <template v-if="localSegmentFilter.label">
      <b-button
        v-if="newSegment.label"
        class="start"
        label="Save segment"
        type="is-ghost is-borderless"
        @click="createSegment()"
      />

      <b-button
        v-else-if="segmentHasChange"
        class="start"
        label="Update segment"
        type="is-ghost is-borderless"
        @click="editSegment()"
      />
    </template>
  </b-field>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSegmentFilterManager',
  model: {
    prop: 'selectedSegment',
  },
  props: {
    selectedSegment: {
      type: Object,
      default: null,
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newSegment: {
        label: '',
        icon: 'ubu-filter',
        color: '1',
      },
    };
  },
  computed: {
    ...mapGetters({
      segmentFilterList: 'TheFilters/segmentFilterList',
    }),

    localSelectedSegment: {
      get() { return this.selectedSegment; },
      set(val) { this.$emit('input', val); },
    },

    localSegmentFilter() {
      return this.localSelectedSegment ? this.localSelectedSegment : this.newSegment;
    },

    segmentHasChange() {
      if (!this.localSegmentFilter) return false;

      const { sfid, label, filters } = this.localSegmentFilter;

      const labelHasChange = label !== this.segmentFilterList[sfid].label;

      if (labelHasChange) return true;

      // eslint-disable-next-line max-len
      const filtersHasChange = JSON.stringify(filters) !== JSON.stringify(this.filters.map(({ key, operator, values }) => ({ key, operator, values })));

      if (filtersHasChange) return true;
      return false;
    },
  },
  methods: {
    ...mapActions({
      createSegmentFilter: 'TheFilters/createSegmentFilter',
      updateSegmentFilter: 'TheFilters/updateSegmentFilter',
    }),

    updateSegmentFilterLabel(event) {
      if (this.localSelectedSegment) {
        this.localSelectedSegment.label = event;

        return;
      }

      this.newSegment.label = event;
    },

    createSegment() {
      const toRegister = {
        ...this.newSegment,
        filters: this.filters.map(({ key, operator, values }) => ({ key, operator, values })),
      };

      return this.createSegmentFilter({ payload: toRegister })
        .then((segmentFilter) => {
          this.localSelectedSegment = { ...segmentFilter };
          this.newSegment = {
            label: '',
            icon: 'ubu-filter',
            color: '1',
          };
        });
    },

    editSegment() {
      const toRegister = {
        ...this.localSegmentFilter,
        filters: this.filters.map(({ key, operator, values }) => ({ key, operator, values })),
      };

      return this.updateSegmentFilter({ payload: toRegister })
        .then((segmentFilter) => { this.localSelectedSegment = { ...segmentFilter }; });
    },
  },
};
</script>
