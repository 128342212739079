import moment from 'moment';
import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    tribeList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiTribe/tribe/getterTribeList': tribeList } = rootGetters;

      return tribeList;
    },

    tribeThreadCounters: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiTribe/threadCounters/getterTribeThreadCounters': getterTribeThreadCounters } = rootGetters;

      return getterTribeThreadCounters;
    },
  },
  actions: {
    UP_tribe({ dispatch }, tribe) {
      tribe.memberGroupPosition.forEach(({ memberGroupMgid, previousMemberGroupMgid }) => {
        console.log('🚀 ~ file: tribe.js ~ line 14 ~ UP_tribe ~ tribe', memberGroupMgid, previousMemberGroupMgid);
      });
      const action = {
        dispatch,
        target: 'ApiTribe/tribe/UP_tribe',
        context: tribe,
        textNotifier: 'UP_tribe tribe',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to UP_tribe tribe list.', indefinite: true }));
    },

    getTribe({ dispatch, rootGetters }, { payload = {}, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/tribe/get',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Get tribe',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to get tribe list.', indefinite: true }));
    },

    reportTribeList({ dispatch, rootGetters }, { payload = {}, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/tribe/report',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Report tribe list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report tribe list.', indefinite: true }));
    },

    reportTribeThreadCounters({ dispatch, rootGetters }, { payload = {}, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/threadCounters/report',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Report tribe thread counters',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to tribe thread counters.', indefinite: true }));
    },

    createTribe({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/tribe/create',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
            createdOn: moment().format(),
            updatedOn: null,
            deletedOn: null,
          },
          httpQuery,
        },
        textNotifier: 'Create tribe',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to create tribe.', indefinite: true }));
    },

    updateTribe({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const { overview, ...rest } = payload;

      const action = {
        dispatch,
        target: 'ApiTribe/tribe/update',
        context: {
          payload: {
            ...rest,
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
            updatedOn: moment().format(),
          },
          httpQuery,
        },
        textNotifier: 'Update tribe',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to update tribe.', indefinite: true }));
    },

    deleteTribe({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/tribe/delete',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
          },
          httpQuery,
        },
        textNotifier: 'Delete tribe',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to delete tribe.', indefinite: true }));
    },

    addFromCustom({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/tribe/addFromCustom',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
          },
          httpQuery,
        },
        textNotifier: 'Add from custom',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to add from custom.', indefinite: true }));
    },
  },
};
