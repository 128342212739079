<template>
  <b-dropdown
    ref="dropdown"
    v-model="httpQueryRow"
    aria-role="list"
    class="is-fullWidth"
    position="is-bottom-right"
    :disabled="disabled"
  >
    <template #trigger>
      <b-button
        v-if="httpQueryRow === ''"
        type="is-dashed"
      >
        {{ colDetail.label }}
      </b-button>

      <b-button
        v-else
        type="is-dashed"
        focused
      >
        {{ colDetail.label }}
      </b-button>
    </template>

    <b-dropdown-item
      v-for="item in list"
      :key="item[colDetail.col]"
      :value="item[colDetail.col]"
      aria-role="listitem"
      custom
    >
      <div>
        <b-field
          grouped
          class="no-shrink no-margin"
        >
          <b-radio
            v-model="localHttpQueryRow"
            :native-value="item.value"
          >
            {{ item.key }}
          </b-radio>
        </b-field>
      </div>
    </b-dropdown-item>

    <b-field
      grouped
      class="padding-reset"
    >
      <b-field expanded>
        <p class="control">
          <b-button
            expanded
            type="is-secondary"
            label="Reset"
            @click="$emit('reset')"
          />
        </p>
      </b-field>

      <b-field expanded>
        <p class="control">
          <b-button
            expanded
            type="is-primary"
            label="Done"
            @click="closeDropdown()"
          />
        </p>
      </b-field>
    </b-field>
  </b-dropdown>
</template>

<script>
export default {
  name: 'UbuCRMFilterRadio',
  model: {
    prop: 'httpQueryRow',
  },
  props: {
    httpQueryRow: {
      type: String,
      default: '',
    },
    colDetail: {
      type: Object,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localHttpQueryRow: {
      get() { return this.httpQueryRow; },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    closeDropdown() {
      this.$refs.dropdown.isActive = false;
    },
  },
};
</script>

<style lang="scss">
.padding-reset {
  padding: 12px;
}
</style>
