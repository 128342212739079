<template>
  <div
    :class="
      'popover popover-item-time is-flex is-justify-content-space-between' +
        [disabled ? ' is-disabled' : ''] +
        [hovered ? ' is-hovered' : ''] +
        [focused ? ' is-focused' : '']
    "
  >
    <div class="is-flex is-align-items-center">
      <b-icon
        v-if="iconLeft"
        pack="ubu"
        :icon="iconLeft.icon"
        class="popover-icon-left"
      />
      <span>
        {{ name }}
      </span>
    </div>
    <template>
      <input
        type="time"
        v-model="time"
        required
      >
    </template>
  </div>
</template>

<script>
export default {
  name: 'UbuPopoverItemTime',
  props: {
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hovered: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: Object,
      default: () => {},
    },
    iconRight: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      time: null,
    };
  },
};
</script>
