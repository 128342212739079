export default {
  data() {
    return {
      columnsLatestUpdates: [
        {
          col: 'createdOn',
          label: 'Date',
          cmp: 'date',
          cellClass: 'static',
          // isSortable: true,
        },
        {
          col: 'title',
          label: 'Title',
          cmp: 'flat',
          cellClass: 'fullWidth',
          noTruncate: true,
        },
      ],
      columnsHelpCenter: [
        {
          col: 'url',
          label: 'Action',
          buttonType: 'button',
          buttonTitle: 'Read Article',
          type: 'is-ghost is-borderless',
          event: 'openLink',
          cellClass: 'static',
        },
        {
          col: 'title',
          label: 'Article',
          cmp: 'flat',
          cellClass: 'fullWidth',
          noTruncate: true,
        },
      ],
    };
  },
};
