import Vue from 'vue';
import Buefy from 'buefy';
import moment from 'moment';

// import 'buefy/dist/buefy.css';
import vClickOutside from 'v-click-outside';
import VueClipboard from 'vue-clipboard2';
import VueShortkey from 'vue-shortkey';
import VueVirtualScroller from 'vue-virtual-scroller';
import VueTelInput from 'vue-tel-input';
import TextareaAutosize from 'vue-textarea-autosize';

// CSS
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import VueSegmentAnalytics from 'vue-segment-analytics';

import JsonCSV from 'vue-json-csv';
import { nFormatter } from '@dailyplanet/utils/formate';

// if (process.env.VUE_APP_MODE !== 'dev') {
Vue.use(VueSegmentAnalytics, {
  id: process.env.VUE_APP_SEGMENT_APP_ID,
  // router // Optional
});
// }

// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('downloadCsv', JsonCSV);

Vue.use(Buefy);

Vue.use(vClickOutside);
Vue.use(VueClipboard);
Vue.use(VueShortkey);
Vue.use(VueVirtualScroller);
Vue.use(VueTelInput);
Vue.use(TextareaAutosize);

Vue.directive('focus', { inserted(el) { setTimeout(() => el.focus(), 100); } });

Vue.filter('capitalize', (value) => (value ? value.toString().charAt(0).toUpperCase() + value.slice(1) : ''));
Vue.filter('toDateTime', (value) => (moment(String(value)).format('YYYY/MM/DD hh:mm')));
Vue.filter('toDate', (value) => (moment(String(value)).format('YYYY/MM/DD')));
Vue.filter('humanizeDate', (value) => {
  if (moment(value).isBetween(moment().startOf('day'), moment().endOf('day'))) {
    return `at ${moment(String(value)).format('hh:mm')}`;
  }
  return `on ${moment(String(value)).format('YYYY/MM/DD')}`;
});
const parsedNumber = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

Vue.filter('nFormatter', (value) => (Number(value) > 0 ? nFormatter(Number(value), 1) : '-'));
Vue.filter('nDecimalFormatter', (value) => (Number(value) > 0 ? nFormatter(parsedNumber(Number(value) / 100), 1) : '-'));

Vue.filter('lowercase', (value) => (value ? value.toString().toLowerCase() : ''));
