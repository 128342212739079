/* eslint-disable no-multi-spaces */
import TheInbox from './TheInbox';
import TheInboxSidebar from './TheInboxSidebar';
import TheInboxMainPanel from './TheInboxMainPanel';

const capitalize = ([firstLetter, ...rest]) => firstLetter.toUpperCase() + rest.join('').toLowerCase();

const customsRoutes = ['tag', 'label', 'campaign', 'appoint'].flatMap((custom) => ([
  {
    path: `/inbox/${custom}/:threadBy${capitalize(custom)}s`,
    name: `Inbox - ${capitalize(custom)}`,
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheInbox',
      isCommonThreadBox: custom === 'appoint',
    },
    components: {
      inboxSidebar: TheInboxSidebar,
    },
  },
  {
    path: `/inbox/${custom}/:threadBy${capitalize(custom)}s/channel/:channelCid/thread/:tid`,
    name: `Inbox - ${capitalize(custom)} - Thread`,
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheInbox',
      isCommonThreadBox: custom === 'appoint',
    },
    components: {
      inboxSidebar: TheInboxSidebar,
      inboxMainPanel: TheInboxMainPanel,
    },
  },
]));

export default [
  {
    path: '/inbox',
    redirect: '/inbox/common/primary',
  },
  {
    path: '/inbox',
    name: 'Inbox',
    component: TheInbox,
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheInbox',
    },

    children: [
      {
        path: '/inbox/common/:inboxType',
        name: 'Inbox - Common',
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheInbox',
          isCommonThreadBox: true,
        },
        components: {
          inboxSidebar: TheInboxSidebar,
        },
      },
      {
        path: '/inbox/common/:inboxType/channel/:channelCid/thread/:tid',
        name: 'Inbox - Common - Thread',
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheInbox',
          isCommonThreadBox: true,
        },
        components: {
          inboxSidebar: TheInboxSidebar,
          inboxMainPanel: TheInboxMainPanel,
        },
      },
      ...customsRoutes,
    ],
  },
];
