/* eslint-disable object-curly-newline */

import builder from '../../engine';

const target = 'clark';

const userRole = {
  report: ({ payload, httpQuery }) => builder({ url: '/api/userRole', method: 'REPORT', payload, httpQuery, target }),
};

export default userRole;
