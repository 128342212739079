<template>
  <section class="ubuSettingView">
    <div class="multibar row-vcentered">
      <h1 class="start title is-2">Templates</h1>

      <b-button
        class="end mr-2"
        type="is-secondary"
        label="Folders"
        icon-pack="ubu"
        icon-left="ubu-share"
        @click="isOpenFolder = true"
      />

      <b-button
        class="end"
        type="is-primary"
        label="Add template"
        icon-pack="ubu"
        icon-left="ubu-add"
        @click="isOpenPanelAddTemplate = true"
      />
    </div>

    <br>

    <TheTemplateManagerSimple />

    <TheSettingAddTemplate
      v-if="isOpenPanelAddTemplate"
      closing-way="emit"
      @close="isOpenPanelAddTemplate = false"
    />

    <TheSettingTemplateFolderPanel
      v-if="isOpenFolder"
      @close="isOpenFolder = false"
    />

    <div
      v-if="$route.meta.lockBody || isOpenFolder || isOpenPanelAddTemplate"
      class="overlayWithLock"
    />
  </section>
</template>

<script>

export default {
  name: 'TheSettingTemplate',
  data() {
    return {
      isOpenFolder: false,
      isOpenPanelAddTemplate: false,
    };
  },
};
</script>
