/* eslint-disable no-multi-spaces */
import TheUser from './TheUser';
import TheUserSignIn from './TheUserSignIn';
import TheDetailledUser from './TheDetailledUser';

export default [
  {
    path: '/user',
    name: 'TheUser',
    component: TheUser,
    meta: {
      requireAuth: true,
      requiredRights: ['USER.CREATE'],
      hasMenu: true,
      viewStoreNavigation: 'TheUser',
    },
    children: [
      {
        path: '/user/signin',
        name: 'TheUserSignIn',
        component: TheUserSignIn,
        meta: {
          requireAuth: true,
          requiredRights: ['USER.CREATE'],
          hasMenu: true,
          viewStoreNavigation: 'TheUser',
        },
      },
      {
        path: '/user/detailledUser',
        name: 'TheDetailledUser',
        component: TheDetailledUser,
        meta: {
          requireAuth: true,
          requiredRights: ['USER.REPORT_DETAILLED'],
          hasMenu: true,
          viewStoreNavigation: 'TheUser',
        },
      },
    ],
  },
];
