/* eslint-disable object-curly-newline */

import builder from '../../engine';

const target = 'clark';

const user = {
  get: ({ payload, httpQuery }) => builder({ url: `/api/user/${payload.uid}`, method: 'GET', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: '/api/user', method: 'POST', payload, httpQuery, target }),

  userDetail: {
    put: ({ payload, httpQuery }) => builder({ url: `/api/user/${payload.uid}/userDetail/${payload.userUid}`, method: 'PUT', payload, httpQuery, target }),
  },
  setTheme: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/user/${payload.uid}/setTheme/${payload.theme}`, method: 'POST', payload, httpQuery, target }),
  },
  setRole: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/user/${payload.uid}/setRole`, method: 'POST', payload, httpQuery, target }),
  },
  accountMemberView: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/user/${payload.uid}/accountMemberView/`, method: 'REPORT', payload, httpQuery, target }),
    get: ({ payload, httpQuery }) => builder({ url: `/api/user/${payload.uid}/accountMemberView/${payload.aid}`, method: 'GET', payload, httpQuery, target }),
  },
  setLocked: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/user/${payload.uid}/setLocked`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/api/user/${payload.uid}/setLocked`, method: 'DELETE', payload, httpQuery, target }),
  },
  setDefaultChannelGroup: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/user/${payload.uid}/setDefaultChannelGroup/${payload.cgid}`, method: 'POST', payload, httpQuery, target }),
  },
};

export default user;
