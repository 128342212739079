<template>
  <section class="mainPanel">
    <header class="mainPanelHeader multibar row-vcentered">
      <h1 class="title is-2 start">Download Task</h1>

      <b-button
        class="end"
        label="Refresh"
        icon-pack="ubu"
        icon-left="ubu-refresh"
        type="is-primary"
        @click="reportDatas()"
      />
    </header>

    <article class="mainPanelTable">
      <b-table
        ref="table"
        :data="mappedDonwloadTaskList"
        class="table-row-clickable table-full-height"
        style="min-height: calc(100vh - 200px)"
        hoverable
        sticky-header
        focusable
        paginated
        backend-pagination
        :total="_downloadTaskTotal"
        :per-page="perPage"
        pagination-size="is-small"
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @page-change="pageChange"
        @sort="onSort"
      >
        <b-table-column
          v-for="column in columns"
          :key="column.field"
          :field="column.field"
          :label="column.label"
          :sortable="column.isSortable"
          :visible="column.visible"
          :custom-sort="(a, b, isAsc) => customSort(a, b, isAsc, column)"
        >
          <template v-if="column.cmp === 'CustomUser'" #default="props">
            <p>
              {{ props.row.email }}
            </p>
          </template>

          <template v-else-if="column.cmp === 'CustomDate'" #default="props">
            <div>
              <p v-if="props.row.createdOn">
                {{ $moment(props.row.createdOn).format("DD/MM/YYYY HH:mm") }} (UTC)
              </p>
              <p v-else>-</p>
            </div>
          </template>

          <template v-else-if="column.cmp === 'CustomState'" #default="props">
            <p>
              <b-taglist
                class="no-margin"
              >
                <b-tag
                  v-if="props.row.state === 'ERROR'"
                  type="is-danger"
                >
                  Error
                </b-tag>
                <b-tag
                  v-else-if="props.row.state === 'IN_PROGRESS'"
                  type="is-warning"
                >
                  In progress
                </b-tag>
                <b-tag
                  v-else-if="props.row.state === 'AWAITING'"
                  type="is-primary"
                >
                  Awaiting
                </b-tag>
                <b-tag
                  v-else
                  type="is-success"
                >
                  Done
                </b-tag>
              </b-taglist>
            </p>
          </template>

          <template v-else-if="column.cmp === 'CustomPostTreatment'" #default="props">
            <b-button
              v-if="props.row?.postTreatment && props.row?.postTreatment?.url
                && !props.row?.postTreatment?.error"
              label="Download"
              type="is-primary"
              icon-pack="ubu"
              icon-left="ubu-download"
              class="end"
              tag="a"
              :href="props.row.postTreatment.url"
            />

            <div v-else>
              <div class="start multibar row-vcentered">
                <span class="text-truncate start">-</span>
              </div>
            </div>
          </template>

          <template v-else #default="props">
            <component
              :is="column.cmp"
              :cpo="props.row"
              :field="column.field"
              :column="column"
            />
          </template>
        </b-table-column>

        <template #empty>
          <p class="has-text-secondary">No download task found!</p>
        </template>
      </b-table>
    </article>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';

export default {
  name: 'TheSettingDownloadTask',
  mixins: [TableUtilsMixin],
  data() {
    return {
      perPage: 25,
      page: 1,
      sortField: 'createdOn',
      sortOrder: 'desc',
      columns: {
        username: {
          cmp: 'CustomUser',
          field: 'userUid',
          label: 'Asked by',
          isSortable: true,
          visible: true,
        },
        createdOn: {
          cmp: 'CustomDate',
          field: 'createdOn',
          label: 'Created on',
          isSortable: true,
          visible: true,
        },
        state: {
          cmp: 'CustomState',
          field: 'state',
          label: 'State',
          isSortable: true,
          visible: true,
        },
        postTreatment: {
          cmp: 'CustomPostTreatment',
          field: 'postTreatment',
          label: 'url',
          isSortable: true,
          visible: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      _downloadTaskList: 'TheSettingWorkspaceSetting/downloadTaskList',
      _downloadTaskTotal: 'TheSettingWorkspaceSetting/downloadTaskTotal',
      userList: 'TheContactPlatformPanel/userList',
    }),
    mappedDonwloadTaskList() {
      return this._downloadTaskList.reduce((acc, dt) => {
        if (this.$moment(dt.createdOn).isSameOrBefore(this.$moment().subtract({ days: 10 }))) return acc;
        acc.push({ ...dt, email: this.userList[dt.userUid]?.userDetail?.email });
        return acc;
      }, []);
    },
  },
  mounted() {
    this.reportDatas();
  },
  methods: {
    ...mapActions({
      reportDownloadTaskList: 'TheSettingWorkspaceSetting/reportDownloadTaskList',
    }),

    reportDatas() {
      const httpQuery = {
        pagin: `${this.page - 1},${this.perPage}`,
        sorting: `${this.sortField},${this.sortOrder}`,
      };

      return this.reportDownloadTaskList({ payload: {}, httpQuery });
    },
  },
};
</script>
