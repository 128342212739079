import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    segmentFilterList: {},
  },
  mutations: {
    SET_SEGMENT_FILTER_LIST(state, newData) { state.segmentFilterList = newData; },
  },
  getters: {
    getterSegmentFilterList: (state) => state.segmentFilterList,
  },
  actions: {
    get({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAnalytics.segmentFilter.get({ payload, httpQuery })
        .then((segmentFilter) => {
          commit('SET_SEGMENT_FILTER_LIST', { ...getters.getterSegmentFilterList, [segmentFilter.sfid]: segmentFilter });

          return segmentFilter;
        });
    },

    report({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAnalytics.segmentFilter.report({ payload, httpQuery })
        .then((segmentFilterList) => {
          const arrayMappedSegmentFilterListList = segmentFilterList.arrayMapper('sfid');

          commit('SET_SEGMENT_FILTER_LIST', { ...getters.getterSegmentFilterList, ...arrayMappedSegmentFilterListList });
        });
    },

    create({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAnalytics.segmentFilter.create({ payload, httpQuery })
        .then((segmentFilter) => {
          commit('SET_SEGMENT_FILTER_LIST', { ...getters.getterSegmentFilterList, [segmentFilter.sfid]: segmentFilter });

          return segmentFilter;
        });
    },

    update({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAnalytics.segmentFilter.update({ payload, httpQuery })
        .then((segmentFilter) => {
          commit('SET_SEGMENT_FILTER_LIST', { ...getters.getterSegmentFilterList, [segmentFilter.sfid]: segmentFilter });

          return segmentFilter;
        });
    },

    delete({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAnalytics.segmentFilter.delete({ payload, httpQuery })
        .then((segmentFilter) => {
          const filtered = Object.values(getters.getterSegmentFilterList).filter(({ sfid }) => sfid !== payload.sfid).arrayMapper('sfid');

          commit('SET_SEGMENT_FILTER_LIST', filtered);

          return segmentFilter;
        });
    },
  },
};
