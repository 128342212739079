/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '@dailyplanet/providers/engine';

const target = 'clark';

const contactPlatformStatistic = {
  patch: ({ payload: { cpid, cgid, ...payload } }) => builder({ url: `/api/channelGroup/${cgid}/contactPlatformStatistic/${cpid}`, method: 'PATCH', payload, target }),

};

export default contactPlatformStatistic;
