/* eslint-disable max-len */
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions({
      createAccountLinkChannel: 'TheWorkspaceCommon/createAccountLinkChannel',
      createAccountLinkChannelGroup: 'TheWorkspaceCommon/createAccountLinkChannelGroup',
      createAccountUnlinkChannel: 'TheWorkspaceCommon/createAccountUnlinkChannel',
      createAccountUnlinkChannelGroup: 'TheWorkspaceCommon/createAccountUnlinkChannelGroup',
      up_linkChannelGroup: 'TheWorkspaceCommon/up_linkChannelGroup',
      up_unlinkChannelGroup: 'TheWorkspaceCommon/up_unlinkChannelGroup',
      up_linkChannel: 'TheWorkspaceCommon/up_linkChannel',
      up_unlinkChannel: 'TheWorkspaceCommon/up_unlinkChannel',
      up_linkChannelGroupUser: 'TheWorkspaceCommon/up_linkChannelGroupUser',
      up_unlinkChannelGroupUser: 'TheWorkspaceCommon/up_unlinkChannelGroupUser',
      up_linkChannelUser: 'TheWorkspaceCommon/up_linkChannelUser',
      up_unlinkChannelUser: 'TheWorkspaceCommon/up_unlinkChannelUser',
    }),
    // eslint-disable-next-line object-curly-newline
    updateGroupNextGen({ aid, uid, event: { channelGroup, channelList }, userMode = false }) {
      let {
        up_linkChannelGroup: upLinkChannelGroup,
        up_unlinkChannelGroup: upUnlinkChannelGroup,
        up_linkChannel: upLinkChannel,
        up_unlinkChannel: upUnlinkChannel,
      } = this;

      if (userMode) {
        upLinkChannelGroup = this.up_linkChannelGroupUser;
        upUnlinkChannelGroup = this.up_unlinkChannelGroupUser;
        upLinkChannel = this.up_linkChannelUser;
        upUnlinkChannel = this.up_unlinkChannelUser;
      }

      return Promise.resolve()
        .then(() => {
          if (!Object.values(channelGroup).length) return undefined;

          const { cgid, toBeLinked, toBeUnlinked } = channelGroup;
          if (toBeLinked) {
            return this.createAccountLinkChannelGroup({ payload: { aid, uid, cgid } })
              .then(() => { upLinkChannelGroup({ toBeLinked: [cgid], aid, uid }); });
          }
          if (toBeUnlinked) {
            return this.createAccountUnlinkChannelGroup({ payload: { aid, uid, cgid } })
              .then(() => { upUnlinkChannelGroup({ toBeUnlinked: [cgid], aid, uid }); });
          }
          return undefined;
        })
        .then(() => {
          const { toBeLinked, toBeUnlinked } = Object.values(channelList).reduce((acc, channel) => {
            if (channel.toBeUnlinked) {
              acc.toBeUnlinked.push(channel.cid);
            }
            if (channel.toBeLinked) {
              acc.toBeLinked.push(channel.cid);
            }
            return acc;
          }, { toBeLinked: [], toBeUnlinked: [] });

          return Promise.all([
            ...toBeLinked.map((cid) => this.createAccountLinkChannel({ payload: { aid, uid, cid } })
              .then(() => upLinkChannel({ toBeLinked, aid, uid }))),

            ...toBeUnlinked.map((cid) => this.createAccountUnlinkChannel({ payload: { aid, uid, cid } })
              .then(() => upUnlinkChannel({ toBeUnlinked, aid, uid }))),
          ]);
        });
    },
    // updateGroup({
    //   event, uid, editableItem: previousState, aid,
    // }) {
    //   const { value: cgid, isActive, children } = event;
    //   const { children: previousChannelStates, isActive: previousChannelGroupIsActive } = previousState[cgid];
    //   console.log('🚀 ~ file: linkUnlinkChannelChannelGroup.mixins.js ~ line 20 ~ isActive', isActive);
    //   console.log('🚀 ~ file: linkUnlinkChannelChannelGroup.mixins.js ~ line 22 ~ previousChannelGroupIsActive', previousChannelGroupIsActive);
    //   const mappedCurrent = children.map((channel) => ({ cid: channel.cid, isActive: channel.isActive })).arrayMapper('cid');
    //   const cleanedPreviousChannels = previousChannelStates.map((channel) => ({ cid: channel.cid, isActive: channel.isActive }));
    //   console.log('🚀 ~ file: linkUnlinkChannelChannelGroup.mixins.js ~ line 23 ~ cleanedPreviousChannels', cleanedPreviousChannels);
    //   return Promise.resolve()
    //     .then(() => {
    //       if (isActive === previousChannelGroupIsActive) return undefined;
    //       if (!isActive && previousChannelGroupIsActive) {
    //         return this.createAccountUnlinkChannelGroup({ payload: { aid, uid, cgid } })
    //           .then(() => { this.up_unlinkChannelGroup({ toBeUnlinked: [cgid], aid, uid }); });
    //       }
    //       if (isActive && !previousChannelGroupIsActive) {
    //         return this.createAccountLinkChannelGroup({ payload: { aid, uid, cgid } })
    //           .then(() => { this.up_linkChannelGroup({ toBeLinked: [cgid], aid, uid }); });
    //       }
    //       return undefined;
    //     })
    //     .then(() => {
    //       const { toBeLinked, toBeUnlinked } = cleanedPreviousChannels.reduce((acc, oldChannel) => {
    //         if (mappedCurrent[oldChannel.cid]) {
    //           const current = mappedCurrent[oldChannel.cid];
    //           // if (current.isActive && oldChannel.isActive) {
    //           //   return acc;
    //           // }
    //           if (!current.isActive && oldChannel.isActive) {
    //             acc.toBeUnlinked.push(oldChannel.cid);
    //           }
    //           if (current.isActive && !oldChannel.isActive) {
    //             acc.toBeLinked.push(oldChannel.cid);
    //           }
    //         }
    //         return acc;
    //       }, { toBeLinked: [], toBeUnlinked: [] });

    //       return Promise.all([
    //         ...toBeLinked.map((cid) => this.createAccountLinkChannel({ payload: { aid, uid, cid } })
    //           .then(() => this.up_linkChannel({ toBeLinked, aid, uid }))),

    //         ...toBeUnlinked.map((cid) => this.createAccountUnlinkChannel({ payload: { aid, uid, cid } })
    //           .then(() => this.up_unlinkChannel({ toBeUnlinked, aid, uid }))),
    //       ]);
    //     });
    // },
    // updateGroup({
    //   event: { children, ...channelGroup }, uid, account = null, aid = null,
    // }) {
    //   return Promise.resolve()
    //     .then(() => this.updateChannelGroup(children, uid, channelGroup, account, aid))
    //     .then(() => this.updateChannel(children, uid, account, aid));
    // },
    // updateChannelGroup(newChildren, uid, channelGroup = null, account = null, aid = null) {
    //   let userInitialData;
    //   if (!account) {
    //     userInitialData = this.accountMemberViewList.find((user) => user.uid === uid);
    //   } else {
    //     userInitialData = account;
    //   }

    //   const actualActives = userInitialData.activeChannelGroup || [];

    //   const _new = newChildren.reduce((acc, cg) => {
    //     if (cg.isActive && !actualActives.includes(cg.channelGroupCgid)) {
    //       if (!acc.toBeLinked.includes(cg.channelGroupCgid)) {
    //         return { ...acc, toBeLinked: [...acc.toBeLinked, cg.channelGroupCgid] };
    //       }
    //       return acc;
    //     }
    //     if (!cg.isActive && actualActives.includes(cg.channelGroupCgid)) {
    //       const findChannelActive = newChildren.find((c) => c.isActive);
    //       if (!findChannelActive && !acc.toBeUnlinked.includes(cg.channelGroupCgid)) {
    //         return { ...acc, toBeUnlinked: [...acc.toBeUnlinked, cg.channelGroupCgid] };
    //       }
    //       return acc;
    //     }
    //     return acc;
    //   }, { toBeLinked: [], toBeUnlinked: [] });

    //   return Promise.all(
    //     _new.toBeLinked.map((cgid) => this.createAccountLinkChannelGroup({
    //       payload: { aid: aid || this.account.aid, uid, cgid },
    //     })
    //       .then(() => {
    //         this.up_linkChannelGroup({
    //           toBeLinked: _new.toBeLinked, aid: aid || this.account.aid, uid,
    //         });
    //       }))
    //       .concat(_new.toBeUnlinked.map((cgid) => {
    //         if (userInitialData.uid !== this.myAuthenticate.uid) {
    //           return this.createAccountUnlinkChannelGroup({
    //             payload: { aid: aid || this.account.aid, uid, cgid },
    //           })
    //             .then(() => {
    //               this.up_unlinkChannelGroup({
    //                 toBeUnlinked: _new.toBeUnlinked, aid: aid || this.account.aid, uid,
    //               });
    //             });
    //         }
    //         return null;
    //       })),
    //   );
    // },
    // updateChannel(newChildren, uid, account = null, aid = null) {
    //   let userInitialData;
    //   if (!account) {
    //     userInitialData = this.accountMemberViewList.find((user) => user.uid === uid);
    //   } else {
    //     userInitialData = account;
    //   }

    //   const actualActives = userInitialData.activeChannel || [];

    //   const _new = newChildren.reduce((acc, channel) => {
    //     if (channel.isActive && !actualActives.includes(channel.cid)) {
    //       return { ...acc, toBeLinked: [...acc.toBeLinked, channel.cid] };
    //     }
    //     if (!channel.isActive && actualActives.includes(channel.cid)) {
    //       return { ...acc, toBeUnlinked: [...acc.toBeUnlinked, channel.cid] };
    //     }
    //     return acc;
    //   }, { toBeLinked: [], toBeUnlinked: [] });

    //   return Promise.all(
    //     _new.toBeLinked.map((cid) => this.createAccountLinkChannel({
    //       payload: { aid: aid || this.account.aid, uid, cid },
    //     })
    //       .then(() => {
    //         this.up_linkChannel({
    //           toBeLinked: _new.toBeLinked, aid: aid || this.account.aid, uid,
    //         });
    //       }))
    //       .concat(_new.toBeUnlinked.map((cid) => this.createAccountUnlinkChannel({
    //         payload: { aid: aid || this.account.aid, uid, cid },
    //       })
    //         .then(() => {
    //           this.up_unlinkChannel({
    //             toBeUnlinked: _new.toBeUnlinked, aid: aid || this.account.aid, uid,
    //           });
    //         }))),
    //   );
    // },
  },
};
