import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  actions: {
    migrateCommon({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'Migrate/migrateCommon',
        context: { payload },
        textNotifier: 'migrateCommon',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to migrateCommon.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },

    migrateVarious({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'Migrate/migrateVarious',
        context: { payload },
        textNotifier: 'migrateVarious',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to migrateVarious.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },

    migrateProto({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'Migrate/migrateProto',
        context: { payload },
        textNotifier: 'migrateProto',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to migrateProto.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
  },
};
