<template>
  <TheSimplePanel>
    <template #header>
      <h1 class="title is-1">Add contact label</h1>
    </template>

    <template #content>
      <form @submit.prevent="createContactGroup()">
        <UbuEditCustomForm
          v-model="custom"
          type="CONTACT LABEL"
        />

        <div class="multibar">
          <div class="buttons end">
            <b-button
              type="is-success"
              native-type="submit"
              label="Create"
              :disabled="!canSave"
            >
              Create
            </b-button>
          </div>
        </div>
      </form>
    </template>
  </TheSimplePanel>
</template>

<script>
/* eslint-disable max-len */
import { mapActions } from 'vuex';
import NavigationMixin from '@dailyplanet/mixins/NavigationMixin';

export default {
  name: 'TheSettingAddContactLabel',
  mixins: [NavigationMixin],
  data() {
    return {
      custom: {
        label: null,
        color: '1',
      },
    };
  },
  computed: {
    canSave() {
      const { label, color } = this.custom;
      if ((label && label.length) && color) return true;
      return false;
    },
  },
  methods: {
    ...mapActions({
      _createContactGroup: 'TheSettingWorkspaceSetting/createContactGroup',
    }),
    createContactGroup() {
      return this._createContactGroup({ payload: { color: this.custom.color.toString(), label: this.custom.label } })
        .then(() => {
          this.goToPreviousPath();
        });
    },
  },
};
</script>
