import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheFilterDropdown from './smarts/TheFilterDropdown';
import TheFilterDropdownSegmentSelector from './smarts/TheFilterDropdownSegmentSelector';
import TheSegmentFilterManager from './smarts/TheSegmentFilterManager';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheFilterDropdown);
    registerComponent(Vue, TheFilterDropdownSegmentSelector);
    registerComponent(Vue, TheSegmentFilterManager);
  },
};

use(Plugin);

export default Plugin;

export {
  TheFilterDropdown,
  TheFilterDropdownSegmentSelector,
  TheSegmentFilterManager,
};
