/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    counterList: null,
    receivedMessages: 0,
    timeToNextCall: 0,
    refreshIsRunning: false,
  },
  mutations: {
    SET_COUNTER_LIST(state, newData) { state.counterList = newData; },
    SET_REFRESH_IS_RUNNING(state, newData) { state.refreshIsRunning = newData; },
    SET_RECEIVED_MESSAGES(state, newData) { state.receivedMessages = newData; },
    SET_TIME_TO_NEXT_CALL(state, newData) { state.timeToNextCall = newData; },
    RESET_STORE(state) {
      state.counterList = null;
    },
  },
  getters: {
    getterCounterList: (state) => state.counterList,
    getterReceivedMessages: (state) => state.receivedMessages,
    getterTimeToNextCall: (state) => state.timeToNextCall,
    getterRefreshIsRunning: (state) => state.refreshIsRunning,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'COUNTER.REPORT')) return undefined;

      return clark.api.channelGroup.counter.report({ payload, httpQuery })
        .then((counterList) => {
          commit('SET_COUNTER_LIST', counterList);
          return counterList;
        });
    },

    up_counterList({ dispatch, commit, getters }) {
      const { getterReceivedMessages: receivedMessages, getterRefreshIsRunning: refreshIsRunning } = getters;
      commit('SET_RECEIVED_MESSAGES', receivedMessages + 1);

      if (!refreshIsRunning) {
        commit('SET_REFRESH_IS_RUNNING', true);
        return dispatch('setRecursive');
      }
      return undefined;
    },

    setRecursive({
      dispatch, getters, commit, rootGetters,
    }) {
      const { getterReceivedMessages: receivedMessages, getterTimeToNextCall: timeToNextCall } = getters;
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      return setTimeout(() => {
        console.log('🚀 ~ file: counter.store.js ~ line 49 ~ timeToNextCall', timeToNextCall, receivedMessages);

        if (timeToNextCall > 0) {
          commit('SET_TIME_TO_NEXT_CALL', timeToNextCall - 1);
        }

        if (timeToNextCall <= 0) {
          if (receivedMessages < 30) {
            commit('SET_TIME_TO_NEXT_CALL', 10);
          } else {
            commit('SET_TIME_TO_NEXT_CALL', 20);
          }
          console.log('🚀 ~ file: counter.store.js ~ line 49 ~ call refresh');
          return dispatch('report', { payload: { cgid: currentChannelGroup.cgid }, httpQuery: {} })
            .then(() => {
              commit('SET_RECEIVED_MESSAGES', 0);
              commit('SET_REFRESH_IS_RUNNING', false);
            });
        }

        return dispatch('setRecursive');
      }, 1000);
    },
  },
};
