import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuTextareaBottomTools from './UbuTextareaBottomTools';
import UbuTextareaTop from './UbuTextareaTop';
import UbuTextareaEmojisDropdownItem from './UbuTextareaEmojisDropdownItem';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuTextareaBottomTools);
    registerComponent(Vue, UbuTextareaTop);
    registerComponent(Vue, UbuTextareaEmojisDropdownItem);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuTextareaBottomTools,
  UbuTextareaTop,
  UbuTextareaEmojisDropdownItem,
};
