export default {
  data() {
    return {
      colors: [
        'rgba(77, 77, 77, 0.8)',
        'rgba(255, 96, 96, 0.8)',
        'rgba(255, 164, 113, 0.8)',
        'rgba(255, 198, 112, 0.8)',
        'rgba(107, 206, 61, 0.8)',
        'rgba(115, 218, 138, 0.8)',
        'rgba(67, 210, 193, 0.8)',
        'rgba(60, 166, 242, 0.8)',
        'rgba(111, 109, 227, 0.8)',
        'rgba(185, 141, 240, 0.8)',
        'rgba(248, 130, 236, 0.8)',
        'rgba(255, 98, 164, 0.8)',
      ],
    };
  },
};
