<!-- eslint-disable vue/no-template-target-blank -->
<template>
  <TheSimplePanel
    closing-way="emit"
    @close="$emit('close')"
  >
    <template #header>
      <p class="text is-3 has-text-secondary">Extensions</p>
      <h1 class="title">Connect shopify store</h1>
    </template>

    <template #content>
      <b-message type="is-classic">
        Having trouble connecting your Shopify store?
        <a
          class="has-text-primary no-decoration"
          href="https://app.intercom.com/a/apps/vkrekt62/articles/articles/6046554/show"
          target="_blank"
        >
          Watch our tutorial
        </a> or
        <a
          class="has-text-primary no-decoration"
          @click="openIntercom()"
        >
          contact support.
        </a>
      </b-message>

      <UbuShopifyConnexionForm
        v-model="newShopConnexion"
        @connexion="connexion()"
      />
    </template>
  </TheSimplePanel>
</template>

<script>
import { mapActions } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheShopifyConnexionPanel',
  props: {
    shopToReconnect: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      newShopConnexion: {
        baseUrl: null,
        extrefId: null,
        apiKey: null,
        password: null,
        apiVersion: null,
        sharedSecret: null,
        isConnected: false,
      },
    };
  },
  methods: {
    ...mapActions({
      _connectShop: 'TheShopify/connectShop',
    }),
    connexion() {
      return Promise.resolve()
        .then(() => {
          // used to remove extra / at the end url and http(s)://
          // eslint-disable-next-line prefer-destructuring
          const parsedUrl = /(?:https?:\/\/)?([^/#?]+)(?:.*)$/
            .exec(this.newShopConnexion.baseUrl)[1];

          const preparedPayload = {
            baseUrl: parsedUrl.trim(),
            extrefId: this.newShopConnexion.extrefId.trim(),
            apiVersion: this.newShopConnexion.apiVersion.trim(),
            apiKey: this.newShopConnexion.apiKey.trim(),
            password: this.newShopConnexion.password.trim(),
            sharedSecret: this.newShopConnexion.sharedSecret.trim(),
            type: 'SHOPIFY',
            isConnected: true,
          };

          this._connectShop({ payload: preparedPayload });

          this.$buefy.snackbar.open({
            ...snackbarSuccess,
            message: 'Successfully connected shopify store.',
          });
        })
        .then(() => { this.$emit('close'); });
    },

    openIntercom() { this.$intercom.showNewMessage('Hello, I need help to connect my shopify store please.'); },
  },
};
</script>
