/* eslint-disable max-len */
import { actions } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        {
          path: '/activity/analytics',
          text: 'Analytics',
          icon: 'ubu-overview',
        },
        {
          path: '/activity/mentions',
          text: 'Mentions',
          icon: 'ubu-mentions',
        },
        {
          path: '/activity/hashtags',
          text: 'Hashtags',
          icon: 'ubu-hashtag',
        },
      ],
    },
  },
  getters: {
    navigation: (state) => state.navigation,
  },
  actions: {
    ...actions.channelGroup,
  },
};
