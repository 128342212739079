<template>
  <section
    id="TheHome"
  >
    <!-- class="ubuSettingView" -->
    <!-- <div id="background" /> -->
    <!-- <div class="multibar row-vcentered">
      <h1 class="start title is-2">Welcome, {{ currentUserDetail.firstName }}!</h1>

      <div class="buttons is-right end">
        <b-button
          type="is-secondary"
          label="Request a feature"
          @click="openIntercom(messageRequestFeature)"
        />

        <b-button
          type="is-primary"
          label="Contact support"
          @click="openIntercom(messageContactSupport)"
        />
      </div>
    </div>

    <br>

    <div class="ubuCardHomeContainer">
      <UbuCardHome
        v-for="item in displayCardHome"
        :key="item.title"
        :title="item.title"
        :sub-title="item.subTitle"
        :image="item.image"
        :path="item.path"
      />
    </div> -->
    <!--
    <br>

    <b-tabs
      v-model="activeTab"
      type="is-toggle-rounded"
      class="ubuTabs"
    >
      <b-tab-item
        value="LAST_UPDATE"
        label="Latest updates"
      >
        <template v-if="activeTab === 'LAST_UPDATE'">
          <TheTable
            v-if="latestUpdateList.length"
            id="TheHomeTable"
            :columns="columnsLatestUpdates"
            :lead-list="latestUpdateList"
            :loading="loading"
            :total="total"
            :page="page"
            :per-page.sync="perPage"
            :sort-field="sortField"
            :sort-order="sortOrder"
            :default-sort-order="defaultSortOrder"
            :selected.sync="selected"
            :checked-rows.sync="checkedRows"
            detail-key="luid"
            :checkable="false"
            :sticky-header="false"
            :backend-sorting="false"
            class="ubuTableSetting is-height-small"
            @openLink="openLink($event)"
          />

          <UbuNoData
            v-else
            title="No updates... Yet!"
            subtitle="New updates will show up here when they are live."
            :border="false"
          />
        </template>
      </b-tab-item>

      <b-tab-item
        value="HELP_CENTER"
        label="Help center"
      >
        <template v-if="activeTab === 'HELP_CENTER'">
          <TheTable
            v-if="helpCenterList.length"
            id="TheHomeTable"
            :columns="columnsHelpCenter"
            :lead-list="helpCenterList"
            :loading="loading"
            :total="total"
            :page="page"
            :per-page.sync="perPage"
            :sort-field="sortField"
            :sort-order="sortOrder"
            :default-sort-order="defaultSortOrder"
            :selected.sync="selected"
            :checked-rows.sync="checkedRows"
            detail-key="hcid"
            :checkable="false"
            :sticky-header="false"
            :backend-sorting="false"
            class="ubuTableSetting is-height-small"
            @openLink="openLink($event)"
          />

          <UbuNoData
            v-else
            title="No articles... Yet!"
            subtitle="Articles will show up here when they are live."
            :border="false"
          />
        </template>
      </b-tab-item>
    </b-tabs> -->
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TheHomeColumns from './$mixins/TheHomeColumns.columns.mixins';

const inboxImg = require('@dailyplanet/selina/src/assets/images/ubuArt-Inbox.png');
const commentsImg = require('@dailyplanet/selina/src/assets/images/ubuArt-Comments.png');
const influenceImg = require('@dailyplanet/selina/src/assets/images/ubuArt-Influence.png');
const activityImg = require('@dailyplanet/selina/src/assets/images/ubuArt-Analytics.png');

export default {
  name: 'TheHome',
  mixins: [TheHomeColumns],
  data() {
    return {
      activeTab: 'LAST_UPDATE',
      inbox: inboxImg,
      comments: commentsImg,
      influence: influenceImg,
      activity: activityImg,
      loading: false,
      total: 0,
      page: 0,
      perPage: 50,
      sortField: 'createdOn',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      checkedRows: [],
      selected: {},
      currentDate: [this.$moment().subtract({ days: 32 }), this.$moment()],
      messageRequestFeature: 'Hello, I need to step up my Ubu application.',
      messageContactSupport: 'Hello, I need help with Ubu\'s application please.',
    };
  },
  computed: {
    ...mapGetters({
      currentUserDetail: 'TheHome/currentUserDetail',
      helpCenterList: 'TheHome/helpCenterList',
      latestUpdateList: 'TheHome/latestUpdateList',
    }),
    displayCardHome() {
      return [
        {
          title: 'Inbox',
          subTitle: 'Manage messages and interactions.',
          image: this.inbox,
          path: '/inbox',
        },
        {
          title: 'Comments',
          subTitle: 'Manage comments from posts and ads.',
          image: this.comments,
          path: '/comments',
        },
        {
          title: 'Influence',
          subTitle: 'Find and engage your ambassadors.',
          image: this.influence,
          path: '/influence',
        },
        {
          title: 'Activity',
          subTitle: 'Track your growth and UGCs.',
          image: this.activity,
          path: '/activity',
        },
      ];
    },
  },
  // mounted() {
  //   return Promise.all([
  //     this.reportHelpCenterList({}),
  //     this.reportLatestUpdateList({ httpQuery: { orderOn: this.currentDate } }),
  //   ]);
  // },
  methods: {
    ...mapActions({
      reportHelpCenterList: 'TheHome/reportHelpCenterList',
      reportLatestUpdateList: 'TheHome/reportLatestUpdateList',
    }),
    openLink(event) {
      window.open(event.url, '_blank');
    },
    openIntercom(message) {
      this.$intercom.showNewMessage(message);
    },
  },
};
</script>

<style lang="scss">
#TheHome {
  display: flex;
  min-height: 100%;
  height: 100vh;
  width: 100%;
  & #background {
    flex-grow: 1;
    background-image: url('../../assets/images/login-background-dark.png');
    background-size: cover;
    &::after {
      content: ' ';
      display: flex;
      height: 100%;
      background: var(--overlay);
      opacity: .7;
    }
  }
}
</style>
