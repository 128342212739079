<template>
  <UbuTableSkeleton
    v-if="loading"
    class="mt-4"
  />
  <b-table
    v-else
    :data="leadList"
    :total="total"
    :loading="loading"
    backend-pagination
    :paginated="paginated"
    :backend-sorting="backendSorting"
    :per-page="perPage"
    pagination-size="is-small"
    :current-page="page"
    :default-sort-direction="defaultSortOrder"
    :default-sort="[sortField, sortOrder]"
    :opened-detailed="defaultOpenedDetails"
    :detailed="isActive"
    :detail-key="detailKey"
    hoverable
    :checkable="checkable"
    :checked-rows.sync="localChecked"
    :sticky-header="stickyHeader"
    sticky-checkbox
    @sort="onSort"
    @page-change="$emit('onPageChange', $event)"
  >
    <b-table-column
      v-for="column in columns"
      :key="column.col"
      :visible="column.visible"
      :label="column.label"
      :field="column.col"
      :sortable="column.isSortable"
      :custom-sort="shouldUseCustomSort(column.cmp) ?
        (a, b, isAsc) => customSort(a, b, isAsc, column) : null"
      :sticky="column.sticky"
      :cell-class="column.cellClass"
    >
      <template
        v-if="column.base === 'INSTAGRAM'
          || column.base === 'TIKTOK'
          || column.base === 'YOUTUBE'
          || column.base === 'SMS'"
        v-slot:header
      >
        <UbuPlatformCard
          :platform-name="column.base.toLowerCase()"
          :text="column.label"
          font-size="24px"
        />
      </template>
      <template
        v-slot="props"
      >
        <template v-if="column.cmp === 'flat'">
          <b-tooltip
            v-if="column.tooltip"
            multilined
            append-to-body
            size="is-large"
            position="is-bottom"
          >
            <template v-slot:content>
              <p class="breakAll">{{ props.row[column.col] || '-' }}</p>
            </template>
            <span class="text-truncate">
              {{ props.row[column.col] || '-' }}
            </span>
          </b-tooltip>
          <template v-else>
            <span v-if="column.noTruncate">{{ props.row[column.col] || '-' }}</span>
            <span
              v-else
              class="text-truncate"
            >
              {{ props.row[column.col] || '-' }}
            </span>
          </template>
        </template>

        <template v-if="column.cmp === 'campaign'">
          <div class="campaignColumn">
            <b-icon
              pack="ubu"
              icon="ubu-custom-influence"
              :class="`has-text-color-${props.row[column.color]} mr-3`"
            />
            <div class="campaignColumnContent">
              <p class="subtitle is-3 m-0">{{ props.row[column.col] }}</p>
              <p class="subtext has-text-secondary">
                {{ $moment(props.row[column.date]).format('YYYY-MM-DD') }}
              </p>
            </div>
          </div>
        </template>

        <template v-if="column.cmp === 'feature'">
          <span
            :class="[{
              'has-text-success': props.row.event.includes('ADD'),
              'has-text-danger': props.row.event.includes('REMOVE'),
            }]"
          >
            <b-icon
              v-if="props.row[column.col]"
              pack="ubu"
              :icon="props.row.event.includes('ADD') ? 'ubu-check' : 'ubu-cross'"
            />
            {{ props.row[column.col] || '-' }}
          </span>
        </template>

        <!-- <template v-if="column.cmp === 'shortenNumber'">
          <span v-if="!column.nested">
            {{ props.row[column.col] ? shortNumber(Number(props.row[column.col])) : '-' }}
          </span>
          <span v-else>
            {{
              props.row[column.base] && props.row[column.base][column.nestedKey1]
                ? shortNumber(props.row[column.base][column.nestedKey1])
                : '-'
            }}
          </span>
        </template> -->

        <component
          :is="'UbuTableShortenNumber'"
          v-if="column.cmp === 'shortenNumber'"
          :value="column.nested ? props.row[column.base][column.nestedKey1] : props.row[column.col]"
          :symbol="column.symbol"
        />
        <component
          :is="column.cmp"
          v-if="column.cmp === 'UbuTableTrueFalseMarker'"
          :value="!!props.row[column.col].length"
        />

        <template v-if="column.cmp === 'roundedNumber'">
          <span v-if="!column.nested">
            {{ props.row[column.col] ?
              `${roundNumber(Number(props.row[column.col]))}${column.symbol}` : '-' }}
          </span>
          <span v-else>
            {{
              props.row[column.base] && props.row[column.base][column.nestedKey1]
                ? `${roundNumber(props.row[column.base][column.nestedKey1])}${column.symbol}`
                : '-'
            }}
          </span>
        </template>

        <template v-if="column.cmp === 'flat-symbol'">
          <b-tooltip
            v-if="column.tooltip"
            :label="`${column.symbol} ${props.row[column.col]}`"
            multilined
            append-to-body
            position="is-bottom"
          >
            <template v-if="column.pos === 'before'">{{ column.symbol }}</template>
            {{ props.row[column.col] || '-' }}
            <template v-if="column.pos === 'after'">{{ column.symbol }}</template>
          </b-tooltip>
          <template v-else>
            <template v-if="column.pos === 'before'">{{ column.symbol }}</template>
            {{ props.row[column.col] || '-' }}
            <template v-if="column.pos === 'after'">{{ column.symbol }}</template>
          </template>
        </template>

        <template v-if="column.cmp === 'date'">
          <span v-if="props.row[column.col]">
            {{ $moment(props.row[column.col]).format('YYYY-MM-DD') }}
          </span>
          <span v-else>-</span>
        </template>

        <template v-if="column.cmp === 'nested'">
          <template v-if="column.base && props.row[column.base]">
            <b-tooltip
              v-if="column.tooltip"
              :label="props.row[column.base][column.nestedKey1]"
              multilined
              append-to-body
              position="is-bottom"
            >
              <span class="text-truncate">
                {{ props.row[column.base][column.nestedKey1] || '-' }}
              </span>
            </b-tooltip>
            <span
              v-else
              class="text-truncate"
            >
              {{ props.row[column.base][column.nestedKey1] || '-' }}
            </span>
          </template>
          <span v-else>
            -
          </span>
        </template>

        <template v-if="column.cmp === 'isUbu'">
          <b-icon
            pack="ubu"
            icon="ubu-circle-divider"
            :type="props.row.isUbu ? 'is-success' : 'is-danger'"
          />
        </template>

        <template v-if="column.cmp === 'status'">
          <b-dropdown
            v-if="column.base && props.row[column.base]"
            aria-role="list"
            position="is-top-right"
            append-to-body
            max-height="300px"
            scrollable
            class="tableDropdown"
            :disabled="!props.row[column.base].isActive ? true : false"
          >
            <template #trigger="{ active }">
              <span class="is-flex">
                <b-icon
                  icon="circle-small"
                  :class="['is-flex', {
                    'has-text-danger': !props.row[column.base].isActive,
                    'has-text-success': props.row[column.base].isActive,
                  }]"
                />
                <div
                  :class="[{
                    'dropdownCursor': props.row[column.base].isActive
                  }]"
                >
                  <span>{{ props.row[column.base][column.nestedKey1] }}</span>
                  <b-icon
                    v-if="props.row[column.base].isActive"
                    :icon="active ? 'menu-up' : 'menu-down'"
                  />
                </div>
              </span>
            </template>

            <template v-if="props.row[column.base].isActive">
              <b-dropdown-item
                v-for="(item, i) in props.row[column.base][column.nestedKey2]"
                :key="i"
                class="dropdown-status-icon"
                @click="$emit(item.event, {
                  activityList: props.row.activityList,
                  status: item.status,
                  activityAid: props.row[column.base].activityAid,
                  cid: props.row.cid,
                  row: props.row,
                })"
              >
                <div
                  :class="['is-flex is-align_items-center', {
                    'has-text-danger': item.type === 'is-danger',
                    'has-text-success': item.type === 'is-success',
                    'has-text-primary': item.type === 'is-primary',
                    'has-text-secondary': item.type === 'is-secondary',
                  }]"
                >
                  <b-icon
                    pack="ubu"
                    :icon="item.icon"
                  />
                  <span>{{ item.label.capitalize() }}</span>
                </div>
              </b-dropdown-item>
            </template>
          </b-dropdown>
          <span
            v-else-if="column.text"
            :class="[{
              'has-text-danger': !props.row[column.base].isActive,
              'has-text-success': props.row[column.base].isActive,
            }]"
          >
            {{ props.row[column.col] }}
          </span>
          <span v-else>-</span>
        </template>

        <template v-if="column.cmp === 'icon'">
          <div class="multibar row-vcentered">
            <component
              :is="`UbuIcon${props.row.platformName.capitalize()}`"
              v-if="column.isSocial"
              class="start"
              font-size="16px"
            />
            <b-icon
              v-else
              pack="ubu"
              :icon="column.icon"
            />
          </div>
        </template>

        <template v-if="column.cmp === 'UbuCustomButton'">
          <UbuCustomButtonTable
            v-if="props.row[column.col]"
            :customs="props.row[column.col].map((row) => ({ ...row, icon: column.icon}))"
          />
          <span v-else>-</span>
        </template>

        <template v-if="column.cmp === 'UbuThreadButton'">
          <UbuThreadButton
            :crm-contact="props.row"
          />
        </template>

        <template v-if="column.cmp === 'openContactPanel'">
          <b-button
            type="is-ghost"
            label="Open details"
            :disabled="props.row.isOpenContactPanelDisabled"
            @click="localSelected = props.row"
          />
        </template>

        <template v-if="column.event">
          <b-button
            v-if="column.buttonType === 'tertiary'"
            :type="column.type"
            icon-pack="ubu"
            :icon-right="column.icon"
            @click="$emit(column.event, props.row)"
          />
          <b-button
            v-else-if="column.buttonType === 'button'"
            :label="column.buttonTitle ? column.buttonTitle : column.label"
            :type="column.type"
            @click="$emit(column.event, props.row)"
          />
          <b-button
            v-else
            :type="column.type"
            :label="props.row[column.col]"
            @click="$emit(column.event, props.row)"
          />
        </template>

        <template v-if="column.cmp === 'custom-username'">
          <div
            v-if="props.row.platformName"
            class="multibar row-vcentered"
          >
            <component
              :is="`UbuIcon${props.row.platformName.capitalize()}`"
              class="start"
              font-size="16px"
            />
            <span class="start ml-2 text-truncate">@{{ props.row[column.col] }}</span>
          </div>
          <span v-else>-</span>
        </template>

        <template v-if="column.cmp === 'tribe'">
          <template v-if="column.nested">
            <b-icon
              v-if="props.row[column.base][column.nestedKey1] !== null"
              pack="ubu"
              icon=" ubu-circle-divider"
              :class="[{
                'has-text-success': props.row[column.base][column.nestedKey1],
                'has-text-danger': !props.row[column.base][column.nestedKey1],
              }]"
            />
            <span v-else>-</span>
          </template>
          <b-icon
            v-else
            pack="ubu"
            icon=" ubu-circle-divider"
            :class="[{
              'has-text-success': props.row[column.col],
              'has-text-danger': !props.row[column.col],
            }]"
          />
        </template>

        <template v-if="column.cmp === 'flat-has-icon'">
          <div
            v-if="props.row[column.keyRequired]"
            class="multibar row-vcentered"
          >
            <b-icon
              :class="
                `ubu-custom-influence text-color-${props.row[column.col][column.nestedKey2]} start`"
            />
            <span class="start">{{ props.row[column.col][column.nestedKey1] }}</span>
          </div>
          <span v-else>-</span>
        </template>

        <component
          :is="column.cmpToUse"
          v-if="column.cmp === 'component'"
          :value="props.row"
        />
      </template>
    </b-table-column>

    <template #detail="props">
      <DevDetails
        open
        :cnt="{name: 'props.row', obj: props.row}"
      />
    </template>
  </b-table>
</template>

<script>
import { nFormatter } from '@dailyplanet/utils/formate';
import { mapGetters } from 'vuex';

export default {
  name: 'TheTable',
  props: {
    leadList: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    checkedRows: {
      type: Array,
      required: true,
    },
    selected: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    sortField: {
      type: String,
      required: true,
    },
    detailKey: {
      type: String,
      default: 'cid',
    },
    sortOrder: {
      type: String,
      required: true,
    },
    defaultSortOrder: {
      type: String,
      required: true,
    },
    checkable: {
      type: Boolean,
      default: true,
    },
    paginated: {
      type: Boolean,
      default: true,
    },
    stickyHeader: {
      type: Boolean,
      default: true,
    },
    backendSorting: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultOpenedDetails: [],
    };
  },
  computed: {
    ...mapGetters({
      isActive: 'DevDetails/isActive',
    }),
    localSelected: {
      get() { return this.selected; },
      set(newValue) { this.$emit('update:selected', newValue); },
    },
    localChecked: {
      get() { return this.checkedRows; },
      set(newValue) { this.$emit('update:checkedRows', newValue); },
    },
  },
  // watch: {
  //   leadList() {
  //     console.log('🚀 ~ file: TheTable.vue ~ line 508 ~ leadList ~ leadList', this.leadList);
  //     if (!this.localChecked.length) return;
  //     const mapped = this.localChecked.arrayMapper(this.detailKey);
  //     this.localChecked = this.leadList.filter((row) => mapped[row[this.detailKey]]);
  //   },
  // },
  methods: {
    onSort(field, order) {
      this.$emit('onSort', [field, order]);
    },
    shouldUseCustomSort(cmp) {
      return ['date', 'shortenNumber'].includes(cmp);
    },
    customSort(rowA, rowB, isAsc, column) {
      let a;
      let b;

      if (column.nested) {
        a = rowA[column.base] ? rowA[column.base][column.nestedKey1] : null;
        b = rowB[column.base] ? rowB[column.base][column.nestedKey1] : null;
      } else {
        a = rowA[column.col];
        b = rowB[column.col];
      }

      if (column.cmp === 'date' && isAsc) return this.$moment(a) - this.$moment(b);
      if (column.cmp === 'date' && !isAsc) return this.$moment(b) - this.$moment(a);

      if (column.cmp === 'shortenNumber' && isAsc) return a - b;
      if (column.cmp === 'shortenNumber' && !isAsc) return b - a;

      return null;
    },
    shortNumber(nb) {
      return nFormatter(Number(nb), 1);
    },
    roundNumber(nb) {
      return Math.round(nb * 100) / 100;
    },
    // customIsChecked(a, b) {
    //   return a[this.detailKey] === b[this.detailKey];
    // },
  },
};
</script>
