var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.account)?_c('section',{staticClass:"ubuSettingView start"},[_c('header',{staticClass:"start"},[_c('h2',{staticClass:"subtitle is-1 has-text-secondary m-0"},[_vm._v(_vm._s(_vm.account.owner.toUpperCase()))]),_vm._m(0)]),_c('br'),_c('DevDetails',{attrs:{"cnt":{name: 'accountMemberViewList', obj: _vm.accountMemberViewList}}}),_c('DevDetails',{attrs:{"cnt":{name: 'allChannelGroupAccountNewVersion', obj: _vm.allChannelGroupAccountNewVersion}}}),_c('b-table',{staticClass:"ubuTableSetting",attrs:{"data":Object.values(_vm.allChannelGroupAccountNewVersion),"opened-detailed":_vm.defaultOpenedDetails,"detailed":"","detail-key":"uid","has-detailed-visible":() => false,"hoverable":"","is-row-selectable":() => true,"show-header":false},scopedSlots:_vm._u([{key:"detail",fn:function(props){return [_c('legend',{staticClass:"has-text-secondary subtitle is-3"},[_vm._v("RIGHTS")]),_c('b-field',[_c('b-switch',{on:{"input":function($event){return _vm.checkIsAdmin($event, props.row.uid)}},model:{value:(props.row.isAdmin),callback:function ($$v) {_vm.$set(props.row, "isAdmin", $$v)},expression:"props.row.isAdmin"}},[_vm._v(" Admin ")])],1),_c('br'),_c('DevDetails',{attrs:{"cnt":{name: 'props.row', obj: props.row}}}),_c('legend',{staticClass:"has-text-secondary subtitle is-3"},[_vm._v("CHANNELS")]),_vm._l((props.row.editableItem),function(row){return _c('div',{key:row.channelGroup.cgid},[_c('UbuChannelGroupAndChannelSelector',{attrs:{"model":JSON.stringify(row)},on:{"updateGroup":function($event){return _vm.updateGroupNextGen({
            event: $event,
            uid: props.row.uid,
            aid: _vm.currentAid,
          })}}})],1)})]}}],null,false,2168004846)},_vm._l((_vm.columns),function(column){return _c('b-table-column',{key:column.col,attrs:{"label":column.label,"field":column.col,"sortable":column.isSortable,"cell-class":column.cellClass},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(column.cmp === 'action')?[_c('b-button',{staticClass:"is-borderless",attrs:{"type":"is-ghost","pack":"ubu","icon-pack":"ubu","icon-left":_vm.defaultOpenedDetails.includes(props.row.uid)
            ? 'ubu-arrow-down'
            : 'ubu-arrow-up'},on:{"click":function($event){$event.preventDefault();return props.toggleDetails(props.row)}}})]:_vm._e(),(column.cmp === 'img')?void 0:_vm._e(),(column.cmp === 'flat')?[_c('p',{staticClass:"ellipsis text is-3 mb-1"},[_vm._v(_vm._s(props.row[column.col]))]),_c('p',{staticClass:"ellipsis subtext is-3 has-text-secondary"},[_vm._v(" "+_vm._s(props.row[column.secondCold])+" ")])]:_vm._e(),(column.cmp === 'tag')?[(props.row[column.tag.condition])?_c('b-tag',{attrs:{"type":column.tag.color}},[_vm._v(" "+_vm._s(column.tag.text)+" ")]):_vm._e()]:_vm._e(),(column.cmp === 'button')?[_c('div',{staticClass:"multibar"},[_c('b-button',{staticClass:"end",attrs:{"label":column.button.label,"type":column.button.type},on:{"click":function($event){$event.preventDefault();return _vm.createLink(props.row.uid)}}})],1)]:_vm._e()]}}],null,true)})}),1)],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"multibar"},[_c('h1',{staticClass:"start title is-1"},[_vm._v("Account members")])])
}]

export { render, staticRenderFns }