/* eslint-disable max-len */
import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    activityOverviewList: (_state, _getters, _rootState, rootGetters) => {
      const {
        'ApiInfluence/activityOverview/getterActivityOverviewList': activityOverviewList,
        'ApiContact/contactPlatformOverview/getterContactPlatformOverviewList': contactPlatformOverviewList,
      } = rootGetters;

      return Object.values(activityOverviewList).map((cpid) => contactPlatformOverviewList[cpid]);
    },
  },
  actions: {
    reportActivityOverview({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiInfluence/activityOverview/report',
        context: { payload: { cgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'report campaign activities overview',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report campaign activities overview.', indefinite: true }));
    },
  },
};
