import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    invite(_, { payload, httpQuery = {} }) {
      return clark.api.apiCrew.crewUser.invite({ payload, httpQuery })
        .then((token) => token);
    },
  },
};
