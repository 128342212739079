<template>
  <aside class="ubuSidePanel ubuPanel-xl">
    <div
      v-if="isWizardApplied"
      class="multibar row-vcentered"
    >
      <b-button
        v-if="routeForPreviousButton.path && activeTab === 0"
        class="start is-ghost is-borderless"
        icon-pack="ubu"
        icon-left="ubu-arrow-left"
        label="Previous step"
        tag="router-link"
        :to="routeForPreviousButton"
      />

      <b-button
        v-else-if="routeForPreviousButton.path && activeTab >= 1"
        class="start is-ghost is-borderless"
        icon-pack="ubu"
        icon-left="ubu-arrow-left"
        label="Previous step"
        @click="activeTab -= 1"
      />

      <b-button
        :to="{ path: '/crm', query: $route.query }"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
        tag="router-link"
      />
    </div>
    <div class="ubuSidePanel-content">
      <router-view
        name="bulkActionsHome"
        :account-features="accountFeatures"
      />

      <router-view
        v-model="localChecked"
        :active-tab.sync="activeTab"
        name="addToContactLabel"
        @reloadCRM="$emit('reloadCRM')"
        @isWizardApplied="isWizardApplied = $event"
      />

      <router-view
        v-model="localChecked"
        :active-tab.sync="activeTab"
        name="addToCampaign"
        @reloadCRM="$emit('reloadCRM')"
        @isWizardApplied="isWizardApplied = $event"
      />

      <router-view
        v-model="localChecked"
        :active-tab.sync="activeTab"
        name="addToList"
        @reloadCRM="$emit('reloadCRM', $event)"
        @isWizardApplied="isWizardApplied = $event"
      />
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheBulkActionsPanel',
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      isWizardApplied: true,
    };
  },
  computed: {
    ...mapGetters({
      accountFeatures: 'TheCRM/accountFeatures',
    }),
    routeForPreviousButton() {
      const { path } = this.$route;
      const pathList = path
        // transform string to array
        .split('/')
        // remove empty [0] and last element [-1]
        .slice(1, -1);

      return { path: `/${pathList.join('/')}`, query: this.$route.query };
    },
    localChecked: {
      get() { return this.checkedRows; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
  mounted() {
    if (!this.checkedRows.length) { this.$router.push({ path: '/crm', query: this.$route.query }); }
  },
};
</script>
