export default {
  data() {
    return {
      tooltipValue: false,
      tooltipPosition: 'left',
    };
  },

  watch: {
    tooltipPosition(position) {
      this.displayToolipByPosition(position);
    },
  },

  mounted() {
    this.getAttributeOfElement();
  },

  methods: {
    getAttributeOfElement() {
      const dataTooltipAttr = document.querySelectorAll('[data-tooltip-hover]');

      dataTooltipAttr.forEach((element) => {
        element.addEventListener('mouseover', (e) => {
          const attrValue = e.target.parentElement.attributes['data-tooltip-hover'].value;
          const attrPosition = e.target.parentElement.attributes['data-tooltip-position'].value;
          this.tooltipValue = attrValue;
          this.tooltipPosition = attrPosition;
        });
        element.addEventListener('mouseleave', () => {
          this.tooltipValue = '';
          this.tooltipPosition = '';
        });
      });
    },
    displayToolipByPosition(position) {
      document.addEventListener('mousemove', (e) => {
        let x;
        let y;
        let tw2;
        let th2;

        const tooltip = document.getElementById('tooltip-dynamic');

        const tw = tooltip.offsetWidth;
        const th = tooltip.offsetHeight;
        const { pageX } = e;
        const { pageY } = e;

        // x = pageX;
        // y = pageY;

        if (position === 'left') {
          tw2 = tw / 5;
          th2 = th / 2;
          x = pageX - tw - tw2;
          y = pageY - th + th2;
        }
        if (position === 'top') {
          tw2 = tw / 2;
          th2 = th / 2;
          x = pageX - tw + tw2;
          y = pageY - th - th2;
        }
        if (position === 'right') {
          tw2 = tw / 5;
          th2 = th / 2;
          x = pageX + tw2;
          y = pageY - th + th2;
        }
        if (position === 'bottom') {
          tw2 = tw / 2;
          th2 = th / 2;
          x = pageX - tw + tw2;
          y = pageY + th2;
        }

        tooltip.style.left = `${x}px`;
        tooltip.style.top = `${y}px`;
      });
    },
  },
};
