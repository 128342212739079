export default {
  computed: {
    messageLink() {
      if (this.message) {
        if (this.message.link) return this.message.link;
        if (this.message.medias && this.message.medias.length) return this.message.medias[0].url;
      }
      return undefined;
    },
  },
};
