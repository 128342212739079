<template>
  <div class="buttons is-right">
    <b-button
      v-if="prev"
      type="is-ghost is-borderless"
      icon-pack="ubu"
      icon-left="ubu-arrow-left"
      :disabled="localActiveStep === 0"
      @click.prevent="localActiveStep === 1 ? localActiveStep = 0 : localActiveStep -= 1"
    >
      Previous
    </b-button>
    <b-button
      v-if="next"
      type="is-ghost is-borderless"
      icon-pack="ubu"
      icon-right="ubu-arrow-right"
      :disabled="!nextIsAllowed"
      @click.prevent="localActiveStep += 1"
    >
      Next
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'UbuSteperControls',
  props: {
    activeStep: {
      type: Number,
      required: true,
    },
    nextIsAllowed: {
      type: Boolean,
      required: true,
    },
    prev: {
      type: Boolean,
      default: true,
    },
    next: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    localActiveStep: {
      get() { return this.activeStep; },
      set(newValue) { this.$emit('update:activeStep', newValue); },
    },
  },
};
</script>
