<template>
  <section>
    <b-field label="Name">
      <b-input
        v-model="workspace.name"
        placeholder="My workspace..."
        required
        @input="$update('name', workspace.name)"
      />
    </b-field>

    <!-- <br>

    <b-field grouped>
      <section class="multibar row-vcentered">
        <div class="content has-text-centered">
          <p>
            <b-icon
              pack="ubu"
              icon="ubu-image"
              class="title"
            />
          </p>
        </div>
      </section>

      <b-upload
        v-model="workspace.picture"
        accept=".jpg, .jpeg, .png"
        drag-drop
        expanded
        @input="$update('picture', workspace.picture)"
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon
                pack="ubu"
                icon="ubu-download"
                size="is-large"
              />
            </p>
            <span>Edit workspace picture</span>
          </div>
        </section>
      </b-upload>
    </b-field> -->
  </section>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';

export default {
  name: 'UbuWorkspaceInfoForm',
  mixins: [VModelMixin],
  model: {
    prop: 'workspace',
  },
  props: {
    workspace: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>

</style>
