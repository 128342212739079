<template>
  <div>
    <p class="text has-text-secondary">
      For now, you can only send messages to people on Instagram.
      You can add people from TikTok or YouTube to scan their mentions in a next step.
    </p>

    <br>

    <div class="buttons is-right">
      <b-button
        label="Next"
        type="is-primary"
        :disabled="!timeZoneFilter.selected || !localChecked.length"
        @click="$emit('next')"
      />
    </div>

    <b-message
      v-show="!localChecked.length"
      type="is-danger"
    >
      <p>You must select at least one person to send a message to.</p>
      <p>
        Don't want to send messages?
        Go back to previous step and choose a "Add without message" option.
      </p>
    </b-message>

    <b-field label="Time zone">
      <UbuAutocomplete
        v-model="timeZoneFilter"
        :autofocus="true"
        :options="filteredTimeZonePreset"
        placeholder="Time Zone"
        @select="localBulkAction = {...localBulkAction, timezone: $event}"
      />
    </b-field>

    <br>

    <UbuSearchbar
      v-model="search"
      :autofocus="false"
      @clear="search = ''"
    />

    <br>

    <b-table
      :data="instagramersToReach"
      checkable
      :checked-rows.sync="localChecked"
      paginated
      pagination-size="is-small"
    >
      <b-table-column
        v-slot="{ row }"
        field="username"
        label="Username"
        sortable
      >
        <UbuPlatformCard
          :platform-name="row.contactPlatform.platformName.toLowerCase()"
          :text="row.contactPlatform.username"
          font-size="20px"
        />
      </b-table-column>

      <template #empty>
        <p class="text has-text-secondary">
          You don't select contacts matching these criterias.
        </p>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import momentTz from 'moment-timezone';

import TheAddToCampaignMixin from '../mixins/TheAddToCampaign.mixin';

export default {
  name: 'TheAddToCampaignToSendMessage',
  mixins: [TheAddToCampaignMixin],
  data() {
    return {
      timeZoneFilter: {
        preset: momentTz.tz.names(),
        selected: null,
        value: '',
      },
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      newBulkAction: 'TheNewAddToCampaign/newBulkAction',
      instagramersToSend: 'TheNewAddToCampaign/instagramersToSend',
    }),

    localChecked: {
      get() { return this.instagramersToSend; },
      set(newValue) { this.UP_instagramersToSend(newValue); },
    },

    localBulkAction: {
      get() { return this.newBulkAction; },
      set(newValue) { this.UP_newBulkAction(newValue); },
    },

    filteredTimeZonePreset() {
      if (!this.timeZoneFilter) return undefined;

      return this.timeZoneFilter.preset.filter((option) => option
        .toString()
        .toLowerCase()
        .indexOf(this.timeZoneFilter.value.toLowerCase()) >= 0);
    },
  },
  mounted() {
    // get user timezone from his browser
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // allowed timezone list
    const timezones = momentTz.tz.names();

    if (tz && timezones.includes(tz)) {
      this.timeZoneFilter.selected = tz;
      this.timeZoneFilter.value = tz;
      this.localBulkAction = { ...this.localBulkAction, timezone: tz };
    }

    if (!this.instagramersToSend.length) {
      this.localChecked = this.instagramersToReach;
    }
  },
  methods: {
    ...mapActions({
      UP_newBulkAction: 'TheNewAddToCampaign/UP_newBulkAction',
      UP_instagramersToSend: 'TheNewAddToCampaign/UP_instagramersToSend',
    }),
  },
};
</script>
