/* eslint-disable max-len */
import { getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  getters: {
    ...getters.channelGroup,
  },
  actions: {
  },
};
