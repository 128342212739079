<template>
  <UbuDropdown
    class="filterDropdown"
    :close-on-click="closeOnClick"
    position="is-bottom-right"
    scrollable
    :is-disabled="isDisabled"
    @open="isOpen = true; closeOnClick = false;"
    @close="reset()"
  >
    <template #button>
      <b-button
        :label="buttonLabel"
        type="is-secondary"
        icon-pack="mdi"
        icon-left="sort"
        :disabled="isDisabled"
      />
    </template>

    <template
      v-if="activeTab !== 'root'"
      #header
    >
      <UbuDropdownBreadcrumbs
        class="mb-2"
        :breadcrumbs-options="breadcrumbs"
        :current-breadcrumb="breadcrumbs[breadcrumbs.length - 1]"
        @goBack="useBreadcrumb($event)"
      />
    </template>

    <template #search>
      <b-dropdown-item
        v-if="showSearchbar"
        custom
        paddingless
        class="mb-2"
      >
        <UbuSearchbar
          v-if="isOpen"
          v-model="search"
          @clear="search = ''"
        />
      </b-dropdown-item>
    </template>

    <template v-slot:content>
      <b-tabs
        v-model="activeTab"
        class="paddingless no-margin no-header"
      >
        <b-tab-item value="root">
          <b-dropdown-item
            v-for="(item) in filteredFilterList"
            :key="item.sortField"
            :class="[{ 'is-active': localSelectedSort.sortField === item.sortField }]"
            @click="selectKeyAndChangeTab(item)"
          >
            {{ item.label }}
          </b-dropdown-item>
        </b-tab-item>

        <b-tab-item value="root.selectValue">
          <b-dropdown-item @click="applyFilter('asc')">
            <!-- <b-field>
              <b-radio
                v-model="localSelectedSort.sortOrder"
                native-value="asc"
              >
                <span v-if="sortKeyType">{{ sorting[sortKeyType].asc }}</span>
              </b-radio>
            </b-field> -->
            <span v-if="sortKeyType">{{ sorting[sortKeyType].asc }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="applyFilter('desc')">
            <!-- <b-field>
              <b-radio
                v-model="localSelectedSort.sortOrder"
                native-value="desc"
              >
                <span v-if="sortKeyType">{{ sorting[sortKeyType].desc }}</span>
              </b-radio>
            </b-field> -->
            <span v-if="sortKeyType">{{ sorting[sortKeyType].desc }}</span>
          </b-dropdown-item>
        </b-tab-item>
      </b-tabs>
    </template>
  </UbuDropdown>
</template>

<script>
/* eslint-disable max-len */
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheSortingDropdown',
  mixins: [FilterEngineMixin],
  model: {
    prop: 'selectedSort',
  },
  props: {
    selectedSort: {
      type: Object,
      required: true,
    },
    sortKeyList: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 'root',
      breadcrumbs: [{ label: 'Filters', tabToGo: 'root' }],
      search: '',
      isOpen: false,
      closeOnClick: false,
      sortField: null,
      sortKeyType: null,
      sorting: {
        number: {
          asc: 'increasing',
          desc: 'decreasing',
        },
        string: {
          asc: 'A to Z',
          desc: 'Z to A',
        },
        date: {
          asc: 'oldest first',
          desc: 'newest first',
        },
      },
    };
  },
  computed: {
    localSelectedSort: {
      get() { return this.selectedSort; },
      set(values) {
        this.$emit('input', values);
      },
    },

    _filterCollection() {
      return {
        /**
       * @method  searchByLabel Count
       * @params  {Number}   rawMax  maximum to be compared to
       * @returns {Function}
       * */
        searchByLabel(rawSearch) {
          const search = rawSearch.toLowerCase();
          return (m) => (m.label.toLowerCase().includes(search) ? m : undefined);
        },
      };
    },

    filteredFilterList() {
      const filtersToApply = {};

      if (this.search) filtersToApply.searchByLabel = this.search;

      return this.filterEngine(Object.values(this.sortKeyList), filtersToApply);
    },

    showAction() {
      return this.activeTab === 'root.selectValue';
    },

    showSearchbar() {
      return this.activeTab === 'root';
    },

    buttonLabel() {
      const order = this.sorting[this.localSelectedSort.sortKeyType][this.localSelectedSort.sortOrder];
      // let disclaimer = order;

      // console.log('🚀 ~ file: TheSortingDropdown.vue ~ line 169 ~ buttonLabel ~ this.localSelectedSort.sortKeyType', this.localSelectedSort.sortKeyType);
      // switch (this.localSelectedSort.sortKeyType) {
      //   case 'number': disclaimer = order === 'ascending' ? 'increasing' : 'decreasing'; break;
      //   case 'string': disclaimer = order === 'ascending' ? 'a to z' : 'z to a'; break;
      //   case 'date': disclaimer = order === 'ascending' ? 'oldest first' : 'newest first'; break;
      //   default: disclaimer = order; break;
      // }
      return `${this.sortKeyList[this.localSelectedSort.sortField].label.toLowerCase()} ${order}`;
    },
  },
  methods: {
    selectKeyAndChangeTab({ sortField, label, sortKeyType }) {
      this.sortField = sortField;
      this.sortKeyType = sortKeyType;
      this.search = '';

      this.activeTab = 'root.selectValue';
      this.breadcrumbs.push({ label, tabToGo: 'root.selectValue' });
      this.closeOnClick = true;
    },

    applyFilter(sortOrder) {
      this.localSelectedSort = {
        sortField: this.sortField,
        sortKeyType: this.sortKeyType,
        sortOrder,
      };
      this.reset();
    },

    reset() {
      this.breadcrumbs = [{ label: 'Field', tabToGo: 'root' }];
      this.activeTab = 'root';
      this.search = '';
      this.isOpen = false;
      this.closeOnClick = false;
    },

    useBreadcrumb({ item, index }) {
      this.activeTab = item.tabToGo;
      this.breadcrumbs = this.breadcrumbs.filter((_, i) => i <= index);
      this.search = '';
      this.closeOnClick = false;
    },
  },
};
</script>
