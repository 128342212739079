/* eslint-disable max-len */
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        {
          path: '/home',
          text: 'What\'s New',
          icon: 'ubu-home',
        },
        {
          path: '/setting',
          text: 'Settings',
          icon: 'ubu-settings',
        },
        {
          path: '/api',
          text: 'Api',
          icon: 'ubu-share',
        },
      ],
    },
  },
  getters: {
    ...getters.apiKey,
    ...getters.channelGroup,
  },
  actions: {
    ...actions.apiKey,
  },
};
