<template>
  <section class="ubuContactPlatformPanelTab">
    <article class="ubuContactPlatformPanelItem">
      <UbuSingleTextarea
        :key="newNote"
        v-model="newNote"
        placeholder="Type a note..."
        custom-class="ubuTransparentInput"
        :maxlength="255"
        @send="createNote($event)"
      />
    </article>

    <article
      v-if="!mappedData.length"
      class="ubuContactPlatformPanelItem"
    >
      <p class="text is-3">Nothing to display yet !</p>
    </article>

    <template v-if="mappedData.length">
      <article
        class="ubuContactPlatformPanelItem"
        style="border-bottom: none;"
      >
        <b-pagination
          v-if="mappedData.length"
          :current="page + 1"
          :per-page="perPage"
          :total="total"
          :range-before="1"
          :range-after="1"
          size="is-small"
          @change="changePage($event)"
        />
      </article>
      <div id="UbuScroller">
        <template v-for="(item, index) in mappedData">
          <div :key="index">
            <fieldset
              v-if="shouldIDisplaySeparator(index)"
              class="dateSeparator text is-3 m-2 mb-3"
            >
              <legend>
                {{ getDay(item.noteDate) }}
              </legend>
            </fieldset>

            <UbuNoteItemLogbook
              v-if="!item.isPrivateNote"
              :item="item"
            />
            <UbuNoteItemPrivate
              v-if="item.isPrivateNote"
              :item="item"
              @updateNote="updateNote($event)"
              @deleteNote="deleteNote($event)"
            />
          </div>
        </template>
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheContactPlatformPanelHistoryTab',
  data() {
    return {
      httpQuery: {
        pagin: [0, 20],
      },
      page: 0,
      total: 0,
      perPage: 20,
      itemList: [],
      isLoading: false,

      payload: {},
      newNote: '',
    };
  },
  computed: {
    ...mapGetters({
      windowWidth: 'TheMenu/getterWindowWidth',
      myAuthenticate: 'TheContactPlatformPanel/myAuthenticate',
      isAllCustomFetched: 'Sequence/isAllCustomFetched',

      contactPlatformOverview: 'TheContactPlatformPanel/contactPlatformOverviewUnhelded',

      campaignList: 'TheContactPlatformPanel/campaignList',
      contactGroupList: 'TheContactPlatformPanel/contactGroupList',
      threadGroupList: 'TheContactPlatformPanel/threadGroupList',
      userList: 'TheContactPlatformPanel/userList',
      crmListList: 'TheContactPlatformPanel/crmListList',
    }),

    mappedData() {
      // eslint-disable-next-line max-len
      if (!this.contactPlatformOverview || !this.contactPlatformOverview.contactNotesForPanel) return [];

      const itemList = Object.values(this.contactPlatformOverview.contactNotesForPanel)
        .map((note, index) => ({
          customIndex: index,
          ...note,
          isPrivateNote: !note.event,
          text: this.getTextFromEvent(note),
          icon: this.getIconFromEvent(note),

          user: note.userUid ? this.userList[note.userUid].userDetail : null,
          threadGroup: note.threadGroupTgid ? this.threadGroupList[note.threadGroupTgid] : null,
          contactGroup: note.contactGroupCgid ? this.contactGroupList[note.contactGroupCgid] : null,
          crmList: note.crmListClid ? this.crmListList[note.crmListClid] : null,
          campaign: note.campaignCid ? this.campaignList[note.campaignCid] : null,

          contactPlatform: note.contactPlatformCpid === this.contactPlatformOverview.cpid
            ? this.contactPlatformOverview.contactPlatform
            : null,
        }))
        .sort((a, b) => (this.$moment(b.noteDate).unix() - this.$moment(a.noteDate).unix()));

      return itemList;
    },
  },
  mounted() {
    // Payload initialization
    this.payload = { cid: this.contactPlatformOverview.contactPlatform.contactCid };
    this.reportContactNotesForPanel();
  },
  methods: {
    ...mapActions({
      _reportContactNotesForPanel: 'TheContactPlatformPanel/reportContactNotesForPanel',

      _createPrivateNote: 'TheContactPlatformPanel/createPrivateNote',
      _updatePrivateNote: 'TheContactPlatformPanel/updatePrivateNote',
      _deletePrivateNote: 'TheContactPlatformPanel/deletePrivateNote',
    }),

    changePage(currentPage) {
      this.page = currentPage - 1;
      this.reportContactNotesForPanel();
    },

    reportContactNotesForPanel() {
      return this._reportContactNotesForPanel({
        payload: this.payload,
        httpQuery: { pagin: [this.page, this.perPage] },
      })
        .then(({ results, total }) => {
          this.total = total;
          this.itemList = results;
        });
    },

    createNote(newNote) {
      this.newNote = newNote;
      this._createPrivateNote({
        payload: {
          contactCid: this.contactPlatformOverview.contactPlatform.contactCid,
          contactPlatformCpid: this.contactPlatformOverview.contactPlatform.cpid,
          userUid: this.myAuthenticate.uid,
          text: newNote,
        },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_createPrivateNote',
          payload: {
            cpids: [this.contactPlatformOverview.contactPlatform.cpid],
          },
        }],
      })
        .then(() => { this.newNote = ''; });
    },

    updateNote(item) {
      // need to restructure a REST format payload to update
      const payload = {
        pnid: item.originalId,
        channelGroupCgid: item.channelGroupCgid,
        contactCid: item.contactCid,
        contactPlatformCpid: item.contactPlatformCpid,
        userUid: item.userUid,
        text: item.text,
        createdOn: item.noteDate,
        updatedOn: this.$moment().format(),
        deletedOn: null,
      };

      this._updatePrivateNote({
        payload,
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_updatePrivateNote',
          payload: {
            note: { ...item, updatedOn: this.$moment().format() },
            cpids: [this.contactPlatformOverview.contactPlatform.cpid],
          },
        }],
      })
        .then(() => { this.isModalNoteOpen = false; });
    },

    deleteNote(item) {
      this._deletePrivateNote({
        payload: { pnid: item.originalId },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_removePrivateNote',
          payload: {
            note: item,
            cpids: [this.contactPlatformOverview.contactPlatform.cpid],
          },
        }],
      })
        .then(() => { this.isModalNoteOpen = false; });
    },

    getDay(day) {
      return this.$moment(day).calendar(null, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'YYYY-MM-DD',
      });
    },

    shouldIDisplaySeparator(index) {
      return index === 0 || this.$moment(this.mappedData[index - 1].noteDate).format('YY-MM-DD') !== this.$moment(this.mappedData[index].noteDate).format('YY-MM-DD');
    },

    getTextFromEvent(item) {
      /* eslint-disable no-multi-spaces */
      switch (item.event) {
        case 'THREAD_READ':            return 'Thread read';
        case 'THREAD_UNREAD':          return 'Thread unread';
        case 'THREAD_OPENED':          return 'Thread opened';
        case 'THREAD_CLOSED':          return 'Thread closed';
        case 'THREAD_DONE':            return 'Thread done';
        case 'THREAD_GROUP_ASSIGN':    return 'Added tag';
        case 'THREAD_GROUP_UNASSIGN':  return 'Removed tag';
        case 'CONTACT_GROUP_ASSIGN':   return 'Added label';
        case 'CONTACT_GROUP_UNASSIGN': return 'Removed label';
        case 'CRM_LIST_ASSIGN':        return 'Added to list';
        case 'CRM_LIST_UNASSIGN':      return 'Removed from list';
        case 'THREAD_APPOINTMENT':     return 'Assigned';
        case 'THREAD_UNAPPOINTMENT':   return 'Unassigned';
        case 'ACTIVITY_UPDATE':        return `Set status to ${item.text.toString().split('_').map((t) => t.capitalize()).join(' ')}`;
        case 'ACTIVITY_END':           return 'Removed from campaign';
        case 'ACTIVITY_CREATE':        return 'Added to campaign';
        case 'THREAD_AUTO_CLOSED':     return 'Thread auto-closed';
        case 'THREAD_AUTO_DONE':       return 'Thread auto-done';
        case 'THREAD_MUTED':           return 'Thread muted';
        case 'THREAD_UNMUTED':         return 'Thread unmuted';
        default:                       return item.text;
      }
      /* eslint-enable no-multi-spaces */
    },
    getIconFromEvent(item) {
      /* eslint-disable no-multi-spaces */
      switch (item.event) {
        case 'THREAD_READ':            return null;
        case 'THREAD_UNREAD':          return null;
        case 'THREAD_OPENED':          return null;
        case 'THREAD_CLOSED':          return null;
        case 'THREAD_DONE':            return null;
        case 'THREAD_GROUP_ASSIGN':    return 'ubu-custom-tag';
        case 'THREAD_GROUP_UNASSIGN':  return 'ubu-custom-tag';
        case 'CONTACT_GROUP_ASSIGN':   return 'ubu-custom-contact';
        case 'CONTACT_GROUP_UNASSIGN': return 'ubu-custom-contact';
        case 'CRM_LIST_ASSIGN':        return 'ubu-custom-lists';
        case 'CRM_LIST_UNASSIGN':      return 'ubu-custom-lists';
        case 'THREAD_APPOINTMENT':     return null;
        case 'THREAD_UNAPPOINTMENT':   return null;
        case 'ACTIVITY_UPDATE':        return 'ubu-custom-influence';
        case 'ACTIVITY_END':           return 'ubu-custom-influence';
        case 'ACTIVITY_CREATE':        return 'ubu-custom-influence';
        case 'THREAD_AUTO_CLOSED':     return null;
        case 'THREAD_AUTO_DONE':       return null;
        case 'THREAD_MUTED':           return null;
        case 'THREAD_UNMUTED':         return null;
        default:                       return null;
      }
      /* eslint-enable no-multi-spaces */
    },
  },
};
</script>
