<template>
  <UbuDropdown
    :close-on-click="false"
    :scrollable="true"
    :position="position"
    :is-disabled="isDisabled"
    @open="isOpen = true"
    @close="reset(); isOpen = false;"
  >
    <template v-slot:button>
      <b-tooltip
        position="is-bottom"
        :multilined="!!customsSplitByAssignment.assigneds.length"
        size="small"
      >
        <template v-slot:content>
          <template v-if="customsSplitByAssignment.assigneds.length">
            <UbuCustomItemRow
              v-for="(item, index) in customsSplitByAssignment.assigneds"
              :key="index"
              :custom="item"
              :icon="icon"
            />
          </template>

          <span v-else-if="isDisabled">No thread yet !</span>
          <span v-else>Add tag</span>
        </template>
        <UbuCustomButton
          :customs="_cpo[customsName].map((id) => customs[id])"
          :custom-default="{ icon }"
          :active-tooltip="true"
          :window-width="windowWidth"
          :is-disabled="isDisabled"
        />
      </b-tooltip>
    </template>

    <template
      v-if="isOpen"
      v-slot:search
    >
      <b-dropdown-item custom>
        <UbuSearchbar
          v-model="newCustom.label"
          placeholder="Search or create..."
          @clear="reset()"
          @input="updateFilter($event)"
        />
      </b-dropdown-item>
    </template>

    <template v-slot:content>
      <b-tabs
        v-model="activeTab"
        class="no-header paddingless"
      >
        <UbuDropdownCustomSelector
          :key-name="keyName"
          :search-label="newCustom.label"
          :assigneds="customsSplitByAssignment.assigneds"
          :unassigneds="customsSplitByAssignment.unassigneds"
          :icon-name="icon"
          :button-label="buttonLabel"
          @unassignAll="unassignAll()"
          @selectCustom="assignCustom($event)"
          @unselectCustom="unassignCustom($event)"
          @changeTab="activeTab = $event"
        />

        <UbuDropdownCustomCreator
          v-model="newCustom"
          :label="buttonLabel"
          @create="createCustom()"
        />
      </b-tabs>
    </template>
  </UbuDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import CustomDropdownMixin from '../_mixins/customsDropdown.mixin';

export default {
  name: 'TheTagPopover',
  mixins: [CustomDropdownMixin],
  props: {
    position: {
      type: String,
      default: 'is-bottom-left',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Functionnal
      activeTab: 'root',
      newCustom: {
        label: '',
        color: '',
      },

      isOpen: false,

      // Settings
      keyName: 'tgid',
      customsName: 'tags',
      icon: 'ubu-custom-tag',
      buttonLabel: 'Create Tag',
    };
  },
  computed: {
    ...mapGetters({
      _cpo: 'TheContactPlatformPanel/contactPlatformOverview',
      customs: 'TheTagPopover/threadGroupList',
      windowWidth: 'TheMenu/getterWindowWidth',
    }),
  },
  methods: {
    ...mapActions({
      _createCustom: 'TheTagPopover/createThreadGroup',
      _assignCustom: 'TheTagPopover/_assignThreadGroup',
      _unassignCustom: 'TheTagPopover/_unassignThreadGroup',
    }),
  },
};
</script>
