<template>
  <UbuDropdown
    :close-on-click="false"
    :scrollable="true"
    append-to-body
    position="is-bottom-left"
    @open="isOpen = true"
    @close="activeTab = 'root'; isOpen = false;"
  >
    <template v-slot:button>
      <b-icon
        class="end clickable"
        pack="ubu"
        icon="ubu-more-vertical"
        @click.native="$emit('click')"
      />
    </template>

    <template #header>
      <UbuDropdownBreadcrumbs
        class="mb-2"
        :breadcrumbs-options="breadcrumbs"
        :current-breadcrumb="breadcrumbs[breadcrumbs.length - 1]"
        @goBack="useBreadcrumb($event)"
      />
    </template>

    <template #search>
      <b-dropdown-item
        v-if="activeTab === 'root.moveToGroup'"
        custom
        paddingless
        class="mb-2"
      >
        <UbuSearchbar
          v-model="search"
          @clear="search = ''"
        />
      </b-dropdown-item>
    </template>

    <template v-slot:content>
      <b-tabs
        v-if="isOpen"
        v-model="activeTab"
        class="no-header paddingless"
      >
        <b-tab-item value="root">
          <b-dropdown-item
            v-for="option in options"
            :key="option.event"
            @click="handleChoice(option)"
          >
            <div class="multibar row-vcentered">
              <b-icon
                class="start"
                pack="ubu"
                :icon="option.icon"
              />
              <span class="start">{{ option.label }}</span>
            </div>
          </b-dropdown-item>
        </b-tab-item>

        <b-tab-item value="root.moveToGroup">
          <b-dropdown-item @click="changeGroup(null)">
            None
          </b-dropdown-item>

          <b-dropdown-item
            v-for="mg in filteredList"
            :key="mg.mgid"
            @click="changeGroup(mg)"
          >
            <div class="multibar row-vcentered">
              <b-icon
                class="start"
                pack="ubu"
                :icon="mg.icon"
              />
              <span class="start">{{ mg.name }}</span>
            </div>
          </b-dropdown-item>
        </b-tab-item>
      </b-tabs>
    </template>
  </UbuDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheTribePeopleColumnRowDropdrown',
  mixins: [FilterEngineMixin],
  model: {
    prop: 'item',
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 'root',
      search: '',
      isOpen: false,

      options: [
        // { event: 'sendMessage', label: 'Send message', icon: 'ubu-compose' },
        // { event: 'addToCampaign', label: 'Add to campaign', icon: 'ubu-add' },
        { event: 'moveToGroup', label: 'Move to group', icon: 'ubu-folder' },
        { event: 'removeFromTribe', label: 'Remove from Tribe', icon: 'ubu-trash' },
      ],

      localFilter: {},

      breadcrumbs: [{ label: 'Options', tabToGo: 'root' }],
    };
  },
  computed: {
    ...mapGetters({
      memberGroupList: 'TheTribe/memberGroupList',
    }),
    filteredList() {
      const mgList = Object.values(this.memberGroupList)
        .filter(({ mgid }) => this.item.memberGroupMgid !== mgid);

      if (!mgList.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByName = this.search;

      return this.filterEngine(mgList, filtersToApply);
    },

  },

  methods: {
    ...mapActions({
      updateTribeMember: 'TheTribe/updateTribeMember',
      _deleteTribeMember: 'TheTribe/deleteTribeMember',
    }),

    handleChoice(choice) {
      switch (choice.event) {
        case 'moveToGroup':
          this.activeTab = 'root.moveToGroup';
          this.breadcrumbs.push({ label: 'Move to group', tabToGo: 'root.moveToGroup' });
          break;

        case 'removeFromTribe':
          this.activeTab = 'root';
          this.deleteTribeMember();
          break;

        default:
          break;
      }
    },
    changeGroup(memberGroup) {
      const {
        contactPlatform,
        tribeMember,
      } = this.item;

      return this.updateTribeMember({
        payload: {
          tid: this.$route.params.tribeTid,
          tribeMemberList: [{
            ...tribeMember,
            memberGroupMgid: memberGroup ? memberGroup.mgid : null,
          }],
        },
      })
        .then(() => {
          let message;

          if (!memberGroup) message = `${contactPlatform.username} moved to no group`;
          else message = `${contactPlatform.username} moved to group ${memberGroup.name}`;

          this.activeTab = 'root';
          this.$buefy.snackbar.open({
            message,
            type: 'is-success',
            position: 'is-top',
          });
        });
    },
    deleteTribeMember() {
      const {
        contactPlatform,
      } = this.item;

      return this._deleteTribeMember({
        payload: {
          tid: this.$route.params.tribeTid,
          tmids: [this.item.tmid],
        },
      })
        .then(() => {
          this.$emit('close');
          this.activeTab = 'root';
          this.isOpen = false;
          this.$buefy.snackbar.open({
            message: `${contactPlatform.username} removed from Tribe`,
            type: 'is-success',
            position: 'is-top',
          });
        });
    },

    useBreadcrumb({ item, index }) {
      this.activeTab = item.tabToGo;
      this.breadcrumbs = this.breadcrumbs.filter((_, i) => i <= index);
      this.search = '';
    },
  },
};
</script>
