/* eslint-disable max-len */

const orderPost = {
  /**
   * GENERIC REQUIRED KEYS
   */
  method: 'POST',

  title: 'Create an order',

  requirementList: [
    'You must have a registered shop to use it.',
    'Shop MUST NOT be registered in Ubu as an "Shopify shop".',
    'The shopSid path parameter is the Ubu\'s internal ID of the shop that you registered.',
    'Order will be linked to at least one existing coupon from Getubu.',
    'You must provide at least one referral or one discount into arrays of payload.',
  ],

  description: [],

  pathRequestParams: {
    version: { key: 'version', type: 'String', required: true },
    sid: { key: 'shopSid', type: 'String', required: true },
  },

  bodyRequestParams: {
    extrefId: { key: 'extrefId', type: 'String', required: true },
    amountLocale: { key: 'amountLocale', type: 'Number', required: true },
    currencyLocale: { key: 'currencyLocale', type: 'Enum', required: true },
    productCount: { key: 'productCount', type: 'Number | NULL', required: true },
    customerEmail: { key: 'customerEmail', type: 'String | NULL', required: true },
    countryCode: { key: 'countryCode', type: 'String | NULL', required: true },
    city: { key: 'city', type: 'String | NULL', required: true },
    createdOn: { key: 'createdOn', type: 'DateTime UTC', required: true },
    referrals: { key: 'referrals', type: 'Array [String] | [Empty]', required: true },
    discounts: { key: 'discounts', type: 'Array [String] | [Empty]', required: true },
  },
  requestUrl: '/api/v1/shop/:sid/order',

  requestExample: `
  const axios = require("axios");

  const payload = {
    "extrefId": "5161615frzrez",
    "amountLocale": 120,
    "currencyLocale": "EUR",
    "productCount": null,
    "customerEmail": null,
    "countryCode": "FRA",
    "city": "Paris",
    "createdOn": "2023-01-16T09:55:02.000Z",
    "referrals": [],
    "discounts": ["EMMA10", "WELCOME20"]
  }

  return axios.request({
    method: 'POST',
    baseURL: "https://gamma.getubu.com/api/v1/shop/:shopSid/order",
    headers: { "x-api-key": "MY_API_KEY" },
    data: payload,
  })
  `,
  responseExpected: `
  HTTP 201 Created`,

  errorList: [
    { code: '401', name: 'E_UNAUTHORIZED_WH_SHOP_ORDER_MISSING_HEADER_API_KEY', reason: 'missing x-api-key from http request headers' },
    { code: '401', name: 'E_UNAUTHORIZED_WH_SHOP_ORDER_INVALID_KEY', reason: 'wrong apiKey format or apiKey revoked' },
    { code: '400', name: 'E_BAD_REQUEST_WH_SHOP_ORDER_SCHEMA', reason: 'wrong payload format' },
    { code: '404', name: 'E_NOT_FOUND_WH_SHOP_ORDER_SHOP', reason: 'shop not found' },
    { code: '422', name: 'E_UNPROCESSABLE_WH_SHOP_ORDER_SHOP_MUST_NOT_BE_TYPE_SHOPIFY', reason: 'retrieved shop is type shopify' },
    { code: '422', name: 'E_UNPROCESSABLE_API_AFFILIATION_ORDER_ORDER_ALREADY_EXISTS', reason: 'order extrefId already exists' },
    { code: '422', name: 'E_UNPROCESSABLE_API_AFFILIATION_ORDER_HAS_NO_CODE', reason: 'No coupon found to link at this order' },
  ],
  /**
   * GENERIC REQUIRED KEYS
   */

  currencyEnum: ['AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTC', 'BTN', 'BWP', 'BYN', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'EUR', 'FJD', 'FKP', 'GBP', 'GEL', 'GGP', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'IMP', 'INR', 'IQD', 'IRR', 'ISK', 'JEP', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LTL', 'LVL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'USD', 'UYU', 'UZS', 'VND', 'VUV', 'WST', 'XAF', 'XAG', 'XAU', 'XCD', 'XDR', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZMW', 'ZWL']
  ,
};

export default orderPost;
