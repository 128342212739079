/* eslint-disable max-len */
import { dispatchAndForward, genericErrorHandler } from '../../$utils/helpers';

export default {
  namespaced: true,
  getters: {
    activitiesStats: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiInfluence/campaignAnalytics/getter_activitiesStats': activitiesStats } = rootGetters;

      return activitiesStats;
    },
    mentionsStats: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiInfluence/campaignAnalytics/getter_mentionsStats': mentionsStats } = rootGetters;

      return mentionsStats;
    },
    salesStats: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiInfluence/campaignAnalytics/getter_salesStats': salesStats } = rootGetters;

      return salesStats;
    },
  },
  actions: {
    report_activitiesStats({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const action = {
        dispatch,
        target: 'ApiInfluence/campaignAnalytics/campaignAnalytics',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery: { ...httpQuery, activitiesStats: true } },
        textNotifier: 'Report analytics List',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report campaign activities analytics.', indefinite: true }));
    },
    report_mentionsStats({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const action = {
        dispatch,
        target: 'ApiInfluence/campaignAnalytics/campaignAnalytics',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery: { ...httpQuery, mentionsStats: true } },
        textNotifier: 'Report analytics List',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report campaign mentions analytics.', indefinite: true }));
    },
    report_salesStats({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const action = {
        dispatch,
        target: 'ApiInfluence/campaignAnalytics/campaignAnalytics',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery: { ...httpQuery, salesStats: true } },
        textNotifier: 'Report analytics List',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report campaign sales analytics.', indefinite: true }));
    },
  },
};
