/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      _threadList: 'TheInbox/threadList',
      heldThreads: 'TheInbox/heldThreads',
    }),
    threadListArray() {
      return Object.values(this._threadList).filter((t) => t && t.tid);
    },
    threadList() {
      const values = this.threadListArray;

      if (!values.length) return [];

      const httpQuery = this.$route.query;

      const {
        inboxType,
        threadByTags,
        threadByLabels,
        threadByCampaigns,
        threadByAppoints,
        threadByTribes,
      } = this.$route.params;

      let activityStatus = null;
      if (httpQuery && httpQuery.activityStatus) {
        activityStatus = httpQuery.activityStatus;
      }

      const notInThisTribe = (tribeMemberList) => {
        if (!threadByTribes) return false;
        if ((!tribeMemberList || !tribeMemberList.length)) return true;
        if ((!tribeMemberList.find(({ tribeTid }) => Number(tribeTid) === Number(threadByTribes)))) return true;
        return false;
      };

      const threadArray = values
        .reduce((acc, item) => {
          const {
            cpid,
            thread: {
              isOpen,
              isRead,
              isImportant,
              isOpportunity,
              isMention,
            },
            lastMessage,
            labels,
            tags,
            lastActivity,
            hasUser,
            tribeMemberList,
          } = item;

          const isReceived = !lastMessage.isMe;
          const campaignCid = (lastActivity && String(lastActivity.campaignCid)) || false;

          if (!httpQuery.search && (this.heldThreads && !this.heldThreads[cpid])) {
          // if (!httpQuery.search) {
            if (httpQuery.open === 'true' && !isOpen) return acc;
            if (httpQuery.open === 'false' && isOpen) return acc;

            if (httpQuery.displayOptions === 'received' && !isReceived) return acc;
            if (httpQuery.displayOptions === 'sent' && isReceived) return acc;
            if (httpQuery.displayOptions === 'unread' && isRead) return acc;

            if (httpQuery.displayOptions === 'withLabel' && !labels.length) return acc;
            if (httpQuery.displayOptions === 'withoutLabel' && labels.length) return acc;

            if (httpQuery.displayOptions === 'withTag' && !tags.length) return acc;
            if (httpQuery.displayOptions === 'withoutTag' && tags.length) return acc;

            if (httpQuery.displayOptions === 'withAssign' && !hasUser) return acc;
            if (httpQuery.displayOptions === 'withoutAssign' && hasUser) return acc;

            if (inboxType === 'primary' && isMention && (!isReceived || httpQuery.displayOptions === 'all') && campaignCid) return acc;
            if (inboxType === 'importants' && !isImportant && campaignCid) return acc;
            if (inboxType === 'opportunities' && !isOpportunity && campaignCid) return acc;
            if (inboxType === 'mentions' && !isMention && campaignCid) return acc;

            if (threadByAppoints && (!hasUser || Number(hasUser.userUid) !== Number(threadByAppoints))) return acc;
            if (threadByTags && (!tags || !tags.includes(Number(threadByTags)))) return acc;
            if (threadByLabels && (!labels || !labels.includes(Number(threadByLabels)))) return acc;
            if (notInThisTribe(tribeMemberList)) return acc;
            if (threadByCampaigns && (String(threadByCampaigns) !== campaignCid)) return acc;
            if (activityStatus && (!lastActivity || lastActivity.status !== activityStatus)) return acc;
          }

          acc.push(item);
          return acc;
        }, [])
        .sort((a, b) => {
          switch (httpQuery.sortOptions) {
            case 'oldest': return this.$moment(a.thread.updatedOn).unix() - this.$moment(b.thread.updatedOn).unix();
            case 'followers': return Number(b.contactPlatform.followerCount) - Number(a.contactPlatform.followerCount);
            default: return this.$moment(b.thread.updatedOn).unix() - this.$moment(a.thread.updatedOn).unix();
          }
        });

      return threadArray;
    },

    currentRoute() {
      const {
        params: {
          campaignCid,
          tribeTid,
          threadByTags,
          threadByLabels,
          threadByCampaigns,
          threadByAppoints,
          threadByTribes,
          inboxType,
        },
        fullPath,
      } = this.$route;

      const baseRoute = [];

      if (fullPath.includes('/tribe')) baseRoute.push(`/tribe/${tribeTid}/messages`);
      else if (fullPath.includes('/campaign') && !fullPath.includes('/inbox')) baseRoute.push(`/campaign/${campaignCid}/messages`);
      else {
        baseRoute.push('/inbox');
      }

      const groupRoute = [];
      if (threadByTags) groupRoute.push(`/tag/${threadByTags}/`);
      if (threadByLabels) groupRoute.push(`/label/${threadByLabels}/`);
      if (threadByCampaigns) groupRoute.push(`/campaign/${threadByCampaigns}/`);
      if (threadByAppoints) groupRoute.push(`/appoint/${threadByAppoints}/`);
      if (threadByTribes) groupRoute.push(`/tribe/${threadByTribes}/`);
      if (inboxType) groupRoute.push(`/common/${inboxType}/`);

      return baseRoute.concat(groupRoute).join('');
    },

    currentThreadIndex() {
      // console.log('🚀 ~ file: ThreadListMixin.js ~ line 147 ~ currentThreadIndex ~ this.threadList.findIndex((t) => t.tid === this.$route.params.tid)', this.threadList.findIndex((t) => String(t.tid) === String(this.$route.params.tid)));
      // console.log('🚀 ~ file: ThreadListMixin.js ~ line 148 ~ currentThreadIndex ~ this.threadList', this.threadList);
      return this.threadList.findIndex((t) => String(t.tid) === String(this.$route.params.tid));
    },
    previousThread() { return this.threadList[this.currentThreadIndex - 1] ? this.threadList[this.currentThreadIndex - 1] : null; },
    previousThreadPath() { return `${this.currentRoute}channel/${this.previousThread.thread.channelCid}/thread/${this.previousThread.tid}`; },
    nextThread() { return this.threadList[this.currentThreadIndex + 1] ? this.threadList[this.currentThreadIndex + 1] : null; },
    nextThreadPath() { return `${this.currentRoute}channel/${this.nextThread.thread.channelCid}/thread/${this.nextThread.tid}`; },
  },
  methods: {
    ...mapActions({
      up_focusedElement: 'TheInbox/up_focusedElement',
    }),
    goToThread(item) {
      const path = `${this.currentRoute}channel/${item.thread.channelCid}/thread/${item.thread.tid}`;

      if (this.$route.path !== path) {
        return this.up_focusedElement({ name: 'THREAD_LIST' })
          .then(() => {
            this.$router.push({ path, query: this.$route.query });
          });
      }
      return undefined;
    },
  },
};
