/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    post({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'USER.SET_LOCKED')) return undefined;
      const { 'DetailledUser/getterDetailledUserList': detailledUserList } = rootGetters;
      const { 'DetailledUser/getterDetailledUser': detailledUser } = rootGetters;

      return clark.api.user.setLocked.post({ payload, httpQuery: {} })
        .then((user) => {
          const newPayloadDetailledUser = { ...detailledUser, lockedOn: user.lockedOn };

          const newPayloadDetailledUserList = detailledUserList;
          newPayloadDetailledUserList[payload.uid].lockedOn = user.lockedOn;

          commit('DetailledUser/SET_DETAILLED_USER', newPayloadDetailledUser, { root: true });
          commit('DetailledUser/SET_DETAILLED_USER_LIST', newPayloadDetailledUserList, { root: true });
        });
    },
    delete({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'USER.SET_LOCKED')) return undefined;
      const { 'DetailledUser/getterDetailledUserList': detailledUserList } = rootGetters;
      const { 'DetailledUser/getterDetailledUser': detailledUser } = rootGetters;

      return clark.api.user.setLocked.delete({ payload, httpQuery: {} })
        .then(() => {
          const newPayloadDetailledUser = { ...detailledUser, lockedOn: null };

          const newPayloadDetailledUserList = detailledUserList;
          newPayloadDetailledUserList[payload.uid].lockedOn = null;

          commit('DetailledUser/SET_DETAILLED_USER', newPayloadDetailledUser, { root: true });
          commit('DetailledUser/SET_DETAILLED_USER_LIST', newPayloadDetailledUserList, { root: true });
        });
    },
  },
};
