<template>
  <div
    v-if="account"
    class="ubuSettingView start"
  >
    <header class="start">
      <h2 class="subtitle is-1 has-text-secondary m-0">{{ account.owner.toUpperCase() }}</h2>
      <div class="multibar">
        <h1 class="start title is-1">Account Details</h1>
        <div class="buttons end">
          <b-button
            tag="router-link"
            :to="`/admin/account/${currentAid}/details/addFeatures`"
            label="Add features"
            icon-pack="ubu"
            icon-left="ubu-settings"
            type="is-primary"
          />
        </div>
      </div>
    </header>

    <br>

    <DevDetails :cnt="{name: 'account', obj: account}" />
    <DevDetails :cnt="{name: 'myAuthenticate', obj: myAuthenticate}" />
    <template v-for="item in displayInfoOptions">
      <section
        v-if="item"
        :key="item.text"
        class="pt-3 pb-3"
      >
        <div class="multibar">
          <div class="start">
            <p class="subtext has-text-secondary">{{ item.text }} :</p>
            <p class="text">{{ item.data ? item.data : '-' }}</p>
          </div>
          <b-button
            v-if="item.labelButton"
            class="is-borderless end"
            :type="item.type"
            :label="item.labelButton"
            tag="router-link"
            :to="item.to"
          />
        </div>

        <router-view
          :name="item.router"
          :path="`/admin/account/${currentAid}/details`"
          @refresh="refresh()"
        />

        <hr class="is-l">
      </section>
    </template>

    <br>

    <div class="multibar">
      <div class="buttons end">
        <b-button
          v-if="canDeleteAccount"
          label="Delete account"
          type="is-ghost"
          @click="deleteAccount()"
        />
        <b-button
          v-if="account.state !== 'BLOCKED'"
          label="Block account"
          icon-pack="ubu"
          icon-left="ubu-trash"
          type="is-danger"
          @click="blockAccount()"
        />
        <b-button
          v-else
          tag="router-link"
          :to="`/admin/account/${currentAid}/details/edit`"
          label="Unblock account"
          icon-pack="ubu"
          icon-left="ubu-settings"
          type="is-secondary"
          @click="blockAccount()"
        />
      </div>
    </div>

    <router-view name="addFeatures" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheAdminAccountDetails',
  computed: {
    ...mapGetters({
      account: 'TheAdmin/account',
      myAuthenticate: 'TheAdmin/myAuthenticate',
    }),
    currentAid() {
      return this.$route.params.aid;
    },
    canDeleteAccount() {
      const userRolesCanDelete = ['SUPER_ADMIN'];

      if (Number(this.myAuthenticate.setting.defaultAccountAid) === Number(this.currentAid)) return false;
      return !!userRolesCanDelete.includes(this.myAuthenticate.roleDetail.urid);
    },
    displayInfoOptions() {
      if (!this.account) return false;
      return [
        {
          text: 'Account ID', data: this.account.aid,
        },
        {
          text: 'Owner', data: this.account.owner,
        },
        {
          text: 'State', data: this.account.state, labelButton: 'Edit', type: 'is-ghost', to: `/admin/account/${this.currentAid}/details/edit`, router: 'editAccount',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      changeState: 'TheAdmin/changeState',
      up_changeState: 'TheAdmin/up_changeState',
      _deleteAccount: 'TheAdmin/deleteAccount',
      up_deleteAccount: 'TheAdmin/up_deleteAccount',
    }),
    blockAccount() {
      const payload = { aid: this.account.aid, state: 'BLOCKED' };

      this.$buefy.dialog.confirm({
        title: 'Block account',
        message: 'Are you sure you want to <b>Block this account</b>? This action will modify all the user\'s rights on the application.',
        confirmText: 'Block account',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.changeState({ payload })
          .then(({ response: account }) => {
            if (account) this.up_changeState({ payload });
          }),
      });
    },
    deleteAccount() {
      const { aid } = this.account;
      const payload = { aid };

      this.$buefy.dialog.confirm({
        title: 'Delete account',
        message: 'Are you sure you want to <b>delete account</b>? This action will delete all datas linked at this account.',
        confirmText: 'Delete account',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this._deleteAccount({ payload })
          .then(({ response: accountDeleted }) => {
            if (accountDeleted) {
              this.up_deleteAccount({ payload });
              this.$router.push('/admin/accounts');
              this.$emit('reset');
            }
          }),
      });
    },
  },
};
</script>
