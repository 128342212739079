<template>
  <section class="mainPanel">
    <header class="mainPanelHeader multibar row-vcentered">
      <h1 class="title is-2 start">Active Api Key</h1>

      <b-button
        label="Request new api key"
        type="is-primary"
        class="end"
        icon-pack="ubu"
        icon-right="ubu-next-Arrow"
        @click="isRequestPanelOpen = !isRequestPanelOpen"
      />
    </header>

    <ul class="section">
      <li v-for="item in sortedList" :key="item.akid" class="multibar row-vcentered mb-6">
        <b-tag class="start" type="is-info" size="is-medium">{{ item.label }}</b-tag>

        <span class="start ml-3 subheader">
          <strong>{{ _userList[item.userUid].userDetail.firstName }}</strong>
        </span>

        <span class="end">{{ item.createdOn | toDate }}</span>

        <b-button
          class="end ml-4"
          label="Revoke"
          type="is-primary"
          @click="askRevoke(item.akid)"
        />
      </li>
    </ul>

    <TheSimplePanel
      v-if="isRequestPanelOpen"
      closing-way="emit"
      @close="closePanel()"
    >
      <template #header>
        <h1 class="title is-1">Request new Api Key</h1>
      </template>

      <template #content>
        <form v-if="!newToken" @submit.prevent="submitRequest()">
          <b-field label="Label to remind you where the key is used">
            <b-input
              v-model="newLabel"
              placeholder="PRODUCTION, STAGING..."
              required
            />
          </b-field>

          <div class="buttons is-right">
            <b-button
              type="is-success"
              native-type="submit"
              label="Request"
            />
          </div>
        </form>

        <b-message v-else type="is-info">
          This is your new api key:

          <br>

          <b-button
            :label="newToken"
            type="is-ghost"
            icon-pack="ubu"
            icon-right="ubu-copy"
            class="p-0"
            @click="copyToClipBoard()"
          />

          <br>

          <strong class="has-text-primary">Please save it, you can't retrieve it later !</strong>
        </b-message>
      </template>
    </TheSimplePanel>

    <div
      v-if="isRequestPanelOpen"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheApiKeyList',
  data() {
    return {
      isRequestPanelOpen: false,
      newToken: null,
      newLabel: '',
    };
  },
  computed: {
    ...mapGetters({
      _apiKeyList: 'TheApiKey/apiKeyList',
      _userList: 'TheApiKey/userList',
    }),

    sortedList() {
      return Object.values(this._apiKeyList)
        .sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
    },
  },
  mounted() {
    return this._reportActiveApiKey();
  },
  methods: {
    ...mapActions({
      _reportActiveApiKey: 'TheApiKey/reportActiveApiKey',
      _requestNewApiKey: 'TheApiKey/requestNewApiKey',
      _revokeApiKey: 'TheApiKey/revokeApiKey',
    }),

    askRevoke(akid) {
      this.$buefy.dialog.confirm({
        title: 'Revoke api key',
        message: 'Are you sure you want to <b>revoke</b> this api key? This action cannot be undone.',
        confirmText: 'Revoke',
        type: 'is-danger',
        onConfirm: () => this._revokeApiKey({ payload: { akid } }),
      });
    },

    submitRequest() {
      const payload = {
        label: this.newLabel,
        createdOn: this.$moment().format(),
        revokedOn: null,
      };

      return this._requestNewApiKey({ payload })
        .then(({ response }) => { this.newToken = response.token; });
    },

    copyToClipBoard() {
      navigator.clipboard.writeText(this.newToken);
      this.$buefy.snackbar.open({
        message: 'Api Key copied to clipboard.',
        ...snackbarSuccess,
      });
    },

    closePanel() {
      if (this.newToken) {
        this.$buefy.dialog.confirm({
          title: '',
          message: 'Did you save your api key? You can\'t get it back later.',
          confirmText: 'Yes, I save it',
          type: 'is-danger',
          onConfirm: () => this.reset(),
        });
      } else this.reset();
    },

    reset() {
      this.isRequestPanelOpen = false;
      this.newToken = null;
      this.newLabel = '';
    },
  },
};
</script>
