<template>
  <UbuDropdown
    :expanded="true"
    position="is-bottom-right"
  >
    <template #button>
      <button
        v-if="selectedItem"
        class="button flex w-100"
        expanded
      >
        <div class="multibar row-vcentered w-100">
          <UbuDropdownMemberGroupRow
            class="start"
            :member-group="selectedItem"
          />

          <b-icon
            icon="circle"
            :class="['end badge', {'is-active': !selectedItem.unreadCount.length}]"
            size="is-small"
          />

          <span class="end counter">
            {{ selectedItem.threadCount }}
          </span>

          <b-icon
            class="end"
            size="is-small"
            pack="ubu"
            icon="ubu-arrow-down"
          />
        </div>
      </button>
    </template>

    <template #content>
      <b-dropdown-item
        v-for="item in memberGroupList"
        v-show="selected !== item.name"
        :key="item.label"
        @click="$emit('select', valueToEmit(item))"
      >
        <div class="multibar row-vcentered">
          <UbuDropdownMemberGroupRow
            class="start"
            :member-group="item"
          />

          <b-icon
            icon="circle"
            :class="['end badge', {'is-active': !item.unreadCount.length}]"
            size="is-small"
          />

          <span class="end counter">
            {{ item.threadCount }}
          </span>
        </div>
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
export default {
  name: 'UbuDropdownMemberGroupCounters',
  props: {
    memberGroupList: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      default: 'All',
    },
  },
  computed: {
    selectedItem() {
      if (!this.selected) return this.memberGroupList[0];
      if (this.selected === 'All') return this.memberGroupList.find(({ name }) => name === 'All');
      if (this.selected === 'Open') return this.memberGroupList.find(({ name }) => name === 'Open');
      return this.memberGroupList.find(({ mgid }) => String(mgid) === this.selected);
    },
  },
  methods: {
    valueToEmit(item) {
      if (item.name === 'All') return 'All';
      if (item.name === 'Open') return 'Open';
      return item.mgid;
    },
  },
};
</script>
