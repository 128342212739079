import Ws from '@dailyplanet/src-websocket';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      basePath: 'rt.kara',
      socketIsConnected: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'TheAuthenticate/myAuthenticate',
    }),
  },
  methods: {
    ...mapActions({
      UP_task: 'TheMonitoring/UP_task',
    }),

    connect(isConnected) {
      if (!this.currentUser || !this.currentUser.isUbu) return;

      Ws.onconnect = () => {
        window.socketConnected = true;
      };

      if (!window.socketConnected) {
        Ws.connect({});
        this.socketIsConnected = isConnected;
        this.subscribeStream();
      }
    },

    generatePath() {
      this.taskDone = `${this.basePath}.taskDone`;
      this.taskScheduled = `${this.basePath}.taskScheduled`;
    },

    subscribeStream() {
      this.generatePath();

      Ws.subscribeStream(this.taskDone);
      Ws.subscribeStream(this.taskScheduled);

      this.listeners();
    },

    unsubscribeStream() {
      Ws.unSubscribeStream(this.taskDone);
      Ws.unSubscribeStream(this.taskScheduled);
    },

    listeners() {
      Ws.on(this.taskDone, (mess) => {
        this.UP_task(mess.content);
      });

      Ws.on(this.taskScheduled, (mess) => {
        this.UP_task(mess.content);
      });
    },
  },
};
