import UbuTableShortenNumber from './UbuTableShortenNumber';
import UbuTableCampaign from './UbuTableCampaign';
import UbuTableTrueFalseMarker from './UbuTableTrueFalseMarker';

export {
  // eslint-disable-next-line import/prefer-default-export
  UbuTableShortenNumber,
  UbuTableCampaign,
  UbuTableTrueFalseMarker,
};

export default {
  // eslint-disable-next-line import/prefer-default-export
  UbuTableShortenNumber,
  UbuTableCampaign,
  UbuTableTrueFalseMarker,
};
