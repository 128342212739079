import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheAskUgcPermission from './smarts/TheAskUgcPermission';
import TheAskUgcPermissionTag from './smarts/TheAskUgcPermissionTag';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheAskUgcPermission);
    registerComponent(Vue, TheAskUgcPermissionTag);
  },
};

use(Plugin);

export default Plugin;

export {
  TheAskUgcPermission,
  TheAskUgcPermissionTag,
};
