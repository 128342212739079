/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    affiliationCodeOverviewList: {},
    totalAffiliationCodeOverview: 0,

    discountCodeOverviewList: {},
    totalDiscountCodeOverview: 0,
  },
  mutations: {
    SET_AFFILIATION_CODE_OVERVIEW_LIST(state, newData) { state.affiliationCodeOverviewList = newData; },
    SET_TOTAL_AFFILIATION_CODE_OVERVIEW(state, newData) { state.totalAffiliationCodeOverview = newData; },

    SET_DISCOUNT_CODE_OVERVIEW_LIST(state, newData) { state.discountCodeOverviewList = newData; },
    SET_TOTAL_DISCOUNT_CODE_OVERVIEW(state, newData) { state.totalDiscountCodeOverview = newData; },

    RESET_DISCOUNT_CODE_OVERVIEW_LIST(state) { state.discountCodeOverviewList = {}; },
  },
  getters: {
    getterAffiliationCodeOverviewList: (state) => state.affiliationCodeOverviewList,
    getterTotalAffiliationCodeOverview: (state) => state.totalAffiliationCodeOverview,

    getterDiscountCodeOverviewList: (state) => state.discountCodeOverviewList,
    getterTotalDiscountCodeOverview: (state) => state.totalDiscountCodeOverview,
  },
  actions: {
    report({ commit }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.affiliationCode.report({ payload, httpQuery })
        .then(({ total, results }) => {
          commit('SET_TOTAL_AFFILIATION_CODE_OVERVIEW', total);
          commit('SET_AFFILIATION_CODE_OVERVIEW_LIST', results.arrayMapper('acid'));
        });
    },

    reportDiscountCodeOverview({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.affiliationCode.report({ payload, httpQuery })
        .then(({ total, results }) => {
          const { getterDiscountCodeOverviewList } = getters;

          commit('SET_TOTAL_DISCOUNT_CODE_OVERVIEW', total);
          commit('SET_DISCOUNT_CODE_OVERVIEW_LIST', { ...getterDiscountCodeOverviewList, ...results.arrayMapper('acid') });
        });
    },

    create({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.affiliationCode.create({ payload, httpQuery })
        .then((affiliationCodeList) => {
          const { getterAffiliationCodeOverviewList } = getters;

          const mappedAffiliationCodeList = affiliationCodeList.arrayMapper('acid');

          commit('SET_AFFILIATION_CODE_OVERVIEW_LIST', { ...getterAffiliationCodeOverviewList, ...mappedAffiliationCodeList });

          return affiliationCodeList;
        });
    },

    update({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.affiliationCode.put({ payload, httpQuery })
        .then((affiliationCodeList) => {
          const { getterAffiliationCodeOverviewList } = getters;

          const mappedAffiliationCodeList = affiliationCodeList.arrayMapper('acid');

          const updatedAffiliationCodeList = Object.values(getterAffiliationCodeOverviewList)
            .reduce((acc, row) => {
              if (!mappedAffiliationCodeList[row.acid]) return acc;
              acc[row.acid] = { ...row, ...mappedAffiliationCodeList[row.acid] };
              return acc;
            }, {});

          commit('SET_AFFILIATION_CODE_OVERVIEW_LIST', { ...getterAffiliationCodeOverviewList, ...updatedAffiliationCodeList });

          return affiliationCodeList;
        });
    },

    patch({ commit, getters }, { payload, httpQuery = {} }) {
      return clark.api.apiAffiliation.affiliationCode.patch({ payload, httpQuery })
        .then((affiliationCode) => {
          const { getterAffiliationCodeOverviewList } = getters;

          // do this to keep nesting that we dont re-fetch
          const updatedRow = {
            [affiliationCode.acid]: {
              ...getterAffiliationCodeOverviewList[affiliationCode.acid],
              ...affiliationCode,
            },
          };

          commit('SET_AFFILIATION_CODE_OVERVIEW_LIST', { ...getterAffiliationCodeOverviewList, ...updatedRow });

          return affiliationCode;
        });
    },

    UP_resetDiscountCodeOverviewList({ commit }) {
      commit('RESET_DISCOUNT_CODE_OVERVIEW_LIST');
    },
  },
};
