<template>
  <div class="">
    <b-table
      class=""
      :data="Object.values(authenticates)"
      detail-key="aid"
      :default-sort="['aid', 'desc']"
      paginated
      :per-page="5"
    >
      <b-table-column
        v-slot="{ row: { aid } }"
        field="aid"
        label="aid"
        sortable
      >
        {{ aid }}
      </b-table-column>

      <b-table-column
        v-slot="{ row: { userUid } }"
        field="userUid"
        label="User ID"
      >
        {{ userUid }}
      </b-table-column>

      <b-table-column
        v-slot="{ row: { aid } }"
        width="200"
        field="passName"
        label="Email"
      >
        <b-field>
          <b-input
            v-model="authenticates[aid].passName"
            type="email"
            expanded
          />
        </b-field>
      </b-table-column>

      <b-table-column
        v-slot="{ row: { aid, passPhrase } }"
        width="300"
        label="Password"
      >
        <b-field>
          <p class="control">
            <b-button
              type="is-dark"
              icon-right="sync"
              @click="onPasswordChange(aid)"
            />
          </p>
          <b-input
            v-model="authenticates[aid].passPhrase"
            expanded
            required
          />
          <p class="control">
            <b-button
              v-clipboard:copy="passPhrase"
              v-clipboard:success="onCopy"
              type="is-link"
              icon-right="content-copy"
            />
          </p>
        </b-field>
      </b-table-column>

      <b-table-column
        v-slot="{ row: { method } }"
        field="method"
        label="Method"
      >
        {{ method }}
      </b-table-column>

      <b-table-column
        v-slot="{ row: { createdOn } }"
        field="createdOn"
        label="Created on"
      >
        <b-tag ellipsis>
          {{ $moment(createdOn).format('DD/MM/YY HH:mm:ss') }}
        </b-tag>
      </b-table-column>

      <b-table-column
        v-slot="{ row: { updatedOn } }"
        field="updatedOn"
        label="Updated on"
      >
        <b-tag ellipsis>
          {{ $moment(updatedOn).format('DD/MM/YY HH:mm:ss') }}
        </b-tag>
      </b-table-column>

      <b-table-column
        v-slot="{ row: { allowedOn } }"
        field="allowedOn"
        label="Allowed on"
      >
        <b-tag ellipsis>
          {{ $moment(allowedOn).format('DD/MM/YY HH:mm:ss') }}
        </b-tag>
      </b-table-column>

      <b-table-column
        v-slot="{ row: { revokedOn } }"
        field="revokedOn"
        label="Revoked on"
      >
        {{ revokedOn }}
      </b-table-column>

      <b-table-column
        v-slot="{ row: { aid } }"
        label="Actions"
        numeric
      >
        <div class="buttons is-right">
          <b-button
            type="is-info"
            icon-right="ubu-edit"
            icon-pack="ubu"
            @click="confirmAuthenticateUpdate(aid)"
          />
          <!-- <b-button
            type="is-danger"
            icon-right="ubu-trash"
            icon-pack="ubu"
            @click="confirmAuthenticateDelete(aid)"
          /> -->
        </div>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import VModelMixin from '@dailyplanet/mixins/VModelMixin';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';

export default {
  name: 'UbuKaraDetailledUsersAuthenticates',
  mixins: [VModelMixin],
  model: {
    prop: 'authenticates',
  },
  props: {
    authenticates: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditable: false,
    };
  },

  methods: {
    confirmAuthenticateUpdate(aid) {
      this.$buefy.dialog.confirm({
        title: 'Updating Authenticate',
        // eslint-disable-next-line max-len
        message: `Are you sure you want to <b>change</b> this authenticate with passName (${this.authenticates[aid].passName}) and passPhrase (${this.authenticates[aid].passPhrase}) ? This action cannot be undone.`,
        confirmText: 'Change Authenticate !',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$emit('changeAuthenticate', this.authenticates[aid]);
          this.$buefy.toast.open('Authenticate changed!');
        },
      });
    },
    onPasswordChange(aid) {
      this.authenticates[aid].passPhrase = uuidv4();
    },
    onCopy() {
      this.$buefy.snackbar.open({
        message: 'Copied to clipboard.',
        ...snackbarSuccess,
      });
    },
  },
};
</script>
