export default {
  data() {
    return {
      columns: {
        aid: {
          col: 'aid', label: 'aid', visible: true, isSortable: false, cmp: 'flat',
        },
        owner: {
          col: 'owner', label: 'owner', visible: true, isSortable: false, cmp: 'flat',
        },
        state: {
          col: 'state', label: 'state', visible: true, isSortable: false, cmp: 'flat',
        },
        action: {
          col: 'action', label: 'action', visible: true, isSortable: true, cmp: 'openContactPanel', sticky: true,
        },
      },
    };
  },
};
