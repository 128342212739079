<template>
  <aside class="ubuMenu is-sub-menu">
    <div
      v-for="(item) in sidebarOptions"
      :key="item.title"
      class="start menu-item-list"
    >
      <p class="menu-item-title disclaimer is-3">
        {{ item.title }}
      </p>

      <UbuMenuItem
        v-for="(route) in item.items"
        :key="route.text"
        :item="route"
      />
    </div>
  </aside>
</template>

<script>
export default {
  name: 'UbuAsideMenu',
  props: {
    sidebarOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>
