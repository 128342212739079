// import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure } from '../$utils/helpers';

export default {
  getters: {
    favikonProfile: (_state, _getters, _rootState, rootGetters) => {
      const { 'FavikonProfile/getterFavikonProfile': favikonProfile } = rootGetters;

      return favikonProfile;
    },
  },
  actions: {
    getFavikonProfile({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'FavikonProfile/get',
        context: { payload, httpQuery },
        textNotifier: 'get favikon profile',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .then((response) => {
          dispatch('Segment/segmentTrack', { event: 'reportFavikon' }, { root: true });
          return response;
        })
        .catch((error) => {
          dispatchFailure(action);
          return Promise.reject(error);
        });
    },
  },
};
