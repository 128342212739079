/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  actions: {
    reportContactNotesForPanel({ commit, getters, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CHANNEL_GROUP_CONTACT_NOTE.REPORT')) return undefined;

      return clark.api.channelGroup.contact.note.report({ payload, httpQuery })
        .then(({ results: contactNotesForPanel, total }) => {
          const contactPlatformOverviewList = Object.values(getters.getterContactPlatformOverviewList).filter(({ contactPlatform }) => payload.cid === contactPlatform.contactCid);

          commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
            ...getters.getterContactPlatformOverviewList,
            ...contactPlatformOverviewList.reduce((acc, row) => {
              acc[row.cpid] = row;
              acc[row.cpid].totalNotesForPanel = total;

              if (!acc[row.cpid].contactNotesForPanel) acc[row.cpid].contactNotesForPanel = {};

              acc[row.cpid].contactNotesForPanel = {
                ...acc[row.cpid].contactNotesForPanel,
                ...contactNotesForPanel
                  .arrayMapper('customId'),
              };
              return acc;
            }, {}),
          });

          return ({ results: contactNotesForPanel, total });
        });
    },

    UP_createPrivateNote({ commit, getters }, { payload, response }) {
      if (!response) return;

      const contactPlatformOverviewList = Object.values(getters.getterContactPlatformOverviewList).filter(({ cpid }) => payload.cpids.includes(cpid));

      // eslint-disable-next-line object-curly-newline
      const noteMapped = {
        [`${response.pnid}#PRIVATE_NOTE`]: {
          ...response,
          customId: `${response.pnid}#PRIVATE_NOTE`,
          noteDate: response.createdOn,
          itemDate: response.createdOn, // used to sort from commonViewStore (thread) getter messageList
          originalId: response.pnid,
          type: 'PRIVATE_NOTE',
        },
      };

      commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
        ...getters.getterContactPlatformOverviewList,
        ...contactPlatformOverviewList.reduce((acc, row) => {
          acc[row.cpid] = row;
          acc[row.cpid].totalNotesForPanel += 1;

          if (!acc[row.cpid].contactNotesForPanel) acc[row.cpid].contactNotesForPanel = {};

          acc[row.cpid].contactNotesForPanel = {
            ...acc[row.cpid].contactNotesForPanel,
            ...noteMapped,
          };
          return acc;
        }, {}),
      });
    },

    UP_updatePrivateNote({ commit, getters }, { payload }) {
      const contactPlatformOverviewList = Object.values(getters.getterContactPlatformOverviewList).filter(({ cpid }) => payload.cpids.includes(cpid));

      commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
        ...getters.getterContactPlatformOverviewList,
        ...contactPlatformOverviewList.reduce((acc, row) => {
          acc[row.cpid] = row;

          if (!acc[row.cpid].contactNotesForPanel) acc[row.cpid].contactNotesForPanel = {};

          acc[row.cpid].contactNotesForPanel = {
            ...acc[row.cpid].contactNotesForPanel,
            [payload.note.customId]: payload.note,
          };
          return acc;
        }, {}),
      });
    },

    UP_removePrivateNote({ commit, getters }, { payload }) {
      const contactPlatformOverviewList = Object.values(getters.getterContactPlatformOverviewList).filter(({ cpid }) => payload.cpids.includes(cpid));

      commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
        ...getters.getterContactPlatformOverviewList,
        ...contactPlatformOverviewList.reduce((acc, row) => {
          acc[row.cpid] = row;

          acc[row.cpid].totalNotesForPanel -= 1;

          if (!acc[row.cpid].contactNotesForPanel) acc[row.cpid].contactNotesForPanel = {};

          const { contactNotesForPanel } = acc[row.cpid];
          delete contactNotesForPanel[payload.note.customId];

          acc[row.cpid].contactNotesForPanel = contactNotesForPanel;
          return acc;
        }, {}),
      });
    },
  },
};
