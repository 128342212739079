<template>
  <article class="ubuContactPlatformPanelItem">
    <header class="multibar row-vcentered p-0">
      <h3 class="start">Overview</h3>
      <b-icon
        class="end"
        pack="ubu"
        icon="ubu-download"
      />
    </header>

    <div class="tile is-ancestor is-vertical">
      <template v-for="(_, index) in tiles">
        <div
          v-if="index % 2 !== 1"
          :key="index"
          class="tile is-parent is-horizontal"
        >
          <div class="tile is-child">
            <p class="subtext is-2 has-text-secondary">{{ tiles[index].label }}</p>
            <p class="text is-2">
              {{ tiles[index].value ? tiles[index].symbolBefore : '' }}
              {{ tiles[index].value | nFormatter }}
              {{ tiles[index].value ? tiles[index].symbolAfter : '' }}
            </p>
          </div>
          <div
            v-if="tiles[index + 1]"
            class="tile is-child"
          >
            <p class="subtext is-2 has-text-secondary">{{ tiles[index + 1].label }}</p>
            <p class="text is-2">
              {{ tiles[index + 1].value ? tiles[index + 1].symbolBefore : '' }}
              {{ tiles[index + 1].value | nFormatter }}
              {{ tiles[index + 1].value ? tiles[index + 1].symbolAfter : '' }}
            </p>
          </div>
        </div>
      </template>
    </div>
  </article>
</template>

<script>
export default {
  name: 'UbuContactPlatformPanelStats',
  model: {
    prop: 'contactPlatformOverview',
  },
  props: {
    contactPlatformOverview: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tiles() {
      return [
        {
          label: 'Followers 😍',
          value: this.contactPlatformOverview.contactPlatform.followerCount,
        },
        {
          label: 'Engagement 🎯',
          value: this.contactPlatformOverview.contactPlatform.engagement,
          symbolAfter: '%',
        },
        {
          label: 'Likes (avg) 💘',
          value: this.contactPlatformOverview.contactPlatform.likeCount,
        },
        {
          label: 'Comments (avg) 💬',
          value: this.contactPlatformOverview.contactPlatform.commentCount,
        },
        {
          label: 'Growth 🚀',
          value: this.contactPlatformOverview.contactPlatform.growth,
          symbolAfter: '%',
        },
        {
          label: 'Mentions 🏞',
          value: this.contactPlatformOverview.contactPlatformStatistic.totalMentions,
        },
        {
          label: 'Revenue 💰',
          value: this.contactPlatformOverview.contactPlatformStatistic.totalRevenuesUsd,
          symbolBefore: '$',
        },
        {
          label: 'Orders 🛍',
          value: this.contactPlatformOverview.contactPlatformStatistic.totalOrders,
        },
      ];
    },
  },
};
</script>
