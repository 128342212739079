/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import Errors from '@dailyplanet/utils/Errors';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {

  },
  actions: {
    downloadMention({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/downloadMention/download',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
            ...payload,
          },
          httpQuery,
        },
        textNotifier: 'download mentions',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'downloadMention' }, { root: true }))
        .catch((error) => {
          console.log('🚀 ~ file: message.js ~ line 56 ~ postMessage ~ error', error);
          Snackbar.open({
            message: 'Unable to download mentions.',
            ...snackBarFailure,
            indefinite: true,
          });

          dispatchFailure(action);
          throw new Errors(error);
        });
    },
  },
};
