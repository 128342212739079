<template>
  <b-dropdown-item class="ubuTextareaEmojisDropdownItem">
    <div class="multibar">
      <span>{{ label }}</span>

      <div class="ml-1">
        <b-tag
          v-for="(chip, indexChip) in tooltipSecondaryActions.chips"
          :key="indexChip"
          type="is-light"
          class="ml-1"
        >
          <b-icon
            v-if="chip.icon"
            :icon="chip.icon"
            size="is-small"
          />
          <span v-else>{{ chip.label }}</span>
        </b-tag>
      </div>
    </div>

    <div class="emojiList">
      <div
        v-for="(emoji, index) in emojis"
        :key="index"
        @click="$emit('selectEmoji', emoji)"
      >
        {{ emoji }}
      </div>
    </div>
  </b-dropdown-item>
</template>

<script>
export default {
  name: 'UbuTextareaEmojisDropdownItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    tooltipSecondaryActions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      emojis: ['😂', '😘', '😍', '🔥', '💘', '💪 ', '🚀', '🥰', '🙈', '✨', '😇', '❤️'],
    };
  },
};
</script>
