/* eslint-disable max-len */

import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';
import { actions, getters, commonState } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        ...commonState.menu.home.menuItems,
      ],
    },
  },
  getters: {
    navigation: (state) => state.navigation,
    ...getters.account,
    ...getters.channelGroup,
    manageShopList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/manageShop/getterManageShopList': manageShopList } = rootGetters;
      return manageShopList;
    },
    manageShop: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/manageShop/getterManageShop': manageShop } = rootGetters;
      return manageShop;
    },
    manageWebhookList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/manageWebhook/getterManageWebhookList': manageWebhookList } = rootGetters;
      return manageWebhookList;
    },
    pingShop: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/pingShop/getterPingShop': pingShop } = rootGetters;
      return pingShop;
    },
  },
  actions: {
    ...actions.account,
    ...actions.channelGroup,
    reportManageShop({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/manageShop/report',
        context: {
          payload,
          httpQuery,
        },
        textNotifier: 'report manage shopify shop list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report manage shopify shop list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    getManageShop({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/manageShop/get',
        context: { payload, httpQuery },
        textNotifier: 'get manage shopify shop list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to get manage shopify shop list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    createManageShop({ dispatch }, { payload, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/manageShop/create',
        context: { payload, httpQuery },
        textNotifier: 'create manage shopify shop list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to create manage shopify shop list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    updateManageShop({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/manageShop/update',
        context: { payload, httpQuery },
        textNotifier: 'update manage shopify shop list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to update manage shopify shop list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    deleteManageShop({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/manageShop/delete',
        context: { payload, httpQuery },
        textNotifier: 'delete manage shopify shop list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to delete manage shopify shop list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    reportManageWebhookList({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/manageWebhook/report',
        context: { payload, httpQuery },
        textNotifier: 'report manage webhook list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report manage webhook list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    createManageWebhook({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/manageWebhook/create',
        context: { payload, httpQuery },
        textNotifier: 'create manage webhook list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to create manage webhook list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    deleteManageWebhook({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/manageWebhook/delete',
        context: { payload, httpQuery },
        textNotifier: 'delete shopify list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to delete shopify list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    up_manageShop({ commit }, { payload }) {
      commit('ApiAffiliation/manageShop/SET_MANAGE_SHOP', payload, { root: true });
    },
    up_manageShopList({ commit, rootGetters }, { payload }) {
      const { 'ApiAffiliation/manageShop/getterManageShopList': manageShopList } = rootGetters;

      const newManageShopList = manageShopList.map((shop) => {
        if (Number(shop.sid) === payload.sid) return payload;
        return shop;
      });

      commit('ApiAffiliation/manageShop/SET_MANAGE_SHOP_LIST', newManageShopList, { root: true });
    },
    ping({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/pingShop/get',
        context: { payload, httpQuery },
        textNotifier: 'ping shop',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to ping shop',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
  },
};
