<template>
  <b-modal
    v-model="localOpenModal"
    :width="900"
    scroll="keep"
    class="UbuInfluenceMentionModal"
    :on-cancel="() => hasSidePanelOpen ? null : $emit('close')"
    @close="$emit('close')"
  >
    <b-carousel
      :indicator="false"
      :autoplay="false"
      :arrow="false"
      animated="fade"
    >
      <b-carousel-item v-if="currentMedia">
        <div class="card is-flex">
          <UbuMentionModalMedia
            :current-media="currentMedia"
            @changeCarouselIndex="indexFromCarousel = $event"
          />

          <div class="UbuInfluenceMentionModalContent card-content">
            <div class="media">
              <div class="media-header">
                <div class="media-left">
                  <UbuAvatar
                    :profile-picture="currentMedia.profilePicture"
                    :initial-name="initialUsername(currentMedia)"
                  />
                  <div class="media-user">
                    <p class="text is-3">
                      {{ platformName !== 'YOUTUBE' ? '@' : '' }}{{ currentMedia.username }}
                    </p>
                    <p class="subtext is-3 has-text-secondary">
                      {{ utilsNFormatter(currentMedia.followerCount, 1) }} followers
                    </p>
                  </div>
                </div>
                <UbuMentionModalHeaderButtons
                  :current-media-download-url="currentMediaDownloadUrl"
                  :is-media-favorited="currentMedia.isFav"
                  @openSendMessagePanel="$emit('openSendMessagePanel', currentMedia)"
                  @downloadMentionEvent="emitSegmentEvent({ event: 'downloadMention' });"
                  @toggleFavorite="$emit('toggleFavorite', currentMedia)"
                />
              </div>
            </div>

            <br>

            <DevDetails :cnt="{name: 'contentMedia', obj: currentMedia}" />
            <div class="content subtext has-text-secondary">
              <div class="content-meta">
                <span>{{ currentMedia.createdOn | toDate }}</span>
                <span>|</span>
                <b-button
                  class="is-ghost no-padding is-borderless text is-3"
                  :label="typeContent"
                  tag="a"
                  :href="currentMediaRedirectUrl"
                  target="blank"
                  icon-pack="ubu"
                  icon-right="ubu-link"
                />
                <span v-if="canOpenContactPanel">|</span>
                <b-button
                  v-if="canOpenContactPanel"
                  class="is-ghost has-text-secondary no-padding is-borderless text is-3"
                  label="Open details"
                  icon-pack="ubu"
                  icon-right="ubu-overview"
                  @click="$emit('openContactPanel', currentMedia)"
                />
              </div>

              <br>

              <div
                v-if="currentMedia.text"
                class="content-body"
              >
                <template v-if="!openText">
                  <p class="text is-3 has-text-secondary">
                    {{ truncateMediaText(currentMedia.text) }}
                    <b-button
                      v-if="isTruncate"
                      label="See more"
                      type="is-ghost is-only-text"
                      @click="openText = true"
                    />
                  </p>
                </template>
                <template v-else>
                  <p class="text is-3 has-text-secondary">
                    {{ currentMedia.text }}
                    <b-button
                      v-if="isTruncate"
                      label="See less"
                      type="is-ghost is-only-text"
                      @click="openText = false"
                    />
                  </p>
                </template>
              </div>

              <br>

              <div
                v-if="canAskUgc"
                class="flex"
              >
                <b-button
                  label="Request usage rights"
                  type="is-ghost is-only-text"
                  @click="$emit('askUgcPermission', currentMedia)"
                />

                <b-taglist class="end ml-2">
                  <b-tag type="is-primary">Beta</b-tag>
                </b-taglist>
              </div>

              <br>
              <br>

              <div
                :class="`analyticsGrid col-2`"
              >
                <template v-for="(analytic, indexAnalytic) in formatAnalyticsInMedia">
                  <UbuAnalyticCard
                    v-if="analytic"
                    :key="indexAnalytic"
                    :name="analytic.name"
                    :value="analytic.value"
                    :icon="analytic.icon"
                    :color="analytic.color"
                    :symbol="analytic.symbol"
                    :growth-percent="analytic.growthPercent"
                    :last-periodvalue="analytic.lastPeriodvalue"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-item>
    </b-carousel>

    <b-button
      class="carouselArrow is-left"
      type="is-tertiary"
      icon-pack="ubu"
      icon-left="ubu-arrow-left"
      @click.stop="prev()"
    />

    <b-button
      class="carouselArrow is-right"
      type="is-tertiary"
      icon-pack="ubu"
      icon-left="ubu-arrow-right"
      @click.stop="next()"
    />
  </b-modal>
</template>

<script>
// import TheInfluenceMentionMixinx from '../influence/$mixins/TheInfluenceMentionMixins.mixins';
import { nFormatter } from '@dailyplanet/utils/formate';
import { mapActions } from 'vuex';

export default {
  name: 'TheMentionViewMedia',
  model: {
    prop: 'openModal',
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    hasSidePanelOpen: {
      type: Boolean,
      default: false,
    },
    mentionList: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    platformName: {
      type: String,
      required: true,
    },
    mentionType: {
      type: String,
      required: true,
    },
    view: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      default: '',
    },
    canAskUgc: {
      type: Boolean,
      default: false,
    },
    canOpenContactPanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTruncate: false,
      openText: false,
      length: 130,
      ending: '...',
      activeItem: 0,
      errorImg: false,
      sendMessagePanel: false,
      askUgcPanel: false,
      platformUrl: {
        instagram: 'https://www.instagram.com',
        tiktok: 'https://www.tiktok.com',
        youtube: 'https://www.youtube.com',
      },
      indexFromCarousel: 0,
    };
  },
  computed: {
    currentMediaRedirectUrl() {
      const base = this.platformUrl[this.formatPlatformName];
      if (this.formatMentionType === 'post' && this.mentionList[this.activeItem].link) {
        if (this.formatPlatformName === 'instagram') return `${base}/p/${this.mentionList[this.activeItem].link}`;
      }
      return `${base}/${this.currentMedia.username}`;
    },
    currentMediaDownloadUrl() {
      if (!this.currentMedia) return '';
      if (this.currentMedia.carousel && this.currentMedia.carousel.length) {
        return `${this.currentMedia.carousel[this.indexFromCarousel].url}?download=true`;
      }
      return `${this.currentMedia.mainMedia.url}?download=true`;
    },
    formatPlatformName() {
      return this.platformName.toLowerCase();
    },
    formatMentionType() {
      return this.mentionType.toLowerCase();
    },
    localOpenModal: {
      get() { return this.openModal; },
      set(newValue) { this.$emit('update', newValue); },
    },
    typeContent() {
      if (this.platformName === 'INSTAGRAM') return `${this.platformName.capitalize()} ${this.formatMentionType}`;
      return this.platformName.capitalize();
    },
    formatAnalyticsInMedia() {
      const payload = {};

      if (!this.mentionList[this.activeItem]) return payload;

      if (this.view === 'analytics') return this.mentionList[this.activeItem].analyticsDataList;

      const {
        /* prints, */ views, engagement, newFollowers, comments, likes,
      } = this.mentionList[this.activeItem];

      // payload.prints = {
      //   name: this.formatPlatformName !== 'instagram' ? 'Views' : 'Prints',
      //   value: this.formatPlatformName !== 'instagram' ? Number(views) : Number(prints) || null,
      //   color: 3,
      //   icon: 'ubu-eye',
      // };

      payload.views = this.formatMentionType !== 'story' && this.formatPlatformName === 'instagram'
        ? {
          name: 'Views',
          value: Number(views),
          color: 3,
          icon: 'ubu-eye',
        }
        : null;

      payload.likes = this.formatMentionType !== 'story' || this.formatPlatformName !== 'instagram'
        ? {
          name: 'Likes',
          value: Number(likes) || null,
          color: 9,
          icon: 'ubu-like',
        }
        : null;

      payload.comments = this.formatMentionType !== 'story' || this.formatPlatformName !== 'instagram'
        ? {
          name: 'Comments',
          value: Number(comments) || null,
          color: 9,
          icon: 'ubu-comment',
        }
        : null;

      payload.engagement = this.formatMentionType !== 'story' || this.formatPlatformName !== 'instagram'
        ? {
          name: 'Post engagement',
          value: Number(engagement) || null,
          color: 9,
          icon: 'ubu-organic',
          symbol: '%',
        }
        : null;

      payload.followers = this.formatPlatformName === 'instagram'
        ? {
          name: 'Followers earned',
          value: Number(newFollowers) || null,
          color: 12,
          icon: 'ubu-love',
        }
        : null;

      return Object.values(payload).filter((stat) => stat && stat.value && stat.value !== -1);
    },
    currentMedia() {
      return this.mentionList[this.activeItem];
    },

  },
  mounted() {
    this.activeItem = this.index;
  },
  methods: {
    ...mapActions({
      emitSegmentEvent: 'TheMention/emitSegmentEvent',
    }),
    prev() {
      if (this.activeItem === 0) this.activeItem = this.mentionList.length - 1;
      else this.activeItem -= 1;
      this.openText = false;
      this.isTruncate = false;
    },
    next() {
      if (this.activeItem === this.mentionList.length - 1) this.activeItem = 0;
      else this.activeItem += 1;
      this.openText = false;
      this.isTruncate = false;
    },
    truncateMediaText(content) {
      if (content.length > this.length) {
        this.isTruncate = true;
        return content.substring(0, this.length - this.ending.length) + this.ending;
      }
      return content;
    },
    initialUsername(contentMedia) {
      return contentMedia.username.charAt(0).capitalize();
    },
    utilsNFormatter(num, digit) {
      if (!num || Number(num) < 0) return 0;
      return nFormatter(Number(num), digit);
    },
  },
};
</script>
