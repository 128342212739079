<template>
  <section class="mainPanel">
    <div class="mainPanelFilters buttons is-right">
      <b-button
        label="Connect new shop"
        icon-pack="ubu"
        icon-left="ubu-add"
        type="is-primary"
        @click="isOpenShopifyConnexionPanel = !isOpenShopifyConnexionPanel"
      />
    </div>

    <article :class="['mainPanelTable', { hasFilter: false }]">
      <b-table
        ref="table"
        :data="Object.values(_shopList)"
        class="table-row-clickable table-full-height"
        hoverable
        sticky-header
        :detail-key="detailKey"
      >
        <b-table-column
          v-for="column in columns"
          :key="column.field"
          :field="column.field"
          :label="column.label"
          :sortable="column.isSortable"
          :visible="column.visible"
        >
          <template v-if="column.cmp === 'UbuTableAction'" #default="props">
            <b-button
              icon-pack="ubu"
              icon-left="ubu-edit"
              @click="openEditPanel(props.row)"
            />
          </template>

          <template
            v-else-if="column.cmp === 'UbuTablePercentage'"
            #default="props"
          >
            <span v-if="props.row.commissionRate">{{ props.row.commissionRate * 100 }}%</span>
            <span v-else>-</span>
          </template>

          <template v-else #default="props">
            <component
              :is="column.cmp"
              :cpo="props.row"
              :field="column.field"
              :column="column"
            />
          </template>
        </b-table-column>

        <template #empty>
          <p class="has-text-secondary">No shop found!</p>
        </template>
      </b-table>
    </article>

    <TheShopifyConnexionPanel
      v-if="isOpenShopifyConnexionPanel"
      :shop-to-reconnect="shopToReconnect"
      @close="isOpenShopifyConnexionPanel = false"
    />

    <TheManagerShopPanel
      v-if="isOpenEditShopPanel"
      :shop="shopToReconnect"
      @close="isOpenEditShopPanel = false"
    />

    <div
      v-if="isOpenShopifyConnexionPanel || isOpenEditShopPanel"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheSettingShop',
  data() {
    return {
      isOpenShopifyConnexionPanel: false,
      isOpenEditShopPanel: false,
      shopToReconnect: null,
      // table
      detailKey: 'sid',
      /* eslint-disable object-curly-newline */
      /* eslint-disable key-spacing */
      /* eslint-disable no-multi-spaces */
      columns: {
        action:         { cmp: 'UbuTableAction',     field: 'action',         label: '',                isSortable: false,  visible: true },
        baseUrl:        { cmp: 'UbuTableSimple',     field: 'baseUrl',        label: 'Url',             isSortable: false,  visible: true },
        type:           { cmp: 'UbuTableSimple',     field: 'type',           label: 'Type',            isSortable: false,  visible: true },
        commissionRate: { cmp: 'UbuTablePercentage', field: 'commissionRate', label: 'Commission rate', isSortable: false,  visible: true },
        isConnected:    { cmp: 'UbuTablePastille',   field: 'isConnected',    label: 'State',           isSortable: false,  visible: true },
      },
      /* eslint-enable key-spacing */
      /* eslint-enable no-multi-spaces */
      /* eslint-enable object-curly-newline */
    };
  },
  computed: {
    ...mapGetters({
      _shopList: 'TheShopDropdown/shopList',
    }),
  },
  methods: {
    openEditPanel(shop) {
      this.isOpenEditShopPanel = true;
      this.shopToReconnect = shop;
    },
  },
};
</script>
