import { dispatchAndForward, genericErrorHandler, genericThenHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    tribeMemberList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiTribe/tribeMember/getterTribeMemberList': tribeMemberList } = rootGetters;

      return tribeMemberList;
    },
  },
  actions: {
    reportTribeMemberList({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/tribeMember/report',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Report tribeMember list',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report tribeMember list.', indefinite: true }));
    },

    createTribeMember({ dispatch, rootGetters }, { payload, httpQuery, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/tribeMember/create',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
          },
          httpQuery,
        },
        textNotifier: 'Create tribeMember',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to create tribeMember.', indefinite: true }));
    },

    updateTribeMember({ dispatch, rootGetters }, { payload, httpQuery, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/tribeMember/update',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
          },
          httpQuery,
        },
        textNotifier: 'Update tribeMember',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to update tribeMember.', indefinite: true }));
    },

    deleteTribeMember({ dispatch, rootGetters }, { payload, httpQuery, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiTribe/tribeMember/delete',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
          },
          httpQuery,
        },
        textNotifier: 'Delete tribeMember',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to delete tribeMember.', indefinite: true }));
    },
  },
};
