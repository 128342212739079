/* eslint-disable max-len */
export default {
  computed: {
    customsSplitByAssignment() {
      return Object.values(this.customs).reduce((acc, custom) => {
        if (this.newCustom.label && !custom.label.toLowerCase().includes(this.newCustom.label.toLowerCase())) return acc;

        if (this._cpo[this.customsName].includes(custom[this.keyName])) { acc.assigneds.push(custom); return acc; }

        acc.unassigneds.push(custom);

        return acc;
      }, {
        assigneds: [],
        unassigneds: [],
      });
    },
  },
  methods: {
    updateFilter(search) {
      if (!search) this.reset();
      else this.newCustom.label = search;
    },

    unassignAll() {
      const { assigneds } = this.customsSplitByAssignment;

      return Promise.all(assigneds.map(this.unassignCustom));
    },

    assignCustom(item) {
      return this._assignCustom({
        payload: { [this.keyName]: item[this.keyName], rows: [this._cpo] },
        mutations: [
          {
            target: 'ApiContact/contactPlatformOverview/UP_upsertCustom',
            payload: {
              key: this.keyName === 'cgid' ? 'labels' : 'tags',
              itemId: item[this.keyName],
              cpids: [this._cpo.cpid],
            },
          },
        ],
      });
    },

    unassignCustom(item) {
      return this._unassignCustom({
        payload: { [this.keyName]: item[this.keyName], rows: [this._cpo] },
        mutations: [
          {
            target: 'ApiContact/contactPlatformOverview/UP_upsertCustom',
            payload: {
              key: this.keyName === 'cgid' ? 'labels' : 'tags',
              itemId: item[this.keyName],
              cpids: [this._cpo.cpid],
            },
          },
        ],
      });
    },

    createCustom() {
      return this._createCustom({ payload: this.newCustom })
        .then(this.assignCustom)
        .then(() => this.reset());
    },

    reset() {
      this.newCustom = { label: '', color: '' };
      this.activeTab = 'root';
      // this.isOpen = false;
    },
  },
};
