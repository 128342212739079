<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="multibar row-vcentered">
      <b-button
        tag="router-link"
        :to="`/shopify/shop/${currentSid}/manage-webhooks`"
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
      />
    </div>

    <br>

    <h1 class="title is-1">Create manage webhook</h1>

    <br>

    <form>
      <fieldset>
        <b-field label="Topic">
          <b-select
            v-model="webhook.topic"
            placeholder="Select a topic"
            expanded
          >
            <template v-for="webhookTopic in topics">
              <optgroup
                v-if="webhookTopic"
                :key="webhookTopic.name"
                :label="webhookTopic.name"
              >
                <template v-for="topic in webhookTopic.topics">
                  <option
                    v-if="topic"
                    :key="topic"
                    :value="topic"
                  >
                    {{ topic }}
                  </option>
                </template>
              </optgroup>
            </template>
          </b-select>
        </b-field>

        <br>

        <b-field label="Address url">
          <b-input
            v-model="webhook.address"
            required
            placeholder="Address url"
          />
        </b-field>
      </fieldset>

      <br>

      <div class="buttons is-right">
        <b-button
          label="Create manage webhook"
          class="is-primary"
          :disabled="disableButton"
          @click="createNewManageWebook()"
        />
      </div>
    </form>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import topicApiShopify from '../$mixins/topicApiShopify.mixins';

export default {
  name: 'TheShopManageWebhooksCreate',
  mixins: [topicApiShopify],
  data() {
    return {
      webhook: {
        topic: null,
        address: null,
        format: 'json',
      },
    };
  },
  computed: {
    disableButton() {
      const { topic, address } = this.webhook;
      if (!topic || !address) return true;
      return false;
    },
    currentSid() {
      return this.$route.params.sid;
    },
    topics() {
      const topicAvailable = ['Order'];

      return Object.values(this.allTopics).reduce((acc, topic) => {
        if (topicAvailable.includes(topic.name)) acc.push(topic);
        return acc;
      }, []);
    },
  },
  methods: {
    ...mapActions({
      createManageWebhook: 'TheShop/createManageWebhook',
    }),
    createNewManageWebook() {
      this.createManageWebhook({
        payload: {
          sid: Number(this.currentSid),
          webhook: { ...this.webhook },
        },
      })
        .then((manageWebhook) => {
          if (manageWebhook) this.$emit('refresh');
        });
    },
  },
};
</script>
