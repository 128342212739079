<template>
  <div class="mediaPanel">
    <div
      class="navigation"
      @click="$emit('prev')"
    >
      <b-icon
        pack="ubu"
        icon="ubu-arrow-left"
        size="large"
      />
    </div>
    <div class="carouselWrapper">
      <b-carousel
        :indicator-inside="false"
        :arrow="false"
        :autoplay="false"
      >
        <b-carousel-item
          v-for="item in carouselize"
          :key="item.aid"
        >
          <UbuMediaImage
            v-if="item.type === 'IMAGE'"
            :url="item.url"
            :src-fallback="srcFallback"
          />
          <UbuMediaVideo
            v-if="item.type === 'VIDEO'"
            :url="item.url"
            :src-fallback="srcFallback"
          />
        </b-carousel-item>
        <template
          #indicators="props"
        >
          <UbuMediaImage
            v-if="carouselize[props.i] && carouselize[props.i].type === 'IMAGE'"
            :url="carouselize[props.i].url"
            :src-fallback="srcFallback"
          />
          <UbuMediaImage
            v-if="carouselize[props.i] && carouselize[props.i].type === 'VIDEO'"
            :url="`${carouselize[props.i].url}/thumbnail`"
            :src-fallback="srcFallback"
          />
        </template>
      </b-carousel>
    </div>
    <div
      class="navigation"
      @click="$emit('next')"
    >
      <b-icon
        pack="ubu"
        icon="ubu-arrow-right"
        size="large"
      />
    </div>
  </div>
</template>

<script>
const srcFallback = require('@dailyplanet/assets/images/image-placeholder.png');

export default {
  name: 'UbuMentionModalMediaPanel',
  model: {
    prop: 'mentionItem',
  },
  props: {
    mentionItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      srcFallback,
      activeIndex: null,
    };
  },
  computed: {
    carouselize() {
      console.log('🚀 ~ file: UbuMentionModalMediaPanel.vue ~ line 86 ~ carouselize ~ this.mentionItem', this.mentionItem);
      const { carousel, mainMedia } = this.mentionItem;

      return [mainMedia, ...carousel];
    },
  },
};
</script>
