/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import channelSms from './channelSms';
import templateFolder from './templateFolder';
import threadAppoint from './threadAppoint';
import instagramReach from './instagramReach';
import threadGroup from './threadGroup';

export default {
  channelSms,
  templateFolder,
  threadAppoint,
  instagramReach,
  threadGroup,
};
