<template>
  <div>
    <h3 class="ubu-welcome py-6">Ooops... It seems your invitation link has expired 😢</h3>
    <p class="TheError408_text ubu-header1">
      Please, ask the person who invited you to generate a new invitation link.
    </p>
    <router-link
      to="/"
    >
      <UbuButton
        grow
        center
        big
        secondary
      >
        Go home
      </UbuButton>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'TheError408',
};
</script>

<style lang="scss">
  .TheError408_text {
    color: var(--darkgrey);
    padding-bottom: 36px;
  }
</style>
