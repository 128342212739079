/* eslint-disable no-console */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes as addonsRoutes } from './addons';
import store from './store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
];

Object.values(addonsRoutes).forEach((addonRoutes) => {
  routes.push(...addonRoutes);
});

routes.push({ path: '*', redirect: '/error/404' });

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  // console.log('🚀 ~ file: router.js ~ line 37 ~ router.beforeEach ~ to', to);
  store.dispatch('TheMenu/UP_route', to);

  return next();
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth === true)) {
    const auth = store.getters['Authenticate/getterAuthenticate'];
    if (auth && auth.isUbu) return next();

    return store.dispatch('TheMenu/reconnect', null, { root: true })
      .then(() => next())
      .catch(() => next({ name: 'Authenticate - Login' }));
  }
  return next();
});

router.beforeEach((to, from, next) => {
  const auth = store.getters['Authenticate/getterAuthenticate'];

  if (auth && auth.oneTime) {
    if (!to.name.includes('Authenticate - First connexion')) return next({ name: 'Authenticate - First connexion - New password' });
    return next();
  }
  if (auth && !auth.oneTime) {
    if (to.name.includes('Authenticate')) return next({ name: 'Home' });
    return next();
  }

  return next();
});

export default router;
