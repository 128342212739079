import lib from './lib';
import routes from './routes';
import * as stores from './stores';
import smarts from './smarts';

export default {
  lib,
  routes,
  stores,
  smarts,
};
