/* eslint-disable max-len */
import { actions } from '@dailyplanet/data-stores/commonViewStores';
import BulkActionEngineStore from '@dailyplanet/data-stores/engine-stores/bulkAction';

export default {
  namespaced: true,
  state: {
    ...BulkActionEngineStore.state,
  },
  mutations: {
    ...BulkActionEngineStore.mutations,
  },
  getters: {
    ...BulkActionEngineStore.getters,

    tabItems: (state, getters, _rootState, rootGetters) => ({
      root: {
        tab: 'root',
        title: 'What do you want to do ?',
        subtext: 'Bulk actions',
      },
      ...rootGetters['TheAddToLabel/tabItemsForTheCrmBulk'],
      ...rootGetters['TheNewAddToCampaign/tabItemsForTheCrmBulk'],
      ...rootGetters['TheAddToCrmList/tabItemsForTheCrmBulk'],
      ...rootGetters['TheAddToTribe/tabItemsForTheCrmBulk'],
    }),
  },
  actions: {
    ...BulkActionEngineStore.actions,
    ...actions.segment,

    UP_resetStore({ commit }) {
      commit('RESET_STORE');
      commit('TheNewAddToCampaign/RESET_STORE', null, { root: true });
      commit('TheAddToCrmList/RESET_STORE', null, { root: true });
    },

    UP_checkedRows({ commit }, newData) {
      commit('SET_CHECKED_ROWS', newData);
    },
  },
};
