<template>
  <TheSimplePanel
    closing-way="emit"
    @close="$emit('close')"
  >
    <template #header>
      <h1 class="title is-1">Create list</h1>
    </template>

    <template #content>
      <UbuCRMListForm v-model="newCrmList" />

      <br>

      <div class="buttons is-right">
        <b-button
          label="Create"
          type="is-primary"
          @click="handleClick()"
        />
      </div>
    </template>
  </TheSimplePanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackbarError } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheCreateCRMListPanel',
  data() {
    return {
      newCrmList: {
        name: null,
        color: '1',
        scrapParams: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      crmListList: 'TheCRM/crmListList',
    }),
  },
  methods: {
    ...mapActions({
      _createCrmList: 'TheCRM/createCrmList',
    }),

    handleClick() {
      if (!this.newCrmList.name || !this.newCrmList.name.length) return;

      if (Object.values(this.crmListList).find(({ name }) => name === this.newCrmList.name)) {
        this.$buefy.snackbar.open({
          ...snackbarError,
          message: 'This name is already taken. Please choose another one.',
        });

        return;
      }

      this.createCrmList();
    },

    createCrmList() {
      this._createCrmList({ payload: this.newCrmList })
        .then(() => { this.$emit('close'); });
    },
  },
};
</script>
