<template>
  <div>
    <div class="buttons is-right end">
      <b-button
        label="Add selected"
        type="is-primary"
        :disabled="!localChecked.length"
        @click="$emit('next')"
      />
    </div>

    <b-tabs
      v-model="platformActiveTab"
      class="custom-icon-header start"
      :animated="false"
    >
      <template v-for="[platform, cpList] in Object.entries(splitedByPlatform)">
        <b-tab-item
          :key="platform"
          :value="platform"
          :disabled="!cpList.length"
        >
          <template #header>
            <component :is="`UbuIcon${platform.capitalize()}`" />
          </template>

          <template v-if="platformActiveTab === platform">
            <UbuSearchbar
              v-model="search"
              @clear="search = ''"
            />

            <br>

            <b-table
              :data="cpList"
              checkable
              :checked-rows.sync="localChecked"
              per-page="15"
              paginated
              pagination-size="is-small"
              pagination-position="top"
            >
              <b-table-column
                v-slot="{ row }"
                field="username"
                label="Username"
                sortable
              >
                <UbuPlatformCard
                  :platform-name="row.contactPlatform.platformName.toLowerCase()"
                  :text="row.contactPlatform.username"
                  font-size="20px"
                />
              </b-table-column>

              <template #empty>
                <p>All your selected people are already in this list.</p>
              </template>
            </b-table>
          </template>
        </b-tab-item>
      </template>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TheAddToCrmListMixin from '../mixins/TheAddToCrmList.mixin';

export default {
  name: 'TheAddToCrmListToAdd',
  mixins: [TheAddToCrmListMixin],
  data() {
    return {
      platformActiveTab: 'INSTAGRAM',
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      willBeAddedToCrmList: 'TheAddToCrmList/willBeAddedToCrmList',
    }),

    localChecked: {
      get() { return this.willBeAddedToCrmList; },
      set(newValue) { this.UP_willBeAddedToCrmList(newValue); },
    },

    splitedByPlatform() {
      return this.cpToAddToCrmList.reduce((acc, row) => {
        const { contactPlatform: { platformName } } = row;

        return acc[platformName]
          ? { ...acc, [platformName]: [row].concat(acc[platformName]) }
          : { ...acc, [platformName]: [row] };
      }, {
        INSTAGRAM: [],
        TIKTOK: [],
        YOUTUBE: [],
        FACEBOOK: [],
      });
    },
  },
  mounted() {
    if (!this.willBeAddedToCrmList.length) {
      this.localChecked = this.cpToAddToCrmList;
    }
  },
  methods: {
    ...mapActions({
      UP_willBeAddedToCrmList: 'TheAddToCrmList/UP_willBeAddedToCrmList',
    }),
  },
};
</script>
