<template>
  <TheSimplePanel
    closing-way="emit"
    @close="closePanel()"
  >
    <template #header>
      <p class="text is-3 has-text-secondary">Create folder</p>

      <div class="multibar">
        <h1 class="title start">Affiliation folder</h1>

        <TheShopDropdown
          class="start ml-2"
          icon-right="ubu-arrow-down"
          button-type="is-ghost is-borderless"
        />
      </div>
    </template>

    <template #content>
      <form @submit.prevent="createFolder()">
        <b-field label="Name">
          <b-input
            v-model="payload.title"
            placeholder="10% off"
            required
          />
        </b-field>

        <br>

        <b-field label="Description">
          <b-input
            v-model="payload.description"
            placeholder="All codes that give 10% off, limited to one purchase per customers"
            type="textarea"
            required
          />
        </b-field>

        <br>

        <div class="buttons is-right">
          <b-button
            type="is-primary"
            native-type="submit"
            label="Create"
          />
        </div>
      </form>
    </template>
  </TheSimplePanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheCreateAffiliationFolder',
  data() {
    return {
      payload: {
        title: '',
        description: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      _currentShop: 'TheCreateAffiliationCodePanel/currentShop',
    }),
  },
  methods: {
    ...mapActions({
      createAffiliationCodeFolder: 'TheManageAffiliationCodeFolder/createAffiliationCodeFolder',
    }),

    resetPayload() {
      this.payload = {
        title: '',
        description: '',
      };
    },

    createFolder() {
      return this.createAffiliationCodeFolder({
        payload: {
          ...this.payload,
          sid: this._currentShop.sid,
          shopSid: this._currentShop.sid,
          createdOn: this.$moment().format(),
        },
      })
        .then(() => this.closePanel());
    },

    closePanel() {
      this.$emit('close');
      this.resetPayload();
    },
  },
};
</script>
