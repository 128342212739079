<template>
  <div class="mainPanelFilters">
    <div class="multibar row-vcentered">
      <UbuDateRangePicker
        class="start mr-2"
        :max-date="$moment().toDate()"
        :option-date-to-use="optionDateToUse"
        @changeDate="changeDate($event)"
      />

      <UbuSearchbar
        v-model="search"
        class="start"
        placeholder="Search username..."
        @clear="clearSearchbar()"
        @pressEnter="addSearchbarFilter()"
      />

      <TheFilterDropdown
        v-model="localFilters"
        :filter-list="filtersCatalog"
        :non-displayable-filters="nonDisplayableFilters"
        :selected-segment-filter.sync="selectedSegmentFilter"
        class="start ml-2"
      />

      <slot name="actionsEnd" />
    </div>

    <div class="activeFilterList">
      <UbuDropdownFilterForm
        v-for="filter in localFilters.filter(({ key }) => !nonDisplayableFilters.includes(key))"
        :key="filter.id"
        v-model="filtersMapped[filter.id]"
        :is-not-allowed="!allowedFilters.includes(filter.key)"
        :catalog-filter="genericCatalog"
        @handle="handleFilterUpdate($event)"
        @deleteFilter="deleteFilter(filter.id)"
      />
    </div>
  </div>
</template>

<script>
import templatizeMixin from '@dailyplanet/cross-addons/filters/_mixins/templatize.mixin';

export default {
  name: 'TheMentionCartouche',
  mixins: [templatizeMixin],
  model: {
    prop: 'filters',
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    allowedFilters: {
      type: Array,
      required: true,
    },
    nonDisplayableFilters: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      search: '',
      selectedSegmentFilter: null,
      optionDateToUse: [
        'currentWeek',
        'previousWeek',
        'currentMonth',
        'previousMonth',
      ],
    };
  },
  computed: {
    localFilters: {
      get() {
        return this.filters
          .map(({ values, list, ...rest }) => ({
            ...rest,
            values: values.map((value) => value),
            list: list ? list.map((l) => ({ ...l })) : null,
          }));
      },
      set(values) { this.$emit('input', values); },
    },

    filtersMapped() {
      return this.localFilters.arrayMapper('id');
    },

    filtersCatalog() {
      return Object.values(this.genericCatalog)
        .filter(({ key }) => this.allowedFilters.includes(key));
    },
  },
  methods: {
    clearSearchbar() {
      this.localFilters = this.filters.filter(({ key }) => key !== 'contactPlatform.username');
      this.search = '';
    },

    addSearchbarFilter() {
      const filters = this.filters.arrayMapper('key');
      delete filters['contactPlatform.username'];
      this.localFilters = Object.values(filters).concat({ key: 'contactPlatform.username', values: [this.search] });
    },

    handleFilterUpdate(filter) {
      // replace old filter by new & redefine filters array
      const newFilters = {
        ...this.filters.arrayMapper('id'),
        [filter.id]: filter,
      };

      this.localFilters = Object.values(newFilters);
    },

    deleteFilter(event) {
      const filters = this.filters.filter(({ id }) => id !== event);
      this.$emit('input', filters);
    },

    changeDate(dateRange) {
      const [min, max] = dateRange.value;
      const filters = this.filters.arrayMapper('key');
      delete filters['publication.createdOn'];
      this.localFilters = Object.values(filters).concat({ key: 'publication.createdOn', values: [this.$moment(min).startOf('day').format('YYYY-MM-DD HH:mm:ss'), this.$moment(max).endOf('day').format('YYYY-MM-DD HH:mm:ss')] });
    },
  },
};
</script>
