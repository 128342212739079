<template>
  <div>
    <div class="buttons is-right">
      <b-button
        v-if="removedToScan.length"
        label="Scan mentions"
        type="is-primary"
        @click="$emit('next')"
      />
      <b-button
        v-else
        label="Next"
        type="is-primary"
        @click="$emit('next')"
      />
    </div>

    <UbuSearchbar
      v-model="search"
      @clear="search = ''"
    />

    <br>

    <b-table
      :data="toScans"
      checkable
      :checked-rows.sync="localChecked"
      paginated
      pagination-size="is-small"
    >
      <b-table-column
        v-slot="{ row }"
        field="username"
        label="Username"
      >
        <UbuPlatformCard
          :platform-name="row.contactPlatform.platformName.toLowerCase()"
          :text="row.contactPlatform.username"
          font-size="20px"
        />
      </b-table-column>

      <template #empty>
        <p>
          You have selected no accounts to scan mentions from.
        </p>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TheAddToCampaignMixin from '../mixins/TheAddToCampaign.mixin';

export default {
  name: 'TheAddToCampaignToScan',
  mixins: [TheAddToCampaignMixin],
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      newBulkAction: 'TheNewAddToCampaign/newBulkAction',
      removedToScan: 'TheNewAddToCampaign/removedToScan',
    }),

    localChecked: {
      get() { return this.removedToScan; },
      set(newValue) { this.UP_removedToScan(newValue); },
    },

    localBulkAction: {
      get() { return this.newBulkAction; },
      set(newValue) { this.UP_newBulkAction(newValue); },
    },
  },
  mounted() {
    if (!this.removedToScan.length) {
      this.localChecked = this.toScans;
    }
  },
  methods: {
    ...mapActions({
      UP_newBulkAction: 'TheNewAddToCampaign/UP_newBulkAction',
      UP_removedToScan: 'TheNewAddToCampaign/UP_removedToScan',
    }),
  },
};
</script>
