import {
  dispatchAndForward, genericErrorHandler,
} from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    affiliationRewardList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/affiliationReward/getterAffiliationRewardList': affiliationRewardList } = rootGetters;

      return affiliationRewardList;
    },
    totalAffiliationRewardList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/affiliationReward/getterTotalAffiliationRewardList': totalAffiliationRewardList } = rootGetters;

      return totalAffiliationRewardList;
    },
    totalsByPeriod: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/affiliationReward/getterTotalsByPeriod': totalsByPeriod } = rootGetters;

      return totalsByPeriod;
    },
    rewardList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/affiliationReward/getterRewardList': rewardList } = rootGetters;

      return rewardList;
    },
    totalRewardList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/affiliationReward/getterTotalRewardList': totalRewardList } = rootGetters;

      return totalRewardList;
    },
  },
  actions: {
    reportAffiliationRewardList({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationReward/report',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Report affiliation reward',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report reward list.', indefinite: true }));
    },

    reportReward({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationReward/reportReward',
        context: { payload, httpQuery },
        textNotifier: 'report Reward list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report reward list.', indefinite: true }));
    },

    rebuildBalances({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationReward/rebuildBalances',
        context: { payload, httpQuery },
        textNotifier: 'start of rebuild balance',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to rebuild balance.', indefinite: true }));
    },

    getTotalCommissionByPeriod({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationReward/getTotalCommissionByPeriod',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'get totals details reward',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to get totals details reward.', indefinite: true }));
    },

    reviewAffiliationReward({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationReward/setReviews',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Review affiliation reward',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to review reward.', indefinite: true }));
    },

    markAsPaidReward({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationReward/markAsPaid',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload }, httpQuery },
        textNotifier: 'Review affiliation reward',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to markAsPaid reward.', indefinite: true }));
    },
  },
};
