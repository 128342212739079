import brand from './brand.store';
import crewUser from './crewUser.store';
import crewAdmin from './crewAdmin.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    brand,
    crewUser,
    crewAdmin,
  },
};
