/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';

export default {
  namespaced: true,
  state: {
    activityStatusListWithCounters: {},
  },
  mutations: {
    SET_ACTIVITY_STATUS_LIST_WITH_COUNTERS(state, newData) { state.activityStatusListWithCounters = newData; },
    RESET_STORE(state) {
      state.activityStatusListWithCounters = {};
    },
  },
  getters: {
    getterActivityStatusListWithCounters: (state) => state.activityStatusListWithCounters,
  },
  actions: {
    report({ commit }, { payload, httpQuery }) {
      return clark.api.apiInfluence.threadCounters.report({ payload, httpQuery })
        .then((activityStatusListWithCounters) => {
          commit('SET_ACTIVITY_STATUS_LIST_WITH_COUNTERS', activityStatusListWithCounters.arrayMapper('status'));
        });
    },
  },
  modules: {},
};
