<template>
  <div class="multibar row-vcentered">
    <template v-if="cpo.contactPlatform">
      <UbuAvatar
        class="start"
        :profile-picture="cpo.contactPlatform.profilePicture"
        :initial-name="cpo.contactPlatform.username[0]"
        :is-active="hasThreadTidKey && !!cpo.contactPlatformStatistic.threadTid"
        :ended-sticker="hasThreadTidKey"
        :size="24"
        :is-size="24"
      />

      <span
        class="start ml-1 username-rootable"
        @click="$emit('selectContactPlatform', cpo.contactPlatform.cpid)"
      >
        {{ cpo.contactPlatform.username }}
      </span>
    </template>

    <span v-else>-</span>
  </div>
</template>

<script>
export default {
  name: 'UbuTableProfile',
  props: {
    cpo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hasThreadTidKey() {
      const { threadTid } = this.cpo.contactPlatformStatistic;

      if (threadTid || threadTid === false || threadTid === null) return true;
      return false;
    },
  },
};
</script>
