/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {
    isActive: false,
  },
  getters: {
    isActive: (state) => state.isActive,
  },
  mutations: {
    TOGGLE_IS_ACTIVE(state, newData) { state.isActive = newData; },
  },
  actions: {
    toggleActiveDevDetails({ commit }, isActive) {
      commit('TOGGLE_IS_ACTIVE', isActive);
    },
  },
};
