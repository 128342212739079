<template>
  <section class="ubuContactPlatformPanelTab">
    <article class="ubuContactPlatformPanelItem">
      <header class="multibar row-vcentered p-0">
        <h3 class="start">Affiliations</h3>

        <p class="end subtext is-3 displayOnHover">Add affiliation</p>

        <b-button
          class="end"
          :disabled="!_currentShop || !_currentShop.sid"
          icon-pack="ubu"
          icon-left="ubu-add"
          type="is-tertiary"
          @click.native="handleCreateAffiliation()"
        />
      </header>

      <div class="tile is-ancestor is-vertical">
        <div class="tile is-parent is-horizontal">
          <template v-for="(_, index) in tiles">
            <div
              :key="index"
              class="tile is-child"
            >
              <p class="subtext is-3 has-text-secondary">{{ tiles[index].label }}</p>
              <p class="text is-3">
                {{ tiles[index].value ? tiles[index].symbolBefore : '' }}
                {{ tiles[index].value | nFormatter }}
                {{ tiles[index].value ? tiles[index].symbolAfter : '' }}
              </p>
            </div>
          </template>
        </div>
      </div>
    </article>

    <UbuContactPlatformPanelAffiliationDetailed
      v-for="item in sortedAffiliate"
      :key="item.acid"
      :affiliation-code="item"
      :affiliation-code-folder-list="_affiliationCodeFolderList"
      :campaign-list="_campaignList"
      :shop-list="_shopList"
      @edit="editAffiliationCode($event)"
    />

    <TheCreateAffiliationCodePanel
      v-if="isPanelCreateOpen"
      :contact-platform="contactPlatformOverview.contactPlatform"
      @refresh="refresh()"
      @close="isPanelCreateOpen = false"
    />

    <TheEditAffiliationCodePanel
      v-if="selectedAffiliationCode.acid"
      :affiliation-code="selectedAffiliationCode"
      @refresh="refresh()"
      @close="selectedAffiliationCode = {}; refresh();"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheContactPlatformPanelAffiliationTab',
  data() {
    return {
      selectedAffiliationCodeAcid: null,
      selectedAffiliationCode: {},
      isPanelCreateOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      contactPlatformOverview: 'TheContactPlatformPanel/contactPlatformOverviewUnhelded',
      _affiliationCodeFolderList: 'TheContactPlatformPanel/affiliationCodeFolderList',
      _campaignList: 'TheContactPlatformPanel/campaignList',
      accountFeatures: 'TheShopify/accountFeatures',
      _currentShop: 'TheSettingWorkspaceSetting/currentShop',
      _shopList: 'TheCreateAffiliationCodePanel/shopList',
    }),

    tiles() {
      return [
        {
          label: 'Coupons 🎟',
          value: this.contactPlatformOverview.contactPlatformStatistic.totalCoupons,
        },
        {
          label: 'Revenue 💰',
          value: this.contactPlatformOverview.contactPlatformStatistic.totalRevenuesUsd,
          symbolBefore: '$',
        },
        {
          label: 'Orders 🛍',
          value: this.contactPlatformOverview.contactPlatformStatistic.totalOrders,
        },
      ];
    },

    sortedAffiliate() {
      if (!this.contactPlatformOverview.affiliationList) return [];

      return Object.values(this.contactPlatformOverview.affiliationList)
        .sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());
    },
  },
  methods: {
    ...mapActions({
      emitSegmentEvent: 'TheShopify/emitSegmentEvent',
      _reportContactPlatformAffiliation: 'TheContactPlatformPanel/reportContactPlatformAffiliation',
    }),

    handleCreateAffiliation() {
      if (!this.accountFeatures('SHOPIFY')) {
        this.emitSegmentEvent({ event: 'createAffiliationNoFeature' });
        this.$intercom.showNewMessage('Hi! I would like to activate the SHOPIFY module! 🙌');

        return;
      }
      this.isPanelCreateOpen = true;
    },

    editAffiliationCode(coupon) {
      this.selectedAffiliationCode = coupon;
    },

    refresh() {
      return this._reportContactPlatformAffiliation({
        payload: { cpid: this.contactPlatformOverview.cpid },
        httpQuery: { sorting: 'affiliationCode.createdOn,desc' },
      });
    },
  },
};
</script>
