import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  getters: {
    ...getters.autoReport,
  },
  actions: {
    ...actions.autoReport,
  },
};
