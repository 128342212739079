<template>
  <b-menu-item
    expanded
    :class="
      'popover popover-item-select' +
        [type ? ` is-${type}` : ''] +
        [disabled ? ' is-disabled' : ''] +
        [hovered ? ' is-hovered' : ''] +
        [focused ? ' is-focused' : '']
    "
  >
    <template #label="props">
      <div class="is-flex is-align-items-center">
        <b-icon
          v-if="iconLeft.icon"
          pack="ubu"
          :icon="iconLeft.icon"
          class="popover-icon-left"
        />
        <span>
          {{ name }}
        </span>
      </div>
      <b-icon
        :icon="props.expanded ? 'menu-up' : 'menu-down'"
        class="is-pulled-right"
      />
    </template>
    <!-- v-if="props.expanded" -->
    <div
      class="popover-dropdown-item-container is-flex is-flex-direction-column"
    >
      <b-menu>
        <component
          :is="item.component"
          v-for="item in items"
          :key="item.name"
          v-bind="item"
          :type="type"
          :disabled="!!item.disabled"
          :hovered="!!item.hovered"
          :focused="!!item.focused"
          :event="item.event"
        />
      </b-menu>
    </div>
  </b-menu-item>
</template>

<script>
export default {
  name: 'UbuPopoverItemSelect',
  props: {
    type: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hovered: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: Object,
      default: () => {},
    },
    iconRight: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
    props: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
