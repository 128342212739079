<template>
  <b-table
    ref="table"
    :data="salesContactList"
    paginated
    :per-page="20"
    :opened-detailed="defaultOpenedDetails"
    detailed
    detail-key="username"
    :show-detail-icon="true"
    :default-sort="['username']"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    :debounce-search="1000"
    class="scrollable"
    @check="$emit('checkRow', $event)"
    @check-all="$emit('checkAllRows', $event)"
  >
    <b-table-column
      v-slot="props"
      sortable
      searchable
      :visible="columns.username.visible"
      field="username"
      label="username"
    >
      {{ props.row.username }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      sortable
      :visible="columns.updatedOn.visible"
      field="updatedOn"
      label="updatedOn"
    >
      {{ $moment(props.row.updatedOn).format('YY-MM-DD') }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      sortable
      :visible="columns.engagement.visible"
      field="engagement"
      label="engagement"
    >
      {{ humanizeFloat(props.row.engagement, true) }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      sortable
      :visible="columns.engagement.visible"
      :custom-sort="sortInt8('followers')"
      field="followers"
      label="followers"
    >
      {{ humanizeInteger(props.row.followers) }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      sortable
      :visible="columns.growth.visible"
      field="growth"
      label="growth"
    >
      {{ humanizeFloat(props.row.growth, true) }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      sortable
      searchable
      :visible="columns.zone.visible"
      field="zone"
      label="zone"
    >
      {{ props.row.zone }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      sortable
      searchable
      :visible="columns.language.visible"
      field="language"
      label="language"
    >
      {{ props.row.language }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      sortable
      searchable
      :visible="columns.category.visible"
      field="category"
      label="category"
    >
      {{ props.row.category }}
    </b-table-column>

    <b-table-column
      v-slot="{ row: { origin } }"
      sortable
      searchable
      :visible="columns.origin.visible"
      field="origin"
      label="origin"
    >
      <b-tag
        v-if="origin === 'SUGGESTION'"
        type="is-link"
      >
        {{ origin.toLowerCase() }}
      </b-tag>
      <b-tag
        v-if="origin === 'MANUAL'"
        type="is-primary"
      >
        {{ origin.toLowerCase() }}
      </b-tag>
    </b-table-column>

    <b-table-column
      v-slot="{ row: { isVerified } }"
      sortable
      searchable
      :visible="columns.reviewed.visible"
      field="isVerified"
      label="reviewed"
    >
      <b-tag
        v-if="isVerified === true"
        type="is-success"
      >
        validated
      </b-tag>
      <b-tag
        v-if="isVerified === false"
        type="is-danger"
      >
        rejected
      </b-tag>
      <b-tag
        v-if="isVerified === null"
        type="is-dark"
      >
        to be reviewed
      </b-tag>
    </b-table-column>

    <b-table-column
      v-slot="props"
      sortable
      searchable
      :visible="columns['scraping zone'].visible"
      field="scrapingZoneSzid"
      label="scraping zone"
    >
      {{ props.row.scrapingZoneSzid }}
    </b-table-column>

    <template #detail="props">
      <UbuSalesContactTableDetail
        v-model="props.row"
        :scraping-zone-list="scrapingZoneList"
        @updateContact="$emit('updateContact', $event)"
        @createLead="$emit('createLead', $event)"
        @updateLead="$emit('updateLead', $event)"
      />
    </template>

    <!-- <template #bottom-left>
      <div class="multibar column">
        <DevDetails :cnt="{name: 'checkedRows', obj: checkedRows}" />
      </div>
    </template> -->
  </b-table>
</template>

<script>
import CommonMixin from '@dailyplanet/mixins/CommonMixin';

export default {
  name: 'UbuSalesContactTable',
  mixins: [CommonMixin],
  props: {
    salesContactList: {
      type: Array,
      required: true,
    },
    columns: {
      type: Object,
      required: true,
    },
    scrapingZoneList: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      defaultOpenedDetails: [],
    };
  },
  methods: {
    sortInt8: (key) => (a, b, isAsc) => (isAsc
      ? Number(a[key]) > Number(b[key])
      : Number(b[key]) > Number(a[key])),
  },
};
</script>
