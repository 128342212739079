<template>
  <span>
    {{ value | nFormatter }}
  </span>
</template>

<script>
import HelpersMixin from '@dailyplanet/mixins/HelpersMixin';

export default {
  name: 'UbuTableShortenNumber',
  mixins: [HelpersMixin],
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    symbol: {
      type: String,
      default: '',
    },
  },
};
</script>
