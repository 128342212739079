/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

const formatDate = 'YYYY-MM-DD HH:mm';

export default {
  mixins: [FilterEngineMixin],
  data() {
    return {
      formatDate,
      searchChannelGroup: '',
      filterList: [
        { filter: 'Errors', cantBeWith: ['Warnings', 'Will be Canceled'], color: 'danger' },
        { filter: 'Warnings', cantBeWith: ['Errors', 'Will be Canceled'], color: 'warning' },
        { filter: 'Will be Canceled', cantBeWith: ['Errors', 'Warnings'], color: 'fade' },
        { filter: 'Updateds', cantBeWith: ['Not Updateds'] },
        { filter: 'Not Updateds', cantBeWith: ['Updateds'] },
        { filter: 'Tasks Scheduled', cantBeWith: ['Tasks Not Scheduled'] },
        { filter: 'Tasks Not Scheduled', cantBeWith: ['Tasks Scheduled'] },
        { filter: 'Tasks Done', cantBeWith: ['Tasks Not Done'] },
        { filter: 'Tasks Not Done', cantBeWith: ['Tasks Done'] },
        { filter: 'Tasks Canceled', cantBeWith: ['Tasks Not Canceled'] },
        { filter: 'Tasks Not Canceled', cantBeWith: ['Tasks Canceled'] },
        // { filter: 'Channel Group', cantBeWith: [] },
      ],
      timeFields: [
        { label: 'Created On', field: 'createdOn' },
        { label: 'Updated On', field: 'updatedOn' },
        { label: 'Trigger On', field: 'triggerOn' },
      ],
      stringFields: [
        { label: 'Timezone', field: 'timezone' },
        { label: 'Activity Status', field: 'status' },
        { label: 'Task State', field: 'state' },
        { label: 'Task Error', field: 'error' },
      ],
      subMenuDropdownIsClosed: {
        columnSelector: true,
        backendQuery: false,
        frontendFilters: false,
        workspaces: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      tasks: 'TheMonitoring/tasks',
    }),
    filteredChannelGroups() {
      const data = this.tasks.reduce((acc, r) => {
        if (!acc[r.channelGroupCgid]) {
          acc[r.channelGroupCgid] = { name: r.name, cgid: r.channelGroupCgid };
        }
        return acc;
      }, {});

      const filtersToApply = {};
      if (this.searchChannelGroup) filtersToApply.filterChannelGroupName = this.searchChannelGroup;

      return this.filterEngine(Object.values(data), filtersToApply)
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    _filterCollection() {
      return {
        filterChannelGroup(raw) {
          const search = Number(raw);
          return (m) => (Number(m.channelGroupCgid) === search ? m : undefined);
        },
        filterChannelGroupName(raw) {
          const search = String(raw).toLowerCase();
          return (m) => (String(m.name).toLowerCase().includes(search) ? m : undefined);
        },
        filterUpdatedOn() { return (row) => (row.updatedOn ? row : undefined); },
        filterNotUpdatedOn() { return (row) => (!row.updatedOn ? row : undefined); },
        filterTriggerOn() { return (row) => (row.triggerOn ? row : undefined); },
        filterNotTriggerOn() { return (row) => (!row.triggerOn ? row : undefined); },
        filterDone() { return (row) => (row.state === 'DONE' ? row : undefined); },
        filterNotDone() { return (row) => (row.state !== 'DONE' ? row : undefined); },
        filterCanceled() { return (row) => (row.state === 'CANCELED' ? row : undefined); },
        filterNotCanceled() { return (row) => (row.state !== 'CANCELED' ? row : undefined); },
        filterErrors() { return (row) => (row.error ? row : undefined); },
        filterWarnings() { return (row) => (row.state === 'DONE' && row.status === 'SCHEDULED' ? row : undefined); },
        filterWillBeCanceled() { return (row) => (!['DONE', 'CANCELED', 'CANCEL'].includes(row.state) && !['SCHEDULED', 'CANCELED'].includes(row.status) ? row : undefined); },
      };
    },
    filteredDatas() {
      const { filters } = this;

      const filtersToApply = {};
      if (this.selectedChannelGroup) filtersToApply.filterChannelGroup = this.selectedChannelGroup;
      if (filters.includes('Updateds')) filtersToApply.filterUpdatedOn = true;
      if (filters.includes('Not Updateds')) filtersToApply.filterNotUpdatedOn = true;
      if (filters.includes('Tasks Scheduled')) filtersToApply.filterTriggerOn = true;
      if (filters.includes('Tasks Not Scheduled')) filtersToApply.filterNotTriggerOn = true;
      if (filters.includes('Tasks Done')) filtersToApply.filterDone = true;
      if (filters.includes('Tasks Not Done')) filtersToApply.filterNotDone = true;
      if (filters.includes('Tasks Canceled')) filtersToApply.filterCanceled = true;
      if (filters.includes('Tasks Not Canceled')) filtersToApply.filterNotCanceled = true;
      if (filters.includes('Errors')) filtersToApply.filterErrors = true;
      if (filters.includes('Warnings')) filtersToApply.filterWarnings = true;
      if (filters.includes('Will be Canceled')) filtersToApply.filterWillBeCanceled = true;

      return this.filterEngine([...this.tasks], filtersToApply)
        .sort((a, b) => {
          const itemA = a[this.sortField];
          const itemB = b[this.sortField];

          if (typeof itemA === 'number') {
            if (this.sortOrder === 'desc') return itemA - itemB;
            return itemB - itemA;
          }
          if (typeof itemA === 'string' && this.$moment(itemA).isValid()) {
            if (this.sortOrder === 'desc') return this.$moment(itemA).unix() - this.$moment(itemB).unix();
            return this.$moment(itemB).unix() - this.$moment(itemA).unix();
          }
          if (this.sortOrder === 'desc') return itemA < itemB ? -1 : 1;
          return itemA > itemB ? -1 : 1;
        });
    },

  },
  methods: {
    ...mapActions({
      _reportTasks: 'TheMonitoring/reportTask',
      _rescheduleTask: 'TheMonitoring/rescheduleTask',
      _bulkEndActivity: 'TheMonitoring/deleteBulkActivity',
      UP_task: 'TheMonitoring/UP_task',
      _allowProtoList: 'TheMonitoring/allowProtoList',
      _rejectProtoList: 'TheMonitoring/rejectProtoList',
    }),
    confirmCancelTask(task) {
      this.$buefy.dialog.confirm({
        title: 'Reject protolist order',
        // eslint-disable-next-line max-len
        message: `Are you sure you want to <b>cancel</b> the activity with username "${task.username}" ?
        This action cannot be undone and will cancel any task related.`,
        confirmText: 'Cancel activity',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.cancelActivity(task),
      });
    },
    cancelActivity(task) {
      // eslint-disable-next-line object-curly-newline
      const { campaignCid: cid, channelGroupCgid: cgid, aid, tid } = task;
      this.cancelTaskIsLoading.push(tid);

      return this._bulkEndActivity({ payload: { cid, aids: [`${aid}`], cgid } })
        .then(() => this.UP_task({ ...task, status: 'CANCELED' }))
        .then(() => {
          this.cancelTaskIsLoading = this.cancelTaskIsLoading.filter((_tid) => Number(tid) !== Number(_tid));
          this.$buefy.snackbar.open({
            message: 'Activity successfully canceled',
            type: 'is-success',
            position: 'is-top',
          });
        })
        .catch(() => {
          this.cancelTaskIsLoading = this.cancelTaskIsLoading.filter((_tid) => Number(tid) !== Number(_tid));
        });
    },
    confirmRetryTask(task) {
      this.$buefy.dialog.confirm({
        title: 'Reject protolist order',
        // eslint-disable-next-line max-len
        message: `Are you sure you want to <b>retry</b> this task n°(${task.tid}) ?
        This action cannot be undone and will reschedule task at next available time.`,
        confirmText: 'Retry task',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => this.retryTaks(task),
      });
    },
    retryTaks(task) {
      // eslint-disable-next-line object-curly-newline
      const { tid } = task;
      this.retryIsLoading.push(tid);

      return this._rescheduleTask({ payload: { tid } })
        // .then(() => this.UP_task({ ...task, status: 'CANCELED' }))
        .then(() => {
          this.retryIsLoading = this.retryIsLoading.filter((_tid) => Number(tid) !== Number(_tid));
          this.$buefy.snackbar.open({
            message: 'Task successfully rescheduled',
            type: 'is-success',
            position: 'is-top',
          });
        })
        .catch(() => {
          this.retryIsLoading = this.retryIsLoading.filter((_tid) => Number(tid) !== Number(_tid));
        });
    },
  },
};
