<template>
  <div>
    <b-tab-item value="root.askUgc">
      <template v-if="!isLoading">
        <template v-if="cpidsWithThread.length">
          <template v-if="cpidsWithThread.length === 1">
            <UbuContactPlatformCard :contact-platform="contactPlatform" />

            <br>
          </template>

          <b-field
            label="Time zone"
            grouped
          >
            <b-field expanded>
              <UbuAutocomplete
                v-model="timeZoneFilter"
                :autofocus="true"
                :options="filteredTimeZonePreset"
                :required="true"
                placeholder="Time Zone"
              />
            </b-field>

            <template>
              <b-tooltip
                label="Messages will be sent between 8AM and 8PM of the selected timezone."
                position="is-left"
                append-to-body
              >
                <b-icon
                  size="is-small"
                  pack="ubu"
                  icon="ubu-information"
                />
              </b-tooltip>
            </template>
          </b-field>

          <br>

          <TheCustomisableTextarea

            v-model="localMessage"
            :textarea-height="textareaHeight"
            :dropdown-position="dropdownPosition"
            @next="handleAskUgcStep()"
          />

          <br>

          <p class="subtext has-text-secondary">
            The Instagram API allows you to send messages
            within 7 days after receiving the mentions.
          </p>

          <br>
        </template>

        <b-message
          v-if="contactPlatformsWithoutThread.length"
          type="is-classic"
        >
          <p class="subtitle has-text-secondary mb-1">
            {{ noThreadsTitle }}
          </p>
          <p class="text is-3">
            Instagram requires you to have an existing conversation
            with them to perform this action.
            Therefore, the following people won’t be contacted.
          </p>

          <div
            class="multibar row-vcentered mt-2 mb-2"
          >
            <b-button
              type="is-ghost"
              class="start"
              :label="showList ? 'Hide people' : 'Show people'"
              @click="showList = !showList"
            />
            <span class="start has-text-secondary-20">|</span>
            <b-button
              type="is-ghost"
              class="start"
              label="Copy list"
              @click="copyToClipBoard(contactPlatformsWithoutThread.map(({username}) => username))"
            />
          </div>

          <template v-if="showList">
            <div
              v-for="(user, index) in contactPlatformsWithoutThread"
              :key="index"
            >
              <div class="multibar row-vcentered">
                <UbuThreadListUserPicture
                  class="start mr-2"
                  :profile-picture="user.profilePicture"
                  :initial-name="user.username"
                  :size="16"
                  :is-size="16"
                />
                <p class="start text is-3">{{ user.username }}</p>
                <b-button
                  class="end"
                  icon-left="ubu-copy"
                  icon-pack="ubu"
                  type="is-tertiary-icon"
                  @click="copyToClipBoard([user.username])"
                />
              </div>
              <hr>
            </div>
          </template>
        </b-message>
      </template>
    </b-tab-item>

    <b-tab-item value="root.askUgc.addTag">
      <TheAskUgcPermissionTag
        v-model="tgid"
        :is-wizard-loading="isWizardLoading"
        @next="executeWizard()"
      />
    </b-tab-item>

    <b-tab-item value="root.askUgc.done">
      <b-notification
        v-if="isWizardSuccessed"
        type="is-success"
        has-icon
        icon-pack="ubu"
        icon="ubu-close"
        :closable="false"
      >
        Success! Messages will be sent between 8AM and 8PM of the selected timezone.
      </b-notification>

      <b-notification
        v-if="isWizardFailed"
        type="is-danger"
        has-icon
        icon-pack="ubu"
        icon="ubu-failure"
        :closable="false"
      >
        {{ errorNotification }}
      </b-notification>
    </b-tab-item>
  </div>
</template>

<script>
/* eslint-disable max-len */

import { mapActions, mapGetters } from 'vuex';
import { snackbarSuccess } from '@dailyplanet/utils/helpers';
import momentTz from 'moment-timezone';

export default {
  name: 'TheAskUgcPermission',
  model: {
    prop: 'contactPlatformList',
  },
  props: {
    contactPlatformList: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Object,
      required: true,
    },
    tabItems: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      textareaHeight: 800,
      text: 'Thank you for your mention! Do you mind if we save that content and reuse it later? We’ll make sure to tag you! 😎',
      dropdownPosition: {
        emoji: 'is-bottom-left',
        template: 'is-bottom-left',
      },
      tgid: null,
      isWizardLoading: false,
      isWizardSuccessed: false,
      isWizardFailed: false,
      errorNotification: 'An error has occured, contact support !',
      showList: false,
      timeZoneFilter: {
        preset: momentTz.tz.names(),
        selected: null,
        value: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      threadAndParticipantList: 'TheAskUgcPermission/threadAndParticipantList',
    }),
    contactPlatform() {
      return this.contactPlatformList[0];
    },
    localMessage: {
      get() { return this.text; },
      set(newValue) { this.text = newValue.trim(); },
    },
    cpidsWithThread() {
      return Object.keys(this.threadAndParticipantList);
    },
    contactPlatformsWithoutThread() {
      return this.contactPlatformList
        .filter(({ cpid }) => !this.cpidsWithThread.includes(cpid));
    },
    noThreadsTitle() {
      if (!this.cpidsWithThread.length) return 'People can’t be contacted';
      return `${this.contactPlatformsWithoutThread.length} people can’t be contacted`;
    },
    filteredTimeZonePreset() {
      if (!this.timeZoneFilter) return undefined;

      return this.timeZoneFilter.preset.filter((option) => option
        .toString()
        .toLowerCase()
        .indexOf(this.timeZoneFilter.value.toLowerCase()) >= 0);
    },
  },
  watch: {
    contactPlatformList() {
      if (!this.contactPlatformList.length) return undefined;
      return this.reportThreadAndParticipant();
    },
  },
  mounted() {
    // get user timezone from his browser
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // allowed timezone list
    const timezones = momentTz.tz.names();

    if (tz && timezones.includes(tz)) this.timeZoneFilter.value = tz;

    if (!this.contactPlatformList.length) return undefined;

    return this.reportThreadAndParticipant();
  },
  methods: {
    ...mapActions({
      createTasks: 'TheAskUgcPermission/createTasks',
      _reportThreadAndParticipant: 'TheAskUgcPermission/reportThreadAndParticipant',
    }),
    reportThreadAndParticipant() {
      this.isLoading = true;
      this._reportThreadAndParticipant({ payload: { cpids: this.contactPlatformList.map(({ cpid }) => cpid) } })
        .then(() => { this.isLoading = false; });
    },

    handleAskUgcStep() {
      if (!this.timeZoneFilter.value.length) return;

      this.$emit('update:activeTab', this.tabItems['root.askUgc.addTag']);
    },

    executeWizard() {
      this.isWizardLoading = true;

      return this.createTasks({
        payload: {
          text: this.text,
          tgid: this.tgid,
          cpids: this.cpidsWithThread,
          timezone: this.timeZoneFilter.value,
        },
      })
        .then(() => {
          this.isWizardSuccessed = true;
          this.$emit('update:activeTab', this.tabItems['root.askUgc.done']);
          // this.segmentTrack('requestUGCRights');
        })
        .catch((error) => {
          console.error('🚀 ~ file: TheAskUgcPermission.vue ~ line 113 ~ executeWizard ~ error', error);
          switch (error) {
            case 'E_NOT_FOUND_WS_ASK_UGC_PERMISSION_MISSING_THREADS':
              this.errorNotification = `There is no active thread for ${this.cpidsWithThread.length === 1 ? 'this' : 'these'} people`;
              break;

            default:
              this.errorNotification = `Something go wrong when trying to send message to ${this.cpidsWithThread.length === 1 ? 'this' : 'these'} people. Please ask support!`;
              break;
          }
          this.isWizardFailed = true;
          this.$emit('update:activeTab', this.tabItems['root.askUgc.done']);
        })
        .finally(() => {
          this.isWizardLoading = false;
        });
    },
    copyToClipBoard(usernames) {
      navigator.clipboard.writeText(usernames.join(', '));
      this.$buefy.snackbar.open({
        message: `${usernames.length > 1 ? 'usernames were' : 'username was'} copied to clipboard.`,
        ...snackbarSuccess,
      });
    },
  },
};
</script>
