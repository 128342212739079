/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';
// import activity from '../../commonViewStores/activity';

export default {
  namespaced: true,
  state: {
    detailedActivityList: {},
  },
  mutations: {
    SET_DETAILED_ACTIVITY_LIST(state, newData) { state.detailedActivityList = newData; },
    RESET_STORE(state) {
      state.detailedActivityList = {};
    },
  },
  getters: {
    getterDetailedActivityList: (state) => state.detailedActivityList,
  },
  actions: {
    report({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'REPORT_DETAILLED_ACTIVITY.REPORT')) return undefined;

      return clark.api.channelGroup.reportDetailledActivityContactPanel.report({ payload, httpQuery: {} })
        .then((detailedActivityList) => { commit('SET_DETAILED_ACTIVITY_LIST', detailedActivityList); });
    },

    get({ commit, rootGetters, getters: { getterDetailedActivityList } }, { payload }) {
      if (!isActionAllowed(rootGetters, 'REPORT_DETAILLED_ACTIVITY.GET')) return undefined;

      return clark.api.channelGroup.reportDetailledActivityContactPanel.get({ payload, httpQuery: {} })
        .then((detailedActivity) => {
          commit('SET_DETAILED_ACTIVITY_LIST', { ...getterDetailedActivityList, [detailedActivity.activity.aid]: detailedActivity });
        });
    },
  },
};
