<template>
  <UbuDropdown
    position="is-bottom-right"
  >
    <template #button>
      <button
        v-if="selectedItem"
        class="button flex w-100"
        expanded
      >
        <div class="multibar row-vcentered w-100">
          <UbuDropdownStatusRow
            class="start"
            :status="selectedItem"
          />

          <span class="end ml-2 counter">
            {{ selectedItem.count }}
          </span>

          <b-icon
            class="end ml-2"
            size="is-small"
            pack="ubu"
            icon="ubu-arrow-down"
          />
        </div>
      </button>
    </template>

    <template #content>
      <b-dropdown-item
        v-for="item in statusList"
        :key="item.status"
        :class="[{ 'is-active': selectedStatus === item.status}]"
        @click="$emit('input', item.status)"
      >
        <div class="multibar row-vcentered">
          <UbuDropdownStatusRow
            class="start"
            :status="item"
          />

          <span class="end counter">
            {{ item.count }}
          </span>
        </div>
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
export default {
  name: 'UbuDropdownCampaignActivityStatus',
  model: {
    prop: 'selectedStatus',
  },
  props: {
    selectedStatus: {
      type: [String, Number],
      required: true,
    },
    statusList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // selectedStatus: 'ALL_STATUS',
    };
  },
  computed: {
    selectedItem() {
      // if (this.selectedStatus === undefined) return this.statusList.find(({ status }) => status === 'ALL_STATUS');
      return this.statusList.find(({ status }) => status === this.selectedStatus);
    },
  },
};
</script>
