const Log = {
  info: console.log.bind(console, 'Websocket:'),
  error: console.error.bind(console, 'Websocket:'),
};

const {
  VUE_APP_SELINA_APP_NOTIF_PROT: wsProtocol = 'ws',
  VUE_APP_SELINA_APP_NOTIF_PORT: wsPort = 5568,
  VUE_APP_SELINA_APP_NOTIF_HOST: wsHost = 'localhost',
  // VUE_APP_SELINA_DEV_PREFIX: devPrefix = false,
  // VUE_APP_SELINA_APP_NOTIF_PREFIX: notifPrefix = '*',
  // VUE_APP_SELINA_NOTIF_TYPE_PREFIX: typePrefix = 'LOG',
  VUE_APP_MODE,
} = process.env;

// Match regex operators
const sEsc = '[?.*+{}()[\\]\\\\]';
// Match rabbit wildcards (*,#) when surrounded by . or ^ or $
const sRepl = '(?<=^|\\.)[*#](?=$|\\.)';
// Make the regex
const rTrans = new RegExp(`(${sRepl})|(${sEsc})`, 'g');

// Transform a routing key in regex
const topicToStrRegexp = (str) => str.replace(rTrans, (_, repl, esc) => {
  // Escape regex operators
  if (esc) return `\\${esc}`;
  // Replace single fragment wildcard by its regex
  if (repl === '*') return '(?:[^.]*)';
  // Replace multi fragment wildcard by its regex
  return '(?:(?:[^.]*\\.)*[^.]*)';
});

// eslint-disable-next-line prefer-template
// const fullPrefix = (
//   devPrefix
//     ? [devPrefix, notifPrefix, typePrefix]
//     : [notifPrefix, typePrefix]
// ).join('.') + '\\.';
const fullPrefix = '';

const srPrefix = topicToStrRegexp(`^${fullPrefix}`);
const rPrefix = new RegExp(srPrefix);

const LF = '\x0A';
const strToRefresh = '#&?&!§';

/**
 * [WS description]
 */
class WS {
  static get connected() {
    return this.ws && this.ws.readyState === 1;
  }

  static get reconnectDelay() { return 5000; }

  static get inactivityDelay() {
    return document.visibilityState === 'visible'
      ? 5000
      : 5000 * 60;
  }

  static get prefix() { return fullPrefix; }

  /**
   * [connect description]
   * @method connect
   * @param  {Number} [delay=0]                   [description]
   * @param  {String} [xOidToken='<x_oid_token>'] [description]
   * @param  {String} [xOpsToken='<x_ops_token>'] [description]
   */
  static connect({
    delay = 0,
    // eslint-disable-next-line no-unused-vars
    authToken = '<auth_token>',
  }) {
    setTimeout(() => {
      const authQuery = VUE_APP_MODE === 'dev' ? 'testSession=thomas@manacorp.eu' : '';
      this.ws = new WebSocket(`${wsProtocol}://${wsHost}:${wsPort}/mq?${authQuery}`);

      this.ws.onerror = (e) => {
        Log.error(e);
        this.connect({ delay: this.reconnectDelay }); // retry conncect in 5sec
      };

      this.ws.onopen = () => {
        Log.info('Connected');
        if (WS.onconnect && typeof WS.onconnect === 'function') {
          WS.onconnect();
        }

        // Create a locally referenced handler
        // so it can be explicitely unbound from
        // the event listener
        const visibilityHandler = () => {
          this.pushVisibility();
          this.monitorServerActivity();
        };
        document.addEventListener('visibilitychange', visibilityHandler);
        this.pushVisibility();

        this.ws.onerror = (e) => {
          Log.error('Websocket Error', e);
        };

        this.ws.onmessage = (data) => this.messageHandler(data);

        this.ws.onclose = (e) => {
          Log.error('Websocket closed', e);

          clearTimeout(this.activityTimeout);
          clearTimeout(this.pulseTimeout);

          document.removeEventListener('visibilitychange', visibilityHandler);

          this.connect({ delay: this.reconnectDelay }); // retry conncect in 5sec
        };

        this.monitorServerActivity();
        this.pulse(this);

        const subs = {
          notifications: Object.keys(this.notifications),
          streams: Object.keys(this.streams),
          mute: this.mute,
        };

        this.pushSubs(subs);
      };
    }, delay);
  }

  static pushVisibility() {
    if (this.connected) {
      const state = document.visibilityState === 'visible'
        ? '@'
        : '#';
      this.ws.send(state);
    }
  }

  /**
   * [pushSubs description]
   * @method pushSubs
   * @param  {[type]} subs [description]
   */
  static pushSubs(subs) {
    if (this.connected && (subs.notifications.length || subs.streams.length || subs.mute.length)) {
      this.ws.send(JSON.stringify({ prefix: this.prefix, ...subs }));
      this.mute = [];
    }
  }

  /**
   * [subscribeStream description]
   * @method subscribeStream
   * @param  {[type]}        topic [description]
   */
  static subscribeStream(topic) {
    const arTopic = Array.isArray(topic) ? topic : [topic];
    this.streams = arTopic.reduce((acc, t) => {
      acc[t] = true;
      return acc;
    }, this.streams);
    this.pushSubs({ notifications: [], streams: arTopic, mute: [] });
  }

  /**
   * [subscribeNotification description]
   * @method subscribeNotification
   * @param  {[type]}              topic [description]
   */
  static subscribeNotification(topic) {
    const arTopic = Array.isArray(topic) ? topic : [topic];
    this.notifications = arTopic.reduce((acc, t) => {
      acc[t] = true;
      return acc;
    }, this.notifications);
    this.pushSubs({ notifications: arTopic, streams: [], mute: [] });
  }

  /**
   * [subscribeNotification description]
   * @method subscribeNotification
   * @param  {[type]}              topic [description]
   */
  static unSubscribeStream(topic) {
    const arTopic = Array.isArray(topic) ? topic : [topic];
    arTopic.forEach((t) => {
      delete this.streams[t];
      delete this.notifications[t];
    });

    this.mute.push(...arTopic);
    this.pushSubs({ notifications: [], streams: [], mute: arTopic });
  }

  /**
   * [messageHandler description]
   * @method messageHandler
   * @param  {[type]}       wsMsg [description]
   */
  static messageHandler(wsMsg) {
    this.monitorServerActivity();

    if (wsMsg.data === LF) return undefined;
    if (wsMsg.data === strToRefresh) return window.location.assign(window.location.href);

    const { content, topic } = JSON.parse(wsMsg.data);
    const msg = {
      topic: this.trimPrefix(topic),
      content,
    };

    this.handlers.forEach((hdl) => {
      if (hdl.rTopic.test(msg.topic)) hdl.handler(msg);
    });
    return undefined;
  }

  /**
   * [on description]
   * @method on
   * @param  {[type]} topics  [description]
   * @param  {[type]} handler [description]
   */
  static on(topics, handler) {
    const arTopic = Array.isArray(topics) ? topics : [topics];

    this.handlers.push(...arTopic.map((topic) => ({
      topic,
      rTopic: new RegExp(`${topicToStrRegexp(topic)}$`),
      handler,
    })));
  }

  /**
   * [off description]
   * @method off
   * @param  {[type]} topics  [description]
   * @param  {[type]} handler [description]
   */
  static off(topics, handler) {
    const arTopic = Array.isArray(topics) ? topics : [topics];

    const all = typeof handler !== 'undefined';

    this.handlers = arTopic.reduce(
      (hdls, topic) => hdls.map(
        (hdl) => (hdl.topic === topic) && (all || hdl.handler === handler),
      ),
      this.handlers,
    );
  }

  /**
   * [monitorServerActivity description]
   * @method monitorServerActivity
   */
  static monitorServerActivity() {
    clearTimeout(this.activityTimeout);
    this.activityTimeout = setTimeout(() => this.ws.close(), this.inactivityDelay);
  }

  /**
   * [pulse description]
   * @method pulse
   * @param  {[type]} self [description]
   */
  static pulse(self) {
    if (self.connected) {
      self.ws.send(LF);
      // eslint-disable-next-line no-param-reassign
      self.pulseTimeout = setTimeout(() => self.pulse(self), 1000);
    }
  }

  /**
   * [trimPrefix description]
   * @method trimPrefix
   * @param  {[type]}   topic [description]
   * @return {[type]}         [description]
   */
  static trimPrefix(topic) {
    return topic.replace(rPrefix, '');
  }
}

WS.notifications = {};

WS.streams = {};

WS.handlers = [];

WS.mute = [];

// eslint-disable-next-line node/no-unsupported-features/es-syntax
export default WS;
