<template>
  <section class="ubuSettingView start">
    <div class="multibar">
      <h1 class="start title is-1">Shop list</h1>
    </div>

    <br>

    <b-field>
      <b-input
        v-model="inputSearch"
        placeholder="Search a shop..."
        type="search"
        icon-pack="ubu"
        icon="ubu-search"
        icon-right="ubu-cross"
        icon-right-clickable
        @icon-right-click="inputSearch = null"
      />
    </b-field>

    <br>

    <DevDetails :cnt="{name: 'manageShopList', obj: manageShopList}" />
    <DevDetails :cnt="{name: 'manageShopMapper', obj: manageShopMapper}" />
    <TheTable
      v-if="Object.values(manageShopMapper).length"
      :lead-list="Object.values(manageShopMapper)"
      :columns="Object.values(columns)"
      :loading="loading"
      :total="Object.values(manageShopMapper).length"
      :page="page + 1"
      :per-page.sync="perPage"
      :sort-field="sortField"
      :sort-order="sortOrder"
      :default-sort-order="defaultSortOrder"
      :selected.sync="localSelected"
      :checked-rows.sync="checkedRows"
      :detail-key="'uid'"
      :checkable="false"
      :sticky-header="false"
      @onSort="onSort($event)"
      @update:checkedRows="changeCheckedRows($event)"
      @update:selected="localSelected = $event"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from '@dailyplanet/utils/helpers';
import manageShopColumns from '../$mixins/manageShopColumns.columns.mixins';

export default {
  name: 'TheShopAll',
  mixins: [manageShopColumns],
  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      page: 0,
      perPage: 50,
      sortField: 'ssid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      checkedRows: [],
      search: '',
      inputSearch: '',
    };
  },
  computed: {
    ...mapGetters({
      manageShopList: 'TheShop/manageShopList',
    }),
    localSelected: {
      get() { return this.selected; },
      set(newValue) { this.$emit('update:selected', newValue); },
    },
    manageShopMapper() {
      if (!this.manageShopList) return {};
      return this.manageShopList.arrayMapper('sid');
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 500),
  },
  mounted() {
    this.$emit('reset');
  },
};
</script>
