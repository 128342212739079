<template>
  <div
    id="UbuMentionsHashtagsMediaCard"
    :style="[isLoading || !mediaLoaded ? {
      height: isLoading ? randomHeight() : `${media.skeletonHeight}px`} : {height: 'auto'}]"
    @click="$emit('openMediaModal', media)"
  >
    <div
      v-if="isLoading || !mediaLoaded"
      class="mention-skeleton"
    >
      <b-skeleton
        :rounded="false"
        :active="true"
        height="1000"
      />
    </div>

    <div v-if="media">
      <div>
        <b-icon
          v-if="media.mediaType === 'VIDEO' && !isLoading && mediaLoaded"
          icon="play"
          class="playButton"
        />
      </div>

      <div class="media-details">
        <p class="username subtext mb-4">
          <component
            :is="`UbuIcon${media.platformName.capitalize()}`"
            class="mr-1"
          /> @{{ media.username }}
        </p>

        <p class="subtext">
          {{ media.date }}
        </p>
      </div>

      <b-image
        v-if="media.mediaType !== 'VIDEO'"
        class="media-thumbnail"
        :src="media.mediaUrl"
        lazy
        @load="mediaLoaded = true"
      />

      <video
        v-else
        :ref="media.mediaUrl"
        loop
        class="media-thumbnail"
        @loadeddata="mediaLoaded = true"
      >
        <source
          :src="media.mediaUrl"
          :type="'video/mp4'"
        >
        Sorry, your browser doesn't support embedded videos.
      </video>
      <!-- <div :class="['mediaSource', {'spinning': isVideoPlaying}]"> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuMentionsHashtagsMediaCard',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    media: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mediaLoaded: false,
      isVideoPlaying: false,
    };
  },
  computed: {
    skeletonHeight() {
      if (!this.media) return `${this.media.skeletonHeight}px` || null;
      return null;
    },
  },
  methods: {
    randomHeight() {
      const heights = ['220px', '380px'];
      return heights[Math.floor(Math.random() * heights.length)];
    },
    // playPause() {
    //   if (this.isVideoPlaying) this.$refs[this.media.mediaUrl].pause();
    //   else this.$refs[this.media.mediaUrl].play();
    //   this.isVideoPlaying = !this.isVideoPlaying;
    // },
  },
};
</script>
