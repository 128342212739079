/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    memberGroupList: {},
  },
  mutations: {
    SET_MEMBER_GROUP_LIST(state, newData) { state.memberGroupList = newData; },
  },
  getters: {
    getterMemberGroupList: (state) => state.memberGroupList,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.REPORT')) return undefined;

      return clark.api.apiTribe.memberGroup.report({ payload, httpQuery })
        .then((memberGroupList) => {
          commit('SET_MEMBER_GROUP_LIST', memberGroupList.arrayMapper('mgid'));
        });
    },

    create({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.CREATE')) return undefined;

      return clark.api.apiTribe.memberGroup.post({ payload, httpQuery })
        .then((memberGroup) => {
          commit('SET_MEMBER_GROUP_LIST', { ...getters.getterMemberGroupList, [memberGroup.mgid]: memberGroup });
        });
    },

    update({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.UPDATE')) return undefined;

      return clark.api.apiTribe.memberGroup.put({ payload, httpQuery })
        .then((memberGroup) => {
          commit('SET_MEMBER_GROUP_LIST', { ...getters.getterMemberGroupList, [memberGroup.mgid]: memberGroup });
        });
    },

    delete({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.DELETE')) return undefined;

      return clark.api.apiTribe.memberGroup.delete({ payload, httpQuery })
        .then(() => {
          commit('SET_MEMBER_GROUP_LIST', Object.values(getters.getterMemberGroupList).filter(({ mgid }) => mgid !== payload.mgid).arrayMapper('mgid'));
        });
    },
  },
};
