<template>
  <UbuDropdown
    position="is-bottom-right"
    :scrollable="true"
    @open="isOpen = true"
    @close="isOpen = false;"
  >
    <template v-slot:button>
      <b-button
        :label="hasContactPlatform ? selectedContactPlatform.username : 'Select an influencer'"
        icon-pack="ubu"
        :icon-left="hasContactPlatform ? 'ubu-account' : ''"
        type="is-secondary"
      />
    </template>

    <template
      v-if="isOpen"
      v-slot:search
    >
      <b-dropdown-item custom>
        <b-input
          v-model="search"
          :loading="isFetching"
          class="mb-2"
          type="search"
          pattern="^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$"
          validation-message="Only valid username are allowed"
          placeholder="Search any influencer from your CRM..."
          icon="ubu-search"
          icon-pack="ubu"
          icon-right="ubu-cross"
          icon-right-clickable
          @icon-right-click="search = ''"
          @input="isFetching = true"
        />
      </b-dropdown-item>
    </template>

    <template v-slot:content>
      <b-dropdown-item
        v-if="selectedContactPlatform"
        paddingless
        @click="select(null)"
      >
        <div class="multibar row-vcentered">
          <UbuContactPlatformCard
            class="start"
            :contact-platform="selectedContactPlatform"
          />

          <b-icon
            class="end"
            pack="ubu"
            icon="ubu-check"
          />
        </div>
      </b-dropdown-item>

      <b-dropdown-item
        v-for="cp in usernameSearchResult"
        :key="cp.cpid"
        paddingless
        @click="select(cp)"
      >
        <UbuContactPlatformCard :contact-platform="cp" />
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheSearchContactPlatformDropdown',
  model: {
    prop: 'selectedContactPlatform',
  },
  props: {
    selectedContactPlatform: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      search: '',
      usernameSearchResult: [],
      isFetching: false,
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      _searchContactPlatformUsernameList: 'TheSearchContactPlatformDropdown/searchContactPlatformUsernameList',
    }),

    hasContactPlatform() {
      return !!this.selectedContactPlatform;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    search: debounce(function (newVal) {
      this.usernameSearchResult = [];
      if (!newVal || !newVal.length || newVal.length < 3) {
        this.isFetching = false;
        return;
      }
      if (!newVal.match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/)) {
        this.isFetching = false;
        return;
      }
      this.isFetching = true;
      this.reportSearchContactPlatformUsername({ payload: { username: newVal } })
        .then(() => {
          this.usernameSearchResult = this.selectedContactPlatform
            // eslint-disable-next-line max-len
            ? this._searchContactPlatformUsernameList.filter(({ cpid }) => cpid !== this.selectedContactPlatform.cpid)
            : this._searchContactPlatformUsernameList;
          this.isFetching = false;
        });
    }, 1000),
  },
  methods: {
    ...mapActions({
      reportSearchContactPlatformUsername: 'TheSearchContactPlatformDropdown/reportSearchContactPlatformUsername',
    }),

    select(cp) {
      this.$emit('input', cp);
      this.search = '';
    },
  },
};
</script>
