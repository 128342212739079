<template>
  <div
    id="TheAdministration"
    class="card"
  >
    <header class="card-header">
      <p class="card-header-title">
        Administration
      </p>
      <div class="card-header-icon buttons">
        <b-button
          tag="router-link"
          to="/sales/ubuLead/administration/scrapingZone"
          type="is-link"
          label="Scraping Zone"
          :outlined="!isRouteActive('/sales/ubuLead/administration/scrapingZone')"
        />
        <!-- <b-button
          tag="router-link"
          to="/sales/ubuLead/leadsGeneration/suggestions"
          type="is-link"
          label="Suggestions"
          :outlined="!isRouteActive('/sales/ubuLead/leadsGeneration/suggestions')"
        /> -->
      </div>
    </header>

    <div class="card-content">
      <router-view />
    </div>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheAdministration',
  // data() {
  //   return {
  //     newScrapingZone: {
  //       szid: null,
  //       label: null,
  //     },
  //   };
  // },
  // computed: {
  //   ...mapGetters({
  //     scrapingZoneList: 'TheSuggestions/scrapingZoneList',
  //   }),
  // },
  // mounted() {
  //   return this.reportScrapingZone();
  // },
  methods: {
    // ...mapActions({
    //   reportScrapingZone: 'TheAdministration/reportScrapingZone',
    // }),
    isRouteActive(route) {
      return route === this.$route.path;
    },
  },
};
</script>
