/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
// import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    privateNoteList: null,
  },
  mutations: {
    SET_PRIVATE_NOTE_LIST(state, newData) { state.privateNoteList = { ...state.privateNoteList, ...newData }; },
    RESET_STORE(state) {
      state.privateNoteList = null;
    },
  },
  getters: {
    getterPrivateNoteList: (state) => state.privateNoteList,
  },
  actions: {
    create({ commit, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Channel/thread/message/getterMessageList': messageList } = rootGetters;

      return clark.api.channelGroup.privateNote.post({ payload, httpQuery })
        .then((privateNote) => {
          const noteMapped = {
            [`${privateNote.pnid}#PRIVATE_NOTE`]: {
              ...privateNote,
              customId: `${privateNote.pnid}#PRIVATE_NOTE`,
              noteDate: privateNote.createdOn,
              itemDate: privateNote.createdOn, // used to sort from commonViewStore (thread) getter messageList
              originalId: privateNote.pnid,
              type: 'PRIVATE_NOTE',
            },
          };

          const updatedMessageList = {
            ...messageList,
            ...noteMapped,
          };

          commit('ChannelGroup/contact/contactNote/SET_NOTELIST', noteMapped, { root: true });
          commit('Channel/thread/message/SET_MESSAGE_LIST', updatedMessageList, { root: true });
          return privateNote;
        });
    },

    update({ commit, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Channel/thread/message/getterMessageList': messageList } = rootGetters;

      return clark.api.channelGroup.privateNote.put({ payload, httpQuery })
        .then((privateNote) => {
          const noteMapped = {
            [`${privateNote.pnid}#PRIVATE_NOTE`]: {
              ...privateNote,
              customId: `${privateNote.pnid}#PRIVATE_NOTE`,
              noteDate: privateNote.createdOn,
              itemDate: privateNote.createdOn, // used to sort from commonViewStore (thread) getter messageList
              originalId: privateNote.pnid,
              type: 'PRIVATE_NOTE',
            },
          };

          const updatedMessageList = {
            ...messageList,
            ...noteMapped,
          };

          commit('ChannelGroup/contact/contactNote/SET_NOTELIST', noteMapped, { root: true });
          commit('Channel/thread/message/SET_MESSAGE_LIST', updatedMessageList, { root: true });
          return privateNote;
        });
    },

    delete({ commit, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Channel/thread/message/getterMessageList': messageList } = rootGetters;

      return clark.api.channelGroup.privateNote.delete({ payload, httpQuery })
        .then(() => {
          const currentCustomId = `${payload.pnid}#PRIVATE_NOTE`;

          // remove deleted note from messageList
          const removedNote = Object.values(messageList).reduce((acc, row) => {
            if (row.customId !== currentCustomId) acc[row.customId] = row;
            return acc;
          },
          {});

          commit('ChannelGroup/contact/contactNote/UNSET_NOTE_LIST', currentCustomId, { root: true });
          commit('Channel/thread/message/SET_MESSAGE_LIST', removedNote, { root: true });

          return currentCustomId;
        });
    },
  },
};
