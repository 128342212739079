<template>
  <UbuDropdown
    id="TheActivityDropdown"
    :close-on-click="true"
    :expanded="expanded"
    :scrollable="true"
    :position="position"
    :is-disabled="isDisabled"
    @open="isOpen = true"
    @close="reset(); isOpen = false;"
  >
    <template #button>
      <template v-if="customButton && currentStatus">
        <button
          v-if="!cantMoveFrom.includes(currentStatus) && !_cpo.lastActivity.endedOn"
          id="statusButton"
          :class="{ responsive: responsive }"
        >
          <UbuIconCircleStatus :is-active="!_cpo.lastActivity.endedOn" />

          <b-icon
            class="iconStatus"
            pack="ubu"
            :icon="statusListArrayMapped[currentStatus].icon"
          />

          <span class="textStatus">
            {{ currentStatus.split('_').join(' ').capitalize() }}
          </span>
        </button>

        <b-tooltip
          v-else
          type="is-info"
          :position="tooltipPosition"
          class=""
        >
          <template #content>
            <div class="multibar row-vcentered">
              <span>Cannot be changed manually</span>
            </div>
          </template>
          <button
            id="statusButton"
            :class="{ responsive: responsive }"
            disabled
          >
            <UbuIconCircleStatus :is-active="!_cpo.lastActivity.endedOn" />

            <b-icon
              class="iconStatus"
              pack="ubu"
              :icon="statusListArrayMapped[currentStatus].icon"
            />

            <span class="textStatus">
              {{ currentStatus.split('_').join(' ').capitalize() }}
            </span>
          </button>
        </b-tooltip>
      </template>

      <b-button
        v-else
        label="Change status"
        type="is-primary"
        expanded
        icon-pack="ubu"
        icon-right="ubu-arrow-down"
      />
    </template>

    <template
      v-if="isOpen"
      #search
    >
      <b-dropdown-item custom>
        <UbuSearchbar
          v-model="search"
          @clear="reset()"
        />
      </b-dropdown-item>
    </template>

    <template #content>
      <b-dropdown-item
        v-for="status in statusListFiltered"
        :key="status.label"
        :class="[{ 'is-active': status.status === _cpo.lastActivity.status }]"
        @click="changeStatus(status)"
      >
        <UbuDropdownStatusRow :status="status" />
      </b-dropdown-item>

      <b-dropdown-item
        v-if="!statusListFiltered.length"
        custom
      >
        No item with this name in this workspace...
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheActivityDropdown',
  mixins: [FilterEngineMixin],
  props: {
    customButton: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'is-bottom-left',
    },
    tooltipPosition: {
      type: String,
      default: 'is-bottom',
    },
  },
  data() {
    return {
      search: '',
      cantMoveTo: ['SCHEDULED', 'UNSCHEDULED', 'DELIVERED', 'CANCELED'],
      cantMoveFrom: ['SCHEDULED', 'UNSCHEDULED', 'DELIVERED', 'CANCELED'],
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      _cpo: 'TheContactPlatformPanel/contactPlatformOverview',
      activityStatusList: 'TheInbox/activityStatusList',
    }),

    currentStatus() {
      return this._cpo.lastActivity.status;
    },

    statusListArrayMapped() {
      return this.activityStatusList.arrayMapper('status');
    },

    _filterCollection() {
      return {
        isStatusUsable(...rawStatusList) {
          return (m) => (!rawStatusList.includes(m.status) ? m : undefined);
        },
      };
    },

    isDisabled() {
      return this.cantMoveFrom.includes(this.currentStatus) || !!this._cpo.lastActivity.endedOn;
    },

    statusListFiltered() {
      if (!this.activityStatusList.length) return [];

      const filtersToApply = {
        isStatusUsable: this.cantMoveTo,
      };

      if (this.search) filtersToApply.searchByLabelName = this.search;

      return this.filterEngine(this.activityStatusList, filtersToApply);
    },
  },
  methods: {
    ...mapActions({
      _bulkChangeStatus: 'TheActivityDropdown/bulkChangeStatus',
    }),

    reset() {
      this.search = '';
    },

    changeStatus(status) {
      if (status.status === this._cpo.lastActivity.status) return undefined;

      return this._bulkChangeStatus({
        payload: {
          aids: [this._cpo.lastActivity.aid],
          status: status.status,
          cid: this._cpo.lastActivity.campaignCid,
        },
        mutations: [
          {
            target: 'ApiContact/contactPlatformOverview/UP_updateActivityState',
            payload: {
              value: status.status,
              cpids: [this._cpo.cpid],
            },
          },
        ],
      });
    },
  },
};
</script>
