<template>
  <div class="flex">
    <div class="overlay" />
    <div class="ubuPanel ubuPanel-xl">
      <UbuAnimatedLogo />

      <br>
      <br>

      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheError',
};
</script>
