/* eslint-disable no-multi-spaces */
/* eslint-disable max-len */
import TheInbox from '@dailyplanet/selina-addons/inbox/TheInbox';
import TheInboxSidebar from '@dailyplanet/selina-addons/inbox/TheInboxSidebar';
import TheInboxMainPanel from '@dailyplanet/selina-addons/inbox/TheInboxMainPanel';

import TheCampaign from '@dailyplanet/selina-addons/campaign/TheCampaign';
import TheCampaigns from '@dailyplanet/selina-addons/campaign/TheCampaigns';

import TheCampaignPeople from '@dailyplanet/selina-addons/campaign/views/TheCampaignPeople';
import TheCampaignPeopleDetailed from '@dailyplanet/selina-addons/campaign/views/TheCampaignPeopleDetailed';

import TheCampaignMention from '@dailyplanet/selina-addons/campaign/views/TheCampaignMention';

import TheCampaignAnalytic from '@dailyplanet/selina-addons/campaign/views/TheCampaignAnalytic';
import TheCampaignAnalyticActivities from '@dailyplanet/selina-addons/campaign/views/TheCampaignAnalyticActivities';
import TheCampaignAnalyticMentions from '@dailyplanet/selina-addons/campaign/views/TheCampaignAnalyticMentions';
import TheCampaignAnalyticSales from '@dailyplanet/selina-addons/campaign/views/TheCampaignAnalyticSales';

export default [
  // {
  //   path: '/campaign/:campaignCid',
  //   redirect: '/campaign/:campaignCid/people/detailed',
  // },
  {
    path: '/campaign/:campaignCid/people',
    redirect: '/campaign/:campaignCid/people/detailed',
  },
  {
    path: '/campaign/:campaignCid/mentions/detailed',
    redirect: '/campaign/:campaignCid/mentions/detailed',
  },
  {
    path: '/campaign/:campaignCid/analytics',
    redirect: '/campaign/:campaignCid/analytics/activities',
  },
  {
    path: '/campaign',
    name: 'Campaigns',
    component: TheCampaigns,
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheInfluence',
    },
  },
  {
    path: '/campaign/:campaignCid',
    name: 'Campaign',
    redirect: '/campaign/:campaignCid/people/detailed',
    component: TheCampaign,
    meta: {
      requireAuth: true,
      hasMenu: true,
      viewStoreNavigation: 'TheInfluence',
    },
    children: [
      {
        path: '/campaign/:campaignCid/people',
        name: 'Campaign - People',
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheInfluence',
        },
        component: TheCampaignPeople,
        children: [
          {
            path: '/campaign/:campaignCid/people/detailed',
            name: 'Campaign - People - Detailed View',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheInfluence',
              viewType: 'detailed',
            },
            component: TheCampaignPeopleDetailed,
          },
        ],
      },
      {
        path: '/campaign/:campaignCid/messages',
        name: 'Campaign - Messages',
        component: TheInbox,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheInfluence',
        },
        children: [
          {
            path: '/campaign/:campaignCid/messages/campaign/:threadByCampaigns',
            name: 'Campaign - Messages - Common',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheInfluence',
            },
            components: {
              inboxSidebar: TheInboxSidebar,
            },
          },
          {
            path: '/campaign/:campaignCid/messages/campaign/:threadByCampaigns/channel/:channelCid/thread/:tid',
            name: 'Campaign - Common - Thread',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheInfluence',
            },
            components: {
              inboxSidebar: TheInboxSidebar,
              inboxMainPanel: TheInboxMainPanel,
            },
          },
        ],
      },
      {
        path: '/campaign/:campaignCid/mentions',
        name: 'Campaign - Mentions',
        component: TheCampaignMention,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheInfluence',
        },
      },
      {
        path: '/campaign/:campaignCid/analytics',
        name: 'Influence - Analytics',
        component: TheCampaignAnalytic,
        meta: {
          requireAuth: true,
          hasMenu: true,
          viewStoreNavigation: 'TheInfluence',
        },
        children: [
          {
            path: '/campaign/:campaignCid/analytics/activities',
            name: 'Campaign - Analytics - Activities',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheInfluence',
              viewType: 'detailed',
            },
            component: TheCampaignAnalyticActivities,
          },
          {
            path: '/campaign/:campaignCid/analytics/mentions',
            name: 'Campaign - Analytics - Mentions',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheInfluence',
              viewType: 'detailed',
            },
            component: TheCampaignAnalyticMentions,
          },
          {
            path: '/campaign/:campaignCid/analytics/sales',
            name: 'Campaign - Analytics - Sales',
            meta: {
              requireAuth: true,
              hasMenu: true,
              viewStoreNavigation: 'TheInfluence',
              viewType: 'detailed',
            },
            component: TheCampaignAnalyticSales,
          },
        ],
      },
    ],
  },
];
