import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    currentBrand: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiCrew/brand/getterCurrentBrand': currentBrand } = rootGetters;

      return currentBrand;
    },
  },
  actions: {
    getBrand({ dispatch, rootGetters }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiCrew/brand/get',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid } },
        textNotifier: 'Get brand list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to get brand.', indefinite: true }));
    },

    createBrand({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiCrew/brand/create',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'create brand list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to create brand.', indefinite: true }));
    },

    updateBrand({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiCrew/brand/update',
        context: { payload: { channelGroupCgid: currentChannelGroup.cgid, ...payload } },
        textNotifier: 'update brand list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to update brand.', indefinite: true }));
    },
  },
};
