import { use, registerComponent } from '@dailyplanet/utils/plugins';

/* eslint-disable no-multi-spaces */

import UbuMenuItem from './UbuMenuItem';
import UbuMenuItemList from './UbuMenuItemList';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuMenuItem);
    registerComponent(Vue, UbuMenuItemList);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuMenuItem,
  UbuMenuItemList,
};
