import affiliationCode from './affiliationCode';
import affiliationCodeFolder from './affiliationCodeFolder';
import affiliationReward from './affiliationReward';

import discountCode from './discountCode';
import manageShop from './manageShop';
import order from './order';
import shop from './shop';

export default {
  affiliationCode,
  affiliationCodeFolder,
  affiliationReward,

  discountCode,
  manageShop,
  order,
  shop,
};
