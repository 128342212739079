import { dispatchAndForward, genericErrorHandler, genericThenHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    affiliationCodeList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/affiliationCode/getterAffiliationCodeList': affiliationCodeList } = rootGetters;

      return affiliationCodeList;
    },

    affiliationCodeOverviewList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/affiliationCode/getterAffiliationCodeOverviewList': affiliationCodeOverviewList } = rootGetters;

      return affiliationCodeOverviewList;
    },

    totalAffiliationCodeOverview: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/affiliationCode/getterTotalAffiliationCodeOverview': total } = rootGetters;

      return total;
    },

    discountCodeOverviewList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/affiliationCode/getterDiscountCodeOverviewList': discountCodeOverviewList } = rootGetters;

      return discountCodeOverviewList;
    },
  },
  actions: {
    reportAffiliationCodeOverviewList({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationCode/report',
        context: { payload: { ...payload, channelGroupCgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Report affiliationCodeOverview list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report affiliation code overview list.', indefinite: true }));
    },

    reportDiscountCodeOverviewList({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationCode/reportDiscountCodeOverview',
        context: { payload: { ...payload, channelGroupCgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Report affiliationCodeOverview list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to report affiliation code overview list.', indefinite: true }));
    },

    createAffiliationCodeList({ dispatch, rootGetters }, { payload, httpQuery = {}, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationCode/create',
        context: { payload: { ...payload, channelGroupCgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Create affiliationCode list',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to create affiliation code.', indefinite: true }));
    },

    updateAffiliationCodeList({ dispatch, rootGetters }, { payload, httpQuery = {}, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationCode/update',
        context: { payload: { ...payload, channelGroupCgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'Update affiliationCode list',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .catch(genericErrorHandler(action, { message: 'Unable to update affiliation code list.', indefinite: true }));
    },

    patchAffiliationCode({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiAffiliation/affiliationCode/patch',
        context: { payload: { ...payload, channelGroupCgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'patch affiliationCode list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to patch affiliation code.', indefinite: true }));
    },

    UP_resetDiscountCodeOverviewList({ dispatch }) {
      return dispatch('ApiAffiliation/affiliationCode/UP_resetDiscountCodeOverviewList', null, { root: true });
    },
  },
};
