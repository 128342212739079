/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    mediaZipList: [],
  },
  mutations: {
    SET_MEDIA_ZIP_LIST(state, newData) { state.mediaZipList = newData; },
  },
  getters: {
    getterMediaZipList: (state) => state.mediaZipList,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CHANNEL.REPORT')) return undefined;

      return clark.api.apiChannel.dailyMention.report({ payload, httpQuery })
        .then((zipList) => {
          commit('SET_MEDIA_ZIP_LIST', zipList);

          return zipList;
        });
    },

    downloadZip({ rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CHANNEL.GET')) return undefined;

      return clark.api.apiChannel.dailyMention.downloadZip({ payload, httpQuery })
        .then((response) => response);
    },

    setDailyMention({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'CHANNEL.UPDATE')) return undefined;

      return clark.api.apiChannel.dailyMention.post({ payload, httpQuery: { contactPlatform: true } })
        .then((channel) => {
          const { 'Authenticate/getterAuthenticate': myAuthenticate } = rootGetters;

          const updated = {
            ...myAuthenticate,
            channelList: [
              // on remove le channel qu'on veut update pour le remplacer par le new (response de la requete)
              ...myAuthenticate.channelList.filter(({ cpid }) => cpid !== payload.cpid),
              channel,
            ],
          };

          commit('Authenticate/SET_AUTHENTICATE', updated, { root: true });

          return channel;
        });
    },

    unsetDailyMention({ commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'CHANNEL.UPDATE')) return undefined;

      return clark.api.apiChannel.dailyMention.delete({ payload, httpQuery: { contactPlatform: true } })
        .then((channel) => {
          const { 'Authenticate/getterAuthenticate': myAuthenticate } = rootGetters;

          const updated = {
            ...myAuthenticate,
            channelList: [
              // on remove le channel qu'on veut update pour le remplacer par le new (response de la requete)
              ...myAuthenticate.channelList.filter(({ cpid }) => cpid !== payload.cpid),
              channel,
            ],
          };

          commit('Authenticate/SET_AUTHENTICATE', updated, { root: true });

          return channel;
        });
    },
  },
};
