import message from './thread.message.store';

export default {
  namespaced: true,
  state: {
    threadDetails: null,
  },
  mutations: {
    SET_THREAD_DETAILS(state, newData) { state.threadDetails = newData; },
    SET_THREAD_DETAILS_THREAD(state, newData) { state.threadDetails.thread = newData; },
    RESET_STORE(state) {
      state.threadDetails = null;
    },
  },
  getters: {
    getterThreadDetails: (state) => state.threadDetails,
  },
  actions: {
  },
  modules: {
    message,
  },
};
