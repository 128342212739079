import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheContactPlatformPanel from './TheContactPlatformPanel';
import TheContactPlatformPanelOverviewTab from './TheContactPlatformPanelOverviewTab';
import TheContactPlatformPanelInfluenceTab from './TheContactPlatformPanelInfluenceTab';
import TheContactPlatformPanelHistoryTab from './TheContactPlatformPanelHistoryTab';
import TheContactPlatformPanelAffiliationTab from './TheContactPlatformPanelAffiliationTab';
import TheContactPlatformPanelMentionTab from './TheContactPlatformPanelMentionTab';
import TheContactPlatformPanelPrivateMetadata from './TheContactPlatformPanelPrivateMetadata';
// import TheTribePeopleColumnRowDropdrown from './TheTribePeopleColumnRowDropdrown';
// import TheTribePeopleAddPeoplePanel from './TheTribePeopleAddPeoplePanel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheContactPlatformPanel);
    registerComponent(Vue, TheContactPlatformPanelOverviewTab);
    registerComponent(Vue, TheContactPlatformPanelInfluenceTab);
    registerComponent(Vue, TheContactPlatformPanelHistoryTab);
    registerComponent(Vue, TheContactPlatformPanelAffiliationTab);
    registerComponent(Vue, TheContactPlatformPanelMentionTab);
    registerComponent(Vue, TheContactPlatformPanelPrivateMetadata);
    // registerComponent(Vue, TheTribePeopleColumnRowDropdrown);
    // registerComponent(Vue, TheTribePeopleAddPeoplePanel);
  },
};

use(Plugin);

export default Plugin;

export {
  TheContactPlatformPanel,
  TheContactPlatformPanelOverviewTab,
  TheContactPlatformPanelInfluenceTab,
  TheContactPlatformPanelHistoryTab,
  TheContactPlatformPanelAffiliationTab,
  TheContactPlatformPanelMentionTab,
  TheContactPlatformPanelPrivateMetadata,
  // TheTribePeopleColumnRowDropdrown,
  // TheTribePeopleAddPeoplePanel,
};
