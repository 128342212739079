/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import protoList from './protoList';
import campaign from './campaign';
import activity from './activity';
import bulkActivity from './bulkActivity';
import campaignAnalytics from './campaignAnalytics';
import threadCounters from './threadCounters';
import activityStatus from './activityStatus';

export default {
  protoList,
  campaign,
  activity,
  bulkActivity,
  campaignAnalytics,
  threadCounters,
  activityStatus,
};
