export default {
  data() {
    return {
      columns: {
        date: {
          col: 'createdOn', label: 'Date', visible: true, isSortable: false, cmp: 'date',
        },
        status: {
          col: 'status', withState: true, label: 'Status', visible: true, isSortable: false, cmp: 'status',
        },
        firstName: {
          col: 'firstName', label: 'First name', visible: true, isSortable: false, cmp: 'flat',
        },
        lastName: {
          col: 'lastName', label: 'Last name', visible: true, isSortable: false, cmp: 'flat',
        },
        'ig.username': {
          col: 'ig.username', base: 'INSTAGRAM', nestedKey1: 'username', label: 'Username', visible: true, isSortable: false, cmp: 'nested',
        },
        'tk.username': {
          col: 'tk.username', base: 'TIKTOK', nestedKey1: 'username', label: 'Username', visible: true, isSortable: false, cmp: 'nested',
        },
        'yt.username': {
          col: 'yt.username', base: 'YOUTUBE', nestedKey1: 'username', label: 'Username', visible: true, isSortable: false, cmp: 'nested',
        },
        optIn: {
          col: 'optIn', withState: false, label: 'Opt-in', visible: true, isSortable: false, cmp: 'status', centered: true,
        },
      },
    };
  },
};
