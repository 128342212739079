/* eslint-disable quotes */
/* eslint-disable object-curly-newline */

import builder from '../../engine';

const target = 'clark';

const accountState = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/accountState/`, method: 'REPORT', payload, httpQuery, target }),
};

export default accountState;
