<template>
  <TheSimplePanel
    closing-way="emit"
    @close="$emit('close')"
  >
    <template #header>
      <h1 class="title is-2">Edit template</h1>
    </template>

    <template #content>
      <form @submit.prevent="updateTemplate()">
        <b-field grouped>
          <b-field
            class="start w-100 mr-2"
            label="Name"
            expanded
          >
            <b-input
              ref="input"
              v-model="localModel.title"
              placeholder="My template ..."
              @input="localModel = { ...localModel, title: $event }"
            />
          </b-field>
          <b-field
            class="w-100"
            label="Folder"
            expanded
          >
            <UbuDropdown
              :close-on-click="true"
              :expanded="expanded"
              :scrollable="true"
              @open="isOpen = true"
              @close="reset(); isOpen = false;"
            >
              <template v-slot:button>
                <b-button
                  type="is-secondary"
                  expanded
                >
                  <div class="multibar">
                    <span class="start">{{ selectFolderButtonLabel }}</span>

                    <b-icon
                      pack="ubu"
                      icon="ubu-arrow-down"
                      class="end"
                    />
                  </div>
                </b-button>
              </template>

              <template
                v-if="isOpen"
                #search
              >
                <b-dropdown-item custom>
                  <UbuSearchbar
                    v-model="search"
                    @clear="reset()"
                  />
                </b-dropdown-item>
              </template>

              <template #content>
                <b-dropdown-item
                  v-for="templateFolder in customList"
                  :key="templateFolder.label"
                  @click="selectFolder(templateFolder.tfid)"
                >
                  <div class="multibar row-vcentered">
                    <span class="start">{{ templateFolder.label }}</span>

                    <b-icon
                      v-if="localModel.templateFolderTfid === templateFolder.tfid"
                      class="end"
                      pack="ubu"
                      icon="ubu-check"
                    />
                  </div>
                </b-dropdown-item>
              </template>
            </UbuDropdown>
          </b-field>
        </b-field>
        <b-field>
          <UbuSimpleTextarea
            v-model="localModel.text"
            :max-length="1000"
            placeholder="Type some text..."
            @input="localModel = { ...localModel, text: $event }"
          />
        </b-field>

        <div class="buttons">
          <b-button
            type="is-primary"
            native-type="submit"
            label="Update"
            :disabled="!canSave"
          >
            Update
          </b-button>
          <b-button
            type="is-secondary"
            value="Delete"
            @click="deleteTemplate()"
          >
            Delete
          </b-button>
        </div>
      </form>
    </template>
  </TheSimplePanel>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheSettingEditTemplate',
  mixins: [FilterEngineMixin],
  model: {
    prop: 'editableTemplate',
  },
  props: {
    editableTemplate: {
      type: Object,
      required: true,
    },
    closingWay: {
      type: String,
      default: 'route',
    },
  },
  data() {
    return {
      template: {},
      expanded: true,
      isOpen: false,
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      templateFolderList: 'TheTemplateManager/templateFolderList',
      templateList: 'TheTemplateManager/templateList',
    }),

    localModel: {
      get() { return { ...this.editableTemplate, ...this.template }; },
      set(newVal) { this.template = newVal; },
    },
    canSave() {
      const { text, title } = this.localModel;
      if ((text && text.length) && (title && title.length)) return true;
      return false;
    },

    _filterCollection() {
      return {
        /**
       * @method  search Count
       * @params  {Number}   rawMax  maximum to be compared to
       * @returns {Function}
       * */
        searchByLabel(rawSearch) {
          const search = rawSearch.toLowerCase();

          const _searchByLabel = (raw) => {
            const text = raw.toLowerCase();
            return text.includes(search);
          };
          return (m) => (_searchByLabel(m.label) ? m : undefined);
        },
      };
    },
    customList() {
      const templateFolderList = Object.values(this.templateFolderList);

      if (!templateFolderList || !templateFolderList.length) return [];

      const filtersToApply = {};
      if (this.search) filtersToApply.searchByLabel = this.search;

      return this.filterEngine(templateFolderList, filtersToApply);
    },

    selectFolderButtonLabel() {
      return this.template.templateFolderTfid
        ? this.templateFolderList[this.template.templateFolderTfid].label
        : 'Select';
    },
  },
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    ...mapActions({
      _updateTemplate: 'TheTemplateManager/updateTemplate',
      _deleteTemplate: 'TheTemplateManager/deleteTemplate',
    }),
    reset() {
      this.search = '';
    },
    updateTemplate() {
      this._updateTemplate({ payload: this.localModel })
        .then(() => { this.$emit('close'); });
    },
    deleteTemplate() {
      this._deleteTemplate({ payload: { tid: this.localModel.tid } })
        .then(() => { this.$emit('close'); });
    },

    selectFolder(tfid) {
      if (this.localModel.templateFolderTfid === tfid) {
        this.localModel = { ...this.localModel, templateFolderTfid: null };

        return;
      }
      this.localModel = { ...this.localModel, templateFolderTfid: tfid };
    },
  },
};
</script>
