var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TheTabsPanel',{on:{"goToPreviousTab":function($event){_vm.activeTab = _vm.tabItems['root']},"close":function($event){return _vm.closePanel()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('b-tab-item',{attrs:{"value":"root"}},[_c('article',[_c('div',{staticClass:"buttons"},[_c('UbuActionCard',{attrs:{"label":"Tribe information","icon-pack":"ubu","icon-left":"ubu-settings","expanded":"","left-text":"click to continue"},on:{"click":function($event){_vm.activeTab = _vm.tabItems['root.tribeInfos']}}}),_c('UbuActionCard',{attrs:{"label":"Add new group","icon-pack":"ubu","icon-left":"ubu-add","expanded":"","left-text":"click to continue"},on:{"click":function($event){_vm.activeTab = _vm.tabItems['root.addGroup']}}}),_c('UbuActionCard',{attrs:{"label":"Edit groups position","icon-pack":"ubu","icon-left":"ubu-adjust","expanded":"","left-text":"click to continue"},on:{"click":function($event){_vm.activeTab = _vm.tabItems['root.editPosition']}}})],1),_c('fieldset',{staticClass:"fieldset"},[_c('legend',{staticClass:"text"},[_vm._v("Usable groups in this tribe")]),_c('br'),_vm._l((_vm.memberGroupList),function(item){return [_c('UbuActionCard',{key:item.mgid,class:`ubuTribePeopleColumnBtn mb-4
              has-text-color-${_vm.memberGroupList[item.mgid].color}`,attrs:{"label":_vm.memberGroupList[item.mgid].name,"icon-pack":"ubu","icon-left":_vm.memberGroupList[item.mgid].icon,"expanded":"","disabled":false,"left-text":"click to edit"},on:{"click":function($event){_vm.editableMemberGroup = {..._vm.memberGroupList[item.mgid]};
                      _vm.activeTab = _vm.tabItems['root.editGroup']}}})]})],2)])]),_c('b-tab-item',{attrs:{"value":"root.tribeInfos"}},[_c('UbuTribeForm',{on:{"triggerForm":function($event){return _vm.updateTribe()},"delete":function($event){return _vm.deleteTribe()}},model:{value:(_vm.editableTribe),callback:function ($$v) {_vm.editableTribe=$$v},expression:"editableTribe"}})],1),_c('b-tab-item',{attrs:{"value":"root.addGroup"}},[_c('UbuMemberGroupForm',{on:{"triggerForm":function($event){return _vm.createMemberGroup()}},model:{value:(_vm.newMemberGroup),callback:function ($$v) {_vm.newMemberGroup=$$v},expression:"newMemberGroup"}})],1),_c('b-tab-item',{attrs:{"value":"root.editPosition"}},[(_vm.activeTab.tab === 'root.editPosition')?_c('div',[_c('div',{staticClass:"buttons is-right"},[_c('b-button',{attrs:{"type":"is-primary","label":"Save changes"},on:{"click":function($event){return _vm.updateTribeWithPosition()}}})],1),_c('br'),_c('fieldset',{staticClass:"fieldset"},[_c('legend',{staticClass:"text"},[_vm._v("Groups displayed in gallery")]),_c('br'),_c('draggable',{staticClass:"buttons",attrs:{"id":"TheTribePeopleColumns","draggable":".ubuTribePeopleColumnBtn"},model:{value:(_vm.memberGroupColumns),callback:function ($$v) {_vm.memberGroupColumns=$$v},expression:"memberGroupColumns"}},[_vm._l((_vm.memberGroupColumns),function(item){return [_c('UbuActionCard',{key:item.memberGroupMgid,class:`ubuTribePeopleColumnBtn mb-4
              has-text-color-${_vm.memberGroupList[item.memberGroupMgid].color}`,attrs:{"label":_vm.memberGroupList[item.memberGroupMgid].name,"icon-pack":"ubu","icon-left":_vm.memberGroupList[item.memberGroupMgid].icon,"expanded":"","disabled":false,"left-text":"click to remove or drag to change position"},on:{"click":function($event){_vm.memberGroupColumns = _vm.memberGroupColumns
                  .filter(({ memberGroupMgid }) => memberGroupMgid !== item.memberGroupMgid)}}})]})],2)],1),_c('br'),_c('fieldset',{staticClass:"fieldset"},[_c('legend',{staticClass:"text"},[_vm._v("Usable groups in this tribe")]),_c('br'),_vm._l((_vm.memberGroupListNotPositionned),function(item){return [_c('UbuActionCard',{key:item.mgid,class:`ubuTribePeopleColumnBtn mb-4
              has-text-color-${_vm.memberGroupList[item.mgid].color}`,attrs:{"label":_vm.memberGroupList[item.mgid].name,"icon-pack":"ubu","icon-left":_vm.memberGroupList[item.mgid].icon,"expanded":"","disabled":false,"left-text":"click to add to columns"},on:{"click":function($event){_vm.memberGroupColumns = [..._vm.memberGroupColumns,
                                            { tribeTid: _vm.tribe.tid, memberGroupMgid: item.mgid }]}}})]})],2)]):_vm._e()]),_c('b-tab-item',{attrs:{"value":"root.editGroup"}},[(_vm.editableMemberGroup)?_c('UbuMemberGroupForm',{on:{"triggerForm":function($event){return _vm.updateMemberGroup()},"delete":function($event){return _vm.deleteMemberGroup()}},model:{value:(_vm.editableMemberGroup),callback:function ($$v) {_vm.editableMemberGroup=$$v},expression:"editableMemberGroup"}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}})
}
var staticRenderFns = []

export { render, staticRenderFns }