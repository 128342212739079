/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const manageShop = {
  report: ({ payload, httpQuery }) => builder({ url: '/api/manageShop', method: 'REPORT', payload, httpQuery, target }),
  get: ({ payload, httpQuery }) => builder({ url: `/api/manageShop/${payload.sid}`, method: 'GET', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: '/api/manageShop', method: 'POST', payload, httpQuery, target }),
  put: ({ payload, httpQuery }) => builder({ url: `/api/manageShop/${payload.sid}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/manageShop/${payload.sid}`, method: 'DELETE', payload, httpQuery, target }),
  ping: {
    get: ({ payload, httpQuery }) => builder({ url: `/api/manageShop/${payload.sid}/pingShop`, method: 'GET', payload, httpQuery, target }),
  },
  manageWebhook: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/manageShop/${payload.sid}/manageWebhook`, method: 'REPORT', payload, httpQuery, target }),
    post: ({ payload, httpQuery }) => builder({ url: `/api/manageShop/${payload.sid}/manageWebhook`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/api/manageShop/${payload.sid}/manageWebhook`, method: 'DELETE', payload, httpQuery, target }),
  },
};

export default manageShop;
