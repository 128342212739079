/* eslint-disable max-len */

import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';
import { actions, getters, commonState } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        ...commonState.menu.home.menuItems,
      ],
    },
  },
  getters: {
    navigation: (state) => state.navigation,
    ...getters.account,
    ...getters.channelGroup,
    manageShopifyShopList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/manageShopifyShop/getterManageShopifyShopList': manageShopifyShopList } = rootGetters;
      return manageShopifyShopList;
    },
    manageShopifyShop: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiAffiliation/manageShopifyShop/getterManageShopifyShop': manageShopifyShop } = rootGetters;
      return manageShopifyShop;
    },
    manageWebhookList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiPlatformShopify/manageWebhook/getterManageWebhookList': manageWebhookList } = rootGetters;
      return manageWebhookList;
    },
    pingShop: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiPlatformShopify/pingShop/getterPingShop': pingShop } = rootGetters;
      return pingShop;
    },
  },
  actions: {
    ...actions.account,
    ...actions.channelGroup,
    reportManageShopifyShop({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/manageShopifyShop/report',
        context: {
          payload: { ...payload },
          httpQuery: { ...httpQuery },
        },
        textNotifier: 'report manage shopify shop list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report manage shopify shop list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    getManageShopifyShop({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/manageShopifyShop/get',
        context: { payload: { ...payload }, httpQuery: { ...httpQuery } },
        textNotifier: 'get manage shopify shop list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to get manage shopify shop list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    createManageShopifyShop({ dispatch }, { payload, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/manageShopifyShop/create',
        context: {
          payload: { ...payload }, httpQuery: { ...httpQuery },
        },
        textNotifier: 'create manage shopify shop list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to create manage shopify shop list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    updateManageShopifyShop({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/manageShopifyShop/update',
        context: { payload: { ...payload }, httpQuery: { ...httpQuery } },
        textNotifier: 'update manage shopify shop list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to update manage shopify shop list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    deleteManageShopifyShop({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'ApiAffiliation/manageShopifyShop/delete',
        context: { payload: { ...payload }, httpQuery: { ...httpQuery } },
        textNotifier: 'delete manage shopify shop list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to delete manage shopify shop list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    reportManageWebhookList({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiPlatformShopify/manageWebhook/report',
        context: {
          payload: { ...payload },
          httpQuery: { ...httpQuery },
        },
        textNotifier: 'report manage webhook list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report manage webhook list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    createManageWebhook({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'ApiPlatformShopify/manageWebhook/create',
        context: {
          payload: { ...payload },
          httpQuery: { ...httpQuery },
        },
        textNotifier: 'create manage webhook list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to create manage webhook list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    deleteManageWebhook({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'ApiPlatformShopify/manageWebhook/delete',
        context: {
          payload: { ...payload },
          httpQuery: { ...httpQuery },
        },
        textNotifier: 'delete shopify list',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to delete shopify list',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    up_manageShopifyShop({ commit }, { payload }) {
      commit('ApiAffiliation/manageShopifyShop/SET_MANAGE_SHOPIFY_SHOP', payload, { root: true });
    },
    up_manageShopifyShopList({ commit, rootGetters }, { payload }) {
      const { 'ApiAffiliation/manageShopifyShop/getterManageShopifyShopList': manageShopifyShopList } = rootGetters;

      const newManageShopifyShopList = manageShopifyShopList.map((shop) => {
        if (Number(shop.ssid) === payload.ssid) return payload;
        return shop;
      });

      commit('ApiAffiliation/manageShopifyShop/SET_MANAGE_SHOPIFY_SHOP_LIST', newManageShopifyShopList, { root: true });
    },
    ping({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'ApiPlatformShopify/pingShop/get',
        context: {
          payload: { ...payload },
          httpQuery: {},
        },
        textNotifier: 'ping shop',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to ping shop',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
  },
};
