<template>
  <div
    :class="['start', 'menu-item-list', 'can-close', {
      'is-closed': localIsClosed }]
    "
  >
    <div
      class="multibar row-vcentered mb-2"
      @click="localIsClosed = !localIsClosed"
    >
      <p class="start menu-item-title disclaimer is-3">{{ title }}</p>
      <b-icon
        pack="ubu"
        :icon="localIsClosed ? 'ubu-arrow-up' : 'ubu-arrow-down'"
        class="end disclaimer is-3"
      />
    </div>

    <div class="ubuMenuItem">
      <!-- <br> -->
      <slot name="content" />
    </div>
    <hr>
  </div>
</template>

<script>
export default {
  name: 'UbuSubMenuDrawer',
  model: {
    prop: 'isClosed',
  },
  props: {
    isClosed: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    localIsClosed: {
      get() { return this.isClosed; },
      set(val) { this.$emit('input', val); },
    },
  },
};
</script>
