<template>
  <TheUploadFromCSV

    v-model="codeListToUpload"
    :params="paramsToUpload"
    @input="createAffiliationCodeFromCsv($event)"
  >
    <template #moreActions>
      <fieldset class="is-bordered">
        <h3 class="subtitle is-3">Add codes to folder 👇</h3>

        <TheAffiliationCodeFolderDropdown
          v-model="localAffiliationCodeFolder"
          :can-create="true"
        />
      </fieldset>
      <br>

      <b-field label="Coupon description">
        <b-input
          v-model="description"
          type="textarea"
          placeholder="10% Off for each order over $50"
          maxlength="200"
        />
      </b-field>

      <br>
    </template>
  </TheUploadFromCSV>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackBarSuccess } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  name: 'TheCreateAffiliationCodeFromCsv',
  props: {
    affiliationCodeFolderAcfid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      description: null,

      codeListToUpload: [],
      paramsToUpload: {
        maxRows: 100,
        delimiters: [{ label: ';', code: ';' }], // if more item show select delimiter
        columns: [{
          values: [{
            label: 'Code',
            key: 'code',
          }],
        }, {
          values: [
            {
              label: 'ID',
              key: 'id',
            }],
        }],
      },
    };
  },
  computed: {
    ...mapGetters({
      _currentShop: 'TheCreateAffiliationCodePanel/currentShop',
    }),
    localAffiliationCodeFolder: {
      get() { return this.affiliationCodeFolderAcfid; },
      set(val) { this.$emit('input', val); },
    },
  },
  methods: {
    ...mapActions({
      createAffiliationCodeList: 'TheCreateAffiliationCodePanel/createAffiliationCodeList',
    }),
    createAffiliationCodeFromCsv() {
      // remove duplicate and prepare payload
      const affiliationCodeList = this.codeListToUpload.reduce((acc, { code }) => {
        if (!acc[code]) {
          acc[code] = {
            shopSid: this._currentShop.sid,
            affiliationCodeFolderAcfid: this.affiliationCodeFolderAcfid,
            contactPlatformCpid: null,
            campaignCid: null,
            type: 'DISCOUNT',
            code,
            provider: 'MANUAL',
            extrefId: null,
            link: `${this._currentShop.baseUrl}/discount/${code}`,
            createdOn: this.$moment().format(),
            description: this.description,
          };
        }
        return acc;
      }, {});

      const payload = {
        sid: this._currentShop.sid,
        affiliationCodeList: Object.values(affiliationCodeList),
      };

      return this.createAffiliationCodeList({
        payload,
        httpQuery: { contactPlatform: true },
      })
        .then(() => {
          this.$buefy.snackbar.open({
            ...snackBarSuccess,
            message: 'Discount codes successfully created!',
          });
          this.codeListToUpload = [];
          this.$emit('goToTabByTabPath', 'root');
        });
    },
  },
};
</script>
