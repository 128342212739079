/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import builder from '.';

const uploadImage = {
  // eslint-disable-next-line object-curly-newline
  various: ({ payload: { headers, method, url, data }, httpQuery }) => builder({ headers, url, method, payload: data, httpQuery }),
};

export {
  uploadImage,
};
