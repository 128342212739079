import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheUbuLeadNavigation from './TheUbuLeadNavigation';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheUbuLeadNavigation);
  },
};

use(Plugin);

export default Plugin;

export {
  TheUbuLeadNavigation,
};
