<template>
  <div>
    <UbuSearchbar
      v-model="search"
      @clear="search = ''"
    />

    <br>

    <div class="buttons">
      <UbuActionCard
        v-for="cg in filteredLabelList"
        :key="cg.cgid"
        :class="`mb-4 has-text-color-${cg.color}`"
        :label="cg.label"
        icon-pack="ubu"
        icon-left="ubu-custom-contact"
        expanded
        @click="bulkAssign(cg.cgid, cg.cidsWithoutLabel)"
      >
        <template v-slot:left-content>
          <div :class="`end multibar has-text-color-${cg.color}`">
            <b-icon
              v-if="!cg.cidsWithoutLabel.length"
              pack="ubu"
              icon="ubu-success"
              size="is-medium"
            />
            <b-icon
              v-else
              pack="ubu"
              icon="ubu-in-progress"
              class="has-text-secondary"
              size="is-medium"
            />
          </div>
        </template>
      </UbuActionCard>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterEngineMixin from '@dailyplanet/mixins/FilterEngineMixin';

export default {
  name: 'TheBulkAssignLabelButtons',
  mixins: [FilterEngineMixin],
  model: {
    prop: 'checkedRows',
  },
  props: {
    checkedRows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      contactGroupList: 'TheBulkActions/contactGroupList',
    }),

    localChecked: {
      get() { return this.checkedRows; },
      set(value) { this.$emit('input', value); },
    },

    unassignedByLabel() {
      const cgList = Object.values(this.contactGroupList);

      return cgList.map((cg) => ({
        ...cg,
        cidsWithoutLabel: this.getCidsWithoutLabel(cg.cgid),
      }));
    },

    filteredLabelList() {
      const cgList = this.unassignedByLabel;

      if (!cgList.length) return [];

      const filtersToApply = {};

      if (this.search) filtersToApply.searchByLabelName = this.search;

      return this.filterEngine(cgList, filtersToApply);
    },

  },
  methods: {
    ...mapActions({
      _assignContactGroup: 'TheBulkActions/_assignContactGroup',
      up_addLabelsForCRM: 'TheCRM/up_addLabels',
      up_addLabelActivityMention: 'TheMention/up_addLabelActivityMention',
      up_addLabelCampaignMention: 'TheInfluenceMentions/up_addLabelCampaignMention',
      UP_activitiesLabelsForInfluenceDashboard: 'ApiInfluence/dashboards/UP_activitiesLabels',
      up_addLabelCampaignDetailled: 'TheInfluence/up_addLabelCampaignDetailled',
    }),

    getCidsWithoutLabel(cgid) {
      const toBeLabeled = this.localChecked.reduce((acc, row) => {
        if (!acc[row.cid]) {
          if (!row.labels) return { ...acc, [row.cid]: true };
          if (!row.labels.find((label) => String(label.cgid) === String(cgid))) {
            return { ...acc, [row.cid]: true };
          }
        }
        return acc;
      }, {});

      return Object.keys(toBeLabeled);
    },

    bulkAssign(cgid, cids) {
      if (!cids.length) {
        this.$buefy.snackbar.open({
          message: 'All people added to contact label',
          type: 'is-success',
          position: 'is-top',
        });
        this.$emit('done');
        return undefined;
      }

      const rows = this.checkedRows.reduce((acc, row) => {
        if (row.contact) { acc.push(row); return acc; }
        if (!row.contact && row.contactCid) { acc.push({ ...row, contact: { cid: row.contactCid } }); return acc; }
        return acc;
      }, []);

      return this._assignContactGroup({ payload: { cgid, rows } })
        .then(() => Promise.all([
          this.up_addLabelActivityMention({ cids, cgid }),
          this.up_addLabelCampaignMention({ cids, cgid }),
          this.up_addLabelCampaignDetailled({ cids, cgid }),
          this.UP_activitiesLabelsForInfluenceDashboard({ cids, cgid }),
          this.up_addLabelsForCRM({ cids, cgids: [cgid] }),
        ]))
        .then(() => {
          // We emit before update store because it will change checkedRows if exists and may close
          // components depending of checkedRows

          this.$buefy.snackbar.open({
            message: `${cids.length} people added to contact label`,
            type: 'is-success',
            position: 'is-top',
          });
          this.$emit('done');
        });
    },
  },
};
</script>
