<template>
  <div
    class="ubuMessageCard-video"
  >
    <video
      ref="video"
      controls
      preload="metadata"
      poster="@/assets/images/image-placeholder.png"
      @canplay="canPlay()"
    >
      <source
        :src="videoLink"
        :type="contentType+'/'+format"
        @error="$emit('handleError')"
      >
      Sorry, your browser doesn't support embedded videos.
    </video>
  </div>
</template>
<script>
/*
Do not remove the preload tag (used to load the thumbnail)
we add '#t=0.1' at the end of the url so we can load the begining of the video wich will be usefull
to get a video thumbnail instead of the generic one
*/
import MessageMixin from '@dailyplanet/mixins/MessageMixin';

export default {
  name: 'UbuThreadMessageVideo',
  mixins: [MessageMixin],
  props: {
    message: {
      type: Object,
      required: true,
    },
    contentType: {
      type: String,
      default: 'video',
    },
    format: {
      type: String,
      default: 'mp4',
    },
    url: {
      type: String,
      default: null,
    },
  },
  computed: {
    videoLink() {
      const link = this.url ? this.url : this.messageLink;
      if (!link.endsWith('#t=0.1')) {
        return `${link}#t=0.1`;
      }
      return link;
    },
  },
  methods: {
    canPlay() {
      this.$emit('mediaIsReady');
    },
  },
};
</script>
