<template>
  <section>
    <TheCrewCartouche
      v-model="filters"
      :allowed-filters="allowedFilters"
      :non-displayable-filters="nonDisplayableFilters"
    >
      <template #topLine>
        <div class="multibar row-vcentered pb-4">
          <b-dropdown
            v-model="period"
            aria-role="list"
            class="start mr-2"
          >
            <template #trigger="{ active }">
              <b-button
                :label="period"
                type="is-primary"
                :icon-right="active ? 'menu-up' : 'menu-down'"
              />
            </template>

            <b-dropdown-item
              v-for="month in avalaibleRewards"
              :key="month"
              :type="month === period ? 'is-primary' : null"
              :value="month"
              aria-role="listitem"
            >
              {{ month }}
            </b-dropdown-item>
          </b-dropdown>

          <b-taglist class="start">
            <b-taglist attached class="mb-0">
              <b-tag
                type="is-dark"
                icon-pack="ubu"
                icon="ubu-money"
                class="start is-medium mb-0"
              >
                Total revenue
              </b-tag>
              <b-tag
                v-if="totalsFromPeriod?.totalRevenue"
                class="start is-success is-medium mb-0"
              >
                ${{ totalsFromPeriod?.totalRevenue }}
              </b-tag>
              <b-tag v-else class="start is-success is-medium mb-0">
                -
              </b-tag>
            </b-taglist>

            <b-taglist attached class="mb-0 ml-2">
              <b-tag
                type="is-dark"
                icon-pack="ubu"
                icon="ubu-money"
                class="start is-medium mb-0"
              >
                Total commission
              </b-tag>
              <b-tag
                v-if="totalsFromPeriod?.totalCommission"
                class="start is-success is-medium mb-0"
              >
                ${{ totalsFromPeriod?.totalCommission }}
              </b-tag>
              <b-tag v-else class="start is-success is-medium mb-0">
                -
              </b-tag>
            </b-taglist>

            <b-taglist attached class="mb-0 ml-2">
              <b-tag
                type="is-dark"
                icon-pack="ubu"
                icon="ubu-money"
                class="start is-medium mb-0"
              >
                Total commission paid
              </b-tag>
              <b-tag
                v-if="totalsFromPeriod?.totalCommissionPaid"
                class="start is-success is-medium mb-0"
              >
                ${{ totalsFromPeriod?.totalCommissionPaid }}
              </b-tag>
              <b-tag v-else class="start is-success is-medium mb-0">
                -
              </b-tag>
            </b-taglist>
          </b-taglist>
        </div>
      </template>
    </TheCrewCartouche>

    <div
      class="mainPanelTable hasAnalyticsCards"
      @keydown.prevent="shortcutHandlerTable($event, localChecked)"
    >
      <b-table
        ref="table"
        :data="Object.values(affiliationRewardList)"
        class="table-row-clickable table-full-height"
        hoverable
        sticky-header
        :loading="loading"
        :detail-key="detailKey"

        sticky-checkbox
        checkable
        :checked-rows.sync="checkedRows"
        :custom-is-checked="(a, b) => {
          return a[detailKey] === b[detailKey];
        }"

        :selected.sync="selected"
        focusable

        paginated
        backend-pagination
        :total="total"
        :per-page="perPage"
        pagination-size="is-small"

        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"

        @page-change="pageChange"
        @sort="onSort"
        @keydown.prevent="shortcutHandlerTable($event, checkedRows)"
      >
        <b-table-column
          v-for="column in columns"
          :key="column.field"
          :field="column.field"
          :label="column.label"
          :sortable="column.isSortable"
          :visible="column.visible"
          :custom-sort="(a, b, isAsc) => customSort(a, b, isAsc, column)"
        >
          <template
            v-if="column.cmp === 'UbuTablePlatform'"
            #header
          >
            <b-icon
              pack="ubu"
              icon="ubu-organic"
            />
          </template>

          <template
            v-if="column.cmp === 'UbuTableActionBefore'"
            #default="props"
          >
            <div class="buttons">
              <b-button
                type="is-ghost"
                label="Details"
                @click="openForDetails = props.row"
              />
            </div>
          </template>

          <template
            v-else-if="column.cmp === 'UbuTableActionAfter'"
            #default="props"
          >
            <div class="buttons no-wrap">
              <b-button
                v-show="!props.row.reviewedOn"
                type="is-secondary"
                label="Approve"
                icon-pack="ubu"
                icon-left="ubu-thumb"
                :disabled="props.row.reviewedOn !== null"
                @click="setReviews([props.row.arid])"
              />

              <b-button
                v-show="props.row.reviewedOn"
                type="is-primary"
                label="Mark as paid"
                icon-pack="ubu"
                icon-left="ubu-check"
                :disabled="props.row.paidOn !== null"
                @click="openForMarkAsPaid = props.row"
              />
            </div>
          </template>

          <template
            v-else-if="column.cmp === 'ReviewedBy'"
            #default="props"
          >
            <span v-if="props.row.reviewedOn">
              {{ userList[props.row.reviewerUid]?.userDetail?.firstName }}
              {{ userList[props.row.reviewerUid]?.userDetail?.lastName }}
              {{ props.row.reviewedOn | humanizeDate }}
            </span>
            <span v-else>-</span>
          </template>

          <template
            v-else-if="column.cmp === 'UbuTablePaidType'"
            #default="props"
          >
            <span v-if="props.row.paidType === 'MANUAL'">Manual</span>
            <span v-else-if="props.row.paidType === 'COMMON'">Auto</span>
            <span v-else>-</span>
          </template>

          <template v-else #default="props">
            <component
              :is="column.cmp"
              :cpo="props.row"
              :field="column.field"
              :column="column"
              @selectContactPlatform="selectContactPlatform($event)"
            />
          </template>
        </b-table-column>

        <template #empty>
          <p class="has-text-secondary">No rewards yet!</p>
        </template>
      </b-table>
    </div>

    <UbuBulkActionPanelTrigger
      :bulk-is-open="bulkIsOpen"
      :checked-rows-length="checkedRows.length"
      @cancel="bulkIsOpen = false; checkedRows = {};"
      @open="bulkIsOpen = true;"
    />

    <TheRewardBulkPanel
      v-if="bulkIsOpen && checkedRows.length"
      v-model="checkedRows"
      @close="bulkIsOpen = false; checkedRows = [];"
    />

    <TheRewardMarkAsPaidPanel
      v-if="openForMarkAsPaid"
      :reward="openForMarkAsPaid"
      @close="openForMarkAsPaid = null"
    />

    <TheRewardDetailsPanel
      v-if="openForDetails"
      :reward="openForDetails"
      @close="openForDetails = null"
    />

    <TheContactPlatformPanel
      v-if="selectedContactPlatform"
      v-model="selectedContactPlatform"
      is-large
      @close="selectedContactPlatform = null;"
    />

    <div
      v-if="isBackgroundLocked"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import TableUtilsMixin from '@dailyplanet/cross-addons/table/_mixins/TableUtils.mixin';

export default {
  name: 'TheCrewReward',
  mixins: [TableUtilsMixin],
  data() {
    return {
      openForDetails: false,
      openRewardSettingsPanel: false,
      bulkIsOpen: false,
      selectedContactPlatform: null,
      isReviewingLoading: false,
      period: this.$moment().subtract({ month: 1 }).format('YYYY-MM'),

      detailKey: 'arid',
      page: 1,
      perPage: 50,
      total: 0,
      sortField: 'totalRevenue',
      sortOrder: 'desc',
      loading: false,
      selected: null,
      openForMarkAsPaid: null,
      checkedRows: [],
      filters: [{
        id: uuidv4(),
        key: 'affiliationReward.createdOn',
        type: 'rangeDate',
        operator: 'is between',
        allowedOperators: ['is between', 'is empty'],
        values: [this.$moment().subtract({ month: 1 }).startOf('month').format('YYYY-MM-DD HH:mm:ss'), this.$moment().subtract({ month: 1 }).endOf('month').format('YYYY-MM-DD HH:mm:ss')],
      },
      ],
      allowedFilters: [
        'contactPlatform.username',
        // 'contactPlatform.followerCount',
        'affiliationReward.totalRevenue',
        'affiliationReward.totalCommissionFee',
        'affiliationReward.createdOn',
        'affiliationReward.reviewedOn',
        'affiliationReward.rewardAskedOn',
        'affiliationReward.paidOn',
        'affiliationReward.paidType',
      ],
      nonDisplayableFilters: [
        // 'activity.status',
        'contactPlatform.username',
      ],

      /* eslint-disable object-curly-newline */
      /* eslint-disable key-spacing */
      /* eslint-disable no-multi-spaces */
      columns: {
        detail:                 { cmp: 'UbuTableActionBefore',       field: null,                                        label: 'Action',          isSortable: false },
        contactPlatformPlatformName:           { cmp: 'UbuTablePlatform',           field: 'contactPlatform.platformName',              label: 'Platform',        isSortable: false },
        contactPlatformUsername:               { cmp: 'UbuTableContactPlatform',    field: 'contactPlatform.username',                  label: 'Username',        isSortable: true },
        contactPlatformFollowerCount:          { cmp: 'UbuTableNumber',             field: 'contactPlatform.followerCount',             label: 'Followers',       isSortable: true },
        affiliationRewardTotalRevenue:           { cmp: 'UbuTableNumberDecimalFormatter',             field: 'affiliationReward.totalRevenue',            label: 'Revenue',         isSortable: true, before: '$' },
        affiliationRewardTotalCommissionFee:     { cmp: 'UbuTableNumber',             field: 'affiliationReward.totalCommissionFee',      label: 'Commission',      isSortable: true, before: '$' },
        affiliationRewardCreatedOn:              { cmp: 'UbuTableDate',               field: 'affiliationReward.createdOn',               label: 'Date generated',  isSortable: true },
        affiliationRewardReviewerUid:            { cmp: 'ReviewedBy',                 field: 'affiliationReward.reviewerUid',             label: 'Reviewed by',     isSortable: false },
        affiliationRewardRewardAskedOn:          { cmp: 'UbuTableDate',               field: 'affiliationReward.rewardAskedOn',           label: 'Reward asked on', isSortable: true },
        affiliationRewardPaidOn:                 { cmp: 'UbuTableDate',               field: 'affiliationReward.paidOn',                  label: 'Date paid',       isSortable: true },
        affiliationRewardPaidType:               { cmp: 'UbuTablePaidType',           field: 'affiliationReward.paidType',                label: 'Reward type',     isSortable: true },
        action:                 { cmp: 'UbuTableActionAfter',        field: null,                                        label: 'Action',          isSortable: false },
      },
      /* eslint-enable object-curly-newline */
    };
  },

  computed: {
    ...mapGetters({
      affiliationRewardList: 'TheCrew/affiliationRewardList',
      _totalsByPeriod: 'TheCrew/totalsByPeriod',
      userList: 'User/getterUserList',
    }),

    isBackgroundLocked() {
      if (this.bulkIsOpen && this.checkedRows.length) return true;
      if (this.openForMarkAsPaid) return true;
      if (this.openForDetails) return true;
      return false;
    },

    navigation() {
      const basePath = '/reward';
      return [
        {
          label: 'Review',
          path: `${basePath}/review`,
        },
        {
          label: 'Settings',
          path: `${basePath}/settings`,
        },
      ];
    },

    avalaibleRewards() {
      const dateStart = this.$moment('2023-01-01');
      const dateEnd = this.$moment();
      const interim = dateStart.clone();
      const timeValues = [];

      while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
        timeValues.push(interim.format('YYYY-MM'));
        interim.add(1, 'month');
      }
      return timeValues.reverse();
    },

    totalsFromPeriod() {
      return this._totalsByPeriod[this.period];
    },
  },
  watch: {
    filters(newValue) {
      if (newValue) {
        return this.UP_resetAffiliationRewardList()
          .then(() => this.reportDatas());
      }
      return undefined;
    },
    period(newVal, oldVal) {
      if (this.$moment(newVal).isSame(this.$moment(oldVal))) return undefined;
      if (this.totalsFromPeriod) return undefined;

      return this._getTotalCommissionByPeriod({ payload: { period: this.period } });
    },
  },
  mounted() {
    return Promise.all([
      this.reportDatas(),
      this._getTotalCommissionByPeriod({ payload: { period: this.period } }),
    ]);
  },
  methods: {
    ...mapActions({
      _reportAffiliationRewardList: 'TheCrew/reportAffiliationRewardList',
      _getTotalCommissionByPeriod: 'TheCrew/getTotalCommissionByPeriod',
      _reviewAffiliationReward: 'TheCrew/reviewAffiliationReward',
      _markAsPaidReward: 'TheCrew/markAsPaidReward',
      UP_resetAffiliationRewardList: 'ApiAffiliation/affiliationReward/UP_resetAffiliationRewardList',
      getAndSetCurrentContactPlatformOverview: 'TheContactPlatformPanel/getAndSetCurrentContactPlatformOverview',
    }),

    selectContactPlatform(cpid) {
      this.selectedContactPlatform = cpid;
      return this.getAndSetCurrentContactPlatformOverview({ payload: { cpid } });
    },

    setReviews(arids) {
      this.isReviewingLoading = true;

      return this._reviewAffiliationReward({ payload: { arids } })
        .then(() => { this.isReviewingLoading = false; });
    },

    reportDatas() {
      this.loading = true;

      return this._reportAffiliationRewardList({
        payload: {},
        httpQuery: {
          pagin: `${this.page - 1},${this.perPage}`,
          sorting: `${this.sortField},${this.sortOrder}`,
          filters: this.prepareFiltersToRequest(this.allowedFilters, this.filters),
          followUp: true,
        },
      })
        .then(({ total }) => {
          this.total = total;
          this.loading = false;
        });
    },
  },
};
</script>
