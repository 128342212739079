<template>
  <UbuDropdown
    position="is-bottom-right"
    :expanded="true"
    :scrollable="true"
    @open="isOpen = true"
    @close="isOpen = false;"
  >
    <template #button>
      <b-button
        :label="hasSelectedDiscount ? selectedDiscount.code : 'Select a discount code'"
        icon-pack="ubu"
        :icon-left="hasSelectedDiscount ? 'ubu-discount' : ''"
        type="is-secondary"
      />
    </template>

    <template
      v-if="isOpen"
      #search
    >
      <b-dropdown-item custom>
        <UbuSearchbar
          v-model="search"
          :loading="isFetching"
          @input="isFetching = true"
          @clear="search = ''"
        />
      </b-dropdown-item>
    </template>

    <template #content>
      <RecycleScroller
        ref="scroller"
        class="scroller"
        :item-size="40"
        :items="sortedDiscountList"
        key-field="acid"
        :emit-update="true"
        @update="onScroll"
      >
        <template
          #default="{ item }"
        >
          <b-dropdown-item @click="$emit('input', item)">
            <div class="multibar row-vcentered">
              <span class="start text-truncate">{{ item.code }}</span>

              <b-icon
                v-if="item.contactPlatformCpid"
                class="end"
                pack="ubu"
                icon="ubu-account"
              />

              <b-icon
                v-if="item.campaignCid"
                class="end"
                pack="ubu"
                icon="ubu-influence"
              />
            </div>
          </b-dropdown-item>
        </template>
      </RecycleScroller>
    </template>
  </UbuDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from '@dailyplanet/utils/helpers';

export default {
  name: 'TheDiscountCodeDropdown',
  model: {
    prop: 'selectedDiscount',
  },
  props: {
    selectedDiscount: {
      type: Object,
      default: null,
    },
    selectedAffiliationCodeFolderAcfid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      search: '',
      page: 0,
      perPage: 50,
      filters: [
        { key: 'affiliationCode.type', operator: 'is one of', values: ['DISCOUNT'] },
        { key: 'affiliationCode.affiliationCodeFolderAcfid', operator: 'is one of', values: [this.selectedAffiliationCodeFolderAcfid] },
      ],
      isFetching: false,
    };
  },
  computed: {
    ...mapGetters({
      _discountCodeOverviewList: 'TheDiscountCodeDropdown/discountCodeOverviewList',
      _currentShop: 'TheDiscountCodeDropdown/currentShop',
    }),

    hasSelectedDiscount() {
      return !!this.selectedDiscount;
    },

    sortedDiscountList() {
      return Object.values(this._discountCodeOverviewList)
        .filter(({ acid }) => (this.selectedDiscount ? acid !== this.selectedDiscount.acid : true))
        .sort((a, b) => {
          const aScore = (a.campaignCid ? 1 : 0) + (a.contactPlatformCpid ? 1 : 0);
          const bScore = (b.campaignCid ? 1 : 0) + (b.contactPlatformCpid ? 1 : 0);

          return aScore - bScore;
        });
    },
  },
  watch: {
    page(newVal, oldVal) {
      return newVal > oldVal ? this.reportDiscountCode() : undefined;
    },

    selectedAffiliationCodeFolderAcfid(newVal, oldVal) {
      // reset discount code list if acfid change
      if (newVal !== oldVal) {
        this.UP_resetDiscountCodeOverviewList();
        this.filters = this.filters.filter(({ key }) => key !== 'affiliationCode.affiliationCodeFolderAcfid');
        this.$emit('input', null);

        if (newVal) this.filters.push({ key: 'affiliationCode.affiliationCodeFolderAcfid', operator: 'is one of', values: [newVal] });

        if (this.page !== 0) {
          this.page = 0;
        }

        this.reportDiscountCode();
      }
    },

    // eslint-disable-next-line func-names
    search: debounce(function (newVal) {
      this.UP_resetDiscountCodeOverviewList();

      if (!newVal) this.removeSearchFilter();

      if (newVal.length < 3) {
        this.isFetching = false;
        return;
      }

      this.isFetching = true;
      this.filters.push({ key: 'search', values: [this.search] });

      if (this.page !== 0) {
        this.page = 0;
      }

      this.reportDiscountCode();
      this.isFetching = false;
    }, 1000),
  },
  mounted() {
    return Promise.resolve()
      .then(() => this.UP_resetDiscountCodeOverviewList())
      .then(() => this.reportDiscountCode());
  },
  methods: {
    ...mapActions({
      reportDiscountCodeOverviewList: 'TheDiscountCodeDropdown/reportDiscountCodeOverviewList',
      UP_resetDiscountCodeOverviewList: 'TheDiscountCodeDropdown/UP_resetDiscountCodeOverviewList',
    }),

    reportDiscountCode() {
      return this.reportDiscountCodeOverviewList({
        payload: { sid: this._currentShop.sid },
        httpQuery: {
          pagin: `${this.page},${this.perPage}`,
          sorting: 'unlinked,asc',
          filters: this.preparedFilters(),
        },
      });
    },

    onScroll(startIndex, endIndex) {
      const currentPage = Math.floor(endIndex / this.perPage);

      // Changing page
      if (currentPage > this.page) {
        this.page = currentPage;
      }
    },

    preparedFilters() {
      return JSON.stringify(this.filters);
    },

    removeSearchFilter() {
      this.filters = this.filters.filter(({ key }) => key !== 'search');
      this.reportDiscountCode();
    },
  },
};
</script>
