<template>
  <TheSimplePanel>
    <template #header>
      <h1 class="title is-1">Edit name</h1>
    </template>

    <template #content>
      <UbuEditNameForm
        v-model="newName"
        @editName="editName()"
      />
    </template>
  </TheSimplePanel>
</template>

<script>/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheSettingProfilePanelEditName',
  data() {
    return {
      newName: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUserDetail: 'TheSettingUserSetting/currentUserDetail',
    }),
  },
  mounted() {
    this.newName = {
      firstName: this.currentUserDetail.firstName,
      lastName: this.currentUserDetail.lastName,
    };
  },
  methods: {
    ...mapActions({
      updateUserDetail: 'TheSettingUserSetting/updateUserDetail',
      up_detailledUser: 'TheSettingUserSetting/up_detailledUser',
      up_detailledUserList: 'TheSettingUserSetting/up_detailledUserList',
    }),
    editName() {
      const payload = {
        ...this.currentUserDetail,
        ...this.newName,
      };

      this.updateUserDetail({ payload })
        .then(() => { this.$router.push('/setting/profile/'); });
    },
  },
};
</script>
