<template>
  <UbuNoteFilter
    class="ubuMessageWrapper is-note"
    :item="{ ...message, noteDate: message.itemDate }"
    :user-list="userList"
    :campaign-list="campaignList"
    :window-width="windowWidth"
  />
</template>

<script>
export default {
  name: 'UbuThreadMessageLogbook',
  props: {
    message: {
      type: Object,
      default: () => {},
    },
    userList: {
      type: Object,
      default: () => {},
    },
    campaignList: {
      type: Object,
      required: true,
    },
    windowWidth: {
      type: Number,
      default: 0,
    },
  },
};
</script>
