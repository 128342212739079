import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';
import moment from 'moment';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '@dailyplanet/data-stores/$utils/helpers';
// import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';
import { getUsername } from '../$utils/hashtagUtils';

export default {
  namespaced: true,
  getters: {
    ...getters.account,

    hashtagsList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/hashtag/getterHashtagsList': hashtagsList } = rootGetters;

      return hashtagsList;
    },
    currentHashtag: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/hashtag/getterCurrentHashtag': currentHashtag } = rootGetters;

      return currentHashtag;
    },
    mediasFromCurrentHashtag: (_state, _getters, _rootState, rootGetters) => {
      const { 'ChannelGroup/hashtag/getterCurrentHashtag': currentHashtag } = rootGetters;

      if (!currentHashtag) return [];

      const instaMediaMapped = currentHashtag.medias
        .map((m) => ({
          ...m,
          isHashtag: true,
          platformName: 'instagram',
          mediaUrl: m.io_link || `https://io.getubu.com/media/${m.id}`,
          mediaType: m.media_type,
          caption: m.caption || '',
          permalink: m.permalink || `https://www.instagram.com/${getUsername(m, 'instagram')}`,
          date: moment(m.ts).format('YYYY-MM-DD'),
          skeletonHeight: (m.original_height / m.original_width) * 220,
          username: getUsername(m, 'instagram'),
          userPicture: m.user && m.user.profile_pic_url ? m.user.profile_pic_url : null,
          followerCount: m.user ? m.user.follower_count : null,
          commentCount: m.comment_count || null,
          likeCount: m.like_count || null,
          mentionCount: m.mentions ? m.mentions.length : 0,
          prints: null,
          language: m.user ? m.user.language : null,
          location: m.location ? m.location.name : null,
          action: 'Show',
        }));

      let tkMediasMapped = [];

      if (currentHashtag.tiktok_medias && currentHashtag.tiktok_medias.length) {
        tkMediasMapped = currentHashtag.tiktok_medias
          .map((tm) => ({
            ...tm,
            isHashtag: true,
            platformName: 'tiktok',
            mediaUrl: tm.io_link || `https://io.getubu.com/media/${tm.id}`,
            mediaType: 'VIDEO',
            caption: tm.desc || '',
            permalink: `https://www.tiktok.com/@${tm.author.uniqueId}`,
            date: moment(tm.createTime * 1000).format('YYYY-MM-DD'),
            skeletonHeight: (tm.video.height / tm.video.width) * 220,
            username: getUsername(tm, 'tiktok'),
            userPicture: tm.author.avatarThumb,
            followerCount: tm.authorStats.followerCount || null,
            commentCount: tm.stats.commentCount || null,
            likeCount: tm.stats.diggCount || null,
            mentionsCount: tm.mentions ? tm.mentions.length : 0,
            views: tm.stats.playCount,
            language: null,
            location: null,
            action: 'Show',
          }));
      }

      return instaMediaMapped
        .concat(tkMediasMapped)
        .sort((a, b) => (moment(b.date) - moment(a.date)));
    },
  },
  actions: {
    ...actions.segment,

    reportHashtags({ dispatch, rootGetters }, { httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/hashtag/report',
        context: {
          payload: {
            cgid: currentChannelGroup.cgid,
          },
          httpQuery,
        },
        textNotifier: 'report hashtag',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log(e);
          Snackbar.open({
            message: 'Unable to report hashtag.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
    getHashtag({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/hashtag/get',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
          },
          httpQuery,
        },
        textNotifier: 'get hashtag',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log(e);
          Snackbar.open({
            message: 'Unable to get hashtag.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
    createHashtag({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/hashtag/create',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
            channelGroupCgid: currentChannelGroup.cgid,
          },
          httpQuery,
        },
        textNotifier: 'create hashtag',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'createHashtag' }, { root: true }))
        .catch((e) => {
          if (e.message === 'E_UNPROCESSABLE_HASHTAG_HASHTAG_ALREADY_EXISTS') {
            Snackbar.open({
              message: 'You already subscribed to this hashtag. Contact support if you can\'t access to it.',
              ...snackBarFailure,
              indefinite: true,
            });
          } else {
            Snackbar.open({
              message: 'Unable to create hashtag.',
              ...snackBarFailure,
            });
          }
          return dispatchFailure(action);
        });
    },
    deleteHashtag({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ChannelGroup/hashtag/delete',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
          },
          httpQuery,
        },
        textNotifier: 'delete hashtag',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'deleteHashtag' }, { root: true }))
        .catch((e) => {
          console.log(e);
          Snackbar.open({
            message: 'Unable to delete hashtag.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
  },
};
