// import Vue from 'vue';
import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuDropdown from './UbuDropdown';
import UbuDropdownCustomCreator from './UbuDropdownCustomCreator';
import UbuDropdownCustomSelector from './UbuDropdownCustomSelector';
import UbuDropdownCustomRow from './UbuDropdownCustomRow';
import UbuDropdownStatusRow from './UbuDropdownStatusRow';
import UbuDropdownUserRow from './UbuDropdownUserRow';
import UbuDropdownThreadActivityStatus from './UbuDropdownThreadActivityStatus';
import UbuDropdownCampaignActivityStatus from './UbuDropdownCampaignActivityStatus';
import UbuDropdownSortOptions from './UbuDropdownSortOptions';
import UbuDropdownBreadcrumbs from './UbuDropdownBreadcrumbs';
import UbuColumnToggle from './UbuColumnToggle';
import UbuDropdownMemberGroupRow from './UbuDropdownMemberGroupRow';
import UbuDropdownMemberGroupCounters from './UbuDropdownMemberGroupCounters';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuDropdown);
    registerComponent(Vue, UbuDropdownCustomCreator);
    registerComponent(Vue, UbuDropdownCustomSelector);
    registerComponent(Vue, UbuDropdownCustomRow);
    registerComponent(Vue, UbuDropdownStatusRow);
    registerComponent(Vue, UbuDropdownUserRow);
    registerComponent(Vue, UbuDropdownThreadActivityStatus);
    registerComponent(Vue, UbuDropdownCampaignActivityStatus);
    registerComponent(Vue, UbuDropdownSortOptions);
    registerComponent(Vue, UbuDropdownBreadcrumbs);
    registerComponent(Vue, UbuColumnToggle);
    registerComponent(Vue, UbuDropdownMemberGroupRow);
    registerComponent(Vue, UbuDropdownMemberGroupCounters);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuDropdown,
  UbuDropdownCustomCreator,
  UbuDropdownCustomSelector,
  UbuDropdownCustomRow,
  UbuDropdownStatusRow,
  UbuDropdownUserRow,
  UbuDropdownThreadActivityStatus,
  UbuDropdownCampaignActivityStatus,
  UbuDropdownSortOptions,
  UbuDropdownBreadcrumbs,
  UbuColumnToggle,
  UbuDropdownMemberGroupRow,
  UbuDropdownMemberGroupCounters,
};
