<template>
  <b-tabs v-model="activeTab">
    <b-tab-item label="Update contact">
      <form @submit.prevent="$emit('updateContact', localContact)">
        <UbuSalesContactForm
          v-model="localContact"
          :scraping-zone-list="scrapingZoneList"
        />
        <br>
        <div class="buttons is-right">
          <b-button
            type="is-info"
            native-type="submit"
            label="Update"
          />
        </div>
      </form>
    </b-tab-item>

    <b-tab-item label="Create new Lead">
      <div class="buttons is-right">
        <b-button
          type="is-link"
          label="Prefill Headquarter"
          @click="prefillHeadquarter()"
        />
      </div>
      <form @submit.prevent="$emit('createLead', newLead)">
        <UbuLeadForm
          v-model="newLead"
        />
        <br>
        <div class="buttons is-right">
          <b-button
            type="is-success"
            native-type="submit"
            label="Create"
          />
        </div>
      </form>
    </b-tab-item>

    <b-tab-item label="Update existing Leads">
      <!-- <div id="formScroller">
        <div
          v-for="(lead) in localLeads"
          :key="lead.lid"
        >
          <form @submit.prevent="$emit('updateLead', lead)">
            <UbuKaraLeadForm
              v-model="localLeads[lead.lid]"
            />
            <br>
            <div class="buttons is-right">
              <b-button
                type="is-info"
                native-type="submit"
                value="Update"
              />
            </div>
          </form>
        </div>
      </div> -->
      <b-collapse
        v-for="(lead) in localLeads"
        :key="lead.lid"
        class="card"
        animation="slide"
        :open="false"
        aria-id="contentIdForA11y3"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title is-6">
              #{{ lead.lid }} {{ lead.email }}
            </p>
            <a class="card-header-icon">
              <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'"
              />
            </a>
          </div>
        </template>

        <div class="card-content">
          <form @submit.prevent="$emit('updateLead', lead)">
            <UbuLeadForm
              v-model="localLeads[lead.lid]"
            />
            <br>
            <div class="buttons is-right">
              <b-button
                type="is-info"
                native-type="submit"
                value="Update"
              />
            </div>
          </form>
        </div>
      </b-collapse>
    </b-tab-item>

    <b-tab-item label="Dev.">
      <DevDetails
        open
        :cnt="{name: 'newLead details', obj: newLead}"
      />
      <DevDetails
        open
        :cnt="{name: 'contact details', obj: contact}"
      />
    </b-tab-item>
  </b-tabs>
</template>

<script>
const zones = [
  'Africa',
  'Asia / Australia',
  'Europe',
  'North America',
  'South America',
];
export default {
  name: 'UbuSalesContactTableDetail',
  model: {
    prop: 'contact',
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    scrapingZoneList: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      activeTab: 0,
      localLeads: [...this.contact.leads].arrayMapper('lid'),
      localContact: {
        username: this.contact.username,
        platformName: this.contact.platformName,
        followers: this.contact.followers,
        engagement: this.contact.engagement,
        growth: this.contact.growth,
        zone: zones.includes(this.contact.zone) ? this.contact.zone : null,
        language: this.contact.language,
        category: this.contact.category,
        picture: this.contact.picture,
        createdOn: this.contact.createdOn,
        isVerified: this.contact.isVerified,
        origin: this.contact.origin,
        scrapingZoneSzid: this.contact.scrapingZoneSzid,
      },
      newLead: {
        contactUsername: this.contact.username,
        linkedInUrl: null,
        firstName: null,
        lastName: null,
        position: null,
        email: null,
        phone: null,
        isRisky: false,
      },
    };
  },
  methods: {
    prefillHeadquarter() {
      this.newLead = {
        contactUsername: this.contact.username,
        linkedInUrl: null,
        firstName: null,
        lastName: 'HEADQUARTER',
        position: 'HEADQUARTER',
        email: null,
        phone: null,
        isRisky: false,
      };
    },
  },
};
</script>
