/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    tribeList: {},
  },
  mutations: {
    SET_TRIBE_LIST(state, newData) { state.tribeList = newData; },
  },
  getters: {
    getterTribeList: (state) => state.tribeList,
  },
  actions: {
    UP_tribe({ commit, getters }, tribe) {
      commit('SET_TRIBE_LIST', { ...getters.getterTribeList, [tribe.tid]: tribe });
    },

    get({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.GET')) return undefined;

      return clark.api.apiTribe.tribe.get({ payload, httpQuery })
        .then((tribe) => {
          commit('SET_TRIBE_LIST', { ...getters.getterTribeList, [tribe.tid]: tribe });
        });
    },

    report({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.REPORT')) return undefined;

      return clark.api.apiTribe.tribe.report({ payload, httpQuery })
        .then((tribeList) => {
          commit('SET_TRIBE_LIST', tribeList.arrayMapper('tid'));
        });
    },

    create({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.CREATE')) return undefined;

      return clark.api.apiTribe.tribe.post({ payload, httpQuery })
        .then((tribe) => {
          commit('SET_TRIBE_LIST', { ...getters.getterTribeList, [tribe.tid]: tribe });
        });
    },

    update({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.UPDATE')) return undefined;

      return clark.api.apiTribe.tribe.put({ payload, httpQuery })
        .then((tribe) => {
          commit('SET_TRIBE_LIST', { ...getters.getterTribeList, [tribe.tid]: { ...getters.getterTribeList[tribe.tid], ...tribe } });
        });
    },

    delete({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.DELETE')) return undefined;

      return clark.api.apiTribe.tribe.delete({ payload, httpQuery })
        .then(() => {
          commit('SET_TRIBE_LIST', Object.values(getters.getterTribeList).filter(({ tid }) => tid !== payload.tid).arrayMapper('tid'));
        });
    },

    addFromCustom({ rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'TRIBE.UPDATE')) return undefined;

      return clark.api.apiTribe.tribe.addFromCustom({ payload, httpQuery });
    },
  },
};
