<template>
  <div>
    <vue-plyr>
      <audio
        controls
        playsinline
        @canplay="canPlay()"
      >
        <source
          :src="messageLink"
          :type="contentType+'/'+format"
          @error="$emit('handleError')"
        >
      </audio>
    </vue-plyr>
  </div>
</template>

<script>
import MessageMixin from '@dailyplanet/mixins/MessageMixin';

export default {
  name: 'UbuThreadMessageAudio',
  mixins: [MessageMixin],
  props: {
    message: {
      type: Object,
      required: true,
    },
    contentType: {
      type: String,
      default: 'audio',
    },
    format: {
      type: String,
      default: 'mp4',
    },
    isStory: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    canPlay() {
      this.$emit('mediaIsReady');
    },
  },
};
</script>
