/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const downloadMention = {
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/downloadMention`, method: 'POST', payload, httpQuery, target }),
};

export default downloadMention;
