import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  actions: {
    create({ rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'CAMPAIGN.BULK_ADD_TO_CAMPAIGN')) return undefined;

      return clark.api.apiInfluence.bulkActivity.post({ payload, httpQuery });
    },

    changeStatus({ rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'ACTIVITY.UPDATE')) return undefined;

      return clark.api.apiInfluence.bulkActivity.patch({ payload, httpQuery: {} });
    },

    delete({ rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'ACTIVITY.DELETE')) return undefined;

      return clark.api.apiInfluence.bulkActivity.delete({ payload, httpQuery: {} });
    },
  },
};
