/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const routes = {
  upload: ({ payload: { url, headers, data }, httpQuery, callback }) => builder({ url, method: 'PUT', headers, payload: data, httpQuery, callback, target: 's3' }),
  getLink: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/cdn/${payload.key}`, method: 'GET', payload, httpQuery, target: 'clark' }),
  createLink: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.channelGroupCgid}/cdn/${payload.key}`, method: 'POST', payload, httpQuery, target: 'clark' }),
};

export default routes;
