/* eslint-disable max-len */
import Errors from '@dailyplanet/utils/Errors';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    messageList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Channel/thread/message/getterMessageList': messageList } = rootGetters;

      return messageList;
    },
    isLoadingMoreMessagesList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Channel/thread/message/getterIsLoadingMoreMessagesList': isLoadingMoreMessagesList } = rootGetters;

      return isLoadingMoreMessagesList;
    },
    messageTid: (_state, _getters, _rootState, rootGetters) => {
      const { 'Channel/thread/message/getterMessageTid': messageTid } = rootGetters;

      return messageTid;
    },
    messageCid: (_state, _getters, _rootState, rootGetters) => {
      const { 'Channel/thread/message/getterMessageCid': messageCid } = rootGetters;

      return messageCid;
    },
    uploadedImageInfo: (_state, _getters, _rootState, rootGetters) => {
      const { 'Channel/thread/message/getterUploadedImageInfo': uploadedImageInfo } = rootGetters;

      return uploadedImageInfo;
    },
  },
  actions: {
    reportMessage({ dispatch }, { payload }) {
      // const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'Channel/thread/message/report',
        context: { payload },
        textNotifier: 'report message from thread',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: message.js ~ line 30 ~ reportMessage ~ e', e);
          Snackbar.open({
            message: 'Unable to report message from thread.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
    postReachMessage({ dispatch }, { payload }) {
      // const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiConversation/instagramReach/post',
        context: { payload },
        textNotifier: 'post message',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: message.js ~ line 56 ~ postMessage ~ e', e);
          Snackbar.open({
            message: 'Unable to post message.',
            ...snackBarFailure,
            indefinite: true,
          });

          dispatchFailure(action);
          throw new Errors(e);
        });
    },
    deleteHoldedMessage({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'Channel/thread/message/deleteHolded',
        context: { payload },
        textNotifier: 'delete holded message',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: message.js ~ line 82 ~ deleteHoldedMessage ~ e', e);
          Snackbar.open({
            message: 'Unable to delete holded message.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
    loadMoreMessages({ dispatch }, { payload }) {
      // console.log('🚀 ~ file: message.js ~ line 93 ~ loadMoreMessages ~ payload', payload);
      const action = {
        dispatch,
        target: 'Channel/thread/message/loadMore',
        context: { payload },
        textNotifier: 'load more messages from thread',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: message.js ~ line 108 ~ loadMoreMessages ~ e', e);
          Snackbar.open({
            message: 'Unable to load more messages from thread.',
            ...snackBarFailure,
            indefinite: true,
          });

          dispatchFailure(action);
          return Promise.reject(e);
        });
    },
    imageMetadata({ dispatch }, { payload }) {
      // console.log('🚀 ~ file: message.js ~ line 124 ~ imageMetadata ~ payload', payload);
      const action = {
        dispatch,
        target: 'Channel/thread/message/imageMetadata',
        context: { payload },
        textNotifier: 'get image metadata',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: message.js ~ line 138 ~ imageMetadata ~ e', e);
          Snackbar.open({
            message: 'Unable to get image metadata.',
            ...snackBarFailure,
            indefinite: true,
          });

          dispatchFailure(action);
          return Promise.reject(e);
        });
    },
    various({ dispatch }, { payload }) {
      // console.log('🚀 ~ file: message.js ~ line 124 ~ imageMetadata ~ payload', payload);
      const action = {
        dispatch,
        target: 'Channel/thread/message/various',
        context: { payload },
        textNotifier: 'upload image',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: message.js ~ line 164 ~ various ~ e', e);
          Snackbar.open({
            message: 'Unable to upload image.',
            ...snackBarFailure,
            indefinite: true,
          });

          dispatchFailure(action);
          return Promise.reject(e);
        });
    },
    likeMessage({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'Channel/thread/message/like',
        context: { payload },
        textNotifier: 'like message from thread',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch((e) => {
          console.log('🚀 ~ file: message.js ~ line 108 ~ like ~ e', e);
          Snackbar.open({
            message: "Instagram API doesn't allow you to like this message",
            ...snackBarFailure,
            indefinite: true,
          });

          dispatchFailure(action);
          return Promise.reject(e);
        });
    },
    up_isLoadingMoreMessagesList({ dispatch }, { isLoading, tid }) {
      return dispatch('Channel/thread/message/up_isLoadingMoreMessagesList', { isLoading, tid }, { root: true });
    },
  },
};
