import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheDownloadMentions from './smarts/TheDownloadMentions';
import TheMentionBulk from './smarts/TheMentionBulk';
import TheMentionViewMedia from './smarts/TheMentionViewMedia';
import TheMentionModal from './smarts/TheMentionModal';
import TheDailyMentionPanel from './smarts/TheDailyMentionPanel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheDownloadMentions);
    registerComponent(Vue, TheMentionBulk);
    registerComponent(Vue, TheMentionViewMedia);
    registerComponent(Vue, TheMentionModal);
    registerComponent(Vue, TheDailyMentionPanel);
  },
};

use(Plugin);

export default Plugin;

export {
  TheDownloadMentions,
  TheMentionBulk,
  TheMentionViewMedia,
  TheMentionModal,
  TheDailyMentionPanel,
};
