<template>
  <b-field>
    <p class="control">
      <b-dropdown
        v-if="sendButtonMainAction.isDropdownButton"
        position="is-top-right"
      >
        <template #trigger>
          <b-button
            :label="sendButtonMainAction.label"
            :loading="isLoading"
            type="is-primary"
            :disabled="sendButtonMainAction.disabled ? true : false"
          />
        </template>

        <b-dropdown-item
          v-for="el in sendButtonMainAction.dropdownDatas"
          :key="el.label"
          @click="sendButtonMainAction.callback(sendButtonMainAction.event)"
        >
          <div class="media">
            <b-icon
              v-if="el.icon"
              pack="ubu"
              :icon="el.icon"
              class="media-left"
            />
            <div class="media-content">
              <span>{{ el.label.capitalize() }}</span>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>

      <b-tooltip
        v-else
        position="is-top"
        append-to-body
        :active="!!tooltipMainAction && !!tooltipMainAction.label"
      >
        <template
          v-if="!!tooltipMainAction && !!tooltipMainAction.label"
          v-slot:content
        >
          <div>
            <span class="mr-1">{{ tooltipMainAction.label }}</span>
            <b-tag
              v-for="(chip, index) in tooltipMainAction.chips"
              :key="index"
              type="is-light"
              class="ml-1"
            >
              <b-icon
                v-if="chip.icon"
                :icon="chip.icon"
                size="is-small"
              />
              <span v-else>{{ chip.label }}</span>
            </b-tag>
          </div>
        </template>
        <b-button
          :label="sendButtonMainAction.label"
          :loading="isLoading"
          type="is-primary"
          :disabled="sendButtonMainAction.disabled ? true : false"
          @click="sendButtonMainAction.callback(sendButtonMainAction.event)"
        />
      </b-tooltip>
    </p>
    <p
      v-if="!isPrivateNote"
      class="control"
    >
      <b-dropdown
        :triggers="['hover']"
        position="is-top-left"
        :mobile-modal="false"
        :disabled="!sendButtonSecondaryActions.length || isLoading"
      >
        <template #trigger>
          <b-button
            type="is-primary"
            icon-pack="ubu"
            icon-left="ubu-arrow-down"
          />
        </template>

        <template v-for="(action, index) in sendButtonSecondaryActions">
          <UbuTextareaEmojisDropdownItem
            v-if="action.isEmojis"
            :key="index"
            :label="action.label"
            :tooltip-secondary-actions="tooltipSecondaryActions"
            @selectEmoji="action.callback($event)"
          />
          <b-dropdown-item
            v-else
            :key="index"
            class="multibar"
            @click="action.callback(action.event)"
          >
            {{ action.label }}
            <div class="ml-1">
              <b-tag
                v-for="(chip, indexChip) in tooltipSecondaryActions.chips"
                :key="indexChip"
                type="is-light"
                class="ml-1"
              >
                <b-icon
                  v-if="chip.icon"
                  :icon="chip.icon"
                  size="is-small"
                />
                <span v-else>{{ chip.label }}</span>
              </b-tag>
            </div>
          </b-dropdown-item>
        </template>
      </b-dropdown>
    </p>
  </b-field>
</template>

<script>
/* eslint-disable max-len */

import TheMainTextareaMixin from '../_mixins/TheMainTextarea.mixin';

export default {
  name: 'TheTextareaSendButton',
  mixins: [TheMainTextareaMixin],
  model: {
    prop: 'message',
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    isPrivateNote: {
      type: Boolean,
      default: false,
    },
    file: {
      type: [File, Object],
      default: null,
    },
    fileInfo: {
      type: Object,
      default: null,
    },
  },
};
</script>
