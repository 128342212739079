var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{class:['banner', {
      'img-overlay': _vm.hasBanner === 'overlay',
      'img-mentions': _vm.hasBanner === 'mentions',
      'img-sendHooks': _vm.hasBanner === 'sendHooks',
      'img-shopify': _vm.hasBanner === 'shopify',
      'img-audit': _vm.hasBanner === 'audit',
      'img-top': _vm.hasPosition === 'top',
      'img-center': _vm.hasPosition === 'center',
      'img-bottom': _vm.hasPosition === 'bottom',
    }]},[_vm._t("content")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }