import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheAddToLabel from './smarts/TheAddToLabel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheAddToLabel);
  },
};

use(Plugin);

export default Plugin;

export {
  TheAddToLabel,
};
