<template>
  <section class="ubuWizardView ubuSidePanel ubuPanel-xl">
    <div class="multibar">
      <b-button
        class="end is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
        @click="closePanel()"
      />
    </div>

    <header>
      <slot name="header" />
    </header>

    <div class="">
      <slot name="content" />
    </div>
  </section>
</template>

<script>
import NavigationMixin from '@dailyplanet/mixins/NavigationMixin';

export default {
  name: 'TheSimplePanel',
  mixins: [NavigationMixin],
  props: {
    closingWay: {
      type: String,
      default: 'route',
    },
  },
  methods: {
    closePanel() {
      switch (this.closingWay) {
        case 'emit': return this.$emit('close');
        case 'route': return this.goToPreviousPath();
        default: return this.goToPreviousPath();
      }
    },
  },
};
</script>
