/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../engine';

const target = 'clark';

const channel = {
  post: ({ payload, httpQuery }) => builder({ url: '/api/channel', method: 'POST', payload, httpQuery, target }),
  thread: {
    // report: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.cid}/thread`, method: 'REPORT', payload, httpQuery, target }),
    // get: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.cid}/thread/${payload.tid}`, method: 'GET', payload, httpQuery, target }),
    message: {
      report: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.cid}/thread/${payload.tid}/message`, method: 'REPORT', payload, httpQuery, target }),
      post: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.cid}/thread/${payload.tid}/message/`, method: 'POST', payload, httpQuery, target }),
      loadMore: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.cid}/thread/${payload.tid}/loadMoreMessages`, method: 'POST', payload, httpQuery, target }),
      like: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.cid}/thread/${payload.tid}/react/${payload.mid}`, method: 'POST', payload, httpQuery, target }),
      imageMetadata: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.cid}/thread/${payload.tid}/imageMetadata`, method: 'POST', payload, httpQuery, target }),
      // post: ({ payload, httpQuery }) => builder({ url: `/api/instagram/${payload.cid}/message/${payload.tid}/`, method: 'POST', payload, httpQuery, target }),
    },
  },
  connectPrivate: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.cid}/connectPrivate`, method: 'POST', payload, httpQuery, target }),
  },
  tryConnectPrivate: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.cid}/tryConnectPrivate`, method: 'POST', payload, httpQuery, target }),
  },
  conversation: {
    post: ({ payload, httpQuery }) => builder({ url: `/api/manageChannel/${payload.cid}/fetchConversations`, method: 'POST', payload, httpQuery, target }),
  },
};

export default channel;
