import affiliationCode from './affiliationCode.store';
import affiliationCodeFolder from './affiliationCodeFolder.store';
import affiliationReward from './affiliationReward.store';

import discountCode from './discountCode.store';

import manageShopifyShop from './manageShopifyShop.store';
import manageShop from './manageShop.store';
import manageWebhook from './manageWebhook.store';

import order from './order.store';

import pingShop from './pingShop.store';

import shop from './shop.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    affiliationCode,
    affiliationCodeFolder,
    affiliationReward,

    discountCode,

    manageShopifyShop,
    manageShop,
    manageWebhook,

    order,

    pingShop,

    shop,
  },
};
