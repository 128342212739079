<template>
  <div class="multibar row-vcentered">
    <b-icon
      :class="`start has-text-color-${memberGroup.color}`"
      pack="ubu"
      :icon="memberGroup.icon"
    />

    <span class="start text-truncate">{{ memberGroup.name }}</span>
  </div>
</template>

<script>
export default {
  name: 'UbuDropdownMemberGroupRow',
  props: {
    memberGroup: {
      type: Object,
      required: true,
    },
  },
};
</script>
