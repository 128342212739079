<template>
  <UbuVectorIcon
    :icon="{
      pack: 'ubu',
      icon: `ubu-social-youtube`,
      iconPaths: 2,
      fontSize: fontSize
    }"
  />
</template>

<script>
export default {
  name: 'UbuIconYoutube',
  props: {
    fontSize: {
      type: String,
      default: '20px',
    },
  },
};
</script>
