import { dispatchAndForward, genericErrorHandler, genericThenHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {

  },
  actions: {
    favMentions({ dispatch, rootGetters }, { payload, httpQuery, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiPublication/favorite/create',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'favorite mentions',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .then(() => dispatch('ApiTribe/mention/UP_fav', { pids: payload.pids, shouldFav: true }, { root: true }))
        .catch(genericErrorHandler(action, { message: 'Unable to favorite mentions.', indefinite: true }));
    },

    unfavMention({ dispatch, rootGetters }, { payload, httpQuery, mutations }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      const action = {
        dispatch,
        target: 'ApiPublication/favorite/delete',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery },
        textNotifier: 'unfavorite mention',
      };

      return dispatchAndForward(action)
        .then(genericThenHandler(mutations, dispatch))
        .then(() => dispatch('ApiTribe/mention/UP_fav', { pids: [payload.pid], shouldFav: false }, { root: true }))
        .catch(genericErrorHandler(action, { message: 'Unable to unfavorite mention.', indefinite: true }));
    },
  },
};
