<!-- eslint-disable max-len -->
<template>
  <UbuDropdown
    :scrollable="true"
    :is-disabled="isDisabled"
    :class="`is-secondary ${isDisabled ? 'is-disabled' : ''}`"
  >
    <template #button>
      <div class="ubuChip">
        <b-icon
          v-if="mappedStatusList[activity.status].icon"
          pack="ubu"
          :class="`has-text-color-${isEnded ? '2' : '5'}`"
          :icon="mappedStatusList[activity.status].icon"
        />
        <span :class="`has-text-color-${isEnded ? '2' : '5'}`">
          {{ mappedStatusList[activity.status].label }}
        </span>
      </div>
    </template>

    <template #content>
      <b-dropdown-item
        v-for="item in allowedStatusToGo"
        :key="item.status"
        aria-role="listitem"
        :class="['is-flex-direction-row is-align-items-center', { 'is-active': item.status === activity.status }]"
        @click="updateStatus(item.status)"
      >
        <b-icon
          pack="ubu"
          :icon="item.icon"
        />
        <span>{{ item.label }}</span>
      </b-dropdown-item>
    </template>
  </UbuDropdown>
</template>

<script>
/* eslint-disable max-len */

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheActivityStatusPopover',
  model: {
    prop: 'activity',
  },
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      _activityStatusListFiltered: 'TheActivityStatusPopover/activityStatusListFiltered',
    }),
    isEnded() {
      return !!this.activity.endedOn;
    },
    isDisabled() {
      return ['UNSCHEDULED', 'SCHEDULED', 'CANCELED', 'DELIVERED'].includes(this.activity.status) || this.isEnded;
    },
    mappedStatusList() {
      return this._activityStatusListFiltered.arrayMapper('status');
    },
    allowedStatusToGo() {
      const unusableStatus = ['UNSCHEDULED', 'SCHEDULED', 'CANCELED', 'DELIVERED'];
      return this._activityStatusListFiltered.filter(({ status }) => !unusableStatus.includes(status));
    },
  },
  methods: {
    ...mapActions({
      _bulkChangeStatus: 'TheActivityStatusPopover/bulkChangeStatus',
    }),

    updateStatus(status) {
      if (status === this.activity.status) return undefined;

      return this._bulkChangeStatus({
        payload: { cid: this.activity.campaignCid, aids: [this.activity.aid], status },
        mutations: [{
          target: 'ApiContact/contactPlatformOverview/UP_updateCampaignActivity',
          payload: {
            cpids: [this.activity.contactPlatformCpid],
            status,
          },
        }],
      });
    },
  },
};
</script>
