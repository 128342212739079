import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheAddToTribe from './smarts/TheAddToTribe';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheAddToTribe);
  },
};

use(Plugin);

export default Plugin;

export {
  TheAddToTribe,
};
