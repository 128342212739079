/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {
    selectedCps: [],
    newBulkAction: {},
    willBeAddedToCrmList: [],
  },

  mutations: {
    SET_SELECTED_CONTACT_PLATFORMS(state, newData) { state.selectedCps = newData; },
    SET_NEW_BULK_ACTION(state, newData) { state.newBulkAction = newData; },
    SET_willBeAddedToCrmList(state, newData) { state.willBeAddedToCrmList = newData; },

    RESET_STORE(state) {
      state.selectedCps = [];
      state.newBulkAction = {};
      state.willBeAddedToCrmList = [];
    },
  },

  getters: {
    selectedCps: (state) => state.selectedCps,
    newBulkAction: (state) => state.newBulkAction,
    willBeAddedToCrmList: (state) => state.willBeAddedToCrmList,

    tabItemsForTheCrmBulk: () => ({
      'root.addToCrmList': {
        tab: 'root.addToCrmList',
        title: 'Select a list',
        subtext: 'Add to list',
      },
      'root.addToCrmList.alreadyInCrmList': {
        tab: 'root.addToCrmList.alreadyInCrmList',
        title: 'People already in list',
        subtext: 'Add to list',
      },
      'root.addToCrmList.alreadyInCrmList.toKeep': {
        tab: 'root.addToCrmList.alreadyInCrmList.toKeep',
        title: 'Who do you want to add ?',
        subtext: 'Add to list',
      },
    }),
  },

  actions: {
    UP_selectedCps({ commit }, newData) {
      console.log('🚀 ~ file: TheAddToCrmList.store.js ~ line 48 ~ UP_selectedCps ~ newData', newData);
      commit('SET_SELECTED_CONTACT_PLATFORMS', newData);
    },
    UP_resetStore({ commit }) {
      commit('RESET_STORE');
    },
    UP_newBulkAction({ commit, getters }, newBulkAction) {
      commit('SET_NEW_BULK_ACTION', { ...getters.newBulkAction, ...newBulkAction });
    },
    UP_willBeAddedToCrmList({ commit }, newData) {
      commit('SET_willBeAddedToCrmList', newData);
    },
  },
};
