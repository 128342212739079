<template>
  <section
    class="ubuSettingView start"
  >
    <div class="multibar">
      <h1 class="start title is-1">Users</h1>
    </div>

    <br>

    <b-field>
      <b-input
        v-model="inputSearch"
        placeholder="Search an user..."
        type="search"
        icon-pack="ubu"
        icon="ubu-search"
        icon-right="ubu-cross"
        icon-right-clickable
        @icon-right-click="inputSearch = null"
      />
    </b-field>

    <br>

    <DevDetails :cnt="{name: 'detailledUserList', obj: detailledUserList}" />
    <DevDetails :cnt="{name: 'detailledUserFiltered', obj: detailledUserFiltered}" />
    <TheTable
      v-if="detailledUserFiltered.length"
      :lead-list="(detailledUserFiltered)"
      :columns="Object.values(columns)"
      :loading="loading"
      :total="detailledUserFiltered.length"
      :page="page + 1"
      :per-page.sync="perPage"
      :sort-field="sortField"
      :sort-order="sortOrder"
      :default-sort-order="defaultSortOrder"
      :selected.sync="localSelected"
      :checked-rows.sync="checkedRows"
      :detail-key="'uid'"
      :checkable="false"
      :sticky-header="false"
      @update:checkedRows="changeCheckedRows($event)"
      @update:selected="localSelected = $event"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from '@dailyplanet/utils/helpers';
import usersAllUsersColumnsMixins from '../$mixins/usersAllUsersColumnsMixins.columns.mixins';

export default {
  name: 'TheUsersAllUsers',
  mixins: [usersAllUsersColumnsMixins],
  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      page: 0,
      perPage: 50,
      sortField: 'aid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      checkedRows: [],
      search: '',
      inputSearch: '',
    };
  },
  computed: {
    ...mapGetters({
      detailledUserList: 'TheUsers/detailledUserList',
    }),
    localSelected: {
      get() { return this.selected; },
      set(newValue) { this.$emit('update:selected', newValue); },
    },
    detailledUserFiltered() {
      const query = Object.values(this.detailledUserList).reduce((acc, {
        uid,
        isUbu,
        firstName,
        lastName,
        phone,
        lockedOn,
        userRoleUrid,
        authenticates,
      }) => {
        acc.push({
          uid,
          isUbu,
          lockedOn: !lockedOn,
          firstName,
          lastName,
          phone,
          role: userRoleUrid,
          email: authenticates.find(({ revokedOn }) => revokedOn === null).passName,
        });
        return acc;
      }, []);

      if (!this.search) return query;

      return query.reduce((acc, user) => {
        if (user.firstName.toLowerCase().includes(this.search.toLowerCase())
          || user.lastName.toLowerCase().includes(this.search.toLowerCase())
          || user.email.toLowerCase().includes(this.search.toLowerCase())
        ) {
          acc.push(user);
        }
        return acc;
      }, []);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 500),
  },
  mounted() {
    this.$emit('reset');
  },
};
</script>
