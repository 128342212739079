/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    instagramAccountImpressionsList: [],
  },
  mutations: {
    SET_INTAGRAM_ACCOUNT_IMPRESSIONS_LIST(state, newData) { state.instagramAccountImpressionsList = newData; },
  },
  getters: {
    getterInstagramAccountImpressionsList: (state) => state.instagramAccountImpressionsList,
  },
  actions: {
    report({ commit, rootGetters }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PRIVATE_ANALYTICS.REPORT')) return undefined;

      return clark.api.channelGroup.instagramAccountImpressions.report({ payload, httpQuery })
        .then(({ __response__: impressions }) => {
          commit('SET_INTAGRAM_ACCOUNT_IMPRESSIONS_LIST', impressions);
        });
    },
  },
};
