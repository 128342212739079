<template>
  <section id="TheWorkspace">
    <aside class="ubuMenu is-sub-menu">
      <div
        v-for="(item) in routes"
        :key="item.title"
        class="start"
      >
        <div
          v-if="!item.adminOnly"
          class="menu-item-list"
        >
          <p class="menu-item-title disclaimer is-3 mt-4 mb-4">
            {{ item.title }}
          </p>

          <UbuMenuItem
            v-for="(route) in item.items"
            :key="route.text"
            :item="route"
          />
        </div>

        <template v-else>
          <div
            v-if="isAdminOnAccount"
            class="menu-item-list"
          >
            <p class="menu-item-title disclaimer is-3 mt-4 mb-4">
              {{ item.title }}
            </p>

            <UbuMenuItem
              v-for="(route) in item.items"
              :key="route.text"
              :item="route"
            />
          </div>
        </template>
      </div>
    </aside>

    <div class="has-coreview-spaced">
      <router-view />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheSetting',
  computed: {
    ...mapGetters({
      isPrivateDisconnected: 'TheAuthenticate/isPrivateDisconnected',
      accountFeatures: 'TheAuthenticate/accountFeatures',
      isAdminOnAccount: 'TheAuthenticateFirstConnexion/isAdminOnAccount',
    }),
    routes() {
      return [
        {
          title: 'USER SETTINGS',
          ordinal: 0,
          items: [
            {
              text: 'Profile',
              path: '/setting/profile',
            },
            {
              text: 'Theme',
              path: '/setting/theme',
            },
          ],
        },
        {
          title: 'WORKSPACE SETTINGS',
          ordinal: 1,
          items: [
            {
              text: 'Members',
              path: '/setting/member',
            },
            {
              text: 'Channels',
              path: '/setting/channel',
            },
            {
              text: 'Extensions',
              path: '/setting/extension',
              hasAlert: this.accountFeatures('UBU_META_EXTENSION') && this.isPrivateDisconnected,
              // disclaimer: 'Beta',
            },
            {
              text: 'Tags',
              path: '/setting/tag',
            },
            {
              text: 'Contact Labels',
              path: '/setting/label',
            },
            {
              text: 'Templates',
              path: '/setting/template',
            },
            {
              text: 'Affiliations',
              path: '/setting/affiliation',
              // disclaimer: 'Beta',
            },
            {
              text: 'Download task',
              path: '/setting/downloadTask',
              // disclaimer: 'Beta',
            },
            // {
            //   text: 'Crew',
            //   path: '/setting/crew',
            //   disclaimer: 'Beta',
            // },
            // @TODO TRIBE de-commenter quand on remet la feature
            // {
            //   text: 'Tribe',
            //   path: '/setting/tribe',
            //   new: true,
            // },
          ],
        },
        {
          title: 'ORGANISATION SETTINGS',
          adminOnly: true,
          ordinal: 2,
          items: [
            {
              text: 'Organization',
              path: '/setting/organization',
            },
            // {
            //   text: 'Billing',
            //   path: '/setting/billing',
            // },
          ],
        },
      ];
    },
  },
};
</script>
