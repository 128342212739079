import moment from 'moment';

import { dispatchAndForward, genericErrorHandler } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  getters: {
    downloadTaskList: (_state, _getters, _rootState, rootGetters) => {
      const { 'DownloadTask/getterDownloadTaskList': downloadTaskList } = rootGetters;

      return downloadTaskList;
    },
    downloadTaskTotal: (_state, _getters, _rootState, rootGetters) => {
      const { 'DownloadTask/getterDownloadTaskTotal': total } = rootGetters;

      return total;
    },

    downloadTask: (_state, _getters, _rootState, rootGetters) => {
      const { 'DownloadTask/getterDownloadTask': downloadTask } = rootGetters;

      return downloadTask;
    },
  },
  actions: {
    reportDownloadTaskList({ dispatch, rootGetters }, { httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const { 'TheAuthenticate/myChannelInstagram': myChannelInstagram } = rootGetters;

      const action = {
        dispatch,
        target: 'DownloadTask/report',
        context: {
          payload: { cgid: currentChannelGroup.cgid, channelCid: myChannelInstagram.cid },
          httpQuery,
        },
        textNotifier: 'report downloadTask list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to get downloadTask list.', indefinite: true }));
    },

    createDownloadTask({ dispatch, rootGetters }, { payload, httpQuery = {} }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const { 'TheAuthenticate/myChannelInstagram': myChannelInstagram } = rootGetters;

      const action = {
        dispatch,
        target: 'DownloadTask/create',
        context: {
          payload: {
            ...payload,
            cgid: currentChannelGroup.cgid,
            channelCid: myChannelInstagram.cid,
            createdOn: moment().format(),
            updatedOn: moment().format(),

          },
          httpQuery,
        },
        textNotifier: 'create downloadTask list',
      };

      return dispatchAndForward(action)
        .catch(genericErrorHandler(action, { message: 'Unable to create a download task.', indefinite: true }));
    },
  },
};
