// import Vue from 'vue';
import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheUploadFromCSV from './TheUploadFromCSV';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheUploadFromCSV);
  },
};

use(Plugin);

export default Plugin;

export {
  TheUploadFromCSV,
};
