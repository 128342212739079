export default {
  data() {
    return {
      columns: {
        sid: {
          col: 'sid', label: 'sid', visible: true, isSortable: false, cmp: 'flat',
        },
        channelGroupCgid: {
          col: 'channelGroupCgid', label: 'Channel group cgid', visible: true, isSortable: false, cmp: 'flat',
        },
        baseUrl: {
          col: 'baseUrl', label: 'Website url', visible: true, isSortable: false, cmp: 'flat',
        },
        extrefId: {
          col: 'extrefId', label: 'Shop extref id', visible: true, isSortable: false, cmp: 'flat',
        },
        type: {
          col: 'type', label: 'Type', visible: true, isSortable: false, cmp: 'flat',
        },
        // apiVersion: {
        //   col: 'apiVersion', label: 'Api version', visible: true, isSortable: false, cmp: 'flat',
        // },
        isConnected: {
          col: 'isConnected', label: 'Connected', visible: true, isSortable: false, cmp: 'tribe',
        },
        action: {
          col: 'action', label: 'action', visible: true, isSortable: false, cmp: 'openContactPanel', sticky: true,
        },
      },
    };
  },
};
