<template>
  <section id="TheInfluenceAudit">
    <h1 class="title is-1">Audit</h1>

    <br>

    <b-field grouped>
      <UbuSearchbar
        v-model="username"
        placeholder="Search influencer..."
        @clear="username = ''"
      />

      <b-button
        label="Search"
        type="is-primary"
        :disabled="!username"
        :loading="isLoading"
        @click="reportFavikon()"
      />
    </b-field>

    <div class="audit-content columns">
      <div class="column is-half">
        <h1 class="title is-1 has-text-on-img">
          Get deeper insights into your favourite influencers.
        </h1>

        <ul class="has-text-on-img">
          <li class="text is-3">
            Search the instagram username you want a report of and click “Generate report”.
          </li>

          <li class="text is-3">
            Targeted account must have more than 10K followers and not be a private account.
          </li>

          <li class="text is-3">
            Loading can take ≈30sec
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheInfluenceAudit',
  data() {
    return {
      username: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      accountFeatures: 'TheInfluenceAudit/accountFeatures',
      _currentChannelGroup: 'TheContactPlatformPanel/currentChannelGroup',
    }),
  },
  mounted() {
    this.emitSegmentEvent({ event: 'clickOnAudit' });
  },
  methods: {
    ...mapActions({
      emitSegmentEvent: 'TheInfluenceAudit/emitSegmentEvent',
      _createReport: 'TheInfluenceAudit/createReport',
      _tryFetchReportByUsername: 'TheInfluenceAudit/tryFetchReportByUsername',
    }),

    reportFavikon() {
      if (!this.accountFeatures('CRM')) {
        this.emitSegmentEvent({ event: 'favikonNoFeature' });
        this.$intercom.showNewMessage('Hi! I would like to activate the CRM module! 🙌');
        this.username = '';

        return undefined;
      }

      this.isLoading = true;
      let parsedUsername = this.username.trim();

      /**
       * must parse input data to accept '@username' or 'https://www.instagram.com/username/'
       * final data expected => 'username
       */
      if (parsedUsername.startsWith('@')) parsedUsername = parsedUsername.replace('@', '');

      if (parsedUsername.match('https://www.instagram.com/')) {
        const url = new URL(parsedUsername);
        parsedUsername = url.pathname.replaceAll('/', '');
      }

      return Promise.resolve()
        .then(() => this._tryFetchReportByUsername({ payload: { username: parsedUsername } }))
        .then((res) => {
          // report is still stored from iqdata
          if (res?.id) return window.open(`${process.env.VUE_APP_CLARK_BASE_URL}/dl/channelGroup/${this._currentChannelGroup.cgid}/iqdataReport/${res.id}`);

          // need to create a new report
          return this.createReportAndRedirectOnPDF(parsedUsername);
        })
        .then(() => { this.isLoading = false; })
        .catch(() => { this.isLoading = false; });
    },

    createReportAndRedirectOnPDF(username) {
      return this._createReport({ payload: { username } })
        .then((res) => {
          this.isLoading = false;
          window.open(`${process.env.VUE_APP_CLARK_BASE_URL}/dl/channelGroup/${this._currentChannelGroup.cgid}/iqdataReport/${res.extrefId}`);
        });
    },
  },
};
</script>
