<template>
  <div class="card-content">
    <b-field
      label="select theme"
      horizontal
    >
      <b-radio-button
        v-model="radioButton"
        native-value="AUTO"
        type="is-primary"
      >
        <span>Auto</span>
      </b-radio-button>

      <b-radio-button
        v-model="radioButton"
        native-value="DARK"
        type="is-primary"
      >
        <span>Dark</span>
      </b-radio-button>

      <b-radio-button
        v-model="radioButton"
        native-value="LIGHT"
        type="is-primary"
      >
        <span>Classic</span>
      </b-radio-button>
    </b-field>
  </div>
</template>

<script>
import ThemeMixin from '@dailyplanet/mixins/ThemeMixin';

export default {
  name: 'TheSettingTheme',
  mixins: [ThemeMixin],
  computed: {
    radioButton: {
      get() { return this.theme; },
      set(newValue) { this.theme = newValue; },
    },
  },
};
</script>
