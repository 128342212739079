/* eslint-disable max-len */
import { getters, actions } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        {
          path: '/monitoring/tasks',
          text: 'Tasks',
          icon: 'ubu-list',
        },
        {
          path: '/monitoring/protolists',
          text: 'Protolists',
          icon: 'ubu-contact',
        },
      ],
    },
  },
  getters: {
    navigation: (state) => state.navigation,
    ...getters.monitor,
    ...getters.protoList,
  },
  actions: {
    ...actions.monitor,
    ...actions.protoList,
    ...actions.activity,
    ...actions.bulkActivity,
    ...actions.campaign,

    UP_task({ dispatch }, task) {
      return dispatch('Monitor/UP_task', task, { root: true });
    },
  },
};
