/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { hasAccessToChannel } from '@dailyplanet/data-stores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    connect({ commit, rootGetters }, { payload, httpQuery }) {
      if (!hasAccessToChannel(rootGetters, payload.cid)) return undefined;

      return clark.api.channel.connectPrivate.post({ payload, httpQuery })
        .then((channel) => {
          const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
          const { 'Account/getterAccount': account } = rootGetters;

          const channelGroupIndex = account.channelGroupList.findIndex(({ cgid }) => cgid === currentChannelGroup.cgid);
          const instaChannelIndex = account.channelGroupList[channelGroupIndex].channelList.findIndex(({ platformName }) => platformName === 'INSTAGRAM');

          account.channelGroupList[channelGroupIndex].channelList[instaChannelIndex].token = channel.token;

          commit('Account/SET_ACCOUNT', account, { root: true });
          commit('Account/SET_IS_DISCONNECTED_TO_PRIVATE', false, { root: true });

          return channel;
        });
    },
    tryConnect({ commit, rootGetters }, { payload, httpQuery }) {
      if (!hasAccessToChannel(rootGetters, payload.cid)) return undefined;

      return clark.api.channel.tryConnectPrivate.post({ payload, httpQuery })
        .then((channel) => {
          const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
          const { 'Account/getterAccount': account } = rootGetters;

          const channelGroupIndex = account.channelGroupList.findIndex(({ cgid }) => cgid === currentChannelGroup.cgid);
          const instaChannelIndex = account.channelGroupList[channelGroupIndex].channelList.findIndex(({ platformName }) => platformName === 'INSTAGRAM');

          account.channelGroupList[channelGroupIndex].channelList[instaChannelIndex].token = channel.token;

          commit('Account/SET_ACCOUNT', account, { root: true });
          commit('Account/SET_IS_DISCONNECTED_TO_PRIVATE', false, { root: true });

          return channel;
        });
    },
  },
};
