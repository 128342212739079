import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    cityList: (_state, _getters, _rootState, rootGetters) => {
      const { 'City/getterCityList': cityList } = rootGetters;

      return cityList;
    },
  },
  actions: {
    reportCityList({ dispatch }, { httpQuery }) {
      console.log('🚀 ~ file: city.js ~ line 14 ~ reportCityList ~ httpQuery', httpQuery);
      const action = {
        dispatch,
        target: 'City/report',
        context: { httpQuery },
        textNotifier: 'report city list',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to get city list.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },
  },
};
