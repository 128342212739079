<template>
  <div>
    <DevDetails :cnt="{name: 'gridList', obj: gridList}" />
    <div
      v-for="(grid, indexGrid) in gridList"
      :key="indexGrid"
      :class="`analyticsGrid col-${grid.nbCol}`"
    >
      <UbuAnalyticCard
        v-for="(analytic, index) in grid.items"
        :key="index"
        :name="analytic.name"
        :value="analytic.value === 'N/A' ? analytic.value : Number(analytic.value)"
        :icon="analytic.icon"
        :color="analytic.color"
        :symbol="analytic.symbol"
        :growth-percent="analytic.growthPercent"
        :last-periodvalue="Number(analytic.lastPeriodvalue)"
        :loading="analytic.loading"
        :compared-percent-value="analytic.comparedPercentValue"
        :compared-value-tooltip="analytic.comparedValueTooltip"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuAnalyticsGrid',
  props: {
    analytics: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
  computed: {
    gridList() {
      return this.analytics.reduce((acc, card, index, analytics) => {
        const previousCard = analytics[index - 1];
        const accLength = Object.keys(acc).length;
        if (!previousCard || previousCard.size !== card.size) {
          acc[accLength + 1] = {
            nbCol: Math.round(100 / card.size),
            items: [card],
          };
        } else if (acc[accLength]) {
          acc[accLength] = {
            ...acc[accLength],
            items: [...acc[accLength].items, card],
          };
        }
        return acc;
      }, {});
    },
  },
  methods: {
  },
};
</script>
