<template>
  <header class="mainPanelHeader multibar row-vcentered">
    <h1 class="title is-2 mr-2 start">Community</h1>

    <h2 class="text is-3 has-text-secondary start">{{ totalPeople }} people matching</h2>

    <div class="buttons end">
      <b-button
        label="Manage lists"
        icon-pack="ubu"
        icon-left="ubu-edit"
        type="is-secondary"
        @click="$emit('openCRMListManager')"
      />

      <b-button
        label="Add people"
        icon-pack="ubu"
        icon-left="ubu-add"
        type="is-primary"
        @click="$emit('openAddPeople')"
      />
    </div>
  </header>
</template>

<script>
export default {
  name: 'UbuCRMHeader',
  props: {
    totalPeople: {
      type: Number,
      default: 0,
    },
  },
};
</script>
