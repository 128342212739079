export default {
  data() {
    return {
      columns: {
        wid: {
          col: 'wid', label: 'wid', visible: true, isSortable: false, cmp: 'flat',
        },
        topic: {
          col: 'topic', label: 'Topic', visible: true, isSortable: false, cmp: 'flat',
        },
        callbackUrl: {
          col: 'callbackUrl', label: 'Callback URL', visible: true, isSortable: false, cmp: 'flat',
        },
        createdAt: {
          col: 'createdAt', label: 'Created at', visible: true, isSortable: false, cmp: 'date',
        },
        action: {
          col: 'action', label: 'Action', visible: true, isSortable: false, event: 'deleteWebhook', type: 'is-danger is-tertiary', buttonType: 'tertiary', icon: 'ubu-trash',
        },
      },
    };
  },
};
