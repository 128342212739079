<template>
  <b-tooltip
    position="is-bottom"
    label="Assign to a member"
    size="small"
  >
    <UbuDropdown
      :close-on-click="false"
      :scrollable="true"
      @open="isOpen = true"
      @close="reset(); isOpen = false;"
    >
      <template v-slot:button>
        <b-button type="is-secondary">
          <span v-if="assignedUser">
            {{ assignedUser }}
          </span>

          <b-icon
            v-else
            pack="ubu"
            :icon="icon"
          />
        </b-button>
      </template>

      <template
        v-if="isOpen"
        v-slot:search
      >
        <b-dropdown-item custom>
          <UbuSearchbar
            v-model="search"
            @clear="reset()"
            @input="updateFilter($event)"
          />
        </b-dropdown-item>
      </template>

      <template v-slot:content>
        <b-tabs
          v-model="activeTab"
          class="no-header paddingless"
        >
          <b-tab-item value="root">
            <template v-if="customsSplitByAssignment.assigneds.length">
              <b-dropdown-item
                v-for="user in customsSplitByAssignment.assigneds"
                :key="user[keyName]"
                @click="unassignUser(user)"
              >
                <UbuDropdownUserRow
                  :user="user"
                  :is-checked="true"
                />
              </b-dropdown-item>

              <b-dropdown-item separator />
            </template>

            <b-dropdown-item
              v-for="user in customsSplitByAssignment.unassigneds"
              :key="user[keyName]"
              @click="assignUser(user)"
            >
              <UbuDropdownUserRow
                :user="user"
                :is-checked="false"
              />
            </b-dropdown-item>

            <b-dropdown-item
              v-if="!customsSplitByAssignment.assigneds.length
                && !customsSplitByAssignment.unassigneds.length"
              custom
              class="has-text-secondary"
            >
              No item with this name in this workspace...
            </b-dropdown-item>
          </b-tab-item>
        </b-tabs>
      </template>
    </UbuDropdown>
  </b-tooltip>
</template>

<script>
import pChain from '@dailyplanet/utils/PromiseChain';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheAppointDropdown',
  props: {
    payload: {
      type: Array,
      default: () => ([
        // {
        // tid,
        // userUid,
        // },
      ]),
    },
  },
  data() {
    return {
      activeTab: 'root',
      search: '',
      keyName: 'userUid',
      icon: 'ubu-assign',

      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      userList: 'TheInbox/userList',
      windowWidth: 'TheMenu/getterWindowWidth',
      accountFeatures: 'TheInbox/accountFeatures',
      currentChannelGroup: 'Authenticate/getterCurrentChannelGroup',
    }),
    mappedUsers() {
      const { length } = this.payload;

      return Object.values(this.userList).reduce((acc, { userDetail }) => {
        if (this.payload.filter((item) => userDetail.userUid === item.userUid).length === length) {
          acc[userDetail.userUid] = true;
        }

        return acc;
      }, {});
    },

    customsSplitByAssignment() {
      const search = this.search.toLowerCase();
      return Object.values(this.userList).reduce((acc, { userDetail }) => {
        const firstName = (userDetail.firstName || '').toLowerCase();
        const lastName = (userDetail.lastName || '').toLowerCase();
        if (search && !firstName.includes(search) && !lastName.includes(search)) return acc;

        if (this.mappedUsers[userDetail.userUid]) { acc.assigneds.push(userDetail); return acc; }

        acc.unassigneds.push(userDetail);

        return acc;
      }, {
        assigneds: [],
        unassigneds: [],
      });
    },

    assignedUser() {
      const { assigneds } = this.customsSplitByAssignment;
      if (!assigneds.length) return null;
      const [{ firstName, lastName }] = assigneds;
      return `${firstName} ${lastName}`;
    },

  },
  methods: {
    ...mapActions({
      _setThreadAppoint: 'TheInbox/_setThreadAppoint',
      _unsetThreadAppoint: 'TheInbox/_unsetThreadAppoint',
    }),
    updateFilter(search) {
      if (!search) this.reset();
      else this.search = search;
    },

    assignUser(item) {
      if (!this.accountFeatures('INBOX')) {
        this.segmentTrack('assignThreadNoFeature');
        this.$intercom.showNewMessage('Hi! I would like to activate the INBOX module! 🙌');

        return undefined;
      }

      return pChain(
        this.payload.map(({ tid, cpid }) => this._setThreadAppoint({
          payload: { [this.keyName]: item[this.keyName], tid },
          mutations: [
            {
              target: 'ApiContact/contactPlatformOverview/UP_upsertAppoint',
              payload: {
                key: 'hasUser',
                value: {
                  threadTid: tid,
                  userUid: item[this.keyName],
                  channelGroupCgid: this.currentChannelGroup.cgid,
                },
                cpids: [cpid],
              },
            },
          ],
        })),
      );
    },

    unassignUser(item) {
      return pChain(this.payload.map(({ tid, cpid }) => this._unsetThreadAppoint({
        payload: { [this.keyName]: item[this.keyName], tid },
        mutations: [
          {
            target: 'ApiContact/contactPlatformOverview/UP_upsertAppoint',
            payload: {
              key: 'hasUser',
              value: null,
              cpids: [cpid],
            },
          },
        ],
      })));
    },

    reset() {
      this.search = '';
      this.activeTab = 'root';
      // this.isOpen = false;
    },
  },
};
</script>
