<template>
  <div
    ref="theTextarea"
    :class="['ubuTextarea', { 'is-note': isPrivateNote, 'is-focus': isTextareaFocus }]"
  >
    <UbuTextareaTop
      :is-private-note="isPrivateNote"
      :file="file"
      :template-list-filtered="templateListFiltered"
      :reach-mode="reachMode"
      @toggleNote="toggleNote($event)"
      @deleteFile="file = null"
      @selectTemplate="replaceTemplateTitle($event)"
    />

    <b-upload
      v-model="file"
      drag-drop
      expanded
      accept="image/png, image/jpeg, image/svg"
      :disabled="isPrivateNote || reachMode || platformName === 'FACEBOOK' || message.length > 0"
      @input="checkImageMetadata($event)"
    >
      <textarea-autosize
        ref="textarea"
        v-model="message"
        class="text is-3"
        :placeholder="placeholder"
        :disabled="file !== null"
        maxlength="1000"
        @keydown.native="keydownTextAreaHandler($event)"
        @keyup.space.native="fillTemplate()"
        @focus.native="isTextareaFocus = true"
        @blur.native="isTextareaFocus = false"
      />
    </b-upload>

    <p v-if="hasCrewLink" class="start has-text-primary mb-2">
      This link is unique, don't use it for another people
    </p>
    <div class="multibar row-vcentered">
      <UbuTextareaBottomTools
        v-if="!isPrivateNote && threadDetails"
        v-model="file"
        class="start"
        :thread-details="threadDetails"
        :dropdown-position="dropdownPosition"
        :reach-mode="reachMode"
        :template-list="templateList"
        :message="message"
        :account-features="accountFeatures"
        :platform-name="platformName"
        @insertText="insertText($event)"
        @insertTribeFormLink="insertTribeFormLink($event)"
        @openTemplatePanel="$emit('openTemplatePanel')"
        @translateText="translateText($event)"
        @revertTranslate="revertTranslate()"
        @checkImageMetadata="checkImageMetadata(file)"
        @hasPanelOpen="$emit('hasPanelOpen', $event)"
      />

      <p
        v-if="message.length >= 950"
        :class="['ubuTextarea_counter end text is-3', {
          'has-text-warning': message.length >= 975,
          'has-text-danger': message.length >= 990,
          hasReachedLimit: message.length === 1000,
        }]"
      >
        {{ 1000 - message.length }}
      </p>

      <TheTextareaSendButton
        ref="sendButton"
        v-model="message"
        class="end"
        :is-private-note="isPrivateNote"
        :search="search"
        :file="file"
        :file-info="fileInfo"
        @clearFile="file = null"
        @clearMessage="message = ''"
      />
      <!-- :is-loading="isLoading" -->
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive';
import TheMainTextareaMixin from './_mixins/TheMainTextarea.mixin';

export default {
  name: 'TheTextarea',
  directives: {
    autogrow: TextareaAutogrowDirective,
  },
  mixins: [TheMainTextareaMixin],
  model: {
    prop: 'height',
  },
  props: {
    height: {
      type: Number,
      default: 0,
    },
    threadDetails: {
      type: Object,
      default: null,
    },
    reachMode: {
      type: Boolean,
      default: false,
    },
    dropdownPosition: {
      type: Object,
      default: () => ({
        emoji: 'is-top-left',
        template: 'is-top-right',
        affiliations: 'is-top-right',
        translate: 'is-top-right',
      }),
    },
  },
  data() {
    return {
      isTextareaFocus: false,
      open: false,
      isPrivateNote: false,
      file: null,
      message: '',
      isCreateAffiliationPanelOpened: false,
      observer: null,
      downloadFileRequest: null,
      uploadFileRequest: null,
      textBeforeTranslate: null,
      fileInfo: {},
    };
  },
  computed: {
    ...mapGetters({
      templateList: 'TheTextarea/templateList',
      activityStatusList: 'TheTextarea/activityStatusList',
      isFocused: 'TheTextarea/getterIsTextareaFocused',
    }),

    /*
    This MUST only be used to update value of this.message.
    This computed override the localMessage of TheMainTextareaMixin.

    This is black magic, do not reproduce
    */
    localMessage: {
      get() { return this.message; },
      set(val) { this.message = val; },
    },

    isTagsInbox() {
      return !!this.$route.params.threadByTags;
    },

    isInfluence() {
      const { meta: { viewStoreNavigation } } = this.$route;
      if (viewStoreNavigation === 'TheInfluence') return true;
      return false;
    },

    isInboxCampaign() {
      const { meta: { viewStoreNavigation } } = this.$route;
      if (viewStoreNavigation === 'TheInbox' && this.$route.params.threadByCampaigns) return true;
      return false;
    },

    hasCrewLink() {
      return this.message.includes(`${process.env.VUE_APP_TALIA_BASE_URL}/signup/`);
    },

    placeholder() {
      if (this.isPrivateNote) {
        return 'Type a private note (only your team will see)...';
      }
      if (this.file === null) {
        return 'Start typing or enter ‘/’ to load your templates...';
      }

      return '';
    },

    templateListFiltered() {
      const indexOfLastSlash = this.message.lastIndexOf('/');
      if (indexOfLastSlash === -1) return null;
      const title = this.message.substring(indexOfLastSlash + 1);
      const templates = Object.values(this.templateList)
        .filter((t) => t.title.toLowerCase().startsWith(title.toLowerCase()));
      return templates.length ? templates : null;
    },

    platformName() {
      return this._cpo.thread.platformName;
    },
  },

  watch: {
    isFocused(isFocused) {
      if (isFocused) this.$refs.textarea.$el.focus();
    },

    file(file) {
      if (file === null) { this.fileInfo = {}; }
    },
  },

  mounted() {
    this.emitHeight();

    this.observer = new ResizeObserver((() => {
      this.emitHeight();
    }));
    this.observer.observe(this.$refs.theTextarea);
  },

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  methods: {
    ...mapActions({
      up_focusedElement: 'TheInbox/up_focusedElement',
      _translateText: 'TheTextarea/translateText',
      imageMetadata: 'TheInbox/imageMetadata',
    }),

    translateText(targetLang) {
      if (!this.message.length) return null;

      return this._translateText({ payload: { text: this.message, targetLang } })
        .then(({ response }) => {
          // stock original text if user want to revert translate
          this.textBeforeTranslate = this.message;
          this.message = response[0].text;
        });
    },

    revertTranslate() {
      if (!this.textBeforeTranslate) return;

      this.message = this.textBeforeTranslate;
    },

    toggleNote(event) {
      this.isPrivateNote = event;
      this.$refs.textarea.$el.focus();
    },

    insertText(text) {
      const { textarea } = this.$refs;
      const len = this.message.length;
      if (len + text.length > 1000) return;
      let pos = textarea.$el.selectionStart;
      if (pos === undefined) pos = 0;
      const before = this.message.substr(0, pos);
      const after = this.message.substr(pos, len);
      this.message = before + text + after;
      this.$nextTick(() => {
        textarea.$el.focus();
        textarea.$el.selectionStart = pos + text.length;
        textarea.$el.selectionEnd = pos + text.length;
      });
    },

    replaceTemplateTitle(template) {
      const indexOfLastSlash = this.message.lastIndexOf('/');
      const title = this.message.substring(indexOfLastSlash + 1);
      this.message = this.message.replace(`/${title}`, template.text);
    },

    emitHeight() {
      const height = this.$refs.theTextarea.clientHeight;
      this.$emit('update:height', height);
    },

    randomEmoji() {
      const emojis = ['😂', '😘', '😍', '🔥', '💘', '💪 ', '🚀'];
      return emojis[Math.floor(Math.random() * emojis.length)];
    },

    keydownTextAreaHandler(event) {
      const {
        ctrlIsPressed,
        enterIsPressed,
        iIsPressed,
        escapeIsPressed,
        shiftIsPressed,
      } = this.parseEvent(event);

      const ctrlEnterPressed = ctrlIsPressed && enterIsPressed;
      const ctrlShiftEnterPressed = ctrlEnterPressed && shiftIsPressed;
      const canSendAndDone = this.sendButtonSecondaryActions.find(({ type }) => type === 'sendAndDone');
      const canSendAndClose = this.sendButtonSecondaryActions.find(({ type }) => type === 'sendAndClose');
      const canSendEmojiAndDone = this.sendButtonSecondaryActions.find(({ type }) => type === 'sendEmojiAndDone');
      const canSendEmojiAndClose = this.sendButtonSecondaryActions.find(({ type }) => type === 'sendEmojiAndClose');

      if (escapeIsPressed) return this.up_focusedElement({ name: 'THREAD_LIST' });
      if (ctrlEnterPressed && !shiftIsPressed) return this.sendButtonMainAction.callback(this.sendButtonMainAction.event);
      if (ctrlIsPressed && iIsPressed) this.changeThreadStatus('THREAD_READ');
      if (!ctrlShiftEnterPressed) return undefined;
      if (ctrlEnterPressed && shiftIsPressed && canSendAndDone) return this.sendAndDone(this.message);
      if (ctrlEnterPressed && shiftIsPressed && canSendAndClose) return this.sendAndClose(this.message);
      if (ctrlEnterPressed && shiftIsPressed && canSendEmojiAndDone) return this.sendAndDone(this.randomEmoji());
      if (ctrlEnterPressed && shiftIsPressed && canSendEmojiAndClose) return this.sendAndClose(this.randomEmoji());
      return undefined;
    },

    fillTemplate() {
      Object.values(this.templateList).forEach((template) => {
        if (this.message.includes(`/${template.title}`)) {
          this.message = this.message.replace(`/${template.title}`, template.text);
        }
      });
    },

    checkImageMetadata(event) {
      this.file = null;
      const { size, type } = event;
      const { channelCid, tid } = this.$route.params;
      if (size && type) {
        let fileType = type.split('/')[1];
        if (fileType === 'jpeg') fileType = 'jpg';

        const acceptedFileTypes = ['jpg', 'png', 'bmp', 'ico'];
        const sizeLimit = 8000000;

        if (size <= sizeLimit && acceptedFileTypes.includes(fileType)) {
          this.imageMetadata({
            payload: {
              fileType, sizeInBytes: size, cid: channelCid, tid,
            },
          })
            .then((response) => {
              this.fileInfo.downloadFileRequest = response.response.downloadFileRequest;
              this.fileInfo.uploadFileRequest = response.response.uploadFileRequest;
              this.file = event;
            })
            .catch((error) => {
              console.log('ERROR CHECK IMAGE METADATA', error); // ???
            });
        }
      }
    },
  },
};
</script>
