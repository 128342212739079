import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheAddToCampaign from './smarts/TheAddToCampaign';
import TheAddToCampaignAlreadyInCampaign from './smarts/TheAddToCampaignAlreadyInCampaign';
import TheAddToCampaignReachMethod from './smarts/TheAddToCampaignReachMethod';
import TheAddToCampaignToSendMessage from './smarts/TheAddToCampaignToSendMessage';
import TheAddToCampaignMessage from './smarts/TheAddToCampaignMessage';
import TheAddToCampaignToScan from './smarts/TheAddToCampaignToScan';
import TheAddToCampaignSummary from './smarts/TheAddToCampaignSummary';

import TheNewAddToCampaign from './smarts/TheNewAddToCampaign';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheAddToCampaign);
    registerComponent(Vue, TheAddToCampaignAlreadyInCampaign);
    registerComponent(Vue, TheAddToCampaignReachMethod);
    registerComponent(Vue, TheAddToCampaignToSendMessage);
    registerComponent(Vue, TheAddToCampaignMessage);
    registerComponent(Vue, TheAddToCampaignToScan);
    registerComponent(Vue, TheAddToCampaignSummary);

    registerComponent(Vue, TheNewAddToCampaign);
  },
};

use(Plugin);

export default Plugin;

export {
  TheAddToCampaign,
  TheAddToCampaignAlreadyInCampaign,
  TheAddToCampaignReachMethod,
  TheAddToCampaignToSendMessage,
  TheAddToCampaignMessage,
  TheAddToCampaignToScan,
  TheAddToCampaignSummary,

  TheNewAddToCampaign,
};
