/* eslint-disable quotes */
/* eslint-disable object-curly-newline */

import builder from '../../engine';

const target = 'clark';

const downloadTask = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/channel/${payload.channelCid}/downloadTask`, method: 'REPORT', payload, httpQuery, target }),
  post: ({ payload }) => builder({ url: `/api/channelGroup/${payload.cgid}/channel/${payload.channelCid}/downloadTask`, method: 'POST', payload, target }),
};

export default downloadTask;
