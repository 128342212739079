import { use, registerComponent } from '@dailyplanet/utils/plugins';

import TheRouterNavigation from './TheRouterNavigation';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheRouterNavigation);
  },
};

use(Plugin);

export default Plugin;

export {
  TheRouterNavigation,
};
