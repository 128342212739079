/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';

import campaignActivity from './campaignActivity';
import contactNotesForPanel from './contactNotesForPanel';
import customs from './customs';
import heldThreads from './heldThreads';
import mention from './mention';
import tribeMember from './tribeMember';
import thread from './thread';
import contactPlatformStatistic from './contactPlatformStatistic';
import contactPlatformAffiliation from './contactPlatformAffiliation';

export default {
  namespaced: true,
  state: {
    currentContactPlatformOverview: 1,
    contactPlatformOverviewList: {},
    totalPeople: null,
    ...heldThreads.state,
  },
  mutations: {
    SET_CONTACT_PLATFORM_OVERVIEW_LIST(state, newData) {
      console.warn('🚀 ~ file: index.js ~ line 23 ~ SET_CONTACT_PLATFORM_OVERVIEW_LIST ~ newData', newData);
      state.contactPlatformOverviewList = newData;
    },
    SET_CURRENT_CONTACT_PLATFORM_OVERVIEW(state, newData) { state.currentContactPlatformOverview = newData; },
    SET_TOTAL_PEOPLE(state, newData) { state.totalPeople = newData; },

    RESET_CONTACT_PLATFORM_OVERVIEW_LIST(state) {
      state.contactPlatformOverviewList = {};
      state.totalPeople = 0;
      state.currentViewThread = {};
      state.heldThreads = {};
      state.heldThreadsCpids = [];
      state.heldThreadsTids = [];
    },
    ...heldThreads.mutations,
  },
  getters: {
    getterContactPlatformOverviewList: (state) => state.contactPlatformOverviewList,
    getterContactPlatformOverview: (state) => {
      if (state.heldThreadsCpids.includes(state.currentContactPlatformOverview)) {
        return state.heldThreads[state.currentContactPlatformOverview];
      }
      return state.contactPlatformOverviewList[state.currentContactPlatformOverview];
    },
    getterContactPlatformOverviewUnhelded: (state) => state.contactPlatformOverviewList[state.currentContactPlatformOverview],
    getterTotalPeople: (state) => state.totalPeople,
    ...heldThreads.getters,
  },
  actions: {
    resetContactPlatformOverviewList({ commit }) {
      commit('RESET_CONTACT_PLATFORM_OVERVIEW_LIST');
    },
    UP_currentContactPlatformOverview({ commit }, cpid) {
      commit('SET_CURRENT_CONTACT_PLATFORM_OVERVIEW', cpid);
    },

    report({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CRM.REPORT')) return undefined;

      return clark.api.apiContact.contactPlatformOverview.report({ payload, httpQuery })
        .then(({ results: contactPlatformOverviewList, total }) => {
          commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', { ...getters.getterContactPlatformOverviewList, ...contactPlatformOverviewList.arrayMapper('cpid') });
          commit('SET_TOTAL_PEOPLE', total);
          return total;
        });
    },

    getAndSetCurrent({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CRM.GET')) return undefined;

      return clark.api.apiContact.contactPlatformOverview.get({ payload, httpQuery })
        .then((contactPlatformOverview) => {
          commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
            ...getters.getterContactPlatformOverviewList,
            [contactPlatformOverview.cpid]: {
              ...getters.getterContactPlatformOverviewList[contactPlatformOverview.cpid],
              ...contactPlatformOverview,
            },
          });
          commit('SET_CURRENT_CONTACT_PLATFORM_OVERVIEW', contactPlatformOverview.cpid);
          return contactPlatformOverview;
        });
    },

    get({ commit, rootGetters, getters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CRM.GET')) return undefined;

      return clark.api.apiContact.contactPlatformOverview.get({ payload, httpQuery })
        .then((contactPlatformOverview) => {
          commit('SET_CONTACT_PLATFORM_OVERVIEW_LIST', {
            ...getters.getterContactPlatformOverviewList,
            [contactPlatformOverview.cpid]: {
              ...getters.getterContactPlatformOverviewList[contactPlatformOverview.cpid],
              ...contactPlatformOverview,
            },
          });
          return contactPlatformOverview;
        });
    },
    ...campaignActivity.actions,
    ...contactNotesForPanel.actions,
    ...contactPlatformAffiliation.actions,
    ...contactPlatformStatistic.actions,
    ...customs.actions,
    ...heldThreads.actions,
    ...mention.actions,
    ...thread.actions,
    ...tribeMember.actions,
  },
};
