import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

export default {
  namespaced: true,
  state: {

  },
  getters: {
    ...getters.account,
  },
  actions: {
    ...actions.iqdataReport,
    ...actions.segment,
  },
};
