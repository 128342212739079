import { actions, getters } from '@dailyplanet/data-stores/commonViewStores';

/* eslint-disable max-len */
export default {
  namespaced: true,
  getters: {
    ...getters.authenticate,
    ...getters.account,
    ...getters.userDetail,
    ...getters.theme,
  },
  actions: {
    ...actions.authenticate,
    ...actions.account,
    ...actions.user,
    ...actions.userDetail,
    ...actions.theme,
    ...actions.reportDetailledUser,
  },
};
