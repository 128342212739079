/* eslint-disable max-len */
import moment from 'moment';
import Errors from '@dailyplanet/utils/Errors';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    myAuthenticate: (_state, _getters, _rootState, rootGetters) => {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;

      return getterAuthenticate;
    },
    myChannelGroups: (_state, _getters, _rootState, rootGetters) => {
      const { 'Authenticate/getterCurrentChannelGroupList': currentChannelGroupList } = rootGetters;

      if (!currentChannelGroupList) return [];

      return Object.values(currentChannelGroupList);
    },
    myChannels: (_state, _getters, _rootState, rootGetters) => {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;

      return getterAuthenticate && getterAuthenticate.channelList ? getterAuthenticate.channelList : [];
    },
    myChannelInstagram: (_state, _getters) => {
      const { myChannels, currentChannelGroup } = _getters;

      return myChannels.find(({ platformName, channelGroupCgid }) => currentChannelGroup.cgid === channelGroupCgid && platformName === 'INSTAGRAM');
    },
    myChannelPrivate: (_state, _getters) => {
      const { myChannels, currentChannelGroup } = _getters;

      return myChannels.find(({ platformName, channelGroupCgid, token }) => currentChannelGroup.cgid === channelGroupCgid && platformName === 'INSTAGRAM' && token);
    },
    currentChannelGroup: (_state, _getters, _rootState, rootGetters) => {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

      return currentChannelGroup;
    },
    amIConnected: (_state, _getters, _rootState, rootGetters) => {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;

      return !!getterAuthenticate;
    },
  },
  actions: {
    up_currentChannelGroup({ commit }, channelGroup) {
      commit('Authenticate/SET_CURRENT_CHANNEL_GROUP', channelGroup, { root: true });
    },

    up_currentChannelGroupList({ commit, rootGetters }, channelGroup) {
      const { 'Authenticate/getterCurrentChannelGroupList': currentChannelGroupList } = rootGetters;

      const updatedChannelGroupList = {
        ...currentChannelGroupList,
        [channelGroup.cgid]: {
          ...channelGroup,
        },
      };

      commit('Authenticate/SET_CURRENT_CHANNEL_GROUP_LIST', updatedChannelGroupList, { root: true });
    },

    logIn({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'Authenticate/connectUser',
        context: { payload },
        textNotifier: 'Log In',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'login' }, { root: true }))
        .catch((error) => {
          dispatchFailure(action);
          throw new Errors(error);
        });
    },
    reconnect({ dispatch }) {
      const action = {
        dispatch,
        target: 'Authenticate/reconnectUser',
        context: {},
        textNotifier: 'reconnect',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .catch((error) => {
          dispatchFailure(action);
          throw new Errors(error);
        });
    },
    logOut({ dispatch }) {
      const action = {
        dispatch,
        target: 'Authenticate/disconnectUser',
        context: { },
        textNotifier: 'Log Out',
        retry: {
          onSuccess: false,
          onFailure: true,
        },
      };

      return dispatchAndForward(action)
        .then(() => dispatch('Segment/segmentTrack', { event: 'logout' }, { root: true }))
        .catch(() => {
          Snackbar.open({
            message: 'Unable to log Out.',
            ...snackBarFailure,
            indefinite: true,
          });

          return dispatchFailure(action);
        });
    },

    updateChangePasswordFromSelina({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'Authenticate/changePassword/updatePasswordFromSelina',
        context: {
          payload: {
            ...payload,
            method: 'UBU',
            createdOn: moment().format(),
            updatedOn: moment().format(),
            allowedOn: moment().format(),
            revokedOn: null,
            oneTime: false,
          },
        },
        textNotifier: 'Password successfully changed.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to change password.', ...snackBarFailure });
        });
    },

    updateChangePasswordFromKara({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'Authenticate/changePassword/updatePasswordFromKara',
        context: {
          payload: {
            ...payload,
            method: 'UBU',
            createdOn: moment().format(),
            updatedOn: moment().format(),
            allowedOn: moment().format(),
            revokedOn: null,
          },
        },
        textNotifier: 'Password successfully changed.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to change password.', ...snackBarFailure });
        });
    },

    updateChangeEmailFromSelina({ dispatch, rootGetters }, { payload }) {
      const { 'Authenticate/getterAuthenticate': myAuthenticate } = rootGetters;

      const action = {
        dispatch,
        target: 'Authenticate/changeEmail/updateEmailFromSelina',
        context: { payload: { aid: myAuthenticate.aid, ...payload } },
        textNotifier: 'Email successfully changed.',
        retry: { onSuccess: false, onFailure: true },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to change email.', ...snackBarFailure });
        });
    },

    updateChangeEmailFromKara({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'Authenticate/changeEmail/updateEmailFromKara',
        context: { payload },
        textNotifier: 'Email successfully changed.',
        retry: { onSuccess: false, onFailure: true },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to change email.', ...snackBarFailure });
        });
    },
  },
};
