import { use, registerComponent } from '@dailyplanet/utils/plugins';

import UbuContactPlatformPanelStats from './UbuContactPlatformPanelStats';
import UbuContactPlatformPanelCustoms from './UbuContactPlatformPanelCustoms';
import UbuContactPlatformPanelHeader from './UbuContactPlatformPanelHeader';
import UbuContactPlatformPanelItemTogglable from './UbuContactPlatformPanelItemTogglable';
import UbuNoteItemLogbook from './UbuNoteItemLogbook';
import UbuNoteItemPrivate from './UbuNoteItemPrivate';
import UbuContactPlatformPanelAffiliationDetailed from './UbuContactPlatformPanelAffiliationDetailed';
import UbuContactPlatformPanelCrewUser from './UbuContactPlatformPanelCrewUser';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuContactPlatformPanelStats);
    registerComponent(Vue, UbuContactPlatformPanelCustoms);
    registerComponent(Vue, UbuContactPlatformPanelHeader);
    registerComponent(Vue, UbuContactPlatformPanelItemTogglable);
    registerComponent(Vue, UbuNoteItemLogbook);
    registerComponent(Vue, UbuNoteItemPrivate);
    registerComponent(Vue, UbuContactPlatformPanelAffiliationDetailed);
    registerComponent(Vue, UbuContactPlatformPanelCrewUser);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuContactPlatformPanelStats,
  UbuContactPlatformPanelCustoms,
  UbuContactPlatformPanelHeader,
  UbuContactPlatformPanelItemTogglable,
  UbuNoteItemLogbook,
  UbuNoteItemPrivate,
  UbuContactPlatformPanelAffiliationDetailed,
  UbuContactPlatformPanelCrewUser,
};
