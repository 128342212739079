<template>
  <div>
    <p v-if="value && Number(value) !== 0">
      <span v-if="column.before">{{ column.before }}</span>
      <span>{{ value | nFormatter }}</span>
      <span v-if="column.after">{{ column.after }}</span>
    </p>
    <p v-else>-</p>
  </div>
</template>

<script>
import TableColumnMixin from '../_mixins/UbuTableColumn.mixin';

export default {
  name: 'UbuTableNumber',
  mixins: [TableColumnMixin],
  props: {
    cpo: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: String,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
  },
};
</script>
