/* eslint-disable max-len */
import { dispatchAndForward, genericErrorHandler } from '../../$utils/helpers';

export default {
  namespaced: true,
  getters: {
    membersStats: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiTribe/tribeAnalytics/getter_membersStats': membersStats } = rootGetters;

      return membersStats;
    },

    mentionsStats: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiTribe/tribeAnalytics/getter_mentionsStats': mentionsStats } = rootGetters;

      return mentionsStats;
    },

    salesStats: (_state, _getters, _rootState, rootGetters) => {
      const { 'ApiTribe/tribeAnalytics/getter_salesStats': salesStats } = rootGetters;

      return salesStats;
    },
  },
  actions: {
    report_membersStats({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const action = {
        dispatch,
        target: 'ApiTribe/tribeAnalytics/tribeAnalytics',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery: { ...httpQuery, tribeMembersStats: true } },
        textNotifier: 'Report analytics List',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report tribe members analytics.', indefinite: true }));
    },

    report_mentionsStats({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const action = {
        dispatch,
        target: 'ApiTribe/tribeAnalytics/tribeAnalytics',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery: { ...httpQuery, mentionsStats: true } },
        textNotifier: 'Report analytics List',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report tribe mentions analytics.', indefinite: true }));
    },

    report_salesStats({ dispatch, rootGetters }, { payload, httpQuery }) {
      const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;
      const action = {
        dispatch,
        target: 'ApiTribe/tribeAnalytics/tribeAnalytics',
        context: { payload: { ...payload, cgid: currentChannelGroup.cgid }, httpQuery: { ...httpQuery, salesStats: true } },
        textNotifier: 'Report analytics List',
      };

      return dispatchAndForward(action)
        .then(({ response }) => response)
        .catch(genericErrorHandler(action, { message: 'Unable to report tribe sales analytics.', indefinite: true }));
    },
  },
};
