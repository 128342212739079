<template>
  <div
    v-click-outside="() => isDropdownOpened = false"
    class="ubuDateRangePicker"
  >
    <div
      :class="['ubuDateRangePicker_button', {
        'disabled': disabled
      }]"
      @click="openDateRangePicker()"
    >
      <span class="text">{{ currentDisplayableDate }}</span>
      <b-icon
        pack="ubu"
        :icon="isDropdownOpened ? 'ubu-arrow-up' : 'ubu-arrow-down'"
      />
    </div>
    <div
      v-if="isDropdownOpened"
      class="ubuDateRangePicker_dropDown"
    >
      <b-datepicker
        v-model="currentDaterange"
        inline
        range
        :max-date="maxDate"
        nearby-selectable-month-days
        @input="createCustomDate($event)"
      />
      <div class="dateOptionList">
        <div
          v-for="(item, index) in dateOptions"
          :key="index"
          :class="['dateOption', 'subtext', {
            selected: item.subtype === dateOptions[currentDateIndex].subtype
          }]"
          @click="changeDate(item, index)"
        >
          {{ item.displayName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuDateRangePicker',
  props: {
    maxDate: { type: Date, default: null },
    disabled: { type: Boolean, default: false },
    optionDateToUse: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      date: null,
      currentDateIndex: 0,
      currentDaterange: [],
      customDateRange: [this.$moment().subtract({ week: 1 }).toDate(), this.$moment().toDate()],
      isDropdownOpened: false,

      optionDate: {
        currentWeek: {
          type: 'simple',
          subtype: 'currentWeek',
          displayName: 'Last 7 days',
          dateRange: [this.$moment().subtract({ week: 1 }).toDate(), this.$moment().toDate()],
          lastPeriodRange: [this.$moment().subtract({ week: 2 }).toDate(),
            this.$moment().subtract({ week: 1 }).toDate()],
        },
        previousWeek: {
          type: 'simple',
          subtype: 'previousWeek',
          displayName: 'Previous week',
          dateRange: [this.$moment().day(-6).toDate(), this.$moment().day(0).toDate()],
          lastPeriodRange: [this.$moment().day(-6).subtract({ week: 1 }).toDate(),
            this.$moment().day(0).subtract({ week: 1 }).toDate()],
        },
        currentMonth: {
          type: 'simple',
          subtype: 'currentMonth',
          displayName: 'Last 30 days',
          dateRange: [this.$moment().subtract({ month: 1 }).toDate(), this.$moment().toDate()],
          lastPeriodRange: [this.$moment().subtract({ month: 2 }).toDate(),
            this.$moment().subtract({ month: 1 }).toDate()],
        },
        previousMonth: {
          type: 'simple',
          subtype: 'previousMonth',
          displayName: 'Previous month',
          dateRange: [this.$moment().subtract({ month: 1 }).startOf('month').toDate(), this.$moment().subtract({ month: 1 }).endOf('month').toDate()],
          lastPeriodRange: [this.$moment().subtract({ month: 2 }).startOf('month').toDate(),
            this.$moment().subtract({ month: 2 }).endOf('month').toDate()],
        },
        last3Mounth: {
          type: 'simple',
          subtype: 'last3Mounth',
          displayName: 'Last 3 months',
          dateRange: [this.$moment().subtract({ month: 3 }).toDate(), this.$moment().toDate()],
          lastPeriodRange: [this.$moment().subtract({ month: 6 }).toDate(),
            this.$moment().subtract({ month: 3 }).toDate()],
        },
        last6Mounth: {
          type: 'simple',
          subtype: 'last6Mounth',
          displayName: 'Last 6 months',
          dateRange: [this.$moment().subtract({ month: 6 }).toDate(), this.$moment().toDate()],
          lastPeriodRange: [this.$moment().subtract({ year: 1 }).toDate(),
            this.$moment().subtract({ month: 6 }).toDate()],
        },
        currentYear: {
          type: 'simple',
          subtype: 'currentYear',
          displayName: 'This year',
          dateRange: [this.$moment().subtract({ year: 1 }).toDate(), this.$moment().toDate()],
          lastPeriodRange: [this.$moment().subtract({ year: 2 }).toDate(),
            this.$moment().subtract({ year: 1 }).toDate()],
        },
      },
    };
  },
  computed: {
    dateOptions() {
      const [customMinDate, customMaxDate] = this.customDateRange;
      const diffBetween = this.$moment(customMaxDate).diff(customMinDate, 'days');

      const defaultOptions = [{
        type: 'custom',
        subtype: 'custom',
        displayName: 'Custom date range',
        dateRange: this.customDateRange,
        lastPeriodRange: [this.$moment(customMinDate).subtract({ days: diffBetween || 1 })
          .toDate(), this.$moment(customMinDate).toDate()],
      }];

      return this.optionDateToUse.reduce((acc, option) => {
        if (this.optionDate[option]) acc.push(this.optionDate[option]);

        return acc;
      }, []).concat(defaultOptions);
    },

    currentDisplayableDate() {
      if (this.dateOptions[this.currentDateIndex].type === 'custom') return this.customDateRangeString;
      return this.dateOptions[this.currentDateIndex].displayName;
    },
    customDateRangeString() {
      if (!this.customDateRange.length) return 'Custom date range';
      const [minDate, maxDate] = this.customDateRange;
      if (this.$moment(minDate).isSame(maxDate, 'day')) return this.$moment(minDate).format('YYYY-MM-DD');
      return `${this.$moment(minDate).format('YYYY-MM-DD')} - ${this.$moment(maxDate).format('YYYY-MM-DD')}`;
    },
  },
  mounted() {
    this.currentDaterange = this.dateOptions[0].dateRange;
  },
  methods: {
    changeDate(dateOption, index) {
      this.currentDateIndex = index;
      this.currentDaterange = dateOption.dateRange;
      this.$emit('changeDate', {
        value: this.currentDaterange,
        lastPeriod: dateOption.lastPeriodRange,
        type: dateOption.type,
        subtype: dateOption.subtype,
      });
    },
    createCustomDate(dateRange) {
      const [min, max] = dateRange;
      // const diffBetween = this.$moment(min).diff(max, 'days');
      // const momentDateRange = [this.$moment(min).toDate(), this.$moment(max)
      //   .add({ days: diffBetween === 0 ? 1 : 0 }).toDate()];
      const momentDateRange = [this.$moment(min).toDate(), this.$moment(max).toDate()];
      this.currentDateIndex = 4;
      this.currentDaterange = dateRange;
      this.customDateRange = dateRange;
      this.isDropdownOpened = false;
      const lastPeriod = this.dateOptions.find((d) => d.type === 'custom').lastPeriodRange;
      this.$emit('changeDate', { value: momentDateRange, lastPeriod, type: 'custom' });
    },
    openDateRangePicker() {
      if (this.disabled) return;
      this.isDropdownOpened = !this.isDropdownOpened;
    },
  },
};
</script>
