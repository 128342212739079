<template>
  <section class="ubuSidePanel ubuPanel-xl">
    <div class="buttons is-right">
      <b-button
        class="is-ghost is-borderless is-large"
        icon-pack="ubu"
        icon-left="ubu-cross"
        @click="$emit('closePanel')"
      />
    </div>

    <br>

    <section>
      <h1 class="title is-1">Export analytics</h1>

      <br>

      <form @submit.prevent="exportAnalytics()">
        <b-field label="Emails">
          <b-input
            v-model.trim="localExport.emails"
            placeholder="One email per line..."
            type="textarea"
            required
            @input="$update('emails', $event)"
          />
        </b-field>

        <br>

        <b-field grouped>
          <b-field
            label="From"
            expanded
          >
            <b-datepicker
              v-model="localExport.minDate"
              placeholder="Select"
              position="is-top-left"
              append-to-body
              nearby-selectable-month-days
              :max-date="this.$moment().toDate()"
              :date-formatter="(date) => this.$moment(date).format('YYYY-MM-DD')"
              @input="$update('minDate', $event)"
            />
          </b-field>

          <b-field
            label="To"
            expanded
          >
            <b-datepicker
              v-model="localExport.maxDate"
              placeholder="Select"
              position="is-top-left"
              append-to-body
              nearby-selectable-month-days
              :min-date="this.$moment(localExport.minDate).toDate()"
              :max-date="this.$moment().toDate()"
              :date-formatter="(date) => this.$moment(date).format('YYYY-MM-DD')"
              @input="$update('maxDate', $event)"
            />
          </b-field>
        </b-field>

        <br>

        <div class="buttons is-right">
          <b-button
            native-type="submit"
            label="Export"
            class="is-primary"
          />
        </div>
      </form>
    </section>
  </section>
</template>

<script>
import VModelMixin from '@dailyplanet/mixins/VModelMixin';
import { snackbarError } from '@dailyplanet/utils/helpers';

export default {
  name: 'UbuExportAnalyticsPanel',
  mixins: [VModelMixin],
  model: {
    prop: 'export',
  },
  props: {
    export: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    localExport: {
      get() { return { ...this.export }; },
      set(val) { this.$emit('input', val); },
    },
    formIsValid() {
      const { minDate, maxDate, emails } = this.localExport;

      return minDate && maxDate && emails.trim().length;
    },
  },
  methods: {
    exportAnalytics() {
      if (this.formIsValid) {
        this.$emit('export');
        this.$emit('closePanel');
      } else {
        this.$buefy.snackbar.open({
          ...snackbarError,
          message: 'You must fill all field',
        });
      }
    },
  },
};
</script>
