/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import builder from '../../../engine';

const target = 'clark';

const favorite = {
  post: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/favoritePublication`, method: 'POST', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channelGroup/${payload.cgid}/favoritePublication/${payload.pid}`, method: 'DELETE', payload, httpQuery, target }),
};

export default favorite;
