/* eslint-disable max-len */
import { clark } from '@dailyplanet/providers';
import assign from './assign.store';
import autoTag from './autoTag.store';

export default {
  namespaced: true,
  state: {
    threadGroupList: {},
  },
  mutations: {
    SET_THREAD_GROUP_LIST(state, newData) { state.threadGroupList = Object.values(state.threadGroupList).concat(newData).arrayMapper('tgid'); },
    UNSET_THREAD_GROUP(state, newData) { state.threadGroupList = Object.values(state.threadGroupList).filter(({ tgid }) => tgid !== newData).arrayMapper('tgid'); },
    RESET_STORE(state) {
      state.threadGroupList = {};
    },
  },
  getters: {
    getterThreadGroupList: (state) => state.threadGroupList,
  },
  actions: {
    up_threadGroupList({ commit }, threadGroup) {
      console.log('🚀 ~ file: index.js ~ line 23 ~ up_threadGroupList ~ threadGroup', threadGroup);
      commit('SET_THREAD_GROUP_LIST', [threadGroup]);
    },

    remove_threadGroup({ commit }, tgid) {
      commit('UNSET_THREAD_GROUP', tgid);
    },

    report({ commit }, { payload }) {
      return clark.api.apiConversation.threadGroup.report({ payload, httpQuery: {} })
        .then((threadGroupList) => {
          commit('SET_THREAD_GROUP_LIST', threadGroupList);
        });
    },
    create({ commit }, { payload }) {
      return clark.api.apiConversation.threadGroup.post({ payload, httpQuery: {} })
        .then((threadGroup) => {
          commit('SET_THREAD_GROUP_LIST', [threadGroup]);
          return threadGroup;
        });
    },
    update({ commit }, { payload }) {
      return clark.api.apiConversation.threadGroup.put({ payload, httpQuery: {} })
        .then((threadGroup) => {
          commit('SET_THREAD_GROUP_LIST', [threadGroup]);
          return threadGroup;
        });
    },
    delete({ commit }, { payload }) {
      return clark.api.apiConversation.threadGroup.delete({ payload, httpQuery: {} })
        .then(() => {
          commit('UNSET_THREAD_GROUP', payload.tgid);
        });
    },
  },
  modules: {
    assign,
    autoTag,
  },
};
