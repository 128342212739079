/* eslint-disable no-multi-spaces */
import TheServers from './TheServers';

export default [
  {
    path: '/servers',
    name: 'Servers',
    component: TheServers,
    meta: {
      inSidebar: true,
      sidebarSlot: 'Kara',
      requireAuth: true,
      mainFolder: 'App',
    },
  },
];
