import { clark } from '@dailyplanet/providers';
import { isActionAllowed } from '@dailyplanet/data-stores/$utils/helpers';
import { analyticsFormater } from '@dailyplanet/selina-addons/activity/$utils/analyticsUtils';

export default {
  namespaced: true,
  state: {
    accountAnalytics: {},
  },
  mutations: {
    SET_ACCOUNT_ANALYTICS(state, newData) { state.accountAnalytics = newData; },
    RESET_STORE(state) {
      state.accountAnalytics = {};
    },
  },
  getters: {
    getterAccountAnalytics: (state) => state.accountAnalytics,
  },
  actions: {
    get({ commit, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'ACCOUNT_ANALYTICS.GET')) return undefined;

      return clark.api.channelGroup.accountAnalytics.get({ payload, httpQuery })
        .then(({ currentAnalytics, previousAnalytics }) => {
          if (!currentAnalytics || !previousAnalytics) return;
          const { 'Account/getterAccount': account } = rootGetters;
          const { 'Authenticate/getterCurrentChannelGroup': currentChannelGroup } = rootGetters;

          const channelGroup = account.channelGroupList.find(
            ({ cgid }) => cgid === currentChannelGroup.cgid,
          );
          const { name: username } = channelGroup.channelList.find(({ platformName }) => platformName === 'INSTAGRAM');

          commit('SET_ACCOUNT_ANALYTICS', { currentAnalytics: analyticsFormater({ analytics: currentAnalytics, username }), previousAnalytics: analyticsFormater({ analytics: previousAnalytics }) });
        });
    },
  },
};
