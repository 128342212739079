<template>
  <section
    id="TheCampaign"
    class="mainPanel"
  >
    <header
      id="TheCampaignHeader"
      class="mainPanelHeader multibar row-vcentered"
    >
      <h1 :class="`start title is-2 multibar row-vcentered`">
        <b-icon
          :class="`has-text-color-${campaign.color}`"
          pack="ubu"
          icon="ubu-custom-influence"
        />
        <span
          :class="`ml-2 has-text-color-${campaign.color}`"
          class="ml-2"
        >
          {{ campaign.name }}
        </span>
        <b-icon
          class="ml-2 clickable text-color-0"
          pack="ubu"
          icon="ubu-settings"
          @click.native="openCampaignSettingsPanel = !openCampaignSettingsPanel"
        />
      </h1>

      <nav class="end buttons expandable">
        <b-button
          v-for="item in navigation"
          :key="item.path"
          tag="router-link"
          type="is-simple"
          :to="item.path"
          :active="$route.fullPath.includes(item.path)"
        >
          {{ item.label }}
        </b-button>
      </nav>
    </header>

    <router-view
      v-if="!isLoading"
      class="TheCampaignBody"
    />

    <TheCampaignSettingsPanel
      v-if="openCampaignSettingsPanel"
      v-model="campaignDatas"
      @closePanel="openCampaignSettingsPanel = false"
    />

    <div
      v-if="isBackgroundLocked"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheCampaign',
  data() {
    return {
      isLoading: false,
      openCampaignSettingsPanel: false,
    };
  },
  computed: {
    ...mapGetters({
      currentCampaignCid: 'TheCampaign/currentCampaignCid',
      campaign: 'TheCampaign/currentCampaign',
    }),
    navigation() {
      const basePath = `/campaign/${this.$route.params.campaignCid}`;
      return [
        {
          label: 'People',
          path: `${basePath}/people`,
        },
        {
          label: 'Messages',
          path: `${basePath}/messages/campaign/${this.$route.params.campaignCid}`,
        },
        {
          label: 'Mentions',
          path: `${basePath}/mentions`,
        },
        {
          label: 'Analytics',
          path: `${basePath}/analytics`,
        },
      ];
    },
    isBackgroundLocked() {
      if (this.openCampaignSettingsPanel) return true;
      return false;
    },
    campaignDatas() {
      const { overview, population, ...campaign } = this.campaign;
      return campaign;
    },
  },
  beforeMount() {
    return this._resetContactPlatformOverviewList();
  },
  mounted() {
    this.isLoading = true;
    return this.getCampaign({
      payload: { cid: this.$route.params.campaignCid },
      httpQuery: { overview: true },
    })
      .then(() => { this.isLoading = false; });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.campaignCid === from.params.campaignCid) return next();

    this.isLoading = true;
    return Promise.resolve()
      .then(() => this._resetActivityOverviewList())
      .then(() => this.getCampaign({
        payload: { cid: to.params.campaignCid },
        httpQuery: { overview: true },
      }))
      .then(() => {
        this.isLoading = false;
        return next();
      });
  },
  beforeDestroy() {
    return Promise.all([
      // this._resetCampaignMemberList(),
      // this._resetCampaignMentionList(),
    ]);
  },
  methods: {
    ...mapActions({
      getCampaign: 'TheInfluence/getCampaign',
      _resetActivityOverviewList: 'ApiInfluence/activityOverview/UP_resetActivityOverviewList',
      _resetContactPlatformOverviewList: 'ApiContact/contactPlatformOverview/resetContactPlatformOverviewList',
    }),
  },
};
</script>
